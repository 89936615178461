import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Dispatch, useMemo } from 'react';
import { useState } from 'react';
import { SetStateAction } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDebounce } from 'use-lodash-debounce';

import useAxiosHook from '../../../hooks/useAxiosHook';
import useHaveValuesChanged from '../../../hooks/useHaveValuesChanged';
import useSearchQueryDropdownParam from '../../../hooks/useSearchQueryDropdownParam';
import useSearchQueryMultiSelectParam from '../../../hooks/useSearchQueryMultiSelectParam';
import useSearchQueryParam from '../../../hooks/useSearchQueryParam';
import { WithPagination } from '../../../types/api';
import { LabelValue } from '../../../types/options';
import { debounceTimeout } from '../../../utils/constants/misc';
import { queryString } from '../../../utils/helpers/http';
import { tryInt } from '../../../utils/helpers/parse';
import {
  getSearchQueryParam,
  tryMultiSelectParam,
} from '../../../utils/helpers/searchQuery';
import TableHeaderFilters from '../../DataTable/Table/HeaderFilters/TableHeaderFilters';
import {
  OrdinaryFilter,
  filterByValue,
} from '../../DataTable/Table/HeaderFilters/TableHeaderFilters.function';
import { OAuthClient } from '../OAuthClients/OAuthClients.functions';

const rolesOptions: LabelValue<number>[] = [
  { label: 'Administration & Management', value: 1 },
  { label: 'Cash Desk', value: 2 },
  { label: 'Courier', value: 3 },
  { label: 'Warehouse', value: 4 },
  { label: 'Contact Center', value: 5 },
  { label: 'Sales & Marketing', value: 6 },
  { label: 'Transport (Courier)', value: 7 },
  { label: 'Finance', value: 8 },
  { label: 'Customer', value: 100 },
];

function useTableFilters(
  page: number,
  setPage: Dispatch<SetStateAction<number>>,
  limit: number
) {
  const { t } = useTranslation();

  const location = useLocation();

  const [headerFiltersCount, setHeadersFilterCount] = useState<number>(0);

  const [nameFilter, setNameFilter] = useState(
    () => getSearchQueryParam(location.search, 'name') ?? ''
  );

  const [uriFilter, setUriFilter] = useState(
    () => getSearchQueryParam(location.search, 'uri') ?? ''
  );

  const [clientTypeFilter, setClientTypeFilter] = useState(
    () => tryInt(getSearchQueryParam(location.search, 'clientType')) ?? null
  );

  const [rolesFilter, setRolesFilter] = useState(() => {
    const value = getSearchQueryParam(location.search, 'role') ?? null;

    return value
      ? tryMultiSelectParam(value)?.map((o) => Number(o)) ?? null
      : null;
  });

  const debouncedNameFilter = useDebounce(nameFilter, debounceTimeout);
  const debouncedUriFilter = useDebounce(uriFilter, debounceTimeout);

  const { data: oAuthClientsData, isLoading: isOAuthClientsLoading } =
    useAxiosHook<WithPagination<OAuthClient[]>>('/oauthclients?limit=0');

  const clientTypeOptions = useMemo<LabelValue<number>[]>(
    () =>
      oAuthClientsData?.data.map((c) => ({
        label: c.client_name,
        value: c.id,
      })) ?? [],
    [oAuthClientsData?.data]
  );

  const filtersArr = useMemo(() => [], []);

  const haveFiltersChanged = useHaveValuesChanged(filtersArr);

  useEffect(() => {
    setPage(1);
  }, [
    setPage,
    debouncedNameFilter,
    debouncedUriFilter,
    clientTypeFilter,
    rolesFilter,
  ]);

  useSearchQueryParam('name', debouncedNameFilter);
  useSearchQueryParam('uri', uriFilter);

  useSearchQueryDropdownParam(
    'clientType',
    clientTypeFilter,
    setClientTypeFilter,
    clientTypeOptions
  );

  useSearchQueryMultiSelectParam('role', rolesFilter);

  const httpFiltersObj = useMemo(
    () =>
      queryString({
        page: haveFiltersChanged ? 1 : page,
        limit,
        name: debouncedNameFilter,
        uri: debouncedUriFilter,
        client_type: clientTypeFilter,
        roles: rolesFilter,
      }),
    [
      clientTypeFilter,
      debouncedNameFilter,
      debouncedUriFilter,
      haveFiltersChanged,
      limit,
      page,
      rolesFilter,
    ]
  );

  const headerFiltersForm = useMemo<JSX.Element>(
    () => (
      <>
        <div className="filter">
          <label htmlFor="filter_name">{t('Name')}</label>
          <InputText
            id="filter_name"
            value={nameFilter}
            onChange={(e) => setNameFilter(e.target.value)}
          />
        </div>

        <div className="filter">
          <label htmlFor="filter_uri">{t('URI')}</label>
          <InputText
            id="filter_uri"
            value={uriFilter}
            onChange={(e) => setUriFilter(e.target.value)}
          />
        </div>

        <div className="filter">
          <label htmlFor="filter_clientType">{t('Client type')}</label>
          <Dropdown
            id="filter_clientType"
            value={clientTypeFilter}
            options={clientTypeOptions}
            onChange={(e) => setClientTypeFilter(e.value)}
            disabled={isOAuthClientsLoading}
            showClear
            filter
          />
        </div>

        <div className="filter">
          <label htmlFor="filter_roles">{t('Roles')}</label>
          <MultiSelect
            inputId="filter_roles"
            value={rolesFilter}
            options={rolesOptions}
            maxSelectedLabels={0}
            selectedItemsLabel={t('{0} selected')}
            filter
            showClear
            onChange={(e) => {
              setRolesFilter(e.target.value);
            }}
          />
        </div>
      </>
    ),
    [
      clientTypeFilter,
      clientTypeOptions,
      isOAuthClientsLoading,
      nameFilter,
      rolesFilter,
      t,
      uriFilter,
    ]
  );

  const headerFilters = useMemo(() => {
    const ordinary: OrdinaryFilter[] = [
      {
        label: t('Name'),
        value: nameFilter,
        onDelete: () => setNameFilter(''),
      },
      {
        label: t('URI'),
        value: uriFilter,
        onDelete: () => setUriFilter(''),
      },
      {
        label: t('Client type'),
        value: clientTypeFilter
          ? clientTypeOptions.find((m) => m.value === clientTypeFilter)
              ?.label ?? ''
          : '',
        onDelete: () => setClientTypeFilter(null),
      },
      {
        label: t('Notification type'),
        value: rolesFilter
          ? rolesOptions
              .filter((o) => rolesFilter.includes(o.value))
              .map((t) => t.label)
              .join(', ')
          : '',
        onDelete: () => setRolesFilter(null),
      },
    ].filter(filterByValue);

    setHeadersFilterCount(ordinary.length);

    return <TableHeaderFilters ordinary={ordinary} />;
  }, [
    clientTypeFilter,
    clientTypeOptions,
    nameFilter,
    rolesFilter,
    t,
    uriFilter,
  ]);

  function resetAllFilters() {
    setNameFilter('');
    setUriFilter('');
    setClientTypeFilter(null);
    setRolesFilter(null);
  }

  return {
    headerFiltersForm,
    headerFilters,
    resetAllFilters,
    httpFiltersObj,
    headerFiltersCount,
  };
}

export default useTableFilters;
