import './SendSpecToInvolvedDialog.scss';

import * as Sentry from '@sentry/react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { MultiSelect } from 'primereact/multiselect';
import { ToastMessage } from 'primereact/toast';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ToastContext from '../../../../../context/ToastContext';
import useAxiosHook from '../../../../../hooks/useAxiosHook';
import usePrevious from '../../../../../hooks/usePrevious';
import {
  errorToast,
  successToast,
} from '../../../../../utils/helpers/primereact';
import { BulkOrder, BulkOrderParties } from '../../BulkOrders.functions';

type FormSubmissionData = {
  id: number;
  name: string;
  message: string;
  party: number;
  status: boolean;
}[];

type Props = {
  data: BulkOrderParties[];
  selectedList: BulkOrder | any;
  isShown: boolean;
  onHide: () => void;
};

function SendSpecToInvolvedDialog({
  data,
  selectedList,
  isShown,
  onHide,
}: Props) {
  const { t } = useTranslation();
  const [selectedInvolved, setSelectedInvolved] =
    useState<string[] | null>(null);
  const { toastRef } = useContext(ToastContext);

  useEffect(() => {
    if (!isShown) {
      setSelectedInvolved(null);
    }
  }, [isShown]);

  const {
    data: formSubmissionData,
    error: formSubmissionError,
    reload: formSubmissionReload,
    isLoading: isFormSubmissionLoading,
  } = useAxiosHook<FormSubmissionData>();

  const prevFormSubmissionData = usePrevious(formSubmissionData);
  const prevFormSubmissionError = usePrevious(formSubmissionError);

  useEffect(() => {
    if (
      !formSubmissionData ||
      formSubmissionData === prevFormSubmissionData ||
      !toastRef?.current
    ) {
      return;
    }

    const successes = formSubmissionData.filter((p) => p.status);
    const failures = formSubmissionData.filter((p) => !p.status);

    if (successes.length === formSubmissionData.length) {
      successToast(
        toastRef,
        t('Success'),
        t('Mail was sent successfully to all parties involved.'),
        { life: 10000 }
      );

      return;
    }

    toastRef.current.show([
      // Success messages
      {
        severity: 'warn',
        summary: t('Warning'),
        detail: t(
          'Mail was sent successfully to {{successArrayLength}} of {{responseDataLength}} parties involved.',
          {
            successArrayLength: successes.length,
            responseDataLength: formSubmissionData.length,
          }
        ),
        life: 20000,
      },
      // Failed
      ...failures.map(
        (f): ToastMessage => ({
          severity: 'error',
          summary: t('Error'),
          detail: t('Mail was not sent to {{name}} because {{message}}.', {
            name: f.name,
            message: f.message,
          }),
          life: 20000,
          sticky: true,
        })
      ),
    ]);
  }, [prevFormSubmissionData, formSubmissionData, t, toastRef]);

  useEffect(() => {
    if (
      !formSubmissionError ||
      formSubmissionError === prevFormSubmissionError
    ) {
      return;
    }

    errorToast(
      toastRef,
      t('Error'),
      t(
        'An error occured while trying to send specification to the involved parties.'
      )
    );
  }, [prevFormSubmissionError, formSubmissionError, t, toastRef]);

  function handleSendSpecToInvolvedSubmit() {
    if (!selectedList) {
      // Since this is a dialog, we want to capture this
      Sentry.captureMessage(
        `selectedList is falsy: ${selectedList}`,
        Sentry.Severity.Error
      );

      return;
    }

    let payloadObj = {
      import_list_id: selectedList.id,
    };

    if (selectedInvolved?.length) {
      (payloadObj as any)['parties'] = selectedInvolved.map((sInvolved) => {
        const [sId, sParty] = sInvolved.split('-');

        const selectedObj = data.find(
          (party) =>
            party.id === parseInt(sId) && party.party === parseInt(sParty)
        );

        return selectedObj ?? null;
      });
    }

    formSubmissionReload({
      url: `/orders/batch/send-specification`,
      data: payloadObj,
      method: 'POST',
    });
  }

  const dialogFooter = (
    <>
      <Button
        type="button"
        label={t('Cancel')}
        className="p-button-text"
        onClick={() => onHide()}
      />

      <Button
        type="button"
        label={isFormSubmissionLoading ? t('Sending...') : t('Send')}
        disabled={isFormSubmissionLoading}
        onClick={() => {
          handleSendSpecToInvolvedSubmit();
          onHide();
          setSelectedInvolved(null);
        }}
        data-cy="submit-btn"
      />
    </>
  );

  const involvedPartiesOptions = useMemo(
    () => [
      ...(Array.isArray(data)
        ? data.map((p) => ({
            label: `${p.name} ${
              p.party === 0 ? `(${t('Orderer')})` : `(${t('Sender')})`
            }`,
            value: `${p.id}-${p.party}`,
          }))
        : []),
    ],
    [data, t]
  );

  return (
    <Dialog
      visible={isShown}
      onHide={onHide}
      header={t('Send specifications to involved parties')}
      footer={dialogFooter}
      resizable={false}
      className="send-to-involved-dialog"
    >
      <div className="p-fluid">
        <div className="p-field">
          <label htmlFor="parties">
            {t('Select involved parties for sending specifications.')}
          </label>
          <MultiSelect
            inputId="parties"
            value={selectedInvolved}
            options={involvedPartiesOptions}
            onChange={(e) => setSelectedInvolved(e.value)}
            placeholder={t('All clients')}
            filter
          />
        </div>
      </div>
    </Dialog>
  );
}

export default SendSpecToInvolvedDialog;
