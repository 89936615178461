import dayjs, { Dayjs } from 'dayjs';
import { CalendarDateTemplateParams } from 'primereact/calendar';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { $enum } from 'ts-enum-util';
import { useDebounce } from 'use-lodash-debounce';

import { IntBool } from '../../../../enums/booleans';
import useAxiosHook from '../../../../hooks/useAxiosHook';
import { useEndpointGuard } from '../../../../hooks/useEndpointGuard';
import usePrevious from '../../../../hooks/usePrevious';
import useSearchQueryDateParam from '../../../../hooks/useSearchQueryDateParam';
import useSearchQueryDropdownParam from '../../../../hooks/useSearchQueryDropdownParam';
import useSearchQueryParam from '../../../../hooks/useSearchQueryParam';
import useToastMessage from '../../../../hooks/useToastMessage';
import { ConfigCollection } from '../../../../types/api/configs';
import {
  CourierWorkOrderQueryParams,
  CourierWorkOrderResource,
} from '../../../../types/api/couriers';
import { HolidayCollection } from '../../../../types/api/holidays';
import { AvailableHubsCollection } from '../../../../types/api/hubs';
import { RegionCollection } from '../../../../types/api/regions';
import { Numeric } from '../../../../types/general';
import { ReduxState } from '../../../../types/redux';
import { configCollectionGuard } from '../../../../utils/constants/auth/_api/configs';
import { availableHubCollectionGuard } from '../../../../utils/constants/auth/_api/hubs';
import { regionCollectionGuard } from '../../../../utils/constants/auth/_api/regions';
import { debounceTimeout } from '../../../../utils/constants/misc';
import {
  findSingleConfig,
  getBoolConfigValue,
} from '../../../../utils/helpers/api/configs';
import { httpDateFormat } from '../../../../utils/helpers/formatting';
import { queryString } from '../../../../utils/helpers/http';
import { tryInt } from '../../../../utils/helpers/parse';
import {
  getSearchQueryParam,
  tryDateSearchParam,
} from '../../../../utils/helpers/searchQuery';
import { getCandidateFilterOptions } from './functions';
import { CandidatesFilter, WarehousePage } from './types';

type Props = {
  page: WarehousePage;
  isDestinationHubFilterShown?: boolean;
  isTransferCourierFilterShown?: boolean;
  noCouriersAvailableMessage?: string;
};

function getIsPast(page: WarehousePage) {
  if (page > WarehousePage.HubShipmentDistribution) {
    return true;
  }
  return false;
}

function useCommonState(props: Props) {
  const { t } = useTranslation();

  const isPast = getIsPast(props.page);

  const maxDaysCount = 2;

  const requiredProps = useMemo<Required<Props>>(() => {
    const defaultProps = {
      isDestinationHubFilterShown: false,
      isTransferCourierFilterShown: false,
      noCouriersAvailableMessage: t('No couriers are available'),
    };

    return { ...defaultProps, ...(props ?? {}) };
  }, [props, t]);

  const { isDestinationHubFilterShown } = requiredProps;

  const nativeHubId = useSelector<
    ReduxState,
    ReduxState['user']['employee_hub_id']
  >((store) => store.user.employee_hub_id);

  const location = useLocation();

  const canLoadAvailableHubs = useEndpointGuard(availableHubCollectionGuard);
  const canLoadConfigs = useEndpointGuard(configCollectionGuard);
  const canLoadRegions = useEndpointGuard(regionCollectionGuard);

  const canLoadSounds = canLoadConfigs && canLoadRegions;

  const [dateFilter, setDateFilter] = useState<Date>(() => {
    const date =
      tryDateSearchParam(getSearchQueryParam(location.search, 'date') ?? '') ??
      new Date();

    if (date > new Date()) {
      return new Date();
    }

    return date;
  });

  const [destinationHubFilter, setDestinationHubFilter] = useState<
    number | null
  >(() => {
    const param = tryInt(
      getSearchQueryParam(location.search, 'destinationHub')
    );

    return param !== undefined ? param! : null;
  });

  const [courierFilter, setCourierFilter] = useState<number | null>(() => {
    const param = tryInt(getSearchQueryParam(location.search, 'courier'));

    return param !== undefined ? param! : null;
  });

  const [transferCourierFilter, setTransferCourierFilter] = useState<
    string | null
  >(() => {
    const param = getSearchQueryParam(location.search, 'transferCourier');

    return tryInt(param) !== undefined ? param! : null;
  });

  const [candidatesFilter, setCandidatesFilter] = useState<CandidatesFilter>(
    () => {
      const param = getSearchQueryParam(location.search, 'candidates');

      return $enum(CandidatesFilter).asValueOrDefault(
        param,
        CandidatesFilter.First
      );
    }
  );

  const [serialNumberFilter, setSerialNumberFilter] = useState(
    () => getSearchQueryParam(location.search, 'serialNumber') ?? ''
  );

  const debouncedSerialNumberFilter = useDebounce(
    serialNumberFilter,
    debounceTimeout
  );

  const [isCreateWorkOrderDialogShown, setIsCreateWorkOrderDialogShown] =
    useState(false);

  const [isWorkOrderNotOpenDialogShown, setIsWorkOrderNotOpenDialogShown] =
    useState(false);

  const courierWorkOrderRequest = useAxiosHook<CourierWorkOrderResource>(
    `/couriers/${courierFilter}/work-order` +
      queryString<CourierWorkOrderQueryParams>({
        date: httpDateFormat(dateFilter),
      }),
    { skipWhen: !courierFilter }
  );

  const { data: holidays, isLoading: isLoadingHolidays } =
    useAxiosHook<HolidayCollection>('/holidays' + queryString({ limit: 1000 }));

  const { data: courierWorkOrderData, error: courierWorkOrderError } =
    courierWorkOrderRequest;

  const prevCourierWorkOrderData = usePrevious(courierWorkOrderData);

  const isHoliday = (date: Dayjs, holidays: string[]) => {
    return holidays.find((holyday) => {
      return holyday === dayjs(date).format('YYYY-MM-DD');
    });
  };

  const getSelectableDays = useCallback(
    (past: boolean) => {
      if (!holidays) {
        return;
      }

      function getNextDate(past: boolean, date: Dayjs) {
        if (past) {
          return dayjs(date).subtract(1, 'day');
        }
        return dayjs(date).add(1, 'day');
      }

      let selectableDays = [dayjs()];
      let daysCount = 0;
      let tempDate = getNextDate(past, dayjs());

      while (true) {
        if (daysCount >= maxDaysCount) {
          return selectableDays;
        }

        if (dayjs(tempDate).day() === 0) {
          // 0 === Sunday
          selectableDays.push(tempDate);
          tempDate = getNextDate(past, tempDate);

          continue;
        }

        if (
          isHoliday(
            tempDate,
            holidays?.data.map((h) => h.date)
          )
        ) {
          tempDate = getNextDate(past, tempDate);
          continue;
        }

        selectableDays.push(tempDate);
        daysCount++;
        tempDate = getNextDate(past, tempDate);
      }
    },
    [holidays]
  );

  const isSelectable = (_date: Dayjs, past: boolean) => {
    if (
      getSelectableDays(past)?.find((date) => dayjs(date).isSame(_date, 'day'))
    ) {
      return true;
    }

    return false;
  };

  const isDateValid = isSelectable(dayjs(dateFilter), isPast);

  useEffect(() => {
    if (courierWorkOrderData === prevCourierWorkOrderData) {
      return;
    }

    setIsCreateWorkOrderDialogShown(
      !!courierFilter &&
        !!courierWorkOrderData &&
        !Object.keys(courierWorkOrderData).length &&
        isDateValid
    );
  }, [
    courierFilter,
    isDateValid,
    prevCourierWorkOrderData,
    setIsCreateWorkOrderDialogShown,
    courierWorkOrderData,
  ]);

  useToastMessage(undefined, courierWorkOrderError, {
    error: {
      summary: t('An error occured while loading work order data.'),
    },
  });

  const transferCourierWorkOrderRequest =
    useAxiosHook<CourierWorkOrderResource>(
      `/couriers/${transferCourierFilter}/work-order` +
        queryString<CourierWorkOrderQueryParams>({
          date: httpDateFormat(dateFilter),
        }),
      { skipWhen: !transferCourierFilter }
    );

  const {
    data: transferCourierWorkOrderData,
    error: transferCourierWorkOrderError,
  } = transferCourierWorkOrderRequest;

  const prevTransferCourierWorkOrderData = usePrevious(
    transferCourierWorkOrderData
  );

  useEffect(() => {
    if (transferCourierWorkOrderData === prevTransferCourierWorkOrderData) {
      return;
    }

    setIsWorkOrderNotOpenDialogShown(
      !!transferCourierFilter &&
        !!transferCourierWorkOrderData &&
        !Object.keys(transferCourierWorkOrderData).length &&
        isDateValid
    );
  }, [
    isDateValid,
    prevTransferCourierWorkOrderData,
    transferCourierFilter,
    transferCourierWorkOrderData,
  ]);

  useToastMessage(undefined, transferCourierWorkOrderError, {
    error: {
      summary: t(
        'An error occured while loading work order data for the selected transfer courier.'
      ),
    },
  });

  const transferCourierWorkOrderId = transferCourierWorkOrderData?.id;
  const hasTransferCourierWorkOrder = transferCourierWorkOrderId !== undefined;

  const courierWorkOrderId = courierWorkOrderData?.id;
  const hasCourierWorkOrder = courierWorkOrderId !== undefined;

  const availableHubsCollectionRequest = useAxiosHook<AvailableHubsCollection>(
    '/hubs/available',
    {
      skipWhen:
        !canLoadAvailableHubs ||
        (!isDestinationHubFilterShown && !destinationHubFilter),
    }
  );

  const { data: configs, isLoading: isConfigsLoading } =
    useAxiosHook<ConfigCollection>('/configs', {
      skipWhen: !canLoadSounds,
    });

  const { data: regions, isLoading: isRegionsLoading } =
    useAxiosHook<RegionCollection>('/regions', {
      skipWhen: !canLoadSounds,
    });

  function dateTemplate(date: CalendarDateTemplateParams) {
    const _date = dayjs(new Date(date.year, date.month, date.day));

    if (holidays) {
      if (
        isHoliday(
          _date,
          holidays.data.map((holiday) => holiday.date)
        ) ||
        !isSelectable(_date, isPast)
      ) {
        return (
          <span className="p-datepicker-calendar p-disabled">{date.day}</span>
        );
      }
    }
    return <strong>{date.day}</strong>;
  }

  const isRegionSoundsLoading = isConfigsLoading || isRegionsLoading;

  const regionSounds = useMemo<Record<string, string>>(() => {
    const configPlayRegionSound = findSingleConfig(
      configs,
      'Config',
      'PlayRegionSound'
    );

    const shouldPlayRegionSound =
      configPlayRegionSound && getBoolConfigValue(configPlayRegionSound);

    if (!regions || !shouldPlayRegionSound) {
      return {};
    }

    return Object.fromEntries(
      regions.data
        .filter((r) => !!r.sound)
        .map((r) => [r.id, r.sound as string])
    );
  }, [configs, regions]);

  function handleCreateWorkOrderDialogHide() {
    setIsCreateWorkOrderDialogShown(false);
  }

  function handleCreateWorkOrderDialogSuccess() {
    courierWorkOrderRequest.reload();
    handleCreateWorkOrderDialogHide();
  }

  function handleWorkOrderNotOpenDialogHide() {
    setIsWorkOrderNotOpenDialogShown(false);
  }

  const candidatesOptions = useMemo(() => getCandidateFilterOptions(t), [t]);

  useSearchQueryDateParam('date', dateFilter);

  useSearchQueryDropdownParam(
    'candidates',
    candidatesFilter,
    setCandidatesFilter,
    candidatesOptions
  );

  useSearchQueryParam('serialNumber', debouncedSerialNumberFilter);

  const rowClassName = useCallback<
    <T extends { is_candidate: Numeric | undefined }>(
      row: T
    ) => Record<string, boolean>
  >(
    (row) => ({
      'bg-green-200': row?.is_candidate == IntBool.False,
    }),
    []
  );

  return {
    dateFilter,
    setDateFilter,
    destinationHubFilter,
    setDestinationHubFilter,
    transferCourierFilter,
    setTransferCourierFilter,
    courierFilter,
    setCourierFilter,
    candidatesFilter,
    setCandidatesFilter,
    candidatesOptions,
    serialNumberFilter,
    setSerialNumberFilter,
    debouncedSerialNumberFilter,
    isCreateWorkOrderDialogShown,
    handleCreateWorkOrderDialogSuccess,
    handleCreateWorkOrderDialogHide,
    isWorkOrderNotOpenDialogShown,
    setIsWorkOrderNotOpenDialogShown,
    handleWorkOrderNotOpenDialogHide,
    isDateValid,
    transferCourierWorkOrderRequest,
    transferCourierWorkOrderId,
    hasTransferCourierWorkOrder,
    courierWorkOrderRequest,
    courierWorkOrderId,
    hasCourierWorkOrder,
    regionSounds,
    isRegionSoundsLoading,
    availableHubsCollectionRequest,
    rowClassName,
    nativeHubId,
    isLoadingHolidays,
    dateTemplate,
    ...requiredProps,
  };
}

export default useCommonState;
