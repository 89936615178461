import { emptyCell, noColumnStyle } from '../../../../utils/constants/tables';
import { formatPhoneOrMobileNumber } from '../../../../utils/helpers/phoneNumbers';
import { contextMenuModel } from '../../../../utils/helpers/primereact';

export function getColumnHeadersMap(t) {
  return {
    no: t('No.'),
    name: t('Name'),
    address: t('Address'),
    municipality: t('Place'),
    phone: t('Phone'),
    mobile: t('Mobile Phone'),
    partner_name: t('Partner'),
  };
}

export function getColumnOptions(t) {
  const columnHeadersMap = getColumnHeadersMap(t);

  return Object.keys(columnHeadersMap).map((c) => {
    return { field: c, header: c, label: columnHeadersMap[c] };
  });
}

export function additionalColumnProperties(column) {
  switch (column) {
    case 'no':
      return noColumnStyle;

    case 'name':
    case 'partner_name':
      return {
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
        },
      };

    case 'phone':
    case 'mobile':
      return {
        body: (row) =>
          typeof row[column] === 'string' && row[column].length > 2
            ? formatPhoneOrMobileNumber(row[column])
            : emptyCell,
        style: {
          width: 160,
        },
        bodyStyle: {
          width: 160,
        },
      };

    case 'municipality':
      return {
        style: {
          width: 160,
        },
        bodyStyle: {
          width: 160,
        },
      };

    case 'address':
      return {
        style: {
          width: 250,
        },
        bodyStyle: {
          width: 250,
        },
      };

    default:
      return {};
  }
}

export function generateContextMenu(
  t,
  isViewShown,
  handleView,
  isEditShown,
  handleEdit,
  isDeleteShown,
  handleDelete
) {
  const viewOption = {
    label: t('View'),
    icon: 'fas fa-search',
    command: handleView,
  };

  const editOption = {
    label: t('Edit'),
    icon: 'fas fa-edit',
    command: handleEdit,
  };

  const deleteOption = {
    label: t('Delete'),
    icon: 'fas fa-trash',
    command: handleDelete,
  };

  return contextMenuModel([
    [[isViewShown, viewOption]],
    [[isEditShown, editOption]],
    [[isDeleteShown, deleteOption]],
  ]);
}
