import {
  IconDefinition,
  faCheckCircle,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import {
  DataTableProps,
  DataTableSelectionChangeParams,
} from 'primereact/datatable';
import { Dispatch, SetStateAction } from 'react';
import { TFunction } from 'react-i18next';

import { TableProps } from '../../features/DataTable/Table/Table';
import { Numeric } from '../../types/general';
import { emptyCell } from '../constants/tables';
import { userPrefixedString } from './user';

export function getSavedSelectedColumns<T extends object>(
  key: string,
  defaultColumnOptions: T[]
): T[] {
  let returnVal: T[] = defaultColumnOptions;

  try {
    const storageVal = sessionStorage.getItem(userPrefixedString(key));

    if (storageVal) {
      const parsedStorageVal = JSON.parse(storageVal);

      if (Array.isArray(parsedStorageVal)) {
        returnVal = parsedStorageVal;
      }
    }
  } catch (e) {
    Sentry.captureException(e);
  }

  return returnVal;
}

export function dateToFilterText(date: any): string {
  const first = dayjs(date[0]).format('DD.MM.YY');

  return date[1] ? `${first} - ${dayjs(date[1]).format('DD.MM.YY')}` : first;
}

export function dateTimeToFilterText(dateFrom: Date, dateTo: Date): string {
  const first = dayjs(dateFrom).format('DD.MM.YY HH:mm:ss');

  return dateTo
    ? `${first} - ${dayjs(dateTo).format('DD.MM.YY HH:mm:ss')}`
    : first;
}

export function multipleSelectionModeContextMenuChange(
  e: DataTableSelectionChangeParams,
  selectedRows: DataTableProps['selection'],
  setSelectedRows: Dispatch<SetStateAction<DataTableProps['selection']>>,
  selectionModifier?: TableProps['selectionModifier']
) {
  const isSelectedItemAlreadySelected = (
    Array.isArray(selectedRows) ? selectedRows : []
  )?.find((sr: { id: any }) => sr.id === e.value.id);

  if (!isSelectedItemAlreadySelected) {
    setSelectedRows((prevSelected: any) => [
      ...prevSelected,
      ...(typeof selectionModifier === 'function'
        ? selectionModifier([e.value])
        : [e.value]),
    ]);
  }
}

export function dateCell(date: string | null, noTime?: boolean) {
  return date && dayjs(date).isValid()
    ? noTime
      ? dayjs(date).format('DD MMM YYYY')
      : dayjs(date).format('DD MMM YYYY, HH:mm:ss')
    : emptyCell;
}

export function booleanCell({
  t,
  value,
  trueIcon,
  trueTooltip = t('Yes'),
  falseIcon,
  falseTooltip = t('No'),
}: {
  t: TFunction;
  value: Numeric | boolean | null;
  trueIcon?: IconDefinition;
  trueTooltip?: string;
  falseIcon?: IconDefinition;
  falseTooltip?: string;
}) {
  const parsedValue: boolean =
    typeof value === 'boolean'
      ? value
      : typeof value === 'string'
      ? !!parseInt(value)
      : !!value;

  return (
    <div className="p-text-center">
      <span
        data-tip={parsedValue ? trueTooltip : falseTooltip}
        data-for="tooltip-right"
      >
        <FontAwesomeIcon
          icon={parsedValue ? trueIcon ?? faCheckCircle : falseIcon ?? faTimes}
        />
      </span>
    </div>
  );
}

export function weightCell(t: TFunction, value: Numeric): JSX.Element {
  return (
    <>
      {value} {t('kg')}
    </>
  );
}
