import './CourierCompensation.scss';

import dayjs from 'dayjs';
import _ from 'lodash';
import { TabPanel, TabView } from 'primereact/tabview';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDebounce } from 'use-lodash-debounce';

import useAxiosHook from '../../../hooks/useAxiosHook';
import useMediaQuery from '../../../hooks/useMediaQuery';
import usePageTitle from '../../../hooks/usePageTitle';
import useSearchQueryAutoCompleteInputParam from '../../../hooks/useSearchQueryAutoCompleteInputParam';
import useSearchQueryDateParam from '../../../hooks/useSearchQueryDateParam';
import useSearchQueryParam from '../../../hooks/useSearchQueryParam';
import {
  EmployeeCollection,
  EmployeeResource,
} from '../../../types/api/employees';
import { LabelValue } from '../../../types/options';
import { debounceTimeout } from '../../../utils/constants/misc';
import { queryString } from '../../../utils/helpers/http';
import {
  getSearchQueryParam,
  tryDateSearchParam,
} from '../../../utils/helpers/searchQuery';
import {
  CourierCompDocument,
  EmployeeOption,
} from './CourierCompensation.functions';
import AddressesTable from './DataTables/AddressesTable';
import CompensationDocsTable from './DataTables/CompensationDocsTable';
import { addresses, compDocs } from './mock-data';
import CompensationDocFilters from './Sidebar/CompensationDocFilters';
import Stat from './Sidebar/Stat';

function CourierCompensation() {
  const { t } = useTranslation();

  usePageTitle(t('COD Candidates'));

  const isTwoColumnLayout = useMediaQuery('(min-width: 769px)');

  const location = useLocation();

  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedCourierCompDoc, setSelectedCourierCompDoc] =
    useState<CourierCompDocument | null>(null);

  const [dateFromFilter, setDateFromFilter] = useState<Date | null>(
    () =>
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'date_from') ?? ''
      ) ?? dayjs().subtract(1, 'week').toDate()
  );

  const [dateToFilter, setDateToFilter] = useState<Date | null>(
    () =>
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'date_to') ?? ''
      ) ?? new Date()
  );

  const [serialNoFilter, setSerialNoFilter] = useState<string>(
    () => getSearchQueryParam(location.search, 'serialNo') ?? ''
  );

  const [employeeFilter, setEmployeeFilter] = useState<string>('');

  const [employeeFilterObj, setEmployeeFilterObj] =
    useState<EmployeeOption | null>(null);

  const serialNoFilterDebounce = useDebounce(serialNoFilter, debounceTimeout);
  const employeeFilterDebounce = useDebounce(employeeFilter, debounceTimeout);

  const { data: employeeData } = useAxiosHook<EmployeeCollection>(
    '/employees' +
      queryString({
        name: employeeFilterDebounce,
      }),
    { skipWhen: _.isObject(employeeFilterDebounce) }
  );

  const employeeOptions = useMemo<LabelValue<EmployeeOption>[]>(
    () =>
      employeeData?.data
        ?.filter((e) => {
          if (typeof employeeFilter === 'string') {
            return true;
          }

          return e.id !== employeeFilterObj?.id;
        })
        .map((e) => ({ label: `${e.ime} ${e.prezime}`, value: e })) ?? [],
    [employeeData?.data, employeeFilter, employeeFilterObj?.id]
  );

  useSearchQueryParam('serialNo', serialNoFilterDebounce);

  useSearchQueryDateParam('date_from', dateFromFilter);
  useSearchQueryDateParam('date_to', dateToFilter);

  useSearchQueryAutoCompleteInputParam<EmployeeOption, EmployeeResource>({
    param: 'employee',
    filterValue: employeeFilter,
    setFilterValue: setEmployeeFilter,
    filterValueObj: employeeFilterObj,
    setFilterValueObj: setEmployeeFilterObj,
    axiosRequestConfig: (employeeId) => `/employees/${employeeId}`,
    initialFilterValueDataPath: (employeeResource) =>
      `${employeeResource.data.ime} ${employeeResource.data.prezime}`,
    filterValueObjPath: 'id',
    initialDataModifier: (employeeResource) => employeeResource.data,
  });

  const compensationDocs = useMemo(() => {
    return compDocs?.data;
  }, []);

  const compensationDocsAddresses = useMemo(() => {
    return addresses?.data.filter(
      (a) => a.comp_doc_id === selectedCourierCompDoc?.id
    );
  }, [selectedCourierCompDoc]);

  return (
    <div className="courier-compensation-page">
      <h2 className="title">{t('Courier Compensation')}</h2>
      <p className="subtitle">{t('View and manage courier compensation')}</p>

      <div className="p-grid">
        <div
          {...(isTwoColumnLayout
            ? { className: 'p-col-fixed', style: { width: 360 } }
            : { className: 'p-col-12' })}
        >
          <CompensationDocFilters
            dateFromFilter={dateFromFilter}
            setDateFromFilter={setDateFromFilter}
            dateToFilter={dateToFilter}
            setDateToFilter={setDateToFilter}
            employeeFilter={employeeFilter}
            setEmployeeFilter={setEmployeeFilter}
            employeeFilterObj={employeeFilterObj}
            setEmployeeFilterObj={setEmployeeFilterObj}
            serialNoFilter={serialNoFilter}
            setSerialNoFilter={setSerialNoFilter}
            employeeOptions={employeeOptions}
          />

          <Stat
            selectedCourierCompDoc={selectedCourierCompDoc}
            data={compensationDocsAddresses}
          />
        </div>

        <div
          {...(isTwoColumnLayout
            ? {
                className: 'p-col p-pb-0',
                style: { width: 'calc(100% - 360px)' },
              }
            : { className: 'p-col-12 p-pb-0' })}
        >
          <TabView
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
            className="table-tabview"
          >
            <TabPanel header="Compensation Documents">
              <CompensationDocsTable
                data={compensationDocs}
                setSelectedCourierCompDoc={setSelectedCourierCompDoc}
              />
            </TabPanel>

            <TabPanel header="Addresses" disabled={!selectedCourierCompDoc}>
              <AddressesTable data={compensationDocsAddresses} />
            </TabPanel>
          </TabView>
        </div>
      </div>
    </div>
  );
}

export default CourierCompensation;
