export const compDocs = {
  data: [
    {
      id: '11',
      date: '2021-08-01',
      courier_name: 'Kurir 1',
      courier_id: '31',
      serial_no: 'NIN1234',
      cashier_id: '51',
      cashier_name: 'Blagajnik 1',
    },
    {
      id: '12',
      date: '2021-08-02',
      courier_name: 'Kurir 2',
      courier_id: '32',
      serial_no: 'NIN2345',
      cashier_id: '52',
      cashier_name: 'Blagajnik 2',
    },
    {
      id: '13',
      date: '2021-08-03',
      courier_name: 'Kurir 3',
      courier_id: '33',
      serial_no: 'NIN3456',
      cashier_id: '53',
      cashier_name: 'Blagajnik 3',
    },
    {
      id: '14',
      date: '2021-08-04',
      courier_name: 'Kurir 4',
      courier_id: '34',
      serial_no: 'NIN4567',
      cashier_id: '54',
      cashier_name: 'Blagajnik 4',
    },
    {
      id: '15',
      date: '2021-08-05',
      courier_name: 'Kurir 5',
      courier_id: '35',
      serial_no: 'NIN5678',
      cashier_id: '55',
      cashier_name: 'Blagajnik 5',
    },
    {
      id: '16',
      date: '2021-08-06',
      courier_name: 'Kurir 6',
      courier_id: '36',
      serial_no: 'NIN6789',
      cashier_id: '56',
      cashier_name: 'Blagajnik 6',
    },
    {
      id: '17',
      date: '2021-08-07',
      courier_name: 'Kurir 7',
      courier_id: '37',
      serial_no: 'NIN7890',
      cashier_id: '57',
      cashier_name: 'Blagajnik 7',
    },
  ],
};

export const addresses = {
  data: [
    {
      id: '123',
      comp_doc_id: '11',
      employee_create_id: '21',
      employee_create_name: 'Vraboten 1',
      employee_edit_id: '22',
      employee_edit_name: 'Vraboten 2',
      shipment_type_id: '1',
      shipment_type_name: 'Paket',
      status_id: '80',
      status_name: 'Dostavena',
      serial_no: '12341234',
      date_recive: '2021-07-01',
      date_delivery: '2021-07-04',
      recipient_name: 'Primac 1',
      sender_name: 'Isprakjac 1',
      weight: '',
      redemption: '',
      price: '100',
      address_from: 'Adresa isprakjac 1',
      address_to: 'Adresa primac 1',
      contact_from: '',
      contact_to: '',
      phone_from: '045222333',
      phone_to: '046222111',
      mobile_from: '070999888',
      mobile_to: '070888999',
      place_from: 'Mesto isprakjac 1',
      place_to: 'MEsto primac 1',
      total_shipments: 2,
    },
    {
      id: '124',
      comp_doc_id: '11',
      employee_create_id: '22',
      employee_create_name: 'Vraboten 2',
      employee_edit_id: '23',
      employee_edit_name: 'Vraboten 3',
      shipment_type_id: '1',
      shipment_type_name: 'Paket',
      status_id: '80',
      status_name: 'Dostavena',
      serial_no: '23452345',
      date_recive: '2021-07-02',
      date_delivery: '2021-07-05',
      recipient_name: 'Primac 2',
      sender_name: 'Isprakjac 2',
      weight: '1',
      redemption: '3000',
      price: '2000',
      address_from: 'Adresa isprakjac 2',
      address_to: 'Adresa primac 2',
      contact_from: '',
      contact_to: '',
      phone_from: '045222444',
      phone_to: '046222555',
      mobile_from: '070999888',
      mobile_to: '070888999',
      place_from: 'Mesto isprakjac 2',
      place_to: 'Mesto primac 2',
      total_shipments: 1,
    },
    {
      id: '125',
      comp_doc_id: '12',
      employee_create_id: '23',
      employee_create_name: 'Vraboten 3',
      employee_edit_id: '24',
      employee_edit_name: 'Vraboten 4',
      shipment_type_id: '2',
      shipment_type_name: 'Pismo',
      status_id: '80',
      status_name: 'Dostavena',
      serial_no: '34563456',
      date_recive: '2021-07-03',
      date_delivery: '2021-07-06',
      recipient_name: 'Primac 3',
      sender_name: 'Isprakjac 3',
      weight: '0',
      redemption: '0',
      price: '100',
      address_from: 'Adresa isprakjac 3',
      address_to: 'Adresa primac 3',
      contact_from: '',
      contact_to: '',
      phone_from: '045222444',
      phone_to: '046222555',
      mobile_from: '070999888',
      mobile_to: '070888999',
      place_from: 'Mesto isprakjac 3',
      place_to: 'Mesto primac 3',
      total_shipments: 3,
    },
    {
      id: '126',
      comp_doc_id: '12',
      employee_create_id: '24',
      employee_create_name: 'Vraboten 4',
      employee_edit_id: '25',
      employee_edit_name: 'Vraboten 5',
      shipment_type_id: '2',
      shipment_type_name: 'Pismo',
      status_id: '80',
      status_name: 'Dostavena',
      serial_no: '45674567',
      date_recive: '2021-07-04',
      date_delivery: '2021-07-07',
      recipient_name: 'Primac 4',
      sender_name: 'Isprakjac 4',
      weight: '0',
      redemption: '0',
      price: '400',
      address_from: 'Adresa isprakjac 4',
      address_to: 'Adresa primac 4',
      contact_from: '',
      contact_to: '',
      phone_from: '025674563',
      phone_to: '023453456',
      mobile_from: '070111222',
      mobile_to: '070222333',
      place_from: 'Mesto isprakjac 4',
      place_to: 'Mesto primac 4',
      total_shipments: 4,
    },
    {
      id: '127',
      comp_doc_id: '13',
      employee_create_id: '25',
      employee_create_name: 'Vraboten 5',
      employee_edit_id: '26',
      employee_edit_name: 'Vraboten 6',
      shipment_type_id: '1',
      shipment_type_name: 'Paket',
      status_id: '80',
      status_name: 'Dostavena',
      serial_no: '56785678',
      date_recive: '2021-07-05',
      date_delivery: '2021-07-08',
      recipient_name: 'Primac 5',
      sender_name: 'Isprakjac 5',
      weight: '0',
      redemption: '0',
      price: '500',
      address_from: 'Adresa isprakjac 5',
      address_to: 'Adresa primac 5',
      contact_from: '',
      contact_to: '',
      phone_from: '025674563',
      phone_to: '023453456',
      mobile_from: '070111222',
      mobile_to: '070222333',
      place_from: 'Mesto isprakjac 5',
      place_to: 'Mesto primac 5',
      total_shipments: 5,
    },
    {
      id: '128',
      comp_doc_id: '13',
      employee_create_id: '26',
      employee_create_name: 'Vraboten 6',
      employee_edit_id: '27',
      employee_edit_name: 'Vraboten 7',
      shipment_type_id: '1',
      shipment_type_name: 'Paket',
      status_id: '80',
      status_name: 'Dostavena',
      serial_no: '67896789',
      date_recive: '2021-07-06',
      date_delivery: '2021-07-09',
      recipient_name: 'Primac 6',
      sender_name: 'Isprakjac 6',
      weight: '0',
      redemption: '0',
      price: '100',
      address_from: 'Adresa isprakjac 6',
      address_to: 'Adresa primac 6',
      contact_from: '',
      contact_to: '',
      phone_from: '025674563',
      phone_to: '023453456',
      mobile_from: '070111222',
      mobile_to: '070222333',
      place_from: 'Mesto isprakjac 6',
      place_to: 'Mesto primac 6',
      total_shipments: 1,
    },
    {
      id: '129',
      comp_doc_id: '14',
      employee_create_id: '26',
      employee_create_name: 'Vraboten 6',
      employee_edit_id: '27',
      employee_edit_name: 'Vraboten 7',
      shipment_type_id: '1',
      shipment_type_name: 'Paket',
      status_id: '80',
      status_name: 'Dostavena',
      serial_no: '78907890',
      date_recive: '2021-07-07',
      date_delivery: '2021-07-10',
      recipient_name: 'Primac 7',
      sender_name: 'Isprakjac 7',
      weight: '0',
      redemption: '0',
      price: '100',
      address_from: 'Adresa isprakjac 7',
      address_to: 'Adresa primac 7',
      contact_from: '',
      contact_to: '',
      phone_from: '025674563',
      phone_to: '023453456',
      mobile_from: '070111222',
      mobile_to: '070222333',
      place_from: 'Mesto isprakjac 7',
      place_to: 'Mesto primac 7',
      total_shipments: 1,
    },
    {
      id: '130',
      comp_doc_id: '14',
      employee_create_id: '27',
      employee_create_name: 'Vraboten 7',
      employee_edit_id: '28',
      employee_edit_name: 'Vraboten 8',
      shipment_type_id: '1',
      shipment_type_name: 'Paket',
      status_id: '80',
      status_name: 'Dostavena',
      serial_no: '89018901',
      date_recive: '2021-07-08',
      date_delivery: '2021-07-11',
      recipient_name: 'Primac 8',
      sender_name: 'Isprakjac 8',
      weight: '0',
      redemption: '0',
      price: '100',
      address_from: 'Adresa isprakjac 8',
      address_to: 'Adresa primac 8',
      contact_from: '',
      contact_to: '',
      phone_from: '025674563',
      phone_to: '023453456',
      mobile_from: '070111222',
      mobile_to: '070222333',
      place_from: 'Mesto isprakjac 8',
      place_to: 'Mesto primac 8',
      total_shipments: 2,
    },
    {
      id: '131',
      comp_doc_id: '15',
      employee_create_id: '29',
      employee_create_name: 'Vraboten 9',
      employee_edit_id: '30',
      employee_edit_name: 'Vraboten 10',
      shipment_type_id: '1',
      shipment_type_name: 'Paket',
      status_id: '80',
      status_name: 'Dostavena',
      serial_no: '90129012',
      date_recive: '2021-07-09',
      date_delivery: '2021-07-11',
      recipient_name: 'Primac 9',
      sender_name: 'Isprakjac 9',
      weight: '0',
      redemption: '0',
      price: '100',
      address_from: 'Adresa isprakjac 9',
      address_to: 'Adresa primac 9',
      contact_from: '',
      contact_to: '',
      phone_from: '025674563',
      phone_to: '023453456',
      mobile_from: '070111222',
      mobile_to: '070222333',
      place_from: 'Mesto isprakjac 9',
      place_to: 'Mesto primac 9',
      total_shipments: 1,
    },
    {
      id: '132',
      comp_doc_id: '15',
      employee_create_id: '30',
      employee_create_name: 'Vraboten 10',
      employee_edit_id: '31',
      employee_edit_name: 'Vraboten 11',
      shipment_type_id: '1',
      shipment_type_name: 'Paket',
      status_id: '80',
      status_name: 'Dostavena',
      serial_no: '12342345',
      date_recive: '2021-07-09',
      date_delivery: '2021-07-11',
      recipient_name: 'Primac 10',
      sender_name: 'Isprakjac 10',
      weight: '0',
      redemption: '0',
      price: '100',
      address_from: 'Adresa isprakjac 10',
      address_to: 'Adresa primac 10',
      contact_from: '',
      contact_to: '',
      phone_from: '025674563',
      phone_to: '023453456',
      mobile_from: '070111222',
      mobile_to: '070222333',
      place_from: 'Mesto isprakjac 10',
      place_to: 'Mesto primac 10',
      total_shipments: 1,
    },
    {
      id: '132',
      comp_doc_id: '16',
      employee_create_id: '31',
      employee_create_name: 'Vraboten 11',
      employee_edit_id: '32',
      employee_edit_name: 'Vraboten 12',
      shipment_type_id: '1',
      shipment_type_name: 'Paket',
      status_id: '80',
      status_name: 'Dostavena',
      serial_no: '23453456',
      date_recive: '2021-07-09',
      date_delivery: '2021-07-10',
      recipient_name: 'Primac 11',
      sender_name: 'Isprakjac 11',
      weight: '0',
      redemption: '0',
      price: '100',
      address_from: 'Adresa isprakjac 11',
      address_to: 'Adresa primac 11',
      contact_from: '',
      contact_to: '',
      phone_from: '025674563',
      phone_to: '023453456',
      mobile_from: '070111222',
      mobile_to: '070222333',
      place_from: 'Mesto isprakjac 11',
      place_to: 'Mesto primac 11',
      total_shipments: 1,
    },
    {
      id: '132',
      comp_doc_id: '16',
      employee_create_id: '30',
      employee_create_name: 'Vraboten 10',
      employee_edit_id: '31',
      employee_edit_name: 'Vraboten 11',
      shipment_type_id: '1',
      shipment_type_name: 'Paket',
      status_id: '80',
      status_name: 'Dostavena',
      serial_no: '34564567',
      date_recive: '2021-07-09',
      date_delivery: '2021-07-11',
      recipient_name: 'Primac 10',
      sender_name: 'Isprakjac 10',
      weight: '0',
      redemption: '0',
      price: '100',
      address_from: 'Adresa isprakjac 12',
      address_to: 'Adresa primac 12',
      contact_from: '',
      contact_to: '',
      phone_from: '025674563',
      phone_to: '023453456',
      mobile_from: '070111222',
      mobile_to: '070222333',
      place_from: 'Mesto isprakjac 12',
      place_to: 'Mesto primac 12',
      total_shipments: 1,
    },
    {
      id: '132',
      comp_doc_id: '17',
      employee_create_id: '32',
      employee_create_name: 'Vraboten 12',
      employee_edit_id: '33',
      employee_edit_name: 'Vraboten 13',
      shipment_type_id: '1',
      shipment_type_name: 'Paket',
      status_id: '80',
      status_name: 'Dostavena',
      serial_no: '45675678',
      date_recive: '2021-07-09',
      date_delivery: '2021-07-11',
      recipient_name: 'Primac 13',
      sender_name: 'Isprakjac 13',
      weight: '0',
      redemption: '0',
      price: '100',
      address_from: 'Adresa isprakjac 13',
      address_to: 'Adresa primac 13',
      contact_from: '',
      contact_to: '',
      phone_from: '025674563',
      phone_to: '023453456',
      mobile_from: '070111222',
      mobile_to: '070222333',
      place_from: 'Mesto isprakjac 13',
      place_to: 'Mesto primac 13',
      total_shipments: 1,
    },
    {
      id: '132',
      comp_doc_id: '17',
      employee_create_id: '33',
      employee_create_name: 'Vraboten 13',
      employee_edit_id: '34',
      employee_edit_name: 'Vraboten 14',
      shipment_type_id: '1',
      shipment_type_name: 'Paket',
      status_id: '80',
      status_name: 'Dostavena',
      serial_no: '56786789',
      date_recive: '2021-07-09',
      date_delivery: '2021-07-11',
      recipient_name: 'Primac 14',
      sender_name: 'Isprakjac 14',
      weight: '0',
      redemption: '0',
      price: '100',
      address_from: 'Adresa isprakjac 14',
      address_to: 'Adresa primac 14',
      contact_from: '',
      contact_to: '',
      phone_from: '025674563',
      phone_to: '023453456',
      mobile_from: '070111222',
      mobile_to: '070222333',
      place_from: 'Mesto isprakjac 14',
      place_to: 'Mesto primac 14',
      total_shipments: 2,
    },
  ],
};
