import _ from 'lodash';
import { TFunction } from 'react-i18next';
import * as Yup from 'yup';

import { Product } from '../../../../../../enums/product';
import { EmployeeCollection } from '../../../../../../types/api/employees';
import { Unpacked } from '../../../../../../types/util';
import { Courier } from '../../../Regions.functions';

type ProductType = {
  product_name: string;
  product_id: 1 | 2 | 3 | 5;
};

type ShipmentType = {
  shipment_name: string;
  shipment_type_id: 1 | 2;
};

type Assignment = ProductType & ShipmentType;

type AssignmentForm = Pick<ShipmentType, 'shipment_type_id'> &
  Pick<ProductType, 'product_id'> & {
    isChecked: boolean;
  };

export type CourierOption = Pick<
  Unpacked<EmployeeCollection>,
  'id' | 'ime' | 'prezime'
>;

export type FormValues = {
  courier: CourierOption | string;
  assignments: AssignmentForm[];
};

type ApiData = {
  assignments: (Pick<ShipmentType, 'shipment_type_id'> &
    Pick<ProductType, 'product_id'>)[];
  courier_id: number;
};

const productTypes: ProductType[] = [
  { product_name: 'Package', product_id: Product.Package },
  { product_name: 'Letter', product_id: Product.Letter },
  { product_name: 'Personal_File', product_id: Product.PersonalDelivery },
  { product_name: 'Oversize_Package', product_id: Product.OversizePackage },
];

const shipmentTypes: ShipmentType[] = [
  { shipment_name: 'Pickup', shipment_type_id: 1 },
  { shipment_name: 'Deliver', shipment_type_id: 2 },
];

export function assignments(): Assignment[] {
  let assignments: Assignment[] = [];

  for (let product of productTypes) {
    for (let shipment of shipmentTypes) {
      assignments.push({
        product_id: product.product_id,
        product_name: product.product_name,
        shipment_name: shipment.shipment_name,
        shipment_type_id: shipment.shipment_type_id,
      });
    }
  }

  return assignments;
}

function generateAssignments(
  courier: Courier | undefined,
  isEditDialog: boolean
): AssignmentForm[] {
  let assignments: AssignmentForm[] = [];

  for (let product of productTypes) {
    for (let shipment of shipmentTypes) {
      assignments.push({
        isChecked: false,
        shipment_type_id: shipment.shipment_type_id,
        product_id: product.product_id,
      });
    }
  }

  if (
    isEditDialog &&
    courier?.assignments?.length &&
    courier?.assignments?.length > 0
  ) {
    for (let assignment of courier.assignments) {
      let c = assignments.find(
        (a) =>
          a.product_id === assignment.product_id &&
          a.shipment_type_id === assignment.shipment_type_id
      );

      if (c) {
        c.isChecked = true;
      }
    }
  }

  return assignments;
}

export function getInitialValues(
  courier: Courier | undefined,
  isEditDialog: boolean
): FormValues {
  return {
    courier:
      isEditDialog && courier
        ? {
            id: courier.id,
            ime: courier.first_name,
            prezime: courier.last_name,
          }
        : '',
    assignments: generateAssignments(courier, isEditDialog),
  };
}

export function toApiData(values: FormValues): ApiData {
  return {
    courier_id: (values!.courier as CourierOption).id,
    assignments: values.assignments
      .filter((a) => a.isChecked)
      .map((a) => _.pick(a, 'product_id', 'shipment_type_id')),
  };
}

export function getValidationSchema(t: TFunction) {
  return Yup.object().shape({
    courier: Yup.object().required(t('A courier must be selected')),
    assignments: Yup.array<AssignmentForm>()
      .compact((v) => v?.isChecked === false)
      .required(t('At least one assignment must be selected')),
  });
}
