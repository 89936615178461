import {
  addEmployeeHubResourceGuard,
  addEmployeeResourceGuard,
  addEmpoloyeeVehicleResourceGuard,
  employeeCollectionGuard,
  employeeResourceGuard,
  updateEmployeeResourceGuard,
  updateEmployeeRoleResourceGuard,
  updateEmpoloyeeVehicleResourceGuard,
} from './_api/employees';
import { hubCollectionGuard } from './_api/hubs';
import {
  municipalityCollectionGuard,
  placeCollectionGuard,
} from './_api/municipalities';
import {
  oAuthClientAvaiableCollectionGuard,
  registerOAuthClientResourceGuard,
} from './_api/oAuthClients';
import { placeResourceGuard } from './_api/places';
import { employeeRoleCollectionGuard } from './_api/roles';
import { vehicleCollectionGuard } from './_api/vehicles';

export const qrCode = [
  registerOAuthClientResourceGuard,
  oAuthClientAvaiableCollectionGuard,
];

export const employeeRoleFilter = employeeRoleCollectionGuard;
export const hubFilter = hubCollectionGuard;

export const updateStatus = updateEmployeeResourceGuard;

const addEditCommon = [
  addEmployeeHubResourceGuard,
  municipalityCollectionGuard,
  placeResourceGuard,
  placeCollectionGuard,
  hubCollectionGuard,
  employeeRoleCollectionGuard,
  vehicleCollectionGuard,
];

export const readEmployee = [
  employeeResourceGuard,
  placeResourceGuard,
  municipalityCollectionGuard,
];

export const addEmployee = [
  ...addEditCommon,
  addEmployeeResourceGuard,
  addEmpoloyeeVehicleResourceGuard,
];

export const editEmployee = [
  ...addEditCommon,
  employeeResourceGuard,
  updateEmployeeResourceGuard,
  updateEmployeeRoleResourceGuard,
  addEmployeeHubResourceGuard,
  updateEmpoloyeeVehicleResourceGuard,
];

export const routeGuard = employeeCollectionGuard;
