import _ from 'lodash';
import { TFunction } from 'react-i18next';
import * as Yup from 'yup';

import { EntityPropertyCollection } from '../../../../../types/api/entityproperties';
import { OrderImportRequestPayload } from '../../../../../types/api/orders';
import { Unpacked } from '../../../../../types/util';
import { isStreetFreeInputAllowed } from '../../../../../utils/constants/misc';
import {
  conditionallyRequiredField,
  yupRequiredField,
} from '../../../../../utils/helpers/yup';
import { FormValues } from './WebImportDialog.types';

const csvDelimiter = '\t';

const defaultValues: FormValues = {
  _fields: [],
  _clientFilter: '',
  _clientFilterObj: null,
  _clientListType: null,
  _listType: null,
  _municipality: '',
  place: '',
  street: '',
  adresa: '',
  broj: '',
  vlez: '',
  stan: '',
  import_all_or_none: true,
  osiguruvanje: '',
  otkup: '',
  postarina: '',
  povraten_dokument: '',
};

export function getDefaultValues(initialValues: Partial<FormValues>) {
  return { ...defaultValues, ...initialValues };
}

export function toApiData(values: FormValues): OrderImportRequestPayload {
  return {
    ..._.omit(values, [
      '_clientFilter',
      '_clientFilterObj',
      '_clientListType',
      '_listType',
      '_municipality',
      '_fields',
      'street',
      'adresa',
    ]),
    ...(isStreetFreeInputAllowed
      ? { adresa: values.adresa }
      : { street: values.street }),
    listtype: values._listType!.name,
    user_id: values._clientFilterObj!.korisnik_id,
    csv: csvFromFields(values._fields),
    delimiter: csvDelimiter,
  };
}

function csvFromFields(fields: FormValues['_fields']): string {
  const header = fields[0].fields.map((f) => f.name).join(csvDelimiter);
  const content = fields.map((fArr) =>
    fArr.fields.map((f) => f.value).join(csvDelimiter)
  );

  return header + '\n' + content.join('\n');
}

function generateLeftOutRequiredFields(
  parcels: FormValues['_fields'],
  klient_isSender: string
): string {
  const allPropNames = parcels?.[0]?.fields.map((f) => f.property_name) ?? [];

  const leftOutRequiredFieldNamesPerParcel = parcels.map((parcel) => {
    const filledOutPropNames = parcel.fields
      .filter((f) => !!f.value)
      .map((f) => f.property_name);

    const leftOutRequired = parcel.fields
      .filter((f) => !f.value && f.isRequired)
      .map((f) => f.name);

    return [
      ...leftOutRequired,
      ...leftOutMandatoryFieldNames(
        allPropNames,
        filledOutPropNames,
        klient_isSender
      ),
    ];
  });

  return _.uniq(_.flatten(leftOutRequiredFieldNamesPerParcel)).join(', ');
}

// Fields that MUST be present in the list
function leftOutMandatoryFieldNames(
  allPropNames: string[],
  filledOutPropNames: string[],
  klient_isSender: string
): string[] {
  // 3 possible values, "0", "1" and "2"
  //  When "0" (orderer), both sender and recepient fields are required
  const includeSenderFields = klient_isSender !== '1';
  const includeRecepientFields = klient_isSender !== '2';

  let leftOutFields = [];

  if (includeRecepientFields) {
    if (
      !filledOutPropNames.includes('telefon_do') &&
      !filledOutPropNames.includes('mobilen_do') &&
      _.difference(['telefon_do', 'mobilen_do'], allPropNames).length < 2
    ) {
      leftOutFields.push('Телефон на примач');
    }

    if (
      !filledOutPropNames.includes('mesto_do_id') &&
      !filledOutPropNames.includes('mesto_do_ime') &&
      _.difference(['mesto_do_id', 'mesto_do_ime'], allPropNames).length < 2
    ) {
      leftOutFields.push('Место на примач');
    }

    if (
      !filledOutPropNames.includes('adresa_do') &&
      allPropNames.includes('adresa_do')
    ) {
      leftOutFields.push('Адреса на примач');
    }
  }

  if (includeSenderFields) {
    if (
      !filledOutPropNames.includes('telefon_od') &&
      !filledOutPropNames.includes('mobilen_od') &&
      _.difference(['telefon_od', 'mobilen_od'], allPropNames).length < 2
    ) {
      leftOutFields.push('Телефон на испраќач');
    }

    if (
      !filledOutPropNames.includes('mesto_od_id') &&
      !filledOutPropNames.includes('mesto_od_ime') &&
      _.difference(['mesto_od_id', 'mesto_od_ime'], allPropNames).length < 2
    ) {
      leftOutFields.push('Место на испраќач');
    }

    if (
      !filledOutPropNames.includes('adresa_od') &&
      allPropNames.includes('adresa_od')
    ) {
      leftOutFields.push('Адреса на испраќач');
    }
  }

  return leftOutFields;
}

export function getValidationSchema(t: TFunction) {
  return Yup.object().shape({
    // Step 1
    _clientFilterObj: Yup.mixed().required(yupRequiredField(t, t('Client'))),
    _clientListType: Yup.mixed().required(
      yupRequiredField(t, t('Batch Order Template'))
    ),
    // Step 2
    _municipality: Yup.string()
      .nullable()
      .when(
        '_listType',
        (_listType: FormValues['_listType'], schema: Yup.SchemaOf<any>) => {
          if (_listType?.klient_isSender === 1) {
            return schema.required(yupRequiredField(t, t('Municipality')));
          }
        }
      ),
    place: Yup.string()
      .nullable()
      .when(
        '_listType',
        (_listType: FormValues['_listType'], schema: Yup.SchemaOf<any>) => {
          if (_listType?.klient_isSender === 1) {
            return schema.required(yupRequiredField(t, t('Place')));
          }
        }
      ),
    street: Yup.string()
      .nullable()
      .when(
        '_listType',
        (_listType: FormValues['_listType'], schema: Yup.SchemaOf<any>) => {
          if (_listType?.klient_isSender === 1 && !isStreetFreeInputAllowed) {
            return schema.required(yupRequiredField(t, t('Street')));
          }
        }
      ),
    adresa: conditionallyRequiredField(
      Yup.string(),
      isStreetFreeInputAllowed,
      yupRequiredField(t, t('Street'))
    ),
    broj: Yup.string()
      .nullable()
      .when(
        '_listType',
        (_listType: FormValues['_listType'], schema: Yup.SchemaOf<any>) => {
          if (_listType?.klient_isSender === 1) {
            return schema.required(yupRequiredField(t, t('Street No.')));
          }
        }
      ),
    vlez: Yup.string(),
    stan: Yup.string(),
    // Step 3
    postarina: Yup.string()
      .nullable()
      .required(yupRequiredField(t, t('Postage'))),
    otkup: Yup.string()
      .nullable()
      .required(yupRequiredField(t, t('Redemption'))),
    povraten_dokument: Yup.string()
      .nullable()
      .required(yupRequiredField(t, t('Return document'))),
    osiguruvanje: Yup.string()
      .nullable()
      .required(yupRequiredField(t, t('Insurance'))),
    // Step 4
    _fields: Yup.array()
      .of(Yup.object())
      .test({
        test: function (_fields) {
          if (!_fields) {
            return false;
          }

          const leftOutRequiredFields = generateLeftOutRequiredFields(
            _fields as any,
            this.parent._listType?.klient_isSender ?? ''
          );

          if (leftOutRequiredFields.length > 0) {
            return this.createError({
              path: this.path,
              message: t(
                'Some parcels are missing the following field: {{mandatoryFields}}',
                {
                  nsSeparator: false,
                  mandatoryFields: leftOutRequiredFields,
                }
              ),
            });
          }

          return true;
        },
      }),
  });
}

export function generatePropertyName(
  entityProperties: EntityPropertyCollection | undefined,
  propertyId: Unpacked<EntityPropertyCollection>['id']
) {
  const propertyObj = entityProperties?.find(
    (property) => property.id === propertyId
  );

  return propertyObj?.fieldname ?? '';
}
