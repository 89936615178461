import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Fragment, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { UseAxiosReturn } from '../../../../../../hooks/useAxiosHook';
import {
  HandOverOrdersToCustomer,
  HandOverOrdersToCustomerRequestPayload,
} from '../../../../../../types/api/orders';
import { isCanvasBlank } from '../../../../../../utils/helpers/canvas';
import { formDataFromObject } from '../../../../../../utils/helpers/formData';
import { mapPick } from '../../../../../../utils/helpers/functions';
import { ClientFilterOption, Row } from '../../HandoverToCustomer.types';
import Canvas from './Canvas';

type Props = {
  visible: boolean;
  onHide: () => void;
  selectionMultiple: Row[];
  totalOrdersAvailableForClient: number;
  clientFilterObj: ClientFilterOption | null;
  request: UseAxiosReturn<HandOverOrdersToCustomer>;
};

function HandOverDialog({
  visible,
  onHide,
  selectionMultiple,
  totalOrdersAvailableForClient,
  clientFilterObj,
  request: { isLoading, reload },
}: Props): JSX.Element {
  const { t } = useTranslation();

  const canvasRef = useRef<HTMLCanvasElement>(null);

  async function onHandOverBtnClick() {
    const blob = await new Promise<Blob | undefined>((resolve) => {
      if (canvasRef.current === null || isCanvasBlank(canvasRef.current)) {
        resolve(undefined);
        return;
      }

      return canvasRef.current.toBlob(
        (result) => resolve(result ?? undefined),
        'image/png',
        90
      );
    });

    const _data: HandOverOrdersToCustomerRequestPayload = {
      ids: mapPick(selectionMultiple, 'order_id'),
      recipient_identification_number: clientFilterObj?.id ?? '',
      recipient_signature: blob,
    };

    const formData = formDataFromObject(_data);

    reload({
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      url: '/orders/customer-pickup/handover',
      method: 'POST',
      data: formData,
    });
  }

  const dialogFooter = (
    <>
      <Button
        type="button"
        label={t('Close')}
        onClick={onHide}
        className="p-button-text"
      />

      <Button
        type="button"
        label={isLoading ? t('Saving...') : t('Save')}
        disabled={isLoading}
        onClick={onHandOverBtnClick}
      />
    </>
  );

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header={t('Finalize handing over?')}
      footer={dialogFooter}
      style={{ width: 600 }}
    >
      <p className="p-mt-0">
        <span
          dangerouslySetInnerHTML={{
            __html: t(
              'You are about to hand over <b>{{ordersNum}}</b> of total <b>{{totalNum}}</b> to {{clientName}}.',
              {
                ordersNum: selectionMultiple.length,
                totalNum: totalOrdersAvailableForClient,
                clientName: clientFilterObj?.ime ?? '',
              }
            ),
          }}
        />

        <br />

        {t('Are you sure you want to continue?')}
      </p>

      <p>
        {t('Customer signature')} <i>({t('optional')})</i>
      </p>

      <Canvas canvasRef={canvasRef} />

      <p className="p-mb-0">
        <b>{t('Note')}:</b>{' '}
        <span
          dangerouslySetInnerHTML={{
            __html: t(
              'this signature is optional. If the customer signs, the same will be included in the hand-over document.<br />Otherwise, the document will have to be signed physically.'
            ),
          }}
        />
      </p>
    </Dialog>
  );
}

export default HandOverDialog;
