import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import usePageTitle from '../../../../hooks/usePageTitle';
import useTableColumns from '../../../../hooks/useTableColumns';
import useTableState from '../../../../hooks/useTableState';
import Table from '../../../DataTable/Table/Table';
import Flex from '../../../layout/flex/Flex';
import MainContent from '../../../layout/flex/MainContent';
import Sidebar from '../../../layout/flex/Sidebar';
import Filters from '../../Components/Filters/Filters';
import HeaderPages from '../../Components/HeaderPages/HeaderPages';
import {
  additionalListsColumnProperties,
  getColumnHeadersMap,
  mockData,
  tableStorageKey,
} from './CustomerEarnings.functions';
import useTableFilters from './useTableFilters';

function CustomerEarnings() {
  const { t } = useTranslation();

  usePageTitle(t('Customer Earnings'));

  const listsColumnHeadersMap = useMemo(() => getColumnHeadersMap(t), [t]);

  const {
    tableRef,
    page,
    setPage,
    limit,
    setLimit,
    sortField,
    sortOrder,
    selection,
    setSortField,
    setSortOrder,
    setSelection,
  } = useTableState(tableStorageKey);

  const { selectedColumns, setSelectedColumns, columnOptions, columns } =
    useTableColumns(
      page,
      limit,
      'reports_customerOrders',
      listsColumnHeadersMap,
      listsColumnHeadersMap,
      (column) => additionalListsColumnProperties(t, column)
    );

  const { headerFiltersForm, headerFiltersCount, resetAllFilters } =
    useTableFilters(page, setPage!, limit);

  return (
    <div className="customer-orders">
      <HeaderPages>
        <div>
          <h2 className="title">{t('Customer Earnings')}</h2>
          <p className="subtitle">{t('View customer earnings')}</p>
        </div>
      </HeaderPages>

      <Flex direction="column">
        <Sidebar>
          <Filters
            filters={headerFiltersForm}
            resetAllFilters={resetAllFilters}
          />
        </Sidebar>
        <MainContent>
          <Table
            tableRef={tableRef}
            columns={columns}
            data={mockData}
            hasError={false}
            isLoading={false}
            reload={() => {}}
            headerTitle=""
            headerFiltersCount={headerFiltersCount}
            onHeaderFiltersResetAllBtnClick={resetAllFilters}
            setPage={setPage}
            setLimit={setLimit}
            sortField={sortField}
            rows={limit}
            setSortField={setSortField}
            setSortOrder={setSortOrder}
            setSelection={setSelection}
            sortOrder={sortOrder}
            selection={selection}
            storageString={tableStorageKey}
            rebuildTooltip
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            columnOptions={columnOptions}
          />
        </MainContent>
      </Flex>
    </div>
  );
}

export default CustomerEarnings;
