import classNames from 'classnames';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { InputText } from 'primereact/inputtext';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDebounce } from 'use-lodash-debounce';

import useHaveValuesChanged from '../../../hooks/useHaveValuesChanged';
import useSearchQueryParam from '../../../hooks/useSearchQueryParam';
import { debounceTimeout } from '../../../utils/constants/misc';
import { httpQueryObject } from '../../../utils/helpers/misc';
import { getSearchQueryParam } from '../../../utils/helpers/searchQuery';
import { TableProps } from '../../DataTable/Table/Table';
import FiltersCounter from '../Components/Filters/FiltersCounter';

function useTableFilters(
  page: number,
  setPage: TableProps['setPage'],
  limit: number
) {
  const { t } = useTranslation();
  const location = useLocation();
  const [name, setName] = useState<string>(
    () => getSearchQueryParam(location.search, 'client_name') ?? ''
  );
  const [clientId, setClientId] = useState<string>(
    () => getSearchQueryParam(location.search, 'client_id') ?? ''
  );
  const [clientSecret, setClientSecret] = useState<string>(
    () => getSearchQueryParam(location.search, 'client_secret') ?? ''
  );

  const nameDebounce = useDebounce(name, debounceTimeout);
  const clientIdDebounce = useDebounce(clientId, debounceTimeout);
  const clientSecretDebounce = useDebounce(clientSecret, debounceTimeout);

  useSearchQueryParam('client_name', nameDebounce);
  useSearchQueryParam('client_id', clientIdDebounce);
  useSearchQueryParam('client_secret', clientSecretDebounce);

  const filtersArr = useMemo(
    () => [clientIdDebounce, clientSecretDebounce, nameDebounce],
    [clientIdDebounce, clientSecretDebounce, nameDebounce]
  );

  useEffect(() => {
    if (setPage === undefined) {
      return;
    }
    setPage(1);
  }, [filtersArr, setPage]);

  const haveFiltersChanged = useHaveValuesChanged(filtersArr);

  const httpFiltersObj = useMemo(
    () =>
      httpQueryObject({
        client_name: nameDebounce,
        client_id: clientIdDebounce,
        client_secret: clientSecretDebounce,
        page: haveFiltersChanged ? 1 : page,
        limit,
      }),
    [
      clientIdDebounce,
      clientSecretDebounce,
      haveFiltersChanged,
      limit,
      nameDebounce,
      page,
    ]
  );

  const basicFiltersActiveFilterCount = Object.values({
    name,
    clientId,
    clientSecret,
  }).filter(Boolean).length;

  const filters = useMemo<JSX.Element>(
    () => (
      <Accordion multiple activeIndex={[0]}>
        <AccordionTab
          disabled
          headerTemplate={
            <FiltersCounter
              description={t('Basic filters')}
              counter={basicFiltersActiveFilterCount}
            />
          }
        >
          <div className="sidebar_filter">
            <label htmlFor="name">{t('Name')}</label>
            <InputText
              id="name"
              value={name}
              onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                setName(e.target.value);
              }}
              className={classNames({
                sidebar_filter_active: name,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="client_id">{t('Client ID')}</label>
            <InputText
              id="client_id"
              value={clientId}
              onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                setClientId(e.target.value);
              }}
              className={classNames({
                sidebar_filter_active: clientId,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="client_secret">{t('Client secret')}</label>
            <InputText
              id="client_secret"
              value={clientSecret}
              onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                setClientSecret(e.target.value);
              }}
              className={classNames({
                sidebar_filter_active: clientSecret,
              })}
            />
          </div>
        </AccordionTab>
      </Accordion>
    ),
    [clientId, clientSecret, name, basicFiltersActiveFilterCount, t]
  );
  const headerFiltersCount = useMemo(
    () => basicFiltersActiveFilterCount,
    [basicFiltersActiveFilterCount]
  );

  function resetAllFilters() {
    setName('');
    setClientId('');
    setClientSecret('');
  }

  return {
    filters,
    resetAllFilters,
    httpFiltersObj,
    headerFiltersCount,
  };
}

export default useTableFilters;
