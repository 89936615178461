import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AvailableCouriersCollection } from '../../../../../../../types/api/couriers';

type Props = {
  visible: boolean;
  onHide: () => void;
  couriers: AvailableCouriersCollection | undefined;
  courierFilter: string | null;
};

function WorkOrderNotCreatedDialog({
  visible,
  onHide,
  couriers,
  courierFilter,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const selectedCourier = useMemo(
    () => couriers?.find((c) => String(c.id) === courierFilter),
    [courierFilter, couriers]
  );

  const header = (
    <span className="p-error">{t('Work Order not yet created')}</span>
  );

  const footer = (
    <Button
      type="button"
      label={t('Close')}
      onClick={onHide}
      className="p-button-danger p-button-outlined"
    />
  );

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header={header}
      footer={footer}
      resizable={false}
    >
      <span
        dangerouslySetInnerHTML={{
          __html: t(
            'No work order has been created for courier {{name}} yet.',
            {
              name: selectedCourier?.full_name ?? '',
            }
          ),
        }}
      />
    </Dialog>
  );
}

export default WorkOrderNotCreatedDialog;
