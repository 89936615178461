import {
  faExclamationCircle,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Dropdown } from 'primereact/dropdown';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { UseAxiosReturn } from '../../../hooks/useAxiosHook';
import useSearchQueryDropdownParam from '../../../hooks/useSearchQueryDropdownParam';
import { CouriersLocationCollection } from '../../../types/api/tracking';
import { getMapTileLayerProviderOptions } from '../../../utils/helpers/map';
import {
  getCourierDropdownTemplate,
  getCourierStatusConfig,
} from './CourierMap.functions';
import styles from './CourierMap.module.scss';

type Props = {
  focusedCourierId: string | null;
  setFocusedCourierId: Dispatch<SetStateAction<string | null>>;
  mapTileLayerProvider: string;
  setMapTileLayerProvider: Dispatch<SetStateAction<string>>;
  courierStatusConfig: ReturnType<typeof getCourierStatusConfig>;
  request: UseAxiosReturn<CouriersLocationCollection>;
};

function Filters({
  focusedCourierId,
  setFocusedCourierId,
  mapTileLayerProvider,
  setMapTileLayerProvider,
  courierStatusConfig,
  request: { data, isLoading, error },
}: Props): JSX.Element {
  const { t } = useTranslation();

  const courierOptions = useMemo(
    () =>
      data?.map((c) => ({
        label: c.courier_name,
        value: c.courier_id,
      })) ?? [],
    [data]
  );

  const mapTileLayerProviderOptions = useMemo(
    () => getMapTileLayerProviderOptions(t),
    [t]
  );

  useSearchQueryDropdownParam(
    'courier',
    focusedCourierId,
    setFocusedCourierId,
    courierOptions
  );

  useSearchQueryDropdownParam(
    'mapType',
    mapTileLayerProvider,
    setMapTileLayerProvider,
    mapTileLayerProviderOptions,
    false
  );

  return (
    <div className={classNames(styles.mapSettings, 'p-shadow-2')}>
      {data?.length === 0 && !error && (
        <div
          className={classNames(styles.apiStatusInfo, styles.noCouriersFound)}
        >
          <FontAwesomeIcon icon={faInfoCircle} />
          <span>{t('No couriers found')}</span>
        </div>
      )}

      {!!error && (
        <div className={classNames(styles.apiStatusInfo, styles.httpError)}>
          <FontAwesomeIcon icon={faExclamationCircle} />
          <span>{t('Network error')}</span>
        </div>
      )}

      <div className="p-fluid">
        <div className="p-field">
          <label htmlFor="focus_on_courier">{t('Focus on courier')}</label>

          <Dropdown
            inputId="focus_on_courier"
            value={focusedCourierId}
            itemTemplate={getCourierDropdownTemplate(data, courierStatusConfig)}
            placeholder={
              isLoading
                ? t('Loading...')
                : data?.length === 0
                ? t('No couriers found')
                : t('Select a courier')
            }
            options={courierOptions}
            filter
            showClear
            onChange={(e) => {
              setFocusedCourierId(e.target.value);
            }}
            disabled={isLoading}
          />
        </div>

        <div className="p-field">
          <label htmlFor="map_type">{t('Map type')}</label>

          <Dropdown
            inputId="map_type"
            value={mapTileLayerProvider}
            placeholder={t('Select a courier')}
            options={mapTileLayerProviderOptions}
            onChange={(e) => {
              setMapTileLayerProvider(e.value);
            }}
          />
        </div>
      </div>

      <span>{t('Legend')}</span>
      <div className={styles.mapLegend}>
        {Object.keys(courierStatusConfig).map((key) => {
          const { icon, label, duration } =
            courierStatusConfig[
              parseInt(key) as keyof typeof courierStatusConfig
            ];

          return (
            <div key={key} className={styles.mapLegendItem}>
              <img src={icon} alt={label} />
              <span className={styles.label}>{label}</span>
              <span className={styles.duration}>
                (
                {duration.length === 2 ? duration.join('-') : `${duration[0]}+`}{' '}
                {t('min')})
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Filters;
