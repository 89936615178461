import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { PriceListReferences } from '../../../../../../types/api/priceLists';
import styles from './ReferenceList.module.scss';

type Props<K extends keyof PriceListReferences> = {
  title: string;
  references: PriceListReferences[K];
};

function ReferenceList<K extends keyof PriceListReferences>({
  title,
  references,
}: Props<K>): JSX.Element | null {
  const { t } = useTranslation();

  if (!references?.length) {
    return null;
  }

  const cutReferences = references.slice(0, 3);
  const remainingReferences = references.length - cutReferences.length;

  return (
    <div className={classNames(styles.referenceList, 'p-mt-0')}>
      <b>{title}</b>

      <ul className="p-mt-2 p-mb-0">
        {cutReferences.map((reference) => (
          <li key={reference.id}>
            {reference.name ??
              reference.price_list_name ??
              reference.serial_number}
          </li>
        ))}

        {remainingReferences && remainingReferences > 0 ? (
          <li>
            <i>{t('... and {{n}} others.', { n: remainingReferences })}</i>
          </li>
        ) : null}
      </ul>
    </div>
  );
}

export default ReferenceList;
