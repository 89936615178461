import { useMemo } from 'react';
import {
  CircleMarker,
  MapContainer,
  Polygon,
  TileLayer,
  Tooltip,
} from 'react-leaflet';

import { defaultTileLayerProvider } from '../../../utils/constants/map';
import {
  getMapCoordinates,
  getMapMaxBounds,
  getMapZoom,
} from '../../../utils/helpers/map';
import {
  Region,
  RegionWithGeometry,
  RegionWithLocation,
} from './Regions.functions';

type Props = {
  regions: Region[];
  selectedRegionId: number | null;
  setSelectedRegionId: (r: any) => void;
};

function RegionsMap({
  regions,
  selectedRegionId,
  setSelectedRegionId,
}: Props): JSX.Element {
  const markers = useMemo<JSX.Element[]>(() => {
    return regions
      .filter((r): r is RegionWithLocation => !!r.location && !r.geometry)
      .map((r) => (
        <CircleMarker
          key={r.region_id}
          pathOptions={{
            color: r.region_id === selectedRegionId ? '#9c27b0' : '#f5f6fb',
            fillColor: r.region_id === selectedRegionId ? '#faf4fb' : '#3f51b5',
            fillOpacity: 1,
          }}
          center={[r.location.lat, r.location.lng]}
          radius={r.region_id === selectedRegionId ? 12 : 8}
          eventHandlers={{
            click: () => setSelectedRegionId(r.region_id),
          }}
        >
          <Tooltip>
            <div className="tooltip-region-name"> {r.name}</div>
            {r.description && (
              <div className="tooltip-region-description">
                Description: {r.description}
              </div>
            )}
          </Tooltip>
        </CircleMarker>
      ));
  }, [regions, selectedRegionId, setSelectedRegionId]);

  const polygons = useMemo<JSX.Element[]>(() => {
    return regions
      .filter(
        (r): r is RegionWithGeometry =>
          Array.isArray(r.geometry) && r.geometry.length > 2
      )
      .map((r) => (
        <Polygon
          key={r.region_id}
          pathOptions={{
            color: r.region_id === selectedRegionId ? '#9c27b0' : '#2196f3',
            fillColor: r.region_id === selectedRegionId ? '#d4a2dd' : '#2196f3',
          }}
          positions={r.geometry}
          eventHandlers={{
            click: () => setSelectedRegionId(r.region_id),
          }}
        >
          <Tooltip>{r.name}</Tooltip>
        </Polygon>
      ));
  }, [regions, selectedRegionId, setSelectedRegionId]);

  return (
    <MapContainer
      zoom={getMapZoom().defaultZoom}
      center={getMapCoordinates()}
      maxBounds={getMapMaxBounds()}
      className="map p-shadow-2"
    >
      <TileLayer url={defaultTileLayerProvider} />

      {markers}
      {polygons}
    </MapContainer>
  );
}

export default RegionsMap;
