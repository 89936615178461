import './HeaderPages.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';

import GoBackLink from '../../../Misc/GoBack/GoBackLink';

type Props = {
  title?: string;
  subtitle?: string;
  icon?: any;
  goBackTitle?: string;
  goBackTo?: string;
  children?: ReactNode;
};

const HeaderPages: React.FC<Props> = ({
  title,
  subtitle,
  icon,
  goBackTitle,
  goBackTo,
  children,
}) => {
  return (
    <div className="header-pages">
      <div>
        {goBackTo && goBackTitle && (
          <GoBackLink to={goBackTo} page={goBackTitle} />
        )}
        <div className="title-icons">
          {icon && (
            <FontAwesomeIcon className="title-icon" size="lg" icon={icon} />
          )}
          <h2 className="title">{title}</h2>
        </div>
        <p className="subtitle">{subtitle}</p>
      </div>
      <div>{children}</div>
    </div>
  );
};

export default HeaderPages;
