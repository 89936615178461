import './Logs.scss';

import { faTerminal } from '@fortawesome/free-solid-svg-icons';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useAxiosHook from '../../../hooks/useAxiosHook';
import usePageTitle from '../../../hooks/usePageTitle';
import useTableColumns from '../../../hooks/useTableColumns';
import useTableState from '../../../hooks/useTableState';
import { queryString } from '../../../utils/helpers/http';
import Table from '../../DataTable/Table/Table';
import Flex from '../../layout/flex/Flex';
import MainContent from '../../layout/flex/MainContent';
import Filters from '../Components/Filters/Filters';
import HeaderPages from '../Components/HeaderPages/HeaderPages';
import {
  additionalColumnProperties,
  getColumnHeadersMap,
  getRowColor,
  tableStorageKey,
} from './Logs.functions';
import { LogCollection, LogEntry } from './Logs.types';
import useTableFilters from './useTableFilters';

function Logs(): JSX.Element {
  const { t } = useTranslation();
  usePageTitle(t('Logs'));
  const {
    tableRef,
    page,
    setPage,
    limit,
    setLimit,
    sortField,
    sortOrder,
    selection,
    setSortField,
    setSortOrder,
    setSelection,
  } = useTableState<LogEntry>(tableStorageKey);
  const { filters, headerFiltersCount, resetAllFilters, httpFiltersObj } =
    useTableFilters(page, setPage!, limit);

  const { data, isLoading, reload, error } = useAxiosHook<LogCollection>(
    '/log/db' + queryString(httpFiltersObj)
  );

  const columnHeadersMap = useMemo(() => getColumnHeadersMap(t), [t]);

  const { selectedColumns, setSelectedColumns, columnOptions, columns } =
    useTableColumns(
      page,
      limit,
      'priceLists',
      columnHeadersMap,
      columnHeadersMap,
      (c) => additionalColumnProperties(t, c as keyof typeof columnHeadersMap)
    );

  return (
    <div className="page logs-page">
      <HeaderPages
        title={t('Logs')}
        subtitle={t('Log entries view')}
        icon={faTerminal}
      />

      <Flex direction="column">
        <Filters
          filters={filters}
          resetAllFilters={resetAllFilters}
          headerFiltersCount={headerFiltersCount}
          filterHeight={170}
        />
        <MainContent>
          <Table
            tableRef={tableRef}
            columns={columns}
            data={data}
            isLoading={isLoading}
            hasError={!!error}
            reload={reload}
            headerTitle=""
            setPage={setPage}
            setLimit={setLimit}
            sortField={sortField}
            rows={limit}
            setSortField={setSortField}
            setSortOrder={setSortOrder}
            setSelection={setSelection}
            sortOrder={sortOrder}
            selection={selection}
            storageString={tableStorageKey}
            rebuildTooltip
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            columnOptions={columnOptions}
            exportToExcelButton
            filterHeight={170}
            // !!! onExportToExcelBtnClick <- send req to Reports API?!
            rowClassName={getRowColor}
          />
        </MainContent>
      </Flex>
    </div>
  );
}

export default Logs;
