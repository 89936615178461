import dayjs from 'dayjs';
import _ from 'lodash';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { Dispatch, useMemo } from 'react';
import { useState } from 'react';
import { SetStateAction } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDebounce } from 'use-lodash-debounce';

import useAxiosHook from '../../../../hooks/useAxiosHook';
import { useEndpointGuard } from '../../../../hooks/useEndpointGuard';
import useHaveValuesChanged from '../../../../hooks/useHaveValuesChanged';
import useSearchQueryAutoCompleteInputParam from '../../../../hooks/useSearchQueryAutoCompleteInputParam';
import useSearchQueryDropdownParam from '../../../../hooks/useSearchQueryDropdownParam';
import useSearchQueryParam from '../../../../hooks/useSearchQueryParam';
import {
  ClientLookupCollection,
  ClientLookupCollectionQueryParams,
  ClientResource,
} from '../../../../types/api/clients';
import { LabelValue } from '../../../../types/options';
import * as customerOrdersGuards from '../../../../utils/constants/auth/customerOrders';
import { debounceTimeout } from '../../../../utils/constants/misc';
import { dateToFilterText } from '../../../../utils/helpers/dataTable';
import { identity } from '../../../../utils/helpers/functions';
import { queryString } from '../../../../utils/helpers/http';
import {
  dateTimeSearchParamToString,
  getSearchQueryParam,
  tryDateSearchParam,
} from '../../../../utils/helpers/searchQuery';
import TableHeaderFilters from '../../../DataTable/Table/HeaderFilters/TableHeaderFilters';
import {
  OrdinaryFilter,
  RequiredFilter,
  filterByValue,
} from '../../../DataTable/Table/HeaderFilters/TableHeaderFilters.function';
import AutoCompleteInput from '../../../Forms/AutoCompleteInput/AutoCompleteInput';
import { ClientOption } from './CustomerEarnings.functions';

function useTableFilters(
  page: number,
  setPage: Dispatch<SetStateAction<number>>,
  limit: number
) {
  const { t } = useTranslation();

  const location = useLocation();

  const [headerFiltersCount, setHeadersFilterCount] = useState<number>(0);

  const [dateFromFilter, setDateFromFilter] = useState<Date>(
    () =>
      tryDateSearchParam(getSearchQueryParam(location.search, 'date') ?? '') ??
      dayjs().subtract(1, 'month').toDate()
  );

  const [dateToFilter, setDateToFilter] = useState<Date>(
    () =>
      tryDateSearchParam(getSearchQueryParam(location.search, 'date') ?? '') ??
      dayjs().toDate()
  );

  const [paymentTypeFilter, setPaymentTypeFilter] = useState(
    () => getSearchQueryParam(location.search, 'payment_type') ?? 'all'
  );

  const [limitResult, setLimitResult] = useState<string>(
    limit.toString() ?? '30'
  );
  const debouncedLimitResultFilter = useDebounce(limitResult, debounceTimeout);

  const [clientFilter, setClientFilter] = useState<string>('');
  const [clientFilterObj, setClientFilterObj] =
    useState<ClientOption | null>(null);
  const debouncedClientFilter = useDebounce(clientFilter, debounceTimeout);

  const clientFilterGuard = useEndpointGuard(customerOrdersGuards.clientFilter);

  useEffect(() => {
    if (!clientFilterGuard) {
      setClientFilter('');
      setClientFilterObj(null);
    }
  }, [clientFilterGuard]);

  const { data: clients } = useAxiosHook<ClientLookupCollection>(
    '/clients/lookup' +
      queryString<ClientLookupCollectionQueryParams>({
        ime: debouncedClientFilter,
        page: 1,
        limit: 10,
      }),
    { skipWhen: _.isObject(debouncedClientFilter) || !clientFilterGuard }
  );

  const clientsOptions = useMemo<LabelValue<ClientOption>[]>(
    () =>
      clients?.data
        .filter((c) => {
          if (typeof clientFilter === 'string') {
            return true;
          }

          return c.id !== clientFilterObj?.id;
        })
        .map((c) => ({ label: c.ime, value: c })) ?? [],
    [clientFilter, clientFilterObj?.id, clients?.data]
  );

  const paymentTypeOptions = useMemo(() => {
    return [
      { label: t('All'), value: 'all' },
      { label: t('Invoices'), value: 'invoices' },
      { label: t('Cash'), value: 'cash' },
    ];
  }, [t]);

  useSearchQueryParam('dateFrom', dateTimeSearchParamToString(dateFromFilter));
  useSearchQueryParam('dateTo', dateTimeSearchParamToString(dateToFilter));
  useSearchQueryParam('limit', debouncedLimitResultFilter);

  useSearchQueryAutoCompleteInputParam<ClientOption, ClientResource>({
    param: 'client',
    filterValue: clientFilter,
    setFilterValue: setClientFilter,
    filterValueObj: clientFilterObj,
    setFilterValueObj: setClientFilterObj,
    axiosRequestConfig: (clientId) => `/clients/${clientId}`,
    initialFilterValueDataPath: 'ime',
    filterValueObjPath: 'id',
    initialDataModifier: identity,
  });

  useSearchQueryDropdownParam(
    'payment_type',
    paymentTypeFilter,
    setPaymentTypeFilter,
    paymentTypeOptions
  );

  const filtersArr = useMemo(
    () => [
      dateFromFilter,
      dateToFilter,
      setClientFilter,
      setClientFilterObj,
      paymentTypeFilter,
      setPaymentTypeFilter,
    ],
    [dateFromFilter, dateToFilter, paymentTypeFilter]
  );

  const haveFiltersChanged = useHaveValuesChanged(filtersArr);

  useEffect(() => {
    setPage(1);
  }, [setPage]);

  const httpFiltersObj = useMemo(
    () => ({
      page: haveFiltersChanged ? 1 : page,
      limit,
      date_from: dateTimeSearchParamToString(dateFromFilter),
      date_to: dateTimeSearchParamToString(dateToFilter),
      client_id: clientFilterObj?.id,
      payment_type: paymentTypeFilter,
    }),
    [
      clientFilterObj?.id,
      dateFromFilter,
      dateToFilter,
      haveFiltersChanged,
      limit,
      page,
      paymentTypeFilter,
    ]
  );

  const headerFiltersForm = useMemo<JSX.Element>(
    () => (
      <>
        <div className="filter">
          <label htmlFor="filter_date_from">{t('Date from')}</label>

          <Calendar
            inputId="filter_date_from"
            value={dateFromFilter}
            onChange={(e) => setDateFromFilter(e.value as any)}
            dateFormat="dd/mm/yy"
            maxDate={new Date()}
          />
        </div>

        <div className="filter">
          <label htmlFor="filter_date_to">{t('Date to')}</label>

          <Calendar
            inputId="filter_date_to"
            value={dateToFilter}
            onChange={(e) => setDateToFilter(e.value as any)}
            dateFormat="dd/mm/yy"
            maxDate={new Date()}
          />
        </div>

        {clientFilterGuard && (
          <div className="filter">
            <label htmlFor="filter_client">{t('Client')}</label>

            <AutoCompleteInput
              id="filter_client"
              filterValue={clientFilter}
              value={clientFilterObj}
              options={clientsOptions}
              onFilterChange={(e: string) => setClientFilter(e)}
              onSelectionChange={(e) => setClientFilterObj(e)}
            />
          </div>
        )}

        <div className="filter">
          <label htmlFor="filter_payment_type">{t('Payment type')}</label>
          <Dropdown
            id="filter_payment_type"
            value={paymentTypeFilter}
            options={paymentTypeOptions}
            onChange={(e) => setPaymentTypeFilter(e.value)}
          />
        </div>

        <div className="filter">
          <label htmlFor="filter_limit">{t('Limit')}</label>
          <InputNumber
            inputId="minmax-buttons"
            value={parseInt(limitResult)}
            onValueChange={(e) => setLimitResult(e.value.toString())}
            step={10}
            mode="decimal"
            showButtons
            min={0}
            max={100}
          />
        </div>
      </>
    ),
    [
      clientFilter,
      clientFilterGuard,
      clientFilterObj,
      clientsOptions,
      dateFromFilter,
      dateToFilter,
      limitResult,
      paymentTypeFilter,
      paymentTypeOptions,
      t,
    ]
  );

  const headerFilters = useMemo(() => {
    const required: RequiredFilter[] = [
      {
        label: t('Date'),
        value: dateToFilterText([dateFromFilter, dateToFilter]),
      },
    ].filter(filterByValue);

    const ordinary: OrdinaryFilter[] = [
      {
        label: t('Client'),
        value: clientFilterObj?.ime ?? '',
        onDelete: () => {
          setClientFilterObj(null);
          setClientFilter('');
        },
      },
      {
        label: t('Payment'),
        value:
          paymentTypeOptions.find((item) => item.value === paymentTypeFilter)
            ?.label ?? '',
        onDelete: () => setPaymentTypeFilter('all'),
      },
      {
        label: t('Limit'),
        value: limitResult,
        onDelete: () => setLimitResult('30'),
      },
    ].filter(filterByValue);

    setHeadersFilterCount(ordinary.length);

    return <TableHeaderFilters required={required} ordinary={ordinary} />;
  }, [
    clientFilterObj?.ime,
    dateFromFilter,
    dateToFilter,
    limitResult,
    paymentTypeFilter,
    paymentTypeOptions,
    t,
  ]);

  function resetAllFilters() {
    setDateFromFilter(new Date());
    setDateToFilter(dayjs().subtract(1, 'month').toDate());
    setClientFilter('');
    setClientFilterObj(null);
    setPaymentTypeFilter('all');
  }

  return {
    headerFiltersForm,
    headerFilters,
    resetAllFilters,
    httpFiltersObj,
    headerFiltersCount,
  };
}

export default useTableFilters;
