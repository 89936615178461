import dayjs from 'dayjs';

const dateFormat = 'DD.MM.YYYY';
const dateMonthFormat = 'MM.YYYY';
const dateTimeFormat = 'DD.MM.YYYY-HH:mm:ss';

export function getSearchQueryParam(
  searchQuery: string,
  param: string
): string | undefined {
  const searchParams = new URLSearchParams(searchQuery);
  const value = searchParams.get(param);

  return value ? decodeURI(value) : undefined;
}

export function getMultiSelectSearchQuery(searchQuery: string, param: string) {
  const value = getSearchQueryParam(searchQuery, param) ?? undefined;

  return value ? tryMultiSelectParam(value) : undefined;
}

export function getBoolSearchQueryParam(
  searchQuery: string,
  param: string
): boolean | undefined {
  const queryParam = getSearchQueryParam(searchQuery, param);

  if (!queryParam) {
    return undefined;
  }

  return queryParam === 'true';
}

export function getDateRangeSearchQueryParam(
  searchQuery: string,
  param: string,
  defaultValue: [Date, Date] = [new Date(), new Date()]
): [Date, Date] {
  const queryParam = getSearchQueryParam(searchQuery, param);

  return queryParam
    ? tryDateRangeSearchParam(queryParam) ?? defaultValue
    : defaultValue;
}

export function dateSearchParamToString(date: Date): string {
  const _date = dayjs(date);

  return _date.isValid() ? _date.format('DD.MM.YYYY') : '';
}

export function monthSearchParamToString(date: Date): string {
  const _date = dayjs(date);

  return _date.isValid() ? _date.format('MM.YYYY') : '';
}

export function dateTimeSearchParamToString(date: Date | null): string {
  return date ? dayjs(date).format(dateTimeFormat) : '';
}

export function dateRangeSearchParamToString(date: [Date, Date]): string {
  const firstDate = dayjs(date[0]).format(dateFormat);
  const secondDate = dayjs(date[1]).format(dateFormat);

  return `${firstDate}-${secondDate}`;
}

export function tryDateSearchParam(param: string): Date | undefined {
  if (!param) {
    return undefined;
  }

  const parsed = dayjs(param, dateFormat);

  return parsed.isValid() ? parsed.toDate() : undefined;
}

export function tryMonthSearchParam(param: string): Date | undefined {
  if (!param) {
    return undefined;
  }

  const parsed = dayjs(param, dateMonthFormat);

  return parsed.isValid() ? parsed.toDate() : undefined;
}

export function tryDateRangeSearchParam(
  param: string
): [Date, Date] | undefined {
  const split = param.split('-');

  if (split.length !== 2) {
    return undefined;
  }

  const firstDate = dayjs(split[0], dateFormat);
  const secondDate = dayjs(split[1], dateFormat);

  if (!firstDate.isValid() || !secondDate.isValid()) {
    return undefined;
  }

  return [firstDate.toDate(), secondDate.toDate()];
}

export function tryDateTimeSearchParam(param: string): Date | undefined {
  const date = dayjs(param, dateTimeFormat);

  return date.isValid() ? date.toDate() : undefined;
}

export function tryDateTimeRangeSearchParam(
  dateFromParam: string,
  dateToParam: string
): [Date, Date] | undefined {
  const dateFrom = dayjs(dateFromParam, dateTimeFormat);
  const dateTo = dayjs(dateToParam, dateTimeFormat);

  if (!dateFrom.isValid() || !dateTo.isValid() || dateFrom.isAfter(dateTo)) {
    return undefined;
  }

  return [dateFrom.toDate(), dateTo.toDate()];
}

export function tryMultiSelectParam(param: string): string[] | undefined {
  return param.length > 0 ? decodeURIComponent(param).split(',') : undefined;
}
