import { uniq } from 'lodash';
import { Dispatch, SetStateAction } from 'react';
import { Row, SortingRule, TableInstance } from 'react-table';

import { SortingDirection, SortingQueryParams } from '../../types/api';

export function editCellDataUpdate(setData: Dispatch<SetStateAction<any[]>>) {
  return function (rowIndex: number, columnId: string, value: string) {
    if (typeof setData !== 'function') {
      return;
    }

    setData((prevData) =>
      prevData.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...prevData[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };
}

export function getSelection<T extends object>(
  tableInstance: TableInstance<T>
): Row<T>[] {
  const contextMenuSelection =
    tableInstance.contextMenuSelection !== undefined
      ? [tableInstance.contextMenuSelection]
      : [];

  return uniq([...tableInstance.selectedFlatRows, ...contextMenuSelection]);
}

export function sortedColumnsToSortBy<T>(
  sortBy: SortingRule<T>[]
): SortingQueryParams<{}> {
  return {
    sort_by: sortBy.map((sortingRule) => sortingRule.id),
    sort_dir: sortBy.map((sortingRule) =>
      sortingRule.desc ? SortingDirection.Desc : SortingDirection.Asc
    ),
  };
}
