import dayjs from 'dayjs';
import _ from 'lodash';

import { Numeric } from '../../types/general';
import { httpDateFormat } from './formatting';
import { tryStringify } from './parse';

type QueryParams<T> = {
  [P in keyof T]: undefined extends T[P] ? T[P] | null | undefined : T[P];
};

export function queryString<
  T extends Record<
    string,
    Numeric | Array<Numeric> | Date | boolean | object | null | undefined
  >,
  U extends QueryParams<T> = QueryParams<T>
>(obj: U): string {
  let returnStr = '';

  for (const k in obj) {
    const value = obj[k];

    if (
      value === undefined ||
      value === null ||
      (typeof value === 'number' && isNaN(value)) ||
      (typeof value === 'string' && value.trim().length === 0) ||
      (value instanceof Date && !dayjs(value).isValid())
    ) {
      continue;
    }

    if (value instanceof Date) {
      const formattedDateValue = httpDateFormat(value);

      returnStr += `&${k}=${encodeURIComponent(formattedDateValue)}`;
      continue;
    }

    if (Array.isArray(value)) {
      for (const arrItem of value) {
        returnStr += `&${k}[]=${encodeURIComponent(arrItem)}`;
      }

      continue;
    }

    if (typeof value === 'object') {
      const stringified = tryStringify(value as object);

      if (stringified !== undefined) {
        returnStr += `&${k}=${stringified}`;
      }

      continue;
    }

    returnStr += `&${k}=${encodeURIComponent(value as Numeric)}`;
  }

  return returnStr.length ? `?${returnStr.slice(1)}` : '';
}

export function filtersPayloadObject(
  obj: Record<string, Numeric | boolean | Array<unknown> | null | undefined>
) {
  return _.pick(
    obj,
    Object.keys(obj).filter((key) => {
      const value = obj[key];

      if (typeof value === 'boolean') {
        return true;
      }

      if (typeof value === 'number') {
        return !isNaN(value);
      }

      if (Array.isArray(value)) {
        return value.length > 0;
      }

      return !!value;
    })
  );
}
