import './ViewDialog.scss';

import {
  faBoxes,
  faCheck,
  faClock,
  faHandshake,
  faHome,
  faIdCard,
  faMapMarkedAlt,
  faMobileAlt,
  faPhoneAlt,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Tooltip } from 'primereact/tooltip';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useMediaQuery from '../../../../../../hooks/useMediaQuery';
import GeoLocation from './GeoLocation';

function ViewDialog({ data, isShown, onHide, onEditBtnClick }) {
  const { t } = useTranslation();

  let address = (
    <>
      {data?.street_name} {data?.street_no}
      <br />
      {data?.zip} {data?.place_name}, {data?.municipality_name}
    </>
  );

  const workingHours = useMemo(() => {
    if (!data?.working_hours) {
      return '-';
    }

    const wHours = data.working_hours?.split('-');
    const workingHoursOK = wHours?.length === 2;

    const start =
      workingHoursOK && _.trim(wHours[0]) !== 'Invalid date '
        ? _.trim(wHours[0])
        : null;
    const end =
      workingHoursOK && _.trim(wHours[1]) !== ' Invalid date'
        ? _.trim(wHours[1])
        : null;

    if (_.trim(start) && _.trim(end)) {
      const startWorkingHours = start?.split(':');
      const endWorkingHours = end?.split(':');

      if (
        (startWorkingHours[0] && startWorkingHours[1]) ||
        (endWorkingHours[0] && endWorkingHours[1])
      ) {
        return `${start}-${end}`;
      } else {
        return '-';
      }
    }
  }, [data]);

  const regions = useMemo(() => {
    if (!data?.regions) {
      return;
    }

    return (
      <div>
        <span className="total-regions">
          {t('Total')}: {data.regions.length}
        </span>
        <div className="regions-info-table">
          {data.regions.map((region, i) => {
            return <span key={i}>- {region.name}</span>;
          })}
        </div>
      </div>
    );
  }, [data, t]);

  const dialogFooter = (
    <>
      <Button
        type="button"
        label={t('Edit')}
        className="p-button-text"
        onClick={() => onEditBtnClick(data.id)}
      />
      <Button type="button" label={t('Close')} onClick={() => onHide()} />
    </>
  );

  const isOnMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Dialog
      visible={isShown}
      onHide={onHide}
      header={data?.name}
      footer={dialogFooter}
      dismissableMask
      blockScroll
      resizable={false}
      maximizable={isOnMobile}
      maximized={isOnMobile}
      className="hubs-view-dialog"
    >
      <>
        <span className="section-title">{t('General information')}</span>
        <div className="quick-info-container">
          <div className="quick-info">
            <Tooltip target=".name" mouseTrack mouseTrackLeft={10} />
            <div>
              <FontAwesomeIcon
                className="name"
                data-pr-tooltip={t("Warehouse's name")}
                icon={faIdCard}
              />
              <span className="name" data-pr-tooltip={t("Warehouse's name")}>
                {data?.name}
              </span>
            </div>

            <Tooltip target=".address" mouseTrack mouseTrackLeft={10} />
            <div style={{ display: 'flex' }}>
              <FontAwesomeIcon
                icon={faHome}
                className="address"
                data-pr-tooltip={t("Warehouse's address")}
              />

              <span
                className="address"
                data-pr-tooltip={t("Warehouse's address")}
              >
                {address}
              </span>
            </div>

            <Tooltip target=".mobile" mouseTrack mouseTrackLeft={10} />
            <div>
              <FontAwesomeIcon
                icon={faMobileAlt}
                className="mobile"
                data-pr-tooltip={t("Warehouse's mobile")}
              />

              <span
                className="mobile"
                data-pr-tooltip={t("Warehouse's mobile")}
              >
                {data?.mobile || '-'}
              </span>
            </div>

            <Tooltip target=".phone" mouseTrack mouseTrackLeft={10} />
            <div>
              <FontAwesomeIcon
                icon={faPhoneAlt}
                className="phone"
                data-pr-tooltip={t("Warehouse's phone")}
              />

              <span className="phone" data-pr-tooltip={t("Warehouse's phone")}>
                {data?.phone || '-'}
              </span>
            </div>
          </div>

          <div className="quick-info">
            <Tooltip target=".workingHours" mouseTrack mouseTrackLeft={10} />
            <div>
              <FontAwesomeIcon
                icon={faClock}
                className="workingHours"
                data-pr-tooltip={t("Warehouse's working hours")}
              />

              <span
                className="workingHours"
                data-pr-tooltip={t("Warehouse's working hours")}
              >
                {workingHours}
              </span>
            </div>

            <Tooltip target=".drop_point" mouseTrack mouseTrackLeft={10} />
            <div>
              <FontAwesomeIcon
                icon={faMapMarkedAlt}
                className="drop_point"
                data-pr-tooltip={t(
                  'Parcels can be delivered or picked up from warehouse'
                )}
              />

              <FontAwesomeIcon
                icon={parseInt(data?.drop_point) ? faCheck : faTimes}
                className="drop_point"
                data-pr-tooltip={t(
                  'Parcels can be delivered or picked up from warehouse'
                )}
              />
            </div>

            <Tooltip target=".sorting_center" mouseTrack mouseTrackLeft={10} />
            <div>
              <FontAwesomeIcon
                icon={faBoxes}
                className="sorting_center"
                data-pr-tooltip={t('Warehouse is a sorting center')}
              />

              <FontAwesomeIcon
                icon={parseInt(data?.sorting_center) ? faCheck : faTimes}
                className="sorting_center"
                data-pr-tooltip={t('Warehouse is a sorting center')}
              />
            </div>

            <Tooltip target=".partner_ime" mouseTrack mouseTrackLeft={10} />
            <div>
              <FontAwesomeIcon
                icon={faHandshake}
                className="partner_ime"
                data-pr-tooltip={t("Warehouse's partner")}
              />

              <span
                className="partner_ime"
                data-pr-tooltip={t("Warehouse's partner")}
              >
                {data?.partner_name || '-'}
              </span>
            </div>
          </div>
        </div>

        <span className="section-title">{t('Location')}</span>
        <GeoLocation data={data} />

        <span className="section-title">{t('Regions')}</span>
        {data?.regions?.length > 1 ? (
          regions
        ) : (
          <div className="no-location-available">
            <i>{t('This warehouse has no regions defined')}</i>
          </div>
        )}
      </>
    </Dialog>
  );
}

export default ViewDialog;
