import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-lodash-debounce';

import useAxiosHook from '../../../../../../../hooks/useAxiosHook';
import useToastMessage from '../../../../../../../hooks/useToastMessage';
import {
  ClientLookupCollection,
  ClientLookupCollectionQueryParams,
} from '../../../../../../../types/api/clients';
import { LabelValue } from '../../../../../../../types/options';
import { queryString } from '../../../../../../../utils/helpers/http';
import AutoCompleteInput from '../../../../../../Forms/AutoCompleteInput/AutoCompleteInput';
import FieldWithErrors from '../../../../../../Forms/ReactHookForm/FieldWithErrors';
import { FormFields } from './NewReceptionDialog.types';

function Content(): JSX.Element {
  const { t } = useTranslation();

  const { control, setValue } = useFormContext<FormFields>();

  const name = useWatch<FormFields, 'name'>({
    name: 'name',
    control,
  });

  const client_id = useWatch<FormFields, 'client_id'>({
    name: 'client_id',
    control,
  });

  const { data, error, isLoading } = useAxiosHook<ClientLookupCollection>(
    '/clients/lookup' +
      queryString<ClientLookupCollectionQueryParams>({
        ime: useDebounce(name),
        limit: 15,
        page: 1,
      })
  );

  useToastMessage(undefined, error, {
    error: {
      summary: t('An error occured while loading clients.'),
    },
  });

  const clientOptions = useMemo<LabelValue[]>(
    () => data?.data.map((c) => ({ label: c.ime, value: String(c.id) })) ?? [],
    [data?.data]
  );

  return (
    <>
      <FieldWithErrors name="client_id" label={t('Client')}>
        <AutoCompleteInput
          id="filter_client"
          filterValue={name}
          value={client_id}
          options={clientOptions}
          placeholder={isLoading ? t('Loading...') : undefined}
          forceSelection={false}
          onFilterChange={(val) => setValue('name', val)}
          onSelectionChange={(val) => setValue('client_id', val ?? '')}
        />
      </FieldWithErrors>

      <p className="p-mb-0">
        {t('For a non-existent client, simply enter their name.')}
      </p>
    </>
  );
}

export default Content;
