import { CSSProperties, HTMLProps } from 'react';

import { Numeric } from '../../../types/general';
import { numericToCssUnit } from '../../../utils/helpers/styling';
import styles from './Spinner.module.scss';

type Props = {
  size?: Numeric;
  thickness?: Numeric;
  color?: CSSProperties['borderColor'];
  accentColor?: CSSProperties['borderColor'];
} & Omit<HTMLProps<HTMLDivElement>, 'size'>;

function Spinner({
  size = '4.5rem',
  thickness = '0.5rem',
  color = 'white',
  accentColor = 'var(--blue-500)',
  ...otherProps
}: Props): JSX.Element {
  return (
    <section
      className={styles.spinner}
      style={{
        border: `${numericToCssUnit(thickness)} solid ${color}`,
        borderTop: `${numericToCssUnit(thickness)} solid ${accentColor}`,
        width: size,
        height: size,
      }}
      {...otherProps}
    />
  );
}

export default Spinner;
