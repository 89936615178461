import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import _ from 'lodash';
import { Dropdown } from 'primereact/dropdown';
import { forwardRef, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useAxios from '../../../../hooks/useAxios';
import ChartContainer from '../ChartContainer';
import {
  getChartGroupingDate,
  getChartOptionsById,
  isPeriodStateValid,
  totalCurrency,
} from '../Dashboard.functions';

function chartDataFromData(t, data, chartGrouping) {
  let groupedData = _.groupBy(data, 'date');

  switch (chartGrouping) {
    case 'day':
    case 'month':
    case 'year':
      groupedData = _.groupBy(data, 'date');
      break;

    case 'week':
      groupedData = _.groupBy(data, (o) => dayjs(o.date).format('wo-YYYY'));
      break;

    default:
  }

  for (let i in groupedData) {
    groupedData[i] = groupedData[i].reduce(
      (acc, item) => acc + parseInt(item.performance.total_cash_amount),
      0
    );
  }

  return {
    labels: Object.keys(groupedData),
    datasets: [
      {
        label: t('Total cash amount'),
        data: Object.values(groupedData),
        borderColor: '#6372c3',
        backgroundColor: '#f3f5f7',
      },
    ],
  };
}

const FinancesChart = forwardRef(
  ({ id, onOptionsChange, children, ...other }, ref) => {
    const { t } = useTranslation();

    const [date, setDate] = useState([]);
    const [period, setPeriod] = useState(() => {
      const savedState = localStorage.getItem(id + '_period');
      const defaultOption = [new Date(), new Date()];

      return savedState && isPeriodStateValid(savedState)
        ? savedState
        : defaultOption;
    });

    const [chartGrouping, setChartGrouping] = useState(
      getChartOptionsById(id)?.grouping || 'day'
    );

    const chartGroupingDate = getChartGroupingDate(date, chartGrouping);
    const { data, isLoading, hasError, reload } = useAxios(
      '/statistics/courier/data',
      {
        date_from: chartGroupingDate[0],
        date_to: chartGroupingDate[1],
        group_by:
          chartGrouping === 'day' || chartGrouping === 'week'
            ? 'day'
            : chartGrouping,
      },
      {
        method: 'POST',
        // Calendar can return a single date while selecting a range.
        // Make sure it is always a range
        skipWhen: !date[1],
      }
    );

    const chartData = useMemo(
      () => chartDataFromData(t, data, chartGrouping),
      [t, chartGrouping, data]
    );

    const chartGroupingOptions = [
      { label: t('Daily'), value: 'day' },
      { label: t('Weekly'), value: 'week' },
      { label: t('Monthly'), value: 'month' },
      { label: t('Annaully'), value: 'year' },
    ];

    const chartOptions = useMemo(() => {
      return {
        title: {
          display: !!chartData.datasets[0]?.data?.length,
          position: 'bottom',
          text: t('Total: {{totalNumber}}', {
            totalNumber: totalCurrency(chartData),
          }),
        },
      };
    }, [t, chartData]);

    useEffect(() => {
      if (period) {
        localStorage.setItem(id + '_period', period);
      }
    }, [id, period]);

    return (
      <ChartContainer
        ref={ref}
        id={id}
        title={t('Finances')}
        type="line"
        data={chartData}
        options={chartOptions}
        header={
          <>
            <label htmlFor={`${id}-chart-grouping`} className="icon">
              <FontAwesomeIcon icon={faFilter} />
            </label>

            <Dropdown
              options={chartGroupingOptions}
              value={chartGrouping}
              inputId={`${id}-chart-grouping`}
              onChange={(e) => {
                setChartGrouping(e.value);

                onOptionsChange(id, {
                  grouping: e.value,
                });
              }}
            />
          </>
        }
        isLoading={isLoading}
        hasError={hasError}
        date={date}
        setDate={setDate}
        period={period}
        setPeriod={setPeriod}
        onChartRefresh={reload}
        onOptionsChange={onOptionsChange}
        {...other}
      >
        {children}
      </ChartContainer>
    );
  }
);

export default FinancesChart;
