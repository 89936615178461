import { faCashRegister } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import ToastContext from '../../../context/ToastContext';
import { FileTypes } from '../../../enums/files';
import useAxiosHook from '../../../hooks/useAxiosHook';
import { useEndpointGuard } from '../../../hooks/useEndpointGuard';
import usePageTitle from '../../../hooks/usePageTitle';
import usePrevious from '../../../hooks/usePrevious';
import useRouteDialog from '../../../hooks/useRouteDialog';
import useTableColumns from '../../../hooks/useTableColumns';
import useTableState from '../../../hooks/useTableState';
import useToastMessage from '../../../hooks/useToastMessage';
import {
  MarkAsDoneReconciliationsError,
  ReconciliationCollection,
  ReconciliationStatsResource,
  ReconciliationsError,
} from '../../../types/api/reconciliations';
import { ReduxState } from '../../../types/redux';
import {
  RoutePaths,
  constructIdRoute,
} from '../../../utils/constants/routePaths';
import { downloadFile, getFileName } from '../../../utils/helpers/files';
import { httpDateFormat } from '../../../utils/helpers/formatting';
import { queryString } from '../../../utils/helpers/http';
import { errorToast } from '../../../utils/helpers/primereact';
import Table from '../../DataTable/Table/Table';
import Grid from '../../Grid/Grid';
import MainContent from '../../Grid/MainContent';
import SidePanels from '../../Grid/SidePanels';
import HeaderPages from '../Components/HeaderPages/HeaderPages';
import CreateReconciliationDialog from './components/dialogs/CreateReconciliationDialog/CreateReconciliationDialog';
import Filters from './components/sidebar/Filters';
import Stats from './components/sidebar/Stats';
import {
  additionalColumnProperties,
  getColumnHeadersMap,
  tableStorageKey,
} from './CourierReconciliation.functions';
import {
  addGuard,
  approveGuard,
  markAsDoneGuard,
  rejectGuard,
} from './CourierReconciliation.guards';
import { DataRow } from './CourierReconciliation.types';
import useHandleMarkAllAsDoneResponse from './hooks/useHandleMarkAllAsDoneResponse';
import useTableFilters from './useTableFilters';

function CourierReconciliation(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const { toastRef } = useContext(ToastContext);
  const { bottomRightToastRef } = useContext(ToastContext);
  const [action, setAction] = useState<string>('');
  usePageTitle(t('Courier Reconciliation'));

  const user = useSelector<ReduxState, ReduxState['user']>(
    (state) => state.user
  );
  const {
    tableRef,
    page,
    limit,
    sortField,
    sortOrder,
    selectionMultiple,
    contextMenuSelection,
    setPage,
    setLimit,
    setSortField,
    setSortOrder,
    setSelectionMultiple,
    setContextMenuSelection,
  } = useTableState<DataRow>(tableStorageKey);

  const { filters, headerFiltersCount, resetAllFilters, httpFiltersObj } =
    useTableFilters(page, setPage!, limit);

  const columnHeadersMap = useMemo(() => getColumnHeadersMap(t), [t]);

  const canCreate = useEndpointGuard(addGuard);
  const canMarkAsDone = useEndpointGuard(markAsDoneGuard);
  const canApprove = useEndpointGuard(approveGuard);
  const canReject = useEndpointGuard(rejectGuard);

  const { selectedColumns, setSelectedColumns, columnOptions, columns } =
    useTableColumns(
      page,
      limit,
      'CourierReconciliation',
      columnHeadersMap,
      columnHeadersMap,
      (c) =>
        additionalColumnProperties(
          t,
          c as keyof typeof columnHeadersMap,
          setContextMenuSelection,
          setAction,
          canMarkAsDone,
          canApprove,
          canReject
        )
    );

  const finalColumns = useMemo<JSX.Element[]>(
    () => [
      ...columns,
      <Column
        key="action-column"
        header={t('Actions')}
        field="actions"
        frozen
        alignFrozen="right"
        {...additionalColumnProperties(
          t,
          'actions',
          setContextMenuSelection,
          setAction,
          canMarkAsDone,
          canApprove,
          canReject
        )}
      />,
    ],
    [
      columns,
      t,
      setContextMenuSelection,
      setAction,
      canMarkAsDone,
      canApprove,
      canReject,
    ]
  );

  const { data, error, reload, isLoading } = useAxiosHook<
    ReconciliationCollection,
    ReconciliationsError
  >(
    '/reconciliations' +
      queryString({ ...httpFiltersObj, employee_id: user.employee_id })
  );

  const prevError = usePrevious(error);

  useEffect(() => {
    if (!error || error === prevError) {
      return;
    }
    if (
      error.response?.status === 422 &&
      error.response?.data.error_description.work_order_id
    ) {
      return;
    }
    errorToast(
      toastRef,
      t('Error'),
      t('An error occured while loading reconciliations.')
    );
  }, [error, prevError, t, toastRef]);

  const {
    data: markReconciliationsAsDoneData,
    error: markReconciliationsAsDoneError,
    reload: markReconciliationsAsDoneReload,
  } = useAxiosHook<object, MarkAsDoneReconciliationsError>();
  const {
    data: approveData,
    error: approveError,
    reload: approveReload,
  } = useAxiosHook();
  const {
    data: rejectData,
    error: rejectError,
    reload: rejectReload,
  } = useAxiosHook();

  useToastMessage(
    markReconciliationsAsDoneData,
    markReconciliationsAsDoneError,
    {
      success: {
        summary: t('Reconciliations were successfully marked as done.'),
        callback: reload,
      },
      error: {
        summary: t('An error occured while marking reconciliations as done.'),
      },
    }
  );

  useHandleMarkAllAsDoneResponse(markReconciliationsAsDoneError);

  const handleMarkAsDone = useCallback(() => {
    if (!contextMenuSelection) {
      return;
    }
    markReconciliationsAsDoneReload({
      method: 'PUT',
      url: `/reconciliations/${contextMenuSelection.id}/waiting-for-approval`,
    });
  }, [contextMenuSelection, markReconciliationsAsDoneReload]);

  useToastMessage(approveData, approveError, {
    success: {
      summary: t('Reconciliations were successfully approved.'),
      callback: reload,
    },
    error: {
      summary: t('An error occured while approving reconciliations.'),
    },
  });

  const handleApprove = useCallback(() => {
    if (!contextMenuSelection) {
      return;
    }
    approveReload({
      method: 'PUT',
      url: `/reconciliations/${contextMenuSelection?.id}/approve`,
    });
  }, [contextMenuSelection, approveReload]);

  useToastMessage(rejectData, rejectError, {
    success: {
      summary: t('Reconciliations were successfully marked for correction.'),
      callback: reload,
    },
    error: {
      summary: t(
        'An error occured while marking reconciliations for correction.'
      ),
    },
  });

  const handleReject = useCallback(() => {
    if (!contextMenuSelection) {
      return;
    }
    rejectReload({
      method: 'PUT',
      url: `/reconciliations/${contextMenuSelection?.id}/reject`,
    });
  }, [contextMenuSelection, rejectReload]);

  const handlePrint = useCallback(() => {
    if (!contextMenuSelection) {
      return;
    }
    downloadFile(
      `${process.env.REACT_APP_REPORT_URL}/reconciliation-document/pdf?reconciliation_id=${contextMenuSelection.id}`,
      getFileName(t('Reconciliation'), [
        String(contextMenuSelection.id),
        contextMenuSelection.courier_name,
        httpDateFormat(contextMenuSelection.work_order_date),
      ]),
      FileTypes.PDF,
      bottomRightToastRef
    );
  }, [contextMenuSelection, t, bottomRightToastRef]);

  function exportToExcel() {
    if (!contextMenuSelection) {
      return;
    }
    downloadFile(
      `${process.env.REACT_APP_API_URL}/reconciliations/export/excel` +
        queryString(_.omit(httpFiltersObj, ['page', 'limit']) as any),
      getFileName(t('Courier Reconciliation'), undefined, true),
      FileTypes.XLSX,
      bottomRightToastRef
    );
  }

  function goToShipmentReconciliation(id: number) {
    history.push(
      constructIdRoute(RoutePaths.ShipmentReconciliations, id.toString()) +
        queryString(_.omit(httpFiltersObj, ['page', 'limit']) as any)
    );
  }

  const handleShipmentReconciliation = useCallback(() => {
    if (!contextMenuSelection) {
      return;
    }
    goToShipmentReconciliation(contextMenuSelection.id);
  }, [contextMenuSelection]);

  const {
    show: showCreateDialog,
    hide: hideCreateDialog,
    isVisible: isCreateDialogVisible,
  } = useRouteDialog(
    RoutePaths.CourierReconciliations,
    RoutePaths.AddCourierReconciliation,
    canCreate
  );

  useEffect(() => {
    if (action && contextMenuSelection) {
      if (action === 'print') {
        handlePrint();
      }
      if (action === 'mark-as-done') {
        handleMarkAsDone();
      }
      if (action === 'approve') {
        handleApprove();
      }
      if (action === 'mark-for-correction') {
        handleReject();
      }
      if (action === 'shipment-reconciliation') {
        handleShipmentReconciliation();
      }

      setAction('');
    }
  }, [
    action,
    contextMenuSelection,
    handleApprove,
    handleMarkAsDone,
    handlePrint,
    handleReject,
    handleShipmentReconciliation,
  ]);

  const statsRequest = useAxiosHook<ReconciliationStatsResource>(
    '/reconciliations/statistics' +
      queryString(_.omit(httpFiltersObj, ['page', 'limit']) as any)
  );

  function handleTableReload() {
    reload();
    statsRequest.reload();
  }

  return (
    <div className="page">
      <HeaderPages
        title={t('Courier Reconciliation')}
        subtitle={t('View and manage reconciliations by couriers')}
        icon={faCashRegister}
      >
        <Button
          label={t('New reconciliation')}
          icon="fas fa-plus"
          className="main-btn"
          onClick={showCreateDialog}
          data-cy="create-btn"
        />
      </HeaderPages>

      <CreateReconciliationDialog
        isShown={isCreateDialogVisible}
        onSuccess={reload}
        onHide={hideCreateDialog}
        goToReconciliation={goToShipmentReconciliation}
      />

      <Grid>
        <SidePanels>
          <Filters
            filters={filters}
            resetAllFilters={resetAllFilters}
            headerFiltersCount={headerFiltersCount}
          />
          <Stats request={statsRequest} />
        </SidePanels>
        <MainContent>
          <Table
            tableRef={tableRef}
            columns={finalColumns}
            data={data}
            scrollHeight="700"
            isLoading={isLoading}
            reload={handleTableReload}
            hasError={!!error}
            headerTitle=""
            setPage={setPage}
            setLimit={setLimit}
            sortField={sortField}
            rows={limit}
            setSortField={setSortField}
            setSortOrder={setSortOrder}
            setSelection={setSelectionMultiple}
            sortOrder={sortOrder}
            selection={selectionMultiple}
            storageString={tableStorageKey}
            selectionMode="multiple"
            clearSelectionObj={httpFiltersObj}
            selectionPageOnly
            rebuildTooltip
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            columnOptions={columnOptions}
            exportToExcelButton
            onExportToExcelButtonClick={exportToExcel}
            minGroupSelection={2}
            contextMenuSelection={contextMenuSelection}
            setContextMenuSelection={setContextMenuSelection}
          />
        </MainContent>
      </Grid>
    </div>
  );
}

export default CourierReconciliation;
