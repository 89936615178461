import './Table.scss';

import * as Sentry from '@sentry/react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import _, { isEqualWith, uniqBy } from 'lodash';
import { Button } from 'primereact/button';
import { Checkbox, CheckboxChangeParams } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { ContextMenu, ContextMenuProps } from 'primereact/contextmenu';
import {
  DataTable,
  DataTableProps,
  DataTableRowEventParams,
  DataTableSelectionChangeParams,
  DataTableSortOrderType,
  DataTableSortParams,
} from 'primereact/datatable';
import { MultiSelect, MultiSelectChangeParams } from 'primereact/multiselect';
import { OverlayPanel } from 'primereact/overlaypanel';
import { PaginatorPageState } from 'primereact/paginator';
import {
  Dispatch,
  RefObject,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import ReactTooltip from 'react-tooltip';
import XLSX from 'xlsx';

import useMediaQuery from '../../../hooks/useMediaQuery';
import usePrevious from '../../../hooks/usePrevious';
import useTableDataToDisplay from '../../../hooks/useTableDataToDisplay';
import useTableScrollHeight from '../../../hooks/useTableScrollHeight';
import { WithPagination } from '../../../types/api';
import { Numeric } from '../../../types/general';
import { rowsPerPageOptions } from '../../../utils/constants/tables';
import { getDataTableProps } from '../../../utils/globals';
import { isDevEnv } from '../../../utils/helpers/misc';
import { tryInt } from '../../../utils/helpers/parse';
import { removeSessionStorageItem } from '../../../utils/helpers/storage';
import { userPrefixedString } from '../../../utils/helpers/user';
import Tooltip from '../../Misc/Tooltip/Tooltip';
import { paginatorTemplate } from './Table.functions';

type RequiredProps = 'sortField' | 'rows' | 'sortOrder' | 'selection';

export type TableProps<D extends object = object> = Required<
  Pick<DataTableProps, RequiredProps>
> &
  Omit<DataTableProps, RequiredProps> & {
    data: WithPagination<D> | object | undefined;
    storageString: string;
    sortField: string;
    setSortField: Dispatch<SetStateAction<string>>;
    setSortOrder: Dispatch<SetStateAction<DataTableSortOrderType>>;
    setSelection:
      | Dispatch<SetStateAction<D | null>>
      | Dispatch<SetStateAction<D>>
      | Dispatch<SetStateAction<D[]>>;
    setContextMenuSelection?: Dispatch<SetStateAction<any>>;
    columns: JSX.Element | JSX.Element[];
    columnOptions: { field: string; header: string; label: string }[];
    selectedColumns: object[];
    setSelectedColumns: Dispatch<SetStateAction<object[]>>;
    isLoading: boolean;
    hasError: boolean;
    reload: () => void;
    setPage?: Dispatch<SetStateAction<number>>;
    setLimit?: Dispatch<SetStateAction<number>>;
    grid?: boolean;
    striped?: boolean;
    size?: 'small' | 'normal' | 'large';
    customDataModifier?: (data: D[]) => D[];
    contextMenuModel?: ContextMenuProps['model'];
    headerTitle?: string;
    headerFilters?: JSX.Element;
    headerFiltersForm?: JSX.Element;
    headerFiltersCount?: number;
    onHeaderFiltersResetAllBtnClick?: () => void;
    defaultSortField?: string;
    rebuildTooltip?: boolean;
    isReloadDisabled?: boolean;
    exportToCSVButton?: boolean;
    onExportToCSVButtonClick?: () => void;
    exportToExcelButton?: boolean;
    onExportToExcelButtonClick?: () => void;
    exportToJsonButton?: boolean;
    exportToXmlButton?: boolean;
    onExportToXmlButtonClick?: () => void;
    onExportToJsonButtonClick?: () => void;
    selectionModifier?: (value: any) => any;
    groupActionsModel?: ContextMenuProps['model'];
    minGroupSelection?: number;
    selectionPageOnly?: boolean;
    clearSelectionObj?: object & { page: number; limit: number };
    scrollHeight?: string;
    filterHeight?: number; // we set this height on the filters and table on same page
    hideMultiCheckbox?: boolean;
    selectionDisabledRows?: any[];
  };

function Table<D extends object>({
  tableRef,
  data,
  rows,
  setPage,
  setLimit,
  sortField,
  setSortField,
  sortOrder,
  setSortOrder,
  selection,
  setSelection,
  setContextMenuSelection,
  storageString,
  contextMenuModel,
  customDataModifier,
  columns,
  columnOptions,
  selectedColumns,
  setSelectedColumns,
  headerFilters,
  headerFiltersForm,
  headerFiltersCount,
  onHeaderFiltersResetAllBtnClick,
  isLoading,
  hasError,
  reload,
  exportToCSVButton,
  onExportToCSVButtonClick,
  exportToExcelButton,
  onExportToExcelButtonClick,
  exportToJsonButton,
  onExportToJsonButtonClick,
  exportToXmlButton,
  onExportToXmlButtonClick,
  header,
  headerTitle,
  selectionMode = 'single',
  onContextMenuSelectionChange,
  paginatorLeft,
  paginatorRight,
  selectionModifier,
  groupActionsModel,
  isReloadDisabled = false,
  rebuildTooltip = false,
  lazy = true,
  grid = true,
  striped = true,
  size = 'small',
  defaultSortField = 'id',
  className,
  contextMenuSelection,
  minGroupSelection = 1,
  selectionPageOnly,
  clearSelectionObj,
  scrollHeight,
  filterHeight,
  hideMultiCheckbox = false,
  selectionDisabledRows = [],
  ...otherProps
}: TableProps<D> & { tableRef: RefObject<any> }) {
  const { t } = useTranslation();
  const dataTableProps = useMemo(() => getDataTableProps(t), [t]);
  const contextMenuRef = useRef<ContextMenu>(null);
  const groupActionsRef = useRef<ContextMenu>(null);
  const tableScrollHeight = useTableScrollHeight(tableRef, filterHeight);
  const isOnMobile = useMediaQuery('(max-width: 768px)');

  const containerClassName = useMemo(() => {
    return isOnMobile
      ? 'cols-two'
      : headerFiltersCount && headerFiltersCount <= 9
      ? 'cols-three'
      : headerFiltersCount &&
        headerFiltersCount >= 10 &&
        headerFiltersCount <= 16
      ? 'cols-four'
      : 'cols-five';
  }, [headerFiltersCount, isOnMobile]);

  useEffect(() => {
    if (selectionDisabledRows.length > 0) {
      setSelection(selectionDisabledRows as any);
    }
  }, [selectionDisabledRows, setSelection]);

  useEffect(() => {
    if (rebuildTooltip) {
      ReactTooltip.rebuild();
    }
  }, [data, rebuildTooltip]);

  // In case of passing paginated data in non-lazy mode
  useEffect(() => {
    if (isDevEnv() && !lazy && (data as WithPagination<any>)?.pagination) {
      throw Error('Table cannot be passed paginated data in non-lazy mode.');
    }
  }, [data, lazy]);

  // In case of missing out required props in lazy mode
  useEffect(() => {
    if (
      isDevEnv() &&
      lazy &&
      (setPage === undefined || setLimit === undefined)
    ) {
      throw Error(
        'setPage and setLimit are required props for Table in lazy mode.'
      );
    }
  }, [lazy, setLimit, setPage]);

  // In case of using an unsupported selection mode
  useEffect(() => {
    if (
      !isDevEnv() ||
      !selectionMode ||
      ['single', 'multiple'].includes(selectionMode)
    ) {
      return;
    }

    throw Error(
      "Table currently supports 'single' and 'multiple' modes. Please extend it."
    );
  }, [selectionMode]);

  useOnRouteChange(storageString);

  useOnFiltersObjChange(
    clearSelectionObj,
    selectionMode,
    setSelection as Dispatch<SetStateAction<D | D[] | null>>
  );

  const dataToDisplay = useTableDataToDisplay(
    lazy ? (data as WithPagination<any>)?.data : data,
    sortField,
    sortOrder,
    customDataModifier
  );

  function handlePaginationChange(e: PaginatorPageState): void {
    if (setPage === undefined || setLimit === undefined) {
      return;
    }

    setPage(e.page ? e.page + 1 : 1);
    setLimit(e.rows);
  }

  function handleSortChange(e: DataTableSortParams): void {
    setSortOrder((prevSortOrder) =>
      prevSortOrder === -1 && sortField === e.sortField ? 0 : e.sortOrder
    );

    setSortField((prevSortField) =>
      prevSortField === e.sortField && sortOrder === -1
        ? defaultSortField
        : e.sortField
    );
  }

  function handleSelectionChange(e: DataTableSelectionChangeParams): void {
    setSelection(
      typeof selectionModifier === 'function'
        ? selectionModifier(e.value)
        : e.value
    );
  }

  function handleContextMenu(e: DataTableRowEventParams) {
    if (!contextMenuModel || !contextMenuRef.current) {
      return;
    }

    contextMenuRef.current.show(e.originalEvent);
  }

  const overlayPanelRef = useRef<OverlayPanel>(null);

  function xlsx_getWorkbook(fields: any) {
    const worksheet = XLSX.utils.aoa_to_sheet(fields);
    const newWorkbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(newWorkbook, worksheet);

    return newWorkbook;
  }

  const headerToDisplay = useMemo(() => {
    if (isDevEnv() && headerFiltersForm && headerFiltersCount === undefined) {
      throw Error(
        'Prop headerFiltersCount must be present together with headerFiltersForm in Table.'
      );
    }

    const rightHeaderContent = (
      <>
        {groupActionsModel &&
          Array.isArray(selection) &&
          selection.length >= minGroupSelection &&
          (groupActionsModel.length ? (
            <Button
              type="button"
              label={t('Selected ( {{selectionCount}} )', {
                selectionCount: selection.length,
              })}
              className="p-button-outlined p-mr-2"
              onClick={(e) => groupActionsRef?.current?.show(e)}
              data-cy="group-actions-btn"
            />
          ) : (
            <Tooltip text={t('No group actions available')} position="top">
              <Button
                type="button"
                label={t('Selected ( {{selectionCount}} )', {
                  selectionCount: selection.length,
                })}
                className="p-button-outlined p-mr-2"
                disabled
              />
            </Tooltip>
          ))}

        <Button
          type="button"
          icon="fas fa-sync-alt"
          tooltip={t('Reload')}
          tooltipOptions={{ position: 'top' }}
          onClick={reload}
          disabled={isReloadDisabled}
          className="p-mx-2 p-button-text p-button-plain"
        />

        {exportToExcelButton && (
          <Button
            type="button"
            icon="fas fa-file-excel"
            tooltip={t('Export Excel')}
            tooltipOptions={{ position: 'top' }}
            onClick={() => {
              if (onExportToExcelButtonClick) {
                onExportToExcelButtonClick();
              } else {
                let tableHeaders = tableRef.current?.props?.children?.map(
                  (child: any) => child?.props?.header
                );

                let tableFields = tableRef.current?.props?.children?.map(
                  (child: any) => child?.props?.field
                );

                let rows = tableRef.current?.props?.value?.map((row: any) => {
                  return tableFields?.map((field: string) => {
                    return Object.keys(row).includes(field) && row[field];
                  });
                });

                let tableData = _.concat([tableHeaders], rows);

                XLSX.writeFile(xlsx_getWorkbook(tableData), `export.xlsx`);
              }
            }}
            className="p-mr-2 p-button-text p-button-plain"
          />
        )}

        {exportToCSVButton && (
          <Button
            type="button"
            icon="fas fa-file-csv"
            tooltip={t('Export CSV')}
            tooltipOptions={{ position: 'top' }}
            onClick={() => {
              if (onExportToCSVButtonClick) {
                onExportToCSVButtonClick();
              } else {
                tableRef?.current?.exportCSV();
              }
            }}
            className="p-mr-2 p-button-text p-button-plain"
          />
        )}

        {exportToJsonButton && (
          <Button
            type="button"
            icon="fas fa-file-alt"
            tooltip={t('Export JSON')}
            tooltipOptions={{ position: 'top' }}
            onClick={() => {
              if (onExportToJsonButtonClick) {
                onExportToJsonButtonClick();
              }
            }}
            className="p-mr-2 p-button-text p-button-plain"
          />
        )}

        {exportToXmlButton && (
          <Button
            type="button"
            icon="fas fa-file-code"
            tooltip={t('Export XML')}
            tooltipOptions={{ position: 'top' }}
            onClick={() => {
              if (onExportToXmlButtonClick) {
                onExportToXmlButtonClick();
              }
            }}
            className="p-mr-2 p-button-text p-button-plain"
          />
        )}

        <MultiSelect
          inputId={`${storageString}-customize-columns`}
          value={selectedColumns}
          options={columnOptions}
          maxSelectedLabels={1}
          selectedItemsLabel={t('{0} columns')}
          onChange={handleColumnChange}
        />
      </>
    );

    // If no table filters were passed
    if (!headerFiltersForm || headerFiltersCount === undefined) {
      if (isDevEnv() && headerTitle === undefined) {
        throw Error(
          'Prop headerTitle must be present when no headerFiltersForm is passed in Table.'
        );
      }

      return (
        <div className="p-d-flex p-jc-between">
          <div className="p-d-flex">
            <h3 className="p-my-auto">{headerTitle}</h3>
          </div>
          <div className="p-d-flex">{rightHeaderContent}</div>
        </div>
      );
    }

    function handleColumnChange(event: MultiSelectChangeParams) {
      const newSelectedColumns =
        event.value.length > 0 ? event.value : columnOptions;

      setSelectedColumns(newSelectedColumns);

      try {
        sessionStorage.setItem(
          `${storageString}SelectedColumns`,
          JSON.stringify(newSelectedColumns)
        );
      } catch (e) {
        Sentry.captureException(e, {
          extra: {
            data: newSelectedColumns,
          },
        });
      }
    }

    const buttonClassName = classNames('p-mr-2', {
      'p-button-secondary': headerFiltersCount === 0,
      'p-button-warning': headerFiltersCount > 0,
    });

    const badgeClassName = classNames({
      'p-badge-danger': headerFiltersCount > 0,
    });

    return (
      <div className="table-header-container">
        <div>
          <Button
            type="button"
            label={t('Filters')}
            badge={String(headerFiltersCount)}
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              overlayPanelRef.current?.toggle(e, null);
            }}
            badgeClassName={badgeClassName}
            className={buttonClassName}
          />
          <OverlayPanel
            ref={overlayPanelRef}
            showCloseIcon
            className="custom-datatable-filters"
          >
            <div className={`filters-container ${containerClassName}`}>
              {headerFiltersForm}
            </div>
            <div className="p-d-flex p-jc-end p-mt-3">
              <Button
                type="button"
                label={t('Reset all')}
                onClick={() => {
                  if (typeof onHeaderFiltersResetAllBtnClick === 'function') {
                    onHeaderFiltersResetAllBtnClick();
                  }
                }}
              />
            </div>
          </OverlayPanel>
        </div>
        <div className="filter-chips-container">{headerFilters}</div>
        <div>{rightHeaderContent}</div>
      </div>
    );
  }, [
    columnOptions,
    containerClassName,
    exportToCSVButton,
    exportToExcelButton,
    exportToJsonButton,
    exportToXmlButton,
    groupActionsModel,
    headerFilters,
    headerFiltersCount,
    headerFiltersForm,
    headerTitle,
    isReloadDisabled,
    minGroupSelection,
    onExportToCSVButtonClick,
    onExportToExcelButtonClick,
    onExportToJsonButtonClick,
    onExportToXmlButtonClick,
    onHeaderFiltersResetAllBtnClick,
    tableRef,
    reload,
    selectedColumns,
    selection,
    setSelectedColumns,
    storageString,
    t,
  ]);

  const tableClassName = classNames(className ?? '', 'custom-datatable', {
    'p-datatable-sm': size === 'small',
    'p-datatable-lg': size === 'large',
    'p-datatable-striped': striped,
  });

  function isAllRowsSelected(data: any[]) {
    if (data.length > 0) {
      return data.every(
        (entry) =>
          !!selection?.find(
            (selection: { id: Numeric }) => selection.id === entry.id
          )
      );
    }

    return false;
  }

  function filterEveryRowOnPage(selection: { id: Numeric }) {
    return !dataToDisplay.find((entry: any) => entry.id === selection?.id);
  }

  // excluded disabled rows for unselecting
  function filterEveryDisabledRowOnPage() {
    return dataToDisplay.filter(
      (row: any) =>
        row.id === selectionDisabledRows?.find((item) => item.id === row.id)?.id
    );
  }

  function unselectAllRows(prevSelection: { id: Numeric }[]) {
    if (selectionDisabledRows?.length > 0) {
      const filterDisabledRows = filterEveryDisabledRowOnPage();
      return filterDisabledRows;
    } else {
      const filterData = prevSelection.filter(filterEveryRowOnPage);
      return filterData;
    }
  }

  function selectAllRows(prev: { id: Numeric }[]) {
    return uniqBy(
      [...prev, ...dataToDisplay] as any[],
      (e: { id: Numeric }) => e.id
    );
  }

  function onChangeMultipleCheckbox(event: CheckboxChangeParams) {
    if (!event.checked) {
      setSelection(unselectAllRows as any);
      return;
    }

    setSelection(selectAllRows as any);
  }

  const finalColumns =
    selectionMode === 'multiple' && Array.isArray(columns)
      ? [
          <Column
            key="__checkbox__"
            selectionMode="multiple"
            reorderable={false}
            className={selectionPageOnly ? 'selection-column' : undefined}
            style={{ width: '38px', flex: '0 1 auto' }}
            header={
              selectionPageOnly ? (
                <Checkbox
                  disabled={dataToDisplay.length === 0}
                  checked={isAllRowsSelected(dataToDisplay)}
                  onChange={onChangeMultipleCheckbox}
                />
              ) : undefined
            }
          />,
          ...columns,
        ]
      : columns;

  return (
    <>
      <DataTable
        ref={tableRef}
        header={header ?? headerToDisplay}
        value={dataToDisplay}
        resizableColumns
        reorderableColumns
        rowHover
        // Laziness
        loading={isLoading}
        emptyMessage={
          hasError
            ? dataTableProps.emptyMessageError
            : dataTableProps.emptyMessage
        }
        // Sorting
        sortField={sortField}
        sortOrder={sortOrder}
        onSort={handleSortChange}
        // Scrolling
        scrollable
        scrollHeight={`${scrollHeight ? scrollHeight : tableScrollHeight}px`}
        // Selection
        selection={selection}
        selectionMode={selectionMode}
        onSelectionChange={handleSelectionChange}
        // Storage
        stateKey={userPrefixedString(storageString)}
        onRowClick={(e: any) => contextMenuRef?.current?.hide(e.originalEvent)}
        // Context Menu
        contextMenuSelection={
          contextMenuSelection
            ? contextMenuSelection
            : selectionMode === 'multiple'
            ? Array.isArray(selection)
              ? selection[0]
              : {}
            : selection
        }
        onContextMenuSelectionChange={(e) => {
          if (onContextMenuSelectionChange) {
            onContextMenuSelectionChange(e);
            return;
          }

          if (selectionMode === 'single') {
            handleSelectionChange(e);
          }

          if (selectionMode === 'multiple' && !setContextMenuSelection) {
            throw Error(
              'setContextMenuSelection is required in multiple selection mode'
            );
          }

          if (setContextMenuSelection) {
            setContextMenuSelection(e.value);
          }
        }}
        onContextMenu={handleContextMenu}
        // Other
        className={tableClassName}
        // Laziness
        lazy={lazy}
        {...(lazy
          ? {
              first:
                tryInt(
                  Number((data as WithPagination<any>)?.pagination.from) - 1
                ) ?? 0,
              totalRecords:
                tryInt((data as WithPagination<any>)?.pagination.total) ?? 0,
              onPage: handlePaginationChange,
              // Pagination
              paginator: true,
              paginatorLeft:
                paginatorLeft ?? (paginatorRight ? <></> : undefined),
              paginatorRight:
                paginatorRight ?? (paginatorLeft ? <></> : undefined),
              paginatorTemplate: paginatorTemplate,
              currentPageReportTemplate: (data as WithPagination<any>)
                ?.pagination
                ? t('Showing {first} to {last} of {totalRecords} entries')
                : t('Showing 0 of 0 entries'),
              rows: rows,
              rowsPerPageOptions,
            }
          : {})}
        {...otherProps}
      >
        {finalColumns}
      </DataTable>

      <ContextMenu model={contextMenuModel} ref={contextMenuRef} />

      {groupActionsModel && (
        <ContextMenu
          className="group-actions-menu"
          model={groupActionsModel}
          ref={groupActionsRef}
        />
      )}
    </>
  );
}

function useOnRouteChange(storageString: string) {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      removeSessionStorageItem(userPrefixedString(storageString));
    });

    return () => {
      unlisten();
    };
  }, [history, storageString]);
}

function useOnFiltersObjChange<D>(
  clearSelectionObj: (object & { page: number; limit: number }) | undefined,
  selectionMode: string,
  setSelection: Dispatch<SetStateAction<D | D[] | null>>
) {
  const prevClearSelectionObj = usePrevious(clearSelectionObj);
  useEffect(() => {
    if (
      !prevClearSelectionObj ||
      isEqualWith(clearSelectionObj, prevClearSelectionObj, (a, b, key) => {
        if (key === 'limit' || key === 'page') {
          return true;
        }

        return dayjs(a).isValid() && dayjs(b).isValid()
          ? dayjs(a).isSame(b, 'day')
          : undefined;
      })
    ) {
      return;
    }

    selectionMode === 'multiple' ? setSelection([]) : setSelection(null);
  }, [clearSelectionObj, prevClearSelectionObj, selectionMode, setSelection]);
}

export default Table;
