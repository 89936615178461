import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import _ from 'lodash';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { forwardRef, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useAxios from '../../../../hooks/useAxios';
import ChartContainer from '../ChartContainer';
import {
  colorPalette,
  getChartGroupingDate,
  getChartOptionsById,
  isPeriodStateValid,
  totalNumber,
} from '../Dashboard.functions';

function chartDataFromData(t, data, chartGrouping, selectedCouriers) {
  let groupedData;

  switch (chartGrouping) {
    case 'day':
    case 'month':
    case 'year':
      groupedData = _.groupBy(data, 'date');
      break;

    case 'week':
      groupedData = _.groupBy(data, (o) => dayjs(o.date).format('wo-YYYY'));
      break;

    default:
  }

  let allDates = [];
  if (selectedCouriers?.length) {
    let groupedByCourier = _.groupBy(data, 'id');

    let groupedByCourierArr = [];

    for (let courier in groupedByCourier) {
      let groupByDate;

      if (chartGrouping === 'week') {
        groupByDate = _.groupBy(groupedByCourier[courier], (o) =>
          dayjs(o.date).format('Wo-YYYY')
        );
      } else {
        groupByDate = _.groupBy(groupedByCourier[courier], (o) => o.date);
      }

      let courierPerformance = [];

      for (let i in groupByDate) {
        groupByDate[i] = groupByDate[i].reduce((acc, item) => {
          let coll_parcels = item.performance.collected_parcels
            ? item.performance.collected_parcels
            : 0;

          return acc + parseInt(coll_parcels);
        }, 0);

        let dateObj = {
          x: i,
          y: groupByDate[i],
        };

        courierPerformance.push(dateObj);

        allDates.push(i);
      }

      let singleCourierPerformaces = {
        id: groupedByCourier[courier][0].id,
        name: groupedByCourier[courier][0].name,
        performances: courierPerformance,
      };

      groupedByCourierArr.push(singleCourierPerformaces);
    }

    groupedData = groupedByCourierArr;
  }

  function generateLabel(performancesPerCourier) {
    let total = performancesPerCourier.performances.reduce(
      (acc, date) => acc + date.y,
      0
    );

    return `${performancesPerCourier.name} - ${total}`;
  }

  if (groupedData?.length) {
    return {
      labels: _.uniq(allDates).sort(),

      datasets: groupedData.map((performancesPerCourier, index) => {
        return {
          label: generateLabel(performancesPerCourier),
          data: performancesPerCourier.performances,
          borderColor: colorPalette[index % colorPalette.length],
          backgroundColor: '#f3f5f7',
        };
      }),
    };
  } else {
    for (let i in groupedData) {
      groupedData[i] = groupedData[i].reduce((acc, item) => {
        let coll_parcels = item.performance.collected_parcels
          ? item.performance.collected_parcels
          : 0;

        return acc + parseInt(coll_parcels);
      }, 0);
    }

    // format the data so that every date will have x - (date) & y - (collected parcels value) coordinates specified
    let newData = [];

    for (let date in groupedData) {
      let newDate = {
        x: date,
        y: groupedData[date],
      };

      newData.push(newDate);
    }

    return {
      labels: Object.keys(groupedData),
      datasets: [
        {
          label: t('Picked up parcels'),
          data: newData,
          borderColor: '#af50bf',
          backgroundColor: '#f3f5f7',
        },
      ],
    };
  }
}

const PickedUpParcelsChart = forwardRef(
  ({ id, onOptionsChange, children, setIsLoading, ...other }, ref) => {
    const { t } = useTranslation();

    const [date, setDate] = useState([]);
    const [period, setPeriod] = useState(() => {
      const savedState = localStorage.getItem(id + '_period');
      const defaultOption = [new Date(), new Date()];

      return savedState && isPeriodStateValid(savedState)
        ? savedState
        : defaultOption;
    });

    const [chartGrouping, setChartGrouping] = useState(
      getChartOptionsById(id)?.grouping || 'day'
    );

    const [selectedCouriers, setSelectedCouriers] = useState(null);

    const { data: couriersData } = useAxios('/statistics/courier/list', {
      // Calendar can return a single date while selecting a range.
      // Make sure it is always a range
      skipWhen: !date[1],
    });

    const chartGroupingDate = getChartGroupingDate(date, chartGrouping);

    const filters = useMemo(() => {
      let filterObj = {};

      if (chartGroupingDate) {
        if (chartGroupingDate[0]) {
          filterObj['date_from'] = chartGroupingDate[0];
        }

        if (chartGroupingDate[1]) {
          filterObj['date_to'] = chartGroupingDate[1];
        }
      }

      if (chartGrouping) {
        filterObj['group_by'] =
          chartGrouping === 'day' || chartGrouping === 'week'
            ? 'day'
            : chartGrouping;
      }

      if (selectedCouriers) {
        filterObj['couriers'] = selectedCouriers;
      }

      return Object.keys(filterObj).length ? filterObj : null;
    }, [chartGroupingDate, chartGrouping, selectedCouriers]);

    const { data, isLoading, hasError, reload } = useAxios(
      '/statistics/courier/data',
      filters,
      {
        method: 'POST',
        // Calendar can return a single date while selecting a range.
        // Make sure it is always a range
        skipWhen: !date[1],
      }
    );

    const chartData = useMemo(
      () => chartDataFromData(t, data, chartGrouping, selectedCouriers),
      [t, data, chartGrouping, selectedCouriers]
    );

    const chartGroupingOptions = [
      { label: t('Daily'), value: 'day' },
      { label: t('Weekly'), value: 'week' },
      { label: t('Monthly'), value: 'month' },
      { label: t('Annaully'), value: 'year' },
    ];

    const couriersOptions = [
      ...(Array.isArray(couriersData)
        ? couriersData.map((courier) => ({
            value: courier.courier_id,
            label: courier.courier_name,
          }))
        : []),
    ];

    const chartOptions = useMemo(() => {
      return {
        title: {
          display: !!chartData.datasets[0]?.data?.length,
          position: 'bottom',
          text: t('Total: {{totalNumber}}', {
            totalNumber: totalNumber(chartData),
          }),
        },
        tooltips: {
          callbacks: {
            title: function (tooltipItems, chartData) {
              return chartData.datasets[tooltipItems[0].datasetIndex].data[
                tooltipItems[0].index
              ].x;
            },
          },
        },
        points: {},
      };
    }, [t, chartData]);

    useEffect(() => {
      if (period) {
        localStorage.setItem(id + '_period', period);
      }
    }, [id, period]);

    return (
      <ChartContainer
        ref={ref}
        id={id}
        title={t('Picked up parcels')}
        type="line"
        data={chartData}
        options={chartOptions}
        header={
          <>
            <label htmlFor={`${id}-chart-grouping`} className="icon">
              <FontAwesomeIcon icon={faFilter} />
            </label>

            <Dropdown
              options={chartGroupingOptions}
              value={chartGrouping}
              inputId={`${id}-chart-grouping`}
              onChange={(e) => {
                setChartGrouping(e.value);

                onOptionsChange(id, {
                  grouping: e.value,
                });
              }}
            />

            <label htmlFor={`${id}-select-couriers`} className="icon">
              <FontAwesomeIcon icon={faUserFriends} />
            </label>

            <MultiSelect
              options={couriersOptions}
              value={selectedCouriers}
              inputId={`${id}-select-couriers`}
              onChange={(e) => {
                setSelectedCouriers(e.value);
              }}
              placeholder={t('All couriers')}
              filter
            />
          </>
        }
        isLoading={isLoading}
        hasError={hasError}
        date={date}
        setDate={setDate}
        period={period}
        setPeriod={setPeriod}
        onChartRefresh={reload}
        onOptionsChange={onOptionsChange}
        {...other}
      >
        {children}
      </ChartContainer>
    );
  }
);

export default PickedUpParcelsChart;
