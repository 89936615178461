import './AddReplaceChartDialog.scss';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { RadioButton } from 'primereact/radiobutton';
import { useMemo, useState } from 'react';
import { useEffect } from 'react';

import { useEndpointGuard } from '../../../../hooks/useEndpointGuard';
import * as dashboardGuards from '../../../../utils/constants/auth/dashboard';
import { getRadioButtons } from '../Dashboard.functions';

function AddReplaceChartDialog({
  t,
  replacingChartId,
  visible,
  onHide,
  onSubmit,
  ...other
}) {
  const bestCouriersChartGuard = useEndpointGuard(
    dashboardGuards.bestCouriersChart
  );
  const deliveredParcelsChartGuard = useEndpointGuard(
    dashboardGuards.deliveredParcelsChart
  );
  const deliveredParcelsPerRegionChartGuard = useEndpointGuard(
    dashboardGuards.deliveredParcelsPerRegionChart
  );
  const financesChartGuard = useEndpointGuard(dashboardGuards.financesChart);
  const pickedUpParcelsChartGuard = useEndpointGuard(
    dashboardGuards.pickedUpParcelsChart
  );
  const pickedUpParcelsPerRegionChartGuard = useEndpointGuard(
    dashboardGuards.pickedUpParcelsPerRegionChart
  );

  const radioButtons = useMemo(
    () =>
      getRadioButtons(
        t,
        bestCouriersChartGuard,
        deliveredParcelsChartGuard,
        deliveredParcelsPerRegionChartGuard,
        financesChartGuard,
        pickedUpParcelsPerRegionChartGuard,
        pickedUpParcelsChartGuard
      ),
    [
      bestCouriersChartGuard,
      deliveredParcelsChartGuard,
      deliveredParcelsPerRegionChartGuard,
      financesChartGuard,
      pickedUpParcelsChartGuard,
      pickedUpParcelsPerRegionChartGuard,
      t,
    ]
  );

  const [selectedChart, setSelectedChart] = useState();

  useEffect(() => {
    setSelectedChart(null);
  }, [
    bestCouriersChartGuard,
    deliveredParcelsChartGuard,
    deliveredParcelsPerRegionChartGuard,
    financesChartGuard,
    pickedUpParcelsChartGuard,
    pickedUpParcelsPerRegionChartGuard,
  ]);

  const dialogFooter = (
    <>
      <Button
        type="button"
        label={t('Close')}
        className="p-button-text"
        onClick={onHide}
        data-cy="close-btn"
      />

      <Button
        type="button"
        label={replacingChartId ? t('Replace') : t('Add')}
        disabled={!selectedChart}
        onClick={() => {
          onSubmit(selectedChart);
          onHide();
        }}
        data-cy="submit-btn"
      />
    </>
  );

  const replacingChartType = replacingChartId?.split('===')?.[0];
  const radioButtonsToRender = replacingChartId
    ? radioButtons.filter((c) => c.value !== replacingChartType)
    : radioButtons;

  return (
    <Dialog
      visible={visible}
      header={replacingChartId ? t('Replace chart') : t('Add new chart')}
      footer={dialogFooter}
      onHide={onHide}
      dismissableMask
      resizable={false}
      className="dashboard-add-replace-chart-dialog"
      {...other}
    >
      <p>
        {replacingChartId
          ? t('Select the kind of chart that should replace the selected one')
          : t('Select the kind of chart you want to add')}
      </p>

      {radioButtonsToRender.map((rb, i) => (
        <div key={i} className="radio-button">
          <RadioButton
            inputId={i.toString()}
            value={rb.value}
            name="chart"
            checked={selectedChart === rb.value}
            onChange={() => setSelectedChart(rb.value)}
          />
          <label htmlFor={i.toString()}>{rb.label}</label>
        </div>
      ))}
    </Dialog>
  );
}

export default AddReplaceChartDialog;
