export const employeeCollectionGuard = 'GET /employees';
export const employeeResourceGuard = 'GET /employees/d+';
export const addEmployeeResourceGuard = 'POST /employees';
export const updateEmployeeResourceGuard = 'PUT /employees/d+';

export const addEmpoloyeeVehicleResourceGuard =
  'POST /employees/d+/vehicles/add';
export const updateEmpoloyeeVehicleResourceGuard = 'PUT /employees/d+/vehicles';

export const updateEmployeeRoleResourceGuard = 'POST /employees/d+/roles';

export const addEmployeeHubResourceGuard = 'POST /employees/d+/hubs';
