import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import usePageTitle from '../../../../hooks/usePageTitle';
import useRouteDialog from '../../../../hooks/useRouteDialog';
import useTableColumns from '../../../../hooks/useTableColumns';
import useTableState from '../../../../hooks/useTableState';
import { EntityIdRouteParams } from '../../../../types/routing';
import {
  RoutePaths,
  constructIdRoute,
} from '../../../../utils/constants/routePaths';
import { noop } from '../../../../utils/helpers/functions';
import Table from '../../../DataTable/Table/Table';
import Grid from '../../../Grid/Grid';
import MainContent from '../../../Grid/MainContent';
import SidePanels from '../../../Grid/SidePanels';
import GoBackLink from '../../../Misc/GoBack/GoBackLink';
import Actions from '../Sidebar/Actions';
import ProductType from '../Sidebar/ProductType';
import useProductTypeState from '../Sidebar/useProductTypeState';
import { getProductTypes } from '../Tariffs.functions';
import AddEditDialog from './Dialogs/AddEdit/AddEditDialog';
import DeleteDialog from './Dialogs/Delete/DeleteDialog';
import {
  Tariff,
  additionalColumnProperties,
  generateContextMenu,
  getColumnHeadersMap,
  mockData,
  tableStorageKey,
} from './Tariffs.functions';

function Tarrifs(): JSX.Element {
  const { t } = useTranslation();

  usePageTitle(t('Tariffs') + ' - ' + t('Tariffs'));

  const location = useLocation();
  const { id } = useParams<EntityIdRouteParams>();

  const [productType, setProductType] = useProductTypeState();

  const activeProductType = useMemo<string>(() => {
    const productTypes = getProductTypes(t);

    return productTypes.find((p) => p.value === productType)?.label ?? '';
  }, [productType, t]);

  const columnHeadersMap = useMemo(() => getColumnHeadersMap(t), [t]);

  const {
    page,
    limit,
    tableRef,
    sortField,
    sortOrder,
    selection,
    setPage,
    setLimit,
    setSortField,
    setSortOrder,
    setSelection,
  } = useTableState<Tariff>(tableStorageKey);

  const { columns, columnOptions, selectedColumns, setSelectedColumns } =
    useTableColumns(
      page,
      limit,
      'tariffs_tariffs',
      columnHeadersMap,
      columnHeadersMap,
      additionalColumnProperties
    );

  const {
    show: showAddDialog,
    hide: hideAddDialog,
    isVisible: isAddDialogVisible,
  } = useRouteDialog(RoutePaths.Tariffs, RoutePaths.AddTariff);

  const {
    show: showEditDialog,
    hide: hideEditDialog,
    isVisible: isEditDialogVisible,
  } = useRouteDialog(
    RoutePaths.Tariffs,
    constructIdRoute(RoutePaths.EditTariff, id ?? selection?.id)
  );

  const {
    show: showDeleteDialog,
    hide: hideDeleteDialog,
    isVisible: isDeleteDialogVisible,
  } = useRouteDialog(
    RoutePaths.Tariffs,
    constructIdRoute(RoutePaths.DeleteTariff, id ?? selection?.id)
  );

  return (
    <div>
      <GoBackLink
        to={'/tariffs/services' + location.search}
        page={t('Services')}
      />

      <h2 className="title">{t('Tariffs')}</h2>
      <p className="subtitle">{t('View and manage tariffs')}</p>

      <Grid>
        <SidePanels>
          <ProductType
            productType={productType}
            setProductType={setProductType}
          />

          <Actions
            isAddBtnDisabled={false} // !!!
            onAddBtnClick={showAddDialog}
            buttonLabel={
              t('Add') +
              ' ' +
              activeProductType.toLowerCase() +
              ' ' +
              t('tariff')
            }
          />
        </SidePanels>

        <MainContent>
          <Table
            tableRef={tableRef}
            lazy={false}
            columns={columns}
            data={mockData} // !!!
            isLoading={false} // !!!
            hasError={false} // !!!
            reload={noop} // !!!
            isReloadDisabled={false} // !!!
            setPage={setPage}
            setLimit={setLimit}
            sortField={sortField}
            rows={limit}
            setSortField={setSortField}
            setSortOrder={setSortOrder}
            setSelection={setSelection}
            sortOrder={sortOrder}
            selection={selection}
            storageString={tableStorageKey}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            columnOptions={columnOptions}
            headerTitle={activeProductType + ' ' + t('tariffs')}
            footer={t('Total') + ': ' + (mockData.length ?? 0)}
            contextMenuModel={generateContextMenu(
              t,
              true, // !!!
              showEditDialog,
              true, // !!!
              showDeleteDialog
            )}
            rebuildTooltip
          />
        </MainContent>
      </Grid>

      <AddEditDialog
        activeProductType={activeProductType}
        isShown={isAddDialogVisible || isEditDialogVisible}
        onHide={isEditDialogVisible ? hideEditDialog : hideAddDialog}
        isEditDialog={isEditDialogVisible}
      />

      <DeleteDialog isShown={isDeleteDialogVisible} onHide={hideDeleteDialog} />
    </div>
  );
}

export default Tarrifs;
