import { Field, useFormikContext } from 'formik';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-lodash-debounce';

import useAxiosHook from '../../../../../../hooks/useAxiosHook';
import {
  ClientLookupCollection,
  ClientLookupCollectionQueryParams,
} from '../../../../../../types/api/clients';
import { isStreetFreeInputAllowed } from '../../../../../../utils/constants/misc';
import { queryString } from '../../../../../../utils/helpers/http';
import { tryString } from '../../../../../../utils/helpers/parse';
import {
  formatMobileNumber,
  formatPhoneNumber,
  getPhoneorMobileNumberRegion,
} from '../../../../../../utils/helpers/phoneNumbers';
import AutoCompleteInput from '../../../../../Forms/AutoCompleteInput/AutoCompleteInput';
import FieldWithErrors from '../../../../../Forms/FieldWithErrors/FieldWithErrors';
import { FormValues, Street } from '../CreateEditDialog.functions';

type Props = {
  orderIndex: number;
  isConstant: boolean;
};

function OrdererTab({ orderIndex, isConstant }: Props) {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<FormValues>();

  const debouncedOrderer = useDebounce(values.klient_naracatel_ime, 300);

  const { data: orderersData } = useAxiosHook<ClientLookupCollection>(
    '/clients/lookup' +
      queryString<ClientLookupCollectionQueryParams>({
        ime: debouncedOrderer,
        page: 1,
        limit: 10,
      })
  );

  const clientsSuggestions =
    orderersData?.data.map((client) => {
      return {
        label: client.ime,
        value: String(client.id),
      };
    }) ?? [];

  const { data: streetsData } = useAxiosHook<Street[]>(
    {
      url: `places/${values.mesto_naracatel_id}/streets`,
    },
    {
      skipWhen: !values.mesto_naracatel_id,
    }
  );

  const streetOptions = streetsData?.map((street) => ({
    label: street.ime,
    value: String(street.id),
  }));

  const handleClientChange = useCallback(
    (ime: string) => {
      setFieldValue('klient_naracatel_ime', ime);
    },
    [setFieldValue]
  );

  const handleClientSelect = useCallback(
    (id: string | null) => {
      setFieldValue('klient_naracatel_id', id);

      const orderer = orderersData?.data.find(
        (orderer) => String(orderer.id) === id
      );

      setFieldValue('telefon_naracatel', orderer?.telefon ?? '');
      setFieldValue('mobilen_naracatel', orderer?.mobilen ?? '');

      setFieldValue('broj_naracatel', orderer?.broj ?? '');
      setFieldValue('vlez_naracatel', orderer?.vlez ?? '');
      setFieldValue('stan_naracatel', orderer?.stan ?? '');

      setFieldValue('mesto_naracatel_id', orderer?.mesto_id ?? '');
      setFieldValue('mesto_naracatel_ime', orderer?.mesto_ime ?? '');
      setFieldValue('ulica_naracatel_id', tryString(orderer?.ulica_id) ?? '');
      setFieldValue('adresa_naracatel', orderer?.adresa ?? '');
    },
    [orderersData?.data, setFieldValue]
  );

  return (
    <>
      {orderIndex !== 0 && isConstant && <div className="constant" />}

      <FieldWithErrors
        includeErrorsFor={['klient_naracatel_id']}
        name="klient_naracatel_ime"
        label={t('Search Orderer')}
      >
        <AutoCompleteInput
          placeholder={t('Search by name or phone number')}
          options={clientsSuggestions}
          value={tryString(values.klient_naracatel_id) ?? null}
          filterValue={values.klient_naracatel_ime ?? ''}
          onFilterChange={handleClientChange}
          onSelectionChange={handleClientSelect}
          filterDataCy="orderer"
          optionsClassName="data-cy-orderer-options"
        />
      </FieldWithErrors>

      <FieldWithErrors name="mesto_naracatel_ime" label={t('Place')}>
        <Field
          as={InputText}
          id="mesto_naracatel_ime"
          name="mesto_naracatel_ime"
          disabled
        />
      </FieldWithErrors>

      <FieldWithErrors
        name="ulica_naracatel_id"
        label={isStreetFreeInputAllowed ? t('Address') : t('Street')}
      >
        {isStreetFreeInputAllowed ? (
          <Field
            as={InputText}
            name="adresa_naracatel"
            id="adresa_naracatel"
            disabled
            maxLength="256"
          />
        ) : (
          <>
            <Field
              as={Dropdown}
              inputId="ulica_naracatel_id"
              name="ulica_naracatel_id"
              options={streetOptions}
              filter
              filterInputAutoFocus
              disabled
            />

            <FieldWithErrors name="broj_naracatel" label={false}>
              <div className="p-grid">
                <div className="p-col-4">
                  <Field
                    as={InputText}
                    id="broj_naracatel"
                    name="broj_naracatel"
                    placeholder={t('Street No.')}
                    disabled
                  />
                </div>

                <div className="p-col-4">
                  <Field
                    as={InputText}
                    id="vlez_naracatel"
                    name="vlez_naracatel"
                    placeholder={t('Entrance')}
                    disabled
                  />
                </div>

                <div className="p-col-4">
                  <Field
                    as={InputText}
                    id="stan_naracatel"
                    name="stan_naracatel"
                    placeholder={t('Apartment')}
                    disabled
                  />
                </div>
              </div>
            </FieldWithErrors>
          </>
        )}
      </FieldWithErrors>

      <FieldWithErrors name="mobilen_naracatel" label={t('Mobile Number')}>
        <Field
          as={InputText}
          value={formatMobileNumber(
            values.mobilen_naracatel ?? '',
            getPhoneorMobileNumberRegion(values.mobilen_naracatel)
          )}
          id="mobilen_naracatel"
          name="mobilen_naracatel"
          disabled
        />
      </FieldWithErrors>

      <FieldWithErrors name="telefon_naracatel" label={t('Telephone Number')}>
        <Field
          as={InputText}
          id="telefon_naracatel"
          name="telefon_naracatel"
          value={formatPhoneNumber(
            values.telefon_naracatel ?? '',
            getPhoneorMobileNumberRegion(values.telefon_naracatel)
          )}
          disabled
        />
      </FieldWithErrors>
    </>
  );
}

export default OrdererTab;
