import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import ToastContext from '../../../../../context/ToastContext';
import useAxiosHook from '../../../../../hooks/useAxiosHook';
import usePrevious from '../../../../../hooks/usePrevious';
import {
  errorToast,
  successToast,
} from '../../../../../utils/helpers/primereact';
import DialogSpinner from '../../../../Dialogs/DialogSpinner/DialogSpinner';
import { Vehicle } from '../../Vehicles.functions';

type Props = {
  visible: boolean;
  vehicle: Vehicle | null;
  onHide: () => void;
  tableReload: () => void;
};

function DeleteDialog({
  visible,
  vehicle,
  onHide,
  tableReload,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const { toastRef } = useContext(ToastContext);

  const {
    data: deleteData,
    error: deleteError,
    isLoading: isDeleteLoading,
    reload: deleteReload,
    response: deleteResponse,
  } = useAxiosHook<string>({}, { skipWhen: true });

  const prevDeleteError = usePrevious(deleteError);
  const prevDeleteResponse = usePrevious(deleteResponse);

  const { data: checkData, isLoading: isCheckLoading } = useAxiosHook<
    { name: string } | []
  >(`/vehicles/${vehicle?.id}/employee`, {
    skipWhen: !visible || !vehicle?.id,
  });

  const dialogFooter = (
    <>
      <Button
        label={t('Cancel')}
        className="p-button-secondary p-button-text"
        onClick={() => onHide()}
      />
      <Button
        label={isDeleteLoading ? t('Deleting...') : t('Delete')}
        disabled={isDeleteLoading}
        className="p-button-danger"
        onClick={() =>
          deleteReload({ url: `/vehicles/${vehicle?.id}`, method: 'DELETE' })
        }
        data-cy="confirm-delete"
      />
    </>
  );

  useEffect(() => {
    if (
      deleteData === undefined ||
      deleteResponse?.config.url === prevDeleteResponse?.config.url
    )
      return;

    successToast(
      toastRef,
      t('Success'),
      t('Vehicle {{regPlates}} was successfully deleted.', {
        regPlates: vehicle?.registarska_tablicka,
      })
    );

    tableReload();
    onHide();
  }, [
    deleteData,
    deleteResponse?.config.url,
    onHide,
    prevDeleteResponse?.config.url,
    t,
    tableReload,
    toastRef,
    vehicle?.registarska_tablicka,
  ]);

  useEffect(() => {
    if (!deleteError || deleteError === prevDeleteError || !toastRef) return;

    errorToast(
      toastRef,
      t('Error'),
      t('An error occured while deleting vehicle {{regPlates}}', {
        regPlates: vehicle?.registarska_tablicka,
      })
    );
    onHide();
  }, [
    deleteError,
    onHide,
    prevDeleteError,
    t,
    toastRef,
    vehicle?.registarska_tablicka,
  ]);

  return (
    <Dialog
      header={
        isCheckLoading
          ? t('Loading...')
          : t('Delete {{regPlates}}?', {
              regPlates: vehicle?.registarska_tablicka,
            })
      }
      footer={dialogFooter}
      onHide={onHide}
      visible={visible}
      style={{ width: 360, maxWidth: '100%' }}
    >
      {isCheckLoading ? (
        <DialogSpinner />
      ) : (
        <>
          {!Array.isArray(checkData) && (
            <p className="p-mt-0" style={{ color: '#d32f2f' }}>
              <span className="p-text-bold">{t('WARNING')}</span>:{' '}
              {t(
                'You are about to delete an assigned vehicle. This vehicle is assigned to {{employee}}.',
                {
                  employee: checkData?.name,
                }
              )}
            </p>
          )}

          <p>
            {t(
              'Are you sure you want to delete the vehicle with registration plates {{regPlates}}?',
              {
                regPlates: vehicle?.registarska_tablicka,
              }
            )}
            <br />
            {t('This action cannot be undone.')}
          </p>
        </>
      )}
    </Dialog>
  );
}

export default DeleteDialog;
