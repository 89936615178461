import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useAxiosHook, {
  UseAxiosReturn,
} from '../../../../../../../../hooks/useAxiosHook';
import useTableColumns from '../../../../../../../../hooks/useTableColumns';
import useTableState from '../../../../../../../../hooks/useTableState';
import { InvoiceCandidatesShipmentCollection } from '../../../../../../../../types/api/invoices';
import { Unpacked } from '../../../../../../../../types/util';
import Table from '../../../../../../../DataTable/Table/Table';
import ViewActiveOrderDialog from '../../../../../../Orders/Dialogs/View/ViewActiveOrderDialog';
import { SingleOrder } from '../../../../../../Orders/Orders.functions';
import {
  additionalColumnOptions,
  generateContextMenu,
  getColumnHeadersMap,
  tableStorageKey,
} from './Shipments.functions';
import { Column } from './Shipments.types';

type Props = {
  request: UseAxiosReturn<InvoiceCandidatesShipmentCollection>;
};

function Shipments({ request }: Props): JSX.Element {
  const { t } = useTranslation();

  const [isViewOrderDialogVisible, setIsViewOrderDialogVisible] =
    useState(false);

  const columnHeadersMap = useMemo(() => getColumnHeadersMap(t), [t]);

  const {
    tableRef,
    page,
    setPage,
    limit,
    setLimit,
    sortField,
    sortOrder,
    selection,
    setSortField,
    setSortOrder,
    setSelection,
  } =
    useTableState<Unpacked<InvoiceCandidatesShipmentCollection>>(
      tableStorageKey
    );

  const { columns, columnOptions, selectedColumns, setSelectedColumns } =
    useTableColumns<Column>(
      page,
      limit,
      'invoicesShipments',
      columnHeadersMap,
      columnHeadersMap,
      useMemo(() => additionalColumnOptions(t), [t])
    );

  const { data, error, isLoading, reload } = request;

  const { data: orderData, isLoading: isOrderDataLoading } =
    useAxiosHook<SingleOrder>(
      {
        url: `/orders/${selection?.adresnica_id}`,
      },
      {
        skipWhen: !selection?.adresnica_id || !isViewOrderDialogVisible,
      }
    );

  const headerTitle = t('Shipments');

  const contextMenuModel = useMemo(
    () => generateContextMenu(t, () => setIsViewOrderDialogVisible(true)),
    [t]
  );

  const paginatorLeft = (
    <b>
      {t('Total Amount')}: {request.data?.summary?.total_amount ?? '-'}
    </b>
  );

  return (
    <>
      <ViewActiveOrderDialog
        data={orderData}
        isLoading={isOrderDataLoading}
        onHide={() => setIsViewOrderDialogVisible(false)}
        visible={isViewOrderDialogVisible}
      />

      <Table
        tableRef={tableRef}
        columnOptions={columnOptions}
        columns={columns}
        data={data}
        hasError={!!error}
        headerTitle={headerTitle}
        isLoading={isLoading}
        rebuildTooltip
        reload={reload}
        rows={limit}
        selectedColumns={selectedColumns}
        selection={selection}
        setLimit={setLimit}
        setPage={setPage}
        setSelectedColumns={setSelectedColumns}
        setSelection={setSelection}
        setSortField={setSortField}
        setSortOrder={setSortOrder}
        sortField={sortField}
        sortOrder={sortOrder}
        storageString={tableStorageKey}
        contextMenuModel={contextMenuModel}
        onRowDoubleClick={() => setIsViewOrderDialogVisible(true)}
        paginatorLeft={paginatorLeft}
      />
    </>
  );
}

export default Shipments;
