import {
  ConfigCollection,
  ConfigIdent,
  ConfigSection,
} from '../../../types/api/configs';
import { Unpacked } from '../../../types/util';

export function findSingleConfig(
  configs: ConfigCollection | undefined,
  section: ConfigSection,
  ident: ConfigIdent
): Unpacked<ConfigCollection> | undefined {
  return configs?.data.find((c) => c.section === section && c.ident === ident);
}

export function getBoolConfigValue(
  config: Unpacked<ConfigCollection>
): boolean {
  return config.value === true || config.value === 'true';
}
