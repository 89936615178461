import { useTranslation } from 'react-i18next';

import { currencyFormat } from '../../../../../../../utils/helpers/formatting';
import SidebarItem from '../../../../../../Grid/Sidebar/SidebarItem';
import SidebarContentDivider from '../../../../../../Sidebar/ContentDivider/SidebarContentDivider';

type Props = {
  currentReconciledDelivered: number;
  totalReconciledDelivered: number;
  currentReconciledPickedUp: number;
  totalReconciledPickedUp: number;
  currentTotalCashRedemption: number;
  totalTotalCashRedemption: number;
  currentTotalCashDelivered: number;
  totalTotalCashDelivered: number;
  currentTotalCashPickedUp: number;
  totalTotalCashPickedUp: number;
  totalCash: number;
  currentReconciledCash: number;
  totalReconciledCash: number;
  totalFiscalizedAmount: number;
};

function Stats({
  currentReconciledDelivered,
  totalReconciledDelivered,
  currentReconciledPickedUp,
  totalReconciledPickedUp,
  currentTotalCashRedemption,
  totalTotalCashRedemption,
  currentTotalCashDelivered,
  totalTotalCashDelivered,
  currentTotalCashPickedUp,
  totalTotalCashPickedUp,
  totalCash,
  currentReconciledCash,
  totalReconciledCash,
  totalFiscalizedAmount,
}: Props) {
  const { t } = useTranslation();

  return (
    <SidebarItem template="stats">
      <p>
        - {t('Reconciled delivered')}:{' '}
        <b>
          {currentReconciledDelivered} / {totalReconciledDelivered}
        </b>
      </p>

      <p>
        - {t('Reconciled picked up')}:{' '}
        <b>
          {currentReconciledPickedUp} / {totalReconciledPickedUp}
        </b>
      </p>

      <p>
        - {t('Total cash from redemption')}:{' '}
        <b>
          {currencyFormat(currentTotalCashRedemption, { showCurrency: true })} /{' '}
          {currencyFormat(totalTotalCashRedemption, { showCurrency: true })}
        </b>
      </p>

      <p>
        - {t('Total cash from delivered')}:{' '}
        <b>
          {currencyFormat(currentTotalCashDelivered, { showCurrency: true })} /{' '}
          {currencyFormat(totalTotalCashDelivered, { showCurrency: true })}
        </b>
      </p>

      <p>
        - {t('Total cash from picked up')}:{' '}
        <b>
          {currencyFormat(currentTotalCashPickedUp, { showCurrency: true })} /{' '}
          {currencyFormat(totalTotalCashPickedUp, { showCurrency: true })}
        </b>
      </p>

      <p>
        - {t('Total reconciled cash')}:{' '}
        <b>
          {currencyFormat(currentReconciledCash, { showCurrency: true })} /{' '}
          {currencyFormat(totalReconciledCash, { showCurrency: true })}
        </b>
      </p>

      <SidebarContentDivider />

      <p className="p-mt-0 p-text-secondary p-text-bold">{t('Earnings')}</p>

      <p>
        - {t('Total cash')}:{' '}
        <b>{currencyFormat(totalCash, { showCurrency: true })}</b>
      </p>

      <p>
        - {t('Total fiscalized amount')}:{' '}
        <b>{currencyFormat(totalFiscalizedAmount, { showCurrency: true })}</b>
      </p>
    </SidebarItem>
  );
}

export default Stats;
