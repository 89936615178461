import { Dropdown } from 'primereact/dropdown';
import { useContext, useEffect, useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-lodash-debounce';

import useAxiosHook from '../../../../../../hooks/useAxiosHook';
import {
  ClientImportListTypeCollection,
  ClientLookupCollection,
  ClientLookupCollectionQueryParams,
} from '../../../../../../types/api/clients';
import { LabelValue } from '../../../../../../types/options';
import { debounceTimeout } from '../../../../../../utils/constants/misc';
import { queryString } from '../../../../../../utils/helpers/http';
import { tryString } from '../../../../../../utils/helpers/parse';
import Step from '../../../../../Dialogs/Stepper/Step';
import StepperDialogContext from '../../../../../Dialogs/Stepper/StepperDialogContext';
import AutoCompleteInput from '../../../../../Forms/AutoCompleteInput/AutoCompleteInput';
import FieldWithErrors from '../../../../../Forms/ReactHookForm/FieldWithErrors';
import { ClientOption, FormValues } from '../WebImportDialog.types';

type Props = {
  isListTypeLoading: boolean;
  isClientAutoCompleteDisabled?: boolean;
};

function ClientAndListType({
  isListTypeLoading,
  isClientAutoCompleteDisabled,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const { setIsLoading } = useContext(StepperDialogContext);

  const { setValue } = useFormContext<FormValues>();

  const _clientFilter = useWatch<FormValues, '_clientFilter'>({
    name: '_clientFilter',
  });

  const _clientFilterObj = useWatch<FormValues, '_clientFilterObj'>({
    name: '_clientFilterObj',
  });

  useEffect(() => {
    setValue('place', tryString(_clientFilterObj?.mesto_id) ?? '');
    setValue('street', tryString(_clientFilterObj?.ulica_id) ?? '');
    setValue('adresa', _clientFilterObj?.adresa ?? '');
    setValue('broj', _clientFilterObj?.broj ?? '');
    setValue('vlez', _clientFilterObj?.vlez ?? '');
    setValue('stan', _clientFilterObj?.stan ?? '');
  }, [
    _clientFilterObj?.broj,
    _clientFilterObj?.mesto_id,
    _clientFilterObj?.stan,
    _clientFilterObj?.ulica_id,
    _clientFilterObj?.adresa,
    _clientFilterObj?.vlez,
    setValue,
  ]);

  const debouncedClientFilter = useDebounce(_clientFilter, debounceTimeout);

  const { data: clientsData, isLoading: isClientsDataLoading } =
    useAxiosHook<ClientLookupCollection>(
      '/clients/lookup' +
        queryString<ClientLookupCollectionQueryParams>({
          ime: debouncedClientFilter,
          page: 1,
          limit: 20,
        })
    );

  const { data: clientListTypesData, isLoading: isClientListTypesLoading } =
    useAxiosHook<ClientImportListTypeCollection>(
      `/clients/${_clientFilterObj?.id}/importlists/types`,
      {
        skipWhen: !_clientFilterObj?.id,
      }
    );

  useEffect(() => {
    setIsLoading!(isClientsDataLoading || isClientListTypesLoading);
  }, [isClientListTypesLoading, isClientsDataLoading, setIsLoading]);

  const clientOptions = useMemo<LabelValue<ClientOption>[]>(
    () =>
      clientsData?.data?.map((client) => {
        return {
          label: client.ime,
          value: client,
        };
      }) ?? [],
    [clientsData?.data]
  );

  const clientListTypeOptions = useMemo(
    () =>
      clientListTypesData?.map((listType) => {
        return {
          label: listType.name,
          value: listType,
        };
      }) ?? [],
    [clientListTypesData]
  );

  return (
    <Step title={t('Client')} subtitle={t('Which client owns the order?')}>
      <FieldWithErrors name="_clientFilterObj" label={t('Client')}>
        <AutoCompleteInput
          name="_clientFilterObj"
          id="_clientFilterObj"
          options={clientOptions}
          disabled={isClientAutoCompleteDisabled}
          filterValue={_clientFilter}
          onFilterChange={(e) => setValue('_clientFilter', e)}
          value={_clientFilterObj}
          onSelectionChange={(e) => setValue('_clientFilterObj', e)}
          filterDataCy="client"
          optionsClassName="data-cy-client-options"
        />
      </FieldWithErrors>

      <FieldWithErrors name="_clientListType" label={t('Batch Order Template')}>
        <Controller
          name="_clientListType"
          render={({ field }) => (
            <Dropdown
              name="_clientListType"
              inputId="_clientListType"
              options={clientListTypeOptions}
              filter
              disabled={
                !_clientFilterObj ||
                isClientsDataLoading ||
                isListTypeLoading ||
                (clientListTypeOptions?.length === 0 &&
                  !isClientListTypesLoading)
              }
              placeholder={
                !_clientFilterObj
                  ? t('Select a client first')
                  : isClientsDataLoading
                  ? 'Loading...'
                  : clientListTypeOptions?.length === 0 &&
                    !isClientListTypesLoading
                  ? t('This client has no list types assigned yet')
                  : undefined
              }
              value={field.value}
              onChange={(e) => field.onChange(e.value)}
              className="data-cy-listtype"
            />
          )}
        />
      </FieldWithErrors>
    </Step>
  );
}

export default ClientAndListType;
