import { Field, useFormikContext } from 'formik';
import { Checkbox } from 'primereact/checkbox';
import { InputNumber } from 'primereact/inputnumber';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ReduxState } from '../../../../../../types/redux';
import FieldWithErrors from '../../../../../Forms/FieldWithErrors/FieldWithErrors';
import { PayerEnum } from '../Calculator.functions';
import { FormValues } from '../CreateEditDialog.functions';

type Props = {
  isLoading: boolean;
};

function PaymentsTab({ isLoading = false }: Props) {
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const { t } = useTranslation();

  const user = useSelector<ReduxState, ReduxState['user']>(
    (state) => state.user
  );

  // This constant is using magic values. Replace 1 and 5 with values from enum (currently doesn't exist)
  // 1 = administrator, 5 = call center
  const isPriceCorrectionAllowed = user.employee?.data.ulogi.findIndex(
    (role) => Number(role.id) === 1 || Number(role.id) === 5
  );

  return (
    <FieldWithErrors
      name="payments"
      label={false}
      className="p-mb-0"
      childrenWrapperClassName="p-mb-2"
    >
      <div className="payments-tab" data-cy="payments">
        {values?.payments && values?.payments?.length !== 0 && (
          <table className="inner-table" cellSpacing="0">
            <thead>
              <tr>
                <th>{t('Service')}</th>
                <th>{t('Price')}</th>
                <th>{t('Sender')}</th>
                <th>{t('Recipient')}</th>
                {values.orderer === 3 && <th>{t('Orderer')}</th>}
                <th>{t('Cash')}</th>
                <th>{t('Invoice')}</th>
              </tr>
            </thead>
            <tbody>
              {values?.payments?.map((row, index) => (
                <tr key={row.service_id}>
                  <td>{row.description}</td>
                  <td>
                    <InputNumber
                      disabled={isPriceCorrectionAllowed === -1}
                      value={row.editedPrice}
                      onChange={(e) =>
                        setFieldValue(`payments[${index}].editedPrice`, e.value)
                      }
                    />
                  </td>
                  <td>
                    <Field
                      as={Checkbox}
                      checked={
                        (values.klient_od_id !== undefined &&
                          String(row.customer_id) ===
                            String(values.klient_od_id)) ||
                        (values.no_sender &&
                          row.customer_id === PayerEnum.Sender)
                      }
                      onChange={() => {
                        setFieldValue(
                          `payments[${index}].customer_id`,
                          values.no_sender
                            ? PayerEnum.Sender
                            : values.klient_od_id
                        );
                      }}
                    />
                  </td>

                  <td>
                    <Field
                      as={Checkbox}
                      checked={
                        (values.klient_do_id !== undefined &&
                          String(row.customer_id) ===
                            String(values.klient_do_id)) ||
                        (values.no_recipient &&
                          row.customer_id === PayerEnum.Recipient)
                      }
                      onChange={() => {
                        setFieldValue(
                          `payments[${index}].customer_id`,
                          values.no_recipient
                            ? PayerEnum.Recipient
                            : values.klient_do_id
                        );
                      }}
                    />
                  </td>

                  {values.orderer === 3 && (
                    <td>
                      <Field
                        as={Checkbox}
                        checked={
                          values.klient_naracatel_id !== undefined &&
                          String(row.customer_id) ===
                            String(values.klient_naracatel_id)
                        }
                        disabled={
                          values.klient_naracatel_id === PayerEnum.Orderer
                        }
                        onChange={() =>
                          setFieldValue(
                            `payments[${index}].customer_id`,
                            values.klient_naracatel_id
                          )
                        }
                      />
                    </td>
                  )}

                  <td>
                    <Field
                      as={Checkbox}
                      checked={row.cash === 1}
                      onChange={() =>
                        setFieldValue(`payments[${index}].cash`, 1)
                      }
                    />
                  </td>

                  <td>
                    <Field
                      as={Checkbox}
                      checked={row.cash === 0}
                      disabled={
                        values.payments?.[index].customer_id ===
                          PayerEnum.Sender ||
                        values.payments?.[index].customer_id ===
                          PayerEnum.Recipient
                      }
                      onChange={() => {
                        setFieldValue(`payments[${index}].cash`, 0);
                      }}
                    />
                  </td>
                </tr>
              ))}
              {isLoading && (
                <tr>
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td>
                    <ProgressSpinner
                      strokeWidth="4"
                      className="calculator-spinner"
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}

        {(!values.payments || values?.payments?.length === 0) &&
          t('Not enough input to calculate.')}
      </div>
    </FieldWithErrors>
  );
}

export default PaymentsTab;
