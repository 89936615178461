import Axios from 'axios';
import _ from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';

import api from '../utils/api';
import useIsMounted from './useIsMounted';
import usePrevious from './usePrevious';

const defaultOptions = {
  url: '',
  method: 'GET',
  skipWhen: false,
};

function useAxios(url, payload = {}, config = {}) {
  const options = {
    ...defaultOptions,
    ...config,
  };

  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const cancelToken = useRef(Axios.CancelToken.source());

  const prevUrl = usePrevious(url);
  const prevPayload = usePrevious(payload);
  const prevSkipWhen = usePrevious(options.skipWhen);

  const isMounted = useIsMounted();

  const sendRequest = useCallback(
    (newParams = {}) => {
      const config = {
        cancelToken: cancelToken.current.token,
        ...(newParams.config || options.config),
      };

      // In Axios, different types of requests accept different parameters
      let params = {
        method: (newParams.method || options.method).toLowerCase(),
      };

      switch ((newParams.method || options.method).toUpperCase()) {
        case 'GET':
        case 'DELETE':
        case 'HEAD':
        case 'OPTIONS':
          params = { ...params, ...config, url: newParams?.url || url };
          break;

        case 'POST':
        case 'PUT':
        case 'PATCH':
          params = {
            ...params,
            ...config,
            url: newParams?.url || url,
            data: newParams?.payload || payload,
          };
          break;

        // REQUEST, GETURI
        default:
          params.push(...config);
      }

      setIsLoading(true);

      api(params)
        .then((res) => {
          if (!res) {
            return;
          }

          setData(res.data);
          setHasError(false);
          setErrorMessage(null);

          const successCallback =
            newParams.successCallback || options.successCallback;

          if (successCallback && typeof successCallback === 'function') {
            successCallback(res.data);
          }
        })
        .catch((err) => {
          if (Axios.isCancel(err)) {
            return;
          }

          setHasError(true);
          setErrorMessage(err);

          const errorCallback =
            newParams.errorCallback || options.errorCallback;

          if (errorCallback && typeof errorCallback === 'function') {
            errorCallback(err);
          }
        })
        .finally(() => {
          if (!isMounted.current) {
            return;
          }

          const finallyCallback =
            newParams.finallyCallback || options.finallyCallback;

          if (finallyCallback && typeof finallyCallback === 'function') {
            finallyCallback();
          }

          setIsLoading(false);
        });
    },
    [
      isMounted,
      options.config,
      options.errorCallback,
      options.finallyCallback,
      options.method,
      options.successCallback,
      payload,
      url,
    ]
  );

  useEffect(() => {
    if (
      !url ||
      options.skipWhen ||
      (url === prevUrl &&
        _.isEqual(payload, prevPayload) &&
        options.skipWhen === prevSkipWhen)
    ) {
      return;
    }

    sendRequest();
  }, [
    options.skipWhen,
    payload,
    prevPayload,
    prevSkipWhen,
    prevUrl,
    sendRequest,
    url,
  ]);

  // The refs don't point to any DOM node,
  //  don't worry about the warning below
  useEffect(() => {
    return () => {
      cancelToken.current.cancel(); // eslint-disable-line react-hooks/exhaustive-deps
    };
  }, []);

  return { data, isLoading, hasError, errorMessage, reload: sendRequest };
}

export default useAxios;
