import * as Sentry from '@sentry/react';
import { AxiosResponse } from 'axios';
import { Middleware, Store } from 'redux';

import { EmployeeResource } from '../../types/api/employees';
import { ReduxState } from '../../types/redux';
import api from '../../utils/api';
import { deleteCookie, setCookie } from '../../utils/helpers/cookies';
import {
  LOGIN_USER,
  LOGOUT_USER,
  REFRESH_AUTH_TOKEN,
  UPDATE_EMPLOYEE_RESOURCE,
  loginUser,
  updateEmployeeResource,
} from '../actions/userActions';

function persistUser(store: Store<ReduxState>) {
  try {
    localStorage.setItem('user', JSON.stringify(store.getState().user));
  } catch (e) {
    Sentry.captureException(e);
  }
}

function loadEmployeeResource(store: Store<ReduxState>) {
  try {
    api.get('/employees/me').then((res: AxiosResponse<EmployeeResource>) => {
      store.dispatch(updateEmployeeResource(res.data));
    });
  } catch (e) {
    Sentry.captureException(e);
  }
}

const userMiddleware: Middleware = (store) => (next) => (action) => {
  next(action);

  switch (action.type) {
    case LOGIN_USER:
      persistUser(store as Store<ReduxState>);
      loadEmployeeResource(store as Store<ReduxState>);

      if (!(action as ReturnType<typeof loginUser>).rememberMe) {
        setCookie(
          'employeeSessionKey',
          String((action as ReturnType<typeof loginUser>).timestamp)
        );
      }
      break;

    case REFRESH_AUTH_TOKEN:
      persistUser(store as Store<ReduxState>);
      loadEmployeeResource(store as Store<ReduxState>);
      break;

    case LOGOUT_USER:
      try {
        sessionStorage.clear();
        localStorage.removeItem('user');
        deleteCookie('employeeSessionKey');
      } catch (e) {
        Sentry.captureException(e);
      }
      break;

    case UPDATE_EMPLOYEE_RESOURCE:
      persistUser(store as Store<ReduxState>);
      break;

    default:
      return;
  }
};

export default userMiddleware;
