import { faChartPie } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './StatsWrapper.module.scss';

const StatsWrapper: React.FC<ReactNode> = ({ children }) => {
  const { t } = useTranslation();
  return (
    <div className={classNames(styles.stats)}>
      <h3 className={classNames(styles.statsTitle)}>
        <FontAwesomeIcon icon={faChartPie} />
        {t('Stats')}
      </h3>
      <div className={classNames(styles.statsBody)}>{children}</div>
    </div>
  );
};

export default StatsWrapper;
