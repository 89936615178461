import { Field, useFormikContext } from 'formik';
import { InputText } from 'primereact/inputtext';
import { useTranslation } from 'react-i18next';

import FieldWithErrors from '../../../../../../Forms/FieldWithErrors/FieldWithErrors';

function BankAccountField() {
  const { t } = useTranslation();

  const { setFieldValue } = useFormikContext();

  return (
    <FieldWithErrors name="smetka" label={t('Bank account')}>
      <Field
        as={InputText}
        name="smetka"
        id="smetka"
        maxLength="15"
        onChange={(e) => {
          setFieldValue('smetka', e.target.value.replace(/\D/g, ''));
        }}
        data-cy="bank_account"
      />
    </FieldWithErrors>
  );
}

export default BankAccountField;
