import { AxiosRequestConfig } from 'axios';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { MutliSelectAutoCompleteOption } from '../features/Forms/MultiSelectAutoCompleteInput/MultiSelectAutoCompleteInput.functions';
import { getSearchQueryParam } from '../utils/helpers/searchQuery';
import useAxiosHook from './useAxiosHook';
import useSearchQueryParam from './useSearchQueryParam';

// axiosRequestConfig - undefined is expected only in the case of bad URL query params being present;
// in which case we don't send any request
function useSearchQueryMultiSelectAutoCompleteInputParam(
  param: string,
  selection: MutliSelectAutoCompleteOption['value'][],
  setSelection: Dispatch<
    SetStateAction<MutliSelectAutoCompleteOption['value'][]>
  >,
  setAllSelectedOptions: Dispatch<
    SetStateAction<MutliSelectAutoCompleteOption[]>
  >,
  axiosRequestConfig: (
    value: string[]
  ) => AxiosRequestConfig | string | undefined,
  initialDataModifier: (data: any) => MutliSelectAutoCompleteOption[]
): void {
  const location = useLocation();

  const [initialFilterValue] = useState(
    () => getSearchQueryParam(location.search, param)?.split(',') ?? []
  );

  const { data: initialData, error: initialError } = useAxiosHook(
    axiosRequestConfig ? axiosRequestConfig(initialFilterValue) ?? '' : '',
    {
      skipWhen:
        !initialFilterValue.length ||
        !axiosRequestConfig ||
        !initialDataModifier,
    }
  );

  useEffect(() => {
    if (!initialFilterValue || !initialData || !initialDataModifier) {
      return;
    }

    const dataFromModifier = initialDataModifier(initialData);

    setAllSelectedOptions(dataFromModifier);
    setSelection(dataFromModifier.map((d) => d.value));
  }, [
    initialData,
    initialDataModifier,
    initialFilterValue,
    setAllSelectedOptions,
    setSelection,
  ]);

  useEffect(() => {
    if (!initialError) {
      return;
    }

    setSelection([]);
  }, [initialError, setSelection]);

  useSearchQueryParam(
    param,
    selection
      ? selection.join(',')
      : initialError
      ? ''
      : initialFilterValue.join(',')
  );
}

export default useSearchQueryMultiSelectAutoCompleteInputParam;
