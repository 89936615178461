import * as Sentry from '@sentry/react';

import {
  getNotificationChannel,
  getNotificationType,
} from '../../../utils/constants/entities/notifications';

function generateNotificationsObject(t, notificationStr) {
  const notificationType = getNotificationType(t);
  const notificationChannel = getNotificationChannel(t);

  let notifications = {};

  // Initialize notification object
  for (let nType of notificationType) {
    notifications[nType.id] = {};

    for (let nChannel of notificationChannel) {
      notifications[nType.id][nChannel.id] = false;
    }
  }

  // Fill notification object
  if (notificationStr?.length > 0) {
    let parsedNotification = [];

    try {
      parsedNotification = JSON.parse(notificationStr);
    } catch (e) {
      Sentry.captureException(e, {
        extra: {
          data: notificationStr,
        },
      });
    }

    for (let pN of parsedNotification) {
      if (notifications[pN.order_notification_type]) {
        notifications[pN.order_notification_type][
          pN.order_notification_channel
        ] = true;
      }
    }
  }

  return notifications;
}

export function getInitialValues(t, settings) {
  let initialValues = {
    'Adresnica-KontrolaSeriskiBroj': false,
    'Adresnica-PrikaziNovaFormaPosleKreiranje': false,
    'Adresnica-DefaultFreeInputForTheReceiver': false,
    'Company-Address': '',
    'Company-Telephone': '',
    'Company-CEO': '',
    'Company-Name': '',
    'Company-Email': '',
    'Company-VatNumber': '',
    'Company-PersonalNumber': '',
    'Company-BankAccount': '',
    'Company-EmailComplaints': '',
    'Company-Phone': '',
    'Company-Phone2': '',
    'Config-NpoKlientId': 0,
    'Config-FakturaKlientId': 0,
    'Config-NvpKlientId': 0,
    'Config-NppKlientId': 0,
    'Config-PovratenDokumentKlientId': 0,
    'Customer-DefaultPricelistId': 1,
    'Customer-RequiredEmail': false,
    'Customer-RequiredMobileOrPhone': false,
    'Customer-RequiredBankAccount': false,
    'Customer-RequiredEntityNumber': false,
    'Customer-RequiredTaxNumber': false,
    'Customer-RequiredHouseNumber': false,
    'Mail-Notifications': '',
    'Mail-RegistrationAdminApprove': '',
    'Notification-Subscriptions': '[]',
    'SMS-DefaultGateway': '',
    'SMS-NotificationsNumber': '',
    'Web-SaveImportListContent': false,
    'Input-Validation': '[]',
  };

  for (let s in settings?.data) {
    const item = settings.data[s];
    initialValues[`${item.section}-${item.ident}`] = item.value;
  }

  initialValues['Notification-Subscriptions'] = generateNotificationsObject(
    t,
    initialValues['Notification-Subscriptions']
  );

  return initialValues;
}

export function toApiData(initialData, values, section) {
  let apiData = {};

  for (const obj of initialData.data) {
    const id = initialData.data.find(
      (o) => o.section === section && o.ident === obj.ident
    )?.id;

    // Something unexpected happened
    if (!id) {
      continue;
    }

    if (obj.section === section) {
      if (obj.section === 'Notification' && obj.ident === 'Subscriptions') {
        let notification = [];

        for (let nType in values[`${section}-${obj.ident}`]) {
          for (let nChannel in values[`${section}-${obj.ident}`][nType]) {
            if (values[`${section}-${obj.ident}`][nType][nChannel]) {
              notification.push({
                order_notification_type: nType,
                order_notification_channel: nChannel,
              });
            }
          }
        }

        try {
          apiData[id] = JSON.stringify(notification);
        } catch (e) {
          Sentry.captureException(e, {
            extra: {
              data: notification,
            },
          });
        }
      } else {
        apiData[id] = values[`${section}-${obj.ident}`];
      }
    }
  }

  return apiData;
}
