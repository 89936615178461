import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';

import DialogSpinner from '../../../../Dialogs/DialogSpinner/DialogSpinner';
import { OAuthClient } from '../../OAuthClients.functions';

type Props = {
  visible: boolean;
  data: OAuthClient | undefined;
  isLoading: boolean;
  onHide: () => void;
};

function ViewDialog({ visible, data, isLoading, onHide }: Props): JSX.Element {
  const { t } = useTranslation();

  const dialogFooter = (
    <Button
      type="button"
      label={t('Close')}
      onClick={() => onHide()}
      className="p-button-secondary p-button-text"
      data-cy="close"
    />
  );

  return (
    <Dialog
      header={isLoading ? t('Loading...') : data?.client_name}
      footer={dialogFooter}
      visible={visible}
      resizable={false}
      onHide={onHide}
      style={{ width: 360, maxWidth: '100%' }}
    >
      {isLoading ? (
        <DialogSpinner />
      ) : (
        <>
          <p>
            <b>{t('Name')}</b>
            <br />
            {data?.client_name}
          </p>

          <p>
            <b>{t('Client ID')}</b>
            <br />
            {data?.client_id}
          </p>

          <p>
            <b>{t('Client Secret')}</b>
            <br />
            {data?.client_secret}
          </p>

          <p>
            <b>{t('Type')}</b>
            <br />
            {data?.type}
          </p>

          <p>
            <b>{t('Created on')}</b>
            <br />
            {data?.created_at}
          </p>

          <p>
            <b>{t('Created by')}</b>
            <br />
            {data?.created_by_full_name}
          </p>

          <p>
            <b>{t('Last modified on')}</b>
            <br />
            {data?.modified_at}
          </p>

          <p>
            <b>{t('Last modified by')}</b>
            <br />
            {data?.modified_by_full_name}
          </p>
        </>
      )}
    </Dialog>
  );
}

export default ViewDialog;
