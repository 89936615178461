import { Button } from 'primereact/button';

export function getOperatorButtons(
  value: string,
  setValue: (newValue: string) => void,
  operators: { id: number; label: string; value: string; title?: string }[]
) {
  return operators.map((o) => (
    <Button
      key={o.id}
      type="button"
      label={o.label}
      title={o.title}
      onClick={() => setValue(value + ' ' + o.value)}
      className="p-button-secondary p-button-text p-button-sm"
    />
  ));
}
