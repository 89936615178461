import { Field, useFormikContext } from 'formik';
import { Checkbox, CheckboxChangeParams } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { useTranslation } from 'react-i18next';

import FieldWithErrors from '../../../../../../Forms/FieldWithErrors/FieldWithErrors';
import { FormFields } from '../AddEditDialog.functions';

function GeneralTab(): JSX.Element {
  const { t } = useTranslation();

  const { values, setFieldValue } = useFormikContext<FormFields>();

  return (
    <div>
      <div className="p-fluid">
        <FieldWithErrors name="ime" label={t('Name')}>
          <Field as={InputText} id="ime" name="ime" />
        </FieldWithErrors>

        <FieldWithErrors name="kod" label={t('Display name')}>
          <Field as={InputText} id="kod" name="kod" />
        </FieldWithErrors>

        <FieldWithErrors name="opis" label={t('Description')}>
          <Field
            as={InputTextarea}
            id="opis"
            name="opis"
            autoResize
            style={{ maxHeight: 150 }}
          />
        </FieldWithErrors>

        <FieldWithErrors name="aktivna" label={false}>
          <Field
            as={Checkbox}
            name="aktivna"
            inputId="aktivna"
            checked={values.aktivna === '1'}
            onChange={(e: CheckboxChangeParams) =>
              setFieldValue('aktivna', e.checked ? '1' : '0')
            }
          />

          <label htmlFor="aktivna" className="p-checkbox-label">
            {t('Aktivna')}
          </label>
        </FieldWithErrors>

        <FieldWithErrors name="nsot" label={false}>
          <Field
            as={Checkbox}
            name="nsot"
            inputId="nsot"
            checked={values.nsot === '1'}
            onChange={(e: CheckboxChangeParams) =>
              setFieldValue('nsot', e.checked ? '1' : '0')
            }
          />

          <label htmlFor="nsot" className="p-checkbox-label">
            {t('Naplati ja samo ovaa tarifa')}
          </label>
        </FieldWithErrors>

        <FieldWithErrors name="ndot" label={false}>
          <Field
            as={Checkbox}
            name="ndot"
            inputId="ndot"
            checked={values.ndot === '1'}
            onChange={(e: CheckboxChangeParams) =>
              setFieldValue('ndot', e.checked ? '1' : '0')
            }
          />

          <label htmlFor="ndot" className="p-checkbox-label">
            {t('Naplati do ovaa tarifa')}
          </label>
        </FieldWithErrors>

        <FieldWithErrors name="nmxt" label={false}>
          <Field
            as={Checkbox}
            name="nmxt"
            inputId="nmxt"
            checked={values.nmxt === '1'}
            onChange={(e: CheckboxChangeParams) =>
              setFieldValue('nmxt', e.checked ? '1' : '0')
            }
          />

          <label htmlFor="nmxt" className="p-checkbox-label">
            {t('Naplati maksimalna tarifa')}
          </label>
        </FieldWithErrors>
      </div>
    </div>
  );
}

export default GeneralTab;
