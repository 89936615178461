import { faBook } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'primereact/button';
import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import ToastContext from '../../../context/ToastContext';
import { FileTypes } from '../../../enums/files';
import useAxiosHook from '../../../hooks/useAxiosHook';
import { useEndpointGuard } from '../../../hooks/useEndpointGuard';
import usePageTitle from '../../../hooks/usePageTitle';
import useRouteDialog from '../../../hooks/useRouteDialog';
import useTableColumns from '../../../hooks/useTableColumns';
import useTableState from '../../../hooks/useTableState';
import useToastMessage from '../../../hooks/useToastMessage';
import {
  PriceListCollection,
  PriceListParentsCollection,
} from '../../../types/api/priceLists';
import { LabelValue } from '../../../types/options';
import { EntityIdRouteParams } from '../../../types/routing';
import {
  createGuard,
  deleteGuard,
  editGuard,
} from '../../../utils/constants/auth/priceLists';
import { routeGuard as pricesPageGuard } from '../../../utils/constants/auth/prices';
import {
  RoutePaths,
  constructIdRoute,
} from '../../../utils/constants/routePaths';
import { downloadFile, getFileName } from '../../../utils/helpers/files';
import { sequential } from '../../../utils/helpers/functions';
import { queryString } from '../../../utils/helpers/http';
import Table from '../../DataTable/Table/Table';
import Flex from '../../layout/flex/Flex';
import MainContent from '../../layout/flex/MainContent';
import Filters from '../Components/Filters/Filters';
import HeaderPages from '../Components/HeaderPages/HeaderPages';
import CreateEditDialog from './Dialogs/CreateEdit/CreateEditDialog';
import DeleteDialog from './Dialogs/Delete/DeleteDialog';
import {
  Column,
  Row,
  additionalColumnProperties,
  generateContextMenu,
  getColumnHeadersMap,
  tableStorageKey,
} from './PriceLists.functions';
import useTableFilters from './useTableFilters';

function PriceLists(): JSX.Element {
  const { t } = useTranslation();
  usePageTitle(t('Price Lists'));
  const { bottomRightToastRef } = useContext(ToastContext);
  const history = useHistory();
  const { id } = useParams<EntityIdRouteParams>();
  const createEpGuard = useEndpointGuard(createGuard);
  const editEpGuard = useEndpointGuard(editGuard);
  const deleteEpGuard = useEndpointGuard(deleteGuard);
  const pricesPageEpGuard = useEndpointGuard(pricesPageGuard);
  const { data: priceListParents, error: priceListParentsError } =
    useAxiosHook<PriceListParentsCollection>('/price-lists/parents');

  useToastMessage(undefined, priceListParentsError, {
    error: {
      summary: t('An error occured while reading base price list data.'),
    },
  });

  const parentOptions = useMemo<LabelValue[]>(
    () =>
      priceListParents?.map((p) => ({ label: p.name, value: String(p.id) })) ??
      [],
    [priceListParents]
  );
  const createEditParentOptions = useMemo<LabelValue[]>(
    () => [
      { label: t('This price list is a base price list'), value: '-1' },
      ...parentOptions,
    ],
    [parentOptions, t]
  );

  const {
    tableRef,
    page,
    setPage,
    limit,
    setLimit,
    sortField,
    sortOrder,
    selection,
    setSortField,
    setSortOrder,
    setSelection,
  } = useTableState<Row>(tableStorageKey);

  const { filters, headerFiltersCount, resetAllFilters, httpFiltersObj } =
    useTableFilters(page, setPage, limit, parentOptions);

  const columnHeadersMap = useMemo(() => getColumnHeadersMap(t), [t]);

  const { selectedColumns, setSelectedColumns, columnOptions, columns } =
    useTableColumns<Column>(
      page,
      limit,
      'priceLists',
      columnHeadersMap,
      columnHeadersMap,
      additionalColumnProperties
    );
  const {
    show: showCreateDialog,
    hide: hideCreateDialog,
    isVisible: isCreateDialogVisible,
  } = useRouteDialog(
    RoutePaths.PriceLists,
    RoutePaths.AddPriceList,
    createEpGuard
  );
  const {
    show: showEditDialog,
    hide: hideEditDialog,
    isVisible: isEditDialogVisible,
  } = useRouteDialog(
    RoutePaths.PriceLists,
    constructIdRoute(RoutePaths.EditPriceList, id ?? selection?.id),
    editEpGuard
  );
  const {
    show: showDeleteDialog,
    hide: hideDeleteDialog,
    isVisible: isDeleteDialogVisible,
  } = useRouteDialog(
    RoutePaths.PriceLists,
    constructIdRoute(RoutePaths.DeletePriceList, id ?? selection?.id),
    deleteEpGuard
  );

  const { data, error, isLoading, reload } = useAxiosHook<PriceListCollection>(
    '/price-lists' + queryString(httpFiltersObj)
  );

  useToastMessage(undefined, error, {
    error: {
      summary: t('An error occured while reading price list data.'),
    },
  });

  const handleCmPriceDefinitionClick = useCallback(() => {
    history.push('/prices' + queryString({ priceList: selection?.id }));
  }, [history, selection?.id]);

  const contextMenuModel = useMemo(() => {
    function handleCmPrintPricesClick() {
      if (!selection) {
        return;
      }

      downloadFile(
        `/replace/me`, // !!!
        getFileName(t('PriceLists'), selection.name ?? ''),
        FileTypes.PDF,
        bottomRightToastRef
      );
    }

    return generateContextMenu({
      t,
      isEditShown: editEpGuard,
      onEditClick: showEditDialog,
      isPriceDefinitionsShown: pricesPageEpGuard,
      onPriceDefinitionsClick: handleCmPriceDefinitionClick,
      onPrintClick: handleCmPrintPricesClick,
      isDeleteShown: deleteEpGuard,
      onDeleteClick: showDeleteDialog,
    });
  }, [
    bottomRightToastRef,
    deleteEpGuard,
    editEpGuard,
    handleCmPriceDefinitionClick,
    pricesPageEpGuard,
    selection,
    showDeleteDialog,
    showEditDialog,
    t,
  ]);

  return (
    <div className="page price-lists">
      <HeaderPages
        title={t('Price Lists')}
        subtitle={t('View and manage price lists')}
        icon={faBook}
      >
        <Button
          type="button"
          label={t('Create a price list')}
          icon="fas fa-plus"
          onClick={showCreateDialog}
          className="main-btn"
        />
      </HeaderPages>

      <CreateEditDialog
        isEditDialog={isEditDialogVisible}
        isShown={isCreateDialogVisible || isEditDialogVisible}
        onHide={() => sequential(hideEditDialog, hideCreateDialog)}
        parentOptions={createEditParentOptions}
        reloadCollection={reload}
      />

      <DeleteDialog
        isShown={isDeleteDialogVisible}
        onHide={hideDeleteDialog}
        reloadCollection={reload}
      />

      <Flex direction="column">
        <Filters
          filters={filters}
          resetAllFilters={resetAllFilters}
          headerFiltersCount={headerFiltersCount}
          filterHeight={170}
        />
        <MainContent>
          <Table
            tableRef={tableRef}
            columns={columns}
            data={data}
            isLoading={isLoading}
            hasError={!!error}
            reload={reload}
            headerTitle=""
            headerFiltersCount={headerFiltersCount}
            onHeaderFiltersResetAllBtnClick={resetAllFilters}
            setPage={setPage}
            setLimit={setLimit}
            sortField={sortField}
            rows={limit}
            filterHeight={170}
            contextMenuModel={contextMenuModel}
            setSortField={setSortField}
            setSortOrder={setSortOrder}
            setSelection={setSelection}
            sortOrder={sortOrder}
            selection={selection}
            storageString={tableStorageKey}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            columnOptions={columnOptions}
            onRowDoubleClick={handleCmPriceDefinitionClick}
            exportToExcelButton
          />
        </MainContent>
      </Flex>
    </div>
  );
}

export default PriceLists;
