import { TFunction } from 'react-i18next';
import * as Yup from 'yup';

import { tryInt, tryString } from '../../../../../utils/helpers/parse';
import { yupRequiredField } from '../../../../../utils/helpers/yup';
import { CourierLiabilityResource } from '../../CourierLiabilities.functions';

export type FormValues = {
  postar_id: string;
  client: string;
  postar_ime?: string;
  fields: {
    prefix: string;
    seriski_broj_pocetok: string;
    seriski_broj_kraj: string;
  }[];
};

export function getInitialValues(
  data: CourierLiabilityResource | undefined,
  isEditDialog: boolean
): FormValues {
  return {
    postar_id: tryString(data?.postar_id) ?? '',
    client: tryString(data?.client_id) ?? '',
    fields: isEditDialog
      ? [
          {
            prefix: data?.prefix ?? '',
            seriski_broj_pocetok: data?.seriski_broj_pocetok ?? '',
            seriski_broj_kraj: data?.seriski_broj_kraj ?? '',
          },
        ]
      : [
          {
            prefix: '',
            seriski_broj_pocetok: '',
            seriski_broj_kraj: '',
          },
        ],
  };
}

export function createLiabilityToApiData(values?: FormValues) {
  const apiDataArray = values?.fields.map((field) => {
    const totalAddresses =
      parseInt(field.seriski_broj_kraj) -
      parseInt(field.seriski_broj_pocetok) +
      1;

    return {
      prefix: field.prefix,
      seriski_broj_pocetok: field.seriski_broj_pocetok,
      seriski_broj_kraj: field.seriski_broj_kraj,
      vkupen_broj_adresnici: totalAddresses.toString(),
      postar_id: values.postar_id,
      client_id: tryInt(values.client),
    };
  });

  return { items: apiDataArray };
}

export function editLiabilityToApiData(values?: FormValues) {
  const totalAddresses =
    (tryInt(values?.fields?.[0]?.seriski_broj_kraj) ?? 0) -
    (tryInt(values?.fields?.[0]?.seriski_broj_pocetok) ?? 0) +
    1;

  return {
    prefix: values?.fields?.[0]?.prefix,
    seriski_broj_pocetok: values?.fields?.[0]?.seriski_broj_pocetok,
    seriski_broj_kraj: values?.fields?.[0]?.seriski_broj_kraj,
    postar_id: values?.postar_id,
    vkupen_broj_adresnici: totalAddresses.toString(),
    client_id: values?.client,
  };
}

export function getValidationSchema(t: TFunction) {
  return Yup.object().shape({
    postar_id: Yup.string().required(yupRequiredField(t, t('Courier'))),
    client: Yup.string().required(yupRequiredField(t, t('Client'))),
    fields: Yup.array()
      .of(
        Yup.object().shape({
          prefix: Yup.string(),
          seriski_broj_pocetok: Yup.string(),
          seriski_broj_kraj: Yup.string(),
          vkupen_broj_adresnici: Yup.string(),
        })
      )
      .min(1, yupRequiredField(t, t('Addresses')))
      .test({
        name: 'fields',
        test: (value, { createError }) => {
          const mandatory = ['seriski_broj_pocetok', 'seriski_broj_kraj'];

          value?.forEach((row) => {
            if (
              Number(row?.seriski_broj_pocetok) > Number(row?.seriski_broj_kraj)
            ) {
              return createError({
                message: t(
                  'The initial serial number must be smaller than the end serial number.'
                ),
              });
            }

            if (
              Number(row?.seriski_broj_kraj) -
                Number(row?.seriski_broj_pocetok) +
                1 >
              1000
            ) {
              return createError({
                message: t(
                  'The maximum number of addresses must be less than 1000.'
                ),
              });
            }

            mandatory.forEach((mandatoryProperty) => {
              if (!row[mandatoryProperty]) {
                return createError({
                  path: 'fields',
                  message: t('You have required fields left out'),
                });
              }
            });
          });

          return true;
        },
      }),
  });
}
