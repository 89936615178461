import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useRef } from 'react';
import { Link } from 'react-router-dom';

import { MenuItem } from '../../SideMenu/SideMenu.types';
import styles from './PopUpSubMenuItem.module.scss';

type Props = MenuItem & {
  handleChildItemClick: () => void;
};

function PopUpSubMenuItem({ handleChildItemClick, ...item }: Props) {
  const childrenSubMenuRef = useRef<HTMLDivElement>(null);

  return item.type === 'parent' ? (
    <>
      <div className={styles.title} ref={childrenSubMenuRef}>
        {item.label}
      </div>
      {item.items.map((childItem, idx) => {
        const key = `${childItem.type === 'item' ? childItem.path : ''}-${idx}`;
        return (
          <PopUpSubMenuItem
            {...childItem}
            key={key}
            handleChildItemClick={handleChildItemClick}
            {...(childItem.type === 'item'
              ? { path: item.basePath + childItem.path }
              : { basePath: item.basePath + childItem.basePath })}
          />
        );
      })}
    </>
  ) : (
    <div
      className={classNames(styles.item, 'p-px-4 p-py-3')}
      ref={childrenSubMenuRef}
      key={item.path}
    >
      <FontAwesomeIcon
        style={{ marginRight: '0.5rem', color: '#35454c' }}
        icon={item.icon}
      />
      <Link to={item.path} onClick={handleChildItemClick}>
        {item.label}
      </Link>
    </div>
  );
}

export default PopUpSubMenuItem;
