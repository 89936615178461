import _ from 'lodash';

// !!!!!!!!!!!!!!!!
// !!! Obsolete !!!
// !!!!!!!!!!!!!!!!
//
// Please use the TypeScript version instead
export function formik_handleErrorsUponSubmission(error, formRef) {
  if (!error) {
    return false;
  }

  const hasErrorsInvalidParam = error.indexOf('invalid_parameter_') !== -1;

  if (!hasErrorsInvalidParam) {
    return false;
  }

  const fieldName = error
    .replace('invalid_parameter_', '')
    .replace(/_bad.*/, '')
    .replace(/_already.*/, '');

  if (!Object.keys(formRef.current.values).includes(fieldName)) {
    return false;
  }

  const isBadFormat = error.match('_bad_format');
  const isAlreadyInUse = error.match('_already_in_use');

  let responseStr = 'Invalid input';
  if (isBadFormat) {
    responseStr = 'This field has an invalid format';
  } else if (isAlreadyInUse) {
    responseStr = 'This value is already in use';
  }

  formRef.current.setErrors({
    [fieldName]: responseStr,
  });

  return true;
}

export function formik_hasDialogTabErrors(errors, tabFields) {
  for (let t in tabFields) {
    if (errors[t]) {
      return true;
    }
  }

  return false;
}

// !!!!!!!!!!!!!!!!
// !!! Obsolete !!!
// !!!!!!!!!!!!!!!!
//
// Please use the TypeScript version instead
export function formik_handleNewErrorFormatUponSubmission(
  response,
  formRef,
  allowedKeys,
  allowedValues
) {
  const error = response?.data;
  // Handles only 400 errors which are objects
  if (
    !error ||
    !_.isPlainObject(error.error) ||
    Object.keys(error.error).length === 0 ||
    response?.status !== 400
  ) {
    // Intentional, so that we can easier debug, even on prod
    console.error(error, error?.error, error?.response);

    return false;
  }

  const diffKeys = _.difference(Object.keys(error.error), allowedKeys);
  const diffVals = _.difference(
    Object.values(error.error),
    Object.keys(allowedValues)
  );

  if (diffKeys.length > 0 && diffVals.length > 0) {
    // Intentional as well
    console.error(error, error?.error, error?.response, diffKeys, diffVals);

    return false;
  }

  const errorObj = {};
  for (const fieldName in error.error) {
    errorObj[fieldName] = allowedValues[error.error[fieldName]];
  }

  formRef.current.setErrors(errorObj);

  return true;
}
