import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router';

import Tooltip from '../../../tooltip/Tooltip/Tooltip';
import PopUpSubMenu from '../../PopUpSubMenu/PopUpSubMenu';
import MaybeLink from '../MaybeLink';
import { isItemActive } from '../SideMenu.helpers';
import { MenuItem } from '../SideMenu.types';
import styles from './CollapsedMenuItem.module.scss';

type Props = MenuItem & {
  nestingLevel: number;
};

function CollapsedMenuItem({ nestingLevel, ...item }: Props): JSX.Element {
  const location = useLocation();

  const isActive: boolean = useMemo(
    () => isItemActive(location.pathname, item),
    [item, location.pathname]
  );

  const [showChildrenSubmenu, setShowChildrenSubmenu] = useState(false);
  const [popUpSubMenuTop, setPopUpSubMenuTop] = useState<number | undefined>(0);

  const parentItemRef = useRef<HTMLDivElement>(null);

  function handleParentItemMouseEnter() {
    const parentItemHeight = parentItemRef.current?.offsetHeight;

    const parentElementTopOffset =
      parentItemRef.current?.parentElement?.offsetTop;
    const yPosition = parentItemRef.current?.getBoundingClientRect().y;

    if (
      yPosition !== undefined &&
      parentElementTopOffset !== undefined &&
      parentItemHeight !== undefined
    ) {
      setPopUpSubMenuTop(yPosition + parentElementTopOffset - parentItemHeight);
    }
    setShowChildrenSubmenu(true);
  }

  function handleParentItemMouseLeave() {
    setShowChildrenSubmenu(false);
  }

  function handleChildItemClick() {
    setShowChildrenSubmenu(!showChildrenSubmenu);
  }

  // More than 3 nested menu items breaks both the UI and the UX
  const mainContentClassNames = classNames(styles.mainContent, {
    [styles.active]: isActive,
    [styles.nestingLevel1]: nestingLevel === 1,
    [styles.nestingLevel2]: nestingLevel >= 2,
  });

  // If item.path === location.pathname, don't render a Link, as clicking on such Link
  //  doesn't rerender the component and it only ends up resetting all query parameters
  const filteredPath =
    item.type === 'item' && item.path !== location.pathname
      ? item.path
      : undefined;

  return item.type === 'parent' ? (
    <div
      ref={parentItemRef}
      onMouseEnter={handleParentItemMouseEnter}
      onMouseLeave={handleParentItemMouseLeave}
    >
      <MaybeLink to={filteredPath} className={styles.menuItem}>
        <div className={mainContentClassNames}>
          <FontAwesomeIcon icon={item.icon} className={styles.icon} />
        </div>
      </MaybeLink>

      {showChildrenSubmenu && (
        <PopUpSubMenu
          popUpSubMenuTop={popUpSubMenuTop}
          setPopUpSubMenuTop={setPopUpSubMenuTop}
          handleChildItemClick={handleChildItemClick}
          parentItem={item}
        />
      )}
    </div>
  ) : (
    <Tooltip
      text={item.label}
      children={
        <MaybeLink to={filteredPath} className={styles.menuItem}>
          <div className={mainContentClassNames}>
            <FontAwesomeIcon icon={item.icon} className={styles.icon} />
          </div>
        </MaybeLink>
      }
    />
  );
}

export default CollapsedMenuItem;
