import './ViewDialog.scss';

import {
  faEnvelope,
  faHome,
  faIdCard,
  faMobileAlt,
  faPhoneAlt,
  faQuestion,
  faUser,
  faUserAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useAxios from '../../../../hooks/useAxios';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import { getEmployeeActiveStatusIconMap } from '../Employees.functions';

function ViewDialog({ data, isShown, onHide, onEditBtnClick }) {
  const { t } = useTranslation();

  const employeeActiveStatusIconMap = useMemo(
    () => getEmployeeActiveStatusIconMap(t),
    [t]
  );

  const [placeName, setPlaceName] = useState();
  const [zip, setZip] = useState();
  const [municipalityId, setMunicipalityId] = useState();

  useAxios(`/places/${data.mesto_id}`, undefined, {
    skipWhen: !data.mesto_id,
    successCallback: (place) => {
      setPlaceName(place.ime);
      setZip(place.postenski_broj);
      setMunicipalityId(place.opstina_id);
    },
  });

  const { data: municipalities } = useAxios('/municipalities');

  const municipality = useMemo(
    () => municipalities?.find((m) => m.id === municipalityId)?.ime,
    [municipalities, municipalityId]
  );

  const dialogFooter = (
    <>
      <Button
        type="button"
        label={t('Edit')}
        className="p-button-text"
        onClick={() => onEditBtnClick(data)}
      />
      <Button type="button" label={t('Close')} onClick={() => onHide()} />
    </>
  );

  const isOnMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Dialog
      visible={isShown}
      onHide={onHide}
      header={`${data.ime} ${data.prezime}`}
      footer={dialogFooter}
      dismissableMask
      resizable={false}
      maximizable={isOnMobile}
      maximized={isOnMobile}
      className="employees-view-dialog"
    >
      <div className="quick-info-container">
        <div className="profile-picture">
          <FontAwesomeIcon icon={faUserAlt} />

          <div>
            <i>{t('Employee')}</i>
          </div>
        </div>

        <div className="quick-info">
          <div>
            <FontAwesomeIcon icon={faIdCard} /> {data.ime} {data.prezime}
          </div>
          <div>
            <FontAwesomeIcon icon={faHome} />{' '}
            {data.adresa ? (
              <>
                {data.adresa} <br />
              </>
            ) : null}
            {zip} {placeName}, {municipality}
          </div>
          <div>
            <FontAwesomeIcon icon={faPhoneAlt} /> {data.telefon || '-'}
          </div>
          <div>
            <FontAwesomeIcon icon={faMobileAlt} /> {data.mobilen || '-'}
          </div>
          {data.aktiven && (
            <div>
              <FontAwesomeIcon icon={faQuestion} /> {t('Status')}:{' '}
              {employeeActiveStatusIconMap[parseInt(data.aktiven)].label}
            </div>
          )}
          {data.username && (
            <div>
              <FontAwesomeIcon
                icon={data.username.includes('@') ? faEnvelope : faUser}
              />{' '}
              {data.username}
            </div>
          )}
        </div>
      </div>

      <h4>{t('Roles')}</h4>
      <div>
        {data?.ulogi?.map((r) => (
          <Fragment key={r.id}>
            - {r.ime}
            <br />
          </Fragment>
        ))}
      </div>

      <h4>{t('Native warehouse')}</h4>
      <div>{data.hub_ime}</div>

      <h4>{t('Warehouses')}</h4>
      <div>
        {data?.hubs?.map((h) => (
          <Fragment key={h.id}>
            - {h.ime}
            <br />
          </Fragment>
        ))}
      </div>

      <h4>{t('Note')}</h4>
      <div>{data.komentar || <i>- {t('Does not exist')} -</i>}</div>
    </Dialog>
  );
}

export default ViewDialog;
