import { TFunction } from 'react-i18next';

import { InvoiceShipmentCollection } from '../../../../../../../../types/api/invoices';
import {
  TableColumnHeadersMap,
  TableColumns,
  Unpacked,
} from '../../../../../../../../types/util';
import { noColumnStyle } from '../../../../../../../../utils/constants/tables';
import { dateCell } from '../../../../../../../../utils/helpers/dataTable';
import { currencyFormat } from '../../../../../../../../utils/helpers/formatting';

type Row = Unpacked<InvoiceShipmentCollection>;

type Column = TableColumns<
  Pick<
    Row,
    | 'seriski_broj'
    | 'reference_id'
    | 'reference2'
    | 'status_name'
    | 'date_received'
    | 'date_delivered'
    | 'client_sender_name'
    | 'client_recipient_name'
    | 'place_to_name'
    | 'place_from_name'
    | 'comment'
    | 'weight'
    | 'ransom'
    | 'return_document'
    | 'total_postage'
    | 'total_ransom'
    | 'total_return_document'
    | 'total_insurance'
    | 'total_price'
  >
>;

export const tableStorageKey = 'invoiceViewShipments_dataTable';

export function getColumnHeadersMap(
  t: TFunction
): TableColumnHeadersMap<Column> {
  return {
    no: t('No.'),
    seriski_broj: t('Serial Number'),
    reference_id: t('Reference 1'),
    reference2: t('Reference 2'),
    status_name: t('Status'),
    date_received: t('Reception Date'),
    date_delivered: t('Delivery Date'),
    client_sender_name: t('Sender'),
    client_recipient_name: t('Recipient'),
    place_to_name: t('Place To'),
    place_from_name: t('Place From'),
    comment: t('Comment'),
    weight: t('Weight'),
    ransom: t('Redemption'),
    return_document: t('Return Document'),
    total_postage: t('Postage'),
    total_ransom: t('COD Fee'),
    total_return_document: t('Return Document Price'),
    total_insurance: t('Insurance'),
    total_price: t('Total Price'),
  };
}

export function additionalColumnProperties(column: Column) {
  switch (column) {
    case 'no':
      return noColumnStyle;

    case 'date_received':
    case 'date_delivered':
      return {
        body: (row: Row) => dateCell(row[column], true),
        style: { width: 200 },
        bodyStyle: { width: 200 },
      };

    case 'total_postage':
    case 'total_ransom':
    case 'total_insurance':
    case 'total_price':
      return {
        body: (row: Row) => currencyFormat(row[column], { showCurrency: true }),
        style: { width: 200 },
        bodyStyle: { width: 200 },
      };

    case 'total_return_document':
      return {
        body: (row: Row) =>
          currencyFormat(row.total_return_document, { showCurrency: true }),
        style: { width: 240 },
        bodyStyle: { width: 240 },
      };

    default:
      return { style: { width: 200 }, bodyStyle: { width: 200 } };
  }
}

export function generateContextMenu(t: TFunction, handleViewClick: () => void) {
  return [
    {
      label: t('View Details'),
      icon: 'fas fa-search',
      command: handleViewClick,
    },
  ];
}
