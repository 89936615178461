import './CreateEditDialog.scss';

import dayjs from 'dayjs';
import { Field, useFormikContext } from 'formik';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { useTranslation } from 'react-i18next';

import { usePhoneOptionsContext } from '../../../../../../context/PhoneOptionsContext';
import { invalidPhoneNumberCharactersRegex } from '../../../../../../utils/constants/phoneNumbers';
import {
  formatMobileNumber,
  formatPhoneNumber,
  getPhoneorMobileNumberRegion,
} from '../../../../../../utils/helpers/phoneNumbers';
import FieldWithErrors from '../../../../../Forms/FieldWithErrors/FieldWithErrors';

function GeneralInfo({ step, setIsNameUsed }) {
  const { t } = useTranslation();

  const { values, errors, touched, setFieldValue } = useFormikContext();

  const { countryOptionTemplate, languages, selectedCountryTemplate } =
    usePhoneOptionsContext();

  return (
    <div>
      <span className="section-title">
        {step}. {t('General Information')}
      </span>

      <div className="general-info">
        <FieldWithErrors name="ime" label={t('Name')}>
          <Field
            className={`ime p-inputtext p-component ${
              errors.ime && touched.ime && 'invalid'
            }`}
            id="ime"
            name="ime"
            onChange={(e) => {
              setIsNameUsed(false);
              setFieldValue('ime', e.target.value);
            }}
            data-cy="name"
          />
        </FieldWithErrors>

        <FieldWithErrors name="phone" label={t('Phone')}>
          <div className="p-inputgroup number-group">
            <Field
              as={Dropdown}
              className="phone_region"
              style={{ padding: 0, width: '5rem' }}
              id="phone_region"
              name="phone_region"
              options={languages}
              itemTemplate={countryOptionTemplate}
              valueTemplate={selectedCountryTemplate}
              data-cy="phone_region"
            />

            <Field
              as={InputText}
              className={`phone ${errors.phone && touched.phone && 'invalid'}`}
              id="phone"
              name="phone"
              value={formatPhoneNumber(
                values.phone,
                values.phone_region ||
                  getPhoneorMobileNumberRegion(values.phone)
              )}
              onChange={(e) => {
                setFieldValue(
                  'phone',
                  e.target.value?.replace(invalidPhoneNumberCharactersRegex, '')
                );
              }}
              data-cy="phone"
            />
          </div>
        </FieldWithErrors>

        <FieldWithErrors name="mobile" label={t('Mobile Phone')}>
          <div className="p-inputgroup number-group">
            <Field
              as={Dropdown}
              className="mobile_region"
              style={{ padding: 0, width: '5rem' }}
              id="mobile_region"
              name="mobile_region"
              options={languages}
              itemTemplate={countryOptionTemplate}
              valueTemplate={selectedCountryTemplate}
              data-cy="mobile_region"
            />

            <Field
              as={InputText}
              className={`mobile ${
                errors.mobile && touched.mobile && 'invalid'
              }`}
              id="mobile"
              name="mobile"
              maxLength="15"
              value={formatMobileNumber(
                values.mobile,
                values.mobile_region ||
                  getPhoneorMobileNumberRegion(values.mobile)
              )}
              onChange={(e) => {
                setFieldValue(
                  'mobile',
                  e.target.value?.replace(invalidPhoneNumberCharactersRegex, '')
                );
              }}
              data-cy="mobile"
            />
          </div>
        </FieldWithErrors>

        <FieldWithErrors
          name="working_hours_from"
          label={t('Working hours')}
          includeErrorsFor={['working_hours_to']}
        >
          <div className="working-hours-wrapper">
            <Field
              as={Calendar}
              name="working_hours_from"
              inputId="working_hours_from"
              timeOnly
              stepMinute={5}
              onFocus={() => {
                if (
                  Object.keys(values.working_hours_from).length === 0 ||
                  !values.working_hours_from
                ) {
                  setFieldValue(
                    'working_hours_from',
                    dayjs().hour(8).minute(0).toDate()
                  );
                } else {
                  setFieldValue(
                    'working_hours_from',
                    values.working_hours_from
                  );
                }
              }}
              className="data-cy-working_hours_from"
            />

            <span className="divider">-</span>

            <Field
              as={Calendar}
              name="working_hours_to"
              timeOnly
              stepMinute={5}
              onFocus={() => {
                if (
                  Object.keys(values.working_hours_to).length === 0 ||
                  !values.working_hours_to
                ) {
                  setFieldValue(
                    'working_hours_to',
                    dayjs().hour(16).minute(0).toDate()
                  );
                } else {
                  setFieldValue('working_hours_to', values.working_hours_to);
                }
              }}
              className="data-cy-working_hours_to"
            />
          </div>
        </FieldWithErrors>
      </div>
    </div>
  );
}

export default GeneralInfo;
