import { UserLoginResource } from '../../types/api/users';

export function processUser(user: UserLoginResource): UserLoginResource {
  return {
    ...user,
    permitted_routes: user.permitted_routes.map(processPermittedRoute),
  };
}

function processPermittedRoute(permittedRoute: string) {
  return permittedRoute
    .replace(/\\/g, '')
    .replace(/\$/g, '')
    .replace(/\^/g, '');
}
