import { faNetworkWired } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { ContextMenu } from 'primereact/contextmenu';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import useAxios from '../../../../hooks/useAxios';
import useAxiosHook from '../../../../hooks/useAxiosHook';
import { useEndpointGuard } from '../../../../hooks/useEndpointGuard';
import useHaveValuesChanged from '../../../../hooks/useHaveValuesChanged';
import usePageTitle from '../../../../hooks/usePageTitle';
import useSearchQueryDropdownParam from '../../../../hooks/useSearchQueryDropdownParam';
import useTableScrollHeight from '../../../../hooks/useTableScrollHeight';
import * as warehousesGuards from '../../../../utils/constants/auth/warehouses';
import { emptyCell } from '../../../../utils/constants/tables';
import { getDataTableProps } from '../../../../utils/globals';
import { formik_handleNewErrorFormatUponSubmission } from '../../../../utils/helpers';
import { getSavedSelectedColumns } from '../../../../utils/helpers/dataTable';
import { queryString } from '../../../../utils/helpers/http';
import { getSearchQueryParam } from '../../../../utils/helpers/searchQuery';
import { userPrefixedString } from '../../../../utils/helpers/user';
import TableHeader from '../../../DataTable/TableHeader';
import MainContent from '../../../layout/flex/MainContent';
import HeaderPages from '../../Components/HeaderPages/HeaderPages';
import CreateEditDialog from './Dialogs/CreateEditDialog/CreateEditDialog';
import { toApiData } from './Dialogs/CreateEditDialog/CreateEditDialog.functions';
import DeleteHubDialog from './Dialogs/Delete/DeleteHubDialog';
import ViewDialog from './Dialogs/ViewDialog/ViewDialog';
import {
  additionalColumnProperties,
  generateContextMenu,
  getColumnHeadersMap,
  getColumnOptions,
} from './WarehousesList.functions';

function WarehousesList() {
  const { t } = useTranslation();

  usePageTitle(t('Warehouses List'));

  const readGuard = useEndpointGuard(warehousesGuards.readHub);
  const createGuard = useEndpointGuard(warehousesGuards.createHub);
  const editGuard = useEndpointGuard(warehousesGuards.editHub);
  const deleteGuard = useEndpointGuard(warehousesGuards.deleteHub);
  const municipalityFilterGuard = useEndpointGuard(
    warehousesGuards.municipalityFilter
  );
  const placesFilterGuard = useEndpointGuard(warehousesGuards.placesFilter);

  const location = useLocation();

  const columnOptions = useMemo(() => getColumnOptions(t), [t]);
  const dataTableProps = useMemo(() => getDataTableProps(t), [t]);
  const columnHeadersMap = useMemo(() => getColumnHeadersMap(t), [t]);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(30);

  const [selectedRow, setSelectedRow] = useState({});

  const [selectedColumns, setSelectedColumns] = useState(
    getSavedSelectedColumns(
      'warehousesList_dataTableSelectedColumns',
      columnOptions
    )
  );

  const [municipalityFilter, setMunicipalityFilter] = useState(
    municipalityFilterGuard
      ? getSearchQueryParam(location.search, 'municipality') ?? null
      : null
  );

  const [placeFilter, setPlaceFilter] = useState(
    placesFilterGuard
      ? getSearchQueryParam(location.search, 'place') ?? null
      : null
  );

  const [isViewDialogShown, setIsViewDialogShown] = useState(false);
  const [isCreateEditDialogShown, setIsCreateEditDialogShown] = useState(false);
  const [isDeleteDialogShown, setIsDeleteDialogShown] = useState(false);
  const [isEditDialog, setIsEditDialog] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const [step, setStep] = useState(1);

  const [sortOrder, setSortOrder] = useState(1);
  const [sortField, setSortField] = useState(null);

  const [isNameUsed, setIsNameUsed] = useState(false);

  const dataTableRef = useRef(null);
  const contextMenuRef = useRef(null);
  const toastRef = useRef(null);
  const createEditDialogFormRef = useRef(null);

  const filtersArr = useMemo(
    () => [placeFilter, municipalityFilter],
    [municipalityFilter, placeFilter]
  );

  const haveFiltersChanged = useHaveValuesChanged(filtersArr);

  const { data, isLoading, reload, hasError } = useAxios(
    '/hubs' +
      queryString({
        mesto_id: placeFilter,
        opstina_id: municipalityFilter,
        page: haveFiltersChanged ? 1 : page,
        limit,
      })
  );

  const dataToDisplay = useMemo(() => {
    if (!data) {
      return data;
    }

    if (!sortField) {
      return data.data;
    }

    const dataClone = _.clone(data.data);

    return dataClone.sort((a, b) =>
      a[sortField] > b[sortField]
        ? sortOrder
        : b[sortField] > a[sortField]
        ? sortOrder * -1
        : 0
    );
  }, [data, sortField, sortOrder]);

  const { data: municipalities, loading: isLoadingMunicipalities } =
    useAxiosHook('/municipalities', {
      skipWhen: !municipalityFilterGuard,
    });

  const municipalityFilterOptions =
    municipalities?.map((municipality) => {
      return {
        label: municipality.ime,
        value: municipality.id,
      };
    }) ?? [];

  const { data: places, loading: isLoadingPlaces } = useAxiosHook(
    '/places/municipalities',
    { skipWhen: !placesFilterGuard }
  );

  const placeFilterOptions =
    places?.map((place) => {
      return {
        label: place.ime,
        value: place.id,
      };
    }) ?? [];

  const { reload: reloadData, isLoading: isFormSubmissionRequestLoading } =
    useAxios();

  const scrollHeight = useTableScrollHeight(dataTableRef, 10);

  useSearchQueryDropdownParam(
    'place',
    placeFilter,
    setPlaceFilter,
    placeFilterOptions
  );

  useSearchQueryDropdownParam(
    'municipality',
    municipalityFilter,
    setMunicipalityFilter,
    municipalityFilterOptions
  );

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [dataToDisplay]);

  useEffect(() => {
    setPage(1);
  }, [placeFilter, municipalityFilter]);

  useEffect(() => {
    if (!readGuard) {
      setIsViewDialogShown(false);
    }
  }, [readGuard]);

  useEffect(() => {
    if (!createGuard && isCreateEditDialogShown && !isEditDialog) {
      setIsCreateEditDialogShown(false);
    }
  }, [createGuard, isCreateEditDialogShown, isEditDialog]);

  useEffect(() => {
    if (!editGuard && isCreateEditDialogShown && isEditDialog) {
      setIsCreateEditDialogShown(false);
    }
  }, [editGuard, isCreateEditDialogShown, isEditDialog]);

  useEffect(() => {
    if (!deleteGuard) {
      setIsDeleteDialogShown(false);
    }
  }, [deleteGuard]);

  useEffect(() => {
    if (!placesFilterGuard) {
      setPlaceFilter(null);
    }
  }, [placesFilterGuard]);

  useEffect(() => {
    if (!municipalityFilterGuard) {
      setMunicipalityFilter(null);
    }
  }, [municipalityFilterGuard]);

  function handleCreateHubBtnClick() {
    setDialogData({});
    setIsCreateEditDialogShown(true);
  }

  // Context menu functions
  function handleCMViewClick() {
    reloadData({
      url: `/hubs/${selectedRow.id}`,
      successCallback: (data) => {
        setDialogData(data.data);
        setIsViewDialogShown(true);
      },
    });
  }

  function handleCMEditClick() {
    reloadData({
      url: `/hubs/${selectedRow.id}`,
      successCallback: (data) => {
        setDialogData(data.data);
        setIsEditDialog(true);
        setIsCreateEditDialogShown(true);
      },
    });
  }

  function handleCMDeleteClick() {
    setIsDeleteDialogShown(true);
  }

  function handleCreateEditDialogHide() {
    setIsEditDialog(false);
    setIsCreateEditDialogShown(false);
  }

  function handleViewDialogHide() {
    setIsViewDialogShown(false);
    setDialogData({});
  }

  function handleDeleteDialogHide() {
    setIsDeleteDialogShown(false);
  }

  function handleViewDialogEditBtnClick(id) {
    setIsViewDialogShown(false);
    setIsEditDialog(true);
    reloadData({
      url: `/hubs/${id}`,
      successCallback: (data) => {
        setDialogData(data.data);
        setIsEditDialog(true);
        setIsCreateEditDialogShown(true);
      },
    });
  }

  function handleHubCreate(name, isSuccess) {
    toastRef.current.show({
      severity: isSuccess ? 'success' : 'error',
      summary: isSuccess ? t('Success') : t('Error'),
      detail: isSuccess
        ? t('Warehouse {{name}} has been added successfully', { name: name })
        : t('An error occured while adding {{name}}.', { name: name }),
      life: 5000,
    });
    setIsCreateEditDialogShown(false);
    reload();
  }

  function handleHubEdit(name, isSuccess) {
    toastRef.current.show({
      severity: isSuccess ? 'success' : 'error',
      summary: isSuccess ? t('Success') : t('Error'),
      detail: isSuccess
        ? t('Warehouse {{name}} has been edited successfully', { name: name })
        : t('An error occured while editing {{name}}.', { name: name }),
      life: 5000,
    });
    setIsCreateEditDialogShown(false);
    reload();
  }

  function handleCreateEditDialogFormSubmission(values) {
    if (step < 3) {
      let nextStep = step + 1;
      setStep(nextStep);
    } else {
      const allowedKeys = ['ime'];
      const allowedValues = {
        already_in_use: t('This value is already in use'),
      };
      if (isEditDialog) {
        reloadData({
          url: `/hubs/${selectedRow.id}`,
          payload: toApiData(values),
          method: 'PUT',
          successCallback: () => {
            handleHubEdit(values.ime, true);
          },
          errorCallback: (err) => {
            const formErrors = formik_handleNewErrorFormatUponSubmission(
              err?.response,
              createEditDialogFormRef,
              allowedKeys,
              allowedValues
            );
            if (formErrors) {
              setIsNameUsed(true);
              return;
            } else {
              handleHubEdit(values.ime, false);
            }
          },
        });
      } else {
        reloadData({
          url: '/hubs',
          payload: toApiData(values),
          method: 'POST',
          successCallback: () => {
            handleHubCreate(values.ime, true);
          },
          errorCallback: (err) => {
            const formErrors = formik_handleNewErrorFormatUponSubmission(
              err?.response,
              createEditDialogFormRef,
              allowedKeys,
              allowedValues
            );
            if (formErrors) {
              setIsNameUsed(true);
              return;
            } else {
              handleHubCreate(values.ime, false);
            }
          },
        });
      }
    }
  }

  const tableFilters =
    municipalityFilterGuard || placesFilterGuard ? (
      <>
        {municipalityFilterGuard && (
          <div className="p-field">
            <label htmlFor="filter_name">{t('Municipality')}</label>
            <Dropdown
              id="filter_municipality"
              value={municipalityFilter}
              onChange={(e) => setMunicipalityFilter(e.value)}
              disabled={isLoadingMunicipalities}
              options={municipalityFilterOptions}
              placeholder={isLoadingMunicipalities ? t('Loading...') : null}
              filter
              filterPlaceholder={t('Search')}
              showClear
            />
          </div>
        )}

        {placesFilterGuard && (
          <div className="p-field">
            <label htmlFor="filter_name">{t('Place')}</label>

            <Dropdown
              id="filter_place"
              value={placeFilter}
              onChange={(e) => setPlaceFilter(e.value)}
              disabled={isLoadingPlaces}
              options={placeFilterOptions}
              placeholder={isLoadingPlaces ? t('Loading...') : null}
              filter
              filterPlaceholder={t('Search')}
              showClear
            />
          </div>
        )}
      </>
    ) : null;

  const tableHeader = (
    <TableHeader
      filters={tableFilters}
      columnOptions={columnOptions}
      setSelectedColumns={setSelectedColumns}
      selectedColumns={selectedColumns}
      storageKey={userPrefixedString('warehousesList_dataTable')}
      {...(!tableFilters ? { title: t('Warehouses List') } : {})}
    />
  );

  const columns = (
    selectedColumns.length
      ? _.map(selectedColumns, 'field')
      : Object.keys(columnHeadersMap)
  ).map((c) => (
    <Column
      key={c}
      header={columnHeadersMap[c]}
      field={c}
      sortable
      {...(c === 'no'
        ? { body: (_, colData) => colData.rowIndex + 1 + (page - 1) * limit }
        : {})}
      {...(!['no'].includes(c)
        ? {
            body: (rowData) =>
              (typeof rowData[c] === 'string'
                ? rowData[c].trim()
                : rowData[c]) || emptyCell,
          }
        : {})}
      {...additionalColumnProperties(c)}
    />
  ));

  const paginatorLeft = (
    <Button
      type="button"
      icon="fas fa-sync-alt"
      tooltip={t('Reload current view')}
      onClick={reload}
      className="p-button-text"
    />
  );

  const paginatorRight = <></>;

  function handlePaginationChange(e) {
    setPage(e.page ? e.page + 1 : 1);
    setLimit(e.rows);
  }

  function handleSortChange(e) {
    setSortOrder((prevSortOrder) =>
      sortField === e.sortField ? prevSortOrder * -1 : e.sortOrder
    );
    setSortField(e.sortField);
  }

  return (
    <div className="page hubs-page">
      <HeaderPages
        title={t('Warehouses List')}
        subtitle={t('View and manage warehouses')}
        icon={faNetworkWired}
      >
        {createGuard && (
          <Button
            type="button"
            label={t('Create warehouse')}
            icon="fas fa-plus"
            className="main-btn"
            disabled={isLoading}
            onClick={handleCreateHubBtnClick}
            data-cy="add-btn"
          />
        )}
      </HeaderPages>

      <CreateEditDialog
        formRef={createEditDialogFormRef}
        isShown={isCreateEditDialogShown}
        isEditDialog={isEditDialog}
        data={dialogData}
        onHide={handleCreateEditDialogHide}
        onFormSubmision={handleCreateEditDialogFormSubmission}
        isFormSubmissionRequestLoading={isFormSubmissionRequestLoading}
        step={step}
        setStep={setStep}
        isNameUsed={isNameUsed}
        setIsNameUsed={setIsNameUsed}
      />

      <ViewDialog
        isShown={isViewDialogShown}
        data={dialogData}
        onHide={handleViewDialogHide}
        onEditBtnClick={handleViewDialogEditBtnClick}
      />

      <DeleteHubDialog
        visible={isDeleteDialogShown}
        onHide={handleDeleteDialogHide}
        selection={selectedRow}
        successCallback={reload}
      />

      <MainContent>
        <DataTable
          ref={dataTableRef}
          header={tableHeader}
          value={dataToDisplay}
          lazy
          first={data?.pagination?.from - 1 || 0}
          totalRecords={parseInt(data?.pagination?.total) || 0}
          onPage={handlePaginationChange}
          scrollable
          onSort={handleSortChange}
          sortField={sortField}
          sortOrder={sortOrder}
          scrollHeight={`${scrollHeight}px`}
          resizableColumns
          reorderableColumns
          rowHover
          stateKey={userPrefixedString('hubs_dataTable')}
          paginator
          paginatorLeft={paginatorLeft}
          paginatorRight={paginatorRight}
          paginatorTemplate={dataTableProps.paginatorTemplate}
          currentPageReportTemplate={
            data?.pagination
              ? dataTableProps.pageReportTemplate
              : dataTableProps.emptyPageReportTemplate
          }
          rows={limit}
          rowsPerPageOptions={[5, 15, 30, 50, 75, 100, 250]}
          loading={isLoading}
          emptyMessage={
            hasError
              ? dataTableProps.emptyMessageError
              : dataTableProps.emptyMessage
          }
          selectionMode="single"
          selection={selectedRow}
          onSelectionChange={(e) => setSelectedRow(e.value)}
          onRowDoubleClick={readGuard ? handleCMViewClick : () => {}}
          contextMenuSelection={selectedRow}
          onContextMenuSelectionChange={(e) => setSelectedRow(e.value)}
          onContextMenu={(e) => contextMenuRef.current.show(e.originalEvent)}
          className="p-datatable-striped p-datatable-sm"
        >
          {columns}
        </DataTable>
      </MainContent>
      <ContextMenu
        model={generateContextMenu(
          t,
          readGuard,
          handleCMViewClick,
          editGuard,
          handleCMEditClick,
          deleteGuard,
          handleCMDeleteClick
        )}
        ref={contextMenuRef}
      />

      <Toast ref={toastRef} />
    </div>
  );
}
export default WarehousesList;
