export const orderCollectionGuard = 'GET /orders';
export const orderResourceGuard = 'GET /orders/d+';
export const orderRegExpResourceGuard = 'GET /orders/[a-zA-Z0-9 ]+';
export const editOrderResourceGuard = 'PUT /orders/d+';
export const deleteOrderResourceGuard = 'DELETE /orders/d+';
export const cancelOrderResourceGuard = 'POST /orders/d+/cancel';

export const addOrderCollectionGuard = 'POST /orders/add';

export const orderCustomerPickupCollectionGuard = 'GET /orders/customer-pickup';

export const orderCourierAssignmentPickupCollectionGuard =
  'GET /orders/courier/assignments/pickup';
export const addOrderCourierAssignmentPickupResourceGuard =
  'PUT /orders/courier/assignments/pickup';

export const courierDeliveryAssignmentsCollectionGuard =
  'GET /orders/courier/assignments/delivery';
export const assignCourierForDeliveryGuard =
  'PUT /orders/courier/assignments/delivery';
export const cancelCourierDeliveryGuard =
  'DELETE /orders/courier/assignments/delivery/d+';

export const courierOrdersCollectedCollectionGuard =
  'GET /orders/courier/reception/collected';
export const courierOrdersReturnedCollectionGuard =
  'GET /orders/courier/reception/returned';
export const submitCourierOrdersResourceGuard = 'PUT /orders/courier/reception';
export const updateCourierOrdersReturnedResourceGuard =
  'PUT /orders/courier/reception/returned/d+';
export const cancelCourierOrdersResourceGuard =
  'DELETE /orders/courier/reception/d+';

export const courierSummaryCollectionGuard = 'GET /orders/courier/summary';
export const transferSummaryCollectionGuard = 'GET /orders/transfer/summary';

export const ordersBarcodePrefixesCollectionGuard =
  'GET /orders/barcode-prefixes';

export const transferDeliveryAssignmentsCollectionGuard =
  'GET /orders/transfer/assignments';
export const assignTransferDeliveryResourceGuard =
  'PUT /orders/transfer/assignments';
export const cancelTransferDeliveryResourceGuard =
  'DELETE /orders/transfer/assignments/d+';

export const transferReceptionCollectionGuard =
  'GET /orders/transfer/reception';
export const recieveTransferResourceGuard = 'PUT /orders/transfer/reception';
export const cancelTransferRecieveResourceGuard =
  'DELETE /orders/transfer/reception/d+';

export const batchOrderPartyCollectionGuard = 'GET /orders/batch/parties';
export const sendBatchOrderSpecificationResourceGuard =
  'POST /orders/batch/send-specification';

export const deletedOrderCollectionGuard = 'GET /orders/deleted/filter';
export const deletedOrderResourceGuard = 'GET /orders/deleted/[a-zA-Z0-9 ]+';
export const recreateDeletedOrderResourceGuard =
  'POST /orders/deleted/[a-zA-Z0-9 ]+/recreate';
export const exportDeletedOrderResourceGuard =
  'GET /orders/deleted/export/[a-zA-Z0-9 ]+';

export const ordersHistoryCollectionGuard = 'GET /orders/d+/history';
export const orderDeliveryHistoryCollectionGuard =
  'GET /orders/d+/history/delivery';
export const ordersPaymentHistoryCollectionGuard =
  'GET /orders/d+/history/payment';

export const ordersByLocationCollectionGuard = 'GET /orders/locations';
export const ordersByLocationFilterCollectionGuard =
  'GET /orders/locations/filters';

export const orderImportResourceGuard = 'POST /orders/import';

export const orderExportResourceGuard = 'GET /orders/export/[a-zA-Z0-9 ]+';

export const parcelStatusCollectionGuard = 'POST /statuses/parcels';

export const orderCalculatorGuard = 'POST /orders/calculator';

export const orderStatusCollectionGuard = 'POST /orders/status';

export const deleteImportedListResourceGuard = 'DELETE /orders/bulk/d+';
