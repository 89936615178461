import { TFunction } from 'react-i18next';
import * as Yup from 'yup';

export function yupRequiredField(t: TFunction, fieldName: string): string {
  return t('{{fieldName}} is a required field', {
    fieldName: fieldName.trim(),
  });
}

export function yupMinField(
  t: TFunction,
  fieldName: string,
  min: number
): string {
  return t('{{fieldName}} has to be at least {{length}} character(s) long', {
    fieldName: fieldName.trim(),
    length: min,
  });
}

export function yupMaxField(
  t: TFunction,
  fieldName: string,
  max: number
): string {
  return t('{{fieldName}} has to be at most {{length}} character(s) long', {
    fieldName: fieldName.trim(),
    length: max,
  });
}

export function yupRequiredPasswordField(t: TFunction, fieldName: string) {
  return Yup.string()
    .min(8, t('Password is too short - should be 8 characters minimum.'))
    .matches(/[0-9]/, t('Password requires at least 1 digit.'))
    .matches(
      /[.!@*#$%^&()]/,
      t(
        'Password requires at least 1 special character (. ! @ * # $ ( ) % ^ &).'
      )
    )
    .matches(
      /^[a-zA-Z0-9.!@*#$%^&()]*$/,
      t(
        'Password can only contain latin letters, digits and special characters (. ! @ * # $ ( ) % ^ &).'
      )
    )
    .required(yupRequiredField(t, fieldName));
}

export function conditionallyRequiredField(
  field: Yup.AnySchema,
  isRequired: boolean,
  requiredMessage: string
) {
  return isRequired ? field.required(requiredMessage) : field;
}
