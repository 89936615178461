import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { tryParse, tryStringify } from '../utils/helpers/parse';
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from '../utils/helpers/storage';

export function useLocalStorage<T>(
  key: string,
  initialValue: T
): [T, Dispatch<SetStateAction<T>>] {
  const readValue = useCallback((): T => {
    const item = getLocalStorageItem(key);
    return item ? (tryParse(item) as T) : initialValue;
  }, [initialValue, key]);

  const [storedValue, setStoredValue] = useState<T>(readValue);

  const setValue = (value: any) => {
    setLocalStorageItem(key, tryStringify(value));
    setStoredValue(value);
  };

  useEffect(() => {
    setStoredValue(readValue());
  }, [readValue]);

  return [storedValue, setValue as Dispatch<SetStateAction<T>>];
}

export default useLocalStorage;
