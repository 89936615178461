export const customerReceptionResourceGuard = 'GET /customer-receptions/d+';
export const customerReceptionCollectionGuard = 'GET /customer-receptions';
export const addOrdersToCustomerReceptionGuard =
  'PUT /customer-receptions/d+/orders';
export const discardOrderFromCustomerReceptionGuard =
  'DELETE /customer-receptions/d+/orders/d+';
export const addOrdersByBarcodeToCustomerReception =
  'PUT /customer-receptions/orders';
export const discardCustomerReceptionResourceGuard =
  'DELETE /customer-receptions/d+';
export const createCustomerReceptionGuard = 'POST /customer-receptions';
export const finalizeCustomerReceptionGuard = 'PUT /customer-receptions/d+';
