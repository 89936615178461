import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import styles from './GoBack.module.scss';

type Props = {
  to: string;
  page: string;
};

function GoBackLink({ to, page }: Props): JSX.Element {
  return (
    <Link replace to={to} className={styles.goBackLink}>
      <FontAwesomeIcon icon={faChevronLeft} /> {page}
    </Link>
  );
}

export default GoBackLink;
