import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import {
  Children,
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
} from 'react';
import { useTranslation } from 'react-i18next';

import useMediaQuery from '../../../hooks/useMediaQuery';
import { BDItemProps } from './BDItem';
import BreadcrumbsDialogContext from './BreadcrumbsDialogContext';

type BreadcrumbsDialogProps = {
  visible: boolean;
  onHide: () => void;
  index: number;
  setIndex: Dispatch<SetStateAction<number>>;
  children: ReactNode;
};

function BreadcrumbsDialog({
  visible,
  onHide: _onHide,
  index,
  setIndex,
  children,
}: BreadcrumbsDialogProps): JSX.Element {
  const { t } = useTranslation();

  const dialogs = Children.toArray(children) as ReactElement<BDItemProps>[];
  const currentDialog = dialogs[index];

  const items = dialogs
    .map((dialog, i) => ({
      label: dialog.props.label,
      command: () => setIndex(i),
    }))
    .slice(0, index + 1);

  function nextItem() {
    if (index < dialogs.length - 1) {
      setIndex((prevIndex) => prevIndex + 1);
    }
  }

  const isOnMobile = useMediaQuery('(max-width: 768px)');

  function onHide() {
    setIndex(0);
    _onHide();
  }

  const closeBtn = <Button label={t('Close')} onClick={onHide} />;

  const footer = currentDialog.props.footer ? (
    <div className="p-d-flex p-jc-between">
      <div>{currentDialog.props.footer({ onHide })}</div>

      <div>{closeBtn}</div>
    </div>
  ) : (
    closeBtn
  );

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header={items[index].label}
      footer={footer}
      maximizable
      maximized={isOnMobile}
      style={{ minWidth: '90%', width: '90%' }}
    >
      <BreadCrumb model={items} className="p-mb-3" />

      {currentDialog.props.additionalContent}

      <BreadcrumbsDialogContext.Provider value={{ nextItem }}>
        {currentDialog}
      </BreadcrumbsDialogContext.Provider>
    </Dialog>
  );
}

export default BreadcrumbsDialog;
