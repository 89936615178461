import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import { ContextMenuItem } from '../ContextMenu.types';
import styles from './Item.module.scss';

type Props = ContextMenuItem & {
  hideContextMenu?: () => void;
};

function Item({
  label,
  icon,
  action,
  items,
  separator,
  hideContextMenu,
}: Props): JSX.Element {
  if (separator) {
    return <li className={classNames(styles.item, styles.separator)} />;
  }

  function handleOnClick() {
    action?.();

    if (!items?.length) {
      hideContextMenu?.();
    }
  }

  return (
    <li className={styles.item} onClick={handleOnClick}>
      <div className={styles.container}>
        {icon && <FontAwesomeIcon icon={icon} className={styles.icon} />}

        {label}

        {items?.length && (
          <FontAwesomeIcon icon={faAngleRight} className={styles.chevron} />
        )}
      </div>

      {items?.length && (
        <menu>
          {items.map((item, idx) => (
            <Item key={idx} hideContextMenu={hideContextMenu} {...item} />
          ))}
        </menu>
      )}
    </li>
  );
}

export default Item;
