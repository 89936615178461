export const oAuthClientCollectionGuard = 'GET /oauthclients';
export const oAuthClientResourceGuard = 'GET /oauthclients/d+';
export const createOAuthClientResourceGuard = 'POST /oauthclients';
export const updateOAuthClientResourceGuard = 'PUT /oauthclients/d+';
export const deleteOAuthClientResourceGuard = 'DELETE /oauthclients/d+';

export const registerOAuthClientResourceGuard =
  'PUT /oauthclients/registration-request';
export const oAuthClientAvaiableCollectionGuard =
  'GET /oauthclients/registration-available';
