export const functionalities = [
  {
    id: 1,
    name: 'View all clients',
    endpoint: 'GET /clients',
    category: 'Clients',
    roles: ['Admin', 'Courier'],
  },
  {
    id: 2,
    name: 'View client',
    endpoint: 'GET /clients/{id}',
    category: 'Clients',
    roles: ['Admin'],
  },
  {
    id: 3,
    name: 'Add client',
    endpoint: 'PUT /clients',
    category: 'Clients',
    roles: ['Admin'],
  },
  {
    id: 4,
    name: 'Edit client',
    endpoint: 'POST /clients/{id}',
    category: 'Clients',
    roles: ['Admin'],
  },
  {
    id: 5,
    name: 'Delete client',
    endpoint: 'DELETE /clients/{id}',
    category: 'Clients',
    roles: ['Admin'],
  },
  {
    id: 6,
    name: 'View all employees',
    endpoint: 'GET /employees',
    category: 'Employees',
    roles: [
      'Admin',
      'Cash Desk',
      'Courier',
      'Warehouse',
      'Contact Center',
      'Sales & Marketing',
      'Transport (Courier)',
      'Finance',
    ],
  },
  {
    id: 7,
    name: 'View employee',
    endpoint: 'GET /employees/{id}',
    category: 'Employees',
    roles: [
      'Admin',
      'Cash Desk',
      'Courier',
      'Warehouse',
      'Contact Center',
      'Sales & Marketing',
      'Transport (Courier)',
      'Finance',
    ],
  },
  {
    id: 8,
    name: 'Add employee',
    endpoint: 'PUT /employees/{id}',
    category: 'Employees',
    roles: ['Admin'],
  },
  {
    id: 9,
    name: 'Edit employee',
    endpoint: 'POST /employees/{id}',
    category: 'Employees',
    roles: ['Admin'],
  },
  {
    id: 10,
    name: 'Delete employee',
    endpoint: 'DELETE /employees/{id}',
    category: 'Employees',
    roles: ['Admin'],
  },

  {
    id: 11,
    name: 'View courier location in real time',
    endpoint: 'POST /tracking/couriers/location',
    category: 'Courier Map',
    roles: ['Admin', 'Courier', 'Transport (Courier)'],
  },
  {
    id: 12,
    name: 'View pick up attempts on map',
    endpoint: 'POST /pickupattempts',
    category: 'Parcel Map',
    roles: ['Admin', 'Courier', 'Transport (Courier)'],
  },
  {
    id: 13,
    name: 'View delivery attempts on map',
    endpoint: 'POST /deliveryattempts',
    category: 'Parcel Map',
    roles: ['Admin', 'Courier', 'Transport (Courier)'],
  },
  {
    id: 14,
    name: 'View all regions',
    endpoint: 'GET /regions',
    category: 'Regions',
    roles: [
      'Admin',
      'Cash Desk',
      'Courier',
      'Warehouse',
      'Contact Center',
      'Sales & Marketing',
      'Transport (Courier)',
      'Finance',
    ],
  },
  {
    id: 15,
    name: 'Add region',
    endpoint: 'POST /regions/couriers',
    category: 'Regions',
    roles: ['Admin'],
  },
  {
    id: 16,
    name: 'Edit region',
    endpoint: 'PUT /regions/{id}',
    category: 'Regions',
    roles: ['Admin'],
  },
  {
    id: 17,
    name: 'Delete region',
    endpoint: 'DELETE /regions/{id}',
    category: 'Regions',
    roles: ['Admin'],
  },
  {
    id: 18,
    name: 'Add courier in selected region',
    endpoint: 'POST /regions/{id}/couriers',
    category: 'Regions',
    roles: ['Admin', 'Transport (Courier)'],
  },
  {
    id: 19,
    name: 'Edit courier in selected region',
    endpoint: 'POST /regions/{region_id}/couriers/{courier_id}',
    category: 'Regions',
    roles: ['Admin', 'Transport (Courier)'],
  },
  {
    id: 20,
    name: 'View devices of employees',
    endpoint: 'GET /employees/userdevices',
    category: 'Device Management',
    roles: ['Admin'],
  },
  {
    id: 21,
    name: 'Approve device of employee',
    endpoint: 'PUT /userdevicequeues/{id}',
    category: 'Device Management',
    roles: ['Admin'],
  },
  {
    id: 22,
    name: 'Delete device of employee',
    endpoint: 'DELETE /userdevicequeues/{id}',
    category: 'Device Management',
    roles: ['Admin'],
  },
  {
    id: 23,
    name: 'View all list types',
    endpoint: 'GET /importlisttypes',
    category: 'List Types',
    roles: [
      'Admin',
      'Cash Desk',
      'Courier',
      'Warehouse',
      'Contact Center',
      'Sales & Marketing',
      'Transport (Courier)',
      'Finance',
    ],
  },
  {
    id: 24,
    name: 'View list type',
    endpoint: 'GET /importlisttypes/{id}',
    category: 'List Types',
    roles: [
      'Admin',
      'Cash Desk',
      'Courier',
      'Warehouse',
      'Contact Center',
      'Sales & Marketing',
      'Transport (Courier)',
      'Finance',
    ],
  },
  {
    id: 25,
    name: 'Add list type',
    endpoint: 'POST /importlisttypes',
    category: 'List Types',
    roles: ['Admin'],
  },
  {
    id: 26,
    name: 'Edit list type',
    endpoint: 'PUT /importlisttypes/{id}',
    category: 'List Types',
    roles: ['Admin'],
  },
  {
    id: 27,
    name: 'View all imported lists',
    endpoint: 'GET /importlists',
    category: 'Imported Lists',
    roles: [
      'Admin',
      'Cash Desk',
      'Courier',
      'Warehouse',
      'Contact Center',
      'Sales & Marketing',
      'Transport (Courier)',
      'Finance',
    ],
  },
  {
    id: 28,
    name: 'View import log',
    endpoint: 'GET /importlists/{id}/log',
    category: 'Imported Lists',
    roles: [
      'Admin',
      'Cash Desk',
      'Courier',
      'Warehouse',
      'Contact Center',
      'Sales & Marketing',
      'Transport (Courier)',
      'Finance',
    ],
  },
  {
    id: 29,
    name: 'View shipments',
    endpoint: 'GET /importlists/{id}/parcels',
    category: 'Imported Lists',
    roles: [
      'Admin',
      'Cash Desk',
      'Courier',
      'Warehouse',
      'Contact Center',
      'Sales & Marketing',
      'Transport (Courier)',
      'Finance',
    ],
  },
  {
    id: 30,
    name: 'Import list',
    endpoint: 'POST /orders/import',
    category: 'Imported Lists',
    roles: ['Admin'],
  },
  {
    id: 31,
    name: 'Delete list and adresses',
    endpoint: 'DELETE /expeditions/{id}',
    category: 'Imported Lists',
    roles: ['Admin'],
  },
  {
    id: 32,
    name: 'View assigned parcels to couriers',
    endpoint: 'GET /orders/courier/assignments/pickup',
    category: 'Shipment Pickup Assignment',
    roles: ['Admin', 'Cash Desk', 'Courier', 'Transport (Courier)', 'Finance'],
  },
  {
    id: 33,
    name: 'Assigned parcels to courier',
    endpoint: 'PUT /orders/courier/assignments/pickup',
    category: 'Shipment Pickup Assignment',
    roles: ['Admin'],
  },
  {
    id: 34,
    name: 'View all courier liabilities',
    endpoint: 'POST /parceldebts',
    category: 'Courier Liabilities',
    roles: [
      'Admin',
      'Cash Desk',
      'Courier',
      'Warehouse',
      'Contact Center',
      'Sales & Marketing',
      'Transport (Courier)',
      'Finance',
    ],
  },
  {
    id: 35,
    name: 'View courier liabilities',
    endpoint: 'GET /parceldebts/{id}',
    category: 'Courier Liabilities',
    roles: [
      'Admin',
      'Cash Desk',
      'Courier',
      'Warehouse',
      'Contact Center',
      'Sales & Marketing',
      'Transport (Courier)',
      'Finance',
    ],
  },
  {
    id: 36,
    name: 'Add courier liabilities',
    endpoint: 'PUT /parceldebts',
    category: 'Courier Liabilities',
    roles: ['Admin'],
  },
  {
    id: 37,
    name: 'Edit courier liabilities',
    endpoint: 'POST /parceldebts/{id}',
    category: 'Courier Liabilities',
    roles: ['Admin'],
  },
  {
    id: 38,
    name: 'Delete courier liabilities',
    endpoint: 'DELETE /parceldebts/{id}',
    category: 'Courier Liabilities',
    roles: ['Admin'],
  },
  {
    id: 39,
    name: 'View all warehouses',
    endpoint: 'GET /hubs',
    category: 'Warehouses',
    roles: [
      'Admin',
      'Cash Desk',
      'Courier',
      'Warehouse',
      'Contact Center',
      'Sales & Marketing',
      'Transport (Courier)',
      'Finance',
    ],
  },
  {
    id: 40,
    name: 'View warehouse',
    endpoint: 'GET /hubs/{id}',
    category: 'Warehouses',
    roles: [
      'Admin',
      'Cash Desk',
      'Courier',
      'Warehouse',
      'Contact Center',
      'Sales & Marketing',
      'Transport (Courier)',
      'Finance',
    ],
  },
  {
    id: 41,
    name: 'Edit warehouse',
    endpoint: 'POST /hubs/{id}',
    category: 'Warehouses',
    roles: ['Admin', 'Warehouse'],
  },
  {
    id: 42,
    name: 'Delete warehouse',
    endpoint: 'DELETE /hubs/{id}',
    category: 'Warehouses',
    roles: ['Admin', 'Warehouse'],
  },
  {
    id: 43,
    name: 'View all third-party access to the application',
    endpoint: 'GET /oauthclients',
    category: 'OAuth Clients',
    roles: ['Admin'],
  },
  {
    id: 44,
    name: 'Add credentials',
    endpoint: 'POST /oauthclients',
    category: 'OAuth Clients',
    roles: ['Admin'],
  },
  {
    id: 45,
    name: 'Edit credentials',
    endpoint: 'PUT /oauthclients/{id}',
    category: 'OAuth Clients',
    roles: ['Admin'],
  },
  {
    id: 46,
    name: 'View all orders',
    endpoint: 'GET /orders',
    category: 'Orders',
    roles: [
      'Admin',
      'Cash Desk',
      'Courier',
      'Warehouse',
      'Contact Center',
      'Sales & Marketing',
      'Transport (Courier)',
      'Finance',
    ],
  },
  {
    id: 47,
    name: 'View order',
    endpoint: 'GET /orders/{id}',
    category: 'Orders',
    roles: [
      'Admin',
      'Cash Desk',
      'Courier',
      'Warehouse',
      'Contact Center',
      'Sales & Marketing',
      'Transport (Courier)',
      'Finance',
    ],
  },
  {
    id: 48,
    name: 'Add order',
    endpoint: 'POST /orders',
    category: 'Orders',
    roles: ['Admin'],
  },
  {
    id: 49,
    name: 'Edit order',
    endpoint: 'PUT /orders/{id}',
    category: 'Orders',
    roles: ['Admin'],
  },
  {
    id: 50,
    name: 'Delete order',
    endpoint: 'DELETE /orders/{id}',
    category: 'Orders',
    roles: ['Admin'],
  },
  {
    id: 51,
    name: 'View order history',
    endpoint: 'GET /orders/{id}/history',
    category: 'History',
    roles: [
      'Admin',
      'Cash Desk',
      'Courier',
      'Warehouse',
      'Contact Center',
      'Sales & Marketing',
      'Transport (Courier)',
      'Finance',
    ],
  },
  {
    id: 52,
    name: 'View delivery info',
    endpoint: 'GET /orders/{id}/history/delivery',
    category: 'History',
    roles: [
      'Admin',
      'Cash Desk',
      'Courier',
      'Warehouse',
      'Contact Center',
      'Sales & Marketing',
      'Transport (Courier)',
      'Finance',
    ],
  },
  {
    id: 53,
    name: 'View payment info',
    endpoint: 'GET /orders/{id}/history/payment',
    category: 'History',
    roles: [
      'Admin',
      'Cash Desk',
      'Courier',
      'Warehouse',
      'Contact Center',
      'Sales & Marketing',
      'Transport (Courier)',
      'Finance',
    ],
  },
  {
    id: 54,
    name: 'View all deleted orders',
    endpoint: 'GET /orders/deleted/filter',
    category: 'Deleted Orders',
    roles: [
      'Admin',
      'Cash Desk',
      'Courier',
      'Warehouse',
      'Contact Center',
      'Sales & Marketing',
      'Transport (Courier)',
      'Finance',
    ],
  },
  {
    id: 55,
    name: 'View deleted order',
    endpoint: 'GET /orders/deleted/{id}',
    category: 'Deleted Orders',
    roles: [
      'Admin',
      'Cash Desk',
      'Courier',
      'Warehouse',
      'Contact Center',
      'Sales & Marketing',
      'Transport (Courier)',
      'Finance',
    ],
  },
  {
    id: 56,
    name: 'Recreate deleted order',
    endpoint: 'POST /orders/deleted/{seriski_broj}/recreate',
    category: 'Deleted Orders',
    roles: ['Admin'],
  },
];
