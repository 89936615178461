import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Password } from 'primereact/password';
import { useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import useAxiosHook from '../../../../../hooks/useAxiosHook';
import useToastMessage from '../../../../../hooks/useToastMessage';
import { EmployeeCollection } from '../../../../../types/api/employees';
import { Unpacked } from '../../../../../types/util';
import {
  mediumStrenthRegex,
  passwordFeedback,
  strongStrenthRegex,
} from '../../../../../utils/constants/passwords';
import FieldWithErrors from '../../../../Forms/ReactHookForm/FieldWithErrors';
import { PasswordSetErrorResponse } from '../../../Clients/Dialogs/ForceSetPassword/ForceSetPassword.functions';
import {
  FormFields,
  SuccessObj,
  generateErrorMessage,
  getInitialValues,
  getValidationSchema,
} from './ForceSetPassword.functions';
import styles from './ForceSetPassword.module.scss';

const ResetPWFormName = 'reset-pw-form';

type Props = {
  isShown: boolean;
  onHide: () => void;
  employee: Unpacked<EmployeeCollection>;
};

function ForceSetPassword({ isShown, onHide, employee }: Props): JSX.Element {
  const { t } = useTranslation();

  const defaultValues = useMemo(() => getInitialValues(), []);

  const resolver = useMemo(() => yupResolver(getValidationSchema(t)), [t]);

  const methods = useForm({ defaultValues, resolver });

  const { data, error, isLoading, reload } =
    useAxiosHook<SuccessObj, PasswordSetErrorResponse>();

  useToastMessage(data, error, {
    success: {
      title: t('Success'),
      summary: t('Successfully changed password'),
      callback: onHide,
    },
    error: {
      title: t('Force set password error'),
      summary: generateErrorMessage(t, error),
    },
  });

  function handleFormSubmision(values: FormFields) {
    reload({
      url: `/users/${employee?.korisnik_id}/password`,
      data: values,
      method: 'PUT',
    });
  }

  const dialogFooter = (
    <>
      <Button
        type="button"
        label={t('Cancel')}
        className="p-button-text"
        onClick={() => onHide()}
      />

      <Button
        label={isLoading ? t('Resetting...') : t('Reset password')}
        disabled={isLoading}
        type="submit"
        data-cy="submit"
        form={ResetPWFormName}
      />
    </>
  );

  return (
    <Dialog
      header={t('Force set password for employee {{name}}', {
        name: employee?.ime,
      })}
      footer={dialogFooter}
      visible={isShown}
      onHide={onHide}
      className={styles['force-set-pass-dialog']}
    >
      <FormProvider {...methods}>
        <form
          id={ResetPWFormName}
          onSubmit={methods.handleSubmit(handleFormSubmision)}
        >
          <div className="p-fluid">
            <FieldWithErrors name="new_password" label={t('New Password')}>
              <Controller
                name="new_password"
                render={({ field }) => (
                  <Password
                    {...field}
                    feedback
                    header={passwordFeedback(t)}
                    mediumRegex={mediumStrenthRegex as any}
                    strongRegex={strongStrenthRegex as any}
                    toggleMask
                    autoComplete="off"
                    promptLabel={t('Enter a new password')}
                    style={{ width: '100%' }}
                  />
                )}
              />
            </FieldWithErrors>

            <FieldWithErrors
              name="confirm_password"
              label={t('Confirm Password')}
            >
              <Controller
                name="confirm_password"
                render={({ field }) => (
                  <Password
                    {...field}
                    feedback
                    mediumRegex={mediumStrenthRegex as any}
                    strongRegex={strongStrenthRegex as any}
                    toggleMask
                    autoComplete="off"
                    promptLabel={t('Confirm password')}
                    style={{ width: '100%' }}
                  />
                )}
              />
            </FieldWithErrors>
          </div>
        </form>
      </FormProvider>
    </Dialog>
  );
}

export default ForceSetPassword;
