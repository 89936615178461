import classNames from 'classnames';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Dropdown, DropdownChangeParams } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { ChangeEvent, useMemo } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDebounce } from 'use-lodash-debounce';

import useAxiosHook from '../../../hooks/useAxiosHook';
import { useEndpointGuard } from '../../../hooks/useEndpointGuard';
import useSearchQueryDropdownParam from '../../../hooks/useSearchQueryDropdownParam';
import useSearchQueryParam from '../../../hooks/useSearchQueryParam';
import * as vehiclesGuards from '../../../utils/constants/auth/vehicles';
import { httpQueryObject } from '../../../utils/helpers/misc';
import { getSearchQueryParam } from '../../../utils/helpers/searchQuery';
import FiltersCounter from '../Components/Filters/FiltersCounter';

function useTableFilters(page: number, limit: number) {
  const { t } = useTranslation();
  const modelFilterGuard = useEndpointGuard(vehiclesGuards.modelFilter);
  const brandFilterGuard = useEndpointGuard(vehiclesGuards.brandFilter);
  const employeeFilterGuard = useEndpointGuard(vehiclesGuards.employeeFilter);
  const location = useLocation();
  const { data: modelsData } = useAxiosHook<any[]>('/vehicle-models', {
    skipWhen: !modelFilterGuard,
  });
  const { data: brandsData } = useAxiosHook<any[]>('/vehicle-manufacturers', {
    skipWhen: !brandFilterGuard,
  });
  const { data: employeesData } = useAxiosHook<{ id: number; name: string }[]>(
    '/vehicles/employees',
    { skipWhen: !employeeFilterGuard }
  );

  const [brand, setBrand] = useState<string | null>(() =>
    brandFilterGuard
      ? getSearchQueryParam(location.search, 'brand') ?? null
      : null
  );
  const [model, setModel] = useState<string | null>(() =>
    modelFilterGuard
      ? getSearchQueryParam(location.search, 'model') ?? null
      : null
  );
  const [regPlates, setRegPlates] = useState<string>(
    () => getSearchQueryParam(location.search, 'regPlates') ?? ''
  );
  const [active, setActive] = useState<string | null>(
    () => getSearchQueryParam(location.search, 'active') ?? null
  );
  const [assignedEmployee, setAssignedEmployee] = useState<string | null>(() =>
    employeeFilterGuard
      ? getSearchQueryParam(location.search, 'employee') ?? null
      : null
  );

  const regPlatesDebounce = useDebounce(regPlates, 300);

  const models = useMemo(
    () =>
      modelsData?.map((model) => ({ label: model.name, value: model.id })) ??
      [],
    [modelsData]
  );
  const brands = useMemo(
    () =>
      brandsData?.map((brand) => ({ label: brand.name, value: brand.id })) ??
      [],
    [brandsData]
  );
  const employees = useMemo(
    () =>
      employeesData?.map((employee) => ({
        label: employee.name,
        value: employee.id,
      })) ?? [],
    [employeesData]
  );
  const statuses = useMemo(
    () => [
      { label: t('Active.IT'), value: '1' },
      { label: t('Inactive'), value: '0' },
    ],
    [t]
  );

  useSearchQueryParam('regPlates', regPlatesDebounce);
  useSearchQueryDropdownParam('brand', brand, setBrand, brands);
  useSearchQueryDropdownParam('model', model, setModel, models);
  useSearchQueryDropdownParam('active', active, setActive, statuses);
  useSearchQueryDropdownParam(
    'employee',
    assignedEmployee,
    setAssignedEmployee,
    employees
  );

  useEffect(() => {
    if (!modelFilterGuard) {
      setModel(null);
    }
  }, [modelFilterGuard]);
  useEffect(() => {
    if (!brandFilterGuard) {
      setBrand(null);
    }
  }, [brandFilterGuard]);
  useEffect(() => {
    if (!employeeFilterGuard) {
      setAssignedEmployee(null);
    }
  }, [employeeFilterGuard]);

  const httpFiltersObj = useMemo(
    () =>
      httpQueryObject({
        marka_id: brand,
        model_id: model,
        registarska_tablicka: regPlatesDebounce,
        aktivno: active,
        rabotnik_id: assignedEmployee,
        page,
        limit,
      }),
    [active, assignedEmployee, brand, limit, model, page, regPlatesDebounce]
  );

  const basicFiltersActiveFilterCount = Object.values({
    brand,
    model,
    regPlatesDebounce,
    active,
    assignedEmployee,
  }).filter(Boolean).length;

  const filters = useMemo<JSX.Element>(
    () => (
      <Accordion multiple activeIndex={[0]}>
        <AccordionTab
          disabled
          headerTemplate={
            <FiltersCounter
              description={t('Basic filters')}
              counter={basicFiltersActiveFilterCount}
            />
          }
        >
          {brandFilterGuard && (
            <div className="sidebar_filter">
              <label htmlFor="brand">{t('Brand')}</label>
              <Dropdown
                id="brand"
                value={brand}
                onChange={(e: DropdownChangeParams): void =>
                  setBrand(e.target.value)
                }
                options={brands}
                placeholder={t('Select a brand')}
                showClear
                className={classNames({
                  sidebar_filter_active: brand,
                })}
              />
            </div>
          )}
          {modelFilterGuard && (
            <div className="sidebar_filter">
              <label htmlFor="model">{t('Model')}</label>
              <Dropdown
                id="model"
                value={model}
                onChange={(e: DropdownChangeParams): void =>
                  setModel(e.target.value)
                }
                options={models}
                placeholder={t('Select a model')}
                showClear
                className={classNames({
                  sidebar_filter_active: model,
                })}
              />
            </div>
          )}
          <div className="sidebar_filter">
            <label htmlFor="regPlates">{t('Registration Plates')}</label>
            <InputText
              id="regPlates"
              value={regPlates}
              onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                let val = e.target.value.replace(/\s+/g, '-');
                if (e.target.value.length > 10) val = val.slice(0, 10);
                setRegPlates(val);
              }}
              placeholder={t('Enter registration plates')}
              className={classNames({
                sidebar_filter_active: regPlates,
              })}
            />
          </div>
          {employeeFilterGuard && (
            <div className="sidebar_filter">
              <label htmlFor="assignedEmployee">{t('Assigned Employee')}</label>
              <Dropdown
                id="assignedEmployee"
                value={assignedEmployee}
                onChange={(e: DropdownChangeParams): void =>
                  setAssignedEmployee(e.target.value)
                }
                options={employees}
                placeholder={t('Select an employee')}
                showClear
                className={classNames({
                  sidebar_filter_active: assignedEmployee,
                })}
              />
            </div>
          )}
          <div className="sidebar_filter">
            <label htmlFor="active">{t('Active.IT')}</label>
            <Dropdown
              id="active"
              value={active}
              onChange={(e: DropdownChangeParams): void =>
                setActive(e.target.value)
              }
              options={statuses}
              placeholder={t('Select status')}
              showClear
              className={classNames({
                sidebar_filter_active: active,
              })}
            />
          </div>
        </AccordionTab>
      </Accordion>
    ),
    [
      active,
      assignedEmployee,
      brand,
      brandFilterGuard,
      brands,
      employeeFilterGuard,
      employees,
      model,
      modelFilterGuard,
      models,
      regPlates,
      statuses,
      basicFiltersActiveFilterCount,
      t,
    ]
  );

  const headerFiltersCount = useMemo(
    () => basicFiltersActiveFilterCount,
    [basicFiltersActiveFilterCount]
  );

  function resetAllFilters() {
    setBrand(null);
    setModel(null);
    setRegPlates('');
    setActive(null);
    setAssignedEmployee(null);
  }

  return {
    filters,
    resetAllFilters,
    httpFiltersObj,
    headerFiltersCount,
  };
}

export default useTableFilters;
