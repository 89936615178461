import { Skeleton } from 'primereact/skeleton';
import { useTranslation } from 'react-i18next';

function HubsReportSkeleton() {
  const { t } = useTranslation();
  return (
    <>
      <div className="hub-report-cart">
        <div className="hub-report-cart-info">
          <h3
            className="hub-report-cart-title"
            style={{
              marginLeft: '10px',
              marginRight: '15px',
            }}
          >
            <Skeleton width="120px" height="25px" />
          </h3>
          <div className="hub-report-cart-details">
            <div style={{ marginBottom: '5px' }}>
              {t(`Working hours`)}:{' '}
              <span>
                <Skeleton width="100px" height="15px" />
              </span>
            </div>
            <div style={{ marginBottom: '5px' }}>
              {t('Location')}:{' '}
              <span>
                <Skeleton width="100px" height="15px" />
              </span>
            </div>
            <div style={{ marginBottom: '5px' }}>
              {t('Phone')}:{' '}
              <span>
                <Skeleton width="100px" height="15px" />
              </span>
            </div>
          </div>
        </div>
        <div className="hub-reports">
          <div className="hub-report">
            <table className="hub-reports-table">
              <thead>
                <tr>
                  <th colSpan={10}>{t('regular')}</th>
                </tr>
                <tr>
                  <th colSpan={4}>{t('Package')} </th>
                  <th colSpan={6}>{t('Letter')}</th>
                  <th colSpan={1}>{t('Redemption')}</th>
                </tr>
                <tr>
                  <th colSpan={2}>{t('Regular')} </th>
                  <th colSpan={2}>{t('Oversized')}</th>
                  <th colSpan={2}>{t('Unregistered')} </th>
                  <th colSpan={2}>{t('Registered')}</th>
                  <th colSpan={2}>{t('Personal Delivery')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{t('Assigned.SHE')}</td>
                  <td>
                    <Skeleton width="25px" height="15px" />
                  </td>
                  <td>{t('Assigned.SHE')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Assigned.SHE')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Assigned.SHE')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Assigned.SHE')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Assigned.SHE')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                </tr>
                <tr>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="hub-report">
            <table className="hub-reports-table">
              <thead>
                <tr>
                  <th colSpan={10}>{t('express')}</th>
                </tr>
                <tr>
                  <th colSpan={4}>{t('Package')} </th>
                  <th colSpan={6}>{t('Letter')}</th>
                  <th colSpan={1}>{t('Redemption')}</th>
                </tr>
                <tr>
                  <th colSpan={2}>{t('Regular')} </th>
                  <th colSpan={2}>{t('Oversized')}</th>
                  <th colSpan={2}>{t('Unregistered')} </th>
                  <th colSpan={2}>{t('Registered')}</th>
                  <th colSpan={2}>{t('Personal Delivery')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{t('Assigned.SHE')}</td>
                  <td>
                    {' '}
                    <Skeleton width="25px" height="15px" />
                  </td>
                  <td>{t('Assigned.SHE')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Assigned.SHE')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Assigned.SHE')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Assigned.SHE')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Assigned.SHE')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                </tr>
                <tr>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="hub-report-cart">
        <div className="hub-report-cart-info">
          <h3
            className="hub-report-cart-title"
            style={{ marginLeft: '10px', marginRight: '15px' }}
          >
            <Skeleton width="120px" height="25px" />
          </h3>
          <div className="hub-report-cart-details">
            <div style={{ marginBottom: '5px' }}>
              {t(`Warehouse's working hours`)}:{' '}
              <span>
                <Skeleton width="100px" height="15px" />
              </span>
            </div>
            <div style={{ marginBottom: '5px' }}>
              {t('Location')}:{' '}
              <span>
                <Skeleton width="100px" height="15px" />
              </span>
            </div>
            <div style={{ marginBottom: '5px' }}>
              {t('Phone')}:{' '}
              <span>
                <Skeleton width="100px" height="15px" />
              </span>
            </div>
          </div>
        </div>
        <div className="hub-reports">
          <div className="hub-report">
            <table className="hub-reports-table">
              <thead>
                <tr>
                  <th colSpan={10}>{t('regular')}</th>
                </tr>
                <tr>
                  <th colSpan={4}>{t('Package')} </th>
                  <th colSpan={6}>{t('Letter')}</th>
                  <th colSpan={1}>{t('Redemption')}</th>
                </tr>
                <tr>
                  <th colSpan={2}>{t('Regular')} </th>
                  <th colSpan={2}>{t('Oversized')}</th>
                  <th colSpan={2}>{t('Unregistered')} </th>
                  <th colSpan={2}>{t('Registered')}</th>
                  <th colSpan={2}>{t('Personal Delivery')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{t('Assigned.SHE')}</td>
                  <td>
                    {' '}
                    <Skeleton width="25px" height="15px" />
                  </td>
                  <td>{t('Assigned.SHE')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Assigned.SHE')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Assigned.SHE')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Assigned.SHE')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Assigned.SHE')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                </tr>
                <tr>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="hub-report">
            <table className="hub-reports-table">
              <thead>
                <tr>
                  <th colSpan={10}>{t('express')}</th>
                </tr>
                <tr>
                  <th colSpan={4}>{t('Package')} </th>
                  <th colSpan={6}>{t('Letter')}</th>
                  <th colSpan={1}>{t('Redemption')}</th>
                </tr>
                <tr>
                  <th colSpan={2}>{t('Regular')} </th>
                  <th colSpan={2}>{t('Oversized')}</th>
                  <th colSpan={2}>{t('Unregistered')} </th>
                  <th colSpan={2}>{t('Registered')}</th>
                  <th colSpan={2}>{t('Personal Delivery')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{t('Assigned.SHE')}</td>
                  <td>
                    {' '}
                    <Skeleton width="25px" height="15px" />
                  </td>
                  <td>{t('Assigned.SHE')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Assigned.SHE')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Assigned.SHE')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Assigned.SHE')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Assigned.SHE')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                </tr>
                <tr>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="hub-report-cart">
        <div className="hub-report-cart-info">
          <h3
            className="hub-report-cart-title"
            style={{ marginLeft: '10px', marginRight: '15px' }}
          >
            <Skeleton width="120px" height="25px" />
          </h3>
          <div className="hub-report-cart-details">
            <div style={{ marginBottom: '5px' }}>
              {t(`Warehouse's working hours`)}:{' '}
              <span>
                <Skeleton width="100px" height="15px" />
              </span>
            </div>
            <div style={{ marginBottom: '5px' }}>
              {t('Location')}:{' '}
              <span>
                <Skeleton width="100px" height="15px" />
              </span>
            </div>
            <div style={{ marginBottom: '5px' }}>
              {t('Phone')}:{' '}
              <span>
                <Skeleton width="100px" height="15px" />
              </span>
            </div>
          </div>
        </div>
        <div className="hub-reports">
          <div className="hub-report">
            <table className="hub-reports-table">
              <thead>
                <tr>
                  <th colSpan={10}>{t('regular')}</th>
                </tr>
                <tr>
                  <th colSpan={4}>{t('Package')} </th>
                  <th colSpan={6}>{t('Letter')}</th>
                  <th colSpan={1}>{t('Redemption')}</th>
                </tr>
                <tr>
                  <th colSpan={2}>{t('Regular')} </th>
                  <th colSpan={2}>{t('Oversized')}</th>
                  <th colSpan={2}>{t('Unregistered')} </th>
                  <th colSpan={2}>{t('Registered')}</th>
                  <th colSpan={2}>{t('Personal Delivery')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{t('Assigned.SHE')}</td>
                  <td>
                    {' '}
                    <Skeleton width="25px" height="15px" />
                  </td>
                  <td>{t('Assigned.SHE')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Assigned.SHE')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Assigned.SHE')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Assigned.SHE')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Assigned.SHE')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                </tr>
                <tr>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="hub-report">
            <table className="hub-reports-table">
              <thead>
                <tr>
                  <th colSpan={10}>{t('express')}</th>
                </tr>
                <tr>
                  <th colSpan={4}>{t('Package')} </th>
                  <th colSpan={6}>{t('Letter')}</th>
                  <th colSpan={1}>{t('Redemption')}</th>
                </tr>
                <tr>
                  <th colSpan={2}>{t('Regular')} </th>
                  <th colSpan={2}>{t('Oversized')}</th>
                  <th colSpan={2}>{t('Unregistered')} </th>
                  <th colSpan={2}>{t('Registered')}</th>
                  <th colSpan={2}>{t('Personal Delivery')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{t('Assigned.SHE')}</td>
                  <td>
                    {' '}
                    <Skeleton width="25px" height="15px" />
                  </td>
                  <td>{t('Assigned.SHE')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Assigned.SHE')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Assigned.SHE')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Assigned.SHE')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Assigned.SHE')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                </tr>
                <tr>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="hub-report-cart">
        <div className="hub-report-cart-info">
          <h3
            className="hub-report-cart-title"
            style={{ marginLeft: '10px', marginRight: '15px' }}
          >
            <Skeleton width="120px" height="25px" />
          </h3>
          <div className="hub-report-cart-details">
            <div style={{ marginBottom: '5px' }}>
              {t(`Warehouse's working hours`)}:{' '}
              <span>
                <Skeleton width="100px" height="15px" />
              </span>
            </div>
            <div style={{ marginBottom: '5px' }}>
              {t('Location')}:{' '}
              <span>
                <Skeleton width="100px" height="15px" />
              </span>
            </div>
            <div style={{ marginBottom: '5px' }}>
              {t('Phone')}:{' '}
              <span>
                <Skeleton width="100px" height="15px" />
              </span>
            </div>
          </div>
        </div>
        <div className="hub-reports">
          <div className="hub-report">
            <table className="hub-reports-table">
              <thead>
                <tr>
                  <th colSpan={10}>{t('regular')}</th>
                </tr>
                <tr>
                  <th colSpan={4}>{t('Package')} </th>
                  <th colSpan={6}>{t('Letter')}</th>
                  <th colSpan={1}>{t('Redemption')}</th>
                </tr>
                <tr>
                  <th colSpan={2}>{t('Regular')} </th>
                  <th colSpan={2}>{t('Oversized')}</th>
                  <th colSpan={2}>{t('Unregistered')} </th>
                  <th colSpan={2}>{t('Registered')}</th>
                  <th colSpan={2}>{t('Personal Delivery')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{t('Assigned.SHE')}</td>
                  <td>
                    {' '}
                    <Skeleton width="25px" height="15px" />
                  </td>
                  <td>{t('Assigned.SHE')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Assigned.SHE')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Assigned.SHE')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Assigned.SHE')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Assigned.SHE')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                </tr>
                <tr>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="hub-report">
            <table className="hub-reports-table">
              <thead>
                <tr>
                  <th colSpan={10}>{t('express')}</th>
                </tr>
                <tr>
                  <th colSpan={4}>{t('Package')} </th>
                  <th colSpan={6}>{t('Letter')}</th>
                  <th colSpan={1}>{t('Redemption')}</th>
                </tr>
                <tr>
                  <th colSpan={2}>{t('Regular')} </th>
                  <th colSpan={2}>{t('Oversized')}</th>
                  <th colSpan={2}>{t('Unregistered')} </th>
                  <th colSpan={2}>{t('Registered')}</th>
                  <th colSpan={2}>{t('Personal Delivery')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{t('Assigned.SHE')}</td>
                  <td>
                    {' '}
                    <Skeleton width="25px" height="15px" />
                  </td>
                  <td>{t('Assigned.SHE')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Assigned.SHE')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Assigned.SHE')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Assigned.SHE')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Assigned.SHE')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                </tr>
                <tr>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="hub-report-cart">
        <div className="hub-report-cart-info">
          <h3
            className="hub-report-cart-title"
            style={{ marginLeft: '10px', marginRight: '15px' }}
          >
            <Skeleton width="120px" height="25px" />
          </h3>
          <div className="hub-report-cart-details">
            <div style={{ marginBottom: '5px' }}>
              {t(`Warehouse's working hours`)}:{' '}
              <span>
                <Skeleton width="100px" height="15px" />
              </span>
            </div>
            <div style={{ marginBottom: '5px' }}>
              {t('Location')}:{' '}
              <span>
                <Skeleton width="100px" height="15px" />
              </span>
            </div>
            <div style={{ marginBottom: '5px' }}>
              {t('Phone')}:{' '}
              <span>
                <Skeleton width="100px" height="15px" />
              </span>
            </div>
          </div>
        </div>
        <div className="hub-reports">
          <div className="hub-report">
            <table className="hub-reports-table">
              <thead>
                <tr>
                  <th colSpan={10}>{t('regular')}</th>
                </tr>
                <tr>
                  <th colSpan={4}>{t('Package')} </th>
                  <th colSpan={6}>{t('Letter')}</th>
                  <th colSpan={1}>{t('Redemption')}</th>
                </tr>
                <tr>
                  <th colSpan={2}>{t('Regular')} </th>
                  <th colSpan={2}>{t('Oversized')}</th>
                  <th colSpan={2}>{t('Unregistered')} </th>
                  <th colSpan={2}>{t('Registered')}</th>
                  <th colSpan={2}>{t('Personal Delivery')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{t('Assigned.SHE')}</td>
                  <td>
                    {' '}
                    <Skeleton width="25px" height="15px" />
                  </td>
                  <td>{t('Assigned.SHE')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Assigned.SHE')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Assigned.SHE')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Assigned.SHE')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Assigned.SHE')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                </tr>
                <tr>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="hub-report">
            <table className="hub-reports-table">
              <thead>
                <tr>
                  <th colSpan={10}>{t('express')}</th>
                </tr>
                <tr>
                  <th colSpan={4}>{t('Package')} </th>
                  <th colSpan={6}>{t('Letter')}</th>
                  <th colSpan={1}>{t('Redemption')}</th>
                </tr>
                <tr>
                  <th colSpan={2}>{t('Regular')} </th>
                  <th colSpan={2}>{t('Oversized')}</th>
                  <th colSpan={2}>{t('Unregistered')} </th>
                  <th colSpan={2}>{t('Registered')}</th>
                  <th colSpan={2}>{t('Personal Delivery')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{t('Assigned.SHE')}</td>
                  <td>
                    {' '}
                    <Skeleton width="25px" height="15px" />
                  </td>
                  <td>{t('Assigned.SHE')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Assigned.SHE')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Assigned.SHE')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Assigned.SHE')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Assigned.SHE')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                </tr>
                <tr>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                  <td>{t('Received.THEM')}</td>
                  <td>{<Skeleton width="25px" height="15px" />}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default HubsReportSkeleton;
