import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-lodash-debounce';

import useAxiosHook from '../../../../hooks/useAxiosHook';
import useDialogVisibility from '../../../../hooks/useDialogVisibility';
import usePageTitle from '../../../../hooks/usePageTitle';
import useTableColumns from '../../../../hooks/useTableColumns';
import useTableState from '../../../../hooks/useTableState';
import useToastMessage from '../../../../hooks/useToastMessage';
import {
  HandOverOrdersToCustomer,
  OrderCustomerPickupCollection,
  OrderCustomerPickupCollectionQueryParams,
} from '../../../../types/api/orders';
import { debounceTimeout } from '../../../../utils/constants/misc';
import { sequential } from '../../../../utils/helpers/functions';
import { queryString } from '../../../../utils/helpers/http';
import { tryInt } from '../../../../utils/helpers/parse';
import Table from '../../../DataTable/Table/Table';
import Grid from '../../../Grid/Grid';
import MainContent from '../../../Grid/MainContent';
import SidePanels from '../../../Grid/SidePanels';
import ViewActiveOrderDialog from '../../Orders/Dialogs/View/ViewActiveOrderDialog';
import { SingleOrder } from '../../Orders/Orders.functions';
// import DocumentDownloadDialog from './Dialogs/DocumentDownload/DocumentDownloadDialog';
import HandOverDialog from './Dialogs/HandOver/HandOverDialog';
import {
  additionalColumnProperties,
  getColumnHeadersMap,
  tableStorageKey,
} from './HandoverToCustomer.functions';
import { ClientFilterOption, Column, Row } from './HandoverToCustomer.types';
import Actions from './Sidebar/Actions';
import Filters from './Sidebar/Filters';

function HandoverToCustomer(): JSX.Element {
  const { t } = useTranslation();

  usePageTitle(t('Order Handover to Customer'));

  // Filters
  const [clientFilter, setClientFilter] = useState('');
  const [clientFilterObj, setClientFilterObj] =
    useState<ClientFilterOption | null>(null);

  const [barcodeFilter, setBarcodeFilter] = useState('');

  const debouncedBarcodeFilter = useDebounce(barcodeFilter, debounceTimeout);

  // Table
  const {
    tableRef,
    page,
    setPage,
    limit,
    setLimit,
    sortField,
    setSortField,
    sortOrder,
    setSortOrder,
    selectionMultiple,
    setSelectionMultiple,
    contextMenuSelection,
    setContextMenuSelection,
  } = useTableState<Row>(tableStorageKey);

  const columnHeadersMap = useMemo(() => getColumnHeadersMap(t), [t]);

  const { selectedColumns, setSelectedColumns, columnOptions, columns } =
    useTableColumns<Column>(
      page,
      limit,
      'hand_over_to_customer',
      columnHeadersMap,
      columnHeadersMap,
      useMemo(() => additionalColumnProperties(t), [t])
    );

  const { data, error, isLoading, reload } =
    useAxiosHook<OrderCustomerPickupCollection>(
      '/orders/customer-pickup' +
        queryString<OrderCustomerPickupCollectionQueryParams>({
          page,
          limit,
          recipient_id: clientFilterObj?.id ?? '',
          serial_number: debouncedBarcodeFilter,
        }),
      { skipWhen: !clientFilterObj?.id }
    );

  // Single order dialog
  const {
    show: showViewSingleOrderDialog,
    hide: hideViewSingleOrderDialog,
    isVisible: isViewSingleOrderDialogVisible,
  } = useDialogVisibility();

  const {
    data: viewSingleOrderData,
    error: viewSingleOrderError,
    isLoading: isViewSingleOrderLoading,
  } = useAxiosHook<SingleOrder>(`/orders/${contextMenuSelection?.order_id}`, {
    skipWhen:
      contextMenuSelection?.order_id === undefined ||
      !isViewSingleOrderDialogVisible,
  });

  useToastMessage(undefined, viewSingleOrderError, {
    error: {
      summary: t('An error occured while adding order.'),
      callback: () => hideViewSingleOrderDialog,
    },
  });

  // Hand over dialog
  const {
    show: showHandOverDialog,
    hide: hideHandOverDialog,
    isVisible: isHandOverDialogVisible,
  } = useDialogVisibility();

  const handOverDialogRequest = useAxiosHook<HandOverOrdersToCustomer>();
  const { data: handOverDialogData, error: handOverDialogError } =
    handOverDialogRequest;

  useToastMessage(handOverDialogData, handOverDialogError, {
    success: {
      summary: t('Successfully handed over the selected orders!'),
      callback: () => sequential(reload, hideHandOverDialog),
      // sequential(reload, hideHandOverDialog, showDocumentDownloadDialog),
    },
    error: {
      summary: t('An error occured while handing over the selected orders.'),
      callback: hideHandOverDialog,
    },
  });

  // Document download dialog
  // const {
  //   show: showDocumentDownloadDialog,
  //   hide: hideDocumentDownloadDialog,
  //   isVisible: isDocumentDownloadDialogVisible,
  // } = useDialogVisibility();

  return (
    <div>
      <h2 className="title">{t('Handover To Customer In The Warehouse')}</h2>
      <p className="subtitle">{t('Hand over orders to customer')}</p>

      <ViewActiveOrderDialog
        visible={isViewSingleOrderDialogVisible}
        data={viewSingleOrderData}
        isLoading={isViewSingleOrderLoading}
        onHide={hideViewSingleOrderDialog}
      />

      <HandOverDialog
        visible={isHandOverDialogVisible}
        onHide={hideHandOverDialog}
        selectionMultiple={selectionMultiple}
        totalOrdersAvailableForClient={tryInt(data?.pagination.total) ?? 0}
        clientFilterObj={clientFilterObj}
        request={handOverDialogRequest}
      />

      {/* TODO: show this dialog after the document is available in the Reports API */}
      {/* <DocumentDownloadDialog
        visible={isDocumentDownloadDialogVisible}
        onHide={hideDocumentDownloadDialog}
        clientFilterObj={clientFilterObj}
        handOverId={handOverDialogData?.id}
      /> */}

      <Grid>
        <SidePanels>
          <Filters
            clientFilter={clientFilter}
            setClientFilter={setClientFilter}
            clientFilterObj={clientFilterObj}
            setClientFilterObj={setClientFilterObj}
            barcodeFilter={barcodeFilter}
            setBarcodeFilter={setBarcodeFilter}
          />

          <Actions
            onHandOverBtnClick={showHandOverDialog}
            disabled={!clientFilterObj || !selectionMultiple.length}
          />
        </SidePanels>

        <MainContent>
          <Table
            tableRef={tableRef}
            columns={columns}
            data={clientFilterObj !== null ? data : undefined}
            isLoading={isLoading}
            hasError={!!error}
            reload={reload}
            headerTitle={t('Orders')}
            emptyMessage={
              !clientFilterObj
                ? t('Please select a client to hand over shipments to!')
                : !data?.data.length
                ? t('No orders are available for the selected client.')
                : undefined
            }
            setPage={setPage}
            setLimit={setLimit}
            rows={limit}
            selectionMode="multiple"
            selectionPageOnly
            selection={selectionMultiple}
            setSelection={setSelectionMultiple}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
            sortField={sortField}
            setSortField={setSortField}
            storageString={tableStorageKey}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            columnOptions={columnOptions}
            onRowDoubleClick={showViewSingleOrderDialog}
            contextMenuSelection={contextMenuSelection}
            setContextMenuSelection={setContextMenuSelection}
          />
        </MainContent>
      </Grid>
    </div>
  );
}

export default HandoverToCustomer;
