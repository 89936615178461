import { TFunction } from 'react-i18next';

import { LabelValue } from '../../../types/options';
import { TableColumnHeadersMap, TableColumns } from '../../../types/util';
import { emptyCell, noColumnStyle } from '../../../utils/constants/tables';
import { dateCell } from '../../../utils/helpers/dataTable';
import { withTrailingEllipsis } from '../../../utils/helpers/formatting';
import { contextMenuModel } from '../../../utils/helpers/primereact';
import OverlayHoverPanel from '../../DataTable/OverlayHoverPanel/OverlayHoverPanel';

// !!!
// Some properties do not exist in route collection/resource endpoints!
// !!!
export type Route = {
  id: number;
  name: string | null;
  label: string | null;
  description: string | null;
  uri: string | null;
  http_method: string | null;
  created_at: string | null;
  modified_at: string | null;
  created_by_full_name: string | null;
  modified_by_full_name: string | null;
  route_category_name: string | null;
  roles: { clientType: number; privacy: string; roles: number[] }[];
};

type Row = Route;
type Column = TableColumns<
  Pick<
    Row,
    | 'name'
    | 'http_method'
    | 'uri'
    | 'label'
    | 'description'
    | 'created_at'
    | 'modified_at'
    | 'created_by_full_name'
    | 'modified_by_full_name'
    | 'roles'
  >
>;

export const tableStorageKey = 'routes_dataTable';

export function getColumnHeadersMap(
  t: TFunction
): TableColumnHeadersMap<Column> {
  return {
    no: t('No.'),
    name: t('Name'),
    http_method: t('Method'),
    uri: t('Path'),
    label: t('Label'),
    description: t('Description'),
    created_at: t('Created at'),
    modified_at: t('Modified at'),
    created_by_full_name: t('Created by full name'),
    modified_by_full_name: t('Modified by full name'),
    roles: t('Roles assigned'),
  };
}

export function getPrivacyOptions(t: TFunction): LabelValue[] {
  return [
    { label: t('Public'), value: 'public' },
    { label: t('Private'), value: 'private' },
    { label: t('Protected'), value: 'protected' },
  ];
}

export function additionalColumnProperties(column: Column) {
  switch (column) {
    case 'no':
      return noColumnStyle;

    case 'name':
      return {
        style: { width: 350 },
        bodyStyle: { width: 350 },
      };

    case 'http_method':
      return {
        style: { width: 100 },
        bodyStyle: { width: 100 },
      };

    case 'uri':
      return {
        style: { width: 350 },
        bodyStyle: { width: 350 },
      };

    case 'label':
      return {
        style: { width: 180 },
        bodyStyle: { width: 180 },
      };

    case 'description':
      return {
        body: (row: Row) =>
          typeof row[column] === 'string' && row[column]!.trim() ? (
            <OverlayHoverPanel fullText={row[column]}>
              {withTrailingEllipsis(row[column]!, 0, 25)}
            </OverlayHoverPanel>
          ) : (
            emptyCell
          ),
        style: { width: 250 },
        bodyStyle: { width: 250, whiteSpace: 'nowrap' },
      };

    case 'created_at':
    case 'modified_at':
      return {
        body: (row: Row) => dateCell(row[column]),
        style: {
          width: 180,
        },
        bodyStyle: {
          width: 180,
        },
      };

    case 'created_by_full_name':
    case 'modified_by_full_name':
      return {
        style: { width: 280 },
        bodyStyle: { width: 280 },
      };

    case 'roles':
      return {
        style: { width: 150 },
        body: (row: Row) => row.roles?.length ?? 0,
        bodyStyle: { width: 150 },
      };

    default:
      return {};
  }
}

export function generateContextMenu(
  t: TFunction,
  isViewShown: boolean,
  handleView: () => void,
  isEditShown: boolean,
  handleEdit: () => void
) {
  const viewOption = {
    label: t('View route'),
    icon: 'fas fa-search',
    command: handleView,
  };

  const editOption = {
    label: t('Edit route'),
    icon: 'fas fa-edit',
    command: handleEdit,
  };

  return contextMenuModel([
    [[isViewShown, viewOption]],
    [[isEditShown, editOption]],
  ]);
}

export const resourceMockData: Route | undefined = {
  id: 100,
  name: 'occupations_occupation_collection',
  label: null,
  description: '',
  route_category_name: 'Cat one',
  uri: '^/occupations$',
  http_method: 'GET',
  created_by_full_name: 'Someone',
  created_at: '2021-11-16 12:15:52',
  modified_by_full_name: 'Someone',
  modified_at: '2021-11-16 12:15:52',
  roles: [
    {
      clientType: 1,
      privacy: 'private',
      roles: [2, 3, 4],
    },
    {
      clientType: 2,
      privacy: 'public',
      roles: [],
    },
  ],
};
