import dayjs from 'dayjs';
import { Calendar } from 'primereact/calendar';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { useCallback, useEffect, useMemo } from 'react';
import {
  Controller,
  FormProvider,
  UseFormReturn,
  useWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-lodash-debounce';

import useAxiosHook from '../../../../../../hooks/useAxiosHook';
import usePrevious from '../../../../../../hooks/usePrevious';
import useToastMessage from '../../../../../../hooks/useToastMessage';
import { ConfigCollection } from '../../../../../../types/api/configs';
import {
  CourierWorkOrderQueryParams,
  CourierWorkOrderResource,
} from '../../../../../../types/api/couriers';
import {
  ReconciliationEmployeeCollection,
  ReconciliationEmployeeCollectionQueryParams,
} from '../../../../../../types/api/reconciliations';
import { WorkOrderResource } from '../../../../../../types/api/workOrders';
import { LabelValue } from '../../../../../../types/options';
import { Unpacked } from '../../../../../../types/util';
import { debounceTimeout } from '../../../../../../utils/constants/misc';
import { findSingleConfig } from '../../../../../../utils/helpers/api/configs';
import { httpDateFormat } from '../../../../../../utils/helpers/formatting';
import { queryString } from '../../../../../../utils/helpers/http';
import { tryInt, tryString } from '../../../../../../utils/helpers/parse';
import AutoCompleteInput from '../../../../../Forms/AutoCompleteInput/AutoCompleteInput';
import FieldWithErrors from '../../../../../Forms/ReactHookForm/FieldWithErrors';
import { FormFields } from './CreateReconciliationDialog.types';

type Props = {
  methods: UseFormReturn<FormFields>;
  handleFormSubmission: (values: FormFields) => void;
};

function FormContainer({ methods, handleFormSubmission }: Props): JSX.Element {
  const { t } = useTranslation();

  const { control, handleSubmit, setValue, setError } = methods;

  const date = useWatch<FormFields, 'date'>({
    name: 'date',
    control,
  });

  const employee_id = useWatch<FormFields, 'employee_id'>({
    name: 'employee_id',
    control,
  });

  const employee_name = useWatch<FormFields, 'employee_name'>({
    name: 'employee_name',
    control,
  });

  const work_order_id = useWatch<FormFields, 'work_order_id'>({
    name: 'work_order_id',
    control,
  });

  const debounced_work_order_id = useDebounce(work_order_id, debounceTimeout);

  const handleEmployeeFilterChange = useCallback(
    (value: string) => {
      setValue('employee_name', value);
    },
    [setValue]
  );

  const handleEmployeeSelectionChange = useCallback(
    (value: Unpacked<ReconciliationEmployeeCollection> | null) => {
      setValue('employee_id', tryString(value?.employee_id) ?? null);
      setValue('work_order_id', tryString(value?.work_order_id) ?? '');
    },
    [setValue]
  );

  const {
    data: courierWorkOrderData,
    error: courierWorkOrderError,
    isLoading: isCourierWorkOrderLoading,
  } = useAxiosHook<CourierWorkOrderResource>(
    `/couriers/${employee_id}/work-order` +
      queryString<CourierWorkOrderQueryParams>({
        date: httpDateFormat(date),
      }),
    { skipWhen: !employee_id }
  );

  useToastMessage(undefined, courierWorkOrderError, {
    error: {
      summary: t('An error occured while reading work order data.'),
    },
  });

  const prevCourierWorkOrderData = usePrevious(courierWorkOrderData);

  useEffect(() => {
    if (
      !courierWorkOrderData ||
      courierWorkOrderData === prevCourierWorkOrderData ||
      !!Object.keys(courierWorkOrderData).length
    ) {
      return;
    }

    setError('employee_id', {
      type: 'custom',
      message: t(
        "This employee doesn't have a work order created for the selected date."
      ),
    });
  }, [prevCourierWorkOrderData, setError, t, courierWorkOrderData]);

  const {
    data: configsData,
    error: configsError,
    isLoading: isConfigsLoading,
  } = useAxiosHook<ConfigCollection>('/configs');

  useToastMessage(undefined, configsError, {
    error: {
      summary:
        t('An error occured while reading config data.') +
        ' ' +
        t('Only work orders from the current date will be accepted.'),
    },
  });

  const { data: employeesData, isLoading: isEmployeesLoading } =
    useAxiosHook<ReconciliationEmployeeCollection>(
      '/reconciliations/employees' +
        queryString<ReconciliationEmployeeCollectionQueryParams>({
          page: 1,
          limit: 15,
          employee_full_name: employee_name,
          reconciled: 'false',
          work_order_date: httpDateFormat(date),
        })
    );

  const employeeOptions = useMemo<
    LabelValue<Unpacked<ReconciliationEmployeeCollection>>[]
  >(
    () =>
      employeesData?.data.map((employee) => ({
        label: employee.employee_full_name,
        value: employee,
      })) ?? [],
    [employeesData?.data]
  );

  const minDate = useMemo<Date>(() => {
    const maxWorkOrderAgeConfig = findSingleConfig(
      configsData,
      'Reconciliation',
      'MaximumWorkorderAgeDays'
    );

    return dayjs()
      .subtract(tryInt(maxWorkOrderAgeConfig?.value) ?? 0, 'days')
      .toDate();
  }, [configsData]);

  const { data: workOrderData, error: workOrderError } =
    useAxiosHook<WorkOrderResource>(`/work-orders/${debounced_work_order_id}`, {
      skipWhen:
        !debounced_work_order_id ||
        typeof debounced_work_order_id !== 'string' ||
        !!employee_id,
    });

  const prevWorkOrderData = usePrevious(workOrderData);
  const prevWorkOrderError = usePrevious(workOrderError);

  useToastMessage(undefined, workOrderError, {
    error: {
      notStatus: [404],
      summary: t('An error occured while reading work order data.'),
    },
  });

  useEffect(() => {
    if (!workOrderData || workOrderData === prevWorkOrderData) {
      return;
    }

    setValue('work_order_id', workOrderData);
    setValue('date', dayjs(workOrderData.date).toDate());
    setValue('employee_name', workOrderData.courier_full_name);
    setValue('employee_id', String(workOrderData.courier_id));
  }, [prevWorkOrderData, setValue, workOrderData]);

  useEffect(() => {
    if (!workOrderError || workOrderError === prevWorkOrderError) {
      return;
    }

    setError('work_order_id', {
      type: 'custom',
      message:
        workOrderError.response?.status === 404
          ? t('Non-existent work order.')
          : t('Error reading work order data.'),
    });
  }, [prevWorkOrderError, setError, setValue, t, workOrderError]);

  const isLoading =
    isConfigsLoading || isEmployeesLoading || isCourierWorkOrderLoading;

  return (
    <FormProvider {...methods}>
      <p className="p-mt-0">
        {t(
          'Please fill out either a combination of date and employee or manually enter a work order.'
        )}
      </p>

      <form
        id="create-reconciliation-form"
        onSubmit={handleSubmit(handleFormSubmission)}
      >
        <div className="p-fluid">
          <FieldWithErrors name="date" label={t('Date')}>
            <Controller
              name="date"
              render={({ field }) => (
                <Calendar
                  name="date"
                  inputId="date"
                  readOnlyInput
                  minDate={minDate}
                  dateFormat="dd/mm/yy"
                  value={field.value}
                  onChange={field.onChange}
                  placeholder={isLoading ? t('Loading...') : ''}
                />
              )}
            />
          </FieldWithErrors>

          <FieldWithErrors name="employee_id" label={t('Employee')}>
            <Controller
              name="employee_id"
              render={({ field }) => (
                <AutoCompleteInput
                  filterValue={employee_name}
                  value={field.value}
                  onFilterChange={handleEmployeeFilterChange}
                  onSelectionChange={handleEmployeeSelectionChange}
                  options={employeeOptions}
                  placeholder={isLoading ? t('Loading...') : ''}
                />
              )}
            />
          </FieldWithErrors>

          <Divider align="center" type="solid" className="p-my-5">
            <b>{t('OR')}</b>
          </Divider>

          <FieldWithErrors name="work_order_id" label={t('Work Order')}>
            <Controller
              name="work_order_id"
              render={({ field }) => (
                <InputText
                  id="work_order_id"
                  name="work_order_id"
                  value={
                    typeof field.value === 'string'
                      ? field.value
                      : (
                          field.value as FormFields['work_order_id'] as WorkOrderResource
                        ).id
                  }
                  onChange={field.onChange}
                />
              )}
            />
          </FieldWithErrors>
        </div>
      </form>
    </FormProvider>
  );
}

export default FormContainer;
