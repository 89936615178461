import { PaginatorTemplate } from 'primereact/paginator/paginator';

// TODO: Remove this with the next prime-react upgrade (>= v6.4.1)
export type PaginationChange = {
  first: number;
  rows: number;
  page?: number;
  pageCount?: number;
};

export const paginatorTemplate: PaginatorTemplate =
  'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown';
