import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
import { ColumnProps } from 'primereact/column';
import { TFunction } from 'react-i18next';

import { OrderPaymentService } from '../../../../../enums/orders';
import {
  AddCustomerReceptionOrderByBarcodeRequestPayload,
  AddCustomerReceptionOrderByBarcodeResourceError,
} from '../../../../../types/api/customerReceptions';
import { ContextMenuItem } from '../../../../../types/primereact';
import { TableColumnHeadersMap } from '../../../../../types/util';
import {
  emptyCell,
  noColumnStyle,
} from '../../../../../utils/constants/tables';
import { orderCollectionPaymentsFilterByService } from '../../../../../utils/helpers/api/orders';
import { currencyFormat } from '../../../../../utils/helpers/formatting';
import { mapPick, numericSum } from '../../../../../utils/helpers/functions';
import { tryStringify } from '../../../../../utils/helpers/parse';
import { contextMenuModel } from '../../../../../utils/helpers/primereact';
import { Column, Row } from './CustomerShipmentReception.types';

export const tableStorageKey = 'warehouse_reception_customer_dataTable';

export function getColumnHeadersMap(
  t: TFunction
): TableColumnHeadersMap<Column> {
  return {
    no: t('No.'),
    seriski_broj: t('Serial No.'),
    klient_do_ime: t('Recipient'),
    mesto_do_ime: t('Place to'),
    postage: t('Postage'),
    insuranceTax: t('Insurance tax'),
    returnDocumentFee: t('Return Document fee'),
  };
}

export function additionalColumnProperties(column: Column): ColumnProps {
  switch (column) {
    case 'no':
      return noColumnStyle;

    case 'seriski_broj':
      return {
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
        },
      };

    case 'klient_do_ime':
      return {
        style: {
          width: 250,
        },
        bodyStyle: {
          width: 250,
        },
      };

    case 'mesto_do_ime':
      return {
        style: {
          width: 140,
        },
        bodyStyle: {
          width: 140,
        },
      };

    case 'postage':
      return {
        body: (row: Row) => {
          const filteredPayments = orderCollectionPaymentsFilterByService(
            row.payments,
            OrderPaymentService.Postage
          );

          if (filteredPayments === null) {
            return emptyCell;
          }

          return currencyFormat(
            numericSum(mapPick(filteredPayments, 'usluga_id')),
            { showCurrency: true }
          );
        },
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
        },
      };

    case 'insuranceTax':
      return {
        body: (row: Row) => {
          const filteredPayments = orderCollectionPaymentsFilterByService(
            row.payments,
            OrderPaymentService.Insurance
          );

          if (filteredPayments === null) {
            return emptyCell;
          }

          return currencyFormat(
            numericSum(mapPick(filteredPayments, 'usluga_id')),
            { showCurrency: true }
          );
        },
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
        },
      };

    case 'returnDocumentFee':
      return {
        body: (row: Row) => {
          const filteredPayments = orderCollectionPaymentsFilterByService(
            row.payments,
            OrderPaymentService.ReturnDocument
          );

          if (filteredPayments === null) {
            return emptyCell;
          }

          return currencyFormat(
            numericSum(mapPick(filteredPayments, 'usluga_id')),
            { showCurrency: true }
          );
        },
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
        },
      };
  }
}

export function generateContextMenu({
  t,
  handleView,
  handleEdit,
  handleDiscard,
}: {
  t: TFunction;
  handleView: () => void;
  handleEdit: () => void;
  handleDiscard: () => void;
}): ContextMenuItem[] {
  const viewOption = {
    label: t('View'),
    icon: 'fas fa-search',
    command: handleView,
  };

  const editOption = {
    label: t('Edit'),
    icon: 'fas fa-edit',
    command: handleEdit,
  };

  const discardOption = {
    label: t('Discard'),
    icon: 'fas fa-trash',
    command: handleDiscard,
  };

  return contextMenuModel([
    [
      [true, viewOption],
      [true, editOption],
    ],
    [[true, discardOption]],
  ]);
}

export function getBarcodeScanErrorMessageFromResponse(
  t: TFunction,
  error: AxiosError<AddCustomerReceptionOrderByBarcodeResourceError> | undefined
): string {
  const { request, response } = error ?? {};

  // 400
  if (typeof response?.data.error_description === 'string') {
    return response?.data.error_description;
  }

  const { barcode, customer_reception_id } =
    response?.data.error_description ?? {};

  // 422
  if (barcode) {
    return barcode === 'nonexistent'
      ? t('No order or import list were found with the supplied barcode.')
      : t('Invalid barcode supplied!');
  }

  if (customer_reception_id === 'invalid_parameter') {
    return t(
      'The supplied order or import list does not belong to the selected reception!'
    );
  }

  // Miscommunication error b\w f-end and b-end!
  if (typeof response?.status === 'number' && response.status < 500) {
    Sentry.captureMessage(
      `Customer shipment reception, probably a miscommunication error: ${tryStringify(
        error ?? {}
      )}`,
      Sentry.Severity.Error
    );
  }

  return t('An error occurred for barcode {{barcode}}', {
    barcode: (
      request as AddCustomerReceptionOrderByBarcodeRequestPayload | undefined
    )?.barcode,
  });
}
