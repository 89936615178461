import './DeleteHubDialog.scss';

import { Button } from 'primereact/button';
import { Dialog, DialogProps } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';

import useAxiosHook from '../../../../../../hooks/useAxiosHook';
import Content, { ContentProps } from './Content';

type Props = Omit<
  DialogProps,
  'header' | 'footer' | 'resizable' | 'style' | 'className'
> &
  Pick<ContentProps, 'selection' | 'successCallback'>;

function DeleteHubDialog({
  // Content
  selection,
  successCallback,
  // Dialog
  onHide,
  ...restDialogProps
}: Props): JSX.Element {
  const { t } = useTranslation();

  const { data, error, reload, isLoading } = useAxiosHook(
    {
      url: `/hubs/${selection?.id}`,
      method: 'DELETE',
    },
    {
      skipWhen: true,
    }
  );

  const isHubSetAsANativeHubForSomeEmployees = error?.response?.status === 412;

  const header = !isHubSetAsANativeHubForSomeEmployees
    ? t('Delete {{name}}', { name: selection?.name ?? '' })
    : t('We had trouble deleting {{name}}.', { name: selection?.name ?? '' });

  const footer = !isHubSetAsANativeHubForSomeEmployees ? (
    <>
      <Button
        type="button"
        label={t('Cancel')}
        onClick={onHide}
        className="p-button-text"
      />

      <Button
        type="button"
        label={t('Delete')}
        onClick={() => reload()}
        className="p-button-danger"
        data-cy="submit-btn"
      />
    </>
  ) : (
    <Button label={t('Close')} onClick={onHide} />
  );

  return (
    <Dialog
      header={header}
      footer={footer}
      onHide={onHide}
      resizable={false}
      style={{ maxWidth: '50rem' }}
      className="hubs-page-delete-hub-dialog"
      {...restDialogProps}
    >
      <Content
        data={data}
        error={error}
        isLoading={isLoading}
        isHubSetAsANativeHubForSomeEmployees={
          isHubSetAsANativeHubForSomeEmployees
        }
        selection={selection}
        successCallback={successCallback}
        errorCallback={onHide}
      />
    </Dialog>
  );
}

export default DeleteHubDialog;
