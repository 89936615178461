import { TFunction } from 'react-i18next';

import { noColumnStyle } from '../../../utils/constants/tables';
import { dateCell } from '../../../utils/helpers/dataTable';
import { currencyFormat } from '../../../utils/helpers/formatting';

export type CouriersCashData = {
  order_id: number;
  serial_number: string;
  date_pickup: string;
  date_delivery: string;
  courier_id: number;
  courier_name: string;
  client_from_id: number;
  client_from_name: string;
  place_from: string;
  address_from: string;
  contact_from: string;
  phone_from: string;
  client_to_id: number;
  client_to_name: string;
  place_to: string;
  address_to: string;
  contact_to: string;
  phone_to: string;
  weight: string;
  redemption: string;
  sender_cash: string;
  recipient_cash: string;
};

export const tableStorageKey = 'couriersCash_dataTable';

export function getColumnHeadersMap(
  t: TFunction
): Record<
  | 'no'
  | keyof Omit<
      CouriersCashData,
      'order_id' | 'courier_id' | 'client_from_id' | 'client_to_id'
    >,
  string
> {
  return {
    no: t('No.'),
    serial_number: t('Serial No.'),
    date_pickup: t('Pick-up Date'),
    date_delivery: t('Delivery Date'),
    courier_name: t('Courier'),
    weight: t('Weight'),
    redemption: t('Redemption'),
    sender_cash: t('Sender Cash'),
    recipient_cash: t('Recipient Cash'),
    client_from_name: t('Sender'),
    place_from: t('Place From'),
    address_from: t('Address From'),
    contact_from: t('Sender Contact'),
    phone_from: t('Phone From'),
    client_to_name: t('Recipient'),
    place_to: t('Place To'),
    address_to: t('Address To'),
    contact_to: t('Contact To'),
    phone_to: t('Phone To'),
  };
}

export function additionalColumnOptions(column: string) {
  switch (column) {
    case 'no':
      return noColumnStyle;

    case 'date_pickup':
    case 'date_delivery':
      return {
        body: (row: CouriersCashData) => dateCell(row[column]),
        style: { width: 200 },
        bodyStyle: { width: 200 },
      };

    case 'sender_cash':
    case 'recipient_cash':
    case 'redemption':
      return {
        body: (row: CouriersCashData) =>
          currencyFormat(row[column], { showCurrency: true }),
        style: { width: 200 },
        bodyStyle: { width: 200 },
      };

    default:
      return {
        style: { width: 200 },
        bodyStyle: { width: 200 },
      };
  }
}
