import pdfMake from 'pdfmake/build/pdfmake';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { TFunction } from 'react-i18next';

import { EmployeeCollection } from '../../../../types/api/employees';
import { getFileName, saveTxtFile } from '../../../../utils/helpers/files';

export function getDownloadButtonItems(
  t: TFunction,
  selection: EmployeeCollection['data'][0] | null,
  qrCode: string
) {
  return [
    {
      label: 'PDF',
      icon: 'fas fa-file-pdf',
      command: () => exportToPdf(t, selection, qrCode),
    },
    {
      label: t('Text'),
      icon: 'fas fa-file-alt',
      command: () => exportToFile(t, selection, qrCode),
    },
  ];
}

function getQrFilename(
  t: TFunction,
  selection: EmployeeCollection['data'][0] | null
) {
  const fileNameIdentifiers: string[] = [
    selection?.ime ?? '',
    selection?.prezime ?? '',
    selection?.app?.name ?? '',
  ].filter(Boolean);

  return getFileName(t('Employees'), [...fileNameIdentifiers, t('QR Code')]);
}

function exportToFile(
  t: TFunction,
  selection: EmployeeCollection['data'][0] | null,
  qrCode: string
) {
  const fileName = getQrFilename(t, selection);

  saveTxtFile(qrCode, `${fileName}.txt`);
}

async function exportToPdf(
  t: TFunction,
  selection: EmployeeCollection['data'][0] | null,
  qrCode: string
) {
  const fileName = getQrFilename(t, selection);

  const docDefinition: TDocumentDefinitions = {
    content: [
      { text: `${t('Employee')}:`, style: 'sectionTitle' },
      `${selection?.ime ?? ''} ${selection?.prezime ?? ''}`,
      '\n',
      { text: `${t('Application')}:`, style: 'sectionTitle' },
      `${selection?.app?.name ?? ''}`,
      '\n',
      { text: t('QR Code'), style: 'sectionTitle' },
      { qr: qrCode, fit: 200, eccLevel: 'L', style: 'qrCode' },
    ],
    styles: {
      sectionTitle: {
        fontSize: 14,
        bold: true,
      },
      qrCode: {
        margin: [0, 7, 0, 0],
      },
    },
    pageMargins: [75, 80, 75, 80],
  };

  pdfMake.createPdf(docDefinition).download(fileName);
}
