import _ from 'lodash';
import { Column } from 'primereact/column';
import { ContextMenu } from 'primereact/contextmenu';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ToastContext from '../../../../../../context/ToastContext';
import useAxiosHook from '../../../../../../hooks/useAxiosHook';
import { useEndpointGuard } from '../../../../../../hooks/useEndpointGuard';
import useMediaQuery from '../../../../../../hooks/useMediaQuery';
import usePrevious from '../../../../../../hooks/usePrevious';
import * as ordersGuards from '../../../../../../utils/constants/auth/orders';
import { emptyCell } from '../../../../../../utils/constants/tables';
import { errorToast } from '../../../../../../utils/helpers/primereact';
import DialogSpinner from '../../../../../Dialogs/DialogSpinner/DialogSpinner';
import CreateEditDialog from '../../../../Orders/Dialogs/CreateEdit/CreateEditDialog';
import ViewActiveOrderDialog from '../../../../Orders/Dialogs/View/ViewActiveOrderDialog';
import { Order } from '../../../../Orders/Orders.functions';
import { BulkOrder } from '../../../BulkOrders.functions';
import {
  additionalColumnProperties,
  generateContextMenu,
} from './ViewShipmentsDialog.functions';

type Props = {
  selectedList: BulkOrder | any;
  isShown: boolean;
  onHide: () => void;
};

function ViewShipmentsDialog({ selectedList, isShown, onHide }: Props) {
  const { t } = useTranslation();
  const isOnMobile = useMediaQuery('(max-width: 768px)');
  const readGuard = useEndpointGuard(ordersGuards.readOrder);
  const editGuard = useEndpointGuard(ordersGuards.editOrder);
  const [isEditDialogVisible, setIsEditDialogVisible] = useState(false);
  const [isViewDialogVisible, setIsViewDialogVisible] = useState(false);
  const { toastRef } = useContext(ToastContext);
  const contextMenuRef = useRef<any>(null);
  const [selectedRow, setSelectedRow] = useState<Order>({} as Order);

  const columnHeadersMap: any = useMemo(
    () => ({
      no: t('No.'),
      seriski_broj: t('Serial No'),
      klient_do_ime: t('Recipient Name'),
      adresa_do: t('Address To'),
      telefon_do: t('Recipient Phone'),
      mobilen_do: t('Recipient Mobile Phone'),
      otkup: t('Redemption'),
    }),
    [t]
  );

  const {
    data,
    isLoading,
    reload: reloadAll,
    error,
  } = useAxiosHook<Order[]>(`/importlists/${selectedList?.id}/parcels`, {
    skipWhen: !isShown || !selectedList?.id,
  });

  const prevError = usePrevious(error);

  useEffect(() => {
    if (!error || error === prevError) {
      return;
    }

    errorToast(
      toastRef,
      t('Error'),
      t('An error occured while reading order data.')
    );
    onHide();
  }, [error, onHide, prevError, t, toastRef]);

  const dataTableFooter = (
    <div className="text-right">
      <span>
        {t('Total')}: {data?.length}{' '}
        {data?.length === 1 ? t('shipment') : t('shipments')}
      </span>
    </div>
  );

  const columns = Object.keys(columnHeadersMap).map((c) => (
    <Column
      key={c}
      header={columnHeadersMap[c]}
      field={c}
      sortable
      {...(c === 'no' ? { body: (_, colData) => colData.rowIndex + 1 } : {})}
      {...(!['no'].includes(c)
        ? {
            body: (rowData) =>
              (typeof rowData[c] === 'string'
                ? rowData[c].trim()
                : rowData[c]) || emptyCell,
          }
        : {})}
      {...additionalColumnProperties(c)}
    />
  ));

  const {
    data: orderData,
    isLoading: isOrderLoading,
    error: orderError,
    reload: reloadOrder,
  } = useAxiosHook(`/orders/${selectedRow.id}`, {
    skipWhen: !isEditDialogVisible && !isViewDialogVisible,
  });

  const prevOrderError = usePrevious(orderError);

  useEffect(() => {
    if (!orderError || orderError === prevOrderError) {
      return;
    }

    errorToast(
      toastRef,
      t('Error'),
      t('An error occured while reading order data.')
    );

    onHide();
  }, [onHide, orderError, prevOrderError, t, toastRef]);

  function showViewDialog() {
    setIsViewDialogVisible(true);
  }

  function hideViewDialog() {
    setIsViewDialogVisible(false);
  }

  function showEditDialog() {
    hideViewDialog();
    setIsEditDialogVisible(true);
  }

  function hideEditDialog() {
    setIsEditDialogVisible(false);
  }

  function reload() {
    reloadAll();
    reloadOrder();
  }

  return (
    <>
      <CreateEditDialog
        visible={isEditDialogVisible}
        data={orderData}
        isEditDialog
        isLoading={isOrderLoading}
        reload={reload}
        onHide={hideEditDialog}
        isSmallScreen={isOnMobile}
      />

      <ViewActiveOrderDialog
        visible={isViewDialogVisible}
        data={orderData}
        isLoading={isOrderLoading}
        onEdit={showEditDialog}
        onHide={hideViewDialog}
      />

      <Dialog
        visible={isShown}
        onHide={onHide}
        header={t('View shipments from {{selectedListName}} imported list', {
          selectedListName: selectedList?.name,
        })}
        maximizable
        maximized={isOnMobile}
        resizable={false}
        className="datatable-dialog"
      >
        {isLoading ? (
          <DialogSpinner />
        ) : (
          <>
            <DataTable
              value={data}
              footer={dataTableFooter}
              scrollable
              rowHover
              selection={selectedRow}
              selectionMode="single"
              onRowDoubleClick={readGuard ? showViewDialog : () => {}}
              onContextMenuSelectionChange={(e) => setSelectedRow(e.value)}
              onSelectionChange={(e) => setSelectedRow(e.value)}
              onContextMenu={(e) =>
                contextMenuRef.current.show(e.originalEvent)
              }
              className="datatable-no-header p-datatable-sm p-datatable-striped row-cursor-pointer"
            >
              {columns}
            </DataTable>
            <ContextMenu
              model={generateContextMenu(
                t,
                readGuard,
                showViewDialog,
                editGuard,
                showEditDialog
              )}
              ref={contextMenuRef}
            />
          </>
        )}
      </Dialog>
    </>
  );
}

export default ViewShipmentsDialog;
