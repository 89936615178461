import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { UseAxiosReturn } from '../../../../../../hooks/useAxiosHook';
import {
  CourierSummaryCollection,
  TransferCourierSummaryCollection,
} from '../../../../../../types/api/orders';
import { tryInt } from '../../../../../../utils/helpers/parse';
import StatsWrapper from '../../../../Components/StatsWrapper/StatsWrapper';
import CommonStateContext from '../../../_AssignmentAndReception/CommonStateContext';

type Props = {
  transferCourierStatsRequest: UseAxiosReturn<TransferCourierSummaryCollection>;
  courierStatsRequest: UseAxiosReturn<CourierSummaryCollection>;
};

function Stats({
  transferCourierStatsRequest: { data: transferCourierStats },
  courierStatsRequest: { data: courierStats },
}: Props): JSX.Element {
  const { t } = useTranslation();

  const { hasTransferCourierWorkOrder, hasCourierWorkOrder, nativeHubId } =
    useContext(CommonStateContext);

  const doBothCouriersHaveWorkOrder =
    hasTransferCourierWorkOrder && hasCourierWorkOrder;

  const transferCourierHubs = transferCourierStats?.[0]?.hubs;
  const transferCourierAvailable = transferCourierHubs?.reduce(
    (acc, hub) => acc + (tryInt(hub.reception.available) ?? 0),
    0
  );

  const transferCourierCompleted = transferCourierHubs?.reduce(
    (acc, hub) => acc + (tryInt(hub.reception.completed) ?? 0),
    0
  );

  const courierHub = courierStats?.[0]?.hubs?.find((h) => h.id === nativeHubId);

  const courierCompleted = tryInt(courierHub?.assignments.completed);

  return (
    <StatsWrapper>
      <p>
        <b>- {t('Transfer Courier')}</b>
        <br />
        {t('Received.THEM')}:{' '}
        <b>
          {doBothCouriersHaveWorkOrder ? transferCourierCompleted ?? '-' : '-'}
        </b>
        <br />
        {t('Available.THEM')}:{' '}
        <b>
          {doBothCouriersHaveWorkOrder ? transferCourierAvailable ?? '-' : '-'}
        </b>
      </p>

      <p>
        <b>- {t('Loading courier')}</b>
        <br />
        {t('Assigned.THEM')}:{' '}
        <b>{doBothCouriersHaveWorkOrder ? courierCompleted ?? '-' : '-'}</b>
      </p>
    </StatsWrapper>
  );
}

export default Stats;
