import './FieldWithErrors.scss';

import classNames from 'classnames';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import { ReactNode } from 'react';

type Props = {
  name: string;
  children: ReactNode;
  childrenWrapperClassName?: string;
  label?: ReactNode | false | null;
  labelClassName?: string;
  includeErrorsFor?: string[];
  className?: string;
};

function FieldWithErrors({
  name,
  children,
  childrenWrapperClassName = '',
  label,
  labelClassName,
  includeErrorsFor = [],
  className = '',
}: Props) {
  const { errors, touched } = useFormikContext<any>();

  const hasError =
    (errors[name] || includeErrorsFor.some((err) => errors[err])) &&
    (touched[name] || includeErrorsFor.some((err) => touched[err]));

  const parentClassName = classNames(
    'p-field field-with-errors',
    {
      invalid: hasError,
    },
    className
  );

  let errorMessage = errors[name];

  if (hasError && !errorMessage) {
    for (let err of includeErrorsFor) {
      if (errors[err]) {
        errorMessage = errors[err];
        break;
      }
    }
  }

  return (
    <div className={parentClassName}>
      {label !== false && (
        <label htmlFor={name} className={labelClassName}>
          {label || _.capitalize(name.replace(/_/g, ' '))}
        </label>
      )}

      <div className={childrenWrapperClassName}>{children}</div>

      {hasError && typeof errorMessage === 'string' && (
        <span className="error">{errorMessage}</span>
      )}
    </div>
  );
}

export default FieldWithErrors;
