import _ from 'lodash';
import { TFunction } from 'react-i18next';
import * as Yup from 'yup';

import { ClientLookupCollection } from '../../../../../types/api/clients';
import { ImportListTypeResource } from '../../../../../types/api/importlisttypes';
import { Unpacked } from '../../../../../types/util';
import { isStreetFreeInputAllowed } from '../../../../../utils/constants/misc';
import {
  conditionallyRequiredField,
  yupRequiredField,
} from '../../../../../utils/helpers/yup';

export type FormValues = {
  // User
  user_id: string;
  municipality: string;
  place: string;
  street: string;
  adresa?: string;
  broj: string;
  vlez: string;
  stan: string;
  // Other settings
  csv: string;
  import_all_or_none: boolean;
  listtype: string;
  osiguruvanje: string;
  otkup: string;
  postarina: string;
  povraten_dokument: string;
  delimiter: string;
  overwrite_existing: boolean;
};

type ApiData = Omit<FormValues, 'municipality' | 'street'> &
  Partial<Pick<FormValues, 'street'>>;

export type ClientOption = Pick<
  Unpacked<ClientLookupCollection>,
  | 'id'
  | 'korisnik_id'
  | 'mesto_id'
  | 'ulica_id'
  | 'adresa'
  | 'broj'
  | 'vlez'
  | 'stan'
>;

export const csvDelimiter = '\t';

export const initialValues: FormValues = {
  user_id: '',
  municipality: '',
  place: '',
  street: '',
  adresa: '',
  broj: '',
  vlez: '',
  stan: '',
  csv: '',
  import_all_or_none: true,
  listtype: '',
  osiguruvanje: '',
  otkup: '',
  postarina: '',
  povraten_dokument: '',
  overwrite_existing: false,
  delimiter: ',',
};

export function toApiData(
  values: FormValues,
  listType: ImportListTypeResource
): ApiData {
  return {
    ..._.omit(values, ['municipality', 'street', 'adresa']),
    ...(isStreetFreeInputAllowed
      ? { adresa: values.adresa }
      : { street: values.street }),
    listtype: listType.name,
  };
}

export function getValidationSchema(
  t: TFunction,
  listType: ImportListTypeResource | null
): Yup.SchemaOf<Partial<FormValues>> {
  return Yup.object().shape({
    user_id: Yup.string().required(yupRequiredField(t, t('Client'))),
    municipality: conditionallyRequiredField(
      Yup.string(),
      listType?.klient_isSender === 1,
      yupRequiredField(t, t('Municipality'))
    ),
    place: conditionallyRequiredField(
      Yup.string(),
      listType?.klient_isSender === 1,
      yupRequiredField(t, t('Place'))
    ),
    street: conditionallyRequiredField(
      Yup.string(),
      !isStreetFreeInputAllowed,
      yupRequiredField(t, t('Street'))
    ),
    adresa: conditionallyRequiredField(
      Yup.string(),
      isStreetFreeInputAllowed,
      yupRequiredField(t, t('Street'))
    ),
    broj: conditionallyRequiredField(
      Yup.string(),
      listType?.klient_isSender === 1,
      yupRequiredField(t, t('Street No.'))
    ),
    vlez: Yup.string(),
    stan: Yup.string(),
    csv: Yup.string().required(yupRequiredField(t, t('File'))),
    listtype: Yup.string().required(
      yupRequiredField(t, t('Batch Order Template'))
    ),
    osiguruvanje: Yup.string(),
    otkup: Yup.string(),
    postarina: Yup.string(),
    povraten_dokument: Yup.string(),
    import_all_or_none: Yup.boolean(),
    overwrite_existing: Yup.boolean(),
    delimiter: Yup.string(),
  });
}
