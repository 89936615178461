import _ from 'lodash';
import { forwardRef, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useAxios from '../../../../hooks/useAxios';
import { httpDateFormat } from '../../../../utils/helpers/formatting';
import ChartContainer from '../ChartContainer';
import { isPeriodStateValid, totalNumber } from '../Dashboard.functions';

function chartDataFromData(t, data) {
  const couriers = data
    ? Object.values(
        // Returns an object of objects
        data.reduce((acc, item) => {
          let accObj = acc[item.id];

          acc[item.id] = {
            name: item.name,
            delivered_parcels:
              parseInt(item.performance.delivered_parcels) +
              parseInt(accObj?.delivered_parcels || 0),
          };

          return acc;
        }, {})
      )
        .filter((item) => item.delivered_parcels > 0)
        .sort((a, b) => b.delivered_parcels - a.delivered_parcels)
    : [];

  return {
    labels: _.map(couriers, 'name'),
    datasets: [
      {
        label: t('Parcels delivered'),
        data: _.map(couriers, 'delivered_parcels'),
        backgroundColor: '#6ebe71',
        hoverBackgroundColor: '#4caf50',
      },
    ],
  };
}

const BestCouriersChart = forwardRef(({ id, children, ...other }, ref) => {
  const { t } = useTranslation();

  const [date, setDate] = useState([]);

  const [period, setPeriod] = useState(() => {
    const savedState = localStorage.getItem(id + '_period');
    const defaultOption = [new Date(), new Date()];

    return savedState && isPeriodStateValid(savedState)
      ? savedState
      : defaultOption;
  });

  const { data, isLoading, reload, hasError } = useAxios(
    '/statistics/courier/data',
    {
      date_from: httpDateFormat(date[0]),
      date_to: httpDateFormat(date[1]),
      group_by: 'day',
    },
    {
      method: 'POST',
      // Calendar can return a single date while selecting a range.
      // Make sure it is always a range
      skipWhen: !date[1],
    }
  );

  const chartData = useMemo(() => chartDataFromData(t, data), [t, data]);

  const chartOptions = useMemo(() => {
    return {
      title: {
        display: !!chartData.datasets[0]?.data?.length,
        position: 'bottom',
        text: t('Total: {{totalNumber}}', {
          totalNumber: totalNumber(chartData),
        }),
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    };
  }, [t, chartData]);

  useEffect(() => {
    if (period) {
      localStorage.setItem(id + '_period', period);
    }
  }, [id, period]);

  return (
    <ChartContainer
      ref={ref}
      id={id}
      title={t('Best couriers')}
      type="bar"
      data={chartData}
      options={chartOptions}
      isLoading={isLoading}
      hasError={hasError}
      date={date}
      setDate={setDate}
      period={period}
      setPeriod={setPeriod}
      onChartRefresh={reload}
      {...other}
    >
      {children}
    </ChartContainer>
  );
});

export default BestCouriersChart;
