import { InputText } from 'primereact/inputtext';
import { Sidebar } from 'primereact/sidebar';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { MenuContext } from '../../../context/MenuContext';
import useScreenSize from '../../../hooks/css/useScreenSize';
import usePrevious from '../../../hooks/usePrevious';
import { ReduxState } from '../../../types/redux';
import { isDevEnv } from '../../../utils/helpers/misc';
import Footer from './Footer/Footer';
import {
  getDevMenuItems,
  getProductionMenuItems,
  renderItems,
} from './SideMenu.helpers';
import styles from './SideMenu.module.scss';

function SideMenu(): JSX.Element {
  const { t } = useTranslation();
  const { setIsMenuExpanded, isMenuExpanded } = useContext(MenuContext);
  const [filter, setFilter] = useState('');
  const { isDesktopScreen } = useScreenSize();
  const permittedRoutes = useSelector<
    ReduxState,
    ReduxState['user']['permitted_routes']
  >((state) => state.user.permitted_routes);

  const prodMenuItems = useMemo(() => getProductionMenuItems(t), [t]);
  const devMenuItems = useMemo(() => getDevMenuItems(t), [t]);
  const pathname = useLocation().pathname;
  const prevPathname = usePrevious(pathname);

  const onHide = useCallback(
    () => setIsMenuExpanded(false),
    [setIsMenuExpanded]
  );

  useEffect(() => {
    // Prevents close upon rerender
    if (pathname === prevPathname || prevPathname === undefined) {
      return;
    }

    if (!isDesktopScreen) onHide();
  }, [isDesktopScreen, onHide, pathname, prevPathname]);

  return isDesktopScreen ? (
    <div
      className={` ${
        isMenuExpanded ? styles.sideMenuExpanded : styles.sideMenu
      } ${styles.sidebarMenu}`}
    >
      <div>
        <div className={styles.itemsWrapper}>
          {renderItems({
            items: prodMenuItems,
            filter,
            permittedRoutes,
            isMenuExpanded: isMenuExpanded,
          })}

          {isDevEnv() && (
            <>
              {isMenuExpanded ? (
                <h5 className={styles.inDevelopmentTitle}>
                  {t('In Development')}
                </h5>
              ) : (
                <h5 className={styles.inDevelopmentTitle}>{t('Dev')}</h5>
              )}
              {renderItems({
                items: devMenuItems,
                filter,
                permittedRoutes,
                ignoreGuards: true,
                isMenuExpanded: isMenuExpanded,
              })}
            </>
          )}
        </div>
      </div>
      <Footer isMenuExpanded={isMenuExpanded} />
    </div>
  ) : (
    <Sidebar
      visible={isMenuExpanded}
      blockScroll
      showCloseIcon={false}
      baseZIndex={999999}
      onHide={onHide}
      className={styles.sideMenuMobile}
    >
      <div className={styles.title}>
        <h2 className="p-m-0 p-p-3 p-text-light">{t('Menu')}</h2>
        <div className="p-fluid p-px-3 p-pb-3">
          <span className="p-input-icon-right">
            <i className="pi pi-search" />
            <InputText
              value={filter}
              placeholder={t('Search')}
              onChange={(e) => setFilter(e.target.value)}
            />
          </span>
        </div>
      </div>

      {renderItems({
        items: prodMenuItems,
        filter,
        permittedRoutes,
        isMenuExpanded: true,
      })}

      {isDevEnv() && (
        <>
          <h5 className={styles.inDevelopmentTitle}>{t('In Development')}</h5>
          {renderItems({
            items: devMenuItems,
            filter,
            permittedRoutes,
            ignoreGuards: true,
            isMenuExpanded: true,
          })}
        </>
      )}
      <Footer isMenuExpanded={isMenuExpanded} />
    </Sidebar>
  );
}

export default SideMenu;
