import { Button } from 'primereact/button';
import { RefObject } from 'react';
import { useTranslation } from 'react-i18next';

import useCanvasDraw from '../../../../../../hooks/useCanvasDraw';

type Props = {
  canvasRef: RefObject<HTMLCanvasElement>;
};

function Canvas({ canvasRef }: Props): JSX.Element {
  const { t } = useTranslation();

  const { canvas, clear } = useCanvasDraw({
    canvasRef,
    width: 500,
    height: 200,
  });

  return (
    <article>
      {canvas}

      <br />

      <Button
        type="button"
        label={t('Clear')}
        onClick={clear}
        className="p-button-sm p-button-danger p-button-outlined"
      />
    </article>
  );
}

export default Canvas;
