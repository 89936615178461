import { Column } from 'primereact/column';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IntBool } from '../../../../../../../enums/booleans';
import { ReconciliationStatus } from '../../../../../../../enums/reconciliations';
import useAxiosHook from '../../../../../../../hooks/useAxiosHook';
import useTableColumns from '../../../../../../../hooks/useTableColumns';
import { UseTableStateReturn } from '../../../../../../../hooks/useTableState';
import useToastMessage from '../../../../../../../hooks/useToastMessage';
import {
  DeliveredShipmentReconciliationCollection,
  PickedUpShipmentCollectionQueryParams,
  ReconciliationResource,
} from '../../../../../../../types/api/reconciliations';
import { WorkOrderResource } from '../../../../../../../types/api/workOrders';
import { queryString } from '../../../../../../../utils/helpers/http';
import Table from '../../../../../../DataTable/Table/Table';
import { additionalRowClassNames } from '../../ShipmentReconciliation.functions';
import { DataRow } from '../../ShipmentReconciliation.types';
import {
  additionalColumnProperties,
  getColumnHeadersMap,
  reconciliationListsCourierPickedUp,
} from './DeliveredTable.functions';

type Props = {
  tableInstance: UseTableStateReturn<DataRow>;
  reconciliationData: ReconciliationResource | undefined;
  workOrderData: WorkOrderResource | undefined;
  shouldDisplayData: boolean;
  exportToExcel: () => void;
  setDeliveredShipments: (p: DataRow[]) => void;
  onClickEditDialog: (p: DataRow) => void;
};

function DeliveredTable({
  tableInstance,
  reconciliationData,
  workOrderData,
  shouldDisplayData,
  exportToExcel,
  setDeliveredShipments,
  onClickEditDialog,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [deliveredShipmentData, setDeliveredShipmentData] =
    useState<DeliveredShipmentReconciliationCollection>();
  const [disabledRows, setDisabledRows] = useState([]);
  const [action, setAction] = useState<string>('');
  const {
    page,
    limit,
    tableRef,
    sortField,
    sortOrder,
    selectionMultiple,
    contextMenuSelection,
    setPage,
    setLimit,
    setSortField,
    setSortOrder,
    setSelectionMultiple,
    setContextMenuSelection,
  } = tableInstance;

  const columnHeadersMap = useMemo(() => getColumnHeadersMap(t), [t]);
  const { columns, columnOptions, selectedColumns, setSelectedColumns } =
    useTableColumns(
      page,
      limit,
      'reconciliationListsCourierPickedUp_dataTable',
      columnHeadersMap,
      columnHeadersMap,
      (c) =>
        additionalColumnProperties(
          t,
          c as keyof typeof columnHeadersMap,
          setContextMenuSelection,
          setAction
        )
    );

  const finalColumns = useMemo<JSX.Element[]>(
    () => [
      ...columns,
      <Column
        key="action-column"
        header={t('Actions')}
        field="actions"
        frozen
        alignFrozen="right"
        {...additionalColumnProperties(
          t,
          'actions',
          setContextMenuSelection,
          setAction
        )}
      />,
    ],
    [columns, t, setContextMenuSelection, setAction]
  );

  useEffect(() => {
    if (action && contextMenuSelection) {
      if (action === 'edit') {
        onClickEditDialog(contextMenuSelection);
      }
      setAction('');
    }
  }, [action, contextMenuSelection, onClickEditDialog]);

  const {
    data: _deliveredShipmentData,
    error: deliveredShipmentError,
    reload: reloadDeliveredShipmentData,
    isLoading: isDeliveredShipmentDataLoading,
  } = useAxiosHook<DeliveredShipmentReconciliationCollection>(
    '/reconciliations/shipments/delivered' +
      queryString<PickedUpShipmentCollectionQueryParams>({
        page: 1,
        limit: 0,
        work_order_id:
          reconciliationData?.status_id !== ReconciliationStatus.Approved
            ? reconciliationData?.work_order_id
            : undefined,
        reconciliation_id:
          reconciliationData?.status_id === ReconciliationStatus.Approved
            ? reconciliationData?.id
            : undefined,
      }),
    {
      skipWhen: !workOrderData?.date || !reconciliationData,
    }
  );

  useEffect(() => {
    const disabled = _deliveredShipmentData?.data.filter(
      (item) => item.is_reconciled === IntBool.True
    );
    setDisabledRows((disabled as any) ?? []);
    setDeliveredShipments(_deliveredShipmentData?.data ?? []);
    setDeliveredShipmentData(_deliveredShipmentData);
    setSortField('status_id');
    setSortOrder(1);
  }, [
    _deliveredShipmentData,
    setDeliveredShipments,
    setSortField,
    setSortOrder,
  ]);

  useToastMessage(undefined, deliveredShipmentError, {
    error: {
      summary: t('An error occured while reading shipment data'),
    },
  });

  return (
    <Table
      tableRef={tableRef}
      columns={finalColumns}
      data={deliveredShipmentData}
      isLoading={isDeliveredShipmentDataLoading}
      hasError={!!deliveredShipmentError}
      reload={reloadDeliveredShipmentData}
      isReloadDisabled={!shouldDisplayData}
      setPage={setPage}
      setLimit={setLimit}
      sortField={sortField}
      rows={limit}
      setSortField={setSortField}
      setSortOrder={setSortOrder}
      setSelection={setSelectionMultiple}
      sortOrder={sortOrder}
      selectionDisabledRows={disabledRows}
      selection={selectionMultiple}
      storageString={reconciliationListsCourierPickedUp}
      selectedColumns={selectedColumns}
      setSelectedColumns={setSelectedColumns}
      columnOptions={columnOptions}
      headerTitle=""
      selectionMode="multiple"
      rowClassName={additionalRowClassNames}
      exportToExcelButton
      onExportToExcelButtonClick={
        deliveredShipmentData?.data.length ? exportToExcel : () => {}
      }
      rebuildTooltip
      scrollHeight="700"
      selectionPageOnly
      minGroupSelection={2}
      contextMenuSelection={contextMenuSelection}
      setContextMenuSelection={setContextMenuSelection}
      paginator={false}
    />
  );
}

export default DeliveredTable;
