import './CreateEditDialog.scss';

import { Form, Formik, FormikProps } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useContext, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import ToastContext from '../../../../../context/ToastContext';
import useAxiosHook from '../../../../../hooks/useAxiosHook';
import useMediaQuery from '../../../../../hooks/useMediaQuery';
import usePrevious from '../../../../../hooks/usePrevious';
import {
  errorToast,
  successToast,
} from '../../../../../utils/helpers/primereact';
import DialogSpinner from '../../../../Dialogs/DialogSpinner/DialogSpinner';
import { CourierLiabilityResource } from '../../CourierLiabilities.functions';
import {
  FormValues,
  createLiabilityToApiData,
  editLiabilityToApiData,
  getInitialValues,
  getValidationSchema,
} from './CreateEditDialog.functions';
import CreateEditDialogForm from './CreateEditDialogForm';

type Props = {
  isShown: boolean;
  data: CourierLiabilityResource | undefined;
  onHide: () => void;
  reload: () => void;
  isLoading: boolean;
  isEditDialog: boolean;
};

function CreateEditDialog({
  isShown,
  data,
  onHide,
  reload,
  isLoading,
  isEditDialog,
}: Props) {
  const { t } = useTranslation();

  const { toastRef } = useContext(ToastContext);

  const formRef = useRef<FormikProps<FormValues>>(null);

  const initialValues = useMemo(
    () => getInitialValues(data, isEditDialog),
    [data, isEditDialog]
  );
  const validationSchema = useMemo(() => getValidationSchema(t), [t]);

  const {
    data: createLiabilityData,
    error: createLiabilityError,
    reload: createLiability,
  } = useAxiosHook(
    {
      url: '/parceldebts',
      method: 'POST',
    },
    { skipWhen: true }
  );

  const previousCreateLiabilityData = usePrevious(createLiabilityData);
  const previousCreateLiabilityError = usePrevious(createLiabilityError);

  useEffect(() => {
    if (
      createLiabilityData &&
      createLiabilityData !== previousCreateLiabilityData
    ) {
      successToast(
        toastRef!,
        t('Success'),
        t("{{courierName}}'s liability has been successfully added.", {
          courierName: formRef.current?.values?.postar_ime,
        })
      );
      onHide();
      reload();
    }
  }, [
    createLiabilityData,
    onHide,
    previousCreateLiabilityData,
    reload,
    t,
    toastRef,
  ]);

  useEffect(() => {
    if (
      createLiabilityError &&
      createLiabilityError !== previousCreateLiabilityError
    ) {
      errorToast(
        toastRef!,
        t('Error'),
        t(
          "An error occured while trying to add {{courierName}}'s liability due to {{errorMessage}}",
          {
            courierName: formRef.current?.values?.postar_ime,
            errorMessage: createLiabilityError?.message,
          }
        )
      );
    }
  }, [createLiabilityError, previousCreateLiabilityError, t, toastRef]);

  const {
    data: editLiabilityData,
    error: editLiabilityError,
    isLoading: isFormSubmissionRequestLoading,
    reload: editLiability,
  } = useAxiosHook(
    {
      url: `/parceldebts/${data?.id}`,
      method: 'PUT',
    },
    { skipWhen: true }
  );

  const previousEditLiabilityData = usePrevious(editLiabilityData);
  const previousEditLiabilityError = usePrevious(editLiabilityError);

  useEffect(() => {
    if (editLiabilityData && editLiabilityData !== previousEditLiabilityData) {
      successToast(
        toastRef!,
        t('Success'),
        t("{{courierLabel}}'s liability has been successfully edited.", {
          courierLabel: data?.postar_ime,
        })
      );
      reload();
    }
  }, [
    data?.postar_ime,
    editLiabilityData,
    previousEditLiabilityData,
    reload,
    t,
    toastRef,
  ]);

  useEffect(() => {
    if (
      editLiabilityError &&
      editLiabilityError !== previousEditLiabilityError
    ) {
      errorToast(
        toastRef!,
        t('Error'),
        t(
          "An error occured while trying to edit {{courierLabel}}'s liability due to {{errorMessage}}",
          {
            courierLabel: data?.postar_ime,
            errorMessage: editLiabilityError.message,
          }
        )
      );
    }
  }, [
    data?.postar_ime,
    editLiabilityError,
    previousEditLiabilityError,
    t,
    toastRef,
  ]);

  function handleFormSubmision(values: FormValues) {
    if (isEditDialog) {
      editLiability({
        data: editLiabilityToApiData(values),
      });
    } else {
      createLiability({
        data: createLiabilityToApiData(values),
      });
    }
  }

  const dialogHeader = isLoading
    ? t('Loading...')
    : isEditDialog
    ? t("Editing {{courierLabel}}'s liability", {
        courierLabel: data?.postar_ime,
      })
    : t('Add liability');

  const dialogFooter = (
    <>
      <Button
        type="button"
        label={t('Cancel')}
        className="p-button-text"
        onClick={onHide}
      />
      <Button
        type="submit"
        label={
          isFormSubmissionRequestLoading
            ? t('Saving...')
            : isEditDialog
            ? t('Save changes')
            : t('Create')
        }
        disabled={isFormSubmissionRequestLoading || isLoading}
        form="create-edit-courier-liability-form"
        data-cy="submit-btn"
      />
    </>
  );

  const isOnMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Dialog
      visible={isShown}
      onHide={onHide}
      header={dialogHeader}
      footer={dialogFooter}
      resizable={false}
      maximizable={isOnMobile}
      maximized={isOnMobile}
      className="courier-liabilities-create-edit-dialog"
    >
      {isLoading ? (
        <div className="p-my-4">
          <DialogSpinner />
        </div>
      ) : (
        <Formik
          innerRef={formRef}
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmision}
        >
          <Form id="create-edit-courier-liability-form">
            <CreateEditDialogForm data={data} isEditDialog={isEditDialog} />
          </Form>
        </Formik>
      )}
    </Dialog>
  );
}

export default CreateEditDialog;
