import { Numeric } from './general';
import { XOR } from './util';

export type WithData<T> = {
  data: T;
};

export type WithPagination<T> = WithData<T> & {
  pagination: {
    current_page: Numeric;
    last_page: Numeric;
    limit: Numeric;
    from: Numeric;
    to: Numeric;
    total: Numeric;
    requested_page: Numeric;
  };
};

export type WithPaginationAndSummary<T, S extends object> =
  WithPagination<T> & { summary: S };

export enum SortingDirection {
  Asc = 0,
  Desc = 1,
}

export type SortingQueryParams<T> = T & {
  sort_by: string[];
  sort_dir: SortingDirection[];
};

export type PaginationQueryParams<T> = T & {
  page: Numeric;
  limit: Numeric;
};

export type ErrorResponse = {
  error?: string;
  request_id?: string;
  error_description?: Record<string, string>;
};

export enum ValidationErrorMessage {
  Empty = 'empty',
  NotString = 'not_string',
  NotNumeric = 'not_numeric',
  NonExistent = 'nonexistent',
  InvalidParameter = 'invalid_parameter',
  Duplicate = 'duplicate',
}

export type MaybeValidationError<
  T extends Record<string, ValidationErrorMessage>
> = XOR<
  // 400
  {
    error_description: string;
  },
  // 422
  {
    error_description: T;
  }
>;
