import { EndpointGuard } from '../../helpers/auth';
import {
  deliveredShipmentReconciliationCollectionGuard,
  employeesShipmentReconciliationCollectionGuard,
  pickedUpShipmentReconciliationCollectionGuard,
  updateShipmentReconciliationCollectionGuard,
} from './_api/shipmentReconciliations';

export const readDelivered = deliveredShipmentReconciliationCollectionGuard;
export const readPickedUp = pickedUpShipmentReconciliationCollectionGuard;
export const couriersFilter = employeesShipmentReconciliationCollectionGuard;

export const updateShipments = updateShipmentReconciliationCollectionGuard;

export const routeGuard: EndpointGuard = {
  oneOf: [readDelivered, readPickedUp],
};
