import dayjs from 'dayjs';
import { Calendar } from 'primereact/calendar';
import { Dropdown, DropdownChangeParams } from 'primereact/dropdown';
import { Dispatch, useMemo } from 'react';
import { useState } from 'react';
import { SetStateAction } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDebounce } from 'use-lodash-debounce';

import useAxiosHook from '../../../../hooks/useAxiosHook';
import useHaveValuesChanged from '../../../../hooks/useHaveValuesChanged';
import useSearchQueryAutoCompleteInputParam from '../../../../hooks/useSearchQueryAutoCompleteInputParam';
import useSearchQueryDateParam from '../../../../hooks/useSearchQueryDateParam';
import useSearchQueryDropdownParam from '../../../../hooks/useSearchQueryDropdownParam';
import {
  EmployeeCollection,
  EmployeeResource,
} from '../../../../types/api/employees';
import { debounceTimeout } from '../../../../utils/constants/misc';
import { dateToFilterText } from '../../../../utils/helpers/dataTable';
import { httpDateFormat } from '../../../../utils/helpers/formatting';
import { queryString } from '../../../../utils/helpers/http';
import {
  getSearchQueryParam,
  tryDateSearchParam,
} from '../../../../utils/helpers/searchQuery';
import TableHeaderFilters from '../../../DataTable/Table/HeaderFilters/TableHeaderFilters';
import {
  OrdinaryFilter,
  RequiredFilter,
  filterByValue,
} from '../../../DataTable/Table/HeaderFilters/TableHeaderFilters.function';
import AutoCompleteInput from '../../../Forms/AutoCompleteInput/AutoCompleteInput';
import { CourierOption } from './Cashdesk.functions';

function useTableFilters(
  page: number,
  setPage: Dispatch<SetStateAction<number>>,
  limit: number
) {
  const { t } = useTranslation();

  const location = useLocation();

  const [headerFiltersCount, setHeadersFilterCount] = useState<number>(0);

  const [dateFrom, setDateFrom] = useState<Date | null>(
    () =>
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'date_from') ?? ''
      ) ?? new Date()
  );

  const [dateTo, setDateTo] = useState<Date | null>(
    () =>
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'date_to') ?? ''
      ) ?? new Date()
  );

  const [groupBy, setGroupBy] = useState<string>('day');

  const [courierFilter, setCourierFilter] = useState('');
  const [courierFilterObj, setCourierFilterObj] =
    useState<CourierOption | null>(null);

  const debouncedCourierFilter = useDebounce(courierFilter, debounceTimeout);

  const { data: courierData, isLoading: isCourierDataLoading } =
    useAxiosHook<EmployeeCollection>(
      '/employees' +
        queryString({
          name: debouncedCourierFilter,
          roles: [3],
          limit: 20,
        })
    );
  const couriersOptions = useMemo(
    () =>
      courierData?.data?.map((courier) => {
        return {
          label: courier.ime + ' ' + courier.prezime,
          value: courier,
        };
      }) ?? [],
    [courierData?.data]
  );

  const groupByOptions = useMemo(() => {
    return [
      { label: t('Day'), value: 'day' },
      { label: t('Month'), value: 'month' },
    ];
  }, [t]);

  const filtersArr = useMemo(
    () => [dateFrom, dateTo, courierFilter, groupBy],
    [courierFilter, dateFrom, dateTo, groupBy]
  );

  const haveFiltersChanged = useHaveValuesChanged(filtersArr);

  useEffect(() => {
    setPage(1);
  }, [setPage]);

  useSearchQueryDropdownParam('groupBy', groupBy, setGroupBy, groupByOptions);

  useSearchQueryDateParam('date_from', dateFrom);
  useSearchQueryDateParam('date_to', dateTo);

  useSearchQueryAutoCompleteInputParam<CourierOption, EmployeeResource>({
    param: 'courier',
    filterValue: courierFilter,
    setFilterValue: setCourierFilter,
    filterValueObj: courierFilterObj,
    setFilterValueObj: setCourierFilterObj,
    axiosRequestConfig: (employeeId) => `/employees/${employeeId}`,
    initialFilterValueDataPath: (employeeResource) =>
      `${employeeResource.data.ime} ${employeeResource.data.prezime}`,
    filterValueObjPath: 'id',
    initialDataModifier: (employeeResource) => employeeResource.data,
  });

  const httpFiltersObj = useMemo(
    () => ({
      datum_od: dateFrom ? httpDateFormat(dateFrom) : undefined,
      datum_do: dateTo ? httpDateFormat(dateTo) : undefined,
      courier_id: courierFilterObj?.id,
      groupBy: groupBy,
      page: haveFiltersChanged ? 1 : page,
      limit,
    }),
    [
      courierFilterObj?.id,
      dateFrom,
      dateTo,
      groupBy,
      haveFiltersChanged,
      limit,
      page,
    ]
  );

  const headerFiltersForm = useMemo<JSX.Element>(
    () => (
      <>
        <div className="filter">
          <label htmlFor="filter_date_from">{t('Date From')}</label>
          <Calendar
            value={dateFrom ?? undefined}
            monthNavigator
            yearNavigator
            yearRange={`2009:${dayjs().format('YYYY')}`}
            dateFormat="dd/mm/yy"
            maxDate={new Date()}
            inputId="filter_date_from"
            onChange={(e) => {
              setDateFrom(e.value as Date | null);
            }}
          />
        </div>

        <div className="filter">
          <label htmlFor="filter_date_to">{t('Date To')}</label>
          <Calendar
            value={dateTo ?? undefined}
            monthNavigator
            yearNavigator
            yearRange={`2009:${dayjs().format('YYYY')}`}
            dateFormat="dd/mm/yy"
            maxDate={new Date()}
            inputId="filter_date_to"
            onChange={(e) => {
              setDateTo(e.value as Date | null);
            }}
          />
        </div>

        <div className="filter">
          <label htmlFor="filter_courier">{t('Courier')}</label>
          <AutoCompleteInput
            id="filter_courier"
            filterValue={courierFilter}
            value={courierFilterObj}
            options={couriersOptions}
            onFilterChange={setCourierFilter}
            onSelectionChange={setCourierFilterObj}
            isLoading={isCourierDataLoading}
          />
        </div>

        <div className="filter">
          <label htmlFor="group_by">{t('Group By')}</label>
          <Dropdown
            id="group_by"
            value={groupBy}
            onChange={(e: DropdownChangeParams): void =>
              setGroupBy(e.target.value)
            }
            options={groupByOptions}
          />
        </div>
      </>
    ),
    [
      courierFilter,
      courierFilterObj,
      couriersOptions,
      dateFrom,
      dateTo,
      groupBy,
      groupByOptions,
      isCourierDataLoading,
      t,
    ]
  );

  const headerFilters = useMemo(() => {
    const required: RequiredFilter[] = [
      {
        label: t('Date'),
        value: dateToFilterText([dateFrom, dateTo]),
      },
    ];

    const ordinary: OrdinaryFilter[] = [
      {
        label: t('Courier'),
        value: courierFilterObj
          ? `${courierFilterObj.ime} ${courierFilterObj.prezime}`
          : '',
        onDelete: () => {
          setCourierFilter('');
          setCourierFilterObj(null);
        },
      },
      {
        label: t('Group By'),
        value:
          groupByOptions.find((item) => item.value === groupBy)?.label ?? '',
        onDelete: () => setGroupBy('day'),
      },
    ].filter(filterByValue);

    setHeadersFilterCount(ordinary.length);

    return <TableHeaderFilters required={required} ordinary={ordinary} />;
  }, [courierFilterObj, dateFrom, dateTo, groupBy, groupByOptions, t]);

  function resetAllFilters() {
    setDateFrom(new Date());
    setDateTo(new Date());
    setCourierFilter('');
    setCourierFilterObj(null);
    setGroupBy('');
  }

  return {
    headerFiltersForm,
    headerFilters,
    resetAllFilters,
    httpFiltersObj,
    headerFiltersCount,
    couriersOptions,
  };
}

export default useTableFilters;
