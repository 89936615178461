import { Field, useFormikContext } from 'formik';
import { Checkbox, CheckboxChangeParams } from 'primereact/checkbox';
import { Dropdown, DropdownChangeParams } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { useCallback } from 'react';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDebounce } from 'use-lodash-debounce';

import { usePhoneOptionsContext } from '../../../../../../context/PhoneOptionsContext';
import useAxiosHook from '../../../../../../hooks/useAxiosHook';
import { ClientLookupCollection } from '../../../../../../types/api/clients';
import { HubCollection } from '../../../../../../types/api/hubs';
import { LabelValue } from '../../../../../../types/options';
import { ReduxState } from '../../../../../../types/redux';
import {
  debounceTimeout,
  isStreetFreeInputAllowed,
} from '../../../../../../utils/constants/misc';
import { invalidPhoneNumberCharactersRegex } from '../../../../../../utils/constants/phoneNumbers';
import { queryString } from '../../../../../../utils/helpers/http';
import { placeItemTemplate } from '../../../../../../utils/helpers/misc';
import { tryString } from '../../../../../../utils/helpers/parse';
import {
  formatMobileNumber,
  formatPhoneNumber,
  getPhoneorMobileNumberRegion,
} from '../../../../../../utils/helpers/phoneNumbers';
import AutoCompleteInput from '../../../../../Forms/AutoCompleteInput/AutoCompleteInput';
import FieldWithErrors from '../../../../../Forms/FieldWithErrors/FieldWithErrors';
import {
  FormValues,
  PlaceCollection,
  Street,
  isNumber,
} from '../CreateEditDialog.functions';

type Props = {
  isOrderLoading: boolean;
  isConstant: boolean;
  hubs: HubCollection | undefined;
  orderIndex?: number;
  isWarehouseReception?: boolean;
  disabled?: boolean;
};

function SenderTab({
  hubs,
  orderIndex = 0,
  isConstant,
  isWarehouseReception,
  disabled,
}: Props) {
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const { t } = useTranslation();

  const debouncedClient = useDebounce(values?.klient_od_ime, debounceTimeout);
  const debouncedPlaceFrom = useDebounce(values?.mesto_od_ime, debounceTimeout);

  const user = useSelector<ReduxState, ReduxState['user']>(
    (state) => state.user
  );

  const employeeHub = hubs?.data.find((hub) => {
    return hub.id == user.employee_hub_id;
  });

  const hubsSuggestions =
    hubs?.data.map((hub) => {
      return { id: hub.id, label: hub.name };
    }) ?? [];

  const { data: clientsData } = useAxiosHook<ClientLookupCollection>(
    '/clients/lookup' +
      queryString({
        ime: !isNumber(debouncedClient ?? '') ? debouncedClient : null,
        telefon: isNumber(debouncedClient ?? '') ? debouncedClient : null,
        page: 1,
        limit: 10,
      }),
    { skipWhen: !!values?.no_sender || !!disabled }
  );

  const clientsSuggestions =
    clientsData?.data?.map((client) => {
      return { label: client.ime, value: String(client.id) };
    }) ?? [];

  const { data: placesData } = useAxiosHook<PlaceCollection>(
    '/places' +
      queryString({
        place: debouncedPlaceFrom,
        page: 1,
        limit: 15,
      })
  );

  const placeSuggestions: LabelValue[] =
    placesData?.data.map((place) => {
      return {
        label: place.place_name,
        value: String(place.place_id),
        postal_code: place.place_postal_code,
        municipality_id: place.municipality_id,
        municipality_name: place.municipality_name,
      };
    }) ?? [];

  const { data: streetsData, isLoading: isLoadingStreetsData } = useAxiosHook<
    Street[]
  >(
    {
      url: `places/${values?.mesto_od_id}/streets`,
    },
    {
      skipWhen: !values?.mesto_od_id,
    }
  );

  const streetOptions = streetsData?.map((street) => ({
    label: street.ime,
    value: String(street.id),
  }));

  const { countryOptionTemplate, languages, selectedCountryTemplate } =
    usePhoneOptionsContext();

  const handleClientChange = useCallback(
    (ime: string) => {
      setFieldValue('klient_od_ime', ime);
    },
    [setFieldValue]
  );

  const handleClientSelect = useCallback(
    (id: string | null) => {
      setFieldValue('klient_od_id', id);

      const sender = clientsData?.data.find(
        (sender) => String(sender.id) === id
      );

      setFieldValue('mesto_od_id', sender?.mesto_id ?? '');
      setFieldValue('mesto_od_ime', sender?.mesto_ime ?? '');
      setFieldValue('opstina_od_id', sender?.opstina_id ?? '');
      setFieldValue('opstina_od_ime', sender?.opstina_ime ?? '');
      setFieldValue('telefon_od', sender?.telefon ?? '');
      setFieldValue(
        'telefon_od_region',
        getPhoneorMobileNumberRegion(sender?.telefon ?? '')
      );
      setFieldValue('mobilen_od', sender?.mobilen ?? '');
      setFieldValue(
        'mobilen_od_region',
        getPhoneorMobileNumberRegion(sender?.mobilen ?? '')
      );
      setFieldValue('broj_od', sender?.broj ?? '');
      setFieldValue('vlez_od', sender?.vlez ?? '');
      setFieldValue('stan_od', sender?.stan ?? '');
      setFieldValue('ulica_od_id', tryString(sender?.ulica_id) ?? '');
      setFieldValue('adresa_od', sender?.adresa ?? '');
    },
    [clientsData?.data, setFieldValue]
  );

  const handlePlaceChange = useCallback(
    (name: string) => {
      setFieldValue('mesto_od_ime', name);
    },
    [setFieldValue]
  );

  const handlePlaceSelectionChange = useCallback(
    (id: string | null) => {
      setFieldValue('mesto_od_id', id);

      if (id) {
        const place = placesData?.data.find(
          (place) => String(place.place_id) === id
        );
        setFieldValue('mesto_od_ime', place?.place_name);
        setFieldValue('opstina_od_id', place?.municipality_id);
        setFieldValue('opstina_od_ime', place?.municipality_name);
      }
    },
    [placesData?.data, setFieldValue]
  );

  return (
    <div>
      {orderIndex !== 0 && isConstant && <div className="constant" />}

      {values?.no_sender ? (
        <FieldWithErrors
          name="klient_od_ime"
          includeErrorsFor={['klient_od_id']}
          label={t('Sender Name')}
        >
          <Field
            as={InputText}
            name={'klient_od_ime'}
            placeholder={t('Required')}
            disabled={disabled}
          />
        </FieldWithErrors>
      ) : (
        <FieldWithErrors
          name="klient_od_ime"
          includeErrorsFor={['klient_od_id']}
          label={t('Search Clients')}
        >
          <AutoCompleteInput
            disabled={disabled}
            placeholder={t('Search by name or phone number')}
            options={clientsSuggestions}
            value={tryString(values.klient_od_id) ?? null}
            filterValue={values.klient_od_ime ?? ''}
            onFilterChange={handleClientChange}
            onSelectionChange={handleClientSelect}
            filterDataCy="sender"
            optionsClassName="data-cy-sender-options"
          />
        </FieldWithErrors>
      )}

      <FieldWithErrors name="no_sender" label={false}>
        <Field
          as={Checkbox}
          name="no_sender"
          inputId="no_sender"
          checked={values.no_sender}
          disabled={isWarehouseReception || disabled}
          onChange={(e: CheckboxChangeParams) => {
            if (e.checked) {
              setFieldValue('klient_od_id', '1');
              setFieldValue('no_sender', true);
            } else {
              setFieldValue('klient_od_id', '');
              setFieldValue('no_sender', false);
            }
          }}
        />

        <label htmlFor="no_sender">{t('Unknown client')}</label>
      </FieldWithErrors>

      <FieldWithErrors name="mesto_od_id" label={t('Place')}>
        <AutoCompleteInput
          disabled={disabled}
          options={placeSuggestions}
          itemTemplate={placeItemTemplate}
          valueTemplate={placeItemTemplate}
          value={tryString(values.mesto_od_id) ?? null}
          filterValue={values.mesto_od_ime ?? ''}
          placeholder={t('Search places')}
          onFilterChange={handlePlaceChange}
          onSelectionChange={handlePlaceSelectionChange}
        />
      </FieldWithErrors>

      <FieldWithErrors
        name="ulica_od_id"
        label={isStreetFreeInputAllowed ? t('Address') : t('Street')}
      >
        {isStreetFreeInputAllowed ? (
          <Field
            as={InputText}
            name="adresa_od"
            id="adresa_od"
            disabled={!values.mesto_od_ime || isLoadingStreetsData}
            maxLength="256"
          />
        ) : (
          <>
            <Field
              as={Dropdown}
              inputId="ulica_od_id"
              name="ulica_od_id"
              filter
              filterInputAutoFocus
              options={streetOptions}
              disabled={
                !values.mesto_od_ime || isLoadingStreetsData || disabled
              }
              placeholder={
                isLoadingStreetsData ? t('Loading...') : t('Select Street')
              }
              onChange={(e: DropdownChangeParams) => {
                if (e.value) {
                  const item = streetOptions?.find((s) => s.value === e.value);
                  setFieldValue('ulica_od_id', e.value);
                  setFieldValue('adresa_od', item?.label);
                }
              }}
              className="p-mb-2"
            />

            <FieldWithErrors
              name="broj_od"
              label={false}
              includeErrorsFor={['vlez_od', 'stan_od']}
            >
              <div className="p-grid">
                <div className="p-col-4">
                  <Field
                    as={InputText}
                    id="broj_od"
                    name="broj_od"
                    placeholder={t('Street No.')}
                  />
                </div>

                <div className="p-col-4">
                  <Field
                    as={InputText}
                    id="vlez_od"
                    name="vlez_od"
                    placeholder={t('Entrance')}
                  />
                </div>

                <div className="p-col-4">
                  <Field
                    as={InputText}
                    id="stan_od"
                    name="stan_od"
                    placeholder={t('Apartment')}
                  />
                </div>
              </div>
            </FieldWithErrors>
          </>
        )}
      </FieldWithErrors>

      <FieldWithErrors
        name="mobilen_od"
        includeErrorsFor={['telefon_od', 'mobilen_od']}
        label={false}
      >
        <label htmlFor="mobilen_od">{t('Mobile number')}</label>

        <div className="p-field p-inputgroup number-group">
          <Field
            as={Dropdown}
            className="mobilen_od_region"
            style={{ padding: 0, width: '5rem' }}
            id="mobilen_od_region"
            name="mobilen_od_region"
            options={languages}
            itemTemplate={countryOptionTemplate}
            valueTemplate={selectedCountryTemplate}
          />

          <Field
            as={InputText}
            id="mobilen_od"
            name="mobilen_od"
            value={formatMobileNumber(
              values.mobilen_od || '',
              values.mobilen_od_region ||
                getPhoneorMobileNumberRegion(values.mobilen_od)
            )}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setFieldValue(
                'mobilen_od',
                e.target.value?.replace(invalidPhoneNumberCharactersRegex, '')
              );
            }}
          />
        </div>

        <label htmlFor="telefon_od">{t('Phone number')}</label>
        <div className="p-field p-inputgroup number-group">
          <Field
            as={Dropdown}
            className="telefon_od_region"
            style={{ padding: 0, width: '5rem' }}
            id="telefon_od_region"
            name="telefon_od_region"
            options={languages}
            itemTemplate={countryOptionTemplate}
            valueTemplate={selectedCountryTemplate}
          />

          <Field
            as={InputText}
            name="telefon_od"
            value={formatPhoneNumber(
              values.telefon_od || '',
              values.telefon_od_region ||
                getPhoneorMobileNumberRegion(values.telefon_od)
            )}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setFieldValue(
                'telefon_od',
                e.target.value?.replace(invalidPhoneNumberCharactersRegex, '')
              );
            }}
          />
        </div>
      </FieldWithErrors>

      <FieldWithErrors name="pickup_hub" label={t('Handover in warehouse')}>
        <div className="p-d-flex p-ai-center">
          <Field
            as={Checkbox}
            name="pickup_location_type_id"
            inputId="pickup_location_type_id"
            checked={values.pickup_location_type_id}
            disabled={isWarehouseReception || disabled}
            className="hub-checkbox"
            onChange={(e: CheckboxChangeParams) => {
              setFieldValue('pickup_location_type_id', e.checked);

              if (!e.checked) {
                setFieldValue('hub_od_id', null);
                return;
              }
              setFieldValue('hub_od_id', employeeHub?.id ?? t('Unknown'));
            }}
          />
          <Field
            as={Dropdown}
            name="hub_od_id"
            filter
            filterBy="label"
            inputId="hub_do_id"
            options={hubsSuggestions}
            optionLabel="label"
            optionValue="id"
            disabled={!values.pickup_location_type_id}
          />
        </div>
      </FieldWithErrors>
    </div>
  );
}

export default SenderTab;
