import { TFunction } from 'react-i18next';

import { EmployeeCollection } from '../../../types/api/employees';
import { Numeric } from '../../../types/general';
import { Unpacked } from '../../../types/util';
import { emptyCell, noColumnStyle } from '../../../utils/constants/tables';
import { dateCell } from '../../../utils/helpers/dataTable';

export type EmployeeOption = Pick<Unpacked<EmployeeCollection>, 'id'>;

export type CourierCompDocument = {
  id: Numeric;
  date: string;
  courier_name: string;
  courier_id: Numeric;
  serial_no: string;
  cashier_id: Numeric;
  cashier_name: string;
};

export type CourierCompDocumentAddresses = {
  id: Numeric;
  comp_doc_id: Numeric;
  employee_created_id?: Numeric;
  employee_create_name: string;
  employee_edit_id?: Numeric;
  employee_edit_name: string;
  shipment_type_id?: Numeric;
  shipment_type_name: string;
  status_id: Numeric;
  status_name: string;
  serial_no: string;
  date_recive: string;
  date_delivery: string;
  recipient_name: string;
  sender_name: string;
  weight?: string;
  redemption?: string;
  price?: string;
  address_from: string;
  address_to: string;
  contact_from: string;
  contact_to: string;
  phone_from: string;
  phone_to: string;
  mobile_from: string;
  mobile_to: string;
  total_shipments: number;
};

export function getCourierCompensationDocColumnHeadersMap(t: TFunction) {
  return {
    no: t('No.'),
    date: t('Date'),
    courier_name: t('Courier'),
    serial_no: t('Serial No'),
    cashier_name: t('Cashier'),
  };
}

export function getCCDAddressesColumnHeadersMap(t: TFunction) {
  return {
    no: t('No.'),
    employee_create_name: t('Employee Create'),
    employee_edit_name: t('Employee Edit'),
    serial_no: t('Serial No.'),
    date_recive: t('Receive Date'),
    date_delivery: t('Delivery Date'),
  };
}

export function additionalCCDColumnProperties(
  column: keyof ReturnType<typeof getCourierCompensationDocColumnHeadersMap>
) {
  switch (column) {
    case 'no':
      return noColumnStyle;

    case 'serial_no':
      return {
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
        },
      };

    case 'courier_name':
    case 'cashier_name':
      return {
        style: {
          width: 250,
        },
        bodyStyle: {
          width: 250,
        },
      };

    case 'date':
      return {
        body: (row: CourierCompDocument) =>
          typeof row[column] === 'string' && !!row[column]?.trim()
            ? dateCell(row[column], true)
            : emptyCell,
        style: {
          width: 140,
        },
        bodyStyle: {
          width: 140,
        },
      };

    default:
      return {};
  }
}

export function additionalCCDAddressColumnProperties(
  column: keyof ReturnType<typeof getCCDAddressesColumnHeadersMap>
) {
  switch (column) {
    case 'no':
      return {
        ...noColumnStyle,
      };

    case 'serial_no':
      return {
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
        },
      };

    case 'employee_create_name':
    case 'employee_edit_name':
      return {
        style: {
          width: 250,
        },
        bodyStyle: {
          width: 250,
        },
      };

    case 'date_recive':
    case 'date_delivery':
      return {
        body: (row: CourierCompDocumentAddresses) =>
          typeof row[column] === 'string' && !!row[column]?.trim()
            ? dateCell(row[column], true)
            : emptyCell,
        style: {
          width: 140,
        },
        bodyStyle: {
          width: 140,
        },
      };

    default:
      return {};
  }
}
