import { TFunction } from 'react-i18next';

import { EmployeeCollection } from '../../../../types/api/employees';
import { Unpacked } from '../../../../types/util';
import { noColumnStyle } from '../../../../utils/constants/tables';
import { dateCell } from '../../../../utils/helpers/dataTable';
import { currencyFormat } from '../../../../utils/helpers/formatting';

export type CourierOption = Pick<
  Unpacked<EmployeeCollection>,
  'id' | 'ime' | 'prezime'
>;

type CashdeskCourier = {
  datum: string;
  id: string;
  postar_ime: string;
  pratki_za_dostava: string;
  dostaveni_pratki: string;
  vrateni_pratki: string;
  adresi_za_dostava: string;
  dostaveni_adresi: string;
  primeni_pratki_vo_magacin: string;
  vneseni_pratki_vo_datacentar: string;
  gotovina_dostava: string;
  gotovina_otkup: string;
  gotovina_priem: string;
  ocekuvana_gotovina_priem: string;
  kusok_visok_priem: string;
  vkupno_razdolzena_gotovina: string;
};

type TotalsRow = {
  [key: string]: number | undefined;
  pratki_za_dostava?: number;
  dostaveni_pratki?: number;
  vrateni_pratki?: number;
  adresi_za_dostava?: number;
  dostaveni_adresi?: number;
  primeni_pratki_vo_magacin?: number;
  vneseni_pratki_vo_datacentar?: number;
  gotovina_dostava?: number;
  gotovina_otkup?: number;
  gotovina_priem?: number;
  ocekuvana_gotovina_priem?: number;
  kusok_visok_priem?: number;
  vkupno_razdolzena_gotovina?: number;
};

export const tableStorageKey = 'cashdesk_dataTable';

export function getColumnHeadersMap(t: TFunction) {
  return {
    no: t('No.'),
    datum: t('Date'),
    id: t('ID'),
    postar_ime: t('Courier'),
    pratki_za_dostava: t('For Delivery'),
    dostaveni_pratki: t('Delivered'),
    vrateni_pratki: t('Returned'),
    adresi_za_dostava: t('Addresses for delivery'),
    dostaveni_adresi: t('Delivered Addresses'),
    primeni_pratki_vo_magacin: t('Warehouse Shipments'),
    vneseni_pratki_vo_datacentar: t('Data Center Shipments'),
    gotovina_dostava: t('Delivery Cash'),
    gotovina_otkup: t('Redemption Cash'),
    gotovina_priem: t('Cash'),
    ocekuvana_gotovina_priem: t('Expected Cash'),
    kusok_visok_priem: t('Plus/Minus Cash'),
    vkupno_razdolzena_gotovina: t('Total Cash'),
  };
}

export function additionalColumnProperties(column: string) {
  switch (column) {
    case 'no':
      return {
        ...noColumnStyle,
      };
    case 'id':
      return {
        body: (row: CashdeskCourier) =>
          row.id === 'hide-no-col-row' ? null : row[column],
        style: { width: 70 },
      };
    case 'datum':
      return {
        body: (row: CashdeskCourier) =>
          row.id === 'hide-no-col-row' ? null : dateCell(row[column], true),
        style: {
          width: 140,
        },
        bodyStyle: {
          width: 140,
        },
      };
    case 'postar_ime':
      return {
        body: (row: CashdeskCourier) =>
          row.id === 'hide-no-col-row' ? 'Total' : row[column],
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
        },
      };
    case 'pratki_za_dostava':
    case 'dostaveni_pratki':
    case 'vrateni_pratki':
      return {
        style: {
          width: 140,
        },
        bodyStyle: {
          width: 140,
        },
      };

    case 'gotovina_priem':
    case 'ocekuvana_gotovina_priem':
    case 'kusok_visok_priem':
      return {
        body: (row: CashdeskCourier) =>
          currencyFormat(row[column], { showCurrency: true }),
        style: { width: 200 },
        bodyStyle: { width: 200 },
      };

    case 'gotovina_dostava':
    case 'gotovina_otkup':
    case 'vkupno_razdolzena_gotovina':
      return {
        body: (row: CashdeskCourier) =>
          currencyFormat(row[column], { showCurrency: true }),
        style: { width: 160 },
        bodyStyle: { width: 160 },
      };

    default:
      return {
        style: { width: 200 },
        bodyStyle: { width: 200 },
      };
  }
}

export function getTotals(data: CashdeskCourier[]) {
  const oldData = [...data];
  let totalsRow: TotalsRow = {};

  const courierObjKeys = Object.keys(oldData[0]).filter(
    (k) =>
      k !== 'datum' && k !== 'id' && k !== 'postar_ime' && k !== 'postar_id'
  );

  courierObjKeys.forEach((k: string) => {
    const sum = oldData.reduce(
      (acc: any, item: any) => acc + parseInt(item[k]),
      0
    );
    totalsRow[k] = sum.toString();
  });

  return { id: 'hide-no-col-row', ...totalsRow };
}

export const mockData = {
  data: [
    {
      datum: '2019-11-06',
      id: '7',
      postar_ime: 'АВДИ ТЕТОВО',
      pratki_za_dostava: '1',
      dostaveni_pratki: '1',
      vrateni_pratki: '0',
      adresi_za_dostava: '1',
      dostaveni_adresi: '1',
      primeni_pratki_vo_magacin: '0',
      vneseni_pratki_vo_datacentar: '0',
      gotovina_dostava: '190.00',
      gotovina_otkup: '9000.00',
      gotovina_priem: '0.00',
      ocekuvana_gotovina_priem: '0.00',
      kusok_visok_priem: '0.00',
      vkupno_razdolzena_gotovina: '9190.00',
    },
    {
      datum: '2019-11-07',
      id: '7',
      postar_ime: 'АВДИ ТЕТОВО',
      pratki_za_dostava: '4',
      dostaveni_pratki: '3',
      vrateni_pratki: '0',
      adresi_za_dostava: '2',
      dostaveni_adresi: '1',
      primeni_pratki_vo_magacin: '8',
      vneseni_pratki_vo_datacentar: '0',
      gotovina_dostava: '0.00',
      gotovina_otkup: '0.00',
      gotovina_priem: '160.00',
      ocekuvana_gotovina_priem: '0.00',
      kusok_visok_priem: '-160.00',
      vkupno_razdolzena_gotovina: '0.00',
    },
    {
      datum: '2019-11-07',
      id: '7',
      postar_ime: 'АВДИ ТЕТОВО',
      pratki_za_dostava: '7',
      dostaveni_pratki: '7',
      vrateni_pratki: '0',
      adresi_za_dostava: '6',
      dostaveni_adresi: '6',
      primeni_pratki_vo_magacin: '2',
      vneseni_pratki_vo_datacentar: '0',
      gotovina_dostava: '188.00',
      gotovina_otkup: '14300.00',
      gotovina_priem: '160.00',
      ocekuvana_gotovina_priem: '0.00',
      kusok_visok_priem: '-160.00',
      vkupno_razdolzena_gotovina: '14488.00',
    },
    {
      datum: '2019-11-08',
      id: '114',
      postar_ime: 'РАМПО ПРИЛЕП',
      pratki_za_dostava: '2',
      dostaveni_pratki: '2',
      vrateni_pratki: '0',
      adresi_za_dostava: '1',
      dostaveni_adresi: '1',
      primeni_pratki_vo_magacin: '0',
      vneseni_pratki_vo_datacentar: '0',
      gotovina_dostava: '0.00',
      gotovina_otkup: '0.00',
      gotovina_priem: '0.00',
      ocekuvana_gotovina_priem: '0.00',
      kusok_visok_priem: '0.00',
      vkupno_razdolzena_gotovina: '0.00',
    },
    {
      datum: '2019-11-09',
      id: '14',
      postar_ime: 'АЛЕКСАНДАР ШТИП',
      pratki_za_dostava: '1',
      dostaveni_pratki: '0',
      vrateni_pratki: '0',
      adresi_za_dostava: '1',
      dostaveni_adresi: '0',
      primeni_pratki_vo_magacin: '0',
      vneseni_pratki_vo_datacentar: '0',
      gotovina_dostava: '0.00',
      gotovina_otkup: '0.00',
      gotovina_priem: '0.00',
      ocekuvana_gotovina_priem: '0.00',
      kusok_visok_priem: '0.00',
      vkupno_razdolzena_gotovina: '0.00',
    },
    {
      datum: '2019-11-09',
      id: '14',
      postar_ime: 'АЛЕКСАНДАР ШТИП',
      pratki_za_dostava: '1',
      dostaveni_pratki: '1',
      vrateni_pratki: '0',
      adresi_za_dostava: '1',
      dostaveni_adresi: '1',
      primeni_pratki_vo_magacin: '0',
      vneseni_pratki_vo_datacentar: '0',
      gotovina_dostava: '0.00',
      gotovina_otkup: '2000.00',
      gotovina_priem: '0.00',
      ocekuvana_gotovina_priem: '0.00',
      kusok_visok_priem: '0.00',
      vkupno_razdolzena_gotovina: '2000.00',
    },
    {
      datum: '2019-11-11',
      id: '25',
      postar_ime: 'ГОРАЗД ЦЕНТАР',
      pratki_za_dostava: '2',
      dostaveni_pratki: '2',
      vrateni_pratki: '0',
      adresi_za_dostava: '2',
      dostaveni_adresi: '2',
      primeni_pratki_vo_magacin: '0',
      vneseni_pratki_vo_datacentar: '0',
      gotovina_dostava: '30.00',
      gotovina_otkup: '2000.00',
      gotovina_priem: '0.00',
      ocekuvana_gotovina_priem: '0.00',
      kusok_visok_priem: '0.00',
      vkupno_razdolzena_gotovina: '2030.00',
    },

    {
      datum: '2019-11-12',
      id: '7',
      postar_ime: 'АВДИ ТЕТОВО',
      pratki_za_dostava: '1',
      dostaveni_pratki: '1',
      vrateni_pratki: '0',
      adresi_za_dostava: '1',
      dostaveni_adresi: '1',
      primeni_pratki_vo_magacin: '0',
      vneseni_pratki_vo_datacentar: '0',
      gotovina_dostava: '190.00',
      gotovina_otkup: '9000.00',
      gotovina_priem: '0.00',
      ocekuvana_gotovina_priem: '0.00',
      kusok_visok_priem: '0.00',
      vkupno_razdolzena_gotovina: '9190.00',
    },
    {
      datum: '2019-11-13',
      id: '7',
      postar_ime: 'АВДИ ТЕТОВО',
      pratki_za_dostava: '4',
      dostaveni_pratki: '3',
      vrateni_pratki: '0',
      adresi_za_dostava: '2',
      dostaveni_adresi: '1',
      primeni_pratki_vo_magacin: '8',
      vneseni_pratki_vo_datacentar: '0',
      gotovina_dostava: '0.00',
      gotovina_otkup: '0.00',
      gotovina_priem: '160.00',
      ocekuvana_gotovina_priem: '0.00',
      kusok_visok_priem: '-160.00',
      vkupno_razdolzena_gotovina: '0.00',
    },
    {
      datum: '2019-11-14',
      id: '7',
      postar_ime: 'АВДИ ТЕТОВО',
      pratki_za_dostava: '7',
      dostaveni_pratki: '7',
      vrateni_pratki: '0',
      adresi_za_dostava: '6',
      dostaveni_adresi: '6',
      primeni_pratki_vo_magacin: '2',
      vneseni_pratki_vo_datacentar: '0',
      gotovina_dostava: '188.00',
      gotovina_otkup: '14300.00',
      gotovina_priem: '160.00',
      ocekuvana_gotovina_priem: '0.00',
      kusok_visok_priem: '-160.00',
      vkupno_razdolzena_gotovina: '14488.00',
    },
    {
      datum: '2019-11-13',
      id: '114',
      postar_ime: 'РАМПО ПРИЛЕП',
      pratki_za_dostava: '2',
      dostaveni_pratki: '2',
      vrateni_pratki: '0',
      adresi_za_dostava: '1',
      dostaveni_adresi: '1',
      primeni_pratki_vo_magacin: '0',
      vneseni_pratki_vo_datacentar: '0',
      gotovina_dostava: '0.00',
      gotovina_otkup: '0.00',
      gotovina_priem: '0.00',
      ocekuvana_gotovina_priem: '0.00',
      kusok_visok_priem: '0.00',
      vkupno_razdolzena_gotovina: '0.00',
    },
    {
      datum: '2019-11-13',
      id: '14',
      postar_ime: 'АЛЕКСАНДАР ШТИП',
      pratki_za_dostava: '1',
      dostaveni_pratki: '0',
      vrateni_pratki: '0',
      adresi_za_dostava: '1',
      dostaveni_adresi: '0',
      primeni_pratki_vo_magacin: '0',
      vneseni_pratki_vo_datacentar: '0',
      gotovina_dostava: '0.00',
      gotovina_otkup: '0.00',
      gotovina_priem: '0.00',
      ocekuvana_gotovina_priem: '0.00',
      kusok_visok_priem: '0.00',
      vkupno_razdolzena_gotovina: '0.00',
    },
    {
      datum: '2019-11-13',
      id: '14',
      postar_ime: 'АЛЕКСАНДАР ШТИП',
      pratki_za_dostava: '1',
      dostaveni_pratki: '1',
      vrateni_pratki: '0',
      adresi_za_dostava: '1',
      dostaveni_adresi: '1',
      primeni_pratki_vo_magacin: '0',
      vneseni_pratki_vo_datacentar: '0',
      gotovina_dostava: '0.00',
      gotovina_otkup: '2000.00',
      gotovina_priem: '0.00',
      ocekuvana_gotovina_priem: '0.00',
      kusok_visok_priem: '0.00',
      vkupno_razdolzena_gotovina: '2000.00',
    },
    {
      datum: '2019-11-13',
      id: '25',
      postar_ime: 'ГОРАЗД ЦЕНТАР',
      pratki_za_dostava: '2',
      dostaveni_pratki: '2',
      vrateni_pratki: '0',
      adresi_za_dostava: '2',
      dostaveni_adresi: '2',
      primeni_pratki_vo_magacin: '0',
      vneseni_pratki_vo_datacentar: '0',
      gotovina_dostava: '30.00',
      gotovina_otkup: '2000.00',
      gotovina_priem: '0.00',
      ocekuvana_gotovina_priem: '0.00',
      kusok_visok_priem: '0.00',
      vkupno_razdolzena_gotovina: '2030.00',
    },
  ],
  pagination: {
    current_page: 1,
    last_page: 1,
    limit: 30,
    from: 1,
    to: 14,
    total: 14,
    requested_page: 10,
  },
};
