import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useContext, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import ToastContext from '../../../../../context/ToastContext';
import useAxiosHook from '../../../../../hooks/useAxiosHook';
import useToastMessage from '../../../../../hooks/useToastMessage';
import { PriceResource } from '../../../../../types/api/prices';
import { EntityIdRouteParams } from '../../../../../types/routing';
import { sequential } from '../../../../../utils/helpers/functions';
import { getChangedValues } from '../../../../../utils/helpers/object';
import { infoToast } from '../../../../../utils/helpers/primereact';
import DialogSpinner from '../../../../Dialogs/DialogSpinner/DialogSpinner';
import Price from '../_Common/Price';
import usePriceTabHelpers from '../_Common/usePriceTabHelpers';
import { getDefaultValues } from './EditDialog.functions';
import {
  FormFields,
  getValidationSchema,
  toApiData,
} from './EditDialog.functions';

type Props = {
  isShown: boolean;
  onHide: () => void;
  reloadCollection: () => void;
};

function EditDialog({ isShown, onHide, reloadCollection }: Props): JSX.Element {
  const { t } = useTranslation();

  const { id } = useParams<EntityIdRouteParams>();

  const { toastRef } = useContext(ToastContext);

  const {
    data: resourceData,
    error: resourceError,
    isLoading: isResourceLoading,
  } = useAxiosHook<PriceResource>(`/prices/${id}`, {
    skipWhen: !isShown || id === undefined,
  });

  useToastMessage(undefined, resourceError, {
    error: {
      summary: t('An error occured while reading price data.'),
      callback: onHide,
    },
  });

  const defaultValues = useMemo(
    () => getDefaultValues(resourceData),
    [resourceData]
  );

  const validationSchema = useMemo(() => getValidationSchema(t), [t]);

  const methods = useForm<FormFields>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const { handleSubmit, reset } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const {
    data: updateData,
    error: updateError,
    isLoading: isUpdateLoading,
    reload: updateReload,
  } = useAxiosHook();

  useToastMessage(updateData, updateError, {
    success: {
      summary: t('The price has been edited successfully.'),
      callback: () => sequential(reloadCollection, onHide),
    },
    error: {
      summary: t('An error occured while editing the price.'),
      callback: onHide,
    },
  });

  function handleFormSubmission(values: FormFields) {
    const apiData = getChangedValues(values, defaultValues);

    // If there are no changes introduced, don't contaminate the API
    if (!Object.keys(apiData).length) {
      infoToast(
        toastRef,
        t('No changes made'),
        t("You haven't made any changes yet.")
      );

      return;
    }

    updateReload({
      url: `/prices/${id}`,
      method: 'PUT',
      data: toApiData(values),
    });
  }

  const dialogFooter = (
    <>
      <Button
        type="button"
        label={t('Close')}
        onClick={onHide}
        className="p-button-text"
      />

      <Button
        type="submit"
        form="prices-edit-price-form"
        label={isUpdateLoading ? t('Updating...') : t('Update')}
        disabled={isResourceLoading || isUpdateLoading}
      />
    </>
  );

  const {
    entitiesRequest: { data: entitiesData, isLoading: isEntitiesLoading },
    functionsRequest: { data: functionsData, isLoading: isFunctionsLoading },
    operatorsRequest: { data: operatorsData, isLoading: isOperatorsLoading },
  } = usePriceTabHelpers();

  return (
    <Dialog
      header={t('Editing {{name}}', { name: resourceData?.data.price })}
      footer={dialogFooter}
      visible={isShown}
      resizable={false}
      onHide={onHide}
      maximizable
      style={{ width: 600, maxWidth: '100%' }}
    >
      <FormProvider {...methods}>
        <form
          id="prices-edit-price-form"
          onSubmit={handleSubmit(handleFormSubmission)}
        >
          {isResourceLoading ? (
            <DialogSpinner />
          ) : (
            <Price
              entitiesData={entitiesData}
              functionsData={functionsData}
              operatorsData={operatorsData}
              isEditorDisabled={
                isEntitiesLoading || isFunctionsLoading || isOperatorsLoading
              }
            />
          )}
        </form>
      </FormProvider>
    </Dialog>
  );
}

export default EditDialog;
