import { Field, FieldArray, useFormikContext } from 'formik';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useTranslation } from 'react-i18next';

import { EmailAction, EmailRecipient } from '../../../../../../../enums/email';
import { FormFields } from '../../CreateEditDialog.types';

type Props = {
  emailAction: EmailAction;
  emailRecipient: EmailRecipient;
};

function Fields({ emailAction, emailRecipient }: Props): JSX.Element {
  const { t } = useTranslation();

  const { values } = useFormikContext<FormFields>();

  return (
    <FieldArray
      name={`email_configuration[${emailAction}][${emailRecipient}]`}
      render={(arrayHelpers) =>
        values.email_configuration[emailAction][emailRecipient].length ? (
          values.email_configuration[emailAction][emailRecipient].map(
            (_, idx) => (
              <div className="p-mb-2 p-fluid p-d-flex p-ai-baseline" key={idx}>
                <span style={{ width: 35 }}>{`${idx + 1}.`}</span>

                <Field
                  name={`email_configuration[${emailAction}][${emailRecipient}][${idx}]`}
                  as={InputText}
                  className="p-mx-2"
                />

                <Button
                  type="button"
                  icon="fas fa-trash"
                  onClick={() => arrayHelpers.remove(idx)}
                  className="p-button-secondary p-button-text"
                />
              </div>
            )
          )
        ) : (
          <p>
            <i>{t('No emails are added yet.')}</i>
          </p>
        )
      }
    />
  );
}

export default Fields;
