import './StepperDialog.scss';

import classNames from 'classnames';
import { Button } from 'primereact/button';
import { Dialog, DialogProps } from 'primereact/dialog';
import { ComponentType, HTMLProps, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import StepperDialogContext, {
  StepperDialogContextType,
} from './StepperDialogContext';

export type Step = {
  title: string | JSX.Element;
  component: ComponentType;
  nextButtonLabel?: (context: StepperDialogContextType) => string;
};

type Props = DialogProps & {
  steps: Step[];
  showStepCounter?: boolean;
  backButtonProps?: HTMLProps<HTMLButtonElement>;
  nextButtonProps?: HTMLProps<HTMLButtonElement>;
  cancelButtonProps?: HTMLProps<HTMLButtonElement>;
  onNextBtnClick?: (context: StepperDialogContextType) => Promise<boolean>;
  onBackBtnClick?: (context: StepperDialogContextType) => Promise<boolean>;
  isLoading?: boolean;
};

function StepperDialog({
  visible,
  steps,
  backButtonProps,
  nextButtonProps,
  cancelButtonProps,
  header,
  showStepCounter = true,
  onHide,
  onNextBtnClick,
  onBackBtnClick,
  isLoading: isLoadingProp,
  className,
  ...other
}: Props): JSX.Element {
  const { t } = useTranslation();

  const [step, setStep] = useState(0);
  const [isLoading, setIsLoading] = useState(isLoadingProp ?? false);

  const isAnythingLoading = isLoadingProp || isLoading;

  const currentStep = steps[step];
  const isLastStep = step === steps.length - 1;
  const StepComponent = steps[step].component;

  const context = useMemo<StepperDialogContextType>(
    () => ({ stepIndex: step, isLastStep, setIsLoading }),
    [isLastStep, step]
  );

  useEffect(() => {
    if (!visible) {
      setStep(0);
    }
  }, [visible]);

  async function handleNextBtnClick(): Promise<void> {
    function handle() {
      if (!isLastStep) {
        setStep((step) => step + 1);
      }
    }

    if (onNextBtnClick) {
      if (await onNextBtnClick(context)) {
        handle();
      }
    } else {
      handle();
    }
  }

  async function handleBackBtnClick(): Promise<void> {
    function handle() {
      if (step > 0) {
        setStep((currentStep) => currentStep - 1);
      }
    }

    if (onBackBtnClick) {
      if (await onBackBtnClick(context)) {
        handle();
      }
    } else {
      handle();
    }
  }

  const dialogHeader = (
    <>
      <span>{header}</span>

      {showStepCounter && (
        <span className="step-counter">
          {t('Step')} {step + 1} {t('of')} {steps.length}
        </span>
      )}
    </>
  );

  const footer = (
    <>
      <Button
        type="button"
        label={t('Cancel')}
        onClick={onHide}
        style={{ float: 'left' }}
        className="p-button-secondary p-button-text"
        data-testid="cancel-btn"
        {...(cancelButtonProps as any)}
      />

      {step > 0 && (
        <Button
          type="button"
          label={isAnythingLoading ? t('Loading...') : t('Back')}
          onClick={handleBackBtnClick}
          disabled={isAnythingLoading}
          icon="fa fa-angle-left"
          className="p-button-text"
          data-testid="back-btn"
          {...(backButtonProps as any)}
        />
      )}

      <Button
        type={isLastStep ? 'submit' : 'button'}
        label={
          isAnythingLoading
            ? t('Loading...')
            : currentStep.nextButtonLabel?.(context) ??
              (isLastStep ? t('Submit') : t('Next'))
        }
        icon={isLastStep ? undefined : 'fas fa-angle-right'}
        iconPos="right"
        disabled={isAnythingLoading}
        onClick={(e) => {
          // Solves a nasty bug that makes the form submit before the last step even renders
          if (!isLastStep) {
            e.preventDefault();
          }

          handleNextBtnClick();
        }}
        className={isLastStep ? 'p-button-outlined' : 'p-button-primary'}
        data-testid="next-btn"
        {...(nextButtonProps as any)}
      />
    </>
  );

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header={dialogHeader}
      footer={footer}
      resizable={false}
      className={classNames('stepper-dialog', className)}
      {...other}
    >
      <StepperDialogContext.Provider value={context}>
        <StepComponent />
      </StepperDialogContext.Provider>
    </Dialog>
  );
}

export default StepperDialog;
