import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useDebounce } from 'use-lodash-debounce';

import { debounceTimeout } from '../../../utils/constants/misc';

function AutoSubmitOnFilterChange({ formRef }) {
  const { values } = useFormikContext();

  const debouncedValues = useDebounce(values, debounceTimeout);

  useEffect(() => {
    formRef.current.handleSubmit();
  }, [debouncedValues, formRef]);

  return null;
}

export default AutoSubmitOnFilterChange;
