import { useFormikContext } from 'formik';

import useAxios from '../../../../../../hooks/useAxios';
import { isStreetFreeInputAllowed } from '../../../../../../utils/constants/misc';
import GeneralInfo from './GeneralInfo';
import Location from './Location';
import RegionsPartner from './RegionsPartner';

function DialogContainer({
  step,
  isEditDialog,
  regionsOptions,
  isLoadingRegions,
  data,
  isNameUsed,
  setIsNameUsed,
  isShown,
}) {
  const { values, setFieldValue } = useFormikContext();

  const { data: municipalities, loading: isLoadingMunicipalities } = useAxios(
    '/municipalities',
    undefined,
    {
      skipWhen: !isShown,
    }
  );

  useAxios(`/places/${data?.place_id}`, undefined, {
    skipWhen: !data?.place_id,
    successCallback: (place) => {
      setFieldValue('place_name', place.ime);
      setFieldValue('place_id', data?.place_id);
      setFieldValue('municipality_id', place.opstina_id);
      setFieldValue('street_name', data?.street_name);
      setFieldValue('adresa', data?.adresa);
      setFieldValue('street_id', data?.street_id);
      setFieldValue('street_no', data?.street_no);
    },
  });

  const {
    data: places,
    reload: reloadPlaces,
    isLoading: isLoadingPlaces,
  } = useAxios(`/municipalities/${values.municipality_id}/places`, undefined, {
    skipWhen: !values.municipality_id || values.municipality_id === null,
  });

  const {
    data: streets,
    reload: reloadStreets,
    isLoading: isLoadingStreets,
  } = useAxios(`/places/${data?.place_id}/streets`, undefined, {
    skipWhen: !data?.place_id ?? isStreetFreeInputAllowed,
    successCallback: (streets) => {
      if (isEditDialog && !values.street) {
        const street = streets.find((s) => s.id === data.ulica_id);

        if (street) {
          setFieldValue('street_name', street.ime);
          setFieldValue('adresa', street.ime);
          setFieldValue('street_id', street.id);
          setFieldValue('street_no', data.broj);
        }
      }
    },
  });

  const municipalitiesOptions = municipalities?.map((m) => ({
    label: m.ime,
    value: m.id,
  }));

  const placesOptions = places?.map((m) => ({
    label: m.ime,
    value: m.id,
    postal_code: m.postenski_broj,
  }));

  const streetsOptions =
    streets?.length > 0
      ? streets.map((m) => ({ label: m.ime, value: m.id }))
      : [];

  return (
    <div>
      {step === 1 && <GeneralInfo step={step} setIsNameUsed={setIsNameUsed} />}

      {step === 2 && (
        <RegionsPartner
          step={step}
          regionsOptions={regionsOptions}
          isLoadingRegions={isLoadingRegions}
        />
      )}

      {step === 3 && (
        <Location
          step={step}
          municipalitiesOptions={municipalitiesOptions}
          isMunicipalitiesLoading={isLoadingMunicipalities}
          places={places}
          placesOptions={placesOptions}
          isPlacesLoading={isLoadingPlaces}
          reloadPlaces={reloadPlaces}
          streets={streets}
          streetsOptions={streetsOptions}
          isStreetsLoading={isLoadingStreets}
          reloadStreets={reloadStreets}
          isNameUsed={isNameUsed}
        />
      )}
    </div>
  );
}

export default DialogContainer;
