import { AxiosError } from 'axios';
import { TFunction } from 'i18next';
import * as Yup from 'yup';

import { yupRequiredPasswordField } from '../../../../../utils/helpers/yup';

export type FormFields = {
  new_password: string;
  confirm_password: string;
};

export type SuccessObj = {
  message?: string;
};

export type PasswordSetErrorResponse = {
  error_description?: { new_password?: string };
};

export function getInitialValues(): FormFields {
  return {
    new_password: '',
    confirm_password: '',
  };
}

export function getValidationSchema(t: TFunction): Yup.AnyObjectSchema {
  return Yup.object().shape({
    new_password: yupRequiredPasswordField(t, 'New password'),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('new_password'), null], t('Passwords must match'))
      .required(t('Confirm password is required')),
  });
}

export function generateErrorMessage(
  t: TFunction,
  err: AxiosError<PasswordSetErrorResponse> | undefined
) {
  if (
    err?.response?.data?.error_description?.new_password ===
    'same_as_current_password'
  ) {
    return t('New password is not different from the current one.');
  }
  return t('Changing password failed {{errorMessage}}.', {
    errorMessage: err?.message ?? '',
  });
}
