import { OrderPaymentService } from '../../../enums/orders';
import { OrderCollection } from '../../../types/api/orders';
import { Unpacked } from '../../../types/util';

export function orderCollectionPaymentsFilterByService(
  payments: Unpacked<OrderCollection>['payments'],
  service: OrderPaymentService
): Unpacked<OrderCollection>['payments'] {
  return payments?.filter((p) => p.usluga_id === service) ?? null;
}
