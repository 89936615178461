import dayjs from 'dayjs';
import { TFunction } from 'i18next';

import activePin from '../../../assets/img/map-pins/active.png';
import awayPin from '../../../assets/img/map-pins/away.png';
import gonePin from '../../../assets/img/map-pins/gone.png';
import offlinePin from '../../../assets/img/map-pins/offline.png';
import { CouriersLocationCollection } from '../../../types/api/tracking';
import { LabelValue } from '../../../types/options';

enum CourierStatus {
  active,
  away,
  gone,
  offline,
}

type CourierStatusConfig = {
  label: string;
  icon: string;
  duration: [number, number?];
};

export function getCourierStatusConfig(
  t: TFunction
): Record<CourierStatus, CourierStatusConfig> {
  return {
    [CourierStatus.active]: {
      label: t('Active'),
      icon: activePin,
      duration: [0, 1],
    },
    [CourierStatus.away]: {
      label: t('Away'),
      icon: awayPin,
      duration: [1, 3],
    },
    [CourierStatus.gone]: {
      label: t('Gone'),
      icon: gonePin,
      duration: [3, 5],
    },
    [CourierStatus.offline]: {
      label: t('Offline'),
      icon: offlinePin,
      duration: [5],
    },
  };
}

export function lastActiveDateToCourierStatusConfig(
  lastActiveDate: string,
  courierStatusConfig: ReturnType<typeof getCourierStatusConfig>
): CourierStatusConfig {
  const minutesSinceLastActive = dayjs
    .duration(dayjs().diff(dayjs(lastActiveDate)))
    .asMinutes();

  const matchingKey = Object.keys(courierStatusConfig).find((key) => {
    const config = courierStatusConfig[parseInt(key) as CourierStatus];
    const [from, to] = config.duration;

    return (
      minutesSinceLastActive >= from &&
      (to ? minutesSinceLastActive < to : true)
    );
  });

  return courierStatusConfig[
    matchingKey
      ? (parseInt(matchingKey) as CourierStatus)
      : CourierStatus.offline
  ];
}

export function getCourierDropdownTemplate(
  data: CouriersLocationCollection | undefined,
  courierStatusConfig: ReturnType<typeof getCourierStatusConfig>
) {
  return (option: LabelValue<number>) => {
    const courier = data?.find((o) => o.courier_id === option.value);

    if (!courier) {
      return null;
    }

    const config = lastActiveDateToCourierStatusConfig(
      courier.date,
      courierStatusConfig
    );

    return (
      <div className="p-d-flex">
        <img
          src={config.icon}
          alt={config.label}
          style={{ width: 16, height: 'auto', marginRight: 6 }}
        />

        <span>{option.label}</span>
      </div>
    );
  };
}
