import { EndpointGuard } from '../../helpers/auth';
import { orderResourceGuard } from './_api/orders';

export const readParcel = orderResourceGuard;

export const routeGuard: EndpointGuard = [
  'GET /employees/all',
  'POST /pickupattempts',
  'POST /deliveryattempts',
];
