import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCheckCircle,
  faEnvelope,
  faPlus,
  faQuestion,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { TFunction } from 'react-i18next';

import { emptyCell, noColumnStyle } from '../../../../utils/constants/tables';
import { dateCell } from '../../../../utils/helpers/dataTable';
import {
  currencyFormat,
  withTrailingEllipsis,
} from '../../../../utils/helpers/formatting';
import { formatPhoneOrMobileNumber } from '../../../../utils/helpers/phoneNumbers';
import { contextMenuModel } from '../../../../utils/helpers/primereact';
import OverlayHoverPanel from '../../../DataTable/OverlayHoverPanel/OverlayHoverPanel';

export type WarrantList = {
  id: number;
  datum: string;
  seriski: string | null;
  adresnica_seriski: string | null;
  client_name: string;
  hub_name: number | null;
  opis: string | null;
  status: number;
  adresnica_status: string | null;
  vkupen_otkup: number | null;
  email_sent_date: string | null;
  email_status_id: number | null;
  adresnica_id: number | null;
  department_id: number | null;
  department_name: string | null;
  employee_name: string;
  hub_id: number | null;
  klient_id: number | null;
  rabotnik_id: number | null;
};

export type SingleWarrantList = {
  id: number;
  klient_id: number | null;
  adresnica_id: number | null;
  datum: string | null;
  opis: string | null;
  seriski: string | null;
  adresnica_seriski: string | null;
  rabotnik_id: number | null;
  status: number;
  email_status_id: number;
  email_sent_date: null;
  hub_id: number | null;
  client_name: string | null;
  employee_name: string | null;
  vkupen_otkup: number | null;
};

export type WarrantParcel = {
  seriski_broj: string | null;
  datum_kreiranje: string | null;
  datum_priem: string | null;
  datum_isporaka: string | null;
  klient_od_ime: string | null;
  adresa_od: string | null;
  mesto_od_ime: string | null;
  telefon_od: string | null;
  mobilen_od: string | null;
  klient_do_ime: string | null;
  adresa_do: string | null;
  mesto_do_ime: string | null;
  telefon_do: string | null;
  mobilen_do: string | null;
  otkup: string | null;
  reference1: string;
  reference2: string;
};

export const tableStorageKey = 'cod_warrants_dataTable';

export function getListsColumnHeadersMap(
  t: TFunction
): Partial<Record<keyof WarrantList | 'no', string>> {
  return {
    no: t('No.'),
    datum: t('Date'),
    seriski: t('COD Serial No.'),
    adresnica_seriski: t('Parcel No'),
    client_name: t('Client'),
    department_name: t('Department'),
    hub_name: t('Warehouse'),
    opis: t('Description'),
    status: t('Status'),
    adresnica_status: t('Parcel status'),
    email_sent_date: t('Email date'),
    email_status_id: t('Email status'),
    vkupen_otkup: t('Total redemption'),
  };
}

export function getParcelsColumnHeadersMap(
  t: TFunction
): Record<keyof WarrantParcel | 'no', string> {
  return {
    no: t('No.'),
    seriski_broj: t('Serial No.'),
    reference1: t('Reference 1'),
    reference2: t('Reference 2'),
    datum_kreiranje: t('Created on'),
    datum_priem: t('Received on'),
    datum_isporaka: t('Shipped on'),
    klient_od_ime: t('Client from'),
    adresa_od: t('Address from'),
    mesto_od_ime: t('Place from'),
    telefon_od: t('Phone from'),
    mobilen_od: t('Mobile from'),
    klient_do_ime: t('Client to'),
    adresa_do: t('Address to'),
    mesto_do_ime: t('Place to'),
    telefon_do: t('Phone to'),
    mobilen_do: t('Mobile to'),
    otkup: t('Redemption'),
  };
}

export function additionalListsColumnProperties(
  t: TFunction,
  column: keyof ReturnType<typeof getListsColumnHeadersMap>
) {
  switch (column) {
    case 'no':
      return noColumnStyle;

    case 'email_sent_date':
    case 'datum':
      return {
        body: (row: WarrantList) => dateCell(row[column]),
        style: {
          width: 180,
        },
        bodyStyle: {
          width: 180,
        },
      };

    case 'seriski':
    case 'adresnica_seriski':
      return {
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
        },
      };

    case 'client_name':
      return {
        style: {
          width: 250,
        },
        bodyStyle: {
          width: 250,
        },
      };

    case 'hub_name':
      return {
        body: (row: WarrantList) => row[column] ?? emptyCell,
        style: {
          width: 160,
        },
        bodyStyle: {
          width: 160,
        },
      };

    case 'opis':
      return {
        body: (row: WarrantList) =>
          typeof row[column] === 'string' && row[column]!.trim() ? (
            <OverlayHoverPanel fullText={row[column]}>
              {withTrailingEllipsis(row[column]!, 0, 20)}
            </OverlayHoverPanel>
          ) : (
            emptyCell
          ),
        style: {
          width: 200,
        },
        bodyStyle: {
          whiteSpace: 'nowrap',
          width: 200,
        },
      };

    case 'status':
      return {
        body: (row: WarrantList) => {
          let icon;
          let tooltipText;
          let color;

          if (row[column] === 1) {
            icon = faPlus;
            tooltipText = t('Created');
            color = 'var(--blue-400)';
          } else if (row[column] === 2) {
            icon = faCheckCircle;
            tooltipText = t('Paid');
            color = 'var(--green-400)';
          } else {
            icon = faQuestion;
            tooltipText = t('Unknown');
            color = 'var(--yellow-400)';
          }

          return (
            <div className="text-center">
              <span data-tip={tooltipText} data-for="tooltip-right">
                <FontAwesomeIcon icon={icon} style={{ color }} />
              </span>
            </div>
          );
        },
        style: {
          width: 120,
        },
        bodyStyle: {
          width: 120,
        },
      };

    case 'adresnica_status':
      return {
        style: {
          width: 140,
        },
        bodyStyle: {
          width: 140,
        },
      };

    case 'email_status_id':
      return {
        body: (row: WarrantList) => {
          const value = row[column];
          let icon: IconProp;
          let tooltipText;
          let color;

          if (value === 1) {
            icon = faEnvelope;
            tooltipText = t('Not for sending');
            color = 'var(--yellow-400)';
          } else if (value === 2) {
            icon = faEnvelope;
            tooltipText = t('Waiting to be sent');
            color = 'var(--blue-400)';
          } else {
            icon = faEnvelope;
            tooltipText = t('Sent');
            color = 'var(--green-400)';
          }

          return (
            <div className="text-center">
              <span data-tip={tooltipText} data-for="tooltip-right">
                <FontAwesomeIcon icon={icon} style={{ color }} />
              </span>
            </div>
          );
        },
        style: {
          width: 150,
        },
        bodyStyle: {
          width: 150,
        },
      };

    case 'vkupen_otkup':
      return {
        body: (row: WarrantList) => {
          const value = row[column];

          if (value === null) {
            return emptyCell;
          }

          return Intl.NumberFormat().format(value);
        },
        style: {
          width: 140,
        },
        bodyStyle: {
          width: 140,
        },
      };

    default:
      return {};
  }
}

export function additionalParcelsColumnProperties(
  column: keyof ReturnType<typeof getParcelsColumnHeadersMap>
) {
  switch (column) {
    case 'no':
      return noColumnStyle;

    case 'seriski_broj':
      return {
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
        },
      };

    case 'datum_kreiranje':
    case 'datum_isporaka':
    case 'datum_priem':
      return {
        body: (row: WarrantParcel) =>
          row[column]
            ? dayjs(row[column] ?? '').format('DD MMM YYYY, HH:mm:ss')
            : emptyCell,
        style: {
          width: 180,
        },
        bodyStyle: {
          width: 180,
        },
      };

    case 'klient_od_ime':
    case 'klient_do_ime':
      return {
        style: {
          width: 250,
        },
        bodyStyle: {
          width: 250,
        },
      };

    case 'adresa_od':
    case 'adresa_do':
      return {
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
        },
      };

    case 'mesto_od_ime':
    case 'mesto_do_ime':
      return {
        style: {
          width: 140,
        },
        bodyStyle: {
          width: 140,
        },
      };

    case 'telefon_od':
    case 'telefon_do':
    case 'mobilen_od':
    case 'mobilen_do':
      return {
        body: (row: WarrantParcel) =>
          row[column]?.trim()
            ? formatPhoneOrMobileNumber(row[column] as string)
            : emptyCell,
        style: {
          width: 140,
        },
        bodyStyle: {
          width: 140,
        },
      };

    case 'otkup':
      return {
        body: (row: WarrantParcel) =>
          row[column]
            ? currencyFormat(row[column] as string, { showCurrency: true })
            : emptyCell,
        style: {
          width: 140,
        },
        bodyStyle: {
          width: 140,
        },
      };

    default:
      return {
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
        },
      };
  }
}

export function generateContextMenu(
  t: TFunction,
  selection: WarrantList | null,
  isViewShown: boolean,
  handleViewParcels: () => void,
  isExportToExcelShown: boolean,
  handleExportToExcel: () => void,
  isExportToCsvShown: boolean,
  handleExportToCSV: () => void,
  isSendCodEmailShown: boolean,
  handleSendCodEmail: () => void,
  isRevertToCreatedShown: boolean,
  handleRevertToCreated: () => void,
  isEditShown: boolean,
  handleMarkAsUnpaid: () => void,
  handleMarkAsUnsent: () => void
) {
  const viewOption = {
    label: t('View Parcels'),
    icon: 'fas fa-search',
    command: handleViewParcels,
  };

  const exportToCsvOption = {
    label: t('Export to CSV'),
    icon: 'fas fa-file-csv',
    command: handleExportToCSV,
  };

  const exportToExcelOption = {
    label: t('Export to Excel'),
    icon: 'fas fa-file-excel',
    command: handleExportToExcel,
  };

  const sendCodEmailOption = {
    label: selection?.email_sent_date
      ? t('Resend COD email')
      : t('Send COD email'),
    icon: 'fas fa-file-invoice',
    command: handleSendCodEmail,
  };

  const revertToCreatedOption = {
    label: t('Revert to Created'),
    icon: 'fas fa-check-circle',
    command: handleRevertToCreated,
  };

  const markAsUnpaidOption = {
    label: t('Mark as unpaid'),
    icon: 'fas fa-people-carry',
    command: handleMarkAsUnpaid,
  };

  const showMarkAsUnsentOption = selection?.email_status_id === 3;

  const markAsUnsentOption = {
    label: t('Mark as Unsent'),
    icon: 'fas fa-people-carry',
    command: handleMarkAsUnsent,
  };

  const status = selection?.status?.toString();

  return contextMenuModel([
    [[isViewShown, viewOption]],
    [
      [isExportToCsvShown, exportToCsvOption],
      [isExportToExcelShown, exportToExcelOption],
    ],
    [
      [isSendCodEmailShown, sendCodEmailOption],
      [isEditShown && showMarkAsUnsentOption, markAsUnsentOption],
    ],
    [
      [status === '1' && isRevertToCreatedShown, revertToCreatedOption],
      [status === '2', markAsUnpaidOption],
    ],
  ]);
}
