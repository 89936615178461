// DBTable "usluga"
export enum Service {
  Postage = 1,
  Ransom = 2,
  ReturnDocument = 3,
  ValuableShipment = 4,
  BuyingWithCourier = 5,
  Reception = 6,
  ManualPriceAdjustment = 51,
}
