import { AxiosError, AxiosResponse } from 'axios';
import _ from 'lodash';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import ToastContext from '../../../../../../context/ToastContext';
import usePrevious from '../../../../../../hooks/usePrevious';
// import { sequential } from '../../../../../../utils/helpers/functions';
import {
  errorToast,
  successToast,
  warnToast,
} from '../../../../../../utils/helpers/primereact';
import { printInvalidShipments } from '../ShipmentReconciliation.functions';
import { ReconcileShipments } from '../ShipmentReconciliation.types';

function useHandleReconcileResponse(
  updateResponse: AxiosResponse<ReconcileShipments> | undefined,
  updateData: ReconcileShipments | undefined,
  updateError: AxiosError<object> | undefined
  // reloadDeliveredShipmentData: (newConfig?: AxiosRequestConfig) => void,
  // reloadPickedUpShipmentData: (newConfig?: AxiosRequestConfig) => void
) {
  const { t } = useTranslation();

  const { toastRef } = useContext(ToastContext);

  const previousUpdateResponse = usePrevious(updateResponse);
  const previousUpdateData = usePrevious(updateData);
  const previousUpdateError = usePrevious(updateError);

  useEffect(() => {
    if (!updateError || updateError === previousUpdateError) {
      return;
    }

    errorToast(
      toastRef,
      t('Error'),
      t('An error occured while trying to update shipment status')
    );
  }, [previousUpdateError, t, toastRef, updateError]);

  useEffect(() => {
    if (!updateData || updateData === previousUpdateData) {
      return;
    }

    if (updateData && _.isEmpty(updateData)) {
      successToast(toastRef, t('Success'), t('Shipments saved'));
      // sequential(reloadDeliveredShipmentData, reloadPickedUpShipmentData);
    }
  }, [
    previousUpdateData,
    // reloadDeliveredShipmentData,
    // reloadPickedUpShipmentData,
    t,
    toastRef,
    updateData,
  ]);

  useEffect(() => {
    if (!updateResponse || updateResponse === previousUpdateResponse) {
      return;
    }

    if (
      updateResponse?.status === 207 &&
      updateData &&
      !_.isEmpty(updateData)
    ) {
      warnToast(
        toastRef,
        t('Could not reconcile orders'),
        printInvalidShipments(updateData, t)
      );
      // sequential(reloadDeliveredShipmentData, reloadPickedUpShipmentData);
    }
  }, [
    previousUpdateResponse,
    // reloadDeliveredShipmentData,
    // reloadPickedUpShipmentData,
    t,
    toastRef,
    updateData,
    updateResponse,
  ]);
}

export default useHandleReconcileResponse;
