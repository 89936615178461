import { entityCollectionGuard } from './_api/entities';
import {
  createImportListParcelDataDefinitionResourceGuard,
  createImportListTypeFieldResourceGuard,
  createImportListTypeResourceGuard,
  deleteImportListTypeResourceGuard,
  entityPropertyCollectionGuard,
  exportListTypeToExcelOrCSVCollectionGuard,
  importListTypeCollectionGuard,
  importListTypeResourceGuard,
  updateImportListTypeFieldResourceGuard,
  updateImportListTypeResourceGuard,
} from './_api/importLists';
import { priceListCollectionGuard } from './_api/priceLists';

export const readListType = [
  importListTypeResourceGuard,
  priceListCollectionGuard,
  entityCollectionGuard,
  entityPropertyCollectionGuard,
];

export const exportListTypeToExcelOrCsv =
  exportListTypeToExcelOrCSVCollectionGuard;

export const createListType = [
  ...readListType,
  createImportListTypeResourceGuard,
  createImportListTypeFieldResourceGuard,
  createImportListParcelDataDefinitionResourceGuard,
];

export const editListType = [
  ...readListType,
  updateImportListTypeResourceGuard,
  createImportListTypeFieldResourceGuard,
  updateImportListTypeFieldResourceGuard,
  deleteImportListTypeResourceGuard,
];

export const routeGuard = importListTypeCollectionGuard;
