import { Action } from 'redux';

import { ReduxState } from '../../types/redux';
import {
  LOGIN_USER,
  LOGOUT_USER,
  REFRESH_AUTH_TOKEN,
  UPDATE_EMPLOYEE_RESOURCE,
  UPDATE_USER,
  loginUser,
  refreshAuthToken,
  updateEmployeeResource,
  updateUser,
} from '../actions/userActions';

const defaultState = {
  isLoggedIn: false,
};

function userReducer(state = defaultState, action: Action): ReduxState['user'] {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        isLoggedIn: true,
        ...(action as ReturnType<typeof loginUser>).data,
        ...(!(action as ReturnType<typeof loginUser>).rememberMe
          ? {
              sessionKey: String(
                (action as ReturnType<typeof loginUser>).timestamp
              ),
            }
          : {}),
      };

    case UPDATE_USER:
    case REFRESH_AUTH_TOKEN:
      return {
        ...state,
        isLoggedIn: true,
        ...(action as ReturnType<typeof updateUser | typeof refreshAuthToken>)
          .data,
      };

    case UPDATE_EMPLOYEE_RESOURCE:
      if (!state.isLoggedIn) {
        return state;
      }

      return {
        ...state,
        employee: (action as ReturnType<typeof updateEmployeeResource>).data,
      };

    case LOGOUT_USER:
      return defaultState;

    default:
      return state;
  }
}

export default userReducer;
