const FiltersCounter = ({
  description,
  counter,
}: {
  description: string;
  counter: number;
}): JSX.Element => {
  return (
    <div className="accordionHeaderTemplate">
      <span style={{ font: 'initial' }}>{description}</span>
      {counter > 0 && <span className="badge">{counter}</span>}
    </div>
  );
};

export default FiltersCounter;
