import { useContext } from 'react';
import { useSelector } from 'react-redux';

import { MenuContext } from './context/MenuContext';
import SideMenu from './features/menu/SideMenu/SideMenu';
import Routes from './features/Routes/Routes';
import useScreenSize from './hooks/css/useScreenSize';
import { ReduxState } from './types/redux';

function MainSection() {
  const { isDesktopScreen } = useScreenSize();
  const { isMenuExpanded } = useContext(MenuContext);
  const isUserLoggedIn = useSelector<
    ReduxState,
    ReduxState['user']['isLoggedIn']
  >((state) => state.user.isLoggedIn);

  return isUserLoggedIn ? (
    <div style={{ display: 'flex', width: '100%' }}>
      <SideMenu />
      <div
        className="content-wrapper"
        style={{
          width: isMenuExpanded ? 'calc(100% - 20rem)' : 'calc(100% - 5rem)',
          left: isMenuExpanded ? '20rem' : '5rem',
          position: isDesktopScreen ? 'absolute' : 'static',
        }}
      >
        <Routes />
      </div>
    </div>
  ) : (
    <Routes />
  );
}

export default MainSection;
