import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import ToastContext from '../../../../../context/ToastContext';
import useAxiosHook from '../../../../../hooks/useAxiosHook';
import usePrevious from '../../../../../hooks/usePrevious';
import {
  errorToast,
  successToast,
} from '../../../../../utils/helpers/primereact';
import { CourierLiability } from '../../CourierLiabilities.functions';

type Props = {
  selectedLiability: CourierLiability | null;
  isShown: boolean;
  onHide: () => void;
  reload: () => void;
};
function DeleteLiabilityDialog({
  selectedLiability,
  isShown,
  onHide,
  reload,
}: Props) {
  const { t } = useTranslation();

  const { toastRef } = useContext(ToastContext);

  const {
    data: deleteData,
    error: deleteError,
    isLoading: isSubmissionRequestLoading,
    reload: deleteLiability,
  } = useAxiosHook(
    {
      url: `/parceldebts/${selectedLiability?.id}`,
      method: 'DELETE',
    },
    { skipWhen: true }
  );

  const previousDeleteData = usePrevious(deleteData);
  const previousDeleteError = usePrevious(deleteError);

  useEffect(() => {
    if (deleteData && deleteData !== previousDeleteData) {
      successToast(
        toastRef!,
        t('Success'),
        t('Liability to {{courierName}} has been successfully deleted.', {
          courierName: selectedLiability?.postar_ime,
        })
      );

      reload();
      onHide();
    }
  }, [
    deleteData,
    onHide,
    previousDeleteData,
    reload,
    selectedLiability?.postar_ime,
    t,
    toastRef,
  ]);

  useEffect(() => {
    if (deleteError && deleteError !== previousDeleteError) {
      errorToast(
        toastRef!,
        t('Error'),
        t(
          "An error occured while trying to delete the {{courierName}}'s liability.",
          { courierName: selectedLiability?.postar_ime }
        )
      );

      reload();
      onHide();
    }
  }, [
    deleteError,
    onHide,
    previousDeleteError,
    reload,
    selectedLiability?.postar_ime,
    t,
    toastRef,
  ]);

  const dialogHeader = <span>{t('Are you sure?')}</span>;

  const dialogFooter = (
    <>
      <Button
        type="button"
        label={t('Cancel')}
        onClick={onHide}
        className="p-button-text"
      />

      <Button
        type="button"
        label={isSubmissionRequestLoading ? t('Deleting...') : t('Delete')}
        disabled={isSubmissionRequestLoading}
        onClick={() => deleteLiability()}
        className="p-button-danger"
        data-cy="submit-btn"
      />
    </>
  );

  return (
    <>
      <Dialog
        visible={isShown}
        onHide={onHide}
        header={dialogHeader}
        footer={dialogFooter}
        resizable={false}
        dismissableMask
      >
        {t("Are you sure you want to delete {{courierName}}'s liability?", {
          courierName: selectedLiability?.postar_ime,
        })}
      </Dialog>
    </>
  );
}

export default DeleteLiabilityDialog;
