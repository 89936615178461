import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Dispatch, useCallback, useMemo } from 'react';
import { useState } from 'react';
import { SetStateAction } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDebounce } from 'use-lodash-debounce';

import { IntBool } from '../../../enums/booleans';
import { UserRole } from '../../../enums/users';
import useAxiosHook from '../../../hooks/useAxiosHook';
import { useEndpointGuard } from '../../../hooks/useEndpointGuard';
import useHaveValuesChanged from '../../../hooks/useHaveValuesChanged';
import useSearchQueryDropdownParam from '../../../hooks/useSearchQueryDropdownParam';
import useSearchQueryMultiSelectAutoCompleteInputParam from '../../../hooks/useSearchQueryMultiSelectAutoCompleteInputParam';
import useSearchQueryMultiSelectParam from '../../../hooks/useSearchQueryMultiSelectParam';
import useSearchQueryParam from '../../../hooks/useSearchQueryParam';
import { WithPagination } from '../../../types/api';
import {
  ClientLookupCollection,
  ClientLookupCollectionQueryParams,
} from '../../../types/api/clients';
import {
  AvailableCouriersCollection,
  AvailableCouriersCollectionQueryParams,
} from '../../../types/api/couriers';
import { LabelValue } from '../../../types/options';
import * as opaGuards from '../../../utils/constants/auth/courierPickupAssignment';
import { debounceTimeout } from '../../../utils/constants/misc';
import { queryString } from '../../../utils/helpers/http';
import { httpQueryObject } from '../../../utils/helpers/misc';
import {
  getSearchQueryParam,
  tryMultiSelectParam,
} from '../../../utils/helpers/searchQuery';
import TableHeaderFilters from '../../DataTable/Table/HeaderFilters/TableHeaderFilters';
import {
  OrdinaryFilter,
  filterByValue,
} from '../../DataTable/Table/HeaderFilters/TableHeaderFilters.function';
import MultiSelectAutoCompleteInput from '../../Forms/MultiSelectAutoCompleteInput/MultiSelectAutoCompleteInput';
import { Region } from '../Regions/Regions.functions';

function useTableFilters(
  page: number,
  setPage: Dispatch<SetStateAction<number>>,
  limit: number
) {
  const { t } = useTranslation();

  const couriersFilterGuard = useEndpointGuard(opaGuards.couriersFilter);
  const clientsFilterGuard = useEndpointGuard(opaGuards.clientsFilter);

  const location = useLocation();

  const [headerFiltersCount, setHeadersFilterCount] = useState<number>(0);

  const [serialNoFilter, setSerialNoFilter] = useState(
    getSearchQueryParam(location.search, 'serialNo') ?? ''
  );

  const [couriersFilter, setCouriersFilter] = useState(() => {
    if (!couriersFilterGuard) {
      return null;
    }

    const value = getSearchQueryParam(location.search, 'couriers') ?? null;

    return value
      ? tryMultiSelectParam(value)?.map((o) => Number(o)) ?? null
      : null;
  });

  const [regionsFilter, setRegionsFilter] = useState(() => {
    if (!couriersFilterGuard) {
      return null;
    }

    const value = getSearchQueryParam(location.search, 'regions') ?? null;

    return value
      ? tryMultiSelectParam(value)?.map((o) => Number(o)) ?? null
      : null;
  });

  const [clientsFilter, setClientsFilter] = useState('');
  const [clientsFilterSelection, setClientsFilterSelection] = useState(() => {
    if (!couriersFilterGuard) {
      return [];
    }

    const value = getSearchQueryParam(location.search, 'clients') ?? null;

    return value ? tryMultiSelectParam(value) ?? [] : [];
  });
  const [clientsFilterAllSelectedOptions, setClientsFilterAllSelectedOptions] =
    useState<LabelValue[]>([]);

  const [statusFilter, setStatusFilter] = useState(
    () => getSearchQueryParam(location.search, 'status') ?? null
  );

  const debouncedSerialNoFilter = useDebounce(serialNoFilter, debounceTimeout);
  const debouncedClientsFilter = useDebounce(clientsFilter, debounceTimeout);
  const debouncedClientsFilterSelection = useDebounce(
    clientsFilterSelection,
    debounceTimeout
  );

  const { data: regions, isLoading: isRegionsLoading } =
    useAxiosHook<WithPagination<Region[]>>('/regions/couriers');

  const { data: coureirs, isLoading: isCouriersLoading } =
    useAxiosHook<AvailableCouriersCollection>(
      '/couriers/available' +
        queryString<AvailableCouriersCollectionQueryParams>({
          is_assigned_to_region: IntBool.False,
          user_role_id: UserRole.Courier,
        })
    );

  const { data: clients, isLoading: isClientsLoading } =
    useAxiosHook<ClientLookupCollection>(
      '/clients/lookup' +
        queryString<ClientLookupCollectionQueryParams>({
          ime: debouncedClientsFilter,
          limit: 20,
          page: 1,
        })
    );

  const regionsOptions = useMemo(
    () =>
      regions?.data.map((r) => ({
        label: r.name,
        value: r.region_id,
      })) ?? [],
    [regions]
  );

  const couriersOptions = useMemo(() => {
    return coureirs?.map((c) => ({ label: c.full_name, value: c.id }));
  }, [coureirs]);

  const clientsOptions = useMemo(
    () =>
      clients?.data.map((c) => ({
        label: c.ime,
        value: String(c.id),
      })) ?? [],
    [clients]
  );

  const statusOptions = useMemo(
    () => [
      { label: t('Assigned.SHE'), value: 'assigned' },
      { label: t('Unassigned.SHE'), value: 'unassigned' },
      { label: t('Picked Up.SHE'), value: 'received' },
      { label: t('Failed Pick Up.SHE'), value: 'failed_receive' },
    ],
    [t]
  );

  const filtersArr = useMemo(
    () => [
      couriersFilter,
      regionsFilter,
      clientsFilter,
      statusFilter,
      debouncedSerialNoFilter,
    ],
    [
      clientsFilter,
      couriersFilter,
      debouncedSerialNoFilter,
      regionsFilter,
      statusFilter,
    ]
  );

  const haveFiltersChanged = useHaveValuesChanged(filtersArr);

  useEffect(() => {
    setPage(1);
  }, [
    setPage,
    clientsFilter,
    couriersFilter,
    debouncedSerialNoFilter,
    regionsFilter,
    statusFilter,
  ]);

  useSearchQueryParam('serialNo', debouncedSerialNoFilter);

  useSearchQueryMultiSelectParam('couriers', couriersFilter);
  useSearchQueryMultiSelectParam('regions', regionsFilter);

  useSearchQueryMultiSelectAutoCompleteInputParam(
    'clients',
    clientsFilterSelection,
    setClientsFilterSelection,
    setClientsFilterAllSelectedOptions,
    (ids) => {
      const validIds = ids.map((id) => parseInt(id)).filter((id) => !isNaN(id));

      return validIds.length
        ? '/clients/lookup' +
            queryString<ClientLookupCollectionQueryParams>({
              id: validIds,
              limit: 0,
              page: 1,
            })
        : undefined;
    },
    useCallback(
      (data: ClientLookupCollection) =>
        data.data.map((c) => ({ label: c.ime, value: String(c.id) })),
      []
    )
  );

  useSearchQueryDropdownParam(
    'status',
    statusFilter,
    setStatusFilter,
    statusOptions
  );

  useEffect(() => {
    if (!couriersFilterGuard) {
      setCouriersFilter(null);
      setRegionsFilter(null);
    }
  }, [couriersFilterGuard]);

  useEffect(() => {
    if (!clientsFilterGuard) {
      setClientsFilter('');
      setClientsFilterSelection([]);
    }
  }, [clientsFilterGuard]);

  const httpFiltersObj = useMemo(
    () =>
      httpQueryObject({
        courier_id: couriersFilter,
        region_id: regionsFilter,
        customer_id: debouncedClientsFilterSelection,
        status: statusFilter,
        serial_number: debouncedSerialNoFilter,
        page: haveFiltersChanged ? 1 : page,
        limit,
      }),
    [
      couriersFilter,
      debouncedClientsFilterSelection,
      debouncedSerialNoFilter,
      haveFiltersChanged,
      limit,
      page,
      regionsFilter,
      statusFilter,
    ]
  );

  const headerFiltersForm = useMemo<JSX.Element>(
    () => (
      <>
        <div className="filter">
          <label htmlFor="filter_serial_no">{t('Serial No')}</label>
          <InputText
            id="filter_serial_no"
            value={serialNoFilter}
            onChange={(e) => setSerialNoFilter(e.target.value.trim())}
          />
        </div>

        {couriersFilterGuard && (
          <>
            <div className="filter">
              <label htmlFor="filter_couriers">{t('Couriers')}</label>
              <MultiSelect
                inputId="filter_couriers"
                value={couriersFilter}
                options={couriersOptions}
                maxSelectedLabels={0}
                selectedItemsLabel={t('{0} selected')}
                filter
                showClear
                disabled={isCouriersLoading}
                placeholder={isCouriersLoading ? t('Loading...') : undefined}
                onChange={(e) => {
                  setCouriersFilter(e.target.value);
                }}
              />
            </div>
            <div className="filter">
              <label htmlFor="filter_regions">{t('Regions')}</label>
              <MultiSelect
                inputId="filter_regions"
                value={regionsFilter}
                options={regionsOptions}
                maxSelectedLabels={0}
                selectedItemsLabel={t('{0} selected')}
                filter
                showClear
                disabled={isRegionsLoading}
                placeholder={isRegionsLoading ? t('Loading...') : undefined}
                onChange={(e) => {
                  setRegionsFilter(e.target.value);
                }}
              />
            </div>
          </>
        )}

        {clientsFilterGuard && (
          <div className="filter">
            <label htmlFor="filter_clients">{t('Clients')}</label>
            <MultiSelectAutoCompleteInput
              id="filter_clients"
              filterValue={clientsFilter}
              setFilterValue={setClientsFilter}
              selection={clientsFilterSelection}
              setSelection={setClientsFilterSelection}
              allSelectedOptions={clientsFilterAllSelectedOptions}
              setAllSelectedOptions={setClientsFilterAllSelectedOptions}
              options={clientsOptions}
              isLoading={isClientsLoading}
            />
          </div>
        )}

        <div className="filter">
          <label htmlFor="filter_status">{t('Status')}</label>
          <Dropdown
            inputId="filter_status"
            value={statusFilter}
            options={statusOptions}
            showClear
            onChange={(e) => {
              setStatusFilter(e.target.value);
            }}
          />
        </div>
      </>
    ),
    [
      clientsFilter,
      clientsFilterAllSelectedOptions,
      clientsFilterGuard,
      clientsFilterSelection,
      clientsOptions,
      couriersFilter,
      couriersFilterGuard,
      couriersOptions,
      isClientsLoading,
      isCouriersLoading,
      isRegionsLoading,
      regionsFilter,
      regionsOptions,
      serialNoFilter,
      statusFilter,
      statusOptions,
      t,
    ]
  );

  const headerFilters = useMemo(() => {
    const ordinary: OrdinaryFilter[] = [
      {
        label: t('Serial No'),
        value: serialNoFilter,
        onDelete: () => setSerialNoFilter(''),
      },
      {
        label: t('Couriers'),
        value: couriersFilter?.length
          ? couriersFilter
              .map(
                (c) => couriersOptions?.find((o) => o.value === c)?.label ?? ''
              )
              .join(', ')
          : '',
        onDelete: () => setCouriersFilter(null),
      },
      {
        label: t('Regions'),
        value: regionsFilter?.length
          ? regionsFilter
              .map(
                (r) => regionsOptions?.find((o) => o.value === r)?.label ?? ''
              )
              .join(', ')
          : '',
        onDelete: () => setRegionsFilter(null),
      },
      {
        label: t('Clients'),
        value: clientsFilterSelection?.length
          ? clientsFilterSelection
              .map((cId) =>
                clientsFilterAllSelectedOptions
                  .filter((o) => o.value === cId)
                  .map((o) => o.label)
              )
              .join(', ')
          : '',
        onDelete: () => {
          setClientsFilter('');
          setClientsFilterSelection([]);
        },
      },
      {
        label: t('Status'),
        value: statusFilter
          ? statusOptions.find((o) => o.value === statusFilter)?.label ?? ''
          : '',
        onDelete: () => setStatusFilter(null),
      },
    ].filter(filterByValue);

    setHeadersFilterCount(ordinary.length);

    return <TableHeaderFilters ordinary={ordinary} />;
  }, [
    clientsFilterAllSelectedOptions,
    clientsFilterSelection,
    couriersFilter,
    couriersOptions,
    regionsFilter,
    regionsOptions,
    serialNoFilter,
    statusFilter,
    statusOptions,
    t,
  ]);

  function resetAllFilters() {
    setSerialNoFilter('');
    setCouriersFilter(null);
    setRegionsFilter(null);
    setClientsFilter('');
    setClientsFilterSelection([]);
    setStatusFilter(null);
  }

  return {
    headerFiltersForm,
    headerFilters,
    resetAllFilters,
    httpFiltersObj,
    headerFiltersCount,
    couriersOptions,
  };
}

export default useTableFilters;
