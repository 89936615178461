export function base64ToAudio(src: string): HTMLAudioElement {
  return new Audio('data:audio/wav;base64,' + src);
}

export function playAudio(src: string | HTMLAudioElement): void {
  const sound = typeof src === 'string' ? new Audio(src) : src;

  // Playing audio sounds might throw an error.
  // Most of the time this is due to the sound being played before the user has
  //  interacted with the document, so we simply ignore it.
  // .then() is redundant, but it's there for just-in-case compatibility purposes.
  sound.play().then().catch();
}

export function chainAudio(sounds: HTMLAudioElement[]): void {
  for (let i = 0; i < sounds.length; i++) {
    if (i < sounds.length - 1) {
      sounds[i].onended = () => playAudio(sounds[i + 1]);
    }
  }

  if (sounds.length) {
    playAudio(sounds[0]);
  }
}
