import { Form, Formik, FormikProps } from 'formik';
import { Field } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import ToastContext from '../../../../../context/ToastContext';
import useAxiosHook from '../../../../../hooks/useAxiosHook';
import usePrevious from '../../../../../hooks/usePrevious';
import {
  RoutePaths,
  constructIdRoute,
} from '../../../../../utils/constants/routePaths';
import {
  errorToast,
  successToast,
} from '../../../../../utils/helpers/primereact';
import DialogSpinner from '../../../../Dialogs/DialogSpinner/DialogSpinner';
import FieldWithErrors from '../../../../Forms/FieldWithErrors/FieldWithErrors';
import { DialogRouteParams, OAuthClient } from '../../OAuthClients.functions';
import {
  FormFields,
  getInitialValues,
  getValidationSchema,
} from './AddEditDialog.functions';

type Props = {
  data: OAuthClient | undefined;
  isLoading: boolean;
  isEditDialog: boolean;
  visible: boolean;
  tableDataReload: () => void;
  onHide: () => void;
};

function AddEditDialog({
  data,
  isLoading,
  isEditDialog,
  visible,
  tableDataReload,
  onHide,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const validationSchema = useMemo(() => getValidationSchema(t), [t]);

  const history = useHistory();

  const { id } = useParams<DialogRouteParams>();

  const formRef = useRef<FormikProps<FormFields>>(null);

  const { toastRef } = useContext(ToastContext);

  // Protect from deleting non-third-party customers
  useEffect(() => {
    if (visible && data?.type && data?.type !== 'tp-customer') {
      onHide();
    }
  }, [data?.type, onHide, visible]);

  const {
    data: addData,
    error: addError,
    reload: addReload,
    isLoading: isAddLoading,
  } = useAxiosHook<{ id: number }>(
    { url: '/oauthclients', method: 'POST' },
    { skipWhen: true }
  );

  const prevAddData = usePrevious(addData);
  const prevAddError = usePrevious(addError);

  useEffect(() => {
    if (!addData || addData === prevAddData) {
      return;
    }

    successToast(
      toastRef,
      t('Success'),
      t('Client {{clientName}} has been successfully created.', {
        clientName: formRef?.current?.values?.name ?? '',
      })
    );

    tableDataReload();

    if (addData) {
      history.push(
        constructIdRoute(RoutePaths.ViewOAuthClient, addData?.id.toString())
      );
    }
  }, [t, addData, history, prevAddData, tableDataReload, toastRef]);

  useEffect(() => {
    if (!addError || addError === prevAddError) {
      return;
    }

    errorToast(
      toastRef,
      t('Error'),
      t('An error occured while creating client {{clientName}}.', {
        clientName: formRef?.current?.values?.name ?? '',
      })
    );

    onHide();
  }, [t, addError, onHide, prevAddError, toastRef]);

  const {
    data: editData,
    error: editError,
    reload: editReload,
    isLoading: isEditLoading,
  } = useAxiosHook(
    { url: `/oauthclients/${id}`, method: 'PUT' },
    { skipWhen: true }
  );

  const prevEditData = usePrevious(editData);
  const prevEditError = usePrevious(editError);

  const handleEditSuccess = useCallback(() => {
    if (toastRef?.current) {
      successToast(
        toastRef,
        t('Success'),
        t('Client {{clientName}} has been successfully edited.', {
          clientName: formRef?.current?.values?.name ?? '',
        })
      );
    }

    tableDataReload();
    onHide();
  }, [t, onHide, tableDataReload, toastRef]);

  useEffect(() => {
    if (!editData || editData === prevEditData) {
      return;
    }

    handleEditSuccess();
  }, [editData, handleEditSuccess, prevEditData, toastRef]);

  useEffect(() => {
    if (!editError || editError === prevEditError) {
      return;
    }

    errorToast(
      toastRef,
      t('Error'),
      t('An error occured while editing client {{clientName}}.', {
        clientName: formRef?.current?.values?.name ?? '',
      })
    );

    onHide();
  }, [t, editError, onHide, prevEditError, toastRef]);

  const dialogFooter = !isLoading && (
    <>
      <Button
        type="button"
        label={t('Close')}
        onClick={() => onHide()}
        className="p-button-secondary p-button-text"
      />

      <Button
        type="button"
        label={
          isEditDialog
            ? isEditLoading
              ? t('Saving...')
              : t('Save')
            : isAddLoading
            ? t('Adding...')
            : t('Add')
        }
        disabled={
          isLoading ||
          isAddLoading ||
          isEditLoading ||
          (isEditDialog && data?.type !== 'tp-customer')
        }
        onClick={() => formRef.current?.handleSubmit()}
        data-cy="submit"
      />
    </>
  );

  return (
    <Dialog
      header={
        isEditDialog
          ? isLoading
            ? t('Loading...')
            : t('Editing {{clientName}}', { clientName: data?.client_name })
          : t('Add new credentials')
      }
      footer={dialogFooter}
      visible={visible}
      resizable={false}
      onHide={onHide}
      style={{ width: 360, maxWidth: '100%' }}
    >
      <Formik
        innerRef={formRef}
        initialValues={getInitialValues(isEditDialog, data)}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={(values) => {
          if (isEditDialog && data?.type !== 'tp-customer') {
            onHide();
            return;
          }

          if (
            formRef?.current?.values.name ===
            formRef?.current?.initialValues.name
          ) {
            handleEditSuccess();
            return;
          }

          if (isEditDialog) {
            editReload({ data: { client_name: values.name } });
          } else {
            addReload({
              data: { client_name: values.name, type: 'tp-customer' },
            });
          }
        }}
      >
        <Form>
          {isLoading ? (
            <DialogSpinner />
          ) : (
            <div className="p-fluid">
              <FieldWithErrors name="name" label={t('Name')}>
                <Field
                  name="name"
                  as={InputText}
                  id="name"
                  maxLength={45}
                  data-cy="credential-name"
                />
              </FieldWithErrors>
            </div>
          )}
        </Form>
      </Formik>
    </Dialog>
  );
}

export default AddEditDialog;
