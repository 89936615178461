import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import useAxiosHook from '../../../../../hooks/useAxiosHook';
import useToastMessage from '../../../../../hooks/useToastMessage';
import {
  PriceListReferences,
  PriceListResource,
} from '../../../../../types/api/priceLists';
import { EntityIdRouteParams } from '../../../../../types/routing';
import { sequential } from '../../../../../utils/helpers/functions';
import DialogSpinner from '../../../../Dialogs/DialogSpinner/DialogSpinner';
import ContainsReferences from './Content/ContainsReferences';
import ReadyToBeDeleted from './Content/ReadyToBeDeleted';

type Props = {
  isShown: boolean;
  onHide: () => void;
  reloadCollection: () => void;
};

function DeleteDialog({
  isShown,
  onHide,
  reloadCollection,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const { id } = useParams<EntityIdRouteParams>();

  const {
    data: priceListReferencesData,
    error: priceListReferencesError,
    isLoading: isPriceListReferencesLoading,
  } = useAxiosHook<PriceListReferences>(`/price-lists/${id}/references`, {
    skipWhen: !isShown || !id,
  });

  const {
    data: priceListData,
    error: priceListError,
    isLoading: isPriceListLoading,
  } = useAxiosHook<PriceListResource>(`/price-lists/${id}`, {
    skipWhen: !isShown || !id,
  });

  useToastMessage(undefined, priceListError || priceListReferencesError, {
    error: {
      summary: t('An error occured while reading price list data.'),
    },
  });

  const {
    data: deleteData,
    error: deleteError,
    isLoading: isDeleteLoading,
    reload: deleteReload,
  } = useAxiosHook(
    {
      url: `/price-lists/${id}`,
      method: 'DELETE',
    },
    {
      skipWhen: true,
    }
  );

  useToastMessage(deleteData, deleteError, {
    success: {
      summary: t('The price list has been deleted successfully.'),
      callback: () => sequential(onHide, reloadCollection),
    },
    error: {
      summary: t('An error occured while deleting the price list.'),
      callback: onHide,
    },
  });

  const isInitialDataLoading =
    isPriceListLoading || isPriceListReferencesLoading;

  const hasActiveReferences = useMemo<boolean>(
    () =>
      priceListReferencesData
        ? !!Object.values(priceListReferencesData).find(Boolean)
        : false,
    [priceListReferencesData]
  );

  const dialogFooter = isInitialDataLoading ? (
    <></>
  ) : hasActiveReferences ? (
    <Button
      type="button"
      label={t('Close')}
      onClick={onHide}
      className="p-button-text"
    />
  ) : (
    <>
      <Button
        type="button"
        label={t('Close')}
        onClick={onHide}
        className="p-button-text"
      />

      <Button
        type="button"
        label={
          isInitialDataLoading
            ? t('Loading...')
            : isDeleteLoading
            ? t('Deleting...')
            : t('Delete')
        }
        disabled={isInitialDataLoading || isDeleteLoading}
        onClick={() => deleteReload()}
        className="p-button-danger"
      />
    </>
  );

  const name = priceListData?.name ?? '';

  return (
    <Dialog
      header={
        isInitialDataLoading
          ? t('Loading...')
          : t('Deleting {{name}}', { name })
      }
      footer={dialogFooter}
      visible={isShown}
      resizable={false}
      onHide={onHide}
      style={{ width: 480, maxWidth: '100%' }}
    >
      {isInitialDataLoading ? (
        <DialogSpinner />
      ) : hasActiveReferences ? (
        <ContainsReferences references={priceListReferencesData} />
      ) : (
        <ReadyToBeDeleted name={name} />
      )}
    </Dialog>
  );
}

export default DeleteDialog;
