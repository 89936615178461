import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useAxiosHook from '../../../../../hooks/useAxiosHook';
import useDialogVisibility from '../../../../../hooks/useDialogVisibility';
import useMediaQuery from '../../../../../hooks/useMediaQuery';
import usePageTitle from '../../../../../hooks/usePageTitle';
import usePrevious from '../../../../../hooks/usePrevious';
import useRouteDialog from '../../../../../hooks/useRouteDialog';
import useTableColumns from '../../../../../hooks/useTableColumns';
import useTableState from '../../../../../hooks/useTableState';
import useToastMessage from '../../../../../hooks/useToastMessage';
import { ClientResource } from '../../../../../types/api/clients';
import {
  AddCustomerReceptionOrderByBarcodeRequestPayload,
  AddCustomerReceptionOrderByBarcodeResource,
  AddCustomerReceptionOrderByBarcodeResourceError,
  AddCustomerReceptionOrderRequestPayload,
  CustomerReceptionResource,
} from '../../../../../types/api/customerReceptions';
import {
  CreateOrderResource,
  OrderCollection,
  OrderCollectionQueryParams,
  OrderImport,
} from '../../../../../types/api/orders';
import { RoutePaths } from '../../../../../utils/constants/routePaths';
import { noop, sequential } from '../../../../../utils/helpers/functions';
import { queryString } from '../../../../../utils/helpers/http';
import Table from '../../../../DataTable/Table/Table';
import Grid from '../../../../Grid/Grid';
import MainContent from '../../../../Grid/MainContent';
import SidePanels from '../../../../Grid/SidePanels';
import Barcode from '../../../../Sidebar/Barcode/Barcode';
import useBarcodeState from '../../../../Sidebar/Barcode/useBarcodeState';
import WebImportDialog from '../../../BulkOrders/Dialogs/WebImport/WebImportDialog';
import CreateEditDialog from '../../../Orders/Dialogs/CreateEdit/CreateEditDialog';
import ViewActiveOrderDialog from '../../../Orders/Dialogs/View/ViewActiveOrderDialog';
import { SingleOrder } from '../../../Orders/Orders.functions';
import {
  additionalColumnProperties,
  generateContextMenu,
  getBarcodeScanErrorMessageFromResponse,
  getColumnHeadersMap,
  tableStorageKey,
} from './CustomerShipmentReception.functions';
import {
  Column,
  CustomerReceptionOption,
  Row,
} from './CustomerShipmentReception.types';
import DiscardDialog from './Dialogs/Discard/DiscardDialog';
import NewReceptionDialog from './Dialogs/NewReception/NewReceptionDialog';
import SaveDialog from './Dialogs/Save/SaveDialog';
import Actions from './Sidebar/Actions';
import Filters from './Sidebar/Filters';

function CustomerShipmentReception(): JSX.Element {
  const { t } = useTranslation();

  usePageTitle(t('Customer Order Reception'));

  // Filters
  const [customerReceptionFilter, setCustomerReceptionFilter] = useState('');
  const [customerReceptionFilterObj, setCustomerReceptionFilterObj] =
    useState<CustomerReceptionOption | null>(null);

  const { data: newCustomerReceptionData, reload: newCustomerReceptionReload } =
    useAxiosHook<CustomerReceptionResource>();

  useEffect(() => {
    setCustomerReceptionFilter(newCustomerReceptionData?.name ?? '');
    setCustomerReceptionFilterObj(newCustomerReceptionData ?? null);
  }, [newCustomerReceptionData]);

  // Table
  const {
    tableRef,
    page,
    setPage,
    limit,
    setLimit,
    sortField,
    setSortField,
    sortOrder,
    setSortOrder,
    selection,
    setSelection,
    contextMenuSelection,
    setContextMenuSelection,
  } = useTableState<Row>(tableStorageKey);

  const columnHeadersMap = useMemo(() => getColumnHeadersMap(t), [t]);

  const { selectedColumns, setSelectedColumns, columnOptions, columns } =
    useTableColumns<Column>(
      page,
      limit,
      'customer_reception',
      columnHeadersMap,
      columnHeadersMap,
      additionalColumnProperties
    );

  const customerReceptionId = customerReceptionFilterObj?.id;

  const { data, error, isLoading, reload } = useAxiosHook<OrderCollection>(
    '/orders' +
      queryString<OrderCollectionQueryParams>({
        customer_reception_id: customerReceptionId,
        payments: 1,
        page,
        limit,
      }),
    { skipWhen: customerReceptionId === undefined }
  );

  const {
    show: showNewReceptionDialog,
    hide: hideNewReceptionDialog,
    isVisible: isNewReceptionDialogVisible,
  } = useRouteDialog(
    RoutePaths.CustomerShipmentReception,
    RoutePaths.AddCustomerShipmentReception
  );

  function onNewReceptionCreation(id: number) {
    newCustomerReceptionReload({
      url: `/customer-receptions/${id}`,
    });
  }

  const {
    show: showViewSingleOrderDialog,
    hide: hideViewSingleOrderDialog,
    isVisible: isViewSingleOrderDialogVisible,
  } = useDialogVisibility();

  const {
    show: showAddSingleOrderDialog,
    hide: hideAddSingleOrderDialog,
    isVisible: isAddSingleOrderDialogVisible,
  } = useRouteDialog(
    RoutePaths.CustomerShipmentReception,
    RoutePaths.AddSingleOrderCustomerShipmentReception
  );

  const {
    show: showAddBatchOrderDialog,
    hide: hideAddBatchOrderDialog,
    isVisible: isAddBatchOrderDialogVisible,
  } = useRouteDialog(
    RoutePaths.CustomerShipmentReception,
    RoutePaths.AddBatchOrderCustomerShipmentReception
  );

  const {
    show: showEditSingleOrderDialog,
    hide: hideEditSingleOrderDialog,
    isVisible: isEditSingleOrderDialogVisible,
  } = useDialogVisibility();

  const {
    show: showSaveReceptionDialog,
    hide: hideSaveReceptionDialog,
    isVisible: isSaveReceptionDialogVisible,
  } = useDialogVisibility();

  const {
    show: showDiscardReceptionDialog,
    hide: hideDiscardReceptionDialog,
    isVisible: isDiscardReceptionDialogVisible,
  } = useDialogVisibility();

  const {
    data: viewSingleOrderData,
    error: viewSingleOrderError,
    isLoading: isViewSingleOrderLoading,
  } = useAxiosHook<SingleOrder>(`/orders/${selection?.id}`, {
    skipWhen: selection?.id === undefined || !isViewSingleOrderDialogVisible,
  });

  useToastMessage(undefined, viewSingleOrderError, {
    error: {
      summary: t('An error occured while adding order.'),
      callback: () => sequential(hideViewSingleOrderDialog, reload),
    },
  });

  const {
    data: addSingleOrderData,
    error: addSingleOrderError,
    reload: addSingleOrderReload,
    isLoading: isAddSingleOrderLoading,
  } = useAxiosHook();

  useToastMessage(addSingleOrderData, addSingleOrderError, {
    success: {
      summary: t('Order added successfully.'),
      callback: reload,
    },
    error: {
      summary: t('An error occured while adding order.'),
    },
  });

  function handleSingleOrderCreate(response: CreateOrderResource) {
    const data: AddCustomerReceptionOrderRequestPayload = {
      order_id: response.id,
    };

    addSingleOrderReload({
      url: `/customer-receptions/${customerReceptionId}/orders`,
      method: 'PUT',
      data,
    });
  }

  const { data: editSingleOrderData, error: editSingleOrderError } =
    useAxiosHook<SingleOrder>(`/orders/${selection?.id}`, {
      skipWhen: !selection?.id || !isEditSingleOrderDialogVisible,
    });

  const prevEditSingleOrderData = usePrevious(editSingleOrderData);

  useEffect(() => {
    if (
      !editSingleOrderData ||
      editSingleOrderData === prevEditSingleOrderData
    ) {
      return;
    }

    reload();
  }, [editSingleOrderData, prevEditSingleOrderData, reload]);

  useToastMessage(undefined, editSingleOrderError, {
    error: {
      summary: t('An error occured while reading order data.'),
    },
  });

  function handleSingleOrderEdit(_: number) {
    reload();
  }

  const {
    data: discardSingleOrderData,
    error: discardSingleOrderError,
    reload: discardSingleOrderReload,
  } = useAxiosHook();

  useToastMessage(discardSingleOrderData, discardSingleOrderError, {
    success: {
      summary: t('Order discarded successfully.'),
      callback: reload,
    },
    error: {
      summary: t('An error occured while discarding order.'),
    },
  });

  const {
    data: addBatchOrderDialogClientData,
    error: addBatchOrderDialogClientError,
    isLoading: isAddBatchOrderDialogClientLoading,
  } = useAxiosHook<ClientResource>(
    `/clients/${customerReceptionFilterObj?.client_id}`,
    {
      skipWhen:
        !customerReceptionFilterObj?.client_id || !isAddBatchOrderDialogVisible,
    }
  );

  useToastMessage(undefined, addBatchOrderDialogClientError, {
    error: {
      summary: t('An error occured while reading client data.'),
      callback: hideAddBatchOrderDialog,
    },
  });

  const {
    data: addBatchOrderData,
    error: addBatchOrderError,
    isLoading: isAddBatchOrderLoading,
    reload: addBatchOrderReload,
  } = useAxiosHook();

  useToastMessage(addBatchOrderData, addBatchOrderError, {
    success: {
      summary: t('Orders successfully added!'),
      callback: () => sequential(hideAddBatchOrderDialog, reload),
    },
    error: {
      summary: t('An error occured while reading client data.'),
      callback: hideAddBatchOrderDialog,
    },
  });

  function onBatchOrderImport(response: OrderImport) {
    const data: AddCustomerReceptionOrderRequestPayload = {
      import_list_id: response.log.import.listID,
    };

    addBatchOrderReload({
      url: `/customer-receptions/${customerReceptionId}/orders`,
      method: 'PUT',
      data,
    });
  }

  const {
    value: barcodeValue,
    setValue: setBarcodeValue,
    hasError: hasBarcodeError,
    setHasError: setHasBarcodeError,
  } = useBarcodeState();

  const {
    data: barcodeScanData,
    error: barcodeScanError,
    isLoading: isBarcodeScanLoading,
    reload: barcodeScanReload,
  } = useAxiosHook<
    AddCustomerReceptionOrderByBarcodeResource,
    AddCustomerReceptionOrderByBarcodeResourceError
  >();

  const prevBarcodeScanData = usePrevious(barcodeScanData);

  useEffect(() => {
    if (
      barcodeScanData === prevBarcodeScanData ||
      customerReceptionId ||
      barcodeScanData?.customer_reception_id === undefined
    ) {
      return;
    }

    newCustomerReceptionReload({
      url: `/customer-receptions/${barcodeScanData.customer_reception_id}`,
    });
  }, [
    barcodeScanData,
    customerReceptionId,
    newCustomerReceptionReload,
    prevBarcodeScanData,
  ]);

  useToastMessage(barcodeScanData, barcodeScanError, {
    success: {
      summary:
        barcodeScanData?.barcode_type === 'order'
          ? t('Order added successfully!')
          : t('Order list added successfully!'),
      callback: reload,
    },
    error: {
      summary: getBarcodeScanErrorMessageFromResponse(t, barcodeScanError),
    },
  });

  useEffect(() => {
    if (!barcodeValue) {
      return;
    }

    const data: AddCustomerReceptionOrderByBarcodeRequestPayload = {
      barcode: barcodeValue,
      customer_reception_id: customerReceptionId,
    };

    barcodeScanReload({
      url: '/customer-receptions/orders',
      method: 'PUT',
      data,
    });

    setBarcodeValue('');
  }, [barcodeScanReload, barcodeValue, customerReceptionId, setBarcodeValue]);

  function handleCustomerReceptionSave() {
    setCustomerReceptionFilter('');
    setCustomerReceptionFilterObj(null);
  }

  function handleCustomerReceptionDiscard() {
    setCustomerReceptionFilter('');
    setCustomerReceptionFilterObj(null);
  }

  const contextMenuModel = useMemo(() => {
    function handleDiscard() {
      discardSingleOrderReload({
        url: `/customer-receptions/${customerReceptionId}/orders/${selection?.id}`,
        method: 'DELETE',
      });
    }

    return generateContextMenu({
      t,
      handleView: showViewSingleOrderDialog,
      handleEdit: showEditSingleOrderDialog,
      handleDiscard,
    });
  }, [
    customerReceptionId,
    discardSingleOrderReload,
    selection?.id,
    showEditSingleOrderDialog,
    showViewSingleOrderDialog,
    t,
  ]);

  const singleOrderDialogInitialCreationValues = useMemo(
    () =>
      !!customerReceptionFilterObj?.client_id
        ? {
            klient_od_id: customerReceptionFilterObj.client_id,
            klient_od_ime: customerReceptionFilterObj.name,
          }
        : undefined,
    [customerReceptionFilterObj?.client_id, customerReceptionFilterObj?.name]
  );

  const batchOrderDialogInitialValues = useMemo(
    () =>
      !!customerReceptionFilterObj?.client_id && addBatchOrderDialogClientData
        ? {
            _clientFilterObj: {
              id: addBatchOrderDialogClientData.id,
              korisnik_id: addBatchOrderDialogClientData.korisnik_id,
              broj: addBatchOrderDialogClientData.broj,
              mesto_id: addBatchOrderDialogClientData.mesto_id,
              stan: addBatchOrderDialogClientData.stan,
              ulica_id: addBatchOrderDialogClientData.ulica_id,
              adresa: addBatchOrderDialogClientData.street_name,
              vlez: addBatchOrderDialogClientData.vlez,
            },
            _clientFilter: customerReceptionFilterObj.name,
          }
        : undefined,
    [
      addBatchOrderDialogClientData,
      customerReceptionFilterObj?.client_id,
      customerReceptionFilterObj?.name,
    ]
  );

  const isSmallScreen = useMediaQuery('(max-width: 1000px)');

  const isTableLoading =
    isLoading || isAddBatchOrderLoading || isAddSingleOrderLoading;

  return (
    <div>
      <h2 className="title">{t('Reception From Customer In The Warehouse')}</h2>
      <p className="subtitle">{t('Receive orders from customers')}</p>

      <NewReceptionDialog
        visible={isNewReceptionDialogVisible}
        onHide={hideNewReceptionDialog}
        onSuccess={onNewReceptionCreation}
      />

      <ViewActiveOrderDialog
        visible={isViewSingleOrderDialogVisible}
        data={viewSingleOrderData}
        isLoading={isViewSingleOrderLoading}
        onEdit={() =>
          sequential(hideViewSingleOrderDialog, showEditSingleOrderDialog)
        }
        onHide={hideViewSingleOrderDialog}
      />

      <CreateEditDialog
        title={
          isAddSingleOrderDialogVisible ? t('Receive Shipment') : undefined
        }
        data={editSingleOrderData}
        visible={
          isAddSingleOrderDialogVisible || isEditSingleOrderDialogVisible
        }
        isEditDialog={isEditSingleOrderDialogVisible}
        isWarehouseReception={true}
        onHide={() =>
          sequential(hideAddSingleOrderDialog, hideEditSingleOrderDialog)
        }
        reload={noop}
        onCreate={handleSingleOrderCreate}
        onEdit={handleSingleOrderEdit}
        setOrderData={noop}
        isSmallScreen={isSmallScreen}
        initialCreationValues={singleOrderDialogInitialCreationValues}
        isSenderTabDisabled={!!customerReceptionFilterObj?.client_id}
      />

      <WebImportDialog
        isShown={isAddBatchOrderDialogVisible}
        onHide={hideAddBatchOrderDialog}
        reloadImportedLists={reload}
        initialValues={batchOrderDialogInitialValues}
        isLoading={isAddBatchOrderDialogClientLoading || isAddBatchOrderLoading}
        isClientAutoCompleteDisabled={true}
        onImport={onBatchOrderImport}
      />

      <SaveDialog
        visible={isSaveReceptionDialogVisible}
        onHide={hideSaveReceptionDialog}
        onSuccess={handleCustomerReceptionSave}
        receptionId={customerReceptionId}
        shipmentCount={data?.pagination.total ?? 0}
      />

      <DiscardDialog
        visible={isDiscardReceptionDialogVisible}
        onHide={hideDiscardReceptionDialog}
        onSuccess={handleCustomerReceptionDiscard}
        receptionId={customerReceptionId}
      />

      <Grid>
        <SidePanels>
          <Filters
            customerReceptionFilter={customerReceptionFilter}
            setCustomerReceptionFilter={setCustomerReceptionFilter}
            customerReceptionFilterObj={customerReceptionFilterObj}
            setCustomerReceptionFilterObj={setCustomerReceptionFilterObj}
          />

          <Actions
            receptionId={customerReceptionFilterObj?.id}
            onNewReceptionBtnClick={showNewReceptionDialog}
            onSingleOrderBtnClick={showAddSingleOrderDialog}
            onBatchOrderBtnClick={showAddBatchOrderDialog}
            onSaveBtnClick={showSaveReceptionDialog}
            onDiscardBtnClick={showDiscardReceptionDialog}
            isSaveBtnDisabled={!data?.data.length}
          />

          <Barcode
            value={barcodeValue}
            setValue={setBarcodeValue}
            hasError={hasBarcodeError}
            setHasError={setHasBarcodeError}
            disabled={isBarcodeScanLoading}
          />
        </SidePanels>

        <MainContent>
          <Table
            tableRef={tableRef}
            columns={columns}
            data={customerReceptionId !== undefined ? data : undefined}
            isLoading={isTableLoading}
            hasError={customerReceptionId !== undefined ? !!error : false}
            reload={customerReceptionId !== undefined ? reload : noop}
            headerTitle={t('Orders')}
            emptyMessage={
              customerReceptionId === undefined
                ? t(
                    'Please select an already existing reception or create a new one'
                  )
                : !data?.data.length
                ? t('No orders are present yet for the current reception.')
                : undefined
            }
            setPage={setPage}
            setLimit={setLimit}
            rows={limit}
            contextMenuModel={contextMenuModel}
            selection={selection}
            setSelection={setSelection}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
            sortField={sortField}
            setSortField={setSortField}
            storageString={tableStorageKey}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            columnOptions={columnOptions}
            onRowDoubleClick={showViewSingleOrderDialog}
            contextMenuSelection={contextMenuSelection}
            setContextMenuSelection={setContextMenuSelection}
          />
        </MainContent>
      </Grid>
    </div>
  );
}

export default CustomerShipmentReception;
