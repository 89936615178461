import { Field } from 'formik';
import { Dropdown } from 'primereact/dropdown';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PriceListCollection } from '../../../../../../types/api/priceLists';
import { LabelValue } from '../../../../../../types/options';
import FieldWithErrors from '../../../../../Forms/FieldWithErrors/FieldWithErrors';

type Props = {
  priceListsData: PriceListCollection | undefined;
  arePriceListsLoading: boolean;
};

function PaymentsTab({
  priceListsData,
  arePriceListsLoading,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const priceListOptions = useMemo<LabelValue<number>[]>(
    () =>
      priceListsData?.data.map((p) => ({
        label: p.name ?? '',
        value: p.id,
      })) ?? [],
    [priceListsData]
  );

  return (
    <div className="payments-tab">
      <FieldWithErrors
        name="cenovnik_id"
        label={t('Price list')}
        className="p-fluid"
      >
        <Field
          as={Dropdown}
          name="cenovnik_id"
          inputId="cenovnik_id"
          options={priceListOptions}
          placeholder={
            arePriceListsLoading
              ? t('Loading...')
              : priceListOptions.length > 0
              ? t('Select a price list')
              : t('No pricelists founds')
          }
          disabled={!priceListOptions.length}
        />
      </FieldWithErrors>
    </div>
  );
}

export default PaymentsTab;
