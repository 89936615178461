import { Dispatch, SetStateAction } from 'react';
import { TFunction } from 'react-i18next';

import { getReconciliationStatusConfig } from '../../../configs/reconciliations';
import { ReconciliationStatus } from '../../../enums/reconciliations';
import { emptyCell, noColumnStyle } from '../../../utils/constants/tables';
import { currencyFormat } from '../../../utils/helpers/formatting';
import ActionsTableMenu from '../Components/ActionsTableMenu/ActionsTableMenu';
import { DataRow } from './CourierReconciliation.types';

export const tableStorageKey = 'reconciliationLists_dataTable';

export function getColumnHeadersMap(t: TFunction) {
  return {
    no: t('No.'),
    work_order_id: t('Work Order'),
    status_id: t('Status'),
    work_order_date: t('Date'),
    courier_name: t('Courier'),
    times_printed: t('Times printed'),
    created_at: t('Created at'),
    created_by_full_name: t('Created by'),
    modified_at: t('Modified at'),
    modified_by_full_name: t('Modified by'),
    total_cash_reconciled_delivered_sum: t(
      'Total cash from reconciled delivered sum'
    ),
    total_cash_delivered_sum: t('Expected cash from delivered'),
    total_cash_reconciled_pickedup_sum: t(
      'Total cash from reconciled pickedup sum'
    ),
    total_cash_pickedup_sum: t('Expected cash from picked up'),
    total_cash_reconciled_redemption_sum: t(
      'Total cash reconciled redemption sum'
    ),
    total_cash_redemption_sum: t('Expected cash redemption sum'),
    total_reconciled_delivered_shipments: t(
      'Total reconciled delivered shipments'
    ),
    total_delivered_shipments: t('Total delivered shipments'),
    total_reconciled_pickedup_shipments: t(
      'Total reconciled pickedup shipments'
    ),
    total_pickedup_shipments: t('Total pickedup shipments'),
  };
}

export function additionalColumnProperties(
  t: TFunction,
  column: keyof ReturnType<typeof getColumnHeadersMap> | 'actions',
  setContextMenuSelection: Dispatch<SetStateAction<any>>,
  setAction: (t: string) => void,
  markAsDoneGuard: boolean,
  approveGuard: boolean,
  rejectGuard: boolean
) {
  const reconciliationStatusConfig = getReconciliationStatusConfig(t);
  switch (column) {
    case 'no':
      return noColumnStyle;

    case 'work_order_id':
      return {
        style: {
          width: 120,
        },
        bodyStyle: {
          width: 120,
        },
      };
    case 'status_id':
      return {
        body: (row: DataRow) => {
          const config =
            reconciliationStatusConfig[row.status_id as ReconciliationStatus];
          return (
            <div className="text-center">
              <span
                style={{
                  backgroundColor: config.bgColor,
                  color: config.color,
                  border: `1px solid ${config.color}`,
                  borderRadius: 25,
                  display: 'inline-block',
                  padding: '0 8px',
                }}
              >
                {config.label}
              </span>
            </div>
          );
        },
      };
    case 'work_order_date':
    case 'courier_name':
      return {
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
        },
      };
    case 'times_printed':
      return {
        style: {
          width: 100,
        },
        bodyStyle: {
          width: 100,
          textAlign: 'center',
        },
      };
    case 'created_at':
      return {
        style: {
          width: 120,
        },
        bodyStyle: {
          width: 120,
        },
      };

    case 'created_by_full_name':
      return {
        style: {
          width: 240,
        },
        bodyStyle: {
          width: 240,
        },
      };
    case 'modified_at':
      return {
        style: {
          width: 120,
        },
        bodyStyle: {
          width: 120,
        },
      };
    case 'modified_by_full_name':
      return {
        style: {
          width: 240,
        },
        bodyStyle: {
          width: 240,
        },
      };
    case 'total_cash_reconciled_delivered_sum':
    case 'total_cash_delivered_sum':
    case 'total_cash_reconciled_pickedup_sum':
    case 'total_cash_pickedup_sum':
    case 'total_cash_reconciled_redemption_sum':
    case 'total_cash_redemption_sum':
      return {
        body: (row: DataRow) => {
          return typeof row[column] === 'string' ||
            typeof row[column] === 'number'
            ? currencyFormat(row[column] as string, { showCurrency: true })
            : emptyCell;
        },
        style: {
          width: 120,
        },
        bodyStyle: {
          width: 120,
        },
      };
    case 'total_reconciled_delivered_shipments':
    case 'total_delivered_shipments':
    case 'total_reconciled_pickedup_shipments':
    case 'total_pickedup_shipments':
      return {
        style: {
          width: 180,
        },
        bodyStyle: {
          width: 180,
          textAlign: 'center',
        },
      };
    case 'actions':
      return {
        body: (row: DataRow) => {
          return (
            <ActionsTableMenu
              actions={[
                {
                  label:
                    row.status_id === ReconciliationStatus.Creating
                      ? t('Reconcile Shipments')
                      : t('Reconciled Shipments'),
                  icon: 'fas fa-coins',
                  disabled: false,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('shipment-reconciliation');
                    // setCaller('context-menu');
                  },
                },
                {
                  label: t('Mark as done'),
                  icon: 'fas fa-check-double',
                  disabled:
                    row.status_id === ReconciliationStatus.Creating &&
                    !markAsDoneGuard,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('mark-as-done');
                    // setCaller('context-menu');
                  },
                },
                {
                  label: t('Print'),
                  icon: 'fas fa-print',
                  disabled: false,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('print');
                    // setCaller('context-menu');
                  },
                },
                {
                  label: t('Approve'),
                  icon: 'fas fa-check-circle',
                  disabled:
                    row.status_id === ReconciliationStatus.Creating &&
                    !approveGuard,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('approve');
                    // setCaller('context-menu');
                  },
                },
                {
                  label: t('Mark for correction'),
                  icon: 'fas fa-undo-alt',
                  disabled:
                    row.status_id === ReconciliationStatus.WaitingForApproval &&
                    !rejectGuard,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('mark-for-correction');
                    // setCaller('context-menu');
                  },
                },
              ]}
            />
          );
        },
        style: {
          width: 60,
          borderLeft: '1px solid #e9ecef',
          flex: '0 1 auto',
          position: 'sticky',
        },
        bodyStyle: {
          width: 60,
          borderLeft: '1px solid #e9ecef',
          flex: '0 1 auto',
          position: 'sticky',
          justifyContent: 'center',
        },
      };

    default:
      return {};
  }
}
