import { Coordinates, MaxBounds, Zoom } from '../../types/maps';

export const coordinates: Coordinates = {
  mk: [41.6086, 21.7453],
  rs: [44.0165, 21.0059],
};

export const maxBounds: MaxBounds = {
  mk: [
    [42.416318, 19.839309],
    [40.553642, 23.706547],
  ],
  rs: [
    [46.717, 17.688],
    [41.517, 24.016],
  ],
};

export const zoom: Zoom = {
  mk: {
    minZoom: 8,
    maxZoom: 19,
    defaultZoom: 9,
  },
  rs: {
    minZoom: 6,
    maxZoom: 18,
    defaultZoom: 9,
  },
};

export const defaultTileLayerProvider =
  'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
