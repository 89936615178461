import { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import ToastContext from '../../../../../context/ToastContext';
import useAxiosHook from '../../../../../hooks/useAxiosHook';
import usePrevious from '../../../../../hooks/usePrevious';
import useToastMessage from '../../../../../hooks/useToastMessage';
import { WorkOrderResource } from '../../../../../types/api/workOrders';
import { httpStringToDate } from '../../../../../utils/helpers/dates';
import { errorToast } from '../../../../../utils/helpers/primereact';
import PlainBarcode from '../../../../Sidebar/Barcode/Barcode';
import useBarcodeState from '../../../../Sidebar/Barcode/useBarcodeState';
import CommonStateContext from '../CommonStateContext';

const prefixExceptions = ['LD'];

type Props = {
  onValidBarcodeScan: (barcode: string) => void;
  disabled?: boolean;
};

function Barcode({ onValidBarcodeScan, disabled }: Props): JSX.Element {
  const { t } = useTranslation();

  const { toastRef } = useContext(ToastContext);

  const {
    setDateFilter,
    courierFilter,
    setCourierFilter,
    isDateValid,
    hasCourierWorkOrder,
    setDestinationHubFilter,
  } = useContext(CommonStateContext);

  const {
    value,
    setValue,
    hasError,
    setHasError,
    isInvalidPrefix,
    setIsInvalidPrefix,
    scannedBarcodes,
    setScannedBarcodes,
  } = useBarcodeState();

  const {
    data: workOrderBarcodeData,
    error: workOrderBarcodeError,
    reload: workOrderBarcodeReload,
  } = useAxiosHook<WorkOrderResource>();

  const prevWorkOrderBarcodeData = usePrevious(workOrderBarcodeData);

  useEffect(() => {
    if (
      !workOrderBarcodeData ||
      workOrderBarcodeData === prevWorkOrderBarcodeData
    ) {
      return;
    }

    setCourierFilter(workOrderBarcodeData.courier_id);
    setDateFilter(httpStringToDate(workOrderBarcodeData.date));
    setDestinationHubFilter(workOrderBarcodeData.courier_hub_id);
  }, [
    prevWorkOrderBarcodeData,
    setCourierFilter,
    setDateFilter,
    setDestinationHubFilter,
    workOrderBarcodeData,
  ]);

  useToastMessage(undefined, workOrderBarcodeError, {
    error: {
      summary: t('An error occured while loading work order data.'),
    },
  });

  const validityCheck = useCallback<() => boolean>(() => {
    if (!hasCourierWorkOrder) {
      setIsInvalidPrefix(true);

      errorToast(
        toastRef,
        t('Invalid barcode!'),
        t(
          'When no work order is present only work order (LD prefixed) barcodes can be scanned!'
        )
      );

      return false;
    }

    return true;
  }, [hasCourierWorkOrder, setIsInvalidPrefix, t, toastRef]);

  useEffect(() => {
    if (!value) {
      return;
    }

    if (isDateValid) {
      onValidBarcodeScan(value);
    } else {
      setHasError(true);
    }

    setValue('');
  }, [isDateValid, onValidBarcodeScan, setHasError, setValue, value]);

  const handlePrefixExceptionScan = useCallback(
    (_, __, valueWithoutPrefix: string) => {
      workOrderBarcodeReload({
        url: `/work-orders/${valueWithoutPrefix}`,
      });

      setValue('');
    },
    [setValue, workOrderBarcodeReload]
  );

  const notTodaysDateMessage = !isDateValid
    ? t('Date is different from today!')
    : undefined;

  return (
    <PlainBarcode
      value={value}
      setValue={setValue}
      hasError={hasError}
      setHasError={setHasError}
      isInvalidPrefix={isInvalidPrefix}
      setIsInvalidPrefix={setIsInvalidPrefix}
      errorMessage={notTodaysDateMessage}
      scannedBarcodes={scannedBarcodes}
      setScannedBarcodes={setScannedBarcodes}
      infoMessage={notTodaysDateMessage}
      prefixExceptions={prefixExceptions}
      onPrefixExceptionScan={handlePrefixExceptionScan}
      placeholder={
        isDateValid && courierFilter ? undefined : t('Work Order ID')
      }
      disabled={disabled}
      validityCheck={validityCheck}
    />
  );
}

export default Barcode;
