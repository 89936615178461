import { OverlayPanel, OverlayPanelProps } from 'primereact/overlaypanel';
import { MouseEvent, ReactNode, useRef } from 'react';

type Props = OverlayPanelProps & {
  fullText: ReactNode;
  children: ReactNode;
};

function OverlayHoverPanel({
  children,
  fullText,
  style,
  ...rest
}: Props): JSX.Element {
  const wrapperRef = useRef(null);
  const ref = useRef<OverlayPanel>(null);

  function handleMouseEnter(e: MouseEvent<HTMLSpanElement>) {
    if (!ref.current) {
      return;
    }

    ref.current.show(e, wrapperRef.current);
  }

  function handleMouseLeave() {
    if (!ref.current) {
      return;
    }

    ref.current.hide();
  }

  return (
    <div ref={wrapperRef}>
      <OverlayPanel ref={ref} {...rest} style={{ maxWidth: 600, ...style }}>
        {fullText}
      </OverlayPanel>

      <span onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {children}
      </span>
    </div>
  );
}

export default OverlayHoverPanel;
