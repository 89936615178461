import { TFunction } from 'i18next';

import { LabelValue } from '../../../../types/options';
import { CandidatesFilter } from './types';

export function getCandidateFilterOptions(
  t: TFunction
): LabelValue<CandidatesFilter>[] {
  return [
    {
      label: t('Candidates first') + ' ' + t('(default)'),
      value: CandidatesFilter.First,
    },
    { label: t('Candidates last'), value: CandidatesFilter.Last },
    { label: t('Hide candidates'), value: CandidatesFilter.Hidden },
  ];
}
