import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { UseAxiosReturn } from '../../../../../../hooks/useAxiosHook';
import { TransferCourierSummaryCollection } from '../../../../../../types/api/orders';
import { tryInt, trySum } from '../../../../../../utils/helpers/parse';
import StatsWrapper from '../../../../Components/StatsWrapper/StatsWrapper';
import CommonStateContext from '../../../_AssignmentAndReception/CommonStateContext';

type Props = {
  request: UseAxiosReturn<TransferCourierSummaryCollection>;
};

function Stats({ request: { data } }: Props): JSX.Element {
  const { t } = useTranslation();

  const { hasCourierWorkOrder } = useContext(CommonStateContext);

  const hubs = data?.[0]?.hubs;
  const available = hubs?.reduce(
    (acc, hub) => acc + (tryInt(hub.reception.available) ?? 0),
    0
  );

  const completed = hubs?.reduce(
    (acc, hub) => acc + (tryInt(hub.reception.completed) ?? 0),
    0
  );

  const expected = trySum(available, completed);

  return (
    <StatsWrapper>
      <p>
        - {t('Received.THEM')}:{' '}
        <b>{hasCourierWorkOrder ? completed ?? '-' : '-'}</b>
      </p>

      <p>
        - {t('Remaining.THEM')}:{' '}
        <b>{hasCourierWorkOrder ? available ?? '-' : '-'}</b>
      </p>

      <p>
        - {t('Expected.THEM')}:{' '}
        <b>{hasCourierWorkOrder ? expected ?? '-' : '-'}</b>
      </p>
    </StatsWrapper>
  );
}

export default Stats;
