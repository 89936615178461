import { ColumnProps } from 'primereact/column';
import { TFunction } from 'react-i18next';

import { InvoiceCandidatesCustomerCollection } from '../../../../types/api/invoices';
import {
  TableColumnHeadersMap,
  TableColumns,
  Unpacked,
} from '../../../../types/util';
import { noColumnStyle } from '../../../../utils/constants/tables';
import {
  currencyFormat,
  percentageFormat,
} from '../../../../utils/helpers/formatting';
import { contextMenuModel } from '../../../../utils/helpers/primereact';

export type Row = Unpacked<InvoiceCandidatesCustomerCollection>;

export type Column = TableColumns<
  Pick<
    Row,
    | 'client_id'
    | 'client_name'
    | 'count'
    | 'department_name'
    | 'discounts'
    | 'price'
    | 'price_and_vat_discounted'
    | 'price_discount'
    | 'vat_discount'
  >
>;

export const tableStorageKey = 'invoices_dataTable';

export function getColumnHeadersMap(
  t: TFunction
): TableColumnHeadersMap<Column> {
  return {
    no: t('No.'),
    client_id: t('Client ID'),
    client_name: t('Client'),
    department_name: t('Department'),
    count: t('Shipments'),
    price: t('Price'),
    discounts: t('Discounts'),
    price_discount: t('Discounted Price'),
    vat_discount: t('VAT After Discount'),
    price_and_vat_discounted: t('Total Amount'),
  };
}

export function additionalColumnProperties(column: Column): ColumnProps {
  switch (column) {
    case 'no':
      return noColumnStyle;

    case 'client_id':
      return {
        style: { width: 100 },
        bodyStyle: { width: 100 },
      };

    case 'department_name':
      return {
        style: { width: 200 },
        bodyStyle: { width: 200 },
      };

    case 'client_name':
      return {
        style: { width: 350 },
        bodyStyle: { width: 350 },
      };

    case 'count':
      return {
        style: { width: 120 },
        bodyStyle: { width: 120 },
      };

    case 'discounts':
      return {
        body: (row: Row) => percentageFormat(row.discounts),
        style: { width: 200 },
        bodyStyle: { width: 200 },
      };

    case 'price_and_vat_discounted':
    case 'price_discount':
    case 'price':
    case 'vat_discount':
      return {
        body: (row: Row) => currencyFormat(row[column], { showCurrency: true }),
        style: { width: 200 },
        bodyStyle: { width: 200 },
      };
  }
}

export function generateContextMenu({
  t,
  isViewItemsVisible,
  handleViewItemsClick,
  isViewShipmentsVisible,
  handleViewShipmentsClick,
}: {
  t: TFunction;
  isViewItemsVisible: boolean;
  handleViewItemsClick: () => void;
  isViewShipmentsVisible: boolean;
  handleViewShipmentsClick: () => void;
}) {
  const viewInvoiceItemsOption = {
    label: t('Show Items'),
    icon: 'fas fa-search',
    command: handleViewItemsClick,
  };

  const viewInvoiceShipmentsOption = {
    label: t('Show Shipments'),
    icon: 'fas fa-search',
    command: handleViewShipmentsClick,
  };

  return contextMenuModel([
    [
      [isViewItemsVisible, viewInvoiceItemsOption],
      [isViewShipmentsVisible, viewInvoiceShipmentsOption],
    ],
  ]);
}

export function generateGroupActions({
  t,
  isCreateInvoiceVisible,
  handleCreateClick,
}: {
  t: TFunction;
  isCreateInvoiceVisible: boolean;
  handleCreateClick: () => void;
}) {
  const createInvoicesOption = {
    label: t('Create Invoices'),
    icon: 'fas fa-plus',
    command: handleCreateClick,
  };

  return contextMenuModel([[[isCreateInvoiceVisible, createInvoicesOption]]]);
}
