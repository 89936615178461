import _ from 'lodash';
import { Column } from 'primereact/column';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';

import { emptyCell } from '../utils/constants/tables';
import { getSavedSelectedColumns } from '../utils/helpers/dataTable';

type Return = {
  selectedColumns: object[];
  setSelectedColumns: Dispatch<SetStateAction<object[]>>;
  columns: JSX.Element[];
  columnOptions: { field: string; header: string; label: string }[];
};

function objectToColumns(columnHeadersMap: Record<string, string>) {
  return Object.keys(columnHeadersMap).map((c) => {
    return { field: c, header: c, label: columnHeadersMap[c] };
  });
}

function useTableColumns<T extends string = string>(
  page: number,
  limit: number,
  key: string,
  defaultSelectedColumns: Record<string, string> | object[],
  columnHeadersMap: Record<string, string>,
  additionalColumnProps?: (c: T) => object
): Return {
  const defaultCols = useMemo<object[]>(() => {
    if (Array.isArray(defaultSelectedColumns)) {
      return defaultSelectedColumns;
    }

    return objectToColumns(defaultSelectedColumns);
  }, [defaultSelectedColumns]);

  const [selectedColumns, setSelectedColumns] = useState<object[]>(
    getSavedSelectedColumns(key + '_dataTableSelectedColumns', defaultCols)
  );

  const columns = useMemo<JSX.Element[]>(() => {
    return (
      selectedColumns.length
        ? _.map(selectedColumns, 'field')
        : Object.keys(columnHeadersMap)
    ).map((c) => (
      <Column
        key={c}
        header={columnHeadersMap[c]}
        field={c}
        sortable
        body={(rowData: any, colData: any) => {
          if (c === 'no') {
            return rowData.id === 'hide-no-col-row'
              ? ''
              : colData.rowIndex + 1 + (page - 1) * limit;
          }

          return (
            (typeof rowData[c] === 'string' ? rowData[c].trim() : rowData[c]) ??
            emptyCell
          );
        }}
        {...(typeof additionalColumnProps === 'function'
          ? additionalColumnProps(c)
          : {})}
      />
    ));
  }, [additionalColumnProps, columnHeadersMap, limit, page, selectedColumns]);

  const columnOptions = useMemo(
    () => objectToColumns(columnHeadersMap),
    [columnHeadersMap]
  );

  return { selectedColumns, setSelectedColumns, columns, columnOptions };
}

export default useTableColumns;
