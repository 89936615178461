import './Stats.scss';

import { useTranslation } from 'react-i18next';

import { UseAxiosReturn } from '../../../../../hooks/useAxiosHook';
import useToastMessage from '../../../../../hooks/useToastMessage';
import { ReconciliationStatsResource } from '../../../../../types/api/reconciliations';
import { currencyFormat } from '../../../../../utils/helpers/formatting';
import SidebarItem from '../../../../Grid/Sidebar/SidebarItem';
import SidebarContentDivider from '../../../../Sidebar/ContentDivider/SidebarContentDivider';

export type StatsProps = {
  request: UseAxiosReturn<ReconciliationStatsResource>;
};
function Stats({ request }: StatsProps) {
  const { t } = useTranslation();

  const { data, error, isLoading } = request;

  useToastMessage(undefined, error, {
    error: {
      summary: t('An error occured while reading reconciliation statistics.'),
    },
  });

  return (
    <SidebarItem template="stats" className="stats">
      <span className="stats-section">
        - {t('Reconciled delivered')}:
        <p className="stats-section">
          {isLoading ? (
            <div className="skeleton-content">
              <div className="skeleton"></div>/ <div className="skeleton"></div>
            </div>
          ) : (
            <>
              {data?.total_reconciled_delivered_shipments} /{' '}
              {data?.total_delivered_shipments}
            </>
          )}
        </p>
      </span>
      <br />
      <span className="stats-section">
        - {t('Reconciled picked up')}:{' '}
        {!isLoading ? (
          <p className="stats-section">
            {data?.total_reconciled_pickedup_shipments} /{' '}
            {data?.total_pickedup_shipments}
          </p>
        ) : (
          <div className="skeleton-content">
            <div className="skeleton"></div>/ <div className="skeleton"></div>
          </div>
        )}
      </span>
      <br />
      <span className="stats-section">
        - {t('Total cash from redemption')}:{' '}
        {!isLoading && data ? (
          <p className="stats-section">
            {currencyFormat(data?.total_cash_reconciled_redemption_sum, {
              showCurrency: true,
            })}{' '}
            /{' '}
            {currencyFormat(data?.total_cash_redemption_sum, {
              showCurrency: true,
            })}
          </p>
        ) : (
          <div className="skeleton-content">
            <div className="skeleton"></div>/ <div className="skeleton"></div>
          </div>
        )}
      </span>
      <br />
      <span className="stats-section">
        - {t('Total cash from delivered')}:{' '}
        {!isLoading && data ? (
          <p className="stats-section">
            {currencyFormat(data?.total_cash_reconciled_delivered_sum, {
              showCurrency: true,
            })}{' '}
            /{' '}
            {currencyFormat(data?.total_cash_delivered_sum, {
              showCurrency: true,
            })}
          </p>
        ) : (
          <div className="skeleton-content">
            <div className="skeleton"></div>/ <div className="skeleton"></div>
          </div>
        )}
      </span>
      <br />
      <span className="stats-section">
        - {t('Total cash from picked up')}:{' '}
        {!isLoading && data ? (
          <p className="stats-section">
            {currencyFormat(data.total_cash_reconcilled_pickedup_sum, {
              showCurrency: true,
            })}{' '}
            /{' '}
            {currencyFormat(data.total_cash_pickedup_sum, {
              showCurrency: true,
            })}
          </p>
        ) : (
          <div className="skeleton-content">
            <div className="skeleton"></div>/ <div className="skeleton"></div>
          </div>
        )}
      </span>
      <SidebarContentDivider />
      <span className="p-mt-0 p-text-secondary p-text-bold">
        {t('Earnings')}
      </span>
      <br />
      <span className="stats-section">
        - {t('Total cash')}:{' '}
        <p className="stats-section">
          {!isLoading && data ? (
            currencyFormat(data.total_cash_sum, {
              showCurrency: true,
            })
          ) : (
            <div className="skeleton-content">
              <div className="skeleton"></div>
            </div>
          )}
        </p>
      </span>
      <br />
      <span className="stats-section">
        - {t('Total reconciled cash')}:{' '}
        <p className="stats-section">
          {!isLoading && data ? (
            currencyFormat(data.total_cash_reconciled_sum, {
              showCurrency: true,
            })
          ) : (
            <div className="skeleton-content">
              <div className="skeleton"></div>
            </div>
          )}
        </p>
      </span>
      <br />
      <span>
        - {t('Total fiscalized amount')}:{' '}
        <p className="stats-section">
          {!isLoading && data ? (
            currencyFormat(data.total_fiscalized_amount, {
              showCurrency: true,
            })
          ) : (
            <div className="skeleton-content">
              <div className="skeleton"></div>
            </div>
          )}
        </p>
      </span>
    </SidebarItem>
  );
}

export default Stats;
