import { priceListCollectionGuard } from './_api/priceLists';
import {
  addPriceResourceGuard,
  deletePriceResourceGuard,
  editPriceResourceGuard,
  priceCollectionGuard,
  priceResourceGuard,
} from './_api/prices';
import { tariffCollectionGuard } from './_api/tariffs';

export const createGuard = [
  addPriceResourceGuard,
  tariffCollectionGuard,
  priceListCollectionGuard,
];
export const editGuard = editPriceResourceGuard;
export const deleteGuard = deletePriceResourceGuard;

export const routeGuard = [priceResourceGuard, priceCollectionGuard];
