import { faMinus } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  getInvoiceEmailStatusOptions,
  getInvoiceStatusOptions,
} from '../../../../../configs/invoices';
import {
  InvoiceEmailStatus,
  InvoiceStatus,
} from '../../../../../enums/invoices';
import useAxiosHook from '../../../../../hooks/useAxiosHook';
import useSearchQueryDateParam from '../../../../../hooks/useSearchQueryDateParam';
import useSearchQueryDropdownParam from '../../../../../hooks/useSearchQueryDropdownParam';
import useSearchQueryMonthParam from '../../../../../hooks/useSearchQueryMonthParam';
import useSearchQueryParam from '../../../../../hooks/useSearchQueryParam';
import { InvoicedCollection } from '../../../../../types/api/clients';
import { httpDateFormat } from '../../../../../utils/helpers/formatting';
import { queryString } from '../../../../../utils/helpers/http';
import { renderIcon } from '../../../../../utils/helpers/icon';
import SidebarItem from '../../../../Grid/Sidebar/SidebarItem';

type Props = {
  client: string | null;
  setClient: Dispatch<SetStateAction<string | null>>;
  dateFrom: Date;
  setDateFrom: Dispatch<SetStateAction<Date>>;
  dateTo: Date;
  setDateTo: Dispatch<SetStateAction<Date>>;
  month: Date;
  setMonth: Dispatch<SetStateAction<Date>>;
  status: InvoiceStatus | null;
  setStatus: Dispatch<SetStateAction<InvoiceStatus | null>>;
  emailStatus: InvoiceEmailStatus | null;
  setEmailStatus: Dispatch<SetStateAction<InvoiceEmailStatus | null>>;
  ordinalNumber: string;
  setOrdinalNumber: Dispatch<SetStateAction<string>>;
  amountFrom: string;
  setAmountFrom: Dispatch<SetStateAction<string>>;
  amountTo: string;
  setAmountTo: Dispatch<SetStateAction<string>>;
};

function Filters({
  client,
  setClient,
  dateFrom,
  setDateFrom,
  dateTo,
  setDateTo,
  month,
  setMonth,
  status,
  setStatus,
  emailStatus,
  setEmailStatus,
  ordinalNumber,
  setOrdinalNumber,
  amountFrom,
  setAmountFrom,
  amountTo,
  setAmountTo,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const { data: clientsData, isLoading: isClientsLoading } =
    useAxiosHook<InvoicedCollection>(
      '/clients/invoiced' +
        queryString({
          date_created_from: httpDateFormat(dateFrom),
          date_created_to: httpDateFormat(dateTo),
          limit: 0,
        })
    );

  const clientsOptions = useMemo(
    () =>
      clientsData?.data.map((client) => ({
        label: client.ime,
        value: client.id,
      })) ?? [],
    [clientsData?.data]
  );

  const statusOptions = useMemo(() => getInvoiceStatusOptions(t), [t]);
  const emailStatusOptions = useMemo(
    () => getInvoiceEmailStatusOptions(t),
    [t]
  );

  useSearchQueryDateParam('dateFrom', dateFrom);
  useSearchQueryDateParam('dateTo', dateTo);

  useSearchQueryMonthParam('month', month);

  useSearchQueryDropdownParam('client', client, setClient, clientsOptions);

  useSearchQueryDropdownParam('status', status, setStatus, statusOptions);
  useSearchQueryDropdownParam(
    'emailStatus',
    emailStatus,
    setEmailStatus,
    emailStatusOptions
  );

  useSearchQueryParam('ordinalNumber', ordinalNumber);
  useSearchQueryParam('amountFrom', amountFrom);
  useSearchQueryParam('amountTo', amountTo);

  return (
    <SidebarItem template="filters">
      <div className="sidebar_filter">
        <label htmlFor="client">{t('Client')}</label>
        <Dropdown
          name="client"
          id="client"
          filter
          options={clientsOptions}
          value={client}
          showClear
          showFilterClear
          onChange={(e) => setClient(e.target.value)}
          disabled={!clientsOptions.length}
          placeholder={
            isClientsLoading
              ? t('Loading...')
              : !clientsOptions.length
              ? t('No clients found')
              : t('All')
          }
        />
      </div>

      <div className="sidebar_filter">
        <label htmlFor="filter_date_from">{t('Created Date')}</label>
        <div className="sidebar_filter_row">
          <Calendar
            dateFormat="dd/mm/yy"
            id="date_from"
            maxDate={new Date()}
            monthNavigator
            name="date_from"
            value={dateFrom}
            readOnlyInput
            yearNavigator
            yearRange={`2000:${dayjs().format('YYYY')}`}
            onChange={(e) => {
              setDateFrom(e.value as Date);
            }}
          />
          <span style={{ margin: 'auto' }}>{renderIcon(faMinus)}</span>
          <Calendar
            dateFormat="dd/mm/yy"
            id="date_to"
            maxDate={new Date()}
            monthNavigator
            name="date_to"
            value={dateTo}
            readOnlyInput
            yearNavigator
            yearRange={`2000:${dayjs().format('YYYY')}`}
            onChange={(e) => {
              setDateTo(e.value as Date);
            }}
          />
        </div>
      </div>

      <div className="sidebar_filter">
        <label htmlFor="month">{t('Month')}</label>
        <Calendar
          id="month"
          name="month"
          dateFormat="mm/yy"
          monthNavigator
          view="month"
          value={month}
          readOnlyInput
          yearNavigator
          yearRange={`2000:${dayjs().format('YYYY')}`}
          onChange={(e) => {
            setMonth(e.target.value as Date);
          }}
        />
      </div>

      <div className="sidebar_filter">
        <label htmlFor="status">{t('Invoice Status')}</label>
        <Dropdown
          name="status"
          id="status"
          options={statusOptions}
          value={status}
          showClear
          placeholder={t('All')}
          onChange={(e) => setStatus(e.target.value)}
        />
      </div>

      <div className="sidebar_filter">
        <label htmlFor="email_status">{t('Email Status')}</label>
        <Dropdown
          name="email_status"
          id="email_status"
          options={emailStatusOptions}
          value={emailStatus}
          showClear
          placeholder={t('All')}
          onChange={(e) => setEmailStatus(e.target.value)}
        />
      </div>

      <div className="sidebar_filter">
        <label htmlFor="ordinal_number">{t('Ordinal Number')}</label>
        <InputText
          id="ordinal_number"
          value={ordinalNumber}
          onChange={(e) => setOrdinalNumber(e.target.value)}
        />
      </div>

      <div className="sidebar_filter">
        <label htmlFor="from_value">{t('Amount')}</label>
        <div className="sidebar_filter_row">
          <InputText
            name="from_value"
            id="from_value"
            value={amountFrom}
            onChange={(e) =>
              setAmountFrom(e.target.value.replace(/[^0-9]/, ''))
            }
          />
          <span style={{ margin: 'auto' }}>{renderIcon(faMinus)}</span>
          <InputText
            name="to_value"
            id="to_value"
            value={amountTo}
            onChange={(e) => setAmountTo(e.target.value.replace(/[^0-9]/, ''))}
          />
        </div>
      </div>
    </SidebarItem>
  );
}

export default Filters;
