import {
  approveUserDeviceResourceGuard,
  deleteUserDeviceResourceGuard,
  denyUserDeviceResourceGuard,
  userDeviceCollectionGuard,
} from './_api/userDevices';

export const approveDevice = approveUserDeviceResourceGuard;
export const denyDevice = denyUserDeviceResourceGuard;
export const deleteDevice = deleteUserDeviceResourceGuard;

export const routeGuard = userDeviceCollectionGuard;
