import { faCheckSquare, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Sentry from '@sentry/react';
import classNames from 'classnames';
import _ from 'lodash';
import { Column, ColumnProps } from 'primereact/column';
import { ReactNode } from 'react';
import { TFunction } from 'react-i18next';

import { emptyCell } from '../../../../utils/constants/tables';
import { currencyFormat } from '../../../../utils/helpers/formatting';

type Order = {
  id: number;
  audit_id: number;
  date: string;
  action: 'C' | 'M' | 'D';
  modified_by_ime: string | null;
  seriski_broj: string | null;
  barkod: string | null;
  status_ime: string | null;
  hub_ime: string | null;
  kurir_ime: string | null;
  datum_priem: string;
  postar_od_ime: string | null;
  hub_od_ime: string | null;
  datum_isporaka: string;
  postar_do_ime: string | null;
  hub_do_ime: string | null;
  klient_od_ime: string | null;
  klient_do_ime: string | null;
  adresa_od: string | null;
  adresa_do: string | null;
  vremenska_ramka_name: string | null;
  cena: string | null;
  tezina: string | null;
  volumen: string | null;
  otkup: string | null;
  vrednost: string | null;
  povraten_dokument: string | null;
  komentar: string | null;
  fakturirana: string | null;
  zatvorena: string | null;
  created_by_ime: string | null;
  location_from: string | null;
  location_to: string | null;
};

type OrderChange = Omit<Partial<Order>, 'id' | 'audit_id' | 'action'> &
  Pick<Order, 'audit_id' | 'action'>;

export type ApiData = [Order, ...OrderChange[]];

export type TableRowData = Omit<Order, 'id'>;

function getHeadersMap(t: TFunction) {
  return {
    date: t('Modification date'),
    action: t('Action'),
    modified_by_ime: t('Modified by'),
    seriski_broj: t('Serial No'),
    barkod: t('Barcode'),
    status_ime: t('Status'),
    hub_ime: t('Warehouse'),
    kurir_ime: t('Courier'),
    datum_priem: t('Reception date'),
    postar_od_ime: t('Courier dispatch'),
    hub_od_ime: t('Warehouse dispatch'),
    datum_isporaka: t('Dispatch date'),
    postar_do_ime: t('Reception courier'),
    hub_do_ime: t('Warehouse reception'),
    klient_od_ime: t('Sender'),
    klient_do_ime: t('Recipient'),
    adresa_od: t('Dispatch address'),
    adresa_do: t('Delivery address'),
    vremenska_ramka_name: t('Time frame'),
    cena: t('Price'),
    tezina: t('Weight'),
    volumen: t('Volume'),
    otkup: t('Redemption'),
    vrednost: t('Value'),
    povraten_dokument: t('Return doc.'),
    komentar: t('Comment'),
    fakturirana: t('Invoiced.SHE'),
    zatvorena: t('Archived.SHE'),
    created_by_ime: t('Created by'),
    location_from: t('Location from'),
    location_to: t('Location to'),
  };
}

export function buildRowsFromDiff(changes: ApiData): Readonly<TableRowData>[] {
  let rows = [{ ...changes[0] }] as TableRowData[];

  const fieldColNames = Object.keys(_.omit(changes[0], 'id')) as Array<
    keyof Omit<Order, 'id'>
  >;

  for (let i = 1; i < changes.length; i++) {
    let rowFields = {} as Order;

    const prevRow = rows[i - 1];
    for (let fieldCol = 0; fieldCol < fieldColNames.length; fieldCol++) {
      const column = fieldColNames[fieldCol];

      (rowFields[column] as any) = changes[i][column] ?? prevRow[column];
    }

    rows[i] = {
      ...changes[i],
      ...rowFields,
    };
  }

  return rows;
}

export function tableColumns(
  t: TFunction,
  rows: Readonly<TableRowData[]>
): JSX.Element[] {
  const headersMap = getHeadersMap(t);

  return Object.keys(headersMap).map((k, i) => (
    <Column
      key={i}
      field={k}
      header={headersMap[k as keyof typeof headersMap]}
      body={(data, props: any) => columnBody(t, rows, data, props)}
      {...additionalColumnProps(k as keyof ReturnType<typeof getHeadersMap>)}
    />
  ));
}

export function getRowColor(change: TableRowData): object {
  const typeColorMap = {
    D: 'yellow',
    M: '',
    C: 'green',
  };

  const color = typeColorMap[change.action];

  return color ? { [color]: true } : {};
}

function columnBody(
  t: TFunction,
  rows: Readonly<TableRowData[]>,
  data: any,
  props: ColumnProps
): ReactNode {
  const currentRowIndex = rows.findIndex((c) => c.audit_id === data.audit_id);

  const field = props.field! as keyof Order;
  const value = data[field];
  let prevRowValue;

  let returnValue = value;
  if (currentRowIndex > 0 && field !== 'id') {
    prevRowValue = rows[currentRowIndex - 1][field];

    if (returnValue === null || returnValue === undefined) {
      returnValue = prevRowValue;
    }
  }

  let isModified;
  let shouldCenter;

  switch (field) {
    case 'action':
      if (value === 'C') {
        returnValue = t('Creation');
      } else if (value === 'D') {
        returnValue = t('Deletion');
      } else {
        returnValue = t('Modification');
      }
      break;

    case 'povraten_dokument':
    case 'fakturirana':
    case 'zatvorena':
      returnValue = !!parseInt(value) ? (
        <FontAwesomeIcon icon={faCheckSquare} />
      ) : (
        <FontAwesomeIcon icon={faTimes} />
      );
      shouldCenter = true;
      break;

    case 'cena':
    case 'otkup':
    case 'vrednost':
      returnValue = currencyFormat(returnValue, { showCurrency: true });
      break;

    case 'location_from':
    case 'location_to':
      try {
        if (typeof returnValue === 'string' && returnValue.length > 0) {
          const parsedObj = JSON.parse(returnValue);

          returnValue = `${parsedObj.lng}, ${parsedObj.lat}`;
        }
      } catch (e) {
        Sentry.captureException(e, {
          extra: {
            data: returnValue,
          },
        });
      }
      break;

    default:
      break;
  }

  if (!returnValue) {
    returnValue = emptyCell;
    shouldCenter = true;
  }

  if (
    currentRowIndex !== 0 &&
    props.field !== 'action' &&
    props.field !== 'date' &&
    field !== 'id'
  ) {
    isModified = value !== prevRowValue;

    if (isModified && typeof returnValue === 'string') {
      returnValue = <b>{returnValue}</b>;
    }
  }

  const className = classNames({
    'is-modified': isModified,
    'p-jc-center': shouldCenter,
  });

  return <span className={className}>{returnValue}</span>;
}

// Column body modifications should be addressed in columnBody instead
function additionalColumnProps(
  column: keyof ReturnType<typeof getHeadersMap>
): Partial<ColumnProps> {
  switch (column) {
    case 'date':
      return {
        style: {
          width: 180,
        },
        bodyStyle: {
          width: 180,
        },
      };

    case 'action':
      return {
        style: {
          width: 100,
        },
        bodyStyle: {
          width: 100,
        },
      };

    case 'datum_priem':
    case 'datum_isporaka':
      return {
        style: {
          width: 180,
        },
        bodyStyle: {
          width: 180,
        },
      };

    case 'seriski_broj':
    case 'barkod':
    case 'hub_ime':
    case 'hub_od_ime':
    case 'hub_do_ime':
    case 'adresa_od':
    case 'adresa_do':
    case 'vremenska_ramka_name':
    case 'location_from':
    case 'location_to':
      return {
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
        },
      };

    case 'kurir_ime':
    case 'postar_od_ime':
    case 'postar_do_ime':
    case 'klient_od_ime':
    case 'klient_do_ime':
    case 'created_by_ime':
    case 'modified_by_ime':
    case 'komentar':
    case 'status_ime':
      return {
        style: {
          width: 250,
        },
        bodyStyle: {
          width: 250,
        },
      };

    case 'tezina':
    case 'volumen':
      return {
        style: {
          width: 90,
        },
        bodyStyle: {
          width: 90,
        },
      };

    case 'cena':
    case 'otkup':
    case 'vrednost':
      return {
        style: {
          width: 120,
        },
        bodyStyle: {
          width: 120,
        },
      };

    case 'povraten_dokument':
    case 'fakturirana':
    case 'zatvorena':
      return {
        style: {
          width: 120,
        },
        bodyStyle: {
          width: 120,
          textAlign: 'center',
        },
      };

    default:
      return {};
  }
}

export function sortByAuditId<T extends { audit_id: number }>(
  rows: Readonly<T[]>
): T[] {
  return [...rows].sort((a, b) => a.audit_id - b.audit_id);
}
