import { useFormikContext } from 'formik';
import { useCallback, useEffect } from 'react';

import useSearchQueryDropdownParam from '../../../hooks/useSearchQueryDropdownParam';
import useSearchQueryParam from '../../../hooks/useSearchQueryParam';
import { httpDateFormat } from '../../../utils/helpers/formatting';

function FiltersSync({ courierOptions }) {
  const { values, setFieldValue } = useFormikContext();

  const setCourierId = useCallback(
    (value) => setFieldValue('courier_id', value),
    [setFieldValue]
  );

  useEffect(() => {
    if (values.courier_id) {
      return;
    }

    setCourierId(courierOptions?.[0]?.value ?? null);
  }, [courierOptions, setCourierId, values.courier_id]);

  useSearchQueryDropdownParam(
    'courier',
    values.courier_id,
    setCourierId,
    courierOptions,
    false
  );

  useSearchQueryParam('serialNo', values.serial_number);

  useSearchQueryParam('date', httpDateFormat(values.date));

  return null;
}

export default FiltersSync;
