export const vehicleCollectionGuard = 'GET /vehicles';
export const addVehicleResourceGuard = 'POST /vehicles';
export const editVehicleResourceGuard = 'PUT /vehicles/d+';
export const deleteVehicleResourceGuard = 'DELETE /vehicles/d+';

export const vehicleModelCollectionGuard = 'GET /vehicle-models';
export const vehicleManufacturerCollectionGuard = 'GET /vehicle-manufacturers';
export const employeesWithAssignedVehicleCollectionGuard =
  'GET /vehicles/employees';
export const assignedEmployeeToVehicleResourceGuard =
  'GET /vehicles/d+/employee';
