import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import useMediaQuery from '../../hooks/useMediaQuery';
import { ReduxState } from '../../types/redux';
import { RoutePaths } from '../../utils/constants/routePaths';

function HeaderMenu(): JSX.Element {
  const { t } = useTranslation();
  const headerMenu = useRef<any>(null);
  const history = useHistory();
  const isOnMobile = useMediaQuery('(max-width: 768px)');
  const user = useSelector<ReduxState, ReduxState['user']>(
    (state) => state.user,
    shallowEqual
  );

  const splitBtnMenuModel: MenuItem[] = [
    // {
    //   label: "What's new",
    //   icon: 'fas fa-info-circle',
    //   command: () => setIsWhatsNewDialogVisible(true),
    // },
    {
      label: t('Profile'),
      icon: 'fas fa-address-card',
      command: () => history.push(RoutePaths.Profile),
    },
    {
      label: t('Log out'),
      icon: 'fas fa-sign-out-alt',
      command: () => history.push(RoutePaths.Logout),
    },
  ];

  return (
    <div className="cart flex justify-content-center">
      <Menu model={splitBtnMenuModel} popup ref={headerMenu} id="header_menu" />
      <Button
        icon="fas fa-user-alt"
        label={`${
          !isOnMobile
            ? `${user.employee_firstname} ${user.employee_lastname}`
            : ''
        }`}
        onClick={(event) => headerMenu.current?.toggle(event)}
        aria-controls="header_menu"
        aria-haspopup
        className="p-button-outlined menu-button"
      />
    </div>
  );
}

export default HeaderMenu;
