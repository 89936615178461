import { InputText } from 'primereact/inputtext';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import SidebarItem from '../../../../Grid/Sidebar/SidebarItem';

type Props = {
  serialNoFilter: string;
  setSerialNoFilter: Dispatch<SetStateAction<string>>;
};

function Filters({ serialNoFilter, setSerialNoFilter }: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <SidebarItem template="filters">
      <div className="p-field">
        <label htmlFor="serialNo">{t('Serial Number')}</label>
        <InputText
          value={serialNoFilter}
          onChange={(e: ChangeEvent<HTMLInputElement>): void => {
            setSerialNoFilter(e.target.value);
          }}
        />
      </div>
    </SidebarItem>
  );
}

export default Filters;
