import { ReactNode } from 'react';

export type BDItemProps = {
  label: string;
  children: ReactNode;
  additionalContent?: ReactNode;
  footer?: ({ onHide }: { onHide: () => void }) => JSX.Element;
};

function BDItem({ children }: BDItemProps): JSX.Element {
  return <>{children}</>;
}

export default BDItem;
