import { Location } from 'history';
import { isEqual, omit } from 'lodash';

import { OrderLocationType, OrderStatus } from '../../../../enums/orders';
import { Product } from '../../../../enums/product';
import { tryEnumValue, tryInt } from '../../../../utils/helpers/parse';
import {
  getBoolSearchQueryParam,
  getSearchQueryParam,
  tryDateSearchParam,
  tryDateTimeSearchParam,
  tryMultiSelectParam,
} from '../../../../utils/helpers/searchQuery';
import { OrderPackaging } from '../Dialogs/CreateEdit/CreateEditDialog.functions';
import { FormValues } from './useSidebarFilters.types';

export function getStatuses<T>(location: Location<T>) {
  const value = getSearchQueryParam(location.search, 'status') ?? null;

  return value
    ? tryMultiSelectParam(value)
        ?.filter((value) => tryEnumValue(tryInt(value), OrderStatus))
        ?.map(Number) ?? null
    : null;
}

export function getDefaultValues<T>(location: Location<T>): FormValues {
  return {
    serial: getSearchQueryParam(location.search, 'serial') ?? null,
    group_serial_number:
      getSearchQueryParam(location.search, 'group_serial_number') ?? null,
    reference1: getSearchQueryParam(location.search, 'reference1') ?? null,
    reference2: getSearchQueryParam(location.search, 'reference2') ?? null,
    comment: getSearchQueryParam(location.search, 'comment') ?? null,
    semaphore:
      Boolean(getSearchQueryParam(location.search, 'semaphore')) ||
      isEqual(getStatuses(location), semaphoreStatusesValues),
    phone_from: getSearchQueryParam(location.search, 'phone_from') ?? null,
    phone_to: getSearchQueryParam(location.search, 'phone_to') ?? null,
    client_from_name:
      getSearchQueryParam(location.search, 'client_from_name') ?? null,
    client_to_name:
      getSearchQueryParam(location.search, 'client_to_name') ?? null,
    client_orderer_name:
      getSearchQueryParam(location.search, 'client_orderer_name') ?? null,
    import_list_type_id:
      getSearchQueryParam(location.search, 'import_list_type_id') ?? null,
    status: Boolean(getSearchQueryParam(location.search, 'semaphore'))
      ? semaphoreStatusesValues
      : getStatuses(location),
    created_by_id:
      tryInt(getSearchQueryParam(location.search, 'created_by_id')) ?? null,
    modified_by_id:
      tryInt(getSearchQueryParam(location.search, 'modified_by_id')) ?? null,
    date_from:
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'date_from') ?? ''
      ) ?? new Date(),
    date_to:
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'date_to') ?? ''
      ) ?? new Date(),
    pickup_date_from:
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'pickup_date_from') ?? ''
      ) ?? null,
    pickup_date_to:
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'pickup_date_to') ?? ''
      ) ?? null,
    delivery_date_from:
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'delivery_date_from') ?? ''
      ) ?? null,
    delivery_date_to:
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'delivery_date_to') ?? ''
      ) ?? null,
    fragile: getBoolSearchQueryParam(location.search, 'fragile') ?? null,
    return_document:
      getBoolSearchQueryParam(location.search, 'return_document') ?? null,
    two_man_delivery:
      getBoolSearchQueryParam(location.search, 'two_man_delivery') ?? null,
    adresnica_service_packaging_id:
      tryEnumValue(
        tryInt(
          getSearchQueryParam(location.search, 'adresnica_service_packaging_id')
        ),
        OrderPackaging
      ) ?? null,
    product_type_id:
      tryEnumValue(
        tryInt(getSearchQueryParam(location.search, 'product_type_id')),
        Product
      ) ?? null,
    personal_delivery:
      getBoolSearchQueryParam(location.search, 'personal_delivery') ?? null,
    weight_from:
      tryInt(getSearchQueryParam(location.search, 'weight_from')) ?? null,
    weight_to:
      tryInt(getSearchQueryParam(location.search, 'weight_to')) ?? null,
    redemption_from:
      tryInt(getSearchQueryParam(location.search, 'redemption_from')) ?? null,
    redemption_to:
      tryInt(getSearchQueryParam(location.search, 'redemption_to')) ?? null,
    courier_id: getSearchQueryParam(location.search, 'courier') ?? null,
    warehouse_id: getSearchQueryParam(location.search, 'warehouse') ?? null,
    price_from:
      tryInt(getSearchQueryParam(location.search, 'price_from')) ?? null,
    price_to: tryInt(getSearchQueryParam(location.search, 'price_to')) ?? null,
    location_type_id:
      tryEnumValue(
        tryInt(getSearchQueryParam(location.search, 'location_type_id')),
        OrderLocationType
      ) ?? null,
    requested_delivery_date:
      tryDateTimeSearchParam(
        getSearchQueryParam(location.search, 'requested_delivery_date') ?? ''
      ) ?? null,
    shipping_type_id:
      tryInt(getSearchQueryParam(location.search, 'shipping_type_id')) ?? null,
  };
}

export function getNullValues(): FormValues {
  return {
    serial: null,
    group_serial_number: null,
    reference1: null,
    reference2: null,
    comment: null,
    phone_from: null,
    phone_to: null,
    client_from_name: null,
    client_to_name: null,
    semaphore: false,
    client_orderer_name: null,
    import_list_type_id: null,
    status: null,
    created_by_id: null,
    modified_by_id: null,
    date_from: new Date(),
    date_to: new Date(),
    pickup_date_from: null,
    pickup_date_to: null,
    delivery_date_from: null,
    delivery_date_to: null,
    adresnica_service_packaging_id: null,
    fragile: null,
    return_document: null,
    two_man_delivery: null,
    product_type_id: null,
    personal_delivery: null,
    weight_from: null,
    weight_to: null,
    redemption_from: null,
    redemption_to: null,
    courier_id: null,
    warehouse_id: null,
    price_from: null,
    price_to: null,
    location_type_id: null,
    requested_delivery_date: null,
    shipping_type_id: null,
  };
}

export function toHttpObject(values: FormValues) {
  return {
    ...omit(values, 'semaphore'),
    summary: true,
  };
}

export const semaphoreStatusesValues = [
  35, 40, 41, 45, 46, 47, 48, 50, 51, 55, 60, 65, 75,
];
