import { faBorderAll } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import _ from 'lodash';
import { Button } from 'primereact/button';
import { useEffect } from 'react';
import { useMemo, useState } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { useTranslation } from 'react-i18next';

import { useEndpointGuard } from '../../../hooks/useEndpointGuard';
import usePageTitle from '../../../hooks/usePageTitle';
import * as dashboardGuards from '../../../utils/constants/auth/dashboard';
import HeaderPages from '../Components/HeaderPages/HeaderPages';
import BestCouriersChart from './Charts/BestCouriersChart';
import DeliveredParcelsChart from './Charts/DeliveredParcelsChart';
import DeliveredParcelsPerRegionChart from './Charts/DeliveredParcelsPerRegionChart';
import FinancesChart from './Charts/FinancesChart';
import PickedUpParcelsChart from './Charts/PickedUpParcelsChart';
import PickedUpParcelsPerRegionChart from './Charts/PickedUpParcelsPerRegionChart';
import {
  getChartArrayOptions,
  getCurrentBreakpoint,
  getStorageItem,
  gridSettings,
  setStorageItem,
} from './Dashboard.functions';
import AddReplaceChartDialog from './Dialogs/AddReplaceChartDialog';

const ResponsiveGridLayout = WidthProvider(Responsive);

function Dashboard() {
  const { t } = useTranslation();

  const bestCouriersChartGuard = useEndpointGuard(
    dashboardGuards.bestCouriersChart
  );
  const deliveredParcelsChartGuard = useEndpointGuard(
    dashboardGuards.deliveredParcelsChart
  );
  const deliveredParcelsPerRegionChartGuard = useEndpointGuard(
    dashboardGuards.deliveredParcelsPerRegionChart
  );
  const financesChartGuard = useEndpointGuard(dashboardGuards.financesChart);
  const pickedUpParcelsChartGuard = useEndpointGuard(
    dashboardGuards.pickedUpParcelsChart
  );
  const pickedUpParcelsPerRegionChartGuard = useEndpointGuard(
    dashboardGuards.pickedUpParcelsPerRegionChart
  );

  usePageTitle(t('Dashboard'));

  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [dialogChartId, setDialogChartId] = useState(null);
  const [replacingChartId, setReplacingChartId] = useState(null);

  const [chartArray, setChartArray] = useState(() => {
    const storedArr = getStorageItem('dashboard_rgl_chartArray');

    return Array.isArray(storedArr)
      ? storedArr
      : [
          {
            i:
              'DeliveredParcelsChart===' +
              Math.floor(Math.random() * 100000000000),
            x: 0,
            y: 0,
            w: 4,
            h: 30,
            minH: 25,
          },
        ];
  });

  useEffect(() => {
    const allowedCharts = [
      bestCouriersChartGuard ? 'BestCouriersChart' : null,
      deliveredParcelsChartGuard ? 'DeliveredParcelsChart' : null,
      deliveredParcelsPerRegionChartGuard
        ? 'DeliveredParcelsPerRegionChart'
        : null,
      financesChartGuard ? 'FinancesChart' : null,
      pickedUpParcelsPerRegionChartGuard
        ? 'PickedUpParcelsPerRegionChart'
        : null,
      pickedUpParcelsChartGuard ? 'PickedUpParcelsChart' : null,
    ].filter(Boolean);

    setChartArray((prevChartArr) =>
      prevChartArr.filter((c) => allowedCharts.includes(c.i.split('===')[0]))
    );
  }, [
    bestCouriersChartGuard,
    deliveredParcelsChartGuard,
    deliveredParcelsPerRegionChartGuard,
    financesChartGuard,
    pickedUpParcelsChartGuard,
    pickedUpParcelsPerRegionChartGuard,
  ]);

  const rglChildren = useMemo(() => {
    const chartTypes = {
      BestCouriersChart,
      DeliveredParcelsChart,
      DeliveredParcelsPerRegionChart,
      FinancesChart,
      PickedUpParcelsPerRegionChart,
      PickedUpParcelsChart,
    };

    return chartArray.map((c) => {
      const ChartComponent = chartTypes[c.i.split('===')[0]];

      if (!ChartComponent) {
        return null;
      }

      return (
        <ChartComponent
          key={c.i}
          id={c.i}
          onReplace={(id) => {
            setReplacingChartId(id);
            setIsDialogVisible(true);
            setDialogChartId(c.i);
          }}
          onRemove={() => handleChartRemoval(c.i)}
          onOptionsChange={handleChartOptionsChange}
        />
      );
    });
  }, [chartArray]);

  function handleLayoutChange(newLayoutArray) {
    setChartArray(newLayoutArray);

    setStorageItem('dashboard_rgl_chartArray', newLayoutArray);
  }

  function handleChartAddition(chartType) {
    const id = chartType + '===' + Math.floor(Math.random() * 100000000000);

    // This goes before updating the chartArray
    //  so that the newly made chart picks up the date set by default
    handleChartOptionsChange(id, {
      date: [dayjs().unix(), dayjs().unix()],
    });

    setChartArray((chartArr) => {
      const colWidth = gridSettings.cols[getCurrentBreakpoint()];
      const lastLayoutItem = chartArr[chartArr.length - 1];

      let w = colWidth < 4 ? colWidth : 4;
      let h = 30;

      let x = lastLayoutItem ? lastLayoutItem.x + lastLayoutItem.w : 0;
      let y = lastLayoutItem ? lastLayoutItem.y + lastLayoutItem.h : 0;

      if (x + w > colWidth) {
        x = 0;
      }

      return [
        ...chartArr,
        {
          i: id,
          x: x,
          y: y,
          w: w,
          h: h,
          minH: 25,
        },
      ];
    });
  }

  function handleChartReplacement(newChartType) {
    setChartArray((chartArr) =>
      chartArr.map((c) => {
        if (c.i === dialogChartId) {
          let newC = _.cloneDeep(c);
          const newId = newChartType + '===' + newC.i.split('===')[1];
          newC.i = newId;

          // Chart options update
          let newChartArrOptions = getChartArrayOptions();
          newChartArrOptions[newId] = newChartArrOptions[c.i];

          delete newChartArrOptions[c.i];

          setStorageItem(
            'dashboard_rgl_chartArrayOptions',
            newChartArrOptions,
            false
          );

          return newC;
        }

        return c;
      })
    );

    setDialogChartId(null);
  }

  function handleChartRemoval(id) {
    setChartArray((chartArr) => chartArr.filter((c) => c.i !== id));

    let newChartArrOptions = getChartArrayOptions();
    delete newChartArrOptions[id];

    setStorageItem(
      'dashboard_rgl_chartArrayOptions',
      newChartArrOptions,
      false
    );

    const filterLSKeys = Object.keys(localStorage).filter(
      (k) => k !== id + '_period'
    );

    let newStorageObj = {};

    filterLSKeys.forEach((k) => {
      newStorageObj[k] = localStorage.getItem(k);
    });
    localStorage.clear();

    for (let k in newStorageObj) {
      localStorage.setItem(k, [k]);
    }
  }

  function handleChartOptionsChange(id, options) {
    let newChartArrOptions = getChartArrayOptions();
    newChartArrOptions[id] = { ...newChartArrOptions[id], ...options };

    setStorageItem(
      'dashboard_rgl_chartArrayOptions',
      newChartArrOptions,
      false
    );
  }

  function handleAddReplaceChartDialogHide() {
    setIsDialogVisible(false);
  }

  const layouts = {
    lg: chartArray,
    md: chartArray,
    sm: chartArray,
    xs: chartArray,
    xxs: chartArray,
  };

  return (
    <div className="page dashboard-page">
      <HeaderPages
        title={t('Dashboard')}
        subtitle={t('Easy data visualization')}
        icon={faBorderAll}
      >
        <Button
          type="button"
          label={t('New chart')}
          icon="fas fa-plus"
          onClick={() => {
            setIsDialogVisible(true);
            setReplacingChartId(null);
          }}
          data-cy="add-btn"
        />
      </HeaderPages>

      <ResponsiveGridLayout
        className="layout"
        breakpoints={gridSettings.breakpoints}
        cols={gridSettings.cols}
        layouts={layouts}
        onLayoutChange={handleLayoutChange}
        rowHeight={1}
        autoSize
        draggableCancel=".cancel-drag"
        draggableHandle=".draggable-handle"
      >
        {rglChildren}
      </ResponsiveGridLayout>

      <AddReplaceChartDialog
        t={t}
        visible={isDialogVisible}
        replacingChartId={replacingChartId}
        onHide={handleAddReplaceChartDialogHide}
        onSubmit={(chartType) =>
          replacingChartId
            ? handleChartReplacement(chartType)
            : handleChartAddition(chartType)
        }
      />
    </div>
  );
}

export default Dashboard;
