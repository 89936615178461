import { forwardRef, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useAxios from '../../../../hooks/useAxios';
import { httpDateFormat } from '../../../../utils/helpers/formatting';
import ChartContainer from '../ChartContainer';
import { isPeriodStateValid, totalNumber } from '../Dashboard.functions';

function chartDataFromData(t, data) {
  return {
    labels: data
      ? Object.keys(data).map((l, index) => {
          const fullName = l
            .replace(/mk/gi, t('Macedonia'))
            .replace(/sk/gi, t('Skopje'))
            .replace(/_/gi, ' - ');
          return `${fullName}  (${Object.values(data)[index]})`;
        })
      : [],
    datasets: [
      {
        data: data ? Object.values(data) : [],
        backgroundColor: ['#fccc55', '#6ebe71', '#4baaf5', '#ed4981'],
        hoverBackgroundColor: ['#fbc02d', '#4caf50', '#2196f3', '#e91e63'],
      },
    ],
  };
}

const DeliveredParcelsPerRegionChart = forwardRef(
  ({ id, children, ...other }, ref) => {
    const { t } = useTranslation();

    const [date, setDate] = useState([]);
    const [period, setPeriod] = useState(() => {
      const savedState = localStorage.getItem(id + '_period');
      const defaultOption = [new Date(), new Date()];

      return savedState && isPeriodStateValid(savedState)
        ? savedState
        : defaultOption;
    });

    const { data, isLoading, hasError, reload } = useAxios(
      '/statistics/region/shipment-delivery-data',
      {
        date_from: httpDateFormat(date[0]),
        date_to: httpDateFormat(date[1]),
      },
      {
        method: 'POST',
        // Calendar can return a single date while selecting a range.
        // Make sure it is always a range
        skipWhen: !date[1],
      }
    );

    const chartData = useMemo(() => chartDataFromData(t, data), [data, t]);

    const chartOptions = useMemo(() => {
      return {
        title: {
          display: !!chartData.datasets[0]?.data?.length,
          position: 'bottom',
          text: t('Total: {{totalNumber}}', {
            totalNumber: totalNumber(chartData),
          }),
        },
        legend: {
          position: 'right',
        },
      };
    }, [t, chartData]);

    useEffect(() => {
      if (period) {
        localStorage.setItem(id + '_period', period);
      }
    }, [id, period]);

    return (
      <ChartContainer
        ref={ref}
        title={t('Delivered parcels per region')}
        type="doughnut"
        data={chartData}
        options={chartOptions}
        isLoading={isLoading}
        hasError={hasError}
        date={date}
        setDate={setDate}
        period={period}
        setPeriod={setPeriod}
        onChartRefresh={reload}
        {...other}
      >
        {children}
      </ChartContainer>
    );
  }
);

export default DeliveredParcelsPerRegionChart;
