import _ from 'lodash';

import { Numeric } from '../../types/general';
import { NonEmptyArray } from '../../types/util';

// !!! File reserved for functional programing concepts only !!!
// Q: Why this file, at all?
// A: Sometimes using lodash functions implies too much overhead.
//    And a lot of times we need simple, pure functions that don't do much except
//    abstractify some tedious, repetitive tasks.

export const noop = (..._: any) => {};

export function sequential(...fns: (() => void)[]): void {
  fns.forEach((fn) => fn());
}

export function randomOf<T>(arr: NonEmptyArray<T>): T {
  return arr[Math.ceil(Math.random() * 100) % arr.length];
}

export function sum(numbers: number[]): number {
  return numbers.reduce((acc, current) => acc + current, 0);
}

export function numericSum(arr: Numeric[]): number {
  return sum(
    arr
      .map((n) => (typeof n === 'number' ? n : Number(n)))
      .filter((n) => !isNaN(n))
  );
}

export function mapPick<T extends object, K extends keyof T>(
  arr: T[],
  key: K
): T[K][] {
  return arr.map((obj) => obj[key]);
}

export function identity<T>(a: T): T {
  return a;
}

export function allEqual<T>(array: T[], deepEqual: boolean = false): boolean {
  return array.every((element) =>
    deepEqual ? element === array[0] : _.isEqual(element, array[0])
  );
}

export function range(from: number, to: number): number[] {
  let arr = [];

  for (let i = from; i <= to; i++) {
    arr.push(i);
  }

  return arr;
}

// Resolves TypeScript errors due to bad lodash type definitions
export function omit<T extends object>(obj: T, ...keys: (keyof T)[]) {
  return _.omit(obj, ...keys);
}
