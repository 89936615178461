import { availableCourierCollectionGuard } from './_api/courier';
import {
  assignCourierForDeliveryGuard,
  cancelCourierDeliveryGuard,
  courierDeliveryAssignmentsCollectionGuard,
  courierSummaryCollectionGuard,
  orderResourceGuard,
  ordersBarcodePrefixesCollectionGuard,
} from './_api/orders';

export const assignShipmentGuard = assignCourierForDeliveryGuard;
export const cancelShipmentAssignmentGuard = cancelCourierDeliveryGuard;

export const routeGuard = [
  availableCourierCollectionGuard,
  courierDeliveryAssignmentsCollectionGuard,
  courierSummaryCollectionGuard,
  orderResourceGuard,
  ordersBarcodePrefixesCollectionGuard,
];
