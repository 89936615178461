import classNames from 'classnames';
import L from 'leaflet';
import { MutableRefObject } from 'react';
import { MapContainer } from 'react-leaflet';

import { UseAxiosReturn } from '../../../hooks/useAxiosHook';
import useDynamicTileLayer from '../../../hooks/useDynamicTileLayer';
import { CouriersLocationCollection } from '../../../types/api/tracking';
import {
  getMapCoordinates,
  getMapMaxBounds,
  getMapZoom,
} from '../../../utils/helpers/map';
import { getCourierStatusConfig } from './CourierMap.functions';
import styles from './CourierMap.module.scss';
import CourierMarker from './CourierMarker';

type Props = {
  mapRef: MutableRefObject<L.Map | undefined>;
  mapTileLayerProvider: string;
  courierStatusConfig: ReturnType<typeof getCourierStatusConfig>;
  request: UseAxiosReturn<CouriersLocationCollection>;
};

function Map({
  mapRef,
  mapTileLayerProvider,
  courierStatusConfig,
  request: { data },
}: Props): JSX.Element {
  const DynamicTileLayer = useDynamicTileLayer(mapTileLayerProvider);

  const mapZoom = getMapZoom();
  const mapCoordinates = getMapCoordinates();
  const mapMaxBounds = getMapMaxBounds();

  return (
    <MapContainer
      center={mapCoordinates}
      maxBounds={mapMaxBounds}
      zoom={mapZoom.defaultZoom}
      minZoom={mapZoom.minZoom}
      maxZoom={mapZoom.maxZoom}
      whenCreated={(map) => (mapRef.current = map)}
      className={classNames(styles.map, 'p-shadow-2')}
    >
      <DynamicTileLayer />

      {data?.map((c) => (
        <CourierMarker
          key={c.courier_id}
          courier={c}
          courierStatusConfig={courierStatusConfig}
        />
      ))}
    </MapContainer>
  );
}

export default Map;
