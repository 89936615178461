import {
  BoundsLiteral,
  LatLngBoundsLiteral,
  LatLngLiteral,
  LatLngTuple,
} from 'leaflet';
import { TFunction } from 'react-i18next';

import { LabelValue } from '../../types/options';
import { coordinates, maxBounds, zoom } from '../constants/map';
import { getCountryString } from './misc';

export function getMapCoordinates(): LatLngTuple {
  return coordinates[getCountryString()];
}

export function getMapMaxBounds(): BoundsLiteral {
  return maxBounds[getCountryString()];
}

export function getMapZoom() {
  return zoom[getCountryString()];
}

export function latLngLiteralToLatLngBounds(
  coordinates: LatLngLiteral[]
): LatLngBoundsLiteral {
  return coordinates.map((c) => [c.lat, c.lng]);
}

export function getMapTileLayerProviderOptions(t: TFunction): LabelValue[] {
  return [
    {
      label: t('Colored Detailed'),
      value: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    },
    {
      label: t('Colored Simple'),
      value:
        'https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png',
    },
    {
      label: t('Satelite'),
      value:
        'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
    },
    {
      label: t('Black and White'),
      value:
        'https://stamen-tiles-{s}.a.ssl.fastly.net/toner/{z}/{x}/{y}{r}.png',
    },
  ];
}
