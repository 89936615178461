import { PaginationQueryParams, WithPagination } from '../api';
import { Numeric } from '../general';

// GET /clients
export type ClientCollection = WithPagination<
  {
    id: number;
    korisnik_id: number;
    ime: string;
    email: string | null;
    mesto_id: number | null;
    mesto_ime: string | null;
    opstina_id: number | null;
    opstina_ime: string | null;
    ulica_id: number | null;
    telefon: string | null;
    mobilen: string | null;
    adresa: string | null;
    broj: string | null;
    vlez: string | null;
    stan: string | null;
  }[]
>;

// GET /clients/:id
export type ClientResource = {
  id: number;
  korisnik_id: number;
  ime: string;
  mesto_id: number | null;
  mesto_ime: string | null;
  opstina_id: number | null;
  opstina_ime: string | null;
  ulica_id: number | null;
  street_name: string | null;
  broj: string | null;
  vlez: string | null;
  stan: string | null;
  mobilen: string | null;
  faktura: number | null;
  status_id: number;
  telefon: string | null;
  adresa: string | null;
};

// GET /clients/invoiceable
export type InvoiceableCollection = WithPagination<
  {
    id: number;
    ime: string;
  }[]
>;

// GET /clients/invoiced
export type InvoicedCollection = WithPagination<
  {
    id: number;
    ime: string;
  }[]
>;

// GET /clients/lookup
export type ClientLookupCollection = WithPagination<
  {
    id: number;
    korisnik_id: number;
    ime: string;
    email: string | null;
    mesto_id: number | null;
    mesto_ime: string | null;
    opstina_id: number | null;
    opstina_ime: string | null;
    ulica_id: number | null;
    telefon: string | null;
    mobilen: string | null;
    adresa: string | null;
    broj: string | null;
    vlez: string | null;
    stan: string | null;
  }[]
>;

// GET /clients/:id/importlists/types
export type ClientImportListTypeCollection = {
  id: number;
  name: string;
}[];

// ---------- Query params ---------- //
export type ClientLookupCollectionQueryParams = PaginationQueryParams<{
  id?: Numeric | Numeric[];
  ime?: string;
}>;

// ---------- Query params ---------- //
export enum ClientsCollectionGroupRoleFilter {
  Headquarter = 'headquarter',
  Subsidiary = 'subsidiary',
}
