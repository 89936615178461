import { Dispatch, SetStateAction, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import ToastContext from '../../../../../../../../context/ToastContext';
import { FileTypes } from '../../../../../../../../enums/files';
import { UseAxiosReturn } from '../../../../../../../../hooks/useAxiosHook';
import useTableColumns from '../../../../../../../../hooks/useTableColumns';
import useTableState from '../../../../../../../../hooks/useTableState';
import { InvoiceResource } from '../../../../../../../../types/api/invoices';
import { Unpacked } from '../../../../../../../../types/util';
import {
  downloadFile,
  getFileName,
} from '../../../../../../../../utils/helpers/files';
import {
  currencyFormat,
  dateFormat,
} from '../../../../../../../../utils/helpers/formatting';
import { queryString } from '../../../../../../../../utils/helpers/http';
import Table from '../../../../../../../DataTable/Table/Table';
import BreadcrumbsDialogContext from '../../../../../../../Dialogs/BreadCrumbsDialog/BreadcrumbsDialogContext';
import {
  Column,
  additionalColumnProperties,
  generateContextMenu,
  getColumnHeadersMap,
  tableStorageKey,
} from './Items.functions';

type TableStateType = Unpacked<NonNullable<InvoiceResource['data']['items']>>;

type Props = {
  invoiceID: number | undefined;
  ordinalNumber: string | undefined;
  request: UseAxiosReturn<InvoiceResource>;
  selection: TableStateType | null;
  setSelection: Dispatch<SetStateAction<TableStateType | null>>;
};

function Items({
  invoiceID,
  ordinalNumber,
  request,
  selection,
  setSelection,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const columnHeadersMap = useMemo(() => getColumnHeadersMap(t), [t]);

  const { bottomRightToastRef } = useContext(ToastContext);

  const { nextItem } = useContext(BreadcrumbsDialogContext);

  const { tableRef, sortField, sortOrder, setSortField, setSortOrder } =
    useTableState<TableStateType>(tableStorageKey);

  const { columns, columnOptions, selectedColumns, setSelectedColumns } =
    useTableColumns<Column>(
      1,
      0,
      'invoicesViewInvoice',
      columnHeadersMap,
      columnHeadersMap,
      additionalColumnProperties
    );

  const { data, error, isLoading, reload } = request;

  function handleExportToExcel() {
    if (!selection) {
      return;
    }

    downloadFile(
      `${process.env.REACT_APP_REPORT_URL}/invoice-items/xlsx` +
        queryString({ invoice_id: invoiceID }),
      getFileName(t('Invoices'), ordinalNumber, true),
      FileTypes.XLSX,
      bottomRightToastRef
    );
  }

  const contextMenuModel = useMemo(
    () => generateContextMenu(t, () => (nextItem ? nextItem() : {})),
    [nextItem, t]
  );

  return (
    <>
      <div className="p-mb-4">
        <div className="p-mb-4">
          <p className="p-my-1">
            <b>{t('Client')}:</b> {data?.data.client_name}
          </p>
          <p className="p-my-1">
            <b>{t('Invoicing Period')}:</b>{' '}
            {data?.data.date_from &&
              data.data.date_to &&
              `${dateFormat(data.data.date_from)} - ${dateFormat(
                data.data.date_to
              )}`}
          </p>
        </div>

        <p className="p-my-1">
          <b>{t('Amount')}:</b>{' '}
          {data?.data.amount &&
            currencyFormat(data.data.amount, { showCurrency: true })}
        </p>

        <p className="p-my-1">
          <b>{t('VAT')}:</b>{' '}
          {data?.data.vat &&
            currencyFormat(data.data.vat, { showCurrency: true })}
        </p>

        <p className="p-m-0">
          <b>{t('Total')}:</b>{' '}
          {data?.data.total &&
            currencyFormat(data.data.total, { showCurrency: true })}
        </p>
      </div>

      <Table
        tableRef={tableRef}
        columnOptions={columnOptions}
        columns={columns}
        data={data?.data.items ?? undefined}
        hasError={!!error}
        headerTitle={t('Invoice Items')}
        isLoading={isLoading}
        rebuildTooltip
        reload={reload}
        rows={0}
        selectedColumns={selectedColumns}
        selection={selection}
        setSelectedColumns={setSelectedColumns}
        setSelection={setSelection}
        setSortField={setSortField}
        setSortOrder={setSortOrder}
        sortField={sortField}
        sortOrder={sortOrder}
        storageString={tableStorageKey}
        lazy={false}
        exportToExcelButton
        onExportToExcelButtonClick={handleExportToExcel}
        contextMenuModel={contextMenuModel}
        onRowDoubleClick={() => (nextItem ? nextItem() : {})}
      />
    </>
  );
}

export default Items;
