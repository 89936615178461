import {
  IconDefinition,
  faAddressCard,
  faBell,
  faBuilding,
  faGlobe,
  faInbox,
  faSms,
  faTasks,
  faUserTag,
  faUsersCog,
} from '@fortawesome/free-solid-svg-icons';
import { ComponentType } from 'react';
import { TFunction } from 'react-i18next';

import Company from './Pages/Company';
import Config from './Pages/Config';
import CustomerInputs from './Pages/CustomerInputs';
import Features from './Pages/Features';
import Mail from './Pages/Mail';
import Notifications from './Pages/Notifications';
import Parcel from './Pages/Parcel';
import SMS from './Pages/SMS';
import Web from './Pages/Web';

type Route = {
  path: string;
  component: ComponentType<any>;
  icon: IconDefinition;
  title: string;
  desc: string;
  section: string;
};

export function getRoutes(t: TFunction): Route[] {
  return [
    {
      path: '/parcels',
      component: Parcel,
      icon: faAddressCard,
      title: t('Parcel'),
      desc: t('Parcel config'),
      section: 'Adresnica',
    },
    {
      path: '/company-info',
      component: Company,
      icon: faBuilding,
      title: t('Company info'),
      desc: t('Company information used for stickers or business purposes'),
      section: 'Company',
    },
    {
      path: '/config',
      component: Config,
      icon: faUsersCog,
      title: t('Config'),
      desc: t('Client configurations'),
      section: 'Config',
    },
    {
      path: '/mail',
      component: Mail,
      icon: faInbox,
      title: t('Mail'),
      desc: t('Set up email accounts'),
      section: 'Mail',
    },
    {
      path: '/notifications',
      component: Notifications,
      icon: faBell,
      title: t('Notifications'),
      desc: t('Default notifications received by your clients'),
      section: 'Notification',
    },
    {
      path: '/sms',
      component: SMS,
      icon: faSms,
      title: t('SMS'),
      desc: t('Default SMS setup'),
      section: 'SMS',
    },
    {
      path: '/web',
      component: Web,
      icon: faGlobe,
      title: t('Web'),
      desc: t('Web platform related information'),
      section: 'Web',
    },
    {
      path: '/customer-inputs',
      component: CustomerInputs,
      icon: faTasks,
      title: t('Customer inputs'),
      desc: t('Customer inputs config'),
      section: 'Customer',
    },
    {
      path: '/features',
      component: Features,
      icon: faUserTag,
      title: t('Features'),
      desc: t('Info table for features'),
      section: 'Features',
    },
  ];
}
