import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getReconciliationStatusConfig } from '../../../../../../../configs/reconciliations';
import { ReconciliationResource } from '../../../../../../../types/api/reconciliations';
import { WorkOrderResource } from '../../../../../../../types/api/workOrders';
import { dateFormat } from '../../../../../../../utils/helpers/formatting';
import SidebarItem from '../../../../../../Grid/Sidebar/SidebarItem';

type Props = {
  workOrder: WorkOrderResource | undefined;
  reconciliation: ReconciliationResource | undefined;
};

function Info({ workOrder, reconciliation }: Props): JSX.Element {
  const { t } = useTranslation();
  const statusConfig = useMemo(() => getReconciliationStatusConfig(t), [t]);

  return (
    <SidebarItem template="info">
      <p>
        <b>{t('Date')}:</b> {workOrder ? dateFormat(workOrder.date) : '-'}
      </p>

      <p>
        <b>{t('Courier')}:</b> {workOrder?.courier_full_name ?? '-'}
      </p>

      <p>
        <b>{t('Work Order ID')}:</b> {workOrder?.id ?? '-'}
      </p>

      <p>
        <b>{t('Reconciliation ID')}:</b> {reconciliation?.id ?? '-'}
      </p>

      <p>
        <b>{t('Reconciliation status')}:</b>{' '}
        {reconciliation ? statusConfig[reconciliation.status_id].label : '-'}
      </p>
    </SidebarItem>
  );
}

export default Info;
