import './AddEditCourierDialog.scss';

import { Form, Formik, FormikProps } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useRef } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useAxiosHook from '../../../../../../hooks/useAxiosHook';
import useMediaQuery from '../../../../../../hooks/useMediaQuery';
import { Courier, Region } from '../../../Regions.functions';
import {
  CourierOption,
  FormValues,
  getInitialValues,
  getValidationSchema,
  toApiData,
} from './AddEditCourierDialog.functions';
import DialogContainer from './DialogContainer';

type Props = {
  isShown: boolean;
  isEditDialog: boolean;
  selectedRegion: Region | undefined;
  selectedCourier: Courier | undefined;
  onHide: () => void;
  onCreateSubmit: (courierName: string, regionName: string) => void;
  onEditSubmit: (courierName: string, regionName: string) => void;
  onError: (courierName: string, regionName: string) => void;
};

function AddEditCourierDialog({
  isShown,
  isEditDialog,
  selectedRegion,
  selectedCourier,
  onHide,
  onCreateSubmit,
  onEditSubmit,
  onError,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const validationSchema = useMemo(() => getValidationSchema(t), [t]);

  const formRef = useRef<FormikProps<any>>(null);

  const { data, error, reload, isLoading } = useAxiosHook();

  const courierName = `${selectedCourier?.first_name} ${selectedCourier?.last_name}`;

  const dialogHeader = isEditDialog
    ? t('Editing - {{courierName}}', { courierName: courierName })
    : t('Assign Courier');

  const dialogFooter = (
    <>
      <Button
        type="button"
        label={t('Cancel')}
        className="p-button-text"
        onClick={() => onHide()}
      />

      <Button
        type="button"
        label={
          isLoading
            ? t('Saving...')
            : isEditDialog
            ? t('Save changes')
            : t('Assign')
        }
        disabled={isLoading}
        onClick={() => {
          formRef.current?.handleSubmit?.();
        }}
        data-cy="submit-btn"
      />
    </>
  );

  function handleFormSubmision(values: FormValues) {
    const courierObj = toApiData(values);

    if (isEditDialog) {
      reload({
        url: `regions/${selectedRegion?.region_id}/couriers/${
          (values.courier as CourierOption).id
        }`,
        data: courierObj,
        method: 'PUT',
      });
    } else {
      reload({
        url: `/regions/${selectedRegion?.region_id}/couriers`,
        data: courierObj,
        method: 'POST',
      });
    }
  }

  const isOnMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Dialog
      visible={isShown}
      onHide={onHide}
      header={dialogHeader}
      footer={dialogFooter}
      dismissableMask
      resizable={false}
      maximizable={isOnMobile}
      maximized={isOnMobile}
      className="regions-add-edit-courier-dialog"
    >
      <Formik
        innerRef={formRef}
        initialValues={getInitialValues(selectedCourier, isEditDialog)}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmision}
      >
        <Form>
          <DialogContainer
            isEditDialog={isEditDialog}
            selectedRegion={selectedRegion}
            selectedCourier={selectedCourier}
            data={data}
            error={error}
            onCreateSubmit={onCreateSubmit}
            onEditSubmit={onEditSubmit}
            onError={onError}
          />
        </Form>
      </Formik>
    </Dialog>
  );
}

export default AddEditCourierDialog;
