import './CreateEditDialog.scss';

import { Field, useFormikContext } from 'formik';
import { Checkbox } from 'primereact/checkbox';
import { MultiSelect } from 'primereact/multiselect';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-lodash-debounce';

import useAxiosHook from '../../../../../../hooks/useAxiosHook';
import { debounceTimeout } from '../../../../../../utils/constants/misc';
import { queryString } from '../../../../../../utils/helpers/http';
import AutoCompleteInput from '../../../../../Forms/AutoCompleteInput/AutoCompleteInput';
import FieldWithErrors from '../../../../../Forms/FieldWithErrors/FieldWithErrors';

function RegionsPartner({ step, regionsOptions, isLoadingRegions }) {
  const { t } = useTranslation();

  const { values, errors, touched, setFieldValue } = useFormikContext();

  const debouncedPartnerName = useDebounce(
    values.partner_name,
    debounceTimeout
  );

  const { data: partnerData, isLoading: isPartnerLoading } = useAxiosHook(
    '/clients/lookup' +
      queryString({ ime: debouncedPartnerName, page: 1, limit: 10 })
  );

  const partnerOptions = useMemo(
    () =>
      partnerData?.data.map((client) => {
        return {
          label: client.ime,
          value: client.id,
        };
      }) ?? [],
    [partnerData?.data]
  );

  return (
    <div>
      <span className="section-title">
        {step}. {t('Regions and Partners')}
      </span>

      <div className="general-info">
        <FieldWithErrors name="partner_id" label={t('Partner')}>
          <AutoCompleteInput
            id="partner_id"
            disabled={isPartnerLoading}
            filterValue={values.partner_name}
            value={values.partner_id}
            options={partnerOptions}
            onFilterChange={(e) => setFieldValue('partner_name', e)}
            onSelectionChange={(e) => setFieldValue('partner_id', e)}
            placeholder={isPartnerLoading ? t('Loading...') : null}
            className={errors.partner_id && touched.partner_id && 'invalid'}
            filterDataCy="partner"
            optionsClassName="data-cy-partner-options"
          />
        </FieldWithErrors>

        <FieldWithErrors name="regions" label={t('Regions')}>
          <Field
            disabled={isLoadingRegions}
            name="regions"
            inputId="regions"
            as={MultiSelect}
            options={regionsOptions}
            optionLabel="name"
            optionValue="id"
            filter
            filterPlaceholder={t('Search')}
            filterBy="name"
            placeholder={isLoadingRegions ? t('Loading...') : null}
            className={errors.regions && touched.regions && 'invalid'}
          />
        </FieldWithErrors>

        <div className="sorting-center p-field-checkbox">
          <Checkbox
            inputId="sorting_center"
            onChange={(e) => setFieldValue('sorting_center', e.checked)}
            checked={!!values.sorting_center}
          />

          <label htmlFor="sorting_center" data-cy="sorting_center">
            {t('Warehouse is a sorting center')}
          </label>
        </div>

        <div className="drop-point p-field-checkbox">
          <Checkbox
            inputId="drop_point"
            onChange={(e) => setFieldValue('drop_point', e.checked)}
            checked={!!values.drop_point}
          />

          <label htmlFor="drop_point" data-cy="drop_point">
            {t('Parcels can be delivered or picked up from warehouse')}
          </label>
        </div>
      </div>
    </div>
  );
}

export default RegionsPartner;
