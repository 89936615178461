import './ViewDialog.scss';

import {
  faBriefcase,
  faCheckCircle,
  faCheckSquare,
  faEnvelope,
  faFax,
  faGlobe,
  faHome,
  faIdCard,
  faMobileAlt,
  faPhoneAlt,
  faQuestion,
  faTimesCircle,
  faUserCircle,
  faUserTie,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getOrderStatusConfig } from '../../../../../configs/orders';
import { getProductTypeLabels } from '../../../../../configs/product';
import { getServiceTypeLabels } from '../../../../../configs/service';
import useAxios from '../../../../../hooks/useAxios';
import useMediaQuery from '../../../../../hooks/useMediaQuery';
import {
  getNotificationChannel,
  getNotificationRecipient,
  getNotificationType,
} from '../../../../../utils/constants/entities/notifications';
import { bankPrefixes } from '../../../../../utils/globals';
import {
  formatPhoneNumber,
  getPhoneorMobileNumberRegion,
} from '../../../../../utils/helpers/phoneNumbers';
import DialogSpinner from '../../../../Dialogs/DialogSpinner/DialogSpinner';
import { getClientStatusIdIconMap } from '../../Clients.functions';
import {
  generateDiscountObject,
  generateNotificationsObject,
} from '../CreateEditDialog/CreateEditDialog.functions';

function ViewDialog({ selectedRow, isShown, onHide, onEditBtnClick }) {
  const { t } = useTranslation();

  const notificationType = useMemo(() => getNotificationType(t), [t]);
  const notificationChannel = useMemo(() => getNotificationChannel(t), [t]);
  const notificationRecipient = useMemo(() => getNotificationRecipient(t), [t]);
  const clientStatusIdIconMap = useMemo(() => getClientStatusIdIconMap(t), [t]);

  const serviceLabels = useMemo(() => getServiceTypeLabels(t), [t]);
  const productLabels = useMemo(() => getProductTypeLabels(t), [t]);
  const orderStatusConfig = useMemo(() => getOrderStatusConfig(t), [t]);

  const [placeName, setPlaceName] = useState();
  const [zip, setZip] = useState();
  const [municipalityId, setMunicipalityId] = useState();

  const { data, isLoading: isClientDataLoading } = useAxios(
    `/clients/${selectedRow?.id}`,
    undefined,
    {
      skipWhen: !isShown || !selectedRow?.id,
    }
  );

  const { data: headquarterData, isLoading: isHeadquarterDataLoading } =
    useAxios(`/clients/${data?.direkcija_id}`, undefined, {
      skipWhen:
        !data || data.direkcija_id === null || data.id === data.direkcija_id,
    });

  const isHeadquarter =
    data &&
    data.pravno_lice === 1 &&
    (data.id === data.direkcija_id || data.direkcija_id === null);

  const { isLoading: isPlaceLoading } = useAxios(
    `/places/${data?.mesto_id}`,
    undefined,
    {
      skipWhen: !data?.mesto_id,
      successCallback: (place) => {
        setPlaceName(place.ime);
        setZip(place.postenski_broj);
        setMunicipalityId(place.opstina_id);
      },
    }
  );

  const { data: municipalities, isLoading: areMunicipalitiesLoading } =
    useAxios('/municipalities');

  const { data: streets, isLoading: areStreetsLoading } = useAxios(
    `/places/${data?.mesto_id}/streets`,
    undefined,
    {
      skipWhen: !data?.mesto_id,
    }
  );

  const { data: occupations, isLoading: areOccupationsLoading } = useAxios(
    '/occupations',
    undefined,
    {
      skipWhen: !data?.dejnost_id,
    }
  );

  const { data: priceLists, isLoading: arePriceListsLoading } = useAxios(
    '/price-lists?limit=0',
    undefined,
    {
      skipWhen: !data?.cenovnik_id,
    }
  );

  const { data: headquarters, isLoading: areHeadquartersLoading } = useAxios(
    '/clients/hq/ref',
    undefined,
    {
      skipWhen: isHeadquarter,
    }
  );

  const occupation = occupations?.find((o) => o.id === data?.dejnost_id)?.ime;
  const street =
    streets?.find((s) => s.id === data?.ulica_id) ?? selectedRow?.ulica_ime;

  const subsidiaryHeadquarter = useMemo(
    () =>
      headquarters?.data?.find((e) => e.id === parseInt(data?.direkcija_id)),
    [data, headquarters]
  );

  const municipality = useMemo(
    () => municipalities?.find((m) => m.id === municipalityId)?.ime,
    [municipalities, municipalityId]
  );

  const priceListName = useMemo(() => {
    let valueToCompareTo = isHeadquarter
      ? data?.cenovnik_id
      : headquarterData?.cenovnik_id;

    return priceLists?.data.find((p) => p.id === valueToCompareTo)?.name;
  }, [data, headquarterData, isHeadquarter, priceLists]);

  let address = (
    <>
      {typeof street === 'string' ? street : street?.ime} {data?.broj}
      {data?.vlez && ' '}
      {data?.stan}
      <br />
      {zip} {placeName}, {municipality}
    </>
  );

  const workingHours = useMemo(() => {
    if (!data?.rabotno_vreme) {
      return '-';
    }

    const wHours = data.rabotno_vreme?.split('-');

    if (
      wHours?.length !== 2 ||
      !dayjs(wHours[0], 'HH:mm').isValid() ||
      !dayjs(wHours[1], 'HH:mm').isValid()
    ) {
      return '-';
    }

    return data.rabotno_vreme;
  }, [data]);

  const discounts = useMemo(
    () => generateDiscountObject(data?.popust),
    [data?.popust]
  );

  const notificationTableData = useMemo(() => {
    const notifications = generateNotificationsObject(t, data);

    return notificationType.map((nType) => (
      <tr key={nType.id}>
        <td title={nType.description}>{nType.title}</td>

        {notificationRecipient.map((nrType) =>
          notificationChannel.map((nChannel) => (
            <td key={nChannel.id}>
              {Object.keys(notifications).length &&
              notifications?.[`${nType.id}-${nrType.id}-${nChannel.id}`]
                ?.isChecked ? (
                <FontAwesomeIcon icon={faCheckSquare} />
              ) : (
                '-'
              )}
            </td>
          ))
        )}
      </tr>
    ));
  }, [data, notificationChannel, notificationRecipient, notificationType, t]);

  const assignedTemplatesTableData = useMemo(() => {
    return data?.import_list_types?.length > 0 ? (
      data?.import_list_types.map((listType) => {
        return <div key={listType.id}>- {listType.name}</div>;
      })
    ) : (
      <div>
        <i>{t('None assigned')}</i>
      </div>
    );
  }, [t, data]);

  const isDialogDataLoading =
    isClientDataLoading ||
    isHeadquarterDataLoading ||
    isPlaceLoading ||
    areMunicipalitiesLoading ||
    areStreetsLoading ||
    areOccupationsLoading ||
    arePriceListsLoading ||
    areHeadquartersLoading;

  const dialogFooter = isDialogDataLoading ? null : (
    <>
      <Button
        type="button"
        label={t('Edit')}
        className="p-button-text"
        onClick={() => onEditBtnClick(data)}
      />
      <Button type="button" label={t('Close')} onClick={() => onHide()} />
    </>
  );

  const isOnMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Dialog
      visible={isShown}
      onHide={onHide}
      header={selectedRow?.ime ?? ''}
      footer={dialogFooter}
      dismissableMask
      blockScroll
      resizable={false}
      maximizable={isOnMobile}
      maximized={isOnMobile}
      className={`clients-view-dialog ${isDialogDataLoading ? 'loading' : ''}`}
    >
      {isDialogDataLoading ? (
        <DialogSpinner />
      ) : (
        <>
          <div className="quick-info-container">
            <div className="profile-picture">
              <FontAwesomeIcon
                icon={!!parseInt(data?.pravno_lice) ? faUserTie : faUserCircle}
              />
              <div>
                <i>
                  {!!parseInt(data?.pravno_lice)
                    ? t('Legal entity')
                    : t('Individual entity')}
                </i>
              </div>
            </div>

            <div className="quick-info">
              <div>
                <FontAwesomeIcon icon={faIdCard} /> {data?.ime}
              </div>
              <div>
                <FontAwesomeIcon icon={faBriefcase} /> {occupation || '-'}
              </div>
              <div>
                <FontAwesomeIcon icon={faHome} /> {address}
              </div>

              {data?.status_id && (
                <div>
                  <FontAwesomeIcon icon={faQuestion} />
                  Status: {clientStatusIdIconMap[data?.status_id].label}
                </div>
              )}
            </div>

            <div className="quick-info">
              <div>
                <FontAwesomeIcon icon={faPhoneAlt} />{' '}
                {data?.telefon
                  ? formatPhoneNumber(
                      data.telefon,
                      getPhoneorMobileNumberRegion(data.telefon)
                    )
                  : '-'}
              </div>
              <div>
                <FontAwesomeIcon icon={faMobileAlt} />{' '}
                {data?.mobilen
                  ? formatPhoneNumber(
                      data.mobilen,
                      getPhoneorMobileNumberRegion(data.mobilen)
                    )
                  : '-'}
              </div>
              <div>
                <FontAwesomeIcon icon={faEnvelope} /> {data?.email || '-'}
              </div>
              <div>
                <FontAwesomeIcon icon={faGlobe} /> {data?.web || '-'}
              </div>
              <div>
                <FontAwesomeIcon icon={faFax} />{' '}
                {data?.faks
                  ? formatPhoneNumber(
                      data.faks,
                      getPhoneorMobileNumberRegion(data.faks)
                    )
                  : '-'}
              </div>
            </div>
          </div>

          <table cellPadding="0" cellSpacing="0" border="0">
            <tbody>
              <tr className="section">
                <td colSpan="2">
                  <h4>{t('General information')}</h4>
                </td>
              </tr>
              {data?.pravno_lice === 0 ? (
                <>
                  <tr>
                    <td>
                      <b>{t('Social Security No.')}</b>
                    </td>
                    <td>{data?.maticen_broj || '-'}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>{t('Bank account')}</b>
                    </td>
                    <td>{data?.smetka || '-'}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>{t('Price list')}</b>
                    </td>
                    <td>{priceListName || '-'}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>{t('Working hours')}</b>
                    </td>
                    <td>{workingHours}</td>
                  </tr>
                </>
              ) : (
                <>
                  <tr>
                    <td>
                      <b>{t('Person in charge')}</b>
                    </td>
                    <td>{data?.odgovorno_lice || '-'}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>{t('Contact person')}</b>
                    </td>
                    <td>{data?.kontakt_lice || '-'}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>{t('Reference No.')}</b>
                    </td>
                    <td>{data?.referenca || '-'}</td>
                  </tr>
                </>
              )}
              <tr>
                <td>
                  <b>{t('Comment')}</b>
                </td>
                <td>{data?.komentar || '-'}</td>
              </tr>
              <tr>
                <td>
                  <b>{t('Internal client')}</b>
                </td>
                <td>
                  <FontAwesomeIcon
                    icon={
                      parseInt(data?.interen_klient)
                        ? faCheckCircle
                        : faTimesCircle
                    }
                  />
                </td>
              </tr>

              {!!parseInt(data?.pravno_lice) && (
                <>
                  <tr className="section">
                    <td colSpan="2">
                      <h4>{t('Legal data')}</h4>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <b>{t('Headquarter')}</b>
                    </td>
                    <td>
                      {data &&
                        (isHeadquarter ? (
                          <FontAwesomeIcon icon={faCheckCircle} />
                        ) : (
                          subsidiaryHeadquarter?.ime
                        ))}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>{t('Contract No.')}</b>
                    </td>
                    <td>{data?.broj_dogovor || '-'}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>{t('Social Security No.')}</b>
                    </td>
                    <td>{data?.maticen_broj || '-'}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>{t('Tax No.')}</b>
                    </td>
                    <td>{data?.danocen_broj || '-'}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>{t('Bank account')}</b>
                    </td>
                    <td>{data?.smetka || '-'}</td>
                  </tr>
                  {data?.smetka?.length === 16 && (
                    <tr>
                      <td>
                        <b>{t('Bank')}</b>
                      </td>
                      <td>{bankPrefixes[data.smetka.substring(3)] || '-'}</td>
                    </tr>
                  )}
                  <tr>
                    <td>
                      <b>{t('Price list')}</b>
                    </td>
                    <td>{priceListName || '-'}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>{t('Payment due')}</b>
                    </td>
                    <td>{data?.rok_plakanje || '-'}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>{t('Working hours')}</b>
                    </td>
                    <td>{workingHours}</td>
                  </tr>

                  <tr className="discount-table">
                    <td colSpan={discounts.length ? 2 : undefined}>
                      <b>{t('Discounts')}</b>

                      {!!discounts.length && (
                        <table
                          cellPadding="0"
                          cellSpacing="0"
                          border="0"
                          className="p-mt-1 p-mx-3"
                        >
                          <thead>
                            <tr>
                              <th>{t('Service')}</th>
                              <th>{t('Product')}</th>
                              <th>{t('Order status')}</th>
                              <th>{t('Discount')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {discounts.map((discountField, idx) => (
                              <tr key={idx}>
                                <td>
                                  {serviceLabels[discountField.ServiceId] ??
                                    t('All')}
                                </td>
                                <td>
                                  {productLabels[discountField.ProductId] ??
                                    t('All')}
                                </td>
                                <td>
                                  {orderStatusConfig[discountField.StatusId]
                                    ?.label ?? t('All')}
                                </td>
                                <td>{discountField.Popust}%</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </td>

                    {!discounts.length && <td>-</td>}
                  </tr>

                  <tr>
                    <td>
                      <b>{t('Invoicing')}</b>
                    </td>
                    <td>
                      <span className="icon">
                        <FontAwesomeIcon
                          icon={
                            parseInt(data?.faktura)
                              ? faCheckCircle
                              : faTimesCircle
                          }
                        />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>{t('Use the same price list for recipient')}</b>
                    </td>
                    <td>
                      <span className="icon">
                        <FontAwesomeIcon
                          icon={
                            parseInt(data?.force_price_list_to_receiver)
                              ? faCheckCircle
                              : faTimesCircle
                          }
                        />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>{t('Receive COD on bank account')}</b>
                    </td>
                    <td>
                      <span className="icon">
                        <FontAwesomeIcon
                          icon={
                            parseInt(data?.cod_bank_account_payment)
                              ? faCheckCircle
                              : faTimesCircle
                          }
                        />
                      </span>
                    </td>
                  </tr>
                  {isHeadquarter && (
                    <>
                      <tr>
                        <td>
                          <b>
                            {t(
                              'Only price list for the subsidiaries of this HQ'
                            )}
                          </b>
                        </td>
                        <td>
                          <span className="icon">
                            <FontAwesomeIcon
                              icon={
                                parseInt(data?.cenovnik_direkcija)
                                  ? faCheckCircle
                                  : faTimesCircle
                              }
                            />
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>
                            {t('Only invoice for the subsidiaries of this HQ')}
                          </b>
                        </td>
                        <td>
                          <span className="icon">
                            <FontAwesomeIcon
                              icon={
                                parseInt(data?.faktura_direkcija)
                                  ? faCheckCircle
                                  : faTimesCircle
                              }
                            />
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>
                            {t('Only COD list for the subsidiaries of this HQ')}
                          </b>
                        </td>
                        <td>
                          <span className="icon">
                            <FontAwesomeIcon
                              icon={
                                parseInt(data?.npo_direkcija)
                                  ? faCheckCircle
                                  : faTimesCircle
                              }
                            />
                          </span>
                        </td>
                      </tr>
                    </>
                  )}
                </>
              )}
              <tr className="section">
                <td colSpan="2">
                  <h4>{t('Orders')}</h4>
                </td>
              </tr>
              <tr>
                <td>
                  <b>{t('Allow incomplete address')}</b>
                </td>
                <td>
                  <FontAwesomeIcon
                    icon={
                      parseInt(data?.allow_incomplete_address)
                        ? faCheckCircle
                        : faTimesCircle
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <b>{t('Access orders on map')}</b>
                </td>
                <td>
                  <span className="icon">
                    <FontAwesomeIcon
                      icon={
                        parseInt(data?.orders_on_map_access)
                          ? faCheckCircle
                          : faTimesCircle
                      }
                    />
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <b>{t('Confirmation receipt')}</b>
                </td>
                <td>
                  <span className="icon">
                    <FontAwesomeIcon
                      icon={
                        parseInt(data?.potvrda_priem)
                          ? faCheckCircle
                          : faTimesCircle
                      }
                    />
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <b>{t('Client list templates')}</b>
                  <div className="indented-section">
                    {assignedTemplatesTableData}
                  </div>
                </td>
                <td />
              </tr>
              <tr className="section">
                <td colSpan="2">
                  <h4>{t('Notifications')}</h4>
                </td>
              </tr>
              <tr>
                <td>
                  <b>{t('SMS Provider')}</b>
                </td>
                <td>{data?.sms_gateway || t("Don't notify")}</td>
              </tr>
              <tr>
                <td>
                  <b>{t('Automated sending of COD warrant')}</b>
                </td>
                <td>
                  <FontAwesomeIcon
                    icon={
                      parseInt(data?.cod_email_warrant)
                        ? faCheckCircle
                        : faTimesCircle
                    }
                  />
                </td>
              </tr>
              {!!parseInt(data?.pravno_lice) ? (
                <tr>
                  <td>
                    <b>{t('Receive import notifications')}</b>
                  </td>
                  <td>
                    <FontAwesomeIcon
                      icon={
                        !!data?.import_notification
                          ? faCheckCircle
                          : faTimesCircle
                      }
                    />
                  </td>
                </tr>
              ) : null}
              <tr>
                <td>
                  <b>{t('Automated sending invoice copy on email')}</b>
                </td>
                <td>
                  <FontAwesomeIcon
                    icon={
                      parseInt(data?.invoice_email)
                        ? faCheckCircle
                        : faTimesCircle
                    }
                  />
                </td>
              </tr>
              <tr className="subscriptions-table">
                <td colSpan="2">
                  <table cellPadding="0" cellSpacing="0" border="0">
                    <thead>
                      <tr>
                        <th>{t('Notification type')}</th>
                        <th>{t('S. Email')}</th>
                        <th>{t('S. SMS')}</th>
                        <th>{t('R. Email')}</th>
                        <th>{t('R. SMS')}</th>
                      </tr>
                    </thead>

                    <tbody>{notificationTableData}</tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}
    </Dialog>
  );
}

export default ViewDialog;
