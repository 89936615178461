import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Redirect, useLocation } from 'react-router-dom';

import usePageTitle from '../../../hooks/usePageTitle';
import { RoutePaths } from '../../../utils/constants/routePaths';
import AuthRoute from '../../Routes/AuthRoute';
import SentryRoute from '../../Routes/SentryRoute';
import { getRoutes } from './routes';

function Profile() {
  const { t } = useTranslation();

  const profileRoutes = useMemo(() => getRoutes(t), [t]);

  const location = useLocation();

  const currentRoute = profileRoutes.find(
    (r) => r.path === location.pathname.replace('/profile', '')
  );

  usePageTitle(currentRoute ? `${currentRoute.title}` : 'Profile');

  return (
    <div className="page settings-page">
      <h2 className="title">{t('Profile')}</h2>
      <p className="subtitle">{t('Configure profile settings')}</p>

      <div className="settings-container p-shadow-1">
        <div className="settings-links-container">
          {profileRoutes.map((r) => (
            <Link
              key={r.path}
              to={`${RoutePaths.Profile}${r.path}`}
              className={`settings-link ${
                `${RoutePaths.Profile}${r.path}` === location.pathname
                  ? 'active'
                  : ''
              }`}
            >
              <span className="link-container">
                <span className="link-icon">
                  <FontAwesomeIcon icon={r.icon} />
                </span>

                <span className="link-title-description">
                  <span className="link-title">{r.title}</span>
                  <br />
                  <span className="link-description">{r.desc}</span>
                </span>
              </span>
            </Link>
          ))}
        </div>

        <div className="settings-content">
          <h2 className="mt-0">{currentRoute?.title}</h2>

          <div className="settings-content-wrapper">
            <SentryRoute
              exact
              path="/profile"
              render={() => <Redirect to="/profile/change-password" />}
            />

            {profileRoutes.map((r) => {
              const RouteComponent = r.component;

              return (
                <AuthRoute key={r.path} path={`${RoutePaths.Profile}${r.path}`}>
                  <RouteComponent section={r.section} />
                </AuthRoute>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
