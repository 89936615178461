import './ViewRegionDialog.scss';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  MapContainer,
  Marker,
  Polygon,
  TileLayer,
  Tooltip,
} from 'react-leaflet';

import useMediaQuery from '../../../../../../hooks/useMediaQuery';
import { defaultTileLayerProvider } from '../../../../../../utils/constants/map';
import { Region, fitMapBounds } from '../../../Regions.functions';

type Props = {
  isShown: boolean;
  selectedRegion: Region | undefined;
  onHide: () => void;
  onEditBtnClick: () => void;
};

function ViewDialog({
  isShown,
  selectedRegion,
  onHide,
  onEditBtnClick,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const dialogHeader = selectedRegion?.name || t('View Region');

  const mapElement = useMemo<JSX.Element | null>(() => {
    return selectedRegion?.geometry ? (
      <MapContainer
        zoom={10}
        whenCreated={(map) => fitMapBounds(map, selectedRegion)}
        className="map"
      >
        <TileLayer url={defaultTileLayerProvider} />

        <Polygon positions={selectedRegion.geometry} />
      </MapContainer>
    ) : selectedRegion?.location ? (
      <MapContainer zoom={15} center={selectedRegion?.location} className="map">
        <TileLayer url={defaultTileLayerProvider} />

        <Marker position={selectedRegion.location}>
          <Tooltip>{selectedRegion?.name}</Tooltip>
        </Marker>
      </MapContainer>
    ) : null;
  }, [selectedRegion]);

  const dialogFooter = (
    <Button
      type="button"
      label={t('Edit')}
      onClick={onEditBtnClick}
      className="p-button-text"
    />
  );

  const isOnMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Dialog
      visible={isShown}
      onHide={onHide}
      header={dialogHeader}
      footer={dialogFooter}
      dismissableMask
      resizable={false}
      maximizable
      maximized={isOnMobile}
      className="regions-view-region-dialog"
    >
      {mapElement}

      <div className="info">
        {!mapElement && (
          <div className="info-item">
            <h4>{t('Location')}</h4>
            <div className="no-data-available">
              {t('This region has no location defined')}
            </div>
          </div>
        )}

        <div className="info-item">
          <h4>{t('Couriers assigned')}</h4>
          {selectedRegion?.couriers && selectedRegion.couriers.length > 0 ? (
            selectedRegion.couriers.map((c) => (
              <div key={c.id}>
                - {c.first_name} {c.last_name}
              </div>
            ))
          ) : (
            <div className="no-data-available">
              {t('No couriers have been assigned yet')}
            </div>
          )}
        </div>

        <div className="info-item">
          <h4>{t('Description')}</h4>
          <div className="description-content">
            {selectedRegion?.description ? (
              <span>{selectedRegion?.description}</span>
            ) : (
              <i>{t('No description provided')}</i>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default ViewDialog;
