import {
  clientImportListTypeCollectionGuard,
  clientLookupCollectionGuard,
  clientResourceGuard,
} from './_api/clients';
import {
  entityPropertyCollectionGuard,
  importListCollectionGuard,
  importListResourceGuard,
  importListTypeCollectionGuard,
  importListTypeResourceGuard,
  importListsImportLogCollectionGuard,
  importListsParcelCollectionGuard,
} from './_api/importLists';
import {
  municipalityCollectionGuard,
  placeCollectionGuard,
} from './_api/municipalities';
import {
  batchOrderPartyCollectionGuard,
  deleteImportedListResourceGuard,
  orderImportResourceGuard,
  sendBatchOrderSpecificationResourceGuard,
} from './_api/orders';
import {
  placeResourceGuard,
  placesMunicipalityCollectionGuard,
  placesStreetCollectionGuard,
} from './_api/places';

export const clientFilter = clientLookupCollectionGuard;
export const listTypeFilter = importListTypeCollectionGuard;

export const readImportLog = importListsImportLogCollectionGuard;
export const readShipments = importListsParcelCollectionGuard;

export const exportToCsv = importListResourceGuard;

export const sendSpecToInvolved = [
  batchOrderPartyCollectionGuard,
  sendBatchOrderSpecificationResourceGuard,
];

export const importFile = [
  orderImportResourceGuard,
  clientFilter,
  clientImportListTypeCollectionGuard,
  clientResourceGuard,
  importListTypeResourceGuard,
  municipalityCollectionGuard,
  placeResourceGuard,
  placeCollectionGuard,
  placesStreetCollectionGuard,
];

export const importList = [
  entityPropertyCollectionGuard,
  orderImportResourceGuard,
  clientFilter,
  clientImportListTypeCollectionGuard,
  clientResourceGuard,
  importListTypeResourceGuard,
  municipalityCollectionGuard,
  placeResourceGuard,
  placeCollectionGuard,
  placesStreetCollectionGuard,
  placesMunicipalityCollectionGuard,
];

export const deleteImportedList = deleteImportedListResourceGuard;

export const routeGuard = importListCollectionGuard;
