import { faMobileAlt } from '@fortawesome/free-solid-svg-icons';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useAxiosHook from '../../../hooks/useAxiosHook';
import { useEndpointGuard } from '../../../hooks/useEndpointGuard';
import usePageTitle from '../../../hooks/usePageTitle';
import useTableColumns from '../../../hooks/useTableColumns';
import useTableState from '../../../hooks/useTableState';
import useToastMessage, {
  UseToastMessageOptions,
} from '../../../hooks/useToastMessage';
import { EmployeeUserDeviceCollection } from '../../../types/api/employees';
import * as deviceManagementGuards from '../../../utils/constants/auth/deviceManagement';
import { queryString } from '../../../utils/helpers/http';
import Table from '../../DataTable/Table/Table';
import Flex from '../../layout/flex/Flex';
import MainContent from '../../layout/flex/MainContent';
import Filters from '../Components/Filters/Filters';
import HeaderPages from '../Components/HeaderPages/HeaderPages';
import {
  Column,
  Row,
  additionalColumnProperties,
  generateContextMenu,
  getColumnHeadersMap,
  tableStorageKey,
} from './DeviceManagement.functions';
import DeleteDialog from './Dialogs/Delete/DeleteDialog';
import useTableFilters from './useTableFilters';

function DeviceManagement(): JSX.Element {
  const { t } = useTranslation();
  usePageTitle(t('Device Management'));
  const columnHeadersMap = useMemo(() => getColumnHeadersMap(t), [t]);
  const approveGuard = useEndpointGuard(deviceManagementGuards.approveDevice);
  const denyGuard = useEndpointGuard(deviceManagementGuards.denyDevice);
  const deleteGuard = useEndpointGuard(deviceManagementGuards.deleteDevice);
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
  const {
    tableRef,
    page,
    setPage,
    limit,
    setLimit,
    sortField,
    sortOrder,
    selection,
    setSortField,
    setSortOrder,
    setSelection,
  } = useTableState<Row>(tableStorageKey);

  const { columns, columnOptions, selectedColumns, setSelectedColumns } =
    useTableColumns<Column>(
      page,
      limit,
      'deviceManagement',
      columnHeadersMap,
      columnHeadersMap,
      (c) => additionalColumnProperties(t, c)
    );

  const { headerFiltersCount, filters, httpFiltersObj, resetAllFilters } =
    useTableFilters(page, setPage!, limit);

  const { data, error, isLoading, reload } =
    useAxiosHook<EmployeeUserDeviceCollection>(
      '/employees/userdevices' + queryString(httpFiltersObj)
    );

  const {
    data: deleteData,
    error: deleteError,
    reload: deleteDevice,
  } = useAxiosHook(
    { url: `/userdevices/${selection?.user_device_id}`, method: 'DELETE' },
    { skipWhen: true }
  );

  const {
    data: updateData,
    error: updateError,
    reload: updateStatus,
  } = useAxiosHook();

  const deleteToastOptions = useMemo<UseToastMessageOptions>(
    () => ({
      success: {
        summary: t('The device was succesfully deleted.'),
        callback: reload,
      },
      error: {
        summary: t('An error occured while trying to delete the device.'),
      },
    }),
    [reload, t]
  );

  const updateToastOptions = useMemo<UseToastMessageOptions>(
    () => ({
      success: {
        title: t('Status Change Success'),
        summary: t('Device status successfully updated.'),
        callback: reload,
      },
      error: {
        title: t('Status Change Error'),
        summary: t('An error occured while trying to update device status.'),
      },
    }),
    [reload, t]
  );

  useToastMessage(deleteData, deleteError, deleteToastOptions);
  useToastMessage(updateData, updateError, updateToastOptions);

  // Handlers
  function handleDeviceDelete() {
    deleteDevice();
    setIsDeleteDialogVisible(false);
  }

  const contextMenuModel = useMemo(() => {
    function handleApprove() {
      updateStatus({
        url: `/userdevicequeues/${selection?.user_device_queue_id}`,
        method: 'PUT',
      });
    }

    function handleDeny() {
      updateStatus({
        url: `/userdevicequeues/${selection?.user_device_queue_id}`,
        method: 'DELETE',
      });
    }

    return generateContextMenu({
      t,
      selection: selection,
      isApproveShown: approveGuard,
      handleApprove: handleApprove,
      isDenyShown: denyGuard,
      handleDeny: handleDeny,
      isDeleteShown: deleteGuard,
      handleDelete: () => setIsDeleteDialogVisible(true),
    });
  }, [approveGuard, deleteGuard, denyGuard, selection, t, updateStatus]);

  return (
    <div className="page device-management-page">
      <HeaderPages
        title={t('Device Management')}
        subtitle={t('View and manage devices of employees')}
        icon={faMobileAlt}
      />

      <DeleteDialog
        visible={isDeleteDialogVisible}
        onHide={() => setIsDeleteDialogVisible(false)}
        handleDeviceDelete={handleDeviceDelete}
      />

      <Flex direction="column">
        <Filters
          filters={filters}
          resetAllFilters={resetAllFilters}
          headerFiltersCount={headerFiltersCount}
          filterHeight={220}
        />
        <MainContent>
          <Table
            tableRef={tableRef}
            columnOptions={columnOptions}
            columns={columns}
            data={data}
            hasError={!!error}
            headerTitle=""
            isLoading={isLoading}
            rebuildTooltip
            reload={reload}
            rows={limit}
            filterHeight={220}
            selectedColumns={selectedColumns}
            selection={selection}
            setLimit={setLimit}
            setPage={setPage}
            setSelectedColumns={setSelectedColumns}
            setSelection={setSelection}
            setSortField={setSortField}
            setSortOrder={setSortOrder}
            sortField={sortField}
            sortOrder={sortOrder}
            storageString={tableStorageKey}
            headerFiltersCount={headerFiltersCount}
            onHeaderFiltersResetAllBtnClick={resetAllFilters}
            contextMenuModel={contextMenuModel}
          />
        </MainContent>
      </Flex>
    </div>
  );
}

export default DeviceManagement;
