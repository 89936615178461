import './ViewDialog.scss';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import useMediaQuery from '../../../../../hooks/useMediaQuery';
import { EntityIdRouteParams } from '../../../../../types/routing';
import {
  RoutePaths,
  constructIdRoute,
} from '../../../../../utils/constants/routePaths';
import DialogSpinner from '../../../../Dialogs/DialogSpinner/DialogSpinner';
import useDialogData from '../useDialogData';
import DialogContainer from './DialogContainer';

type Props = {
  isShown: boolean;
  onHide: () => void;
  isEditBtnShown: boolean;
};

function ViewDialog({ isShown, onHide, isEditBtnShown }: Props): JSX.Element {
  const { t } = useTranslation();

  const history = useHistory();
  const { id } = useParams<EntityIdRouteParams>();

  const {
    title,
    data,
    isLoading,
    clientTypeOptions,
    rolesOptions,
    privacyOptions,
  } = useDialogData(isShown, onHide);

  const header = isLoading ? t('Loading...') : t('Viewing') + ' ' + title;

  const dialogFooter = isLoading ? (
    <></>
  ) : (
    <>
      {isEditBtnShown && (
        <Button
          type="button"
          label={t('Edit')}
          onClick={() =>
            history.push(constructIdRoute(RoutePaths.EditRoute, id as string))
          }
          className="p-button-secondary p-button-text"
        />
      )}

      <Button type="button" label={t('Close')} onClick={onHide} />
    </>
  );

  const isOnMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Dialog
      header={header}
      footer={dialogFooter}
      visible={isShown}
      resizable={false}
      onHide={onHide}
      maximizable
      maximized={isOnMobile}
      style={{ width: 580, maxWidth: '100%' }}
      className="roles-page-view-dialog"
    >
      {isLoading ? (
        <DialogSpinner />
      ) : (
        <DialogContainer
          data={data}
          privacyOptions={privacyOptions}
          rolesOptions={rolesOptions}
          clientTypeOptions={clientTypeOptions}
        />
      )}
    </Dialog>
  );
}

export default ViewDialog;
