import './Sidebar.scss';

import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useEndpointGuard } from '../../../hooks/useEndpointGuard';
import useSearchQueryDropdownParam from '../../../hooks/useSearchQueryDropdownParam';
import * as regionsGuards from '../../../utils/constants/auth/regions';
import { Region } from './Regions.functions';

type Props = {
  regions: Region[];
  selectedRegion: Region | undefined;
  selectedRegionId: number | null;
  setSelectedRegionId: Dispatch<SetStateAction<number | null>>;
  selectedCourierId: number | null;
  setSelectedCourierId: Dispatch<SetStateAction<number | null>>;
  handleNewRegionBtnClick: () => void;
  handleViewRegionBtnClick: () => void;
  handleEditRegionBtnClick: () => void;
  handleDeleteRegionBtnClick: () => void;
  handleNewCourierBtnClick: () => void;
  handleViewCourierBtnClick: () => void;
  handleEditCourierBtnClick: () => void;
  handleDeleteCourierBtnClick: () => void;
};

const Sidebar = ({
  regions,
  selectedRegion,
  selectedRegionId,
  setSelectedRegionId,
  selectedCourierId,
  setSelectedCourierId,
  handleNewRegionBtnClick,
  handleViewRegionBtnClick,
  handleEditRegionBtnClick,
  handleDeleteRegionBtnClick,
  handleNewCourierBtnClick,
  handleViewCourierBtnClick,
  handleEditCourierBtnClick,
  handleDeleteCourierBtnClick,
}: Props) => {
  const { t } = useTranslation();

  const addRegionGuard = useEndpointGuard(regionsGuards.addRegion);
  const editRegionGuard = useEndpointGuard(regionsGuards.editRegion);
  const deleteRegionGuard = useEndpointGuard(regionsGuards.deleteRegion);
  const assignCourierGuard = useEndpointGuard(regionsGuards.assignCourier);
  const editCourierGuard = useEndpointGuard(regionsGuards.editCourier);
  const deleteCourierGuard = useEndpointGuard(regionsGuards.deleteCourier);

  const isRegionSelected = !!selectedRegion;
  const isCourierSelected = selectedCourierId !== null;

  const selectRegionDropdownOptions = useMemo<
    { label: string; value: number }[]
  >(
    () => regions.map((r) => ({ label: r.name, value: r.region_id })),
    [regions]
  );

  const selectCourierDropdownOptions = useMemo(
    () =>
      selectedRegion?.couriers?.map((c) => ({
        label: `${c.first_name} ${c.last_name}`,
        value: c.id,
      })) ?? [],
    [selectedRegion]
  );

  useSearchQueryDropdownParam(
    'region',
    selectedRegionId,
    setSelectedRegionId,
    selectRegionDropdownOptions
  );

  useSearchQueryDropdownParam(
    'courier',
    selectedCourierId,
    setSelectedCourierId,
    selectCourierDropdownOptions
  );

  return (
    <div className="sidebar p-shadow-2">
      <div className="p-fluid">
        <div className="p-field">
          <label htmlFor="select_region">{t('Region')}</label>

          <Dropdown
            inputId="select_region"
            value={selectedRegionId}
            options={selectRegionDropdownOptions}
            placeholder={t('Select a region')}
            filter
            showClear
            onChange={(e) => {
              setSelectedRegionId(e.target.value);
            }}
            className="data-cy-select_region"
          />
        </div>
      </div>

      <div className="action-buttons">
        {addRegionGuard && (
          <Button
            type="button"
            icon="fas fa-plus"
            tooltip={t('Add new region')}
            tooltipOptions={{ position: 'bottom' }}
            onClick={handleNewRegionBtnClick}
            data-cy="add-region-btn"
          />
        )}

        <Button
          type="button"
          icon="fas fa-search"
          tooltip={t('View region')}
          tooltipOptions={{ position: 'bottom' }}
          disabled={!isRegionSelected}
          onClick={handleViewRegionBtnClick}
          className="p-button-text"
        />

        {editRegionGuard && (
          <Button
            type="button"
            icon="fas fa-edit"
            tooltip={t('Edit region')}
            tooltipOptions={{ position: 'bottom' }}
            disabled={!isRegionSelected}
            onClick={handleEditRegionBtnClick}
            className="p-button-text"
            data-cy="edit-region-btn"
          />
        )}

        {deleteRegionGuard && (
          <Button
            type="button"
            icon="fas fa-trash"
            tooltip={t('Delete region')}
            tooltipOptions={{ position: 'bottom' }}
            disabled={!isRegionSelected}
            onClick={handleDeleteRegionBtnClick}
            className="p-button-text"
            data-cy="delete-region-btn"
          />
        )}
      </div>

      {isRegionSelected && (
        <>
          <div className="p-fluid">
            <div className="p-field">
              <label htmlFor="select_courier">{t('Courier')}</label>

              <Dropdown
                inputId="select_courier"
                value={selectedCourierId}
                options={selectCourierDropdownOptions}
                filter
                showClear
                placeholder={t('Select a courier')}
                onChange={(e) => {
                  setSelectedCourierId(e.target.value);
                }}
                disabled={
                  !selectedRegion || selectedRegion?.couriers?.length === 0
                }
              />
            </div>
          </div>

          <div className="action-buttons">
            {assignCourierGuard && (
              <Button
                type="button"
                icon="fas fa-plus"
                tooltip={t('Assign courier')}
                tooltipOptions={{ position: 'bottom' }}
                onClick={handleNewCourierBtnClick}
                className="p-button-outlined"
                data-cy="assign-courier-btn"
              />
            )}

            <Button
              type="button"
              icon="fas fa-search"
              tooltip={t('View courier')}
              tooltipOptions={{ position: 'bottom' }}
              onClick={handleViewCourierBtnClick}
              disabled={!isCourierSelected}
              className="p-button-text"
            />

            {editCourierGuard && (
              <Button
                type="button"
                icon="fas fa-edit"
                tooltip={t('Edit courier')}
                tooltipOptions={{ position: 'bottom' }}
                onClick={handleEditCourierBtnClick}
                disabled={!isCourierSelected}
                className="p-button-text"
                data-cy="edit-courier-btn"
              />
            )}

            {deleteCourierGuard && (
              <Button
                type="button"
                icon="fas fa-trash"
                tooltip={t('Remove courier')}
                tooltipOptions={{ position: 'bottom' }}
                onClick={handleDeleteCourierBtnClick}
                disabled={!isCourierSelected}
                className="p-button-text"
                data-cy="delete-courier-btn"
              />
            )}
          </div>
        </>
      )}

      {isRegionSelected &&
        selectedRegion?.couriers &&
        selectedRegion.couriers.length === 0 && (
          <p className="no-couriers-assigned">
            <FontAwesomeIcon icon={faInfoCircle} />
            {t(
              'There are currently no couriers assigned to the selected region'
            )}
          </p>
        )}
    </div>
  );
};

export default Sidebar;
