import _ from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import SidebarItem from '../../../Grid/Sidebar/SidebarItem';
import {
  CourierCompDocument,
  CourierCompDocumentAddresses,
} from '../CourierCompensation.functions';

type Props = {
  selectedCourierCompDoc: CourierCompDocument | null;
  data: CourierCompDocumentAddresses[];
};

function Stats({ selectedCourierCompDoc, data }: Props) {
  const { t } = useTranslation();

  const totalShipments = useMemo(() => {
    return data.length > 0 ? _.sumBy(_.map(data, 'total_shipments')) : '-';
  }, [data]);
  return (
    <SidebarItem template="stats">
      <p>
        - {selectedCourierCompDoc?.serial_no ?? ''} {t('total shipments')}:{' '}
        <b>{totalShipments}</b>
      </p>
    </SidebarItem>
  );
}

export default Stats;
