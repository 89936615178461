import './Welcome.scss';

import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import welcomeIllustration from '../../../assets/svg/welcome-high-five.svg';
import { useEndpointGuard } from '../../../hooks/useEndpointGuard';
import usePageTitle from '../../../hooks/usePageTitle';
import { ReduxState } from '../../../types/redux';
import * as clientsGuards from '../../../utils/constants/auth/clients';
import * as courierMapGuards from '../../../utils/constants/auth/courierMap';
import * as courierPickupAssignmentGuards from '../../../utils/constants/auth/courierPickupAssignment';
import * as dashboardGuards from '../../../utils/constants/auth/dashboard';
import * as importedListsGuards from '../../../utils/constants/auth/importedLists';
import * as receptionAndDeliveryMapGuards from '../../../utils/constants/auth/receptionAndDeliveryMap';
import * as shipmentReconciliationGuards from '../../../utils/constants/auth/shipmentReconciliation';
import { RoutePaths } from '../../../utils/constants/routePaths';
import { isDevEnv } from '../../../utils/helpers/misc';

function Welcome(): JSX.Element {
  const { t } = useTranslation();

  const dashboardGuard = useEndpointGuard(dashboardGuards.routeGuard);
  const clientsGuard = useEndpointGuard(clientsGuards.routeGuard);
  const courierMapGuard = useEndpointGuard(courierMapGuards.routeGuard);
  const receptionAndDeliveryMapGuard = useEndpointGuard(
    receptionAndDeliveryMapGuards.routeGuard
  );
  const orderParcelAssignmentGuard = useEndpointGuard(
    courierPickupAssignmentGuards.routeGuard
  );
  const importedListsGuard = useEndpointGuard(importedListsGuards.routeGuard);
  const shipmentReconciliationGuard = useEndpointGuard(
    shipmentReconciliationGuards.routeGuard
  );

  usePageTitle(t('Home page'));

  const user = useSelector<ReduxState, ReduxState['user']>(
    (state) => state.user,
    shallowEqual
  );

  return (
    <div className="page welcome-page">
      <div className="welcome-grid">
        <div>
          <img src={welcomeIllustration} alt="" className="unselectable" />
        </div>

        <div>
          <h1>
            {t('Welcome back, {{user}}!', {
              user: user.employee_firstname,
            })}
          </h1>

          <p>
            {t(
              "The Accura system developed by Koder is a system which supports postal offices' working activities in every segment. It consists of mobile applications intended for use by couriers and warehousemen, as well as web applications used for managing the post office's resources, including shipment creation and tracking in an autonomous manner."
            )}
          </p>

          <p>
            {t('For more information about our company, visit our')}{' '}
            <a href="https://www.koder.mk/" target="_blank" rel="noreferrer">
              {t('web site')}
            </a>
            .
          </p>

          <h4 className="p-text-secondary p-mt-5 p-mb-2">{t('Quick links')}</h4>

          <div>
            {dashboardGuard && (
              <Link to={RoutePaths.Dashboard}>
                <Button
                  type="button"
                  label={t('Dashboard')}
                  className="p-mb-2 p-mr-2"
                />
              </Link>
            )}

            {clientsGuard && (
              <Link to={RoutePaths.Clients}>
                <Button
                  type="button"
                  label={t('Clients')}
                  className="p-mb-2 p-mr-2"
                />
              </Link>
            )}

            {courierMapGuard && (
              <Link to={RoutePaths.CourierMap}>
                <Button
                  type="button"
                  label={t('Courier Map')}
                  className="p-mb-2 p-mr-2"
                />
              </Link>
            )}

            {receptionAndDeliveryMapGuard && (
              <Link to={RoutePaths.ReceptionAndDeliveryMap}>
                <Button
                  type="button"
                  label={t('Reception And Delivery Map')}
                  className="p-mb-2 p-mr-2"
                />
              </Link>
            )}

            {importedListsGuard && (
              <Link to={RoutePaths.BulkOrders}>
                <Button
                  type="button"
                  label={t('Batch Orders')}
                  className="p-mb-2 p-mr-2"
                />
              </Link>
            )}

            {orderParcelAssignmentGuard && (
              <Link to={RoutePaths.CourierPickupAssignment}>
                <Button
                  type="button"
                  label={t('Courier Pickup Assignment')}
                  className="p-mb-2 p-mr-2"
                />
              </Link>
            )}

            {isDevEnv() && shipmentReconciliationGuard && (
              <Link to={RoutePaths.ShipmentReconciliations}>
                <Button
                  type="button"
                  label={t('Shipment Reconciliation')}
                  className="p-mb-2 p-mr-2"
                />
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Welcome;
