import dayjs from 'dayjs';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import useAxiosHook from '../../../../../hooks/useAxiosHook';
import useSearchQueryDateParam from '../../../../../hooks/useSearchQueryDateParam';
import useSearchQueryDropdownParam from '../../../../../hooks/useSearchQueryDropdownParam';
import { InvoiceableCollection } from '../../../../../types/api/clients';
import { httpDateFormat } from '../../../../../utils/helpers/formatting';
import { queryString } from '../../../../../utils/helpers/http';
import SidebarItem from '../../../../Grid/Sidebar/SidebarItem';

type Props = {
  dateFrom: Date;
  dateTo: Date;
  client: string | null;
  setDateFrom: Dispatch<SetStateAction<Date>>;
  setDateTo: Dispatch<SetStateAction<Date>>;
  setClient: Dispatch<SetStateAction<string | null>>;
};

function Filters({
  dateFrom,
  dateTo,
  client,
  setDateFrom,
  setDateTo,
  setClient,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const { data: clientsData, isLoading: isClientsLoading } =
    useAxiosHook<InvoiceableCollection>(
      '/clients/invoiceable' +
        queryString({
          date_from: httpDateFormat(dateFrom),
          date_to: httpDateFormat(dateTo),
          limit: 0,
        })
    );

  const clientsOptions =
    clientsData?.data.map((client) => ({
      label: client.ime,
      value: client.id,
    })) ?? [];

  useSearchQueryDateParam('dateFrom', dateFrom);
  useSearchQueryDateParam('dateTo', dateTo);
  useSearchQueryDropdownParam('client', client, setClient, clientsOptions);

  return (
    <SidebarItem template="filters">
      <div className="p-field">
        <label htmlFor="client">{t('Client')}</label>
        <Dropdown
          name="client"
          id="client"
          filter
          showClear
          showFilterClear
          options={clientsOptions}
          value={client}
          onChange={(e) => setClient(e.target.value)}
          disabled={!clientsOptions.length}
          placeholder={
            isClientsLoading
              ? t('Loading...')
              : !clientsOptions.length
              ? t('No clients found')
              : t('Select a client')
          }
        />
      </div>

      <div className="p-grid">
        <div className="p-col p-field">
          <label htmlFor="date_from">{t('Date From')}</label>
          <Calendar
            dateFormat="dd/mm/yy"
            id="date_from"
            maxDate={dayjs().endOf('month').toDate()}
            monthNavigator
            name="date_from"
            value={dateFrom}
            readOnlyInput
            yearNavigator
            yearRange={`2000:${dayjs().format('YYYY')}`}
            onChange={(e) => {
              setDateFrom(e.value as Date);
            }}
          />
        </div>

        <div className="p-col p-field">
          <label htmlFor="date_to">{t('Date To')}</label>
          <Calendar
            dateFormat="dd/mm/yy"
            id="date_to"
            maxDate={dayjs().endOf('month').toDate()}
            monthNavigator
            name="date_to"
            value={dateTo}
            readOnlyInput
            yearNavigator
            yearRange={`2000:${dayjs().format('YYYY')}`}
            onChange={(e) => {
              setDateTo(e.value as Date);
            }}
          />
        </div>
      </div>
    </SidebarItem>
  );
}

export default Filters;
