import { faMinus } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDebounce } from 'use-lodash-debounce';

import useAxiosHook from '../../../../hooks/useAxiosHook';
import { useEndpointGuard } from '../../../../hooks/useEndpointGuard';
import useHaveValuesChanged from '../../../../hooks/useHaveValuesChanged';
import useSearchQueryDateParam from '../../../../hooks/useSearchQueryDateParam';
import useSearchQueryDropdownParam from '../../../../hooks/useSearchQueryDropdownParam';
import useSearchQueryParam from '../../../../hooks/useSearchQueryParam';
import { WithPagination } from '../../../../types/api';
import { HubCollection } from '../../../../types/api/hubs';
import { LabelValue } from '../../../../types/options';
import * as warrantsGuards from '../../../../utils/constants/auth/cod/warrants';
import { debounceTimeout } from '../../../../utils/constants/misc';
import { httpDateFormat } from '../../../../utils/helpers/formatting';
import { queryString } from '../../../../utils/helpers/http';
import { renderIcon } from '../../../../utils/helpers/icon';
import {
  getSearchQueryParam,
  tryDateSearchParam,
} from '../../../../utils/helpers/searchQuery';
import { TableProps } from '../../../DataTable/Table/Table';
import FiltersCounter from '../../Components/Filters/FiltersCounter';

type ClientOption = {
  id: number;
  name: string;
};

function useTableFilters(
  page: number,
  setPage: TableProps['setPage'],
  limit: number
) {
  const { t } = useTranslation();
  const clientFilterGuard = useEndpointGuard(warrantsGuards.clientFilter);
  const hubFilterGuard = useEndpointGuard(warrantsGuards.hubFilter);
  const location = useLocation();
  const [clientFilter, setClientFilter] = useState<string | null>(() =>
    clientFilterGuard
      ? getSearchQueryParam(location.search, 'client') ?? null
      : null
  );
  const [hubFilter, setHubFilter] = useState<string | null>(() =>
    hubFilterGuard ? getSearchQueryParam(location.search, 'hub') ?? null : null
  );
  const [serialNoFilter, setSerialNoFilter] = useState<string>(
    () => getSearchQueryParam(location.search, 'shipment_serial_no') ?? ''
  );
  const [parcelSerialNoFilter, setParcelSerialNoFilter] = useState<string>(
    () => getSearchQueryParam(location.search, 'cod_shipment_serial_no') ?? ''
  );
  const [codSerialNumberFilter, setCodSerialNumberFilter] = useState<string>(
    () => getSearchQueryParam(location.search, 'cod_serial_no') ?? ''
  );
  const [dateFrom, setDateFrom] = useState<Date | null>(
    () =>
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'date_from') ?? ''
      ) ?? dayjs().subtract(1, 'month').toDate()
  );
  const [dateTo, setDateTo] = useState<Date | null>(
    () =>
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'date_to') ?? ''
      ) ?? new Date()
  );

  const debouncedSerialNoFilter = useDebounce(serialNoFilter, debounceTimeout);
  const debouncedParcelSerialNo = useDebounce(
    parcelSerialNoFilter,
    debounceTimeout
  );
  const debouncedCodSerialNumber = useDebounce(
    codSerialNumberFilter,
    debounceTimeout
  );

  const { data: clients, isLoading: isLoadingClients } = useAxiosHook<
    WithPagination<ClientOption[]>
  >(
    '/cashondeliveries/clients' +
      queryString({
        date_from: dateFrom ? httpDateFormat(dateFrom) : undefined,
        date_to: dateTo ? httpDateFormat(dateTo) : undefined,
        limit: 1000,
      }),
    { skipWhen: !dateTo || !clientFilterGuard }
  );

  const { data: hubs } = useAxiosHook<HubCollection>('/hubs', {
    skipWhen: !hubFilterGuard,
  });

  useEffect(() => {
    if (!setPage) {
      return;
    }

    setPage(1);
  }, [
    dateFrom,
    dateTo,
    debouncedSerialNoFilter,
    debouncedParcelSerialNo,
    clientFilter,
    hubFilter,
    setPage,
  ]);

  useEffect(() => {
    if (!clientFilterGuard) {
      setClientFilter(null);
    }
  }, [clientFilterGuard]);

  useEffect(() => {
    if (!hubFilterGuard) {
      setHubFilter(null);
    }
  }, [hubFilterGuard]);

  const hubsOptions = useMemo<LabelValue<number>[]>(
    () =>
      hubs?.data?.map((h) => ({
        label: h.name,
        value: h.id,
      })) ?? [],
    [hubs?.data]
  );

  const filtersArr = useMemo(
    () => [
      debouncedSerialNoFilter,
      debouncedParcelSerialNo,
      clientFilter,
      hubFilter,
      dateFrom,
      dateTo,
    ],
    [
      clientFilter,
      dateFrom,
      dateTo,
      debouncedParcelSerialNo,
      debouncedSerialNoFilter,
      hubFilter,
    ]
  );

  const haveFiltersChanged = useHaveValuesChanged(filtersArr);

  const httpFiltersObj = useMemo(
    () => ({
      shipment_serial_number: debouncedSerialNoFilter,
      cod_shipment_serial_number: debouncedParcelSerialNo,
      cod_serial_number: debouncedCodSerialNumber,
      client_id: clientFilter,
      hub_id: hubFilter,
      date_from: dateFrom ? httpDateFormat(dateFrom) : undefined,
      date_to: dateTo ? httpDateFormat(dateTo) : undefined,
      page: haveFiltersChanged ? 1 : page,
      limit,
    }),
    [
      clientFilter,
      dateFrom,
      dateTo,
      debouncedCodSerialNumber,
      debouncedParcelSerialNo,
      debouncedSerialNoFilter,
      haveFiltersChanged,
      hubFilter,
      limit,
      page,
    ]
  );

  const clientsOptions = useMemo<LabelValue<number>[]>(
    () =>
      clients?.data?.map((c) => ({
        label: c.name,
        value: c.id,
      })) ?? [],
    [clients?.data]
  );

  useSearchQueryDateParam('date_from', dateFrom);
  useSearchQueryDateParam('date_to', dateTo);
  useSearchQueryParam('serialNo', debouncedSerialNoFilter);
  useSearchQueryParam('parcelSerialNo', debouncedParcelSerialNo);
  useSearchQueryDropdownParam(
    'client',
    clientFilter,
    setClientFilter,
    clientsOptions
  );
  useSearchQueryDropdownParam('hub', hubFilter, setHubFilter, hubsOptions);

  const basicFiltersActiveFilterCount = Object.values({
    dateFrom, // date_from and date_to is count like one
    serialNoFilter,
    parcelSerialNoFilter,
    clientFilter,
  }).filter(Boolean).length;

  const filters = useMemo<JSX.Element>(
    () => (
      <Accordion multiple activeIndex={[0]}>
        <AccordionTab
          disabled
          headerTemplate={
            <FiltersCounter
              description={t('Basic filters')}
              counter={basicFiltersActiveFilterCount}
            />
          }
        >
          <div className="sidebar_filter">
            <label htmlFor="filter_date_from">{t('Created Date')}</label>
            <div className="sidebar_filter_row">
              <Calendar
                value={dateFrom ?? undefined}
                monthNavigator
                yearNavigator
                yearRange={`2000:${dayjs().format('YYYY')}`}
                dateFormat="dd/mm/yy"
                maxDate={new Date()}
                inputId="filter_date_from"
                onChange={(e) => {
                  setDateFrom(e.value as Date | null);
                }}
              />
              <span style={{ margin: 'auto' }}>{renderIcon(faMinus)}</span>
              <Calendar
                value={dateTo ?? undefined}
                monthNavigator
                yearNavigator
                yearRange={`2000:${dayjs().format('YYYY')}`}
                dateFormat="dd/mm/yy"
                maxDate={new Date()}
                inputId="filter_date_to"
                onChange={(e) => {
                  setDateTo(e.value as Date | null);
                }}
              />
            </div>
          </div>
          <div className="sidebar_filter">
            <label htmlFor="filter_serial_no">{t('Parcel Serial No')}</label>
            <InputText
              name="filter_serial_no"
              value={serialNoFilter}
              onChange={(e) => setSerialNoFilter(e.target.value)}
              id="filter_serial_no"
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="filter_parcel_serial_no">
              {t('COD Shipment Serial No.')}
            </label>
            <InputText
              name="filter_parcel_serial_no"
              id="filter_parcel_serial_no"
              value={parcelSerialNoFilter}
              onChange={(e) => setParcelSerialNoFilter(e.target.value)}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="cod_serial_number">{t('COD Serial No.')}</label>
            <InputText
              name="cod_serial_number"
              id="cod_serial_number"
              value={codSerialNumberFilter}
              onChange={(e) => setCodSerialNumberFilter(e.target.value)}
            />
          </div>
          {clientFilterGuard && (
            <div className="sidebar_filter">
              <label htmlFor="filter_client">{t('Client')}</label>
              <Dropdown
                name="filter_client"
                inputId="filter_client"
                value={clientFilter}
                onChange={(e) => setClientFilter(e.value)}
                filter
                filterPlaceholder={t('Search by name')}
                showClear
                disabled={isLoadingClients}
                placeholder={isLoadingClients ? t('Loading...') : ''}
                options={clientsOptions}
              />
            </div>
          )}
          {hubFilterGuard && (
            <div className="sidebar_filter">
              <label htmlFor="filter_hub">{t('Warehouse')}</label>
              <Dropdown
                name="filter_hub"
                inputId="filter_hub"
                value={hubFilter}
                onChange={(e) => setHubFilter(e.value)}
                options={hubsOptions}
                filter
                filterPlaceholder={t('Search by name')}
                showClear
              />
            </div>
          )}
        </AccordionTab>
      </Accordion>
    ),
    [
      clientFilter,
      clientFilterGuard,
      clientsOptions,
      codSerialNumberFilter,
      dateFrom,
      dateTo,
      hubFilter,
      hubFilterGuard,
      hubsOptions,
      isLoadingClients,
      parcelSerialNoFilter,
      serialNoFilter,
      basicFiltersActiveFilterCount,
      t,
    ]
  );

  const headerFiltersCount = useMemo(
    () => basicFiltersActiveFilterCount,
    [basicFiltersActiveFilterCount]
  );

  function resetAllFilters() {
    setDateFrom(new Date());
    setDateTo(new Date());
    setSerialNoFilter('');
    setParcelSerialNoFilter('');
    setClientFilter(null);
    setHubFilter(null);
    setCodSerialNumberFilter('');
  }

  return {
    filters,
    resetAllFilters,
    httpFiltersObj,
    headerFiltersCount,
    dateFrom,
    dateTo,
  };
}

export default useTableFilters;
