import { TFunction } from 'react-i18next';
import * as Yup from 'yup';

import { yupRequiredField } from '../../../../../utils/helpers/yup';

export type FormValues = {
  email: string;
};

export const initialValues: Record<keyof FormValues, string> = {
  email: '',
};

export function getValidationSchema(
  t: TFunction
): Yup.ObjectSchema<Record<keyof FormValues, any>> {
  return Yup.object().shape({
    email: Yup.string()
      .email()
      .required(yupRequiredField(t, t('Email'))),
  });
}
