import { Field, useFormikContext } from 'formik';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { invalidDecimalPointCharactersRegex } from '../../../../../../../utils/constants/regex';
import FieldWithErrors from '../../../../../../Forms/FieldWithErrors/FieldWithErrors';
import { FormValues } from '../../CreateEditDialog.functions';

type Props = {
  isRedemptionFilledOut: boolean;
};

export function LetterAttributes({ isRedemptionFilledOut }: Props) {
  const { t } = useTranslation();

  const { setFieldValue } = useFormikContext<FormValues>();

  const valueTooltipContent = t(
    'Only one of redemption or value can be present at a time.'
  );

  return (
    <>
      <FieldWithErrors name="vrednost" label={t('Value')} className="p-col-6">
        <div
          className="disabled-value-tooltip"
          data-pr-tooltip={valueTooltipContent}
        >
          <Field
            as={InputText}
            name="vrednost"
            disabled={isRedemptionFilledOut}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setFieldValue(
                'vrednost',
                e.target.value?.replace(invalidDecimalPointCharactersRegex, '')
              );
            }}
          />
        </div>
      </FieldWithErrors>

      <FieldWithErrors
        name="personal_delivery"
        label={t('Letter type')}
        className="p-col-6"
      >
        <Field
          as={Dropdown}
          name="personal_delivery"
          inputId="recomended_letter"
          options={[
            { value: 0, label: t('Unregistered letter') },
            { value: 1, label: t('Registered letter') },
          ]}
          showClear
        />
      </FieldWithErrors>
    </>
  );
}
