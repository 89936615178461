import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import ToastContext from '../../../../../../context/ToastContext';
import useAxiosHook from '../../../../../../hooks/useAxiosHook';
import usePrevious from '../../../../../../hooks/usePrevious';
import {
  errorToast,
  successToast,
} from '../../../../../../utils/helpers/primereact';
import { BulkOrder } from '../../../BulkOrders.functions';

type Props = {
  selectedList: BulkOrder | any;
  isShown: boolean;
  onHide: () => void;
  reloadImportedLists: () => void;
};

function DeleteImportedListDialog({
  selectedList,
  isShown,
  onHide,
  reloadImportedLists,
}: Props) {
  const { t } = useTranslation();

  const { toastRef } = useContext(ToastContext);

  const {
    data: submissionRequestData,
    error: submissionRequestError,
    isLoading: isSubmissionRequestLoading,
    reload: submissionRequestReload,
  } = useAxiosHook(
    {
      url: `/orders/bulk/${selectedList?.id}`,
      method: 'DELETE',
    },
    {
      skipWhen: true,
    }
  );

  const prevSubmissionRequestData = usePrevious(submissionRequestData);
  const prevSubmissionRequestError = usePrevious(submissionRequestError);

  useEffect(() => {
    if (
      !submissionRequestData ||
      submissionRequestData === prevSubmissionRequestData ||
      !selectedList
    ) {
      return;
    }

    successToast(
      toastRef,
      t('Success'),
      t('Imported list {{selectedListName}} has been successfully deleted', {
        selectedListName: selectedList.name,
      })
    );

    reloadImportedLists();
    onHide();
  }, [
    onHide,
    prevSubmissionRequestData,
    reloadImportedLists,
    selectedList,
    submissionRequestData,
    t,
    toastRef,
  ]);

  useEffect(() => {
    if (
      !submissionRequestError ||
      submissionRequestError === prevSubmissionRequestError ||
      !selectedList
    ) {
      return;
    }

    errorToast(
      toastRef,
      t('Error'),
      t(
        'An error occured while trying to delete the import list {{selectedListName}}.',
        { selectedListName: selectedList.name }
      )
    );

    onHide();
  }, [
    onHide,
    prevSubmissionRequestError,
    selectedList,
    submissionRequestError,
    t,
    toastRef,
  ]);

  function handleDeleteSubmision() {
    submissionRequestReload();
  }

  const dialogHeader = <span>{t('Are you sure?')}</span>;

  const dialogFooter = (
    <>
      <Button
        type="button"
        label={t('Cancel')}
        onClick={onHide}
        className="p-button-outlined"
      />

      <Button
        type="button"
        label={isSubmissionRequestLoading ? t('Deleting...') : t('Delete')}
        disabled={isSubmissionRequestLoading}
        onClick={handleDeleteSubmision}
        className="p-button-danger"
        data-cy="submit-btn"
      />
    </>
  );

  return (
    <Dialog
      visible={isShown}
      onHide={onHide}
      header={dialogHeader}
      footer={dialogFooter}
      dismissableMask
      resizable={false}
    >
      {t(
        'Are you sure you want to delete {{selectedListName}} imported list?',
        { selectedListName: selectedList?.name ?? '' }
      )}
    </Dialog>
  );
}

export default DeleteImportedListDialog;
