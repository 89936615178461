import { FieldArrayRenderProps, useFormikContext } from 'formik';
import { Button } from 'primereact/button';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getOrderStatusOptions } from '../../../../../../../configs/orders';
import {
  getProductTypeLabels,
  getProductTypeOptions,
} from '../../../../../../../configs/product';
import {
  getServiceTypeLabels,
  getServiceTypeOptions,
} from '../../../../../../../configs/service';
import { Product } from '../../../../../../../enums/product';
import { Service } from '../../../../../../../enums/service';
import FieldWithErrors from '../../../../../../Forms/FieldWithErrors/FieldWithErrors';
import { FormFields } from '../../CreateEditDialog.types';
import styles from './Discounts.module.scss';
import Row from './Row';
import TitleRow from './TitleRow';

type Props = {
  disabled: boolean;
  arrayHelpers: FieldArrayRenderProps;
  productServiceMapping: [Product, Service][];
  isLoading: boolean;
};

function Content({
  disabled,
  arrayHelpers,
  productServiceMapping,
  isLoading,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const { values } = useFormikContext<FormFields>();

  const serviceLabels = useMemo(() => getServiceTypeLabels(t), [t]);
  const serviceOptions = useMemo(() => getServiceTypeOptions(t), [t]);

  const productLabels = useMemo(() => getProductTypeLabels(t), [t]);
  const productOptions = useMemo(() => getProductTypeOptions(t), [t]);

  const orderStatusOptions = useMemo(() => getOrderStatusOptions(t), [t]);

  return (
    <>
      <FieldWithErrors
        name="popust"
        label={false}
        className="p-fluid"
        childrenWrapperClassName={styles.discountsGrid}
      >
        <TitleRow />

        {values.popust?.map((_, idx) => {
          return (
            <Row
              key={idx}
              idx={idx}
              arrayHelpers={arrayHelpers}
              serviceOptions={serviceOptions}
              serviceLabels={serviceLabels}
              productLabels={productLabels}
              productOptions={productOptions}
              orderStatusOptions={orderStatusOptions}
              productServiceMapping={productServiceMapping}
              disabled={disabled}
              isLoading={isLoading}
            />
          );
        })}
      </FieldWithErrors>

      <Button
        type="button"
        label={t('Discount')}
        icon="fas fa-plus"
        className="p-button-outlined p-button-sm"
        onClick={() =>
          arrayHelpers.push({
            UslugaId: null,
            ProizvodId: null,
            StatusId: null,
            Popust: '',
          })
        }
      />
    </>
  );
}

export default Content;
