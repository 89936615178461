import './DialogSpinner.scss';

import { ProgressSpinner } from 'primereact/progressspinner';

function DialogSpinner({ style }: { style?: any }): JSX.Element {
  return (
    <ProgressSpinner
      fill="#ccc"
      strokeWidth="4"
      style={style}
      className="dialog-progress-spinner"
    />
  );
}

export default DialogSpinner;
