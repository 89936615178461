import { Field, useFormikContext } from 'formik';
import { Checkbox } from 'primereact/checkbox';
import { useTranslation } from 'react-i18next';

import { bankAccountRegex } from '../../../../../../../utils/constants/regex';
import FieldWithErrors from '../../../../../../Forms/FieldWithErrors/FieldWithErrors';

function CodBankAccountPaymentField() {
  const { t } = useTranslation();

  const { values } = useFormikContext();

  const isBankAccountValid = bankAccountRegex.test(values.smetka);

  return (
    <FieldWithErrors name="cod_bank_account_payment" label={false}>
      <Field
        as={Checkbox}
        name="cod_bank_account_payment"
        inputId="cod_bank_account_payment"
        checked={!isBankAccountValid ? false : values.cod_bank_account_payment}
        disabled={!isBankAccountValid}
        tooltip={
          values.smetka.length > 0
            ? !isBankAccountValid
              ? t('Invalid bank account number')
              : null
            : t('No bank account specified')
        }
      />
      <label htmlFor="cod_bank_account_payment">
        {t('Receive COD on bank account')}
      </label>
    </FieldWithErrors>
  );
}

export default CodBankAccountPaymentField;
