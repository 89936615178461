import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { useContext, useEffect, useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import useAxiosHook from '../../../../../../hooks/useAxiosHook';
import usePrevious from '../../../../../../hooks/usePrevious';
import {
  MunicipalityCollection,
  MunicipalityPlaceCollection,
} from '../../../../../../types/api/municipalities';
import {
  PlaceResource,
  PlacesStreetCollection,
} from '../../../../../../types/api/places';
import { PlaceOption } from '../../../../../../types/options';
import { isStreetFreeInputAllowed } from '../../../../../../utils/constants/misc';
import {
  placeItemTemplate,
  placeValueTemplate,
} from '../../../../../../utils/helpers/misc';
import Step from '../../../../../Dialogs/Stepper/Step';
import StepperDialogContext from '../../../../../Dialogs/Stepper/StepperDialogContext';
import FieldWithErrors from '../../../../../Forms/ReactHookForm/FieldWithErrors';
import { FormValues } from '../WebImportDialog.types';

function SenderAddress(): JSX.Element {
  const { t } = useTranslation();

  const { setIsLoading } = useContext(StepperDialogContext);

  const { setValue } = useFormContext<FormValues>();

  const _listType = useWatch<FormValues, '_listType'>({ name: '_listType' });
  const _municipality = useWatch<FormValues, '_municipality'>({
    name: '_municipality',
  });
  const place = useWatch<FormValues, 'place'>({ name: 'place' });
  const street = useWatch<FormValues, 'street'>({ name: 'street' });
  const adresa = useWatch<FormValues, 'adresa'>({ name: 'adresa' });

  const isClientSender = _listType?.klient_isSender === 1;

  const { data: municipalities, isLoading: isMunicipalitiesLoading } =
    useAxiosHook<MunicipalityCollection>('/municipalities');

  const { data: placeData, isLoading: isPlaceDataLoading } =
    useAxiosHook<PlaceResource>(`/places/${place}`, {
      skipWhen: !place,
    });

  const { data: places, isLoading: isPlacesLoading } =
    useAxiosHook<MunicipalityPlaceCollection>(
      `/municipalities/${_municipality}/places`,
      {
        skipWhen: !_municipality,
      }
    );

  const { data: streetsData, isLoading: isStreetsLoading } =
    useAxiosHook<PlacesStreetCollection>(`/places/${place}/streets`, {
      skipWhen: !place,
    });

  useEffect(() => {
    setIsLoading!(
      isMunicipalitiesLoading ||
        isPlaceDataLoading ||
        isPlacesLoading ||
        isStreetsLoading
    );
  }, [
    isMunicipalitiesLoading,
    isPlaceDataLoading,
    isPlacesLoading,
    isStreetsLoading,
    setIsLoading,
  ]);

  const municipalitiesOptions = useMemo(
    () =>
      municipalities?.map((m) => ({
        label: m.ime,
        value: String(m.id),
      })),
    [municipalities]
  );

  const placesOptions = useMemo<PlaceOption[]>(
    () =>
      places?.map((m) => ({
        label: m.ime,
        value: String(m.id),
        postal_code: m.postenski_broj,
      })) ?? [],
    [places]
  );

  const streetsOptions = streetsData
    ? streetsData.map((m) => ({ label: m.ime, value: String(m.id) }))
    : [];

  const prevPlaceData = usePrevious(placeData);

  useEffect(() => {
    if (!placeData || placeData === prevPlaceData) {
      return;
    }

    setValue('_municipality', String(placeData.opstina_id));
  }, [placeData, prevPlaceData, setValue]);

  return (
    <Step
      title={isClientSender ? t('Sender address') : t('Recipient address')}
      subtitle={
        isClientSender
          ? t('What is the pickup location?')
          : t('What is the delivery location?')
      }
    >
      <FieldWithErrors name="_municipality" label={t('Municipality')}>
        <Controller
          name="_municipality"
          render={({ field }) => (
            <Dropdown
              name="_municipality"
              inputId="_municipality"
              options={municipalitiesOptions}
              filter
              filterPlaceholder={t('Search')}
              placeholder={
                isMunicipalitiesLoading ? t('Loading...') : undefined
              }
              value={field.value}
              onChange={(e) => {
                field.onChange(e.value);
                setValue('place', '');
                setValue('street', '');
                setValue('adresa', '');
                setValue('broj', '');
                setValue('vlez', '');
                setValue('stan', '');
              }}
            />
          )}
        />
      </FieldWithErrors>

      <FieldWithErrors name="place" label={t('Place')}>
        <Controller
          name="place"
          render={({ field }) => (
            <Dropdown
              disabled={!_municipality || isPlacesLoading}
              name="place"
              inputId="place"
              options={placesOptions}
              itemTemplate={placeItemTemplate}
              valueTemplate={placeValueTemplate}
              filter
              filterPlaceholder={t('Search')}
              placeholder={isPlacesLoading ? t('Loading...') : undefined}
              value={field.value}
              onChange={(e) => {
                if (e.value) {
                  field.onChange(e.value);
                  setValue('street', '');
                  setValue('adresa', '');
                  setValue('broj', '');
                  setValue('vlez', '');
                  setValue('stan', '');
                } else {
                  field.onChange('');
                  setValue('street', '');
                  setValue('adresa', '');
                  setValue('broj', '');
                  setValue('vlez', '');
                  setValue('stan', '');
                }
              }}
            />
          )}
        />
      </FieldWithErrors>

      <FieldWithErrors
        name="street"
        label={isStreetFreeInputAllowed ? t('Address') : t('Street')}
      >
        {isStreetFreeInputAllowed ? (
          <Controller
            name="adresa"
            render={({ field }) => (
              <InputText
                id={field.name}
                {...field}
                className="adresa"
                name="adresa"
                disabled={!_municipality || !place}
                maxLength={256}
              />
            )}
          />
        ) : (
          <>
            <Controller
              name="street"
              render={({ field }) => (
                <Dropdown
                  disabled={!_municipality || !place || isStreetsLoading}
                  name="street"
                  inputId="street"
                  options={streetsOptions}
                  filterPlaceholder={t('Search')}
                  placeholder={isStreetsLoading ? t('Loading...') : undefined}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e.value);
                    setValue('broj', '');
                    setValue('vlez', '');
                    setValue('stan', '');
                  }}
                />
              )}
            />
            <div className="street-numbers">
              <FieldWithErrors name="broj" label={t('Street No.')}>
                <Controller
                  name="broj"
                  render={({ field }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      disabled={isStreetFreeInputAllowed ? !adresa : !street}
                      className="broj"
                      name="broj"
                    />
                  )}
                />
              </FieldWithErrors>

              <FieldWithErrors name="vlez" label={t('Entrance No.')}>
                <Controller
                  name="vlez"
                  render={({ field }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      disabled={isStreetFreeInputAllowed ? !adresa : !street}
                      className="vlez"
                      name="vlez"
                    />
                  )}
                />
              </FieldWithErrors>

              <FieldWithErrors name="stan" label={t('Flat No.')}>
                <Controller
                  name="stan"
                  render={({ field }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      disabled={isStreetFreeInputAllowed ? !adresa : !street}
                      className="stan"
                      name="stan"
                    />
                  )}
                />
              </FieldWithErrors>
            </div>
          </>
        )}
      </FieldWithErrors>
    </Step>
  );
}

export default SenderAddress;
