import { Field, Form, Formik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import ToastContext from '../../../../../../context/ToastContext';
import useAxiosHook from '../../../../../../hooks/useAxiosHook';
import usePrevious from '../../../../../../hooks/usePrevious';
import { Numeric } from '../../../../../../types/general';
import {
  errorToast,
  successToast,
} from '../../../../../../utils/helpers/primereact';
import FieldWithErrors from '../../../../../Forms/FieldWithErrors/FieldWithErrors';

type Props = {
  invoiceID: number | undefined;
  ordinalNumber: Numeric | undefined;
  visible: boolean;
  onHide: () => void;
  reloadData: () => void;
};

function EditInvoice({
  invoiceID,
  ordinalNumber,
  visible,
  onHide,
  reloadData,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const { toastRef } = useContext(ToastContext);

  const {
    data: updateInvoiceData,
    error: updateInvoiceError,
    isLoading: isUpdateInvoiceLoading,
    reload: updateInvoiceReload,
  } = useAxiosHook(`/invoices/${invoiceID}`, { skipWhen: true });

  const updateInvoiceDataPrevious = usePrevious(updateInvoiceData);
  const updateInvoiceErrorPrevious = usePrevious(updateInvoiceError);

  useEffect(() => {
    if (!updateInvoiceData || updateInvoiceData === updateInvoiceDataPrevious) {
      return;
    }

    successToast(
      toastRef,
      t('Invoice Successfully Updated'),
      t('Invoice ordinal number is updated.')
    );

    reloadData();
    onHide();
  }, [
    onHide,
    reloadData,
    t,
    toastRef,
    updateInvoiceData,
    updateInvoiceDataPrevious,
  ]);

  useEffect(() => {
    if (
      !updateInvoiceError ||
      updateInvoiceError === updateInvoiceErrorPrevious
    ) {
      return;
    }

    errorToast(
      toastRef,
      t('Invoice Update Failed'),
      t('An error occurred while trying to update the ordinal number')
    );

    onHide();
  }, [onHide, t, toastRef, updateInvoiceError, updateInvoiceErrorPrevious]);

  function handleUpdateInvoice(values: { ordinalNumber: string }) {
    if (String(ordinalNumber) === values.ordinalNumber) {
      onHide();
      return;
    }

    updateInvoiceReload({
      url: `invoices/${invoiceID}`,
      method: 'PUT',
      data: { serial_number: values.ordinalNumber },
    });
  }

  const dialogFooter = (
    <>
      <Button
        type="button"
        label={t('Close')}
        className="p-button-secondary p-button-text"
        onClick={onHide}
      />
      <Button
        type="submit"
        label={isUpdateInvoiceLoading ? t('Saving...') : t('Save')}
        disabled={isUpdateInvoiceLoading}
        form="ordinalNumberUpdateForm"
      />
    </>
  );

  return (
    <Dialog
      header={t('Edit Invoice - {{ordinalNumber}}', {
        ordinalNumber: ordinalNumber,
      })}
      footer={dialogFooter}
      visible={visible}
      onHide={onHide}
      style={{ width: 360 }}
    >
      <Formik
        initialValues={{ ordinalNumber: String(ordinalNumber ?? '') }}
        onSubmit={handleUpdateInvoice}
      >
        {() => (
          <Form id="ordinalNumberUpdateForm">
            <div className="p-fluid">
              <FieldWithErrors
                name={t('Ordinal Number')}
                label={t('Ordinal Number')}
              >
                <Field id="ordinalNumber" name="ordinalNumber" as={InputText} />
              </FieldWithErrors>
            </div>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

export default EditInvoice;
