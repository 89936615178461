import { useTranslation } from 'react-i18next';

type Props = {
  name: string;
};

function ReadyToBeDeleted({ name }: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      <p className="p-m-0">
        {t('Are you sure you want to delete price list {{name}}?', {
          name,
        })}
      </p>

      <p className="p-m-0 p-mt-2">{t('This action cannot be undone.')}</p>
    </>
  );
}

export default ReadyToBeDeleted;
