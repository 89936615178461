import { WithPagination } from '../../../types/api';

export enum LogSeverity {
  Error = 1,
  Warning,
  Info,
}

export type LogEntry = {
  id: number;
  user_id: number;
  user_name: string;
  ip_address: string;
  severity_id: number;
  message: string;
  user_agent: string;
  source: string;
};

export type LogCollection = WithPagination<LogEntry[]>;
