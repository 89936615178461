import { Field, Form, Formik, FormikProps } from 'formik';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import ToastContext from '../../../../../context/ToastContext';
import useAxiosHook from '../../../../../hooks/useAxiosHook';
import usePrevious from '../../../../../hooks/usePrevious';
import {
  mediumStrenthRegex,
  passwordFeedback,
  strongStrenthRegex,
} from '../../../../../utils/constants/passwords';
import {
  errorToast,
  successToast,
} from '../../../../../utils/helpers/primereact';
import FieldWithErrors from '../../../../Forms/FieldWithErrors/FieldWithErrors';
import {
  FormFields,
  SuccessObj,
  generateErrorMsg,
  getInitialValues,
  getValidationSchema,
} from './ChangePassword.functions';

function ChangePassword(): JSX.Element {
  const { t } = useTranslation();

  const formRef = useRef<FormikProps<any>>(null);
  const toastRef = useContext(ToastContext)?.toastRef!;

  const { data, error, isLoading, reload } = useAxiosHook<SuccessObj>();

  const prevData = usePrevious(data);
  const prevError = usePrevious(error);

  useEffect(() => {
    if (!data || data === prevData) {
      return;
    }

    successToast(
      toastRef,
      t('Success'),
      data?.message ?? t('Successfully changed password')
    );

    formRef?.current?.resetForm();
  }, [t, data, prevData, toastRef]);

  useEffect(() => {
    if (!error || error === prevError) {
      return;
    }

    errorToast(
      toastRef,
      t('Error'),
      t('Changing password failed {{errorMessage}}.', {
        errorMessage: generateErrorMsg(t, error.message),
      })
    );

    formRef?.current?.resetForm();
  }, [t, error, prevError, toastRef]);

  function handleFormSubmision(values: FormFields) {
    reload({
      url: `/users/own/password`,
      data: values,
      method: 'PUT',
    });
  }

  return (
    <div className="change-password">
      <div className="p-fluid">
        <Formik
          innerRef={formRef}
          initialValues={getInitialValues()}
          validationSchema={getValidationSchema(t)}
          onSubmit={handleFormSubmision}
          validateOnBlur
        >
          <Form>
            <FieldWithErrors
              name="current_password"
              label={t('Current Password')}
            >
              <Field
                as={Password}
                id="current_password"
                name="current_password"
                feedback
                mediumRegex={mediumStrenthRegex as any}
                strongRegex={strongStrenthRegex as any}
                toggleMask
                autoComplete="off"
                promptLabel={t('Enter current password')}
              />
            </FieldWithErrors>

            <FieldWithErrors name="new_password" label={t('New Password')}>
              <Field
                as={Password}
                id="new_password"
                name="new_password"
                feedback
                header={passwordFeedback(t)}
                mediumRegex={mediumStrenthRegex as any}
                strongRegex={strongStrenthRegex as any}
                toggleMask
                autoComplete="off"
                promptLabel={t('Enter a new password')}
              />
            </FieldWithErrors>

            <FieldWithErrors
              name="confirm_password"
              label={t('Confirm Password')}
            >
              <Field
                as={Password}
                id="confirm_password"
                name="confirm_password"
                feedback
                mediumRegex={mediumStrenthRegex as any}
                strongRegex={strongStrenthRegex as any}
                toggleMask
                autoComplete="off"
                promptLabel={t('Confirm password')}
              />
            </FieldWithErrors>
          </Form>
        </Formik>
      </div>

      <Button
        type="button"
        label={isLoading ? t('Submitting...') : t('Submit')}
        disabled={isLoading}
        onClick={() => {
          if (formRef.current) {
            formRef.current.handleSubmit();
          }
        }}
      />
    </div>
  );
}

export default ChangePassword;
