import { createContext } from 'react';

import { noop } from '../../../utils/helpers/functions';

type Context = {
  nextItem: () => void;
};

const BreadcrumbsDialogContext = createContext<Context>({
  nextItem: noop,
});

export default BreadcrumbsDialogContext;
