import { Field, useFormikContext } from 'formik';
import { Button } from 'primereact/button';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-lodash-debounce';

import useAxiosHook from '../../../../../hooks/useAxiosHook';
import {
  ClientLookupCollection,
  ClientLookupCollectionQueryParams,
} from '../../../../../types/api/clients';
import { LabelValue } from '../../../../../types/options';
import { Unpacked } from '../../../../../types/util';
import { debounceTimeout } from '../../../../../utils/constants/misc';
import { queryString } from '../../../../../utils/helpers/http';
import AutoCompleteInput from '../../../../Forms/AutoCompleteInput/AutoCompleteInput';
import FieldWithErrors from '../../../../Forms/FieldWithErrors/FieldWithErrors';
import {
  ClientOption,
  FormFields,
  getItemTemplate,
} from './MergeDialog.functions';

type Props = {
  client: Unpacked<ClientLookupCollection> | undefined;
};

function DialogContent({ client }: Props): JSX.Element {
  const { t } = useTranslation();

  const { values, setFieldValue, setErrors } = useFormikContext<FormFields>();

  const [preservingFilter, setPreservingFilter] = useState<string>('');
  const [deletingFilter, setDeletingFilter] = useState<string>(
    client?.ime ?? ''
  );

  const debouncedPreservingClient = useDebounce<string>(
    preservingFilter,
    debounceTimeout
  );

  const debouncedDeletingClient = useDebounce<string>(
    deletingFilter,
    debounceTimeout
  );

  const { data: preservingData, isLoading: isPreservingLoading } =
    useAxiosHook<ClientLookupCollection>(
      `/clients/lookup` +
        queryString<ClientLookupCollectionQueryParams>({
          ime: debouncedPreservingClient,
          limit: 20,
          page: 1,
        }),
      {
        skipWhen: !!values.preserving,
      }
    );

  const { data: deletingData, isLoading: isDeletingLoading } =
    useAxiosHook<ClientLookupCollection>(
      '/clients/lookup' +
        queryString<ClientLookupCollectionQueryParams>({
          ime: debouncedDeletingClient,
          limit: 20,
          page: 1,
        }),
      {
        skipWhen: !!values.deleting,
      }
    );

  const preservingOptions = useMemo<LabelValue<ClientOption>[]>(
    () =>
      preservingData?.data
        .filter((c) => {
          if (typeof values.deleting === 'string') {
            return true;
          }

          return c.id !== values.deleting?.id;
        })
        .map((c) => ({ label: c.ime, value: c })) ?? [],
    [preservingData, values.deleting]
  );

  const deletingOptions = useMemo<LabelValue<ClientOption>[]>(
    () =>
      deletingData?.data
        .filter((c) => {
          if (typeof values.preserving === 'string') {
            return true;
          }

          return c.id !== values.preserving?.id;
        })
        .map((c) => ({ label: c.ime, value: c })) ?? [],
    [deletingData, values.preserving]
  );

  const handlePreservingChange = useCallback(
    (newValue: string) => {
      setFieldValue('preserving', newValue);
    },
    [setFieldValue]
  );

  const handleDeletingChange = useCallback(
    (newValue: string) => {
      setFieldValue('deleting', newValue);
    },
    [setFieldValue]
  );

  function handleSwapBtnClick() {
    const preservingValue = values.preserving;

    setPreservingFilter(deletingFilter);
    setDeletingFilter(preservingFilter);

    setFieldValue('preserving', values.deleting, false);
    setFieldValue('deleting', preservingValue, false);

    setErrors({});
  }

  const itemTemplate = useMemo(() => getItemTemplate(t), [t]);

  return (
    <>
      <FieldWithErrors name="preserving" label={t('Preserving client')}>
        <Field
          as={AutoCompleteInput}
          id="preserving"
          name="preserving"
          filterValue={preservingFilter}
          options={preservingOptions}
          onFilterChange={setPreservingFilter}
          onSelectionChange={handlePreservingChange}
          isLoading={isPreservingLoading}
          itemTemplate={itemTemplate}
          filterDataCy="preserving"
          optionsClassName="data-cy-preserving-options"
        />
      </FieldWithErrors>

      <FieldWithErrors name="deleting" label={t('Deleting client')}>
        <Field
          as={AutoCompleteInput}
          id="deleting"
          name="deleting"
          filterValue={deletingFilter}
          options={deletingOptions}
          onFilterChange={setDeletingFilter}
          onSelectionChange={handleDeletingChange}
          isLoading={isDeletingLoading}
          itemTemplate={itemTemplate}
          filterDataCy="deleting"
          optionsClassName="data-cy-deleting-options"
        />
      </FieldWithErrors>

      <Button
        type="button"
        label={t('Swap')}
        icon="fas fa-sync-alt"
        disabled={!values.preserving && !values.deleting}
        className="p-button-secondary p-button-text"
        onClick={handleSwapBtnClick}
      />
    </>
  );
}

export default DialogContent;
