import { faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnProps } from 'primereact/column';

export const noColumnStyle: ColumnProps = {
  sortable: false,
  reorderable: false,
  style: {
    width: 60,
  },
  bodyStyle: {
    width: 60,
  },
};

export const emptyCell = (
  <div className="text-center">
    <FontAwesomeIcon icon={faMinus} style={{ color: 'var(--surface-400)' }} />
  </div>
);

export const rowsPerPageOptions = [5, 15, 30, 50, 75, 100];

export const rowsPerPageOptions500 = [
  ...rowsPerPageOptions,
  250,
  300,
  400,
  500,
];
