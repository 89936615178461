import dayjs from 'dayjs';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import SidebarItem from '../../../../../Grid/Sidebar/SidebarItem';
import { GroupFilter } from '../../CreateWarrants.functions';

type Props = {
  dateFromFilter: Date | null;
  setDateToFilter: Dispatch<SetStateAction<Date | null>>;
  dateToFilter: Date | null;
  setDateFromFilter: Dispatch<SetStateAction<Date | null>>;
  groupFilter: GroupFilter;
  setGroupFilter: Dispatch<SetStateAction<GroupFilter>>;
};

function ClientFilters({
  dateFromFilter,
  setDateToFilter,
  dateToFilter,
  setDateFromFilter,
  groupFilter,
  setGroupFilter,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const groupOptions = useMemo(
    () => [
      {
        label: t('Date'),
        value: 'datum_isporaka',
      },
      {
        label: t('Name'),
        value: 'ime',
      },
    ],
    [t]
  );

  return (
    <SidebarItem template="filters">
      <div className="p-grid">
        <div className="p-field p-col">
          <label htmlFor="date_filter_from">{t('Date From')}</label>
          <Calendar
            value={dateFromFilter ?? undefined}
            monthNavigator
            yearNavigator
            yearRange={`2009:${dayjs().format('YYYY')}`}
            dateFormat="dd/mm/yy"
            maxDate={new Date()}
            inputId="date_filter_from"
            onChange={(e) => {
              setDateFromFilter(e.value as Date | null);
            }}
          />
        </div>

        <div className="p-field p-col">
          <label htmlFor="date_filter_to">{t('Date To')}</label>
          <Calendar
            value={dateToFilter ?? undefined}
            monthNavigator
            yearNavigator
            yearRange={`2009:${dayjs().format('YYYY')}`}
            dateFormat="dd/mm/yy"
            maxDate={new Date()}
            inputId="date_filter_to"
            onChange={(e) => {
              setDateToFilter(e.value as Date | null);
            }}
          />
        </div>
      </div>

      <div className="p-field">
        <label htmlFor="group_filter">{t('Group by')}</label>
        <Dropdown
          options={groupOptions}
          inputId="group_filter"
          value={groupFilter}
          onChange={(e) => {
            setGroupFilter(e.value);
          }}
        />
      </div>
    </SidebarItem>
  );
}

export default ClientFilters;
