import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import store from './redux/store';
import en from './translations/en.json';
import mk from './translations/mk.json';
import srCyril from './translations/sr-Cyrl.json';
import srLatn from './translations/sr-Latn.json';

i18next.use(initReactI18next).init({
  lng: store.getState().language,
  fallbackLng: 'en',
  resources: {
    en: en,
    mk: mk,
    'sr-Cyrl': srCyril,
    'sr-Latn': srLatn,
  },
  interpolation: {
    escapeValue: false,
  },
});

export default i18next;
