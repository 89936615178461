import dayjs from 'dayjs';
import { Field, Form, Formik, FormikProps } from 'formik';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Dropdown, DropdownChangeParams } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';

import ToastContext from '../../../../../context/ToastContext';
import useAxiosHook from '../../../../../hooks/useAxiosHook';
import useMediaQuery from '../../../../../hooks/useMediaQuery';
import usePrevious from '../../../../../hooks/usePrevious';
import { httpDateFormat } from '../../../../../utils/helpers/formatting';
import { getChangedValues, isEqual } from '../../../../../utils/helpers/object';
import {
  errorToast,
  successToast,
} from '../../../../../utils/helpers/primereact';
import FieldWithErrors from '../../../../Forms/FieldWithErrors/FieldWithErrors';
import { Vehicle } from '../../Vehicles.functions';
import {
  FormFields,
  getInitialValues,
  getValidationSchema,
} from './AddEditDialog.functions';

type Props = {
  data: Vehicle | null;
  isEditDialog: boolean;
  visible: boolean;
  onHide: () => void;
  tableReload: () => void;
};

function AddEditDialog({
  data,
  isEditDialog,
  visible,
  onHide,
  tableReload,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const validationSchema = useMemo(() => getValidationSchema(t), [t]);

  const { toastRef } = useContext(ToastContext);

  const formRef = useRef<FormikProps<FormFields>>(null);

  const reformattedDates = useMemo(
    () =>
      data
        ? {
            datum_registracija: dayjs(data.datum_registracija).isValid()
              ? new Date(data.datum_registracija)
              : null,
            posleden_servis: dayjs(data.posleden_servis).isValid()
              ? new Date(data.posleden_servis)
              : null,
          }
        : undefined,
    [data]
  );

  // Requests
  const {
    data: addData,
    error: addError,
    reload: addReload,
    isLoading: isAddLoading,
  } = useAxiosHook<any, any>(
    { url: '/vehicles', method: 'POST' },
    { skipWhen: true }
  );

  const prevAddData = usePrevious(addData);
  const prevAddError = usePrevious(addError);

  useEffect(() => {
    if (!addData || addData === prevAddData) return;

    successToast(
      toastRef,
      t('Success'),
      t('Vehicle {{regPlates}} has been sucessfully created.', {
        regPlates: data?.registarska_tablicka,
      })
    );

    tableReload();
    onHide();
  }, [
    addData,
    data?.registarska_tablicka,
    onHide,
    prevAddData,
    t,
    tableReload,
    toastRef,
  ]);

  useEffect(() => {
    if (!addError || addError === prevAddError) return;

    errorToast(
      toastRef,
      t('Error'),
      t(
        'An error occured while creating vehicle {{regPlates}}. Make sure that a vehicle with the same registration plates is not registered.',
        {
          regPlates: data?.registarska_tablicka,
        }
      )
    );

    onHide();
  }, [addError, data?.registarska_tablicka, onHide, prevAddError, t, toastRef]);

  const {
    data: editData,
    error: editError,
    reload: editReload,
    isLoading: isEditLoading,
  } = useAxiosHook(
    { url: `/vehicles/${data?.id}`, method: 'PUT' },
    { skipWhen: true }
  );

  const prevEditData = usePrevious(editData);
  const prevEditError = usePrevious(editError);

  const handleSuccess = useCallback(() => {
    if (toastRef?.current) {
      successToast(
        toastRef,
        t('Success'),
        t('Vehicle {{regPlates}} has been sucessfully edited.', {
          regPlates: data?.registarska_tablicka,
        })
      );
    }
  }, [data?.registarska_tablicka, t, toastRef]);

  useEffect(() => {
    if (!editData || editData === prevEditData) {
      return;
    }

    handleSuccess();

    tableReload();
    onHide();
  }, [editData, handleSuccess, onHide, prevEditData, tableReload]);

  useEffect(() => {
    if (!editError || editError === prevEditError) {
      return;
    }

    errorToast(
      toastRef,
      t('Error'),
      t('An error occured while editing vehicle {{regPlates}}.', {
        regPlates: data?.registarska_tablicka,
      })
    );

    onHide();
  }, [
    data?.registarska_tablicka,
    editError,
    onHide,
    prevEditError,
    t,
    toastRef,
  ]);

  const { data: modelsData } = useAxiosHook<
    { id: number; name: string; vehicle_manufacturer_id: number }[]
  >('/vehicle-models', { skipWhen: !visible });

  const { data: brandsData } = useAxiosHook<{ id: number; name: string }[]>(
    '/vehicle-manufacturers',
    { skipWhen: !visible }
  );

  const models = useMemo(
    () =>
      modelsData?.map((model) => ({
        label: model.name,
        value: model.id,
        vehicle_manufacturer_id: model.vehicle_manufacturer_id,
      })) ?? [],
    [modelsData]
  );
  const brands = useMemo(
    () =>
      brandsData?.map((brand) => ({ label: brand.name, value: brand.id })) ??
      [],
    [brandsData]
  );

  const dialogFooter = (
    <>
      <Button
        type="button"
        label={t('Cancel')}
        onClick={() => onHide()}
        className="p-button-secondary p-button-text"
      />
      <Button
        type="button"
        label={
          isEditDialog
            ? isEditLoading
              ? t('Saving...')
              : t('Save')
            : isAddLoading
            ? t('Adding...')
            : t('Add')
        }
        disabled={isAddLoading || isEditLoading}
        onClick={() => {
          formRef.current?.handleSubmit();
        }}
        data-cy="submit-btn"
      />
    </>
  );

  const isOnMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Dialog
      header={
        isEditDialog
          ? t('Editing {{regPlates}}', {
              regPlates: data?.registarska_tablicka,
            })
          : t('Add New Vehicle')
      }
      footer={dialogFooter}
      visible={visible}
      onHide={onHide}
      maximizable={isOnMobile}
      maximized={isOnMobile}
      style={{ width: 360, maxWidth: '100%' }}
    >
      <Formik
        innerRef={formRef}
        initialValues={getInitialValues(isEditDialog, {
          ...data,
          ...reformattedDates,
        })}
        onSubmit={(values) => {
          if (isEditDialog) {
            if (formRef.current) {
              if (
                isEqual<FormFields>(
                  formRef.current.values,
                  formRef.current.initialValues
                )
              ) {
                handleSuccess();
                onHide();
                return;
              }

              let requestData = getChangedValues<FormFields>(
                formRef.current.values,
                formRef.current.initialValues
              );
              if (requestData.datum_registracija) {
                requestData.datum_registracija = dayjs(
                  requestData.datum_registracija
                ).isValid()
                  ? httpDateFormat(requestData.datum_registracija)
                  : null;
              }

              if (requestData.posleden_servis) {
                requestData.posleden_servis = dayjs(
                  requestData.posleden_servis
                ).isValid()
                  ? httpDateFormat(requestData.posleden_servis)
                  : null;
              }

              editReload({ data: requestData });
              return;
            }
          }

          addReload({
            data: {
              ...values,
              datum_registracija: dayjs(values.datum_registracija).isValid()
                ? httpDateFormat(values.datum_registracija)
                : null,
              posleden_servis: dayjs(values.posleden_servis).isValid()
                ? httpDateFormat(values.posleden_servis)
                : null,
            },
          });
        }}
        validateOnBlur
        validationSchema={validationSchema}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <div className="p-fluid">
              <FieldWithErrors name="marka_id" label={t('Brand')}>
                <Field
                  name="marka_id"
                  as={Dropdown}
                  options={brands}
                  inputId="marka_id"
                  onChange={(e: DropdownChangeParams) => {
                    setFieldValue('marka_id', e.target.value);
                    setFieldValue('model_id', null);
                  }}
                  className="data-cy-vehicle-brand"
                />
              </FieldWithErrors>
            </div>

            <div className="p-fluid">
              <FieldWithErrors name="model_id" label={t('Model')}>
                <Field
                  name="model_id"
                  as={Dropdown}
                  disabled={!values.marka_id}
                  options={models.filter(
                    ({ vehicle_manufacturer_id }) =>
                      vehicle_manufacturer_id === values.marka_id
                  )}
                  inputId="model_id"
                  className="data-cy-vehicle-model"
                />
              </FieldWithErrors>
            </div>

            <div className="p-fluid">
              <FieldWithErrors
                name="registarska_tablicka"
                label={t('Registration Plates')}
              >
                <Field
                  name="registarska_tablicka"
                  as={InputText}
                  id="registarska_tablicka"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFieldValue(
                      'registarska_tablicka',
                      e.target.value.toUpperCase().replace(/\s+/g, '-')
                    )
                  }
                  data-cy="reg-plates"
                />
              </FieldWithErrors>
            </div>

            <div className="p-fluid">
              <FieldWithErrors
                name="datum_registracija"
                label={t('Registration Date')}
              >
                <Field
                  name="datum_registracija"
                  as={Calendar}
                  id="datum_registracija"
                  dateFormat="dd/mm/yy"
                  maxDate={new Date()}
                  className="data-cy-reg-date"
                />
              </FieldWithErrors>
            </div>

            <div className="p-fluid">
              <FieldWithErrors name="posleden_servis" label={t('Last Service')}>
                <Field
                  name="posleden_servis"
                  as={Calendar}
                  id="posleden_servis"
                  dateFormat="dd/mm/yy"
                  maxDate={new Date()}
                  className="data-cy-last-service"
                />
              </FieldWithErrors>
            </div>

            <div className="p-fluid">
              <FieldWithErrors
                name="volume"
                label={
                  <>
                    {t('Load Capacity')} (m<sup>3</sup>)
                  </>
                }
              >
                <Field
                  name="volume"
                  as={InputText}
                  id="volume"
                  data-cy="load-volume"
                />
              </FieldWithErrors>
            </div>

            <div className="p-fluid">
              <FieldWithErrors name="aktivno" label={false}>
                <Field
                  type="checkbox"
                  name="aktivno"
                  as={Checkbox}
                  inputId="aktivno"
                  className="data-cy-vehicle-active"
                />
                <label htmlFor="aktivno">{t('Active.IT')}</label>
              </FieldWithErrors>
            </div>

            <div className="p-fluid">
              <FieldWithErrors name="komentar" label={t('Comment')}>
                <Field
                  name="komentar"
                  as={InputTextarea}
                  id="komentar"
                  autoResize={false}
                  style={{ resize: 'none' }}
                />
              </FieldWithErrors>
            </div>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

export default AddEditDialog;
