import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';

type Props = {
  visible: boolean;
  onHide: () => void;
  handleDeviceDelete: () => void;
};

function DeleteDialog({
  visible,
  onHide,
  handleDeviceDelete,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <Dialog
      header={t('Are you sure?')}
      visible={visible}
      modal
      footer={
        <>
          <Button
            type="button"
            label={t('Cancel')}
            className="p-button-plain p-button-text"
            onClick={onHide}
          />

          <Button
            type="button"
            label={t('Yes')}
            className="p-button-danger"
            onClick={handleDeviceDelete}
            data-cy="submit-btn"
          />
        </>
      }
      onHide={onHide}
      style={{ maxWidth: 480 }}
    >
      <div className="confirmation-content">
        <span>
          {t('Are you sure you want to delete this device?')}
          <br />
          {t('This action cannot be undone.')}
        </span>
      </div>
    </Dialog>
  );
}

export default DeleteDialog;
