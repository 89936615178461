import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnProps } from 'primereact/column';
import { TFunction } from 'react-i18next';

import { getOrderStatusConfig } from '../../../configs/orders';
import { ClientLookupCollection } from '../../../types/api/clients';
import { CourierPickupAssignmentCollection } from '../../../types/api/orders';
import { ContextMenuItem } from '../../../types/primereact';
import {
  TableColumnHeadersMap,
  TableColumns,
  Unpacked,
} from '../../../types/util';
import { noColumnStyle } from '../../../utils/constants/tables';
import { contextMenuModel } from '../../../utils/helpers/primereact';

export type Row = Unpacked<CourierPickupAssignmentCollection>;
export type ClientOption = Unpacked<ClientLookupCollection>['id'];

type Column =
  | TableColumns<
      Pick<
        Row,
        'status_id' | 'seriski_broj' | 'klient_od_ime' | 'mesto_od' | 'mesto_do'
      >
    >
  | 'courier';

export const tableStorageKey = 'courierParcelAssignment_dataTable';

export function getColumnHeadersMap(
  t: TFunction
): TableColumnHeadersMap<Column> {
  return {
    no: t('No.'),
    status_id: t('Status'),
    courier: t('Courier'),
    seriski_broj: t('Serial No'),
    klient_od_ime: t('Client'),
    mesto_od: t('Pickup place'),
    mesto_do: t('Delivery place'),
  };
}

export function additionalColumnProperties(
  t: TFunction,
  column: Column
): ColumnProps {
  switch (column) {
    case 'no':
      return noColumnStyle;

    case 'status_id':
      return {
        body: (row: Row) => {
          const orderStatusConfig = getOrderStatusConfig(t);
          const status = row[column];
          const config = orderStatusConfig[status];

          return (
            <span data-tip={config.label} data-for="tooltip-right">
              <FontAwesomeIcon icon={config.icon} />
            </span>
          );
        },
        style: {
          width: 120,
        },
        bodyStyle: {
          textAlign: 'center',
          width: 120,
        },
      };

    case 'courier':
      return {
        body: (row: Row) => `${row.postar_ime} ${row.postar_prezime}`,
        style: {
          width: 250,
        },
        bodyStyle: {
          width: 250,
        },
      };

    case 'seriski_broj':
      return {
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
        },
      };

    case 'klient_od_ime':
      return {
        style: {
          width: 250,
        },
        bodyStyle: {
          width: 250,
        },
      };

    case 'mesto_od':
    case 'mesto_do':
      return {
        style: {
          width: 180,
        },
        bodyStyle: {
          width: 180,
        },
      };
  }
}

export function generateContextMenu(
  t: TFunction,
  isViewOrderShown: boolean,
  handleCMViewOrderClick: () => void
): ContextMenuItem[] {
  const viewOrderDetailsOption = {
    label: t('View Details'),
    icon: 'fas fa-search',
    command: handleCMViewOrderClick,
  };

  return contextMenuModel([[[isViewOrderShown, viewOrderDetailsOption]]]);
}

export function generateGroupActions(
  t: TFunction,
  isAssignShown: boolean,
  handleCMAssignOrderClick: () => void
): ContextMenuItem[] {
  const assignOrders = {
    label: t('Assign order(s)'),
    icon: 'fas fa-check',
    command: handleCMAssignOrderClick,
  };

  return contextMenuModel([[[isAssignShown, assignOrders]]]);
}
