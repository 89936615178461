import { TFunction } from 'react-i18next';
import * as Yup from 'yup';

import { Route } from '../../Routes.functions';

type AccessRole = {
  clientType: number;
  privacy: string;
  roles: number[];
};

export type FormFields = {
  roles: AccessRole[];
};

export function getDefaultValues(data: Route | undefined): FormFields {
  return { roles: data?.roles ?? [] };
}

export function getValidationSchema(t: TFunction) {
  return Yup.object().shape({
    roles: Yup.array()
      .of(
        Yup.object().shape({
          clientType: Yup.number(),
          privacy: Yup.string(),
          roles: Yup.array().of(Yup.number()),
        })
      )
      .required()
      .test((clientTypes, { path, createError }) => {
        if (!clientTypes) {
          return false;
        }

        for (let i = 0; i < clientTypes.length; i++) {
          const clientType = clientTypes[i];

          // Client type and privacy are required field.
          // Further, roles are only required when privacy !== 'private'
          if (
            clientType.clientType === undefined ||
            clientType.privacy === undefined ||
            (clientType.privacy === 'private' &&
              (clientType.roles === undefined ||
                (Array.isArray(clientType.roles) &&
                  clientType.roles.length === 0)))
          ) {
            return createError({
              path,
              message: t('Some required fields are left out.'),
            });
          }
        }

        return true;
      }),
  }) as Yup.SchemaOf<FormFields>;
}
