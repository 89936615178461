import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import {
  faCheckCircle,
  faHourglassHalf,
  faPlusCircle,
} from '@fortawesome/free-solid-svg-icons';
import { TFunction } from 'react-i18next';

import { ReconciliationStatus } from '../enums/reconciliations';
import { LabelValue } from '../types/options';

type ReconciliationStatusConfig = Record<
  ReconciliationStatus,
  {
    label: string;
    color: string;
    bgColor: string;
    icon: IconDefinition;
  }
>;

export function getReconciliationStatusConfig(
  t: TFunction
): ReconciliationStatusConfig {
  return {
    [ReconciliationStatus.Creating]: {
      label: t('Creating'),
      color: 'var(--indigo-800)',
      bgColor: 'var(--indigo-100)',
      icon: faPlusCircle,
    },
    [ReconciliationStatus.WaitingForApproval]: {
      label: t('Waiting for approval'),
      color: 'var(--cyan-800)',
      bgColor: 'var(--cyan-100)',
      icon: faHourglassHalf,
    },
    [ReconciliationStatus.Approved]: {
      label: t('Approved'),
      color: 'var(--green-800)',
      bgColor: 'var(--green-100)',
      icon: faCheckCircle,
    },
  };
}

export function getReconciliationStatusOptions(
  t: TFunction
): LabelValue<ReconciliationStatus>[] {
  const config = getReconciliationStatusConfig(t);

  return Object.keys(config).map((key) => ({
    label: config[parseInt(key) as ReconciliationStatus].label,
    value: parseInt(key),
  }));
}
