import classNames from 'classnames';
import _ from 'lodash';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { Tooltip } from 'primereact/tooltip';
import { useMemo } from 'react';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { EntityPropertyCollection } from '../../../../../../types/api/entityproperties';
import Step from '../../../../../Dialogs/Stepper/Step';
import FieldWithErrors from '../../../../../Forms/ReactHookForm/FieldWithErrors';
import { generatePropertyName } from '../WebImportDialog.functions';
import { FormValues } from '../WebImportDialog.types';

type Props = {
  entityProperties: EntityPropertyCollection | undefined;
};

function Content({ entityProperties }: Props): JSX.Element {
  const { t } = useTranslation();

  const { register } = useFormContext<FormValues>();

  const { fields, append, remove } = useFieldArray<FormValues, '_fields'>({
    name: '_fields',
  });

  const _listType = useWatch<FormValues, '_listType'>({
    name: '_listType',
  });

  const fieldsGroupedById = useMemo(
    () => _.groupBy(_listType?.fields, 'id'),
    [_listType?.fields]
  );

  return (
    <Step
      title={t('Parcels')}
      subtitle={t("Let's add some parcels to the list!")}
      childrenWrapperClassName="content"
      fullWidth
    >
      <table>
        <thead>
          <tr>
            <th />
            {_listType?.fields?.map((field) => {
              return <th key={field.id}>{field.name}</th>;
            })}
          </tr>
        </thead>

        <tbody>
          {fields.map((field, idx) => {
            return (
              <tr key={field.id}>
                <td className="delete-row-btn">
                  <Button
                    type="button"
                    label={`${idx + 1}`}
                    icon={idx > 0 ? 'fas fa-times' : undefined}
                    tooltip={idx > 0 ? t('Remove field') : undefined}
                    tooltipOptions={{ position: 'top' }}
                    className={classNames('p-button-plain p-button-text', {
                      'row-num-delete-btn': idx > 0,
                    })}
                    onClick={() => {
                      return idx > 0 ? remove(idx) : undefined;
                    }}
                  />
                </td>

                {field.fields.map((subField, subIdx) => {
                  const fieldValues =
                    fieldsGroupedById[subField.id]?.[0]?.values;

                  return (
                    <td key={`${idx}-${subIdx}`}>
                      <div className="p-fluid">
                        {Array.isArray(fieldValues) && fieldValues.length ? (
                          <Controller
                            name={`_fields.${idx}.fields.${subIdx}.value`}
                            defaultValue={`_fields.${idx}.fields.${subIdx}.default_value`}
                            render={({ field: controlledField }) => (
                              <Dropdown
                                options={fieldValues}
                                filter
                                filterBy="value"
                                optionLabel="value"
                                optionValue="id"
                                placeholder={
                                  subField.isRequired
                                    ? t('Required')
                                    : t('Optional')
                                }
                                value={controlledField.value}
                                onChange={(e) =>
                                  controlledField.onChange(e.value)
                                }
                              />
                            )}
                          />
                        ) : (
                          <input
                            {...register(
                              `_fields.${idx}.fields.${subIdx}.value` as any
                            )}
                            defaultValue={`_fields.${idx}.fields.${subIdx}.default_value`}
                            placeholder={
                              subField.isRequired
                                ? t('Required')
                                : t('Optional')
                            }
                            className="p-inputtext p-component"
                          />
                        )}
                      </div>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="add-row-btn">
        <Button
          type="button"
          label={t('Add parcel')}
          icon="fas fa-plus"
          className="p-button-text p-button-sm"
          onClick={() => {
            const fieldValues = {
              fields: _listType!.fields.map((field) => ({
                id: field.id,
                name: field.name,
                value: field.default_value ?? '',
                isRequired: !!field.isRequired,
                property_id: field.property_id,
                property_name: generatePropertyName(
                  entityProperties,
                  field.property_id
                ),
                default_value: field.default_value,
              })),
            };

            append(fieldValues, { shouldFocus: false });
          }}
          data-cy="add-row-btn"
        />
      </div>

      <div
        data-pr-tooltip={t(
          'If an error occurs in a single shipment, all shipments will be rolled back.'
        )}
        className="p-mt-5 import-all-or-nothing"
      >
        <Controller
          name="import_all_or_none"
          render={({ field }) => (
            <>
              <Tooltip target=".import-all-or-nothing" />

              <div className="p-field-checkbox">
                <Checkbox
                  name="import_all_or_none"
                  inputId="import_all_or_none"
                  checked={!!field.value}
                  onChange={(e) => field.onChange(e.checked)}
                />
                <label
                  htmlFor="import_all_or_none"
                  className="p-checkbox-label"
                >
                  {t('Import all or nothing')}
                </label>
              </div>
            </>
          )}
        />
      </div>

      <FieldWithErrors name="_fields" label={false} children={null} />
    </Step>
  );
}

export default Content;
