import './ViewOrderDialog.scss';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import ToastContext from '../../../../../context/ToastContext';
import useAxiosHook from '../../../../../hooks/useAxiosHook';
import useMediaQuery from '../../../../../hooks/useMediaQuery';
import usePrevious from '../../../../../hooks/usePrevious';
import { WithData } from '../../../../../types/api';
import { errorToast } from '../../../../../utils/helpers/primereact';
import DialogSpinner from '../../../../Dialogs/DialogSpinner/DialogSpinner';
import { Order } from '../../DeletedOrders/DeletedOrders.functions';
import { SingleOrder } from '../../Orders.functions';
import ViewOrderContent from './ViewOrderContent';

type Props = {
  isShown: boolean;
  dataSerialNo: Order['seriski_broj'] | undefined;
  onHide: () => void;
  onRecreate: () => void;
};

function ViewDeletedOrderDialog({
  isShown,
  dataSerialNo,
  onHide,
  onRecreate,
}: Props) {
  const { t } = useTranslation();

  const { toastRef } = useContext(ToastContext);

  const { data, error, isLoading } = useAxiosHook<WithData<SingleOrder>>(
    {
      url: `/orders/deleted/${dataSerialNo}`,
    },
    { skipWhen: !dataSerialNo }
  );

  const prevError = usePrevious(error);

  useEffect(() => {
    if (!error || error === prevError) {
      return;
    }

    if (toastRef?.current) {
      errorToast(
        toastRef,
        t('Error'),
        t('An error occured while reading order data.')
      );
    }

    onHide();
  }, [error, onHide, prevError, t, toastRef]);

  const dialogFooter = (
    <>
      <Button
        label={t('Recreate')}
        className="p-button-text"
        onClick={() => onRecreate()}
      />

      <Button label={t('Close')} onClick={() => onHide()} />
    </>
  );

  const isOnMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Dialog
      visible={isShown}
      onHide={onHide}
      header={
        isLoading
          ? t('Loading...')
          : t('View Order {{serialNo}}', {
              serialNo: data?.data.seriski_broj ?? '',
            })
      }
      footer={dialogFooter}
      dismissableMask
      blockScroll
      maximizable
      maximized={isOnMobile}
      className="view-order-dialog"
    >
      {isLoading ? <DialogSpinner /> : <ViewOrderContent data={data?.data} />}
    </Dialog>
  );
}

export default ViewDeletedOrderDialog;
