import dayjs from 'dayjs';
import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { currencyFormat } from '../../../../utils/helpers/formatting';
import SidebarItem from '../../../Grid/Sidebar/SidebarItem';
import SidebarContentDivider from '../../../Sidebar/ContentDivider/SidebarContentDivider';
import { CouriersCashData } from '../CouriersCash.functions';

type Props = {
  date: Date;
  courier: { id: number; name: string } | undefined;
  allData: CouriersCashData[] | undefined;
  courierData: CouriersCashData[] | undefined;
};

function Stats({ date, courier, allData, courierData }: Props): JSX.Element {
  const { t } = useTranslation();

  // Total data stats
  const [totalCouriers, setTotalCouriers] = useState<number>(0);
  const [totalRedemptionCash, setTotalRedemptionCash] = useState<number>(0);
  const [totalPostageCash, setTotalPostageCash] = useState<number>(0);

  // Courier data stats
  const [totalCourierRedemptionCash, setTotalCourierRedemptionCash] =
    useState<number>(0);
  const [totalCourierPostageCash, setTotalCourierPostageCash] =
    useState<number>(0);

  useEffect(() => {
    // Calculations
    const couriers = new Set<number>();
    let allDataRedemptionCash = 0;
    let allDataPostageCash = 0;
    let courierDataRedemptionCash = 0;
    let courierDataPostageCash = 0;

    allData?.forEach((entry) => {
      couriers.add(entry.courier_id);

      allDataRedemptionCash += Number(entry.redemption);
      allDataPostageCash +=
        Number(entry.sender_cash) + Number(entry.recipient_cash);
    });

    courierData?.forEach((entry) => {
      courierDataRedemptionCash += Number(entry.redemption);
      courierDataPostageCash +=
        Number(entry.sender_cash) + Number(entry.recipient_cash);
    });

    // Set state from calculated values
    setTotalCouriers(couriers.size);
    setTotalRedemptionCash(allDataRedemptionCash);
    setTotalPostageCash(allDataPostageCash);
    setTotalCourierRedemptionCash(courierDataRedemptionCash);
    setTotalCourierPostageCash(courierDataPostageCash);
  }, [allData, courierData]);

  return (
    <SidebarItem template="stats">
      {courier && courierData && (
        <>
          <div className="courier-stats">
            <p>
              {t('Courier')}: <b>{courier.name}</b>
            </p>
            <p>
              {t('Shipments')}: <b>{courierData.length}</b>
            </p>
            <p>
              {t('Cash from postage')}:{' '}
              <b>
                {currencyFormat(totalCourierPostageCash, {
                  showCurrency: true,
                })}
              </b>
            </p>
            <p>
              {t('Cash from redemption')}:{' '}
              <b>
                {currencyFormat(totalCourierRedemptionCash, {
                  showCurrency: true,
                })}
              </b>
            </p>
          </div>

          <SidebarContentDivider />
        </>
      )}

      <div className="total-stats">
        <p>
          {t('Total couriers')}: <b>{totalCouriers}</b>
        </p>
        <p>
          {t('Total shipments for {{date}}', {
            date: dayjs(date).format('DD.MM.YYYY'),
          })}
          : <b>{allData?.length}</b>
        </p>
        <p>
          {t('Total cash from postage')}:{' '}
          <b>{currencyFormat(totalPostageCash, { showCurrency: true })}</b>
        </p>
        <p>
          {t('Total cash from redemption')}:{' '}
          <b>{currencyFormat(totalRedemptionCash, { showCurrency: true })}</b>
        </p>
      </div>
    </SidebarItem>
  );
}

export default Stats;
