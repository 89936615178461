import { Button } from 'primereact/button';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import useAxiosHook from '../../../hooks/useAxiosHook';
import { useEndpointGuard } from '../../../hooks/useEndpointGuard';
import usePageTitle from '../../../hooks/usePageTitle';
import useRouteDialog from '../../../hooks/useRouteDialog';
import useTableColumns from '../../../hooks/useTableColumns';
import useTableState from '../../../hooks/useTableState';
import { WithPagination } from '../../../types/api';
import { EntityIdRouteParams } from '../../../types/routing';
import * as courierLiabilitiesGuards from '../../../utils/constants/auth/courierLiabilities';
import {
  RoutePaths,
  constructIdRoute,
} from '../../../utils/constants/routePaths';
import { queryString } from '../../../utils/helpers/http';
import { httpQueryObject } from '../../../utils/helpers/misc';
import Table from '../../DataTable/Table/Table';
import HeaderPages from '../Components/HeaderPages/HeaderPages';
import {
  CourierLiability,
  CourierLiabilityResource,
  additionalColumnProperties,
  generateContextMenu,
  getColumnHeadersMap,
  tableStorageKey,
} from './CourierLiabilities.functions';
import CreateEditDialog from './Dialogs/CreateEdit/CreateEditDialog';
import DeleteLiabilityDialog from './Dialogs/Delete/DeleteLiabilityDialog';
import ViewAddressesDialog from './Dialogs/View/ViewAddressesDialog';
import useTableFilters from './useTableFilters';

function CourierLiabilities() {
  const { t } = useTranslation();

  const readGuard = useEndpointGuard(courierLiabilitiesGuards.readParcel);
  const createGuard = useEndpointGuard(courierLiabilitiesGuards.createParcel);
  const editGuard = useEndpointGuard(courierLiabilitiesGuards.editParcel);
  const deleteGuard = useEndpointGuard(courierLiabilitiesGuards.deleteParcel);

  const {
    tableRef,
    page,
    setPage,
    limit,
    setLimit,
    sortField,
    sortOrder,
    selection,
    setSortField,
    setSortOrder,
    setSelection,
  } = useTableState<CourierLiability>(tableStorageKey);

  const {
    headerFilters,
    headerFiltersForm,
    headerFiltersCount,
    resetAllFilters,
    httpFiltersObj,
  } = useTableFilters(page, setPage!, limit);

  const columnHeadersMap = useMemo(() => getColumnHeadersMap(t), [t]);

  const { selectedColumns, setSelectedColumns, columnOptions, columns } =
    useTableColumns(
      page,
      limit,
      'courierLiabilities',
      columnHeadersMap,
      columnHeadersMap,
      (c) => additionalColumnProperties(c as keyof typeof columnHeadersMap)
    );

  usePageTitle(t('Courier Liabilities'));

  const { id } = useParams<EntityIdRouteParams>();

  const {
    show: showCreateDialog,
    hide: hideCreateDialog,
    isVisible: isCreateDialogVisible,
  } = useRouteDialog(
    RoutePaths.CourierLiabilities,
    RoutePaths.AddCourierLiability
  );

  const {
    show: showEditDialog,
    hide: hideEditDialog,
    isVisible: isEditDialogVisible,
  } = useRouteDialog(
    RoutePaths.CourierLiabilities,
    constructIdRoute(RoutePaths.EditCourierLiability, id ?? selection?.id)
  );

  const {
    show: showViewAddressesDialog,
    hide: hideViewAddressesDialog,
    isVisible: isViewAddressesDialogVisible,
  } = useRouteDialog(
    RoutePaths.CourierLiabilities,
    constructIdRoute(RoutePaths.ViewCourierLiability, id ?? selection?.id)
  );

  const {
    show: showDeleteDialog,
    hide: hideDeleteDialog,
    isVisible: isDeleteDialogVisible,
  } = useRouteDialog(
    RoutePaths.CourierLiabilities,
    constructIdRoute(RoutePaths.DeleteCourierLiability, id ?? selection?.id)
  );

  function handleHide() {
    hideEditDialog();
    hideCreateDialog();
  }

  const { data: dialogData, isLoading: isDialogDataLoading } =
    useAxiosHook<CourierLiabilityResource>(
      `/parceldebts/${id ?? selection?.id}`,
      {
        skipWhen: !id && !selection?.id,
      }
    );

  useEffect(() => {
    if (!readGuard && isViewAddressesDialogVisible) {
      hideViewAddressesDialog();
    }
  }, [hideViewAddressesDialog, isViewAddressesDialogVisible, readGuard]);

  useEffect(() => {
    if (!createGuard && isCreateDialogVisible) {
      hideCreateDialog();
    }
  }, [createGuard, hideCreateDialog, isCreateDialogVisible]);

  useEffect(() => {
    if (!editGuard && isEditDialogVisible) {
      hideEditDialog();
    }
  }, [editGuard, hideEditDialog, isEditDialogVisible]);

  useEffect(() => {
    if (!deleteGuard && isDeleteDialogVisible) {
      hideDeleteDialog();
    }
  }, [deleteGuard, hideDeleteDialog, isDeleteDialogVisible]);

  const canLoadData = httpFiltersObj.date_from && httpFiltersObj.date_to;

  const { data, reload, isLoading, error } = useAxiosHook<
    WithPagination<CourierLiability[]>
  >('/parceldebts' + queryString(httpQueryObject(httpFiltersObj)), {
    skipWhen: !canLoadData,
  });

  return (
    <div className="page couriers-liability-page">
      <HeaderPages>
        <div>
          <h2 className="title">{t('Courier Liabilities')}</h2>
          <p className="subtitle">{t('View and manage courier liabilities')}</p>
        </div>
        <div>
          {createGuard && (
            <Button
              type="button"
              label={t('Create liability')}
              icon="fas fa-plus"
              className="p-mr-2 main-btn"
              onClick={showCreateDialog}
              data-cy="create-btn"
            />
          )}
        </div>
      </HeaderPages>

      <CreateEditDialog
        isShown={isCreateDialogVisible || isEditDialogVisible}
        onHide={handleHide}
        reload={reload}
        isLoading={isDialogDataLoading}
        isEditDialog={isEditDialogVisible}
        data={isEditDialogVisible ? dialogData : undefined}
      />

      <ViewAddressesDialog
        selectedLiability={selection}
        data={dialogData}
        isShown={isViewAddressesDialogVisible}
        onHide={hideViewAddressesDialog}
        isLoading={isDialogDataLoading}
      />

      <DeleteLiabilityDialog
        selectedLiability={selection}
        isShown={isDeleteDialogVisible}
        onHide={hideDeleteDialog}
        reload={reload}
      />

      <Table
        tableRef={tableRef}
        columns={columns}
        data={data}
        isLoading={isLoading}
        hasError={!!error}
        reload={reload}
        isReloadDisabled={!canLoadData}
        headerFilters={headerFilters}
        headerFiltersForm={headerFiltersForm}
        headerFiltersCount={headerFiltersCount}
        onHeaderFiltersResetAllBtnClick={resetAllFilters}
        setPage={setPage}
        setLimit={setLimit}
        sortField={sortField}
        rows={limit}
        contextMenuModel={generateContextMenu(
          t,
          readGuard,
          showViewAddressesDialog,
          editGuard,
          showEditDialog,
          deleteGuard,
          showDeleteDialog
        )}
        setSortField={setSortField}
        setSortOrder={setSortOrder}
        setSelection={setSelection}
        sortOrder={sortOrder}
        selection={selection}
        storageString={tableStorageKey}
        rebuildTooltip
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        columnOptions={columnOptions}
        onRowDoubleClick={readGuard ? showViewAddressesDialog : () => {}}
        exportToExcelButton
      />
    </div>
  );
}

export default CourierLiabilities;
