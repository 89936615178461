import { Field, FieldArray, useFormikContext } from 'formik';
import _ from 'lodash';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-lodash-debounce';

import useAxiosHook from '../../../../../../hooks/useAxiosHook';
import AutoCompleteInput from '../../../../../Forms/AutoCompleteInput/AutoCompleteInput';
import FieldWithErrors from '../../../../../Forms/FieldWithErrors/FieldWithErrors';

function OrdersTab() {
  const { t } = useTranslation();

  const { values } = useFormikContext();

  const [importedListTypeFilter, setImportedListTypeFilter] = useState('');

  const debouncedImportedListTypeFilter = useDebounce(importedListTypeFilter);

  const { data: importedListTypes } = useAxiosHook(
    '/importlisttypes?name=' + debouncedImportedListTypeFilter
  );

  const importedListTypeOptions = useMemo(() => {
    if (!importedListTypes?.data) {
      return [];
    }

    const diff = _.differenceBy(
      importedListTypes.data,
      values.import_list_types,
      'id'
    );

    return diff.map((lt) => ({
      value: _.pick(lt, ['id', 'name']),
      label: lt.name,
    }));
  }, [importedListTypes, values.import_list_types]);

  return (
    <div className="orders-tab">
      <span className="dialog-section-divider">{t('Order settings')}</span>

      <div className="orders-flags">
        <FieldWithErrors name="allow_incomplete_address" label={false}>
          <Field
            as={Checkbox}
            name="allow_incomplete_address"
            inputId="allow_incomplete_address"
            checked={values.allow_incomplete_address}
          />

          <label
            htmlFor="allow_incomplete_address"
            data-cy="allow_incomplete_address"
          >
            {t('Allow incomplete address')}
          </label>
        </FieldWithErrors>

        <FieldWithErrors name="orders_on_map_access" label={false}>
          <Field
            as={Checkbox}
            name="orders_on_map_access"
            inputId="orders_on_map_access"
            checked={values.orders_on_map_access}
          />

          <label htmlFor="orders_on_map_access" data-cy="orders_on_map_access">
            {t('Access orders on map')}
          </label>
        </FieldWithErrors>

        <FieldWithErrors name="potvrda_priem" label={false}>
          <Field
            as={Checkbox}
            name="potvrda_priem"
            inputId="potvrda_priem"
            checked={values.potvrda_priem}
          />

          <label htmlFor="potvrda_priem" data-cy="confirmation_receipt">
            {t('Confirmation receipt')}
          </label>
        </FieldWithErrors>
      </div>

      <span className="dialog-section-divider">{t('Assigned list types')}</span>

      <FieldArray
        name="import_list_types"
        validateOnChange={false}
        render={(arrayHelpers) => (
          <>
            <AutoCompleteInput
              id="client_list_type_add"
              filterValue={importedListTypeFilter}
              value={null}
              options={importedListTypeOptions}
              onFilterChange={setImportedListTypeFilter}
              onSelectionChange={(e) => {
                if (e) {
                  arrayHelpers.push(e);
                  setImportedListTypeFilter('');
                }
              }}
              filterDataCy="list_types"
              optionsClassName="data-cy-list_types-options"
            />

            <div className="assigned-templates-container">
              {values.import_list_types.length > 0 &&
                values.import_list_types.map((typeList, i) => (
                  <div key={typeList.id} className="assigned-template">
                    <div className="assigned-template-name">
                      {typeList.name}
                    </div>

                    <Button
                      type="button"
                      icon="fas fa-times"
                      className="p-button-plain p-button-text"
                      onClick={() => {
                        arrayHelpers.remove(i);
                      }}
                    />
                  </div>
                ))}
            </div>
          </>
        )}
      />
    </div>
  );
}

export default OrdersTab;
