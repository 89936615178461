import { faMinus } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Calendar } from 'primereact/calendar';
import { Dropdown, DropdownChangeParams } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Dispatch, useMemo } from 'react';
import { useState } from 'react';
import { SetStateAction } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDebounce } from 'use-lodash-debounce';

import { IntBool } from '../../../enums/booleans';
import { UserStatus } from '../../../enums/users';
import useAxiosHook from '../../../hooks/useAxiosHook';
import { useEndpointGuard } from '../../../hooks/useEndpointGuard';
import useHaveValuesChanged from '../../../hooks/useHaveValuesChanged';
import useSearchQueryDateParam from '../../../hooks/useSearchQueryDateParam';
import useSearchQueryDropdownParam from '../../../hooks/useSearchQueryDropdownParam';
import useSearchQueryMultiSelectParam from '../../../hooks/useSearchQueryMultiSelectParam';
import useSearchQueryParam from '../../../hooks/useSearchQueryParam';
import { ClientsCollectionGroupRoleFilter } from '../../../types/api/clients';
import { PriceListCollection } from '../../../types/api/priceLists';
import { LabelValue } from '../../../types/options';
import * as clientsGuards from '../../../utils/constants/auth/clients';
import {
  getNotificationChannel,
  getNotificationType,
} from '../../../utils/constants/entities/notifications';
import { debounceTimeout } from '../../../utils/constants/misc';
import { renderIcon } from '../../../utils/helpers/icon';
import { tryInt } from '../../../utils/helpers/parse';
import {
  getSearchQueryParam,
  tryDateSearchParam,
  tryMultiSelectParam,
} from '../../../utils/helpers/searchQuery';
import FiltersCounter from '../Components/Filters/FiltersCounter';

function useTableFilters(
  page: number,
  setPage: Dispatch<SetStateAction<number>>,
  limit: number
) {
  const { t } = useTranslation();
  const location = useLocation();
  const municipalityGuard = useEndpointGuard(clientsGuards.municipalityFilter);
  const priceListGuard = useEndpointGuard(clientsGuards.pricelistFilter);
  const subsidiaryOfGuard = useEndpointGuard(clientsGuards.subsidiaryOfFilter);
  const [name, setName] = useState<string | null>(
    () => getSearchQueryParam(location.search, 'ime') ?? null
  );
  const [phone, setPhone] = useState<string | null>(
    () => getSearchQueryParam(location.search, 'phone') ?? null
  );
  const [email, setEmail] = useState<string | null>(
    () => getSearchQueryParam(location.search, 'email') ?? null
  );
  const [priceId, setPriceId] = useState<string | null>(
    priceListGuard
      ? getSearchQueryParam(location.search, 'cenovnik_id') ?? null
      : null
  );
  const [clientType, setClientType] = useState(
    () => getSearchQueryParam(location.search, 'pravno_lice') ?? null
  );
  const [clientStatus, setClientStatus] = useState(
    () => getSearchQueryParam(location.search, 'status_id') ?? null
  );
  const [invoicing, setInvoicing] = useState(
    () => getSearchQueryParam(location.search, 'faktura') ?? null
  );
  const [municipalityId, setMunicipalityId] = useState(() =>
    municipalityGuard
      ? getSearchQueryParam(location.search, 'opstina_id') ?? null
      : null
  );
  const [groupRole, setGroupRole] = useState(
    () => getSearchQueryParam(location.search, 'group_role') ?? null
  );
  const [notificationTypeId, setNotificationTypeId] = useState(() => {
    const value =
      getSearchQueryParam(location.search, 'notification_type_id') ?? null;
    return value
      ? tryMultiSelectParam(value)?.map((o) => Number(o)) ?? null
      : null;
  });
  const [notificationChannelId, setNotificationChannelId] = useState(() => {
    const value =
      getSearchQueryParam(location.search, 'notification_channel_id') ?? null;
    return value
      ? tryMultiSelectParam(value)?.map((o) => Number(o)) ?? null
      : null;
  });
  const [socialSecurityNumber, setSocialSecurityNumber] = useState(
    () => getSearchQueryParam(location.search, 'maticen_broj') ?? null
  );
  const [taxNumber, setTaxNumber] = useState<string | null>(
    () => getSearchQueryParam(location.search, 'danocen_broj') ?? null
  );
  const [codEmailWarrant, setCodEmailWarrant] = useState<string | null>(
    () => getSearchQueryParam(location.search, 'cod_email_warrant') ?? null
  );
  const [invoiceEmail, setInvoiceEmail] = useState<string | null>(
    () => getSearchQueryParam(location.search, 'invoice_email') ?? null
  );
  const [dateCreatedFrom, setDateCreatedFrom] = useState<Date | null>(
    () =>
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'date_created_from') ?? ''
      ) ?? null
  );
  const [dateCreatedTo, setDateCreatedTo] = useState<Date | null>(
    () =>
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'date_created_to') ?? ''
      ) ?? null
  );
  const [dateModifiedFrom, setDateModifiedFrom] = useState<Date | null>(
    () =>
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'date_modified_from') ?? ''
      ) ?? null
  );
  const [dateModifiedTo, setDateModifiedTo] = useState<Date | null>(
    () =>
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'date_modified_to') ?? ''
      ) ?? null
  );
  const [createdByName, setCreatedByName] = useState<string | null>(
    () => getSearchQueryParam(location.search, 'created_by_name') ?? null
  );
  const [modifiedByName, setModifiedByName] = useState<string | null>(
    () => getSearchQueryParam(location.search, 'modified_by_name') ?? null
  );
  const [subsidiaryOf, setSubsidiaryOf] = useState(() =>
    subsidiaryOfGuard
      ? tryInt(getSearchQueryParam(location.search, 'direkcija_od')) ?? null
      : null
  );
  const [occupation, setOccupation] = useState<string | null>(
    () => getSearchQueryParam(location.search, 'dejnost_id') ?? null
  );

  useEffect(() => {
    if (!setPage) {
      return;
    }
    setPage(1);
  }, [
    dateCreatedFrom,
    dateCreatedTo,
    dateModifiedFrom,
    dateModifiedTo,
    setPage,
  ]);

  const { data: municipalityData } = useAxiosHook<
    { ime: string; id: string }[]
  >('/municipalities', {
    skipWhen: !municipalityGuard,
  });
  const { data: pricelistData } = useAxiosHook<PriceListCollection>(
    '/price-lists?limit=0',
    { skipWhen: !priceListGuard }
  );
  const { data: hqData } = useAxiosHook<{
    data: { ime: string; id: number }[];
  }>('/clients/hq/ref', { skipWhen: !subsidiaryOfGuard });
  const { data: occupations, isLoading: isOccupationsLoading } =
    useAxiosHook<any>('/occupations');

  const municipalityOptions = useMemo(
    () =>
      municipalityData?.map((m) => ({
        label: m.ime,
        value: m.id,
      })) ?? [],
    [municipalityData]
  );
  const pricelistOptions = useMemo<LabelValue<number>[]>(
    () =>
      pricelistData?.data.map((p) => ({
        label: p.name ?? '',
        value: p.id,
      })) ?? [],
    [pricelistData]
  );
  const clientTypeOptions = useMemo(
    () => [
      { label: t('Individual entity'), value: '0' },
      { label: t('Legal entity'), value: '1' },
    ],
    [t]
  );
  const clientStatusOptions = useMemo(
    () => [
      { label: t('Active'), value: UserStatus.Active },
      { label: t('Inactive'), value: UserStatus.Blocked },
      {
        label: t('Accepted, awaiting e-mail activation'),
        value: UserStatus.ActivationAccepted,
      },
      { label: t('Rejected'), value: UserStatus.ActivationRejected },
      { label: t('Pending'), value: UserStatus.ActivationRequest },
      { label: t('Deleted'), value: UserStatus.Delete },
      {
        label: t('Password reset request was sent'),
        value: UserStatus.PasswordReset,
      },
    ],
    [t]
  );
  const invoicingOptions = useMemo(
    () => [
      { label: t('Invoice'), value: '1' },
      { label: t('Cash'), value: '0' },
    ],
    [t]
  );
  const groupRoleOptions = useMemo(
    () => [
      {
        label: t('Headquarter'),
        value: ClientsCollectionGroupRoleFilter.Headquarter,
      },
      {
        label: t('Subsidiary'),
        value: ClientsCollectionGroupRoleFilter.Subsidiary,
      },
    ],
    [t]
  );
  const subsidiaryOfOptions = useMemo(
    () =>
      hqData?.data?.map((hq) => ({
        label: hq.ime,
        value: hq.id,
      })) ?? [],
    [hqData?.data]
  );
  const notificationTypeOptions = useMemo(
    () => getNotificationType(t).map((c) => ({ label: c.title, value: c.id })),
    [t]
  );
  const notificationChannelOptions = useMemo(
    () =>
      getNotificationChannel(t).map((c) => ({ label: c.title, value: c.id })),
    [t]
  );
  const notificationOptions = useMemo(
    () => [
      { label: t('Enabled'), value: IntBool.True },
      { label: t('Disabled'), value: IntBool.False },
    ],
    [t]
  );
  const occupationOptions = useMemo(
    () =>
      occupations?.map(
        (m: any) =>
          ({
            label: m.ime,
            value: m.id,
          } ?? [])
      ),
    [occupations]
  );
  const debouncedName = useDebounce(name, debounceTimeout);
  const debouncedPhone = useDebounce(phone, debounceTimeout);
  const debouncedEmail = useDebounce(email, debounceTimeout);
  const debouncedSocialSecurityNumber = useDebounce(
    socialSecurityNumber,
    debounceTimeout
  );
  const debouncedTaxNumber = useDebounce(taxNumber, debounceTimeout);
  const debouncedCreatedByName = useDebounce(createdByName, debounceTimeout);
  const debouncedModifiedByName = useDebounce(modifiedByName, debounceTimeout);

  const filtersArr = useMemo(
    () => [
      debouncedName,
      debouncedPhone,
      debouncedEmail,
      priceId,
      clientType,
      clientStatus,
      invoicing,
      municipalityId,
      groupRole,
      subsidiaryOf,
      notificationTypeId,
      notificationChannelId,
      debouncedSocialSecurityNumber,
      debouncedTaxNumber,
      codEmailWarrant,
      invoiceEmail,
      dateCreatedFrom,
      dateCreatedTo,
      dateModifiedFrom,
      dateModifiedTo,
      debouncedCreatedByName,
      debouncedModifiedByName,
      occupation,
    ],
    [
      debouncedName,
      debouncedPhone,
      debouncedEmail,
      priceId,
      clientType,
      clientStatus,
      invoicing,
      municipalityId,
      groupRole,
      subsidiaryOf,
      notificationTypeId,
      notificationChannelId,
      debouncedSocialSecurityNumber,
      debouncedTaxNumber,
      codEmailWarrant,
      invoiceEmail,
      dateCreatedFrom,
      dateCreatedTo,
      dateModifiedFrom,
      dateModifiedTo,
      debouncedCreatedByName,
      debouncedModifiedByName,
      occupation,
    ]
  );

  useSearchQueryParam('ime', debouncedName);
  useSearchQueryParam('phone', debouncedPhone);
  useSearchQueryParam('email', debouncedEmail);
  useSearchQueryDropdownParam(
    'cenovnik_id',
    priceId,
    setPriceId,
    pricelistOptions
  );
  useSearchQueryDropdownParam(
    'pravno_lice',
    clientType,
    setClientType,
    clientTypeOptions
  );
  useSearchQueryDropdownParam(
    'status_id',
    clientStatus,
    setClientStatus,
    clientStatusOptions
  );
  useSearchQueryDropdownParam(
    'faktura',
    invoicing,
    setInvoicing,
    invoicingOptions
  );
  useSearchQueryDropdownParam(
    'opstina_id',
    municipalityId,
    setMunicipalityId,
    municipalityOptions
  );
  useSearchQueryDropdownParam(
    'group_role',
    groupRole,
    setGroupRole,
    groupRoleOptions
  );
  useSearchQueryDropdownParam(
    'direkcija_id',
    subsidiaryOf,
    setSubsidiaryOf,
    subsidiaryOfOptions
  );
  useSearchQueryMultiSelectParam('notification_type_id', notificationTypeId);
  useSearchQueryMultiSelectParam(
    'notification_channel_id',
    notificationChannelId
  );
  useSearchQueryParam('socialSecurityNumber', debouncedSocialSecurityNumber);
  useSearchQueryParam('taxNumber', debouncedTaxNumber);
  useSearchQueryDropdownParam(
    'cod_email_warrant',
    codEmailWarrant,
    setCodEmailWarrant,
    notificationOptions
  );
  useSearchQueryDropdownParam(
    'invoice_email',
    invoiceEmail,
    setInvoiceEmail,
    notificationOptions
  );
  useSearchQueryDropdownParam(
    'dejnost_id',
    occupation,
    setOccupation,
    occupationOptions
  );
  useSearchQueryDateParam('date_created_from', dateCreatedFrom);
  useSearchQueryDateParam('date_created_to', dateCreatedTo);
  useSearchQueryDateParam('date_modified_from', dateModifiedFrom);
  useSearchQueryDateParam('date_modified_to', dateModifiedTo);
  useSearchQueryParam('created_by_name', debouncedCreatedByName);
  useSearchQueryParam('modified_by_name', debouncedModifiedByName);

  const basicFiltersActiveFilterCount = Object.values({
    name,
    phone,
    email,
    priceId,
    clientType,
    clientStatus,
    invoicing,
    municipalityId,
    groupRole,
    subsidiaryOf,
    notificationTypeId,
    notificationChannelId,
  }).filter(Boolean).length;

  const orderAttributesFiltersActiveFilterCount = Object.values({
    codEmailWarrant,
    invoiceEmail,
    dateCreatedFrom, // dateCreatedFrom and dateCreatedTo is count like one
    dateModifiedFrom, // dateModifiedFrom and dateModifiedTo is count like one
    createdByName,
    modifiedByName,
    occupation,
  }).filter(Boolean).length;

  useEffect(() => {
    if (!municipalityGuard) {
      setMunicipalityId(null);
    }
  }, [municipalityGuard]);
  useEffect(() => {
    if (!priceListGuard) {
      setPriceId(null);
    }
  }, [priceListGuard]);
  useEffect(() => {
    if (!subsidiaryOfGuard) {
      setSubsidiaryOf(null);
    }
  }, [subsidiaryOfGuard]);

  const haveFiltersChanged = useHaveValuesChanged(filtersArr);

  const httpFiltersObj = useMemo(() => {
    return {
      ime: debouncedName,
      phone: debouncedPhone,
      email: debouncedEmail,
      cenovnik_id: priceId,
      pravno_lice: clientType,
      status_id: clientStatus,
      faktura: invoicing,
      opstina_id: municipalityId,
      group_role: groupRole,
      direkcija_id: subsidiaryOf,
      notification_type_id: notificationTypeId,
      notification_channel_id: notificationChannelId,
      maticen_broj: debouncedSocialSecurityNumber,
      danocen_broj: debouncedTaxNumber,
      cod_email_warrant: codEmailWarrant,
      invoice_email: invoiceEmail,
      date_created_from: dateCreatedFrom,
      date_created_to: dateCreatedTo,
      date_modified_from: dateModifiedFrom,
      date_modified_to: dateModifiedTo,
      created_by_name: debouncedCreatedByName,
      modified_by_name: debouncedModifiedByName,
      dejnost_id: occupation,
      page: haveFiltersChanged ? 1 : page,
      limit,
      // summary: 1,
    };
  }, [
    clientStatus,
    clientType,
    debouncedEmail,
    debouncedName,
    debouncedPhone,
    debouncedSocialSecurityNumber,
    debouncedTaxNumber,
    groupRole,
    haveFiltersChanged,
    invoicing,
    limit,
    municipalityId,
    notificationChannelId,
    notificationTypeId,
    priceId,
    codEmailWarrant,
    debouncedCreatedByName,
    dateCreatedFrom,
    dateCreatedTo,
    dateModifiedFrom,
    dateModifiedTo,
    subsidiaryOf,
    invoiceEmail,
    debouncedModifiedByName,
    occupation,
    page,
  ]);

  const filters = useMemo<JSX.Element>(
    () => (
      <Accordion multiple activeIndex={[0]}>
        <AccordionTab
          disabled
          headerTemplate={
            <FiltersCounter
              description={t('Basic filters')}
              counter={basicFiltersActiveFilterCount}
            />
          }
        >
          <div className="sidebar_filter">
            <label htmlFor="filter_name">{t('Name')}</label>
            <InputText
              id="filter_name"
              value={name ?? ''}
              onChange={(e) => setName(e.target.value)}
              className={classNames({
                sidebar_filter_active: name,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="filter_phone">{t('Phone')}</label>
            <InputText
              id="filter_phone"
              value={phone ?? ''}
              onChange={(e) => setPhone(e.target.value)}
              className={classNames({
                sidebar_filter_active: phone,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="filter_email">{t('Email')}</label>
            <InputText
              id="filter_email"
              value={email ?? ''}
              onChange={(e) => setEmail(e.target.value)}
              className={classNames({
                sidebar_filter_active: email,
              })}
            />
          </div>
          {municipalityGuard && (
            <div className="sidebar_filter">
              <label htmlFor="filter_municipality">{t('Municipality')}</label>
              <Dropdown
                id="filter_municipality"
                value={municipalityId}
                options={municipalityOptions}
                onChange={(e: DropdownChangeParams) =>
                  setMunicipalityId(e.value)
                }
                showClear
                filter
                className={classNames({
                  sidebar_filter_active: municipalityId,
                })}
              />
            </div>
          )}
          {priceListGuard && (
            <div className="sidebar_filter">
              <label htmlFor="filter_pricelist">{t('Pricelist')}</label>
              <Dropdown
                id="filter_pricelist"
                value={priceId}
                options={pricelistOptions}
                onChange={(e: DropdownChangeParams) => setPriceId(e.value)}
                showClear
                filter
                className={classNames({
                  sidebar_filter_active: priceId,
                })}
              />
            </div>
          )}
          <div className="sidebar_filter">
            <label htmlFor="filter_client_type">{t('Type')}</label>
            <Dropdown
              id="filter_client_type"
              value={clientType}
              options={clientTypeOptions}
              onChange={(e: DropdownChangeParams) => setClientType(e.value)}
              showClear
              className={classNames({
                sidebar_filter_active: clientType,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="filter_client_status">{t('Status')}</label>
            <Dropdown
              id="filter_client_status"
              value={clientStatus}
              options={clientStatusOptions}
              onChange={(e: DropdownChangeParams) => setClientStatus(e.value)}
              showClear
              className={classNames({
                sidebar_filter_active: clientStatus,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="filter_invoicing">{t('Payment')}</label>
            <Dropdown
              id="filter_invoicing"
              value={invoicing}
              options={invoicingOptions}
              onChange={(e: DropdownChangeParams) => setInvoicing(e.value)}
              showClear
              className={classNames({
                sidebar_filter_active: invoicing,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="filter_group_role">{t('Group role')}</label>
            <Dropdown
              id="filter_group_role"
              value={groupRole}
              options={groupRoleOptions}
              onChange={(e: DropdownChangeParams) => {
                setGroupRole(e.value);
                if (e.value !== null && subsidiaryOf !== null) {
                  setSubsidiaryOf(null);
                }
              }}
              showClear
              disabled={!!subsidiaryOf}
              className={classNames({
                sidebar_filter_active: groupRole,
              })}
            />
          </div>
          {subsidiaryOfGuard && (
            <div className="sidebar_filter">
              <label htmlFor="direkcija_od">{t('Subsidiary of')}</label>
              <Dropdown
                id="direkcija_od"
                value={subsidiaryOf}
                options={subsidiaryOfOptions}
                onChange={(e: DropdownChangeParams) => {
                  setSubsidiaryOf(e.value);
                  if (e.value !== null && groupRole !== null) {
                    setGroupRole(null);
                  }
                }}
                showClear
                filter
                disabled={!!groupRole}
                className={classNames({
                  sidebar_filter_active: subsidiaryOf,
                })}
              />
            </div>
          )}
          <div className="sidebar_filter">
            <label htmlFor="filter_notification_type">
              {t('Notification type')}
            </label>
            <MultiSelect
              inputId="filter_notification_type"
              value={notificationTypeId}
              options={notificationTypeOptions}
              maxSelectedLabels={0}
              selectedItemsLabel={t('{0} selected')}
              filter
              showClear
              onChange={(e) => {
                setNotificationTypeId(e.value);
              }}
              className={classNames({
                sidebar_filter_active: notificationTypeId,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="filter_notification_channel">
              {t('Notification channel')}
            </label>
            <MultiSelect
              inputId="filter_notification_channel"
              value={notificationChannelId}
              options={notificationChannelOptions}
              maxSelectedLabels={0}
              selectedItemsLabel={t('{0} selected')}
              filter
              showClear
              onChange={(e) => {
                setNotificationChannelId(e.value);
                if (e.value !== null && groupRole !== null) {
                  setGroupRole(null);
                }
              }}
              className={classNames({
                sidebar_filter_active: notificationTypeId,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="filter_ssn">{t('Social Security No.')}</label>
            <InputText
              id="filter_ssn"
              value={socialSecurityNumber ?? ''}
              onChange={(e) => setSocialSecurityNumber(e.target.value)}
              className={classNames({
                sidebar_filter_active: socialSecurityNumber,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="filter_tax_no">{t('Tax No.')}</label>
            <InputText
              id="filter_tax_no"
              value={taxNumber ?? ''}
              onChange={(e) => setTaxNumber(e.target.value)}
              className={classNames({
                sidebar_filter_active: taxNumber,
              })}
            />
          </div>
        </AccordionTab>
        <AccordionTab
          headerTemplate={
            <FiltersCounter
              description={t('Additional Filters')}
              counter={orderAttributesFiltersActiveFilterCount}
            />
          }
        >
          <div className="sidebar_filter">
            <label htmlFor="cod_email_warrant">
              {t('Automated sending of COD warrant')}
            </label>
            <Dropdown
              id="cod_email_warrant"
              value={codEmailWarrant}
              options={notificationOptions}
              onChange={(e: DropdownChangeParams) =>
                setCodEmailWarrant(e.value)
              }
              showClear
              className={classNames({
                sidebar_filter_active: codEmailWarrant,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="invoice_email">
              {t('Automated sending invoice copy on email')}
            </label>
            <Dropdown
              id="invoice_email"
              value={invoiceEmail}
              options={notificationOptions}
              onChange={(e: DropdownChangeParams) => setInvoiceEmail(e.value)}
              showClear
              className={classNames({
                sidebar_filter_active: invoiceEmail,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="date_created_from">{t('Creation Date')}</label>
            <div className="sidebar_filter_row">
              <Calendar
                id="date_created_from"
                value={dateCreatedFrom as unknown as Date}
                onChange={(e) => {
                  setDateCreatedFrom(e.value as Date | null);
                  if (!dateCreatedTo) {
                    setDateCreatedTo(e.value as Date);
                  }
                }}
                dateFormat="dd/mm/yy"
                maxDate={new Date()}
                monthNavigator
                yearNavigator
                yearRange={`2009:${dayjs().format('YYYY')}`}
                inputClassName={classNames({
                  sidebar_filter_active: dateCreatedFrom,
                })}
              />
              <span style={{ margin: 'auto' }}>{renderIcon(faMinus)}</span>
              <Calendar
                id="date_created_to"
                value={dateCreatedTo as unknown as Date}
                dateFormat="dd/mm/yy"
                maxDate={new Date()}
                monthNavigator
                yearNavigator
                yearRange={`2009:${dayjs().format('YYYY')}`}
                inputClassName={classNames({
                  sidebar_filter_active: dateCreatedTo,
                })}
              />
            </div>
          </div>
          <div className="sidebar_filter">
            <label htmlFor="date_modified_from">{t('Modification Date')}</label>
            <div className="sidebar_filter_row">
              <Calendar
                id="date_modified_from"
                value={dateModifiedFrom as unknown as Date}
                onChange={(e) => {
                  setDateModifiedFrom(e.value as Date | null);
                  if (!dateModifiedTo) {
                    setDateModifiedTo(e.value as Date);
                  }
                }}
                dateFormat="dd/mm/yy"
                maxDate={new Date()}
                monthNavigator
                yearNavigator
                yearRange={`2009:${dayjs().format('YYYY')}`}
                inputClassName={classNames({
                  sidebar_filter_active: dateModifiedFrom,
                })}
              />
              <span style={{ margin: 'auto' }}>{renderIcon(faMinus)}</span>
              <Calendar
                id="date_modified_to"
                value={dateModifiedTo as unknown as Date}
                dateFormat="dd/mm/yy"
                maxDate={new Date()}
                monthNavigator
                yearNavigator
                yearRange={`2009:${dayjs().format('YYYY')}`}
                inputClassName={classNames({
                  sidebar_filter_active: dateModifiedTo,
                })}
              />
            </div>
          </div>
          <div className="sidebar_filter">
            <label htmlFor="created_by_name">{t('Created by')}</label>
            <InputText
              id="created_by_name"
              value={createdByName ?? ''}
              onChange={(e) => setCreatedByName(e.target.value)}
              className={classNames({
                sidebar_filter_active: createdByName,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="modified_by_name">{t('Modified by')}</label>
            <InputText
              id="modified_by_name"
              value={modifiedByName ?? ''}
              onChange={(e) => setModifiedByName(e.target.value)}
              className={classNames({
                sidebar_filter_active: modifiedByName,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="modified_by_name">{t('Occupation')}</label>
            <Dropdown
              id="dejnost_id"
              name="dejnost_id"
              showClear
              options={occupationOptions}
              value={occupation}
              onChange={(e) => setOccupation(e.value)}
              disabled={isOccupationsLoading}
              className={classNames({
                sidebar_filter_active: occupation,
              })}
            />
          </div>
        </AccordionTab>
      </Accordion>
    ),
    [
      createdByName,
      modifiedByName,
      codEmailWarrant,
      notificationOptions,
      clientStatus,
      clientStatusOptions,
      clientType,
      clientTypeOptions,
      email,
      groupRole,
      groupRoleOptions,
      invoicing,
      invoicingOptions,
      municipalityId,
      municipalityGuard,
      municipalityOptions,
      name,
      notificationChannelId,
      notificationChannelOptions,
      notificationTypeId,
      notificationTypeOptions,
      phone,
      priceListGuard,
      pricelistOptions,
      socialSecurityNumber,
      subsidiaryOf,
      subsidiaryOfGuard,
      subsidiaryOfOptions,
      basicFiltersActiveFilterCount,
      orderAttributesFiltersActiveFilterCount,
      priceId,
      dateCreatedFrom,
      dateCreatedTo,
      dateModifiedFrom,
      dateModifiedTo,
      occupationOptions,
      isOccupationsLoading,
      occupation,
      t,
      taxNumber,
    ]
  );

  const headerFiltersCount = useMemo(
    () =>
      basicFiltersActiveFilterCount + orderAttributesFiltersActiveFilterCount,
    [basicFiltersActiveFilterCount, orderAttributesFiltersActiveFilterCount]
  );

  function resetAllFilters() {
    setName('');
    setPhone('');
    setEmail('');
    setInvoicing(null);
    setClientType(null);
    setClientStatus(null);
    setPriceId(null);
    setMunicipalityId(null);
    setGroupRole(null);
    setSubsidiaryOf(null);
    setNotificationTypeId(null);
    setNotificationChannelId(null);
    setSocialSecurityNumber('');
    setTaxNumber('');
    setCodEmailWarrant(null);
    setInvoiceEmail(null);
    setDateCreatedFrom(null);
    setDateCreatedTo(null);
    setDateModifiedFrom(null);
    setDateModifiedTo(null);
    setCreatedByName('');
    setModifiedByName('');
  }

  return {
    filters,
    resetAllFilters,
    httpFiltersObj,
    headerFiltersCount,
  };
}

export default useTableFilters;
