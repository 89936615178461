import { Divider } from 'primereact/divider';
import { TFunction } from 'react-i18next';

export function passwordFeedback(t: TFunction) {
  return (
    <div className="p-mb-2">
      <Divider />
      <p className="p-mb-2">{t('Suggestions')}</p>
      <div className="p-pl-2">
        - {t('At least one lowercase or uppercase letter')}
        <br />- {t('At least one number')}
        <br />- {t('Minimum 8 characters')}
        <br />- {t('At least one special character (. ! @ * # $ ( ) % ^ &)')}
      </div>
    </div>
  );
}

// Characters have to be properly escaped, ESLint is wrong here
/* eslint-disable no-useless-escape */
export const mediumStrenthRegex =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[.@$!%*#&^()]).{8,}$/;

export const strongStrenthRegex =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[.@$!%*#&^()]).{12,}$/;
/* eslint-enable */
