import './AddEditRegionDialog.scss';

import { Form, Formik, FormikProps } from 'formik';
import { Map } from 'leaflet';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useAxiosHook from '../../../../../../hooks/useAxiosHook';
import useMediaQuery from '../../../../../../hooks/useMediaQuery';
import usePrevious from '../../../../../../hooks/usePrevious';
import { Region } from '../../../Regions.functions';
import {
  FormFields,
  getInitialValues,
  getValidationSchema,
  toApiData,
} from './AddEditRegionDialog.functions';
import DialogForm from './DialogForm';

type Props = {
  isShown: boolean;
  regions: Region[];
  selectedRegion: Region | undefined;
  isEditDialog: boolean;
  onHide: () => void;
  onCreateSubmit: (name: string) => void;
  onEditSubmit: (name: string) => void;
  onError: (name: string) => void;
};

function AddEditRegionDialog({
  isShown,
  regions,
  selectedRegion,
  isEditDialog,
  onHide,
  onEditSubmit,
  onCreateSubmit,
  onError,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const formRef = useRef<FormikProps<any>>(null);
  const [map, setMap] = useState<Map>();

  const { data, error, isLoading, reload } = useAxiosHook();
  const prevData = usePrevious(data);
  const prevError = usePrevious(error);

  const regionName = useRef<string>();

  useEffect(() => {
    if (!data || data === prevData) {
      return;
    }

    if (isEditDialog) {
      onEditSubmit(regionName.current || '');
    } else {
      onCreateSubmit(regionName.current || '');
    }
  }, [data, isEditDialog, onCreateSubmit, onEditSubmit, prevData]);

  useEffect(() => {
    if (!error || error === prevError) {
      return;
    }

    onError(regionName.current || '');
  }, [error, onError, prevError]);

  function handleFormSubmision(values: FormFields) {
    const regionObj = toApiData(values, map);
    regionName.current = values.name;

    if (isEditDialog) {
      reload({
        url: `regions/${selectedRegion?.region_id}`,
        data: regionObj,
        method: 'PUT',
      });
    } else {
      reload({
        url: '/regions',
        data: regionObj,
        method: 'POST',
      });
    }
  }

  const dialogHeader = isEditDialog
    ? t('Editing - {{selectedRegion}}', {
        selectedRegion: selectedRegion?.name,
      })
    : t('Create a Region');
  const dialogFooter = (
    <>
      <Button
        type="button"
        label={t('Cancel')}
        className="p-button-text"
        onClick={() => onHide()}
      />

      <Button
        type="button"
        label={
          isLoading
            ? t('Saving...')
            : isEditDialog
            ? t('Save changes')
            : t('Create')
        }
        disabled={isLoading}
        onClick={() => {
          if (formRef.current) {
            formRef.current.handleSubmit();
          }
        }}
        data-cy="submit-btn"
      />
    </>
  );

  const isOnMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Dialog
      visible={isShown}
      onHide={onHide}
      header={dialogHeader}
      footer={dialogFooter}
      resizable={false}
      maximizable
      maximized={isOnMobile}
      className="regions-add-edit-region-dialog"
    >
      <Formik
        innerRef={formRef}
        initialValues={getInitialValues(selectedRegion, isEditDialog)}
        validationSchema={getValidationSchema(t, map)}
        onSubmit={handleFormSubmision}
      >
        <Form>
          <DialogForm
            isEditDialog={isEditDialog}
            selectedRegion={selectedRegion}
            allRegions={regions}
            map={map}
            setMap={setMap}
          />
        </Form>
      </Formik>
    </Dialog>
  );
}

export default AddEditRegionDialog;
