import '../CreateEditDialog/CreateEditDialog.scss';
import './GroupEditDialog.scss';

import { Formik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { TabPanel, TabView } from 'primereact/tabview';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import useAxiosHook from '../../../../../hooks/useAxiosHook';
import useMediaQuery from '../../../../../hooks/useMediaQuery';
import { WithData } from '../../../../../types/api';
import { PriceListCollection } from '../../../../../types/api/priceLists';
import NotificationsTab from '../CreateEditDialog/Tabs/Fields/NotificationsTab/NotificationsTab';
import { getInitialValues } from './GroupEditDialog.functions';
import PaymentsTab from './Tabs/PaymentsTab';

type Props = {
  visible: boolean;
  onHide: () => void;
};

function GroupEditDialog({ visible, onHide }: Props): JSX.Element {
  const { t } = useTranslation();

  const formRef = useRef<any>(null);

  const { data: defaultNotificationsData } = useAxiosHook<WithData<any[]>>(
    '/notifications/default'
  );

  const defaultNotifications = useMemo(() => {
    if (!(defaultNotificationsData?.data?.length! > 0)) {
      return;
    }

    let returnObj: any = {};

    for (const n of defaultNotificationsData!.data) {
      returnObj[`${n.event}-${n.actor}-${n.channel}`] = n;
    }

    return returnObj;
  }, [defaultNotificationsData]);

  const { data: smsGateways } = useAxiosHook('/clients/sms/gateways');

  const smsGatewayOptions = useMemo(
    () => [
      { label: t("Don't notify"), value: '' },
      { label: t('Default Provider'), value: 'default' },
      ...(Array.isArray(smsGateways) ? smsGateways : [])
        .filter((g) => typeof g === 'string')
        .map((g) => ({ label: g, value: g })),
    ],
    [smsGateways, t]
  );

  const { data: priceListsData, isLoading: arePriceListsLoading } =
    useAxiosHook<PriceListCollection>('/price-lists?limit=0');

  const isOnMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Dialog
      header={t('Client Group Edit')}
      footer={
        <>
          <Button
            type="button"
            label={t('Cancel')}
            className="p-button-secondary p-button-outlined"
            onClick={onHide}
          />
          <Button
            type="button"
            label={t('Save')}
            onClick={() => {
              if (formRef.current) {
                formRef.current.handleSubmit();
              }
            }}
          />
        </>
      }
      visible={visible}
      onHide={onHide}
      maximized={isOnMobile}
      maximizable
      className="clients-group-edit-dialog"
    >
      <div>
        <Formik
          innerRef={formRef}
          initialValues={getInitialValues(t, defaultNotifications)}
          onSubmit={(values) => console.log(values)}
          enableReinitialize
        >
          <TabView>
            <TabPanel header={t('Payments')}>
              <PaymentsTab
                priceListsData={priceListsData}
                arePriceListsLoading={arePriceListsLoading}
              />
            </TabPanel>

            <TabPanel header={t('Notifications')}>
              <NotificationsTab
                defaultNotifications={defaultNotifications}
                smsGatewayOptions={smsGatewayOptions}
              />
            </TabPanel>
          </TabView>
        </Formik>
      </div>
    </Dialog>
  );
}

export default GroupEditDialog;
