import { noColumnStyle } from '../../../utils/constants/tables';
import { contextMenuModel } from '../../../utils/helpers/primereact';

export const tableStorageKey = 'listTypes_dataTable';

export function getColumnHeadersMap(t) {
  return {
    no: t('No.'),
    id: t('ID'),
    name: t('Name'),
  };
}

export function additionalColumnProperties(column) {
  switch (column) {
    case 'no':
      return noColumnStyle;

    case 'id':
      return {
        style: {
          width: 80,
        },
        bodyStyle: {
          width: 80,
        },
      };

    case 'name':
      return {
        style: {
          width: 'auto',
          minWidth: 350,
        },
        bodyStyle: {
          width: 'auto',
          minWidth: 350,
        },
      };

    default:
      return {};
  }
}

export function generateContextMenu(
  t,
  isViewShown,
  handleView,
  isEditShown,
  handleEdit,
  isExportToExcelShown,
  handleExportToExcel,
  isExportToCsvShown,
  handleExportToCsv
) {
  const viewOption = {
    label: t('View'),
    icon: 'fas fa-search',
    command: handleView,
  };

  const editOption = {
    label: t('Edit'),
    icon: 'fas fa-edit',
    command: handleEdit,
  };

  const exportToExcelOption = {
    label: t('Export to Excel'),
    icon: 'fas fa-file-excel',
    command: handleExportToExcel,
  };

  const exportToCsvOption = {
    label: t('Export to CSV'),
    icon: 'fas fa-file-csv',
    command: handleExportToCsv,
  };

  return contextMenuModel([
    [[isViewShown, viewOption]],
    [[isEditShown, editOption]],
    [
      [isExportToExcelShown, exportToExcelOption],
      [isExportToCsvShown, exportToCsvOption],
    ],
  ]);
}

export function sortFields(fields) {
  if (!fields) {
    return [];
  }

  return [...fields].sort((a, b) => a.index - b.index);
}
