import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import useAxiosHook from '../../../../../../../../../hooks/useAxiosHook';
import useMediaQuery from '../../../../../../../../../hooks/useMediaQuery';
import useToastMessage from '../../../../../../../../../hooks/useToastMessage';
import { sequential } from '../../../../../../../../../utils/helpers/functions';
import { Row } from '../../ReturnedShipments.functions';
import {
  FormFields,
  getDefaultValues,
  toApiData,
} from './EditDialog.functions';
import FormContent from './FormContent';

type Props = {
  courierWorkOrderId: number | undefined;
  selection: Row | null;
  visible: boolean;
  onHide: () => void;
  tableReload: () => void;
};

function EditDialog({
  courierWorkOrderId,
  selection,
  visible,
  onHide,
  tableReload,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const { data, error, isLoading, reload } = useAxiosHook();

  useToastMessage(data, error, {
    success: {
      summary: t('Successfully updated the data for the returned order.'),
      callback: () => sequential(tableReload, onHide),
    },
    error: {
      summary: t(
        'An error occured while trying to update the data for the returned order.'
      ),
      callback: onHide,
    },
  });

  const defaultValues = useMemo(() => getDefaultValues(selection), [selection]);

  const methods = useForm<FormFields>({
    defaultValues,
  });

  const { reset, handleSubmit } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  function handleFormSubmission(values: FormFields) {
    reload({
      url: `/orders/courier/reception/returned/${selection?.order_id}`,
      method: 'PUT',
      data: toApiData(values, courierWorkOrderId!),
    });
  }

  const dialogFooter = (
    <>
      <Button
        type="button"
        label={t('Cancel')}
        onClick={onHide}
        className="p-button-secondary p-button-text"
      />

      <Button
        type="submit"
        label={isLoading ? t('Saving...') : t('Save')}
        disabled={isLoading}
        form="edit-returned-shipment-dialog"
      />
    </>
  );

  const isOnMobile = useMediaQuery('(max-width: 768px)');

  const header = t('Edit {{name}}', { name: selection?.serial_number ?? '' });

  return (
    <Dialog
      header={header}
      footer={dialogFooter}
      visible={visible}
      onHide={onHide}
      maximizable={isOnMobile}
      maximized={isOnMobile}
      style={{ width: 360, maxWidth: '100%' }}
    >
      <FormProvider {...methods}>
        <form
          id="edit-returned-shipment-dialog"
          onSubmit={handleSubmit(handleFormSubmission)}
        >
          <FormContent />
        </form>
      </FormProvider>
    </Dialog>
  );
}

export default EditDialog;
