import { useEffect, useState } from 'react';

const minNumberOfChars = 6;
const maxTimeBetweenChars = 100;

function useBarcodeScannerDetection(): string {
  const [value, setValue] = useState<string>('');

  useEffect(() => {
    let valueCandidate = '';
    let lastInputTime: number | undefined;
    let candidateClearTimeout: NodeJS.Timeout | undefined;

    function resetOrSetValueCandidate() {
      const trimmedCandidate = valueCandidate.trim();

      valueCandidate = '';

      if (trimmedCandidate.length >= minNumberOfChars) {
        setValue(trimmedCandidate);
      }
    }

    function keyDownListener(e: KeyboardEvent) {
      if (process.env.NODE_ENV !== 'test' && e.target !== document.body) {
        return;
      }

      // e.key === 'Enter' because if you click on the barcode scanner multiple times
      //  it concatenates the strings and you end up with an invalid barcode.
      if (
        lastInputTime === undefined ||
        Date.now() - lastInputTime >= maxTimeBetweenChars ||
        e.key === 'Enter'
      ) {
        resetOrSetValueCandidate();
      }

      // Filters out non-character keys such as meta, alt, shift, arrows etc
      if (e.key.length === 1) {
        valueCandidate += e.key;
      }

      lastInputTime = Date.now();

      if (candidateClearTimeout !== undefined) {
        clearTimeout(candidateClearTimeout);
      }

      candidateClearTimeout = setTimeout(() => {
        resetOrSetValueCandidate();
      }, maxTimeBetweenChars);
    }

    function inputEventListener(e: Event) {
      if (process.env.NODE_ENV !== 'test' && e.target !== document.body) {
        return;
      }

      const value = (e as InputEvent).data?.trim() ?? '';

      if (value.length >= minNumberOfChars) {
        setValue(value);
      }
    }

    function pasteEventListener(e: ClipboardEvent) {
      // Jest doesn't work with document.body, hence the first condition
      if (
        process.env.NODE_ENV !== 'test' &&
        e.currentTarget !== document.body
      ) {
        return;
      }

      const value =
        (e.clipboardData || (window as any).clipboardData)
          ?.getData('text/plain')
          ?.trim() ?? '';

      if (typeof value === 'string' && value.length >= minNumberOfChars) {
        setValue(value);
      }
    }

    document.addEventListener('keydown', keyDownListener);
    document.addEventListener('input', inputEventListener);
    document.addEventListener('paste', pasteEventListener);

    return () => {
      document.removeEventListener('keydown', keyDownListener);
      document.removeEventListener('input', inputEventListener);
      document.removeEventListener('paste', pasteEventListener);

      if (candidateClearTimeout !== undefined) {
        clearTimeout(candidateClearTimeout);
      }
    };
  }, []);

  return value;
}

export default useBarcodeScannerDetection;
