import {
  addClientResourceGuard,
  clientHeadquartersRefCollectionGuard,
  clientLookupCollectionGuard,
  clientResourceGuard,
  editClientResourceGuard,
  forgotPasswordGuard,
  mergeClientsGuard,
  reinitializeClientActivationGuard,
  smsGetawayCollectionGuard,
  updateClientStatusGuard,
} from './_api/clients';
import { configCollectionGuard } from './_api/configs';
import { importListTypeCollectionGuard } from './_api/importLists';
import {
  municipalityCollectionGuard,
  placeCollectionGuard,
} from './_api/municipalities';
import { occupationCollectionGuard } from './_api/occupations';
import { placeResourceGuard, placesStreetCollectionGuard } from './_api/places';
import { priceListCollectionGuard } from './_api/priceLists';
import { notificationsCollectionGuard } from './notifications';

export const municipalityFilter = municipalityCollectionGuard;
export const pricelistFilter = priceListCollectionGuard;
export const subsidiaryOfFilter = clientHeadquartersRefCollectionGuard;

export const readClient = [
  clientResourceGuard,
  placeResourceGuard,
  municipalityCollectionGuard,
  placesStreetCollectionGuard,
  occupationCollectionGuard,
  priceListCollectionGuard,
  clientHeadquartersRefCollectionGuard,
];

const createEditCommon = [
  clientResourceGuard,
  importListTypeCollectionGuard,
  priceListCollectionGuard,
  placeResourceGuard,
  municipalityCollectionGuard,
  placeCollectionGuard,
  placesStreetCollectionGuard,
  occupationCollectionGuard,
  clientHeadquartersRefCollectionGuard,
  smsGetawayCollectionGuard,
  notificationsCollectionGuard,
  configCollectionGuard,
];

export const createClient = [...createEditCommon, addClientResourceGuard];
export const editClient = [...createEditCommon, editClientResourceGuard];

export const mergeClients = mergeClientsGuard;

export const statusChange = updateClientStatusGuard;
export const resendEmail = reinitializeClientActivationGuard;
export const forgotPassword = forgotPasswordGuard;

export const routeGuard = [clientLookupCollectionGuard];
