import './VirtualizedReactTable.scss';

import {
  faSort,
  faSortDown,
  faSortUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import {
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Row, TableInstance } from 'react-table';
import { useVirtual } from 'react-virtual';

import useVirtualizedReactTableHeight from '../../../hooks/useVirtualizedReactTableHeight';
import Spinner from '../../indicator/Spinner/Spinner';
import ContextMenu from '../../menu/ContextMenu/ContextMenu';
import { ContextMenuModel } from '../../menu/ContextMenu/ContextMenu.types';
import Tooltip from '../../tooltip/Tooltip/Tooltip';

type Props<T extends object> = {
  tableInstance: TableInstance<T>;
  header?: ReactNode;
  reload?: () => void;
  isLoading?: boolean;
  exportToExcel?: () => void;
  footer?: ReactNode;
  rowSelection?: boolean | ((row: Row<T>) => void);
  multiSelect?: boolean;
  additionalRowClassNames?: (row: Row<T>) => string;
  heightAddition?: number;
  containerRef?: RefObject<HTMLDivElement>;
  contextMenuModel?: ContextMenuModel;
  onRowDoubleClick?: (row: Row<T>) => void;
};

function VirtualizedReactTable<T extends object>({
  tableInstance,
  header,
  reload,
  isLoading,
  exportToExcel,
  footer,
  rowSelection = true,
  multiSelect = false,
  additionalRowClassNames,
  heightAddition = 0,
  containerRef,
  contextMenuModel,
  onRowDoubleClick,
}: Props<T>): JSX.Element {
  const { t } = useTranslation();

  const rootElementRef = useRef<HTMLElement>(null);
  const tbodyRef = useRef<HTMLDivElement>(null);
  const localContainerRef = useRef<HTMLDivElement>(null);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setContextMenuSelection,
  } = tableInstance;

  const dataLength = tableInstance.flatRows.length;

  const rowVirtualizer = useVirtual({
    size: dataLength,
    parentRef: containerRef ?? localContainerRef,
    estimateSize: useCallback(() => 38, []),
    overscan: 10,
  });

  const containerHeight =
    useVirtualizedReactTableHeight(rootElementRef) + heightAddition - 38;

  const multiSelectFooterFallback = useMemo(
    () => (
      <span>
        {t('Selected: {{selected}}', {
          selected: Object.keys(tableInstance.selectedFlatRows).length,
          nsSeparator: false,
        })}
      </span>
    ),
    [tableInstance.selectedFlatRows, t]
  );

  useEffect(() => {
    function handleClick(e: MouseEvent) {
      if (
        e.target instanceof Element &&
        ((e.target.contains(rootElementRef.current) &&
          !e.target.matches('menu, menu *')) ||
          !e.target.contains(rootElementRef.current))
      ) {
        setContextMenuSelection(undefined);
      }
    }

    function handleContextMenu(e: MouseEvent) {
      if (e.target instanceof Element && !e.target.matches('.tr, .tr *')) {
        setContextMenuSelection(undefined);
      }
    }

    document.addEventListener('click', handleClick);
    document.addEventListener('contextmenu', handleContextMenu);

    return () => {
      document.removeEventListener('click', handleClick);
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, [setContextMenuSelection]);

  return (
    <section ref={rootElementRef} className="react-table">
      {contextMenuModel && (
        <ContextMenu
          target={tbodyRef}
          model={contextMenuModel}
          onHide={() => setContextMenuSelection(undefined)}
        />
      )}

      <div className="table-wrapper">
        <div className="table-header p-d-flex p-ai-center p-jc-between">
          {typeof header === 'string' ? <h3>{header}</h3> : header ?? <div />}

          <div>
            {reload && typeof reload === 'function' && (
              <Tooltip text={t('Reload')} position="top">
                <Button
                  icon="fas fa-sync-alt"
                  onClick={reload}
                  disabled={isLoading}
                  className="p-button-text p-button-plain p-mx-2"
                />
              </Tooltip>
            )}

            {exportToExcel && typeof exportToExcel === 'function' && (
              <Tooltip text={t('Export to Excel')} position="top">
                <Button
                  icon="fas fa-file-excel"
                  onClick={exportToExcel}
                  className="p-button-text p-button-plain p-mr-2"
                />
              </Tooltip>
            )}
          </div>
        </div>

        <div
          ref={containerRef ?? localContainerRef}
          className="table-container"
          style={{
            height: containerHeight,
            ...(isLoading ? { position: 'relative', overflow: 'hidden' } : {}),
          }}
        >
          {isLoading && (
            <div
              className="loading-indicator text-center"
              style={{
                paddingTop: containerHeight / 2 - 100,
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.325)',
                zIndex: 10,
              }}
            >
              <Spinner />

              <h2 style={{ color: 'white', fontWeight: 600 }}>
                {t('Loading...')}
              </h2>
            </div>
          )}

          <div className="table" {...getTableProps()}>
            <div className="thead">
              {headerGroups.map((headerGroup) => (
                <div {...headerGroup.getHeaderGroupProps()} className="tr">
                  {headerGroup.headers.map((column) => (
                    <div
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className="th"
                    >
                      {column.render('Header')}

                      {column.isSorted ? (
                        <span>
                          <FontAwesomeIcon
                            icon={column.isSortedDesc ? faSortDown : faSortUp}
                            color="var(--surface-400)"
                          />
                        </span>
                      ) : (
                        <span>
                          {column.canSort ? (
                            <FontAwesomeIcon
                              icon={faSort}
                              color="var(--surface-400)"
                            />
                          ) : null}
                        </span>
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </div>

            <div
              ref={tbodyRef}
              style={{
                height: `${rowVirtualizer.totalSize}px`,
                width: '100%',
                position: 'relative',
              }}
              className="tbody"
              {...getTableBodyProps()}
            >
              {!isLoading && dataLength === 0 && (
                <div style={{ padding: '24px 16px' }}>
                  <i>{t("Your filters didn't match any records")}</i>
                </div>
              )}

              {rowVirtualizer.virtualItems.map((virtualRow) => {
                const row = rows[virtualRow.index];

                prepareRow(row);

                const rowClassNames = classNames(
                  'tr',
                  {
                    'row-even': virtualRow.index % 2 === 0,
                    'selected-row': row.isSelected,
                    'context-menu-selected-row':
                      tableInstance.contextMenuSelection?.id === row.id,
                  },
                  typeof additionalRowClassNames === 'function'
                    ? additionalRowClassNames(row)
                    : ''
                );

                return (
                  <div
                    {...row.getRowProps()}
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: `${virtualRow.size}px`,
                      transform: `translateY(${virtualRow.start}px)`,
                    }}
                    onClick={() => {
                      if (!multiSelect) {
                        tableInstance.toggleAllRowsSelected(false);
                      }

                      if (typeof rowSelection === 'function') {
                        rowSelection(row);

                        return;
                      }

                      if (rowSelection) {
                        row.toggleRowSelected();
                      }
                    }}
                    onContextMenu={() => {
                      if (!multiSelect) {
                        tableInstance.toggleAllRowsSelected(false);
                        row.toggleRowSelected();
                      }

                      if (typeof rowSelection === 'function') {
                        rowSelection(row);
                      }

                      setContextMenuSelection(row);
                    }}
                    onDoubleClick={() => {
                      if (typeof onRowDoubleClick === 'function') {
                        onRowDoubleClick(row);
                      }
                    }}
                    className={rowClassNames}
                  >
                    {row.cells.map((cell) => (
                      <div {...cell.getCellProps()} className="td">
                        {cell.render('Cell')}
                      </div>
                    ))}
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        {(footer || multiSelect) && (
          <div className="table-footer">
            {footer ?? multiSelectFooterFallback}
          </div>
        )}
      </div>
    </section>
  );
}

export default VirtualizedReactTable;
