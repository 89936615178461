import { TFunction } from 'react-i18next';

const maxLabelLength = 20;

export type OrdinaryFilter = {
  label: string;
  value: string;
  onDelete: () => void;
};

export type RequiredFilter = Omit<OrdinaryFilter, 'onDelete'>;

type BasicFilter = Pick<OrdinaryFilter, 'label' | 'value'>;

function getFullLabel(filter: BasicFilter): string {
  return `${filter.label}: ${filter.value}`;
}

export function getLabel(filter: BasicFilter): string {
  const fullLabel = getFullLabel(filter);

  return fullLabel.length > maxLabelLength
    ? `${fullLabel.substring(0, maxLabelLength - 3)}...`
    : fullLabel;
}

export function getRequiredTooltip(filter: BasicFilter): string {
  const fullLabel = getFullLabel(filter);

  return fullLabel.length > maxLabelLength ? `${filter.value}` : '';
}

export function getOrdinaryTooltip(t: TFunction, filter: BasicFilter): string {
  const fullLabel = getFullLabel(filter);

  return fullLabel.length > maxLabelLength
    ? `${filter.value}\n\n${t('Click to remove filter')}`
    : t('Remove filter');
}

export function filterByValue(filter: BasicFilter) {
  return !!filter.value;
}
