export const codWarrantCollectionGuard = 'GET /cashondeliveries';
export const createCODWarrantCollectionGuard = 'POST /cashondeliveries';
export const codWarrantResourceGuard = 'GET /cashondeliveries/d+';
export const editCODWarrantResourceGuard = 'PUT /cashondeliveries/d+';

export const codWarrantClientCollectionGuard = 'GET /cashondeliveries/clients';

export const codWarrantCandidateCollectionGuard =
  'GET /cashondeliveries/candidates';

export const exportToExcelOrCsvGuard =
  'GET /cashondeliveries/d+/export/(excel|csv)';

export const codWarrantNotifyGuard =
  'POST /cashondeliveries/d+/notify/(email|sms)';
