import { faUserTie } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ToastContext from '../../../context/ToastContext';
import useAxios from '../../../hooks/useAxios';
import useAxiosHook from '../../../hooks/useAxiosHook';
import { useEndpointGuard } from '../../../hooks/useEndpointGuard';
import usePageTitle from '../../../hooks/usePageTitle';
import useTableColumns from '../../../hooks/useTableColumns';
import useTableState from '../../../hooks/useTableState';
import { EmployeeCollection } from '../../../types/api/employees';
import { ReduxState } from '../../../types/redux';
import { Unpacked } from '../../../types/util';
import * as employeesGuards from '../../../utils/constants/auth/employees';
import { queryString } from '../../../utils/helpers/http';
import { httpQueryObject } from '../../../utils/helpers/misc';
import Table from '../../DataTable/Table/Table';
import Flex from '../../layout/flex/Flex';
import MainContent from '../../layout/flex/MainContent';
import Filters from '../Components/Filters/Filters';
import HeaderPages from '../Components/HeaderPages/HeaderPages';
import CreateEditDialog from './Dialogs/CreateEditDialog';
import ForceSetPassword from './Dialogs/ForceSetPasswordDialog/ForceSetPassword';
import QRDialog from './Dialogs/QRDialog';
import ViewDialog from './Dialogs/ViewDialog';
import {
  additionalColumnProperties,
  getColumnHeadersMap,
  tableStorageKey,
} from './Employees.functions';
import { Row } from './Employees.types';
import useTableFilters from './useTableFilters';

function Employees() {
  const { t } = useTranslation();
  const readGuard = useEndpointGuard(employeesGuards.readEmployee);
  const addGuard = useEndpointGuard(employeesGuards.addEmployee);
  const editGuard = useEndpointGuard(employeesGuards.editEmployee);
  const qrCodeGuard = useEndpointGuard(employeesGuards.qrCode);
  const updateStatusGuard = useEndpointGuard(employeesGuards.updateStatus);
  const [isViewDialogShown, setIsViewDialogShown] = useState(false);
  const { toastRef } = useContext(ToastContext);
  const [action, setAction] = useState<string>('');
  const [caller, setCaller] =
    useState<'group-actions' | 'context-menu'>('context-menu');
  const [isForceSetPassDialogShown, setIsForceSetPassDialogShown] =
    useState(false);
  const [isAddEditDialogShown, setIsAddEditDialogShown] = useState(false);
  const [isQrDialogShown, setIsQrDialogShown] = useState(false);
  const [dialogData, setDialogData] =
    useState<
      | (EmployeeCollection['data'][0] & {
          qrCode: string;
        })
      | null
    >(null);
  const user = useSelector<ReduxState, ReduxState['user']>(
    (state) => state.user
  );
  usePageTitle(t('Employees'));

  const {
    tableRef,
    page,
    setPage,
    limit,
    setLimit,
    sortField,
    sortOrder,
    selection,
    setSortField,
    setSortOrder,
    setSelection,
    contextMenuSelection,
    setContextMenuSelection,
  } = useTableState<Row>(tableStorageKey);

  const { headerFiltersCount, filters, resetAllFilters, httpFiltersObj } =
    useTableFilters(page, setPage!, limit);

  const columnHeadersMap = useMemo(() => getColumnHeadersMap(t), [t]);

  const { selectedColumns, setSelectedColumns, columnOptions, columns } =
    useTableColumns(
      page,
      limit,
      'employees',
      columnHeadersMap,
      columnHeadersMap,
      (c) =>
        additionalColumnProperties(
          t,
          c as keyof typeof columnHeadersMap,
          setContextMenuSelection,
          setAction,
          setCaller,
          readGuard,
          editGuard,
          updateStatusGuard,
          qrCodeGuard,
          user
        )
    );

  const { data: availableApps } = useAxiosHook<any>(
    '/oauthclients/registration-available',
    { skipWhen: !qrCodeGuard }
  );

  const finalColumns = useMemo<JSX.Element[]>(
    () => [
      ...columns,
      <Column
        key="action-column"
        header={t('Actions')}
        field="actions"
        frozen
        alignFrozen="right"
        {...additionalColumnProperties(
          t,
          'actions',
          setContextMenuSelection,
          setAction,
          setCaller,
          readGuard,
          editGuard,
          updateStatusGuard,
          qrCodeGuard,
          user
        )}
      />,
    ],
    [
      columns,
      t,
      setContextMenuSelection,
      readGuard,
      editGuard,
      updateStatusGuard,
      qrCodeGuard,
      user,
    ]
  );

  const {
    reload: appRegistrationRequest,
    isLoading: isAppRegistrationRequestLoading,
  } = useAxios('/oauthclients/registration-request', undefined, {
    method: 'PUT',
    skipWhen: true,
    errorCallback: () => {
      toastRef?.current?.show({
        severity: 'error',
        summary: t('Error'),
        detail: t(
          'An error occured while trying to generate a QR code for {{selectedRowName}}.',
          { selectedRowName: contextMenuSelection?.ime }
        ),
        life: 5000,
      });
    },
  });

  const { data, isLoading, reload, error } = useAxiosHook<EmployeeCollection>(
    '/employees' + queryString(httpQueryObject(httpFiltersObj))
  );

  const { reload: updateStatus } = useAxios(
    `/employees/${contextMenuSelection?.id}`,
    undefined,
    {
      method: 'PUT',
      skipWhen: true,
      successCallback: () => {
        reload();

        toastRef?.current?.show({
          severity: 'success',
          summary: t('Status change'),
          detail: t('{{selectedRowName}} has been successfully updated', {
            selectedRowName: contextMenuSelection?.ime,
          }),
          life: 5000,
        });
      },
      errorCallback: () => {
        toastRef?.current?.show({
          severity: 'error',
          summary: t('Error'),
          detail: t(
            "An error occured while trying to change {{selectedRowName}}'s status.",
            { selectedRowName: contextMenuSelection?.ime }
          ),
          life: 5000,
        });
      },
    }
  );

  const {
    isLoading: isEmployeeDialogDataLoading,
    reload: reloadEmployeeDialogData,
  } = useAxios(undefined, undefined, {
    errorCallback: () => {
      toastRef?.current?.show({
        severity: 'error',
        summary: t('Error'),
        detail: t(
          'An error occured while trying to get data for employee {{selectedRowName}}.',
          { selectedRowName: contextMenuSelection?.ime }
        ),
        life: 5000,
      });
    },
  });

  const isEditDialog = !!dialogData;

  useEffect(() => {
    if (!readGuard) {
      setIsViewDialogShown(false);
    }
  }, [readGuard]);

  useEffect(() => {
    if (!addGuard && isAddEditDialogShown && !isEditDialog) {
      setIsAddEditDialogShown(false);
    }
  }, [addGuard, isAddEditDialogShown, isEditDialog]);

  useEffect(() => {
    if (!editGuard && isAddEditDialogShown && isEditDialog) {
      setIsAddEditDialogShown(false);
    }
  }, [editGuard, isAddEditDialogShown, isEditDialog]);

  function handleAddEmployeeBtnClick() {
    setDialogData(null);
    setIsAddEditDialogShown(true);
  }

  // Context menu functions
  const handleStatusChange = useCallback(
    (newStatus: any) => {
      updateStatus({
        payload: { aktiven: newStatus },
      });
    },
    [updateStatus]
  );

  const handleCMViewClick = useCallback(() => {
    reloadEmployeeDialogData({
      url: `/employees/${contextMenuSelection?.id}`,
      successCallback: (data: any) => {
        setDialogData(data.data);
        setIsViewDialogShown(true);
      },
    });
  }, [contextMenuSelection, reloadEmployeeDialogData]);

  const handleCMEditClick = useCallback(() => {
    reloadEmployeeDialogData({
      url: `/employees/${contextMenuSelection?.id}`,
      successCallback: (data: any) => {
        setDialogData(data.data);
        setIsAddEditDialogShown(true);
      },
    });
  }, [contextMenuSelection, reloadEmployeeDialogData]);

  const handleCMActivateClick = useCallback(() => {
    handleStatusChange(1);
  }, [handleStatusChange]);

  const handleCMDeactivateClick = useCallback(() => {
    handleStatusChange(0);
  }, [handleStatusChange]);

  const handleCMQrCodeAppClick = useCallback(
    (app: any) => {
      if (contextMenuSelection) {
        appRegistrationRequest({
          payload: {
            user_id: contextMenuSelection.korisnik_id,
            client_id: app.id,
          },
          successCallback: (data: any) => {
            setDialogData({
              ...(contextMenuSelection ?? {}),
              qrCode: data?.data?.registration_code ?? '',
              app,
            });
            setIsQrDialogShown(true);
          },
        });
      }
    },
    [contextMenuSelection, appRegistrationRequest]
  );

  const handleCreateEditDialogHide = useCallback(() => {
    setIsAddEditDialogShown(false);
    setDialogData(null);
  }, []);

  const handleViewDialogHide = useCallback(() => {
    setIsViewDialogShown(false);
  }, []);

  function handleQrDialogHide() {
    setIsQrDialogShown(false);
    setDialogData(null);
  }

  const handleCMForceResetPasswordClick = useCallback(() => {
    setIsForceSetPassDialogShown(true);
  }, []);

  function handleForceSetPassDialogHide() {
    setIsForceSetPassDialogShown(false);
  }

  function handleViewDialogEditBtnClick() {
    setIsViewDialogShown(false);
    setIsAddEditDialogShown(true);
  }

  function handleAllRequestsFinish() {
    handleCreateEditDialogHide();
    reload();
  }

  function handleBasicInfoSubmit(name: string, isSuccess: boolean) {
    toastRef?.current?.show({
      severity: isSuccess ? 'success' : 'error',
      summary: isSuccess ? t('Success') : t('Error'),
      detail: isSuccess
        ? isEditDialog
          ? t('Employee {{name}} has been sucessfully edited.', { name: name })
          : t('Employee {{name}} has been sucessfully created.', { name: name })
        : t('An error occured while trying to create employee {{name}}.', {
            name: name,
          }),
      life: 5000,
    });
  }

  function handleRolesSubmit(name: string, isSuccess: boolean) {
    toastRef?.current?.show({
      severity: isSuccess ? 'success' : 'error',
      summary: isSuccess ? t('Success') : t('Error'),
      detail: isSuccess
        ? t("Employee {{name}}'s roles have been successfully assigned", {
            name: name,
          })
        : t(
            'An error occured while trying to assign roles to employee {{name}}.',
            { name: name }
          ),
      life: 5000,
    });
  }

  function handleHubsSubmit(name: string, isSuccess: boolean) {
    toastRef?.current?.show({
      severity: isSuccess ? 'success' : 'error',
      summary: isSuccess ? t('Success') : t('Error'),
      detail: isSuccess
        ? t("Employee {{name}}'s warehouses have been successfully assigned", {
            name: name,
          })
        : t(
            'An error occured while trying to assign warehouses to employee {{name}}.',
            { name: name }
          ),
      life: 5000,
    });
  }

  function handleVehicleSubmit(name: string, isSuccess: boolean) {
    toastRef?.current?.show({
      severity: isSuccess ? 'success' : 'error',
      summary: isSuccess ? t('Success') : t('Error'),
      detail: isSuccess
        ? t("Employee {{name}}'s vehicle has been successfully updated", {
            name: name,
          })
        : t(
            'An error occured while trying to assign a vehicle to employee {{name}}. Make sure that the vehicle is not assigned to anotother employee.',
            { name: name }
          ),
      life: 5000,
    });
  }

  useEffect(() => {
    if (action && contextMenuSelection) {
      setCaller('context-menu');

      if (action === 'view-details') {
        handleCMViewClick();
      }
      if (action === 'edit') {
        handleCMEditClick();
      }
      if (action === 'activate') {
        handleCMActivateClick();
      }
      if (action === 'deactivate') {
        handleCMDeactivateClick();
      }
      if (action === 'qr-code-courier') {
        handleCMQrCodeAppClick(availableApps?.data?.available_applications[0]);
      }
      if (action === 'qr-code-warehouse') {
        handleCMQrCodeAppClick(availableApps?.data?.available_applications[1]);
      }
      if (action === 'force-password') {
        handleCMForceResetPasswordClick();
      }
      setAction('');
    }
  }, [
    action,
    contextMenuSelection,
    availableApps,
    setAction,
    handleCMViewClick,
    handleCMEditClick,
    handleCMActivateClick,
    handleCMDeactivateClick,
    handleCMQrCodeAppClick,
    handleCMForceResetPasswordClick,
  ]);

  return (
    <div className="page employees-page">
      <HeaderPages
        title={t('Employees')}
        subtitle={t('View and manage employees')}
        icon={faUserTie}
      >
        {addGuard && (
          <Button
            type="button"
            label={t('Add employee')}
            icon="fas fa-plus"
            className="main-btn"
            disabled={isLoading}
            onClick={handleAddEmployeeBtnClick}
            data-cy="add-btn"
          />
        )}
      </HeaderPages>

      <CreateEditDialog
        isShown={isAddEditDialogShown}
        data={dialogData ?? {}}
        onHide={handleCreateEditDialogHide}
        onBasicInfoSubmit={handleBasicInfoSubmit}
        onRolesSubmit={handleRolesSubmit}
        onHubsSubmit={handleHubsSubmit}
        onVehicleSubmit={handleVehicleSubmit}
        onAllRequestsFinish={handleAllRequestsFinish}
      />

      <ViewDialog
        isShown={isViewDialogShown}
        data={dialogData ?? {}}
        onHide={handleViewDialogHide}
        onEditBtnClick={handleViewDialogEditBtnClick}
      />

      <QRDialog
        isShown={isQrDialogShown}
        data={dialogData}
        onHide={handleQrDialogHide}
      />

      <Dialog
        visible={isEmployeeDialogDataLoading || isAppRegistrationRequestLoading}
        onHide={() => {}}
        closable={false}
        closeOnEscape={false}
        resizable={false}
        header={t('Loading...')}
      >
        <ProgressSpinner
          fill="#ccc"
          strokeWidth="4"
          style={{ width: 360, textAlign: 'center', overflow: 'hidden' }}
        />
      </Dialog>

      <ForceSetPassword
        isShown={isForceSetPassDialogShown}
        onHide={handleForceSetPassDialogHide}
        employee={selection as Unpacked<EmployeeCollection>}
      />

      <Flex direction="column">
        <Filters
          filters={filters}
          resetAllFilters={resetAllFilters}
          headerFiltersCount={headerFiltersCount}
          filterHeight={230}
        />
        <MainContent>
          <Table
            tableRef={tableRef}
            columns={finalColumns}
            data={data}
            isLoading={isLoading}
            hasError={!!error}
            reload={reload}
            headerTitle=""
            setPage={setPage}
            setLimit={setLimit}
            sortField={sortField}
            rows={limit}
            filterHeight={230}
            setSortField={setSortField}
            setSortOrder={setSortOrder}
            setSelection={setSelection}
            sortOrder={sortOrder}
            selection={selection}
            storageString={tableStorageKey}
            rebuildTooltip
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            columnOptions={columnOptions}
            onRowDoubleClick={readGuard ? handleCMViewClick : () => {}}
            exportToCSVButton
            onExportToCSVButtonClick={() =>
              (tableRef?.current as any)?.exportCSV()
            }
          />
        </MainContent>
      </Flex>
    </div>
  );
}

export default Employees;
