import { TFunction } from 'react-i18next';
import { Column } from 'react-table';

import { NoCell } from './cells';
import { IndeterminateCheckbox } from './components';

export function NoColumn<T extends object>(t: TFunction): Column<T> {
  return {
    id: 'no',
    Header: () => t('No.'),
    Cell: NoCell,
    width: 60,
  };
}

export const SelectionColumn: Column = {
  id: 'selection',
  Header: ({ getToggleAllRowsSelectedProps }) => (
    <div>
      <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
    </div>
  ),
  Cell: ({ row }) => (
    <div className="p-text-center">
      <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
    </div>
  ),
};
