import { faMinus } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Dispatch, useMemo } from 'react';
import { useState } from 'react';
import { SetStateAction } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDebounce } from 'use-lodash-debounce';

import { getReconciliationStatusOptions } from '../../../configs/reconciliations';
import useAxiosHook from '../../../hooks/useAxiosHook';
import useHaveValuesChanged from '../../../hooks/useHaveValuesChanged';
import useSearchQueryDateParam from '../../../hooks/useSearchQueryDateParam';
import useSearchQueryDropdownParam from '../../../hooks/useSearchQueryDropdownParam';
import useSearchQueryParam from '../../../hooks/useSearchQueryParam';
import { ReconciliationEmployeeCollection } from '../../../types/api/reconciliations';
import { LabelValue } from '../../../types/options';
import { debounceTimeout } from '../../../utils/constants/misc';
import { httpDateFormat } from '../../../utils/helpers/formatting';
import { queryString } from '../../../utils/helpers/http';
import { renderIcon } from '../../../utils/helpers/icon';
import {
  getSearchQueryParam,
  tryDateSearchParam,
} from '../../../utils/helpers/searchQuery';
import FiltersCounter from '../Components/Filters/FiltersCounter';

function useTableFilters(
  page: number,
  setPage: Dispatch<SetStateAction<number>>,
  limit: number
) {
  const { t } = useTranslation();
  const location = useLocation();
  const [workOrderId, setWorkOrderId] = useState<string | null>(
    () => getSearchQueryParam(location.search, 'work_order_id') ?? ''
  );
  const [dateFrom, setDateFrom] = useState<Date | null>(
    () =>
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'work_order_date_from') ?? ''
      ) ?? dayjs().toDate()
  );
  const [dateTo, setDateTo] = useState<Date | null>(
    () =>
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'work_order_date_to') ?? ''
      ) ?? dayjs().toDate()
  );
  const [statusId, setStatusId] = useState<string | null>(
    () => getSearchQueryParam(location.search, 'status_id') ?? ''
  );
  const [courierId, setCourierId] = useState<string | null>(
    () => getSearchQueryParam(location.search, 'courier_id') ?? ''
  );

  const { data: employees, isLoading } =
    useAxiosHook<ReconciliationEmployeeCollection>(
      '/reconciliations/employees' +
        queryString({
          page: 1,
          limit: 0,
          work_order_date_from: httpDateFormat(dateFrom!),
          work_order_date_to: httpDateFormat(dateTo!),
          status_id: statusId,
        }),
      { skipWhen: !dateFrom }
    );

  const courierOptions = useMemo<LabelValue<number>[]>(
    () =>
      employees?.data.map((employee) => ({
        label: employee.employee_full_name,
        value: employee.employee_id,
      })) ?? [],
    [employees?.data]
  );
  const statusOptions = useMemo(() => getReconciliationStatusOptions(t), [t]);

  const debouncedWorkOrderId = useDebounce(workOrderId, debounceTimeout);

  const filtersArr = useMemo(
    () => [debouncedWorkOrderId, dateFrom, dateTo, statusId, courierId],
    [debouncedWorkOrderId, dateFrom, dateTo, statusId, courierId]
  );

  useEffect(() => {
    setPage(1);
  }, [setPage, filtersArr]);

  useSearchQueryParam('work_order_id', debouncedWorkOrderId);
  useSearchQueryDateParam('work_order_date_from', dateFrom);
  useSearchQueryDateParam('work_order_date_to', dateTo);
  useSearchQueryDropdownParam(
    'status_id',
    statusId,
    setStatusId,
    statusOptions
  );
  useSearchQueryDropdownParam(
    'courier_id',
    courierId,
    setCourierId,
    courierOptions
  );

  const haveFiltersChanged = useHaveValuesChanged(filtersArr);

  const httpFiltersObj = useMemo(
    () => ({
      work_order_id: debouncedWorkOrderId,
      work_order_date_from: dateFrom ? httpDateFormat(dateFrom) : null,
      work_order_date_to: dateTo ? httpDateFormat(dateTo) : null,
      courier_id: courierId,
      status_id: statusId,
      page: haveFiltersChanged ? 1 : page,
      limit,
    }),
    [
      debouncedWorkOrderId,
      dateFrom,
      dateTo,
      statusId,
      courierId,
      haveFiltersChanged,
      page,
      limit,
    ]
  );

  const basicFiltersActiveFilterCount = Object.values({
    workOrderId,
    dateFrom,
    statusId,
    courierId,
  }).filter(Boolean).length;

  const filters = useMemo<JSX.Element>(
    () => (
      <Accordion multiple activeIndex={[0]}>
        <AccordionTab
          disabled
          headerTemplate={
            <FiltersCounter
              description={t('Basic filters')}
              counter={basicFiltersActiveFilterCount}
            />
          }
        >
          <div className="sidebar_filter">
            <label htmlFor="work_order_id">{t('Work Order')}</label>
            <InputText
              id="work_order_id"
              value={workOrderId ?? ''}
              placeholder={t('Work Order')}
              className={classNames({
                sidebar_filter_active: workOrderId,
              })}
              onChange={(e) => setWorkOrderId(e.target.value)}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="filter_date_from">{t('Created Date')}</label>
            <div className="sidebar_filter_row">
              <Calendar
                id="filter_date_from"
                value={dateFrom ?? undefined}
                monthNavigator
                yearNavigator
                dateFormat="dd/mm/yy"
                yearRange={`2009:${dayjs().format('YYYY')}`}
                maxDate={new Date()}
                onChange={(e) => setDateFrom(e.value as Date | null)}
                inputClassName={classNames({
                  sidebar_filter_active: dateFrom,
                })}
              />
              <span style={{ margin: 'auto' }}>{renderIcon(faMinus)}</span>
              <Calendar
                id="filter_date_to"
                value={dateTo ?? undefined}
                onChange={(e) => {
                  setDateTo(e.value as Date | null);
                }}
                dateFormat="dd/mm/yy"
                maxDate={new Date()}
                monthNavigator
                yearNavigator
                yearRange={`2009:${dayjs().format('YYYY')}`}
                inputClassName={classNames({
                  sidebar_filter_active: dateTo,
                })}
              />
            </div>
          </div>
          <div className="sidebar_filter">
            <label htmlFor="status_filter">{t('Status')}</label>
            <Dropdown
              id="status_filter"
              options={statusOptions}
              value={statusId}
              showClear
              filter
              placeholder={t('All')}
              disabled={!statusOptions.length}
              onChange={(e) => setStatusId(e.value)}
              className={classNames({
                sidebar_filter_active: statusId,
              })}
            />
          </div>

          <div className="sidebar_filter">
            <label htmlFor="courier_filter">{t('Courier')}</label>
            <Dropdown
              id="courier_filter"
              options={courierOptions}
              value={courierId}
              showClear
              filter
              placeholder={
                courierOptions.length
                  ? t('All')
                  : t('Current filters match no couriers')
              }
              onChange={(e) => setCourierId(e.value)}
              disabled={!!workOrderId || isLoading || !courierOptions.length}
              className={classNames({
                sidebar_filter_active: courierId,
              })}
            />
          </div>
        </AccordionTab>
      </Accordion>
    ),
    [
      workOrderId,
      dateFrom,
      dateTo,
      statusId,
      statusOptions,
      courierId,
      courierOptions,
      isLoading,
      basicFiltersActiveFilterCount,
      t,
    ]
  );

  function resetAllFilters() {
    setWorkOrderId('');
    setDateFrom(new Date());
    setDateTo(new Date());
    setStatusId(null);
    setCourierId(null);
  }

  const headerFiltersCount = useMemo(
    () => basicFiltersActiveFilterCount,
    [basicFiltersActiveFilterCount]
  );

  return {
    filters,
    resetAllFilters,
    httpFiltersObj,
    headerFiltersCount,
  };
}

export default useTableFilters;
