import { LatLngLiteral, Map } from 'leaflet';

import { NonNullableProperties } from '../../../types/util';
import { latLngLiteralToLatLngBounds } from '../../../utils/helpers/map';

type CourierAssignment = {
  product_id: number;
  shipment_type_id: number;
};

export type Courier = {
  id: number;
  last_name: string;
  first_name: string;
  assignments: CourierAssignment[];
};

export type Region = {
  region_id: number;
  zone_id: number | null;
  name: string;
  description: string;
  location: LatLngLiteral | null;
  geometry: LatLngLiteral[] | null;
  couriers: Courier[];
};

export type RegionWithLocation = Omit<Region, 'location'> &
  NonNullableProperties<Pick<Region, 'location'>>;

export type RegionWithGeometry = Omit<Region, 'geometry'> &
  NonNullableProperties<Pick<Region, 'geometry'>>;

export function fitMapBounds(map: Map, region: Region): void {
  if (region.geometry) {
    map.fitBounds(latLngLiteralToLatLngBounds(region.geometry));
  }
}
