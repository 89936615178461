import './ViewImportLogDialog.scss';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useMediaQuery from '../../../../../../hooks/useMediaQuery';
import { emptyCell } from '../../../../../../utils/constants/tables';
import { BulkOrder } from '../../../BulkOrders.functions';
import {
  LogsData,
  additionalColumnProperties,
} from './ViewImportLogDialog.functions';

type Props = {
  data: LogsData;
  selectedList: BulkOrder | any;
  isShown: boolean;
  onHide: () => void;
};

function ViewImportLogDialog({ data, selectedList, isShown, onHide }: Props) {
  const { t } = useTranslation();
  const resultLog = data?.logs?.find((log) => log.poraka_tip === 'result');
  const resultLogMessages = resultLog?.poraka.split(/[!,?.]/);
  const tableData = data?.logs?.filter((log) => log.poraka_tip !== 'result');

  const columnHeadersMap: any = useMemo(
    () => ({
      red: t('Data row'),
      datum: t('Date'),
      poraka: t('Message'),
    }),
    [t]
  );

  const columns = Object.keys(columnHeadersMap).map((c) => (
    <Column
      key={c}
      header={columnHeadersMap[c]}
      field={c}
      {...(c === 'no' ? { body: (_, colData) => colData.rowIndex + 1 } : {})}
      {...(!['no'].includes(c)
        ? {
            body: (rowData) =>
              (typeof rowData[c] === 'string'
                ? rowData[c].trim()
                : rowData[c]) || emptyCell,
          }
        : {})}
      {...additionalColumnProperties(c)}
    />
  ));

  const isOnMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Dialog
      visible={isShown}
      onHide={onHide}
      header={t('View import log messages')}
      maximizable
      maximized={isOnMobile}
      resizable={false}
      className="imported-lists-view-import-dialog datatable-dialog"
    >
      <div className="log-dialog-info">
        <div className="p-mb-2">
          <div className="p-d-flex p-jc-start">
            <span>{t('Client')}:&nbsp;</span>
            <span className="info-value">{selectedList?.client_name}</span>
          </div>

          <div className="p-d-flex p-jc-start">
            <span>{t('List name')}:&nbsp;</span>
            <span className="info-value">{selectedList?.name}</span>
          </div>

          <div className="p-d-flex p-jc-start">
            <span>{'Barcode'}:&nbsp;</span>
            <span className="info-value">{selectedList?.barcode}</span>
          </div>
        </div>
        <div className="p-mb-2">
          {resultLogMessages?.map((msg, index: number) => {
            return (
              <div key={index}>
                <span>{msg}</span>
              </div>
            );
          })}
        </div>
      </div>
      <DataTable
        value={tableData}
        scrollable
        className="import-log-dt datatable-no-header p-mt-2 p-datatable-sm p-datatable-striped"
        rowClassName={(row) => {
          return {
            warning: row.poraka_tip === 'warning',
            error: row.poraka_tip === 'error',
          };
        }}
      >
        {columns}
      </DataTable>
    </Dialog>
  );
}

export default ViewImportLogDialog;
