import _ from 'lodash';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { getSearchQueryParam } from '../../../../utils/helpers/searchQuery';
import { getProductTypes } from '../Tariffs.functions';

function useProductTypeState() {
  const { t } = useTranslation();

  const location = useLocation();

  const productTypeOptions = useMemo(() => getProductTypes(t), [t]);

  return useState<string>(() => {
    const queryParam =
      getSearchQueryParam(location.search, 'productType') ?? 'package';

    if (!_.map(productTypeOptions, 'value').includes(queryParam)) {
      return 'package';
    }

    return queryParam;
  });
}

export default useProductTypeState;
