import { faFileContract } from '@fortawesome/free-solid-svg-icons';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { useEffect, useState } from 'react';
import { useMemo } from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import ToastContext from '../../../../context/ToastContext';
import { FileTypes } from '../../../../enums/files';
import useAxiosHook from '../../../../hooks/useAxiosHook';
import { useEndpointGuard } from '../../../../hooks/useEndpointGuard';
import usePageTitleToggler from '../../../../hooks/usePageTitleToggler';
import usePrevious from '../../../../hooks/usePrevious';
import useRouteDialog from '../../../../hooks/useRouteDialog';
import useTableColumns from '../../../../hooks/useTableColumns';
import useTableState from '../../../../hooks/useTableState';
import { WithPagination } from '../../../../types/api';
import { EntityIdRouteParams } from '../../../../types/routing';
import * as warrantsGuards from '../../../../utils/constants/auth/cod/warrants';
import {
  RoutePaths,
  constructIdRoute,
} from '../../../../utils/constants/routePaths';
import { downloadFile, getFileName } from '../../../../utils/helpers/files';
import { queryString } from '../../../../utils/helpers/http';
import { errorToast, successToast } from '../../../../utils/helpers/primereact';
import Table from '../../../DataTable/Table/Table';
import Flex from '../../../layout/flex/Flex';
import Filters from '../../Components/Filters/Filters';
import HeaderPages from '../../Components/HeaderPages/HeaderPages';
import SendCODConfirmDialog from './Dialogs/SendCOD/SendCODConfirmDialog';
import ViewParcelsDialog from './Dialogs/View/ViewParcelsDialog';
import useTableFilters from './useTableFilters';
import {
  SingleWarrantList,
  WarrantList,
  additionalListsColumnProperties,
  generateContextMenu,
  getListsColumnHeadersMap,
  tableStorageKey,
} from './Warrants.functions';

function Warrants(): JSX.Element {
  const { t } = useTranslation();
  const [isMarkAsUnsentDialogShown, setIsMarkAsUnsentDialogShown] =
    useState(false);
  const readGuard = useEndpointGuard(warrantsGuards.readWarrant);
  const editGuard = useEndpointGuard(warrantsGuards.editWarrant);
  const exportToExcelOrCsvGuard = useEndpointGuard(
    warrantsGuards.exportToExcelOrCsv
  );
  const sendCodEmailGuard = useEndpointGuard(warrantsGuards.sendCodEmail);
  const listsColumnHeadersMap = useMemo(() => getListsColumnHeadersMap(t), [t]);
  const { toastRef, bottomRightToastRef } = useContext(ToastContext);
  const { id } = useParams<EntityIdRouteParams>();
  const {
    tableRef,
    page,
    setPage,
    limit,
    setLimit,
    sortField,
    sortOrder,
    selection,
    setSortField,
    setSortOrder,
    setSelection,
  } = useTableState<WarrantList>(tableStorageKey);

  const {
    show: showViewDialog,
    hide: hideViewDialog,
    isVisible: isViewDialogVisible,
  } = useRouteDialog(
    RoutePaths.Warrants,
    constructIdRoute(RoutePaths.ViewWarrant, id ?? selection?.id)
  );

  const {
    show: showSendCodEmailDialog,
    hide: hideSendCodEmailDialog,
    isVisible: isSendCodEmailDialogVisible,
  } = useRouteDialog(
    RoutePaths.Warrants,
    constructIdRoute(RoutePaths.EmailWarrant, id ?? selection?.id)
  );

  const {
    data: singleWarrant,
    isLoading: isSingleWarrantLoading,
    error: singleWarrantError,
  } = useAxiosHook<SingleWarrantList>(
    `cashondeliveries/${id ?? selection?.id}`,
    {
      skipWhen:
        (!isViewDialogVisible && !isSendCodEmailDialogVisible) ||
        (!id && (!selection?.id || isNaN(selection.id))),
    }
  );

  const prevSingleWarrantError = usePrevious(singleWarrantError);

  useEffect(() => {
    if (!readGuard) {
      hideViewDialog();
    }
  }, [hideViewDialog, readGuard]);

  useEffect(() => {
    if (!sendCodEmailGuard) {
      hideSendCodEmailDialog();
    }
  }, [hideSendCodEmailDialog, sendCodEmailGuard]);

  useEffect(() => {
    if (!singleWarrantError || singleWarrantError === prevSingleWarrantError) {
      return;
    }

    errorToast(
      toastRef,
      t('Error'),
      t('An error occured while reading parcel data.')
    );

    hideViewDialog();
  }, [t, hideViewDialog, prevSingleWarrantError, singleWarrantError, toastRef]);

  usePageTitleToggler(
    t('View {{clientName}} - COD Warrants', {
      clientName: selection?.client_name,
    }),
    t('COD Warrants'),
    isViewDialogVisible
  );

  usePageTitleToggler(
    t('Email {{clientName}} - COD Warrants', {
      clientName: selection?.client_name,
    }),
    t('COD Warrants'),
    isSendCodEmailDialogVisible
  );

  const { selectedColumns, setSelectedColumns, columnOptions, columns } =
    useTableColumns(
      page,
      limit,
      'cod_warrants',
      listsColumnHeadersMap,
      listsColumnHeadersMap,
      (column) =>
        additionalListsColumnProperties(
          t,
          column as keyof typeof listsColumnHeadersMap
        )
    );

  const { filters, headerFiltersCount, resetAllFilters, httpFiltersObj } =
    useTableFilters(page, setPage, limit);

  const canLoadData = httpFiltersObj.date_from && httpFiltersObj.date_to;

  const { data, reload, isLoading, error } = useAxiosHook<
    WithPagination<WarrantList>
  >('/cashondeliveries' + queryString(httpFiltersObj), {
    skipWhen: !canLoadData,
  });

  const {
    data: updateListStatusData,
    error: updateListStatusError,
    reload: updateListStatus,
    isLoading: isUpdateListStatusLoading,
  } = useAxiosHook(
    { url: `/cashondeliveries/${selection?.id}`, method: 'PUT' },
    {
      skipWhen: true,
    }
  );

  const prevUpdateListStatusError = usePrevious(updateListStatusError);
  const prevUpdateListStatusData = usePrevious(updateListStatusData);

  useEffect(() => {
    if (
      !updateListStatusError ||
      updateListStatusError === prevUpdateListStatusError
    ) {
      return;
    }

    errorToast(
      toastRef,
      t('Error'),
      t('An error occured while changing the status of {{serialNumber}}.', {
        serialNumber: selection?.seriski,
      })
    );
  }, [
    t,
    prevUpdateListStatusError,
    selection?.seriski,
    toastRef,
    updateListStatusError,
  ]);

  useEffect(() => {
    if (
      !updateListStatusData ||
      updateListStatusData === prevUpdateListStatusData
    ) {
      return;
    }

    successToast(
      toastRef,
      t('Success'),
      t("List {{seialNumber}}'s status has been changed successfully.", {
        serialNumber: selection?.seriski,
      })
    );

    reload();
  }, [
    t,
    prevUpdateListStatusData,
    reload,
    selection?.seriski,
    toastRef,
    updateListStatusData,
  ]);

  function handleListStatusUpdate(status: number) {
    updateListStatus({
      data: {
        status,
      },
    });
  }

  function handleCMRevertToCreated() {
    return handleListStatusUpdate(2);
  }

  function handleCMMarkAsUnpaid() {
    return handleListStatusUpdate(1);
  }

  function handleCMMarkAsUnsent() {
    setIsMarkAsUnsentDialogShown(true);
  }

  function handleCMViewParcelsClick() {
    showViewDialog();
  }

  function handleCMExportToCSV() {
    if (!selection) {
      return;
    }

    downloadFile(
      `/cashondeliveries/${selection?.id}/export/csv`,
      getFileName(t('Warrants'), [
        selection.seriski ?? '',
        selection.client_name,
      ]),
      FileTypes.CSV,
      bottomRightToastRef
    );
  }

  function handleCMExportToExcel() {
    if (!selection) {
      return;
    }

    downloadFile(
      `/cashondeliveries/${selection?.id}/export/excel`,
      getFileName(t('Warrants'), [
        selection.seriski ?? '',
        selection.client_name,
      ]),
      FileTypes.XLSX,
      bottomRightToastRef
    );
  }

  function handleCMSendCodEmail() {
    showSendCodEmailDialog();
  }

  function handleSendCodEmailDialogHide() {
    hideSendCodEmailDialog();
  }

  function handleViewParcelsDialogHide() {
    hideViewDialog();
  }

  return (
    <div className="page cod-warrants-page">
      <HeaderPages
        title={t('Warrants')}
        subtitle={t('View and manage cash on delivery warrants')}
        icon={faFileContract}
      />

      <ViewParcelsDialog
        isShown={isViewDialogVisible}
        warrant={singleWarrant}
        isWarrantLoading={isSingleWarrantLoading}
        onHide={handleViewParcelsDialogHide}
      />

      <SendCODConfirmDialog
        isShown={isSendCodEmailDialogVisible}
        warrant={singleWarrant}
        isWarrantLoading={isSingleWarrantLoading}
        onHide={handleSendCodEmailDialogHide}
      />

      <ConfirmDialog
        visible={isMarkAsUnsentDialogShown}
        onHide={() => setIsMarkAsUnsentDialogShown(false)}
        message={t('Are you sure you want to mark this warrant as unsent?')}
        header={t('Confirmation')}
        icon="fas fa-exclamation-triangle"
        accept={() => {
          updateListStatus({
            data: {
              email_status_id: 2,
            },
          });
        }}
        reject={() => setIsMarkAsUnsentDialogShown(false)}
        acceptLabel={t('Mark as unsent')}
        rejectLabel={t('Cancel')}
        style={{ maxWidth: 480 }}
      />

      <Flex direction="column">
        <Filters
          filters={filters}
          resetAllFilters={resetAllFilters}
          filterHeight={220}
        />
        <div style={{ boxShadow: '-1px 0px 6px 0px lightgray' }}>
          <Table
            tableRef={tableRef}
            columns={columns}
            data={data}
            hasError={!!error}
            isLoading={isLoading || isUpdateListStatusLoading}
            reload={reload}
            isReloadDisabled={!canLoadData}
            headerTitle=""
            filterHeight={220}
            headerFiltersCount={headerFiltersCount}
            onHeaderFiltersResetAllBtnClick={resetAllFilters}
            setPage={setPage}
            setLimit={setLimit}
            sortField={sortField}
            rows={limit}
            setSortField={setSortField}
            setSortOrder={setSortOrder}
            setSelection={setSelection}
            sortOrder={sortOrder}
            selection={selection}
            onRowDoubleClick={handleCMViewParcelsClick}
            contextMenuModel={generateContextMenu(
              t,
              selection,
              readGuard,
              handleCMViewParcelsClick,
              exportToExcelOrCsvGuard,
              handleCMExportToExcel,
              exportToExcelOrCsvGuard,
              handleCMExportToCSV,
              sendCodEmailGuard,
              handleCMSendCodEmail,
              editGuard,
              handleCMRevertToCreated,
              editGuard,
              handleCMMarkAsUnpaid,
              handleCMMarkAsUnsent
            )}
            storageString={tableStorageKey}
            rebuildTooltip
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            columnOptions={columnOptions}
          />
        </div>
      </Flex>
    </div>
  );
}

export default Warrants;
