import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import 'leaflet/dist/leaflet.css';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css';
import 'leaflet-defaulticon-compatibility';
import 'primereact/resources/themes/saga-blue/theme.css';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import 'dayjs/locale/en';
import 'dayjs/locale/mk';
import 'ace-builds/src-noconflict/ace';
import 'ace-builds/src-noconflict/mode-mysql';
import 'ace-builds/src-noconflict/theme-tomorrow';
import 'ace-builds/src-min-noconflict/ext-language_tools';

import './translations/primereact';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { createBrowserHistory } from 'history';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';

import App from './App';
import i18n from './i18n';
import store from './redux/store';
import { tryFloat } from './utils/helpers/parse';

// dayjs
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);
dayjs.extend(weekOfYear);
dayjs.extend(quarterOfYear);
dayjs.extend(isToday);
dayjs.extend(isYesterday);
dayjs.extend(isBetween);

// pdfMake
pdfMake.vfs = pdfFonts.pdfMake.vfs;

// Cypress
if (window.Cypress) {
  window._store = store;
}

// Sentry
const history = createBrowserHistory();

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      tracingOrigins: [
        'localhost',
        '127.0.0.1',
        process.env.REACT_APP_API_URL!,
        process.env.REACT_APP_REPORT_URL!,
        /^\//,
      ],
    }),
  ],
  tracesSampleRate:
    tryFloat(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE) ?? 0.2,
  environment: process.env.NODE_ENV,
});

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <App history={history} />
    </I18nextProvider>
  </Provider>,
  document.getElementById('root')
);
