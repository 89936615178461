import { faClipboardCheck } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import ToastContext from '../../../../context/ToastContext';
import { FileTypes } from '../../../../enums/files';
import useAxiosHook from '../../../../hooks/useAxiosHook';
import usePageTitleToggler from '../../../../hooks/usePageTitleToggler';
import useRouteDialog from '../../../../hooks/useRouteDialog';
import useTableColumns from '../../../../hooks/useTableColumns';
import useTableState from '../../../../hooks/useTableState';
import { EntityIdRouteParams } from '../../../../types/routing';
import {
  RoutePaths,
  constructIdRoute,
} from '../../../../utils/constants/routePaths';
import { rowsPerPageOptions500 } from '../../../../utils/constants/tables';
import { downloadFile, getFileName } from '../../../../utils/helpers/files';
import { queryString } from '../../../../utils/helpers/http';
import { httpQueryObject } from '../../../../utils/helpers/misc';
import Table from '../../../DataTable/Table/Table';
import Flex from '../../../layout/flex/Flex';
import MainContent from '../../../layout/flex/MainContent';
import Filters from '../../Components/Filters/Filters';
import HeaderPages from '../../Components/HeaderPages/HeaderPages';
import {
  ApiData,
  CustomerOrder,
  additionalListsColumnProperties,
  generateContextMenu,
  getColumnHeadersMap,
  tableStorageKey,
} from './CustomerOrders.functions';
import ViewCustomerOrder from './Dialogs/View/ViewCustomerOrder';
import useTableFilters from './useTableFilters';

function CustomerOrders() {
  const { t } = useTranslation();
  const { id } = useParams<EntityIdRouteParams>();
  const listsColumnHeadersMap = useMemo(() => getColumnHeadersMap(t), [t]);
  const { bottomRightToastRef } = useContext(ToastContext);
  const {
    tableRef,
    page,
    setPage,
    limit,
    setLimit,
    sortField,
    sortOrder,
    selection,
    setSortField,
    setSortOrder,
    setSelection,
  } = useTableState<CustomerOrder>(tableStorageKey);

  const { selectedColumns, setSelectedColumns, columnOptions, columns } =
    useTableColumns(
      page,
      limit,
      'reports_customerOrders',
      listsColumnHeadersMap,
      listsColumnHeadersMap,
      (column) => additionalListsColumnProperties(t, column)
    );

  const { filters, headerFiltersCount, resetAllFilters, httpFiltersObj } =
    useTableFilters(page, setPage, limit);

  const canLoadData = httpFiltersObj.date_from && httpFiltersObj.date_to;

  const { data, error, reload, isLoading } = useAxiosHook<ApiData>(
    '/reports/client-orders' + queryString(httpQueryObject(httpFiltersObj)),
    { skipWhen: !canLoadData }
  );

  const {
    show: showCustomerOrderDialog,
    hide: hideCustomerOrderDialog,
    isVisible: isViewCustomerOrderDialogVisible,
  } = useRouteDialog(
    RoutePaths.CustomerOrders,
    constructIdRoute(RoutePaths.ViewCustomerOrder, id ?? selection?.id)
  );

  usePageTitleToggler(
    t('Orders by {{name}}', {
      name: selection?.ime ?? '',
    }),
    t('Customer Order'),
    isViewCustomerOrderDialogVisible
  );

  function handleViewDialogHide() {
    hideCustomerOrderDialog();
  }

  const contextMenuModel = useMemo(
    () =>
      generateContextMenu({ t, handleCMViewClick: showCustomerOrderDialog }),
    [showCustomerOrderDialog, t]
  );

  function handleExport(type: string) {
    downloadFile(
      `/reports/client-orders/export/${type}` +
        queryString(_.omit(httpFiltersObj, ['page', 'limit']) as any),
      getFileName(t('Customer Orders'), undefined, true),
      type === 'excel' ? FileTypes.XLSX : FileTypes.CSV,
      bottomRightToastRef
    );
  }

  return (
    <div className="page customer-orders">
      <HeaderPages
        title={t('Customer Orders')}
        subtitle={t('View customer orders')}
        icon={faClipboardCheck}
      />

      <ViewCustomerOrder
        isShown={isViewCustomerOrderDialogVisible}
        onHide={handleViewDialogHide}
      />

      <Flex direction="column">
        <Filters
          filters={filters}
          resetAllFilters={resetAllFilters}
          headerFiltersCount={headerFiltersCount}
          filterHeight={220}
        />
        <MainContent>
          <Table
            tableRef={tableRef}
            columns={columns}
            data={data}
            hasError={!!error}
            isLoading={isLoading}
            reload={reload}
            isReloadDisabled={!canLoadData}
            headerTitle=""
            setPage={setPage}
            setLimit={setLimit}
            sortField={sortField}
            rows={limit}
            filterHeight={220}
            setSortField={setSortField}
            setSortOrder={setSortOrder}
            setSelection={setSelection}
            sortOrder={sortOrder}
            selection={selection}
            onRowDoubleClick={showCustomerOrderDialog}
            contextMenuModel={contextMenuModel}
            storageString={tableStorageKey}
            rebuildTooltip
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            columnOptions={columnOptions}
            exportToExcelButton
            onExportToExcelButtonClick={() => handleExport('excel')}
            exportToCSVButton
            onExportToCSVButtonClick={() => handleExport('csv')}
            rowsPerPageOptions={rowsPerPageOptions500}
          />
        </MainContent>
      </Flex>
    </div>
  );
}

export default CustomerOrders;
