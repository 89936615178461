import { TFunction } from 'react-i18next';

import { generateNotificationsObject } from '../CreateEditDialog/CreateEditDialog.functions';

export function getInitialValues(t: TFunction, data: any) {
  return {
    cenovnik_id: '',
    notification: generateNotificationsObject(t, data),
  };
}
