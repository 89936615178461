import './CreateEditDialog.scss';

import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Field, useFormikContext } from 'formik';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { useTranslation } from 'react-i18next';

import { isStreetFreeInputAllowed } from '../../../../../../utils/constants/misc';
import FieldWithErrors from '../../../../../Forms/FieldWithErrors/FieldWithErrors';

function Location({
  step,
  municipalitiesOptions,
  isLoadingMunicipalities,
  places,
  placesOptions,
  isLoadingPlaces,
  reloadPlaces,
  streets,
  streetsOptions,
  isLoadingStreets,
  reloadStreets,
  isNameUsed,
}) {
  const { t } = useTranslation();

  const { values, errors, touched, setFieldValue } = useFormikContext();

  return (
    <div className="p-fluid">
      <span className="section-title">
        {step}. {t('Location')}
      </span>
      <div>
        <div className="address">
          <FieldWithErrors name="municipality_id" label={t('Municipality')}>
            <Field
              name="municipality_id"
              as={Dropdown}
              inputId="municipality_id"
              options={municipalitiesOptions}
              filter
              filterPlaceholder={t('Search')}
              showClear={false}
              placeholder={isLoadingMunicipalities ? t('Loading...') : null}
              onChange={(e) => {
                let municipality = municipalitiesOptions.find(
                  (m) => m.value === e.value
                );

                setFieldValue('municipality_name', municipality?.label);
                setFieldValue('municipality_id', e.value);
                setFieldValue('place_id', '');
                setFieldValue('street_name', '');
                setFieldValue('adresa', '');
                setFieldValue('street_id', '');
                setFieldValue('street_no', '');

                reloadPlaces({
                  url: `municipalities/${e.value}/places`,
                });

                setFieldValue('place_id', '');
                setFieldValue('street_name', '');
                setFieldValue('adresa', '');
                setFieldValue('street_id', '');
                setFieldValue('street_no', '');
              }}
              className="data-cy-municipality"
            />
          </FieldWithErrors>

          <FieldWithErrors
            name="place_id"
            label={t('Place')}
            includeErrorsFor={['place_id']}
          >
            <Field
              disabled={!values.municipality_id || isLoadingPlaces}
              name="place_id"
              inputId="place_id"
              as={Dropdown}
              options={placesOptions}
              filter
              filterPlaceholder={t('Search')}
              showClear={false}
              placeholder={isLoadingPlaces ? t('Loading...') : null}
              onChange={(e) => {
                if (e.value) {
                  const place = places.find((p) => p.id === e.value);

                  reloadStreets({
                    url: `places/${place.id}/streets`,
                  });
                  setFieldValue('place_id', e.value);
                  setFieldValue('place_name', place.ime);
                  setFieldValue('street_name', '');
                  setFieldValue('adresa', '');
                  setFieldValue('street_id', '');
                  setFieldValue('street_no', '');
                } else {
                  setFieldValue('place_id', '');
                  setFieldValue('place_name', '');
                  setFieldValue('street_name', '');
                  setFieldValue('adresa', '');
                  setFieldValue('street_id', '');
                  setFieldValue('street_no', '');
                }
              }}
              className={classNames('data-cy-place', {
                invalid: errors.place_id && touched.place_id,
              })}
            />
          </FieldWithErrors>

          <div className="street-wrapper">
            <div className="street">
              <FieldWithErrors
                name="street_id"
                label={isStreetFreeInputAllowed ? t('Address') : t('Street')}
              >
                {isStreetFreeInputAllowed ? (
                  <Field
                    as={InputText}
                    name="adresa"
                    id="adresa"
                    disabled={!values.municipality_id || !values.place_id}
                    maxLength="256"
                  />
                ) : (
                  <Field
                    disabled={
                      !values.municipality_id ||
                      !values.place_id ||
                      isLoadingStreets
                    }
                    name="street_id"
                    inputId="street_id"
                    as={Dropdown}
                    options={streetsOptions}
                    filter
                    filterPlaceholder={t('Search')}
                    showClear={false}
                    placeholder={isLoadingStreets ? t('Loading...') : null}
                    onChange={(e) => {
                      const street = streets.find((p) => p.id === e.value);

                      setFieldValue('street_name', street.ime);
                      setFieldValue('adresa', street.ime);
                      setFieldValue('street_id', e.value);
                      setFieldValue('street_no', '');
                    }}
                    className={classNames('data-cy-street', {
                      invalid: errors.street && touched.street,
                    })}
                  />
                )}
              </FieldWithErrors>
            </div>

            <div className="street-no">
              <FieldWithErrors name="street_no" label={t('Street No.')}>
                <Field
                  className={`street_no p-inputtext p-component ${
                    errors.street_no && touched.street_no && 'invalid'
                  }`}
                  as={InputText}
                  name="street_no"
                  id="street_no"
                  maxLength="45"
                  disabled={
                    isStreetFreeInputAllowed
                      ? !values.adresa
                      : !values.street_id
                  }
                  data-cy="street_no"
                />
              </FieldWithErrors>
            </div>
          </div>
        </div>
      </div>

      <div className={isNameUsed ? 'show-section' : 'hide-section'}>
        <FontAwesomeIcon icon={faExclamationCircle} />
        <span>{t('Error on 1st step, Name is already in use.')}</span>
      </div>
    </div>
  );
}

export default Location;
