export enum CandidatesFilter {
  First = 'first',
  Last = 'last',
  Hidden = 'hidden',
}

export enum WarehousePage {
  CourierShipmentAssignment,
  HubShipmentDistribution,
  CourierShipmentReception,
  HubShipmentReception,
  TransferCourierToCourier,
}
