import '../TrackShipmentsByLocation.scss';

import { faSearchLocation } from '@fortawesome/free-solid-svg-icons';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import SidebarItem from '../../../../Grid/Sidebar/SidebarItem';
import {
  ShipmentLocation,
  renderLocations,
} from '../TrackShipmentsByLocation.functions';

type Props = {
  data: ShipmentLocation[];
  setOpenPanelGroup: Dispatch<SetStateAction<string[]>>;
  openPanelGroup: string[];
  selectedLocation: string | undefined;
  setSelectedLocation: Dispatch<SetStateAction<string | undefined>>;
};

function ShipmentsLocation({
  data,
  setOpenPanelGroup,
  openPanelGroup,
  selectedLocation,
  setSelectedLocation,
}: Props) {
  const { t } = useTranslation();

  const menuItems = useMemo(() => {
    return data.map((l: ShipmentLocation) => {
      return (
        <div key={l.id}>
          {renderLocations(
            l,
            setOpenPanelGroup,
            openPanelGroup,
            selectedLocation,
            setSelectedLocation
          )}
        </div>
      );
    });
  }, [
    data,
    openPanelGroup,
    selectedLocation,
    setOpenPanelGroup,
    setSelectedLocation,
  ]);

  return (
    <SidebarItem
      title={t('Entity')}
      icon={faSearchLocation}
      className="shipment-locations"
    >
      {menuItems}
    </SidebarItem>
  );
}

export default ShipmentsLocation;
