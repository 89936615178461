import _ from 'lodash';
import { useCallback, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { useEndpointGuard } from './useEndpointGuard';

type ReturnValues = {
  show: () => void;
  hide: () => void;
  isVisible: boolean;
};

function useRouteDialog(
  baseRoute: string,
  dialogRoute: string,
  guard?: ReturnType<typeof useEndpointGuard>
): ReturnValues {
  const history = useHistory();
  const routeMatch = useRouteMatch(dialogRoute);

  const show = useCallback<() => void>(() => {
    history.replace({
      ..._.omit(history.location, 'key'),
      pathname: dialogRoute,
    });
  }, [dialogRoute, history]);

  const hide = useCallback<() => void>(() => {
    if (!routeMatch) {
      return;
    }

    history.replace({
      ..._.omit(history.location, 'key'),
      pathname: `${baseRoute}`,
    });
  }, [baseRoute, history, routeMatch]);

  useEffect(() => {
    if (guard === false) {
      hide();
    }
  }, [guard, hide]);

  return { show, hide, isVisible: !!routeMatch };
}

export default useRouteDialog;
