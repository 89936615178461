import './ReceptionAndDeliveryMap.scss';

import {
  faInfoCircle,
  faMapMarkedAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { Field, Form, Formik } from 'formik';
import L from 'leaflet';
import _ from 'lodash';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MapContainer, Marker, Popup, Tooltip } from 'react-leaflet';
import { useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import shadowPin from '../../../assets/img/map-pins/shadow.png';
import useAxios from '../../../hooks/useAxios';
import useDynamicTileLayer from '../../../hooks/useDynamicTileLayer';
import { useEndpointGuard } from '../../../hooks/useEndpointGuard';
import useMediaQuery from '../../../hooks/useMediaQuery';
import usePageTitle from '../../../hooks/usePageTitle';
import useSearchQueryDropdownParam from '../../../hooks/useSearchQueryDropdownParam';
import * as receptionAndDeliveryMapGuards from '../../../utils/constants/auth/receptionAndDeliveryMap';
import { defaultTileLayerProvider } from '../../../utils/constants/map';
import {
  RoutePaths,
  constructIdRoute,
} from '../../../utils/constants/routePaths';
import { getMapProps } from '../../../utils/globals';
import { formik_handleErrorsUponSubmission } from '../../../utils/helpers';
import { httpDateFormat } from '../../../utils/helpers/formatting';
import { getSearchQueryParam } from '../../../utils/helpers/searchQuery';
import FieldWithErrors from '../../Forms/FieldWithErrors/FieldWithErrors';
import HeaderPages from '../Components/HeaderPages/HeaderPages';
import AutoSubmitOnFilterChange from './AutoSubmitOnFilterChange';
import ViewOrderDialog from './Dialogs/ViewOrderDialog';
import FiltersSync from './FiltersSync';
import {
  generateParcelIcon,
  getInitialValues,
  getParcelStatusMap,
  toApiData,
} from './ReceptionAndDeliveryMap.functions';

function ReceptionAndDeliveryMap() {
  const { t } = useTranslation();

  const readGuard = useEndpointGuard(receptionAndDeliveryMapGuards.readParcel);

  const location = useLocation();

  const mapProps = useMemo(() => getMapProps(t), [t]);
  const parcelStatusMap = useMemo(() => getParcelStatusMap(t), [t]);

  usePageTitle(t('Reception And Delivery Map'));

  const mapLegend = useMemo(
    () =>
      Object.keys(parcelStatusMap).map((key) => (
        <div key={key} className="map-legend-item">
          <img src={parcelStatusMap[key].icon} alt="" />

          <span className="label">{parcelStatusMap[key].label}</span>
        </div>
      )),
    [parcelStatusMap]
  );

  const [mapTileLayerProvider, setMapTileLayerProvider] = useState(
    getSearchQueryParam(location.search, 'mapType') ?? defaultTileLayerProvider
  );

  const DynamicTileLayer = useDynamicTileLayer(mapTileLayerProvider);

  const formRef = useRef(null);

  const history = useHistory();

  const [pickedupParcelsResp, setPickedupParcelsResp] = useState([]);
  const [deliveredParcelsResp, setDeliveredParcelsResp] = useState([]);

  const [parcelTypeFilter, setParcelTypeFilter] = useState(
    getSearchQueryParam(location.search, 'parcelType') ?? 'all'
  );

  const [dialogData, setDialogData] = useState([]);
  const [isViewInfoDialogVisible, setIsViewInfoDialogVisible] = useState(false);

  const [isViewOrderDialogOpen, setIsViewOrderDialogOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const parcelTypeOptions = useMemo(
    () => [
      {
        label: t('All'),
        value: 'all',
      },
      {
        label: t('Delivered'),
        value: 'delivered',
      },
      {
        label: t('Picked up'),
        value: 'picked-up',
      },
    ],
    [t]
  );

  useSearchQueryDropdownParam(
    'parcelType',
    parcelTypeFilter,
    setParcelTypeFilter,
    parcelTypeOptions,
    false
  );

  useSearchQueryDropdownParam(
    'mapType',
    mapTileLayerProvider,
    setMapTileLayerProvider,
    mapProps.tileLayerProviderOptions,
    false
  );

  useEffect(() => {
    if (!readGuard) {
      setIsViewOrderDialogOpen(false);
    }
  }, [readGuard]);

  const filterParcelType = useMemo(() => {
    let filterObj = {
      delivered: false,
      pickedup: false,
    };

    if (parcelTypeFilter === 'delivered') {
      filterObj.delivered = true;
    } else if (parcelTypeFilter === 'picked-up') {
      filterObj.pickedup = true;
    }

    return filterObj;
  }, [parcelTypeFilter]);

  function countParcelTypes(parcelArr) {
    const parcelTypesObj = {
      deliverySuccess: 0,
      pickupSuccess: 0,
      deliveryFailure: 0,
      pickupFailure: 0,
    };

    for (let parcel in parcelArr) {
      if (parcelArr[parcel].parcelType === 'Delivery parcel') {
        if (parcelArr[parcel].success === 1) {
          parcelTypesObj.deliverySuccess++;
        }

        if (parcelArr[parcel].success === 0) {
          parcelTypesObj.deliveryFailure++;
        }
      }

      if (parcelArr[parcel].parcelType === 'Pickup parcel') {
        if (parcelArr[parcel].success === 1) {
          parcelTypesObj.pickupSuccess++;
        }

        if (parcelArr[parcel].success === 0) {
          parcelTypesObj.pickupFailure++;
        }
      }
    }

    return (
      <div>
        <span className="info-parcel-type">{t('Delivery success')}</span>
        <span className="info-parcel-value">
          {parcelTypesObj.deliverySuccess}
        </span>
        <br />

        <span className="info-parcel-type">{t('Delivery failure')}</span>
        <span className="info-parcel-value">
          {parcelTypesObj.deliveryFailure}
        </span>
        <br />

        <span className="info-parcel-type">{t('Pick up success')}</span>
        <span className="info-parcel-value">
          {parcelTypesObj.pickupSuccess}
        </span>
        <br />

        <span className="info-parcel-type">{t('Pick up failure')}</span>
        <span className="info-parcel-value">
          {parcelTypesObj.pickupFailure}
        </span>
        <br />
      </div>
    );
  }

  const {
    data: couriersData,
    isLoading: isLoadingCouriers,
    hasError,
  } = useAxios('/employees/all');

  const couriersOptions = useMemo(
    () =>
      couriersData?.data
        ?.filter((employee) => employee.uloga_id.includes(3))
        .map((courier) => ({
          label: courier.ime + ' ' + courier.prezime,
          value: courier.id,
        })) ?? [],
    [couriersData?.data]
  );

  const { reload: formSubmissionRequest } = useAxios();

  const handleFormSubmision = useCallback(
    (values, parcelTypeFilter) => {
      if (parcelTypeFilter !== 'delivered') {
        formSubmissionRequest({
          url: `/pickupattempts`,
          payload: toApiData(values),
          method: 'POST',
          successCallback: (resp) => {
            setPickedupParcelsResp(resp);
          },
          errorCallback: (err) => {
            if (
              formik_handleErrorsUponSubmission(
                err?.response?.data?.error,
                formRef
              )
            ) {
              return;
            }
          },
        });
      }

      if (parcelTypeFilter !== 'picked-up') {
        formSubmissionRequest({
          url: `/deliveryattempts`,
          payload: toApiData(values),
          method: 'POST',
          successCallback: (resp) => {
            setDeliveredParcelsResp(resp);
          },
          errorCallback: (err) => {
            if (
              formik_handleErrorsUponSubmission(
                err?.response?.data?.error,
                formRef
              )
            ) {
              return;
            }
          },
        });
      }
    },
    [formSubmissionRequest]
  );

  const parcels = useMemo(() => {
    let deliveredParcels = [];
    let pickedupParcesl = [];

    if (filterParcelType.pickedup) {
      deliveredParcels = [];
    } else if (deliveredParcelsResp && deliveredParcelsResp.length) {
      deliveredParcels = deliveredParcelsResp.map((p) => {
        const statusMessage =
          p.success === 1 ? t('Successfully delivered') : t('Failed delivery');

        return {
          ...p,
          statusMessage,
          datum: httpDateFormat(p.date),
          vreme: dayjs(p.date).format('HH:mm'),
          parcelType: 'Delivery parcel',
        };
      });
    }

    if (filterParcelType.delivered) {
      pickedupParcesl = [];
    } else if (pickedupParcelsResp && pickedupParcelsResp.length) {
      pickedupParcesl = pickedupParcelsResp.map((p) => {
        const statusMessage =
          p.success === 1 ? t('Successfully picked up') : t('Failed pickup');

        return {
          ...p,
          statusMessage,
          datum: httpDateFormat(p.date),
          vreme: dayjs(p.date).format('HH:mm'),
          parcelType: t('Pickup parcel'),
        };
      });
    }

    const allParcels = _.concat(deliveredParcels, pickedupParcesl);

    let markerId = 1;

    return _.map(
      _.groupBy(allParcels, function (p) {
        return p.latitude && p.longitude;
      }),
      (value) => {
        const locationObj = {
          marker: markerId,
          lat: value[0].latitude,
          lng: value[0].longitude,
          parcels: _.map(value),
        };

        markerId++;

        return locationObj;
      }
    );
  }, [
    filterParcelType.pickedup,
    filterParcelType.delivered,
    deliveredParcelsResp,
    pickedupParcelsResp,
    t,
  ]);

  useEffect(() => {
    if (
      formRef.current?.values.courier_id ||
      formRef.current?.values.serial_number
    ) {
      const reloadInterval = setInterval(() => {
        handleFormSubmision(formRef.current?.values, parcelTypeFilter);
      }, 30000);

      return () => {
        clearInterval(reloadInterval);
      };
    }
  }, [handleFormSubmision, parcelTypeFilter]);

  function onHide() {
    setDialogData([]);
    setIsViewInfoDialogVisible(false);
  }

  const handleViewOrder = useCallback((parcel) => {
    setSelectedOrder(parcel.adresnica_id);
    setIsViewOrderDialogOpen(true);
  }, []);

  function handleHideViewOrderDialog() {
    setIsViewOrderDialogOpen(false);
    setSelectedOrder(null);
  }

  function handleViewHistory() {
    if (!selectedOrder) {
      return;
    }

    history.push(constructIdRoute(RoutePaths.OrdersHistory, selectedOrder));
  }

  const generateParcelInfo = useCallback(
    (parcels) => {
      return parcels.map((parcel) => {
        return (
          <div className="info-parcel-container" key={parcel.id}>
            <span className="info-title">{t('Courier')}</span>
            <span className="info-value">
              {parcel.courier_firstname} {parcel.courier_lastname}
            </span>
            <br />

            <span className="info-title">{t('Date')}</span>
            <span className="info-value">{parcel.datum}</span>
            <br />

            <span className="info-title">{t('Time')}</span>
            <span className="info-value">{parcel.vreme}</span>
            <br />

            <span className="info-title">{t('Serial Number')}</span>
            <span className="info-value">
              {parcel.seriski_broj ? (
                readGuard ? (
                  <Button
                    label={parcel.seriski_broj}
                    className="p-button-link serial-no-link-btn"
                    onClick={() => {
                      handleViewOrder(parcel);
                    }}
                  />
                ) : (
                  parcel.seriski_broj
                )
              ) : (
                <i>{t('No serial number provided')}</i>
              )}
            </span>
            <br />

            <span className="info-title">{t('Status')}</span>
            <span className="info-value">{parcel.statusMessage}</span>
          </div>
        );
      });
    },
    [handleViewOrder, readGuard, t]
  );

  function openViewDialog(parcelArr) {
    setDialogData(parcelArr);
    setIsViewInfoDialogVisible(true);
  }

  const generatePopupContent = useCallback(
    (parcelArr) => {
      if (parcelArr.length > 1) {
        return (
          <>
            <Tooltip>{t('Multiple parcels')}</Tooltip>

            <Popup>
              <span className="total-parcel">
                {t('Total {{parcelArrLength}} parcels', {
                  parcelArrLength: parcelArr.length,
                })}
              </span>

              <Button
                type="button"
                className="p-button-outlined more-info-btn"
                label={t('Show more info')}
                onClick={() => openViewDialog(parcelArr)}
              />

              {parcelArr.map((parcel) => {
                return (
                  <div className="info-parcel-container" key={parcel.id}>
                    <span className="info-title">{t('Serial Number')}</span>

                    <span className="info-value">
                      {parcel.seriski_broj ? (
                        readGuard ? (
                          <Button
                            label={parcel.seriski_broj}
                            className="p-button-link serial-no-link-btn"
                            onClick={() => {
                              handleViewOrder(parcel);
                            }}
                          />
                        ) : (
                          parcel.seriski_broj
                        )
                      ) : (
                        <i>{t('No serial number provided')}</i>
                      )}
                    </span>
                    <br />

                    <span className="info-title">{t('Status')}</span>

                    <span className="info-value">{parcel.statusMessage}</span>
                  </div>
                );
              })}
            </Popup>
          </>
        );
      } else {
        return (
          <>
            <Tooltip>{parcelArr[0].parcelType}</Tooltip>
            <Popup>{generateParcelInfo(parcelArr)}</Popup>
          </>
        );
      }
    },
    [generateParcelInfo, handleViewOrder, readGuard, t]
  );

  const parcelMarkers = useMemo(
    () =>
      parcels?.map((locationObj) => {
        const parcelStatusIcon = L.icon({
          iconUrl: generateParcelIcon(t, locationObj),
          shadowUrl: shadowPin,
          iconSize: [36, 36],
          shadowSize: [36, 36],
          iconAnchor: [36, 36],
          shadowAnchor: [22, 35],
          popupAnchor: [-18, -40],
          tooltipAnchor: [0, -18],
        });

        return (
          <Marker
            key={locationObj.marker}
            icon={parcelStatusIcon}
            position={[locationObj.lat, locationObj.lng]}
          >
            {generatePopupContent(locationObj.parcels)}
          </Marker>
        );
      }),
    [parcels, t, generatePopupContent]
  );

  const validationSchema = Yup.object().shape(
    {
      courier_id: Yup.string().when('serial_number', {
        is: (value) => !value,
        then: Yup.string().nullable().required(t('Courier is required')),
        otherwise: Yup.string().nullable(),
      }),
      serial_number: Yup.string().when('courier_id', {
        is: (value) => !value || value === null,
        then: Yup.string().required(t('Serial number is required')),
        otherwise: Yup.string(),
      }),
    },
    ['courier_id', 'serial_number']
  );

  const isOnMobile = useMediaQuery('(max-width: 768px)');

  return (
    <div className="page parcel-map-page">
      <HeaderPages
        title={t('Reception and Delivery Map')}
        subtitle={t('See parcel pickup and delivery location on map')}
        icon={faMapMarkedAlt}
      />

      <ViewOrderDialog
        isShown={isViewOrderDialogOpen}
        dataId={selectedOrder}
        onHide={handleHideViewOrderDialog}
        onHistory={handleViewHistory}
      />

      <div className="p-grid">
        <div className="p-col">
          <MapContainer
            zoom={parcels.length ? 13 : mapProps.defaultZoom}
            center={
              parcels.length
                ? [parcels[0].lat, parcels[0].lng]
                : mapProps.macedoniaCoordinates
            }
            maxBounds={mapProps.mapBoundsPoints}
            minZoom={mapProps.minZoom}
            maxZoom={mapProps.maxZoom}
            className="map p-shadow-2"
          >
            <DynamicTileLayer />

            {parcelMarkers}
          </MapContainer>
        </div>

        <div className="p-col-fixed" style={{ width: '300px' }}>
          <div className="map-settings p-shadow-2">
            {pickedupParcelsResp?.length === 0 &&
              deliveredParcelsResp?.length === 0 && (
                <div className="api-status-info no-couriers-found">
                  <FontAwesomeIcon icon={faInfoCircle} />

                  <span>{t('No parcels found')}</span>
                </div>
              )}

            <div className="p-fluid">
              <Formik
                innerRef={formRef}
                initialValues={getInitialValues(location)}
                enableReinitialize
                validationSchema={validationSchema}
                validateOnChange
                validateOnBlur={false}
                onSubmit={(values) => {
                  handleFormSubmision(values, parcelTypeFilter);
                }}
              >
                {({ values }) => (
                  <Form>
                    <FiltersSync courierOptions={couriersOptions} />
                    <AutoSubmitOnFilterChange formRef={formRef} />

                    <div className="p-fluid">
                      <FieldWithErrors name="courier_id" label={t('Courier')}>
                        <Field
                          name="courier_id"
                          inputId="courier_id"
                          as={Dropdown}
                          options={couriersOptions}
                          filter
                          value={
                            values.serial_number ? null : values.courier_id
                          }
                          placeholder={
                            values.serial_number
                              ? t('All couriers')
                              : t('Select a courier')
                          }
                          disabled={
                            !!(
                              isLoadingCouriers ||
                              !couriersData ||
                              couriersData.length === 0 ||
                              hasError ||
                              values.serial_number
                            )
                          }
                        />
                      </FieldWithErrors>
                    </div>

                    <div className="p-fluid">
                      <FieldWithErrors name="date" label={t('Date')}>
                        <Field
                          name="date"
                          inputId="date"
                          value={values.serial_number ? null : values.date}
                          placeholder={t('All dates')}
                          as={Calendar}
                          monthNavigator
                          yearNavigator
                          yearRange={`2000:${dayjs().format('YYYY')}`}
                          dateFormat="dd/mm/yy"
                          maxDate={new Date()}
                          readOnlyInput
                          disabled={!!values.serial_number}
                        />
                      </FieldWithErrors>
                    </div>

                    <div className="p-field">
                      <label htmlFor="parcel_type">{t('Parcel type')}</label>

                      <Dropdown
                        name="parcel_type"
                        inputId="parcel_type"
                        value={parcelTypeFilter}
                        placeholder={t('Parcel type')}
                        options={parcelTypeOptions}
                        optionLabel="label"
                        onChange={(e) => {
                          setParcelTypeFilter(e.target.value);
                        }}
                        disabled={!!values.serial_number}
                      />
                    </div>

                    <div className="p-fluid">
                      <FieldWithErrors
                        name="serial_number"
                        label={t('Serial Number')}
                      >
                        <Field
                          name="serial_number"
                          id="serial_number"
                          as={InputText}
                          disabled={isLoadingCouriers || hasError}
                        />
                      </FieldWithErrors>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>

            <div className="p-fluid p-mt-5">
              <div className="p-field">
                <label htmlFor="map_type">{t('Map type')}</label>

                <Dropdown
                  inputId="map_type"
                  value={mapTileLayerProvider}
                  placeholder={t('Select map type')}
                  options={mapProps.tileLayerProviderOptions}
                  onChange={(e) => {
                    setMapTileLayerProvider(e.value);
                  }}
                />
              </div>
            </div>

            <span>{t('Legend')}</span>

            <div className="map-legend">{mapLegend}</div>
          </div>

          <Dialog
            visible={isViewInfoDialogVisible}
            onHide={onHide}
            resizable={false}
            maximizable
            maximized={isOnMobile}
            header={t('Parcels Info')}
            className="parcel-map-parcel-info-dialog"
          >
            <span className="total-parcel">
              {t('Total {{dialogDataLength}} parcels', {
                dialogDataLength: dialogData.length,
              })}
            </span>

            {countParcelTypes(dialogData)}

            {generateParcelInfo(dialogData)}
          </Dialog>
        </div>
      </div>
    </div>
  );
}

export default ReceptionAndDeliveryMap;
