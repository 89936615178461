import './TableHeader.scss';

import { faColumns, faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Sentry from '@sentry/react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import { useTranslation } from 'react-i18next';

import useMediaQuery from '../../hooks/useMediaQuery';

function TableHeader({
  title,
  filters,
  mobileFilters,
  chipFilters,
  onChipFiltersBtnClick,
  columnOptions,
  selectedColumns,
  setSelectedColumns,
  storageKey,
  breakpoint = '768px',
  inputIdPrefix = '',
}) {
  const { t } = useTranslation();
  const isMobileDisplay = useMediaQuery(`(max-width: ${breakpoint})`);

  function handleColumnChange(event) {
    const newSelectedColumns =
      event.value.length > 0 ? event.value : columnOptions;

    setSelectedColumns(newSelectedColumns);

    try {
      sessionStorage.setItem(
        `${storageKey}SelectedColumns`,
        JSON.stringify(newSelectedColumns)
      );
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  const multiSelect = (
    <MultiSelect
      inputId={`${inputIdPrefix ? inputIdPrefix + '-' : ''}customize-columns`}
      value={selectedColumns}
      options={columnOptions}
      maxSelectedLabels={1}
      selectedItemsLabel={t('{0} selected')}
      onChange={handleColumnChange}
    />
  );

  return (
    <div className="custom-table-header">
      {isMobileDisplay ? (
        <Accordion legend={t('Expand')} toggleable className="sm-section">
          {mobileFilters || filters ? (
            <AccordionTab header={t('Filters')}>
              <div className="p-fluid">{mobileFilters || filters}</div>
            </AccordionTab>
          ) : title ? (
            <h3 className="title">{title}</h3>
          ) : (
            [] // Accordion throws an error if a falsy value is returned (probably maps over its children)
          )}

          <AccordionTab header={t('Columns')}>
            <div className="p-fluid">
              <div className="p-field">
                <label
                  htmlFor={`${
                    inputIdPrefix ? inputIdPrefix + '-' : ''
                  }customize-columns`}
                >
                  {t('Columns')}
                </label>
                {multiSelect}
              </div>
            </div>
          </AccordionTab>
        </Accordion>
      ) : (
        <div className="header-section-wrapper">
          {chipFilters ? (
            <div className="header-section chip-filters">
              <span
                className="icon"
                data-tip={t('Filters')}
                data-for="tooltip-right"
              >
                <FontAwesomeIcon icon={faFilter} />
              </span>

              <Button
                type="button"
                label={t('Manage filters')}
                className="p-button-outlined p-button-secondary manage-filters-btn"
                onClick={onChipFiltersBtnClick}
              />

              {chipFilters.map(
                (cf, i) =>
                  cf.value && (
                    <div
                      key={i}
                      className="p-overlay-badge filter-overlay-badge"
                    >
                      {cf.label}:<span className="value">{cf.value}</span>
                      {cf.onRemove && (
                        <span className="p-badge p-badge-danger">
                          <Button
                            type="button"
                            icon="fas fa-times"
                            className="p-button-rounded p-button-danger"
                            onClick={cf.onRemove}
                          />
                        </span>
                      )}
                    </div>
                  )
              )}
            </div>
          ) : filters ? (
            <div className="header-section">
              <span
                className="icon"
                data-tip={t('Filters')}
                data-for="tooltip-right"
              >
                <FontAwesomeIcon icon={faFilter} />
              </span>

              {filters}
            </div>
          ) : title ? (
            <h3 className="title">{title}</h3>
          ) : null}

          <div className="header-section customize-columns">
            <span
              className="icon"
              data-tip={t('Columns')}
              data-for="tooltip-left"
            >
              <FontAwesomeIcon icon={faColumns} />
            </span>

            {multiSelect}
          </div>
        </div>
      )}
    </div>
  );
}

export default TableHeader;
