import _ from 'lodash';
import { TFunction } from 'react-i18next';
import * as Yup from 'yup';

import {
  CreatePriceListResourceRequestPayload,
  PriceListResource,
  UpdatePriceListResourceRequestPayload,
} from '../../../../../types/api/priceLists';
import { tryInt } from '../../../../../utils/helpers/parse';
import { yupRequiredField } from '../../../../../utils/helpers/yup';
import { FormFields } from './CreateEditDialog.types';

export function getDefaultValues(
  isEditDialog: boolean,
  data: PriceListResource | undefined
): FormFields {
  return {
    name: isEditDialog ? data?.name ?? '' : '',
    description: isEditDialog ? data?.description ?? '' : '',
    parent_id: isEditDialog
      ? data?.parent_id && data.parent_id === data.id
        ? '-1'
        : String(data?.parent_id) ?? '-1'
      : '-1',
  };
}

export function toCreateResourceApiData(
  values: FormFields
): CreatePriceListResourceRequestPayload {
  return values.parent_id !== '-1' ? values : _.omit(values, 'parent_id');
}

export function toUpdateResourceApiData(
  values: FormFields,
  data: PriceListResource | undefined
): UpdatePriceListResourceRequestPayload {
  const parentId =
    values.parent_id === '-1' ? data?.id : tryInt(values?.parent_id);

  return typeof parentId === 'number'
    ? {
        ...values,
        parent_id: parentId,
      }
    : _.omit(values, 'parent_id');
}

export function getValidationSchema(
  t: TFunction
): Yup.SchemaOf<Partial<FormFields>> {
  return Yup.object().shape({
    name: Yup.string().required(yupRequiredField(t, t('Name'))),
    description: Yup.string().required(yupRequiredField(t, t('Description'))),
    parent_id: Yup.string().required(yupRequiredField(t, t('Base price list'))),
  });
}
