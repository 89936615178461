import './QRDialog.scss';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { SplitButton } from 'primereact/splitbutton';
import QRCode from 'qrcode.react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { EmployeeCollection } from '../../../../types/api/employees';
import { getDownloadButtonItems } from './QRDialog.functions';

// TODO: change data after refactor
type Props = {
  data:
    | (EmployeeCollection['data'][0] & {
        qrCode: string;
      })
    | null;
  isShown: boolean;
  onHide: () => void;
};

function QRDialog({ data, isShown, onHide }: Props) {
  const { t } = useTranslation();

  const dialogFooter = (
    <Button type="button" label="Close" onClick={onHide} data-cy="close-btn" />
  );

  const downloadButtonItems = useMemo(
    () => getDownloadButtonItems(t, data, data?.qrCode ?? ''),
    [data, t]
  );

  return (
    <Dialog
      visible={isShown}
      onHide={onHide}
      header={t('QR Code')}
      footer={dialogFooter}
      dismissableMask
      resizable={false}
      className="employees-qr-dialog"
    >
      <div className="info-item">
        <h4>{t('Employee')}</h4>
        <span>
          {data?.ime} {data?.prezime}
        </span>
      </div>

      <div className="info-item">
        <h4>{t('Application')}</h4>
        <span>{data?.app?.name}</span>
      </div>

      {isShown && (
        <>
          <h4>{t('QR Code')}</h4>
          <QRCode
            value={data?.qrCode ?? ''}
            level="L"
            size={256}
            className="qr-code"
          />
        </>
      )}

      <div className="p-mt-2 p-text-center">
        <SplitButton
          label={t('Download')}
          model={downloadButtonItems}
          onClick={downloadButtonItems[0].command}
          className="p-button-secondary"
        />
      </div>
    </Dialog>
  );
}

export default QRDialog;
