import { Field, useFormikContext } from 'formik';
import { InputText } from 'primereact/inputtext';

import FieldWithErrors from '../../../../../../Forms/FieldWithErrors/FieldWithErrors';

function PersonalSubjectNoField({ label, maxLength }) {
  const { setFieldValue } = useFormikContext();

  return (
    <FieldWithErrors name="maticen_broj" label={label}>
      <Field
        as={InputText}
        name="maticen_broj"
        id="maticen_broj"
        maxLength={maxLength}
        onChange={(e) => {
          setFieldValue('maticen_broj', e.target.value.replace(/\D/g, ''));
        }}
        data-cy="ssn"
      />
    </FieldWithErrors>
  );
}

export default PersonalSubjectNoField;
