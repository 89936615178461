import { TFunction } from 'react-i18next';
import * as Yup from 'yup';

import {
  yupMaxField,
  yupMinField,
  yupRequiredField,
} from '../../../../../utils/helpers/yup';
import { OAuthClient } from '../../OAuthClients.functions';

export type FormFields = {
  name: string;
};

export function getInitialValues(
  isEditDialog: boolean,
  data: OAuthClient | undefined
): FormFields {
  return {
    name: isEditDialog ? data?.client_name ?? '' : '',
  };
}

export function getValidationSchema(t: TFunction) {
  return Yup.object().shape({
    name: Yup.string()
      .required(yupRequiredField(t, t('Name')))
      .min(3, yupMinField(t, t('Name'), 3))
      .max(45, yupMaxField(t, t('Name'), 45)),
  });
}
