import { TFunction } from 'i18next';
import { Dispatch, SetStateAction } from 'react';

import { getOrderStatusConfig } from '../../../../../../../configs/orders';
import { getProductTypeLabels } from '../../../../../../../configs/product';
import { ReconciledOrderType } from '../../../../../../../enums/reconciliations';
import { noColumnStyle } from '../../../../../../../utils/constants/tables';
import { currencyFormat } from '../../../../../../../utils/helpers/formatting';
import Tooltip from '../../../../../../tooltip/Tooltip/Tooltip';
import ActionsTableMenu from '../../../../../Components/ActionsTableMenu/ActionsTableMenu';
import { generateTooltipText } from '../../ShipmentReconciliation.functions';
import { DataRow } from '../../ShipmentReconciliation.types';

export const reconciliationListsCourierPickedUp =
  'reconciliationListsCourierPickedUp_dataTable';

export function getColumnHeadersMap(t: TFunction) {
  return {
    no: t('No.'),
    serial_number: t('Serial No.'),
    status_id: t('Status'),
    product_id: t('Product Type'),
    reconciled_postage_cash: t('Reconciled cash'),
    cash_delivery: t('Delivery cash'),
    client_to_name: t('Recipient'),
    has_recipient_signature: t("Recipient's signature"),
    place_to_name: t('Delivery place'),
    redemption: t('Redemption'),
    returning_document: t('Return document'),
    bill: t('Fiscalized amount'),
  };
}

export function additionalColumnProperties(
  t: TFunction,
  column: keyof ReturnType<typeof getColumnHeadersMap> | 'actions',
  setContextMenuSelection: Dispatch<SetStateAction<any>>,
  setAction: (t: string) => void
) {
  const orderStatusConfig = getOrderStatusConfig(t);
  const productTypeLabels = getProductTypeLabels(t);
  switch (column) {
    case 'no':
      return noColumnStyle;
    case 'serial_number':
      return {
        body: (row: DataRow) => {
          const tooltipText = generateTooltipText(
            row,
            ReconciledOrderType.Delivered,
            t
          );
          return <Tooltip text={tooltipText}>{row.serial_number}</Tooltip>;
        },
      };
    case 'status_id':
      return {
        body: (row: DataRow) => {
          return orderStatusConfig[row.status_id].label;
        },
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
          disable: true,
        },
      };
    case 'product_id':
      return {
        body: (row: DataRow) => {
          return productTypeLabels[row.product_id];
        },
        style: {
          width: 160,
        },
        bodyStyle: {
          width: 160,
          disable: true,
        },
      };
    case 'reconciled_postage_cash':
      return {
        body: (row: DataRow) => {
          // // This is where i mutate the row.values in order when i click to reconcile the row.value.reconciled_postage_cash will have the correct value;
          // //Check ShipmentReconciliation.tsx -> handleSaveButtonClick()
          // -----   we have ti reload table when we pres save ---- to fetch new refresh data -----
          return currencyFormat(row.reconciled_postage_cash, {
            showCurrency: true,
          });
        },
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
        },
      };
    case 'cash_delivery':
      return {
        body: (row: DataRow) =>
          currencyFormat(row[column], { showCurrency: true }),
      };
    case 'client_to_name':
    case 'has_recipient_signature':
    case 'place_to_name':
    case 'redemption':
    case 'returning_document':
    case 'bill':
      return {
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
        },
      };
    case 'actions':
      return {
        body: (row: DataRow) => {
          return (
            <ActionsTableMenu
              actions={[
                {
                  label: t('View Details'),
                  icon: 'fas fa-print',
                  disabled: false,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('edit');
                  },
                },
              ]}
            />
          );
        },
        style: {
          width: 60,
          borderLeft: '1px solid #e9ecef',
          flex: '0 1 auto',
          position: 'sticky',
        },
        bodyStyle: {
          width: 60,
          borderLeft: '1px solid #e9ecef',
          flex: '0 1 auto',
          position: 'sticky',
          justifyContent: 'center',
        },
      };

    default:
      return {};
  }
}
