import { useCallback, useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { EntityCollection } from '../../../../../types/api/entities';
import {
  FunctionExpressionCollection,
  OperatorExpressionCollection,
} from '../../../../../types/api/expressions';
import MySQLCodeEditor from '../../../../CodeEditors/MySQL/MySQLCodeEditor';
import { getOperatorButtons } from '../../../../CodeEditors/MySQL/MySQLCodeEditor.functions';
import { HelperSection } from '../../../../CodeEditors/MySQL/MySQLCodeEditor.types';
import { CommonFormFields } from './_Common.types';

type Props = {
  entitiesData: EntityCollection | undefined;
  functionsData: FunctionExpressionCollection | undefined;
  operatorsData: OperatorExpressionCollection | undefined;
  isEditorDisabled: boolean;
};

function Price({
  entitiesData,
  functionsData,
  operatorsData,
  isEditorDisabled,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const { setValue } = useFormContext<CommonFormFields>();

  const price = useWatch<CommonFormFields, 'price'>({
    name: 'price',
  });

  const setPrice = useCallback(
    (newValue: string) => setValue('price', newValue),
    [setValue]
  );

  const onChange = useCallback((value) => setValue('price', value), [setValue]);

  const entitiesSubsections = useMemo<HelperSection['subsections']>(
    () =>
      entitiesData
        ?.filter((e) => !!e.properties?.length)
        .map((e) => ({
          label: e.classname,
          content: getOperatorButtons(
            price,
            setPrice,
            e.properties!.map((ep) => ({
              id: ep.id,
              label: ep.propertyname,
              value: `${e.classname}.${ep.propertyname}`,
              title: ep.displayname,
            }))
          ),
        })) ?? [],
    [entitiesData, price, setPrice]
  );

  const functionsSubsections = useMemo<HelperSection['subsections']>(
    () =>
      functionsData
        ?.filter((e) => !!e.functions?.length)
        .map((f) => ({
          label: f.name,
          content: getOperatorButtons(
            price,
            setPrice,
            f.functions!.map((ff) => ({
              id: ff.id,
              label: ff.name,
              value: `${f.name}.${ff.name}`,
              title: ff.description,
            }))
          ),
        })) ?? [],
    [functionsData, price, setPrice]
  );

  const operatorsSubsections = useMemo<HelperSection['subsections']>(
    () =>
      operatorsData
        ?.filter((o) => !!o.operators?.length)
        .map((o) => ({
          label: o.name,
          content: getOperatorButtons(
            price,
            setPrice,
            o.operators!.map((oo) => ({
              id: oo.id,
              label: oo.name,
              value: `${o.name}.${oo.name}`,
              title: oo.description,
            }))
          ),
        })) ?? [],
    [operatorsData, price, setPrice]
  );

  const sections = useMemo<HelperSection[]>(
    () => [
      {
        title: t('Entities'),
        subsections: entitiesSubsections,
      },
      {
        title: t('Functions'),
        subsections: functionsSubsections,
      },
      {
        title: t('Operators'),
        subsections: operatorsSubsections,
      },
    ],
    [entitiesSubsections, functionsSubsections, operatorsSubsections, t]
  );

  return (
    <Controller
      name="price"
      render={({ field }) => (
        <MySQLCodeEditor
          value={field.value}
          onChange={onChange}
          sections={sections}
          editorProps={{
            readOnly: isEditorDisabled,
          }}
        />
      )}
    />
  );
}

export default Price;
