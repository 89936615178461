import { faBox } from '@fortawesome/free-solid-svg-icons';
import { RadioButton } from 'primereact/radiobutton';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useSearchQueryParam from '../../../../hooks/useSearchQueryParam';
import SidebarItem from '../../../Grid/Sidebar/SidebarItem';
import { getProductTypes } from '../Tariffs.functions';

type Props = {
  productType: string;
  setProductType: Dispatch<SetStateAction<string>>;
};

function ProductType({ productType, setProductType }: Props): JSX.Element {
  const { t } = useTranslation();

  const productTypeOptions = useMemo(() => getProductTypes(t), [t]);

  useSearchQueryParam('productType', productType);

  return (
    <SidebarItem title={t('Product Type')} icon={faBox}>
      <p className="p-mb-2">{t('Choose one')}</p>

      {productTypeOptions.map((p) => {
        return (
          <div key={p.value} className="p-field-radiobutton">
            <RadioButton
              inputId={p.value}
              name={p.value}
              value={p.value}
              checked={productType === p.value}
              onChange={(e) => setProductType(e.value)}
            />

            <label htmlFor={p.value}>{p.label}</label>
          </div>
        );
      })}
    </SidebarItem>
  );
}

export default ProductType;
