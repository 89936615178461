import { smsGetawayCollectionGuard } from './_api/clients';
import { configCollectionGuard, editConfigResourceGuard } from './_api/configs';
import { priceListCollectionGuard } from './_api/priceLists';

export const routeGuard = [
  configCollectionGuard,
  editConfigResourceGuard,
  smsGetawayCollectionGuard,
  priceListCollectionGuard,
];
