import dayjs from 'dayjs';
import { Field, useFormikContext } from 'formik';
import { Calendar, CalendarChangeParams } from 'primereact/calendar';
import { Dropdown, DropdownChangeParams } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Tooltip } from 'primereact/tooltip';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-lodash-debounce';

import { Product } from '../../../../../../../enums/product';
import useAxiosHook from '../../../../../../../hooks/useAxiosHook';
import usePrevious from '../../../../../../../hooks/usePrevious';
import { WithPagination } from '../../../../../../../types/api';
import { LabelValue } from '../../../../../../../types/options';
import { debounceTimeout } from '../../../../../../../utils/constants/misc';
import { invalidDecimalPointCharactersRegex } from '../../../../../../../utils/constants/regex';
import AutoCompleteInput from '../../../../../../Forms/AutoCompleteInput/AutoCompleteInput';
import FieldWithErrors from '../../../../../../Forms/FieldWithErrors/FieldWithErrors';
import { Order } from '../../../../Orders.functions';
import {
  FormValues,
  RedemptionFieldValidation,
  RedemptionReceiverFieldValidation,
  TimeSlot,
  getOrderPackagingOptions,
  getTimeSlotOptions,
  validateRedemptionField,
  validateRedemptionReceiverField,
} from '../../CreateEditDialog.functions';
import { LetterAttributes } from './LetterAttributes';
import { PackageAttributes } from './PackageAttributes';

type Props = {
  isTabs?: boolean;
  orderIndex?: number;
  isConstant?: boolean;
};

function AttributesTab({ isTabs, orderIndex, isConstant }: Props) {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<FormValues>();

  const [relatedOrderFilter, setRelatedOrderFilter] = useState('');

  const debouncedRelatedOrderFilter = useDebounce(
    relatedOrderFilter,
    debounceTimeout
  );

  const {
    data: relatedOrderOptionsData,
    isLoading: isRelatedOrderOptionsDataLoading,
  } = useAxiosHook<WithPagination<Order[]>>(
    `/orders?serial=${debouncedRelatedOrderFilter}`
  );

  const relatedOrderOptions = useMemo<LabelValue<string>[]>(
    () =>
      relatedOrderOptionsData?.data.map((o) => ({
        label: o.seriski_broj,
        value: String(o.id),
      })) ?? [],
    [relatedOrderOptionsData]
  );

  const onRelatedOrderSelectionChange = useCallback(
    (value: string) => {
      setFieldValue('reference_id', value);
    },
    [setFieldValue]
  );

  const orderPackagingOptions = useMemo(() => getOrderPackagingOptions(t), [t]);
  const timeSlotOptions = useMemo(() => getTimeSlotOptions(t), [t]);

  const { data: relatedOrder } = useAxiosHook<Order>(
    `/orders/${values.reference_id}`,
    {
      skipWhen: !values.reference_id || !!relatedOrderFilter,
    }
  );

  const prevRelatedOrder = usePrevious(relatedOrder);

  useEffect(() => {
    if (!relatedOrder || prevRelatedOrder || relatedOrderFilter) {
      return;
    }

    setRelatedOrderFilter(relatedOrder.seriski_broj);
  }, [prevRelatedOrder, relatedOrder, relatedOrderFilter]);

  const isTimeslot = values.vremenska_ramka_id == TimeSlot.TimeSlot;

  const isRedemptionFilledOut = parseFloat(values.otkup ?? '') > 0;

  const redemptionFieldValidation = useMemo(
    () => validateRedemptionField(values.klient_od_id, values.vrednost),
    [values.klient_od_id, values.vrednost]
  );

  const isRedemptionDisabled =
    redemptionFieldValidation !== RedemptionFieldValidation.Valid;

  const redemptionReceiverFieldValidation = useMemo(
    () =>
      validateRedemptionReceiverField(
        values.klient_od_id,
        values.otkup,
        values.vrednost,
        values.orderer
      ),
    [values.klient_od_id, values.orderer, values.otkup, values.vrednost]
  );

  const isRedemptionReceiverDisabled =
    redemptionReceiverFieldValidation !==
    RedemptionReceiverFieldValidation.Valid;

  return (
    <div className="special-tab p-grid">
      <Tooltip
        target=".disabled-value-tooltip"
        position="left"
        style={isRedemptionFilledOut ? {} : { display: 'none' }}
      />

      <Tooltip
        target=".disabled-redemption-tooltip"
        position="left"
        style={isRedemptionDisabled ? {} : { display: 'none' }}
      />

      <Tooltip
        target=".disabled-redemption-receiver-tooltip"
        position="left"
        style={isRedemptionReceiverDisabled ? {} : { display: 'none' }}
      />

      {orderIndex !== 0 && isConstant && <div className="constant" />}

      {isTabs && (
        <>
          <FieldWithErrors
            name="proizvod_id"
            label={t('Order type')}
            className="p-col-12"
          >
            <Field
              as={Dropdown}
              name="proizvod_id"
              inputId="proizvod_id_tabs"
              options={[
                { label: t('Package'), value: Product.Package },
                { label: t('Letter'), value: Product.Letter },
              ]}
            />
          </FieldWithErrors>

          <FieldWithErrors
            name="orderer"
            label={t('Orderer')}
            className="p-col-12"
          >
            <Field
              as={Dropdown}
              name="orderer"
              inputId="orderer"
              optionValue="id"
              options={[
                { label: t('Sender is orderer'), id: 1 },
                { label: t('Recipient is orderer'), id: 2 },
                { label: t('Third party orderer'), id: 3 },
              ]}
              className="data-cy-orderer"
            />
          </FieldWithErrors>
        </>
      )}

      <FieldWithErrors name="tezina" label={t('Weight')} className="p-col-6">
        <Field
          as={InputText}
          name="tezina"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setFieldValue(
              'tezina',
              e.target.value?.replace(invalidDecimalPointCharactersRegex, '')
            );
          }}
          data-cy="weight"
        />
      </FieldWithErrors>

      <FieldWithErrors
        name="kolicina"
        label={t('Quantity')}
        className="p-col-6"
      >
        <Field
          as={InputText}
          name="kolicina"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setFieldValue(
              'kolicina',
              e.target.value?.replace(invalidDecimalPointCharactersRegex, '')
            );
          }}
          data-cy="quantity"
        />
      </FieldWithErrors>

      {values.proizvod_id === Product.Package && (
        <PackageAttributes
          isRedemptionReceiverDisabled={isRedemptionReceiverDisabled}
          redemptionReceiverFieldValidation={redemptionReceiverFieldValidation}
        />
      )}

      {values.proizvod_id === Product.Letter && (
        <LetterAttributes isRedemptionFilledOut={isRedemptionFilledOut} />
      )}

      <FieldWithErrors
        name="reference1"
        label={t('Reference No. 1')}
        className="p-col-6"
      >
        <Field as={InputText} name="reference1" />
      </FieldWithErrors>

      <FieldWithErrors
        name="reference2"
        label={t('Reference No. 2')}
        className="p-col-6"
      >
        <Field as={InputText} name="reference2" />
      </FieldWithErrors>

      <FieldWithErrors
        name="reference_id"
        label={t('Related Order')}
        className="p-col-6"
      >
        <Field
          as={AutoCompleteInput}
          name="reference_id"
          id="reference_id"
          filterValue={relatedOrderFilter}
          value={values.reference_id}
          onFilterChange={setRelatedOrderFilter}
          onSelectionChange={onRelatedOrderSelectionChange}
          options={relatedOrderOptions}
          isLoading={isRelatedOrderOptionsDataLoading}
          placeholder={t('Serial No.')}
        />
      </FieldWithErrors>

      <FieldWithErrors
        name="vremenska_ramka_id"
        label={t('Timeframe')}
        className="p-col-6"
      >
        <Field
          as={Dropdown}
          name="vremenska_ramka_id"
          inputId="vremenska_ramka_id"
          options={timeSlotOptions}
          onChange={(e: DropdownChangeParams) => {
            setFieldValue('vremenska_ramka_id', e.value);
            if (e.value === 1) {
              setFieldValue('terminski_datum', dayjs().toDate());
            } else if (e.value === 2) {
              setFieldValue('terminski_datum', dayjs().add(1, 'day').toDate());
            } else if (e.value === 3) {
              setFieldValue('terminski_datum', dayjs().add(3, 'day').toDate());
            }
          }}
          className="data-cy-timeframe"
        />
      </FieldWithErrors>

      <FieldWithErrors
        name="terminsko_vreme"
        label={t('Delivery time')}
        className="p-col-6"
      >
        <div className="daily-timeframe p-grid">
          <div className="p-col">
            <Field
              as={Calendar}
              name="terminski_datum"
              placeholder={t('Date')}
              disabled={!isTimeslot}
              dateFormat="dd/mm/yy"
            />
          </div>

          {isTimeslot && (
            <div className="daily-timeframe p-col-6">
              <Field
                as={Calendar}
                placeholder={t('From')}
                name="terminsko_vreme_od"
                timeOnly
                showTime
                onChange={(e: CalendarChangeParams) => {
                  setFieldValue('terminsko_vreme_od', e.value);
                }}
              />
              <div className="divider"> - </div>

              <Field
                as={Calendar}
                placeholder={t('To')}
                name="terminsko_vreme_do"
                timeOnly
                showTime
                onChange={(e: CalendarChangeParams) => {
                  setFieldValue('terminsko_vreme_do', e.value);
                }}
              />
            </div>
          )}
        </div>
      </FieldWithErrors>

      <FieldWithErrors
        name="adresnica_service_packaging_id"
        label={t('Order service packaging')}
        className="p-col-6"
      >
        <Field
          as={Dropdown}
          name="adresnica_service_packaging_id"
          inputId="adresnica_service_packaging_id_tabs"
          options={orderPackagingOptions}
          showClear
          placeholder={t('No packaging')}
        />
      </FieldWithErrors>

      <FieldWithErrors
        name="povraten_dokument"
        label={t('Return Document')}
        className="p-col-6"
      >
        <Field
          as={InputSwitch}
          name="povraten_dokument"
          data-cy="povraten_dokument"
          checked={Boolean(values.povraten_dokument)}
        />
      </FieldWithErrors>

      {values?.replacement_shipment !== 2 && (
        <FieldWithErrors
          name="replacement_shipment"
          label={t('Replacement shipment')}
          className="p-col-6"
        >
          <Field
            as={InputSwitch}
            name="replacement_shipment"
            data-cy="replacement_shipment"
            checked={Boolean(values.replacement_shipment)}
          />
        </FieldWithErrors>
      )}

      <FieldWithErrors
        name="fragile"
        label={t('Is fragile ?')}
        className="p-col-6"
      >
        <Field
          as={InputSwitch}
          name="fragile"
          data-cy="fragile"
          checked={Boolean(values.fragile)}
        />
      </FieldWithErrors>

      <FieldWithErrors
        name="two_man_delivery"
        label={t('Is two man delivery ?')}
        className="p-col-6 two_man_delivery"
      >
        <Field
          as={InputSwitch}
          name="two_man_delivery"
          data-cy="two_man_delivery"
          checked={Boolean(values.two_man_delivery)}
        />
      </FieldWithErrors>

      <FieldWithErrors
        name="komentar"
        label={t('Note')}
        className="p-col-12 note"
      >
        <Field
          as={InputTextarea}
          name="komentar"
          autoResize
          style={{ maxHeight: 100, overflowY: 'auto' }}
          data-cy="comment"
        />
      </FieldWithErrors>
    </div>
  );
}

export default AttributesTab;
