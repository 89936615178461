import { faCubes } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getOrderRowClassName } from '../../../configs/orders';
import useAxiosHook from '../../../hooks/useAxiosHook';
import { useEndpointGuard } from '../../../hooks/useEndpointGuard';
import usePageTitle from '../../../hooks/usePageTitle';
import useTableColumns from '../../../hooks/useTableColumns';
import useTableState from '../../../hooks/useTableState';
import { CourierPickupAssignmentCollection } from '../../../types/api/orders';
import * as opaGuards from '../../../utils/constants/auth/courierPickupAssignment';
import { readOrder } from '../../../utils/constants/auth/orders';
import { queryString } from '../../../utils/helpers/http';
import Table from '../../DataTable/Table/Table';
import MainContent from '../../Grid/MainContent';
import Flex from '../../layout/flex/Flex';
import HeaderPages from '../Components/HeaderPages/HeaderPages';
import ViewActiveOrderDialog from '../Orders/Dialogs/View/ViewActiveOrderDialog';
import { SingleOrder } from '../Orders/Orders.functions';
import {
  Row,
  additionalColumnProperties,
  generateContextMenu,
  generateGroupActions,
  getColumnHeadersMap,
  tableStorageKey,
} from './CourierPickupAssignment.functions';
import AssignDialog from './Dialogs/Assign/AssignDialog';
import useTableFilters from './useTableFilters';

function OrderParcelAssignment() {
  const { t } = useTranslation();

  const assignParcelGuard = useEndpointGuard(opaGuards.assignParcel);
  const viewOrderGuard = useEndpointGuard(readOrder);

  usePageTitle(t('Courier Pickup Assignment'));

  const {
    tableRef,
    page,
    setPage,
    limit,
    setLimit,
    sortField,
    sortOrder,
    selectionMultiple,
    setSortField,
    setSortOrder,
    setSelectionMultiple,
  } = useTableState<Row>(tableStorageKey);

  const [contextMenuSelection, setContextMenuSelection] = useState<any>(null);

  const {
    headerFilters,
    headerFiltersForm,
    headerFiltersCount,
    resetAllFilters,
    httpFiltersObj,
    couriersOptions,
  } = useTableFilters(page, setPage!, limit);

  const columnHeadersMap = useMemo(() => getColumnHeadersMap(t), [t]);

  const { selectedColumns, setSelectedColumns, columnOptions, columns } =
    useTableColumns(
      page,
      limit,
      'courierPickupAssignment',
      columnHeadersMap,
      columnHeadersMap,
      (c) => additionalColumnProperties(t, c as keyof typeof columnHeadersMap)
    );

  const [isViewOrderDialogVisible, setIsViewOrderDialogVisible] =
    useState(false);
  const [isCourierDialogVisible, setIsCourierDialogVisible] = useState(false);

  const { data, reload, isLoading, error } =
    useAxiosHook<CourierPickupAssignmentCollection>(
      '/orders/courier/assignments/pickup' + queryString(httpFiltersObj)
    );

  useEffect(() => {
    if (!assignParcelGuard) {
      setIsCourierDialogVisible(false);
    }
  }, [assignParcelGuard]);

  function handleCMAssignOrderClick() {
    setIsCourierDialogVisible(true);
  }

  function onAssignDialogHide() {
    setIsCourierDialogVisible(false);
  }

  const { data: orderData, isLoading: isOrderDataLoading } =
    useAxiosHook<SingleOrder>(
      {
        url: `/orders/${contextMenuSelection?.id}`,
      },
      {
        skipWhen: !isViewOrderDialogVisible || !contextMenuSelection?.id,
      }
    );

  const rowClassName = useMemo(
    () => getOrderRowClassName(t, (order: Row) => order.status_id),
    [t]
  );

  return (
    <div className="page order-parcel-assignment-page">
      <HeaderPages
        title={t('Manual Pickup Assignment To Courier')}
        subtitle={t('View and assign orders to couriers')}
        icon={faCubes}
      />

      <ViewActiveOrderDialog
        data={orderData}
        visible={isViewOrderDialogVisible}
        onHide={() => setIsViewOrderDialogVisible(false)}
        isLoading={isOrderDataLoading}
      />

      <AssignDialog
        isShown={isCourierDialogVisible}
        onHide={onAssignDialogHide}
        courierOptions={couriersOptions}
        selectionMultiple={selectionMultiple}
        setSelectionMultiple={setSelectionMultiple}
        reload={reload}
      />

      <Flex direction="column">
        {/* // here should be filters but there is some stupid problem */}
        <MainContent columnLayout={false}>
          <Table
            tableRef={tableRef}
            columns={columns}
            data={data}
            isLoading={isLoading}
            hasError={!!error}
            reload={reload}
            filterHeight={10}
            headerFilters={headerFilters}
            headerFiltersForm={headerFiltersForm}
            headerFiltersCount={headerFiltersCount}
            onHeaderFiltersResetAllBtnClick={resetAllFilters}
            setPage={setPage}
            setLimit={setLimit}
            sortField={sortField}
            rows={limit}
            contextMenuModel={generateContextMenu(t, viewOrderGuard, () =>
              setIsViewOrderDialogVisible(true)
            )}
            groupActionsModel={generateGroupActions(
              t,
              assignParcelGuard,
              handleCMAssignOrderClick
            )}
            setSortField={setSortField}
            setSortOrder={setSortOrder}
            setSelection={setSelectionMultiple}
            sortOrder={sortOrder}
            selection={selectionMultiple}
            selectionMode={assignParcelGuard ? 'multiple' : 'single'}
            storageString={tableStorageKey}
            rebuildTooltip
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            columnOptions={columnOptions}
            contextMenuSelection={contextMenuSelection}
            setContextMenuSelection={setContextMenuSelection}
            rowClassName={rowClassName}
          />
        </MainContent>
      </Flex>
    </div>
  );
}

export default OrderParcelAssignment;
