import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { ComponentProps, RefObject } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Barcode from './Barcode';
import { FormValues } from './Barcode.types';

type Props = Pick<
  ComponentProps<typeof Barcode>,
  'hasError' | 'setHasError' | 'placeholder'
> & {
  barcodeRef: RefObject<HTMLInputElement>;
  methods: UseFormReturn<FormValues>;
  disabled: boolean;
  onSubmit: (values: FormValues) => void;
};

function Form({
  barcodeRef,
  methods,
  disabled,
  hasError,
  setHasError,
  onSubmit,
  placeholder,
}: Props): JSX.Element {
  const { handleSubmit, control } = methods;

  const { t } = useTranslation();

  const inputClassName = classNames({
    'p-invalid': hasError,
  });

  return (
    <form id="webImportForm" onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="barcode"
        control={control}
        render={({ field }) => (
          <InputText
            ref={barcodeRef as any}
            id="barcode"
            name="barcode"
            placeholder={
              placeholder ??
              (disabled ? t('Disabled') : t('Select parcel by barcode'))
            }
            disabled={disabled}
            value={field.value}
            // NOTE: Please don't trim the value here as barcodes can have spaces in them
            //  and this would inhibit the user from manually typing one.
            onChange={(e) => {
              field.onChange(e);
              setHasError(false);
            }}
            className={inputClassName}
          />
        )}
      />
    </form>
  );
}

export default Form;
