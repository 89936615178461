import { useFormikContext } from 'formik';
import { useEffect, useRef } from 'react';

import usePrevious from '../../../../../../hooks/usePrevious';
import { Courier, Region } from '../../../Regions.functions';
import { FormValues } from './AddEditCourierDialog.functions';
import DialogForm from './DialogForm';

type Props = {
  isEditDialog: boolean;
  selectedRegion: Region | undefined;
  selectedCourier: Courier | undefined;
  data: any;
  error: any;
  onCreateSubmit: (courierName: string, regionName: string) => void;
  onEditSubmit: (courierName: string, regionName: string) => void;
  onError: (courierName: string, regionName: string) => void;
};

function DialogContainer({
  isEditDialog,
  selectedRegion,
  selectedCourier,
  data,
  error,
  onCreateSubmit,
  onEditSubmit,
  onError,
}: Props): JSX.Element {
  const { values } = useFormikContext<FormValues>();

  const isInitialRender = useRef<boolean>(true);

  const prevData = usePrevious(data);
  const prevError = usePrevious(error);

  const courierName = isEditDialog
    ? `${selectedCourier?.first_name} ${selectedCourier?.last_name}`
    : `${typeof values.courier !== 'string' ? values.courier?.ime : ''} ${
        typeof values.courier !== 'string' ? values.courier?.prezime : ''
      }`;

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    if (!data || data === prevData || isInitialRender.current) {
      return;
    }

    if (isEditDialog) {
      onEditSubmit(courierName, selectedRegion?.name || '');
    } else {
      onCreateSubmit(courierName, selectedRegion?.name || '');
    }
  }, [
    courierName,
    data,
    isEditDialog,
    onCreateSubmit,
    onEditSubmit,
    prevData,
    selectedRegion,
  ]);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    if (!error || error === prevError || isInitialRender.current) {
      return;
    }

    onError(courierName, selectedRegion?.name || '');
  }, [courierName, error, onError, prevError, selectedRegion]);

  return (
    <DialogForm isEditDialog={isEditDialog} selectedRegion={selectedRegion} />
  );
}

export default DialogContainer;
