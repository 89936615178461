import './History.scss';

import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import ToastContext from '../../../../context/ToastContext';
import useAxiosHook from '../../../../hooks/useAxiosHook';
import { useEndpointGuard } from '../../../../hooks/useEndpointGuard';
import usePageTitleToggler from '../../../../hooks/usePageTitleToggler';
import useRouteDialog from '../../../../hooks/useRouteDialog';
import { EntityIdRouteParams } from '../../../../types/routing';
import * as ordersGuards from '../../../../utils/constants/auth/orders';
import {
  RoutePaths,
  constructIdRoute,
} from '../../../../utils/constants/routePaths';
import { noColumnStyle } from '../../../../utils/constants/tables';
import { getDataTableProps } from '../../../../utils/globals';
import { errorToast } from '../../../../utils/helpers/primereact';
import MainContent from '../../../layout/flex/MainContent';
import GoBackWithHistory from '../../../Misc/GoBack/GoBackWithHistory';
import HeaderPages from '../../Components/HeaderPages/HeaderPages';
import DeliveryInfoDialog from './Dialogs/Delivery/DeliveryInfoDialog';
import PaymentInfoDialog from './Dialogs/Payment/PaymentInfoDialog';
import {
  ApiData,
  TableRowData,
  buildRowsFromDiff,
  getRowColor,
  sortByAuditId,
  tableColumns,
} from './History.functions';

function History(): JSX.Element {
  const { t } = useTranslation();
  const dataTableRef = useRef<any>();
  const paymentInfoGuard = useEndpointGuard(ordersGuards.orderHistoryPayment);
  const deliveryInfoGuard = useEndpointGuard(ordersGuards.orderHistoryDelivery);
  const { toastRef } = useContext(ToastContext);
  const { id } = useParams<EntityIdRouteParams>();

  const {
    show: showPaymentDialog,
    hide: hidePaymentDialog,
    isVisible: isPaymentDialogVisible,
  } = useRouteDialog(
    constructIdRoute(RoutePaths.OrdersHistory, id),
    constructIdRoute(RoutePaths.PaymentOrderHistory, id)
  );

  const {
    show: showDeliveryDialog,
    hide: hideDeliveryDialog,
    isVisible: isDeliveryDialogVisible,
  } = useRouteDialog(
    constructIdRoute(RoutePaths.OrdersHistory, id),
    constructIdRoute(RoutePaths.DeliveryOrderHistory, id)
  );

  const { data, error, isLoading } = useAxiosHook<ApiData>({
    url: `/orders/${id}/history`,
  });

  const sortedData = useMemo<ApiData | undefined>(() => {
    if (!data) {
      return undefined;
    }

    return sortByAuditId(data) as ApiData;
  }, [data]);

  const serialNumber: string = sortedData?.length
    ? sortedData[0]?.seriski_broj ?? ''
    : '';

  usePageTitleToggler(
    t('{{serialNo}} Payment data - History', { serialNo: serialNumber }),
    t('History'),
    isPaymentDialogVisible
  );

  usePageTitleToggler(
    t('{{serialNo}} Delivery data - History', { serialNo: serialNumber }),
    t('History'),
    isDeliveryDialogVisible
  );

  const handleDialogError = useCallback<() => void>(() => {
    if (toastRef) {
      errorToast(
        toastRef,
        t('Error'),
        t('There was an error processing your request.')
      );
    }

    hidePaymentDialog();
  }, [hidePaymentDialog, t, toastRef]);

  useEffect(() => {
    if (!deliveryInfoGuard) {
      hideDeliveryDialog();
    }
  }, [deliveryInfoGuard, hideDeliveryDialog]);

  useEffect(() => {
    if (!paymentInfoGuard) {
      hidePaymentDialog();
    }
  }, [hidePaymentDialog, paymentInfoGuard]);

  const dataTableProps = useMemo(() => getDataTableProps(t), [t]);

  const rows = useMemo<TableRowData[]>(() => {
    if (!data) {
      return [];
    }

    return buildRowsFromDiff(data);
  }, [data]);

  const columns = [
    <Column
      field="no"
      header={t('No.')}
      body={(_, colData: any) => <span>{colData.rowIndex + 1}</span>}
      {...noColumnStyle}
    />,
    ...tableColumns(t, rows),
  ];

  return (
    <div className="orders-history-page">
      <GoBackWithHistory to="/orders" page={t('Orders')} />
      <HeaderPages
        title={t('History')}
        subtitle={
          isLoading
            ? t('Loading...')
            : sortedData?.length
            ? t('Auditing changes of order {{serialNo}}', {
                serialNo: sortedData[0]?.seriski_broj,
              })
            : t('Audit changes')
        }
      >
        <div className="header-buttons">
          {paymentInfoGuard && (
            <Button
              type="button"
              label={t('Payment info')}
              icon="fas fa-dollar-sign"
              onClick={() => {
                showPaymentDialog();
              }}
              className="main-btn p-button-secondary"
              data-cy="payment-info-btn"
            />
          )}

          {deliveryInfoGuard && (
            <Button
              type="button"
              label={t('Delivery info')}
              icon="fas fa-truck-loading"
              onClick={() => {
                showDeliveryDialog();
              }}
              className="main-btn p-button-secondary"
              data-cy="delivery-info-btn"
            />
          )}
        </div>
      </HeaderPages>

      <PaymentInfoDialog
        id={id}
        serialNumber={serialNumber}
        visible={isPaymentDialogVisible}
        onHide={() => hidePaymentDialog()}
        onError={handleDialogError}
      />

      <DeliveryInfoDialog
        id={id}
        serialNumber={serialNumber}
        visible={isDeliveryDialogVisible}
        onHide={() => hideDeliveryDialog()}
        onError={handleDialogError}
      />

      <MainContent>
        <DataTable
          ref={dataTableRef}
          value={rows}
          scrollable
          // lazy
          rowClassName={getRowColor}
          emptyMessage={
            error
              ? dataTableProps.emptyMessageError
              : dataTableProps.emptyMessage
          }
        >
          {columns}
        </DataTable>
      </MainContent>
    </div>
  );
}

export default History;
