export const reconciliationCollectionGuard = 'GET /reconciliations';
export const reconciliationResourceGuard = 'GET /reconciliations/d+';
export const reconciliationStatisticsGuard = 'GET /reconciliations/statistics';
export const reconciliationEmployeesGuard = 'GET /reconciliations/employees';

export const createReconciliationResourceGuard = 'POST /reconciliations';

export const markAsDoneReconciliationResourceGuard =
  'PUT /reconciliations/waiting-for-approval';
export const approveReconciliationResourceGuard =
  'PUT /reconciliations/approve';
export const rejectReconciliationResourceGuard = 'PUT /reconciliations/reject';

export const deliveredReconciliationCollectionGuard =
  'GET /reconciliations/shipments/delivered';
export const pickedUpReconciliationCollectionGuard =
  'GET /reconciliations/shipments/picked-up';

export const updateReconciliationShipmentsGuard = 'PUT /reconciliations/d+';
