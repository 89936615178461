import classNames from 'classnames';

import CollapsibleFilterSection from '../../../layout/flex/components/CollapsibleFilterSection';

interface IProps {
  filters: JSX.Element;
  resetAllFilters: Function;
  headerFiltersCount?: any;
  columns?: boolean;
  filterHeight?: number;
}

const Filters = ({
  filters,
  resetAllFilters,
  headerFiltersCount,
  columns = false,
  filterHeight, // we set this height in the table on the page to because of height of the table on full screen
}: IProps) => {
  return (
    <CollapsibleFilterSection
      childrenWrapperClassName={classNames({
        'p-accordion-filters-columns': columns,
        'p-accordion-filters': !columns,
      })}
      activeFiltersCount={headerFiltersCount}
      onResetFields={resetAllFilters}
      filterHeight={filterHeight}
    >
      {filters}
    </CollapsibleFilterSection>
  );
};

export default Filters;
