import {
  addVehicleResourceGuard,
  assignedEmployeeToVehicleResourceGuard,
  deleteVehicleResourceGuard,
  editVehicleResourceGuard,
  employeesWithAssignedVehicleCollectionGuard,
  vehicleCollectionGuard,
  vehicleManufacturerCollectionGuard,
  vehicleModelCollectionGuard,
} from './_api/vehicles';

export const modelFilter = vehicleModelCollectionGuard;
export const brandFilter = vehicleManufacturerCollectionGuard;
export const employeeFilter = employeesWithAssignedVehicleCollectionGuard;

export const addVehicle = [addVehicleResourceGuard, modelFilter, brandFilter];
export const editVehicle = [editVehicleResourceGuard, modelFilter, brandFilter];
export const deleteVehicle = [
  deleteVehicleResourceGuard,
  assignedEmployeeToVehicleResourceGuard,
];

export const routeGuard = vehicleCollectionGuard;
