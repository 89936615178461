import './ViewDialog.scss';

import {
  faCheckSquare,
  faDollarSign,
  faIdCard,
  faList,
  faPaperPlane,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useAxios from '../../../../hooks/useAxios';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import { sortFields } from '../ListTypes.functions';
import { getPaymentDoneByOptions } from './CreateEditDialog.functions';

function ViewDialog({ data, isShown, onHide, onEditBtnClick }) {
  const { t } = useTranslation();

  const paymentDoneByOptions = useMemo(() => getPaymentDoneByOptions(t), [t]);

  const { data: entities } = useAxios('/entities', undefined, undefined, {
    skipWhen: !isShown,
  });

  const { data: entityProperties } = useAxios(
    '/entityproperties',
    undefined,
    {
      method: 'GET',
    },
    { skipWhen: !isShown }
  );

  const { data: pricebooks, isLoading: isPricebooksLoading } = useAxios(
    '/price-lists?limit=0',
    { skipWhen: !isShown }
  );

  const sortedFields = useMemo(() => {
    if (data?.fields) {
      let oldFields = [...data.fields];

      let newFields = oldFields.map((f) => {
        let property = entityProperties?.find(
          (prop) => prop.id === f.property_id
        );

        let entity = entities?.find((e) => e.id === property?.entity_id);

        return {
          ...f,
          entityname: entity?.displayname ? entity.displayname : null,
          propertyname: property?.fieldname ? property.fieldname : null,
        };
      });

      return sortFields(newFields);
    }
  }, [data, entityProperties, entities]);

  function getPricebookName(id) {
    if (parseInt(id) === 0) {
      return 'Default pricebook';
    }
    return pricebooks?.data.find((p) => parseInt(id) === parseInt(p.id))?.name;
  }

  function findPaymentDoneBy(value) {
    return paymentDoneByOptions.find(
      (p) => parseInt(value) === parseInt(p.value)
    );
  }

  const dialogFooter = (
    <>
      <Button
        type="button"
        label={t('Edit')}
        className="p-button-text"
        onClick={() => onEditBtnClick(data)}
      />
      <Button type="button" label={t('Close')} onClick={() => onHide()} />
    </>
  );

  const ordererRole = paymentDoneByOptions.find(
    (p) => p.value === data.klient_isSender
  )?.label;

  const isOnMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Dialog
      visible={isShown}
      onHide={onHide}
      header={data.name}
      footer={dialogFooter}
      dismissableMask
      resizable={false}
      maximizable={isOnMobile}
      maximized={isOnMobile}
      className="list-types-view-dialog"
    >
      <div className="quick-info-container">
        <div className="profile-picture">
          <FontAwesomeIcon icon={faList} />
        </div>

        <div className="quick-info">
          <div>
            <FontAwesomeIcon icon={faIdCard} /> {data.name}
          </div>
          <div>
            <FontAwesomeIcon icon={faPaperPlane} />
            {t('Orderer role')}
            {` - ${ordererRole?.toLowerCase() ?? t('Unknown')}`}
          </div>
          <div>
            <FontAwesomeIcon icon={faDollarSign} />
            {parseInt(data.changeable_payment) === 1
              ? t('Variable payment')
              : t('Invariable payment')}
          </div>
        </div>
      </div>

      <h4>{t('Payment configuration')}</h4>
      <table
        cellPadding="0"
        cellSpacing="0"
        border="0"
        className="payment-configuration-table"
      >
        <tbody>
          <tr>
            <td>
              <b>{t('Postage paid by')}</b>
            </td>
            <td>{findPaymentDoneBy(data.plaka_postarina_isprakac)?.label}</td>
          </tr>
          <tr>
            <td>
              <b>{t('Redemption paid by')}</b>
            </td>
            <td>{findPaymentDoneBy(data.plaka_otkup_isprakac)?.label}</td>
          </tr>
          <tr>
            <td>
              <b>{t('Return document paid by')}</b>
            </td>
            <td>
              {findPaymentDoneBy(data.plaka_povraten_dokument_isprakac)?.label}
            </td>
          </tr>
          <tr>
            <td>
              <b>{t('Insurance paid by')}</b>
            </td>
            <td>
              {findPaymentDoneBy(data.plaka_osiguruvanje_isprakac)?.label}
            </td>
          </tr>
        </tbody>
      </table>

      <h4>{t('Price list configuration')}</h4>
      <table
        cellPadding="0"
        cellSpacing="0"
        border="0"
        className="price-list-configuration-table"
      >
        <tbody>
          {isPricebooksLoading ? (
            <tr>
              <td>
                <b>{t('Loading...')}</b>
              </td>
            </tr>
          ) : (
            <>
              <tr>
                <td>
                  <b>{t("Sender's price list")}</b>
                </td>
                <td>{getPricebookName(data.isprakac_cenovnik_id)}</td>
              </tr>
              <tr>
                <td>
                  <b>{t("Recipient's price list")}</b>
                </td>
                <td>{getPricebookName(data.primac_cenovnik_id)}</td>
              </tr>
              <tr>
                <td>
                  <b>{t("Orderer's price list")}</b>
                </td>
                <td>{getPricebookName(data.naracatel_cenovnik_id)}</td>
              </tr>
            </>
          )}
        </tbody>
      </table>

      <h4>{t('Field mapping')}</h4>
      <table cellPadding="0" cellSpacing="0" border="0">
        {sortedFields?.length > 0 ? (
          <>
            <thead>
              <tr>
                <th>{t('Field name')}</th>
                <th>{t('Entity')}</th>
                <th>{t('Property')}</th>
                <th>{t('Required')}</th>
                <th>{t('Default value')}</th>
              </tr>
            </thead>

            <tbody>
              {sortedFields.map((f) => (
                <tr key={f.id}>
                  <td>{f.name}</td>
                  <td>{f.entityname}</td>
                  <td>{f.propertyname}</td>
                  <td>
                    <FontAwesomeIcon
                      icon={f.isRequired === 1 ? faCheckSquare : faTimes}
                    />
                  </td>
                  <td>{f.default_value}</td>
                </tr>
              ))}
            </tbody>
          </>
        ) : (
          <tbody>
            <tr>
              <td>
                <i>{t("The list doesn't contain any fields.")}</i>
              </td>
            </tr>
          </tbody>
        )}
      </table>
    </Dialog>
  );
}

export default ViewDialog;
