import { faCheckSquare, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { Column, ColumnProps } from 'primereact/column';
import { TFunction } from 'react-i18next';

import { emptyCell } from '../../../../../../utils/constants/tables';
import { dateCell } from '../../../../../../utils/helpers/dataTable';
import { currencyFormat } from '../../../../../../utils/helpers/formatting';

type DeliveryEntity = {
  datum: string;
  action: 'NO' | 'AO' | 'DO' | 'RO';
  opis: string;
  razdolzena: string;
  gotovina: string;
  dodelena: string;
  postar_ime: string | null;
  rabotnik_ime: string | null;
};

export type ApiData = DeliveryEntity[];

function getHeadersMap(t: TFunction) {
  return {
    datum: t('Date'),
    action: t('Action'),
    opis: t('Description'),
    razdolzena: t('Indebted.SHE'),
    gotovina: t('Cash'),
    dodelena: t('Assigned.SHE'),
    postar_ime: t('Courier'),
    rabotnik_ime: t('Employee'),
  };
}

export function getTableColumns(t: TFunction): JSX.Element[] {
  const headersMap = getHeadersMap(t);

  return Object.keys(headersMap).map<JSX.Element>((k, i) => (
    <Column
      key={i}
      field={k}
      header={headersMap[k as keyof typeof headersMap]}
      body={(rowData) =>
        (typeof rowData[k] === 'string' ? rowData[k].trim() : rowData[k]) ||
        emptyCell
      }
      {...additionalColumnProperties(t, k as keyof typeof headersMap)}
    />
  ));
}

export function sortByTimestamp<T extends { datum: string }>(
  rows: Readonly<T[]>
): T[] {
  return [...rows].sort((a, b) => {
    const ts1 = dayjs(a.datum);
    const ts2 = dayjs(b.datum);

    return ts1.isSame(ts2) ? 0 : ts1.isAfter(ts2) ? 1 : -1;
  });
}

function additionalColumnProperties(
  t: TFunction,
  column: keyof ReturnType<typeof getHeadersMap>
): Partial<ColumnProps> {
  switch (column) {
    case 'datum':
      return {
        body: (row) => dateCell(row[column]),
        style: {
          width: 180,
        },
        bodyStyle: {
          width: 180,
        },
      };

    case 'action':
      return {
        body: (row) => {
          const value = row[column] as DeliveryEntity['action'];

          if (value === 'NO') {
            return t('New Order');
          } else if (value === 'AO') {
            return t('Assigned to courier for delivery');
          } else if (value === 'DO') {
            return t('Delivered order');
          } else if (value === 'RO') {
            return t('Returned order in warehouse');
          } else {
            return t('Unknown');
          }
        },
        style: {
          width: 250,
        },
        bodyStyle: {
          width: 250,
        },
      };

    case 'opis':
      return {
        style: {
          width: 160,
        },
        bodyStyle: {
          width: 160,
        },
      };

    case 'razdolzena':
    case 'dodelena':
      return {
        body: (row) =>
          !!parseInt(row[column]) ? (
            <FontAwesomeIcon icon={faCheckSquare} />
          ) : (
            <FontAwesomeIcon icon={faTimes} />
          ),
        style: {
          width: 120,
        },
        bodyStyle: {
          width: 120,
          textAlign: 'center',
        },
      };

    case 'gotovina':
      return {
        body: (row) => currencyFormat(row[column], { showCurrency: true }),
        style: {
          width: 140,
        },
        bodyStyle: {
          width: 140,
        },
      };

    case 'postar_ime':
    case 'rabotnik_ime':
      return {
        style: {
          width: 250,
        },
        bodyStyle: {
          width: 250,
        },
      };

    default:
      return {};
  }
}
