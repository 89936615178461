import './Cashdesk.scss';

import { Button } from 'primereact/button';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import usePageTitle from '../../../../hooks/usePageTitle';
import useTableColumns from '../../../../hooks/useTableColumns';
import useTableState from '../../../../hooks/useTableState';
import Table from '../../../DataTable/Table/Table';
import Flex from '../../../layout/flex/Flex';
import MainContent from '../../../layout/flex/MainContent';
import Sidebar from '../../../layout/flex/Sidebar';
import Filters from '../../Components/Filters/Filters';
import HeaderPages from '../../Components/HeaderPages/HeaderPages';
import {
  additionalColumnProperties,
  getColumnHeadersMap,
  getTotals,
  mockData,
  tableStorageKey,
} from './Cashdesk.functions';
import useTableFilters from './useTableFilters';

function Cashdesk() {
  const { t } = useTranslation();

  usePageTitle(t('Cashdesk'));

  const data = useMemo(() => {
    return mockData;
  }, []);

  const cashdeskColumnHeadersMap = useMemo(() => getColumnHeadersMap(t), [t]);

  const {
    tableRef,
    page,
    setPage,
    limit,
    setLimit,
    sortField,
    sortOrder,
    setSortField,
    setSortOrder,
    setSelection,
  } = useTableState(tableStorageKey);

  const { selectedColumns, setSelectedColumns, columnOptions, columns } =
    useTableColumns(
      page,
      limit,
      'reports_customerOrders',
      cashdeskColumnHeadersMap,
      cashdeskColumnHeadersMap,
      (column) => additionalColumnProperties(column)
    );

  const { headerFiltersForm, headerFiltersCount, resetAllFilters } =
    useTableFilters(page, setPage!, limit);

  const modifiedData = useMemo(() => {
    if (!data) {
      return;
    }

    const totals = getTotals(data.data);
    const newData = [...data.data, { ...totals }];

    return { data: newData, pagination: data.pagination };
  }, [data]);

  return (
    <div className="cashdesk-report-page">
      <HeaderPages>
        <div>
          <h2 className="title">{t('Cashdesk')}</h2>
          <p className="subtitle">{t('View and print cahsdesk report')}</p>
        </div>
        <div>
          <Button
            type="button"
            label={t('Print cashdesk report')}
            icon="fas fa-plus"
            className="main-btn"
            disabled={false}
            onClick={() => {}}
          />
        </div>
      </HeaderPages>

      <Flex direction="column">
        <Sidebar>
          <Filters
            filters={headerFiltersForm}
            resetAllFilters={resetAllFilters}
          />
        </Sidebar>
        <MainContent>
          <Table
            tableRef={tableRef}
            columns={columns}
            data={modifiedData}
            hasError={false}
            isLoading={false}
            reload={() => {}}
            headerTitle=""
            headerFiltersCount={headerFiltersCount}
            onHeaderFiltersResetAllBtnClick={resetAllFilters}
            setPage={setPage}
            setLimit={setLimit}
            sortField={sortField}
            rows={limit}
            setSortField={setSortField}
            setSortOrder={setSortOrder}
            setSelection={setSelection}
            sortOrder={sortOrder}
            selection={{}}
            storageString={tableStorageKey}
            rebuildTooltip
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            columnOptions={columnOptions}
          />
        </MainContent>
      </Flex>
    </div>
  );
}

export default Cashdesk;
