import { LabelValue } from '../../../types/options';

// TODO: change <string> to something more generic, like <Numeric>
export type MutliSelectAutoCompleteOption = LabelValue<string>;

export function emptyItemTemplate(
  option: MutliSelectAutoCompleteOption
): JSX.Element {
  return <i>{option.label}</i>;
}
