import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import _ from 'lodash';

import {
  currencyFormat,
  httpDateFormat,
} from '../../../utils/helpers/formatting';
import { nonEmptyObjectsOnly } from '../../../utils/helpers/misc';
import { userPrefixedString } from '../../../utils/helpers/user';

export function getRadioButtons(
  t,
  isBestCouriersShown,
  isDeliveredParcelsShown,
  isDeliveredParcelsPerRegionShown,
  isFinancesShown,
  isPickedUpParcelsPerRegionShown,
  isPickedUpParcelsShown
) {
  return nonEmptyObjectsOnly([
    isBestCouriersShown
      ? { value: 'BestCouriersChart', label: t('Best couriers') }
      : {},
    isDeliveredParcelsShown
      ? { value: 'DeliveredParcelsChart', label: t('Delivered parcels') }
      : {},
    isDeliveredParcelsPerRegionShown
      ? {
          value: 'DeliveredParcelsPerRegionChart',
          label: t('Delivered parcels per region'),
        }
      : {},
    isFinancesShown ? { value: 'FinancesChart', label: t('Finances') } : {},
    isPickedUpParcelsPerRegionShown
      ? {
          value: 'PickedUpParcelsPerRegionChart',
          label: t('Picked up parcels per region'),
        }
      : {},
    isPickedUpParcelsShown
      ? {
          value: 'PickedUpParcelsChart',
          label: t('Picked up parcels'),
        }
      : {},
  ]);
}

export function getChartOptionsById(id) {
  let item;

  try {
    item = sessionStorage.getItem(
      userPrefixedString('dashboard_rgl_chartArrayOptions')
    );
  } catch (e) {
    Sentry.captureException(e);
  }

  let returnValue = {};

  if (item) {
    try {
      returnValue = JSON.parse(item)[id];
    } catch (e) {
      Sentry.captureException(e, {
        extra: {
          data: item,
        },
      });
    }
  }

  return returnValue;
}

export function getChartDateById(id) {
  const date = getChartOptionsById(id)?.date;

  if (!date) {
    return null;
  }

  const from = dayjs.unix(date[0]).toDate();
  const to = dayjs.unix(date[1]).toDate();

  return [from, to];
}

export function getYesterdayDate() {
  const from = dayjs().subtract(1, 'day');
  const to = dayjs().subtract(1, 'day');

  return [from, to];
}

export function getLastWeek() {
  const from = dayjs().subtract(6, 'day');
  const to = dayjs();

  return [from, to];
}

export function getLastMonth() {
  const currentMonth = dayjs().get('month') + 1;
  const currentYear = dayjs().get('year');
  const daysInMonth = dayjs(
    `${currentYear}-${currentMonth - 1}-01`
  ).daysInMonth();

  const from = dayjs(`${currentYear}-${currentMonth - 1}-01`);
  const to = dayjs(`${currentYear}-${currentMonth - 1}-${daysInMonth}`);

  return [from, to];
}

export function getLastYear() {
  const currentYear = dayjs().get('year');

  const from = dayjs(`${currentYear - 1}-01-01`);
  const to = dayjs(`${currentYear - 1}-12-31`);

  return [from, to];
}

export function getChartPeriodById(id) {
  const period = getChartOptionsById(id)?.period;

  if (!period) {
    return null;
  }

  if (period === 'yesterday') {
    return getYesterdayDate();
  }

  if (period === 'last_week') {
    return getLastWeek();
  }

  if (period === 'last_month') {
    return getLastMonth();
  }

  if (period === 'last_month') {
    return getLastYear();
  }
}

export function getChartGroupingDate(date, grouping) {
  return grouping === 'day'
    ? [httpDateFormat(date[0]), httpDateFormat(date[1])]
    : [
        httpDateFormat(dayjs(date[0]).startOf(grouping)),
        httpDateFormat(dayjs(date[1]).endOf(grouping)),
      ];
}

function totalFromChartData(data) {
  let totalPerCourierArr = [];

  for (let courier in data.datasets) {
    totalPerCourierArr.push({
      label: data.datasets[courier].label,
      total: data.datasets[courier].data?.reduce((acc, d) => {
        let val;

        if (typeof d === 'object') {
          val = d.y;
        } else {
          val = d;
        }

        return acc + parseInt(val);
      }, 0),
    });
  }

  return totalPerCourierArr.reduce(
    (acc, totalSingleCourier) => acc + totalSingleCourier.total,
    0
  );
}

export function totalNumber(chartData) {
  return new Intl.NumberFormat().format(totalFromChartData(chartData));
}

export function totalCurrency(chartData) {
  return currencyFormat(totalFromChartData(chartData));
}

export const colorPalette = [
  '#4baaf5',
  '#6ebe71',
  '#fccc55',
  '#30c9dc',
  '#ed4981',
  '#6372c3',
  '#30aa9f',
  '#f79530',
  '#7e96a1',
  '#af50bf',
];

export const gridSettings = {
  breakpoints: { xxs: 0, xs: 480, sm: 768, md: 996, lg: 1200 },
  cols: { xxs: 1, xs: 2, sm: 4, md: 12, lg: 12 },
};

export function getCurrentBreakpoint() {
  let breakpoint;

  for (let b in gridSettings.breakpoints) {
    if (window.innerWidth > gridSettings.breakpoints[b]) {
      breakpoint = b;
    } else {
      break;
    }
  }

  return breakpoint;
}

export function getStorageItem(key, isLocalStorage = true) {
  let item;

  try {
    item = JSON.parse(
      (isLocalStorage ? localStorage : sessionStorage).getItem(
        userPrefixedString(key)
      )
    );
  } catch (e) {
    Sentry.captureException(e);
  }

  return item;
}

export function setStorageItem(key, item, isLocalStorage = true) {
  try {
    (isLocalStorage ? localStorage : sessionStorage).setItem(
      userPrefixedString(key),
      JSON.stringify(item)
    );
  } catch (e) {
    Sentry.captureException(e, {
      extra: {
        data: item,
      },
    });
  }
}

export function getChartArrayOptions() {
  const item = getStorageItem('dashboard_rgl_chartArrayOptions', false);

  return _.isObject(item) ? item : {};
}

export function isPeriodStateValid(period) {
  return (
    period === 'yesterday' ||
    period === 'last_week' ||
    period === 'last_month' ||
    period === 'last_year'
  );
}
