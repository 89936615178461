import { Field, useFormikContext } from 'formik';
import { Dropdown } from 'primereact/dropdown';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useAxiosHook from '../../../../hooks/useAxiosHook';
import { queryString } from '../../../../utils/helpers/http';
import FieldWithErrors from '../../../Forms/FieldWithErrors/FieldWithErrors';

function CreateEditDialogVehiclesTab() {
  const { t } = useTranslation();

  const { values } = useFormikContext();

  const { data: vehiclesData, isLoading } = useAxiosHook(
    '/vehicles' + queryString({ page: 1, limit: 0 })
  );

  const vehicleOptions = useMemo(
    () =>
      vehiclesData?.data
        ?.filter(
          (vehicle) => vehicle.id === values.vozilo_id || !vehicle.assigned
        )
        .map((vehicle) => ({
          label: `${vehicle.marka_name} ${vehicle.model_name} - ${vehicle.registarska_tablicka}`,
          value: Number(vehicle.id),
        })),
    [values.vozilo_id, vehiclesData?.data]
  );

  return (
    <>
      {isLoading ? (
        <div className="text-center">
          <ProgressSpinner style={{ width: 72 }} />
        </div>
      ) : (
        <div className="p-fluid">
          <FieldWithErrors name="vozilo_id" label={t('Vehicle')}>
            <Field
              name="vozilo_id"
              as={Dropdown}
              inputId="vozilo_id"
              options={vehicleOptions}
              filter
              filterPlaceholder={t('Search')}
              disabled={isLoading}
              placeholder={isLoading ? t('Loading...') : t('Select a vehicle')}
              showClear
              showFilterClear
              className="data-cy-vehicle"
            />
          </FieldWithErrors>
        </div>
      )}
    </>
  );
}

export default CreateEditDialogVehiclesTab;
