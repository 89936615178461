import { TabPanelProps } from 'primereact/tabview';
import { useMemo } from 'react';
import { UseFormReturn, useFormContext } from 'react-hook-form';

function useTabPanelRhfErrorProps<F extends object>(
  fields: (keyof F)[],
  _formState?: UseFormReturn<F>['formState']
): TabPanelProps {
  const ctx = useFormContext<F>();
  const formState = ctx ? ctx.formState : _formState;

  const hasErrors = useMemo<boolean>(
    () =>
      !!Object.keys(formState?.errors ?? {}).find((k) =>
        fields.includes(k as any)
      ),
    [formState, fields]
  );

  return {
    leftIcon: hasErrors ? 'fas fa-exclamation-circle' : undefined,
    headerClassName: hasErrors ? 'invalid' : '',
  };
}

export default useTabPanelRhfErrorProps;
