import './ChartContainer.scss';

import { faCalendar, faHistory } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Chart } from 'primereact/chart';
import { Dropdown } from 'primereact/dropdown';
import { forwardRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  getChartDateById,
  getChartPeriodById,
  getLastMonth,
  getLastWeek,
  getLastYear,
  getYesterdayDate,
} from './Dashboard.functions';

const ChartContainer = forwardRef(
  (
    {
      id,
      title,
      type,
      options = {},
      data,
      header,
      className,
      isLoading,
      hasError,
      date,
      setDate,
      period,
      setPeriod,
      onChartRefresh,
      onReplace,
      onRemove,
      onOptionsChange,
      children,
      ...other
    },
    ref
  ) => {
    const { t } = useTranslation();

    useEffect(() => {
      const date = getChartPeriodById(id) ||
        getChartDateById(id) || [new Date(), new Date()];

      setDate([dayjs(date[0]).toDate(), dayjs(date[1]).toDate()]);
    }, [id, setDate]);

    useEffect(() => {
      if (!period) {
        return;
      }

      if (period === 'yesterday') {
        const [from, to] = getYesterdayDate();
        setDate([dayjs(from).toDate(), dayjs(to).toDate()]);
      }

      if (period === 'last_week') {
        const [from, to] = getLastWeek();
        setDate([dayjs(from).toDate(), dayjs(to).toDate()]);
      }

      if (period === 'last_month') {
        const [from, to] = getLastMonth();
        setDate([dayjs(from).toDate(), dayjs(to).toDate()]);
      }

      if (period === 'last_year') {
        const [from, to] = getLastYear();
        setDate([dayjs(from).toDate(), dayjs(to).toDate()]);
      }
    }, [period, setDate]);

    const periodOptions = [
      { label: t('Yesterday'), value: 'yesterday' },
      { label: t('Last Week'), value: 'last_week' },
      { label: t('Last Month'), value: 'last_month' },
      { label: t('Last Year'), value: 'last_year' },
    ];

    return (
      <div
        ref={ref}
        className={`${className} chart-container ${
          isLoading ? 'is-loading' : ''
        }`}
        data-cy="chart-container"
        {...other}
      >
        <div className="chart-header draggable-handle">
          <span className="chart-title">{title}</span>

          <div className="chart-buttons cancel-drag">
            <Button
              type="button"
              icon="fas fa-sync"
              className="chart-button"
              title={t('Reload chart data')}
              onClick={() => onChartRefresh()}
              data-cy="reload-btn"
            />

            <Button
              type="button"
              icon="fas fa-recycle"
              className="chart-button"
              title={t('Replace chart')}
              onClick={() => onReplace(id)}
              data-cy="replace-btn"
            />

            <Button
              type="button"
              icon="fas fa-times"
              className="chart-button"
              title={t('Remove chart from dashboard')}
              onClick={() => onRemove()}
              data-cy="remove-btn"
            />
          </div>
        </div>
        {!isLoading && !hasError ? (
          <div className="chart">
            <div className="chart-settings">
              {header && <div className="header-container">{header}</div>}

              <div
                className={`date-range-calendar-container ${
                  !header ? 'only-child' : ''
                }`}
              >
                <label htmlFor={`${id}-date-range`} className="icon">
                  <FontAwesomeIcon icon={faHistory} />
                </label>
                <Dropdown
                  value={period}
                  options={periodOptions}
                  onChange={(e) => {
                    setPeriod(e.value);
                    onOptionsChange(id, {
                      period: e.value,
                    });
                  }}
                  placeholder={t('Select Period')}
                />
              </div>
              <div
                className={`date-range-calendar-container ${
                  !header ? 'only-child' : ''
                }`}
              >
                <label htmlFor={`${id}-date-range`} className="icon">
                  <FontAwesomeIcon icon={faCalendar} />
                </label>
                <Calendar
                  value={date}
                  selectionMode="range"
                  numberOfMonths={1}
                  monthNavigator
                  yearNavigator
                  yearRange={`2009:${dayjs().format('YYYY')}`}
                  dateFormat="dd/mm/yy"
                  maxDate={new Date()}
                  inputId={`${id}-date-range`}
                  readOnlyInput
                  onChange={(e) => {
                    setPeriod(null);
                    setDate(e.value);

                    if (e.value[1]) {
                      onOptionsChange(id, {
                        date: [
                          dayjs(e.value[0]).unix(),
                          dayjs(e.value[1]).unix(),
                        ],
                      });
                    }
                  }}
                />
              </div>
            </div>

            {!!data?.datasets?.[0]?.data?.length ? (
              <Chart type={type} data={data} options={options} />
            ) : (
              <div className="chart-container-error">
                <h3>{t('No data')}</h3>
                <span>{t('Your filters did not match any records.')}</span>
              </div>
            )}
            {children}
          </div>
        ) : isLoading ? (
          <div className="chart-container-loading">
            <span>{t('Loading...')}</span>
          </div>
        ) : (
          <div className="chart-container-error">
            <h3>{t('An error occured')}</h3>
            <span>
              {t('Please make sure you are connected to the internet')}
              <br />
              {t('and try refreshing the page.')}
            </span>
          </div>
        )}
      </div>
    );
  }
);

export default ChartContainer;
