import classNames from 'classnames';
import dayjs from 'dayjs';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDebounce } from 'use-lodash-debounce';

import useAxiosHook from '../../../../hooks/useAxiosHook';
import useHaveValuesChanged from '../../../../hooks/useHaveValuesChanged';
import useSearchQueryDateParam from '../../../../hooks/useSearchQueryDateParam';
import useSearchQueryDropdownParam from '../../../../hooks/useSearchQueryDropdownParam';
import useSearchQueryParam from '../../../../hooks/useSearchQueryParam';
import { AvailableCouriersCollection } from '../../../../types/api/couriers';
import { LabelValue } from '../../../../types/options';
import { debounceTimeout } from '../../../../utils/constants/misc';
import { httpDateFormat } from '../../../../utils/helpers/formatting';
import {
  getSearchQueryParam,
  tryDateSearchParam,
} from '../../../../utils/helpers/searchQuery';
import FiltersCounter from '../../Components/Filters/FiltersCounter';

function useHubsFilters(page: number, limit: number) {
  const { t } = useTranslation();
  const location = useLocation();
  const [date, setDate] = useState<Date | null>(
    () =>
      tryDateSearchParam(getSearchQueryParam(location.search, 'date') ?? '') ??
      dayjs().toDate()
  );
  const [hubName, setHubName] = useState<string>(
    () => getSearchQueryParam(location.search, 'hub_name') ?? ''
  );
  const [warehouseReceiptStatus, setWarehouseReceiptStatus] = useState<string>(
    () => getSearchQueryParam(location.search, 'warehouse_receipt_status') ?? ''
  );
  const [transporterId, setTransporterId] = useState<string>(
    () => getSearchQueryParam(location.search, 'transporter_id') ?? ''
  );

  const { data: couriersData } = useAxiosHook<AvailableCouriersCollection>(
    '/couriers/available?user_role_id=7',
    { skipWhen: !date }
  );

  const filtersArr = useMemo(
    () => [date, hubName, warehouseReceiptStatus, transporterId],
    [date, hubName, warehouseReceiptStatus, transporterId]
  );
  const haveFiltersChanged = useHaveValuesChanged(filtersArr);
  const debouncedHubName = useDebounce(hubName, debounceTimeout);

  const courierOptions = useMemo<LabelValue<number>[]>(
    () =>
      couriersData?.map((courier) => ({
        label: courier.full_name,
        value: courier.id,
      })) ?? [],
    [couriersData]
  );

  const warehouseReceiptStatusOptions = useMemo<LabelValue<number>[]>(
    () => [
      {
        label: t('Fully received'),
        value: 1,
      },
      {
        label: t('Partially received'),
        value: 2,
      },
      {
        label: t('No received'),
        value: 3,
      },
    ],
    [t]
  );

  const httpFiltersObj = useMemo(() => {
    const _date = dayjs(date as any);
    return {
      date: httpDateFormat(_date),
      hub_name: debouncedHubName,
      warehouse_receipt_status: warehouseReceiptStatus,
      transporter_id: transporterId,
      page: haveFiltersChanged ? 1 : page,
      limit,
      summary: 1,
    };
  }, [
    date,
    debouncedHubName,
    warehouseReceiptStatus,
    transporterId,
    page,
    limit,
    haveFiltersChanged,
  ]);

  useSearchQueryParam('hub_name', hubName);
  useSearchQueryDateParam('date', date);
  useSearchQueryDropdownParam(
    'warehouse_receipt_status',
    warehouseReceiptStatus,
    setWarehouseReceiptStatus,
    warehouseReceiptStatusOptions
  );
  useSearchQueryDropdownParam(
    'transporter_id',
    transporterId,
    setTransporterId,
    courierOptions
  );

  const basicFiltersActiveFilterCount = Object.values({
    date,
    hubName,
    warehouseReceiptStatus,
    transporterId,
  }).filter(Boolean).length;

  const filters = useMemo<JSX.Element>(
    () => (
      <Accordion multiple activeIndex={[0]}>
        <AccordionTab
          disabled
          headerTemplate={
            <FiltersCounter
              description={t('Basic filters')}
              counter={basicFiltersActiveFilterCount}
            />
          }
        >
          <div className="sidebar_filter">
            <label htmlFor="filter_date">{t('Date')}</label>
            <Calendar
              id="filter_date"
              value={date ?? undefined}
              monthNavigator
              yearNavigator
              dateFormat="dd/mm/yy"
              yearRange={`2009:${dayjs().format('YYYY')}`}
              maxDate={new Date()}
              onChange={(e) => setDate(e.value as Date | null)}
              inputClassName={classNames({
                sidebar_filter_active: date,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="hub_name_filter">{t("Warehouse's name")}</label>
            <InputText
              id="hub_name_filter"
              value={hubName ?? ''}
              onChange={(e) => setHubName(e.target.value)}
              className={classNames({
                sidebar_filter_active: hubName,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="status_filter">
              {t('Warehouse receipt status')}
            </label>
            <Dropdown
              id="warehouse_receipt_status_filter"
              options={warehouseReceiptStatusOptions}
              value={warehouseReceiptStatus}
              showClear
              placeholder={t('All')}
              disabled={!warehouseReceiptStatusOptions.length}
              onChange={(e) => setWarehouseReceiptStatus(e.value)}
              className={classNames({
                sidebar_filter_active: warehouseReceiptStatus,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="status_filter">{t('Route / Transporter')}</label>
            <Dropdown
              id="transport_id_filter"
              options={courierOptions}
              value={transporterId}
              showClear
              placeholder={t('All')}
              disabled={!courierOptions.length}
              onChange={(e) => setTransporterId(e.value)}
              className={classNames({
                sidebar_filter_active: transporterId,
              })}
            />
          </div>
        </AccordionTab>
      </Accordion>
    ),
    [
      date,
      hubName,
      warehouseReceiptStatus,
      transporterId,
      warehouseReceiptStatusOptions,
      courierOptions,
      basicFiltersActiveFilterCount,
      t,
    ]
  );

  const headerFiltersCount = useMemo(
    () => basicFiltersActiveFilterCount,
    [basicFiltersActiveFilterCount]
  );

  function resetAllFilters() {
    setDate(new Date());
    setHubName('');
    setWarehouseReceiptStatus('');
    setTransporterId('');
  }

  return {
    filters,
    resetAllFilters,
    httpFiltersObj,
    headerFiltersCount,
  };
}

export default useHubsFilters;
