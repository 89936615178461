import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Fragment, useMemo } from 'react';
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
  useWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { LabelValue } from '../../../../../types/options';
import { Route } from '../../Routes.functions';
import {
  FormFields,
  getDefaultValues,
  getValidationSchema,
} from './EditDialog.functions';

type Props = {
  data: Route | undefined;
  clientTypeOptions: LabelValue<number>[];
  privacyOptions: LabelValue[];
  rolesOptions: LabelValue<number>[];
  handleFormSubmission: SubmitHandler<FormFields>;
};

function FormContainer({
  data,
  clientTypeOptions,
  privacyOptions,
  rolesOptions,
  handleFormSubmission,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const schema = useMemo(() => getValidationSchema(t), [t]);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormFields>({
    resolver: yupResolver(schema),
    defaultValues: getDefaultValues(data),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'roles',
  });

  const rolesResults = useWatch({ control, name: 'roles' });

  return (
    <form
      id="roles-page-edit-dialog-form"
      onSubmit={handleSubmit(handleFormSubmission)}
    >
      <div className="access-roles-grid p-fluid">
        <span className="access-roles-grid-header">{t('Client type')}</span>
        <span className="access-roles-grid-header">{t('Privacy')}</span>
        <span className="access-roles-grid-header">{t('Roles')}</span>
        <span className="access-roles-grid-header" />

        {fields.map((item, index) => (
          <Fragment key={item.id}>
            <div className="p-field">
              <Controller
                name={`roles.${index}.clientType` as const}
                control={control}
                render={({ field, fieldState }) => (
                  <Dropdown
                    {...field}
                    id={field.name}
                    options={clientTypeOptions}
                    className={classNames({ 'p-invalid': fieldState.invalid })}
                  />
                )}
              />
            </div>

            <div className="p-field">
              <Controller
                name={`roles.${index}.privacy` as const}
                control={control}
                render={({ field, fieldState }) => (
                  <Dropdown
                    {...field}
                    id={field.name}
                    options={privacyOptions}
                    className={classNames({ 'p-invalid': fieldState.invalid })}
                  />
                )}
              />
            </div>

            <div className="p-field">
              <Controller
                name={`roles.${index}.roles` as const}
                control={control}
                render={({ field, fieldState }) => (
                  <MultiSelect
                    {...field}
                    id={field.name}
                    maxSelectedLabels={0}
                    selectedItemsLabel={t('{0} selected')}
                    filter
                    options={rolesOptions}
                    className={classNames({ 'p-invalid': fieldState.invalid })}
                    disabled={rolesResults[index]?.privacy !== 'private'}
                  />
                )}
              />
            </div>

            <Button
              icon="pi pi-times"
              className="p-button-rounded p-button-danger p-button-text"
              tooltip={t('Delete')}
              tooltipOptions={{ position: 'right' }}
              onClick={() => remove(index)}
            />
          </Fragment>
        ))}
      </div>

      {Object.keys(errors).length > 0 && (
        <p className="p-mt-3 p-mb-0 p-error">
          {t('Some required fields are left out.')}
        </p>
      )}

      <Button
        type="button"
        label={t('Add')}
        icon="fas fa-plus"
        onClick={() => append({})}
        className="p-mt-4 p-button-secondary"
      />
    </form>
  );
}

export default FormContainer;
