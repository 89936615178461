import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useAxiosHook from '../../../../../../hooks/useAxiosHook';
import useMediaQuery from '../../../../../../hooks/useMediaQuery';
import usePrevious from '../../../../../../hooks/usePrevious';
import { noColumnStyle } from '../../../../../../utils/constants/tables';
import { tryInt } from '../../../../../../utils/helpers/parse';
import EmptyMessage from '../../../../../DataTable/EmptyMessage/EmptyMessage';
import DialogSpinner from '../../../../../Dialogs/DialogSpinner/DialogSpinner';
import {
  ApiData,
  getTableColumns,
  sortByTimestamp,
} from './DeliveryInfoDialog.functions';

type Props = {
  id?: string;
  serialNumber: string;
  visible: boolean;
  onHide: () => void;
  onError: () => void;
};

function DeliveryInfoDialog({
  id,
  serialNumber,
  visible,
  onHide,
  onError,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const { data, error, isLoading } = useAxiosHook<ApiData>(
    `/orders/${id}/history/delivery`,
    { skipWhen: !visible || typeof tryInt(id) !== 'number' }
  );

  const prevError = usePrevious(error);

  const rows = useMemo(() => (data ? sortByTimestamp(data) : []), [data]);

  useEffect(() => {
    if (!error || error === prevError) {
      return;
    }

    onError();
  }, [error, onError, prevError]);

  const dialogFooter = !isLoading ? (
    <Button
      type="button"
      label={t('Close')}
      onClick={() => onHide()}
      className="p-button-secondary p-button-text"
    />
  ) : (
    // Bottom corners are cut out otherwise
    <></>
  );

  const isOnMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Dialog
      header={isLoading ? t('Loading...') : t('Delivery info')}
      footer={dialogFooter}
      visible={visible}
      resizable={false}
      onHide={onHide}
      maximizable
      maximized={isOnMobile}
      className="datatable-dialog"
      style={!data?.length ? { width: 480 } : {}}
    >
      {isLoading ? (
        <DialogSpinner />
      ) : !data?.length ? (
        <EmptyMessage
          text={t('This order does not have any delivery data yet')}
        />
      ) : (
        <>
          <p className="mt-0">
            {t('Delivery data for {{serialNo}}', { serialNo: serialNumber })}
          </p>

          <DataTable
            value={rows}
            autoLayout
            scrollable
            rowHover
            className="p-datatable-gridlines p-datatable-sm"
          >
            <Column
              field="no"
              header={t('No.')}
              body={(_, colData: any) => colData.rowIndex + 1}
              {...noColumnStyle}
            />

            {getTableColumns(t)}
          </DataTable>
        </>
      )}
    </Dialog>
  );
}

export default DeliveryInfoDialog;
