import '../../../CourierReconciliation/components/sidebar/Stats.scss';

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { InvoiceCollection } from '../../../../../types/api/invoices';
import { currencyFormat } from '../../../../../utils/helpers/formatting';
import SidebarItem from '../../../../Grid/Sidebar/SidebarItem';
import SidebarContentDivider from '../../../../Sidebar/ContentDivider/SidebarContentDivider';
import { Row } from '../InvoicesPreview.functions';

type Props = {
  data: InvoiceCollection | undefined;
  selectionMultiple: Row[];
};

function Stats({ data, selectionMultiple }: Props): JSX.Element {
  const { t } = useTranslation();

  const selectionSummary = useMemo(
    () =>
      selectionMultiple.reduce(
        (acc, current) => {
          return {
            amount: acc.amount + current.amount,
            vat: acc.vat + current.vat,
            total: acc.total + current.total,
          };
        },
        {
          amount: 0,
          vat: 0,
          total: 0,
        }
      ),
    [selectionMultiple]
  );

  return (
    <SidebarItem template="stats" className="stats">
      <span className="stats-section">
        {t('Total number of invoices for selected period')}:{' '}
        <p className="stats-section">{data?.pagination.total ?? '-'}</p>
      </span>

      <span className="stats-section">
        {t('Total amount for selected period')}:{' '}
        <p className="stats-section">
          {data?.summary.amount
            ? currencyFormat(data.summary.amount, { showCurrency: true })
            : '-'}
        </p>
      </span>

      <span className="stats-section">
        {t('Total VAT for selected period')}:{' '}
        <p className="stats-section">
          {data?.summary.vat
            ? currencyFormat(data.summary.vat, { showCurrency: true })
            : '-'}
        </p>
      </span>

      <span className="stats-section">
        {t('Total for selected period')}:{' '}
        <p className="stats-section">
          {data?.summary.amount
            ? currencyFormat(data.summary.amount, { showCurrency: true })
            : '-'}
        </p>
      </span>

      <SidebarContentDivider />

      <span className="stats-section">
        {t('Total number of invoices for selection')}:{' '}
        <p className="stats-section">{selectionMultiple.length}</p>
      </span>

      <span className="stats-section">
        {t('Total amount for selection')}:{' '}
        <p className="stats-section">
          {selectionSummary.amount
            ? currencyFormat(selectionSummary.amount, { showCurrency: true })
            : '-'}
        </p>
      </span>

      <span className="stats-section">
        {t('Total VAT for selection')}:{' '}
        <p className="skeleton-content">
          {selectionSummary.vat
            ? currencyFormat(selectionSummary.vat, { showCurrency: true })
            : '-'}
        </p>
      </span>

      <span className="stats-section">
        {t('Total for selection')}:{' '}
        <p className="skeleton-content">
          {selectionSummary.amount
            ? currencyFormat(selectionSummary.amount, { showCurrency: true })
            : '-'}
        </p>
      </span>
    </SidebarItem>
  );
}

export default Stats;
