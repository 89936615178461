import { TFunction } from 'react-i18next';

import { Service } from '../enums/service';
import { LabelValue } from '../types/options';

export function getServiceTypeLabels(t: TFunction): Record<Service, string> {
  return {
    [Service.Postage]: t('Postage'),
    [Service.Ransom]: t('Ransom'),
    [Service.ReturnDocument]: t('Return Document'),
    [Service.ValuableShipment]: t('Valuable Shipment'),
    [Service.BuyingWithCourier]: t('Buying with Courier'),
    [Service.Reception]: t('Reception'),
    [Service.ManualPriceAdjustment]: t('Manual Price Adjustment'),
  };
}

export function getServiceTypeOptions(t: TFunction): LabelValue<Service>[] {
  const labels = getServiceTypeLabels(t);

  return [
    { label: labels[Service.Postage], value: Service.Postage },
    { label: labels[Service.Ransom], value: Service.Ransom },
    { label: labels[Service.ReturnDocument], value: Service.ReturnDocument },
    {
      label: labels[Service.ValuableShipment],
      value: Service.ValuableShipment,
    },
    {
      label: labels[Service.BuyingWithCourier],
      value: Service.BuyingWithCourier,
    },
    { label: labels[Service.Reception], value: Service.Reception },
    {
      label: labels[Service.ManualPriceAdjustment],
      value: Service.ManualPriceAdjustment,
    },
  ];
}
