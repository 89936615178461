import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';

import { getEmailRecipientLabels } from '../../../../../../../configs/email';
import { EmailAction, EmailRecipient } from '../../../../../../../enums/email';
import { FormFields } from '../../CreateEditDialog.types';
import styles from './EmailConfiguration.module.scss';
import Fields from './Fields';

type Props = {
  emailAction: EmailAction;
  emailRecipient: EmailRecipient;
  emailRecipientLabels: ReturnType<typeof getEmailRecipientLabels>;
};

function Recipient({
  emailAction,
  emailRecipient,
  emailRecipientLabels,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const { values, setFieldValue } = useFormikContext<FormFields>();

  function handleAddBtnClick() {
    setFieldValue(`email_configuration[${emailAction}][${emailRecipient}]`, [
      ...values.email_configuration[emailAction][emailRecipient],
      '',
    ]);
  }

  return (
    <section className={classNames(styles.recipient, 'p-pl-3')}>
      <h4 className="p-mb-2">{emailRecipientLabels[emailRecipient]}</h4>

      <Fields emailAction={emailAction} emailRecipient={emailRecipient} />

      <Button
        type="button"
        label={t('Add')}
        icon="fas fa-plus"
        onClick={handleAddBtnClick}
        className="p-button-sm p-button-outlined"
      />
    </section>
  );
}

export default Recipient;
