export const clientCollectionGuard = 'GET /clients';
export const clientLookupCollectionGuard = 'GET /clients/lookup';
export const clientResourceGuard = 'GET /clients/d+';
export const addClientResourceGuard = 'POST /clients';
export const editClientResourceGuard = 'PUT /clients/d+';

export const mergeClientsGuard = 'POST /clients/merge';

export const updateClientStatusGuard = 'PUT /clients/d+/status';

export const reinitializeClientActivationGuard =
  'POST /clients/d+/reinit-activation';

export const forgotPasswordGuard = 'POST /clients/forgotpassword';

export const clientsLegalEntityCollectionGuard = 'GET /clients/legal';

export const clientHeadquartersRefCollectionGuard = 'GET /clients/hq/ref';

export const smsGetawayCollectionGuard = 'GET /clients/sms/gateways';

export const clientImportListTypeCollectionGuard =
  'GET /clients/d+/importlists/types';
