import dayjs from 'dayjs';
import L from 'leaflet';
import { useTranslation } from 'react-i18next';
import { Marker, Popup, Tooltip } from 'react-leaflet';

import shadowPin from '../../../assets/img/map-pins/shadow.png';
import { CouriersLocationCollection } from '../../../types/api/tracking';
import { Unpacked } from '../../../types/util';
import {
  getCourierStatusConfig,
  lastActiveDateToCourierStatusConfig,
} from './CourierMap.functions';
import styles from './CourierMap.module.scss';

type Props = {
  courier: Unpacked<CouriersLocationCollection>;
  courierStatusConfig: ReturnType<typeof getCourierStatusConfig>;
};

function CourierMarker({ courier, courierStatusConfig }: Props): JSX.Element {
  const { t } = useTranslation();

  const icon = L.icon({
    iconUrl: lastActiveDateToCourierStatusConfig(
      courier.date,
      courierStatusConfig
    ).icon,
    shadowUrl: shadowPin,
    iconSize: [36, 36],
    shadowSize: [36, 36],
    iconAnchor: [36, 36],
    shadowAnchor: [22, 35],
    popupAnchor: [-18, -40],
    tooltipAnchor: [0, -18],
  });

  return (
    <Marker
      key={courier.courier_id}
      icon={icon}
      position={[Number(courier.latitude), Number(courier.longitude)]}
    >
      <Tooltip offset={[36, 0]}>{courier.courier_name}</Tooltip>

      <Popup>
        <span className={styles.popupTitle}>{t('Courier')}</span>
        <span className={styles.popupValue}>{courier.courier_name}</span>
        <br />

        <span className={styles.popupTitle}>{t('Device')}</span>
        <span className={styles.popupValue}>{courier.device_product_name}</span>
        <br />

        <span className={styles.popupTitle}>{t('Mobile Number')}</span>
        <span className={styles.popupValue}>
          {courier.mobile_number.trim() || <i>{t('not set')}</i>}
        </span>
        <br />

        <span className={styles.popupTitle}>{t('Last online')}</span>
        <span className={styles.popupValue}>
          {dayjs(courier.date).fromNow()}
        </span>
      </Popup>
    </Marker>
  );
}

export default CourierMarker;
