import { LatLngLiteral, Layer, Map, Polygon } from 'leaflet';
import polylabel from 'polylabel';
import { TFunction } from 'react-i18next';
import * as Yup from 'yup';

import { tryInt, tryString } from '../../../../../../utils/helpers/parse';
import { yupRequiredField } from '../../../../../../utils/helpers/yup';
import { Region } from '../../../Regions.functions';

export type FormFields = {
  name: string;
  zone_id: number | '';
  description: string;
  coordinates: string;
};

type ApiData = {
  name: string;
  zone_id: number;
  description: string;
  geometry: LatLngLiteral[];
  location: LatLngLiteral;
};

export function getZoneDropdownOptions(
  t: TFunction
): { label: string; value: number }[] {
  return [
    { label: t('Base'), value: 1 },
    { label: t('Zone 1'), value: 2 },
    { label: t('Zone 2'), value: 3 },
  ];
}

export function getInitialValues(
  region: Region | undefined,
  isEditDialog: boolean
): FormFields {
  // It is easier and wiser to keep the map
  //   as a single source of truth regarding polygon data,
  //   hence, the geometry key below is excess data used by Formik
  //   and should be validated in the validation schema accordingly.
  return {
    name: isEditDialog ? tryString(region?.name) ?? '' : '',
    zone_id: isEditDialog ? tryInt(region?.zone_id) ?? '' : '',
    description: isEditDialog ? tryString(region?.description) ?? '' : '',
    coordinates: '', // Excess data
  };
}

export function toApiData(
  formValues: FormFields,
  mapRef: Map | undefined
): ApiData {
  const geoJsonCoordinates = mapRef
    ? ((mapRef.pm.getGeomanLayers() as Polygon[])[0].toGeoJSON(10).geometry
        .coordinates[0] as number[][])
    : [];

  const geometry = geoJsonCoordinates.map((c: number[]) => ({
    lat: Number.isInteger(c[1]) ? parseFloat(`${c[1]}.000001`) : c[1],
    lng: Number.isInteger(c[0]) ? parseFloat(`${c[0]}.000001`) : c[0],
  }));

  const center = polylabel([geoJsonCoordinates], 10);
  const location = { lat: center[1], lng: center[0] };

  return {
    name: formValues.name,
    zone_id: formValues.zone_id as number,
    description: formValues.description,
    geometry,
    location,
  };
}

export function getValidationSchema(
  t: TFunction,
  mapRef: Map | undefined
): Yup.AnyObjectSchema {
  return Yup.object().shape({
    name: Yup.string()
      .min(2, t('The name must be at least 2 characters'))
      .max(30, t('The name cannot be more than 30 characters'))
      .required(yupRequiredField(t, t('Name'))),
    zone_id: Yup.number().required(yupRequiredField(t, t('Zone'))),
    description: Yup.string(),
    coordinates: Yup.mixed().test(
      'coordinates',
      t('An area is required'),
      () => !!mapRef && (mapRef.pm.getGeomanLayers() as Layer[]).length > 0
    ),
  });
}
