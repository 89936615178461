import './ViewOrderDialog.scss';

import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { getServiceTypeLabels } from '../../../../../configs/service';
import { formatPhoneOrMobileNumber } from '../../../../../utils/helpers/phoneNumbers';
import { getReturnDocIcon } from '../../DeletedOrders/DeletedOrders.functions';
import {
  Payment,
  ProductType,
  ServiceType,
  SingleOrder,
} from '../../Orders.functions';
import { getAmount } from './VIewOrderDialog.functions';

type Props = {
  data: SingleOrder | undefined;
};

function ViewOrderDialog({ data }: Props) {
  const { t } = useTranslation();

  const { search } = useLocation();

  const senderAddress = `${data?.adresa_od ?? ''} ${data?.vlez_od ?? ''} ${
    data?.stan_od ?? ''
  }, ${data?.mesto_od_ime ?? ''}`;

  const recipientAddress = `${data?.adresa_do ?? ''} ${data?.vlez_do ?? ''} ${
    data?.stan_do ?? ''
  }, ${data?.mesto_do_ime ?? ''}`;

  const paymentTableData = useMemo<JSX.Element[]>(
    () =>
      data?.payments?.map((p: Payment, index: number) => (
        <tr key={index}>
          <td>{getServiceTypeLabels(t)[p.usluga_id]}</td>
          <td>{p.iznos}</td>
          <td>
            {p.klient_id === data?.klient_od_id
              ? t('Sender')
              : p.klient_id === data?.klient_do_id
              ? t('Recipient')
              : t('Orderer')}
          </td>
          <td>{p.gotovina === 1 ? t('Cash') : t('Invoice')}</td>
        </tr>
      )) ?? [],
    [data?.klient_do_id, data?.klient_od_id, data?.payments, t]
  );

  return (
    <>
      {data?.return_document_id && (
        <>
          <h4>{t('Return Document')}</h4>
          <p>
            {t('This order has a return document.')}{' '}
            <Link
              to={`/orders/active/${data.return_document_id}/view${search}`}
            >
              {t('Go to return document')}
            </Link>
          </p>
        </>
      )}
      {data?.origin_return_document_id && (
        <>
          <h4>{t('Origin Order')}</h4>
          <p>
            {t('This order is a return document.')}{' '}
            <Link
              to={`/orders/active/${data.origin_return_document_id}/view${search}`}
            >
              {t('Go to origin order')}
            </Link>
          </p>
        </>
      )}
      <h4>{t('General information')}</h4>
      <table cellPadding="0" cellSpacing="0">
        <tbody>
          <tr>
            <td>
              <b>{t('Serial Number')}</b>
            </td>
            <td>{data?.seriski_broj ?? '-'}</td>
          </tr>

          <tr>
            <td>
              <b>{t('Group Serial Number')}</b>
            </td>
            <td>{data?.group_serial_number ?? '-'}</td>
          </tr>

          <tr>
            <td>
              <b>{t('Barcode')}</b>
            </td>
            <td>{data?.barkod ?? '-'}</td>
          </tr>

          <tr>
            <td>
              <b>{t('Shipment Type')}</b>
            </td>
            <td>{data?.proizvod_ime ?? '-'}</td>
          </tr>

          <tr>
            <td>
              <b>{t('Status')}</b>
            </td>
            <td>{data?.status_name ?? '-'}</td>
          </tr>

          <tr>
            <td>
              <b>{t('Value')}</b>
            </td>
            <td>{data?.vrednost ?? '-'}</td>
          </tr>

          <tr>
            <td>
              <b>{t('Redemption')}</b>
            </td>
            <td>{data?.otkup ?? '-'}</td>
          </tr>

          <tr>
            <td>
              <b>{t('Return Document')}</b>
            </td>
            <td>
              {data?.povraten_dokument != null
                ? getReturnDocIcon(t, data?.povraten_dokument)
                : '-'}
            </td>
          </tr>

          <tr>
            <td>
              <b>{t('Created Date')}</b>
            </td>
            <td>
              {data?.datum_kreiranje
                ? dayjs(data.datum_kreiranje).format('DD MMM YYYY, HH:mm:ss')
                : '-'}
            </td>
          </tr>

          <tr>
            <td>
              <b>{t('Reception Date')}</b>
            </td>
            <td>
              {data?.datum_priem
                ? dayjs(data.datum_priem).format('DD MMM YYYY, HH:mm:ss')
                : '-'}
            </td>
          </tr>

          <tr>
            <td>
              <b>{t('Delivery Date')}</b>
            </td>
            <td>
              {data?.datum_isporaka
                ? dayjs(data?.datum_isporaka).format('DD MMM YYYY, HH:mm:ss')
                : '-'}
            </td>
          </tr>

          <tr>
            <td>
              <b>{t('Expected pickup courier')}</b>
            </td>
            <td>{data?.postar_od_name ?? '-'}</td>
          </tr>

          <tr>
            <td>
              <b>{t('Expected delivery courier')}</b>
            </td>
            <td>{data?.postar_do_name ?? '-'}</td>
          </tr>

          <tr>
            <td>
              <b>{t('Delivery Type')}</b>
            </td>
            <td>{data?.type_of_delivery ?? '-'}</td>
          </tr>

          <tr>
            <td colSpan={2}>
              <b>{t('Attributes')}</b>
            </td>
          </tr>

          <tr>
            <td colSpan={2} className="inner-table">
              <table cellPadding="0" cellSpacing="0">
                <tbody>
                  <tr>
                    <td>{t('Quantity')}</td>
                    <td>{data?.kolicina ?? '-'}</td>
                  </tr>

                  <tr>
                    <td>{t('Number of letters')}</td>
                    <td>
                      {getAmount(
                        data,
                        ProductType.Letter,
                        ServiceType.Letter
                      ) ?? '-'}
                    </td>
                  </tr>

                  <tr>
                    <td>{t('Number of packages')}</td>
                    <td>
                      {getAmount(
                        data,
                        ProductType.Package,
                        ServiceType.Package
                      ) ?? '-'}
                    </td>
                  </tr>

                  <tr>
                    <td>{t('Total weight')} (kg)</td>
                    <td>{data?.tezina ?? '-'}</td>
                  </tr>

                  <tr>
                    <td>{t('Height')} (m)</td>
                    <td>{data?.visina ?? '-'}</td>
                  </tr>

                  <tr>
                    <td>{t('Length')} (m)</td>
                    <td>{data?.dolzina ?? '-'}</td>
                  </tr>

                  <tr>
                    <td>{t('Width')} (m)</td>
                    <td>{data?.shirina ?? '-'}</td>
                  </tr>

                  <tr>
                    <td>
                      {t('Total volume')} (m<sup>3</sup>)
                    </td>
                    <td>{data?.volumen ?? '-'}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>

      <h4>{t('Sender information')}</h4>
      <table cellPadding="0" cellSpacing="0">
        <tbody>
          <tr>
            <td>
              <b>{t('Name / Company Name')}</b>
            </td>
            <td>{data?.klient_od_ime ?? '-'}</td>
          </tr>

          <tr>
            <td>
              <b>{t('Address')}</b>
            </td>
            <td>{senderAddress ?? '-'}</td>
          </tr>

          <tr>
            <td>
              <b>{t('Phone')}</b>
            </td>
            <td>
              {data?.telefon_od
                ? formatPhoneOrMobileNumber(data.telefon_od)
                : '-'}
            </td>
          </tr>

          <tr>
            <td>
              <b>{t('Mobile')}</b>
            </td>
            <td>
              {data?.mobilen_od
                ? formatPhoneOrMobileNumber(data.mobilen_od)
                : '-'}
            </td>
          </tr>
        </tbody>
      </table>

      <h4>{t('Recipient information')}</h4>
      <table cellPadding="0" cellSpacing="0">
        <tbody>
          <tr>
            <td>
              <b>{t('Name / Company Name')}</b>
            </td>
            <td>{data?.klient_do_ime ?? '-'}</td>
          </tr>

          <tr>
            <td>
              <b>{t('Address')}</b>
            </td>
            <td>{recipientAddress ?? '-'}</td>
          </tr>

          <tr>
            <td>
              <b>{t('Phone')}</b>
            </td>
            <td>
              {data?.telefon_do
                ? formatPhoneOrMobileNumber(data.telefon_do)
                : '-'}
            </td>
          </tr>

          <tr>
            <td>
              <b>{t('Mobile')}</b>
            </td>
            <td>
              {data?.mobilen_do
                ? formatPhoneOrMobileNumber(data.mobilen_do)
                : '-'}
            </td>
          </tr>
        </tbody>
      </table>

      <h4>{t('Additional Services and Payments')}</h4>
      <table cellPadding="0" cellSpacing="0">
        <thead>
          <tr>
            <th />
            <th>{t('Price')}</th>
            <th>{t('Pays')}</th>
            <th>{t('Type')}</th>
          </tr>
        </thead>

        <tbody>
          {paymentTableData}

          <tr>
            <td>{t('Total')}</td>
            <td>{data?.cena}</td>
            <td />
            <td />
          </tr>
        </tbody>
      </table>

      <h4>{t('Note')}</h4>
      <table>
        <tbody>
          <tr>
            <td colSpan={2}>
              {data?.komentar ?? (
                <i>{t('There are no notes for this order')}</i>
              )}
            </td>
          </tr>
        </tbody>
      </table>

      <table>
        <tbody>
          <tr>
            <td>
              <b>{t('Reference 1')}</b>
            </td>
            <td>{data?.reference1 ?? '-'}</td>
          </tr>

          <tr>
            <td>
              <b>{t('Reference 2')}</b>
            </td>
            <td>{data?.reference2 ?? '-'}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
}

export default ViewOrderDialog;
