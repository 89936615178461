import { TFunction } from 'react-i18next';

import { InvoiceCandidatesItemCollection } from '../../../../../../../../types/api/invoices';
import { Unpacked } from '../../../../../../../../types/util';
import { noColumnStyle } from '../../../../../../../../utils/constants/tables';
import {
  currencyFormat,
  percentageFormat,
} from '../../../../../../../../utils/helpers/formatting';

export const tableStorageKey = 'invoicesItems_dataTable';

export function getColumnHeadersMap(
  t: TFunction
): Record<
  | keyof Omit<
      Unpacked<InvoiceCandidatesItemCollection>,
      | 'client_id'
      | 'description'
      | 'price_and_vat_per_item'
      | 'vat_per_item'
      | 'tariff_id'
    >
  | 'no',
  string
> {
  return {
    no: t('No.'),
    faktura_id: t('Invoice ID'),
    tariff_name: t('Tariff'),
    client_name: t('Client'),
    price_per_item: t('Price Per Item'),
    vat: t('VAT'),
    discount: t('Discount'),
    price_per_item_discounted: t('Price Per Item Discounted'),
    vat_per_item_discounted: t('VAT Per Item Discounted'),
    price_and_vat_per_item_discounted: t('Price And VAT Per Item Discounted'),
    amount: t('Amount'),
    price_total: t('Price Total'),
    vat_total: t('VAT Total'),
    price_and_vat_total: t('Total'),
  };
}

export function additionalColumnOptions(column: string) {
  switch (column) {
    case 'no':
      return noColumnStyle;

    case 'price_per_item':
    case 'price_per_item_discounted':
    case 'vat_per_item_discounted':
    case 'price_and_vat_per_item_discounted':
    case 'price_total':
    case 'vat_total':
    case 'price_and_vat_total':
      return {
        body: (row: Unpacked<InvoiceCandidatesItemCollection>) =>
          currencyFormat(row[column], { showCurrency: true }),
        style:
          column === 'price_and_vat_per_item_discounted'
            ? { width: 280 }
            : { width: 250 },
        bodyStyle:
          column === 'price_and_vat_per_item_discounted'
            ? { width: 280 }
            : { width: 250 },
      };

    case 'vat':
    case 'discount':
      return {
        body: (row: Unpacked<InvoiceCandidatesItemCollection>) =>
          percentageFormat(row[column]),
        style: { width: 200 },
        bodyStyle: { width: 200 },
      };

    default:
      return {
        style: { width: 200 },
        bodyStyle: { width: 200 },
      };
  }
}

export function generateContextMenu(t: TFunction, handleViewClick: () => void) {
  return [
    {
      label: t('View Shipments'),
      icon: 'fas fa-search',
      command: handleViewClick,
    },
  ];
}
