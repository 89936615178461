import { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { LabelValue } from '../../../../../../types/options';
import AutoCompleteInput from '../../../../../Forms/AutoCompleteInput/AutoCompleteInput';
import FieldWithErrors from '../../../../../Forms/ReactHookForm/FieldWithErrors';
import { FormFields } from '../AddDialog.types';

type Props = {
  tariff_name: string;
  tariffOptions: LabelValue[];
  isTariffsLoading: boolean;
  price_list_name: string;
  priceListOptions: LabelValue[];
  isPriceListsLoading: boolean;
};

function GeneralInfo({
  tariff_name,
  tariffOptions,
  isTariffsLoading,
  price_list_name,
  priceListOptions,
  isPriceListsLoading,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const { setValue } = useFormContext<FormFields>();

  const handleTariffFilterChange = useCallback(
    (value: string) => {
      setValue('tariff_name', value);
    },
    [setValue]
  );

  const handleTariffSelectionChange = useCallback(
    (value: string | null) => {
      setValue('tariff_id', value);
    },
    [setValue]
  );

  const handlePriceListFilterChange = useCallback(
    (value: string) => {
      setValue('price_list_name', value);
    },
    [setValue]
  );

  const handlePriceListSelectionChange = useCallback(
    (value: string | null) => {
      setValue('price_list_id', value);
    },
    [setValue]
  );

  return (
    <div className="p-fluid">
      <FieldWithErrors name="tariff_id" label={t('Tariff')}>
        <Controller
          name="tariff_id"
          render={({ field }) => (
            <AutoCompleteInput
              filterValue={tariff_name}
              value={field.value}
              onFilterChange={handleTariffFilterChange}
              onSelectionChange={handleTariffSelectionChange}
              options={tariffOptions}
              placeholder={isTariffsLoading ? t('Loading...') : ''}
            />
          )}
        />
      </FieldWithErrors>

      <FieldWithErrors name="price_list_id" label={t('Price list')}>
        <Controller
          name="price_list_id"
          render={({ field }) => (
            <AutoCompleteInput
              filterValue={price_list_name}
              value={field.value}
              onFilterChange={handlePriceListFilterChange}
              onSelectionChange={handlePriceListSelectionChange}
              options={priceListOptions}
              placeholder={isPriceListsLoading ? t('Loading...') : ''}
            />
          )}
        />
      </FieldWithErrors>
    </div>
  );
}

export default GeneralInfo;
