import classNames from 'classnames';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { IntBool } from '../../../../../../enums/booleans';
import { OrderStatus } from '../../../../../../enums/orders';
import useMediaQuery from '../../../../../../hooks/useMediaQuery';
import { currencyFormat } from '../../../../../../utils/helpers/formatting';
import { tryFloat } from '../../../../../../utils/helpers/parse';
import { SingleOrder } from '../../../Orders.functions';
import { StatusTrackingApiData } from '../StatusTrackingDialog.functions';
import styles from '../StatusTrackingDialog.module.scss';

type Props = {
  orderStatusTrackingData: StatusTrackingApiData | undefined;
  data: SingleOrder | undefined;
  isOnMobile: boolean;
};

function OrderSummary({ data, orderStatusTrackingData }: Props): JSX.Element {
  const { t } = useTranslation();

  const isOnMobile = useMediaQuery('(max-width: 768px)');

  const shipmentInfoClassName = classNames('p-d-flex', {
    'p-flex-row  p-mb-2': !isOnMobile,
    'p-flex-column  p-mb-3': isOnMobile,
  });

  const filteredPayments = useMemo(() => {
    if (!data?.payments) {
      return null;
    }

    const filteredCashPayments = data.payments.filter(
      (p) => p.gotovina == IntBool.True
    );

    if (data.status_id <= OrderStatus.PickedUp) {
      return filteredCashPayments;
    }

    return filteredCashPayments.filter((p) => {
      return p.klient_id == data.klient_do_id || p.klient_id == 2;
    });
  }, [data?.klient_do_id, data?.payments, data?.status_id]);

  const paymentInfo = filteredPayments?.map((p, index) => (
    <div key={index}>
      <span>{p.opis}: </span>

      <span style={{ float: 'right', marginLeft: '14px' }}>
        {currencyFormat(p.iznos ?? '', {
          showCurrency: true,
        })}
      </span>

      <br />
    </div>
  ));

  const totalFilteredPayments = filteredPayments?.reduce((total, current) => {
    return total + (tryFloat(current.iznos) ?? 0);
  }, 0);

  return (
    <>
      <div className={shipmentInfoClassName}>
        <span className={styles['info-header']}>{t('Created')}</span>
        <div>
          <span className="p-mb-2 subtitle">
            {(data?.seriski_broj &&
              dayjs(
                orderStatusTrackingData?.[data?.seriski_broj]?.history?.[0]
                  ?.date
              ).format('DD MMM YYYY, HH:mm:ss')) ??
              '-'}
          </span>
        </div>
      </div>

      <div className={shipmentInfoClassName}>
        <span className={styles['info-header']}>{t('Sender Info')}</span>
        <div>
          <span className="p-mb-2 subtitle">{data?.klient_od_ime}</span>
          <br />
          <span className="subtitle">{data?.adresa_od}</span>
        </div>
      </div>

      <div className={shipmentInfoClassName}>
        <span className={styles['info-header']}>{t('Recipient Info')}</span>
        <div>
          <span className="subtitle">{data?.klient_do_ime}</span>
          <br />
          <span className="subtitle">{data?.adresa_do}</span>
        </div>
      </div>

      {data?.payments && (
        <div className={shipmentInfoClassName}>
          <span className={styles['info-header']}>{t('Payments')}</span>
          <div>
            <span className="subtitle">{paymentInfo}</span>
            <br />
            <span className="subtitle">{t('Total')}</span>
            <span className="subtitle" style={{ float: 'right' }}>
              <b>
                {currencyFormat(totalFilteredPayments ?? '', {
                  showCurrency: true,
                })}
              </b>
            </span>
          </div>
        </div>
      )}
    </>
  );
}

export default OrderSummary;
