import dayjs from 'dayjs';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { getFailedOrderReasonOptions } from '../../../../../../../../../configs/orders';
import { ReturnedOrderCheck } from '../../../../../../../../../enums/orders';
import FieldWithErrors from '../../../../../../../../Forms/ReactHookForm/FieldWithErrors';

function FormContent(): JSX.Element {
  const { t } = useTranslation();

  const returnReasonOptions = getFailedOrderReasonOptions(t);

  return (
    <div className="p-fluid">
      <FieldWithErrors name="reason_id" label={t('Reason')}>
        <Controller
          name="reason_id"
          render={({ field }) => (
            <Dropdown
              name="reason_id"
              inputId="reason_id"
              value={field.value}
              filter
              options={returnReasonOptions}
              onChange={field.onChange}
            />
          )}
        />
      </FieldWithErrors>

      <FieldWithErrors name="next_delivery" label={t('Next Delivery')}>
        <Controller
          name="next_delivery"
          render={({ field }) => (
            <Calendar
              name="next_delivery"
              inputId="next_delivery"
              monthNavigator
              yearNavigator
              readOnlyInput
              minDate={new Date()}
              yearRange={`2009:${dayjs().format('YYYY')}`}
              dateFormat="dd/mm/yy"
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </FieldWithErrors>

      <FieldWithErrors name="check_id" label={false}>
        <Controller
          name="check_id"
          render={({ field }) => (
            <div className="p-field-checkbox">
              <Checkbox
                name="check_id"
                inputId="check_id"
                checked={field.value === ReturnedOrderCheck.Yes}
                onChange={(e) =>
                  field.onChange(
                    e.checked ? ReturnedOrderCheck.Yes : ReturnedOrderCheck.No
                  )
                }
              />

              <label
                htmlFor="check_id"
                className="p-checkbox-label"
                data-cy="check_id"
              >
                {t('For Check')}
              </label>
            </div>
          )}
        />
      </FieldWithErrors>
    </div>
  );
}

export default FormContent;
