import {
  createPriceResourceListGuard,
  deletePriceResourceListGuard,
  editPriceResourceListGuard,
  priceListCollectionGuard,
  priceListReferenceCollectionGuard,
  priceListResourceGuard,
} from './_api/priceLists';

export const createGuard = createPriceResourceListGuard;
export const editGuard = editPriceResourceListGuard;
export const deleteGuard = [
  priceListReferenceCollectionGuard,
  deletePriceResourceListGuard,
];

export const routeGuard = [priceListResourceGuard, priceListCollectionGuard];
