import { dateCell } from '../../../../../../utils/helpers/dataTable';

export type LogsData = {
  deleted: number;
  import_result: string;
  logs: Logs;
};

export type Logs = {
  datum: string;
  klient_id: number;
  poraka: string;
  poraka_tip: string;
  red: number;
}[];

export function additionalColumnProperties(column: string) {
  switch (column) {
    case 'red':
      return {
        style: {
          width: 130,
        },
        bodyStyle: {
          width: 130,
        },
      };

    case 'datum':
      return {
        body: (row: any) => dateCell(row[column]),
        style: {
          width: 180,
        },
        bodyStyle: {
          width: 180,
        },
      };

    case 'poraka':
      return {
        style: {
          width: 'auto',
          minWidth: 350,
        },
        bodyStyle: {
          width: 'auto',
          minWidth: 350,
        },
      };

    default:
      return {};
  }
}
