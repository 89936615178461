import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useEffect } from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import ToastContext from '../../../../../context/ToastContext';
import useAxiosHook from '../../../../../hooks/useAxiosHook';
import usePrevious from '../../../../../hooks/usePrevious';
import {
  errorToast,
  successToast,
} from '../../../../../utils/helpers/primereact';
import DialogSpinner from '../../../../Dialogs/DialogSpinner/DialogSpinner';
import { OAuthClient } from '../../OAuthClients.functions';

type Props = {
  data: OAuthClient | undefined;
  isLoading: boolean;
  visible: boolean;
  tableDataReload: () => void;
  onHide: () => void;
};

function DeleteDialog({
  data,
  isLoading,
  visible,
  tableDataReload,
  onHide,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const { toastRef } = useContext(ToastContext);

  // Protect from deleting non-third-party customers
  useEffect(() => {
    if (visible && data?.type && data?.type !== 'tp-customer') {
      onHide();
    }
  }, [data?.type, onHide, visible]);

  const {
    data: deleteData,
    error: deleteError,
    isLoading: isDeleteLoading,
    reload: deleteReload,
    response: deleteResponse,
  } = useAxiosHook<string>(undefined, { skipWhen: true });

  const prevDeleteResponse = usePrevious(deleteResponse);
  const prevDeleteError = usePrevious(deleteError);

  useEffect(() => {
    if (
      deleteData === undefined ||
      deleteResponse?.config.url === prevDeleteResponse?.config.url ||
      !toastRef?.current
    ) {
      return;
    }

    successToast(
      toastRef,
      t('Success'),
      t('Client {{clientName}} was successfully deleted.', {
        clientName: data?.client_name,
      })
    );

    tableDataReload();
    onHide();
  }, [
    t,
    data?.client_name,
    deleteData,
    deleteResponse?.config.url,
    onHide,
    prevDeleteResponse?.config.url,
    tableDataReload,
    toastRef,
  ]);

  useEffect(() => {
    if (!deleteError || deleteError === prevDeleteError) {
      return;
    }

    errorToast(
      toastRef,
      t('Error'),
      t('An error occured while deleting client {{clientName}}.', {
        clientName: data?.client_name,
      })
    );

    onHide();
  }, [t, data?.client_name, deleteError, onHide, prevDeleteError, toastRef]);

  const dialogFooter = !isLoading && (
    <>
      <Button
        type="button"
        label={t('Close')}
        onClick={() => onHide()}
        className="p-button-secondary p-button-text"
      />

      <Button
        type="button"
        label={isDeleteLoading ? t('Deleting...') : t('Delete')}
        disabled={isDeleteLoading || data?.type !== 'tp-customer'}
        onClick={() => {
          deleteReload({ url: `/oauthclients/${data?.id}`, method: 'DELETE' });
        }}
        className="p-button-danger"
        data-cy="submit"
      />
    </>
  );

  return (
    <Dialog
      header={
        isLoading
          ? t('Loading...')
          : t('Delete {{clientName}}?', { clientName: data?.client_name })
      }
      footer={dialogFooter}
      visible={visible}
      resizable={false}
      onHide={onHide}
      style={{ width: 360, maxWidth: '100%' }}
    >
      {isLoading ? (
        <DialogSpinner />
      ) : (
        <p>
          {t('Are you sure you want to delete {{clientName}}?', {
            clientName: data?.client_name,
          })}
          <br />
          {t('This action cannot be undone.')}
        </p>
      )}
    </Dialog>
  );
}

export default DeleteDialog;
