import './ImportFile.scss';

import { Field, useFormikContext } from 'formik';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown, DropdownChangeParams } from 'primereact/dropdown';
import { FileUpload, FileUploadSelectParams } from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import { Tooltip } from 'primereact/tooltip';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-lodash-debounce';
import XLSX from 'xlsx';

import { FileTypes } from '../../../../../enums/files';
import useAxiosHook from '../../../../../hooks/useAxiosHook';
import usePrevious from '../../../../../hooks/usePrevious';
import {
  ClientImportListTypeCollection,
  ClientLookupCollection,
  ClientLookupCollectionQueryParams,
} from '../../../../../types/api/clients';
import { ImportListTypeResource } from '../../../../../types/api/importlisttypes';
import {
  MunicipalityCollection,
  MunicipalityPlaceCollection,
} from '../../../../../types/api/municipalities';
import {
  PlaceResource,
  PlacesStreetCollection,
} from '../../../../../types/api/places';
import { LabelValue, PlaceOption } from '../../../../../types/options';
import {
  debounceTimeout,
  isStreetFreeInputAllowed,
} from '../../../../../utils/constants/misc';
import { cleanUpCsv } from '../../../../../utils/helpers/files';
import { queryString } from '../../../../../utils/helpers/http';
import {
  nonEmptyObjectsOnly,
  placeItemTemplate,
  placeValueTemplate,
} from '../../../../../utils/helpers/misc';
import { tryString } from '../../../../../utils/helpers/parse';
import AutoCompleteInput from '../../../../Forms/AutoCompleteInput/AutoCompleteInput';
import FieldWithErrors from '../../../../Forms/FieldWithErrors/FieldWithErrors';
import { ClientOption, FormValues, csvDelimiter } from './ImportFile.functions';

type Props = {
  client: string;
  setClient: Dispatch<SetStateAction<string>>;
  clientObj: ClientOption | null;
  setClientObj: Dispatch<SetStateAction<ClientOption | null>>;
  listTypeObj: ImportListTypeResource | null;
  setListTypeObj: Dispatch<SetStateAction<ImportListTypeResource | null>>;
};

function ImportFromFileForm({
  client,
  setClient,
  clientObj,
  setClientObj,
  listTypeObj,
  setListTypeObj,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const debouncedClientFilter = useDebounce(client, debounceTimeout);

  const { values, errors, touched, setFieldValue, resetForm } =
    useFormikContext<FormValues>();

  const { data: clientListTypesData, isLoading: isClientListTypesDataLoading } =
    useAxiosHook<ClientImportListTypeCollection>(
      `/clients/${clientObj?.id}/importlists/types`,
      {
        skipWhen: !clientObj,
      }
    );

  const selectedListType = useMemo(
    () => clientListTypesData?.find((l) => l.name === values.listtype),
    [clientListTypesData, values.listtype]
  );

  const { data: listTypeData, isLoading: isListTypeLoading } =
    useAxiosHook<ImportListTypeResource>(
      `/importlisttypes/${selectedListType?.id}`,
      {
        skipWhen: !selectedListType,
      }
    );

  const prevlistTypeData = usePrevious(listTypeData);

  useEffect(() => {
    if (!listTypeData || listTypeData === prevlistTypeData) {
      return;
    }

    setListTypeObj(listTypeData);

    setFieldValue('postarina', listTypeData.plaka_postarina_isprakac);
    setFieldValue('otkup', listTypeData.plaka_otkup_isprakac);
    setFieldValue('osiguruvanje', listTypeData.plaka_osiguruvanje_isprakac);
    setFieldValue(
      'povraten_dokument',
      listTypeData.plaka_povraten_dokument_isprakac
    );
  }, [listTypeData, prevlistTypeData, setFieldValue, setListTypeObj]);

  const paymentOptions = useMemo(
    () =>
      nonEmptyObjectsOnly<LabelValue>([
        {
          label: t('Sender'),
          value: '1',
        },
        {
          label: t('Recipient'),
          value: '2',
        },
        listTypeObj?.klient_isSender === 0
          ? { label: t('Orderer'), value: '0' }
          : {},
      ]),
    [t, listTypeObj]
  );

  const { data: clientsData, isLoading: isClientsDataLoading } =
    useAxiosHook<ClientLookupCollection>(
      '/clients/lookup' +
        queryString<ClientLookupCollectionQueryParams>({
          ime: debouncedClientFilter,
          page: 1,
          limit: 20,
        })
    );

  const clientsOptions = useMemo<LabelValue<ClientOption>[]>(
    () =>
      clientsData?.data?.map((client) => ({
        label: client.ime,
        value: client,
      })) ?? [],
    [clientsData?.data]
  );

  const listTypeOptions = useMemo<LabelValue[]>(
    () =>
      clientListTypesData?.map((listType) => ({
        label: listType.name,
        value: listType.name,
      })) ?? [],
    [clientListTypesData]
  );

  const { data: municipalities, isLoading: isLoadingMunicipalities } =
    useAxiosHook<MunicipalityCollection>('/municipalities');

  const { data: placeData, isLoading: isPlaceLoading } =
    useAxiosHook<PlaceResource>(`/places/${clientObj?.mesto_id}`, {
      skipWhen: !clientObj?.mesto_id,
    });

  const prevplaceData = usePrevious(placeData);

  useEffect(() => {
    if (!placeData || placeData === prevplaceData) {
      return;
    }

    setFieldValue('municipality', String(placeData.opstina_id));
  }, [placeData, prevplaceData, setFieldValue]);

  useEffect(() => {
    return () => {
      resetForm();
    };
  }, [resetForm]);

  useEffect(() => {
    setFieldValue('user_id', clientObj?.korisnik_id ?? '');
    setFieldValue('place', tryString(clientObj?.mesto_id) ?? '');
    setFieldValue('street', tryString(clientObj?.ulica_id) ?? '');
    setFieldValue('adresa', clientObj?.adresa ?? '');
    setFieldValue('broj', clientObj?.broj ?? '');
    setFieldValue('vlez', clientObj?.vlez ?? '');
    setFieldValue('stan', clientObj?.stan ?? '');

    if (!clientObj) {
      setFieldValue('municipality', '');
    }
  }, [clientObj, setFieldValue]);

  const { data: places, isLoading: isLoadingPlaces } =
    useAxiosHook<MunicipalityPlaceCollection>(
      `/municipalities/${values.municipality}/places`,
      {
        skipWhen: !values.municipality,
      }
    );

  const { data: streets, isLoading: isLoadingStreets } =
    useAxiosHook<PlacesStreetCollection>(`/places/${values.place}/streets`, {
      skipWhen: !values.place,
    });

  const municipalitiesOptions = useMemo<LabelValue<string>[]>(
    () =>
      municipalities?.map((m) => ({
        label: m.ime,
        value: String(m.id),
      })) ?? [],
    [municipalities]
  );

  const placesOptions = useMemo<PlaceOption[]>(
    () =>
      places?.map((m) => ({
        label: m.ime,
        value: String(m.id),
        postal_code: m.postenski_broj,
      })) ?? [],
    [places]
  );

  const streetsOptions = useMemo<LabelValue<string>[]>(
    () =>
      streets?.length && streets?.length > 0
        ? streets.map((m) => ({ label: m.ime, value: String(m.id) }))
        : [],
    [streets]
  );

  async function uploadHandler(evt: FileUploadSelectParams): Promise<void> {
    const file = evt.files[0];

    if (file.size > 1000000) {
      return;
    }

    const fileReader = new FileReader();

    // The logic here is to first parse the file with XLSX - since this library has a powerful parser,
    //  it'd be best to avoid parsing it ourselves. The approach is to simply normalize it,
    //  i.e., act like we've imported an excel file, and then cleanup the potentially messy CSV.
    // The internal functions of "cleanUpCsv" collide when there's a bad CSV. Example:
    //  "Header 1; Header 2; Header 3; Header 4"
    //  "Value11;Value12;Value13;Value14"
    //  "Value21;Value22;Value23;Value24"
    //  "Value31;Value32;Value33;Value34"
    // XLSX can't possibly parse this as well, but according to the CSV only cells can be wrapped in quotes
    //  and it doesn't make sense to wrap rows in quotes. So, the joke is on the user.
    if (file.type === FileTypes.CSV) {
      fileReader.onload = (e) => {
        if (!e.target?.result || typeof e.target.result !== 'string') {
          return;
        }

        // Normalize CSV file before processing
        const workbook = XLSX.read(e.target.result, { type: 'string' });
        const firstSheet = workbook.Sheets[Object.keys(workbook.Sheets)[0]];

        const csvData = XLSX.utils.sheet_to_csv(firstSheet, {
          FS: csvDelimiter,
          forceQuotes: true,
        });

        // CSV cleanup
        const cleanedUpCsvData = cleanUpCsv(csvData, csvDelimiter);

        setFieldValue('csv', cleanedUpCsvData);
        setFieldValue('delimiter', csvDelimiter);
      };

      fileReader.readAsText(file);
    } else {
      fileReader.onload = (e) => {
        if (!e.target?.result || typeof e.target.result === 'string') {
          return;
        }

        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const firstSheet = workbook.Sheets[Object.keys(workbook.Sheets)[0]];

        const csvData = XLSX.utils.sheet_to_csv(firstSheet, {
          FS: csvDelimiter,
          forceQuotes: true,
        });

        const cleanedUpCsvData = cleanUpCsv(csvData, csvDelimiter);

        setFieldValue('csv', cleanedUpCsvData);
        setFieldValue('delimiter', csvDelimiter);
      };

      fileReader.readAsArrayBuffer(file);
    }
  }

  const clientsSelectionChange = useCallback(
    (e) => {
      setClientObj(e);
      setFieldValue('listtype', '');
      setListTypeObj(null);
    },
    [setClientObj, setFieldValue, setListTypeObj]
  );

  return (
    <div className="import-from-file-form">
      <div className="client-and-list-type">
        <span className="section-title">{t('Client')}</span>
        <span className="section-subtitle">
          {t('Which client owns the order?')}
        </span>
        <FieldWithErrors name="client" label={t('Client')}>
          <Field
            name="client"
            id="client"
            options={clientsOptions}
            as={AutoCompleteInput}
            filterValue={client}
            onFilterChange={setClient}
            onSelectionChange={clientsSelectionChange}
            disabled={isListTypeLoading}
            filterDataCy="client"
            optionsClassName="data-cy-client-options"
          />
        </FieldWithErrors>

        <FieldWithErrors name="listtype" label={t('Batch Order Template')}>
          <Field
            name="listtype"
            inputId="listtype"
            options={listTypeOptions}
            as={Dropdown}
            filter
            disabled={
              !clientObj ||
              isClientsDataLoading ||
              isListTypeLoading ||
              (listTypeOptions?.length === 0 && !isClientListTypesDataLoading)
            }
            placeholder={
              !clientObj
                ? t('Select a client first')
                : isClientsDataLoading
                ? 'Loading...'
                : listTypeOptions?.length === 0 && !isClientListTypesDataLoading
                ? t('This client has no list types assigned yet')
                : undefined
            }
            className="data-cy-listtype"
          />
        </FieldWithErrors>

        {isListTypeLoading && (
          <div className="p-m-0 p-text-bold">{t('Loading...')}</div>
        )}
      </div>

      {!isListTypeLoading && clientObj && listTypeObj?.klient_isSender === 1 && (
        <>
          <span className="section-title">{t('Sender address')}</span>
          <span className="section-subtitle">
            {t('What is the pickup location?')}
          </span>

          <div className="klient-is-sender">
            <FieldWithErrors name="municipality" label={t('Municipality')}>
              <Field
                name="municipality"
                as={Dropdown}
                inputId="municipality"
                options={municipalitiesOptions}
                filter
                filterPlaceholder={t('Search')}
                showClear={false}
                disabled={isPlaceLoading}
                placeholder={
                  isLoadingMunicipalities ? t('Loading...') : undefined
                }
                onChange={(e: DropdownChangeParams) => {
                  setFieldValue('municipality', e.value);
                  setFieldValue('place', '');
                  setFieldValue('street', '');
                  setFieldValue('adresa', '');
                  setFieldValue('broj', '');
                  setFieldValue('vlez', '');
                  setFieldValue('stan', '');
                }}
              />
            </FieldWithErrors>

            <FieldWithErrors name="place" label={t('Place')}>
              <Field
                disabled={
                  !values.municipality || isLoadingPlaces || isPlaceLoading
                }
                name="place"
                inputId="place"
                as={Dropdown}
                options={placesOptions}
                itemTemplate={placeItemTemplate}
                valueTemplate={placeValueTemplate}
                filter
                filterPlaceholder={t('Search')}
                showClear={false}
                placeholder={isLoadingPlaces ? t('Loading...') : undefined}
                className={
                  errors.place && touched.place ? 'invalid' : undefined
                }
                onChange={(e: DropdownChangeParams) => {
                  setFieldValue('place', e.value);
                  setFieldValue('street', '');
                  setFieldValue('adresa', '');
                  setFieldValue('broj', '');
                  setFieldValue('vlez', '');
                  setFieldValue('stan', '');
                }}
              />
            </FieldWithErrors>

            <FieldWithErrors
              name="street"
              label={isStreetFreeInputAllowed ? t('Address') : t('Street')}
            >
              {isStreetFreeInputAllowed ? (
                <Field
                  as={InputText}
                  name="adresa"
                  id="adresa"
                  disabled={!values.municipality || !values.place}
                  maxLength="256"
                />
              ) : (
                <>
                  <Field
                    disabled={
                      !values.municipality || !values.place || isLoadingStreets
                    }
                    name="street"
                    inputId="street"
                    as={Dropdown}
                    options={streetsOptions}
                    filter
                    filterPlaceholder={t('Search')}
                    showClear={false}
                    placeholder={isLoadingStreets ? t('Loading...') : undefined}
                    className={
                      errors.place && touched.place ? 'invalid' : undefined
                    }
                  />
                  <div className="street-numbers">
                    <FieldWithErrors name="broj" label={t('Street No.')}>
                      <Field
                        as={InputText}
                        disabled={
                          isStreetFreeInputAllowed
                            ? !values.adresa
                            : !values.street
                        }
                        className={`broj p-inputtext p-component ${
                          errors.broj && touched.broj ? 'invalid' : ''
                        }`}
                        id="broj"
                        name="broj"
                      />
                    </FieldWithErrors>

                    <FieldWithErrors name="vlez" label={t('Entrance No.')}>
                      <Field
                        as={InputText}
                        disabled={
                          isStreetFreeInputAllowed
                            ? !values.adresa
                            : !values.street
                        }
                        className={`vlez p-inputtext p-component ${
                          errors.vlez && touched.vlez ? 'invalid' : ''
                        }`}
                        id="vlez"
                        name="vlez"
                      />
                    </FieldWithErrors>

                    <FieldWithErrors name="stan" label={t('Flat No.')}>
                      <Field
                        as={InputText}
                        disabled={
                          isStreetFreeInputAllowed
                            ? !values.adresa
                            : !values.street
                        }
                        className={`stan p-inputtext p-component ${
                          errors.stan && touched.stan ? 'invalid' : ''
                        }`}
                        id="stan"
                        name="stan"
                      />
                    </FieldWithErrors>
                  </div>
                </>
              )}
            </FieldWithErrors>
          </div>
        </>
      )}

      {!isListTypeLoading && listTypeObj?.changeable_payment === '1' && (
        <>
          <span className="section-title">{t('Payments')}</span>

          <span className="section-subtitle">{t('Who pays for what?')}</span>

          <div className="changeable-payment">
            <div>
              <span>{t('Postage')}</span>

              {paymentOptions.map((option) => {
                return (
                  <div key={option.label} className="p-field-radiobutton">
                    <RadioButton
                      inputId={`postarina-${option.label}`}
                      name="postarina"
                      value={option}
                      onChange={(e) =>
                        setFieldValue('postarina', e.value.value)
                      }
                      checked={values.postarina === option.value}
                    />

                    <label htmlFor={`postarina-${option.label}`}>
                      {option.label}
                    </label>
                  </div>
                );
              })}
            </div>

            <div>
              <span>{t('Redemption')}</span>

              {paymentOptions.map((option) => {
                return (
                  <div key={option.label} className="p-field-radiobutton">
                    <RadioButton
                      inputId={`otkup-${option.label}`}
                      name="otkup"
                      value={option}
                      onChange={(e) => setFieldValue('otkup', e.value.value)}
                      checked={values.otkup === option.value}
                    />

                    <label htmlFor={`otkup-${option.label}`}>
                      {option.label}
                    </label>
                  </div>
                );
              })}
            </div>

            <div>
              <span>{t('Return document')}</span>

              {paymentOptions.map((option) => {
                return (
                  <div key={option.label} className="p-field-radiobutton">
                    <RadioButton
                      inputId={`povraten_dokument-${option.label}`}
                      name="povraten_dokument"
                      value={option}
                      onChange={(e) =>
                        setFieldValue('povraten_dokument', e.value.value)
                      }
                      checked={values.povraten_dokument === option.value}
                    />

                    <label htmlFor={`povraten_dokument-${option.label}`}>
                      {option.label}
                    </label>
                  </div>
                );
              })}
            </div>

            <div>
              <span>{t('Insurance')}</span>

              {paymentOptions.map((option) => {
                return (
                  <div key={option.label} className="p-field-radiobutton">
                    <RadioButton
                      inputId={`osiguruvanje-${option.label}`}
                      name="osiguruvanje"
                      value={option}
                      onChange={(e) =>
                        setFieldValue('osiguruvanje', e.value.value)
                      }
                      checked={values.osiguruvanje === option.value}
                    />

                    <label htmlFor={`osiguruvanje-${option.label}`}>
                      {option.label}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}

      {!isListTypeLoading && clientObj && listTypeObj && (
        <>
          <span className="section-title">{t('File')}</span>

          <span className="section-subtitle">
            {t('Upload your Excel or CSV file')}
          </span>

          <div className="content">
            <div className="upload-file">
              <FieldWithErrors name="csv" label={false} className="upload-file">
                <Field
                  name="csv"
                  as={FileUpload}
                  id="csv-file-upload"
                  customUpload
                  mode="advanced"
                  accept=".xlsx, .xls, .csv, .tsv, .ods, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  maxFileSize={1000000}
                  onSelect={uploadHandler}
                  chooseLabel={t('Upload')}
                  className="p-button-outlined"
                  onRemove={() => setFieldValue('csv', '')}
                  icon="fas fa-upload"
                  disabled={!!values?.csv}
                />
              </FieldWithErrors>
            </div>

            <Tooltip target=".import-all-or-nothing" />
            <Tooltip target=".overwrite-existing" />

            <div
              className="import-all-or-nothing"
              data-pr-tooltip={t(
                'If an error occurs in a single shipment, all shipments will be rolled back.'
              )}
            >
              <Checkbox
                name="import_all_or_none"
                inputId="import_all_or_none"
                checked={values.import_all_or_none}
                onChange={(e) => setFieldValue('import_all_or_none', e.checked)}
              />

              <label htmlFor="import_all_or_none">
                {t('Import all or nothing')}
              </label>
            </div>

            <div
              className="overwrite-existing"
              data-pr-tooltip={t(
                'Updates already existing shipments instead of importing them as new.'
              )}
            >
              <Checkbox
                name="overwrite_existing"
                inputId="overwrite_existing"
                checked={values.overwrite_existing}
                onChange={(e) => setFieldValue('overwrite_existing', e.checked)}
              />

              <label htmlFor="overwrite_existing">
                {t('Overwrite already existing')}
              </label>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ImportFromFileForm;
