import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import styles from './GoBack.module.scss';

type Props = {
  to: string;
  page: string;
};

function GoBackWithHistory({ to, page }: Props): JSX.Element {
  const { t } = useTranslation();

  const history = useHistory();

  // History == 2 means the page was opened from a new tab,
  //   so if we go back when this is the case,
  //   the user will end up back to the new tab page
  return history.length > 2 ? (
    <div
      onClick={() => history.goBack()}
      className={styles.goBackLink}
      data-cy="go-back-btn"
    >
      <FontAwesomeIcon icon={faChevronLeft} /> {t('Go back')}
    </div>
  ) : (
    <Link replace to={to} className={styles.goBackLink} data-cy="go-back-btn">
      <FontAwesomeIcon icon={faChevronLeft} /> {t('Back to')} {page}
    </Link>
  );
}

export default GoBackWithHistory;
