import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import useToastMessage from '../../../../../../hooks/useToastMessage';
import { HubCollection } from '../../../../../../types/api/hubs';
import { RoutePaths } from '../../../../../../utils/constants/routePaths';
import DialogSpinner from '../../../../../Dialogs/DialogSpinner/DialogSpinner';

export type ContentProps = {
  data: object | undefined;
  error: AxiosError | undefined;
  isLoading: boolean;
  isHubSetAsANativeHubForSomeEmployees: boolean;
  selection: HubCollection['data'][0] | null;
  successCallback: () => void;
  errorCallback: () => void;
};

function Content({
  data,
  error,
  isLoading,
  isHubSetAsANativeHubForSomeEmployees,
  selection,
  successCallback,
  errorCallback,
}: ContentProps): JSX.Element {
  const { t } = useTranslation();

  useToastMessage(
    data,
    !isHubSetAsANativeHubForSomeEmployees ? error : undefined,
    {
      success: {
        summary: t('Warehouse {{name}} has been deleted successfully.', {
          name: selection?.name ?? '',
        }),
        callback: successCallback,
      },
      error: {
        summary: t('An error occured while deleting warehouse {{name}}.', {
          name: selection?.name ?? '',
        }),
        callback: errorCallback,
      },
    }
  );

  return isLoading ? (
    <DialogSpinner />
  ) : !isHubSetAsANativeHubForSomeEmployees ? (
    <p>
      {t('Are you sure you want to delete {{name}}?', {
        name: selection?.name ?? '',
      })}
      <br />
      {t('This action cannot be undone.')}
    </p>
  ) : (
    <div className="native-hub-error-grid">
      <div className="p-ml-3">
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          color={'var(--surface-300)'}
          size="5x"
        />
      </div>

      <div>
        <p>
          {t('We had trouble deleting {{name}}.', {
            name: selection?.name ?? '',
          })}
        </p>
        <p>
          {t(
            'This warehouse is used as a native warehouse for some employees. Deleting it will not be possible as long as this is the case.'
          )}
        </p>
        <p>
          {t(
            'To update the employees that have this warehouse set as their native warehouse, click'
          )}{' '}
          <Link
            to={`${RoutePaths.Employees}?native_hub=${selection?.id ?? ''}`}
            target="_blank"
          >
            {t('here')}.
          </Link>
        </p>
      </div>
    </div>
  );
}

export default Content;
