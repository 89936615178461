import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';

import SidebarItem from '../../../../../Grid/Sidebar/SidebarItem';

type Props = {
  onBtnClick: () => void;
  isDisabled: boolean;
  isLoading: boolean;
  hasError: boolean;
};

function Actions({
  onBtnClick,
  isDisabled,
  isLoading,
  hasError,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <SidebarItem template="actions">
      {hasError && <p>{t('An error occured')}</p>}

      <Button
        type="button"
        icon="fas fa-save"
        label={isLoading ? t('Loading...') : t('Create warrant')}
        onClick={onBtnClick}
        disabled={isDisabled}
        style={{ width: '100%' }}
      />
    </SidebarItem>
  );
}

export default Actions;
