import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

import { XOR } from '../../../types/util';
import CollapsibleFilterSection from '../../layout/flex/components/CollapsibleFilterSection';
import { getTemplateIcon, getTemplateTitle } from './SidebarItem.functions';
import styles from './SidebarItem.module.scss';
import { Template, TooltipOptions } from './SidebarItem.types';

type Props = {
  tooltip?: TooltipOptions;
  className?: string;
  children: ReactNode;
  direction?: string;
  filtersCount?: number;
} & XOR<
  {
    template: Template;
    fluid?: boolean;
  },
  {
    title: string;
    icon: IconDefinition;
  }
>;

function SidebarItem({
  template,
  // Stats items don't usually need to be fluid
  fluid = template !== 'stats',
  title: _title,
  icon: _icon,
  tooltip,
  className: _className,
  children,
  direction = 'row',
  filtersCount,
}: Props): JSX.Element {
  const { t } = useTranslation();

  useEffect(() => {
    if (tooltip) {
      ReactTooltip.rebuild();
    }
  }, [tooltip]);

  const title = template ? getTemplateTitle(t, template) : _title!;
  const icon = template ? getTemplateIcon(template) : _icon!;

  const className = classNames(styles.sidebarItem, {
    [_className ?? '']: !!_className,
  });

  const childrenWrapperClassname = classNames(styles.childrenWrapper, {
    'p-fluid': fluid,
  });

  return (
    // lets see what customers like more
    <>
      {direction === 'column' ? (
        <CollapsibleFilterSection
          childrenWrapperClassName={classNames(styles.filtersWrapper)}
          activeFiltersCount={filtersCount}
          // direction={direction}
        >
          <div
            className={classNames(childrenWrapperClassname, styles.filterDiv)}
          >
            {children}
          </div>
        </CollapsibleFilterSection>
      ) : (
        <div className={className}>
          <h3>
            <FontAwesomeIcon icon={icon} />
            {title}

            {tooltip && (
              <span
                data-tip={tooltip.content}
                data-for={`tooltip-${tooltip.position ?? 'right'}`}
                className={classNames(tooltip.className, styles.tooltip)}
              >
                <FontAwesomeIcon icon={faInfoCircle} />
              </span>
            )}
          </h3>

          <div className={childrenWrapperClassname}>{children}</div>
        </div>
      )}
    </>
  );
}

export default SidebarItem;
