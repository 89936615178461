import { yupResolver } from '@hookform/resolvers/yup';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { useEffect, useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  CreatePriceListResourceRequestPayload,
  PriceListResource,
  UpdatePriceListResourceRequestPayload,
} from '../../../../../types/api/priceLists';
import { LabelValue } from '../../../../../types/options';
import { getChangedValues } from '../../../../../utils/helpers/object';
import FieldWithErrors from '../../../../Forms/ReactHookForm/FieldWithErrors';
import {
  getDefaultValues,
  getValidationSchema,
  toCreateResourceApiData,
  toUpdateResourceApiData,
} from './CreateEditDialog.functions';
import { FormFields } from './CreateEditDialog.types';

type Props = {
  isEditDialog: boolean;
  priceListData: PriceListResource | undefined;
  parentOptions: LabelValue[];
  handleCreation: (data: CreatePriceListResourceRequestPayload) => void;
  handleEditing: (data: UpdatePriceListResourceRequestPayload) => void;
};

function FormContainer({
  isEditDialog,
  priceListData,
  parentOptions,
  handleCreation,
  handleEditing,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const defaultValues = useMemo(
    () => getDefaultValues(isEditDialog, priceListData),
    [isEditDialog, priceListData]
  );
  const validationSchema = useMemo(() => getValidationSchema(t), [t]);

  const methods = useForm<FormFields>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const { handleSubmit, reset } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  function handleFormSubmission(values: FormFields) {
    if (isEditDialog) {
      handleEditing(
        getChangedValues(
          toUpdateResourceApiData(values, priceListData),
          toUpdateResourceApiData(defaultValues, priceListData)
        )
      );

      return;
    }

    handleCreation(toCreateResourceApiData(values));
  }

  return (
    <FormProvider {...methods}>
      <form
        id="price-lists-create-edit-form"
        onSubmit={handleSubmit(handleFormSubmission)}
      >
        <div className="p-fluid">
          <FieldWithErrors
            name="parent_id"
            label={t('Base price list')}
            tooltip={t(
              'The parent price list from which prices will be inherited from.'
            )}
          >
            <Controller
              name="parent_id"
              render={({ field }) => (
                <Dropdown
                  name="parent_id"
                  inputId="parent_id"
                  options={parentOptions}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </FieldWithErrors>

          <FieldWithErrors name="name" label={t('Name')}>
            <Controller
              name="name"
              render={({ field }) => (
                <InputText
                  id="name"
                  name="name"
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </FieldWithErrors>

          <FieldWithErrors name="description" label={t('Description')}>
            <Controller
              name="description"
              render={({ field }) => (
                <InputTextarea
                  id="description"
                  name="description"
                  autoResize
                  style={{ maxHeight: 150 }}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </FieldWithErrors>
        </div>
      </form>
    </FormProvider>
  );
}

export default FormContainer;
