import classNames from 'classnames';
import dayjs from 'dayjs';
import { Field, useFormikContext } from 'formik';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { useTranslation } from 'react-i18next';

import { usePhoneOptionsContext } from '../../../../context/PhoneOptionsContext';
import useAxios from '../../../../hooks/useAxios';
import { invalidPhoneNumberCharactersRegex } from '../../../../utils/constants/phoneNumbers';
import {
  placeItemTemplate,
  placeValueTemplate,
} from '../../../../utils/helpers/misc';
import {
  formatMobileNumber,
  formatPhoneNumber,
  getPhoneorMobileNumberRegion,
} from '../../../../utils/helpers/phoneNumbers';
import FieldWithErrors from '../../../Forms/FieldWithErrors/FieldWithErrors';

function CreateEditDialogBasicInfoTab({ isEditDialog }) {
  const { t } = useTranslation();

  const { values, errors, touched, setFieldValue } = useFormikContext();

  const { data: municipalities, isLoading: isLoadingMunicipalities } =
    useAxios('/municipalities');

  useAxios(`/places/${values.mesto_id}`, undefined, {
    skipWhen: !values.mesto_id,
    successCallback: (place) => {
      setFieldValue('place', place.ime);
      setFieldValue('municipality', place.opstina_id);
      setFieldValue('municipality_id', place.opstina_id);
    },
  });

  const {
    data: places,
    reload: reloadPlaces,
    isLoading: isLoadingPlaces,
  } = useAxios(`/municipalities/${values.municipality_id}/places`, undefined, {
    skipWhen: !values.municipality_id,
  });

  const municipalitiesOptions = municipalities?.map((m) => {
    return { label: m.ime, value: m.id };
  });

  const placesOptions = places?.map((m) => {
    return {
      label: m.ime,
      value: m.ime,
      postal_code: m.postenski_broj,
    };
  });

  const { countryOptionTemplate, languages, selectedCountryTemplate } =
    usePhoneOptionsContext();

  return (
    <div className="basic-info-tab p-fluid">
      <div>
        <FieldWithErrors name="ime" label={t('Name')}>
          <Field
            as={InputText}
            id="ime"
            name="ime"
            maxLength="25"
            data-cy="name"
          />
        </FieldWithErrors>

        <FieldWithErrors name="prezime" label={t('Surname')}>
          <Field
            as={InputText}
            id="prezime"
            name="prezime"
            maxLength="25"
            data-cy="surname"
          />
        </FieldWithErrors>

        {isEditDialog && (
          <FieldWithErrors name="username" label={t('Email (username)')}>
            <Field
              as={InputText}
              name="username"
              id="username"
              maxLength="254"
              data-cy="username"
            />
          </FieldWithErrors>
        )}

        <FieldWithErrors name="adresa" label={t('Address')}>
          <Field
            as={InputText}
            id="adresa"
            name="adresa"
            maxLength="50"
            data-cy="address"
          />
        </FieldWithErrors>

        <FieldWithErrors name="municipality" label={t('Municipality')}>
          <Field
            name="municipality"
            as={Dropdown}
            inputId="municipality"
            options={municipalitiesOptions}
            filter
            filterPlaceholder={t('Search')}
            disabled={isLoadingMunicipalities}
            placeholder={isLoadingMunicipalities ? t('Loading...') : null}
            onChange={(e) => {
              setFieldValue('municipality', e.value);
              setFieldValue('municipality_id', e.value);

              reloadPlaces({ url: `municipalities/${e.value}/places` });

              setFieldValue('place', '');
            }}
            className="data-cy-municipality"
          />
        </FieldWithErrors>

        <FieldWithErrors
          name="place"
          label="Place"
          includeErrorsFor={['mesto_id']}
        >
          <Field
            disabled={!values.municipality || isLoadingPlaces}
            name="place"
            inputId="place"
            as={Dropdown}
            options={placesOptions}
            itemTemplate={placeItemTemplate}
            valueTemplate={placeValueTemplate}
            filter
            filterPlaceholder={t('Search')}
            placeholder={isLoadingPlaces ? t('Loading...') : null}
            className={classNames('data-cy-place', {
              invalid: errors.place && touched.place,
            })}
            onChange={(e) => {
              if (e.value) {
                const place = places.find((p) => p.ime === e.value);

                setFieldValue('mesto_id', place.id);
                setFieldValue('place', e.value);
              } else {
                setFieldValue('mesto_id', '');
                setFieldValue('place', '');
              }
            }}
          />
        </FieldWithErrors>

        <FieldWithErrors name="telefon" label={t('Phone')}>
          <div className="p-inputgroup number-group">
            <Field
              as={Dropdown}
              className="telefon_region"
              style={{ padding: 0, width: '5rem' }}
              id="telefon_region"
              name="telefon_region"
              options={languages}
              itemTemplate={countryOptionTemplate}
              valueTemplate={selectedCountryTemplate}
              data-cy="telefon_region"
            />

            <Field
              as={InputText}
              id="telefon"
              name="telefon"
              maxLength="13"
              value={formatPhoneNumber(
                values.telefon,
                values.telefon_region ||
                  getPhoneorMobileNumberRegion(values.telefon)
              )}
              onChange={(e) => {
                setFieldValue(
                  'telefon',
                  e.target.value?.replace(invalidPhoneNumberCharactersRegex, '')
                );
              }}
              data-cy="phone"
            />
          </div>
        </FieldWithErrors>

        <FieldWithErrors name="mobilen" label={t('Mobile')}>
          <div className="p-inputgroup number-group">
            <Field
              as={Dropdown}
              className="mobilen_region"
              style={{ padding: 0, width: '5rem' }}
              id="mobilen_region"
              name="mobilen_region"
              options={languages}
              itemTemplate={countryOptionTemplate}
              valueTemplate={selectedCountryTemplate}
              data-cy="mobilen_region"
            />

            <Field
              as={InputText}
              id="mobilen"
              name="mobilen"
              maxLength="13"
              value={formatMobileNumber(
                values.mobilen,
                values.mobilen_region ||
                  getPhoneorMobileNumberRegion(values.mobilen)
              )}
              onChange={(e) => {
                setFieldValue(
                  'mobilen',
                  e.target.value?.replace(invalidPhoneNumberCharactersRegex, '')
                );
              }}
              data-cy="mobile"
            />
          </div>
        </FieldWithErrors>

        <FieldWithErrors name="aktiven" label={false}>
          <div className="checkbox-container">
            <Field
              inputId="aktiven"
              name="aktiven"
              as={Checkbox}
              checked={values.aktiven}
            />

            <label htmlFor="aktiven">{t('Set status to active?')}</label>
          </div>
        </FieldWithErrors>
      </div>

      {!isEditDialog && (
        <div className="form-divider">
          <FieldWithErrors name="username" label={t('Email (username)')}>
            <Field
              as={InputText}
              id="username"
              name="username"
              maxLength="254"
              data-cy="username"
            />
          </FieldWithErrors>

          <FieldWithErrors name="datum_raganje" label={t('Date of Birth')}>
            <Field
              name="datum_raganje"
              as={Calendar}
              inputId="datum_raganje"
              dateFormat="dd/mm/yy"
              monthNavigator
              yearNavigator
              yearRange={`1900:${dayjs().format('YYYY')}`}
              inputClassName="data-cy-dob"
              onChange={(e) =>
                setFieldValue('datum_raganje', e.value ?? '', false)
              }
            />
          </FieldWithErrors>

          <FieldWithErrors name="embg" label={t('Personal No.')}>
            <Field
              as={InputText}
              id="embg"
              name="embg"
              maxLength="13"
              data-cy="ssn"
            />
          </FieldWithErrors>

          <FieldWithErrors name="password" label={t('Password')}>
            <Field
              as={InputText}
              id="password"
              name="password"
              data-cy="password"
            />
          </FieldWithErrors>

          <FieldWithErrors name="komentar" label={t('Note')}>
            <Field
              as={InputTextarea}
              autoResize
              id="komentar"
              name="komentar"
              data-cy="note"
            />
          </FieldWithErrors>
        </div>
      )}
    </div>
  );
}

export default CreateEditDialogBasicInfoTab;
