import dayjs from 'dayjs';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { LabelValue } from '../../../../types/options';
import AutoCompleteInput from '../../../Forms/AutoCompleteInput/AutoCompleteInput';
import SidebarItem from '../../../Grid/Sidebar/SidebarItem';
import { EmployeeOption } from '../CourierCompensation.functions';

type Props = {
  dateFromFilter: Date | null;
  setDateFromFilter: Dispatch<SetStateAction<Date | null>>;
  dateToFilter: Date | null;
  setDateToFilter: Dispatch<SetStateAction<Date | null>>;
  employeeFilter: string;
  setEmployeeFilter: Dispatch<SetStateAction<string>>;
  employeeFilterObj: EmployeeOption | null;
  setEmployeeFilterObj: Dispatch<SetStateAction<EmployeeOption | null>>;
  serialNoFilter: string;
  setSerialNoFilter: Dispatch<SetStateAction<string>>;
  employeeOptions: LabelValue<EmployeeOption>[];
};

function CompensationDocFilters({
  dateFromFilter,
  setDateFromFilter,
  dateToFilter,
  setDateToFilter,
  employeeFilter,
  setEmployeeFilter,
  employeeFilterObj,
  setEmployeeFilterObj,
  serialNoFilter,
  setSerialNoFilter,
  employeeOptions,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <SidebarItem template="filters" fluid={false}>
      <div className="p-grid">
        <div className="p-col p-field">
          <label htmlFor="filter_date_from">{t('Date From')}</label>
          <Calendar
            value={dateFromFilter ?? undefined}
            monthNavigator
            yearNavigator
            yearRange={`2009:${dayjs().format('YYYY')}`}
            dateFormat="dd/mm/yy"
            maxDate={new Date()}
            inputId="filter_date_from"
            onChange={(e) => {
              setDateFromFilter(e.value as Date | null);
            }}
          />
        </div>

        <div className="p-col p-field">
          <label htmlFor="filter_date_to">{t('Date To')}</label>
          <Calendar
            value={dateToFilter ?? undefined}
            monthNavigator
            yearNavigator
            yearRange={`2009:${dayjs().format('YYYY')}`}
            dateFormat="dd/mm/yy"
            maxDate={new Date()}
            inputId="filter_date_to"
            onChange={(e) => {
              setDateToFilter(e.value as Date | null);
            }}
          />
        </div>
      </div>

      <div className="p-field">
        <label htmlFor="filter_employee">{t('Employee')}</label>

        <AutoCompleteInput
          id="filter_employee"
          filterValue={employeeFilter}
          value={employeeFilterObj}
          options={employeeOptions}
          onFilterChange={setEmployeeFilter}
          onSelectionChange={setEmployeeFilterObj}
        />
      </div>

      <div className="p-field">
        <label htmlFor="filter_serial_no">{t('Serial No')}</label>

        <InputText
          value={serialNoFilter}
          onChange={(e: ChangeEvent<HTMLInputElement>): void => {
            setSerialNoFilter(e.target.value);
          }}
        />
      </div>
    </SidebarItem>
  );
}

export default CompensationDocFilters;
