import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { isDevEnv } from '../utils/helpers/misc';

function usePreventWindowClose(): void {
  const { t } = useTranslation();

  useEffect(() => {
    // Better developer experience
    if (!isDevEnv()) {
      window.onbeforeunload = () =>
        t(
          'You might have unsaved changes. Are you sure you want to close this window?'
        );
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [t]);
}

export default usePreventWindowClose;
