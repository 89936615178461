import {
  clientLookupCollectionGuard,
  clientResourceGuard,
} from './_api/clients';
import {
  employeeCollectionGuard,
  employeeResourceGuard,
} from './_api/employees';
import {
  addParcelDebtResourceGuard,
  deleteParcelDebtResourceGuard,
  editParcelDebtResourceGuard,
  parcelDebtResourceGuard,
  parcelDebtsollectionGuard,
} from './_api/parcelDebts';

export const employeeFilters = [employeeCollectionGuard, employeeResourceGuard];
export const clientFilter = [clientLookupCollectionGuard, clientResourceGuard];

export const readParcel = parcelDebtResourceGuard;

export const createParcel = [
  addParcelDebtResourceGuard,
  ...clientFilter,
  ...employeeFilters,
];

export const editParcel = [
  editParcelDebtResourceGuard,
  ...clientFilter,
  ...employeeFilters,
];

export const deleteParcel = deleteParcelDebtResourceGuard;

export const routeGuard = parcelDebtsollectionGuard;
