import { LatLngExpression, Layer, Map, PM } from 'leaflet';
import _ from 'lodash';
import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import {
  MapContainer,
  Polygon,
  Rectangle,
  TileLayer,
  Tooltip,
} from 'react-leaflet';

import { defaultTileLayerProvider } from '../../../../../../utils/constants/map';
import {
  getMapCoordinates,
  getMapMaxBounds,
  latLngLiteralToLatLngBounds,
} from '../../../../../../utils/helpers/map';
import { Region, fitMapBounds } from '../../../Regions.functions';

const defaultTools: PM.ToolbarOptions = {
  drawMarker: false,
  drawPolyline: false,
  drawCircle: false,
  drawCircleMarker: false,
  cutPolygon: false,
};

type Props = {
  isEditDialog: boolean;
  selectedRegion: Region | undefined;
  allRegions: Region[];
  map: Map | undefined;
  setMap: Dispatch<SetStateAction<Map | undefined>>;
  onCreate: () => void;
  onRemove: () => void;
};

function DialogMap({
  isEditDialog,
  selectedRegion,
  allRegions,
  map,
  setMap,
  onCreate,
  onRemove,
}: Props): JSX.Element {
  const regionsToShow = useMemo<Region[]>(
    () =>
      allRegions.filter(
        (r) =>
          Array.isArray(r.geometry) &&
          r.geometry.length > 2 &&
          (isEditDialog ? r.region_id !== selectedRegion?.region_id : true)
      ),
    [allRegions, isEditDialog, selectedRegion]
  );

  const editDialogRegion = useMemo<JSX.Element | null>(() => {
    if (
      !isEditDialog ||
      !Array.isArray(selectedRegion?.geometry) ||
      !(selectedRegion?.geometry && selectedRegion?.geometry.length > 2)
    ) {
      return null;
    }

    // Polygons that do not follow this pattern will be rendered as rectangles instead
    const isRectangle =
      selectedRegion.geometry.length === 5 &&
      _.isEqual(selectedRegion.geometry[0], selectedRegion.geometry[4]) &&
      selectedRegion.geometry[0].lng === selectedRegion.geometry[1].lng &&
      selectedRegion.geometry[2].lng === selectedRegion.geometry[3].lng &&
      selectedRegion.geometry[0].lat === selectedRegion.geometry[3].lat &&
      selectedRegion.geometry[1].lat === selectedRegion.geometry[2].lat;

    return isRectangle ? (
      <Rectangle
        key={selectedRegion?.region_id}
        bounds={latLngLiteralToLatLngBounds(selectedRegion?.geometry)}
      >
        <Tooltip>{selectedRegion?.name}</Tooltip>
      </Rectangle>
    ) : (
      <Polygon
        key={selectedRegion?.region_id}
        positions={selectedRegion?.geometry}
      >
        <Tooltip>{selectedRegion?.name}</Tooltip>
      </Polygon>
    );
  }, [isEditDialog, selectedRegion]);

  useEffect(() => {
    if (!map) {
      return;
    }

    function toggleDrawing() {
      if (!map) {
        return;
      }

      const geomanLayers = map.pm.getGeomanLayers() as Layer[];

      if (geomanLayers.length > 0) {
        map.pm.addControls({
          ...defaultTools,
          drawControls: false,
          editControls: true,
        });
      } else {
        map.pm.addControls({
          ...defaultTools,
          drawControls: true,
          drawPolygon: true,
          editControls: false,
        });
      }
    }

    map.pm.addControls({
      ...defaultTools,
      editControls: false,
    });

    map.on('pm:create', () => {
      toggleDrawing();
      onCreate();
    });

    map.on('pm:remove', () => {
      toggleDrawing();
      onRemove();
    });
  }, [map, onCreate, onRemove]);

  // Enable edit controls if this is an edit dialog
  useEffect(() => {
    if (
      !map ||
      !isEditDialog ||
      !Array.isArray(selectedRegion?.geometry) ||
      !(selectedRegion?.geometry && selectedRegion?.geometry.length > 2)
    ) {
      return;
    }

    map.pm.addControls({
      ...defaultTools,
      drawControls: false,
      editControls: true,
    });
  }, [isEditDialog, map, selectedRegion]);

  return (
    <MapContainer
      zoom={7.5}
      center={getMapCoordinates()}
      maxBounds={getMapMaxBounds()}
      whenCreated={(map) => {
        setMap(map);

        if (isEditDialog && selectedRegion) {
          fitMapBounds(map, selectedRegion);
        }
      }}
      className="map data-cy-map"
    >
      <TileLayer url={defaultTileLayerProvider} />

      {regionsToShow?.map((r) => (
        <Polygon
          key={r.region_id}
          pathOptions={{ color: 'rgba(0, 0, 0, 0.45)' }}
          positions={r.geometry as LatLngExpression[]}
          pmIgnore
          snapIgnore={false}
        >
          <Tooltip>{r.name}</Tooltip>
        </Polygon>
      ))}

      {editDialogRegion}
    </MapContainer>
  );
}

export default DialogMap;
