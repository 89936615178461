import './DeleteRegionDialog.scss';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useAxiosHook from '../../../../../../hooks/useAxiosHook';
import usePrevious from '../../../../../../hooks/usePrevious';
import { Region } from '../../../Regions.functions';

type Props = {
  isShown: boolean;
  selectedRegion: Region | undefined;
  onHide: () => void;
  onDeleteSuccess: (name: string) => void;
  onError: (name: string) => void;
};

function DeleteRegionDialog({
  isShown,
  selectedRegion,
  onHide,
  onDeleteSuccess,
  onError,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const { data, error, isLoading, reload } = useAxiosHook();
  const prevData = usePrevious(data);
  const prevError = usePrevious(error);

  useEffect(() => {
    if (!data || data === prevData) {
      return;
    }

    onDeleteSuccess(selectedRegion?.name || '');
  }, [data, onDeleteSuccess, prevData, selectedRegion]);

  useEffect(() => {
    if (!error || error === prevError) {
      return;
    }

    onError(selectedRegion?.name || '');
  }, [error, onError, prevError, selectedRegion]);

  function handleDeleteSubmision() {
    reload({
      url: `/regions/${selectedRegion?.region_id}`,
      method: 'DELETE',
    });
  }

  const dialogHeader = t('Delete region');
  const dialogFooter = (
    <>
      <Button
        type="button"
        label={t('Cancel')}
        onClick={onHide}
        className="p-button-text"
      />

      <Button
        type="button"
        label={isLoading ? t('Deleting...') : t('Delete')}
        disabled={isLoading}
        onClick={handleDeleteSubmision}
        className="p-button-danger"
        data-cy="submit-btn"
      />
    </>
  );

  return (
    <Dialog
      visible={isShown}
      onHide={onHide}
      header={dialogHeader}
      footer={dialogFooter}
      dismissableMask
      resizable={false}
      className="delete-region-dialog"
    >
      {t('You are about to delete the {{selectedRegionName}} region.', {
        selectedRegionName: selectedRegion?.name || t('an unnamed'),
      })}
      <br />
      {t('This action cannot be undone.')}
      <br />
      {t('Are you sure?')}
    </Dialog>
  );
}

export default DeleteRegionDialog;
