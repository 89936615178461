import './WhatsNewDialog.scss';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';

type Props = {
  isShown: boolean;
  onHide: () => void;
};

function WhatsNewDialog({ isShown, onHide }: Props): JSX.Element {
  const { t } = useTranslation();

  const dialogFooter = (
    <Button type="button" label={t('Close')} onClick={() => onHide()} />
  );

  return (
    <Dialog
      header={t("What's new?")}
      footer={dialogFooter}
      visible={isShown}
      onHide={onHide}
      resizable={false}
      className="whats-new-dialog"
    >
      <p>
        {t(
          'These are the latest improvements and features that have been introduced in the application'
        )}
        :
      </p>

      <b>v2.0.0</b>
      <ul>
        <li>Feature one</li>
        <li>Feature two</li>
      </ul>
    </Dialog>
  );
}

export default WhatsNewDialog;
