import { TabPanel, TabView } from 'primereact/tabview';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ExpressionTab from './Tabs/ExpressionTab';
import GeneralTab from './Tabs/GeneralTab';

function DialogContainer(): JSX.Element {
  const { t } = useTranslation();

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  return (
    <TabView
      activeIndex={activeTabIndex}
      onTabChange={(e) => setActiveTabIndex(e.index)}
    >
      <TabPanel header={t('General')}>
        <GeneralTab />
      </TabPanel>

      <TabPanel header={t('Expression')}>
        <ExpressionTab />
      </TabPanel>
    </TabView>
  );
}

export default DialogContainer;
