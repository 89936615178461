import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import {
  faBan,
  faBullseye,
  faCalendarWeek,
  faCheckCircle,
  faCircle,
  faCircleNotch,
  faDolly,
  faDotCircle,
  faExchangeAlt,
  faExclamationCircle,
  faExclamationTriangle,
  faHands,
  faHourglassHalf,
  faRandom,
  faSearch,
  faSearchPlus,
  faUndoAlt,
} from '@fortawesome/free-solid-svg-icons';
import { TFunction } from 'react-i18next';
import { $enum } from 'ts-enum-util';

import errorSound from '../assets/sounds/error.wav';
import successSound from '../assets/sounds/success.wav';
import warningSound from '../assets/sounds/warning.wav';
import {
  FailedOrderReason,
  OrderStatus,
  WarehouseAssignmentAndReceptionStatusSeverity,
  WarehouseCourierOrderAssignmentStatus,
  WarehouseOrderReceptionStatus,
  WarehouseTransferCourierOrderAssignmentStatus,
  WarehouseTransferCourierOrderReceptionStatus,
} from '../enums/orders';
import { TableProps } from '../features/DataTable/Table/Table';
import { LabelValue } from '../types/options';

type OrderStatusConfig = {
  label: string;
  icon: IconDefinition;
  rowClassName?: string;
};

type WarehouseOrderReceptionStatusConfig = {
  message: string;
  severity: WarehouseAssignmentAndReceptionStatusSeverity;
};

type WarehouseCourierOrderAssignmentStatusConfig = {
  message: string;
  severity: WarehouseAssignmentAndReceptionStatusSeverity;
};

type WarehouseTransferCourierOrderReceptionStatusConfig = {
  message: string;
  severity: WarehouseAssignmentAndReceptionStatusSeverity;
};

type WarehouseTransferCourierOrderAssignmentStatusConfig = {
  message: string;
  severity: WarehouseAssignmentAndReceptionStatusSeverity;
};

export function getOrderStatusConfig(
  t: TFunction
): Record<OrderStatus, OrderStatusConfig> {
  return {
    [OrderStatus.CreatedAddress]: {
      label: t('Created address.ORDER'),
      icon: faCircleNotch,
    },
    [OrderStatus.CreatedAddressFromCounter]: {
      label: t('Created address from counter.ORDER'),
      icon: faCircleNotch,
    },
    [OrderStatus.Created]: {
      label: t('Created.ORDER'),
      icon: faCircle,
    },
    [OrderStatus.CreatedAddressFromMobile]: {
      label: t('Created address from mobile.ORDER'),
      icon: faCircle,
    },
    [OrderStatus.CreatedGroupOrder]: {
      label: t('Created group order.ORDER'),
      icon: faCircle,
    },
    [OrderStatus.AssignedToAReceptionCourier]: {
      label: t('Assigned to a reception courier.ORDER'),
      icon: faDotCircle,
    },
    [OrderStatus.UnsuccessfulPickup]: {
      label: t('Unsuccessful pickup.ORDER'),
      icon: faExclamationTriangle,
      rowClassName: 'bg-yellow-100',
    },
    [OrderStatus.Cancelled]: {
      label: t('Cancelled.ORDER'),
      icon: faBan,
      rowClassName: 'bg-red-50',
    },
    [OrderStatus.PickedUp]: {
      label: t('Picked up.ORDER'),
      icon: faDolly,
      rowClassName: 'bg-teal-50',
    },
    [OrderStatus.Sorting]: {
      label: t('In warehouse - sorting.ORDER'),
      icon: faRandom,
      rowClassName: 'bg-green-100',
    },
    [OrderStatus.AwaitingTransfer]: {
      label: t('Wrong warehouse - awaiting transfer.ORDER'),
      icon: faExchangeAlt,
      rowClassName: 'bg-orange-50',
    },
    [OrderStatus.AwaitingControl]: {
      label: t('Awaiting control.ORDER'),
      icon: faSearch,
      rowClassName: 'bg-orange-100',
    },
    [OrderStatus.Lost]: {
      label: t('Lost.ORDER'),
      icon: faSearchPlus,
      rowClassName: 'bg-orange-200',
    },
    [OrderStatus.AwaitingPickupFromClient]: {
      label: t('Awaiting pickup from client.ORDER'),
      icon: faCalendarWeek,
      rowClassName: 'bg-orange-400',
    },
    [OrderStatus.Abandoned]: {
      label: t('Abandoned.ORDER'),
      icon: faHourglassHalf,
    },
    [OrderStatus.HandedOverToARelocationCourier]: {
      label: t('Handed over to a relocation courier.ORDER'),
      icon: faHands,
    },
    [OrderStatus.HandedOverToADeliveryCourier]: {
      label: t('Handed over to a delivery courier.ORDER'),
      icon: faHands,
      rowClassName: 'bg-green-200',
    },
    [OrderStatus.UnsuccessfulDeliveryAttempt]: {
      label: t('Unsuccessful delivery attempt.ORDER'),
      icon: faExclamationCircle,
      rowClassName: 'bg-orange-300',
    },
    [OrderStatus.MarkedForReturn]: {
      label: t('Marked for return.ORDER'),
      icon: faUndoAlt,
      rowClassName: 'bg-purple-500',
    },
    [OrderStatus.Returned]: {
      label: t('Returned.ORDER'),
      icon: faUndoAlt,
      rowClassName: 'bg-purple-500',
    },
    [OrderStatus.MarkedAsDelivered]: {
      label: t('Marked as delivered.ORDER'),
      icon: faBullseye,
      rowClassName: 'bg-green-800',
    },
    [OrderStatus.Delivered]: {
      label: t('Delivered.ORDER'),
      icon: faCheckCircle,
      rowClassName: 'bg-green-800',
    },
  };
}

export function getOrderStatusOptions(t: TFunction): LabelValue<OrderStatus>[] {
  return Object.entries(getOrderStatusConfig(t)).map(([key, value]) => ({
    label: `(${key}) ${value.label}`,
    value: Number(key),
  }));
}

export function getFailedOrderReasonLabel(
  t: TFunction
): Record<FailedOrderReason, string> {
  return {
    [FailedOrderReason.Unknown]: t('Unknown'),
    [FailedOrderReason.DoesntWantToReceive]: t("Doesn't want to receive"),
    [FailedOrderReason.WrongAddress]: t('Wrong address'),
    [FailedOrderReason.WrongPhone]: t('Wrong phone'),
    [FailedOrderReason.ICouldntGetInTime]: t("I couldn't get in time"),
    [FailedOrderReason.DoesntAnswer]: t("Doesn't answer"),
    [FailedOrderReason.NobodyAnswers]: t('Nobody answers'),
    [FailedOrderReason.Later]: t('Later'),
    [FailedOrderReason.ReturnedOrderWithNewAddressDocument]: t(
      'Returned order with new address document'
    ),
    [FailedOrderReason.DeliveryOnSpecificDate]: t('Delivery on specific date'),
    [FailedOrderReason.MissingReconciliationDocument]: t(
      'Missing reconciliation document'
    ),
    [FailedOrderReason.MovedOut]: t('Moved out'),
    [FailedOrderReason.PassedAway]: t('Passed away'),
    [FailedOrderReason.LegalEntityDoesntExist]: t(
      "The legal entity doesn't exist"
    ),
    [FailedOrderReason.WrongRegion]: t('Wrong region'),
    [FailedOrderReason.Misassigned]: t('Misassigned'),
  };
}

export function getFailedOrderReasonOptions(
  t: TFunction
): LabelValue<FailedOrderReason>[] {
  const labels = getFailedOrderReasonLabel(t);

  return $enum(FailedOrderReason)
    .getValues()
    .map((k) => ({
      label: labels[k],
      value: k,
    }));
}

export function getWarehouseOrderReceptionStatusConfig(
  t: TFunction
): Record<WarehouseOrderReceptionStatus, WarehouseOrderReceptionStatusConfig> {
  return {
    [WarehouseOrderReceptionStatus.SuccessfullyReceived]: {
      message: t('Successfully received in warehouse!'),
      severity: WarehouseAssignmentAndReceptionStatusSeverity.Info,
    },
    [WarehouseOrderReceptionStatus.AlreadyReceivedPreviouslyAssignedForReception]:
      {
        message: t(
          'Already received order in warehouse that was assigned for reception!'
        ),
        severity: WarehouseAssignmentAndReceptionStatusSeverity.Warning,
      },
    [WarehouseOrderReceptionStatus.AlreadyReceivedFromAnotherCourier]: {
      message: t('Already received order in warehouse from another courier!'),
      severity: WarehouseAssignmentAndReceptionStatusSeverity.Error,
    },
    [WarehouseOrderReceptionStatus.AlreadyReceivedInAnotherWarehouse]: {
      message: t('Already received order in another warehouse!'),
      severity: WarehouseAssignmentAndReceptionStatusSeverity.Error,
    },
    [WarehouseOrderReceptionStatus.AlreadyReceivedOnAnotherDate]: {
      message: t('Already received order in warehouse on another date!'),
      severity: WarehouseAssignmentAndReceptionStatusSeverity.Error,
    },
    [WarehouseOrderReceptionStatus.AlreadyReceivedAssignedForDelivery]: {
      message: t(
        'Already received order in warehouse that was assigned for delivery!'
      ),
      severity: WarehouseAssignmentAndReceptionStatusSeverity.Error,
    },
    [WarehouseOrderReceptionStatus.AlreadyReceivedAssignedForDeliveryToAnotherCourier]:
      {
        message: t(
          'Already received order in warehouse that was assigned for delivery to another courier!'
        ),
        severity: WarehouseAssignmentAndReceptionStatusSeverity.Error,
      },
    [WarehouseOrderReceptionStatus.AlreadyReceivedFromDeliveryInAnotherWarehouse]:
      {
        message: t(
          'Already received order from delivery in another warehouse!'
        ),
        severity: WarehouseAssignmentAndReceptionStatusSeverity.Error,
      },
    [WarehouseOrderReceptionStatus.EmptyOrder]: {
      message: t('Received empty order in warehouse!'),
      severity: WarehouseAssignmentAndReceptionStatusSeverity.Warning,
    },
    [WarehouseOrderReceptionStatus.NotAssignedToCourierForReception]: {
      message: t(
        'Receipt of an order in warehouse that was not assigned to courier for reception!'
      ),
      severity: WarehouseAssignmentAndReceptionStatusSeverity.Warning,
    },
    [WarehouseOrderReceptionStatus.AssignedForReceptionToAnotherCourier]: {
      message: t(
        'Receipt of an order in warehouse that was assigned for reception to another courier!'
      ),
      severity: WarehouseAssignmentAndReceptionStatusSeverity.Warning,
    },
    [WarehouseOrderReceptionStatus.NotAssignedForDelivery]: {
      message: t(
        'Receipt of an order in warehouse that was not assigned for delivery!'
      ),
      severity: WarehouseAssignmentAndReceptionStatusSeverity.Error,
    },
    [WarehouseOrderReceptionStatus.AssignedForDeliveryToAnotherCourier]: {
      message: t(
        'Receipt of an order in warehouse that was assigned for delivery to another courier!'
      ),
      severity: WarehouseAssignmentAndReceptionStatusSeverity.Error,
    },
    [WarehouseOrderReceptionStatus.ShippedOrMarkedAsShipped]: {
      message: t('Order shipped or marked as shipped!'),
      severity: WarehouseAssignmentAndReceptionStatusSeverity.Error,
    },
    [WarehouseOrderReceptionStatus.WrongWarehouse]: {
      message: t('Wrong warehouse!'),
      severity: WarehouseAssignmentAndReceptionStatusSeverity.Error,
    },
    [WarehouseOrderReceptionStatus.WrongRegion]: {
      message: t('Wrong region!'),
      severity: WarehouseAssignmentAndReceptionStatusSeverity.Warning,
    },
    [WarehouseOrderReceptionStatus.WrongProduct]: {
      message: t('Wrong product!'),
      severity: WarehouseAssignmentAndReceptionStatusSeverity.Warning,
    },
    [WarehouseOrderReceptionStatus.WorkingDayNotOpen]: {
      message: t('Working day not open!'),
      severity: WarehouseAssignmentAndReceptionStatusSeverity.Error,
    },
    [WarehouseOrderReceptionStatus.CourierMissingWorkOrder]: {
      message: t('Missing work order for courier!'),
      severity: WarehouseAssignmentAndReceptionStatusSeverity.Error,
    },
    [WarehouseOrderReceptionStatus.AssignedForTransferAndCanOnlyBeReceivedFromATransferWarhouse]:
      {
        message: t(
          'Order assigned for transfer and can be received only from a transfer warehouse!'
        ),
        severity: WarehouseAssignmentAndReceptionStatusSeverity.Error,
      },
    [WarehouseOrderReceptionStatus.ClosedOrder]: {
      message: t('Closed order!'),
      severity: WarehouseAssignmentAndReceptionStatusSeverity.Error,
    },
  };
}

export function getWarehouseCourierOrderAssignmentStatusConfig(
  t: TFunction
): Record<
  WarehouseCourierOrderAssignmentStatus,
  WarehouseCourierOrderAssignmentStatusConfig
> {
  return {
    [WarehouseCourierOrderAssignmentStatus.SuccessfullyAssigned]: {
      message: t('Successfully assigned to courier'),
      severity: WarehouseAssignmentAndReceptionStatusSeverity.Info,
    },
    [WarehouseCourierOrderAssignmentStatus.AlreadyAssigned]: {
      message: t('Already assigned shipment!'),
      severity: WarehouseAssignmentAndReceptionStatusSeverity.Warning,
    },
    [WarehouseCourierOrderAssignmentStatus.WrongDate]: {
      message: t('Wrong date!'),
      severity: WarehouseAssignmentAndReceptionStatusSeverity.Warning,
    },
    [WarehouseCourierOrderAssignmentStatus.WrongCourier]: {
      message: t('Wrong courier!'),
      severity: WarehouseAssignmentAndReceptionStatusSeverity.Warning,
    },
    [WarehouseCourierOrderAssignmentStatus.WrongRegion]: {
      message: t('Wrong region!'),
      severity: WarehouseAssignmentAndReceptionStatusSeverity.Warning,
    },
    [WarehouseCourierOrderAssignmentStatus.ShipmentNotInWarehouse]: {
      message: t('Shipment not in warehouse!'),
      severity: WarehouseAssignmentAndReceptionStatusSeverity.Error,
    },
    [WarehouseCourierOrderAssignmentStatus.MissingDeliveryRegion]: {
      message: t('Missing delivery region!'),
      severity: WarehouseAssignmentAndReceptionStatusSeverity.Error,
    },
    [WarehouseCourierOrderAssignmentStatus.ShipmentStatusDoesNotAllowAssignment]:
      {
        message: t(
          'The shipment status does not allow for courier assignments!'
        ),
        severity: WarehouseAssignmentAndReceptionStatusSeverity.Error,
      },
    [WarehouseCourierOrderAssignmentStatus.MissingShipmentData]: {
      message: t('Missing shipment data!'),
      severity: WarehouseAssignmentAndReceptionStatusSeverity.Error,
    },
    [WarehouseCourierOrderAssignmentStatus.ForbiddenRegion]: {
      message: t('Forbidden region!'),
      severity: WarehouseAssignmentAndReceptionStatusSeverity.Warning,
    },
    [WarehouseCourierOrderAssignmentStatus.AlreadyAssignedToAnotherCourier]: {
      message: t('Already assigned to another courier!'),
      severity: WarehouseAssignmentAndReceptionStatusSeverity.Warning,
    },
    [WarehouseCourierOrderAssignmentStatus.ProductTypeNotAllowedForDestinationRegion]:
      {
        message: t('The product type is not allowed for a destination region!'),
        severity: WarehouseAssignmentAndReceptionStatusSeverity.Warning,
      },
    [WarehouseCourierOrderAssignmentStatus.InadequateProductTypeForAppVersion]:
      {
        message: t('Inadequate product type for application version!'),
        severity: WarehouseAssignmentAndReceptionStatusSeverity.Error,
      },
    [WarehouseCourierOrderAssignmentStatus.UnregisteredShipment]: {
      message: t('Unregistered shipment!'),
      severity: WarehouseAssignmentAndReceptionStatusSeverity.Error,
    },
    [WarehouseCourierOrderAssignmentStatus.WrongDateError]: {
      message: t('Wrong date!'),
      severity: WarehouseAssignmentAndReceptionStatusSeverity.Error,
    },
    [WarehouseCourierOrderAssignmentStatus.ClosedShipment]: {
      message: t('Closed shipment!'),
      severity: WarehouseAssignmentAndReceptionStatusSeverity.Error,
    },
  };
}

export function getWarehouseTransferCourierOrderReceptionStatusConfig(
  t: TFunction
): Record<
  WarehouseTransferCourierOrderReceptionStatus,
  WarehouseTransferCourierOrderReceptionStatusConfig
> {
  return {
    [WarehouseTransferCourierOrderReceptionStatus.SuccessfullyReceivedFromTransferInWarehouse]:
      {
        message: t(
          'Successfully received shipment from transfer into warehouse.'
        ),
        severity: WarehouseAssignmentAndReceptionStatusSeverity.Info,
      },
    [WarehouseTransferCourierOrderReceptionStatus.UnknownSerialNumber]: {
      message: t('Unknown serial number!'),
      severity: WarehouseAssignmentAndReceptionStatusSeverity.Error,
    },
    [WarehouseTransferCourierOrderReceptionStatus.MissingAddressData]: {
      message: t('Missing address data!'),
      severity: WarehouseAssignmentAndReceptionStatusSeverity.Error,
    },
    [WarehouseTransferCourierOrderReceptionStatus.MissingDeliveryRegion]: {
      message: t('Missing delivery region!'),
      severity: WarehouseAssignmentAndReceptionStatusSeverity.Error,
    },
    [WarehouseTransferCourierOrderReceptionStatus.UnknownDeliveryRegion]: {
      message: t('Unknown delivery region!'),
      severity: WarehouseAssignmentAndReceptionStatusSeverity.Error,
    },
    [WarehouseTransferCourierOrderReceptionStatus.WrongDate]: {
      message: t('Wrong date!'),
      severity: WarehouseAssignmentAndReceptionStatusSeverity.Warning,
    },
    [WarehouseTransferCourierOrderReceptionStatus.ShipmentNotInTransferList]: {
      message: t('The shipment is not in transfer list!'),
      severity: WarehouseAssignmentAndReceptionStatusSeverity.Error,
    },
    [WarehouseTransferCourierOrderReceptionStatus.ShipmentAlreadyReceivedInWarehouse]:
      {
        message: t('The shipment is already received in warehouse!'),
        severity: WarehouseAssignmentAndReceptionStatusSeverity.Error,
      },
    [WarehouseTransferCourierOrderReceptionStatus.WrongStatus]: {
      message: t('Wrong status!'),
      severity: WarehouseAssignmentAndReceptionStatusSeverity.Error,
    },
    [WarehouseTransferCourierOrderReceptionStatus.ClosedShipment]: {
      message: t('Closed shipment!'),
      severity: WarehouseAssignmentAndReceptionStatusSeverity.Error,
    },
    [WarehouseTransferCourierOrderReceptionStatus.AlternateHub]: {
      message: t('Assigning to alternate warehouse!'),
      severity: WarehouseAssignmentAndReceptionStatusSeverity.Warning,
    },
  };
}

export function getWarehouseTransferCourierOrderAssignmentStatusConfig(
  t: TFunction
): Record<
  WarehouseTransferCourierOrderAssignmentStatus,
  WarehouseTransferCourierOrderAssignmentStatusConfig
> {
  return {
    [WarehouseTransferCourierOrderAssignmentStatus.SuccessfullyAssignedForTransferToWarehouse]:
      {
        message: t('Successfully assigned for transfer to warehouse.'),
        severity: WarehouseAssignmentAndReceptionStatusSeverity.Info,
      },
    [WarehouseTransferCourierOrderAssignmentStatus.UnknownSerialNumber]: {
      message: t('Unknown serial number!'),
      severity: WarehouseAssignmentAndReceptionStatusSeverity.Error,
    },
    [WarehouseTransferCourierOrderAssignmentStatus.MissingAddressData]: {
      message: t('Missing address data!'),
      severity: WarehouseAssignmentAndReceptionStatusSeverity.Error,
    },
    [WarehouseTransferCourierOrderAssignmentStatus.UnknownDeliveryRegion]: {
      message: t('Unknown delivery region!'),
      severity: WarehouseAssignmentAndReceptionStatusSeverity.Error,
    },
    [WarehouseTransferCourierOrderAssignmentStatus.ShipmentAlreadyAssignedForTransfer]:
      {
        message: t('Shipment already assigned for transfer!'),
        severity: WarehouseAssignmentAndReceptionStatusSeverity.Error,
      },
    [WarehouseTransferCourierOrderAssignmentStatus.WrongStatus]: {
      message: t('Wrong status!'),
      severity: WarehouseAssignmentAndReceptionStatusSeverity.Error,
    },
    [WarehouseTransferCourierOrderAssignmentStatus.WrongDate]: {
      message: t('Wrong date!'),
      severity: WarehouseAssignmentAndReceptionStatusSeverity.Error,
    },
    [WarehouseTransferCourierOrderAssignmentStatus.ShipmentNotInWarehouse]: {
      message: t('The shipment is not in warehouse!'),
      severity: WarehouseAssignmentAndReceptionStatusSeverity.Error,
    },
    [WarehouseTransferCourierOrderAssignmentStatus.ShipmentHasUnreconciledCash]:
      {
        message: t('The shipment has unreconciled cash!'),
        severity: WarehouseAssignmentAndReceptionStatusSeverity.Error,
      },
    [WarehouseTransferCourierOrderAssignmentStatus.ShipmentForDeliveryInCurrentRegion]:
      {
        message: t('The shipment is for delivery in the current region!'),
        severity: WarehouseAssignmentAndReceptionStatusSeverity.Error,
      },
    [WarehouseTransferCourierOrderAssignmentStatus.WrongSourceWarehouse]: {
      message: t('Wrong source warehouse!'),
      severity: WarehouseAssignmentAndReceptionStatusSeverity.Error,
    },
    [WarehouseTransferCourierOrderAssignmentStatus.WrongDestinationWarehouse]: {
      message: t('Wrong destination warehouse!'),
      severity: WarehouseAssignmentAndReceptionStatusSeverity.Error,
    },
    [WarehouseTransferCourierOrderAssignmentStatus.MissingOrIncorrectMappingRegionToWarehouse]:
      {
        message: t('Missing or incorrect mapping region-warehouse!'),
        severity: WarehouseAssignmentAndReceptionStatusSeverity.Error,
      },
    [WarehouseTransferCourierOrderAssignmentStatus.ClosedShipment]: {
      message: t('Closed shipment!'),
      severity: WarehouseAssignmentAndReceptionStatusSeverity.Error,
    },
    [WarehouseTransferCourierOrderAssignmentStatus.AlternateHub]: {
      message: t('Reception to alternate hub!'),
      severity: WarehouseAssignmentAndReceptionStatusSeverity.Warning,
    },
  };
}

export function getOrderRowClassName<T>(
  t: TFunction,
  statusTransformer: (row: T) => OrderStatus
): TableProps['rowClassName'] {
  const orderStatusConfig = getOrderStatusConfig(t);

  return (row: T) => {
    const status = statusTransformer(row);

    return { [orderStatusConfig[status].rowClassName ?? '']: true };
  };
}

export function getAssignmentAndReceptionStatusSeveritySound(
  severity: WarehouseAssignmentAndReceptionStatusSeverity,
  shouldPlaySuccess: boolean = false
): HTMLAudioElement | undefined {
  // Success sound is played only on delivery since we don't have region sounds there.
  return severity === WarehouseAssignmentAndReceptionStatusSeverity.Warning
    ? new Audio(warningSound)
    : severity === WarehouseAssignmentAndReceptionStatusSeverity.Error
    ? new Audio(errorSound)
    : shouldPlaySuccess
    ? new Audio(successSound)
    : undefined;
}
