import './CreateEditDialog.scss';

import { Form, Formik } from 'formik';
import _ from 'lodash';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { TabPanel, TabView } from 'primereact/tabview';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import useAxios from '../../../../hooks/useAxios';
import { invalidTabHeaderIcon } from '../../../../utils/globals';
import { formik_hasDialogTabErrors } from '../../../../utils/helpers';
import { tryApiContextValidation } from '../../../../utils/helpers/formik';
import {
  basicConfigTabFields,
  getAllowedErrors,
  getFieldMappingTabFields,
  getInitialValues,
  toApiData,
} from './CreateEditDialog.functions';
import CreateEditDialogBasicConfigTab from './CreateEditDialogBasicConfigTab';
import CreateEditDialogFieldMappingTab from './CreateEditDialogFieldMappingTab';

function CreateEditDialog({
  data = {},
  isShown,
  onHide,
  onCreateEditSubmit,
  onListFieldSubmit,
  onFieldDefinitionSubmit,
}) {
  const { t } = useTranslation();

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [deletedFields, setDeletedFields] = useState([]);

  const formRef = useRef(null);

  const isEditDialog = Object.keys(data).length > 0;

  const {
    reload: formSubmissionRequest,
    isLoading: isFormSubmissionRequestLoading,
  } = useAxios();

  const { data: entities, isLoading: isEntitiesLoading } =
    useAxios('/entities');

  const { data: entityProperties, isLoading: isEntityPropertiesLoading } =
    useAxios('/entityproperties', undefined, { method: 'GET' });

  const initialValues = useMemo(
    () => getInitialValues(data, entities, entityProperties),
    [data, entities, entityProperties]
  );

  useEffect(() => {
    setActiveTabIndex(0);
    setDeletedFields([]);
  }, [isShown]);

  function handleFieldDelete(field) {
    setDeletedFields((prevState) => [...prevState, field]);
  }

  function handleFieldsRequest(values, listId) {
    // New and existing fields
    for (let field of values.fields) {
      const isNewField = !!String(field.id).match('_new_');

      let payload = {
        ...field,
        import_list_type_id: listId,
        print: '1',
      };

      payload.property_id =
        payload.property_id === '' ? null : payload.property_id;

      delete payload.id;
      delete payload.entity_id;
      delete payload.entityname;
      delete payload.propertyname;

      formSubmissionRequest({
        url: isNewField
          ? '/importlisttypefields'
          : `/importlisttypefields/${field.id}`,
        payload: payload,
        method: isNewField ? 'POST' : 'PUT',
        successCallback: () => {
          onListFieldSubmit(field.name, isNewField, true);
        },
        errorCallback: () => {
          onListFieldSubmit(field.name, isNewField, false);
        },
      });
    }

    // Deleted fields
    const filteredDeletedFields = deletedFields.filter(
      (f) => !String(f.id).match('_new_')
    );

    for (let field of filteredDeletedFields) {
      formSubmissionRequest({
        url: `/importlisttypefields/${field.id}`,
        method: 'DELETE',
        successCallback: () => {
          handleFieldDelete(field.name, true);
        },
        errorCallback: () => {
          handleFieldDelete(field.name, false);
        },
      });
    }
  }

  function handleFieldDefinitions(values, listId) {
    const fields = values.fields.map((f) => ({
      name: f.name,
      property_id: f.property_id,
    }));

    formSubmissionRequest({
      url: '/importlistparceldata/definition',
      method: 'POST',
      payload: {
        import_list_type_id: listId,
        fields,
      },
      successCallback: () => {
        onFieldDefinitionSubmit(values.name, true);
      },
      errorCallback: () => {
        onFieldDefinitionSubmit(values.name, false);
      },
    });
  }

  function handleCreateRequest(values) {
    formSubmissionRequest({
      url: '/importlisttypes',
      payload: toApiData(values, isEditDialog),
      method: 'POST',
      successCallback: (data) => {
        onCreateEditSubmit(values.name, true, true);
        handleFieldsRequest(values, data.id);
        handleFieldDefinitions(values, data.id);
      },
      errorCallback: (err) => {
        const allowedErrors = getAllowedErrors(t);
        const errorDescription = err?.response?.data.error_description;

        if (
          errorDescription &&
          tryApiContextValidation(errorDescription, formRef, allowedErrors)
        ) {
          return;
        }
        onCreateEditSubmit(values.name, true, false);
      },
    });
  }

  function handleEditRequest(values) {
    formSubmissionRequest({
      url: `/importlisttypes/${data.id}`,
      // Yes, id is required both as a query and a body parameter...
      payload: toApiData({ ...values, id: data.id }, isEditDialog),
      method: 'PUT',
      successCallback: () => {
        onCreateEditSubmit(values.name, false, true);
      },
      errorCallback: (err) => {
        const allowedErrors = getAllowedErrors(t);
        const errorDescription = err?.response?.data.error_description;

        if (
          errorDescription &&
          tryApiContextValidation(errorDescription, formRef, allowedErrors)
        ) {
          return;
        }
        onCreateEditSubmit(values.name, false, false);
      },
    });

    if (!_.isEqual(data.fields, values.fields)) {
      handleFieldsRequest(values, data.id);
      handleFieldDefinitions(values, data.id);
    }
  }

  function handleFormSubmision(values) {
    if (isEditDialog) {
      handleEditRequest(values);
    } else {
      handleCreateRequest(values);
    }
  }

  const dialogHeader = isEditDialog
    ? t('Editing - {{dataName}}', { dataName: data.name })
    : t('Add new import list type');
  const dialogFooter = (
    <>
      <Button
        type="button"
        label={t('Cancel')}
        className="p-button-text"
        onClick={() => onHide()}
      />

      <Button
        type="button"
        label={
          isFormSubmissionRequestLoading
            ? t('Saving...')
            : isEditDialog
            ? t('Save changes')
            : t('Add list type')
        }
        disabled={isFormSubmissionRequestLoading}
        onClick={() => {
          if (formRef.current) {
            formRef.current.handleSubmit();
          }
        }}
        data-cy="submit"
      />
    </>
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().required(),
        klient_isSender: Yup.string().required(),
        plaka_postarina_isprakac: Yup.string().required(),
        plaka_otkup_isprakac: Yup.string().required(),
        plaka_povraten_dokument_isprakac: Yup.string().required(),
        plaka_osiguruvanje_isprakac: Yup.string().required(),
        isprakac_cenovnik_id: Yup.string().required(),
        primac_cenovnik_id: Yup.string().required(),
        naracatel_cenovnik_id: Yup.string().required(),
        fields: Yup.array()
          .of(
            Yup.object().shape({
              name: Yup.string().required(),
              entityname: Yup.string().nullable(),
              propertyname: Yup.string().nullable(),
              isRequired: Yup.string(),
              default_value: Yup.string(),
            })
          )
          .required()
          .test({
            test: function (value) {
              let mandatory = [];
              let atLeastOneOf = [];
              let someOf = [];
              if (this.parent.klient_isSender != 1) {
                mandatory = [...mandatory, 'klient_od_ime', 'adresa_od'];

                atLeastOneOf = [...atLeastOneOf, ['mobilen_od', 'telefon_od']];

                someOf = [
                  ...someOf,
                  'mesto_od_id',
                  'mesto_od_ime',
                  'opstina_od_id',
                  'opstina_od_ime',
                  'grad_od_id',
                  'grad_od_ime',
                ];
              }

              if (this.parent.klient_isSender != 2) {
                mandatory = [...mandatory, 'klient_do_ime', 'adresa_do'];

                atLeastOneOf = [...atLeastOneOf, ['mobilen_do', 'telefon_do']];

                someOf = [
                  ...someOf,
                  'mesto_do_id',
                  'mesto_do_ime',
                  'opstina_do_id',
                  'opstina_do_ime',
                  'grad_do_id',
                  'grad_do_ime',
                ];
              }

              const propertiesArr = _.map(value, 'propertyname');
              const mandatoryFieldsDiff = _.difference(
                mandatory,
                propertiesArr
              );

              for (const atLeast of atLeastOneOf) {
                const diff = _.difference(atLeast, propertiesArr);

                if (diff.length > 1) {
                  return this.createError({
                    message: t(
                      'At least one of the following properties must be present in the list: {{presentProperties}}',
                      { presentProperties: diff.join(', '), nsSeparator: false }
                    ),
                  });
                }
              }

              if (mandatoryFieldsDiff.length > 0) {
                return this.createError({
                  message: t(
                    'The list is missing the following properties: {{missingProperties}}',
                    {
                      missingProperties: mandatoryFieldsDiff.join(', '),
                      nsSeparator: false,
                    }
                  ),
                });
              }
              const someOfFieldsDiff = _.difference(someOf, propertiesArr);

              if (someOfFieldsDiff.length === someOf.length) {
                return this.createError({
                  message: t(
                    'At least one of the following properties must be present in the list: {{presentProperties}}',
                    {
                      presentProperties: someOfFieldsDiff.join(', '),
                      nsSeparator: false,
                    }
                  ),
                });
              }

              return true;
            },
          }),
      }),
    [t]
  );

  return (
    <Dialog
      visible={isShown}
      onHide={onHide}
      header={dialogHeader}
      footer={dialogFooter}
      maximizable
      maximized
      resizable={false}
      className="list-types-create-edit-dialog"
    >
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleFormSubmision}
      >
        {({ values, errors, touched }) => {
          const fieldMappingTabFields = getFieldMappingTabFields(
            values.klient_isSender
          );

          const basicConfigTabIcon = formik_hasDialogTabErrors(
            errors,
            basicConfigTabFields
          )
            ? invalidTabHeaderIcon
            : {};

          const fieldMappingTabIcon = formik_hasDialogTabErrors(
            errors,
            fieldMappingTabFields
          )
            ? invalidTabHeaderIcon
            : {};

          return (
            <Form>
              <TabView
                activeIndex={activeTabIndex}
                onTabChange={(e) => setActiveTabIndex(e.index)}
                className={
                  Object.keys(errors).length > 0 &&
                  Object.keys(touched).length > 0
                    ? 'invalid'
                    : ''
                }
              >
                <TabPanel
                  header={t('Basic configuration')}
                  {...basicConfigTabIcon}
                >
                  <CreateEditDialogBasicConfigTab
                    entities={entities}
                    entityProperties={entityProperties}
                  />
                </TabPanel>

                <TabPanel
                  header={t('Field mapping')}
                  headerClassName="data-cy-field-mapping-tab"
                  {...fieldMappingTabIcon}
                >
                  <CreateEditDialogFieldMappingTab
                    onFieldDelete={handleFieldDelete}
                    entities={entities}
                    isEntitiesLoading={isEntitiesLoading}
                    entityProperties={entityProperties}
                    isEntityPropertiesLoading={isEntityPropertiesLoading}
                  />
                </TabPanel>
              </TabView>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
}

export default CreateEditDialog;
