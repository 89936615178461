import './MultiSelectAutoCompleteInput.scss';

import classNames from 'classnames';
import _ from 'lodash';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { ListBox } from 'primereact/listbox';
import {
  ChangeEvent,
  Dispatch,
  FocusEvent,
  KeyboardEvent,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  MutliSelectAutoCompleteOption,
  emptyItemTemplate,
} from './MultiSelectAutoCompleteInput.functions';

type Props = {
  filterValue: string;
  setFilterValue: Dispatch<SetStateAction<string>>;
  selection: MutliSelectAutoCompleteOption['value'][];
  setSelection: Dispatch<
    SetStateAction<MutliSelectAutoCompleteOption['value'][]>
  >;
  allSelectedOptions: MutliSelectAutoCompleteOption[];
  setAllSelectedOptions: Dispatch<
    SetStateAction<MutliSelectAutoCompleteOption[]>
  >;
  options: MutliSelectAutoCompleteOption[];
  isLoading?: boolean;
  maxWidth?: number;
  maxHeight?: number;
  id?: string;
  name?: string;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
};

function MultiSelectAutoCompleteInput({
  filterValue,
  setFilterValue,
  selection,
  setSelection,
  allSelectedOptions,
  setAllSelectedOptions,
  options,
  isLoading,
  maxWidth,
  maxHeight = 300,
  id,
  name,
  placeholder,
  label,
  disabled,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const [isOptionListShown, setIsOptionListShown] = useState<boolean>(false);

  const filterRef = useRef<any>(null);
  const listBoxRef = useRef<any>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const actualOptions = useMemo(() => {
    const optionsWithoutSelection = _.differenceWith(
      options,
      allSelectedOptions.length
        ? allSelectedOptions
        : options.filter((o) => selection.includes(o.value)),
      _.isEqual
    );

    return [
      {
        label: t('Selection') + ` (${selection.length})`,
        items: allSelectedOptions.filter((so) =>
          selection.find((s) => _.isEqual(s, so.value))
        ),
      },
      {
        label: t('Suggestions') + ` (${optionsWithoutSelection.length})`,
        items: optionsWithoutSelection,
      },
    ];
  }, [allSelectedOptions, options, selection, t]);

  useEffect(() => {
    if (isOptionListShown) {
      return;
    }

    setFilterValue('');
  }, [isOptionListShown, setFilterValue]);

  function handleContainerBlur(e: FocusEvent) {
    if (
      !containerRef.current?.contains(e.relatedTarget as Node) &&
      !(listBoxRef.current?.element as HTMLDivElement | undefined)?.contains(
        e.relatedTarget as Node
      )
    ) {
      setIsOptionListShown(false);
    }
  }

  const listBoxClassnames = classNames('listbox', 'p-shadow-3', {
    shown: isOptionListShown,
  });

  const listBoxWidth = filterRef.current?.element?.offsetWidth;

  const listBoxStyle = {
    ...(typeof maxWidth === 'number' ? { maxWidth } : {}),
    ...(listBoxWidth ? { width: listBoxWidth } : {}),
    ...(maxHeight && isOptionListShown ? { maxHeight } : {}),
  };

  return (
    <div className="multi-select-auto-complete-input">
      <div
        ref={containerRef}
        onBlur={handleContainerBlur}
        className="p-fluid"
        style={maxWidth ? { maxWidth } : {}}
      >
        {label && (
          <label htmlFor={id} className="p-d-block">
            {label}
          </label>
        )}
        <div className="p-inputgroup">
          <InputText
            id={id}
            name={id ?? name}
            ref={filterRef}
            value={filterValue}
            disabled={disabled || (!isOptionListShown && isLoading)}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setFilterValue(e.target.value ?? '');
            }}
            onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
              if (e.key === 'ArrowDown' && options.length > 0) {
                e.preventDefault();

                listBoxRef.current?.element
                  ?.querySelector(`[aria-label="${options[0].label}"]`)
                  ?.focus();
              }
            }}
            placeholder={
              placeholder ??
              t('{{number}} selected', { number: selection.length })
            }
            onFocus={() => setIsOptionListShown(true)}
            autoComplete="off"
          />

          <Button
            type="button"
            icon={isLoading ? 'pi pi-spin pi-spinner' : 'pi pi-times'}
            disabled={isLoading || !selection.length}
            className="p-button-secondary p-button-outlined"
            onClick={() => {
              setSelection([]);
              setAllSelectedOptions([]);
              setIsOptionListShown(false);
            }}
          />
        </div>

        <ListBox
          ref={listBoxRef}
          value={selection}
          multiple
          options={actualOptions}
          itemTemplate={options.length > 0 ? undefined : emptyItemTemplate}
          onChange={(e) => {
            setSelection(e.value);

            const selectedOptions = options.filter((o) =>
              (e.value as unknown[]).includes(o.value)
            );

            setAllSelectedOptions((prevAsos) =>
              _.uniqWith(
                [
                  ...prevAsos.filter((pAso) =>
                    (e.value as unknown[]).includes(pAso.value)
                  ),
                  ...selectedOptions,
                ],
                _.isEqual
              )
            );
          }}
          className={listBoxClassnames}
          style={listBoxStyle}
          optionGroupLabel="label"
          optionGroupChildren="items"
        />
      </div>
    </div>
  );
}

export default MultiSelectAutoCompleteInput;
