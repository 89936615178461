import { ColumnProps } from 'primereact/column';
import { TFunction } from 'react-i18next';

import { getOrderStatusConfig } from '../../../../configs/orders';
import { getProductTypeLabels } from '../../../../configs/product';
import { TableColumnHeadersMap } from '../../../../types/util';
import { emptyCell, noColumnStyle } from '../../../../utils/constants/tables';
import { dateCell } from '../../../../utils/helpers/dataTable';
import { currencyFormat } from '../../../../utils/helpers/formatting';
import { Column, Row } from './HandoverToCustomer.types';

export const tableStorageKey = 'warehouse_hand_over_to_customer_dataTable';

export function getColumnHeadersMap(
  t: TFunction
): TableColumnHeadersMap<Column> {
  return {
    no: t('No.'),
    serial_number: t('Serial Number'),
    created_at: t('Creation Date'),
    order_reception_date: t('Reception Date'),
    order_delivery_date: t('Delivery Date'),
    product_id: t('Product'),
    status_id: t('Status'),
    sender_name: t('Sender'),
    sender_address: t('Sender Address'),
    recipient_name: t('Recipient'),
    recipient_address: t('Recipient Address'),
    weight: t('Weight'),
    redemption: t('Redemption'),
    price: t('Price'),
    value: t('Value'),
    comment: t('Comment'),
  };
}

export function additionalColumnProperties(t: TFunction) {
  const orderStatusConfig = getOrderStatusConfig(t);
  const productTypeLabels = getProductTypeLabels(t);

  return (column: Column): ColumnProps => {
    switch (column) {
      case 'no':
        return noColumnStyle;

      case 'serial_number':
        return {
          style: {
            width: 200,
          },
          bodyStyle: {
            width: 200,
          },
        };

      case 'created_at':
      case 'order_reception_date':
      case 'order_delivery_date':
        return {
          body: (row: Row) => dateCell(row[column] ?? ''),
          style: {
            width: 180,
          },
          bodyStyle: {
            width: 180,
          },
        };

      case 'product_id':
        return {
          body: (row: Row) => productTypeLabels[row[column]],
          style: { width: 150 },
          bodyStyle: { width: 150 },
        };

      case 'status_id':
        return {
          body: (row: Row) => orderStatusConfig[row[column]].label,
          style: { width: 300 },
          bodyStyle: { width: 300 },
        };

      case 'sender_name':
      case 'recipient_name':
        return {
          style: {
            width: 250,
          },
          bodyStyle: {
            width: 250,
          },
        };

      case 'sender_address':
      case 'recipient_address':
        return {
          style: {
            width: 200,
          },
          bodyStyle: {
            width: 200,
          },
        };

      case 'weight':
        return {
          style: {
            width: 120,
          },
          bodyStyle: {
            width: 120,
          },
        };

      case 'price':
      case 'value':
        return {
          body: (row: Row) => {
            return typeof row[column] === 'string'
              ? currencyFormat(row[column] as string, { showCurrency: true })
              : emptyCell;
          },
          style: {
            width: 120,
          },
          bodyStyle: {
            width: 120,
          },
        };

      case 'redemption':
        return {
          body: (row: Row) => {
            return typeof row[column] === 'string'
              ? currencyFormat(row[column] as string, { showCurrency: true })
              : emptyCell;
          },
          style: {
            width: 150,
          },
          bodyStyle: {
            width: 150,
          },
        };

      case 'comment':
        return {
          body: (row: Row) => (!!row[column]?.trim() ? row[column] : emptyCell),
          style: {
            width: 180,
          },
          bodyStyle: {
            width: 180,
          },
        };
    }
  };
}
