import { TFunction } from 'react-i18next';
import * as Yup from 'yup';

import { CreateCustomerReceptionResourceRequestPayload } from '../../../../../../../types/api/customerReceptions';
import { FormFields } from './NewReceptionDialog.types';

export const defaultValues: FormFields = {
  client_id: '',
  name: '',
};

export function toApiData(
  values: FormFields
): CreateCustomerReceptionResourceRequestPayload {
  return values.client_id
    ? { client_id: values.client_id }
    : { name: values.name };
}

export function getValidationSchema(
  t: TFunction
): Yup.SchemaOf<Partial<FormFields>> {
  return Yup.object().shape({
    client_id: Yup.string().test(
      'client_id',
      t('In the case of non-existing clients, a name must be present.'),
      (value, context) => (value ? true : !!(context.parent as FormFields).name)
    ),
    name: Yup.string(),
  });
}
