import './ConfigDialog.scss';

import { Field, useFormikContext } from 'formik';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';

import { ConstantFields } from './CreateEditDialog.functions';

type Props = {
  visible: boolean;
  onHide: () => void;
  onStart: () => void;
};

export function GroupOrderConfig({ visible, onHide, onStart }: Props) {
  const { t } = useTranslation();

  const { values } = useFormikContext<ConstantFields>();

  const configDialogFooter = (
    <div>
      <Button
        label="Start"
        onClick={onStart}
        type="button"
        data-cy="start-btn"
      />
    </div>
  );

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header={t('Group Order Config')}
      footer={configDialogFooter}
      maximizable
      className="group-order-config-dialog"
    >
      <div className="p-pb-5">{t('Select constant field groups')}:</div>

      <div className="p-field">
        <div className="p-field-radiobutton">
          <Field
            as={Checkbox}
            inputId="sender"
            name="isSenderConstant"
            checked={values?.isSenderConstant}
          />
          <label htmlFor="sender" data-cy="sender-cbx">
            {t('Sender')}
          </label>
        </div>

        <div className="p-field-radiobutton">
          <Field
            as={Checkbox}
            inputId="recipient"
            name="isRecipientConstant"
            checked={values?.isRecipientConstant}
          />
          <label htmlFor="recipient">{t('Recipient')}</label>
        </div>

        <div className="p-field-checkbox">
          <Field
            as={Checkbox}
            inputId="Orderer"
            name="isOrdererConstant"
            checked={values?.isOrdererConstant}
          />
          <label htmlFor="Orderer">{t('Orderer')}</label>
        </div>

        <div className="p-field-checkbox">
          <Field
            as={Checkbox}
            checked={values?.isAttributesConstant}
            inputId="Attributes"
            name="isAttributesConstant"
          />
          <label htmlFor="Attributes" data-cy="attributes-cbx">
            {t('Attributes')}
          </label>
        </div>
      </div>
    </Dialog>
  );
}
