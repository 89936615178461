// DBTable "invoice_status"
export enum InvoiceStatus {
  Created = 1,
  Approved,
  Cancelled,
  Paid,
}

export enum InvoiceEmailStatus {
  NotSent = 1,
  Sent,
  ErrorWhileSending,
}
