import { Tree } from 'primereact/tree';
import { Dispatch, SetStateAction } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TreeComponentSelectionNode } from '../../../../../../types/primereact';
import {
  ClientTreeNode,
  GroupFilter,
  generateTreeNodes,
  getNodeTemplate,
} from '../../CreateWarrants.functions';

type Props = {
  clientTree: ClientTreeNode[];
  selectedNodes: TreeComponentSelectionNode;
  setSelectedNodes: Dispatch<SetStateAction<TreeComponentSelectionNode>>;
  isLoading: boolean;
  groupFilter: GroupFilter;
};

function ClientTree({
  clientTree,
  selectedNodes,
  setSelectedNodes,
  isLoading,
  groupFilter,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const treeNodes = useMemo(() => {
    const parentFilter = groupFilter;
    const childFilter =
      groupFilter === 'datum_isporaka' ? 'ime' : 'datum_isporaka';

    return generateTreeNodes(t, clientTree, parentFilter, childFilter);
  }, [t, clientTree, groupFilter]);

  return (
    <div className="client-tree">
      <Tree
        value={treeNodes as any}
        filter
        filterPlaceholder={t('Filter by name or date')}
        selectionMode="checkbox"
        selectionKeys={selectedNodes as any}
        onSelectionChange={(e) => {
          setSelectedNodes(e.value as any);
        }}
        nodeTemplate={getNodeTemplate(t)}
        loading={isLoading}
      />
    </div>
  );
}

export default ClientTree;
