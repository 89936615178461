export enum FileTypes {
  PDF = 'application/pdf',
  CSV = 'text/csv',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  JSON = 'text/json',
  XML = 'text/xml',
  TXT = 'text/plain',
  PNG = 'image/png',
  JPEG = 'image/jpeg',
}
