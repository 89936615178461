// Data Table
export function getDataTableProps(t) {
  return {
    emptyMessage: t('No results found matching the selected filters.'),
    emptyMessageError: t(
      'An error occured. Please make sure you are connected to the internet and try again'
    ),
    paginatorTemplate:
      'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown',
    pageReportTemplate: t(
      'Showing {first} to {last} of {totalRecords} entries'
    ),
    emptyPageReportTemplate: t('Showing 0 of 0 entries'),
  };
}

// Map
export function getMapProps(t) {
  return {
    mapBoundsPoints: [
      [42.416318, 19.839309],
      [40.553642, 23.706547],
    ],
    minZoom: 8,
    maxZoom: 19,
    defaultZoom: 9,
    macedoniaCoordinates: [41.6086, 21.7453],
    defaultTileLayerProvider:
      'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    tileLayerProviderOptions: [
      {
        label: t('Colored Detailed'),
        value: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      },
      {
        label: t('Colored Simple'),
        value:
          'https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png',
      },
      {
        label: t('Satelite'),
        value:
          'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
      },
      {
        label: t('Black and White'),
        value:
          'https://stamen-tiles-{s}.a.ssl.fastly.net/toner/{z}/{x}/{y}{r}.png',
      },
    ],
  };
}

export const invalidTabHeaderIcon = {
  leftIcon: 'fas fa-exclamation-circle',
};

export const bankPrefixes = {
  100: 'Народна банка на Република Македонија',
  200: 'Стопанска Банка А. Д. Скопје',
  210: 'НЛБ Тутунска Банка А. Д. Скопје',
  240: 'Универзална Инвестициона Банка А. Д. Скопје',
  250: 'Шпаркасе Банка Македонија А.Д. Скопје',
  270: 'Халк Банка А. Д. Скопје',
  280: 'Алфа Банка А.Д. Скопје',
  290: 'ТТК Банка А. Д. Скопје',
  300: 'Комерцијална Банка А. Д. Скопје',
  320: 'Централна  Кооперативна Банка А. Д. Скопје',
  330: 'Капитал  Банка А. Д. Скопје',
  350: 'Македонска Банка за поддршка на развојот А. Д. Скопје',
  370: 'Еуростандард Банка А. Д. Скопје',
  380: 'ПроКредит Банка А. Д. Скопје',
  500: 'Стопанска Банка А.Д. Битола',
  530: 'Охридска Банка А. Д. Скопје',
};
