import { DataTable, DataTableSortOrderType } from 'primereact/datatable';
import { Dispatch, RefObject, SetStateAction, useRef, useState } from 'react';

import { TableProps } from '../features/DataTable/Table/Table';
import { getUserPrefixedSessionStorageItem } from '../utils/helpers/storage';

export type UseTableStateReturn<T> = {
  tableRef: RefObject<DataTable>;
  sortField: TableProps['sortField'];
  setSortField: TableProps['setSortField'];
  defaultSortField: string;
  sortOrder: TableProps['sortOrder'];
  setSortOrder: TableProps['setSortOrder'];
  selection: T | null;
  setSelection: Dispatch<SetStateAction<T | null>>;
  selectionMultiple: T[];
  setSelectionMultiple: Dispatch<SetStateAction<T[]>>;
  contextMenuSelection: T | undefined;
  setContextMenuSelection: Dispatch<SetStateAction<T | undefined>>;
  page: TableProps['rows'];
  setPage: NonNullable<TableProps['setPage']>;
  limit: number;
  setLimit: NonNullable<TableProps['setLimit']>;
};

type TableStorage<T> = {
  columnWidths: string;
  first: number;
  rows: number;
  selection: T | T[];
  sortField: keyof T;
  sortOrder: number;
} | null;

function getInitialLimit<T>(storageKey: string) {
  return (
    getUserPrefixedSessionStorageItem<TableStorage<T>>(storageKey)?.rows ?? 30
  );
}

function useTableState<T extends object>(
  storageKey: string,
  defaultSortField: string = 'id'
): UseTableStateReturn<T> {
  const tableRef = useRef<DataTable>(null);
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(getInitialLimit(storageKey));
  const [sortOrder, setSortOrder] = useState<DataTableSortOrderType>(1);
  const [sortField, setSortField] = useState<string>(defaultSortField);
  const [selection, setSelection] = useState<T | null>(null);
  const [selectionMultiple, setSelectionMultiple] = useState<T[]>([]);
  const [contextMenuSelection, setContextMenuSelection] =
    useState<T | undefined>(undefined);

  return {
    tableRef,
    sortField,
    setSortField,
    defaultSortField,
    sortOrder: sortOrder ?? null,
    setSortOrder,
    selection,
    setSelection,
    selectionMultiple,
    setSelectionMultiple,
    contextMenuSelection,
    setContextMenuSelection,
    page,
    setPage,
    limit,
    setLimit,
  };
}

export default useTableState;
