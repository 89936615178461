import _ from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ReduxState } from '../types/redux';
import { EndpointGuard } from '../utils/helpers/auth';

export function useEndpointGuard(endpoints: EndpointGuard): boolean {
  const user = useSelector<ReduxState, ReduxState['user']>(
    (state) => state.user
  );

  return useMemo(() => {
    if (typeof endpoints === 'string' || Array.isArray(endpoints)) {
      return (
        _.difference(
          typeof endpoints === 'string' ? [endpoints] : endpoints,
          user.permitted_routes!
        ).length === 0
      );
    }

    const oneOfStrings = endpoints.oneOf.filter((e) => typeof e === 'string');
    const oneOfStringArrs = endpoints.oneOf.filter((e) => Array.isArray(e));

    const areRequiredEndpointsPermitted = endpoints.required
      ? _.difference(endpoints.required, user.permitted_routes!).length === 0
      : true;

    const areOneOfStringsPermitted =
      _.difference(oneOfStrings, user.permitted_routes!).length <
      oneOfStrings.length;

    const areOneOfStringArrsPermitted = oneOfStringArrs.some(
      (arr) => _.difference(arr, user.permitted_routes!).length === 0
    );

    return (
      areRequiredEndpointsPermitted &&
      (areOneOfStringsPermitted || areOneOfStringArrsPermitted)
    );
  }, [endpoints, user.permitted_routes]);
}
