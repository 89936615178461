import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';

import useAxiosHook from '../../../../../../../hooks/useAxiosHook';
import useMediaQuery from '../../../../../../../hooks/useMediaQuery';
import {
  InvoiceCollection,
  InvoiceShipmentCollection,
} from '../../../../../../../types/api/invoices';
import { Unpacked } from '../../../../../../../types/util';
import Shipments from '../Content/Shipments/Shipments';

type Props = {
  visible: boolean;
  title: string;
  invoiceID: Unpacked<InvoiceCollection>['id'] | undefined;
  onHide: () => void;
};

function ViewShipments({
  visible,
  title,
  invoiceID,
  onHide,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const request = useAxiosHook<InvoiceShipmentCollection>(
    `/invoices/${invoiceID}/shipments`,
    { skipWhen: !invoiceID || !visible }
  );

  const isOnMobile = useMediaQuery('(max-width: 768px)');

  const footer = <Button label={t('Close')} onClick={() => onHide()} />;

  return (
    <Dialog
      header={title}
      footer={footer}
      visible={visible}
      onHide={onHide}
      maximizable
      maximized={isOnMobile}
      style={{ width: '90%' }}
    >
      <Shipments request={request} />
    </Dialog>
  );
}

export default ViewShipments;
