import './Filters.scss';

import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  filters: JSX.Element;
  resetAllFilters: any;
  headerFiltersCount?: any;
} & HTMLAttributes<HTMLElement>;

function Filters({
  filters,
  resetAllFilters,
  headerFiltersCount,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="filters-reconciliation">
      <div className="filters-reconciliation-header">
        <div className="filters-reconciliation-header-left">
          <i className="fa fa-filter p-text-secondary p-overlay-badge">
            {typeof headerFiltersCount !== 'undefined' &&
              headerFiltersCount > 0 && (
                <Badge
                  style={{ scale: '.9' }}
                  severity="danger"
                  value={headerFiltersCount}
                />
              )}
          </i>
          <div className="filters-reconciliation-header-title">
            {t('Filters')}
          </div>
        </div>
        <div>
          <Button
            type="button"
            icon="fas fa-sync-alt"
            tooltip={t('Reset filters')}
            tooltipOptions={{ position: 'top' }}
            onClick={resetAllFilters}
            className="p-button-text p-button-plain p-overlay-badge"
          />
        </div>
      </div>
      <div className="filters-reconciliation-filters">{filters}</div>
    </div>
  );
}

export default Filters;
