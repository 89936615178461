import { AxiosRequestConfig } from 'axios';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';

import { InvoiceCandidatesCustomerCollection } from '../../../../../../types/api/invoices';
import { Unpacked } from '../../../../../../types/util';
import { httpDateFormat } from '../../../../../../utils/helpers/formatting';

type Props = {
  dateFrom: Date;
  dateTo: Date;
  selection: Unpacked<InvoiceCandidatesCustomerCollection>[];
  visible: boolean;
  onHide: () => void;
  createRequest: (config?: AxiosRequestConfig) => void;
};

function CreateDialog({
  dateFrom,
  dateTo,
  selection,
  visible,
  onHide,
  createRequest,
}: Props): JSX.Element {
  const { t } = useTranslation();

  function handleCreation() {
    for (const invoice of selection) {
      createRequest({
        url: 'invoices',
        method: 'POST',
        data: {
          date_from: httpDateFormat(dateFrom),
          date_to: httpDateFormat(dateTo),
          client_id: invoice.client_id,
        },
      });
    }

    onHide();
  }

  const dialogFooter = (
    <>
      <Button
        label={t('Cancel')}
        className="p-button-secondary p-button-text"
        onClick={() => onHide()}
      />
      <Button label={t('Create')} onClick={handleCreation} />
    </>
  );

  return (
    <Dialog
      header={t('Create {{invoicesCount}} Invoices', {
        invoicesCount: selection.length,
      })}
      footer={dialogFooter}
      onHide={onHide}
      visible={visible}
      style={{ width: 360 }}
    >
      <p>
        {t(
          'Are you sure you want to create invoices for the following clients?'
        )}
      </p>
      <br />
      <div className="clients" style={{ maxHeight: 100, overflow: 'auto' }}>
        {selection.map((invoice, index) => (
          <div key={index}>
            {index + 1}. <span>{invoice?.client_name}</span>
          </div>
        ))}
      </div>
    </Dialog>
  );
}

export default CreateDialog;
