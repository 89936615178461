import * as Sentry from '@sentry/react';
import {
  CSSProperties,
  RefObject,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactDOM from 'react-dom';

import useIsMounted from '../../../hooks/useIsMounted';
import { XOR } from '../../../types/util';
import styles from './ContextMenu.module.scss';
import {
  ContextMenuItem,
  ContextMenuModel,
  ContextMenuSection,
} from './ContextMenu.types';
import EmptyItem from './Item/EmptyItem';
import Item from './Item/Item';
import Section from './Section/Section';

type Props = {
  model: ContextMenuModel;
  target: RefObject<HTMLElement>;
  emptyState?: boolean;
  onHide?: () => void;
};

function ContextMenu({
  model,
  target,
  emptyState = false,
  onHide,
}: Props): JSX.Element {
  const [isVisible, setIsVisible] = useState(false);
  const [style, setStyle] = useState<CSSProperties | undefined>();

  const [topPosition, setTopPosition] = useState(0);
  const [leftPosition, setLeftPosition] = useState(0);

  const isMounted = useIsMounted();

  const ref = useRef<HTMLMenuElement>(null);

  useEffect(() => {
    const targetEl = target.current;

    function handleContextMenu(e: MouseEvent) {
      if (
        isMounted.current &&
        targetEl &&
        e.target instanceof Element &&
        targetEl.contains(e.target) &&
        (emptyState ? true : !!model.length)
      ) {
        e.preventDefault();

        setTopPosition(e.pageY);
        setLeftPosition(e.pageX);

        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }

    targetEl?.addEventListener('contextmenu', handleContextMenu);

    return () => {
      targetEl?.removeEventListener('contextmenu', handleContextMenu);
    };
  }, [emptyState, isMounted, model.length, target]);

  useEffect(() => {
    const targetEl = target.current;

    function handleClick(e: MouseEvent) {
      if (
        isMounted.current &&
        e.target instanceof Element &&
        !ref.current?.contains(e.target)
      ) {
        setIsVisible(false);
      }
    }

    function handleDocumentContextMenu(e: MouseEvent) {
      if (
        isMounted.current &&
        e.target instanceof Element &&
        !targetEl?.contains(e.target)
      ) {
        setIsVisible(false);
      }
    }

    function handleEscBtn(e: KeyboardEvent) {
      if (e.key !== 'Escape') {
        return;
      }

      setIsVisible(false);
    }

    document.addEventListener('click', handleClick);
    document.addEventListener('contextmenu', handleDocumentContextMenu);
    document.addEventListener('keyup', handleEscBtn);

    return () => {
      document.removeEventListener('click', handleClick);
      document.removeEventListener('contextmenu', handleDocumentContextMenu);
      document.removeEventListener('keyup', handleEscBtn);
    };
  }, [isMounted, target]);

  useEffect(() => {
    if (!isVisible) {
      onHide?.();
    }
  }, [isVisible, onHide]);

  useLayoutEffect(() => {
    const frameId = requestAnimationFrame(() => {
      const clientRect = ref.current?.getBoundingClientRect();
      const rectWidth = clientRect?.width ?? 0;
      const rectHeight = clientRect?.height ?? 0;

      const isOverflowingX = leftPosition + rectWidth > window.innerWidth;
      const isOverflowingY = topPosition + rectHeight > window.innerHeight;

      setStyle({
        visibility: isVisible ? undefined : 'hidden',
        top: isOverflowingY ? topPosition - rectHeight : topPosition,
        left: isOverflowingX ? leftPosition - rectWidth : leftPosition,
      });
    });

    return () => cancelAnimationFrame(frameId);
  }, [isVisible, leftPosition, topPosition]);

  function hideContextMenu() {
    setIsVisible(false);
  }

  const items = useMemo(
    () =>
      model.map((item: XOR<ContextMenuSection, ContextMenuItem>, idx: number) =>
        !!item.title ? (
          <Section
            key={idx}
            {...(item as ContextMenuSection)}
            hideContextMenu={hideContextMenu}
          />
        ) : (
          <Item
            key={idx}
            {...(item as ContextMenuItem)}
            hideContextMenu={hideContextMenu}
          />
        )
      ),
    [model]
  );

  const menuPortalContainer =
    document.getElementById('menu-portal-container') || document.body;

  if (menuPortalContainer === document.body) {
    Sentry.captureMessage(
      'ContextMenu portal container element not found!',
      Sentry.Severity.Error
    );
  }

  return ReactDOM.createPortal(
    <menu ref={ref} style={style} className={styles.contextMenu}>
      {items.length === 0 && emptyState ? <EmptyItem /> : items}
    </menu>,
    menuPortalContainer
  );
}

export default ContextMenu;
