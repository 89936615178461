import { Field, Form, Formik, FormikProps } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { useContext, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import ToastContext from '../../../../../context/ToastContext';
import useAxiosHook from '../../../../../hooks/useAxiosHook';
import usePrevious from '../../../../../hooks/usePrevious';
import {
  errorToast,
  successToast,
} from '../../../../../utils/helpers/primereact';
import DialogSpinner from '../../../../Dialogs/DialogSpinner/DialogSpinner';
import FieldWithErrors from '../../../../Forms/FieldWithErrors/FieldWithErrors';
import { OAuthClient } from '../../OAuthClients.functions';
import {
  FormValues,
  getValidationSchema,
  initialValues,
} from './EmailCredentialsDialog.functions';

type Props = {
  data: OAuthClient | undefined;
  isLoading: boolean;
  visible: boolean;
  onHide: () => void;
};

function EmailCredentialsDialog({
  data,
  isLoading,
  visible,
  onHide,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const { toastRef } = useContext(ToastContext);

  const formRef = useRef<FormikProps<FormValues>>(null);

  const validationSchema = useMemo(() => getValidationSchema(t), [t]);

  const {
    data: sendEmailData,
    error: sendEmailError,
    isLoading: isSendEmailLoading,
    reload: sendEmailReload,
  } = useAxiosHook<string>();

  const prevSendEmailData = usePrevious(sendEmailData);
  const prevSendEmailError = usePrevious(sendEmailError);

  useEffect(() => {
    if (!sendEmailData || sendEmailData === prevSendEmailData) {
      return;
    }

    if (toastRef?.current) {
      successToast(
        toastRef,
        t('Success'),
        t(
          "Client {{clientName}}'s credentials were successfully emailed to {{email}}.",
          {
            clientName: data?.client_name,
            email: formRef.current?.values.email,
          }
        )
      );
    }

    onHide();
  }, [
    data?.client_name,
    onHide,
    prevSendEmailData,
    sendEmailData,
    t,
    toastRef,
  ]);

  useEffect(() => {
    if (!sendEmailError || sendEmailError === prevSendEmailError) {
      return;
    }

    errorToast(
      toastRef,
      t('Error'),
      t(
        "An error occured while trying to email {{clientName}}'s credentials.",
        {
          clientName: data?.client_name,
        }
      )
    );

    onHide();
  }, [
    data?.client_name,
    onHide,
    prevSendEmailError,
    sendEmailError,
    t,
    toastRef,
  ]);

  function handleFormSubmision() {
    sendEmailReload({
      url: `/oauthclients/${data?.id}/email`,
      method: 'POST',
      data: {
        email: formRef.current?.values.email,
      },
    });
  }

  const dialogFooter = !isLoading && (
    <>
      <Button
        type="button"
        label={t('Close')}
        onClick={() => onHide()}
        className="p-button-secondary p-button-text"
      />

      <Button
        type="button"
        label={isSendEmailLoading ? t('Sending...') : t('Send e-mail')}
        disabled={isSendEmailLoading}
        onClick={() => {
          formRef.current?.handleSubmit();
        }}
        data-cy="submit"
      />
    </>
  );

  return (
    <Dialog
      header={
        isLoading
          ? t('Loading...')
          : t("Email {{clientName}}'s credentials", {
              clientName: data?.client_name,
            })
      }
      footer={dialogFooter}
      visible={visible}
      resizable={false}
      onHide={onHide}
      style={{ minWidth: 360, maxWidth: 400 }}
    >
      {isLoading ? (
        <DialogSpinner />
      ) : (
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmision}
        >
          <Form>
            <p className="p-mt-0 p-text-secondary">
              {t(
                "Enter an e-mail address below to which {{clientName}}'s credentials will be sent.",
                { clientName: data?.client_name }
              )}
            </p>

            <div className="p-fluid">
              <FieldWithErrors
                name="email"
                label={t("Recipient's e-mail address")}
              >
                <Field
                  as={InputText}
                  id="email"
                  name="email"
                  data-cy="recipient-email"
                />
              </FieldWithErrors>
            </div>
          </Form>
        </Formik>
      )}
    </Dialog>
  );
}

export default EmailCredentialsDialog;
