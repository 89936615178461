import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import ToastContext from '../../../../../../context/ToastContext';
import useAxiosHook from '../../../../../../hooks/useAxiosHook';
import usePrevious from '../../../../../../hooks/usePrevious';
import {
  errorToast,
  successToast,
} from '../../../../../../utils/helpers/primereact';
import DialogSpinner from '../../../../../Dialogs/DialogSpinner/DialogSpinner';
import { SingleWarrantList } from '../../Warrants.functions';

type Props = {
  isShown: boolean;
  warrant: SingleWarrantList | undefined;
  isWarrantLoading: boolean;
  onHide: () => void;
};

function SendCODConfirmDialog({
  isShown,
  warrant,
  isWarrantLoading,
  onHide,
}: Props) {
  const { t } = useTranslation();

  const { toastRef } = useContext(ToastContext);

  const { data, error, reload, isLoading } = useAxiosHook<
    unknown,
    { error: string }
  >(
    { url: `cashondeliveries/${warrant?.id}/notify/email`, method: 'POST' },
    { skipWhen: true }
  );

  const prevData = usePrevious(data);
  const prevError = usePrevious(error);

  useEffect(() => {
    if (!data || data === prevData) {
      return;
    }

    successToast(
      toastRef,
      t('Success'),
      t('COD email has been successfully {{emailStatus}}.', {
        emailStatus: warrant?.email_sent_date ? t('resent') : t('sent'),
      })
    );

    onHide();
  }, [t, data, onHide, prevData, warrant?.email_sent_date, toastRef]);

  useEffect(() => {
    if (!error || error === prevError) {
      return;
    }

    errorToast(
      toastRef,
      t('Error'),
      warrant?.email_sent_date
        ? t(
            'An error occured while trying to resend COD email due to {{errorMessage}}.',
            { errorMessage: error?.response?.data?.error }
          )
        : t(
            'An error occured while trying to send COD email due to {{errorMessage}}.',
            { errorMessage: error?.response?.data?.error }
          )
    );

    onHide();
  }, [t, error, onHide, prevError, warrant?.email_sent_date, toastRef]);

  function handleSendCODSubmision() {
    reload();
  }

  const dialogHeader = `${
    warrant?.email_sent_date ? t('Resend COD email') : t('Send COD email')
  }`;

  const dialogFooter = !isWarrantLoading && (
    <>
      <Button
        type="button"
        label={t('Cancel')}
        onClick={onHide}
        className="p-button-text"
      />

      <Button
        type="button"
        label={
          isLoading
            ? `${
                warrant?.email_sent_date ? t('Resending...') : t('Sending...')
              }`
            : `${warrant?.email_sent_date ? t('Resend') : t('Send')}`
        }
        disabled={isLoading}
        onClick={() => handleSendCODSubmision()}
      />
    </>
  );

  return (
    <>
      <Dialog
        visible={isShown}
        onHide={onHide}
        header={dialogHeader}
        footer={dialogFooter}
        resizable={false}
        dismissableMask
        style={{ maxWidth: 600 }}
      >
        {isWarrantLoading ? (
          <DialogSpinner />
        ) : (
          <div className="p-p-2">
            {warrant?.email_status_id === 3
              ? t(
                  'Are you sure you want to resend COD email to {{clientName}}?',
                  { clientName: warrant?.client_name }
                )
              : warrant?.email_status_id === 2
              ? t(
                  'The COD email will be sent automaticaly. Are you sure you want to resend the COD email to {{clientName}}?',
                  {
                    clientName: warrant?.client_name,
                  }
                )
              : t(
                  "The client hasn't configured the option to receive COD email. Are you sure you want to send COD email to {{clientName}}?",
                  {
                    clientName: warrant?.client_name,
                  }
                )}
          </div>
        )}
      </Dialog>
    </>
  );
}

export default SendCODConfirmDialog;
