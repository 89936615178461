import { useTranslation } from 'react-i18next';

function TitleRow(): JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      <b>#</b>
      <b>{t('Service')}</b>
      <b>{t('Product')}</b>
      <b>{t('Order status')}</b>
      <b>{t('Discount')} (%)</b>
      <span></span>
    </>
  );
}

export default TitleRow;
