import { omit } from 'lodash';
import { ColumnProps } from 'primereact/column';
import { TFunction } from 'react-i18next';

import {
  getInvoiceEmailStatusLabels,
  getInvoiceStatusConfig,
} from '../../../../configs/invoices';
import { InvoiceEmailStatus, InvoiceStatus } from '../../../../enums/invoices';
import {
  InvoiceCollection,
  InvoiceResource,
} from '../../../../types/api/invoices';
import {
  TableColumnHeadersMap,
  TableColumns,
  Unpacked,
} from '../../../../types/util';
import { noColumnStyle } from '../../../../utils/constants/tables';
import { dateCell } from '../../../../utils/helpers/dataTable';
import { currencyFormat } from '../../../../utils/helpers/formatting';
import { contextMenuModel } from '../../../../utils/helpers/primereact';
import styles from './InvoicesPreview.module.scss';

export type Row = Unpacked<InvoiceCollection>;

export const tableStorageKey = 'invoicesPreview_dataTable';

type Column = TableColumns<
  Pick<
    Row,
    | 'id'
    | 'status_id'
    | 'year'
    | 'serial_number'
    | 'orders_count'
    | 'client_id'
    | 'client_name'
    | 'address'
    | 'email'
    | 'department_name'
    | 'client_ssn'
    | 'date_from'
    | 'date_to'
    | 'amount'
    | 'vat'
    | 'total'
    | 'date_created'
    | 'date_modified'
    | 'date_email_sent'
    | 'email_status_id'
  >
>;

export function getColumnHeadersMap(
  t: TFunction
): TableColumnHeadersMap<Column> {
  return {
    no: t('No.'),
    id: t('ID'),
    status_id: t('Status'),
    year: t('Year'),
    serial_number: t('Ordinal Number'),
    orders_count: t('Orders count'),
    client_id: t('Client ID'),
    client_name: t('Client'),
    address: t('Client'),
    email: t('Email'),
    department_name: t('Department'),
    client_ssn: t('Client SSN'),
    date_from: t('Date From'),
    date_to: t('Date To'),
    amount: t('Price'),
    vat: t('VAT'),
    total: t('Total'),
    date_created: t('Created At'),
    date_modified: t('Modified At'),
    date_email_sent: t('Email sent on'),
    email_status_id: t('Email status'),
  };
}

const badgeClassNameMap: Record<InvoiceStatus, keyof typeof styles> = {
  [InvoiceStatus.Created]: styles.created,
  [InvoiceStatus.Approved]: styles.approved,
  [InvoiceStatus.Cancelled]: styles.reversed,
  [InvoiceStatus.Paid]: styles.paid,
};

export function additionalColumnProperties(t: TFunction) {
  const invoiceStatusConfig = getInvoiceStatusConfig(t);
  const invoiceEmailStatusLabels = getInvoiceEmailStatusLabels(t);

  return (column: Column): ColumnProps => {
    switch (column) {
      case 'no':
        return noColumnStyle;

      case 'id':
        return {
          style: { width: 120 },
          bodyStyle: { width: 120 },
        };

      case 'year':
        return {
          style: { width: 130 },
          bodyStyle: { width: 130 },
        };

      case 'client_id':
      case 'orders_count':
        return {
          style: { width: 150 },
          bodyStyle: { width: 150 },
        };

      case 'serial_number':
      case 'department_name':
      case 'client_ssn':
      case 'email':
        return {
          style: { width: 200 },
          bodyStyle: { width: 200 },
        };

      case 'client_name':
        return {
          style: { width: 350 },
          bodyStyle: { width: 350 },
        };

      case 'address':
        return {
          style: { width: 250 },
          bodyStyle: { width: 250 },
        };

      case 'date_from':
      case 'date_to':
      case 'date_created':
      case 'date_modified':
      case 'date_email_sent':
        return {
          body: (row: Row) =>
            dateCell(
              row[column],
              column === 'date_from' || column === 'date_to'
            ),
          style: { width: 200 },
          bodyStyle: { width: 200 },
        };

      case 'amount':
      case 'vat':
      case 'total':
        return {
          body: (row: Row) =>
            currencyFormat(row[column], { showCurrency: true }),
          style: { width: 200 },
          bodyStyle: { width: 200 },
        };

      case 'status_id':
        return {
          body: (row: Row) => {
            const status = row[column] as InvoiceStatus;
            const className = badgeClassNameMap[status];

            return (
              <span
                className={`p-px-2 p-py-1 ${styles.statusBadge} ${className}`}
              >
                {invoiceStatusConfig[status].label}
              </span>
            );
          },
          style: { width: 130 },
          bodyStyle: { width: 130 },
        };

      case 'email_status_id':
        return {
          body: (row: Row) =>
            invoiceEmailStatusLabels[row[column] as InvoiceEmailStatus],
          style: { width: 200 },
          bodyStyle: { width: 200 },
        };
    }
  };
}

export function generateContextMenu({
  t,
  isViewInvoiceVisible,
  handleView,
  isViewShipmentsVisible,
  handleShipments,
  isEditInvoiceVisible,
  handleEdit,
  handlePrintInvoice,
}: {
  t: TFunction;
  isViewInvoiceVisible: boolean;
  handleView: () => void;
  isViewShipmentsVisible: boolean;
  handleShipments: () => void;
  isEditInvoiceVisible: boolean;
  handleEdit: () => void;
  handlePrintInvoice: () => void;
}) {
  const showInvoiceOption = {
    label: t('Show Invoice'),
    icon: 'fas fa-search',
    command: handleView,
  };

  const showShipmentsOption = {
    label: t('Show Shipments'),
    icon: 'fas fa-search',
    command: handleShipments,
  };

  const editInvoiceOption = {
    label: t('Edit Invoice'),
    icon: 'fas fa-edit',
    command: handleEdit,
  };

  const printInvoiceOption = {
    label: t('Print Invoice'),
    icon: 'fas fa-file-invoice',
    command: handlePrintInvoice,
  };

  // const printSpecificationOption = {
  //   label: t('Print Specification'),
  //   icon: 'fas fa-print',
  //   command: () => {},
  // };

  return contextMenuModel([
    [
      [isViewInvoiceVisible, showInvoiceOption],
      [isViewShipmentsVisible, showShipmentsOption],
    ],
    [[isEditInvoiceVisible, editInvoiceOption]],
    [[true, printInvoiceOption]],
  ]);
}

export function generateGroupActions({
  t,
  isApproveVisible,
  handleApprove,
  isCancelVisible,
  handleCancel,
  isMarkAsPaidVisible,
  handleMarkAsPaid,
  isRecreateVisible,
  handleRecreate,
  isSendEmailVisible,
  handleSendEmail,
}: {
  t: TFunction;
  isApproveVisible: boolean;
  handleApprove: () => void;
  isCancelVisible: boolean;
  handleCancel: () => void;
  isMarkAsPaidVisible: boolean;
  handleMarkAsPaid: () => void;
  isRecreateVisible: boolean;
  handleRecreate: () => void;
  isSendEmailVisible: boolean;
  handleSendEmail: () => void;
}) {
  const approveOption = {
    label: t('Approve'),
    icon: 'fas fa-check',
    command: handleApprove,
  };

  const cancelOption = {
    label: t('Cancel.INVOICE'),
    icon: 'fas fa-minus-circle',
    command: handleCancel,
  };

  const markAsPaidOption = {
    label: t('Mark as Paid.SHE'),
    icon: 'fas fa-coins',
    command: handleMarkAsPaid,
  };

  const recreateOption = {
    label: t('Recreate'),
    icon: 'fas fa-redo',
    command: handleRecreate,
  };

  const sendEmailOption = {
    label: t('Send e-mail'),
    icon: 'fas fa-paper-plane',
    command: handleSendEmail,
  };

  return contextMenuModel([
    [
      [isApproveVisible, approveOption],
      [isCancelVisible, cancelOption],
      [isMarkAsPaidVisible, markAsPaidOption],
    ],
    [[isRecreateVisible, recreateOption]],
    [[isSendEmailVisible, sendEmailOption]],
  ]);
}

export function filterData(initialData: InvoiceResource['data']) {
  let returnObject: any = omit(initialData, [
    'created_by',
    'modified_by',
    'created_by_full_name',
    'modified_by_full_name',
    'employee_id',
  ]);

  if (returnObject.items) {
    for (let i = 0; i < returnObject.items.length; i++) {
      returnObject.items[i] = omit(returnObject.items[i], [
        'created_by',
        'modified_by',
        'created_by_full_name',
        'modified_by_full_name',
      ]);
    }
  }

  return returnObject;
}

export async function JSONtoXML(invoices: any) {
  let xml = '<?xml version="1.0" encoding="UTF-8"?>\n<invoices>\n';

  for (const invoice in invoices) {
    let invoiceXML = '\t<invoice>\n';

    for (const key in invoices[invoice]) {
      if (key === 'items') {
        if (!invoices[invoice]['items']) {
          continue;
        }

        invoiceXML += '\t\t<items>\n';
        for (const item in invoices[invoice]['items']) {
          invoiceXML += '\t\t\t<item>\n';
          for (const itemKey in invoices[invoice]['items'][item]) {
            invoiceXML += `\t\t\t\t<${itemKey}>\n`;
            invoiceXML += `\t\t\t\t\t${invoices[invoice]['items'][item][itemKey]}\n`;
            invoiceXML += `\t\t\t\t</${itemKey}>\n`;
          }
          invoiceXML += '\t\t\t</item>\n';
        }
        invoiceXML += '\t\t</items>\n';
      } else {
        invoiceXML += `\t\t<${key}>\n`;
        invoiceXML += `\t\t\t${invoices[invoice][key]}\n`;
        invoiceXML += `\t\t</${key}>\n`;
      }
    }

    invoiceXML += '\t</invoice>\n';
    xml += invoiceXML;
  }

  return xml;
}
