import {
  faChevronDown,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useMemo, useState } from 'react';
import { useLocation } from 'react-router';

import MaybeLink from '../MaybeLink';
import { isItemActive } from '../SideMenu.helpers';
import { MenuItem } from '../SideMenu.types';
import styles from './Item.module.scss';

type Props = MenuItem & {
  nestingLevel: number;
};

function Item({ nestingLevel, ...item }: Props): JSX.Element {
  const location = useLocation();

  const isActive: boolean = useMemo(
    () => isItemActive(location.pathname, item),
    [item, location.pathname]
  );

  const [isItemsExpanded, setIsItemsExpanded] = useState(isActive);

  function toggleExpansion() {
    setIsItemsExpanded((prevVal) => !prevVal);
  }

  // More than 3 nested menu items breaks both the UI and the UX
  const mainContentClassNames = classNames(styles.mainContent, {
    [styles.active]: isActive,
    [styles.nestingLevel1]: nestingLevel === 1,
    [styles.nestingLevel2]: nestingLevel >= 2,
  });

  // If item.path === location.pathname, don't render a Link, as clicking on such Link
  //  doesn't rerender the component and it only ends up resetting all query parameters
  const filteredPath =
    item.type === 'item' && item.path !== location.pathname
      ? item.path
      : undefined;

  return (
    <MaybeLink to={filteredPath} className={styles.menuItem}>
      <div onClick={toggleExpansion} className={mainContentClassNames}>
        <FontAwesomeIcon icon={item.icon} className={styles.icon} />
        <div style={{ marginRight: 'auto' }}>{item.label}</div>
        {item.type === 'parent' && (
          <FontAwesomeIcon
            icon={isItemsExpanded ? faChevronDown : faChevronRight}
          />
        )}
      </div>

      {item.type === 'parent' && isItemsExpanded && (
        <div className={styles.children}>
          {item.items.map((childItem, idx) => {
            const key = `${
              childItem.type === 'item' ? childItem.path : ''
            }-${idx}`;
            return (
              <Item
                {...childItem}
                key={key}
                nestingLevel={nestingLevel + 1}
                {...(childItem.type === 'item'
                  ? { path: item.basePath + childItem.path }
                  : { basePath: item.basePath + childItem.basePath })}
              />
            );
          })}
        </div>
      )}
    </MaybeLink>
  );
}

export default Item;
