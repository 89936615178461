import { IntBool } from '../../../../../enums/booleans';
import {
  ReplacementShipment,
  ReturnDocument,
} from '../../../../../enums/orders';
import { Numeric } from '../../../../../types/general';
import { Payment } from '../../Orders.functions';
import { FormValues, OrderPackaging } from './CreateEditDialog.functions';

export enum PayerEnum {
  Orderer,
  Sender,
  Recipient,
}

type CalculatorData = {
  // Required
  product_id: Numeric;
  pickup_city: string;
  delivery_city: string;

  // Optional
  sender_id?: string;
  recipient_id?: string;
  orderer_id?: string;
  pickup_address?: string;
  pickup_zip?: string;
  delivery_address?: string;
  delivery_zip?: string;
  pickup_hub_id?: string;
  delivery_hub_id?: string;
  secured_shipment?: string;
  return_document?: any;
  replacement_shipment?: any;
  weight?: string;
  cod_amount?: string;
  value_amount?: string;
  shipping_payer?: PayerEnum;
  redemption_payer?: PayerEnum;
  return_document_payer?: PayerEnum;
  insurance_payer?: PayerEnum;
  shipping_pay_cash?: string;
  redemption_pay_cash?: string;
  return_document_pay_cash?: string;
  insurance_pay_cash?: string;
  volume?: string;
  quantity?: string;
  fragile?: IntBool;
  two_man_delivery?: IntBool;
  adresnica_service_packaging_id?: OrderPackaging;
  specijaliziran_proizvod_id?: any;
};

export function toCalculatorData(data: FormValues) {
  const placeFromCleanedUp =
    data?.mesto_od_ime?.replace(/\(.+\)$/, '') +
    (data?.opstina_od_ime ? ' (' + data?.opstina_od_ime + ')' : '');
  const placeToCleanedUp =
    data?.mesto_do_ime?.replace(/\(.+\)$/, '') +
    (data?.opstina_do_ime ? ' (' + data?.opstina_do_ime + ')' : '');
  const addressFromCleanedUp = data?.adresa_od
    ? data?.adresa_od + (data?.broj_od ? ' ' + data?.broj_od : '')
    : '';
  const addressToCleanedUp = data?.adresa_do
    ? data?.adresa_do + (data?.broj_do ? ' ' + data?.broj_do : '')
    : '';

  let calculatorObject = {
    sender_id: data?.klient_od_id,
    recipient_id: data?.klient_do_id,
    orderer_id: data?.klient_naracatel_id,

    pickup_city: placeFromCleanedUp,
    pickup_address: addressFromCleanedUp,

    delivery_city: placeToCleanedUp,
    delivery_address: addressToCleanedUp,

    pickup_hub_id:
      data.pickup_location_type_id && data.hub_od_id
        ? String(data.hub_od_id)
        : undefined,
    delivery_hub_id:
      data.delivery_location_type_id && data.hub_do_id
        ? String(data.hub_do_id)
        : undefined,

    weight: data?.tezina,
    volume: data?.volumen,
    return_document: data.povraten_dokument
      ? ReturnDocument.HasReturnDocument
      : ReturnDocument.NoReturnDocument,

    replacement_shipment: data.replacement_shipment
      ? ReplacementShipment.HasReplacementShipment
      : ReplacementShipment.NoReplacementShipment,

    two_man_delivery: data?.two_man_delivery ?? undefined,
    fragile: data?.fragile ?? undefined,
    packaging_id: data.adresnica_service_packaging_id ?? undefined,

    product_id: String(data?.proizvod_id),
    quantity: data?.kolicina,
    specijaliziran_proizvod_id: data?.specijaliziran_proizvod_id ?? undefined,
  } as CalculatorData;

  if (!data.otkup || parseInt(data.otkup) > 0) {
    calculatorObject.cod_amount = data.otkup;
  } else {
    if (!data.vrednost || parseInt(data.vrednost) > 0) {
      calculatorObject.value_amount = data.vrednost;
    }
  }

  for (const payment of data?.payments ?? []) {
    const payerAttribute = servicePayerAttributeEn(payment.service_id);
    const cashAttribute = serviceCashAttributeEn(payment.service_id);

    calculatorObject[payerAttribute] =
      String(payment?.customer_id) === String(data.klient_od_id) ||
      payment?.customer_id === PayerEnum.Sender
        ? PayerEnum.Sender
        : String(payment?.customer_id) === String(data.klient_do_id) ||
          payment.customer_id === PayerEnum.Recipient
        ? PayerEnum.Recipient
        : PayerEnum.Orderer;

    calculatorObject[cashAttribute] = String(payment?.cash);
  }

  return calculatorObject;
}

export function servicePayerAttribute(service: Numeric): ServicePayerAttribute {
  switch (Number(service)) {
    case 1:
      return 'postarina_plaka';
    case 2:
      return 'otkupnina_plaka';
    case 3:
      return 'povraten_dokument_plaka';
    case 4:
      return 'osiguruvanje_plaka';
    default:
      return 'postarina_plaka';
  }
}

function servicePayerAttributeEn(service: Numeric): ServicePayerAttributeEn {
  switch (Number(service)) {
    case 1:
      return 'shipping_payer';
    case 2:
      return 'redemption_payer';
    case 3:
      return 'return_document_payer';
    case 4:
      return 'insurance_payer';
    default:
      return 'shipping_payer';
  }
}

export function serviceCashAttribute(service: Numeric): ServiceCashAttribute {
  switch (Number(service)) {
    case 1:
      return 'postarina_gotovina';
    case 2:
      return 'otkupnina_gotovina';
    case 3:
      return 'povraten_dokument_gotovina';
    case 4:
      return 'osiguruvanje_gotovina';
    default:
      return 'postarina_gotovina';
  }
}

function serviceCashAttributeEn(service: Numeric): ServiceCashAttributeEn {
  switch (Number(service)) {
    case 1:
      return 'shipping_pay_cash';
    case 2:
      return 'redemption_pay_cash';
    case 3:
      return 'return_document_pay_cash';
    case 4:
      return 'insurance_pay_cash';
    default:
      return 'shipping_pay_cash';
  }
}

export function parseCalculatorData(
  calculatorData: Payment[] | null | undefined
): SinglePayment[] | undefined {
  return calculatorData?.map((value: Payment) => {
    return {
      cash: Number(value.gotovina),
      created: value.created,
      customer_id: Number(value.klient_id),
      description: value.opis ?? '',
      price: Number(value.iznos),
      quantity: Number(value.kolicina),
      service_id: Number(value.usluga_id),
      service_name: value.usluga_ime,
      tariff_id: Number(value.tarifa_id),
      unit_price: Number(value.ediznos),
      vat_percent: Number(value.ddv),
      pricelist_id: Number(value.cenovnik_id),
    };
  });
}

export type SinglePayment = {
  cash: number;
  created: string;
  customer_id: number;
  description: string;
  price: number;
  quantity: number;
  service_id: number;
  service_name: string;
  tariff_id: number;
  pricelist_id: number;
  unit_price: number;
  vat_percent: number;
};

type ServicePayerAttribute =
  | 'postarina_plaka'
  | 'povraten_dokument_plaka'
  | 'otkupnina_plaka'
  | 'osiguruvanje_plaka';

type ServicePayerAttributeEn =
  | 'shipping_payer'
  | 'redemption_payer'
  | 'return_document_payer'
  | 'insurance_payer';

type ServiceCashAttribute =
  | 'postarina_gotovina'
  | 'povraten_dokument_gotovina'
  | 'otkupnina_gotovina'
  | 'osiguruvanje_gotovina';

type ServiceCashAttributeEn =
  | 'shipping_pay_cash'
  | 'redemption_pay_cash'
  | 'return_document_pay_cash'
  | 'insurance_pay_cash';
