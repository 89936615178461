import { availableCourierCollectionGuard } from './_api/courier';
import {
  assignTransferDeliveryResourceGuard,
  cancelTransferDeliveryResourceGuard,
  orderResourceGuard,
  ordersBarcodePrefixesCollectionGuard,
  transferDeliveryAssignmentsCollectionGuard,
  transferSummaryCollectionGuard,
} from './_api/orders';
import {
  transferAssignStatusCollectionGuard,
  transferAssignStatusResourceGuard,
} from './_api/transferAssignStatuses';

export const assignShipmentGuard = assignTransferDeliveryResourceGuard;
export const cancelShipmentAssignmentGuard =
  cancelTransferDeliveryResourceGuard;
export const assignStatusCollectionGuard = transferAssignStatusCollectionGuard;

export const routeGuard = [
  availableCourierCollectionGuard,
  transferDeliveryAssignmentsCollectionGuard,
  transferSummaryCollectionGuard,
  orderResourceGuard,
  ordersBarcodePrefixesCollectionGuard,
  transferAssignStatusCollectionGuard,
  transferAssignStatusResourceGuard,
];
