import { clientLookupCollectionGuard } from './_api/clients';
import {
  addOrderCourierAssignmentPickupResourceGuard,
  orderCourierAssignmentPickupCollectionGuard,
} from './_api/orders';
import { regionCourierCollectionGuard } from './_api/regions';

export const couriersFilter = regionCourierCollectionGuard;
export const clientsFilter = clientLookupCollectionGuard;

export const assignParcel = addOrderCourierAssignmentPickupResourceGuard;

export const routeGuard = orderCourierAssignmentPickupCollectionGuard;
