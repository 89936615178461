import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import ToastContext from '../../../../../../../context/ToastContext';
import useAxiosHook from '../../../../../../../hooks/useAxiosHook';
import usePrevious from '../../../../../../../hooks/usePrevious';
import { AvailableCouriersCollection } from '../../../../../../../types/api/couriers';
import {
  errorToast,
  infoToast,
  successToast,
} from '../../../../../../../utils/helpers/primereact';

type Props = {
  visible: boolean;
  onHide: () => void;
  onSuccess: () => void;
  couriers: AvailableCouriersCollection | undefined;
  courierFilter: number | null;
};

function CreateWorkOrderDialog({
  visible,
  onHide,
  onSuccess,
  couriers,
  courierFilter,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const { toastRef } = useContext(ToastContext);

  const selectedCourier = useMemo(
    () => couriers?.find((c) => c.id === courierFilter),
    [courierFilter, couriers]
  );

  const { data, error, isLoading, reload } = useAxiosHook(
    {
      method: 'POST',
      url: `/couriers/${courierFilter}/work-order`,
    },
    { skipWhen: true }
  );

  const prevData = usePrevious(data);
  const prevError = usePrevious(error);

  useEffect(() => {
    if (!data || data === prevData) {
      return;
    }

    successToast(
      toastRef,
      t('Success'),
      t('Work order was successfully created for courier {{name}}.', {
        name: selectedCourier?.full_name ?? '',
      })
    );

    onSuccess();
  }, [data, prevData, selectedCourier?.full_name, onSuccess, t, toastRef]);

  useEffect(() => {
    if (!error || error === prevError) {
      return;
    }

    // If the work day has already been created while the dialog was opened,
    //  showing an error toast message would be inappropriate.
    if (error.response?.status === 422) {
      infoToast(
        toastRef,
        t('Work order already created'),
        t(
          'The work order for courier {{name}} has already been created in the meantime.',
          { name: selectedCourier?.full_name ?? '' }
        )
      );

      onSuccess();
    } else {
      errorToast(
        toastRef,
        t('Error'),
        t(
          'An error occured while trying to create a work order for courier {{name}}.',
          { name: selectedCourier?.full_name ?? '' }
        )
      );
    }
  }, [error, onSuccess, prevError, selectedCourier?.full_name, t, toastRef]);

  const header = t('Create work order?');

  const footer = (
    <>
      <Button
        type="button"
        label={t('Cancel')}
        onClick={onHide}
        className="p-button-outlined"
      />

      <Button
        type="button"
        label={isLoading ? t('Creating...') : t('Create')}
        disabled={isLoading}
        onClick={() => reload()}
      />
    </>
  );

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header={header}
      footer={footer}
      resizable={false}
    >
      <span
        dangerouslySetInnerHTML={{
          __html: t(
            'No work order is created for courier {{name}} yet.<br />Would you like to create one?',
            {
              name: selectedCourier?.full_name ?? '',
            }
          ),
        }}
      />
    </Dialog>
  );
}

export default CreateWorkOrderDialog;
