import {
  addRegionCollectionGuard,
  assignCourierToRegionResourceGuard,
  deleteCourierToRegionResourceGuard,
  deleteRegionResourceGuard,
  editCourierToRegionResourceGuard,
  editRegionResourceGuard,
  regionCourierCollectionGuard,
} from './_api/regions';

export const addRegion = addRegionCollectionGuard;
export const editRegion = editRegionResourceGuard;
export const deleteRegion = deleteRegionResourceGuard;

export const assignCourier = assignCourierToRegionResourceGuard;
export const editCourier = editCourierToRegionResourceGuard;
export const deleteCourier = deleteCourierToRegionResourceGuard;

export const routeGuard = regionCourierCollectionGuard;
