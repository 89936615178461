import { Form, Formik, FormikProps } from 'formik';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

import { CreateOrderResource } from '../../../../../types/api/orders';
import { Numeric } from '../../../../../types/general';
import { SingleOrder } from '../../Orders.functions';
import CreateEditDialog from './CreateEditDialog';
import {
  FormValues,
  attributesTabFields,
  ordererTabFields,
  recipientTabFields,
  senderTabFields,
  values,
} from './CreateEditDialog.functions';
import { GroupOrderConfig } from './GroupOrderConfig';

type Props = {
  reload: () => void;
  onHide: () => void;
  onCreate?: (response: CreateOrderResource) => void;
  onEdit?: (id: Numeric) => void;
  data?: SingleOrder;
  visible: boolean;
  isSmallScreen: boolean;
  isWarehouseReception?: boolean;
};

export function GroupOrderDialog({
  reload,
  onHide,
  onCreate,
  onEdit,
  visible,
  isSmallScreen,
}: Props) {
  const [orderIndex, setOrderIndex] = useState(0);
  const [orderData, setOrderData] = useState<FormValues[]>([]);
  const [isConfigVisible, setIsConfigVisible] = useState(true);

  const formRef = useRef<FormikProps<any>>(null);

  useEffect(() => {
    setOrderIndex(0);
    setOrderData([values]);
    setIsConfigVisible(true);
  }, [visible]);

  useEffect(() => {
    setOrderData((orderData) => {
      let newOrderData = [...orderData];
      const constantFields = formRef?.current?.values;
      const firstOrder = orderData[0];

      let initialValues = {
        ...firstOrder,
        ...(!constantFields?.isSenderConstant ? senderTabFields : {}),
        ...(!constantFields?.isRecipientConstant ? recipientTabFields : {}),
        ...(!constantFields?.isOrdererConstant ? ordererTabFields : {}),
        ...(!constantFields?.isAttributesConstant ? attributesTabFields : {}),
        seriski_broj: undefined,
      };

      if (orderIndex >= orderData.length) {
        newOrderData[orderIndex] = initialValues;
      }

      return newOrderData;
    });
  }, [orderIndex]);

  function setOrder(value: any) {
    setOrderData((orderData) => {
      let newOrderData = [...orderData];
      newOrderData[orderIndex] = value;
      return newOrderData;
    });
  }

  return (
    <>
      <Formik
        initialValues={{
          isSenderConstant: false,
          isRecipientConstant: false,
          isOrdererConstant: false,
          isAttributesConstant: false,
        }}
        innerRef={formRef}
        onSubmit={() => {}}
      >
        <Form>
          <GroupOrderConfig
            visible={visible && isConfigVisible}
            onHide={onHide}
            onStart={() => setIsConfigVisible(false)}
          />
        </Form>
      </Formik>

      <CreateEditDialog
        visible={visible && !isConfigVisible}
        isGroupDialog
        orderData={orderData}
        setOrderData={setOrder}
        orderIndex={orderIndex}
        setOrderIndex={setOrderIndex}
        reload={reload}
        onHide={onHide}
        constantFields={formRef?.current?.values}
        isSmallScreen={isSmallScreen}
        onCreate={onCreate}
        onEdit={onEdit}
      />
    </>
  );
}
