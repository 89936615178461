import './CreateEditDialog.scss';

import { Form, Formik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { TabPanel, TabView } from 'primereact/tabview';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import useAxios from '../../../../hooks/useAxios';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import { invalidTabHeaderIcon } from '../../../../utils/globals';
import {
  formik_handleErrorsUponSubmission,
  formik_hasDialogTabErrors,
} from '../../../../utils/helpers';
import {
  isValidMobileNumber,
  isValidPhoneNumber,
} from '../../../../utils/helpers/phoneNumbers';
import { yupRequiredField } from '../../../../utils/helpers/yup';
import {
  basicInfoTabFields,
  getInitialValues,
  hubsTabFields,
  rolesDataTabFields,
  toApiData,
  vehiclesTabFields,
} from './CreateEditDialog.functions';
import CreateEditDialogBasicInfoTab from './CreateEditDialogBasicInfoTab';
import CreateEditDialogHubsTab from './CreateEditDialogHubsTab';
import CreateEditDialogRolesDataTab from './CreateEditDialogRolesDataTab';
import CreateEditDialogVehiclesTab from './CreateEditDialogVehiclesTab';

function CreateEditDialog({
  data = {},
  isShown,
  onHide,
  onBasicInfoSubmit,
  onRolesSubmit,
  onHubsSubmit,
  onVehicleSubmit,
  onAllRequestsFinish,
}) {
  const { t } = useTranslation();

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const formRef = useRef(null);
  const isBasicInfoUpdated = useRef(false);
  const isRolesUpdated = useRef(false);
  const isHubsUpdated = useRef(false);
  const isVehicleUpdated = useRef(false);

  const isEditDialog = Object.keys(data).length > 0;

  // Used for onAllRequestsFinish, which refreshes the data table
  const {
    reload: basicInfoUpdateRequest,
    isLoading: isBasicInfoUpdateRequestLoading,
  } = useAxios();
  const { reload: rolesUpdateRequest, isLoading: isRolesUpdateRequestLoading } =
    useAxios();
  const { reload: hubsUpdateRequest, isLoading: isHubsUpdateRequestLoading } =
    useAxios();
  const {
    reload: vehicleUpdateRequest,
    isLoading: isVehicleUpdateRequestLoading,
  } = useAxios();

  useEffect(() => {
    return () => {
      isHubsUpdated.current = false;
      setActiveTabIndex(0);
    };
  }, [isShown]);

  // It calls onAllRequestsFinish which refreshes the data table
  // Makes sure that happens after all 3 (or 2) requests have been sent
  //  and thus, only one request is sent
  function requestFinished() {
    if (
      isBasicInfoUpdated.current &&
      (isEditDialog ? isRolesUpdated.current : true) &&
      isHubsUpdated.current &&
      isVehicleUpdated.current
    ) {
      onAllRequestsFinish();
    }
  }

  function updateRolesAndHubs(id, values) {
    if (isEditDialog) {
      rolesUpdateRequest({
        url: `/employees/${id}/roles`,
        payload: { roles: values.roles },
        method: 'POST',
        successCallback: () => {
          onRolesSubmit(values.ime, true);
        },
        errorCallback: () => {
          onRolesSubmit(values.ime, false);
        },
        finallyCallback: () => {
          isRolesUpdated.current = true;
          requestFinished();
        },
      });
    }

    hubsUpdateRequest({
      url: `/employees/${id}/hubs`,
      payload: { hubs: values.hubs },
      method: 'POST',
      successCallback: () => {
        onHubsSubmit(values.ime, true);
      },
      errorCallback: () => {
        onHubsSubmit(values.ime, false);
      },
      finallyCallback: () => {
        isHubsUpdated.current = true;
        requestFinished();
      },
    });
  }

  function setOrUpdateVehicle(id, vehicle_id, initial_vehicle) {
    if (initial_vehicle === vehicle_id || vehicle_id === null) {
      isVehicleUpdated.current = true;
      requestFinished();
      return;
    }

    if (!initial_vehicle || !isEditDialog) {
      vehicleUpdateRequest({
        url: `/employees/${id}/vehicles/add`,
        method: 'POST',
        payload: { vozilo_id: vehicle_id },
        successCallback: () => {
          onVehicleSubmit(formRef.current.values.name, true);
        },
        errorCallback: () => {
          onVehicleSubmit(formRef.current.values.name, false);
        },
        finallyCallback: () => {
          isVehicleUpdated.current = true;
          requestFinished();
        },
      });
    } else {
      vehicleUpdateRequest({
        url: `/employees/${id}/vehicles`,
        method: 'PUT',
        payload: { vozilo_id: vehicle_id ?? null },
        successCallback: () => {
          onVehicleSubmit(formRef.current.values.name, true);
        },
        errorCallback: () => {
          onVehicleSubmit(formRef.current.values.name, false);
        },
        finallyCallback: () => {
          isVehicleUpdated.current = true;
          requestFinished();
        },
      });
    }
  }

  function handleFormSubmision(values) {
    isBasicInfoUpdated.current = false;
    isRolesUpdated.current = false;
    isHubsUpdated.current = false;
    isVehicleUpdated.current = false;

    if (isEditDialog) {
      basicInfoUpdateRequest({
        url: `/employees/${data.id}`,
        payload: toApiData(values, isEditDialog),
        method: 'PUT',
        successCallback: () => {
          onBasicInfoSubmit(values.ime, true);
        },
        errorCallback: (err) => {
          if (
            formik_handleErrorsUponSubmission(
              err?.response?.data?.error,
              formRef
            )
          ) {
            return;
          }

          onBasicInfoSubmit(values.ime, false);
        },
        finallyCallback: () => {
          isBasicInfoUpdated.current = true;
          requestFinished();
        },
      });

      updateRolesAndHubs(data.id, values);

      setOrUpdateVehicle(
        data.id,
        values.vozilo_id,
        formRef.current.initialValues.vozilo_id
      );
    } else {
      basicInfoUpdateRequest({
        url: '/employees',
        payload: toApiData(values, isEditDialog),
        method: 'POST',
        successCallback: (responseData) => {
          onBasicInfoSubmit(values.ime, true);
          updateRolesAndHubs(responseData[0], values);
          setOrUpdateVehicle(responseData[0], values.vozilo_id);
        },
        errorCallback: (err) => {
          if (
            formik_handleErrorsUponSubmission(
              err?.response?.data?.error,
              formRef
            )
          ) {
            return;
          }

          onBasicInfoSubmit(values.ime, false);
        },
        finallyCallback: () => {
          isBasicInfoUpdated.current = true;
          requestFinished();
        },
      });
    }
  }

  const dialogHeader = isEditDialog
    ? t('Editing - {{name}}', { name: data.ime })
    : t('Add an employee');

  const isCreateUpdateRequestLoading =
    isBasicInfoUpdateRequestLoading ||
    isRolesUpdateRequestLoading ||
    isHubsUpdateRequestLoading ||
    isVehicleUpdateRequestLoading;

  const dialogFooter = (
    <>
      <Button
        type="button"
        label={t('Cancel')}
        className="p-button-text"
        onClick={() => onHide()}
      />
      <Button
        type="button"
        label={
          isCreateUpdateRequestLoading
            ? t('Saving...')
            : isEditDialog
            ? t('Save changes')
            : t('Add employee')
        }
        disabled={isCreateUpdateRequestLoading}
        onClick={() => {
          if (formRef.current) {
            formRef.current.handleSubmit();
          }
        }}
        data-cy="submit"
      />
    </>
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        ime: Yup.string()
          .required(yupRequiredField(t, t('Name')))
          .max(25),
        prezime: Yup.string()
          .required(yupRequiredField(t, t('Surname')))
          .max(25),
        username: Yup.string()
          .required(yupRequiredField(t, t('Email (username)')))
          .max(254),
        adresa: Yup.string().max(50),
        municipality: Yup.string().required(
          yupRequiredField(t, t('Municipality'))
        ),
        place: Yup.string().required(yupRequiredField(t, t('Place'))),
        telefon: Yup.string()
          .test('telefon', t('Invalid phone number'), (value, context) => {
            if (!value) {
              return true;
            }

            const { telefon_region } = context.parent;
            return isValidPhoneNumber(value, telefon_region);
          })
          .test(
            'telefon',
            t('At least phone or mobile must be filled out'),
            function (telefon) {
              return this.parent.mobilen || telefon;
            }
          ),
        mobilen: Yup.string()
          .test('mobilen', t('Invalid mobile number'), (value, context) => {
            if (!value) {
              return true;
            }

            const { mobilen_region } = context.parent;
            return isValidMobileNumber(value, mobilen_region);
          })
          .test(
            'mobilen',
            t('At least phone or mobile must be filled out'),
            function (mobilen) {
              return this.parent.telefon || mobilen;
            }
          ),
        hub_id: Yup.string().required(
          yupRequiredField(t, t('Native warehouse'))
        ),
        hubs: Yup.array(),
        roles: Yup.array()
          .min(1, yupRequiredField(t, t('Roles')))
          .required(yupRequiredField(t, t('Roles'))),
        ...(!isEditDialog
          ? {
              password: Yup.string().required(
                yupRequiredField(t, t('Password'))
              ),
              datum_raganje: Yup.string().required(
                yupRequiredField(t, t('Date of Birth'))
              ),
            }
          : {}),
      }),
    [isEditDialog, t]
  );

  const isOnMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Dialog
      visible={isShown}
      onHide={onHide}
      header={dialogHeader}
      footer={dialogFooter}
      maximizable
      maximized={isOnMobile}
      resizable={false}
      className="employees-create-edit-dialog"
    >
      <Formik
        innerRef={formRef}
        initialValues={getInitialValues(data)}
        validateOnChange={false}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmision}
      >
        {({ errors, touched }) => {
          const basicInfoTabIcon = formik_hasDialogTabErrors(
            errors,
            basicInfoTabFields
          )
            ? invalidTabHeaderIcon
            : {};

          const rolesDataTabIcon = formik_hasDialogTabErrors(
            errors,
            rolesDataTabFields
          )
            ? invalidTabHeaderIcon
            : {};

          const hubsTabIcon = formik_hasDialogTabErrors(errors, hubsTabFields)
            ? invalidTabHeaderIcon
            : {};

          const vehiclesTabIcon = formik_hasDialogTabErrors(
            errors,
            vehiclesTabFields
          )
            ? invalidTabHeaderIcon
            : {};

          return (
            <Form>
              <TabView
                activeIndex={activeTabIndex}
                onTabChange={(e) => setActiveTabIndex(e.index)}
                className={
                  Object.keys(errors).length > 0 &&
                  Object.keys(touched).length > 0
                    ? 'invalid'
                    : ''
                }
              >
                <TabPanel header={t('Basic information')} {...basicInfoTabIcon}>
                  <CreateEditDialogBasicInfoTab
                    data={data}
                    isEditDialog={isEditDialog}
                    isShown={isShown}
                  />
                </TabPanel>

                <TabPanel
                  header={t('Roles')}
                  headerClassName="data-cy-roles-tab"
                  {...rolesDataTabIcon}
                >
                  <CreateEditDialogRolesDataTab />
                </TabPanel>

                <TabPanel
                  header={t('Warehouses')}
                  headerClassName="data-cy-hubs-tab"
                  {...hubsTabIcon}
                >
                  <CreateEditDialogHubsTab />
                </TabPanel>

                <TabPanel
                  header={t('Vehicle')}
                  headerClassName="data-cy-vehicles-tab"
                  {...vehiclesTabIcon}
                >
                  <CreateEditDialogVehiclesTab />
                </TabPanel>
              </TabView>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
}

export default CreateEditDialog;
