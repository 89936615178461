import './Login.scss';

import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik, FormikProps } from 'formik';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import FieldWithErrors from '../../../features/Forms/FieldWithErrors/FieldWithErrors';
import useAxiosHook from '../../../hooks/useAxiosHook';
import usePageTitle from '../../../hooks/usePageTitle';
import usePrevious from '../../../hooks/usePrevious';
import { loginUser } from '../../../redux/actions/userActions';
import { UserLoginResource } from '../../../types/api/users';
import {
  FormValues,
  getValidationSchema,
  initialValues,
} from './Login.functions';

function Login(): JSX.Element {
  const { t } = useTranslation();

  usePageTitle(t('Login'));

  const dispatch = useDispatch();

  const formRef = useRef<FormikProps<FormValues>>(null);

  const { data, error, isLoading, reload } = useAxiosHook<UserLoginResource>(
    { url: '/users/login', method: 'POST' },
    { skipWhen: true }
  );

  const prevData = usePrevious(data);

  useEffect(() => {
    if (!data || data === prevData) {
      return;
    }

    dispatch(loginUser(data, formRef.current?.values.rememberMe, Date.now()));
  }, [dispatch, data, prevData]);

  const validationSchema = useMemo(() => getValidationSchema(t), [t]);

  function handleSubmit(values: FormValues) {
    reload({
      data: {
        username: values.username,
        password: values.password,
        grant_type: 'password',
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
      },
    });
  }

  return (
    <div className="login-page">
      <div className="form-wrapper p-shadow-1">
        <h1 className="p-mt-6 p-mb-3 p-text-center p-text-normal">
          {t('Welcome back!')}
        </h1>
        <p className="p-m-0 p-text-center p-text-secondary p-text-light">
          {t('Enter your credentials to access GLS Employees.')}
        </p>

        {error?.response?.status === 401 && (
          <div className="login-error">
            <FontAwesomeIcon icon={faExclamationCircle} className="p-mr-2" />
            {t('Incorrect username or password')}
          </div>
        )}

        <div className="p-p-6 p-pt-0">
          <Formik
            innerRef={formRef}
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={handleSubmit}
          >
            {({ values, isValid }) => (
              <Form>
                <div className="p-fluid">
                  <FieldWithErrors name="username" label={t('Username')}>
                    <Field
                      id="username"
                      name="username"
                      className="p-inputtext p-component"
                    />
                  </FieldWithErrors>

                  <FieldWithErrors name="password" label={t('Password')}>
                    <Field
                      id="password"
                      name="password"
                      type="password"
                      className="p-inputtext p-component"
                    />
                  </FieldWithErrors>

                  <Field
                    as={Checkbox}
                    inputId="rememberMe"
                    name="rememberMe"
                    checked={values.rememberMe}
                  />
                  <label htmlFor="rememberMe" className="p-checkbox-label">
                    {t('Remember me')}
                  </label>

                  <Button
                    type="submit"
                    label={t('Login')}
                    disabled={!isValid || isLoading}
                    className="p-mt-5"
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default Login;
