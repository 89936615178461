import { faFileInvoice } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import { DataTableRowClickEventParams } from 'primereact/datatable';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import ToastContext from '../../../../context/ToastContext';
import useAxiosHook from '../../../../hooks/useAxiosHook';
import { useEndpointGuard } from '../../../../hooks/useEndpointGuard';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import usePageTitle from '../../../../hooks/usePageTitle';
import usePrevious from '../../../../hooks/usePrevious';
import useTableColumns from '../../../../hooks/useTableColumns';
import useTableState from '../../../../hooks/useTableState';
import {
  InvoiceCandidatesCustomerCollection,
  InvoiceCandidatesItemCollection,
  InvoiceCandidatesShipmentCollection,
} from '../../../../types/api/invoices';
import { Unpacked } from '../../../../types/util';
import * as invoicesGuards from '../../../../utils/constants/auth/_api/invoices';
import { httpDateFormat } from '../../../../utils/helpers/formatting';
import { queryString } from '../../../../utils/helpers/http';
import { errorToast, successToast } from '../../../../utils/helpers/primereact';
import {
  getSearchQueryParam,
  tryDateSearchParam,
} from '../../../../utils/helpers/searchQuery';
import Table from '../../../DataTable/Table/Table';
import BDItem from '../../../Dialogs/BreadCrumbsDialog/BDItem';
import BreadcrumbsDialog from '../../../Dialogs/BreadCrumbsDialog/BreadcrumbsDialog';
import Grid from '../../../Grid/Grid';
import MainContent from '../../../Grid/MainContent';
import SidePanels from '../../../Grid/SidePanels';
import HeaderPages from '../../Components/HeaderPages/HeaderPages';
import CreateDialog from './Dialogs/Create/CreateDialog';
import Items from './Dialogs/View/Content/Items/Items';
import Shipments from './Dialogs/View/Content/Shipments/Shipments';
import ViewShipments from './Dialogs/View/ViewShipments/ViewShipments';
import {
  Row,
  additionalColumnProperties,
  generateContextMenu,
  generateGroupActions,
  getColumnHeadersMap,
  tableStorageKey,
} from './InvoicesCreation.functions';
import Filters from './Sidebar/Filters';
import Stats from './Sidebar/Stats';

function Invoices(): JSX.Element {
  const { t } = useTranslation();

  usePageTitle(t('Invoices Creation'));

  const location = useLocation();

  const { toastRef } = useContext(ToastContext);

  const createInvoiceResourceGuard = useEndpointGuard(
    invoicesGuards.createInvoiceResourceGuard
  );
  const invoiceCandidatesItemCollection = useEndpointGuard(
    invoicesGuards.invoiceCandidatesItemCollectionGuard
  );
  const invoiceCandidatesShipmentCollection = useEndpointGuard(
    invoicesGuards.invoiceCandidatesShipmentCollectionGuard
  );

  const columnHeadersMap = useMemo(() => getColumnHeadersMap(t), [t]);

  const {
    tableRef,
    page,
    setPage,
    limit,
    setLimit,
    sortField,
    setSortField,
    sortOrder,
    setSortOrder,
    selectionMultiple,
    setSelectionMultiple,
    contextMenuSelection,
    setContextMenuSelection,
  } = useTableState<Row>(tableStorageKey);

  const [isSingleAction, setIsSingleAction] = useState(true);

  const { columns, columnOptions, selectedColumns, setSelectedColumns } =
    useTableColumns(
      page,
      limit,
      'invoices',
      columnHeadersMap,
      columnHeadersMap,
      additionalColumnProperties
    );

  const isTwoColumnLayout = useMediaQuery('(min-width: 769px)');

  // Filters
  const [dateFrom, setDateFrom] = useState<Date>(
    () =>
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'dateFrom') ?? ''
      ) ?? dayjs().subtract(6, 'months').startOf('month').toDate()
  );

  const [dateTo, setDateTo] = useState<Date>(
    () =>
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'dateTo') ?? ''
      ) ?? dayjs().subtract(1, 'month').endOf('month').toDate()
  );

  const [client, setClient] = useState<string | null>(
    () => getSearchQueryParam(location.search, 'client') ?? null
  );

  // Dialogs
  const [isViewDialogVisible, setIsViewDialogVisible] = useState(false);
  const [isCreateDialogVisible, setIsCreateDialogVisible] = useState(false);
  const [isViewShipmentsDialogVisible, setIsViewShipmentsDialogVisible] =
    useState(false);
  const [index, setIndex] = useState(0);

  // Selections
  const [invoiceItemSelection, setInvoiceItemSelection] =
    useState<Unpacked<InvoiceCandidatesItemCollection> | null>(null);

  const httpFiltersObj = useMemo(
    () => ({
      client_id: client,
      date_from: httpDateFormat(dateFrom),
      date_to: httpDateFormat(dateTo),
      page: page,
      limit: limit,
    }),
    [client, dateFrom, dateTo, limit, page]
  );

  const canLoadData = dateFrom && dateTo;

  const { data, error, isLoading, reload } =
    useAxiosHook<InvoiceCandidatesCustomerCollection>(
      '/invoices/candidates/customers' + queryString(httpFiltersObj),
      {
        skipWhen: !canLoadData,
      }
    );

  const {
    data: createInvoiceData,
    error: createInvoiceError,
    reload: createInvoice,
  } = useAxiosHook();

  const createInvoiceDataPrevious = usePrevious(createInvoiceData);
  const createInvoiceErrorPrevious = usePrevious(createInvoiceError);

  useEffect(() => {
    if (!createInvoiceData || createInvoiceData === createInvoiceDataPrevious) {
      return;
    }

    successToast(
      toastRef,
      t('Invoice Successfully Created'),
      t('An invoices has been created for the requested client')
    );

    reload();
  }, [createInvoiceData, createInvoiceDataPrevious, reload, t, toastRef]);

  useEffect(() => {
    if (
      !createInvoiceError ||
      createInvoiceError === createInvoiceErrorPrevious
    ) {
      return;
    }

    errorToast(
      toastRef,
      t('Invoice Creation Failed'),
      t(
        'An error ocurred while trying to create an invoice for the reqeusted client'
      )
    );
  }, [createInvoiceError, createInvoiceErrorPrevious, t, toastRef]);

  const viewShipmentsRequest =
    useAxiosHook<InvoiceCandidatesShipmentCollection>(
      '/invoices/candidates/shipments' +
        queryString({
          client_id: contextMenuSelection?.client_id,
          discount: invoiceItemSelection?.discount,
          vat: invoiceItemSelection?.vat,
          description: invoiceItemSelection?.description,
          price_per_item: invoiceItemSelection?.price_per_item,
          vat_per_item: invoiceItemSelection?.vat_per_item,
          price_and_vat_per_item: invoiceItemSelection?.price_and_vat_per_item,
          price_per_item_discounted:
            invoiceItemSelection?.price_per_item_discounted,
          vat_per_item_discounted:
            invoiceItemSelection?.vat_per_item_discounted,
          price_and_vat_per_item_discounted:
            invoiceItemSelection?.price_and_vat_per_item_discounted,
          date_from: httpDateFormat(dateFrom),
          date_to: httpDateFormat(dateTo),
        }),
      {
        skipWhen: !contextMenuSelection?.client_id || index === 0,
      }
    );

  const itemsLabel = contextMenuSelection?.client_name
    ? t('Invoice Items for {{name}}', {
        name: contextMenuSelection?.client_name,
      })
    : t('Invoice Items');

  const shipmentsLabel = invoiceItemSelection?.tariff_name
    ? t('Invoice Shipments for Item - {{invoiceItem}}', {
        invoiceItem: invoiceItemSelection?.tariff_name,
      })
    : t('Invoice Shipments');

  const contextMenuModel = useMemo(
    () =>
      generateContextMenu({
        t,
        isViewItemsVisible:
          invoiceCandidatesItemCollection &&
          invoiceCandidatesShipmentCollection,
        handleViewItemsClick: () => setIsViewDialogVisible(true),
        isViewShipmentsVisible:
          invoiceCandidatesItemCollection &&
          invoiceCandidatesShipmentCollection,
        handleViewShipmentsClick: () => setIsViewShipmentsDialogVisible(true),
      }),
    [invoiceCandidatesItemCollection, invoiceCandidatesShipmentCollection, t]
  );

  const groupActionsModel = useMemo(
    () =>
      generateGroupActions({
        t,
        isCreateInvoiceVisible: createInvoiceResourceGuard,
        handleCreateClick: () => {
          setIsSingleAction(false);
          setIsCreateDialogVisible(true);
        },
      }),
    [createInvoiceResourceGuard, t]
  );

  return (
    <div className="page invoices">
      <HeaderPages
        title={t('Invoices Creation')}
        subtitle={t('Preview invoice candidates and create invoices')}
        icon={faFileInvoice}
        // goBackTitle={t('Invoices Preview')}
        // goBackTo="/invoices"
      />

      <Grid>
        <SidePanels columnLayout={isTwoColumnLayout}>
          <Filters
            client={client}
            dateFrom={dateFrom}
            dateTo={dateTo}
            setClient={setClient}
            setDateFrom={setDateFrom}
            setDateTo={setDateTo}
          />

          <Stats collectionData={data} selectionMultiple={selectionMultiple} />
        </SidePanels>

        <MainContent columnLayout={isTwoColumnLayout}>
          <BreadcrumbsDialog
            index={index}
            setIndex={setIndex}
            visible={isViewDialogVisible}
            onHide={() => setIsViewDialogVisible(false)}
          >
            <BDItem label={itemsLabel}>
              <Items
                dateFrom={dateFrom}
                dateTo={dateTo}
                contextMenuSelection={contextMenuSelection}
                selection={invoiceItemSelection}
                setSelection={setInvoiceItemSelection}
              />
            </BDItem>

            <BDItem label={shipmentsLabel}>
              <Shipments request={viewShipmentsRequest} />
            </BDItem>
          </BreadcrumbsDialog>

          <CreateDialog
            dateFrom={dateFrom}
            dateTo={dateTo}
            selection={
              isSingleAction
                ? contextMenuSelection
                  ? [contextMenuSelection]
                  : []
                : selectionMultiple
            }
            visible={isCreateDialogVisible}
            onHide={() => {
              setIsCreateDialogVisible(false);
              setIsSingleAction(true);
            }}
            createRequest={createInvoice}
          />

          <ViewShipments
            clientID={contextMenuSelection?.client_id}
            dateFrom={dateFrom}
            dateTo={dateTo}
            visible={isViewShipmentsDialogVisible}
            onHide={() => setIsViewShipmentsDialogVisible(false)}
          />

          <Table
            tableRef={tableRef}
            columnOptions={columnOptions}
            columns={columns}
            data={data}
            hasError={!!error}
            filterHeight={10}
            headerTitle={t('Invoice Candidates')}
            isLoading={isLoading}
            rebuildTooltip
            reload={reload}
            isReloadDisabled={!canLoadData}
            rows={limit}
            selectedColumns={selectedColumns}
            selection={selectionMultiple}
            setLimit={setLimit}
            setPage={setPage}
            setSelectedColumns={setSelectedColumns}
            setSelection={setSelectionMultiple}
            setSortField={setSortField}
            setSortOrder={setSortOrder}
            sortField={sortField}
            sortOrder={sortOrder}
            storageString={tableStorageKey}
            selectionMode="multiple"
            selectionPageOnly
            clearSelectionObj={httpFiltersObj}
            onRowDoubleClick={(e: DataTableRowClickEventParams) => {
              setContextMenuSelection(e.data);
              setIsViewDialogVisible(true);
            }}
            contextMenuModel={contextMenuModel}
            groupActionsModel={groupActionsModel}
            contextMenuSelection={contextMenuSelection}
            setContextMenuSelection={setContextMenuSelection}
          />
        </MainContent>
      </Grid>
    </div>
  );
}

export default Invoices;
