import { ColumnProps } from 'primereact/column';
import { TFunction } from 'react-i18next';

import { getProductTypeLabels } from '../../../configs/product';
import { getServiceTypeLabels } from '../../../configs/service';
import { PriceListCollection } from '../../../types/api/priceLists';
import { PriceCollection } from '../../../types/api/prices';
import { ContextMenuItem } from '../../../types/primereact';
import {
  TableColumnHeadersMap,
  TableColumns,
  Unpacked,
} from '../../../types/util';
import { emptyCell, noColumnStyle } from '../../../utils/constants/tables';
import { dateCell } from '../../../utils/helpers/dataTable';
import { withTrailingEllipsis } from '../../../utils/helpers/formatting';
import { contextMenuModel } from '../../../utils/helpers/primereact';
import Tooltip from '../../Misc/Tooltip/Tooltip';

export type PriceListOption = Pick<
  Unpacked<PriceListCollection>,
  'id' | 'name'
>;

export type Row = Unpacked<PriceCollection>;

export type Column = TableColumns<
  Pick<
    Row,
    | 'tariff_name'
    | 'tariff_product_id'
    | 'tariff_service_id'
    | 'price_list_name'
    | 'price'
    | 'created_at'
    | 'created_by_full_name'
    | 'modified_at'
    | 'modified_by_full_name'
  >
>;

export const tableStorageKey = 'prices_dataTable';

export function getColumnHeadersMap(
  t: TFunction
): TableColumnHeadersMap<Column> {
  return {
    no: t('No.'),
    tariff_name: t('Tariff'),
    tariff_product_id: t('Product'),
    tariff_service_id: t('Service'),
    price_list_name: t('Price list'),
    price: t('Price'),
    created_at: t('Created at'),
    created_by_full_name: t('Created by'),
    modified_at: t('Modified at'),
    modified_by_full_name: t('Modified by'),
  };
}

export function getAdditionalColumnProperties(t: TFunction) {
  const productTypeLabels = getProductTypeLabels(t);
  const serviceTypeLabels = getServiceTypeLabels(t);

  return (column: Column): ColumnProps => {
    switch (column) {
      case 'no':
        return noColumnStyle;

      case 'tariff_name':
      case 'price_list_name':
      case 'created_by_full_name':
      case 'modified_by_full_name':
        return {
          style: { width: 250 },
          bodyStyle: { width: 250 },
        };

      case 'price':
        return {
          body: (row: Row) =>
            !!row[column] ? (
              <Tooltip text={row[column]}>
                {withTrailingEllipsis(row[column]!, 0, 34)}
              </Tooltip>
            ) : (
              emptyCell
            ),
          style: { width: 160 },
          bodyStyle: {
            width: 160,
          },
        };

      case 'tariff_product_id':
        return {
          body: (row: Row) => productTypeLabels[row[column]] || emptyCell,
          style: { width: 180 },
          bodyStyle: { width: 180 },
        };

      case 'tariff_service_id':
        return {
          body: (row: Row) => serviceTypeLabels[row[column]] || emptyCell,
          style: { width: 180 },
          bodyStyle: { width: 180 },
        };

      case 'created_at':
      case 'modified_at':
        return {
          body: (row: Row) => dateCell(row[column]),
          style: {
            width: 180,
          },
          bodyStyle: {
            width: 180,
          },
        };
    }
  };
}

export function getContextMenuModel({
  t,
  onEditClick,
  isEditShown,
  onDeleteClick,
  isDeleteShown,
}: {
  t: TFunction;
  isEditShown: boolean;
  onEditClick: () => void;
  isDeleteShown: boolean;
  onDeleteClick: () => void;
}): ContextMenuItem[] {
  const editOption = {
    label: t('Edit price'),
    icon: 'fas fa-edit',
    command: onEditClick,
  };

  const deleteOption = {
    label: t('Delete price'),
    icon: 'fas fa-trash',
    command: onDeleteClick,
  };

  return contextMenuModel([
    [[isEditShown, editOption]],
    [[isDeleteShown, deleteOption]],
  ]);
}
