import { TFunction } from 'react-i18next';

import { InvoiceResource } from '../../../../../../../../types/api/invoices';
import {
  TableColumnHeadersMap,
  TableColumns,
  Unpacked,
} from '../../../../../../../../types/util';
import { noColumnStyle } from '../../../../../../../../utils/constants/tables';
import {
  currencyFormat,
  percentageFormat,
} from '../../../../../../../../utils/helpers/formatting';

type Row = Unpacked<NonNullable<InvoiceResource['data']['items']>>;
export type Column = TableColumns<
  Pick<
    Row,
    | 'invoice_id'
    | 'description'
    | 'price_per_item'
    | 'vat'
    | 'discount'
    | 'price_per_item_discounted'
    | 'vat_per_item_discounted'
    | 'price_and_vat_per_item_discounted'
    | 'amount'
    | 'price_total'
    | 'vat_total'
    | 'price_and_vat_total'
  >
>;

export const tableStorageKey = 'invoicesInvoicePreview_dataTable';

export function getColumnHeadersMap(
  t: TFunction
): TableColumnHeadersMap<Column> {
  return {
    no: t('No.'),
    invoice_id: t('Invoice ID'),
    description: t('Description'),
    price_per_item: t('Price Per Item'),
    vat: t('VAT'),
    discount: t('Discount'),
    price_per_item_discounted: t('Price Per Item Discounted'),
    vat_per_item_discounted: t('VAT Per Item Discounted'),
    price_and_vat_per_item_discounted: t('Price And VAT Per Item Discounted'),
    amount: t('Amount'),
    price_total: t('Price Total'),
    vat_total: t('VAT Total'),
    price_and_vat_total: t('Total'),
  };
}

export function additionalColumnProperties(column: Column) {
  switch (column) {
    case 'no':
      return noColumnStyle;

    case 'vat':
    case 'discount':
      return {
        body: (row: Row) => percentageFormat(row[column]),
        style: { width: 100 },
        bodyStyle: { width: 100 },
      };

    case 'description':
      return {
        style: { width: 250 },
        bodyStyle: { width: 250 },
      };

    case 'price_per_item':
    case 'price_per_item_discounted':
    case 'vat_per_item_discounted':
    case 'price_and_vat_per_item_discounted':
    case 'price_total':
    case 'vat_total':
    case 'price_and_vat_total':
      return {
        body: (row: Row) => currencyFormat(row[column], { showCurrency: true }),
        style:
          column === 'price_and_vat_per_item_discounted'
            ? { width: 280 }
            : { width: 250 },
        bodyStyle:
          column === 'price_and_vat_per_item_discounted'
            ? { width: 280 }
            : { width: 250 },
      };

    default:
      return {
        style: { width: 200 },
        bodyStyle: { width: 200 },
      };
  }
}

export function generateContextMenu(t: TFunction, handleViewClick: () => void) {
  return [
    {
      label: t('View Shipments'),
      icon: 'fas fa-search',
      command: handleViewClick,
    },
  ];
}
