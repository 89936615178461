import { availableCourierCollectionGuard } from './_api/courier';
import {
  assignCourierForDeliveryGuard,
  cancelCourierDeliveryGuard,
  courierSummaryCollectionGuard,
  orderResourceGuard,
  ordersBarcodePrefixesCollectionGuard,
  recieveTransferResourceGuard,
  transferReceptionCollectionGuard,
  transferSummaryCollectionGuard,
} from './_api/orders';

export const loadShipmentGuard = [
  recieveTransferResourceGuard,
  assignCourierForDeliveryGuard,
];
export const cancelShipmentAssignmentGuard = cancelCourierDeliveryGuard;

export const routeGuard = [
  availableCourierCollectionGuard,
  transferReceptionCollectionGuard,
  transferSummaryCollectionGuard,
  courierSummaryCollectionGuard,
  orderResourceGuard,
  ordersBarcodePrefixesCollectionGuard,
];
