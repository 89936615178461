// DBName "reconciliation_status"
export enum ReconciliationStatus {
  Creating = 1,
  WaitingForApproval = 2,
  Approved = 3,
}

export enum ReconciledOrderType {
  Delivered = 1,
  PickedUp = 2,
}
