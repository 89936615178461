import {
  clientCollectionGuard,
  clientLookupCollectionGuard,
  clientResourceGuard,
} from './_api/clients';
import { employeeCollectionGuard } from './_api/employees';
import { hubCollectionGuard, nearestHubCollectionGuard } from './_api/hubs';
import { importListTypeCollectionGuard } from './_api/importLists';
import {
  addOrderCollectionGuard,
  cancelOrderResourceGuard,
  deleteOrderResourceGuard,
  deletedOrderCollectionGuard,
  deletedOrderResourceGuard,
  editOrderResourceGuard,
  exportDeletedOrderResourceGuard,
  orderCalculatorGuard,
  orderCollectionGuard,
  orderDeliveryHistoryCollectionGuard,
  orderExportResourceGuard,
  orderResourceGuard,
  orderStatusCollectionGuard,
  ordersHistoryCollectionGuard,
  ordersPaymentHistoryCollectionGuard,
  parcelStatusCollectionGuard,
  recreateDeletedOrderResourceGuard,
} from './_api/orders';
import {
  placeCollectionGuard,
  placeResourceGuard,
  placesStreetCollectionGuard,
} from './_api/places';
import { specializedProductCollectionGuard } from './_api/specializedProducts';

export const routeGuard = orderCollectionGuard;
export const deletedRouteGuard = deletedOrderCollectionGuard;

export const readOrder = orderResourceGuard;
export const readDeletedOrder = deletedOrderResourceGuard;

export const statusFilter = parcelStatusCollectionGuard;
export const clientFilter = clientLookupCollectionGuard;
export const employeeFilter = employeeCollectionGuard;
export const listTypesFilter = importListTypeCollectionGuard;

export const orderHistory = ordersHistoryCollectionGuard;
export const orderHistoryDelivery = orderDeliveryHistoryCollectionGuard;
export const orderHistoryPayment = ordersPaymentHistoryCollectionGuard;

export const statusTracking = orderStatusCollectionGuard;
export const recreate = recreateDeletedOrderResourceGuard;
export const exportOrders = orderExportResourceGuard;
export const exportDeletedOrders = exportDeletedOrderResourceGuard;

const createEditCommon = [
  hubCollectionGuard,
  orderCalculatorGuard,
  specializedProductCollectionGuard,
  clientCollectionGuard,
  clientResourceGuard,
  placeResourceGuard,
  placesStreetCollectionGuard,
  nearestHubCollectionGuard,
  placeCollectionGuard,
];

export const createOrder = [...createEditCommon, addOrderCollectionGuard];
export const editOrder = [...createEditCommon, editOrderResourceGuard];
export const deleteOrder = deleteOrderResourceGuard;
export const cancelOrder = cancelOrderResourceGuard;
