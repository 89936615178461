import _ from 'lodash';
import { DropdownProps } from 'primereact/dropdown';
import { ReactNode } from 'react';
import { $enum } from 'ts-enum-util';

import { Country } from '../../enums/countries';
import store from '../../redux/store';

export function isDevEnv(): boolean {
  return (
    process.env.NODE_ENV === 'development' ||
    process.env.REACT_APP_CLIENT_NAME === 'koder' ||
    process.env.REACT_APP_CLIENT_NAME === 'demo' ||
    !!store.getState().user.employee?.data.ulogi.find((r) => r.id === 50)
  );
}

export function getCountryString(): Country {
  return $enum(Country).asValueOrDefault(
    process.env.REACT_APP_COUNTRY,
    Country.Macedonia
  );
}

export function httpQueryObject(obj: Record<string, any>): Record<string, any> {
  const keysToPick = Object.keys(obj).filter((k) => {
    const value = obj[k];

    if (typeof value === 'number') {
      return !isNaN(value);
    }

    return !!value;
  });

  return _.pick(obj, keysToPick);
}

export function tabViewIndexFromLocation(
  pathname: string,
  baseRoute: string,
  tabPaths: string[]
) {
  const currentTabPath = pathname.replace(`${baseRoute}/`, '');

  const currentTabIndex = tabPaths.findIndex((r) => r === currentTabPath);

  return currentTabIndex === -1 ? 0 : currentTabIndex;
}

export function nonEmptyObjectsOnly<T extends object = object>(
  objectsArr: Array<T | {}>
): T[] {
  return objectsArr.filter((o): o is T => Object.keys(o).length > 0);
}

export function copyToClipboard(
  text: string,
  onSuccess: () => void,
  onError: () => void
): void {
  // Modern browsers implementation
  // @ts-ignore
  if (navigator?.clipboard?.writeText) {
    navigator.clipboard.writeText(text).then(onSuccess).catch(onError);
    return;
  }

  // Fallback solution
  if (
    document.queryCommandSupported &&
    document.queryCommandSupported('copy')
  ) {
    const textArea = document.createElement('textarea');
    // Place in the top-left corner of screen regardless of scroll position.
    // It prevents scrolling to bottom of the page in Microsoft Edge too
    textArea.style.position = 'fixed';
    textArea.style.top = '0px';
    textArea.style.left = '0px';

    // Ensure it has a small width and height. Setting to 1px / 1em
    //  doesn't work as this gives a negative w/h on some browsers
    textArea.style.width = '2em';
    textArea.style.height = '2em';

    // We don't need padding, reducing the size if it does flash render
    textArea.style.padding = '0px';

    // Clean up any borders
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';

    // Avoid flash of the white box if rendered for any reason
    textArea.style.background = 'transparent';

    textArea.value = text;

    document.body.appendChild(textArea);

    textArea.focus();
    textArea.select();

    try {
      // Security exception may be thrown by some browsers
      const isSuccessful = document.execCommand('copy');

      if (isSuccessful) {
        onSuccess();
      } else {
        onError();
      }
    } catch (e) {
      // Please don't log this error to Sentry as it does not indicate anything wrong with the app per se
      onError();
    }

    document.body.removeChild(textArea);
    return;
  }

  // Internet Explorer level fallback
  // @ts-ignore
  if (window?.clipboardData?.setData) {
    // @ts-ignore
    window.clipboardData.setData('Text', text);
    return;
  }

  // If all else fails...
  onError();
}

export function placeItemTemplate(option: {
  label: string;
  postal_code: string | number | null;
}): string {
  return option.postal_code
    ? `${option.label} (${option.postal_code})`
    : option.label;
}

export function placeValueTemplate(
  option: {
    label: string;
    postal_code: string | number | null;
  } | null,
  props: DropdownProps
): ReactNode {
  if (!option) {
    if (props.placeholder) {
      return <span>{props.placeholder}</span>;
    }

    // Primereact's Dropdown component has a CSS bug when this element is empty
    return <span style={{ visibility: 'hidden' }}>empty</span>;
  }

  return option.postal_code
    ? `${option.label} (${option.postal_code})`
    : option.label;
}
