import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { UseAxiosReturn } from '../../../../../../../hooks/useAxiosHook';
import useDialogVisibility from '../../../../../../../hooks/useDialogVisibility';
import { useEndpointGuard } from '../../../../../../../hooks/useEndpointGuard';
import useTableColumns from '../../../../../../../hooks/useTableColumns';
import { UseTableStateReturn } from '../../../../../../../hooks/useTableState';
import {
  CourierReceptionReturnedCollection,
  CourierSummaryCollection,
} from '../../../../../../../types/api/orders';
import {
  receiveShipmentGuard,
  undoReceivedShipmentGuard,
  updateReturnedShipmentGuard,
} from '../../../../../../../utils/constants/auth/courierShipmentReception';
import { sequential } from '../../../../../../../utils/helpers/functions';
import { tryInt } from '../../../../../../../utils/helpers/parse';
import Table from '../../../../../../DataTable/Table/Table';
import EditDialog from './Dialogs/EditDialog/EditDialog';
import {
  Row,
  additionalColumnOptions,
  generateContextMenu,
  getColumnHeadersMap,
  getRowClassName,
  tableStorageKey,
} from './ReturnedShipments.functions';

type Props = {
  isDateValid: boolean;
  courierWorkOrderId: number | undefined;
  request: UseAxiosReturn<CourierReceptionReturnedCollection>;
  statsRequest: UseAxiosReturn<CourierSummaryCollection>;
  tableState: UseTableStateReturn<Row>;
  cancelCallback: (orderID: Row['order_id']) => void;
  showOrderDialog: () => void;
  receptionCallback: () => void;
  shouldDisplayData: boolean;
};

function ReturnedShipments({
  isDateValid,
  courierWorkOrderId,
  request,
  statsRequest,
  tableState,
  cancelCallback,
  showOrderDialog,
  receptionCallback,
  shouldDisplayData,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const columnHeadersMap = useMemo(() => getColumnHeadersMap(t), [t]);

  const receiveGuard = useEndpointGuard(receiveShipmentGuard);
  const editGuard = useEndpointGuard(updateReturnedShipmentGuard);
  const cancelGuard = useEndpointGuard(undoReceivedShipmentGuard);

  const {
    isVisible: isEditDialogVisible,
    show: showEditDialog,
    hide: hideEditDialog,
  } = useDialogVisibility();

  const {
    page,
    limit,
    tableRef,
    sortField,
    sortOrder,
    selection,
    setPage,
    setLimit,
    setSortField,
    setSortOrder,
    setSelection,
  } = tableState;

  const { columns, columnOptions, selectedColumns, setSelectedColumns } =
    useTableColumns(
      page,
      limit,
      'warehouse_courierShipmentReceptionReturnedShipments',
      columnHeadersMap,
      columnHeadersMap,
      additionalColumnOptions(t)
    );

  const { data, error, isLoading, reload } = request;

  const contextMenuModel = useMemo(() => {
    function handleDeleteCMClick() {
      if (!selection) {
        return;
      }

      cancelCallback(selection?.order_id);
    }

    const isReceiveShown =
      isDateValid && receiveGuard && !!tryInt(selection?.is_candidate);

    const canBeModified = isDateValid && !!tryInt(selection?.editable);

    return generateContextMenu({
      t,
      onViewClick: showOrderDialog,
      isReceiveShown,
      onReceiveClick: receptionCallback,
      isEditShown: editGuard && canBeModified,
      onEditClick: showEditDialog,
      isCancelShown: cancelGuard && canBeModified,
      onCancelClick: handleDeleteCMClick,
    });
  }, [
    cancelCallback,
    cancelGuard,
    editGuard,
    isDateValid,
    receiveGuard,
    receptionCallback,
    selection,
    showEditDialog,
    showOrderDialog,
    t,
  ]);

  return (
    <>
      <EditDialog
        courierWorkOrderId={courierWorkOrderId}
        selection={selection}
        onHide={hideEditDialog}
        visible={isEditDialogVisible}
        tableReload={() => sequential(reload, statsRequest.reload)}
      />

      <Table
        tableRef={tableRef}
        columns={columns}
        data={shouldDisplayData ? data : undefined}
        isLoading={isLoading}
        hasError={!!error}
        reload={() => sequential(reload, statsRequest.reload)}
        isReloadDisabled={!shouldDisplayData}
        setPage={setPage}
        setLimit={setLimit}
        sortField={sortField}
        rows={limit}
        setSortField={setSortField}
        setSortOrder={setSortOrder}
        setSelection={setSelection}
        sortOrder={sortOrder}
        selection={selection}
        storageString={tableStorageKey}
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        columnOptions={columnOptions}
        headerTitle={t('Returned Shipments')}
        onRowDoubleClick={showOrderDialog}
        contextMenuModel={contextMenuModel}
        rowClassName={getRowClassName}
        rebuildTooltip
      />
    </>
  );
}

export default ReturnedShipments;
