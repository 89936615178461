import {
  faBan,
  faCheckCircle,
  faChevronCircleRight,
  faQuestion,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TFunction } from 'i18next';
import _ from 'lodash';
import { Dispatch, SetStateAction } from 'react';

import { ReduxState } from '../../../types/redux';
import { noColumnStyle } from '../../../utils/constants/tables';
import { formatPhoneOrMobileNumber } from '../../../utils/helpers/phoneNumbers';
import ActionsTableMenu from '../Components/ActionsTableMenu/ActionsTableMenu';

export const tableStorageKey = 'employees_dataTable';

export function getColumnHeadersMap(t: TFunction) {
  return {
    no: t('No.'),
    aktiven: t('Status'),
    ime: t('Name'),
    prezime: t('Surname'),
    username: t('Email (username)'),
    ulogi: t('Roles'),
    mesto_ime: t('Place'),
    kontakt: t('Contact'),
    komentar: t('Comment'),
    hub_ime: t('Native warehouse'),
    hubs: t('Warehouses'),
  };
}

export function getEmployeeActiveStatusIconMap(t: TFunction) {
  return {
    0: { label: t('Inactive'), icon: faBan },
    1: { label: t('Active'), icon: faCheckCircle },
  };
}

export function additionalColumnProperties(
  t: TFunction,
  column: keyof ReturnType<typeof getColumnHeadersMap> | 'actions',
  setContextMenuSelection: Dispatch<SetStateAction<any>>,
  setAction: (t: string) => void,
  setCaller: Dispatch<SetStateAction<'context-menu' | 'group-actions'>>,
  isViewShown: boolean,
  isEditShown: boolean,
  isStatusUpdateShown: boolean,
  isQrCodeShown: boolean,
  user: ReduxState['user']
) {
  const employeeActiveStatusIconMap: any = getEmployeeActiveStatusIconMap(t);

  switch (column) {
    case 'no':
      return noColumnStyle;
    case 'aktiven':
      return {
        body: (row: any) => {
          const status = employeeActiveStatusIconMap[parseInt(row?.aktiven)];

          return (
            <span
              data-tip={status?.label || 'Unknown'}
              data-for="tooltip-right"
            >
              <FontAwesomeIcon icon={status?.icon || faQuestion} />
            </span>
          );
        },
        style: {
          width: 60,
        },
        bodyStyle: {
          width: 60,
          textAlign: 'center',
        },
      };

    case 'ulogi':
      return {
        body: (row: any) =>
          row.ulogi?.length > 0 ? (
            row.ulogi.length === 1 ? (
              row.ulogi[0].ime
            ) : (
              <span
                data-tip={_.map(row.ulogi, 'ime').join('<br />')}
                data-for="tooltip-right"
              >
                {`${row.ulogi[0].ime} ... `}
                <FontAwesomeIcon
                  icon={faChevronCircleRight}
                  className="secondary-icon"
                />
              </span>
            )
          ) : (
            ''
          ),
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
        },
      };

    case 'hubs':
      return {
        body: (row: any) =>
          row.hubs?.length > 0 ? (
            row.hubs.length === 1 ? (
              row.hubs[0].ime
            ) : (
              <span
                data-tip={_.map(row.hubs, 'ime').join('<br />')}
                data-for="tooltip-right"
              >
                {`${row.hubs[0].ime} ... `}
                <FontAwesomeIcon
                  icon={faChevronCircleRight}
                  className="secondary-icon"
                />
              </span>
            )
          ) : (
            ''
          ),
        style: {
          width: 240,
        },
        bodyStyle: {
          width: 240,
        },
      };

    case 'kontakt':
      return {
        body: (row: any) => {
          // Escape gibberish values
          //  and show only real phone numbers and other contact info
          const contactArr = row.kontakt
            ?.map((k: any) => k.trim())
            ?.filter((k: any) => k.length > 2);

          return (
            contactArr.length > 0 &&
            (contactArr.length === 1 ? (
              formatPhoneOrMobileNumber(contactArr[0])
            ) : (
              <span
                data-tip={contactArr.join('<br />')}
                data-for="tooltip-right"
              >
                {`${formatPhoneOrMobileNumber(contactArr[0])} ... `}

                <FontAwesomeIcon
                  icon={faChevronCircleRight}
                  className="secondary-icon"
                />
              </span>
            ))
          );
        },
        style: {
          width: 120,
        },
        bodyStyle: {
          width: 120,
        },
      };

    case 'mesto_ime':
      return {
        style: {
          width: 100,
        },
        bodyStyle: {
          width: 100,
        },
      };

    case 'ime':
    case 'prezime':
    case 'hub_ime':
      return {
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
        },
      };

    case 'username':
    case 'komentar':
      return {
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
        },
      };

    case 'actions':
      return {
        body: (row: any) => {
          const employeeRoleIds =
            row?.ulogi?.map((r: any) => parseInt(r.id)) ?? [];
          const isEmployeeAdmin = employeeRoleIds.includes(1);
          const isEmployeeSuperAdmin = employeeRoleIds.includes(50);

          const isUserAdmin = !!user.employee?.data.ulogi?.find(
            (r: any) => r.id === '1'
          );
          const isUserSuperAdmin = !!user.employee?.data.ulogi?.find(
            (r: any) => r.id === '50'
          );

          const isCourierAppShown = employeeRoleIds.includes(3);
          const isWarehouseAppShown = employeeRoleIds.includes(4);

          return (
            <ActionsTableMenu
              actions={[
                {
                  label: t('View'),
                  icon: 'fas fa-search',
                  disabled: !isViewShown,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('view-details');
                    setCaller('context-menu');
                  },
                },
                {
                  label: t('Edit'),
                  icon: 'fas fa-edit',
                  disabled: !isEditShown,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('edit');
                    setCaller('context-menu');
                  },
                },
                {
                  label: t('Activate'),
                  icon: 'fas fa-check-circle',
                  disabled: !row?.aktiven && !isStatusUpdateShown,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('activate');
                    setCaller('context-menu');
                  },
                },
                {
                  label: t('Deactivate'),
                  icon: 'fas fa-ban',
                  disabled: !row?.aktiven && !isStatusUpdateShown,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('deactivate');
                    setCaller('context-menu');
                  },
                },
                {
                  label: 'Accura Courier App',
                  icon: 'fas fa-qrcode',
                  disabled:
                    !isQrCodeShown &&
                    (!isEmployeeAdmin ||
                      !isEmployeeSuperAdmin ||
                      !isCourierAppShown),
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('qr-code-courier');
                    setCaller('context-menu');
                  },
                },
                {
                  label: 'Accura Warehouse App',
                  icon: 'fas fa-qrcode',
                  disabled:
                    !isQrCodeShown &&
                    (!isEmployeeAdmin ||
                      !isEmployeeSuperAdmin ||
                      !isWarehouseAppShown),
                  command: () => {
                    setContextMenuSelection(row);
                    setCaller('context-menu');
                    setAction('qr-code-warehouse');
                  },
                },
                {
                  label: t('Force set password'),
                  icon: 'fas fa-key',
                  disabled:
                    !isUserSuperAdmin || (!isUserAdmin && isEmployeeSuperAdmin),
                  command: () => {
                    setContextMenuSelection(row);
                    setCaller('context-menu');
                    setAction('force-password');
                  },
                },
              ]}
            />
          );
        },
        style: {
          width: 60,
          borderLeft: '1px solid #e9ecef',
          flex: '0 1 auto',
          position: 'sticky',
        },
        bodyStyle: {
          width: 60,
          borderLeft: '1px solid #e9ecef',
          flex: '0 1 auto',
          position: 'sticky',
          justifyContent: 'center',
        },
      };
    default:
      return {};
  }
}
