import './FieldWithErrors.scss';

import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import _ from 'lodash';
import { ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';

import Tooltip from '../../Misc/Tooltip/Tooltip';

type Props<T> = {
  name: keyof T;
  children: ReactNode;
  childrenWrapperClassName?: string;
  label?: ReactNode | false | null;
  tooltip?: string;
  labelClassName?: string;
  className?: string;
};

function FieldWithErrors<T extends object>({
  name,
  children,
  childrenWrapperClassName,
  label,
  tooltip,
  labelClassName,
  className,
}: Props<T>): JSX.Element {
  const {
    formState: { errors },
  } = useFormContext<T>();

  const hasError = !!(errors as any)[name];
  const errorMessage = (errors as any)[name]?.message;

  const parentClassName = classNames(
    'p-field rhf-field-with-errors',
    {
      invalid: hasError,
    },
    className
  );

  return (
    <div className={parentClassName}>
      {label !== false && (
        <>
          <label htmlFor={String(name)} className={labelClassName}>
            {label || _.capitalize(String(name).replace(/_/g, ' '))}
          </label>

          {tooltip && (
            <Tooltip text={tooltip} className="p-ml-1">
              <FontAwesomeIcon icon={faInfoCircle} className="secondary-icon" />
            </Tooltip>
          )}
        </>
      )}

      <div className={childrenWrapperClassName}>{children}</div>

      {hasError && typeof errorMessage === 'string' && (
        <span className="error">{errorMessage}</span>
      )}
    </div>
  );
}

export default FieldWithErrors;
