import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { useEndpointGuard } from '../../../../hooks/useEndpointGuard';
import useSearchQueryDateParam from '../../../../hooks/useSearchQueryDateParam';
import useSearchQueryDropdownParam from '../../../../hooks/useSearchQueryDropdownParam';
import * as couriersCashGuards from '../../../../utils/constants/auth/couriersCash';
import SidebarItem from '../../../Grid/Sidebar/SidebarItem';

type Props = {
  date: Date;
  courier: number | null;
  request: {
    data: { id: number; name: string }[] | undefined;
    isLoading: boolean;
  };
  setDate: Dispatch<SetStateAction<Date>>;
  setCourier: Dispatch<SetStateAction<number | null>>;
};

function Filters({
  date,
  courier,
  request,
  setDate,
  setCourier,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const courierFilterGuard = useEndpointGuard(couriersCashGuards.courierFilter);

  const { data, isLoading } = request;

  // Map couriers to dropdown options
  const couriers =
    data?.map((employee) => ({
      label: employee.name,
      value: employee.id,
    })) ?? [];

  // Manage url query params
  useSearchQueryDateParam('date', date);
  useSearchQueryDropdownParam(
    'courier_id',
    courier ? String(courier) : null,
    setCourier,
    couriers
  );

  return (
    <SidebarItem template="filters">
      <div className="p-field">
        <label htmlFor="date">{t('Date')}</label>
        <Calendar
          value={date}
          dateFormat="dd/mm/yy"
          maxDate={new Date()}
          inputId="date"
          readOnlyInput
          onChange={(e) => setDate(e.value as Date)}
          disabled={isLoading}
        />
      </div>

      {courierFilterGuard && (
        <div className="p-field">
          <label htmlFor="courier">{t('Courier')}</label>
          <Dropdown
            options={couriers}
            value={courier}
            onChange={(e) => setCourier(e.value)}
            disabled={isLoading || couriers.length === 0}
            showClear
            filter
            placeholder={
              isLoading
                ? t('Loading...')
                : couriers.length === 0
                ? t('No couriers found')
                : t('Select a courier')
            }
          />
        </div>
      )}
    </SidebarItem>
  );
}

export default Filters;
