import { faMinus } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Calendar } from 'primereact/calendar';
import { Dropdown, DropdownChangeParams } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Dispatch, useMemo } from 'react';
import { useState } from 'react';
import { SetStateAction } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDebounce } from 'use-lodash-debounce';

import useAxiosHook from '../../../hooks/useAxiosHook';
import useHaveValuesChanged from '../../../hooks/useHaveValuesChanged';
import useSearchQueryDateParam from '../../../hooks/useSearchQueryDateParam';
import useSearchQueryDropdownParam from '../../../hooks/useSearchQueryDropdownParam';
import useSearchQueryParam from '../../../hooks/useSearchQueryParam';
import { EmployeeCollection } from '../../../types/api/employees';
import { debounceTimeout } from '../../../utils/constants/misc';
import { httpDateFormat } from '../../../utils/helpers/formatting';
import { renderIcon } from '../../../utils/helpers/icon';
import {
  getSearchQueryParam,
  tryDateSearchParam,
} from '../../../utils/helpers/searchQuery';
import FiltersCounter from '../Components/Filters/FiltersCounter';

function useTableFilters(
  page: number,
  setPage: Dispatch<SetStateAction<number>>,
  limit: number
) {
  const { t } = useTranslation();
  const location = useLocation();
  const [dateFrom, setDateFrom] = useState<Date | null>(() => {
    return (
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'date_from') ?? ''
      ) ?? new Date()
    );
  });
  const [dateTo, setDateTo] = useState<Date | null>(() => {
    return (
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'date_to') ?? ''
      ) ?? new Date()
    );
  });
  const [userId, setUserId] = useState<string | null>(
    () => getSearchQueryParam(location.search, 'user_id') ?? null
  );
  const [message, setMessage] = useState<string>(
    () => getSearchQueryParam(location.search, 'message') ?? ''
  );

  const { data: employeeData } = useAxiosHook<EmployeeCollection>('/employees');

  const employeeOptions = useMemo(
    () =>
      employeeData?.data?.map((e) => ({
        label: `${e.ime} ${e.prezime}`,
        value: e.id,
      })) ?? [],
    [employeeData?.data]
  );

  const debouncedMessage = useDebounce(message, debounceTimeout);

  const filtersArr = useMemo(
    () => [dateFrom, dateTo, userId, debouncedMessage],
    [dateFrom, dateTo, userId, debouncedMessage]
  );

  const haveFiltersChanged = useHaveValuesChanged(filtersArr);

  useEffect(() => {
    setPage(1);
  }, [setPage, filtersArr]);

  useSearchQueryDateParam('date_from', dateFrom);
  useSearchQueryDateParam('date_to', dateTo);
  useSearchQueryParam('message', debouncedMessage);
  useSearchQueryDropdownParam('user_id', userId, setUserId, employeeOptions);

  const httpFiltersObj = useMemo(
    () => ({
      date_from: httpDateFormat(dateFrom as any),
      date_to: httpDateFormat(dateTo as any),
      user_id: userId,
      message: debouncedMessage,
      page: haveFiltersChanged ? 1 : page,
      limit,
    }),
    [
      dateFrom,
      dateTo,
      userId,
      debouncedMessage,
      haveFiltersChanged,
      limit,
      page,
    ]
  );

  const basicFiltersActiveFilterCount = Object.values({
    dateFrom, // date_from and date_to is count like one
    userId,
    message,
  }).filter(Boolean).length;

  const filters = useMemo<JSX.Element>(
    () => (
      <Accordion multiple activeIndex={[0]}>
        <AccordionTab
          disabled
          headerTemplate={
            <FiltersCounter
              description={t('Basic filters')}
              counter={basicFiltersActiveFilterCount}
            />
          }
        >
          <div className="sidebar_filter">
            <label htmlFor="filter_date_from">{t('Created Date')}</label>
            <div className="sidebar_filter_row">
              <Calendar
                inputId="filter_date_from"
                value={dateFrom ?? undefined}
                onChange={(e) => {
                  if (e.value) setDateFrom(e.value as Date | null);
                }}
                dateFormat="dd/mm/yy"
                maxDate={new Date()}
                monthNavigator
                yearNavigator
                yearRange={`2009:${dayjs().format('YYYY')}`}
                inputClassName={classNames({
                  sidebar_filter_active: dateFrom,
                })}
              />
              <span style={{ margin: 'auto' }}>{renderIcon(faMinus)}</span>
              <Calendar
                inputId="filter_date_to"
                value={dateTo ?? undefined}
                onChange={(e) => {
                  if (e.value) setDateTo(e.value as Date | null);
                }}
                dateFormat="dd/mm/yy"
                maxDate={new Date()}
                monthNavigator
                yearNavigator
                yearRange={`2009:${dayjs().format('YYYY')}`}
                inputClassName={classNames({
                  sidebar_filter_active: dateTo,
                })}
              />
            </div>
          </div>

          <div className="sidebar_filter">
            <label htmlFor="filter_employee">{t('Employee')}</label>
            <Dropdown
              id="filter_employee"
              options={employeeOptions}
              value={userId}
              filter
              showClear
              placeholder={t('All')}
              onChange={(e: DropdownChangeParams) => setUserId(e.value)}
              className={classNames({
                sidebar_filter_active: userId,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="filter_message">{t('Message')}</label>
            <InputText
              id="filter_message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className={classNames({
                sidebar_filter_active: message,
              })}
            />
          </div>
        </AccordionTab>
      </Accordion>
    ),
    [
      dateFrom,
      dateTo,
      userId,
      employeeOptions,
      message,
      basicFiltersActiveFilterCount,
      t,
    ]
  );

  const headerFiltersCount = useMemo(
    () => basicFiltersActiveFilterCount,
    [basicFiltersActiveFilterCount]
  );

  function resetAllFilters() {
    setDateFrom(new Date());
    setDateTo(new Date());
    setUserId(null);
    setMessage('');
  }

  return {
    filters,
    resetAllFilters,
    httpFiltersObj,
    headerFiltersCount,
  };
}

export default useTableFilters;
