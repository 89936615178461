import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';

import useAxiosHook from '../../../../../../../hooks/useAxiosHook';
import useMediaQuery from '../../../../../../../hooks/useMediaQuery';
import {
  InvoiceCandidatesShipmentCollection,
  InvoiceCandidatesShipmentCollectionQueryParams,
} from '../../../../../../../types/api/invoices';
import { httpDateFormat } from '../../../../../../../utils/helpers/formatting';
import { queryString } from '../../../../../../../utils/helpers/http';
import Shipments from '../Content/Shipments/Shipments';

type Props = {
  clientID: number | undefined;
  dateFrom: Date;
  dateTo: Date;
  visible: boolean;
  onHide: () => void;
};

function ViewShipments({
  clientID,
  dateFrom,
  dateTo,
  visible,
  onHide,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const request = useAxiosHook<InvoiceCandidatesShipmentCollection>(
    '/invoices/candidates/shipments' +
      queryString<InvoiceCandidatesShipmentCollectionQueryParams>({
        client_id: clientID!,
        date_from: httpDateFormat(dateFrom),
        date_to: httpDateFormat(dateTo),
      }),
    {
      skipWhen: !clientID || !visible,
    }
  );

  const header = t('View Shipments');
  const footer = <Button type="button" label={t('Close')} onClick={onHide} />;

  const isOnMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Dialog
      header={header}
      footer={footer}
      visible={visible}
      onHide={onHide}
      maximizable
      maximized={isOnMobile}
      style={{ width: '90%' }}
    >
      <Shipments request={request} />
    </Dialog>
  );
}

export default ViewShipments;
