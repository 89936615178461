import classNames from 'classnames';
import { useFormikContext } from 'formik';

function useErrorState<T>(name: keyof T) {
  const { errors, touched } = useFormikContext<T>();

  const hasError = !!errors[name] && !!touched[name];

  const parentClassName = classNames('field-with-errors', {
    invalid: hasError,
  });

  const errorMessage = errors[name];

  const error: JSX.Element | undefined = hasError ? (
    <span className="error">
      {typeof errorMessage === 'string'
        ? errorMessage
        : Array.isArray(errorMessage)
        ? errorMessage.join()
        : String(errorMessage)}
    </span>
  ) : undefined;

  return { error, parentClassName };
}

export default useErrorState;
