import { ProgressBar } from 'primereact/progressbar';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { OrderStatus } from '../../../../../../enums/orders';
import useMediaQuery from '../../../../../../hooks/useMediaQuery';
import { SingleOrder } from '../../../Orders.functions';
import { StatusTrackingApiData } from '../StatusTrackingDialog.functions';

type Props = {
  data: SingleOrder | undefined;
  orderStatusTrackingData: StatusTrackingApiData | undefined;
};

function OrderStatusProgressBar({
  data,
  orderStatusTrackingData,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const isOnMobile = useMediaQuery('(max-width: 768px)');

  const progressBarValue = useMemo(() => {
    if (!data?.seriski_broj) {
      return 0;
    }

    const currentOrderStatus =
      orderStatusTrackingData?.[data?.seriski_broj]?.current?.code;

    if (currentOrderStatus) {
      if (currentOrderStatus === OrderStatus.Cancelled) {
        return 0;
      } else if (currentOrderStatus < 21) {
        return 25;
      } else if (
        currentOrderStatus > OrderStatus.CreatedGroupOrder &&
        currentOrderStatus < OrderStatus.HandedOverToARelocationCourier
      ) {
        return 50;
      } else if (
        currentOrderStatus > 49 &&
        currentOrderStatus < OrderStatus.Delivered
      ) {
        return 75;
      } else if (currentOrderStatus > 79) {
        return 100;
      }
    }

    return -1;
  }, [data?.seriski_broj, orderStatusTrackingData]);

  return (
    <>
      <ProgressBar value={progressBarValue} showValue={false} />

      {isOnMobile ? (
        <div className="p-mt-2 p-text-center">
          {progressBarValue === 0
            ? t('Cancelled.SHE')
            : progressBarValue === 25
            ? t('Created')
            : progressBarValue === 50
            ? t('Preparing')
            : progressBarValue === 75
            ? t('Shipped')
            : t('Delivered')}
        </div>
      ) : progressBarValue > 0 ? (
        <div className="p-grid p-justify-end p-align-end">
          <span
            className="p-col p-text-right subtitle"
            style={{ fontWeight: progressBarValue === 25 ? 600 : 300 }}
          >
            {t('Created')}
          </span>
          <span
            className="p-col p-text-right subtitle"
            style={{ fontWeight: progressBarValue === 50 ? 600 : 300 }}
          >
            {t('Preparing')}
          </span>
          <span
            className="p-col p-text-right subtitle"
            style={{ fontWeight: progressBarValue === 75 ? 600 : 300 }}
          >
            {t('Shipped')}
          </span>
          <span
            className="p-col p-text-right subtitle"
            style={{ fontWeight: progressBarValue === 100 ? 600 : 300 }}
          >
            {t('Delivered')}
          </span>
        </div>
      ) : (
        <div className="p-grid">
          <span className="p-col subtitle" style={{ fontWeight: 600 }}>
            {t('Cancelled.SHE')}
          </span>
        </div>
      )}
    </>
  );
}

export default OrderStatusProgressBar;
