import {
  createOAuthClientResourceGuard,
  deleteOAuthClientResourceGuard,
  oAuthClientCollectionGuard,
  oAuthClientResourceGuard,
  updateOAuthClientResourceGuard,
} from './_api/oAuthClients';

const readAll = oAuthClientCollectionGuard;
export const readCredential = oAuthClientResourceGuard;

export const newCredential = createOAuthClientResourceGuard;
export const emailCredential = createOAuthClientResourceGuard;

export const editCredential = [readCredential, updateOAuthClientResourceGuard];

export const deleteCredential = [
  readCredential,
  deleteOAuthClientResourceGuard,
];

export const routeGuard = [readAll, readCredential];
