import { InputText } from 'primereact/inputtext';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useAxiosHook from '../../../../../hooks/useAxiosHook';
import useSearchQueryAutoCompleteInputParam from '../../../../../hooks/useSearchQueryAutoCompleteInputParam';
import {
  ClientLookupCollection,
  ClientLookupCollectionQueryParams,
  ClientResource,
} from '../../../../../types/api/clients';
import { LabelValue } from '../../../../../types/options';
import { identity } from '../../../../../utils/helpers/functions';
import { queryString } from '../../../../../utils/helpers/http';
import AutoCompleteInput from '../../../../Forms/AutoCompleteInput/AutoCompleteInput';
import SidebarItem from '../../../../Grid/Sidebar/SidebarItem';
import { ClientFilterOption } from '../HandoverToCustomer.types';

type Props = {
  clientFilter: string;
  setClientFilter: Dispatch<SetStateAction<string>>;
  clientFilterObj: ClientFilterOption | null;
  setClientFilterObj: Dispatch<SetStateAction<ClientFilterOption | null>>;
  barcodeFilter: string;
  setBarcodeFilter: Dispatch<SetStateAction<string>>;
};

function Filters({
  clientFilter,
  setClientFilter,
  clientFilterObj,
  setClientFilterObj,
  barcodeFilter,
  setBarcodeFilter,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const { data, isLoading } = useAxiosHook<ClientLookupCollection>(
    '/clients/lookup' +
      queryString<ClientLookupCollectionQueryParams>({
        page: 1,
        limit: 15,
        ime: clientFilter,
      })
  );

  const clientOptions = useMemo<LabelValue<ClientFilterOption>[]>(
    () => data?.data.map((r) => ({ label: r.ime, value: r })) ?? [],
    [data?.data]
  );

  useSearchQueryAutoCompleteInputParam<ClientFilterOption, ClientResource>({
    param: 'client',
    filterValue: clientFilter,
    setFilterValue: setClientFilter,
    filterValueObj: clientFilterObj,
    setFilterValueObj: setClientFilterObj,
    axiosRequestConfig: (clientId) => `/clients/${clientId}`,
    initialFilterValueDataPath: 'ime',
    filterValueObjPath: 'id',
    initialDataModifier: identity,
  });

  return (
    <SidebarItem template="filters">
      <div className="p-field">
        <label htmlFor="filter_client">{t('Customer')}</label>

        <AutoCompleteInput
          id="filter_client"
          filterValue={clientFilter}
          value={clientFilterObj}
          options={clientOptions}
          onFilterChange={setClientFilter}
          onSelectionChange={setClientFilterObj}
          placeholder={
            isLoading
              ? t('Loading...')
              : !clientOptions.length
              ? t('No options available')
              : t('Search by name')
          }
          showClear
        />
      </div>

      <div className="p-field">
        <label htmlFor="filter_barcode">{t('Barcode')}</label>

        <InputText
          id="filter_barcode"
          value={barcodeFilter}
          onChange={(e) => setBarcodeFilter(e.target.value)}
        />
      </div>
    </SidebarItem>
  );
}

export default Filters;
