import { useSelector } from 'react-redux';
import { Redirect, RouteProps } from 'react-router-dom';

import { useEndpointGuard } from '../../hooks/useEndpointGuard';
import { ReduxState } from '../../types/redux';
import { RoutePaths } from '../../utils/constants/routePaths';
import { EndpointGuard } from '../../utils/helpers/auth';
import SentryRoute from './SentryRoute';

type Props = RouteProps & {
  guard?: EndpointGuard;
};

function AuthRoute({ guard = '', ...routeProps }: Props): JSX.Element {
  const hasUserAccess = useEndpointGuard(guard);

  const isUserLoggedIn = useSelector<ReduxState>(
    (state) => state.user.isLoggedIn
  );

  return isUserLoggedIn && (guard ? hasUserAccess : true) ? (
    <SentryRoute {...routeProps} />
  ) : (
    <Redirect to={RoutePaths.Login} />
  );
}

export default AuthRoute;
