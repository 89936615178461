export const regionCollectionGuard = 'GET /regions';
export const addRegionCollectionGuard = 'POST /regions';
export const editRegionResourceGuard = 'PUT /regions/d+';
export const deleteRegionResourceGuard = 'DELETE /regions/d+';

export const regionCourierCollectionGuard = 'GET /regions/couriers';

export const assignCourierToRegionResourceGuard = 'POST /regions/d+/couriers';
export const editCourierToRegionResourceGuard = 'PUT /regions/d+/couriers/d+';
export const deleteCourierToRegionResourceGuard =
  'DELETE /regions/d+/couriers/d+';
