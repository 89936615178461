import { faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import L from 'leaflet';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import useAxiosHook from '../../../hooks/useAxiosHook';
import usePageTitle from '../../../hooks/usePageTitle';
import { CouriersLocationCollection } from '../../../types/api/tracking';
import { defaultTileLayerProvider } from '../../../utils/constants/map';
import { httpDateFormat } from '../../../utils/helpers/formatting';
import { getMapCoordinates, getMapZoom } from '../../../utils/helpers/map';
import { getSearchQueryParam } from '../../../utils/helpers/searchQuery';
import HeaderPages from '../Components/HeaderPages/HeaderPages';
import { getCourierStatusConfig } from './CourierMap.functions';
import Filters from './Filters';
import Map from './Map';

function CourierMap() {
  const { t } = useTranslation();

  usePageTitle(t('Courier Map'));

  const location = useLocation();

  const [focusedCourierId, setFocusedCourierId] = useState(
    getSearchQueryParam(location.search, 'courier') ?? null
  );

  const [mapTileLayerProvider, setMapTileLayerProvider] = useState(
    getSearchQueryParam(location.search, 'mapType') ?? defaultTileLayerProvider
  );

  const mapRef = useRef<L.Map>();

  const mapZoom = getMapZoom();
  const mapCoordinates = getMapCoordinates();

  const request = useAxiosHook<CouriersLocationCollection>({
    url: '/tracking/couriers/location',
    method: 'POST',
    data: {
      date: httpDateFormat(new Date()),
    },
  });
  const { data, reload } = request;

  useEffect(() => {
    const reloadInterval = setInterval(() => reload(), 30000);

    return () => {
      clearInterval(reloadInterval);
    };
  }, [reload]);

  useEffect(() => {
    if (!mapRef.current) {
      return;
    }

    const courier =
      data && focusedCourierId
        ? data?.find((c) => String(c.courier_id) === focusedCourierId)
        : null;

    if (!courier) {
      mapRef.current.flyTo(mapCoordinates, mapZoom.defaultZoom, {
        duration: 0.5,
      });

      return;
    }

    mapRef.current.flyTo(
      [parseInt(courier.latitude), parseInt(courier.longitude)],
      18,
      {
        duration: 0.5,
      }
    );
  }, [data, focusedCourierId, mapCoordinates, mapZoom.defaultZoom]);

  const courierStatusConfig = useMemo(() => getCourierStatusConfig(t), [t]);

  return (
    <div className="page">
      <HeaderPages
        title={t('Courier Map')}
        subtitle={t('See courier location in real time')}
        icon={faLocationArrow}
      />

      <div className="p-grid">
        <div className="p-col">
          <Map
            mapRef={mapRef}
            mapTileLayerProvider={mapTileLayerProvider}
            courierStatusConfig={courierStatusConfig}
            request={request}
          />
        </div>

        <div className="p-col-fixed" style={{ width: '300px' }}>
          <Filters
            focusedCourierId={focusedCourierId}
            setFocusedCourierId={setFocusedCourierId}
            mapTileLayerProvider={mapTileLayerProvider}
            setMapTileLayerProvider={setMapTileLayerProvider}
            courierStatusConfig={courierStatusConfig}
            request={request}
          />
        </div>
      </div>
    </div>
  );
}

export default CourierMap;
