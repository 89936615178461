import { faMinus } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDebounce } from 'use-lodash-debounce';

import useAxiosHook from '../../../../hooks/useAxiosHook';
import { useEndpointGuard } from '../../../../hooks/useEndpointGuard';
import useHaveValuesChanged from '../../../../hooks/useHaveValuesChanged';
import useSearchQueryAutoCompleteInputParam from '../../../../hooks/useSearchQueryAutoCompleteInputParam';
import useSearchQueryDateParam from '../../../../hooks/useSearchQueryDateParam';
import useSearchQueryParam from '../../../../hooks/useSearchQueryParam';
import {
  EmployeeCollection,
  EmployeeResource,
} from '../../../../types/api/employees';
import * as ordersGuards from '../../../../utils/constants/auth/orders';
import { debounceTimeout } from '../../../../utils/constants/misc';
import { httpDateFormat } from '../../../../utils/helpers/formatting';
import { queryString } from '../../../../utils/helpers/http';
import { renderIcon } from '../../../../utils/helpers/icon';
import { tryDateRangeFromDiff } from '../../../../utils/helpers/parse';
import { getSearchQueryParam } from '../../../../utils/helpers/searchQuery';
import { TableProps } from '../../../DataTable/Table/Table';
import AutoCompleteInput from '../../../Forms/AutoCompleteInput/AutoCompleteInput';
import FiltersCounter from '../../Components/Filters/FiltersCounter';
import { EmployeeOption } from './DeletedOrders.functions';

function useTableFilters(
  page: number,
  setPage: TableProps['setPage'],
  limit: number
) {
  const { t } = useTranslation();
  const employeeFilterGuard = useEndpointGuard(ordersGuards.employeeFilter);
  const location = useLocation();
  const [serialNoFilter, setSerialNoFilter] = useState<string>(
    () => getSearchQueryParam(location.search, 'serialNo') ?? ''
  );
  const [klientOdIme, setKlientOdIme] = useState<string>(
    () => getSearchQueryParam(location.search, 'client_orderer_name') ?? ''
  );
  const [dateFrom, setDateFrom] = useState<Date | null>(() => {
    const dateFrom = getSearchQueryParam(location.search, 'date_from') ?? '';
    const dateTo = getSearchQueryParam(location.search, 'date_to') ?? '';
    return tryDateRangeFromDiff(dateFrom, dateTo, 30)?.[0] ?? new Date();
  });
  const [dateTo, setDateTo] = useState<Date | null>(() => {
    const dateFrom = getSearchQueryParam(location.search, 'date_from') ?? '';
    const dateTo = getSearchQueryParam(location.search, 'date_to') ?? '';
    return tryDateRangeFromDiff(dateFrom, dateTo, 30)?.[1] ?? new Date();
  });
  const [employeeFilter, setEmployeeFilter] = useState<string>('');
  const [employeeFilterObj, setEmployeeFilterObj] =
    useState<EmployeeOption | null>(null);

  const serialNoFilterDebounce = useDebounce(serialNoFilter, debounceTimeout);
  const employeeFilterDebounce = useDebounce(employeeFilter, debounceTimeout);
  const klientOdImeDebounce = useDebounce(klientOdIme, debounceTimeout);

  const { data: employeeData } = useAxiosHook<EmployeeCollection>(
    '/employees' +
      queryString({
        name: employeeFilterDebounce,
      }),
    { skipWhen: !employeeFilterGuard }
  );

  useEffect(() => {
    if (setPage === undefined) {
      return;
    }
    setPage(1);
  }, [serialNoFilterDebounce, dateFrom, dateTo, employeeFilterObj, setPage]);

  const employeeOptions = useMemo(
    () =>
      employeeData?.data
        ?.filter((e) => {
          if (typeof employeeFilter === 'string') {
            return true;
          }

          return e.id !== employeeFilterObj?.id;
        })
        .map((e) => ({ label: `${e.ime} ${e.prezime}`, value: e })) ?? [],
    [employeeData?.data, employeeFilter, employeeFilterObj?.id]
  );

  useSearchQueryParam('serialNo', serialNoFilterDebounce);
  useSearchQueryParam('klientOdIme', klientOdImeDebounce);
  useSearchQueryDateParam('date_from', dateFrom);
  useSearchQueryDateParam('date_to', dateTo);
  useSearchQueryAutoCompleteInputParam<EmployeeOption, EmployeeResource>({
    param: 'deleted_by',
    filterValue: employeeFilter,
    setFilterValue: setEmployeeFilter,
    filterValueObj: employeeFilterObj,
    setFilterValueObj: setEmployeeFilterObj,
    axiosRequestConfig: (employeeId) => `/employees/${employeeId}`,
    initialFilterValueDataPath: (employeeResource) =>
      `${employeeResource.data.ime} ${employeeResource.data.prezime}`,
    filterValueObjPath: 'id',
    initialDataModifier: (employeeResource) => employeeResource.data,
  });

  const filtersArr = useMemo(
    () => [serialNoFilterDebounce, dateFrom, dateTo, employeeFilterObj],
    [dateFrom, dateTo, employeeFilterObj, serialNoFilterDebounce]
  );

  const haveFiltersChanged = useHaveValuesChanged(filtersArr);

  const httpFiltersObj = useMemo(
    () => ({
      date_from: dateFrom ? httpDateFormat(dateFrom) : undefined,
      date_to: dateTo ? httpDateFormat(dateTo) : undefined,
      serial_number: serialNoFilterDebounce,
      deleted_by_id: employeeFilterObj?.korisnik_id,
      page: haveFiltersChanged ? 1 : page,
      limit,
    }),
    [
      dateFrom,
      dateTo,
      employeeFilterObj?.korisnik_id,
      haveFiltersChanged,
      limit,
      page,
      serialNoFilterDebounce,
    ]
  );

  const basicFiltersActiveFilterCount = Object.values({
    dateFrom, // date_from and date_to is count like one
    serialNoFilter,
    klientOdIme,
    employeeFilter,
  }).filter(Boolean).length;

  const headerFiltersForm = useMemo<JSX.Element>(
    () => (
      <Accordion multiple activeIndex={[0]}>
        <AccordionTab
          disabled
          headerTemplate={
            <FiltersCounter
              description={t('Basic filters')}
              counter={basicFiltersActiveFilterCount}
            />
          }
        >
          <div className="sidebar_filter">
            <label htmlFor="filter_date_from">{t('Created Date')}</label>
            <div className="sidebar_filter_row">
              <Calendar
                value={dateFrom ?? undefined}
                monthNavigator
                yearNavigator
                yearRange={`2009:${dayjs().format('YYYY')}`}
                dateFormat="dd/mm/yy"
                maxDate={new Date()}
                inputId="deleted-orders-filter_date_from"
                onChange={(e) => {
                  const dateRangeDiff = (e.value && dateTo
                    ? tryDateRangeFromDiff(e.value as Date, dateTo, 30)
                    : undefined) ?? [new Date(), new Date()];

                  setDateFrom(dateRangeDiff[0]);
                  setDateTo(dateRangeDiff[1]);
                }}
                inputClassName={classNames({
                  sidebar_filter_active: dateFrom,
                })}
              />
              <span style={{ margin: 'auto' }}>{renderIcon(faMinus)}</span>
              <Calendar
                value={dateTo ?? undefined}
                monthNavigator
                yearNavigator
                yearRange={`2009:${dayjs().format('YYYY')}`}
                dateFormat="dd/mm/yy"
                maxDate={new Date()}
                inputId="deleted-orders-filter_date_to"
                onChange={(e) => {
                  const dateRangeDiff = (e.value && dateTo
                    ? tryDateRangeFromDiff(e.value as Date, dateTo, 30)
                    : undefined) ?? [new Date(), new Date()];

                  setDateFrom(dateRangeDiff[0]);
                  setDateTo(dateRangeDiff[1]);
                }}
                inputClassName={classNames({
                  sidebar_filter_active: dateTo,
                })}
              />
            </div>
          </div>
          <div className="sidebar_filter">
            <label htmlFor="filter_serial_no">{t('Serial No')}</label>
            <InputText
              value={serialNoFilter}
              onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                setSerialNoFilter(e.target.value);
              }}
              className={classNames({
                sidebar_filter_active: serialNoFilter,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="client_orderer_name_filter">{t('Orderer')}</label>
            <InputText
              id="client_orderer_name_filter"
              value={klientOdIme ?? ''}
              onChange={(e) => setKlientOdIme(e.target.value)}
              className={classNames({
                sidebar_filter_active: klientOdIme,
              })}
            />
          </div>
          {employeeFilterGuard && (
            <div className="sidebar_filter">
              <label htmlFor="filter_deleted_by">{t('Deleted by.SHE')}</label>
              <AutoCompleteInput
                id="filter_deleted_by"
                filterValue={employeeFilter}
                value={employeeFilterObj}
                options={employeeOptions}
                onFilterChange={setEmployeeFilter}
                onSelectionChange={setEmployeeFilterObj}
                className={classNames({
                  sidebar_filter_active: employeeFilter,
                })}
              />
            </div>
          )}
        </AccordionTab>
      </Accordion>
    ),
    [
      dateFrom,
      dateTo,
      employeeFilter,
      employeeFilterGuard,
      employeeFilterObj,
      employeeOptions,
      serialNoFilter,
      klientOdIme,
      basicFiltersActiveFilterCount,
      t,
    ]
  );

  const headerFiltersCount = useMemo(
    () => basicFiltersActiveFilterCount,
    [basicFiltersActiveFilterCount]
  );

  function resetAllFilters() {
    setDateFrom(new Date());
    setDateTo(new Date());
    setSerialNoFilter('');
    setEmployeeFilter('');
    setKlientOdIme('');
    setEmployeeFilterObj(null);
  }

  return {
    headerFiltersForm,
    resetAllFilters,
    httpFiltersObj,
    headerFiltersCount,
  };
}

export default useTableFilters;
