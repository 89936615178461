import { yupResolver } from '@hookform/resolvers/yup';
import { TabPanel, TabView } from 'primereact/tabview';
import { useEffect, useMemo } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import useAxiosHook from '../../../../../hooks/useAxiosHook';
import useTabPanelRhfErrorProps from '../../../../../hooks/useTabPanelRhfErrorProps';
import useToastMessage from '../../../../../hooks/useToastMessage';
import {
  PriceListCollection,
  PriceListCollectionQueryParams,
} from '../../../../../types/api/priceLists';
import { AddPriceResourceRequestPayload } from '../../../../../types/api/prices';
import {
  TariffCollection,
  TariffCollectionQueryParams,
} from '../../../../../types/api/tariffs';
import { LabelValue } from '../../../../../types/options';
import { queryString } from '../../../../../utils/helpers/http';
import Price from '../_Common/Price';
import usePriceTabHelpers from '../_Common/usePriceTabHelpers';
import {
  generalInfoTabFields,
  getDefaultValues,
  getValidationSchema,
  priceTabFields,
  toApiData,
} from './AddDialog.functions';
import { FormFields } from './AddDialog.types';
import GeneralInfo from './Tabs/GeneralInfo';

type Props = {
  handleCreation: (data: AddPriceResourceRequestPayload) => void;
};

function FormContainer({ handleCreation }: Props): JSX.Element {
  const { t } = useTranslation();

  const defaultValues = useMemo(() => getDefaultValues(), []);
  const validationSchema = useMemo(() => getValidationSchema(t), [t]);

  const methods = useForm<FormFields>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const { control, handleSubmit, reset } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  function handleFormSubmission(values: FormFields) {
    handleCreation(toApiData(values));
  }

  const tariff_name = useWatch<FormFields, 'tariff_name'>({
    name: 'tariff_name',
    control,
  });

  const price_list_name = useWatch<FormFields, 'price_list_name'>({
    name: 'price_list_name',
    control,
  });

  const {
    data: tariffsData,
    error: tariffsError,
    isLoading: isTariffsLoading,
  } = useAxiosHook<TariffCollection>(
    '/tariffs' +
      queryString<TariffCollectionQueryParams>({
        name: tariff_name,
        page: 1,
        limit: 15,
      })
  );

  useToastMessage(undefined, tariffsError, {
    error: {
      summary: t('An error occured while loading tariffs.'),
    },
  });

  const tariffOptions = useMemo<LabelValue[]>(
    () =>
      tariffsData?.data.map((t) => ({ label: t.name, value: String(t.id) })) ??
      [],
    [tariffsData?.data]
  );

  const {
    data: priceListsData,
    error: priceListsError,
    isLoading: isPriceListsLoading,
  } = useAxiosHook<PriceListCollection>(
    '/price-lists' +
      queryString<Partial<PriceListCollectionQueryParams>>({
        name: price_list_name,
        page: 1,
        limit: 15,
      })
  );

  useToastMessage(undefined, priceListsError, {
    error: {
      summary: t('An error occured while loading price lists.'),
    },
  });

  const priceListOptions = useMemo<LabelValue[]>(
    () =>
      priceListsData?.data.map((t) => ({
        label: t.name ?? '',
        value: String(t.id),
      })) ?? [],
    [priceListsData?.data]
  );

  const {
    entitiesRequest: { data: entitiesData, isLoading: isEntitiesLoading },
    functionsRequest: { data: functionsData, isLoading: isFunctionsLoading },
    operatorsRequest: { data: operatorsData, isLoading: isOperatorsLoading },
  } = usePriceTabHelpers();

  const generalInfoTabProps = useTabPanelRhfErrorProps(
    generalInfoTabFields,
    methods.formState
  );

  const priceTabProps = useTabPanelRhfErrorProps(
    priceTabFields,
    methods.formState
  );

  return (
    <FormProvider {...methods}>
      <form
        id="prices-add-price-form"
        onSubmit={handleSubmit(handleFormSubmission)}
      >
        <TabView>
          <TabPanel header={t('General info')} {...generalInfoTabProps}>
            <GeneralInfo
              tariff_name={tariff_name}
              tariffOptions={tariffOptions}
              isTariffsLoading={isTariffsLoading}
              price_list_name={price_list_name}
              priceListOptions={priceListOptions}
              isPriceListsLoading={isPriceListsLoading}
            />
          </TabPanel>

          <TabPanel header={t('Price')} {...priceTabProps}>
            <Price
              entitiesData={entitiesData}
              functionsData={functionsData}
              operatorsData={operatorsData}
              isEditorDisabled={
                isEntitiesLoading || isFunctionsLoading || isOperatorsLoading
              }
            />
          </TabPanel>
        </TabView>
      </form>
    </FormProvider>
  );
}

export default FormContainer;
