import dayjs from 'dayjs';

import multipleParcelsPin from '../../../assets/img/map-pins/add.png';
import pickupFailurePin from '../../../assets/img/map-pins/alert.png';
import deliveryFailurePin from '../../../assets/img/map-pins/offline.png';
import pickupSuccessPin from '../../../assets/img/map-pins/pickup.png';
import deliverySuccessPin from '../../../assets/img/map-pins/pin.png';
import { httpDateFormat } from '../../../utils/helpers/formatting';
import { tryInt } from '../../../utils/helpers/parse';
import { getSearchQueryParam } from '../../../utils/helpers/searchQuery';

export function getInitialValues(location) {
  const dateParam = getSearchQueryParam(location.search, 'date');

  const date = dateParam
    ? dayjs(dateParam).isValid()
      ? dayjs(dateParam).toDate()
      : new Date()
    : new Date();

  return {
    courier_id: tryInt(getSearchQueryParam(location.search, 'courier')) ?? null,
    serial_number: getSearchQueryParam(location.search, 'serialNo') ?? '',
    date,
  };
}

export function toApiData(values) {
  let filterObj = {};

  if (values.serial_number) {
    filterObj['serial_number'] = values.serial_number;
  } else {
    if (values.date) {
      filterObj['date_from'] = httpDateFormat(values.date);

      filterObj['date_to'] = httpDateFormat(values.date);
    }

    if (values.courier_id) {
      filterObj['courier_id'] = values.courier_id;
    }
  }

  return filterObj;
}

export function getParcelStatusMap(t) {
  return {
    pickupSucceeded: {
      label: t('Pick up success'),
      icon: pickupSuccessPin,
      statusMessage: t('Successfully picked up'),
    },
    pickupFailed: {
      label: t('Pick up failure'),
      icon: pickupFailurePin,
      statusMessage: t('Failed pickup'),
    },
    deliverySucceeded: {
      label: t('Delivery success'),
      icon: deliverySuccessPin,
      statusMessage: t('Successfully delivered'),
    },
    deliveryFailed: {
      label: t('Delivery failure'),
      icon: deliveryFailurePin,
      statusMessage: t('Failed delivery'),
    },
    moreParcels: {
      label: t('Multiple parcels'),
      icon: multipleParcelsPin,
    },
  };
}

function getParcelStatus(t, statusMessage) {
  if (
    statusMessage === 'Successfully picked up' ||
    statusMessage === t('Successfully picked up')
  ) {
    return 'pickupSucceeded';
  }

  if (
    statusMessage === 'Failed pickup' ||
    statusMessage === t('Failed pickup')
  ) {
    return 'pickupFailed';
  }

  if (
    statusMessage === 'Successfully delivered' ||
    statusMessage === t('Successfully delivered')
  ) {
    return 'deliverySucceeded';
  }

  if (
    statusMessage === 'Failed delivery' ||
    statusMessage === t('Failed delivery')
  ) {
    return 'deliveryFailed';
  }
}

export function generateParcelIcon(t, locationObj) {
  const parcelStatusMap = getParcelStatusMap(t);

  if (locationObj.parcels.length > 1) {
    return multipleParcelsPin;
  } else {
    return parcelStatusMap[
      getParcelStatus(t, locationObj.parcels[0].statusMessage)
    ].icon;
  }
}
