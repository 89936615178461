import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import _ from 'lodash';
import { $enum } from 'ts-enum-util';
import * as Yup from 'yup';

import { EmailAction, EmailRecipient } from '../../../../../enums/email';
import {
  getNotificationChannel,
  getNotificationRecipient,
  getNotificationType,
} from '../../../../../utils/constants/entities/notifications';
import { isStreetFreeInputAllowed } from '../../../../../utils/constants/misc';
import {
  bankAccountRegex,
  domainNameRegex,
  individualSsnRegex,
  legalSsnRegex,
  uintRegex,
} from '../../../../../utils/constants/regex';
import { invalidTabHeaderIcon } from '../../../../../utils/globals';
import { getChangedValues } from '../../../../../utils/helpers/object';
import {
  tryArray,
  tryFloat,
  tryInt,
  tryString,
  tryTimeRange,
} from '../../../../../utils/helpers/parse';
import {
  formatMobileNumber,
  formatPhoneNumber,
  getPhoneorMobileNumberRegion,
  isValidMobileNumber,
  isValidPhoneNumber,
} from '../../../../../utils/helpers/phoneNumbers';
import { isEmail } from '../../../../../utils/helpers/validation';
import {
  conditionallyRequiredField,
  yupMaxField,
  yupMinField,
  yupRequiredField,
} from '../../../../../utils/helpers/yup';

const generalInfoTabFields = {
  ime: '',
  email: '',
  municipality: '',
  municipality_id: '',
  place: '',
  adresa: '',
  mesto_id: '',
  street: '',
  ulica_id: '',
  broj: '',
  vlez: '',
  stan: '',
  mobilen: '',
  telefon: '',
  faks: '',
  web: '',
  komentar: '',
  dejnost_id: null,
  pravno_lice: false,
  interen_klient: false,

  // Dynamic fields
  // - Individual entity
  maticen_broj: '',
  smetka: '',
  cenovnik_id: null,
  rabotno_vreme: '',

  // - Legal entity
  odgovorno_lice: '',
  kontakt_lice: '',
  referenca: '',
};

function getLegalDataTabFields(isEditDialog) {
  return {
    direkcija_id: null,
    maticen_broj: '',
    danocen_broj: '',
    smetka: '',
    broj_dogovor: '',
    rok_plakanje: '',
    cenovnik_id: null,
    rabotno_vreme: '',
    cod_bank_account_payment: false,
    faktura: false,
    force_pricelist_to_receiver: false,
    ...(isEditDialog
      ? { cod_department_id: '', invoice_department_id: '' }
      : {}),
  };
}

const discountsTabFields = {
  popust: [],
};

const ordersTabFields = {
  allow_incomplete_address: false,
  orders_on_map_access: false,
  potvrda_priem: false,
  import_list_types: [],
};

const chargingDepartmentsTabFields = {
  departments: [],
};

const emailConfigurationTabFields = {
  email_configuration: Object.fromEntries(
    $enum(EmailAction)
      .getValues()
      .map((emailAction) => [
        emailAction,
        Object.fromEntries(
          $enum(EmailRecipient)
            .getValues()
            .map((emailRecipient) => [emailRecipient, []])
        ),
      ])
  ),
};

function getNotificationsTabFields(t) {
  return {
    sms_gateway: '',
    import_notification: false,
    cod_email_warrant: false,
    invoice_email: false,
    notification: generateNotificationFields(t),
  };
}

function getFields(t, isEditDialog) {
  const legalDataTabFields = getLegalDataTabFields(t, isEditDialog);
  const notificationsTabFields = getNotificationsTabFields(t);

  return {
    ...generalInfoTabFields,
    ...legalDataTabFields,
    ...discountsTabFields,
    ...notificationsTabFields,
    ...ordersTabFields,
    ...chargingDepartmentsTabFields,
    ...emailConfigurationTabFields,
  };
}

// prettier-ignore
export const ckEditorConfig = {
  toolbar: [
    'heading', '|',
    'bold', 'italic', '|',
    'link', '|',
    'bulletedList', 'numberedList', '|',
    'blockQuote', '|',
    'undo', 'redo'
  ]
}

function getHasTabErrors(t, isEditDialog) {
  const legalDataTabFields = getLegalDataTabFields(t, isEditDialog);
  const notificationsTabFields = getNotificationsTabFields(t);

  return function hasTabErrors(tabName, values, errors, touched) {
    const errorsKeys = Object.keys(errors);
    const touchedKeys = Object.keys(touched);

    const tabNameFieldsMap = {
      'general-info': {
        ...(!values.pravno_lice ? { working_hours_from: true } : {}),
        ..._.omit(
          generalInfoTabFields,
          !values.pravno_lice
            ? ['odgovorno_lice', 'kontakt_lice', 'referenca']
            : ['maticen_broj', 'smetka', 'cenovnik_id', 'rabotno_vreme']
        ),
      },
      'legal-data': !values.pravno_lice
        ? {}
        : { ...legalDataTabFields, working_hours_from: true },
      discounts: discountsTabFields,
      orders: ordersTabFields,
      notifications: notificationsTabFields,
      'charging-departments': chargingDepartmentsTabFields,
      'email-configuration': emailConfigurationTabFields,
    };

    const tabFields = Object.keys(tabNameFieldsMap[tabName]);

    for (const field of tabFields) {
      if (errorsKeys.includes(field) && touchedKeys.includes(field)) {
        return true;
      }
    }

    return false;
  };
}

function generateNotificationFields(t) {
  const notificationType = getNotificationType(t);
  const notificationChannel = getNotificationChannel(t);
  const notificationRecipient = getNotificationRecipient(t);

  let notificationFields = {};

  for (let nType of notificationType) {
    for (let nrType of notificationRecipient) {
      for (let nChannel of notificationChannel) {
        let obj = {
          isChecked: false,
        };

        switch (nChannel.id) {
          case 1:
            obj.subject = null;
            obj.body = null;
            break;

          case 2:
            obj.msg = null;
            break;

          default:
        }

        notificationFields[`${nType.id}-${nrType.id}-${nChannel.id}`] = obj;
      }
    }
  }

  return notificationFields;
}

export function generateDiscountObject(discountData) {
  let discount = [];

  if (typeof discountData === 'string') {
    try {
      const parsedDiscount = JSON.parse(discountData);

      if (typeof parsedDiscount === 'string') {
        const finalPass = JSON.parse(parsedDiscount);

        discount = Array.isArray(finalPass) ? finalPass : [];
      } else {
        discount = parsedDiscount;
      }
    } catch (e) {
      Sentry.captureException(e, {
        extra: {
          data: discountData,
        },
      });
    }
  } else if (Array.isArray(discountData)) {
    discount = discountData;
  }

  // Filter and protect from invalid values
  return discount
    .filter((d) => {
      const percent = tryInt(d.Popust);
      const serviceId = tryInt(d.UslugaId);

      return (
        percent !== undefined &&
        serviceId !== undefined &&
        percent >= 0 &&
        percent <= 100
      );
    })
    .map((d) => ({
      ...d,
      StatusId: tryInt(d.StatusId) ?? null,
      Popust: tryFloat(d.Popust) ?? '',
    }));
}

export function tabIcon(t, tabName, values, errors, touched, isEditDialog) {
  const hasTabErrors = getHasTabErrors(t, isEditDialog);
  return hasTabErrors(tabName, values, errors, touched)
    ? invalidTabHeaderIcon
    : {};
}

export function generateNotificationsObject(t, data) {
  let notifications = generateNotificationFields(t);

  if (!data?.notification) {
    return notifications;
  }

  let parsedNotification = [];

  try {
    parsedNotification = JSON.parse(data.notification);
  } catch (e) {
    Sentry.captureException(e, {
      extra: {
        data: data.notification,
      },
    });
  }

  for (let n of parsedNotification) {
    if (
      !n.order_notification_type ||
      !n.order_notification_recipient_type ||
      !n.order_notification_channel
    ) {
      continue;
    }

    const id = `${n.order_notification_type}-${n.order_notification_recipient_type}-${n.order_notification_channel}`;
    let newObj = {};

    if (parseInt(n.order_notification_channel) === 1) {
      newObj.subject = n.subject;
      newObj.body = n.body;
    } else if (parseInt(n.order_notification_channel) === 2) {
      newObj.msg = n.msg;
    }

    notifications[id] = {
      isChecked: true,
      ...newObj,
    };
  }
  return notifications;
}

function initialEmailConfiguration(emailConfiguration) {
  return _.isObject(emailConfiguration)
    ? _.merge(
        {},
        emailConfigurationTabFields.email_configuration,
        emailConfiguration
      )
    : emailConfigurationTabFields.email_configuration;
}

export function getInitialValues(
  t,
  isEditDialog,
  clientApiData = {},
  configs,
  chargingDepartmentsData = []
) {
  const fields = getFields(t, isEditDialog);
  const fieldSettings = customerConfigsOnly(configs?.data);

  const data = isEditDialog ? clientApiData : {};

  let initialValues = { ...fields };

  const pricelistId =
    tryInt(data?.cenovnik_id) ??
    fieldSettings.DefaultPricelistId ??
    fields.cenovnik_id;

  if (pricelistId !== undefined) {
    initialValues.cenovnik_id = pricelistId;
  }

  if (Object.keys(data).length) {
    initialValues.pravno_lice = !!parseInt(data.pravno_lice);

    const occupationId = tryInt(data.dejnost_id);
    if (occupationId !== undefined) {
      initialValues.dejnost_id = occupationId;
    }

    initialValues.ime = tryString(data.ime) ?? fields.ime;
    initialValues.email = tryString(data.email) ?? fields.email;
    initialValues.adresa = tryString(data.adresa) ?? fields.adresa;

    initialValues.mesto_id = tryString(data.mesto_id) ?? fields.mesto_id;
    initialValues.ulica_id = data.ulica_id ?? fields.ulica_id;
    initialValues.broj = tryString(data.broj) ?? fields.broj;
    initialValues.vlez = tryString(data.vlez) ?? fields.vlez;
    initialValues.stan = tryString(data.stan) ?? fields.stan;

    initialValues.web = tryString(data.web) ?? fields.web;

    initialValues.odgovorno_lice =
      tryString(data.odgovorno_lice) ?? fields.odgovorno_lice;

    initialValues.kontakt_lice =
      tryString(data.kontakt_lice) ?? fields.kontakt_lice;

    initialValues.referenca = tryString(data.referenca) ?? fields.referenca;
    initialValues.komentar = tryString(data.komentar) ?? fields.komentar;

    initialValues.broj_dogovor =
      tryString(data.broj_dogovor) ?? fields.broj_dogovor;

    initialValues.maticen_broj =
      tryString(data.maticen_broj) ?? fields.maticen_broj;

    initialValues.danocen_broj =
      tryString(data.danocen_broj) ?? fields.danocen_broj;

    initialValues.smetka = tryString(data.smetka) ?? fields.smetka;

    initialValues.rok_plakanje =
      tryString(data.rok_plakanje) ?? fields.rok_plakanje;

    initialValues.sms_gateway =
      tryString(data.sms_gateway) ?? fields.sms_gateway;

    initialValues.interen_klient = !!parseInt(data.interen_klient);
    initialValues.faktura = !!parseInt(data.faktura);

    if (isEditDialog) {
      initialValues.cod_department_id =
        data.cod_department_id ?? fields.cod_department_id;
      initialValues.invoice_department_id =
        data.invoice_department_id ?? fields.invoice_department_id;
    }

    initialValues.force_pricelist_to_receiver = !!parseInt(
      data.force_pricelist_to_receiver
    );

    initialValues.cod_bank_account_payment = !!parseInt(
      data.cod_bank_account_payment
    );

    initialValues.allow_incomplete_address = !!parseInt(
      data.allow_incomplete_address
    );

    initialValues.potvrda_priem = !!parseInt(data.potvrda_priem);
    initialValues.orders_on_map_access = !!parseInt(data.orders_on_map_access);
    initialValues.import_notification = !!data.import_notification;
    initialValues.cod_email_warrant = !!data.cod_email_warrant;
    initialValues.invoice_email = !!data.invoice_email;

    if (data.direkcija_id !== null) {
      initialValues.direkcija_id = parseInt(data.direkcija_id);
    }

    initialValues.import_list_types = (
      tryArray(data.import_list_types) ?? fields.import_list_types
    ).filter(
      (listType) =>
        _.isObject(listType) &&
        !isNaN(parseInt(listType.id)) &&
        listType.hasOwnProperty('name')
    );

    const workingHours =
      tryTimeRange(data.rabotno_vreme, true) ?? fields.rabotno_vreme;

    if (Array.isArray(workingHours)) {
      initialValues.working_hours_from = workingHours[0];
      initialValues.working_hours_to = workingHours[1];
    } else {
      initialValues.rabotno_vreme = workingHours;
    }

    initialValues.email_configuration = initialEmailConfiguration(
      clientApiData.email_configuration
    );
  } else {
    initialValues.email_configuration = initialEmailConfiguration();
  }

  initialValues.notification = generateNotificationsObject(t, data);
  initialValues.popust = generateDiscountObject(data.popust);

  if (data.id === data.direkcija_id && isEditDialog) {
    initialValues.departments = chargingDepartmentsData ?? [];
  }

  initialValues.telefon = tryString(data.telefon) ?? fields.telefon;
  initialValues.telefon_region = getPhoneorMobileNumberRegion(
    initialValues.telefon
  );

  initialValues.mobilen = tryString(data.mobilen) ?? fields.mobilen;
  initialValues.mobilen_region = getPhoneorMobileNumberRegion(
    initialValues.mobilen
  );
  initialValues.direkcija_id = data.direkcija_id ?? fields.direkcija_id;
  initialValues.faks_region = getPhoneorMobileNumberRegion(initialValues.faks);

  return initialValues;
}

function toApiData(values, headquarterData, clientId = null) {
  let apiData = {
    ...values,
  };

  if (isStreetFreeInputAllowed) {
    delete apiData.ulica_id;
  } else {
    apiData.ulica_id = parseInt(apiData.ulica_id);
  }

  // Number values
  apiData.dejnost_id = parseInt(apiData.dejnost_id);
  apiData.mesto_id = parseInt(apiData.mesto_id);
  if (apiData.direkcija_id !== null) {
    apiData.direkcija_id = parseInt(apiData.direkcija_id);
  }
  apiData.cenovnik_id = parseInt(apiData.cenovnik_id);
  if (apiData.rok_plakanje.length > 0) {
    apiData.rok_plakanje = parseInt(apiData.rok_plakanje);
  }

  // Working hours
  if (apiData.working_hours_from && apiData.working_hours_to) {
    const start = dayjs(apiData.working_hours_from).format('HH:mm');
    const end = dayjs(apiData.working_hours_to).format('HH:mm');
    apiData.rabotno_vreme = `${start}-${end}`;
  }

  // List Types
  apiData.import_list_types = _.map(apiData.import_list_types, 'id');

  // Notification
  let notification = [];

  for (let notificationObj in apiData.notification) {
    if (!apiData.notification[notificationObj].isChecked) {
      continue;
    }

    const [nType, nrType, nChannel] = notificationObj.split('-');

    notification.push({
      order_notification_type: nType,
      order_notification_recipient_type: nrType,
      order_notification_channel: nChannel,
      ...(parseInt(nChannel) === 1
        ? {
            subject: apiData.notification[notificationObj].subject,
            body: apiData.notification[notificationObj].body,
          }
        : {
            msg: apiData.notification[notificationObj].msg,
          }),
    });
  }

  apiData.departments = apiData.departments.map((department) => ({
    id: department.id ? Number(department.id) : null,
    name: department.name,
    headquarters_id: Number(department.headquarters_id),
    charge_customer_id: Number(department.charge_customer_id),
  }));

  apiData.cod_email_warrant = tryInt(apiData.cod_email_warrant) ?? null;
  apiData.invoice_email = tryInt(apiData.invoice_email) ?? null;
  apiData.import_notification = tryInt(apiData.import_notification) ?? null;

  apiData.notification = notification;

  // Delete conditional fields
  if (!apiData.smetka) {
    delete apiData.cod_bank_account_payment;
  }

  if (!apiData.pravno_lice) {
    delete apiData.kontakt_lice;
    delete apiData.odgovorno_lice;
    delete apiData.referenca;
    delete apiData.broj_dogovor;
    delete apiData.danocen_broj;

    delete apiData.direkcija_id;
    delete apiData.rok_plakanje;
    delete apiData.popust;
    delete apiData.faktura;

    delete apiData.cod_department_id;
    delete apiData.invoice_department_id;
    delete apiData.departments;

    delete apiData.import_notification;
  } else {
    if (!apiData.broj_dogovor) {
      delete apiData.faktura;
    }

    if (!apiData.faktura) {
      delete apiData.rok_plakanje;
      delete apiData.popust;
    }

    if (clientId && apiData.direkcija_id !== clientId) {
      // if there is clientId action is edit and if client is not headquarter then remove departments
      delete apiData.departments;
    } else if (!clientId && apiData.direkcija_id !== 0) {
      // if there is not clientId action is create and if client is not headquarter then remove departments
      delete apiData.departments;
    }

    if (apiData.direkcija_id === 0 || apiData.direkcija_id === null) {
      // only on create new client, if this client is headquarter we set 0 value, so if is 0 delete from apiData
      // on edit we set direkcija_id -> client_id
      delete apiData.direkcija_id;
    }
  }

  // Mobile and phone numbers
  if (apiData.telefon) {
    apiData.telefon = formatPhoneNumber(
      apiData.telefon,
      apiData.telefon_region
    );
  }

  if (apiData.mobilen) {
    apiData.mobilen = formatMobileNumber(
      apiData.mobilen,
      apiData.mobilen_region
    );
  }

  // Delete unnecessary data
  delete apiData.place;
  delete apiData.street;
  delete apiData.ulica_ime;
  delete apiData.municipality;
  delete apiData.municipality_id;
  delete apiData.working_hours_from;
  delete apiData.working_hours_to;
  delete apiData.telefon_region;
  delete apiData.mobilen_region;
  delete apiData.faks_region;

  // If create dialog
  if (clientId === null) {
    Object.keys(apiData).forEach((key) => {
      if (typeof apiData[key] === 'string' && apiData[key].trim() === '') {
        delete apiData[key];
      }
    });
  }

  // Settings dependent fields
  if (!apiData.email) {
    delete apiData.email;
  }

  if (!apiData.broj) {
    delete apiData.broj;
  }

  if (!apiData.smetka) {
    delete apiData.smetka;
  }

  if (!apiData.then) {
    delete apiData.then;
  }

  if (!apiData.maticen_broj) {
    delete apiData.maticen_broj;
  }

  if (apiData.popust) {
    apiData.popust = generateDiscountObject(apiData.popust);
  }

  return apiData;
}

export function createDialogApiData(values, headquarterData) {
  const apiData = toApiData(values, headquarterData);

  return _.pick(
    apiData,
    Object.keys(apiData).filter((k) => {
      const val = apiData[k];

      if (typeof val === 'number' || typeof val === 'boolean') {
        return true;
      }

      if (Array.isArray(val)) {
        return val.length > 0;
      }

      if (_.isObject(val)) {
        return Object.keys(val).length > 0;
      }

      return !!k;
    })
  );
}

export function editDialogApiData(
  values,
  headquarterData,
  clientId,
  initialValues
) {
  const apiData = toApiData(values, headquarterData, clientId);
  const initialApiData = toApiData(initialValues, headquarterData, clientId);

  return getChangedValues(apiData, initialApiData);
}

function settingsDependentRequiredField(field, isRequired, requiredMessage) {
  return isRequired ? field.required(requiredMessage) : field;
}

function customerConfigsOnly(configsData) {
  return Object.fromEntries(
    (configsData ?? [])
      .filter((c) => c.section === 'Customer')
      .map((c) => [c.ident, c.value])
  );
}

export function isHeadquarter(values, clientData) {
  return (
    values.pravno_lice &&
    (parseInt(clientData?.id) === values.direkcija_id ||
      values.direkcija_id === 0) // 0 we set when we set this client is legal and This client is headquarter
  );
}

export function generateInvoicingCheckboxProperties(
  values,
  isHeadquarter,
  headquarterData,
  t
) {
  if (isHeadquarter) {
    if (values.broj_dogovor) {
      return { checked: values.faktura };
    } else {
      return {
        disabled: true,
        checked: false,
        tooltip: t('You have to specify a contract number first'),
      };
    }
  } else {
    if (!headquarterData) {
      return { disabled: true, checked: false, tooltip: t('Loading...') };
    }

    if (!headquarterData.broj_dogovor) {
      return {
        disabled: true,
        checked: false,
        tooltip: t(
          "The headquarter of this client doesn't have a contract number specified"
        ),
      };
    } else {
      if (headquarterData.faktura_direkcija) {
        return {
          disabled: true,
          checked: false,
          tooltip: t(
            'The headquarter of this client is using one invoice for all of its subsidiaries'
          ),
        };
      } else {
        return { checked: values.faktura };
      }
    }
  }
}

export function getValidationSchema(t, configs, isEditDialog) {
  const fieldSettings = customerConfigsOnly(configs?.data);

  return Yup.object().shape({
    ime: Yup.string()
      .required(yupRequiredField(t, t('Name')))
      .min(2, ({ min }) => yupMinField(t, t('Name'), min))
      .max(210, ({ max }) => yupMaxField(t, t('Name'), max)),
    email: settingsDependentRequiredField(
      Yup.string().email().max(254),
      fieldSettings.RequiredEmail,
      yupRequiredField(t, t('Email'))
    ),
    web: Yup.string()
      .matches(domainNameRegex, t('Please enter a valid domain name or URL'))
      .max(255, ({ max }) => yupMaxField(t, max)),
    komentar: Yup.string().max(500, ({ max }) =>
      yupMaxField(t, t('Comment'), max)
    ),
    odgovorno_lice: Yup.mixed().when('pravno_lice', {
      is: true,
      then: Yup.string().max(45),
    }),
    kontakt_lice: Yup.mixed().when('pravno_lice', {
      is: true,
      then: Yup.string().max(45),
    }),
    referenca: Yup.mixed().when('pravno_lice', {
      is: true,
      then: Yup.string().max(45),
    }),
    broj_dogovor: Yup.string().max(100, ({ max }) =>
      yupMaxField(t, t('Contract No.'), max)
    ),
    rok_plakanje: Yup.mixed().when('pravno_lice', {
      is: true,
      then: Yup.string().matches(
        uintRegex,
        t('Please enter a valid number of days')
      ),
    }),
    municipality: Yup.string()
      .nullable()
      .required(yupRequiredField(t, t('Municipality'))),
    place: Yup.string()
      .nullable()
      .required(yupRequiredField(t, t('Place'))),
    street: conditionallyRequiredField(
      Yup.string().nullable(),
      !isStreetFreeInputAllowed,
      yupRequiredField(t, t('Street'))
    ),
    adresa: conditionallyRequiredField(
      Yup.string(),
      isStreetFreeInputAllowed,
      yupRequiredField(t, t('Address'))
    ),
    broj: settingsDependentRequiredField(
      Yup.string().max(10, ({ max }) => yupMaxField(t, t('Street No.'), max)),
      fieldSettings.RequiredHouseNumber,
      yupRequiredField(t, t('Street No.'))
    ),
    vlez: Yup.string().max(10, ({ max }) =>
      yupMaxField(t, t('Entrance No.'), max)
    ),
    stan: Yup.string().max(10, ({ max }) => yupMaxField(t, t('Flat No.'), max)),
    telefon: Yup.string()
      .test('telefon', t('Invalid phone number'), (value, context) => {
        if (!value) {
          return true;
        }

        const { telefon_region } = context.parent;
        return isValidPhoneNumber(value, telefon_region);
      })
      .test(
        'telefon',
        t('At least phone or mobile must be filled out'),
        function (value) {
          if (!fieldSettings.RequiredMobileOrPhone) {
            return true;
          }

          const { mobilen } = this.parent;

          if (!mobilen) {
            return value != null;
          }

          return true;
        }
      ),
    mobilen: Yup.string()
      .test('mobilen', t('Invalid mobile phone number'), (value, context) => {
        if (!value) {
          return true;
        }

        const { mobilen_region } = context.parent;
        return isValidMobileNumber(value, mobilen_region);
      })
      .test(
        'mobilen',
        t('At least phone or mobile must be filled out'),
        function (value) {
          if (!fieldSettings.RequiredMobileOrPhone) {
            return true;
          }

          const { telefon } = this.parent;

          if (!telefon) {
            return value != null;
          }

          return true;
        }
      ),
    faks: Yup.string().test(
      'telefon',
      t('Invalid phone number'),
      (value, context) => {
        if (!value) {
          return true;
        }

        const { faks_region } = context.parent;
        return isValidPhoneNumber(value, faks_region);
      }
    ),
    smetka: settingsDependentRequiredField(
      Yup.string().matches(bankAccountRegex, t('Invalid bank account number')),
      fieldSettings.RequiredBankAccount,
      yupRequiredField(t, t('Bank account'))
    ),
    danocen_broj: Yup.string().when('pravno_lice', {
      is: true,
      then: settingsDependentRequiredField(
        Yup.string().matches(
          /^\d{13}$/,
          t('Tax No. must be exactly 13 digits long')
        ),
        fieldSettings.RequiredTaxNumber,
        yupRequiredField(t, t('Tax No.'))
      ),
    }),
    maticen_broj: settingsDependentRequiredField(
      Yup.string().when('pravno_lice', {
        is: true,
        then: Yup.string().matches(
          legalSsnRegex,
          t('Social Security No. must be exactly 7 digits long')
        ),
        otherwise: Yup.string().matches(
          individualSsnRegex,
          t('Social Security No. must be exactly 13 digits long')
        ),
      }),
      fieldSettings.RequiredEntityNumber,
      t('Social Security No.')
    ),
    working_hours_from: Yup.mixed().test(
      'working_hours_from',
      t('Invalid time range'),
      function () {
        const { working_hours_from, working_hours_to } = this.parent;

        // rabotno_vreme is an optional field
        if (!working_hours_from && !working_hours_to) {
          return true;
        }

        // If only one of them is empty, we have an error
        if (
          (!working_hours_from && working_hours_to) ||
          (working_hours_from && !working_hours_to)
        ) {
          return false;
        }

        // If both are filled out, validate from <= to
        const from = dayjs(working_hours_from);
        const to = dayjs(working_hours_to);

        if (from.isValid() && to.isValid()) {
          return to.isAfter(from);
        }

        return true;
      }
    ),
    cod_department_id: Yup.string().when('pravno_lice', {
      is: (value) => value === true && isEditDialog,
      then: Yup.string().required(yupRequiredField(t, t('COD Department'))),
    }),
    invoice_department_id: Yup.string().when('pravno_lice', {
      is: (value) => value === true && isEditDialog,
      then: Yup.string().required(yupRequiredField(t, t('Invoice Department'))),
    }),
    departments: Yup.array().of(
      Yup.object({
        name: Yup.string().required(yupRequiredField(t, t('Department'))),
        charge_customer_id: Yup.string().required(
          yupRequiredField(t, t('Client'))
        ),
      })
    ),
    popust: Yup.array().test('popust', t('Invalid discount'), (discounts) => {
      for (let i = 0; i < discounts.length; i++) {
        const firstDiscount = discounts[i];
        const discount = tryFloat(firstDiscount.Popust);

        if (discount === undefined || discount < 0 || discount > 100) {
          return false;
        }

        for (let j = i + 1; j < discounts.length; j++) {
          const secondDiscount = discounts[j];

          if (
            (firstDiscount.UslugaId === secondDiscount.UslugaId ||
              firstDiscount.UslugaId === null ||
              secondDiscount.UslugaId === null) &&
            (firstDiscount.ProizvodId === secondDiscount.ProizvodId ||
              firstDiscount.ProizvodId === null ||
              secondDiscount.ProizvodId === null) &&
            (firstDiscount.StatusId === secondDiscount.StatusId ||
              firstDiscount.StatusId === null ||
              secondDiscount.StatusId === null)
          ) {
            return false;
          }
        }
      }

      return true;
    }),
    email_configuration: Yup.object().test(
      'email_configuration',
      t('Invalid email address(es) entered!'),
      (value) => {
        let emailAddresses = [];

        const emailActionEnumValues = $enum(EmailAction).getValues();
        const emailRecipientEnumValues = $enum(EmailRecipient).getValues();

        for (const action of emailActionEnumValues) {
          for (const recipient of emailRecipientEnumValues) {
            emailAddresses.push(...value[action][recipient]);
          }
        }

        return emailAddresses.every(isEmail);
      }
    ),
  });
}
