import { TFunction } from 'react-i18next';
import * as Yup from 'yup';

export type FormFields = {
  current_password: string;
  new_password: string;
  confirm_password: string;
};

export type SuccessObj = {
  message?: string;
};

export function getInitialValues(): FormFields {
  return {
    current_password: '',
    new_password: '',
    confirm_password: '',
  };
}

export function toApiData(formValues: FormFields): FormFields {
  return {
    current_password: formValues.current_password,
    new_password: formValues.new_password,
    confirm_password: formValues.confirm_password,
  };
}

export function getValidationSchema(t: TFunction): Yup.AnyObjectSchema {
  return Yup.object().shape({
    current_password: Yup.string().required(t('Current password is required')),
    new_password: Yup.string()
      .min(8, t('Password is too short - should be 8 chars minimum.'))
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,16}$/,
        t(
          'Must Contain 8 to 16 Characters, One Uppercase, One Lowercase and One Number'
        )
      )
      .required(t('New password is required')),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('new_password'), null], t('Passwords must match'))
      .required(t('Confirm password is required')),
  });
}

export function generateErrorMsg(t: TFunction, recreateOrderError: string) {
  let errorMsgObj = recreateOrderError
    .replace(/[&/\\#,+().'"{}]/g, '')
    .split(':');

  return errorMsgObj[1]
    ? t('due to {{errorMessage}}', { errorMessage: errorMsgObj[1] })
    : '';
}
