import _ from 'lodash';
import { useMemo } from 'react';

import { TableProps } from '../features/DataTable/Table/Table';

function useTableDataToDisplay<T extends object>(
  data: T[],
  sortField: TableProps['sortField'],
  sortOrder: TableProps['sortOrder'],
  customDataModifier: ((data: T[]) => T[]) | undefined
) {
  return useMemo<T[]>(() => {
    if (!data) {
      return [];
    }

    // Prevent any mutation from affecting the source data
    let returnVal = _.clone(data);

    if (typeof customDataModifier === 'function') {
      returnVal = customDataModifier(returnVal);
    }

    if (!sortField || !sortOrder) {
      return returnVal;
    }

    return _.clone(returnVal).sort((a: any, b: any) =>
      a[sortField] > b[sortField]
        ? sortOrder
        : b[sortField] > a[sortField]
        ? sortOrder * -1
        : 0
    );
  }, [customDataModifier, data, sortField, sortOrder]);
}

export default useTableDataToDisplay;
