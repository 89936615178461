import { useFormikContext } from 'formik';
import { useCallback } from 'react';

import MySQLCodeEditor from '../../../../../../CodeEditors/MySQL/MySQLCodeEditor';
import { FormFields } from '../AddEditDialog.functions';

function ExpressionTab(): JSX.Element {
  const { values, setFieldValue } = useFormikContext<FormFields>();

  const setValue = useCallback(
    (newValue: string) => setFieldValue('izraz', newValue, false),
    [setFieldValue]
  );

  return (
    <div>
      <MySQLCodeEditor value={values.izraz} onChange={setValue} />
    </div>
  );
}

export default ExpressionTab;
