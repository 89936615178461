import { faCheckCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TFunction } from 'react-i18next';

import { TableColumnHeadersMap, TableColumns } from '../../../../types/util';
import { emptyCell, noColumnStyle } from '../../../../utils/constants/tables';
import { withTrailingEllipsis } from '../../../../utils/helpers/formatting';
import { contextMenuModel } from '../../../../utils/helpers/primereact';
import OverlayHoverPanel from '../../../DataTable/OverlayHoverPanel/OverlayHoverPanel';

export type Tariff = {
  id: number;
  ime: string;
  kod: string;
  opis: string | null;
  izraz: string;
  aktivna: string;
  nsot: string;
  ndot: string;
  nmxt: string;
};

type Row = Tariff;
type Column = TableColumns<
  Pick<
    Row,
    'aktivna' | 'ime' | 'kod' | 'izraz' | 'opis' | 'nsot' | 'ndot' | 'nmxt'
  >
>;

export const tableStorageKey = 'tariffs_tariffs_dataTable';

export function getColumnHeadersMap(
  t: TFunction
): TableColumnHeadersMap<Column> {
  return {
    no: t('No.'),
    aktivna: t('Active'),
    ime: t('Tariff'),
    kod: t('Display name'),
    izraz: t('Expression'),
    opis: t('Description'),
    nsot: t('Naplati ja samo ovaa tarifa'),
    ndot: t('Naplati do ovaa tarifa'),
    nmxt: t('Naplati maksimalna tarifa'),
  };
}

export function additionalColumnProperties(column: Column) {
  switch (column) {
    case 'no':
      return noColumnStyle;

    case 'aktivna':
      return {
        body: (row: Row) => {
          const value = !!parseInt(row[column]);

          return <FontAwesomeIcon icon={value ? faCheckCircle : faTimes} />;
        },
        style: { width: 200 },
        bodyStyle: { width: 200, textAlign: 'center' },
      };

    case 'ime':
      return {
        style: { width: 250 },
        bodyStyle: { width: 250 },
      };

    case 'kod':
      return {
        style: { width: 160 },
        bodyStyle: { width: 160 },
      };

    case 'izraz':
      return {
        body: (row: Row) => (
          <OverlayHoverPanel fullText={row[column]} style={{ maxWidth: 350 }}>
            {withTrailingEllipsis(row[column], 0, 35)}
          </OverlayHoverPanel>
        ),
        style: { width: 300 },
        bodyStyle: { width: 300 },
      };

    case 'opis':
      return {
        body: (row: Row) =>
          !!row[column] ? (
            <span data-tip={row[column]} data-for="tooltip-right">
              {withTrailingEllipsis(row[column]!, 0, 27)}
            </span>
          ) : (
            emptyCell
          ),
        style: { width: 250 },
        bodyStyle: {
          width: 250,
        },
      };

    case 'nsot':
    case 'ndot':
    case 'nmxt':
      return {
        body: (row: Row) => {
          const value = !!parseInt(row[column]);

          return <FontAwesomeIcon icon={value ? faCheckCircle : faTimes} />;
        },
        style: { width: 200 },
        bodyStyle: { width: 200, textAlign: 'center' },
      };

    default:
      return {};
  }
}

export function generateContextMenu(
  t: TFunction,
  isEditShown: boolean,
  handleEdit: () => void,
  isDeleteShown: boolean,
  handleDelete: () => void
) {
  const editOption = {
    label: t('Edit tariff'),
    icon: 'fas fa-edit',
    command: handleEdit,
  };

  const deleteOption = {
    label: t('Delete tariff'),
    icon: 'fas fa-trash',
    command: handleDelete,
  };

  return contextMenuModel([
    [[isEditShown, editOption]],
    [[isDeleteShown, deleteOption]],
  ]);
}

export const mockData: Tariff[] = [
  {
    id: 1,
    ime: 'Пакет 0-1кг Зона1 - 24h',
    kod: '1.1.1.1',
    opis: 'Достава на пакет во Зона 1 со тежина 0 до 1 кг или волумен до 0.07 м3 во рок од 24h',
    proizvod_id: 1,
    usluga_id: 1,
    izraz:
      '({Adresnica.ZonaOdId} <= 2)  AND ({Adresnica.ZonaDoId} <= 2) AND ({Adresnica.Vremenska_ramka_id} IN (2, 3)) AND ({Adresnica.Specijaliziran_proizvod_id} = 0) AND (NOT ({Adresnica.Hub_od_id} > 0)) AND (NOT ({Adresnica.Hub_do_id} > 0)) AND ((({Adresnica.Tezina} > 0.00) AND ({Adresnica.Tezina} <= 1.00)) OR (({Adresnica.Volumen} > 0.00) AND ({Adresnica.Volumen} <= 0.005)))\r\n',
    aktivna: 0,
    nsot: 0,
    ndot: 0,
    nmxt: 1,
  },
  {
    id: 2,
    ime: 'Пакет 3-5кг Зона1 - 24h',
    kod: '1.1.1.2',
    opis: 'Достава на пакет во Зона 1 со тежина 3 до 5 кг или волумен до 0.11 м3 во рок од 24h',
    proizvod_id: 1,
    usluga_id: 1,
    izraz:
      '({Adresnica.ZonaOdId} <= 2)  AND ({Adresnica.ZonaDoId} <= 2) AND ({Adresnica.Vremenska_ramka_id} IN (2, 3)) AND ({Adresnica.Specijaliziran_proizvod_id} = 0) AND ((({Adresnica.Tezina} > 3.00) AND ({Adresnica.Tezina} <= 5.00)) OR (({Adresnica.Volumen} > 0.09) AND ({Adresnica.Volumen} <= 0.11)))',
    aktivna: 0,
    nsot: 0,
    ndot: 0,
    nmxt: 1,
  },
  {
    id: 3,
    ime: 'Пакет 5-10кг Зона1 - 24h',
    kod: '1.1.1.3',
    opis: 'Достава на пакет во Зона 1 со тежина 5 до 10 кг или волумен до 0.13 м3 во рок од 24h',
    proizvod_id: 1,
    usluga_id: 1,
    izraz:
      '({Adresnica.ZonaOdId} <= 2)  AND ({Adresnica.ZonaDoId} <= 2) AND ({Adresnica.Vremenska_ramka_id} IN (2, 3)) AND ({Adresnica.Specijaliziran_proizvod_id} = 0) AND ((({Adresnica.Tezina} > 5.00) AND ({Adresnica.Tezina} <= 10.00)) OR (({Adresnica.Volumen} > 0.11) AND ({Adresnica.Volumen} <= 0.13)))',
    aktivna: 0,
    nsot: 0,
    ndot: 0,
    nmxt: 1,
  },
  {
    id: 4,
    ime: 'Пакет 15-20кг Зона1 - 24h',
    kod: '1.1.1.4',
    opis: 'Достава на пакет во Зона 1 со тежина 15 до 20 кг или волумен до 0.17 м3 во рок од 24h',
    proizvod_id: 1,
    usluga_id: 1,
    izraz:
      '({Adresnica.ZonaOdId} <= 2)  AND ({Adresnica.ZonaDoId} <= 2) AND ({Adresnica.Vremenska_ramka_id} IN (2, 3)) AND ({Adresnica.Specijaliziran_proizvod_id} = 0) AND ((({Adresnica.Tezina} > 15.00) AND ({Adresnica.Tezina} <= 20.00)) OR (({Adresnica.Volumen} > 0.15) AND ({Adresnica.Volumen} <= 0.17)))',
    aktivna: 0,
    nsot: 0,
    ndot: 0,
    nmxt: 0,
  },
  {
    id: 5,
    ime: 'Пакет 20 до 30кг Зона1 - 24h',
    kod: '1.1.1.5',
    opis: 'Достава на пакет во Зона 1 со тежина 20 до 30 кг или волумен до 0.21 м3 во рок од 24h',
    proizvod_id: 1,
    usluga_id: 1,
    izraz:
      '({Adresnica.ZonaOdId} <= 2)  AND ({Adresnica.ZonaDoId} <= 2) AND ({Adresnica.Vremenska_ramka_id} IN (2, 3)) AND ({Adresnica.Specijaliziran_proizvod_id} = 0) AND ((({Adresnica.Tezina} > 20.00) AND ({Adresnica.Tezina} <= 30.00)) OR (({Adresnica.Volumen} > 0.17) AND ({Adresnica.Volumen} <= 0.21)))',
    aktivna: 0,
    nsot: 0,
    ndot: 0,
    nmxt: 0,
  },
  {
    id: 6,
    ime: 'Пакет 30 до 40кг Зона1 - 24h',
    kod: '1.1.1.6',
    opis: 'Достава на пакет во Зона 1 со тежина 30 до 40 кг или волумен до 0.25 м3 во рок од 24h',
    proizvod_id: 1,
    usluga_id: 1,
    izraz:
      '({Adresnica.ZonaOdId} <= 2)  AND ({Adresnica.ZonaDoId} <= 2) AND ({Adresnica.Vremenska_ramka_id} IN (2, 3)) AND ({Adresnica.Specijaliziran_proizvod_id} = 0) AND ((({Adresnica.Tezina} > 30.00) AND ({Adresnica.Tezina} <= 40.00)) OR (({Adresnica.Volumen} > 0.21) AND ({Adresnica.Volumen} <= 0.25)))',
    aktivna: 0,
    nsot: 0,
    ndot: 0,
    nmxt: 0,
  },
  {
    id: 7,
    ime: 'Пакет 40 до 50кг Зона1 - 24h',
    kod: '1.1.1.7',
    opis: 'Достава на пакет во Зона 1 со тежина 40 до 50 кг или волумен до 0.30 м3 во рок од 24h',
    proizvod_id: 1,
    usluga_id: 1,
    izraz:
      '({Adresnica.ZonaOdId} <= 2)  AND ({Adresnica.ZonaDoId} <= 2) AND ({Adresnica.Vremenska_ramka_id} IN (2, 3)) AND ({Adresnica.Specijaliziran_proizvod_id} = 0) AND ((({Adresnica.Tezina} > 40.00) AND ({Adresnica.Tezina} <= 50.00)) OR (({Adresnica.Volumen} > 0.25) AND ({Adresnica.Volumen} <= 0.30)))',
    aktivna: 0,
    nsot: 0,
    ndot: 0,
    nmxt: 0,
  },
  {
    id: 8,
    ime: 'Пакет 0-1кг Зона2 - 72h',
    kod: '1.1.2.1',
    opis: 'Достава на пакет во Зона 2 со тежина 0 до 1 кг или волумен до 0.07 м3 во рок од 72h',
    proizvod_id: 1,
    usluga_id: 1,
    izraz:
      '(({Adresnica.ZonaOdId} = 3)  OR ({Adresnica.ZonaDoId} = 3)) AND ({Adresnica.ZonaOdId} <= 3) AND ({Adresnica.ZonaDoId} <= 3) AND ({Adresnica.Vremenska_ramka_id} = 3) AND ({Adresnica.Specijaliziran_proizvod_id} = 0) AND ((({Adresnica.Tezina} > 0) AND ({Adresnica.Tezina} <= 1.00)) OR (({Adresnica.Volumen} > 0.00) AND ({Adresnica.Volumen} <= 0.07)))',
    aktivna: 0,
    nsot: 0,
    ndot: 0,
    nmxt: 0,
  },
  {
    id: 9,
    ime: 'Пакет 3-5кг Зона2 - 72h',
    kod: '1.1.2.2',
    opis: 'Достава на пакет во Зона 2 со тежина 3 до 5 кг или волумен до 0.11 м3 во рок од 72h',
    proizvod_id: 1,
    usluga_id: 1,
    izraz:
      '(({Adresnica.ZonaOdId} = 3)  OR ({Adresnica.ZonaDoId} = 3)) AND ({Adresnica.ZonaOdId} <= 3) AND ({Adresnica.ZonaDoId} <= 3) AND ({Adresnica.Vremenska_ramka_id} = 3) AND ({Adresnica.Specijaliziran_proizvod_id} = 0) AND ((({Adresnica.Tezina} > 3.00) AND ({Adresnica.Tezina} <= 5.00)) OR (({Adresnica.Volumen} > 0.09) AND ({Adresnica.Volumen} <= 0.11)))',
    aktivna: 0,
    nsot: 0,
    ndot: 0,
    nmxt: 0,
  },
  {
    id: 10,
    ime: 'Пакет 5-10кг Зона2 - 72h',
    kod: '1.1.2.3',
    opis: 'Достава на пакет во Зона 2 со тежина 5 до 10 кг или волумен до 0.13 м3 во рок од 72h',
    proizvod_id: 1,
    usluga_id: 1,
    izraz:
      '(({Adresnica.ZonaOdId} = 3)  OR ({Adresnica.ZonaDoId} = 3)) AND ({Adresnica.ZonaOdId} <= 3) AND ({Adresnica.ZonaDoId} <= 3) AND ({Adresnica.Vremenska_ramka_id} = 3) AND ({Adresnica.Specijaliziran_proizvod_id} = 0) AND ((({Adresnica.Tezina} > 5.00) AND ({Adresnica.Tezina} <= 10.00)) OR (({Adresnica.Volumen} > 0.11) AND ({Adresnica.Volumen} <= 0.13)))',
    aktivna: 0,
    nsot: 0,
    ndot: 0,
    nmxt: 0,
  },
  {
    id: 11,
    ime: 'Пакет 15-20кг Зона2 - 72h',
    kod: '1.1.2.4',
    opis: 'Достава на пакет во Зона 2 со тежина 15 до 20 кг или волумен до 0.17 м3 во рок од 72h',
    proizvod_id: 1,
    usluga_id: 1,
    izraz:
      '(({Adresnica.ZonaOdId} = 3)  OR ({Adresnica.ZonaDoId} = 3)) AND ({Adresnica.ZonaOdId} <= 3) AND ({Adresnica.ZonaDoId} <= 3) AND ({Adresnica.Vremenska_ramka_id} = 3) AND ({Adresnica.Specijaliziran_proizvod_id} = 0) AND ((({Adresnica.Tezina} > 15.00) AND ({Adresnica.Tezina} <= 20.00)) OR (({Adresnica.Volumen} > 0.15) AND ({Adresnica.Volumen} <= 0.17)))',
    aktivna: 0,
    nsot: 0,
    ndot: 0,
    nmxt: 0,
  },
  {
    id: 12,
    ime: 'Пакет 20-30кг Зона2 - 72h',
    kod: '1.1.2.5',
    opis: 'Достава на пакет во Зона 2 со тежина 20 до 30 кг или волумен до 0.21 м3 во рок од 72h',
    proizvod_id: 1,
    usluga_id: 1,
    izraz:
      '(({Adresnica.ZonaOdId} = 3)  OR ({Adresnica.ZonaDoId} = 3)) AND ({Adresnica.ZonaOdId} <= 3) AND ({Adresnica.ZonaDoId} <= 3) AND ({Adresnica.Vremenska_ramka_id} = 3) AND ({Adresnica.Specijaliziran_proizvod_id} = 0) AND ((({Adresnica.Tezina} > 20.00) AND ({Adresnica.Tezina} <= 30.00)) OR (({Adresnica.Volumen} > 0.17) AND ({Adresnica.Volumen} <= 0.21)))',
    aktivna: 0,
    nsot: 0,
    ndot: 0,
    nmxt: 0,
  },
  {
    id: 14,
    ime: 'Пакет 40-50кг Зона2 - 72h',
    kod: '1.1.2.7',
    opis: 'Достава на пакет во Зона 2 со тежина 40 до 50 кг или волумен до 0.30 м3 во рок од 72h',
    proizvod_id: 1,
    usluga_id: 1,
    izraz:
      '(({Adresnica.ZonaOdId} = 3)  OR ({Adresnica.ZonaDoId} = 3)) AND ({Adresnica.ZonaOdId} <= 3) AND ({Adresnica.ZonaDoId} <= 3) AND ({Adresnica.Vremenska_ramka_id} = 3) AND ({Adresnica.Specijaliziran_proizvod_id} = 0) AND ((({Adresnica.Tezina} > 40.00) AND ({Adresnica.Tezina} <= 50.00)) OR (({Adresnica.Volumen} > 0.25) AND ({Adresnica.Volumen} <= 0.30)))',
    aktivna: 0,
    nsot: 0,
    ndot: 0,
    nmxt: 0,
  },
  {
    id: 15,
    ime: 'Пакет 0-1кг Зона2 - 24h',
    kod: '1.1.3.1',
    opis: 'Достава на пакет во Зона 2 со тежина 0 до 1 кг или волумен до 0.07 м3 во рок од 24h',
    proizvod_id: 1,
    usluga_id: 1,
    izraz:
      '(({Adresnica.ZonaOdId} = 3)  OR ({Adresnica.ZonaDoId} = 3)) AND ({Adresnica.ZonaOdId} <= 3) AND ({Adresnica.ZonaDoId} <= 3) AND ({Adresnica.Vremenska_ramka_id} = 2) AND ({Adresnica.Specijaliziran_proizvod_id} = 0) AND ((({Adresnica.Tezina} > 0) AND ({Adresnica.Tezina} <= 1.00)) OR (({Adresnica.Volumen} > 0.00) AND ({Adresnica.Volumen} <= 0.07)))',
    aktivna: 0,
    nsot: 0,
    ndot: 0,
    nmxt: 0,
  },
  {
    id: 17,
    ime: 'Пакет 5-10кг Зона2 - 24h',
    kod: '1.1.3.3',
    opis: 'Достава на пакет во Зона 2 со тежина 5 до 10 кг или волумен до 0.13 м3 во рок од 24h',
    proizvod_id: 1,
    usluga_id: 1,
    izraz:
      '(({Adresnica.ZonaOdId} = 3)  OR ({Adresnica.ZonaDoId} = 3)) AND ({Adresnica.ZonaOdId} <= 3) AND ({Adresnica.ZonaDoId} <= 3) AND ({Adresnica.Vremenska_ramka_id} = 2) AND ({Adresnica.Specijaliziran_proizvod_id} = 0) AND ((({Adresnica.Tezina} > 5.00) AND ({Adresnica.Tezina} <= 10.00)) OR (({Adresnica.Volumen} > 0.11) AND ({Adresnica.Volumen} <= 0.13)))',
    aktivna: 0,
    nsot: 0,
    ndot: 0,
    nmxt: 0,
  },
  {
    id: 19,
    ime: 'Пакет 20-30кг Зона2 - 24h',
    kod: '1.1.3.5',
    opis: 'Достава на пакет во Зона 2 со тежина 20 до 30 кг или волумен до 0.21 м3 во рок од 24h',
    proizvod_id: 1,
    usluga_id: 1,
    izraz:
      '(({Adresnica.ZonaOdId} = 3)  OR ({Adresnica.ZonaDoId} = 3)) AND ({Adresnica.ZonaOdId} <= 3) AND ({Adresnica.ZonaDoId} <= 3) AND ({Adresnica.Vremenska_ramka_id} = 2) AND ({Adresnica.Specijaliziran_proizvod_id} = 0) AND ((({Adresnica.Tezina} > 20.00) AND ({Adresnica.Tezina} <= 30.00)) OR (({Adresnica.Volumen} > 0.17) AND ({Adresnica.Volumen} <= 0.21)))',
    aktivna: 0,
    nsot: 0,
    ndot: 0,
    nmxt: 0,
  },
  {
    id: 70,
    ime: 'Вредносна пратка 1%',
    kod: '4.1.1.1',
    opis: 'Надомест за осигурување на пријавена вресност 1%',
    proizvod_id: 1,
    usluga_id: 4,
    izraz: ' ({Adresnica.Vrednost} > 0)',
    aktivna: 1,
    nsot: 0,
    ndot: 0,
    nmxt: 0,
  },
  {
    id: 71,
    ime: 'Откупнина 1%',
    kod: '4.3.1.1',
    opis: 'Надоместок за откуп 1% од откупнината',
    proizvod_id: 1,
    usluga_id: 2,
    izraz: ' ({Adresnica.Otkup} > 0)',
    aktivna: 1,
    nsot: 0,
    ndot: 0,
    nmxt: 0,
  },
  {
    id: 72,
    ime: 'Пратка со повратен документ',
    kod: '4.4.1.1',
    opis: 'Тарифа за пратки со повратен документ',
    proizvod_id: 1,
    usluga_id: 3,
    izraz: ' {Adresnica.PovratenDokument}  =  1 ',
    aktivna: 1,
    nsot: 0,
    ndot: 0,
    nmxt: 0,
  },
  {
    id: 73,
    ime: 'Пратка со повратен документ',
    kod: '4.4.1.2',
    opis: 'Тарифа за пратки со повратен документ',
    proizvod_id: 2,
    usluga_id: 3,
    izraz: ' {Adresnica.PovratenDokument}  =  2',
    aktivna: 1,
    nsot: 0,
    ndot: 0,
    nmxt: 0,
  },
  {
    id: 78,
    ime: 'Рачна корекција',
    kod: 'Рачна корекција',
    opis: '',
    proizvod_id: 1,
    usluga_id: 51,
    izraz: '(1 = 0)',
    aktivna: 1,
    nsot: 0,
    ndot: 0,
    nmxt: 0,
  },
  {
    id: 100,
    ime: 'Купување со курир',
    kod: '4.2.1.1',
    opis: 'Надоместок за купување со курир',
    proizvod_id: 1,
    usluga_id: 5,
    izraz: '({Adresnica.Kupuva} > 0)',
    aktivna: 1,
    nsot: 0,
    ndot: 0,
    nmxt: 0,
  },
  {
    id: 155,
    ime: 'Терминска достава',
    kod: '1.1.4.1',
    opis: 'Испорака на пратка во одреден термин',
    proizvod_id: 1,
    usluga_id: 1,
    izraz: '({Adresnica.Vremenska_ramka_id} = 4)',
    aktivna: 0,
    nsot: 0,
    ndot: 0,
    nmxt: 0,
  },
  {
    id: 158,
    ime: 'Писмо - Не препорачано',
    kod: '2.1.1.1',
    opis: 'Не препорачано писмо до A3 формат до 300 gr достава 15 работни дена',
    proizvod_id: 2,
    usluga_id: 1,
    izraz: '({Adresnica.Specijaliziran_proizvod_id} = 10)',
    aktivna: 1,
    nsot: 0,
    ndot: 0,
    nmxt: 0,
  },
  {
    id: 159,
    ime: 'Писмо - Препорачано',
    kod: '2.1.1.2',
    opis: 'Препорачано писмо до A3 формат до 300 gr достава 3 работни дена',
    proizvod_id: 2,
    usluga_id: 1,
    izraz: '({Adresnica.Specijaliziran_proizvod_id} = 11)',
    aktivna: 1,
    nsot: 0,
    ndot: 0,
    nmxt: 0,
  },
  {
    id: 160,
    ime: 'Повратна пратка со документ',
    kod: '4.4.1.2',
    opis: 'Пратка за повратниот документ - ова е доставата на повратниот документ до испраќачот',
    proizvod_id: 1,
    usluga_id: 3,
    izraz: '({Adresnica.PovratenDokument} = 2)',
    aktivna: 1,
    nsot: 0,
    ndot: 0,
    nmxt: 0,
  },
  {
    id: 161,
    ime: 'Пратка со повратен документ',
    kod: '4.4.1.1',
    opis: 'Тарифа за пратки со повратен документ',
    proizvod_id: 2,
    usluga_id: 3,
    izraz: '({Adresnica.PovratenDokument} = 1)',
    aktivna: 1,
    nsot: 0,
    ndot: 0,
    nmxt: 0,
  },
  {
    id: 162,
    ime: 'Пратка со повратен документ',
    kod: '4.4.1.2',
    opis: 'Пратка за повратниот документ - ова е доставата на повратниот документ до испраќачот',
    proizvod_id: 2,
    usluga_id: 3,
    izraz: '({Adresnica.PovratenDokument} = 2)',
    aktivna: 1,
    nsot: 0,
    ndot: 0,
    nmxt: 0,
  },
  {
    id: 164,
    ime: 'Пакет 1-3кг Зона1 - 24h',
    kod: '1.1.1.15',
    opis: 'Достава на пакет во Зона 1 со тежина 1 до 3 кг или волумен до 0.09 м3 во рок од 24h',
    proizvod_id: 1,
    usluga_id: 1,
    izraz:
      '({Adresnica.ZonaOdId} <= 2)  AND ({Adresnica.ZonaDoId} <= 2) AND ({Adresnica.Vremenska_ramka_id} IN (2, 3)) AND ({Adresnica.Specijaliziran_proizvod_id} = 0) AND ((({Adresnica.Tezina} > 1.00) AND ({Adresnica.Tezina} <= 3.00)) OR (({Adresnica.Volumen} > 0.07) AND ({Adresnica.Volumen} <= 0.09)))',
    aktivna: 0,
    nsot: 0,
    ndot: 0,
    nmxt: 0,
  },
  {
    id: 165,
    ime: 'Пакет 10-15кг Зона1 - 24h',
    kod: '1.1.1.35',
    opis: 'Достава на пакет во Зона 1 со тежина 10 до 15 кг или волумен до 0.15 м3 во рок од 24h',
    proizvod_id: 1,
    usluga_id: 1,
    izraz:
      '({Adresnica.ZonaOdId} <= 2)  AND ({Adresnica.ZonaDoId} <= 2) AND ({Adresnica.Vremenska_ramka_id} IN (2, 3)) AND ({Adresnica.Specijaliziran_proizvod_id} = 0) AND ((({Adresnica.Tezina} > 10.00) AND ({Adresnica.Tezina} <= 15.00)) OR (({Adresnica.Volumen} > 0.13) AND ({Adresnica.Volumen} <= 0.1554)))',
    aktivna: 0,
    nsot: 0,
    ndot: 0,
    nmxt: 0,
  },
  {
    id: 166,
    ime: 'Пакет 1-3кг Зона2 - 72h',
    kod: '1.1.2.15',
    opis: 'Достава на пакет во Зона 2 со тежина 1 до 3 кг или волумен до 0.09 м3 во рок од 72h',
    proizvod_id: 1,
    usluga_id: 1,
    izraz:
      '(({Adresnica.ZonaOdId} = 3)  OR ({Adresnica.ZonaDoId} = 3)) AND ({Adresnica.ZonaOdId} <= 3) AND ({Adresnica.ZonaDoId} <= 3) AND ({Adresnica.Vremenska_ramka_id} = 3) AND ({Adresnica.Specijaliziran_proizvod_id} = 0) AND ((({Adresnica.Tezina} > 1.00) AND ({Adresnica.Tezina} <= 3.00)) OR (({Adresnica.Volumen} > 0.07) AND ({Adresnica.Volumen} <= 0.09)))',
    aktivna: 0,
    nsot: 0,
    ndot: 0,
    nmxt: 0,
  },
  {
    id: 167,
    ime: 'Пакет 10-15кг Зона2 - 72h',
    kod: '1.1.2.35',
    opis: 'Достава на пакет во Зона 2 со тежина 10 до 15 кг или волумен до 0.15 м3 во рок од 72h',
    proizvod_id: 1,
    usluga_id: 1,
    izraz:
      '(({Adresnica.ZonaOdId} = 3)  OR ({Adresnica.ZonaDoId} = 3)) AND ({Adresnica.ZonaOdId} <= 3) AND ({Adresnica.ZonaDoId} <= 3) AND ({Adresnica.Vremenska_ramka_id} = 3) AND ({Adresnica.Specijaliziran_proizvod_id} = 0) AND ((({Adresnica.Tezina} > 10.00) AND ({Adresnica.Tezina} <= 15.00)) OR (({Adresnica.Volumen} > 0.13) AND ({Adresnica.Volumen} <= 0.15)))',
    aktivna: 0,
    nsot: 0,
    ndot: 0,
    nmxt: 0,
  },
  {
    id: 168,
    ime: 'Пакет 1-3кг Зона2 - 24h',
    kod: '1.1.3.15',
    opis: 'Достава на пакет во Зона 2 со тежина 1 до 3 кг или волумен до 0.09 м3 во рок од 24h',
    proizvod_id: 1,
    usluga_id: 1,
    izraz:
      '(({Adresnica.ZonaOdId} = 3)  OR ({Adresnica.ZonaDoId} = 3)) AND ({Adresnica.ZonaOdId} <= 3) AND ({Adresnica.ZonaDoId} <= 3) AND ({Adresnica.Vremenska_ramka_id} = 2) AND ({Adresnica.Specijaliziran_proizvod_id} = 0) AND ((({Adresnica.Tezina} > 1.00) AND ({Adresnica.Tezina} <= 3.00)) OR (({Adresnica.Volumen} > 0.07) AND ({Adresnica.Volumen} <= 0.09)))',
    aktivna: 0,
    nsot: 0,
    ndot: 0,
    nmxt: 0,
  },
  {
    id: 170,
    ime: 'Лична достава',
    kod: '3.1.1.1',
    opis: 'Лична достава',
    proizvod_id: 3,
    usluga_id: 1,
    izraz: '({Adresnica.Proizvod_id} = 3)',
    aktivna: 1,
    nsot: 0,
    ndot: 0,
    nmxt: 0,
  },
  {
    id: 171,
    ime: 'Експрес достава',
    kod: '3.1.8.7',
    opis: 'ЕП од 0 до 10 кг',
    proizvod_id: 1,
    usluga_id: 1,
    izraz:
      "({Adresnica.Mesto_od_id} IN (111)) AND ({Adresnica.Mesto_do_id} IN (111))  AND (SUBSTRING('{Adresnica.Datum_priem}', 1, 10) = SUBSTRING('{Adresnica.Datum_isporaka}', 1, 10))",
    aktivna: 0,
    nsot: 0,
    ndot: 0,
    nmxt: 0,
  },
  {
    id: 173,
    ime: 'Центар-Аеродром',
    kod: '4.1.1',
    opis: '',
    proizvod_id: 1,
    usluga_id: 1,
    izraz: ' {Adresnica.Reon_od_id} = 1  AND  {Adresnica.Reon_do_id} = 2 ',
    aktivna: 0,
    nsot: 1,
    ndot: 0,
    nmxt: 0,
  },
  {
    id: 175,
    ime: 'Центар-Карпош',
    kod: '4.1.2',
    opis: '',
    proizvod_id: 1,
    usluga_id: 1,
    izraz: '  {Adresnica.Reon_od_id} = 1  AND  {Adresnica.Reon_do_id} = 24 ',
    aktivna: 0,
    nsot: 1,
    ndot: 0,
    nmxt: 0,
  },
  {
    id: 180,
    ime: 'Центар-Центар',
    kod: '4.1.4',
    opis: '',
    proizvod_id: 1,
    usluga_id: 1,
    izraz: ' {Adresnica.Reon_od_id} = 1  AND  {Adresnica.Reon_do_id} = 1\r  ',
    aktivna: 0,
    nsot: 1,
    ndot: 0,
    nmxt: 0,
  },
  {
    id: 289,
    ime: 'Вратен пакет 0-1кг Зона1 - 24h',
    kod: '1.2.1.1',
    opis: 'Враќање на пакет во Зона 1 со тежина 0 до 1 кг или волумен до 0.07 м3 во рок од 24h',
    proizvod_id: 1,
    usluga_id: 1,
    izraz:
      '({Adresnica.OriginReturnShipmentId} > 0) AND ({Adresnica.ZonaOdId} <= 2)  AND ({Adresnica.ZonaDoId} <= 2) AND ({Adresnica.Vremenska_ramka_id} IN (2, 3)) AND ({Adresnica.Specijaliziran_proizvod_id} = 0) AND ((({Adresnica.Tezina} > 0.00) AND ({Adresnica.Tezina} <= 1.00)) OR (({Adresnica.Volumen} > 0.00) AND ({Adresnica.Volumen} <= 0.07)))',
    aktivna: 0,
    nsot: 1,
    ndot: 0,
    nmxt: 0,
  },
  {
    id: 290,
    ime: 'Вратен пакет 3-5кг Зона1 - 24h',
    kod: '1.2.1.2',
    opis: 'Враќање на пакет во Зона 1 со тежина 3 до 5 кг или волумен до 0.11 м3 во рок од 24h',
    proizvod_id: 1,
    usluga_id: 1,
    izraz:
      '({Adresnica.OriginReturnShipmentId} > 0) AND ({Adresnica.ZonaOdId} <= 2)  AND ({Adresnica.ZonaDoId} <= 2) AND ({Adresnica.Vremenska_ramka_id} IN (2, 3)) AND ({Adresnica.Specijaliziran_proizvod_id} = 0) AND ((({Adresnica.Tezina} > 3.00) AND ({Adresnica.Tezina} <= 5.00)) OR (({Adresnica.Volumen} > 0.09) AND ({Adresnica.Volumen} <= 0.11)))',
    aktivna: 0,
    nsot: 1,
    ndot: 0,
    nmxt: 0,
  },
  {
    id: 321,
    ime: 'Писмо - Дрн дрн',
    kod: '2.1.1.3',
    opis: 'Препорачано - непрепорачано',
    proizvod_id: 2,
    usluga_id: 1,
    izraz: '({Adresnica.Specijaliziran_proizvod_id} =13)',
    aktivna: 1,
    nsot: 0,
    ndot: 1,
    nmxt: 0,
  },
  {
    id: 323,
    ime: 'Пакет 0-100 кг база-база',
    kod: '1.1.1.1',
    opis: 'BAZA-BAZA',
    proizvod_id: 1,
    usluga_id: 1,
    izraz:
      '({Adresnica.Specijaliziran_proizvod_id} = 0) AND ({Adresnica.Hub_od_id} > 0) AND ({Adresnica.Hub_do_id} > 0) AND ((({Adresnica.Tezina} > 0) AND ({Adresnica.Tezina} <= 1.00)) OR (({Adresnica.Volumen} > 0.00) AND ({Adresnica.Volumen} <= 0.005)))',
    aktivna: 0,
    nsot: 0,
    ndot: 0,
    nmxt: 0,
  },
  {
    id: 330,
    ime: 'Пакет 0-1кг ЗОНА1',
    kod: '6.1',
    opis: 'Достава на пакет врата/врата во Зона 1 со тежина 0 до 1 кг или волумен до 0.005 м3',
    proizvod_id: 1,
    usluga_id: 1,
    izraz:
      '({Adresnica.ZonaOdId} = 2)  AND ({Adresnica.ZonaDoId} = 2) AND ({Adresnica.Vremenska_ramka_id} IN (2, 3)) AND ({Adresnica.Specijaliziran_proizvod_id} = 0) AND (NOT ({Adresnica.Hub_od_id} > 0)) AND (NOT ({Adresnica.Hub_do_id} > 0)) AND ((({Adresnica.Tezina} > 0.00) AND ({Adresnica.Tezina} <= 1.00)) OR (({Adresnica.Volumen} > 0.00) AND ({Adresnica.Volumen} <= 0.005)))\r\n',
    aktivna: 1,
    nsot: 0,
    ndot: 0,
    nmxt: 0,
  },
  {
    id: 332,
    ime: 'Пакет 0-1кг ЗОНА2',
    kod: '6.2',
    opis: 'Достава на пакет врата/врата во Зона 2 со тежина 0 до 1 кг или волумен до 0.005 м3',
    proizvod_id: 1,
    usluga_id: 1,
    izraz:
      '((({Adresnica.ZonaOdId} = 3)  AND ({Adresnica.ZonaDoId} = 2)) OR (({Adresnica.ZonaOdId} = 2)  AND ({Adresnica.ZonaDoId} = 3))) AND ({Adresnica.Vremenska_ramka_id} IN (2, 3)) AND ({Adresnica.Specijaliziran_proizvod_id} = 0) AND (NOT ({Adresnica.Hub_od_id} > 0)) AND (NOT ({Adresnica.Hub_do_id} > 0)) AND ((({Adresnica.Tezina} > 0) AND ({Adresnica.Tezina} <= 1.00)) OR (({Adresnica.Volumen} > 0.00) AND ({Adresnica.Volumen} <= 0.005)))\r\n',
    aktivna: 1,
    nsot: 0,
    ndot: 0,
    nmxt: 0,
  },
  {
    id: 334,
    ime: 'Пакет 0-1кг база/врата ЗОНА 1',
    kod: '6.4',
    opis: 'Достава на пакет база/врата (ЗОНА 1) со тежина 0 до 1 кг или волумен до 0.005 м3',
    proizvod_id: 1,
    usluga_id: 1,
    izraz:
      '((({Adresnica.ZonaOdId} <= 2) AND  ({Adresnica.Hub_do_id} > 0)) OR (({Adresnica.ZonaDoId} <= 2) AND  ({Adresnica.Hub_od_id} > 0)))  AND ({Adresnica.Specijaliziran_proizvod_id} = 0) AND ((({Adresnica.Hub_od_id} > 0) AND (NOT ({Adresnica.Hub_do_id} > 0))) OR ((NOT ({Adresnica.Hub_od_id} > 0)) AND ({Adresnica.Hub_do_id} > 0))) AND ((({Adresnica.Tezina} > 0) AND ({Adresnica.Tezina} <= 1.00)) OR (({Adresnica.Volumen} > 0.00) AND ({Adresnica.Volumen} <= 0.005)))\r\n',
    aktivna: 1,
    nsot: 0,
    ndot: 0,
    nmxt: 0,
  },
  {
    id: 338,
    ime: 'Пакет 0-1кг база/врата ЗОНА 2',
    kod: '6.5',
    opis: 'Достава на пакет база/врата (ЗОНА 2) со тежина 0 до 1 кг или волумен до 0.005 м3',
    proizvod_id: 1,
    usluga_id: 1,
    izraz:
      '((({Adresnica.ZonaOdId} = 3) AND  ({Adresnica.Hub_do_id} > 0)) OR (({Adresnica.ZonaDoId} = 3) AND  ({Adresnica.Hub_od_id} > 0)))  AND ({Adresnica.Specijaliziran_proizvod_id} = 0) AND ((({Adresnica.Hub_od_id} > 0) AND (NOT ({Adresnica.Hub_do_id} > 0))) OR ((NOT ({Adresnica.Hub_od_id} > 0)) AND ({Adresnica.Hub_do_id} > 0))) AND ((({Adresnica.Tezina} > 0) AND ({Adresnica.Tezina} <= 1.00)) OR (({Adresnica.Volumen} > 0.00) AND ({Adresnica.Volumen} <= 0.005)))\r\n',
    aktivna: 1,
    nsot: 0,
    ndot: 0,
    nmxt: 0,
  },
  {
    id: 342,
    ime: 'Пакет 0-1 кг ЗОНА 2+',
    kod: '6.3',
    opis: 'Достава на пакет врата/врата во Зона 2+ со тежина 0 до 1 кг или волумен до 0.005 м3',
    proizvod_id: 1,
    usluga_id: 1,
    izraz:
      '(({Adresnica.ZonaOdId} = 3) AND ({Adresnica.ZonaDoId} = 3)) AND ({Adresnica.Vremenska_ramka_id} IN (2, 3)) AND ({Adresnica.Specijaliziran_proizvod_id} = 0) AND (NOT ({Adresnica.Hub_od_id} > 0)) AND (NOT ({Adresnica.Hub_do_id} > 0)) AND ((({Adresnica.Tezina} > 0) AND ({Adresnica.Tezina} <= 1.00)) OR (({Adresnica.Volumen} > 0.00) AND ({Adresnica.Volumen} <= 0.005)))',
    aktivna: 1,
    nsot: 0,
    ndot: 0,
    nmxt: 0,
  },
  {
    id: 343,
    ime: 'Пакет 0-1кг база/база',
    kod: '6.6',
    opis: 'Достава на пакет база/база со тежина 0 до 1 кг или волумен до 0.005 м3',
    proizvod_id: 1,
    usluga_id: 1,
    izraz:
      '({Adresnica.Specijaliziran_proizvod_id} = 0) AND ({Adresnica.Hub_od_id} > 0) AND ({Adresnica.Hub_do_id} > 0) AND ((({Adresnica.Tezina} > 0) AND ({Adresnica.Tezina} <= 1.00)) OR (({Adresnica.Volumen} > 0.00) AND ({Adresnica.Volumen} <= 0.005)))\r\n',
    aktivna: 1,
    nsot: 0,
    ndot: 0,
    nmxt: 0,
  },
  {
    id: 344,
    ime: 'Наплати 200 мкд',
    kod: '13',
    opis: 'Тест',
    proizvod_id: 1,
    usluga_id: 1,
    izraz:
      '({Adresnica.ZonaOdId} <= 3)  AND ({Adresnica.ZonaDoId} <= 3) AND ({Adresnica.Vremenska_ramka_id} IN (2, 3)) AND ({Adresnica.Specijaliziran_proizvod_id} = 200) AND ({Adresnica.Tezina} = 0)\r\n',
    aktivna: 1,
    nsot: 0,
    ndot: 0,
    nmxt: 0,
  },
] as any[];
