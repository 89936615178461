import { tryInt } from '../../../../utils/helpers/parse';
import { sortFields } from '../ListTypes.functions';

export function getAllowedErrors(t) {
  return {
    name: {
      empty: t('This field is required'),
    },
  };
}

export function getPaymentDoneByOptions(t) {
  return [
    { label: t('Orderer'), value: 0 },
    { label: t('Recipient'), value: 2 },
    { label: t('Sender'), value: 1 },
  ];
}

export function getNewFieldId() {
  return '_new_' + Math.random() * 100000000000000000;
}

export function setNewFieldsIndexes(fields) {
  for (let i = 0; i < fields.length; i++) {
    fields[i].index = i + 1;
  }
}

export function getPricebookOptions(pricebooks) {
  return [
    {
      label: 'Default pricebook',
      value: 0,
    },
    ...(pricebooks
      ? pricebooks?.data.map((p) => ({ label: p.name ?? '', value: p.id }))
      : []),
  ];
}

export const basicConfigTabFields = {
  name: '',
  klient_isSender: 1,
  changeable_payment: false,

  plaka_postarina_isprakac: 1,
  plaka_otkup_isprakac: 1,
  plaka_povraten_dokument_isprakac: 1,
  plaka_osiguruvanje_isprakac: 1,

  isprakac_cenovnik_id: 0,
  primac_cenovnik_id: 0,
  naracatel_cenovnik_id: 0,
};

export function getFieldMappingTabFields(klient_isSender) {
  let fields = [];

  if (klient_isSender !== 1) {
    fields = [
      ...fields,
      {
        id: getNewFieldId(),
        index: fields.length + 1,
        name: 'Име на испраќач',
        entityname: 'Адресница',
        propertyname: 'klient_od_ime',
        isRequired: 1,
        default_value: '',
      },
      {
        id: getNewFieldId(),
        index: fields.length + 2,
        name: 'Адреса на испраќач',
        entityname: 'Адресница',
        propertyname: 'adresa_od',
        isRequired: 1,
        default_value: '',
      },
      {
        id: getNewFieldId(),
        index: fields.length + 3,
        name: 'Место на испраќач',
        entityname: 'Адресница',
        propertyname: 'mesto_od_ime',
        isRequired: 1,
        default_value: '',
      },
      {
        id: getNewFieldId(),
        index: fields.length + 4,
        name: 'Телефон на испраќач',
        entityname: 'Адресница',
        propertyname: 'telefon_od',
        isRequired: 1,
        default_value: '',
      },
    ];
  }

  if (klient_isSender !== 2) {
    fields = [
      ...fields,
      {
        id: getNewFieldId(),
        index: fields.length + 1,
        name: 'Име на примач',
        entityname: 'Адресница',
        propertyname: 'klient_do_ime',
        isRequired: 1,
        default_value: '',
      },
      {
        id: getNewFieldId(),
        index: fields.length + 2,
        name: 'Адреса на примач',
        entityname: 'Адресница',
        propertyname: 'adresa_do',
        isRequired: 1,
        default_value: '',
      },
      {
        id: getNewFieldId(),
        index: fields.length + 3,
        name: 'Место на примач',
        entityname: 'Адресница',
        propertyname: 'mesto_do_ime',
        isRequired: 1,
        default_value: '',
      },
      {
        id: getNewFieldId(),
        index: fields.length + 4,
        name: 'Телефон на примач',
        entityname: 'Адресница',
        propertyname: 'telefon_do',
        isRequired: 1,
        default_value: '',
      },
    ];
  }

  return {
    fields,
  };
}

export function getInitialValues(
  data = {},
  entities,
  entityProperties,
  isEditDialog
) {
  const klient_isSender =
    data.klient_isSender ?? basicConfigTabFields.klient_isSender;

  const fieldMappingTabFields = getFieldMappingTabFields(klient_isSender);

  const fields = {
    ...basicConfigTabFields,
    ...fieldMappingTabFields,
  };

  let initialValues = {};

  for (let f in fields) {
    initialValues[f] = data[f] ?? fields[f];
  }

  // If there are no fields present in the list, don't overwrite it
  if (isEditDialog) {
    initialValues.fields = data.fields;
  }

  if (Object.keys(data).length) {
    initialValues.changeable_payment = !!parseInt(data.changeable_payment);

    let listTypeFields = data.fields.map((field, i) => {
      let propertySelected = entityProperties.find(
        (property) => property.id === field.property_id
      );

      let entitySelected = entities?.find(
        (entity) => entity.id === tryInt(propertySelected?.entity_id)
      );

      return {
        ...field,
        index: field.index === 0 ? i + 1 : field.index,
        entity_id: propertySelected ? tryInt(propertySelected.entity_id) : null,
        entityname: entitySelected ? entitySelected.displayname : null,
        propertyname: propertySelected?.fieldname,
      };
    });

    initialValues.fields = sortFields(listTypeFields);
  } else {
    initialValues.plaka_otkup_isprakac = 1;
    initialValues.plaka_postarina_isprakac = 1;
    initialValues.plaka_povraten_dokument_isprakac = 1;
    initialValues.plaka_osiguruvanje_isprakac = 1;

    initialValues.isprakac_cenovnik_id = 0;
    initialValues.primac_cenovnik_id = 0;
    initialValues.naracatel_cenovnik_id = 0;

    initialValues.fields = getFieldsByOrdererRole(
      klient_isSender,
      entities,
      entityProperties
    );
  }

  return initialValues;
}

export function getFieldsByOrdererRole(
  klient_isSender,
  entities,
  entityProperties
) {
  const fieldMappingTabFields = getFieldMappingTabFields(klient_isSender);

  const listTypeFields = fieldMappingTabFields.fields.map((field) => {
    const propertySelected = entityProperties?.find(
      (property) => property.fieldname === field.propertyname
    );

    const entitySelected = entities?.find(
      (entity) => entity.displayname === field.entityname
    );

    return {
      ...field,
      entity_id: tryInt(entitySelected?.id) ?? null,
      property_id: tryInt(propertySelected?.id) ?? null,
    };
  });

  return sortFields(listTypeFields);
}

export function toApiData(values) {
  let apiData = {
    ...values,
  };

  apiData.isprakac_cenovnik_id = parseInt(values.isprakac_cenovnik_id);
  apiData.primac_cenovnik_id = parseInt(values.primac_cenovnik_id);
  apiData.naracatel_cenovnik_id = parseInt(values.naracatel_cenovnik_id);

  // Checkboxes
  apiData.changeable_payment = apiData.changeable_payment ? 1 : 0;

  // Fields are sent in a separate request
  delete apiData.fields;

  return apiData;
}
