import './TrackShipmentsByLocation.scss';

import {
  IconDefinition,
  faEdit,
  faSearch,
  faTruck,
  faUserAlt,
  faUsers,
  faWarehouse,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { TFunction } from 'i18next';
import { Panel } from 'primereact/panel';
import { Dispatch, SetStateAction } from 'react';
import { Column } from 'react-table';

import { OrderStatus } from '../../../../enums/orders';
import { OrderLocationCollection } from '../../../../types/api/orders';
import { Unpacked } from '../../../../types/util';
import {
  CellWithTitle,
  DateCell,
} from '../../../../utils/constants/react-table/cells';
import { NoColumn } from '../../../../utils/constants/react-table/columns';
import { contextMenuItemsModel } from '../../../menu/ContextMenu/contextMenu.helpers';

export function getLocationName(t: TFunction, locationId: string) {
  if (locationId === '0') return t('Unknown');
  if (locationId === '1') return t('Sender');
  if (locationId === '2') return t('Courier');
  if (locationId === '3') return t('Transfer Courier');
  if (locationId === '4') return t('Warehouse');
  if (locationId === '5') return t('Recipient');
}

export function getLocationIcon(locationId: string) {
  if (locationId === '1') return faUsers;
  if (locationId === '2') return faUserAlt;
  if (locationId === '3') return faTruck;
  if (locationId === '4') return faWarehouse;
  if (locationId === '5') return faUsers;
}

export function generateTotalShipments(shipments: any) {
  return shipments.reduce(
    (acc: any, item: any) => acc + parseInt(item.total),
    0
  );
}

export type Row = Unpacked<OrderLocationCollection>;

type Status = {
  total: number;
  status_id?: number;
  status_name?: string;
  id?: number;
  label?: any;
};

export type ShipmentLocation = {
  id: string;
  label?: string;
  parent?: string;
  icon?: IconDefinition;
  path?: string;
  items?: ShipmentLocation[];
  entity_id?: number;
  entity_location_id?: number;
  entity_name?: string;
  total?: number | undefined;
  status_id?: OrderStatus;
  status_name?: string;
  statuses?: Status[];
};

export function renderLocations(
  l: ShipmentLocation,
  setOpenPanelGroup: Dispatch<SetStateAction<string[]>>,
  openPanelGroup: string[],
  selectedLocation: string | undefined,
  setSelectedLocation: Dispatch<SetStateAction<string | undefined>>
) {
  const rowClassName = classNames('location', {
    'selected-location': selectedLocation === l.id,
  });
  function template(loc: ShipmentLocation, openPanelGroup: string[]) {
    const toggleIcon = classNames({
      'fas fa-chevron-right': !openPanelGroup.includes(loc.id),
      'fas fa-chevron-down': openPanelGroup.includes(loc.id),
    });

    return (
      <div className={rowClassName}>
        <i
          className={toggleIcon}
          onClick={() => {
            if (openPanelGroup?.includes(loc.id)) {
              const newPanelGroup = openPanelGroup.filter((p) => p !== l.id);
              setOpenPanelGroup(newPanelGroup);
            } else {
              const newPanelGroup = [...openPanelGroup, loc.id];
              setOpenPanelGroup(newPanelGroup);
            }
          }}
        />
        <div
          style={{
            width: 'auto',
          }}
          className="p-d-flex p-jc-start"
          onClick={() => loc.id.includes('-') && setSelectedLocation(loc.id)}
        >
          {loc.icon && (
            <div>
              <FontAwesomeIcon icon={loc.icon} />
            </div>
          )}

          <span className="lp-ml-3">{loc.label}</span>
          {loc.total && <span className="p-ml-3">({loc.total})</span>}
        </div>
      </div>
    );
  }

  if (l.items) {
    return (
      <Panel
        toggleable
        headerTemplate={template(l, openPanelGroup)}
        collapsed={!openPanelGroup?.includes(l.id)}
        onToggle={() => {}}
      >
        {l.items.map((l) => {
          return (
            <div key={l.id}>
              {renderLocations(
                l,
                setOpenPanelGroup,
                openPanelGroup,
                selectedLocation,
                setSelectedLocation
              )}
            </div>
          );
        })}
      </Panel>
    );
  }
  return (
    <div
      id={l.id}
      onClick={() => l.id.includes('-') && setSelectedLocation(l.id)}
      className={rowClassName}
    >
      {l.icon && <FontAwesomeIcon icon={l.icon} />}

      <span className="p-ml-6">{l.label}</span>
      {l.total && <span className="p-ml-3">({l.total})</span>}
    </div>
  );
}

export function getColumns(t: TFunction): Column<Row>[] {
  return [
    NoColumn(t),
    {
      Header: t('Employee Created') as string,
      accessor: 'created_by_full_name',
      Cell: CellWithTitle,
      width: 120,
    },
    {
      Header: t('Employee Modified') as string,
      accessor: 'modified_by_full_name',
      Cell: CellWithTitle,
      width: 120,
    },
    {
      Header: t('Serial No.') as string,
      accessor: 'serial_number',
      Cell: CellWithTitle,
      width: 250,
    },
    {
      Header: t('Status') as string,
      accessor: 'status_name',
      Cell: CellWithTitle,
      width: 200,
    },
    {
      Header: t('Reception date') as string,
      accessor: 'order_reception_date',
      Cell: DateCell,
      width: 200,
    },
    {
      Header: t('Delivery date') as string,
      accessor: 'order_delivery_date',
      Cell: DateCell,
      width: 200,
    },
    {
      Header: t('Sender Name') as string,
      accessor: 'sender_name',
      Cell: CellWithTitle,
      width: 250,
    },
    {
      Header: t('Sender Address') as string,
      accessor: 'sender_address',
      Cell: CellWithTitle,
      width: 250,
    },
    {
      Header: t('Recipient Name') as string,
      accessor: 'recipient_name',
      Cell: CellWithTitle,
      width: 250,
    },
    {
      Header: t('Recipient Address') as string,
      accessor: 'recipient_address',
      Cell: CellWithTitle,
      width: 250,
    },
    {
      Header: t('Comment') as string,
      accessor: 'comment',
      Cell: CellWithTitle,
      width: 200,
    },
  ];
}

export function generateContextMenu(
  t: TFunction,
  handleCMViewClick: () => void,
  readGuard: boolean,
  handleCMEditClick: () => void,
  editGuard: boolean
) {
  const viewOption = {
    label: t('View Details'),
    icon: faSearch,
    action: handleCMViewClick,
  };

  const editOption = {
    label: t('Edit'),
    icon: faEdit,
    action: handleCMEditClick,
  };

  return contextMenuItemsModel([
    [[readGuard, viewOption]],
    [[editGuard, editOption]],
  ]);
}
