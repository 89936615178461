import './CreateEditDialog.scss';

import { Field, FieldArray, useFormikContext } from 'formik';
import { Button } from 'primereact/button';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-lodash-debounce';

import useAxiosHook from '../../../../../hooks/useAxiosHook';
import {
  ClientLookupCollection,
  ClientLookupCollectionQueryParams,
  ClientResource,
} from '../../../../../types/api/clients';
import {
  EmployeeCollection,
  EmployeeResource,
} from '../../../../../types/api/employees';
import { debounceTimeout } from '../../../../../utils/constants/misc';
import { queryString } from '../../../../../utils/helpers/http';
import AutoCompleteInput from '../../../../Forms/AutoCompleteInput/AutoCompleteInput';
import FieldWithErrors from '../../../../Forms/FieldWithErrors/FieldWithErrors';
import { CourierLiabilityResource } from '../../CourierLiabilities.functions';
import { FormValues } from './CreateEditDialog.functions';

type Props = {
  data?: CourierLiabilityResource;
  isEditDialog: boolean;
};

function CreateEditDialogForm({ data, isEditDialog }: Props) {
  const { t } = useTranslation();

  const [courierFilter, setCourierFilter] = useState('');
  const [clientFilter, setClientFilter] = useState('');

  const { values, touched, setFieldValue, setTouched } =
    useFormikContext<FormValues>();

  const debouncedClient = useDebounce(clientFilter, debounceTimeout);
  const debouncedCourier = useDebounce(courierFilter, debounceTimeout);

  const { data: clientData, isLoading: isClientDataLoading } =
    useAxiosHook<ClientLookupCollection>(
      '/clients/lookup' +
        queryString<ClientLookupCollectionQueryParams>({
          ime: debouncedClient,
          page: 1,
          limit: 20,
        }),
      {
        skipWhen: isEditDialog && !!values.client && !touched.client,
      }
    );

  const { data: editClientData, isLoading: isEditClientDataLoading } =
    useAxiosHook<ClientResource>(`/clients/${data?.client_id}`, {
      skipWhen: !isEditDialog || !data?.client_id,
    });

  const { data: courierData, isLoading: isCourierDataLoading } =
    useAxiosHook<EmployeeCollection>(
      '/employees' +
        queryString({
          name: debouncedCourier,
          roles: [3],
          limit: 20,
        }),
      {
        skipWhen: isEditDialog && !!values.postar_id && !touched.postar_id,
      }
    );

  const { data: editCourierData, isLoading: isEditCourierDataLoading } =
    useAxiosHook<EmployeeResource>(`/employees/${data?.postar_id}`, {
      skipWhen: !isEditDialog || !data?.postar_id,
    });

  const courierOptions = useMemo(() => {
    if (values.postar_id && !touched.postar_id && editCourierData?.data) {
      return [
        {
          label: `${editCourierData.data.ime} ${editCourierData.data.prezime}`,
          value: String(editCourierData.data.id),
        },
      ];
    }

    return (
      courierData?.data.map((c) => ({
        label: `${c.ime} ${c.prezime}`,
        value: String(c.id),
      })) ?? []
    );
  }, [
    courierData?.data,
    editCourierData?.data,
    touched.postar_id,
    values.postar_id,
  ]);

  const clientOptions = useMemo(() => {
    if (values.client && !touched.client && editClientData) {
      return [{ label: editClientData.ime, value: String(editClientData.id) }];
    }

    return (
      clientData?.data.map((client) => ({
        label: client.ime,
        value: String(client.id),
      })) ?? []
    );
  }, [clientData?.data, editClientData, touched.client, values.client]);

  useEffect(() => {
    if (touched.postar_id || !editCourierData?.data) {
      return;
    }

    setCourierFilter(
      `${editCourierData.data.ime} ${editCourierData.data.prezime}`
    );
    setFieldValue('postar_id', editCourierData.data.id);
  }, [editCourierData?.data, setFieldValue, touched.postar_id]);

  useEffect(() => {
    if (touched.client || !editClientData) {
      return;
    }

    setClientFilter(editClientData.ime);
    setFieldValue('client', editClientData.id);
  }, [editClientData, setFieldValue, touched.client]);

  const handleCourierFilterChange = useCallback(
    (e) => {
      setTouched({ postar_id: true });
      setCourierFilter(e);
      setFieldValue('postar_ime', e);
    },
    [setFieldValue, setTouched]
  );
  const handleCourierSelectionChange = useCallback(
    (e) => {
      setTouched({ postar_id: true });
      setFieldValue('postar_id', e);
    },
    [setFieldValue, setTouched]
  );

  const handleClientFilterChange = useCallback(
    (e) => {
      setTouched({ client: true });
      setClientFilter(e);
    },
    [setTouched]
  );

  const handleClientSelectionChange = useCallback(
    (e) => {
      setTouched({ client: true });
      setFieldValue('client', e);
    },
    [setFieldValue, setTouched]
  );

  return (
    <div className="courier-liability-form">
      <div className="p-fluid">
        <div className="courier-employee-date">
          <FieldWithErrors name="postar_id" label={t('Courier')}>
            <AutoCompleteInput
              id="postar_id"
              filterValue={courierFilter}
              value={values.postar_id}
              options={courierOptions}
              onFilterChange={handleCourierFilterChange}
              onSelectionChange={handleCourierSelectionChange}
              isLoading={isCourierDataLoading || isEditCourierDataLoading}
              filterDataCy="courier"
              optionsClassName="data-cy-courier-options"
            />
          </FieldWithErrors>

          <FieldWithErrors name="client">
            <AutoCompleteInput
              id="client"
              filterValue={clientFilter}
              value={values.client}
              options={clientOptions}
              onFilterChange={handleClientFilterChange}
              onSelectionChange={handleClientSelectionChange}
              isLoading={isClientDataLoading || isEditClientDataLoading}
              filterDataCy="client"
              optionsClassName="data-cy-client-options"
            />
          </FieldWithErrors>
        </div>
      </div>

      <div className="addresses">
        <FieldWithErrors name="fields" label={t('Addresses')}>
          <FieldArray
            name="fields"
            validateOnChange={false}
            render={(arrayHelpers) => (
              <>
                <div className="p-fluid">
                  <table>
                    {values?.fields?.length > 0 && (
                      <thead>
                        <tr>
                          <th>{t('Prefix')}</th>
                          <th>{t('Initial Serial Number')}</th>
                          <th>{t('End Serial Number')}</th>
                          <th />
                        </tr>
                      </thead>
                    )}
                    <tbody>
                      {values?.fields?.map((_, i) => (
                        <tr key={i}>
                          <td>
                            <div className="p-fluid">
                              <Field
                                name={`fields[${i}].prefix`}
                                className="p-inputtext p-component"
                                placeholder={t('Optional')}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="p-fluid">
                              <Field
                                name={`fields[${i}].seriski_broj_pocetok`}
                                className="p-inputtext p-component"
                                placeholder={t('Required')}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                  setFieldValue(
                                    `fields[${i}].seriski_broj_pocetok`,
                                    e.target.value.replace(/\D/g, '')
                                  )
                                }
                              />
                            </div>
                          </td>
                          <td>
                            <div className="p-fluid">
                              <Field
                                name={`fields[${i}].seriski_broj_kraj`}
                                className="p-inputtext p-component"
                                placeholder={t('Required')}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                  setFieldValue(
                                    `fields[${i}].seriski_broj_kraj`,
                                    e.target.value.replace(/\D/g, '')
                                  )
                                }
                              />
                            </div>
                          </td>

                          <td>
                            {values.fields.length > 1 && (
                              <Button
                                type="button"
                                icon="fas fa-times"
                                tooltip={t('Remove field')}
                                className="p-button-plain p-button-text"
                                onClick={() => {
                                  arrayHelpers.remove(i);
                                }}
                              />
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                {!isEditDialog && (
                  <Button
                    type="button"
                    label={t('New')}
                    icon="fas fa-plus"
                    className="p-mt-2 p-button-outlined p-button-sm"
                    onClick={() =>
                      arrayHelpers.push({
                        prefix: '',
                        seriski_broj_pocetok: '',
                        seriski_broj_kraj: '',
                      })
                    }
                    data-cy="add-row-btn"
                  />
                )}
              </>
            )}
          />
        </FieldWithErrors>
      </div>
    </div>
  );
}

export default CreateEditDialogForm;
