import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { UseAxiosReturn } from '../../../../../../hooks/useAxiosHook';
import { CourierSummaryCollection } from '../../../../../../types/api/orders';
import { tryInt, trySum } from '../../../../../../utils/helpers/parse';
import StatsWrapper from '../../../../Components/StatsWrapper/StatsWrapper';
import CommonStateContext from '../../../_AssignmentAndReception/CommonStateContext';

type Props = {
  request: UseAxiosReturn<CourierSummaryCollection>;
};

function Stats({ request: { data } }: Props): JSX.Element {
  const { t } = useTranslation();

  const { hasCourierWorkOrder, nativeHubId } = useContext(CommonStateContext);

  const summary = data?.[0];
  const hub = summary?.hubs?.find((h) => h.id === nativeHubId);

  const assigned = tryInt(hub?.assignments.completed);
  const remaining = tryInt(hub?.assignments.available);
  const totalExpected = trySum(assigned, remaining);

  return (
    <StatsWrapper>
      <p>
        - {t('Assigned.THEM')}:{' '}
        <b>{hasCourierWorkOrder ? assigned ?? '-' : '-'}</b>
      </p>

      <p>
        - {t('Remaining.THEM')}:{' '}
        <b>{hasCourierWorkOrder ? remaining ?? '-' : '-'}</b>
      </p>

      <p>
        - {t('Expected.THEM')}:{' '}
        <b>{hasCourierWorkOrder ? totalExpected ?? '-' : '-'}</b>
      </p>
    </StatsWrapper>
  );
}

export default Stats;
