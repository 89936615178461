import {
  codWarrantClientCollectionGuard,
  codWarrantCollectionGuard,
  codWarrantNotifyGuard,
  codWarrantResourceGuard,
  editCODWarrantResourceGuard,
  exportToExcelOrCsvGuard,
} from '../_api/cod';
import { hubCollectionGuard } from '../_api/hubs';

export const readWarrant = codWarrantResourceGuard;
export const clientFilter = codWarrantClientCollectionGuard;
export const hubFilter = hubCollectionGuard;
export const exportToExcelOrCsv = exportToExcelOrCsvGuard;

export const sendCodEmail = codWarrantNotifyGuard;

export const editWarrant = editCODWarrantResourceGuard;

export const routeGuard = codWarrantCollectionGuard;
