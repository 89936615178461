import { clientLookupCollectionGuard } from './_api/clients';
import {
  createHubResourceGuard,
  deleteHubResourceGuard,
  editHubResourceGuard,
  hubCollectionGuard,
  hubNotAssignedCollectionGuard,
  hubResourceGuard,
} from './_api/hubs';
import {
  municipalityCollectionGuard,
  placeCollectionGuard,
} from './_api/municipalities';
import {
  placeResourceGuard,
  placesMunicipalityCollectionGuard,
  placesStreetCollectionGuard,
} from './_api/places';

export const readHub = hubResourceGuard;

export const createHub = [
  createHubResourceGuard,
  hubNotAssignedCollectionGuard,
  placeResourceGuard,
  municipalityCollectionGuard,
  placeCollectionGuard,
  placesStreetCollectionGuard,
  clientLookupCollectionGuard,
];

export const editHub = [readHub, editHubResourceGuard];

export const deleteHub = [readHub, deleteHubResourceGuard];

export const municipalityFilter = municipalityCollectionGuard;
export const placesFilter = placesMunicipalityCollectionGuard;

export const routeGuard = hubCollectionGuard;
