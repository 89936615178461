import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { useContext, useState } from 'react';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import ToastContext from '../../../../../context/ToastContext';
import useAxiosHook from '../../../../../hooks/useAxiosHook';
import useMediaQuery from '../../../../../hooks/useMediaQuery';
import usePrevious from '../../../../../hooks/usePrevious';
import { errorToast } from '../../../../../utils/helpers/primereact';
import EmptyMessage from '../../../../DataTable/EmptyMessage/EmptyMessage';
import DialogSpinner from '../../../../Dialogs/DialogSpinner/DialogSpinner';
import { SingleOrder } from '../../Orders.functions';
import OrderStatusProgressBar from './components/OrderStatusProgressBar';
import OrderSummary from './components/OrderSummary';
import RelatedOrderButton from './components/RelatedOrderButton';
import {
  StatusTrackingApiData,
  getTableColumns,
} from './StatusTrackingDialog.functions';
import styles from './StatusTrackingDialog.module.scss';

type Props = {
  visible: boolean;
  data: SingleOrder | undefined;
  isLoading: boolean;
  onHide: () => void;
};

function StatusTrackingDialog({
  visible,
  data,
  isLoading,
  onHide,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const history = useHistory();

  const [showGoBackBtn, setShowGoBackBtn] = useState(false);

  const { toastRef } = useContext(ToastContext);

  const {
    data: orderStatusTrackingData,
    error: orderStatusTrackingError,
    isLoading: isOrderStatusTrackingLoading,
  } = useAxiosHook<StatusTrackingApiData>(
    {
      url: '/orders/status',
      data: { serialnumber: [data?.seriski_broj] },
      method: 'POST',
    },
    {
      skipWhen: !data?.seriski_broj || !visible,
    }
  );

  const prevOrderStatusTrackingError = usePrevious(orderStatusTrackingError);

  useEffect(() => {
    if (
      !orderStatusTrackingError ||
      orderStatusTrackingError === prevOrderStatusTrackingError
    ) {
      return;
    }

    if (toastRef?.current) {
      errorToast(
        toastRef,
        t('Error'),
        t('An error occured while reading status data.')
      );
    }

    onHide();
  }, [
    onHide,
    orderStatusTrackingError,
    prevOrderStatusTrackingError,
    t,
    toastRef,
  ]);

  const rows = useMemo(() => {
    if (data?.seriski_broj) {
      return orderStatusTrackingData?.[data?.seriski_broj]?.history;
    }
  }, [data?.seriski_broj, orderStatusTrackingData]);

  const dialogFooter =
    !isLoading && !isOrderStatusTrackingLoading ? (
      <Button
        onClick={() => onHide()}
        label={t('Close')}
        className="p-button-secondary p-button-text"
        data-cy="close-btn"
      />
    ) : (
      <></>
    );

  const isOnMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Dialog
      header={
        isLoading || isOrderStatusTrackingLoading
          ? t('Loading...')
          : t('Order Tracking - {{serialNumber}}', {
              serialNumber: data?.seriski_broj,
            })
      }
      footer={dialogFooter}
      visible={visible}
      resizable={false}
      maximizable
      maximized={isOnMobile}
      onHide={onHide}
      style={rows?.length === 0 ? { maxWidth: 480 } : {}}
      className="datatable-dialog"
    >
      <div className={styles['shipment-tracking']}>
        {showGoBackBtn && (
          <Button
            type="button"
            icon="fas fa-chevron-left"
            label={t('Go Back')}
            onClick={() => {
              history.goBack();
              setShowGoBackBtn(false);
            }}
            className="p-button-secondary p-button-text"
          />
        )}

        {isLoading || isOrderStatusTrackingLoading ? (
          <DialogSpinner />
        ) : rows?.length === 0 ? (
          <EmptyMessage
            text={t('This order does not have any status data yet')}
          />
        ) : (
          <>
            <p className="mt-0">
              <p className="subtitle p-my-4">
                {t('Order {{serialNo}}', {
                  serialNo: data?.seriski_broj ?? '',
                })}
              </p>
            </p>

            <h4>{t('Status')}</h4>

            <div className="p-my-5">
              <OrderStatusProgressBar
                data={data}
                orderStatusTrackingData={orderStatusTrackingData}
              />
            </div>

            <h4>{t('Order Summary')}</h4>

            <div className="p-mb-5">
              <OrderSummary
                data={data}
                isOnMobile={isOnMobile}
                orderStatusTrackingData={orderStatusTrackingData}
              />

              <RelatedOrderButton
                orderId={data?.return_document_id ?? undefined}
                buttonLabel={t('Return document')}
                setShowGoBackBtn={setShowGoBackBtn}
              />

              <RelatedOrderButton
                orderId={data?.origin_return_document_id ?? undefined}
                buttonLabel={t('Origin order of the return document')}
                setShowGoBackBtn={setShowGoBackBtn}
              />

              <RelatedOrderButton
                orderId={data?.return_shipment_id ?? undefined}
                buttonLabel={t('Return Order')}
                setShowGoBackBtn={setShowGoBackBtn}
              />

              <RelatedOrderButton
                orderId={data?.origin_return_shipment_id ?? undefined}
                buttonLabel={t('Origin order of the returned order')}
                setShowGoBackBtn={setShowGoBackBtn}
              />
            </div>

            <h4>{t('Detailed Status Info')}</h4>

            <DataTable
              value={rows}
              rowHover
              scrollable
              className={styles['datatable-responsive']}
            >
              {getTableColumns(t)}
            </DataTable>
          </>
        )}
      </div>
    </Dialog>
  );
}

export default StatusTrackingDialog;
