import './HubsReports.scss';

import { faWarehouse } from '@fortawesome/free-solid-svg-icons';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SidebarFiltersContext } from '../../../../context/SidebarFiltersContext';
import useAxiosHook from '../../../../hooks/useAxiosHook';
import usePageTitleToggler from '../../../../hooks/usePageTitleToggler';
import useRouteDialog from '../../../../hooks/useRouteDialog';
import { WithData } from '../../../../types/api';
import {
  RoutePaths,
  constructIdRoute,
} from '../../../../utils/constants/routePaths';
import { queryString } from '../../../../utils/helpers/http';
import { httpQueryObject } from '../../../../utils/helpers/misc';
import MainContent from '../../../layout/flex/MainContent';
import Filters from '../../Components/Filters/Filters';
import HeaderPages from '../../Components/HeaderPages/HeaderPages';
import HubCart from './HubCart/HubCart';
import HubReportsDialog from './HubReportsDialog/HubReportsDialog';
import { Hubs } from './HubsReports.functions';
import HubsReportSkeleton from './Skeletons/HubsReportSkeletons';
import useHubsFilters from './useHubsFilters';

function HubsReports() {
  const { t } = useTranslation();
  const [hubData, setHubData] = useState();
  const { isFiltersExpanded } = useContext(SidebarFiltersContext);
  const { filters, headerFiltersCount, resetAllFilters, httpFiltersObj } =
    useHubsFilters(1, 1000);
  const canLoadData = httpFiltersObj.date;

  const {
    show: showReportDataDialog,
    hide: hideReportDataDialog,
    isVisible: isVisibleReportDataDialog,
  } = useRouteDialog(
    RoutePaths.HubsReports,
    constructIdRoute(RoutePaths.HubReportsView, 1)
  );

  usePageTitleToggler(
    t('Hubs Reports'),
    t('Hubs Reports'),
    isVisibleReportDataDialog
  );

  const { data: hubsReportData, isLoading: isLoadingHubsReport } = useAxiosHook<
    WithData<Hubs[]>
  >(
    {
      url:
        '/reports/warehouse/state' +
        queryString(httpQueryObject(httpFiltersObj)),
    },
    {
      skipWhen: !canLoadData,
    }
  );
  console.log('isLoadingHubsReport ', isLoadingHubsReport);
  const {
    data: hubReportData,
    isLoading: isLoadingHubReport,
    reload: reloadHubReportData,
  } = useAxiosHook<any>(
    {
      url: '',
    },
    {
      skipWhen: !hubData,
    }
  );

  function onClickHub(hub: any) {
    console.log(hub);
    // showReportDataDialog();
    // reloadHubReportData({
    //   url: `/hub/${hub.id}`,
    // });
    // console.log(hub);
    // setHubData(hub);
  }

  return (
    <div className="page hubs-reports-page">
      <HeaderPages
        title={t('Hubs Reports')}
        subtitle={t('View and reports of hubs')}
        icon={faWarehouse}
      />

      <HubReportsDialog
        visible={isVisibleReportDataDialog}
        hubData={hubData}
        isLoading={isLoadingHubReport}
        onHide={hideReportDataDialog}
      />

      <Filters
        filters={filters}
        resetAllFilters={resetAllFilters}
        headerFiltersCount={headerFiltersCount}
        filterHeight={160} // change it in div below as well
      />

      <div
        className="hubs-reports-items"
        style={{ height: isFiltersExpanded ? '66vh' : '78vh' }}
      >
        <MainContent>
          {!isLoadingHubsReport && hubsReportData?.data?.length! > 0 ? (
            hubsReportData?.data.map((hub: any) => (
              <HubCart key={hub?.id} hub={hub} onClickHub={onClickHub} />
            ))
          ) : isLoadingHubsReport ? (
            <HubsReportSkeleton />
          ) : !isLoadingHubReport && hubsReportData?.data?.length === 0 ? (
            <div className="empty-reports">
              <h3>{t('No report found for the selected date')}</h3>
            </div>
          ) : (
            <></>
          )}
        </MainContent>
      </div>
    </div>
  );
}

export default HubsReports;
