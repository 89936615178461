import dayjs from 'dayjs';
import _ from 'lodash';
import { TFunction } from 'react-i18next';
import { $enum } from 'ts-enum-util';
import * as Yup from 'yup';

import { IntBool } from '../../../../../enums/booleans';
import { CountryPhone } from '../../../../../enums/countries';
import {
  ReplacementShipment,
  ReturnDocument,
} from '../../../../../enums/orders';
import { Product } from '../../../../../enums/product';
import { WithPagination } from '../../../../../types/api';
import { Numeric } from '../../../../../types/general';
import { LabelValue } from '../../../../../types/options';
import { isStreetFreeInputAllowed } from '../../../../../utils/constants/misc';
import { httpDateFormat } from '../../../../../utils/helpers/formatting';
import {
  tryEnumValue,
  tryInt,
  tryString,
} from '../../../../../utils/helpers/parse';
import {
  formatMobileNumber,
  formatPhoneNumber,
  getPhoneorMobileNumberRegion,
  isValidMobileNumber,
  isValidPhoneNumber,
} from '../../../../../utils/helpers/phoneNumbers';
import {
  conditionallyRequiredField,
  yupMaxField,
  yupRequiredField,
} from '../../../../../utils/helpers/yup';
import { SingleOrder } from '../../Orders.functions';
import { SinglePayment, parseCalculatorData } from './Calculator.functions';

export enum RedemptionReceiver {
  Sender = 'sender',
  Orderer = 'orderer',
}

export enum RedemptionFieldValidation {
  Valid,
  UnregisteredSender,
  NonEmptyInsuredAmount,
}

export enum RedemptionReceiverFieldValidation {
  Valid,
  UnregisteredSender,
  NonEmptyInsuredAmount,
  EmptyRedemption,
  NoThirdParyOrderer,
}

export enum OrderPackaging {
  CardboardBox_300x200x200 = 1,
  CardboardBox_500x300x300,
  CardboardBox_500x300x500,
  SmallBag,
  BigBag,
}

export enum TimeSlot {
  Express = 1,
  NextDay,
  ThreeDays,
  TimeSlot,
}

export function toApiData(
  data: FormValues,
  isEditDialog: boolean = false
): Partial<FormValues> {
  let apiData: Partial<FormValues> = { ...data };

  if (apiData.no_sender) {
    apiData.klient_od_id = '1';
  }

  if (apiData.no_recipient) {
    apiData.klient_do_id = '2';
  }

  if (apiData.orderer === 1) {
    apiData.klient_naracatel_id = apiData.klient_od_id;
  } else if (apiData.orderer === 2) {
    apiData.klient_naracatel_id = apiData.klient_do_id;
  }

  apiData.povraten_dokument = apiData.povraten_dokument
    ? ReturnDocument.HasReturnDocument
    : ReturnDocument.NoReturnDocument;

  apiData.replacement_shipment = apiData.replacement_shipment
    ? ReplacementShipment.HasReplacementShipment
    : ReplacementShipment.NoReplacementShipment;

  delete apiData.no_sender;
  delete apiData.no_recipient;
  delete apiData.orderer;

  delete apiData.quit;
  delete apiData.sender;

  if (!apiData.terminski_datum) {
    delete apiData.terminski_datum;
  } else {
    apiData.terminski_datum = httpDateFormat(apiData.terminski_datum);
  }

  apiData.terminsko_vreme_od = dayjs(apiData.terminsko_vreme_od).format(
    'HH:mm'
  );
  apiData.terminsko_vreme_do = dayjs(apiData.terminsko_vreme_do).format(
    'HH:mm'
  );

  apiData.payments = apiData.payments?.map<SinglePayment | any>((payment) => {
    let paymentsData = {
      gotovina: payment.cash,
      created: payment.created,
      klient_id: payment.customer_id,
      opis: payment.description,
      iznos: payment.quantity * payment.unit_price,
      usluga_ime: payment.service_name,
      tarifa_id: payment.tariff_id,
      kolicina: payment.quantity,
      usluga_id: payment.service_id,
      cenovnik_id: payment.pricelist_id,
      ediznos: payment.unit_price,
      ddv: payment.vat_percent,
      autocalculated: true,
    };

    if (payment.editedPrice !== payment.unit_price) {
      paymentsData.autocalculated = false;
      paymentsData.ediznos = payment.editedPrice;
      paymentsData.iznos = payment.editedPrice * paymentsData.kolicina;
    }

    return paymentsData;
  });

  if (isEditDialog) {
    delete apiData.postarina_plaka;
    delete apiData.povraten_dokument_plaka;
    delete apiData.otkupnina_plaka;
    delete apiData.osiguruvanje_plaka;
  } else {
    delete apiData.terminsko_vreme_od;
    delete apiData.terminsko_vreme_do;
  }

  if (!apiData.delivery_location_type_id) {
    apiData.hub_do_id = null;
  }

  if (!apiData.pickup_location_type_id) {
    apiData.hub_od_id = null;
  }

  if (!apiData.reference_id) {
    apiData.reference_id = null;
  }

  // Display only
  delete apiData.klient_naracatel_ime;
  delete apiData.mesto_naracatel_ime;
  delete apiData.mesto_naracatel_id;
  delete apiData.telefon_naracatel;
  delete apiData.mobilen_naracatel;
  delete apiData.ulica_naracatel_id;
  delete apiData.adresa_naracatel;
  delete apiData.broj_naracatel;
  delete apiData.vlez_naracatel;
  delete apiData.stan_naracatel;

  delete apiData.mesto_od_ime;
  delete apiData.mesto_do_ime;

  if (isStreetFreeInputAllowed) {
    delete apiData.ulica_od_id;
    delete apiData.ulica_do_id;
  } else {
    delete apiData.adresa_od;
    delete apiData.adresa_do;
  }

  delete apiData.pickup_location_type_id;
  delete apiData.delivery_location_type_id;

  apiData.mesto_od_id = tryString(apiData.mesto_od_id);
  apiData.mesto_do_id = tryString(apiData.mesto_do_id);

  apiData.ulica_od_id = tryString(apiData.ulica_od_id);
  apiData.ulica_do_id = tryString(apiData.ulica_do_id);

  for (const key in apiData) {
    if (apiData[key as keyof FormValues] === undefined) {
      delete apiData[key as keyof FormValues];
    }
  }

  // Mobile and phone numbers
  if (apiData.telefon_od) {
    apiData.telefon_od = formatPhoneNumber(
      apiData.telefon_od,
      apiData.telefon_od_region!
    );
  }

  if (apiData.telefon_do) {
    apiData.telefon_do = formatPhoneNumber(
      apiData.telefon_do,
      apiData.telefon_do_region!
    );
  }

  if (apiData.mobilen_od) {
    apiData.mobilen_od = formatMobileNumber(
      apiData.mobilen_od,
      apiData.mobilen_od_region!
    );
  }

  if (apiData.mobilen_do) {
    apiData.mobilen_do = formatMobileNumber(
      apiData.mobilen_do,
      apiData.mobilen_do_region!
    );
  }

  delete apiData.telefon_od_region;
  delete apiData.mobilen_od_region;

  delete apiData.telefon_do_region;
  delete apiData.mobilen_do_region;

  const isRedemptionFilledOut = apiData.otkup && parseFloat(apiData.otkup) > 0;

  // Q: Why cast to any?
  // A: klient_otkup_id in request payload is not of type RedemptionReceiver, but string/number.
  apiData.klient_otkup_id = (
    isRedemptionFilledOut
      ? apiData.klient_otkup_id === RedemptionReceiver.Orderer
        ? apiData.klient_naracatel_id
        : apiData.klient_od_id
      : null
  ) as any;

  // Delete pickup assignment data
  delete apiData.pickupAssignmentCheckbox;
  delete apiData.pickupAssignmentCourier;

  return apiData;
}

type GetInitialValuesProps = {
  data?: SingleOrder;
  isEditDialog: boolean;
  isWarehouseReception?: boolean;
  isGroupDialog: boolean;
  groupOrderData: FormValues;
  initialCreationValues: Partial<FormValues>;
};

export function getInitialValues({
  data,
  isEditDialog,
  isGroupDialog,
  isWarehouseReception,
  groupOrderData,
  initialCreationValues,
}: GetInitialValuesProps): FormValues {
  let initialValues = {
    ...values,
    ...(isEditDialog ? {} : initialCreationValues),
  };

  const isRedemptionFieldValid: boolean =
    isEditDialog &&
    validateRedemptionField(
      initialValues.klient_od_id,
      initialValues.vrednost
    ) === RedemptionFieldValidation.Valid;

  const isRedemptionReceiverFieldValid: boolean =
    isEditDialog &&
    validateRedemptionReceiverField(
      initialValues.klient_od_id,
      initialValues.otkup,
      initialValues.vrednost,
      initialValues.orderer
    ) === RedemptionReceiverFieldValidation.Valid;

  initialValues.otkup = isRedemptionFieldValid
    ? data?.otkup ?? values.otkup
    : values.otkup;

  initialValues.klient_otkup_id = !!data
    ? isRedemptionReceiverFieldValid
      ? initialValues.klient_otkup_id === initialValues.klient_od_id
        ? RedemptionReceiver.Sender
        : RedemptionReceiver.Orderer
      : null
    : RedemptionReceiver.Sender;

  initialValues.mobilen_od_region = getPhoneorMobileNumberRegion(
    initialValues.mobilen_od
  );

  initialValues.telefon_od_region = getPhoneorMobileNumberRegion(
    initialValues.telefon_od
  );

  initialValues.mobilen_do_region = getPhoneorMobileNumberRegion(
    initialValues.mobilen_do
  );

  initialValues.telefon_do_region = getPhoneorMobileNumberRegion(
    initialValues.telefon_do
  );

  if (isWarehouseReception) {
    initialValues.pickup_location_type_id = true;
  }

  if (isGroupDialog) {
    return groupOrderData ?? values;
  }

  if (!isEditDialog) {
    return initialValues;
  }

  initialValues.klient_od_id =
    tryString(data?.klient_od_id) ?? values?.klient_od_id;

  initialValues.klient_od_ime =
    tryString(data?.klient_od_ime) ?? values.klient_od_ime;

  initialValues.mesto_od_id =
    tryString(data?.mesto_od_id) ?? values.mesto_od_id;

  initialValues.mesto_od_ime = data?.mesto_od_ime ?? values.mesto_od_ime;

  initialValues.telefon_od = tryString(data?.telefon_od) ?? values.telefon_od;
  initialValues.mobilen_od = tryString(data?.mobilen_od) ?? values.mobilen_od;

  initialValues.ulica_od_id =
    tryString(data?.ulica_od_id) ?? values.ulica_od_id;
  initialValues.adresa_od = data?.adresa_od ?? values.adresa_od;
  initialValues.broj_od = tryString(data?.broj_od) ?? values.broj_od;
  initialValues.vlez_od = tryString(data?.vlez_od) ?? values.vlez_od;
  initialValues.stan_od = tryString(data?.stan_od) ?? values.stan_od;

  initialValues.klient_do_id =
    tryString(data?.klient_do_id) ?? values.klient_do_id;

  initialValues.klient_do_ime =
    tryString(data?.klient_do_ime) ?? values.klient_do_ime;

  initialValues.mesto_do_id =
    tryString(data?.mesto_do_id) ?? values.mesto_do_id;

  initialValues.mesto_do_ime = data?.mesto_do_ime ?? values.mesto_do_ime;

  initialValues.telefon_do = tryString(data?.telefon_do) ?? values.telefon_do;
  initialValues.mobilen_do = tryString(data?.mobilen_do) ?? values.mobilen_do;
  initialValues.ulica_do_id =
    tryString(data?.ulica_do_id) ?? values.ulica_do_id;
  initialValues.adresa_do = data?.adresa_do ?? values.adresa_do;
  initialValues.broj_do = tryString(data?.broj_do) ?? values.broj_do;
  initialValues.vlez_do = tryString(data?.vlez_do) ?? values.vlez_do;
  initialValues.stan_do = tryString(data?.stan_do) ?? values.stan_do;

  initialValues.no_sender = tryString(data?.klient_od_id) === '1';

  initialValues.no_recipient = tryString(data?.klient_do_id) === '2';

  initialValues.tezina = tryString(data?.tezina) ?? values.tezina;
  initialValues.vrednost =
    (tryString(data?.vrednost) ?? values.vrednost) || '0';
  initialValues.otkup = (tryString(data?.otkup) ?? values.otkup) || '0';
  initialValues.kolicina = tryString(data?.kolicina) ?? values.kolicina;

  initialValues.reference1 = tryString(data?.reference1) ?? values.reference1;
  initialValues.reference2 = tryString(data?.reference2) ?? values.reference2;
  initialValues.reference_id =
    tryString(data?.reference_id) ?? values.reference_id;

  initialValues.povraten_dokument =
    tryInt(data?.povraten_dokument) === 1
      ? 1
      : tryInt(data?.povraten_dokument) === 2
      ? 2
      : 0;

  initialValues.replacement_shipment =
    tryInt(data?.replacement_shipment) === 1
      ? 1
      : tryInt(data?.replacement_shipment) === 2
      ? 2
      : 0;

  initialValues.vremenska_ramka_id = tryInt(data?.vremenska_ramka_id) ?? 2;

  initialValues.komentar = tryString(data?.komentar) ?? values.komentar;
  initialValues.fragile = data?.fragile ?? values.fragile;
  initialValues.two_man_delivery =
    data?.two_man_delivery ?? values.two_man_delivery;
  initialValues.adresnica_service_packaging_id =
    tryEnumValue(data?.adresnica_service_packaging_id, OrderPackaging) ??
    values.adresnica_service_packaging_id;

  initialValues.personal_delivery = tryInt(data?.personal_delivery) ?? 0;

  initialValues.specijaliziran_proizvod_id =
    tryString(data?.specijaliziran_proizvod_id) ??
    values.specijaliziran_proizvod_id;

  initialValues.klient_naracatel_id =
    data?.klient_naracatel_id ?? values.klient_naracatel_id;

  if (
    data?.klient_naracatel_id === tryInt(initialValues.klient_od_id) ||
    data?.klient_naracatel_id === 1
  ) {
    initialValues.orderer = 1;
  } else if (
    data?.klient_naracatel_id === tryInt(initialValues.klient_do_id) ||
    data?.klient_naracatel_id === 2
  ) {
    initialValues.orderer = 2;
  } else {
    initialValues.orderer = 3;
  }

  if (data?.terminsko_vreme_od) {
    const HH = tryInt(data?.terminsko_vreme_od?.substring(0, 2)) ?? 8;
    const mm = tryInt(data?.terminsko_vreme_od?.substring(3, 5)) ?? 0;

    initialValues.terminsko_vreme_od = dayjs().hour(HH).minute(mm).toDate();
  } else {
    initialValues.terminsko_vreme_od = dayjs().hour(8).minute(0).toDate();
  }

  if (data?.terminsko_vreme_do) {
    const HH = tryInt(data?.terminsko_vreme_do?.substring(0, 2)) ?? 8;
    const mm = tryInt(data?.terminsko_vreme_do?.substring(3, 5)) ?? 0;

    initialValues.terminsko_vreme_do = dayjs().hour(HH).minute(mm).toDate();
  } else {
    initialValues.terminsko_vreme_do = dayjs().hour(16).minute(0).toDate();
  }

  initialValues.terminski_datum = dayjs().toDate();

  initialValues.pickup_location_type_id = !!data?.hub_od_id;
  initialValues.delivery_location_type_id = !!data?.hub_do_id;
  initialValues.hub_od_id = data?.hub_od_id ?? values.hub_od_id;
  initialValues.hub_do_id = data?.hub_do_id ?? values.hub_do_id;

  initialValues.payments = parseCalculatorData(data?.payments)?.map(
    (payment) => ({ ...payment, editedPrice: payment.price })
  );

  return initialValues;
}

export function getValidationSchema(t: TFunction) {
  return Yup.object().shape({
    klient_od_id: Yup.mixed()
      .test(
        'klient_od_id',
        t('Sender cannot be the same as recipient.'),
        function (klient_od_id) {
          const { klient_do_id, klient_do_ime, klient_od_ime } = this?.parent;
          return (
            Number(klient_od_id) !== Number(klient_do_id) &&
            klient_od_ime !== klient_do_ime
          );
        }
      )
      .test(
        'klient_od_id',
        t('Sender cannot be the same as orderer.'),
        function (klient_od_id) {
          const {
            klient_naracatel_id,
            klient_od_ime,
            klient_naracatel_ime,
            orderer,
          } = this?.parent;

          if (orderer !== 3) {
            return true;
          }

          return (
            Number(klient_od_id) !== Number(klient_naracatel_id) &&
            klient_od_ime !== klient_naracatel_ime
          );
        }
      ),

    klient_do_id: Yup.mixed()
      .test(
        'klient_do_id',
        t('Recipient cannot be the same as sender.'),
        function (klient_do_id) {
          const { klient_od_id, klient_od_ime, klient_do_ime } = this?.parent;

          return (
            Number(klient_do_id) !== Number(klient_od_id) &&
            klient_do_ime !== klient_od_ime
          );
        }
      )
      .test(
        'klient_do_id',
        t('Recipient cannot be the same as orderer.'),
        function (klient_do_id) {
          const {
            klient_naracatel_id,
            klient_naracatel_ime,
            klient_do_ime,
            orderer,
          } = this?.parent;

          if (orderer !== 3) {
            return true;
          }

          return (
            Number(klient_do_id) !== Number(klient_naracatel_id) &&
            klient_do_ime !== klient_naracatel_ime
          );
        }
      ),

    klient_naracatel_id: Yup.mixed()
      .test(
        'klient_naracatel_id',
        t('Orderer cannot be the same as sender.'),
        function (klient_naracatel_id) {
          const { klient_od_id, klient_od_ime, klient_naracatel_ime, orderer } =
            this?.parent;

          if (orderer !== 3) {
            return true;
          }

          return (
            Number(klient_naracatel_id) !== Number(klient_od_id) &&
            klient_naracatel_ime !== klient_od_ime
          );
        }
      )
      .test(
        'klient_naracatel_id',
        t('Orderer cannot be the same as recipient.'),
        function (klient_naracatel_id) {
          const { klient_do_id, klient_do_ime, klient_naracatel_ime, orderer } =
            this?.parent;

          if (orderer !== 3) {
            return true;
          }

          return (
            Number(klient_naracatel_id) !== Number(klient_do_id) &&
            klient_naracatel_ime !== klient_do_ime
          );
        }
      ),
    klient_od_ime: Yup.string().required(yupRequiredField(t, 'Sender name')),
    mesto_od_id: Yup.string().required(yupRequiredField(t, 'Pickup place')),
    ulica_od_id: conditionallyRequiredField(
      Yup.string(),
      !isStreetFreeInputAllowed,
      yupRequiredField(t, 'Pickup street')
    ),
    adresa_od: conditionallyRequiredField(
      Yup.string(),
      isStreetFreeInputAllowed,
      yupRequiredField(t, 'Pickup street')
    ),
    vlez_od: Yup.string().max(10, ({ max }) =>
      yupMaxField(t, t('Entrance'), max)
    ),

    mobilen_od: Yup.string()
      .test(
        'mobilen_od',
        t('Invalid mobile phone number'),
        (value, context) => {
          if (!value) {
            return true;
          }
          const { mobilen_od_region } = context.parent;
          return isValidMobileNumber(value, mobilen_od_region);
        }
      )
      .test(
        'mobilen_od',
        t('At least mobile or phone number must be filled out'),
        function (value) {
          const { telefon_od } = this?.parent;
          if (!telefon_od) {
            return value != null;
          }

          return true;
        }
      ),

    telefon_od: Yup.string().test(
      'telefon_od',
      t('Invalid phone number'),
      (value, context) => {
        if (!value) {
          return true;
        }
        const { telefon_od_region } = context.parent;
        return isValidPhoneNumber(value, telefon_od_region);
      }
    ),

    telefon_do: Yup.string().test(
      'telefon_do',
      t('Invalid phone number'),
      (value, context) => {
        if (!value) {
          return true;
        }
        const { telefon_do_region } = context.parent;
        return isValidPhoneNumber(value, telefon_do_region);
      }
    ),

    klient_do_ime: Yup.string().required(yupRequiredField(t, 'Recipient name')),
    mesto_do_id: Yup.string().required(yupRequiredField(t, 'Pickup place')),
    ulica_do_id: conditionallyRequiredField(
      Yup.string(),
      !isStreetFreeInputAllowed,
      yupRequiredField(t, 'Pickup street')
    ),
    adresa_do: conditionallyRequiredField(
      Yup.string(),
      isStreetFreeInputAllowed,
      yupRequiredField(t, 'Pickup street')
    ),
    vlez_do: Yup.string().max(10, ({ max }) =>
      yupMaxField(t, t('Entrance'), max)
    ),

    mobilen_do: Yup.string()
      .test(
        'mobilen_do',
        t('Invalid mobile phone number'),
        (value, context) => {
          if (!value) {
            return true;
          }

          const { mobilen_do_region } = context.parent;
          return isValidMobileNumber(value, mobilen_do_region);
        }
      )
      .test(
        'mobilen_do',
        t('At least mobile or phone number must be filled out'),
        function (value) {
          const { telefon_do } = this?.parent;
          if (!telefon_do) {
            return value != null;
          }

          return true;
        }
      ),

    klient_naracatel_ime: Yup.string().test(
      'klient_naracatel_ime',
      yupRequiredField(t, 'Orderer'),
      function () {
        const { klient_naracatel_id, orderer } = this?.parent;
        return !(
          orderer === 3 &&
          (!klient_naracatel_id || klient_naracatel_id === '3')
        );
      }
    ),

    tezina: Yup.string().test('tezina', ' ', (value) => {
      return parseFloat(value ?? '') !== 0 && !!value;
    }),
    fragile: Yup.boolean(),
    two_man_delivery: Yup.boolean(),
    adresnica_service_packaging_id: Yup.mixed<OrderPackaging>().oneOf(
      $enum(OrderPackaging).getValues()
    ),

    payments: Yup.array()
      .required(yupRequiredField(t, t('Payments')))
      .min(1, yupRequiredField(t, t('Payments'))),
  });
}

export function isNumber(candidate: string): boolean {
  const trimmedCandidate = candidate.trim();
  return trimmedCandidate.length > 2 && /^\+?(\s*\d+\s*)*$/.test(candidate);
}

export const arePaymentsEqual = function (
  x: SinglePayment[] | undefined,
  y: SinglePayment[] | undefined
) {
  const isSameSize = _.size(x) === _.size(y);
  return isSameSize && _(x).xorWith(y, _.isEqual).isEmpty();
};

type SenderTabValues = {
  klient_od_id?: Numeric;
  klient_od_ime?: string;
  mesto_od_id?: string;
  mesto_od_ime?: string;
  opstina_od_id?: string;
  opstina_od_ime?: string;
  telefon_od?: string;
  telefon_od_region?: CountryPhone;
  mobilen_od?: string;
  mobilen_od_region?: CountryPhone;
  ulica_od_id?: string;
  broj_od?: string;
  vlez_od?: string;
  stan_od?: string;
};

export const senderTabFields: SenderTabValues = {
  klient_od_id: '',
  klient_od_ime: '',
  mesto_od_id: '',
  mesto_od_ime: '',
  opstina_od_id: '',
  opstina_od_ime: '',
  telefon_od: '',
  mobilen_od: '',
  ulica_od_id: '',
  broj_od: '',
  vlez_od: '',
  stan_od: '',
};

type RecipientTabValues = {
  klient_do_id?: string;
  klient_do_ime?: string;
  mesto_do_id?: string;
  mesto_do_ime?: string;
  opstina_do_id?: string;
  opstina_do_ime?: string;
  telefon_do?: string;
  telefon_do_region?: CountryPhone;
  mobilen_do?: string;
  mobilen_do_region?: CountryPhone;
  ulica_do_id?: string;
  broj_do?: string;
  vlez_do?: string;
  stan_do?: string;
};

export const recipientTabFields: RecipientTabValues = {
  klient_do_id: '',
  klient_do_ime: '',
  mesto_do_id: '',
  mesto_do_ime: '',
  opstina_do_id: '',
  opstina_do_ime: '',
  telefon_do: '',
  mobilen_do: '',
  ulica_do_id: '',
  broj_do: '',
  vlez_do: '',
  stan_do: '',
};

type OrdererTabValues = {
  klient_naracatel_id?: Numeric;
  klient_naracatel_ime?: string;
  mesto_naracatel_id?: string;
  mesto_naracatel_ime?: string;
  telefon_naracatel?: string;
  mobilen_naracatel?: string;
  ulica_naracatel_id?: string;
  adresa_naracatel?: string;
  broj_naracatel?: string;
  vlez_naracatel?: string;
  stan_naracatel?: string;
};

export const ordererTabFields: OrdererTabValues = {
  klient_naracatel_id: '',
  klient_naracatel_ime: '',
  mesto_naracatel_id: '',
  mesto_naracatel_ime: '',
  telefon_naracatel: '',
  mobilen_naracatel: '',
  ulica_naracatel_id: '',
  adresa_naracatel: '',
  broj_naracatel: '',
  vlez_naracatel: '',
  stan_naracatel: '',
};

type AttributesTabValues = {
  proizvod_id?: 1 | 2 | 3;
  tezina: string;
  otkup: string;
  vrednost: string;
  volumen: string;
  kolicina: string;
  specijaliziran_proizvod_id: string | null;
  personal_delivery: IntBool | null;
  povraten_dokument?: 0 | 1 | 2;
  reference1: string;
  reference2: string;
  reference_id: string;
  vremenska_ramka_id: number;
  terminsko_vreme_od: string;
  terminsko_vreme_do: string;
  terminski_datum: string;
  komentar: string;
  fragile: IntBool;
  two_man_delivery: IntBool;
  adresnica_service_packaging_id?: OrderPackaging;
};

export const attributesTabFields: AttributesTabValues = {
  proizvod_id: 1,
  tezina: '1',
  otkup: '0',
  vrednost: '0',
  volumen: '0',
  kolicina: '1',
  personal_delivery: 0,
  specijaliziran_proizvod_id: null,
  povraten_dokument: 0,
  reference1: '',
  reference2: '',
  reference_id: '',
  vremenska_ramka_id: 2,
  terminsko_vreme_od: '',
  terminsko_vreme_do: '',
  terminski_datum: '',
  komentar: '',
  fragile: IntBool.False,
  two_man_delivery: IntBool.False,
};

type PickupAssignmentValues = {
  pickupAssignmentCheckbox: boolean;
  pickupAssignmentCourier: number | null;
};

export const pickupAssignmentValues: PickupAssignmentValues = {
  pickupAssignmentCheckbox: false,
  pickupAssignmentCourier: null,
};

export const values: FormValues = {
  ...senderTabFields,
  ...recipientTabFields,
  ...ordererTabFields,
  ...attributesTabFields,
  ...pickupAssignmentValues,

  orderer: 1,

  payments: [],

  postarina_plaka: 1,
  otkupnina_plaka: 1,
  povraten_dokument_plaka: 1,
  osiguruvanje_plaka: 1,

  postarina_gotovina: 1,
  otkupnina_gotovina: 1,
  povraten_dokument_gotovina: 1,
  osiguruvanje_gotovina: 1,

  no_sender: false,
  no_recipient: true,

  adresa_od: '',
  adresa_do: '',

  pickup_location_type_id: false,
  delivery_location_type_id: false,

  sender: false,

  klient_otkup_id: RedemptionReceiver.Sender,

  calc_price: '0',
};

export type FormValues = SenderTabValues &
  RecipientTabValues &
  OrdererTabValues &
  PickupAssignmentValues & {
    seriski_broj?: number;
    sender?: boolean;
    quit?: boolean;

    orderer?: 1 | 2 | 3;
    no_sender?: boolean;
    no_recipient?: boolean;

    payments?: (SinglePayment & { editedPrice: number })[] | undefined;

    povraten_dokument?: 0 | 1 | 2;
    replacement_shipment?: 0 | 1 | 2;
    proizvod_id?: Product;
    tezina?: string;
    volumen?: string;
    personal_delivery?: IntBool | null;
    specijaliziran_proizvod_id: string | null;
    komentar?: string;
    fragile?: IntBool;
    two_man_delivery?: IntBool;
    adresnica_service_packaging_id?: OrderPackaging;
    otkup?: string;
    vrednost?: string;
    kolicina?: string;

    reference1?: string | null;
    reference2?: string | null;
    reference_id?: string | null;

    postarina_plaka?: number;
    otkupnina_plaka?: number;
    povraten_dokument_plaka?: number;
    osiguruvanje_plaka?: number;

    postarina_gotovina?: number;
    otkupnina_gotovina?: number;
    povraten_dokument_gotovina?: number;
    osiguruvanje_gotovina?: number;

    adresa_od?: string;
    adresa_do?: string;

    hub_od_id?: Numeric | null;

    hub_do_id?: Numeric | null;

    receipt_time_from?: string;
    receipt_time_to?: string;

    terminsko_vreme_od?: string | Date;
    terminsko_vreme_do?: string | Date;
    terminski_datum?: string | Date;

    pickup_location_type_id?: boolean;
    delivery_location_type_id?: boolean;
    vremenska_ramka_id?: number;

    klient_otkup_id: RedemptionReceiver | null;

    calc_price: '0';
  };

export type OrdererType = 'orderer' | 'sender' | 'recipient' | '';

export type SpecialProduct = {
  name?: string;
  id?: Numeric;
};

export type PlaceCollection = WithPagination<
  {
    place_id: number;
    place_name: string;
    place_postal_code: string;
    municipality_id: number;
    municipality_name: string;
  }[]
>;

export type Street = {
  id: number;
  ime: string;
};

export type ConstantFields = {
  isSenderConstant: boolean;
  isRecipientConstant: boolean;
  isOrdererConstant: boolean;
  isAttributesConstant: boolean;
};

export type EditOrderResponse = {
  id?: number;
  error?: string;
};

export function validateRedemptionField(
  klient_od_id: FormValues['klient_od_id'],
  vrednost: FormValues['vrednost']
): RedemptionFieldValidation {
  if (String(klient_od_id) === '1') {
    return RedemptionFieldValidation.UnregisteredSender;
  }

  if (!!vrednost && parseFloat(vrednost) > 0) {
    return RedemptionFieldValidation.NonEmptyInsuredAmount;
  }

  return RedemptionFieldValidation.Valid;
}

export function getRedemptionTooltip(
  t: TFunction,
  validation: RedemptionFieldValidation
): string {
  switch (validation) {
    case RedemptionFieldValidation.UnregisteredSender:
      return t('Redemption is not allowed for unregistered senders.');

    case RedemptionFieldValidation.NonEmptyInsuredAmount:
      return t(
        'Only one of redemption or insured amount can be present at a time.'
      );

    case RedemptionFieldValidation.Valid:
      return '';
  }
}

export function validateRedemptionReceiverField(
  klient_od_id: FormValues['klient_od_id'],
  otkup: FormValues['otkup'],
  vrednost: FormValues['vrednost'],
  orderer: FormValues['orderer']
): RedemptionReceiverFieldValidation {
  if (!klient_od_id || String(klient_od_id) === '1') {
    return RedemptionReceiverFieldValidation.UnregisteredSender;
  }

  if (String(orderer) !== '3') {
    return RedemptionReceiverFieldValidation.NoThirdParyOrderer;
  }

  if (!otkup || parseFloat(otkup) === 0) {
    return RedemptionReceiverFieldValidation.EmptyRedemption;
  }

  if (!!vrednost && parseFloat(vrednost) > 0) {
    return RedemptionReceiverFieldValidation.NonEmptyInsuredAmount;
  }

  return RedemptionReceiverFieldValidation.Valid;
}

export function getRedemptionReceiverTooltip(
  t: TFunction,
  validation: RedemptionReceiverFieldValidation
): string {
  switch (validation) {
    case RedemptionReceiverFieldValidation.NonEmptyInsuredAmount:
      return t(
        'Only one of redemption or insured amount can be present at a time.'
      );

    case RedemptionReceiverFieldValidation.UnregisteredSender:
      return t('Redemption is not allowed for unregistered senders.');

    case RedemptionReceiverFieldValidation.EmptyRedemption:
      return t('Redemption is empty.');

    case RedemptionReceiverFieldValidation.NoThirdParyOrderer:
      return t(
        'Sender is the only applicable value, as no third party orderer is present.'
      );

    case RedemptionReceiverFieldValidation.Valid:
      return '';
  }
}

export function getTimeSlotOptions(t: TFunction) {
  return [
    { label: t('Express'), value: TimeSlot.Express },
    { label: t('Next Day'), value: TimeSlot.NextDay },
    { label: t('3 days'), value: TimeSlot.ThreeDays },
    { label: t('Time slot'), value: TimeSlot.TimeSlot },
  ];
}

export function getOrderPackagingOptions(
  t: TFunction
): LabelValue<OrderPackaging>[] {
  return [
    {
      label: t('CardboardBox_300x200x200'),
      value: OrderPackaging.CardboardBox_300x200x200,
    },
    {
      label: t('CardboardBox_500x300x300'),
      value: OrderPackaging.CardboardBox_500x300x300,
    },
    {
      label: t('CardboardBox_500x300x500'),
      value: OrderPackaging.CardboardBox_500x300x500,
    },
    {
      label: t('SmallBag'),
      value: OrderPackaging.SmallBag,
    },
    {
      label: t('BigBag'),
      value: OrderPackaging.BigBag,
    },
  ];
}

export function getOrderReturnDocumentOptions(
  t: TFunction
): LabelValue<number>[] {
  return [
    {
      label: t('No return document'),
      value: 0,
    },
    {
      label: t('Announcement for a returned document'),
      value: 1,
    },
    {
      label: t('Return document'),
      value: 2,
    },
  ];
}
