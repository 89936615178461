import { Accordion, AccordionTab } from 'primereact/accordion';
import { InputText } from 'primereact/inputtext';
import { Dispatch, useMemo } from 'react';
import { useState } from 'react';
import { SetStateAction } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDebounce } from 'use-lodash-debounce';

import useHaveValuesChanged from '../../../hooks/useHaveValuesChanged';
import useSearchQueryParam from '../../../hooks/useSearchQueryParam';
import { debounceTimeout } from '../../../utils/constants/misc';
import { queryString } from '../../../utils/helpers/http';
import { getSearchQueryParam } from '../../../utils/helpers/searchQuery';
import FiltersCounter from '../Components/Filters/FiltersCounter';

function useTableFilters(
  page: number,
  setPage: Dispatch<SetStateAction<number>>,
  limit: number
) {
  const { t } = useTranslation();
  const location = useLocation();
  const [nameFilter, setNameFilter] = useState<string>(
    getSearchQueryParam(location.search, 'name') ?? ''
  );

  const debouncedNameFilter = useDebounce(nameFilter, debounceTimeout);

  const filtersArr = useMemo(
    () => [debouncedNameFilter],
    [debouncedNameFilter]
  );

  const haveFiltersChanged = useHaveValuesChanged(filtersArr);

  useEffect(() => {
    setPage(1);
  }, [debouncedNameFilter, setPage]);

  useSearchQueryParam('name', debouncedNameFilter);

  const httpFiltersObj = useMemo(
    () =>
      queryString({
        page: haveFiltersChanged ? 1 : page,
        limit,
        name: debouncedNameFilter,
      }),
    [debouncedNameFilter, haveFiltersChanged, limit, page]
  );

  const basicFiltersActiveFilterCount = Object.values({
    nameFilter,
  }).filter(Boolean).length;

  const filters = useMemo<JSX.Element>(
    () => (
      <Accordion multiple activeIndex={[0]}>
        <AccordionTab
          disabled
          headerTemplate={
            <FiltersCounter
              description={t('Basic filters')}
              counter={basicFiltersActiveFilterCount}
            />
          }
        >
          <div className="sidebar_filter">
            <label htmlFor="filter_name">{t('Name')}</label>
            <InputText
              id="filter_name"
              value={nameFilter}
              onChange={(e) => setNameFilter(e.target.value)}
            />
          </div>
        </AccordionTab>
      </Accordion>
    ),
    [nameFilter, basicFiltersActiveFilterCount, t]
  );

  const headerFiltersCount = useMemo(
    () => basicFiltersActiveFilterCount,
    [basicFiltersActiveFilterCount]
  );

  function resetAllFilters() {
    setNameFilter('');
  }

  return {
    filters,
    resetAllFilters,
    httpFiltersObj,
    headerFiltersCount,
  };
}

export default useTableFilters;
