import {
  faExclamationTriangle,
  faMinus,
} from '@fortawesome/free-solid-svg-icons';
import { ColumnProps } from 'primereact/column';
import { TFunction } from 'react-i18next';

import {
  getFailedOrderReasonLabel,
  getOrderStatusConfig,
} from '../../../../../../../configs/orders';
import {
  ParcelSeverity,
  ReturnedOrderCheck,
} from '../../../../../../../enums/orders';
import { CourierReceptionReturnedCollection } from '../../../../../../../types/api/orders';
import { ContextMenuItem } from '../../../../../../../types/primereact';
import {
  TableColumnHeadersMap,
  TableColumns,
  Unpacked,
} from '../../../../../../../types/util';
import {
  emptyCell,
  noColumnStyle,
} from '../../../../../../../utils/constants/tables';
import {
  booleanCell,
  dateCell,
} from '../../../../../../../utils/helpers/dataTable';
import { currencyFormat } from '../../../../../../../utils/helpers/formatting';
import { tryEnumValue } from '../../../../../../../utils/helpers/parse';
import { contextMenuModel } from '../../../../../../../utils/helpers/primereact';

export type Row = Unpacked<CourierReceptionReturnedCollection>;

type Column = TableColumns<
  Pick<
    Row,
    | 'serial_number'
    | 'status_id'
    | 'editable'
    | 'in_courier'
    | 'reason_id'
    | 'specified_next_delivery'
    | 'next_delivery'
    | 'check_id'
    | 'sender_name'
    | 'sender_place_name'
    | 'recipient_name'
    | 'recipient_place_name'
    | 'weight'
    | 'redemption'
    | 'warehouseman_name'
    | 'receive_date'
  >
>;

export const tableStorageKey =
  'warehouse_courierShipmentReceptionReturnedShimpents_dataTable';

export function getColumnHeadersMap(
  t: TFunction
): TableColumnHeadersMap<Column> {
  return {
    no: t('No.'),
    serial_number: t('Serial No.'),
    status_id: t('Status'),
    editable: t('Editable'),
    in_courier: t('In courier'),
    reason_id: t('Return Reason'),
    specified_next_delivery: t('Too early'),
    next_delivery: t('Next Delivery'),
    check_id: t('Check'),
    sender_name: t('Sender'),
    sender_place_name: t('Pickup place'),
    recipient_name: t('Recipient'),
    recipient_place_name: t('Delivery Location'),
    weight: t('Weight'),
    redemption: t('Redemption'),
    receive_date: t('Warehouse Date'),
    warehouseman_name: t('Received by.SHE'),
  };
}

export function additionalColumnOptions(t: TFunction) {
  const orderStatusConfig = getOrderStatusConfig(t);
  const returnReasons = getFailedOrderReasonLabel(t);

  return (column: Column): ColumnProps => {
    switch (column) {
      case 'no':
        return noColumnStyle;

      case 'serial_number':
        return {
          style: { width: 200 },
          bodyStyle: { width: 200 },
        };

      case 'status_id':
        return {
          body: (row: Row) => orderStatusConfig[row[column]].label,
          style: { width: 300 },
          bodyStyle: { width: 300 },
        };

      case 'editable':
        return {
          body: (row: Row) => booleanCell({ t, value: row[column] }),
          style: { width: 200 },
          bodyStyle: { width: 200 },
        };

      case 'in_courier':
        return {
          body: (row: Row) => booleanCell({ t, value: row[column] }),
          style: { width: 120 },
          bodyStyle: { width: 120 },
        };

      case 'reason_id':
        return {
          body: (row: Row) =>
            row.reason_id ? returnReasons[row.reason_id] : emptyCell,
          style: { width: 260 },
          bodyStyle: { width: 260 },
        };

      case 'specified_next_delivery':
        return {
          body: (row: Row) =>
            booleanCell({
              t,
              value: row[column],
              trueIcon: faExclamationTriangle,
              trueTooltip: t(
                'Too early for reception! Please check the specified date for next delivery!'
              ),
              falseIcon: faMinus,
            }),
          style: { width: 200 },
          bodyStyle: { width: 200 },
        };

      case 'next_delivery':
        return {
          body: (row: Row) => dateCell(row[column]),
          style: { width: 200 },
          bodyStyle: { width: 200 },
        };

      case 'check_id':
        return {
          body: (row: Row) =>
            booleanCell({
              t,
              value: row[column] === ReturnedOrderCheck.Yes,
              trueIcon: faExclamationTriangle,
            }),
          style: { width: 150 },
          bodyStyle: { width: 150, textAlign: 'center' },
        };

      case 'sender_name':
        return {
          style: { width: 250 },
          bodyStyle: { width: 250 },
        };

      case 'sender_place_name':
        return {
          style: { width: 250 },
          bodyStyle: { width: 250 },
        };

      case 'recipient_name':
        return {
          style: { width: 250 },
          bodyStyle: { width: 250 },
        };

      case 'recipient_place_name':
        return {
          style: { width: 250 },
          bodyStyle: { width: 250 },
        };

      case 'weight':
        return {
          style: { width: 120 },
          bodyStyle: { width: 120 },
        };

      case 'redemption':
        return {
          body: (row: Row) =>
            currencyFormat(row[column], { showCurrency: true }),
          style: { width: 200 },
          bodyStyle: { width: 200 },
        };

      case 'warehouseman_name':
        return {
          style: { width: 250 },
          bodyStyle: { width: 250 },
        };

      case 'receive_date':
        return {
          body: (row: Row) => dateCell(row[column]),
          style: { width: 200 },
          bodyStyle: { width: 200 },
        };
    }
  };
}

export function generateContextMenu({
  t,
  onViewClick,
  isReceiveShown,
  onReceiveClick,
  isEditShown,
  onEditClick,
  isCancelShown,
  onCancelClick,
}: {
  t: TFunction;
  onViewClick: () => void;
  isReceiveShown: boolean;
  onReceiveClick: () => void;
  isEditShown: boolean;
  onEditClick: () => void;
  isCancelShown: boolean;
  onCancelClick: () => void;
}): ContextMenuItem[] {
  const viewOption = {
    label: t('View'),
    icon: 'fas fa-search',
    command: onViewClick,
  };

  const receiveOption: ContextMenuItem = {
    label: t('Receive'),
    icon: 'fas fa-barcode',
    command: onReceiveClick,
  };

  const editOption = {
    label: t('Edit'),
    icon: 'fas fa-edit',
    command: onEditClick,
  };

  const cancelOption = {
    label: t('Cancel reception'),
    icon: 'fas fa-ban',
    command: onCancelClick,
  };

  return contextMenuModel([
    [[true, viewOption]],
    [[isReceiveShown, receiveOption]],
    [[isEditShown, editOption]],
    [[isCancelShown, cancelOption]],
  ]);
}

export function getRowClassName(row: Row) {
  const severityStatus =
    tryEnumValue(row.assignment_status_severity, ParcelSeverity) ?? 0;

  const isForCheck = row.check_id === ReturnedOrderCheck.Yes;

  return {
    'bg-green-100': severityStatus === ParcelSeverity.SUCCESS && !isForCheck,
    'bg-yellow-400': severityStatus === ParcelSeverity.WARNING || isForCheck,
    'bg-red-100': severityStatus === ParcelSeverity.ERROR,
  };
}
