import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  getEmailActionLabels,
  getEmailRecipientLabels,
} from '../../../../../../../configs/email';
import useErrorState from '../../../../../../../hooks/formik/useErrorState';
import { FormFields } from '../../CreateEditDialog.types';
import Action from './Action';

function EmailConfiguration(): JSX.Element {
  const { t } = useTranslation();

  const emailActionLabels = useMemo(() => getEmailActionLabels(t), [t]);
  const emailRecipientLabels = useMemo(() => getEmailRecipientLabels(t), [t]);

  const { error, parentClassName } = useErrorState<FormFields>(
    'email_configuration'
  );

  return (
    <article className={parentClassName}>
      {Object.keys(emailActionLabels).map((key) => (
        <Action
          key={key}
          emailAction={parseInt(key)}
          emailActionLabels={emailActionLabels}
          emailRecipientLabels={emailRecipientLabels}
        />
      ))}

      {error}
    </article>
  );
}

export default EmailConfiguration;
