import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { RoutePaths } from '../../utils/constants/routePaths';
import logos from '../../utils/logos';
import LanguageSelector from './LanguageSelector';

function UnauthHeader(): JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      <Link to="/" className="logo">
        <img
          src={
            logos[process.env.REACT_APP_CLIENT_NAME as keyof typeof logos] ??
            logos['koder']
          }
          alt="Logo"
        />
      </Link>

      <div className="left-section">
        <Link to={RoutePaths.Login}>{t('Login')}</Link>
      </div>

      <div className="right-section">
        <LanguageSelector />
      </div>
    </>
  );
}

export default UnauthHeader;
