import { EndpointGuard } from '../../helpers/auth';
import {
  courierDataGuard,
  courierListCollectionGuard,
  regionPickUpDataGuard,
  regionShipmentOrderCallDataGuard,
} from './_api/statistics';

export const bestCouriersChart = courierDataGuard;
export const deliveredParcelsChart = courierDataGuard;
export const deliveredParcelsPerRegionChart = regionShipmentOrderCallDataGuard;
export const financesChart = courierDataGuard;
export const pickedUpParcelsChart = [
  courierListCollectionGuard,
  courierDataGuard,
];
export const pickedUpParcelsPerRegionChart = regionPickUpDataGuard;

export const routeGuard: EndpointGuard = {
  oneOf: [
    bestCouriersChart,
    deliveredParcelsChart,
    deliveredParcelsPerRegionChart,
    financesChart,
    pickedUpParcelsChart,
    pickedUpParcelsPerRegionChart,
  ],
};
