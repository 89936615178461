import './Settings.scss';

import { faTools } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Formik } from 'formik';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { useEffect, useRef } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Redirect, useLocation } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import useAxios from '../../../hooks/useAxios';
import useAxiosHook from '../../../hooks/useAxiosHook';
import usePageTitle from '../../../hooks/usePageTitle';
import { RoutePaths } from '../../../utils/constants/routePaths';
import AuthRoute from '../../Routes/AuthRoute';
import SentryRoute from '../../Routes/SentryRoute';
import HeaderPages from '../Components/HeaderPages/HeaderPages';
import { getRoutes } from './routes';
import { getInitialValues, toApiData } from './Settings.functions';

function Settings() {
  const { t } = useTranslation();

  const settingsRoutes = useMemo(() => getRoutes(t), [t]);

  const location = useLocation();

  const currentRoute = settingsRoutes.find(
    (r) => r.path === location.pathname.replace(RoutePaths.Settings, '')
  );

  usePageTitle(
    currentRoute
      ? t('{{currentRoute}} | GLS Settings', {
          currentRoute: currentRoute.title,
        })
      : t('GLS Settings')
  );

  const formRef = useRef(null);
  const toastRef = useRef(null);

  const {
    data: settings,
    reload: reloadSettings,
    isLoading,
  } = useAxiosHook('/configs');

  const { reload: settingsRequest, isLoading: isSettingsRequestLoading } =
    useAxios(undefined, undefined, {
      method: 'PUT',
      successCallback: () => {
        reloadSettings();

        toastRef.current.show({
          severity: 'success',
          summary: t('Status change'),
          detail: t('A field has been successfully updated'),
          life: 5000,
        });
      },
      errorCallback: () => {
        toastRef.current.show({
          severity: 'error',
          summary: t('Error'),
          detail: t(
            'An error occured while trying to update a settings field.'
          ),
          life: 5000,
        });
      },
    });

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [location.pathname]);

  function handleFormSubmision() {
    const fields = toApiData(
      settings,
      formRef.current.values,
      currentRoute.section
    );

    for (let key of Object.keys(fields)) {
      settingsRequest({
        url: `/configs/${key}`,
        payload: {
          value: fields[key],
        },
      });
    }
  }

  return (
    <div className="page settings-page">
      <HeaderPages
        title={`GLS ${t('Settings')}`}
        subtitle={t('Configure post settings')}
        icon={faTools}
      />

      <div className="settings-container p-shadow-1">
        <div className="settings-links-container">
          {settingsRoutes.map((r) => (
            <Link
              key={r.path}
              to={`${RoutePaths.Settings}${r.path}`}
              className={`settings-link ${
                `${RoutePaths.Settings}${r.path}` === location.pathname
                  ? 'active'
                  : ''
              }`}
            >
              <span className="link-container">
                <span className="link-icon">
                  <FontAwesomeIcon icon={r.icon} />
                </span>
                <span className="link-title-description">
                  <span className="link-title">{r.title}</span>
                  <br />
                  <span className="link-description">{r.desc}</span>
                </span>
              </span>
            </Link>
          ))}
        </div>

        <div className="settings-content">
          <h2 className="mt-0">{currentRoute?.title}</h2>

          <div className="settings-content-wrapper">
            <Formik
              innerRef={formRef}
              initialValues={getInitialValues(t, settings)}
              enableReinitialize
              onSubmit={handleFormSubmision}
            >
              <Form>
                <SentryRoute
                  exact
                  path={RoutePaths.Settings}
                  render={() => <Redirect to="/settings/parcels" />}
                />

                {settingsRoutes.map((r) => {
                  const RouteComponent = r.component;

                  return (
                    <AuthRoute
                      key={r.path}
                      path={`${RoutePaths.Settings}${r.path}`}
                    >
                      <RouteComponent section={r.section} />
                    </AuthRoute>
                  );
                })}
              </Form>
            </Formik>

            <div
              className={
                currentRoute?.section === 'Features'
                  ? 'hide-button-section'
                  : 'button-container'
              }
            >
              <Button
                type="button"
                label={
                  isSettingsRequestLoading
                    ? t('Saving...')
                    : isLoading
                    ? t('Loading...')
                    : t('Save changes')
                }
                onClick={() => formRef.current.handleSubmit()}
                disabled={isLoading || isSettingsRequestLoading}
              />

              <Button
                type="button"
                icon="fas fa-sync-alt"
                className="p-button-outlined"
                tooltip={t('Reload settings')}
                onClick={() => {
                  formRef.current.resetForm();
                  reloadSettings();
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <Toast ref={toastRef} />
    </div>
  );
}

export default Settings;
