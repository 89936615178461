import './CreateEditDialog.scss';

import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, useFormikContext } from 'formik';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

import useAxios from '../../../../hooks/useAxios';
import FieldWithErrors from '../../../Forms/FieldWithErrors/FieldWithErrors';
import {
  getFieldsByOrdererRole,
  getPaymentDoneByOptions,
  getPricebookOptions,
} from './CreateEditDialog.functions';

function CreateEditDialogBasicConfigTab({ entities, entityProperties }) {
  const { t } = useTranslation();

  const paymentDoneByOptions = useMemo(() => getPaymentDoneByOptions(t), [t]);

  const { values, setFieldValue } = useFormikContext();

  const { data: pricebooks, isLoading: isPricebooksLoading } = useAxios(
    '/price-lists?limit=0'
  );

  return (
    <div className="basic-config-tab p-fluid">
      <FieldWithErrors name="name" label={t('Name')}>
        <Field as={InputText} id="name" name="name" data-cy="name" />
      </FieldWithErrors>

      <FieldWithErrors name="klient_isSender" label={t('Orderer role')}>
        <Field
          name="klient_isSender"
          as={Dropdown}
          inputId="klient_isSender"
          options={paymentDoneByOptions}
          className="data-cy-orderer_role"
          onChange={(e) => {
            setFieldValue('klient_isSender', e.target.value);

            setFieldValue(
              'fields',
              getFieldsByOrdererRole(e.target.value, entities, entityProperties)
            );
          }}
        />
      </FieldWithErrors>

      <span className="form-group-title">{t('Payment configuration')}</span>

      <FieldWithErrors name="changeable_payment" label={false}>
        <Field
          name="changeable_payment"
          as={Checkbox}
          inputId="changeable_payment"
          checked={values.changeable_payment}
          onChange={(e) => {
            const checked = e.target.checked;

            setFieldValue('changeable_payment', checked);

            if (!checked) {
              setFieldValue('plaka_postarina_isprakac', 1);
              setFieldValue('plaka_otkup_isprakac', 1);
              setFieldValue('plaka_povraten_dokument_isprakac', 1);
              setFieldValue('plaka_osiguruvanje_isprakac', 1);
            }
          }}
        />

        <label htmlFor="changeable_payment" data-cy="changeable_payment">
          {t('Variable payment')}
        </label>

        <div className="info-icon">
          <FontAwesomeIcon
            icon={faExclamationCircle}
            data-tip
            data-for="info-payment"
          />
          <ReactTooltip
            id="info-payment"
            place="top"
            type="dark"
            effect="float"
          >
            <span>
              {t('Payment configuration can {{status}} changed during import', {
                status: !values.changeable_payment ? t('not') : t('be'),
              })}
            </span>
          </ReactTooltip>
        </div>
      </FieldWithErrors>

      <FieldWithErrors name="plaka_postarina_isprakac" label={t('Postage')}>
        <Field
          name="plaka_postarina_isprakac"
          as={Dropdown}
          inputId="plaka_postarina_isprakac"
          options={paymentDoneByOptions}
          className="data-cy-postage"
        />
      </FieldWithErrors>

      <FieldWithErrors name="plaka_otkup_isprakac" label={t('Redemption')}>
        <Field
          name="plaka_otkup_isprakac"
          as={Dropdown}
          inputId="plaka_otkup_isprakac"
          options={paymentDoneByOptions}
          className="data-cy-redemption"
        />
      </FieldWithErrors>

      <FieldWithErrors
        name="plaka_povraten_dokument_isprakac"
        label={t('Return document')}
      >
        <Field
          name="plaka_povraten_dokument_isprakac"
          as={Dropdown}
          inputId="plaka_povraten_dokument_isprakac"
          options={paymentDoneByOptions}
          className="data-cy-return_document"
        />
      </FieldWithErrors>

      <FieldWithErrors
        name="plaka_osiguruvanje_isprakac"
        label={t('Insurance')}
      >
        <Field
          name="plaka_osiguruvanje_isprakac"
          as={Dropdown}
          inputId="plaka_osiguruvanje_isprakac"
          options={paymentDoneByOptions}
          className="data-cy-insurance"
        />
      </FieldWithErrors>

      <span className="form-group-title">{t('Price list configuration')}</span>

      <FieldWithErrors name="isprakac_cenovnik_id" label={t('Sender')}>
        <Field
          name="isprakac_cenovnik_id"
          as={Dropdown}
          inputId="isprakac_cenovnik_id"
          options={getPricebookOptions(pricebooks)}
          disabled={isPricebooksLoading}
          placeholder={isPricebooksLoading ? t('Loading...') : null}
          className="data-cy-sender"
        />
      </FieldWithErrors>

      <FieldWithErrors name="primac_cenovnik_id" label={t('Receiver')}>
        <Field
          name="primac_cenovnik_id"
          as={Dropdown}
          inputId="primac_cenovnik_id"
          options={getPricebookOptions(pricebooks)}
          disabled={isPricebooksLoading}
          placeholder={isPricebooksLoading ? t('Loading...') : null}
          className="data-cy-receiver"
        />
      </FieldWithErrors>

      <FieldWithErrors name="naracatel_cenovnik_id" label={t('Orderer')}>
        <Field
          name="naracatel_cenovnik_id"
          as={Dropdown}
          inputId="naracatel_cenovnik_id"
          options={getPricebookOptions(pricebooks)}
          disabled={isPricebooksLoading}
          placeholder={isPricebooksLoading ? t('Loading...') : null}
          className="data-cy-orderer"
        />
      </FieldWithErrors>
    </div>
  );
}

export default CreateEditDialogBasicConfigTab;
