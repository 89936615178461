import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import ToastContext from '../../../../../../context/ToastContext';
import useAxiosHook from '../../../../../../hooks/useAxiosHook';
import usePrevious from '../../../../../../hooks/usePrevious';
import { InvoiceCollection } from '../../../../../../types/api/invoices';
import { Unpacked } from '../../../../../../types/util';
import {
  errorToast,
  successToast,
} from '../../../../../../utils/helpers/primereact';

type Props = {
  invoiceID: Unpacked<InvoiceCollection>['id'] | undefined;
  ordinalNumber: Unpacked<InvoiceCollection>['serial_number'] | undefined;
  visible: boolean;
  onHide: () => void;
  reloadData: () => void;
};

function CancelDialog({
  invoiceID,
  ordinalNumber,
  visible,
  onHide,
  reloadData,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const { toastRef } = useContext(ToastContext);

  const {
    data: cancelInvoiceData,
    error: cancelInvoiceError,
    isLoading: isCancelInvoiceLoading,
    reload: cancelInvoiceReload,
  } = useAxiosHook(
    { url: `/invoices/${invoiceID}`, method: 'DELETE' },
    { skipWhen: true }
  );

  const cancelInvoiceDataPrevious = usePrevious(cancelInvoiceData);
  const cancelInvoiceErrorPrevious = usePrevious(cancelInvoiceError);

  useEffect(() => {
    if (!cancelInvoiceData || cancelInvoiceData === cancelInvoiceDataPrevious) {
      return;
    }

    successToast(
      toastRef,
      t('Invoice Successfully Canceled'),
      t(
        'Invoice with ordinal number {{ordinalNumber}} has been successfully cancelled.',
        { ordinalNumber: ordinalNumber }
      )
    );
    reloadData();
    onHide();
  }, [
    cancelInvoiceData,
    cancelInvoiceDataPrevious,
    onHide,
    ordinalNumber,
    reloadData,
    t,
    toastRef,
  ]);

  useEffect(() => {
    if (
      !cancelInvoiceError ||
      cancelInvoiceError === cancelInvoiceErrorPrevious
    ) {
      return;
    }

    errorToast(
      toastRef,
      t('Invoice Cancellation Failed'),
      t(
        'An error occurred while trying to cancel the invoice with ordinal number {{ordinalNumber}}',
        { ordinalNumber: ordinalNumber }
      )
    );
    onHide();
  }, [
    cancelInvoiceError,
    cancelInvoiceErrorPrevious,
    onHide,
    ordinalNumber,
    t,
    toastRef,
  ]);

  const dialogFooter = (
    <>
      <Button
        label={t('Cancel')}
        className="p-button-secondary p-button-text"
        onClick={() => onHide()}
      />
      <Button
        label={
          isCancelInvoiceLoading
            ? t('Cancelling invoice...')
            : t('Cancel Invoice')
        }
        disabled={isCancelInvoiceLoading}
        className="p-button-danger"
        onClick={() => cancelInvoiceReload()}
      />
    </>
  );

  return (
    <Dialog
      header={t('Cancel Invoice - {{ordinalNumber}}', {
        ordinalNumber: ordinalNumber,
      })}
      footer={dialogFooter}
      onHide={onHide}
      visible={visible}
      style={{ width: 360 }}
    >
      <p>
        {t(
          'Are you sure you want to cancel the invoice with ordinal number {{ordinalNumber}}?',
          { ordinalNumber: ordinalNumber }
        )}
        <br />
        {t('This action cannot be undone.')}
      </p>
    </Dialog>
  );
}

export default CancelDialog;
