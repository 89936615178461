import useMediaQuery from './useMediaQuery';

function useScreenSize() {
  return {
    isMobileScreen: useMediaQuery('(max-width: 767px)'),
    isTabletScreen: useMediaQuery('(min-width: 768px) and (max-width: 1023px)'),
    isDesktopScreen: useMediaQuery('(min-width: 1024px)'),
  };
}

export default useScreenSize;
