import { faFileInvoice } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import { Button } from 'primereact/button';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { $enum } from 'ts-enum-util';
import { useDebounce } from 'use-lodash-debounce';

import { getInvoiceStatusConfig } from '../../../../configs/invoices';
import ToastContext from '../../../../context/ToastContext';
import { FileTypes } from '../../../../enums/files';
import { InvoiceEmailStatus, InvoiceStatus } from '../../../../enums/invoices';
import useAxiosHook from '../../../../hooks/useAxiosHook';
import { useEndpointGuard } from '../../../../hooks/useEndpointGuard';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import usePageTitle from '../../../../hooks/usePageTitle';
import usePrevious from '../../../../hooks/usePrevious';
import useTableColumns from '../../../../hooks/useTableColumns';
import useTableState from '../../../../hooks/useTableState';
import useToastMessage from '../../../../hooks/useToastMessage';
import {
  BulkApproveInvoicesRequestPayload,
  BulkEmailInvoicesRequestPayload,
  BulkPayInvoicesRequestPayload,
  BulkRecreateInvoicesRequestPayload,
  BulkReverseInvoicesRequestPayload,
  InvoiceCollection,
  InvoiceCollectionQueryParams,
  InvoiceResource,
  InvoiceShipmentCollection,
} from '../../../../types/api/invoices';
import { Unpacked } from '../../../../types/util';
import * as invoicesGuards from '../../../../utils/constants/auth/_api/invoices';
import { debounceTimeout } from '../../../../utils/constants/misc';
import {
  downloadFile,
  getFileExtension,
  getFileName,
  saveJsonFile,
  saveXmlFile,
} from '../../../../utils/helpers/files';
import { httpDateFormat } from '../../../../utils/helpers/formatting';
import { queryString } from '../../../../utils/helpers/http';
import { tryInt } from '../../../../utils/helpers/parse';
import { errorToast } from '../../../../utils/helpers/primereact';
import {
  getSearchQueryParam,
  tryDateSearchParam,
  tryMonthSearchParam,
} from '../../../../utils/helpers/searchQuery';
import Table from '../../../DataTable/Table/Table';
import BDItem from '../../../Dialogs/BreadCrumbsDialog/BDItem';
import BreadcrumbsDialog from '../../../Dialogs/BreadCrumbsDialog/BreadcrumbsDialog';
import Grid from '../../../Grid/Grid';
import MainContent from '../../../Grid/MainContent';
import SidePanels from '../../../Grid/SidePanels';
import HeaderPages from '../../Components/HeaderPages/HeaderPages';
import CancelDialog from './Dialogs/Cancel/CancelDialog';
import EditInvoice from './Dialogs/Edit/EditInvoice';
import Items from './Dialogs/View/Content/Items/Items';
import Shipments from './Dialogs/View/Content/Shipments/Shipments';
import ViewShipments from './Dialogs/View/ViewShipments/ViewShipments';
import {
  JSONtoXML,
  Row,
  additionalColumnProperties,
  filterData,
  generateContextMenu,
  generateGroupActions,
  getColumnHeadersMap,
  tableStorageKey,
} from './InvoicesPreview.functions';
import Filters from './Sidebar/Filters';
import Stats from './Sidebar/Stats';

function InvoicesPreview(): JSX.Element {
  const { t } = useTranslation();

  usePageTitle(t('Invoices Preview'));

  const columnHeadersMap = useMemo(() => getColumnHeadersMap(t), [t]);

  const { toastRef, bottomRightToastRef } = useContext(ToastContext);

  // Route Guards
  const invoiceResourceGuard = useEndpointGuard(
    invoicesGuards.invoiceResourceGuard
  );
  const invoiceShipmentCollectionGuard = useEndpointGuard(
    invoicesGuards.invoiceShipmentCollectionGuard
  );
  const updateInvoiceResourceGuard = useEndpointGuard(
    invoicesGuards.updateInvoiceResourceGuard
  );
  const bulkApproveInvoicesGuard = useEndpointGuard(
    invoicesGuards.bulkApproveInvoicesGuard
  );
  const bulkPayInvoicesGuard = useEndpointGuard(
    invoicesGuards.bulkPayInvoicesGuard
  );
  const bulkReverseInvoicesGuard = useEndpointGuard(
    invoicesGuards.bulkReverseInvoicesGuard
  );
  const bulkRecreateInvoicesGuard = useEndpointGuard(
    invoicesGuards.bulkRecreateInvoicesGuard
  );
  const bulkEmailInvoicesGuard = useEndpointGuard(
    invoicesGuards.bulkEmailInvoicesGuard
  );

  const {
    tableRef,
    page,
    setPage,
    limit,
    setLimit,
    sortField,
    setSortField,
    sortOrder,
    setSortOrder,
    contextMenuSelection,
    setContextMenuSelection,
    selectionMultiple,
    setSelectionMultiple,
  } = useTableState<Row>(tableStorageKey);

  const { columns, columnOptions, selectedColumns, setSelectedColumns } =
    useTableColumns(
      page,
      limit,
      'invoicesPreview',
      columnHeadersMap,
      columnHeadersMap,
      additionalColumnProperties(t)
    );

  const isTwoColumnLayout = useMediaQuery('(min-width: 769px)');
  const location = useLocation();

  // Filters State
  const [client, setClient] = useState<string | null>(
    () => getSearchQueryParam(location.search, 'client') ?? null
  );

  const [dateFrom, setDateFrom] = useState<Date>(
    () =>
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'dateFrom') ?? ''
      ) ?? dayjs().startOf('month').toDate()
  );

  const [dateTo, setDateTo] = useState<Date>(
    () =>
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'dateTo') ?? ''
      ) ?? dayjs().toDate()
  );

  const [month, setMonth] = useState<Date>(
    () =>
      tryMonthSearchParam(
        getSearchQueryParam(location.search, 'month') ?? ''
      ) ?? dayjs().toDate()
  );

  const [status, setStatus] = useState<InvoiceStatus | null>(() => {
    const param = tryInt(
      getSearchQueryParam(location.search, 'status') ?? null
    );

    return param && $enum(InvoiceStatus).isValue(param) ? param : null;
  });

  const [emailStatus, setEmailStatus] = useState<InvoiceEmailStatus | null>(
    () => {
      const param = tryInt(
        getSearchQueryParam(location.search, 'emailStatus') ?? null
      );

      return param && $enum(InvoiceEmailStatus).isValue(param) ? param : null;
    }
  );

  const [ordinalNumber, setOrdinalNumber] = useState<string>(
    () => getSearchQueryParam(location.search, 'ordinal_number') ?? ''
  );

  const [amountFrom, setAmountFrom] = useState<string>(() => {
    const param = tryInt(getSearchQueryParam(location.search, 'amountFrom'));

    return param !== undefined ? String(param) : '';
  });

  const [amountTo, setAmountTo] = useState<string>(() => {
    const param = tryInt(getSearchQueryParam(location.search, 'amountTo'));

    return param !== undefined ? String(param) : '';
  });

  const debouncedAmountFrom = useDebounce(amountFrom, debounceTimeout);
  const debouncedAmountTo = useDebounce(amountTo, debounceTimeout);
  const debouncedOrdinalNumber = useDebounce(ordinalNumber, 300);

  const httpFiltersObj = useMemo(() => {
    const returnValue: InvoiceCollectionQueryParams = {
      client_id: client ?? undefined,
      date_created_from: httpDateFormat(dateFrom),
      date_created_to: httpDateFormat(dateTo),
      month: httpDateFormat(month),
      status: status ?? undefined,
      email_status: emailStatus ?? undefined,
      serial_number: debouncedOrdinalNumber,
      amount_from: debouncedAmountFrom,
      amount_to: debouncedAmountTo,
      page: page,
      limit: limit,
    };

    return returnValue;
  }, [
    client,
    dateFrom,
    dateTo,
    debouncedAmountFrom,
    debouncedAmountTo,
    emailStatus,
    limit,
    month,
    debouncedOrdinalNumber,
    page,
    status,
  ]);

  // Dialogs State
  const [isViewDialogVisible, setIsViewDialogVisible] = useState(false);
  const [isShipmentsDialogVisible, setIsShipmentsDialogVisible] =
    useState(false);
  const [isEditDialogVisible, setIsEditDialogVisible] = useState(false);
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
  const [index, setIndex] = useState(0);
  const [itemsTableSelection, setItemsTableSelection] =
    useState<Unpacked<NonNullable<InvoiceResource['data']['items']>> | null>(
      null
    );

  // Export state
  const [jsonExport, setJsonExport] = useState<{
    data: any[];
    counter: number;
    error: boolean;
    loading: boolean;
    finished: boolean;
    output: 'json' | 'xml';
  }>({
    data: [],
    counter: 0,
    error: false,
    loading: false,
    finished: false,
    output: 'json',
  });

  const canLoadData = dateFrom && dateTo;

  const { data, error, isLoading, reload } = useAxiosHook<InvoiceCollection>(
    '/invoices' + queryString<InvoiceCollectionQueryParams>(httpFiltersObj),
    {
      skipWhen: !canLoadData,
    }
  );

  useEffect(() => {
    setSelectionMultiple([]);
  }, [data, setSelectionMultiple]);

  // Export to JSON
  const {
    data: jsonPartData,
    error: jsonPartError,
    reload: jsonPartReload,
  } = useAxiosHook<InvoiceResource>();

  const jsonPartDataPrevious = usePrevious(jsonPartData);
  const jsonPartErrorPrevious = usePrevious(jsonPartError);

  useEffect(() => {
    if (!jsonPartData || jsonPartData === jsonPartDataPrevious) {
      return;
    }

    setJsonExport((prev) => ({
      ...prev,
      data: [...prev.data, filterData(jsonPartData.data)],
      counter: prev.counter + 1,
      finished: prev.counter + 1 === data?.data.length,
    }));
  }, [data?.data.length, jsonPartData, jsonPartDataPrevious]);

  useEffect(() => {
    if (!jsonPartError || jsonPartError === jsonPartErrorPrevious) {
      return;
    }

    setJsonExport((prev) => ({
      ...prev,
      counter: prev.counter + 1,
      error: true,
      finished: prev.counter + 1 === data?.data.length,
    }));
  }, [data?.data.length, jsonPartError, jsonPartErrorPrevious]);

  function handleExportToJson() {
    if (!data || jsonExport.loading) {
      return;
    }

    setJsonExport((prev) => ({ ...prev, loading: true }));

    for (let i = 0; i < data?.data.length; i++) {
      const partDataId = data.data[i].id;
      jsonPartReload({ url: `/invoices/${partDataId}` });
    }
  }

  function handleExportToXml() {
    setJsonExport((prev) => ({ ...prev, output: 'xml' }));
    handleExportToJson();
  }

  useEffect(() => {
    if (!jsonExport.finished) {
      return;
    }

    if (!jsonExport.error) {
      const fileName = getFileName(t('Invoices'), undefined, true);

      if (jsonExport.output === 'json') {
        const fileExtension = getFileExtension(FileTypes.JSON);

        saveJsonFile(
          JSON.stringify(jsonExport.data, null, 2),
          `${fileName}.${fileExtension}`
        );
      } else {
        JSONtoXML(jsonExport.data).then((xmlData) => {
          const fileExtension = getFileExtension(FileTypes.XML);

          saveXmlFile(xmlData, `${fileName}.${fileExtension}`);
        });
      }

      setJsonExport({
        data: [],
        counter: 0,
        error: false,
        loading: false,
        finished: false,
        output: 'json',
      });
    } else {
      if (toastRef?.current) {
        errorToast(
          toastRef,
          t('Export to {{fileType}} Failed', {
            fileType: jsonExport.output.toUpperCase(),
          }),
          t(
            'An error occurred while trying to export invoices to {{fileType}}.',
            { fileType: jsonExport.output }
          )
        );
      }

      setJsonExport({
        data: [],
        counter: 0,
        error: false,
        loading: false,
        finished: false,
        output: 'json',
      });
    }
  }, [bottomRightToastRef, dateFrom, dateTo, jsonExport, t, toastRef]);

  function handleExportToExcel() {
    downloadFile(
      `${process.env.REACT_APP_REPORT_URL}/invoices/xlsx` +
        queryString({
          client_id: client,
          date_from: httpDateFormat(dateFrom),
          date_to: httpDateFormat(dateTo),
        }),
      getFileName(t('Invoices'), undefined, true),
      FileTypes.XLSX,
      bottomRightToastRef
    );
  }

  const itemsLabel = t('Invoice Preview - {{ordinalNumber}}', {
    ordinalNumber: contextMenuSelection?.serial_number,
  });

  const shipmentsTitle = t('View Shipments - {{ordinalNumber}}', {
    ordinalNumber: contextMenuSelection?.serial_number,
  });

  const isBdItemsShown = isViewDialogVisible && index === 0;
  const isBdShipmentsShown = isViewDialogVisible && index === 1;

  const viewItemsRequest = useAxiosHook<InvoiceResource>(
    `/invoices/${contextMenuSelection?.id}`,
    { skipWhen: !contextMenuSelection?.id || !isBdItemsShown }
  );

  const query = useMemo<string>(() => {
    if (!itemsTableSelection) {
      return '';
    }

    return queryString({
      invoice_id: itemsTableSelection.invoice_id,
      price: itemsTableSelection.price_total,
      vat: itemsTableSelection.vat,
      created_by_id: itemsTableSelection.created_by,
      modified_by_id: itemsTableSelection.modified_by,
    });
  }, [itemsTableSelection]);

  const viewShipmentsRequest = useAxiosHook<InvoiceShipmentCollection>(
    `/invoices/${contextMenuSelection?.id}/shipments` + query,
    { skipWhen: !contextMenuSelection?.id || !isBdShipmentsShown }
  );

  const contextMenuModel = useMemo(() => {
    function handlePrintInvoiceCMClick() {
      if (!contextMenuSelection) {
        return;
      }

      downloadFile(
        `${process.env.REACT_APP_REPORT_URL}/invoice-document/pdf` +
          queryString({ invoice_id: contextMenuSelection?.id }),
        getFileName(t('Invoices'), [
          String(contextMenuSelection.year),
          contextMenuSelection.serial_number,
        ]),
        FileTypes.PDF,
        bottomRightToastRef
      );
    }

    const status = contextMenuSelection?.status_id;

    const isEditInvoiceVisible =
      updateInvoiceResourceGuard &&
      status !== InvoiceStatus.Paid &&
      status !== InvoiceStatus.Cancelled;

    const isViewShipmentsVisible =
      invoiceShipmentCollectionGuard && status !== InvoiceStatus.Cancelled;

    return generateContextMenu({
      t,
      isViewInvoiceVisible: invoiceResourceGuard,
      handleView: () => setIsViewDialogVisible(true),
      isViewShipmentsVisible,
      handleShipments: () => setIsShipmentsDialogVisible(true),
      isEditInvoiceVisible,
      handleEdit: () => setIsDeleteDialogVisible(true),
      handlePrintInvoice: handlePrintInvoiceCMClick,
    });
  }, [
    bottomRightToastRef,
    contextMenuSelection,
    updateInvoiceResourceGuard,
    t,
    invoiceResourceGuard,
    invoiceShipmentCollectionGuard,
  ]);

  const {
    data: approveData,
    error: approveError,
    reload: reloadApprove,
    isLoading: isApproveLoading,
  } = useAxiosHook(
    {
      url: '/invoices/approve',
      method: 'PUT',
    },
    { skipWhen: true }
  );

  const {
    data: cancelData,
    error: cancelError,
    reload: reloadCancel,
    isLoading: isCancelLoading,
  } = useAxiosHook(
    {
      url: '/invoices/reverse',
      method: 'PUT',
    },
    { skipWhen: true }
  );

  const {
    data: markAsPaidData,
    error: markAsPaidError,
    reload: reloadMarkAsPaid,
    isLoading: isMarkAsPaidLoading,
  } = useAxiosHook(
    {
      url: '/invoices/set-payed',
      method: 'PUT',
    },
    { skipWhen: true }
  );

  const {
    data: recreateData,
    error: recreateError,
    reload: reloadRecreate,
    isLoading: isRecreateLoading,
  } = useAxiosHook(
    {
      url: '/invoices/recreate',
      method: 'PUT',
    },
    { skipWhen: true }
  );

  const {
    data: sendEmailData,
    error: sendEmailError,
    reload: reloadSendEmail,
    isLoading: isSendEmailLoading,
  } = useAxiosHook(
    {
      url: '/invoices/email',
      method: 'PUT',
    },
    { skipWhen: true }
  );

  useToastMessage(approveData, approveError, {
    success: {
      summary: t('Invoice(s) approved successfully.'),
      callback: reload,
    },
    error: {
      summary: t('There was an error approving the selected invoice(s).'),
    },
  });

  useToastMessage(cancelData, cancelError, {
    success: {
      summary: t('Invoice(s) cancelled successfully.'),
      callback: reload,
    },
    error: {
      summary: t('There was an error cancelling the selected invoice(s).'),
    },
  });

  useToastMessage(markAsPaidData, markAsPaidError, {
    success: {
      summary: t('Invoice(s) marked as paid successfully.'),
      callback: reload,
    },
    error: {
      summary: t('There was an error marking the selected invoice(s) as paid.'),
    },
  });

  useToastMessage(recreateData, recreateError, {
    success: {
      summary: t('Invoice(s) recreated successfully.'),
      callback: reload,
    },
    error: {
      summary: t('There was an error recreated the selected invoice(s).'),
    },
  });

  useToastMessage(sendEmailData, sendEmailError, {
    success: {
      summary: t('Invoice(s) were successfully sent as email.'),
      callback: reload,
    },
    error: {
      summary: t(
        'There was an error sending the selected invoice(s) as email.'
      ),
    },
  });

  const groupActionsModel = useMemo(() => {
    const firstSelectionStatus = selectionMultiple[0]
      ?.status_id as InvoiceStatus;

    const sameGroupStatus = selectionMultiple.every(
      (s) => s.status_id === firstSelectionStatus
    );

    const isApproveVisible =
      bulkApproveInvoicesGuard &&
      sameGroupStatus &&
      firstSelectionStatus === InvoiceStatus.Created;

    const isCancelVisible =
      bulkReverseInvoicesGuard &&
      sameGroupStatus &&
      firstSelectionStatus !== InvoiceStatus.Cancelled &&
      firstSelectionStatus !== InvoiceStatus.Paid;

    const isMarkAsPaidVisible =
      bulkPayInvoicesGuard &&
      sameGroupStatus &&
      firstSelectionStatus === InvoiceStatus.Approved;

    const isRecreateVisible =
      bulkRecreateInvoicesGuard &&
      sameGroupStatus &&
      firstSelectionStatus !== InvoiceStatus.Paid &&
      firstSelectionStatus !== InvoiceStatus.Cancelled;

    const isSendEmailVisible =
      bulkEmailInvoicesGuard &&
      sameGroupStatus &&
      firstSelectionStatus === InvoiceStatus.Approved;

    function getIdsFromSelection() {
      return selectionMultiple.map((r) => r.id);
    }

    function handleApprove() {
      const data: BulkApproveInvoicesRequestPayload = {
        ids: getIdsFromSelection(),
      };

      reloadApprove({ data });
    }

    function handleCancel() {
      const data: BulkReverseInvoicesRequestPayload = {
        ids: getIdsFromSelection(),
      };

      reloadCancel({ data });
    }

    function handleMarkAsPaid() {
      const data: BulkPayInvoicesRequestPayload = {
        ids: getIdsFromSelection(),
      };

      reloadMarkAsPaid({ data });
    }

    function handleRecreate() {
      const data: BulkRecreateInvoicesRequestPayload = {
        ids: getIdsFromSelection(),
      };

      reloadRecreate({ data });
    }

    function handleSendEmail() {
      const data: BulkEmailInvoicesRequestPayload = {
        ids: getIdsFromSelection(),
      };

      reloadSendEmail({ data });
    }

    return generateGroupActions({
      t,
      isApproveVisible,
      handleApprove,
      isCancelVisible,
      handleCancel,
      isMarkAsPaidVisible,
      handleMarkAsPaid,
      isRecreateVisible,
      handleRecreate,
      isSendEmailVisible,
      handleSendEmail,
    });
  }, [
    bulkApproveInvoicesGuard,
    bulkEmailInvoicesGuard,
    bulkPayInvoicesGuard,
    bulkRecreateInvoicesGuard,
    bulkReverseInvoicesGuard,
    reloadApprove,
    reloadCancel,
    reloadMarkAsPaid,
    reloadRecreate,
    reloadSendEmail,
    selectionMultiple,
    t,
  ]);

  const rowClassName = useCallback(
    (row: Row) => {
      const invoiceStatusConfig = getInvoiceStatusConfig(t);
      const className =
        invoiceStatusConfig[row.status_id as InvoiceStatus].rowClassName;

      if (!className) {
        return {};
      }

      return {
        [className]: true,
      };
    },
    [t]
  );

  const isTableLoading =
    isLoading ||
    isApproveLoading ||
    isCancelLoading ||
    isMarkAsPaidLoading ||
    isRecreateLoading ||
    isSendEmailLoading;

  return (
    <div className="page invoices-preview">
      <HeaderPages
        title={t('Invoices Preview')}
        subtitle={t('Preview and manage created invoices')}
        icon={faFileInvoice}
        goBackTitle={t('Invoices Creation')}
        goBackTo="/invoices/create"
      />

      <BreadcrumbsDialog
        index={index}
        setIndex={setIndex}
        visible={isViewDialogVisible}
        onHide={() => setIsViewDialogVisible(false)}
      >
        <BDItem
          label={itemsLabel}
          footer={({ onHide }) => (
            <>
              <Button
                type="button"
                label={t('Cancel Invoice')}
                className="p-button-danger"
                onClick={() => {
                  onHide();
                  setIsDeleteDialogVisible(true);
                }}
              />

              <Button
                type="button"
                label={t('Edit Invoice')}
                className="p-button-secondary"
                onClick={() => {
                  onHide();
                  setIsEditDialogVisible(true);
                }}
              />
            </>
          )}
        >
          <Items
            invoiceID={contextMenuSelection?.id}
            ordinalNumber={contextMenuSelection?.serial_number}
            request={viewItemsRequest}
            selection={itemsTableSelection}
            setSelection={setItemsTableSelection}
          />
        </BDItem>

        <BDItem label={shipmentsTitle}>
          <Shipments request={viewShipmentsRequest} />
        </BDItem>
      </BreadcrumbsDialog>

      <ViewShipments
        visible={isShipmentsDialogVisible}
        title={shipmentsTitle}
        invoiceID={contextMenuSelection?.id}
        onHide={() => setIsShipmentsDialogVisible(false)}
      />

      <EditInvoice
        invoiceID={contextMenuSelection?.id}
        ordinalNumber={contextMenuSelection?.serial_number}
        visible={isEditDialogVisible}
        onHide={() => setIsEditDialogVisible(false)}
        reloadData={reload}
      />

      <CancelDialog
        invoiceID={contextMenuSelection?.id}
        ordinalNumber={contextMenuSelection?.serial_number}
        visible={isDeleteDialogVisible}
        onHide={() => setIsDeleteDialogVisible(false)}
        reloadData={reload}
      />

      <Grid>
        <SidePanels>
          <Filters
            client={client}
            setClient={setClient}
            dateFrom={dateFrom}
            setDateFrom={setDateFrom}
            dateTo={dateTo}
            setDateTo={setDateTo}
            month={month}
            setMonth={setMonth}
            status={status}
            setStatus={setStatus}
            emailStatus={emailStatus}
            setEmailStatus={setEmailStatus}
            ordinalNumber={ordinalNumber}
            setOrdinalNumber={setOrdinalNumber}
            amountFrom={amountFrom}
            setAmountFrom={setAmountFrom}
            amountTo={amountTo}
            setAmountTo={setAmountTo}
          />
          <Stats data={data} selectionMultiple={selectionMultiple} />
        </SidePanels>

        <MainContent columnLayout={isTwoColumnLayout}>
          <Table
            tableRef={tableRef}
            columnOptions={columnOptions}
            columns={columns}
            data={data}
            filterHeight={10}
            hasError={!!error}
            headerTitle={t('Preview Created Invoices')}
            isLoading={isTableLoading}
            rebuildTooltip
            reload={reload}
            isReloadDisabled={!canLoadData}
            rows={limit}
            selectedColumns={selectedColumns}
            selection={selectionMultiple}
            setLimit={setLimit}
            setPage={setPage}
            setSelectedColumns={setSelectedColumns}
            contextMenuSelection={contextMenuSelection}
            setContextMenuSelection={setContextMenuSelection}
            setSelection={setSelectionMultiple}
            setSortField={setSortField}
            setSortOrder={setSortOrder}
            sortField={sortField}
            sortOrder={sortOrder}
            storageString={tableStorageKey}
            contextMenuModel={contextMenuModel}
            exportToExcelButton
            onExportToExcelButtonClick={handleExportToExcel}
            exportToJsonButton
            onExportToJsonButtonClick={handleExportToJson}
            exportToXmlButton
            onExportToXmlButtonClick={handleExportToXml}
            onRowDoubleClick={() => setIsViewDialogVisible(true)}
            selectionMode="multiple"
            selectionPageOnly
            clearSelectionObj={httpFiltersObj as any}
            groupActionsModel={groupActionsModel}
            rowClassName={rowClassName}
          />
        </MainContent>
      </Grid>
    </div>
  );
}

export default InvoicesPreview;
