import './EditDialog.scss';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useContext, useEffect } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ToastContext from '../../../../../context/ToastContext';
import useAxiosHook from '../../../../../hooks/useAxiosHook';
import useMediaQuery from '../../../../../hooks/useMediaQuery';
import usePrevious from '../../../../../hooks/usePrevious';
import {
  errorToast,
  successToast,
} from '../../../../../utils/helpers/primereact';
import DialogSpinner from '../../../../Dialogs/DialogSpinner/DialogSpinner';
import useDialogData from '../useDialogData';
import { FormFields } from './EditDialog.functions';
import FormContainer from './FormContainer';

type Props = {
  isShown: boolean;
  onHide: () => void;
};

function EditDialog({ isShown, onHide }: Props): JSX.Element {
  const { t } = useTranslation();

  const { toastRef } = useContext(ToastContext);

  const {
    title,
    data,
    isLoading,
    clientTypeOptions,
    rolesOptions,
    privacyOptions,
  } = useDialogData(isShown, onHide);

  const {
    data: updateData,
    error: updateError,
    isLoading: isUpdateLoading,
    reload: updateReload,
  } = useAxiosHook();

  const prevUpdateData = usePrevious(updateData);
  const prevUpdateError = usePrevious(updateError);

  useEffect(() => {
    if (!updateData || updateData === prevUpdateData) {
      return;
    }

    successToast(
      toastRef,
      t('Success'),
      t('Route data has been updated successfully.')
    );

    onHide();
  }, [onHide, prevUpdateData, t, toastRef, updateData]);

  useEffect(() => {
    if (!updateError || updateError === prevUpdateError) {
      return;
    }

    errorToast(
      toastRef,
      t('Error'),
      t('An error occured while trying to update route {{name}}.', {
        name: data?.name ?? '',
      })
    );

    onHide();
  }, [data?.name, onHide, prevUpdateError, t, toastRef, updateError]);

  const handleFormSubmission: SubmitHandler<FormFields> = (values) => {
    updateReload({ url: '/change/me', method: 'PUT', data: values });
  };

  const header = isLoading ? t('Loading...') : t('Viewing') + ' ' + title;

  const dialogFooter = isLoading ? (
    <></>
  ) : (
    <>
      <Button
        type="button"
        label={t('Close')}
        onClick={onHide}
        className="p-button-secondary p-button-text"
      />

      <Button
        type="submit"
        form="roles-page-edit-dialog-form"
        label={isUpdateLoading ? t('Updating...') : t('Update')}
        disabled={isUpdateLoading}
      />
    </>
  );

  const isOnMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Dialog
      header={header}
      footer={dialogFooter}
      visible={isShown}
      resizable={false}
      onHide={onHide}
      maximizable
      maximized={isOnMobile}
      style={{ width: 580, maxWidth: '100%' }}
      className="roles-page-edit-dialog"
    >
      {isLoading ? (
        <DialogSpinner />
      ) : (
        <FormContainer
          data={data}
          clientTypeOptions={clientTypeOptions}
          rolesOptions={rolesOptions}
          privacyOptions={privacyOptions}
          handleFormSubmission={handleFormSubmission}
        />
      )}
    </Dialog>
  );
}

export default EditDialog;
