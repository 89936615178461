import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import ToastContext from '../../../../context/ToastContext';
import { FileTypes } from '../../../../enums/files';
import useAxiosHook from '../../../../hooks/useAxiosHook';
import { useEndpointGuard } from '../../../../hooks/useEndpointGuard';
import usePageTitleToggler from '../../../../hooks/usePageTitleToggler';
import usePrevious from '../../../../hooks/usePrevious';
import useRouteDialog from '../../../../hooks/useRouteDialog';
import useTableColumns from '../../../../hooks/useTableColumns';
import useTableState from '../../../../hooks/useTableState';
import { WithPagination } from '../../../../types/api';
import * as ordersGuards from '../../../../utils/constants/auth/orders';
import {
  RoutePaths,
  constructIdRoute,
} from '../../../../utils/constants/routePaths';
import { downloadFile, getFileName } from '../../../../utils/helpers/files';
import { queryString } from '../../../../utils/helpers/http';
import { httpQueryObject } from '../../../../utils/helpers/misc';
import { errorToast, successToast } from '../../../../utils/helpers/primereact';
import Table from '../../../DataTable/Table/Table';
import Flex from '../../../layout/flex/Flex';
import MainContent from '../../../layout/flex/MainContent';
import Filters from '../../Components/Filters/Filters';
import HeaderPages from '../../Components/HeaderPages/HeaderPages';
import ViewDeletedOrderDialog from '../Dialogs/View/ViewDeletedOrderDialog';
import {
  Order,
  additionalColumnProperties,
  generateContextMenu,
  generateErrorMsg,
  getColumnHeadersMap,
  tableStorageKey,
} from './DeletedOrders.functions';
import useTableFilters from './useTableFilters';

function DeletedOrders(): JSX.Element {
  const { t } = useTranslation();

  const historyGuard = useEndpointGuard(ordersGuards.orderHistory);
  const readGuard = useEndpointGuard(ordersGuards.readDeletedOrder);
  const recreateGuard = useEndpointGuard(ordersGuards.recreate);
  const exportGuard = useEndpointGuard(ordersGuards.exportDeletedOrders);

  const history = useHistory();

  const { toastRef, bottomRightToastRef } = useContext(ToastContext);

  const {
    tableRef,
    page,
    setPage,
    limit,
    setLimit,
    sortField,
    sortOrder,
    selection,
    setSortField,
    setSortOrder,
    setSelection,
  } = useTableState<Order>(tableStorageKey);

  const {
    headerFiltersForm,
    headerFiltersCount,
    resetAllFilters,
    httpFiltersObj,
  } = useTableFilters(page, setPage, limit);

  const canLoadData = httpFiltersObj.date_from && httpFiltersObj.date_to;

  const { data, error, isLoading, reload } = useAxiosHook<
    WithPagination<Order[]>
  >(
    {
      url:
        '/orders/deleted/filter' + queryString(httpQueryObject(httpFiltersObj)),
    },
    {
      skipWhen: !canLoadData,
    }
  );

  function handleCMHistoryClick() {
    if (!selection) {
      return;
    }

    history.push(
      constructIdRoute(RoutePaths.OrdersHistory, selection.id.toString())
    );
  }

  const columnHeadersMap = useMemo(() => getColumnHeadersMap(t), [t]);

  const { selectedColumns, setSelectedColumns, columnOptions, columns } =
    useTableColumns(
      page,
      limit,
      'orders_deletedOrders',
      columnHeadersMap,
      columnHeadersMap,
      (c) => additionalColumnProperties(t, c as keyof typeof columnHeadersMap)
    );

  const {
    show: showViewOrderDialog,
    hide: hideViewOrderDialog,
    isVisible: isViewOrderDialogVisible,
  } = useRouteDialog(
    RoutePaths.DeletedOrders,
    constructIdRoute(RoutePaths.ViewDeletedOrder, selection?.id)
  );

  usePageTitleToggler(
    t('{{serialNo}} preview', { serialNo: selection?.seriski_broj }),
    t('Deleted Orders'),
    isViewOrderDialogVisible
  );

  const {
    data: recreateOrderData,
    error: recreateOrderError,
    reload: reloadRecreateOrder,
  } = useAxiosHook(
    {
      url: `/orders/deleted/${selection?.seriski_broj}/recreate`,
      method: 'POST',
    },
    { skipWhen: true }
  );

  const prevRecreateOrderData = usePrevious(recreateOrderData);

  useEffect(() => {
    if (!recreateOrderData || recreateOrderData === prevRecreateOrderData) {
      return;
    }

    if (toastRef?.current) {
      successToast(
        toastRef,
        t('Order recreated'),
        t('The order was successfully recreated.')
      );
    }

    reload();
  }, [prevRecreateOrderData, recreateOrderData, reload, t, toastRef]);

  useEffect(() => {
    if (!recreateOrderError) {
      return;
    }

    errorToast(
      toastRef,
      t('Error'),
      t('Recreating the order failed {{errorMsg}}.', {
        errorMsg: generateErrorMsg(
          t,
          recreateOrderError?.response?.request?.responseText
        ),
      })
    );
  }, [recreateOrderError, t, toastRef]);

  function handleCMViewClick() {
    if (!selection) {
      return;
    }

    showViewOrderDialog();
  }

  function handleExportToCsv() {
    if (!selection) {
      return;
    }

    downloadFile(
      `/orders/deleted/export/csv` +
        queryString({
          date_from: httpFiltersObj?.date_from,
          date_to: httpFiltersObj?.date_to,
          serial_number: httpFiltersObj?.serial_number,
          deleted_by_id: httpFiltersObj?.deleted_by_id,
        }),
      getFileName(t('DeletedOrders'), undefined, true),
      FileTypes.CSV,
      bottomRightToastRef
    );
  }

  function handleExportToExcel() {
    if (!selection) {
      return;
    }

    downloadFile(
      `/orders/deleted/export/excel` +
        queryString({
          date_from: httpFiltersObj?.date_from,
          date_to: httpFiltersObj?.date_to,
          serial_number: httpFiltersObj?.serial_number,
          deleted_by_id: httpFiltersObj?.deleted_by_id,
        }),
      getFileName(t('DeletedOrders'), undefined, true),
      FileTypes.XLSX,
      bottomRightToastRef
    );
  }

  function handleRecreateOrder() {
    if (!selection) {
      return;
    }
    reloadRecreateOrder();
  }

  useEffect(() => {
    if (!readGuard) {
      hideViewOrderDialog();
    }
  }, [hideViewOrderDialog, readGuard]);

  return (
    <div className="page orders-page">
      <HeaderPages
        title={t('Deleted Orders')}
        subtitle={t('View and manage deleted orders')}
        icon={faTrashAlt}
      />

      <ViewDeletedOrderDialog
        isShown={isViewOrderDialogVisible}
        dataSerialNo={selection?.seriski_broj}
        onHide={hideViewOrderDialog}
        onRecreate={handleRecreateOrder}
      />

      <Flex direction="column">
        <Filters
          filters={headerFiltersForm}
          resetAllFilters={resetAllFilters}
          headerFiltersCount={headerFiltersCount}
          filterHeight={170}
        />
        <MainContent>
          <Table
            tableRef={tableRef}
            columns={columns}
            data={data}
            hasError={!!error}
            isLoading={isLoading}
            reload={reload}
            isReloadDisabled={!canLoadData}
            headerTitle=""
            setPage={setPage}
            setLimit={setLimit}
            sortField={sortField}
            rows={limit}
            filterHeight={170}
            setSortField={setSortField}
            setSortOrder={setSortOrder}
            setSelection={setSelection}
            sortOrder={sortOrder}
            selection={selection}
            onRowDoubleClick={readGuard ? handleCMViewClick : () => {}}
            contextMenuModel={generateContextMenu(
              t,
              handleCMHistoryClick,
              historyGuard,
              handleCMViewClick,
              readGuard,
              handleRecreateOrder,
              recreateGuard
            )}
            storageString={tableStorageKey}
            rebuildTooltip
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            columnOptions={columnOptions}
            exportToCSVButton={exportGuard}
            onExportToCSVButtonClick={
              exportGuard ? handleExportToCsv : () => {}
            }
            exportToExcelButton={exportGuard}
            onExportToExcelButtonClick={
              exportGuard ? handleExportToExcel : () => {}
            }
          />
        </MainContent>
      </Flex>
    </div>
  );
}
export default DeletedOrders;
