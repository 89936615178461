import { setNestedObjectValues, useFormikContext } from 'formik';
import _ from 'lodash';
import { Button } from 'primereact/button';
import { useEffect } from 'react';
import { Dispatch, SetStateAction, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import ToastContext from '../../../../../context/ToastContext';
import useAxiosHook from '../../../../../hooks/useAxiosHook';
import usePrevious from '../../../../../hooks/usePrevious';
import { CreateOrderResource } from '../../../../../types/api/orders';
import {
  errorToast,
  successToast,
} from '../../../../../utils/helpers/primereact';
import { arePaymentsEqual, toApiData } from './CreateEditDialog.functions';
import { FormValues } from './CreateEditDialog.functions';

type Props = {
  isGroupDialog: boolean;
  isEditDialog: boolean;
  isOrderRequestLoading: boolean;
  isTabs: boolean;
  onHide: () => void;
  setOrderIndex?: Dispatch<SetStateAction<number>>;
  orderIndex?: number;
  orderData?: FormValues[];
  setOrderData: Dispatch<SetStateAction<FormValues>>;
  onCreate?: (response: CreateOrderResource) => void;
};

export function DialogFooter({
  isGroupDialog,
  isEditDialog,
  isOrderRequestLoading,
  isTabs,
  onHide,
  orderIndex,
  setOrderIndex = () => {},
  orderData = [],
  setOrderData = () => {},
  onCreate,
}: Props) {
  const { t } = useTranslation();
  const {
    values,
    initialValues,
    errors,
    isValid,
    validateForm,
    setTouched,
    setFieldValue,
    submitForm,
  } = useFormikContext<FormValues>();

  const isLastOrder = orderIndex === orderData.length - 1;
  const toastRef = useContext(ToastContext)?.toastRef!;

  const {
    reload: saveOrder,
    data: saveOrderData,
    error: saveOrderError,
    isLoading: isOrderSaving,
  } = useAxiosHook<CreateOrderResource>();

  const previousSaveOrderData = usePrevious(saveOrderData);
  const previousSaveOrderError = usePrevious(saveOrderError);

  function handleNextPost() {
    validateForm().then(() => {
      setTouched(setNestedObjectValues(errors, true));

      if (isValid) {
        if (values?.seriski_broj) {
          editSingleOrder();
        } else {
          saveSingleOrder();
        }
      }
    });
  }

  function saveSingleOrder(quit?: boolean) {
    if (quit) {
      return validateForm().then(() => {
        setTouched(setNestedObjectValues(errors, true));

        setFieldValue('quit', true);

        if (!isValid) return;

        saveOrder({
          url: '/orders/add',
          method: 'POST',
          data: toApiData(values),
        });
      });
    }

    saveOrder({
      url: '/orders/add',
      method: 'POST',
      data: toApiData(values),
    });
  }

  function editSingleOrder() {
    const currentValues = toApiData(values, true);
    const formatedInitialValues = toApiData(initialValues, true);
    for (const key in currentValues) {
      if (
        key === 'payments' &&
        arePaymentsEqual(
          currentValues?.payments,
          formatedInitialValues?.payments
        )
      ) {
        delete currentValues[key as keyof FormValues];
      } else if (
        currentValues[key as keyof FormValues] ===
        formatedInitialValues[key as keyof FormValues]
      ) {
        delete currentValues[key as keyof FormValues];
      }
    }

    if (_.isEmpty(currentValues)) {
      setOrderData(values);

      setOrderIndex((index: number) => index + 1);

      return;
    }

    saveOrder({
      url: `/orders/${values?.seriski_broj}`,
      method: 'PUT',
      data: currentValues,
    });
  }

  useEffect(() => {
    if (saveOrderData === previousSaveOrderData || !saveOrderData?.id) {
      return;
    }

    if (values.seriski_broj) {
      successToast(
        toastRef,
        t('Order edited'),
        t('Order {{orderID}} edited sucessfully.', {
          orderID: saveOrderData?.id,
        })
      );
    } else {
      onCreate?.(saveOrderData);
      successToast(
        toastRef,
        t('Order created'),
        t('Order {{orderID}} created sucessfully.', {
          orderID: saveOrderData?.id,
        })
      );
    }

    setOrderData({ ...values, seriski_broj: saveOrderData?.id });

    if (values.quit) {
      onHide();
    } else {
      setOrderIndex((index: number) => index + 1);
    }
  }, [
    onCreate,
    onHide,
    previousSaveOrderData,
    saveOrderData,
    setOrderData,
    setOrderIndex,
    t,
    toastRef,
    values,
  ]);

  function handlePreviousPost() {
    validateForm().then(() => {
      setTouched(setNestedObjectValues(errors, true));
    });

    setOrderData(values);
    setOrderIndex((index: number) => index - 1);
  }

  useEffect(() => {
    if (saveOrderError === previousSaveOrderError) {
      return;
    }

    errorToast(
      toastRef,
      t('Error'),
      saveOrderError?.message ?? t('There was a problem')
    );
  }, [previousSaveOrderError, saveOrderError, t, toastRef]);

  return (
    <>
      {isGroupDialog && !isTabs ? (
        <>
          <div className="group-buttons p-d-flex p-jc-between">
            <Button
              type="button"
              label={t('Back')}
              icon="fa fa-angle-left"
              disabled={orderIndex === 0}
              onClick={handlePreviousPost}
              data-cy="back-btn"
            />

            <Button
              type="button"
              label={t('Next')}
              icon={isLastOrder ? 'fas fa-plus' : 'fas fa-angle-right'}
              iconPos="right"
              disabled={isOrderSaving}
              onClick={handleNextPost}
              data-cy="next-btn"
            />
          </div>

          <Button
            label={t('Close')}
            className="p-button-text"
            type="button"
            onClick={onHide}
          />

          <Button
            label={t('Save & Exit')}
            onClick={() => saveSingleOrder(true)}
            disabled={(orderIndex ?? 0) + 1 < orderData.length || isOrderSaving}
            type="submit"
            data-cy="save-n-exit-btn"
          />
        </>
      ) : (
        <Button
          label={
            isOrderRequestLoading
              ? t('Creating...')
              : isEditDialog
              ? t('Save changes')
              : t('Create')
          }
          onClick={submitForm}
          data-cy="submit-btn"
          disabled={isOrderRequestLoading}
        />
      )}
    </>
  );
}
