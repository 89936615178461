import './WebImportDialog.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useEffect, useMemo } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ToastContext from '../../../../../context/ToastContext';
import useAxiosHook from '../../../../../hooks/useAxiosHook';
import usePrevious from '../../../../../hooks/usePrevious';
import { EntityPropertyCollection } from '../../../../../types/api/entityproperties';
import { ImportListTypeResource } from '../../../../../types/api/importlisttypes';
import { OrderImport, OrderImportError } from '../../../../../types/api/orders';
import { tryString } from '../../../../../utils/helpers/parse';
import {
  errorToast,
  successToast,
} from '../../../../../utils/helpers/primereact';
import StepperDialog, { Step } from '../../../../Dialogs/Stepper/StepperDialog';
import ClientAndListType from './Steps/ClientAndListType';
import Content from './Steps/Content';
import Payments from './Steps/Payments';
import SenderAddress from './Steps/SenderAddress';
import {
  generatePropertyName,
  getDefaultValues,
  getValidationSchema,
  toApiData,
} from './WebImportDialog.functions';
import { FormValues } from './WebImportDialog.types';

type Props = {
  isShown: boolean;
  onHide: () => void;
  reloadImportedLists: () => void;
  initialValues?: Partial<FormValues>;
  isLoading?: boolean;
  isClientAutoCompleteDisabled?: boolean;
  onImport?: (response: OrderImport) => void;
};

type StepWithValidation<T> = Step & {
  fieldNames: (keyof T)[];
};

function WebImportDialog({
  isShown,
  onHide,
  reloadImportedLists,
  initialValues,
  isLoading,
  isClientAutoCompleteDisabled,
  onImport,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { toastRef } = useContext(ToastContext);
  const defaultValues = useMemo(
    () => getDefaultValues(initialValues ?? {}),
    [initialValues]
  );
  const resolver = useMemo(() => yupResolver(getValidationSchema(t)), [t]);
  const methods = useForm<FormValues>({
    defaultValues,
    resolver,
  });
  const { handleSubmit, control, trigger, reset, setValue } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const _clientListType = useWatch<FormValues, '_clientListType'>({
    name: '_clientListType',
    control,
  });

  const {
    data: listTypeData,
    error: listTypeError,
    isLoading: isListTypeLoading,
  } = useAxiosHook<ImportListTypeResource>(
    `/importlisttypes/${_clientListType?.id}?lookup=true`,
    {
      skipWhen: !_clientListType?.id,
    }
  );

  const { data: entityProperties, isLoading: isEntityPropertiesLoading } =
    useAxiosHook<EntityPropertyCollection>('/entityproperties');

  const prevListTypeError = usePrevious(listTypeError);

  useEffect(() => {
    setValue('_listType', listTypeData ?? null);
    setValue(
      'osiguruvanje',
      tryString(listTypeData?.plaka_osiguruvanje_isprakac) ?? ''
    );
    setValue('otkup', tryString(listTypeData?.plaka_otkup_isprakac) ?? '');
    setValue(
      'postarina',
      tryString(listTypeData?.plaka_postarina_isprakac) ?? ''
    );
    setValue(
      'povraten_dokument',
      tryString(listTypeData?.plaka_povraten_dokument_isprakac) ?? ''
    );
  }, [listTypeData, setValue]);

  useEffect(() => {
    const fieldValues = listTypeData?.fields?.map((field) => {
      return {
        id: field.id,
        name: field.name,
        value: field.default_value ?? '',
        default_value: field.default_value ?? '',
        isRequired: !!field.isRequired,
        property_id: field.property_id,
        property_name: generatePropertyName(
          entityProperties,
          field.property_id
        ),
      };
    });

    setValue('_fields', fieldValues ? [{ fields: fieldValues }] : []);
  }, [entityProperties, listTypeData?.fields, setValue]);

  useEffect(() => {
    if (!listTypeError || listTypeError === prevListTypeError) {
      return;
    }

    errorToast(
      toastRef,
      t('Error'),
      t('An error occured while reading list data.')
    );
  }, [prevListTypeError, listTypeError, t, toastRef]);

  const steps = useMemo<StepWithValidation<FormValues>[]>(() => {
    let returnArray: StepWithValidation<FormValues>[] = [
      {
        title: t('Client'),
        component: () =>
          ClientAndListType({
            isListTypeLoading,
            isClientAutoCompleteDisabled,
          }),
        fieldNames: ['_clientFilterObj', '_clientListType'],
      },
    ];

    if (listTypeData?.klient_isSender !== 0) {
      returnArray.push({
        title: t('Sender address'),
        component: SenderAddress,
        fieldNames: [
          '_municipality',
          'place',
          'street',
          'adresa',
          'broj',
          'vlez',
          'stan',
        ],
      });
    }

    if (listTypeData?.changeable_payment === '1') {
      returnArray.push({
        title: t('Payments'),
        component: Payments,
        fieldNames: ['postarina', 'otkup', 'povraten_dokument', 'osiguruvanje'],
      });
    }

    returnArray.push({
      title: t('Parcels'),
      component: () => Content({ entityProperties }),
      fieldNames: ['_fields'],
    });

    return returnArray;
  }, [
    entityProperties,
    isClientAutoCompleteDisabled,
    isListTypeLoading,
    listTypeData?.changeable_payment,
    listTypeData?.klient_isSender,
    t,
  ]);

  const {
    data: formSubmissionData,
    error: formSubmissionError,
    isLoading: isFormSubmissionLoading,
    reload: formSubmissionReload,
  } = useAxiosHook<OrderImport, OrderImportError>();

  const prevformSubmissionData = usePrevious(formSubmissionData);
  const prevformSubmissionError = usePrevious(formSubmissionError);

  useEffect(() => {
    if (!formSubmissionData || formSubmissionData === prevformSubmissionData) {
      return;
    }

    onImport?.(formSubmissionData);

    onHide();
    reloadImportedLists();

    successToast(
      toastRef,
      t('Success'),
      formSubmissionData.generalMsg ?? t('List successfully imported')
    );
  }, [
    formSubmissionData,
    onHide,
    onImport,
    prevformSubmissionData,
    reloadImportedLists,
    t,
    toastRef,
  ]);

  useEffect(() => {
    if (
      !formSubmissionError ||
      formSubmissionError === prevformSubmissionError
    ) {
      return;
    }

    onHide();

    errorToast(
      toastRef,
      t('Error'),
      formSubmissionError.response?.data.generalMsg ??
        t('An error occured while trying to import your list')
    );
  }, [formSubmissionError, onHide, prevformSubmissionError, t, toastRef]);

  function onFormSubmit(values: FormValues) {
    formSubmissionReload({
      url: '/orders/import',
      data: toApiData(values),
      method: 'POST',
    });
  }

  return (
    <FormProvider {...methods}>
      <form id="webImportForm" onSubmit={handleSubmit(onFormSubmit)}>
        <StepperDialog
          visible={isShown}
          onHide={onHide}
          header={t('Web importer')}
          steps={steps}
          nextButtonProps={{ form: 'webImportForm' }}
          onNextBtnClick={async ({ stepIndex }) => {
            return await trigger(steps[stepIndex].fieldNames);
          }}
          showStepCounter={!!listTypeData && !!_clientListType}
          isLoading={
            isLoading ||
            isListTypeLoading ||
            isEntityPropertiesLoading ||
            isFormSubmissionLoading
          }
          maximizable
          maximized
          className="imported-lists-new-web-import-dialog"
        />
      </form>
    </FormProvider>
  );
}

export default WebImportDialog;
