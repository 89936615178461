import classNames from 'classnames';
import { TFunction } from 'react-i18next';

import { IntBool } from '../../../../../enums/booleans';
import { AssignmentType, OrderStatus } from '../../../../../enums/orders';
import { ReconciledOrderType } from '../../../../../enums/reconciliations';
import {
  DeliveredShipmentReconciliationCollection,
  PickedUpShipmentReconciliationCollection,
} from '../../../../../types/api/reconciliations';
import { Unpacked } from '../../../../../types/util';
import { DataRow, ReconcileShipments } from './ShipmentReconciliation.types';

export const tabPaths = ['delivered', 'picked-up'];

function isParcelDoesNotContainCash(row: DataRow, type: ReconciledOrderType) {
  if (type === ReconciledOrderType.Delivered) {
    return (
      parseFloat(row.redemption) == 0 && parseFloat(row.cash_delivery) == 0
    );
  }

  return parseFloat(row.cash_pickup) == 0;
}

function isReconciledRow(row: DataRow) {
  return row.is_reconciled === IntBool.True;
}

function isWithReturnDocument(row: DataRow) {
  return row.returning_document === IntBool.True;
}

function isPickupReconciliationRequired(row: DataRow) {
  return (
    row.shipment_type == AssignmentType.Pickup &&
    row.status_id >= OrderStatus.PickedUp &&
    parseFloat(row.cash_pickup) > 0
  );
}

function isDeliveryReconciliationRequired(row: DataRow) {
  return (
    row.shipment_type == AssignmentType.Delivery &&
    row.status_id >= OrderStatus.MarkedAsDelivered
  );
}

function isDeliveryReconciliationRow(row: DataRow) {
  return row.shipment_type == AssignmentType.Delivery;
}

export function generateTooltipText(
  row: DataRow,
  type: ReconciledOrderType,
  t: TFunction
) {
  let tooltip = t('Expected to be reconciled');

  if (isReconciledRow(row)) {
    tooltip = t('Is reconciled');
  }

  if (isWithReturnDocument(row)) {
    tooltip = t('Parcel contains return document');
  }

  if (isReconciledRow(row) && isWithReturnDocument(row)) {
    tooltip = t('Parcel is reconciled and contains return document');
  }

  if (isParcelDoesNotContainCash(row, type)) {
    tooltip += ',\n' + t("Parcel doesn't contain cash");
  }

  return <span style={{ whiteSpace: 'pre-line' }}>{tooltip}</span>;
}

export function additionalRowClassNames(row: DataRow) {
  return classNames({
    'bg-orange-100':
      isPickupReconciliationRequired(row) ||
      isDeliveryReconciliationRequired(row),
    'bg-blue-100':
      isWithReturnDocument(row) && isDeliveryReconciliationRow(row),
    'bg-blue-200':
      isReconciledRow(row) &&
      isWithReturnDocument(row) &&
      isDeliveryReconciliationRow(row),
    'bg-green-200 p-disabled': isReconciledRow(row),
  });
}

export function sortShipmentsPredicate(
  a: Unpacked<
    | DeliveredShipmentReconciliationCollection['data']
    | PickedUpShipmentReconciliationCollection['data']
  >,
  b: Unpacked<
    | DeliveredShipmentReconciliationCollection['data']
    | PickedUpShipmentReconciliationCollection['data']
  >
) {
  const statusOrder = [
    OrderStatus.HandedOverToADeliveryCourier,
    OrderStatus.MarkedAsDelivered,
    OrderStatus.Delivered,
  ];

  const indexA = statusOrder.indexOf(a.status_id);
  const indexB = statusOrder.indexOf(b.status_id);

  if (indexA === -1) {
    return 1;
  } else if (indexB === -1) {
    return -1;
  }

  if (indexA < indexB) {
    return -1;
  } else if (indexA > indexB) {
    return 1;
  } else {
    return 0;
  }
}

export function printInvalidShipments(
  updateData: ReconcileShipments,
  t: TFunction
) {
  return (
    <ul style={{ padding: 0 }}>
      {updateData.data.map((assignment) => {
        return (
          <li>
            {assignment.serial_number
              .concat(': ')
              .concat(t([`${assignment.error}`, 'Unknown error']))
              .concat('\n')}
          </li>
        );
      })}
    </ul>
  );
}
