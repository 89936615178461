import './ForceSetPassword.scss';

import { Field, Form, Formik, FormikProps } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Password } from 'primereact/password';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import useAxiosHook from '../../../../../hooks/useAxiosHook';
import useToastMessage from '../../../../../hooks/useToastMessage';
import { ClientCollection } from '../../../../../types/api/clients';
import { Unpacked } from '../../../../../types/util';
import {
  mediumStrenthRegex,
  passwordFeedback,
  strongStrenthRegex,
} from '../../../../../utils/constants/passwords';
import FieldWithErrors from '../../../../Forms/FieldWithErrors/FieldWithErrors';
import {
  FormFields,
  PasswordSetErrorResponse,
  SuccessObj,
  generateErrorMessage,
  getInitialValues,
  getValidationSchema,
} from './ForceSetPassword.functions';

type Props = {
  isShown: boolean;
  onHide: () => void;
  client: Unpacked<ClientCollection> | undefined;
};

function ForcePasswordReset({ isShown, onHide, client }: Props): JSX.Element {
  const { t } = useTranslation();

  const formRef = useRef<FormikProps<any>>(null);

  const { data, error, isLoading, reload } =
    useAxiosHook<SuccessObj, PasswordSetErrorResponse>();

  useToastMessage(data, error, {
    success: {
      title: t('Success'),
      summary: t('Successfully changed password'),
      callback: onHide,
    },
    error: {
      title: t('Force set password error'),
      summary: generateErrorMessage(t, error),
    },
  });

  function handleFormSubmision(values: FormFields) {
    reload({
      url: `/users/${client?.korisnik_id}/password`,
      data: values,
      method: 'PUT',
    });
  }

  const dialogFooter = (
    <>
      <Button
        type="button"
        label={t('Cancel')}
        className="p-button-text"
        onClick={() => onHide()}
      />

      <Button
        type="button"
        label={isLoading ? t('Resetting...') : t('Reset password')}
        disabled={isLoading}
        onClick={() => {
          formRef?.current?.handleSubmit();
        }}
        data-cy="submit"
      />
    </>
  );

  return (
    <Dialog
      header={t('Force set password for client {{name}}', {
        name: client?.ime,
      })}
      footer={dialogFooter}
      visible={isShown}
      onHide={onHide}
      resizable={false}
      className="force-set-pass-dialog"
    >
      <Formik
        innerRef={formRef}
        initialValues={getInitialValues()}
        validationSchema={getValidationSchema(t)}
        onSubmit={handleFormSubmision}
        validateOnBlur
      >
        <Form>
          <div className="p-fluid">
            <FieldWithErrors name="new_password" label={t('New Password')}>
              <Field
                as={Password}
                id="new_password"
                name="new_password"
                feedback
                header={passwordFeedback(t)}
                mediumRegex={mediumStrenthRegex as any}
                strongRegex={strongStrenthRegex as any}
                toggleMask
                autoComplete="off"
                promptLabel={t('Enter a new password')}
                style={{ width: '100%' }}
              />
            </FieldWithErrors>

            <FieldWithErrors
              name="confirm_password"
              label={t('Confirm Password')}
            >
              <Field
                as={Password}
                id="confirm_password"
                name="confirm_password"
                feedback
                mediumRegex={mediumStrenthRegex as any}
                strongRegex={strongStrenthRegex as any}
                toggleMask
                autoComplete="off"
                promptLabel={t('Confirm password')}
                style={{ width: '100%' }}
              />
            </FieldWithErrors>
          </div>
        </Form>
      </Formik>
    </Dialog>
  );
}

export default ForcePasswordReset;
