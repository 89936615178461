import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';

import ToastContext from '../../../../../context/ToastContext';
import { OrderStatus } from '../../../../../enums/orders';
import useAxiosHook from '../../../../../hooks/useAxiosHook';
import usePrevious from '../../../../../hooks/usePrevious';
import { Numeric } from '../../../../../types/general';
import {
  errorToast,
  successToast,
} from '../../../../../utils/helpers/primereact';
import { Order } from '../../Orders.functions';
import { toApiData } from '../Cancel/CancelOrderDialog.functions';
import { generateErrorMsg } from './DeleteDialog.functions';

type Props = {
  visible: boolean;
  data: Order[];
  onHide: () => void;
  reloadOrders: () => void;
  setSelectionMultiple: Dispatch<SetStateAction<any[]>>;
};

function DeleteDialog({
  visible,
  data,
  onHide,
  reloadOrders,
  setSelectionMultiple,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { toastRef } = useContext(ToastContext);
  const {
    data: deleteData,
    error: deleteError,
    reload: deleteReload,
    isLoading: isDeleteLoading,
  } = useAxiosHook<undefined, { error?: string }>();

  function handleDeleteOrder(orderId: Numeric) {
    if (!orderId) {
      return;
    }
    deleteReload({ url: `orders/${orderId}`, method: 'DELETE' });
  }

  const prevDeleteError = usePrevious(deleteError);
  const prevDeleteData = usePrevious(deleteData);

  useEffect(() => {
    if (!deleteData || deleteData === prevDeleteData) {
      return;
    }

    if (toastRef?.current) {
      successToast(
        toastRef,
        t('Success'),
        data?.length > 1
          ? t('Orders successfully deleted.')
          : t('Order {{serialNo}} was successfully deleted.', {
              serialNo: data[0]?.seriski_broj ?? '',
            })
      );
    }
    onHide();
    reloadOrders();
    setSelectionMultiple([]);
  }, [
    data,
    deleteData,
    prevDeleteData,
    onHide,
    reloadOrders,
    setSelectionMultiple,
    t,
    toastRef,
  ]);

  useEffect(() => {
    if (!deleteError || deleteError === prevDeleteError) {
      return;
    }
    const errText = deleteError?.response?.data?.error;

    if (toastRef?.current) {
      errorToast(
        toastRef,
        t('Error'),
        data?.length > 1
          ? t('Failed group deleting. {{errorMessage}}', {
              errorMessage: errText ? generateErrorMsg(t, errText) : '',
            })
          : t('Deleting order {{serialNo}} failed. {{errorMessage}}.', {
              serialNo: data[0]?.seriski_broj ?? '',
              errorMessage: errText ? generateErrorMsg(t, errText) : '',
            })
      );
    }

    onHide();
  }, [data, deleteError, onHide, prevDeleteError, t, toastRef]);

  function handleDeleteSubmission() {
    if (!data?.length) {
      return;
    }
    const deletableData = toApiData(data);
    deletableData.forEach((o) => handleDeleteOrder(o.id));
  }

  const nonDeletableOrders = useMemo(
    () => data?.filter((o) => o?.status_id > OrderStatus.Cancelled),
    [data]
  );

  const ordersForDelete = useMemo(
    () => data?.filter((o) => o?.status_id <= OrderStatus.Cancelled),
    [data]
  );

  const dialogFooter = (
    <div className='className="p-pt-4"'>
      <Button
        type="button"
        label={t('Close')}
        onClick={() => onHide()}
        className="p-button-secondary p-button-text"
      />

      <Button
        type="button"
        label={isDeleteLoading ? t('Deleting...') : t('Delete')}
        disabled={isDeleteLoading || ordersForDelete.length === 0}
        onClick={() => {
          handleDeleteSubmission();
        }}
        className="p-button-danger"
        data-cy="submit-btn"
      />
    </div>
  );

  return (
    <Dialog
      header={data?.length > 1 ? t('Delete orders') : t('Delete order')}
      footer={dialogFooter}
      visible={visible}
      resizable={false}
      onHide={onHide}
      style={{ width: 480, maxWidth: '100%' }}
    >
      {nonDeletableOrders?.length > 0 && (
        <p>
          <b className="p-mt-0" style={{ color: 'var(--yellow-500)' }}>
            {t('WARNING')}: {t('The following orders cannot be deleted.')}
            <br />
            {nonDeletableOrders?.map((o) => (
              <div key={'nonDeletable-' + o?.id}>
                {' '}
                <b style={{ color: 'var(--yellow-600)' }}>{o?.seriski_broj}</b>
              </div>
            ))}
            <br />
            {ordersForDelete?.length > 0 && t('You can still delete the rest.')}
          </b>
        </p>
      )}
      {ordersForDelete?.length > 0 && (
        <p>
          {ordersForDelete?.length > 1 ? (
            t('Are you sure you want to delete this group of orders?')
          ) : (
            <>
              {t('Are you sure you want to delete the following order?')}{' '}
              <b>{ordersForDelete[0]?.seriski_broj}</b>
            </>
          )}
          <br />
          <br />
          {t('This action cannot be undone.')}
        </p>
      )}
    </Dialog>
  );
}

export default DeleteDialog;
