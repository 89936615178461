import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import useAxiosHook from '../../../../../../../hooks/useAxiosHook';
import useToastMessage from '../../../../../../../hooks/useToastMessage';
import { CreateCustomerReceptionResource } from '../../../../../../../types/api/customerReceptions';
import Content from './Content';
import {
  defaultValues,
  getValidationSchema,
  toApiData,
} from './NewReceptionDialog.functions';
import { FormFields } from './NewReceptionDialog.types';

type Props = {
  visible: boolean;
  onHide: () => void;
  onSuccess: (id: number) => void;
};

function NewReceptionDialog({
  visible,
  onHide,
  onSuccess,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const resolver = useMemo(() => yupResolver(getValidationSchema(t)), [t]);
  const methods = useForm<FormFields>({
    defaultValues,
    resolver,
  });
  const { handleSubmit, reset, setError } = methods;

  useEffect(() => {
    if (!visible) {
      reset();
    }
  }, [reset, visible]);

  const { data, error, isLoading, reload, response } =
    useAxiosHook<CreateCustomerReceptionResource>();

  useToastMessage(data, error, {
    success: {
      summary: t('Reception successfully created!'),
      callback: () => {
        onHide();
        onSuccess(data!.id);
      },
    },
    error:
      response?.status === 422
        ? undefined
        : {
            summary: t(
              'An error occured while trying to create the reception.'
            ),
            callback: onHide,
          },
  });

  useEffect(() => {
    if (response?.status !== 422) {
      return;
    }

    setError('client_id', {
      type: 'custom',
      message: t('An active reception with this client already exists!'),
    });
  }, [response?.status, setError, t]);

  const dialogFooter = (
    <>
      <Button
        type="button"
        label={t('Close')}
        onClick={onHide}
        className="p-button-text"
      />

      <Button
        type="submit"
        form="new-reception-form"
        label={isLoading ? t('Adding...') : t('Add')}
        disabled={isLoading}
      />
    </>
  );

  function onFormSubmit(values: FormFields) {
    reload({
      url: '/customer-receptions',
      method: 'POST',
      data: toApiData(values),
    });
  }

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header={t('New Reception')}
      footer={dialogFooter}
      closeOnEscape={false}
    >
      <FormProvider {...methods}>
        <form id="new-reception-form" onSubmit={handleSubmit(onFormSubmit)}>
          <Content />
        </form>
      </FormProvider>
    </Dialog>
  );
}

export default NewReceptionDialog;
