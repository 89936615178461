import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TFunction } from 'i18next';

import { ClientLookupCollection } from '../../../../types/api/clients';
import { Numeric } from '../../../../types/general';
import { Unpacked } from '../../../../types/util';
import { noColumnStyle } from '../../../../utils/constants/tables';
import { currencyFormat } from '../../../../utils/helpers/formatting';

export type ClientOption = Pick<Unpacked<ClientLookupCollection>, 'id' | 'ime'>;

type CustomerEarnings = {
  id: Numeric;
  ime: string;
  posledna_nedela_gotovina: Numeric;
  posledna_nedela_faktura: Numeric;
  nedelen_prosek: Numeric;
  nedelna_tendencija: string;
  posleden_mesec_gotovina: Numeric;
  posleden_mesec_faktura: Numeric;
  mesecen_prosek: Numeric;
  mesecna_tendencija: string;
  posledna_godina_gotovina: Numeric;
  posledna_godina_faktura: Numeric;
  godisen_prosek: Numeric;
  godisna_tendencija: string;
};

export const tableStorageKey = 'customer_earnings_dataTable';

export function getColumnHeadersMap(t: TFunction) {
  return {
    no: t('No.'),
    id: t('ID'),
    ime: t('Name'),
    posledna_nedela_gotovina: t('Last Week Cash'),
    posledna_nedela_faktura: t('Last Week Invoice'),
    nedelen_prosek: t('Weekly Average'),
    nedelna_tendencija: t('Weekly Trend'),
    posleden_mesec_gotovina: t('Last Month Cash'),
    posleden_mesec_faktura: t('Last Month Invoice'),
    mesecen_prosek: t('Monthly Average'),
    mesecna_tendencija: t('Monthly Trend'),
    posledna_godina_gotovina: t('Last Year Cash'),
    posledna_godina_faktura: t('Last Year Invoice'),
    godisen_prosek: t('Yearly Average'),
    godisna_tendencija: t('Yearly Trend'),
  };
}

export function additionalListsColumnProperties(t: TFunction, column: string) {
  switch (column) {
    case 'no':
      return noColumnStyle;
    case 'id':
      return {
        style: {
          width: 80,
        },
        bodyStyle: {
          width: 80,
        },
      };
    case 'ime':
      return {
        style: {
          width: 250,
        },
        bodyStyle: {
          width: 250,
        },
      };
    case 'posledna_nedela_gotovina':
    case 'posledna_nedela_faktura':
    case 'posleden_mesec_gotovina':
    case 'posleden_mesec_faktura':
    case 'posledna_godina_gotovina':
    case 'posledna_godina_faktura':
    case 'nedelen_prosek':
    case 'mesecen_prosek':
    case 'godisen_prosek':
      return {
        body: (row: CustomerEarnings) =>
          currencyFormat(row[column], { showCurrency: true }),
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
        },
      };
    case 'nedelna_tendencija':
    case 'mesecna_tendencija':
    case 'godisna_tendencija':
      return {
        body: (row: CustomerEarnings) => (
          <span
            data-tip={
              row[column]
                ? row[column] === 'up'
                  ? t('Increasing')
                  : t('Decreasing')
                : t('Unknown')
            }
            data-for="tooltip-right"
          >
            <FontAwesomeIcon
              icon={row[column] === 'up' ? faArrowUp : faArrowDown}
              color={row[column] === 'up' ? 'var(--green-600)' : '#D32F2F'}
            />
          </span>
        ),
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
          textAlign: 'center',
        },
      };
    default:
      return { style: { textAlign: 'center' } };
  }
}
export const mockData = {
  data: [
    {
      id: '11322',
      ime: 'ДАФИНА ТРЕЈД - Дирекција',
      posledna_nedela_gotovina: '0',
      posledna_nedela_faktura: '0',
      posleden_mesec_gotovina: '28',
      posleden_mesec_faktura: '28',
      posledna_godina_gotovina: '28',
      posledna_godina_faktura: '28',
      nedelen_prosek: '10',
      nedelna_tendencija: 'up',
      mesecen_prosek: '20',
      mesecna_tendencija: 'down',
      godisen_prosek: '100',
      godisna_tendencija: 'up',
    },
    {
      id: '11527',
      ime: 'Z-VERSACE HQ',
      posledna_nedela_gotovina: '0',
      posledna_nedela_faktura: '0',
      posleden_mesec_gotovina: '28',
      posleden_mesec_faktura: '28',
      posledna_godina_gotovina: '28',
      posledna_godina_faktura: '28',
      nedelen_prosek: '10',
      nedelna_tendencija: 'up',
      mesecen_prosek: '20',
      mesecna_tendencija: 'down',
      godisen_prosek: '100',
      godisna_tendencija: 'up',
    },
    {
      id: '11324',
      ime: '01АЕРОДРОМ ОНЛАЈН ШОП',
      posledna_nedela_gotovina: '0',
      posledna_nedela_faktura: '0',
      posleden_mesec_gotovina: '28',
      posleden_mesec_faktura: '28',
      posledna_godina_gotovina: '28',
      posledna_godina_faktura: '28',
      nedelen_prosek: '10',
      nedelna_tendencija: 'up',
      mesecen_prosek: '20',
      mesecna_tendencija: 'down',
      godisen_prosek: '100',
      godisna_tendencija: 'up',
    },
    {
      id: '11462',
      ime: 'Kiro',
      posledna_nedela_gotovina: '0',
      posledna_nedela_faktura: '0',
      posleden_mesec_gotovina: '28',
      posleden_mesec_faktura: '28',
      posledna_godina_gotovina: '28',
      posledna_godina_faktura: '28',
      nedelen_prosek: '10',
      nedelna_tendencija: 'up',
      mesecen_prosek: '20',
      mesecna_tendencija: 'down',
      godisen_prosek: '100',
      godisna_tendencija: 'up',
    },
    {
      id: '2594',
      ime: '0-Кодерит Пошта - Достава на Откуп',
      posledna_nedela_gotovina: '0',
      posledna_nedela_faktura: '0',
      posleden_mesec_gotovina: '28',
      posleden_mesec_faktura: '28',
      posledna_godina_gotovina: '28',
      posledna_godina_faktura: '28',
      nedelen_prosek: '10',
      nedelna_tendencija: 'up',
      mesecen_prosek: '20',
      mesecna_tendencija: 'down',
      godisen_prosek: '100',
      godisna_tendencija: 'up',
    },
    {
      id: '11482',
      ime: 'Igor Test',
      posledna_nedela_gotovina: '0',
      posledna_nedela_faktura: '0',
      posleden_mesec_gotovina: '28',
      posleden_mesec_faktura: '28',
      posledna_godina_gotovina: '28',
      posledna_godina_faktura: '28',
      nedelen_prosek: '10',
      nedelna_tendencija: 'up',
      mesecen_prosek: '20',
      mesecna_tendencija: 'down',
      godisen_prosek: '100',
      godisna_tendencija: 'up',
    },
    {
      id: '11429',
      ime: '01ПРИЛЕП ОНЛАЈН ШОП-Дирекција',
      posledna_nedela_gotovina: '0',
      posledna_nedela_faktura: '0',
      posleden_mesec_gotovina: '28',
      posleden_mesec_faktura: '28',
      posledna_godina_gotovina: '28',
      posledna_godina_faktura: '28',
      nedelen_prosek: '10',
      nedelna_tendencija: 'up',
      mesecen_prosek: '20',
      mesecna_tendencija: 'down',
      godisen_prosek: '100',
      godisna_tendencija: 'up',
    },
    {
      id: '11500',
      ime: 'BlaBlaTest',
      posledna_nedela_gotovina: '0',
      posledna_nedela_faktura: '0',
      posleden_mesec_gotovina: '28',
      posleden_mesec_faktura: '28',
      posledna_godina_gotovina: '28',
      posledna_godina_faktura: '28',
      nedelen_prosek: '10',
      nedelna_tendencija: 'up',
      mesecen_prosek: '20',
      mesecna_tendencija: 'down',
      godisen_prosek: '100',
      godisna_tendencija: 'up',
    },
    {
      id: '10',
      ime: '0-Кодерит Пошта - Достава на вратена пратка',
      posledna_nedela_gotovina: '0',
      posledna_nedela_faktura: '0',
      posleden_mesec_gotovina: '28',
      posleden_mesec_faktura: '28',
      posledna_godina_gotovina: '28',
      posledna_godina_faktura: '28',
      nedelen_prosek: '10',
      nedelna_tendencija: 'up',
      mesecen_prosek: '20',
      mesecna_tendencija: 'down',
      godisen_prosek: '100',
      godisna_tendencija: 'up',
    },
    {
      id: '11490',
      ime: 'Blagoja Ovezoski',
      posledna_nedela_gotovina: '0',
      posledna_nedela_faktura: '0',
      posleden_mesec_gotovina: '28',
      posleden_mesec_faktura: '28',
      posledna_godina_gotovina: '28',
      posledna_godina_faktura: '28',
      nedelen_prosek: '10',
      nedelna_tendencija: 'up',
      mesecen_prosek: '20',
      mesecna_tendencija: 'down',
      godisen_prosek: '100',
      godisna_tendencija: 'up',
    },
    {
      id: '11463',
      ime: '01БУТЕЛ ОНЛАЈН ШОП',
      posledna_nedela_gotovina: '0',
      posledna_nedela_faktura: '0',
      posleden_mesec_gotovina: '28',
      posleden_mesec_faktura: '28',
      posledna_godina_gotovina: '28',
      posledna_godina_faktura: '28',
      nedelen_prosek: '10',
      nedelna_tendencija: 'up',
      mesecen_prosek: '20',
      mesecna_tendencija: 'down',
      godisen_prosek: '100',
      godisna_tendencija: 'up',
    },
    {
      id: '11383',
      ime: '01ЦЕНТАР ОНЛАЈН ШОП',
      posledna_nedela_gotovina: '0',
      posledna_nedela_faktura: '0',
      posleden_mesec_gotovina: '28',
      posleden_mesec_faktura: '28',
      posledna_godina_gotovina: '28',
      posledna_godina_faktura: '28',
      nedelen_prosek: '10',
      nedelna_tendencija: 'up',
      mesecen_prosek: '20',
      mesecna_tendencija: 'down',
      godisen_prosek: '100',
      godisna_tendencija: 'up',
    },
    {
      id: '11420',
      ime: 'ДАФИНА ТРЕЈД-ПО1 ШТИП',
      posledna_nedela_gotovina: '0',
      posledna_nedela_faktura: '0',
      posleden_mesec_gotovina: '28',
      posleden_mesec_faktura: '28',
      posledna_godina_gotovina: '28',
      posledna_godina_faktura: '28',
      nedelen_prosek: '10',
      nedelna_tendencija: 'up',
      mesecen_prosek: '20',
      mesecna_tendencija: 'down',
      godisen_prosek: '100',
      godisna_tendencija: 'up',
    },
    {
      id: '11518',
      ime: 'Marija Jovanoska',
      posledna_nedela_gotovina: '0',
      posledna_nedela_faktura: '0',
      posleden_mesec_gotovina: '28',
      posleden_mesec_faktura: '28',
      posledna_godina_gotovina: '28',
      posledna_godina_faktura: '28',
      nedelen_prosek: '10',
      nedelna_tendencija: 'up',
      mesecen_prosek: '20',
      mesecna_tendencija: 'down',
      godisen_prosek: '100',
      godisna_tendencija: 'up',
    },
    {
      id: '11367',
      ime: '01КИСЕЛА ВОДА ОНЛАЈН ШОП',
      posledna_nedela_gotovina: '0',
      posledna_nedela_faktura: '0',
      posleden_mesec_gotovina: '28',
      posleden_mesec_faktura: '28',
      posledna_godina_gotovina: '28',
      posledna_godina_faktura: '28',
      nedelen_prosek: '10',
      nedelna_tendencija: 'up',
      mesecen_prosek: '20',
      mesecna_tendencija: 'down',
      godisen_prosek: '100',
      godisna_tendencija: 'up',
    },
  ],
  pagination: {
    current_page: 1,
    requested_page: 1,
    last_page: 3,
    limit: 15,
    from: 1,
    to: 15,
    total: 15,
  },
};
