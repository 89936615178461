import { TFunction } from 'react-i18next';

import { WithPagination } from '../../../types/api';
import { dateCell } from '../../../utils/helpers/dataTable';
import { contextMenuModel } from '../../../utils/helpers/primereact';

export type DialogRouteParams = {
  id?: string;
};

type ClientType =
  | 'tp-customer'
  | 'gls-admin'
  | 'gls-automated-report'
  | 'gls-courier'
  | 'gls-customer'
  | 'gls-warehouse'
  | 'gls-web';

export type OAuthClient = {
  id: number;
  client_name: string;
  client_id: string;
  client_secret: string;
  type: ClientType;
  created_at: string;
  modified_at: string;
  created_by_full_name: string;
  modified_by_full_name: string;
};

export const tableStorageKey = 'oauthClients_dataTable';

export function getColumnHeadersMap(t: TFunction): {
  [k in keyof Omit<OAuthClient, 'id'>]: string;
} {
  return {
    client_name: t('Name'),
    client_id: t('ID'),
    client_secret: t('Secret'),
    type: t('Type'),
    created_at: t('Created at'),
    created_by_full_name: t('Created by'),
    modified_at: t('Last modified'),
    modified_by_full_name: t('Last modified by'),
  };
}

export type ApiData = WithPagination<OAuthClient[]>;

export function generateContextMenu(
  t: TFunction,
  selection: OAuthClient | null,
  handleCMViewClick: () => void,
  isEditShown: boolean,
  handleCMEditClick: () => void,
  isDeleteShown: boolean,
  handleCMDeleteClick: () => void,
  isEmailShown: boolean,
  handleCMEmailCredentialsClick: () => void,
  handleCMCopyDocUrlClick: () => void
) {
  const viewOption = {
    label: t('View credentials'),
    icon: 'fas fa-search',
    command: handleCMViewClick,
  };

  const editOption = {
    label: t('Edit credentials'),
    icon: 'fas fa-edit',
    command: handleCMEditClick,
  };

  const deleteOption = {
    label: t('Delete credentials'),
    icon: 'fas fa-trash',
    command: handleCMDeleteClick,
  };

  const emailOption = {
    label: t('Email credentials'),
    icon: 'fas fa-paper-plane',
    command: handleCMEmailCredentialsClick,
  };

  const copyDocUrlOption = {
    label: t('Copy documentation URL'),
    icon: 'fas fa-clipboard',
    command: handleCMCopyDocUrlClick,
  };

  const isTpCustomer = selection?.type === 'tp-customer';

  return contextMenuModel([
    [[true, viewOption]],
    [[isTpCustomer, copyDocUrlOption]],
    [
      [isTpCustomer && isEditShown, editOption],
      [isTpCustomer && isDeleteShown, deleteOption],
    ],
    [[isEmailShown, emailOption]],
  ]);
}

export function additionalColumnProperties(
  column: keyof ReturnType<typeof getColumnHeadersMap>
) {
  switch (column) {
    case 'client_name':
      return {
        style: {
          width: 220,
        },
        bodyStyle: {
          width: 220,
        },
      };

    case 'client_id':
    case 'client_secret':
      return {
        body: (row: OAuthClient) => {
          return `${row[column].substring(0, 12)}...`;
        },
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
        },
      };

    case 'type':
      return {
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
        },
      };

    case 'created_at':
    case 'modified_at':
      return {
        body: (row: OAuthClient) => {
          return dateCell(row[column]);
        },
        style: {
          width: 180,
        },
        bodyStyle: {
          width: 180,
        },
      };

    case 'created_by_full_name':
    case 'modified_by_full_name':
      return {
        style: {
          width: 250,
        },
        bodyStyle: {
          width: 250,
        },
      };

    default:
      return {};
  }
}
