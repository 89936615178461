import '../../Orders/Dialogs/View/ViewOrderDialog.scss';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';

import useAxiosHook from '../../../../hooks/useAxiosHook';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import DialogSpinner from '../../../Dialogs/DialogSpinner/DialogSpinner';
import ViewOrderContent from '../../Orders/Dialogs/View/ViewOrderContent';

function ViewOrderDialog({ isShown, dataId, onHide, onHistory }) {
  const { t } = useTranslation();

  const { data, isLoading } = useAxiosHook(`/orders/${dataId}`, {
    skipWhen: !dataId || !isShown,
  });

  const dialogFooter = (
    <>
      <Button
        label={t('History')}
        className="p-button-text"
        onClick={() => onHistory()}
      />
      <Button label={t('Close')} onClick={() => onHide()} />
    </>
  );

  const isOnMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Dialog
      visible={isShown}
      onHide={onHide}
      header={t('Order {{order}}', {
        order: isLoading ? '' : data?.seriski_broj,
      })}
      footer={dialogFooter}
      dismissableMask
      blockScroll
      maximizable
      maximized={isOnMobile}
      className="view-order-dialog"
    >
      {isLoading ? <DialogSpinner /> : <ViewOrderContent data={data} />}
    </Dialog>
  );
}

export default ViewOrderDialog;
