import { Field, useFormikContext } from 'formik';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import { useTranslation } from 'react-i18next';

import { bankPrefixes } from '../../../../../../utils/globals';
import FieldWithErrors from '../../../../../Forms/FieldWithErrors/FieldWithErrors';
import { isHeadquarter } from '../CreateEditDialog.functions';
import BankAccountField from './Fields/BankAccountField';
import CodBankAccountPaymentField from './Fields/CodBankAccountPaymentField';
import PersonalSubjectNoField from './Fields/PersonalSubjectNoField';
import PriceListField from './Fields/PriceListField';
import WorkingHoursField from './Fields/WorkingHoursField';

function getBankName(value) {
  const bank = Object.keys(bankPrefixes).find((k) => k === value.slice(0, 3));

  return bank ? bankPrefixes[bank] : null;
}

function LegalDataTab({
  clientData,
  headquarterData,
  reloadHeadquarterData,
  isHeadquarterDataLoading,
  priceListOptions,
  arePriceListsLoading,
  headQuarterOptions,
  isLegalEntitiesLoading,
  invoicingAndCODDepartmentOptions,
  isEditDialog,
  invoicingCheckboxProperties,
}) {
  const { t } = useTranslation();

  const { values, setFieldValue } = useFormikContext();

  const onChangeHeadquarterId = (e) => {
    const { name, value } = e.target;

    setFieldValue(name, value);

    setFieldValue(
      'departments',
      isEditDialog && clientData?.departments?.length > 0
        ? clientData.departments
        : [
            {
              id: null,
              name: 'Default',
              headquarters_id: null,
              charge_customer_id: 0,
            },
          ]
    );

    if (value === null || parseInt(clientData?.id) === value) {
      return;
    }

    reloadHeadquarterData({
      url: `/clients/${value}`,
      successCallback: (clientData) => {
        if (!!parseInt(clientData.cenovnik_direkcija)) {
          setFieldValue('cenovnik_id', clientData.cenovnik_id);
        }
      },
    });
  };

  return (
    <div className="legal-data-tab p-fluid">
      <div className="p-grid">
        <div className="p-col-12 p-md-6">
          <FieldWithErrors name="broj_dogovor" label={t('Contract No.')}>
            <Field
              as={InputText}
              name="broj_dogovor"
              id="broj_dogovor"
              maxLength="100"
              data-cy="contract_no"
            />
          </FieldWithErrors>

          <PersonalSubjectNoField
            label={t('Social Security No.')}
            maxLength="7"
          />

          <FieldWithErrors name="danocen_broj" label={t('Tax No.')}>
            <Field
              as={InputText}
              name="danocen_broj"
              id="danocen_broj"
              tooltip={
                !values.danocen_broj?.length || values.danocen_broj.length < 3
                  ? null
                  : getBankName(values.danocen_broj)
              }
              maxLength="15"
              onChange={(e) => {
                setFieldValue(
                  'danocen_broj',
                  e.target.value.replace(/[^MKМК\d]/g, '')
                );
              }}
              data-cy="tax_no"
            />
          </FieldWithErrors>

          <BankAccountField />
        </div>

        <div className="p-col-12 p-md-6">
          <FieldWithErrors name="direkcija_id" label={t('Headquarter')}>
            <Field
              as={Dropdown}
              name="direkcija_id"
              inputId="direkcija_id"
              filter
              options={headQuarterOptions}
              placeholder={
                isLegalEntitiesLoading
                  ? t('Loading...')
                  : t('This client is a headquarter')
              }
              onChange={onChangeHeadquarterId}
              className="data-cy-headquarter"
            />
          </FieldWithErrors>

          <FieldWithErrors name="rok_plakanje" label={t('Payment due')}>
            <Tooltip
              target=".payment-due-tooltip"
              position="left"
              style={
                invoicingCheckboxProperties.checked ? { display: 'none' } : {}
              }
            />

            <div
              className="payment-due-tooltip"
              data-pr-tooltip={t('Invoicing is required')}
            >
              <Field
                as={InputText}
                name="rok_plakanje"
                id="rok_plakanje"
                disabled={!invoicingCheckboxProperties.checked}
                onChange={(e) => {
                  setFieldValue(
                    'rok_plakanje',
                    e.target.value.replace(/\D/g, '')
                  );
                }}
                data-cy="payment_due"
              />
            </div>
          </FieldWithErrors>

          <WorkingHoursField />

          <PriceListField
            priceListOptions={priceListOptions}
            isHeadquarter={isHeadquarter}
            headquarterData={headquarterData}
            isHeadquarterDataLoading={isHeadquarterDataLoading}
            arePriceListsLoading={arePriceListsLoading}
          />

          <FieldWithErrors name="force_pricelist_to_receiver" label={false}>
            <Field
              as={Checkbox}
              name="force_pricelist_to_receiver"
              inputId="force_pricelist_to_receiver"
              checked={values.force_pricelist_to_receiver}
            />
            <label
              htmlFor="force_pricelist_to_receiver"
              data-cy="force_price_list_to_receiver"
            >
              {t('Use the same price list for recipient')}
            </label>
          </FieldWithErrors>
        </div>
      </div>

      <span className="dialog-section-divider">{t('Settings')}</span>

      <div className="p-grid">
        <div className="p-col-12 p-md-6">
          <FieldWithErrors name="faktura" label={false}>
            <Field
              name="faktura"
              inputId="faktura"
              as={Checkbox}
              {...invoicingCheckboxProperties}
              className="data-cy-invoicing"
            />

            <label htmlFor="faktura">{t('Invoicing')}</label>
          </FieldWithErrors>

          <CodBankAccountPaymentField />
        </div>

        {isEditDialog && ( // check this one with Kire
          <div className="p-col-12 p-md-6">
            <FieldWithErrors
              name="cod_department_id"
              label={t('COD Department')}
            >
              <Field
                as={Dropdown}
                name="cod_department_id"
                inputId="cod_department_id"
                options={invoicingAndCODDepartmentOptions}
                className="data-cy-cod-department-id"
              />
            </FieldWithErrors>

            <FieldWithErrors
              name="invoice_department_id"
              label={t('Invoicing Department')}
            >
              <Field
                as={Dropdown}
                name="invoice_department_id"
                inputId="invoice_department_id"
                options={invoicingAndCODDepartmentOptions}
                className="data-cy-invoice-department-id"
              />
            </FieldWithErrors>
          </div>
        )}
      </div>
    </div>
  );
}

export default LegalDataTab;
