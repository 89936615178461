import { useTranslation } from 'react-i18next';

import { PriceResource } from '../../../../../types/api/prices';
import { tryFloat } from '../../../../../utils/helpers/parse';

type Props = {
  data: PriceResource | undefined;
};

function Content({ data }: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      <p className="p-mt-0">
        {t(
          'The price you are about to delete is assigned to the tariff {{tariffName}} and price list {{priceListName}}',
          {
            tariffName: data?.data.tariff_name,
            priceListName: data?.data.price_list_name,
          }
        )}

        {data?.data.price.length ? (
          <>
            :
            {tryFloat(data?.data.price) === undefined ? (
              <code className="p-d-block p-my-2">{data?.data.price}</code>
            ) : (
              <b>{data?.data.price}</b>
            )}
          </>
        ) : (
          '.'
        )}
      </p>

      <p className="p-mb-0">
        {t('This action cannot be undone. Are you sure you want to proceed?')}
      </p>
    </>
  );
}

export default Content;
