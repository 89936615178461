import { availableCourierCollectionGuard } from './_api/courier';
import {
  cancelTransferRecieveResourceGuard,
  orderResourceGuard,
  ordersBarcodePrefixesCollectionGuard,
  recieveTransferResourceGuard,
  transferReceptionCollectionGuard,
} from './_api/orders';

export const receiveShipmentGuard = recieveTransferResourceGuard;
export const cancelShipmentReceptionGuard = cancelTransferRecieveResourceGuard;

export const routeGuard = [
  availableCourierCollectionGuard,
  transferReceptionCollectionGuard,
  orderResourceGuard,
  ordersBarcodePrefixesCollectionGuard,
];
