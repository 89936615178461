import { Button } from 'primereact/button';

import SidebarItem from '../../../Grid/Sidebar/SidebarItem';

type Props = {
  isAddBtnDisabled: boolean;
  onAddBtnClick: () => void;
  buttonLabel: string;
};

function Actions({
  isAddBtnDisabled,
  onAddBtnClick,
  buttonLabel,
}: Props): JSX.Element {
  return (
    <SidebarItem template="actions">
      <Button
        type="button"
        icon="fas fa-plus"
        label={buttonLabel}
        onClick={onAddBtnClick}
        disabled={isAddBtnDisabled}
      />
    </SidebarItem>
  );
}

export default Actions;
