import './ViewAddressesDialog.scss';

import { faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { TabPanel, TabView } from 'primereact/tabview';
import { useTranslation } from 'react-i18next';

import useMediaQuery from '../../../../../hooks/useMediaQuery';
import DialogSpinner from '../../../../Dialogs/DialogSpinner/DialogSpinner';
import {
  CourierLiability,
  CourierLiabilityResource,
} from '../../CourierLiabilities.functions';

type Props = {
  selectedLiability: CourierLiability | null;
  isShown: boolean;
  onHide: () => void;
  data: CourierLiabilityResource | undefined;
  isLoading: boolean;
};

function ViewAddressesDialog({
  selectedLiability,
  isShown,
  onHide,
  data,
  isLoading,
}: Props) {
  const { t } = useTranslation();

  const dialogHeader = isLoading ? (
    t('Loading...')
  ) : (
    <span>
      {t("{{courierName}}'s Liability", {
        courierName: selectedLiability?.postar_ime,
      })}
    </span>
  );

  const dialogFooter = (
    <>
      <Button type="button" label={t('Close')} onClick={onHide} />
    </>
  );

  const isOnMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Dialog
      visible={isShown}
      onHide={onHide}
      header={dialogHeader}
      footer={dialogFooter}
      dismissableMask
      maximizable
      maximized={isOnMobile}
      resizable={false}
      className="courier-liabilities-view-addresses-dialog"
    >
      {isLoading ? (
        <div className="p-my-4">
          <DialogSpinner />
        </div>
      ) : (
        <TabView>
          <TabPanel
            header={t('Created ({{createdAt}})', {
              createdAt: data?.indebt?.length,
            })}
          >
            <div className="addresses">
              {Array.isArray(data?.indebt) &&
                data?.indebt?.map((address) => {
                  return (
                    <div key={address} className="address created">
                      <FontAwesomeIcon icon={faFile} />
                      <p>{address}</p>
                    </div>
                  );
                })}
            </div>
          </TabPanel>

          <TabPanel
            header={t('Uncreated ({{uncreatedAt}})', {
              uncreatedAt: data?.outofdebt?.length,
            })}
          >
            <div className="addresses">
              {Array.isArray(data?.outofdebt) &&
                data?.outofdebt?.map((address) => {
                  return (
                    <div key={address} className="address uncreated">
                      <FontAwesomeIcon icon={faFile} />
                      <p>{address}</p>
                    </div>
                  );
                })}
            </div>
          </TabPanel>

          <TabPanel
            header={t('Created for client ({{client}})', {
              client: data?.clientOutofdebt?.length,
            })}
          >
            <div className="addresses">
              {Array.isArray(data?.clientOutofdebt) &&
                data?.clientOutofdebt?.map((address) => {
                  return (
                    <div key={address} className="address created-for-client">
                      <FontAwesomeIcon icon={faFile} />
                      <p>{address}</p>
                    </div>
                  );
                })}
            </div>
          </TabPanel>
        </TabView>
      )}
    </Dialog>
  );
}

export default ViewAddressesDialog;
