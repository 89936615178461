import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function SectionFormTooltip({ text }) {
  return (
    <span className="settings-tooltip" data-tip={text} data-for="tooltip-top">
      <FontAwesomeIcon icon={faInfoCircle} />
    </span>
  );
}

export default SectionFormTooltip;
