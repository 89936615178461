import dayjs from 'dayjs';
import { Column } from 'primereact/column';
import { TFunction } from 'react-i18next';

import { OrderStatus } from '../../../../../enums/orders';

export type StatusTrackingApiData = Record<
  string,
  {
    reference_identifier: string | null;
    history: StatusTrackingInfo[] | undefined;
    current: StatusTrackingInfo | null;
    errorMsg: string | null;
  }
>;

export type StatusTrackingInfo = {
  date: string;
  text: string;
  code: OrderStatus;
  description: string;
  location: string;
};

function getColumnHeadersMap(t: TFunction) {
  return {
    date: t('Date'),
    text: t('Status'),
    location: t('Location / Courier'),
  };
}

function additionalColumnProperties(column: string) {
  switch (column) {
    case 'date':
      return {
        body: (column: StatusTrackingInfo) =>
          column['date']
            ? dayjs(column['date']).format('DD MMM YYYY, HH:mm:ss')
            : '-',
        style: {
          width: 180,
        },
        bodyStyle: {
          width: 180,
        },
      };

    case 'text':
      return {
        body: (column: StatusTrackingInfo) =>
          column['code'] === OrderStatus.UnsuccessfulDeliveryAttempt ? (
            <>
              <span>{column['text']}</span> {'-'} <b>{column['description']}</b>
            </>
          ) : (
            column['text']
          ),
        style: {
          width: 380,
        },
        bodyStyle: {
          width: 380,
        },
      };

    case 'location':
      return {
        style: {
          width: 250,
        },
        bodyStyle: {
          width: 250,
        },
      };

    default:
      return {};
  }
}

export function getTableColumns(t: TFunction): JSX.Element[] {
  return Object.keys(getColumnHeadersMap(t)).map((c) => (
    <Column
      key={c}
      header={
        getColumnHeadersMap(t)[
          c as keyof ReturnType<typeof getColumnHeadersMap>
        ]
      }
      field={c}
      {...additionalColumnProperties(c)}
    />
  ));
}
