import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { TFunction } from 'react-i18next';

import { WithPagination } from '../../../../types/api';
import { ClientLookupCollection } from '../../../../types/api/clients';
import { Numeric } from '../../../../types/general';
import { ContextMenuItem } from '../../../../types/primereact';
import { Unpacked } from '../../../../types/util';
import { emptyCell, noColumnStyle } from '../../../../utils/constants/tables';
import { formatPhoneOrMobileNumber } from '../../../../utils/helpers/phoneNumbers';

export type ClientOption = Pick<Unpacked<ClientLookupCollection>, 'id' | 'ime'>;

export type CustomerOrder = {
  id: Numeric;
  ime: string;
  posledna_nedela: Numeric;
  nedelen_prosek: Numeric;
  nedelna_tendencija: string;
  posleden_mesec: Numeric;
  mesecen_prosek: Numeric;
  mesecna_tendencija: string;
  vkupno_pratki: Numeric;
};

export type Shipment = {
  year: string;
  quarter: string;
  month: string;
  week: string;
  orders: string;
  monthLabel?: string;
};

export type Shipments = {
  client_id: Numeric;
  client_name: string;
  headquarter_id?: Numeric;
  history: Shipment[];
};

export const tableStorageKey = 'reports_customerOrders_dataTable';

export function getColumnHeadersMap(t: TFunction): {
  [key: string]: string;
} {
  return {
    no: t('No.'),
    id: t('ID'),
    ime: t('Name'),
    email: t('Email'),
    phone_number: t('Phone number'),
    mobile_number: t('Mobile Phone'),
    posledna_nedela: t('Last Week'),
    nedelen_prosek: t('Weekly Average'),
    nedelna_tendencija: t('Weekly Trend'),
    posleden_mesec: t('Last Month'),
    mesecen_prosek: t('Monthly Average'),
    mesecna_tendencija: t('Monthly Trend'),
    vkupno_pratki: t('Total for period'),
  };
}

export type ApiData = WithPagination<CustomerOrder[]>;

export function additionalListsColumnProperties(t: TFunction, column: string) {
  switch (column) {
    case 'no':
      return noColumnStyle;

    case 'id':
      return {
        style: {
          width: 80,
        },
        bodyStyle: {
          width: 80,
        },
      };

    case 'ime':
    case 'email':
      return {
        style: {
          width: 300,
        },
        bodyStyle: {
          width: 300,
        },
      };

    case 'phone_number':
    case 'mobile_number':
      return {
        body: (row: any) => {
          return row[column]
            ? formatPhoneOrMobileNumber(row[column])
            : emptyCell;
        },
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
        },
      };

    case 'posledna_nedela':
    case 'posleden_mesec':
      return {
        style: {
          width: 170,
        },
        bodyStyle: {
          width: 170,
        },
      };

    case 'nedelen_prosek':
    case 'mesecen_prosek':
    case 'vkupno_pratki':
      return {
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
        },
      };

    case 'nedelna_tendencija':
    case 'mesecna_tendencija':
      return {
        body: (row: CustomerOrder) => (
          <span
            data-tip={
              row[column]
                ? row[column] === 'up'
                  ? t('Increasing')
                  : t('Decreasing')
                : t('Unknown')
            }
            data-for="tooltip-right"
          >
            <FontAwesomeIcon
              icon={row[column] === 'up' ? faArrowUp : faArrowDown}
              color={row[column] === 'up' ? 'var(--green-500)' : '#D32F2F'}
            />
          </span>
        ),
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
        },
      };

    default:
      return {
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
        },
      };
  }
}

export function generateContextMenu({
  t,
  handleCMViewClick,
}: {
  t: TFunction;
  handleCMViewClick: () => void;
}): ContextMenuItem[] {
  return [
    {
      label: t('View'),
      icon: 'fas fa-search',
      command: handleCMViewClick,
    },
  ];
}

const monthsArray = [
  { label: 'Jan', value: '1' },
  { label: 'Feb', value: '2' },
  { label: 'Mar', value: '3' },
  { label: 'Apr', value: '4' },
  { label: 'May', value: '5' },
  { label: 'June', value: '6' },
  { label: 'July', value: '7' },
  { label: 'Aug', value: '8' },
  { label: 'Sep', value: '9' },
  { label: 'Oct', value: '10' },
  { label: 'Nov', value: '11' },
  { label: 'Dec', value: '12' },
];

function getMonthLabel(month: string) {
  const m = monthsArray.find((m) => m.value === month);
  return m?.label;
}

export function groupData(
  year = 'year',
  period = 'month',
  shipments: Shipment[] | undefined
) {
  let newArray: Shipment[] = [];
  const groupedByYear = _.groupBy(shipments, year);

  let groupedDataByPeriod: any = [];

  for (let year in groupedByYear) {
    groupedDataByPeriod.push({
      [year]: _.groupBy(groupedByYear[year], 'month'),
    });
  }

  groupedDataByPeriod.forEach((gd: any) => {
    for (let year in gd) {
      for (let month in gd[year]) {
        let weekNo = 1;
        gd[year][month].forEach((s: any) => {
          let newObj = {
            ...s,
            monthLabel: getMonthLabel(s.month),
          };

          if (period === 'week') {
            newObj = { ...newObj, week: weekNo };
          }

          newArray.push(newObj);

          weekNo++;
        });
      }
    }
  });

  return newArray;
}
