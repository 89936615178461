import { Dispatch, SetStateAction, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useAxiosHook from '../../../../../../hooks/useAxiosHook';
import useSearchQueryAutoCompleteInputParam from '../../../../../../hooks/useSearchQueryAutoCompleteInputParam';
import {
  CustomerReceptionCollection,
  CustomerReceptionCollectionQueryParams,
  CustomerReceptionResource,
} from '../../../../../../types/api/customerReceptions';
import { LabelValue } from '../../../../../../types/options';
import { identity } from '../../../../../../utils/helpers/functions';
import { queryString } from '../../../../../../utils/helpers/http';
import AutoCompleteInput from '../../../../../Forms/AutoCompleteInput/AutoCompleteInput';
import SidebarItem from '../../../../../Grid/Sidebar/SidebarItem';
import { CustomerReceptionOption } from '../CustomerShipmentReception.types';

type Props = {
  customerReceptionFilter: string;
  setCustomerReceptionFilter: Dispatch<SetStateAction<string>>;
  customerReceptionFilterObj: CustomerReceptionOption | null;
  setCustomerReceptionFilterObj: Dispatch<
    SetStateAction<CustomerReceptionOption | null>
  >;
};

function Filters({
  customerReceptionFilter,
  setCustomerReceptionFilter,
  customerReceptionFilterObj,
  setCustomerReceptionFilterObj,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const { data, isLoading } = useAxiosHook<CustomerReceptionCollection>(
    '/customer-receptions' +
      queryString<CustomerReceptionCollectionQueryParams>({
        page: 1,
        limit: 15,
        name: customerReceptionFilter,
        completed: 0,
      })
  );

  const customerReceptionOptions = useMemo<
    LabelValue<CustomerReceptionOption>[]
  >(
    () => data?.data.map((r) => ({ label: r.name, value: r })) ?? [],
    [data?.data]
  );

  useSearchQueryAutoCompleteInputParam<
    CustomerReceptionOption,
    CustomerReceptionResource
  >({
    param: 'reception',
    filterValue: customerReceptionFilter,
    setFilterValue: setCustomerReceptionFilter,
    filterValueObj: customerReceptionFilterObj,
    setFilterValueObj: setCustomerReceptionFilterObj,
    axiosRequestConfig: (id) => `/customer-receptions/${id}`,
    initialFilterValueDataPath: (crResource) => crResource.name,
    filterValueObjPath: 'id',
    initialDataModifier: identity,
  });

  return (
    <SidebarItem template="filters">
      <div className="p-field">
        <label htmlFor="filter_customer_reception">
          {t('Reception for client')}
        </label>

        <AutoCompleteInput
          id="filter_customer_reception"
          filterValue={customerReceptionFilter}
          value={customerReceptionFilterObj}
          options={customerReceptionOptions}
          onFilterChange={setCustomerReceptionFilter}
          onSelectionChange={setCustomerReceptionFilterObj}
          placeholder={
            isLoading
              ? t('Loading...')
              : !customerReceptionOptions.length
              ? t('No options available')
              : t('Search by name')
          }
          showClear
        />
      </div>
    </SidebarItem>
  );
}

export default Filters;
