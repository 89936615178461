import { Field } from 'formik';
import { InputText } from 'primereact/inputtext';
import { useTranslation } from 'react-i18next';

import FieldWithErrors from '../../../Forms/FieldWithErrors/FieldWithErrors';

function Company({ section }) {
  const { t } = useTranslation();

  return (
    <>
      <FieldWithErrors name={`${section}-Address`} label={t('Address')}>
        <Field
          as={InputText}
          id={`${section}-Address`}
          name={`${section}-Address`}
          tooltip={t("Post office's address")}
        />
      </FieldWithErrors>

      <FieldWithErrors name={`${section}-Telephone`} label={t('Telephone')}>
        <Field
          as={InputText}
          id={`${section}-Telephone`}
          name={`${section}-Telephone`}
          tooltip={t("Post office's telephone number")}
        />
      </FieldWithErrors>

      <FieldWithErrors name={`${section}-CEO`} label={t('CEO')}>
        <Field
          as={InputText}
          id={`${section}-CEO`}
          name={`${section}-CEO`}
          tooltip={t(
            'The person who is a manager and is signed on the invoice'
          )}
        />
      </FieldWithErrors>

      <FieldWithErrors name={`${section}-Name`} label={t('Post Name')}>
        <Field
          as={InputText}
          id={`${section}-Name`}
          name={`${section}-Name`}
          tooltip={t('Post office name')}
        />
      </FieldWithErrors>

      <FieldWithErrors name={`${section}-Email`} label={t('Email')}>
        <Field
          as={InputText}
          id={`${section}-Email`}
          name={`${section}-Email`}
          tooltip={t(
            'Official email used for communication purposes with clients'
          )}
        />
      </FieldWithErrors>

      <FieldWithErrors
        name={`${section}-VatNumber`}
        label={t('VAT Identification Number')}
      >
        <Field
          as={InputText}
          id={`${section}-VatNumber`}
          name={`${section}-VatNumber`}
          tooltip={t('VAT Identification Number (VATIN)')}
        />
      </FieldWithErrors>

      <FieldWithErrors
        name={`${section}-PersonalNumber`}
        label={t('Personal Number')}
      >
        <Field
          as={InputText}
          id={`${section}-PersonalNumber`}
          name={`${section}-PersonalNumber`}
          tooltip={t("Post office's personal number")}
        />
      </FieldWithErrors>

      <FieldWithErrors
        name={`${section}-BankAccount`}
        label={t('Bank Account')}
      >
        <Field
          as={InputText}
          id={`${section}-BankAccount`}
          name={`${section}-BankAccount`}
          tooltip={t("Post office's bank account")}
        />
      </FieldWithErrors>

      <FieldWithErrors
        name={`${section}-EmailComplaints`}
        label={t('Complaints Email')}
      >
        <Field
          as={InputText}
          id={`${section}-EmailComplaints`}
          name={`${section}-EmailComplaints`}
          tooltip={t('Email address intended for complaints')}
        />
      </FieldWithErrors>

      <FieldWithErrors name={`${section}-Phone`} label={t('Phone')}>
        <Field
          as={InputText}
          id={`${section}-Phone`}
          name={`${section}-Phone`}
          tooltip={t('Primary mobile phone number')}
        />
      </FieldWithErrors>

      <FieldWithErrors name={`${section}-Phone2`} label={t('Phone 2')}>
        <Field
          as={InputText}
          id={`${section}-Phone2`}
          name={`${section}-Phone2`}
          tooltip={t('Secondary mobile phone number')}
        />
      </FieldWithErrors>
    </>
  );
}

export default Company;
