import { useCallback, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';

type ReturnValue = (title: string) => void;

function usePageTitle(title: string): ReturnValue {
  const { t } = useTranslation();

  const setTitle = useCallback(
    (prefix: string): void => {
      document.title =
        `${prefix} | ${process.env.REACT_APP_CLIENT_NAME_TITLE} ` +
        t('Employees');
    },
    [t]
  );

  useLayoutEffect(() => {
    setTitle(title);
  }, [setTitle, title]);

  return setTitle;
}

export default usePageTitle;
