import { ContextMenuItem, ContextMenuSection } from './ContextMenu.types';

export function contextMenuItemsModel(
  layout: [boolean, ContextMenuItem][][]
): ContextMenuItem[] {
  const filtered = layout
    .reduce((arr, current) => {
      arr.push(current.filter((item) => item[0]));

      return arr;
    }, [] as [boolean, ContextMenuItem][][])
    .filter((arr) => arr.length);

  let model: ContextMenuItem[] = [];

  for (let i = 0; i < filtered.length; i++) {
    model.push(...filtered[i].map((item) => item[1]));

    if (filtered[i + 1]) {
      model.push({ separator: true });
    }
  }

  return model;
}

// --- ParameterType ---
type ContextMenuSectionsModelLayout = (ContextMenuSection & {
  hidden?: boolean;
})[];

export function contextMenuSectionsModel(
  layout: ContextMenuSectionsModelLayout
): ContextMenuSection[] {
  return (
    layout
      // filter only false, not falsy, since by default shown === undefined
      .filter((section) => !section.hidden && section.items.length > 0)
  );
}
