import { EndpointGuard } from '../../helpers/auth';
import * as clientGuards from './_api/clients';
import * as customerShipmentReceptionGuards from './_api/customerShipmentReception';
import * as orderGuards from './_api/orders';
import * as orderPageGuards from './orders';

export const routeGuard: EndpointGuard = [
  customerShipmentReceptionGuards.customerReceptionResourceGuard,
  customerShipmentReceptionGuards.customerReceptionCollectionGuard,
  customerShipmentReceptionGuards.addOrdersToCustomerReceptionGuard,
  customerShipmentReceptionGuards.discardOrderFromCustomerReceptionGuard,
  customerShipmentReceptionGuards.addOrdersByBarcodeToCustomerReception,
  customerShipmentReceptionGuards.discardCustomerReceptionResourceGuard,
  customerShipmentReceptionGuards.createCustomerReceptionGuard,
  customerShipmentReceptionGuards.finalizeCustomerReceptionGuard,
  orderGuards.orderCollectionGuard,
  orderGuards.orderResourceGuard,
  ...orderPageGuards.createOrder,
  ...orderPageGuards.editOrder,
  clientGuards.clientResourceGuard,
  clientGuards.clientLookupCollectionGuard,
];
