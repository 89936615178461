import './CreateEditDialog.scss';

import { Form, Formik, FormikProps } from 'formik';
import { Dialog } from 'primereact/dialog';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';

import ToastContext from '../../../../../context/ToastContext';
import useAxiosHook from '../../../../../hooks/useAxiosHook';
import useMediaQuery from '../../../../../hooks/useMediaQuery';
import usePrevious from '../../../../../hooks/usePrevious';
import { CreateOrderResource } from '../../../../../types/api/orders';
import { noop } from '../../../../../utils/helpers/functions';
import { getChangedValues } from '../../../../../utils/helpers/object';
import {
  errorToast,
  infoToast,
  successToast,
} from '../../../../../utils/helpers/primereact';
import DialogSpinner from '../../../../Dialogs/DialogSpinner/DialogSpinner';
import { SingleOrder } from '../../Orders.functions';
import {
  EditOrderResponse,
  FormValues,
  getInitialValues,
  getValidationSchema,
  toApiData,
  values,
} from './CreateEditDialog.functions';
import DialogContainer from './DialogContainer';
import { DialogFooter } from './DialogFooter';
import { DialogHeader } from './DialogHeader';

type Props = {
  visible: boolean;
  onHide: () => void;
  reload: () => void;
  setOrderData?: Dispatch<SetStateAction<FormValues>>;
  isSmallScreen: boolean;
  data?: SingleOrder | any;
  isWarehouseReception?: boolean;
  isEditDialog?: boolean;
  isGroupDialog?: boolean;
  isLoading?: boolean;
  onCreate?: (response: CreateOrderResource) => void;
  onEdit?: (id: number) => void;
  orderData?: FormValues[];
  orderIndex?: number;
  setOrderIndex?: Dispatch<SetStateAction<number>>;
  isConfigVisible?: boolean;
  constantFields?: any;
  title?: string;
  initialCreationValues?: Partial<FormValues>;
  isSenderTabDisabled?: boolean;
};

function CreateEditDialog({
  title,
  visible,
  data,
  isEditDialog = false,
  isGroupDialog = false,
  isWarehouseReception = false,
  isLoading = false,
  onHide,
  reload,
  onCreate,
  onEdit,
  orderData,
  setOrderData = noop,
  orderIndex = 0,
  setOrderIndex,
  constantFields = {},
  isConfigVisible,
  isSmallScreen,
  initialCreationValues,
  isSenderTabDisabled,
}: Props) {
  const { t } = useTranslation();

  const validationSchema = useMemo(() => getValidationSchema(t), [t]);
  const formRef = useRef<FormikProps<any>>(null);
  const toastRef = useContext(ToastContext)?.toastRef!;

  function handleFormSubmission() {
    if (isEditDialog) {
      handleEditSubmission();
    } else {
      handleCreateSubmission();
    }
  }

  const {
    data: createOrderData,
    error: createOrderError,
    isLoading: isOrderRequestLoading,
    reload: createOrderRequest,
  } = useAxiosHook<CreateOrderResource>();

  const prevOrderData = usePrevious(createOrderData);
  const prevOrderError = usePrevious(createOrderError);

  const {
    data: pickupAssignmentData,
    error: pickupAssignmentError,
    reload: pickupAssignmentRequest,
  } = useAxiosHook();

  const prevPickupAssignmentData = usePrevious(pickupAssignmentData);
  const prevPickupAssignmentError = usePrevious(pickupAssignmentError);

  function handleCreateSubmission() {
    createOrderRequest({
      url: '/orders/add',
      method: 'POST',
      data: toApiData(formRef?.current?.values),
    });
  }

  useEffect(() => {
    if (
      createOrderData &&
      formRef.current?.values.pickupAssignmentCheckbox &&
      formRef.current?.values.pickupAssignmentCourier
    ) {
      pickupAssignmentRequest({
        url: '/orders/courier/assignments/pickup',
        method: 'PUT',
        data: {
          courier_id: formRef.current.values.pickupAssignmentCourier,
          parcels: [createOrderData.id],
        },
      });
    }
  }, [createOrderData, pickupAssignmentRequest, formRef]);

  useEffect(() => {
    formRef.current?.resetForm();
  }, [visible]);

  useEffect(() => {
    if (!createOrderData || prevOrderData === createOrderData) {
      return;
    }

    if (createOrderData?.id) {
      successToast(
        toastRef,
        t('Order #{{orderID}} Created', { orderID: createOrderData?.id }),
        t('Order created sucessfully.')
      );
      formRef.current?.resetForm();
      onCreate?.(createOrderData);
      reload();
      onHide();
    }
  }, [prevOrderData, createOrderData, onHide, toastRef, t, onCreate, reload]);

  useEffect(() => {
    if (!createOrderError || prevOrderError === createOrderError) {
      return;
    }

    errorToast(
      toastRef,
      t('Order Creation Failed'),
      t(
        'An error occured while trying to create Order. Please check your inputs.'
      )
    );
    formRef.current?.resetForm();
  }, [
    prevOrderData,
    createOrderData,
    createOrderError,
    prevOrderError,
    toastRef,
    t,
  ]);

  useEffect(() => {
    if (
      !pickupAssignmentData ||
      prevPickupAssignmentData === pickupAssignmentData
    ) {
      return;
    }

    successToast(
      toastRef,
      t('Order #{{orderID}} Assigned', { orderID: createOrderData?.id }),
      t('The order has been successfully assigned to the requested courier')
    );
  }, [
    toastRef,
    pickupAssignmentData,
    prevPickupAssignmentData,
    t,
    createOrderData?.id,
  ]);

  useEffect(() => {
    if (
      !pickupAssignmentError ||
      prevPickupAssignmentError === pickupAssignmentError
    ) {
      return;
    }

    errorToast(
      toastRef,
      t('Pickup Assignment Failed'),
      t(
        'An error occurred while trying to assign the order to the specified courier.'
      )
    );
  }, [t, toastRef, pickupAssignmentError, prevPickupAssignmentError]);

  const {
    data: editOrderData,
    error: editOrderError,
    reload: formSubmissionRequest,
  } = useAxiosHook<EditOrderResponse>();

  function handleEditSubmission() {
    const initialValues = toApiData(formRef.current?.initialValues, true);

    const currentValues = toApiData(formRef?.current?.values, true);

    const apiData = getChangedValues(currentValues, initialValues);

    // If there are no changes introduced, don't contaminate the API
    if (!Object.keys(apiData).length) {
      infoToast(
        toastRef,
        t('No changes made'),
        t("You haven't made any changes yet.")
      );

      return;
    }

    formSubmissionRequest({
      url: `/orders/${data?.id}`,
      method: 'PUT',
      data: apiData,
    });
  }

  const prevData = usePrevious(editOrderData);

  useEffect(() => {
    if (!editOrderData || prevData === editOrderData) {
      return;
    }

    if (isEditDialog && editOrderData?.id) {
      successToast(
        toastRef,
        t('Order Updated'),
        t('Order updated sucessfully.')
      );

      reload();
      onHide();
      onEdit?.(editOrderData?.id);

      formRef.current?.resetForm();
    }
  }, [
    editOrderData,
    isEditDialog,
    onEdit,
    onHide,
    prevData,
    reload,
    t,
    toastRef,
  ]);

  const prevError = usePrevious(editOrderError);
  useEffect(() => {
    if (!editOrderError || prevError === editOrderError) {
      return;
    }

    if (isEditDialog) {
      errorToast(
        toastRef,
        t('Order Update Failed'),
        t(
          'An error occured while trying to edit Order. Please check your inputs.'
        )
      );
    }

    formRef.current?.resetForm();
  }, [editOrderError, isEditDialog, prevError, t, toastRef]);

  useEffect(() => {
    if (!isGroupDialog) {
      return;
    }

    formRef.current?.setValues(orderData?.[orderIndex] ?? values);
  }, [orderIndex, orderData, isGroupDialog]);

  const initialValues = useMemo(
    () =>
      getInitialValues({
        isEditDialog,
        isGroupDialog,
        isWarehouseReception,
        data,
        groupOrderData: orderData?.[orderIndex] ?? ([] as any),
        initialCreationValues: initialCreationValues ?? {},
      }),
    [
      data,
      initialCreationValues,
      isEditDialog,
      isGroupDialog,
      isWarehouseReception,
      orderData,
      orderIndex,
    ]
  );

  const isOnMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmission}
      validationSchema={validationSchema}
      innerRef={formRef}
      enableReinitialize
    >
      <Form>
        <Dialog
          visible={visible}
          className={`create-edit-parcel-dialog ${isConfigVisible && 'small'}`}
          header={
            <DialogHeader
              title={title}
              isEditDialog={isEditDialog}
              isGroupDialog={isGroupDialog}
              serialNumber={
                isGroupDialog
                  ? orderData?.[orderIndex]?.seriski_broj
                  : data?.seriski_broj
              }
              orderIndex={orderIndex}
              ordersCount={orderData?.length ?? 0}
            />
          }
          footer={
            <DialogFooter
              isEditDialog={isEditDialog}
              isGroupDialog={isGroupDialog}
              isOrderRequestLoading={isOrderRequestLoading}
              onHide={onHide}
              setOrderIndex={setOrderIndex}
              orderIndex={orderIndex}
              orderData={orderData}
              setOrderData={setOrderData}
              isTabs={isSmallScreen}
              onCreate={onCreate}
            />
          }
          onHide={onHide}
          blockScroll
          maximizable
          maximized={isOnMobile}
        >
          {isLoading ? (
            <DialogSpinner />
          ) : (
            <DialogContainer
              initialValues={initialValues}
              constantFields={constantFields}
              isEditing={isEditDialog}
              isTabs={isSmallScreen}
              isWarehouseReception={isWarehouseReception}
              orderIndex={orderIndex}
              isSenderTabDisabled={isSenderTabDisabled}
            />
          )}
        </Dialog>
      </Form>
    </Formik>
  );
}

export default CreateEditDialog;
