import { configCollectionGuard } from '../../../utils/constants/auth/_api/configs';
import { availableCourierCollectionGuard } from '../../../utils/constants/auth/_api/courier';
import {
  approveReconciliationResourceGuard,
  createReconciliationResourceGuard,
  deliveredReconciliationCollectionGuard,
  markAsDoneReconciliationResourceGuard,
  pickedUpReconciliationCollectionGuard,
  reconciliationCollectionGuard,
  reconciliationEmployeesGuard,
  reconciliationResourceGuard,
  reconciliationStatisticsGuard,
  rejectReconciliationResourceGuard,
} from '../../../utils/constants/auth/_api/reconciliations';
import { workOrderResourceGuard } from '../../../utils/constants/auth/_api/workOrders';

export const addGuard = [
  createReconciliationResourceGuard,
  availableCourierCollectionGuard,
  configCollectionGuard,
  reconciliationEmployeesGuard,
];

export const markAsDoneGuard = markAsDoneReconciliationResourceGuard;
export const approveGuard = approveReconciliationResourceGuard;
export const rejectGuard = rejectReconciliationResourceGuard;

export const routeGuard = [
  reconciliationCollectionGuard,
  reconciliationStatisticsGuard,
  // Shipment Reconciliation page
  reconciliationResourceGuard,
  workOrderResourceGuard,
  deliveredReconciliationCollectionGuard,
  pickedUpReconciliationCollectionGuard,
];
