import dayjs from 'dayjs';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Chart } from 'primereact/chart';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import usePlainDateFilters from '../../../../../../hooks/filters/usePlainDateFilters';
import useAxiosHook from '../../../../../../hooks/useAxiosHook';
import useMediaQuery from '../../../../../../hooks/useMediaQuery';
import { EntityIdRouteParams } from '../../../../../../types/routing';
import { queryString } from '../../../../../../utils/helpers/http';
import { Shipment, Shipments, groupData } from '../../CustomerOrders.functions';
import { toApiData } from './ViewCustomerOrder.functions';

type Props = {
  isShown: boolean;
  onHide: () => void;
};

function ViewCustomerOrder({ isShown, onHide }: Props) {
  const { t } = useTranslation();

  const { id } = useParams<EntityIdRouteParams>();

  const { dateFrom, setDateFrom, dateTo, setDateTo } = usePlainDateFilters({
    defaultValueFrom: dayjs().startOf('quarter').toDate(),
    defaultValueTo: dayjs().endOf('month').toDate(),
  });

  const [isWithSubsidiariesOn, setIsWithSubsidiariesOn] =
    useState<boolean>(false);

  useEffect(() => {
    if (!isShown) {
      setDateFrom(dayjs().startOf('quarter').toDate());
      setDateTo(dayjs().endOf('month').toDate());
    }
  }, [isShown, setDateFrom, setDateTo]);

  const period = useMemo(() => {
    if (!dateFrom || !dateTo) {
      return 'WEEK';
    }

    const yearMonthString = dayjs(dateTo).format('YYYY-MM');
    const daysInMonth = dayjs(yearMonthString).daysInMonth();

    const newDateTo = new Date(`${yearMonthString}-${daysInMonth}`);

    let diff = (newDateTo.getTime() - dateFrom.getTime()) / 1000;
    diff /= 60 * 60 * 24 * 7;

    const weeksBetweeen = Math.abs(Math.round(diff));

    if (weeksBetweeen < 16) return 'WEEK';
    if (weeksBetweeen >= 16 && weeksBetweeen < 60) return 'MONTH';
    if (weeksBetweeen >= 60 && weeksBetweeen < 180) return 'QUARTER';
    return 'YEAR';
  }, [dateFrom, dateTo]);

  const shipmentsFilters = useMemo(() => {
    if (!dateFrom || !dateTo) {
      return;
    }

    return toApiData(dateFrom, dateTo, period, isWithSubsidiariesOn);
  }, [dateFrom, dateTo, isWithSubsidiariesOn, period]);

  const { data, isLoading } = useAxiosHook<Shipments>(
    {
      url:
        `/reports/client-orders/${id}` +
        (shipmentsFilters ? queryString(shipmentsFilters) : ''),
    },
    { skipWhen: !isShown || !id || !dateFrom || !dateTo }
  );

  const getLabels = useMemo(() => {
    let shipments = { ...data };

    if (period === 'WEEK') {
      const newArray = groupData('year', period, shipments?.history);

      return newArray.map((w: Shipment) => {
        return `${w.week}w-${w.monthLabel}-${w.year}`;
      });
    }

    if (period === 'MONTH') {
      const newArray = groupData('year', period, shipments?.history);

      return newArray.map((w: Shipment) => {
        return `${w.monthLabel}-${w.year}`;
      });
    }

    if (period === 'QUARTER') {
      return shipments?.history?.map((w: Shipment) => {
        return `${w.quarter}q-${w.year}`;
      });
    }

    if (period === 'YEAR') {
      return shipments?.history?.map((w: Shipment) => {
        return `${w.year}`;
      });
    }
  }, [data, period]);

  const shipmentData = useMemo(
    () => ({
      labels: getLabels,
      datasets: [
        {
          label: t('Shipments Dataset'),
          data: data?.history?.map((d) => d.orders) ?? [],
          fill: false,
          borderColor: '#0d6efd',
          tension: 0.4,
        },
      ],
    }),
    [data?.history, getLabels, t]
  );

  const dialogFooter = !isLoading && (
    <Button label={t('Close')} onClick={() => onHide()} />
  );

  const isOnMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Dialog
      visible={isShown}
      onHide={onHide}
      header={
        isLoading
          ? t('Loading...')
          : t('Orders by {{name}}', {
              name: data?.client_name ?? '',
            })
      }
      footer={dialogFooter}
      dismissableMask
      blockScroll
      maximizable
      maximized={isOnMobile}
      style={{
        height: 'auto',
        minWidth: '300px',
        width: '800px',
      }}
    >
      <div className="p-d-flex p-jc-end p-mr-3">
        {data?.headquarter_id === data?.client_id && (
          <div className="p-d-flex p-flex-column p-as-end p-mb-2 p-mr-3">
            <div className="p-d-flex">
              <Checkbox
                onChange={(e) => setIsWithSubsidiariesOn(e.checked)}
                checked={isWithSubsidiariesOn}
              />

              <label htmlFor="filter_with_subsidiaries">
                {t('With Subsidiaries')}
              </label>
            </div>
          </div>
        )}
        <div className="p-d-flex p-flex-column p-mr-3">
          <label htmlFor="filter_date_from">{t('Date From')}</label>
          <Calendar
            value={dateFrom ?? undefined}
            onChange={(e) => {
              setDateFrom(e.value as Date | null);
            }}
            view="month"
            dateFormat="mm/yy"
            maxDate={dateTo ?? undefined}
            yearNavigator
            yearRange={`2009:${dayjs().format('YYYY')}`}
          />
        </div>

        <div className="p-d-flex p-flex-column">
          <label htmlFor="filter_date_to">{t('Date To')}</label>
          <Calendar
            value={dateTo ?? undefined}
            onChange={(e) => {
              setDateTo(e.value as Date | null);
            }}
            view="month"
            dateFormat="mm/yy"
            minDate={dateFrom ?? undefined}
            yearNavigator
            yearRange={`2009:${dayjs().format('YYYY')}`}
          />
        </div>
      </div>

      <div className="p-mb-5">
        <span
          style={{
            fontSize: '18px',
            fontWeight: 300,
            color: 'var(--blue-500)',
          }}
        >
          {t('Shipments')}
        </span>
        {isLoading ? (
          <ProgressSpinner style={{ display: 'block', margin: '10px 45%' }} />
        ) : data?.history?.length ? (
          <Chart type="line" data={shipmentData} style={{ margin: '12px 0' }} />
        ) : (
          <div className="p-mt-3">
            <i>{t('No shipments in selected period')}</i>
          </div>
        )}
      </div>
    </Dialog>
  );
}

export default ViewCustomerOrder;
