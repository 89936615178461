import { useState } from 'react';

import useSafeCallback from './useSafeCallback';

type Return = {
  isVisible: boolean;
  show: () => void;
  hide: () => void;
};

function useDialogVisibility(): Return {
  const [isVisible, setIsVisible] = useState(false);

  // useSafeCallback, so that we can utilize show and hide in async operations.
  const safeShow = useSafeCallback(() => setIsVisible(true));
  const safeHide = useSafeCallback(() => setIsVisible(false));

  function show() {
    safeShow?.();
  }

  function hide() {
    safeHide?.();
  }

  return { isVisible, show, hide };
}

export default useDialogVisibility;
