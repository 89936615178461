import { ReactNode } from 'react';

import useMediaQuery from '../../hooks/useMediaQuery';

type Props = {
  children: ReactNode;
  columnLayout?: boolean;
};

function SidePanels({ children, columnLayout }: Props): JSX.Element {
  const columnLayoutFallback = useMediaQuery('(min-width: 769px)');

  return (
    <div
      {...((columnLayout === undefined ? columnLayoutFallback : columnLayout)
        ? { className: 'p-col-fixed', style: { width: 280 } }
        : { className: 'p-col-12' })}
    >
      {children}
    </div>
  );
}

export default SidePanels;
