import { faMinus } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { isBoolean } from 'lodash';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Calendar } from 'primereact/calendar';
import { Dropdown, DropdownChangeParams } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputSwitch, InputSwitchChangeParams } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDebounce } from 'use-lodash-debounce';

import { IntBool } from '../../../enums/booleans';
import { OrderLocationType } from '../../../enums/orders';
import { Product } from '../../../enums/product';
import { UserRole } from '../../../enums/users';
import useAxiosHook from '../../../hooks/useAxiosHook';
import { useEndpointGuard } from '../../../hooks/useEndpointGuard';
import useHaveValuesChanged from '../../../hooks/useHaveValuesChanged';
import useSearchQueryDateParam from '../../../hooks/useSearchQueryDateParam';
import useSearchQueryDropdownParam from '../../../hooks/useSearchQueryDropdownParam';
import useSearchQueryParam from '../../../hooks/useSearchQueryParam';
import { WithData } from '../../../types/api';
import {
  AvailableCouriersCollection,
  AvailableCouriersCollectionQueryParams,
} from '../../../types/api/couriers';
import { EmployeeResource } from '../../../types/api/employees';
import { ImportListTypeCollection } from '../../../types/api/importlisttypes';
import * as ordersGuards from '../../../utils/constants/auth/orders';
import { debounceTimeout } from '../../../utils/constants/misc';
import { httpDateFormat } from '../../../utils/helpers/formatting';
import { queryString } from '../../../utils/helpers/http';
import { renderIcon } from '../../../utils/helpers/icon';
import {
  getSearchQueryParam,
  tryDateSearchParam,
} from '../../../utils/helpers/searchQuery';
import { TableProps } from '../../DataTable/Table/Table';
import FiltersCounter from '../Components/Filters/FiltersCounter';
import {
  getOrderPackagingOptions,
  getOrderReturnDocumentOptions,
  getTimeSlotOptions,
} from './Dialogs/CreateEdit/CreateEditDialog.functions';
import { Status } from './Orders.functions';

function useTableFilters(
  page: number,
  setPage: TableProps['setPage'],
  limit: number
) {
  const { t } = useTranslation();
  const location = useLocation();
  const listTypesFilterGuard = useEndpointGuard(ordersGuards.listTypesFilter);
  const [dateFrom, setDateFrom] = useState<Date | null>(
    () =>
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'date_from') ?? ''
      ) ?? dayjs().toDate()
  );
  const [dateTo, setDateTo] = useState<Date | null>(
    () =>
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'date_to') ?? ''
      ) ?? dayjs().toDate()
  );
  const [pickupDateFrom, setPickupDateFrom] = useState<Date | null>(
    () =>
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'pickup_date_from') ?? ''
      ) ?? null
  );
  const [pickupDateTo, setPickupDateTo] = useState<Date | null>(
    () =>
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'pickup_date_to') ?? ''
      ) ?? null
  );
  const [deliveryDateFrom, setDeliveryDateFrom] = useState<Date | null>(
    () =>
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'delivery_date_from') ?? ''
      ) ?? null
  );
  const [deliveryDateTo, setDeliveryDateTo] = useState<Date | null>(
    () =>
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'delivery_date_to') ?? ''
      ) ?? null
  );
  const [clientOrdererName, setClientOrdererName] = useState<string>(
    () => getSearchQueryParam(location.search, 'client_orderer_name') ?? ''
  );
  const [serial, setSerial] = useState<string>(
    () => getSearchQueryParam(location.search, 'serial') ?? ''
  );
  const [clientFromName, setClientFromName] = useState<string>(
    () => getSearchQueryParam(location.search, 'client_from_name') ?? ''
  );
  const [reference, setReference] = useState<string>(
    () => getSearchQueryParam(location.search, 'reference1') ?? ''
  );
  const [clientToName, setClientToName] = useState<string>(
    () => getSearchQueryParam(location.search, 'client_to_name') ?? ''
  );
  const [status, setStatus] = useState<string | null>(
    () => getSearchQueryParam(location.search, 'status') ?? null
  );
  const [returnDocument, setReturnDocument] = useState<string | null>(
    () => getSearchQueryParam(location.search, 'return_document') ?? null
  );
  const [adresnicaServicePackagingId, setAdresnicaServicePackagingId] =
    useState<string | null>(
      () =>
        getSearchQueryParam(
          location.search,
          'adresnica_service_packaging_id'
        ) ?? null
    );
  const [fragile, setFragile] = useState<boolean | any>(
    () => getSearchQueryParam(location.search, 'fragile') ?? null
  );
  const [twoManDelivery, setTwoManDelivery] = useState<boolean | any>(
    () => getSearchQueryParam(location.search, 'two_man_delivery') ?? null
  );
  const [phoneFrom, setPhoneFrom] = useState<string>(
    getSearchQueryParam(location.search, 'phone_from') ?? ''
  );
  const [phoneTo, setPhoneTo] = useState<string>(
    () => getSearchQueryParam(location.search, 'phone_to') ?? ''
  );
  const [courierId, setCourierId] = useState<number | any>(
    () => getSearchQueryParam(location.search, 'courier_id') ?? null
  );
  const [groupSerialNumber, setGroupSerialNumber] = useState<string>(
    () => getSearchQueryParam(location.search, 'group_serial_number') ?? ''
  );
  const [comment, setComment] = useState<string>(
    () => getSearchQueryParam(location.search, 'comment') ?? ''
  );
  const [importListTypeId, setImportListTypeId] = useState<number | any>(
    () => getSearchQueryParam(location.search, 'import_list_type_id') ?? null
  );
  const [shippingTypeId, setShippingTypeId] = useState<number | any>(
    () => getSearchQueryParam(location.search, 'shipping_type_id') ?? null
  );
  const [requestedDeliveryDate, setRequestedDeliveryDate] = useState<
    Date | any
  >(
    () =>
      getSearchQueryParam(location.search, 'requested_delivery_date') ?? null
  );
  const [personalDelivery, setPersonalDelivery] = useState<boolean | any>(
    () => getSearchQueryParam(location.search, 'personal_delivery') ?? null
  );
  const [productTypeId, setProductTypeId] = useState<number | any>(
    () => getSearchQueryParam(location.search, 'product_type_id') ?? null
  );
  const [weightFrom, setWeightFrom] = useState<number | any>(
    () => getSearchQueryParam(location.search, 'weight_from') ?? null
  );
  const [weightTo, setWeightTo] = useState<number | any>(
    () => getSearchQueryParam(location.search, 'weight_to') ?? null
  );
  const [redemptionFrom, setRedemptionFrom] = useState<number | any>(
    () => getSearchQueryParam(location.search, 'redemption_from') ?? null
  );
  const [redemptionTo, setRedemptionTo] = useState<number | any>(
    () => getSearchQueryParam(location.search, 'redemption_to') ?? null
  );
  const [priceFrom, setPriceFrom] = useState<number | any>(
    () => getSearchQueryParam(location.search, 'price_from') ?? null
  );
  const [priceTo, setPriceTo] = useState<number | any>(
    () => getSearchQueryParam(location.search, 'price_to') ?? null
  );
  const [locationTypeId, setLocationTypeId] = useState<number | any>(
    () => getSearchQueryParam(location.search, 'location_type_id') ?? null
  );
  const [warehouseId, setWarehouseId] = useState<number | any>(
    () => getSearchQueryParam(location.search, 'warehouse_id') ?? null
  );

  useEffect(() => {
    if (!setPage) {
      return;
    }
    setPage(1);
  }, [dateFrom, dateTo, setPage]);

  const {
    data: couriersData,
    error: couriersError,
    isLoading: isCouriersLoading,
  } = useAxiosHook<AvailableCouriersCollection>(
    '/couriers/available' +
      queryString<AvailableCouriersCollectionQueryParams>({
        is_assigned_to_region: IntBool.False,
        user_role_id: UserRole.Courier,
      })
  );
  const courierOptions = useMemo(
    () =>
      couriersData?.map((courier) => ({
        value: courier.id,
        label: courier.full_name,
      })) ?? [],
    [couriersData]
  );
  const orderPackagingOptions = useMemo(
    () =>
      getOrderPackagingOptions(t).map((packingOptions) => ({
        label: packingOptions.label,
        value: String(packingOptions.value),
      })) ?? [],
    [t]
  );
  const orderReturnDocumentOptions = useMemo(
    () =>
      getOrderReturnDocumentOptions(t).map((typeDocument) => ({
        label: typeDocument.label,
        value: String(typeDocument.value),
      })) ?? [],
    [t]
  );

  const debouncedClientOrdererName = useDebounce(
    clientOrdererName,
    debounceTimeout
  );
  const debouncedClientFromName = useDebounce(clientFromName, debounceTimeout);
  const debouncedClientToName = useDebounce(clientToName, debounceTimeout);
  const debouncedReference = useDebounce(reference, debounceTimeout);
  const debouncedSerial = useDebounce(serial, debounceTimeout);
  const debouncedPhoneFrom = useDebounce(phoneFrom, debounceTimeout);
  const debouncedPhoneTo = useDebounce(phoneTo, debounceTimeout);
  const debouncedGroupSerialNumber = useDebounce(
    groupSerialNumber,
    debounceTimeout
  );
  const debouncedComment = useDebounce(comment, debounceTimeout);
  const debouncedWeightFrom = useDebounce(weightFrom, debounceTimeout);
  const debouncedWeightTo = useDebounce(weightTo, debounceTimeout);
  const debouncedRedemptionFrom = useDebounce(redemptionFrom, debounceTimeout);
  const debouncedRedemptionTo = useDebounce(redemptionTo, debounceTimeout);
  const debouncedPriceFrom = useDebounce(priceFrom, debounceTimeout);
  const debouncedPriceTo = useDebounce(priceTo, debounceTimeout);

  const filtersArr = useMemo(
    () => [
      dateFrom,
      dateTo,
      pickupDateFrom,
      pickupDateTo,
      deliveryDateFrom,
      deliveryDateTo,
      debouncedClientOrdererName,
      debouncedSerial,
      debouncedClientFromName,
      debouncedReference,
      debouncedClientToName,
      status,
      returnDocument,
      adresnicaServicePackagingId,
      fragile,
      twoManDelivery,
      debouncedPhoneFrom,
      debouncedPhoneTo,
      debouncedPriceFrom,
      debouncedPriceTo,
      warehouseId,
      locationTypeId,
      productTypeId,
      personalDelivery,
      requestedDeliveryDate,
      shippingTypeId,
      courierId,
      importListTypeId,
      debouncedComment,
      debouncedGroupSerialNumber,
      debouncedRedemptionFrom,
      debouncedRedemptionTo,
      debouncedWeightFrom,
      debouncedWeightTo,
    ],
    [
      dateFrom,
      dateTo,
      pickupDateFrom,
      pickupDateTo,
      deliveryDateFrom,
      deliveryDateTo,
      debouncedClientOrdererName,
      debouncedSerial,
      debouncedClientFromName,
      debouncedReference,
      debouncedClientToName,
      status,
      returnDocument,
      adresnicaServicePackagingId,
      fragile,
      twoManDelivery,
      debouncedPhoneFrom,
      debouncedPhoneTo,
      debouncedPriceFrom,
      debouncedPriceTo,
      warehouseId,
      locationTypeId,
      productTypeId,
      personalDelivery,
      requestedDeliveryDate,
      shippingTypeId,
      courierId,
      importListTypeId,
      debouncedComment,
      debouncedGroupSerialNumber,
      debouncedRedemptionFrom,
      debouncedRedemptionTo,
      debouncedWeightFrom,
      debouncedWeightTo,
    ]
  );

  const haveFiltersChanged = useHaveValuesChanged(filtersArr);

  const httpFiltersObj = useMemo(() => {
    const _dateFrom = dayjs(dateFrom as any);
    const _dateTo = dayjs(dateTo as any);
    const _pickupDateFrom = dayjs(pickupDateFrom as any);
    const _pickupDateTo = dayjs(pickupDateTo as any);
    const _deliveryDateFrom = dayjs(deliveryDateFrom as any);
    const _deliveryDateTo = dayjs(deliveryDateTo as any);
    return {
      date_from: httpDateFormat(_dateFrom),
      date_to: httpDateFormat(_dateTo),
      pickup_date_from: httpDateFormat(_pickupDateFrom),
      pickup_date_to: httpDateFormat(_pickupDateTo),
      delivery_date_from: httpDateFormat(_deliveryDateFrom),
      delivery_date_to: httpDateFormat(_deliveryDateTo),
      client_orderer_name: debouncedClientOrdererName,
      serial: debouncedSerial,
      client_from_name: debouncedClientFromName,
      group_serial_number: debouncedGroupSerialNumber,
      client_to_name: debouncedClientToName,
      reference1: debouncedReference,
      status: status,
      return_document: returnDocument,
      shipping_type_id: shippingTypeId,
      adresnica_service_packaging_id: adresnicaServicePackagingId,
      requested_delivery_date: requestedDeliveryDate,
      product_type_id: productTypeId,
      weight_from: debouncedWeightFrom,
      weight_to: debouncedWeightTo,
      redemption_from: debouncedRedemptionFrom,
      redemption_to: debouncedRedemptionTo,
      price_from: debouncedPriceFrom,
      price_to: debouncedPriceTo,
      fragile: fragile ? '1' : null,
      two_man_delivery: twoManDelivery ? '1' : null,
      comment: debouncedComment,
      import_list_type_id: importListTypeId,
      phone_from: debouncedPhoneFrom,
      phone_to: debouncedPhoneTo,
      location_type_id: locationTypeId,
      courier_id: courierId,
      warehouse_id: warehouseId,
      personal_delivery: personalDelivery ? '1' : null,
      page: haveFiltersChanged ? 1 : page,
      limit,
      summary: 1,
    };
  }, [
    dateFrom,
    dateTo,
    pickupDateFrom,
    pickupDateTo,
    deliveryDateFrom,
    deliveryDateTo,
    debouncedClientOrdererName,
    debouncedSerial,
    debouncedClientFromName,
    debouncedReference,
    debouncedClientToName,
    status,
    returnDocument,
    adresnicaServicePackagingId,
    fragile,
    twoManDelivery,
    debouncedPhoneFrom,
    debouncedPhoneTo,
    debouncedPriceFrom,
    debouncedPriceTo,
    warehouseId,
    locationTypeId,
    productTypeId,
    personalDelivery,
    requestedDeliveryDate,
    shippingTypeId,
    courierId,
    importListTypeId,
    haveFiltersChanged,
    debouncedComment,
    debouncedGroupSerialNumber,
    debouncedRedemptionFrom,
    debouncedRedemptionTo,
    debouncedWeightFrom,
    debouncedWeightTo,
    page,
    limit,
  ]);

  const { data: listTypesData } = useAxiosHook<ImportListTypeCollection>(
    '/importlisttypes',
    { skipWhen: !listTypesFilterGuard }
  );

  const {
    data: statusesData,
    error: errorStatuses,
    isLoading: isLoadingStatuses,
  } = useAxiosHook<WithData<Status[]>>(
    { url: '/orders/statuses' + queryString(httpFiltersObj) },
    {
      skipWhen:
        !!httpFiltersObj.status ||
        !httpFiltersObj.date_to ||
        !httpFiltersObj.date_to,
    }
  );

  const {
    data: hubsData,
    error: hubsError,
    isLoading: isHubsLoading,
  } = useAxiosHook<EmployeeResource>('/employees/me');

  const statusesOptions = useMemo<{ label: string; value: string }[]>(
    () =>
      statusesData?.data?.map((status: Status) => ({
        label: `${status.name} [${status.total}]`,
        value: String(status.id),
      })) ?? [],
    [statusesData]
  );

  const listTypesOptions = useMemo<{ label: string; value: string }[]>(
    () =>
      listTypesData?.data?.map((listType) => {
        return { label: listType.name, value: String(listType.id) };
      }) ?? [],
    [listTypesData]
  );

  const timeSlotOptions = useMemo(() => getTimeSlotOptions(t), [t]);

  const productTypeOptions = useMemo<{ label: string; value: Product }[]>(
    () => [
      { label: t('Package'), value: Product.Package },
      { label: t('Letter'), value: Product.Letter },
    ],
    [t]
  );

  const hubOptions = useMemo(
    () =>
      hubsData?.data?.hubs.map((hub) => ({
        value: hub.id,
        label: hub.ime,
      })) ?? [],
    [hubsData]
  );

  const orderLocationTypeOptions = useMemo<
    { label: string; value: OrderLocationType }[]
  >(
    () => [
      { label: t('Courier'), value: OrderLocationType.Courier },
      { label: t('Transporter'), value: OrderLocationType.Transporter },
      { label: t('Warehouse'), value: OrderLocationType.Warehouse },
      { label: t('Sender'), value: OrderLocationType.Sender },
      { label: t('Recipient'), value: OrderLocationType.Recipient },
    ],
    [t]
  );

  useSearchQueryDateParam('date_from', dateFrom);
  useSearchQueryDateParam('date_to', dateTo);
  useSearchQueryDateParam('pickup_date_from', pickupDateFrom);
  useSearchQueryDateParam('pickup_date_to', pickupDateTo);
  useSearchQueryDateParam('delivery_date_from', deliveryDateFrom);
  useSearchQueryDateParam('delivery_date_to', deliveryDateTo);
  useSearchQueryParam('client_orderer_name', clientOrdererName);
  useSearchQueryParam('serial', debouncedSerial);
  useSearchQueryParam('client_from_name', clientFromName);
  useSearchQueryParam('group_serial_number', debouncedGroupSerialNumber);
  useSearchQueryParam('client_to_name', clientToName);
  useSearchQueryParam('referenceNo1', debouncedReference);
  useSearchQueryDropdownParam('status', status, setStatus, statusesOptions);
  useSearchQueryDropdownParam(
    'return_document',
    returnDocument,
    setReturnDocument,
    orderReturnDocumentOptions
  );
  useSearchQueryDropdownParam(
    'shipping_type_id',
    shippingTypeId,
    setShippingTypeId,
    timeSlotOptions
  );
  useSearchQueryDropdownParam(
    'adresnica_service_packaging_id',
    adresnicaServicePackagingId,
    setAdresnicaServicePackagingId,
    orderPackagingOptions
  );
  useSearchQueryDateParam('requested_delivery_date', requestedDeliveryDate);
  useSearchQueryDropdownParam(
    'product_type_id',
    productTypeId,
    setProductTypeId,
    productTypeOptions
  );
  useSearchQueryParam('weight_from', debouncedWeightFrom);
  useSearchQueryParam('weight_to', debouncedWeightTo);
  useSearchQueryParam(
    'redemption_from',
    debouncedRedemptionFrom ? String(debouncedRedemptionFrom) : null
  );
  useSearchQueryParam(
    'redemption_to',
    debouncedRedemptionTo ? String(debouncedRedemptionTo) : null
  );
  useSearchQueryParam(
    'price_from',
    debouncedPriceFrom ? String(debouncedPriceFrom) : null
  );
  useSearchQueryParam(
    'price_to',
    debouncedPriceTo ? String(debouncedPriceTo) : null
  );
  useSearchQueryParam('fragile', fragile);
  useSearchQueryParam('two_man_delivery', twoManDelivery);
  useSearchQueryParam('comment', debouncedComment);
  useSearchQueryDropdownParam(
    'import_list_type_id',
    importListTypeId,
    setImportListTypeId,
    listTypesOptions
  );
  useSearchQueryParam('phone_from', debouncedPhoneFrom);
  useSearchQueryParam('phone_to', debouncedPhoneTo);
  useSearchQueryDropdownParam(
    'location_type_id',
    locationTypeId,
    setLocationTypeId,
    orderLocationTypeOptions
  );
  useSearchQueryDropdownParam(
    'courier_id',
    courierId,
    setCourierId,
    courierOptions
  );
  useSearchQueryDropdownParam(
    'warehouse_id',
    warehouseId,
    setWarehouseId,
    hubOptions
  );
  useSearchQueryParam('personal_delivery', personalDelivery);
  // useSearchQueryParam(
  //   'semaphore',
  //   semaphore || isEqual(status, semaphoreStatusesValues)
  //     ? String(semaphore)
  //     : null
  // );

  const basicFiltersActiveFilterCount = Object.values({
    dateFrom, // date_from and date_to is count like one
    pickupDateFrom, // pickup_date_from and date_to is count like one
    deliveryDateFrom, // delivery_date_from and date_to is count like one
    clientOrdererName,
    serial,
    clientFromName,
    groupSerialNumber,
    clientToName,
    reference, // reference1 and reference2 values we send and count both here
    status,
  }).filter(Boolean).length;

  const orderAttributesFiltersActiveFilterCount = Object.values({
    returnDocument,
    adresnicaServicePackagingId,
    shippingTypeId,
    requestedDeliveryDate,
    productTypeId,
    weightFrom, // weightFrom and weightTo is count like one
    redemptionFrom, // redemptionFrom and redemptionTo is count like one
    priceFrom, // priceFrom and priceTo is count like one
    fragile,
    twoManDelivery,
    personalDelivery,
  }).filter(Boolean).length;

  const additionalFiltersActiveFilterCount = Object.values({
    comment,
    importListTypeId,
    phoneFrom,
    phoneTo,
    locationTypeId,
    courierId,
    warehouseId,
  }).filter(Boolean).length;

  const filters = useMemo<JSX.Element>(
    () => (
      <Accordion multiple activeIndex={[0]}>
        <AccordionTab
          disabled
          headerTemplate={
            <FiltersCounter
              description={t('Basic filters')}
              counter={basicFiltersActiveFilterCount}
            />
          }
        >
          <div className="sidebar_filter">
            <label htmlFor="filter_date_from">{t('Created Date')}</label>
            <div className="sidebar_filter_row">
              <Calendar
                id="filter_date_from"
                value={dateFrom ?? undefined}
                monthNavigator
                yearNavigator
                dateFormat="dd/mm/yy"
                yearRange={`2009:${dayjs().format('YYYY')}`}
                maxDate={new Date()}
                onChange={(e) => setDateFrom(e.value as Date | null)}
                inputClassName={classNames({
                  sidebar_filter_active: dateFrom,
                })}
              />
              <span style={{ margin: 'auto' }}>{renderIcon(faMinus)}</span>
              <Calendar
                id="filter_date_to"
                value={dateTo ?? undefined}
                onChange={(e) => {
                  setDateTo(e.value as Date | null);
                }}
                dateFormat="dd/mm/yy"
                maxDate={new Date()}
                monthNavigator
                yearNavigator
                yearRange={`2009:${dayjs().format('YYYY')}`}
                inputClassName={classNames({
                  sidebar_filter_active: dateTo,
                })}
              />
            </div>
          </div>
          <div className="sidebar_filter">
            <label htmlFor="filter_pickup_date_from">
              {t('Received Date')}
            </label>
            <div className="sidebar_filter_row">
              <Calendar
                id="filter_pickup_date_from"
                value={pickupDateFrom ?? undefined}
                onChange={(e) => {
                  setPickupDateFrom(e.value as Date | null);
                  if (e.value) {
                    setPickupDateTo(new Date());
                  }
                }}
                dateFormat="dd/mm/yy"
                maxDate={new Date()}
                monthNavigator
                yearNavigator
                yearRange={`2009:${dayjs().format('YYYY')}`}
                inputClassName={classNames({
                  sidebar_filter_active: pickupDateFrom,
                })}
              />
              <span style={{ margin: 'auto' }}>{renderIcon(faMinus)}</span>
              <Calendar
                id="filter_pickup_date_to"
                value={pickupDateTo ?? undefined}
                onChange={(e) => setPickupDateTo(e.value as Date | null)}
                dateFormat="dd/mm/yy"
                maxDate={new Date()}
                monthNavigator
                yearNavigator
                yearRange={`2009:${dayjs().format('YYYY')}`}
                inputClassName={classNames({
                  sidebar_filter_active: pickupDateTo,
                })}
              />
            </div>
          </div>
          <div className="sidebar_filter">
            <label htmlFor="filter_delivery_date_from">
              {t('Shipping Date')}
            </label>
            <div className="sidebar_filter_row">
              <Calendar
                id="filter_delivery_date_from"
                value={deliveryDateFrom ?? undefined}
                onChange={(e) => {
                  setDeliveryDateFrom(e.value as Date | null);
                  if (e.value) {
                    setDeliveryDateTo(new Date());
                  }
                }}
                dateFormat="dd/mm/yy"
                maxDate={new Date()}
                monthNavigator
                yearNavigator
                yearRange={`2009:${dayjs().format('YYYY')}`}
                inputClassName={classNames({
                  sidebar_filter_active: deliveryDateFrom,
                })}
              />
              <span style={{ margin: 'auto' }}>{renderIcon(faMinus)}</span>
              <Calendar
                id="filter_delivery_date_to"
                value={deliveryDateTo ?? undefined}
                onChange={(e) => setDeliveryDateTo(e.value as Date | null)}
                dateFormat="dd/mm/yy"
                maxDate={new Date()}
                monthNavigator
                yearNavigator
                yearRange={`2009:${dayjs().format('YYYY')}`}
                inputClassName={classNames({
                  sidebar_filter_active: deliveryDateTo,
                })}
              />
            </div>
          </div>
          <div className="sidebar_filter">
            <label htmlFor="client_orderer_name_filter">{t('Orderer')}</label>
            <InputText
              id="client_orderer_name_filter"
              value={clientOrdererName ?? ''}
              onChange={(e) => setClientOrdererName(e.target.value)}
              className={classNames({
                sidebar_filter_active: clientOrdererName,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="serial_filter">{t('Serial No')}</label>
            <InputText
              id="serial_filter"
              value={serial ?? ''}
              onChange={(e) => setSerial(e.target.value)}
              className={classNames({
                sidebar_filter_active: serial,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="client_from_name_filter">{t('Sender')}</label>
            <InputText
              id="client_from_name_filter"
              value={clientFromName ?? ''}
              onChange={(e) => setClientFromName(e.target.value)}
              className={classNames({
                sidebar_filter_active: clientFromName,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="group_serial_number">
              {t('Group Serial Number')}
            </label>
            <InputText
              id="group_serial_number"
              value={groupSerialNumber ?? ''}
              onChange={(e) => setGroupSerialNumber(e.target.value)}
              className={classNames({
                sidebar_filter_active: groupSerialNumber,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="client_to_name_filter">{t('Recipient')}</label>
            <InputText
              id="client_to_name_filter"
              value={clientToName ?? ''}
              onChange={(e) => setClientToName(e.target.value)}
              className={classNames({
                sidebar_filter_active: clientToName,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="reference_filter">{t('Reference')}</label>
            <InputText
              id="reference_filter"
              onChange={(e) => setReference(e.target.value)}
              value={reference ?? ''}
              className={classNames({
                sidebar_filter_active: reference,
              })}
            />
          </div>
          {/* {statusFilterGuard && (
          <div className="sidebar_filter">
            <label htmlFor="semaphore">{t('Semaphore')}</label>
            <Controller<FormValues, 'semaphore'>
              name="semaphore"
              control={control}
              render={({ field }) => (
                <InputSwitch
                  checked={field.value}
                  onChange={(e) => {
                    setValue('semaphore', e.value);
                    if (e.value) {
                      setValue('status', semaphoreStatusesValues);
                    } else {
                      setValue('status', null);
                    }
                  }}
                  inputId={field.name}
                />
              )}
            />
          </div>
        )} */}
          <div className="sidebar_filter">
            <label htmlFor="status_filter">{t('Status')}</label>
            <Dropdown
              id="status_filter"
              disabled={isLoadingStatuses ?? errorStatuses}
              onChange={(e: DropdownChangeParams) => setStatus(e.value)}
              options={statusesOptions}
              value={status}
              showClear
              filter
              placeholder={t('All')}
              className={classNames({
                sidebar_filter_active: status,
              })}
            />
          </div>
        </AccordionTab>
        <AccordionTab
          headerTemplate={
            <FiltersCounter
              description={t('Attributes')}
              counter={orderAttributesFiltersActiveFilterCount}
            />
          }
        >
          <div className="sidebar_filter">
            <label htmlFor="return_document_filter">
              {t('Return document')}
            </label>
            <Dropdown
              id="return_document_filter"
              options={orderReturnDocumentOptions}
              onChange={(e: DropdownChangeParams) => setReturnDocument(e.value)}
              value={returnDocument}
              showClear
              placeholder={t('All')}
              className={classNames({
                sidebar_filter_active: returnDocument,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="shipping_type_id">{t('Timeframe')}</label>
            <Dropdown
              id="shipping_type_id"
              options={timeSlotOptions}
              value={shippingTypeId}
              showClear
              placeholder={t('All')}
              onChange={(e: DropdownChangeParams) => setShippingTypeId(e.value)}
              className={classNames({
                sidebar_filter_active: shippingTypeId,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="adresnica_service_packaging_id_filter">
              {t('Order service packaging')}
            </label>
            <Dropdown
              id="adresnica_service_packaging_id_filter"
              options={orderPackagingOptions}
              onChange={(e: DropdownChangeParams) =>
                setAdresnicaServicePackagingId(e.value)
              }
              value={adresnicaServicePackagingId}
              showClear
              placeholder={t('No packaging')}
              className={classNames({
                sidebar_filter_active: adresnicaServicePackagingId,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="requested_delivery_date">
              {t('Requested Delivery')}
            </label>
            <Calendar
              id="requested_delivery_date"
              onChange={(e) => {
                setRequestedDeliveryDate(e.value);
              }}
              value={requestedDeliveryDate}
              dateFormat="dd/mm/yy"
              monthNavigator
              yearNavigator
              yearRange={`2009:${dayjs().add(1, 'year').format('YYYY')}`}
              showButtonBar
              inputClassName={classNames({
                sidebar_filter_active: requestedDeliveryDate,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="product_type_id">{t('Product')}</label>
            <Dropdown
              id="product_type_id"
              options={productTypeOptions}
              value={productTypeId}
              placeholder={t('All')}
              showClear
              className={classNames({
                sidebar_filter_active: productTypeId,
              })}
              onChange={(e) => {
                if (e.value !== Product.Letter) {
                  setPersonalDelivery(null);
                } else {
                  setPersonalDelivery(personalDelivery ?? false);
                }

                setProductTypeId(e.value);
              }}
            />
          </div>
          {productTypeId === Product.Letter && (
            <div className="sidebar_filter">
              <label htmlFor="personal_delivery">
                {t('Personal Delivery')}
              </label>

              <InputSwitch
                id="personal_delivery"
                checked={personalDelivery ?? false}
                onChange={(e) => {
                  setPersonalDelivery(e.value);
                }}
                inputId={personalDelivery}
              />
            </div>
          )}
          <div className="sidebar_filter">
            <label htmlFor="filter_weight_from">{t('Weight')}</label>
            <div className="sidebar_filter_row">
              <InputNumber
                id="weight_from"
                min={0}
                max={100}
                minFractionDigits={1}
                maxFractionDigits={3}
                step={0.001}
                value={weightFrom ?? ''}
                onChange={(e) => setWeightFrom(e.value)}
                className={classNames(
                  {
                    sidebar_filter_active: weightFrom,
                  },
                  'number-group'
                )}
              />
              <span style={{ margin: 'auto' }}>{renderIcon(faMinus)}</span>
              <InputNumber
                id="weight_to"
                min={0}
                max={100}
                minFractionDigits={1}
                maxFractionDigits={3}
                step={0.001}
                value={weightTo ?? ''}
                onChange={(e) => setWeightTo(e.value)}
                className={classNames(
                  {
                    sidebar_filter_active: weightTo,
                  },
                  'number-group'
                )}
              />
            </div>
          </div>
          <div className="sidebar_filter">
            <label htmlFor="redemption_from">{t('Redemption')}</label>
            <div className="sidebar_filter_row">
              <InputNumber
                id="redemption_from"
                value={redemptionFrom ?? ''}
                onChange={(e) => setRedemptionFrom(e.value)}
                className={classNames(
                  {
                    sidebar_filter_active: redemptionFrom,
                  },
                  'number-group'
                )}
              />
              <span style={{ margin: 'auto' }}>{renderIcon(faMinus)}</span>
              <InputNumber
                id="redemption_to"
                value={redemptionTo ?? ''}
                onChange={(e) => setRedemptionTo(e.value)}
                className={classNames(
                  {
                    sidebar_filter_active: redemptionTo,
                  },
                  'number-group'
                )}
              />
            </div>
          </div>
          <div className="sidebar_filter">
            <label htmlFor="price_from">{t('Price')}</label>
            <div className="sidebar_filter_row">
              <InputNumber
                id="price_from"
                value={priceFrom ?? ''}
                onChange={(e) => setPriceFrom(e.value)}
                className={classNames(
                  {
                    sidebar_filter_active: priceFrom,
                  },
                  'number-group'
                )}
              />
              <span style={{ margin: 'auto' }}>{renderIcon(faMinus)}</span>
              <InputNumber
                id="price_to"
                value={priceTo ?? ''}
                onChange={(e) => setPriceTo(e.value)}
                className={classNames(
                  {
                    sidebar_filter_active: priceTo,
                  },
                  'number-group'
                )}
              />
            </div>
          </div>
          <div className="sidebar_filter_same_row">
            <div className="sidebar_filter">
              <label htmlFor="fragile_filter">{t('Fragile')}</label>
              <InputSwitch
                id="fragile_filter"
                checked={isBoolean(fragile) ? fragile : null}
                onChange={(e: InputSwitchChangeParams) =>
                  setFragile(Boolean(e.value))
                }
                className={classNames({
                  sidebar_filter_active: fragile,
                })}
              />
            </div>
            <div className="sidebar_filter">
              <label htmlFor="two_man_delivery_filter">
                {t('Two man delivery')}
              </label>
              <InputSwitch
                id="two_man_delivery_filter"
                checked={isBoolean(twoManDelivery) ? twoManDelivery : null}
                onChange={(e: InputSwitchChangeParams) =>
                  setTwoManDelivery(e.target.value)
                }
                className={classNames({
                  sidebar_filter_active: twoManDelivery,
                })}
              />
            </div>
          </div>
        </AccordionTab>
        <AccordionTab
          headerTemplate={
            <FiltersCounter
              description={t('Additional Filters')}
              counter={additionalFiltersActiveFilterCount}
            />
          }
        >
          <div className="sidebar_filter">
            <label htmlFor="comment">{t('Note')}</label>
            <InputText
              id="comment"
              value={comment ?? ''}
              onChange={(e) => setComment(e.target.value)}
              className={classNames({
                sidebar_filter_active: comment,
              })}
            />
          </div>
          {listTypesFilterGuard && (
            <div className="sidebar_filter">
              <label htmlFor="import_list_type_id">
                {t('Batch Order Template')}
              </label>
              <Dropdown
                id="import_list_type_id"
                options={listTypesOptions}
                value={importListTypeId}
                onChange={(e: DropdownChangeParams) =>
                  setImportListTypeId(e.value)
                }
                showClear
                className={classNames({
                  sidebar_filter_active: importListTypeId,
                })}
              />
            </div>
          )}
          <div className="sidebar_filter">
            <label htmlFor="phone_from_filter">
              {t('Sender phone number')}
            </label>
            <InputText
              id="phone_from_filter"
              value={phoneFrom ?? ''}
              onChange={(e) => setPhoneFrom(e.target.value)}
              className={classNames({
                sidebar_filter_active: phoneFrom,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="phone_to_filter">
              {t('Recipient phone number')}
            </label>
            <InputText
              id="phone_to_filter"
              value={phoneTo ?? ''}
              onChange={(e) => setPhoneTo(e.target.value)}
              className={classNames({
                sidebar_filter_active: phoneTo,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="location_type_id">{t('Location')}</label>
            <Dropdown
              id="location_type_id"
              options={orderLocationTypeOptions}
              value={locationTypeId}
              showClear
              placeholder={t('All')}
              onChange={(e: DropdownChangeParams) => setLocationTypeId(e.value)}
              className={classNames({
                sidebar_filter_active: locationTypeId,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="courier_id">{t('Courier')}</label>
            <Dropdown
              id="courier_id"
              options={courierOptions}
              value={courierId}
              showClear
              placeholder={t('All')}
              onChange={(e: DropdownChangeParams) => setCourierId(e.value)}
              disabled={isCouriersLoading || !!couriersError}
              className={classNames({
                sidebar_filter_active: courierId,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="warehouse_id">{t('Warehouse')}</label>
            <Dropdown
              id="warehouse_id"
              options={hubOptions}
              value={warehouseId}
              filter
              showClear
              placeholder={t('All')}
              onChange={(e: DropdownChangeParams) => setWarehouseId(e.value)}
              disabled={isHubsLoading || !!hubsError}
              className={classNames({
                sidebar_filter_active: warehouseId,
              })}
            />
          </div>
        </AccordionTab>
      </Accordion>
    ),
    [
      dateFrom,
      dateTo,
      pickupDateFrom,
      pickupDateTo,
      deliveryDateFrom,
      deliveryDateTo,
      clientOrdererName,
      serial,
      clientFromName,
      reference,
      clientToName,
      status,
      returnDocument,
      adresnicaServicePackagingId,
      fragile,
      twoManDelivery,
      phoneFrom,
      phoneTo,
      orderPackagingOptions,
      orderReturnDocumentOptions,
      statusesOptions,
      isLoadingStatuses,
      errorStatuses,
      comment,
      courierOptions,
      courierId,
      couriersError,
      groupSerialNumber,
      hubOptions,
      hubsError,
      importListTypeId,
      isCouriersLoading,
      isHubsLoading,
      listTypesFilterGuard,
      listTypesOptions,
      locationTypeId,
      orderLocationTypeOptions,
      personalDelivery,
      priceFrom,
      priceTo,
      weightFrom,
      weightTo,
      productTypeId,
      productTypeOptions,
      redemptionFrom,
      redemptionTo,
      requestedDeliveryDate,
      shippingTypeId,
      timeSlotOptions,
      warehouseId,
      basicFiltersActiveFilterCount,
      additionalFiltersActiveFilterCount,
      orderAttributesFiltersActiveFilterCount,
      t,
    ]
  );

  const headerFiltersCount = useMemo(
    () =>
      basicFiltersActiveFilterCount +
      orderAttributesFiltersActiveFilterCount +
      additionalFiltersActiveFilterCount,
    [
      basicFiltersActiveFilterCount,
      orderAttributesFiltersActiveFilterCount,
      additionalFiltersActiveFilterCount,
    ]
  );

  function resetAllFilters() {
    setDateFrom(new Date());
    setDateTo(new Date());
    setPickupDateFrom(null);
    setPickupDateTo(null);
    setDeliveryDateFrom(null);
    setDeliveryDateTo(null);
    setClientOrdererName('');
    setSerial('');
    setClientFromName('');
    setGroupSerialNumber('');
    setClientToName('');
    setReference('');
    setStatus(null);
    setReturnDocument(null);
    setShippingTypeId(null);
    setAdresnicaServicePackagingId(null);
    setRequestedDeliveryDate(null);
    setProductTypeId(null);
    setWeightFrom('');
    setWeightTo('');
    setRedemptionFrom('');
    setRedemptionTo('');
    setPriceFrom('');
    setPriceTo('');
    setFragile(null);
    setTwoManDelivery(null);
    setComment('');
    setImportListTypeId(null);
    setPhoneFrom('');
    setPhoneTo('');
    setLocationTypeId(null);
    setCourierId(null);
    setWarehouseId(null);
    setPersonalDelivery(null);
  }

  return {
    filters,
    resetAllFilters,
    httpFiltersObj,
    headerFiltersCount,
  };
}

export default useTableFilters;
