import { TFunction } from 'react-i18next';

import { ClientLookupCollection } from '../../../types/api/clients';
import { EmployeeCollection } from '../../../types/api/employees';
import { Numeric } from '../../../types/general';
import { Unpacked } from '../../../types/util';
import { noColumnStyle } from '../../../utils/constants/tables';
import { dateCell } from '../../../utils/helpers/dataTable';
import { contextMenuModel } from '../../../utils/helpers/primereact';

export type ClientOption = Pick<Unpacked<ClientLookupCollection>, 'id' | 'ime'>;

export type EmployeeOption = Pick<
  Unpacked<EmployeeCollection>,
  'id' | 'ime' | 'prezime'
>;

export type CourierLiability = {
  id: number;
  prefix: string;
  seriski_broj_pocetok: string;
  seriski_broj_kraj: string;
  vkupen_broj_adresnici: string;
  rabotnik_id: number;
  rabotnik_ime: string;
  postar_id: number;
  postar_ime: string;
  client_id: number | null;
  client_name: string | null;
  datum_kreiranje: string;
  outofdebt: string;
  clientoutofdebt: string;
  indebt: Numeric;
};

export type CourierLiabilityResource = CourierLiability & {
  outofdebt: string[];
  clientOutofdebt: string[];
  indebt: string[];
};

export const tableStorageKey = 'couriers_liability_dataTable';

export function getColumnHeadersMap(t: TFunction) {
  return {
    no: t('No.'),
    prefix: t('Prefix'),
    seriski_broj_pocetok: t('Initial serial No.'),
    seriski_broj_kraj: t('End serial No.'),
    vkupen_broj_adresnici: t('Total'),
    postar_ime: t('Courier'),
    rabotnik_ime: t('Employee'),
    datum_kreiranje: t('Date'),
    indebt: t('Created'),
    outofdebt: t('Uncreated'),
    clientoutofdebt: t('Created for client'),
    client_name: t('Client'),
  };
}

export function additionalColumnProperties(
  column: keyof ReturnType<typeof getColumnHeadersMap>
) {
  switch (column) {
    case 'no':
      return noColumnStyle;

    case 'datum_kreiranje':
      return {
        body: (row: CourierLiability) => dateCell(row[column], true),
        style: {
          width: 140,
        },
        bodyStyle: {
          width: 140,
        },
      };

    case 'prefix':
      return {
        style: {
          width: 120,
        },
        bodyStyle: {
          width: 120,
        },
      };

    case 'seriski_broj_pocetok':
    case 'seriski_broj_kraj':
      return {
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
        },
      };

    case 'vkupen_broj_adresnici':
      return {
        style: {
          width: 120,
        },
        bodyStyle: {
          width: 120,
        },
      };

    case 'postar_ime':
    case 'rabotnik_ime':
      return {
        style: {
          width: 250,
        },
        bodyStyle: {
          width: 250,
        },
      };

    case 'indebt':
    case 'outofdebt':
      return {
        style: {
          width: 150,
        },
        bodyStyle: {
          width: 150,
        },
      };

    case 'clientoutofdebt':
    case 'client_name':
      return {
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
        },
      };

    default:
      return {};
  }
}

export function generateContextMenu(
  t: TFunction,
  isViewShown: boolean,
  handleViewAddresses: () => void,
  isEditShown: boolean,
  handleEdit: () => void,
  isDeleteShown: boolean,
  handleDelete: () => void
) {
  const viewOption = {
    label: t('View Addresses'),
    icon: 'fas fa-address-book',
    command: handleViewAddresses,
  };

  const editOption = {
    label: t('Edit Liability'),
    icon: 'fas fa-edit',
    command: handleEdit,
  };

  const deleteOption = {
    label: t('Delete Liability'),
    icon: 'fas fa-trash',
    command: handleDelete,
  };

  return contextMenuModel([
    [[isViewShown, viewOption]],
    [[isEditShown, editOption]],
    [[isDeleteShown, deleteOption]],
  ]);
}
