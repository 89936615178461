import { Dispatch, SetStateAction, createContext } from 'react';

import useLocalStorage from '../hooks/useLocalStorage';
import useMediaQuery from '../hooks/useMediaQuery';

type Context = {
  isMenuExpanded: boolean;
  setIsMenuExpanded: Dispatch<SetStateAction<boolean>>;
};

const MenuContext = createContext<Context>({
  isMenuExpanded: true,
  setIsMenuExpanded: () => {},
});

type Props = {
  children: JSX.Element[];
};

function MenuContextProvider({ children }: Props): JSX.Element {
  const isOnMobile = useMediaQuery('(max-width: 768px)');

  const [isMenuExpanded, setIsMenuExpanded] = useLocalStorage(
    'sideMenuExpanded',
    !isOnMobile ? true : false
  );
  return (
    <MenuContext.Provider
      value={{
        isMenuExpanded,
        setIsMenuExpanded,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
}

export { MenuContext, MenuContextProvider };
