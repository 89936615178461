import { useFormikContext } from 'formik';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useAxiosHook from '../../../../../../hooks/useAxiosHook';
import { NearestCouriersCollection } from '../../../../../../types/api/hubs';
import { FormValues } from '../CreateEditDialog.functions';

type Props = {
  initialValues: FormValues;
};

export function PickupAssignmentTab({ initialValues }: Props) {
  const { t } = useTranslation();

  const { values, setFieldValue } = useFormikContext<FormValues>();

  const { data: nearestHub, isLoading: isNearestHubLoading } = useAxiosHook<{
    ime: string;
    id: number;
  }>(
    {
      url: '/hubs/nearest',
      data: {
        place_id: values.mesto_od_id,
        address_from: `${values.adresa_od} ${values.broj_od}, ${values.mesto_od_ime}`,
      },
      method: 'POST',
    },
    {
      skipWhen:
        !values.mesto_od_id ??
        initialValues.mesto_od_id !== values.mesto_od_id ??
        !values.delivery_location_type_id,
    }
  );

  const { data, error, isLoading } = useAxiosHook<NearestCouriersCollection>(
    `/hubs/couriers?hub_id=${nearestHub?.id}`,
    {
      skipWhen: !nearestHub || !values.pickupAssignmentCheckbox,
    }
  );

  const courierOptions = useMemo(
    () =>
      data?.map((courier) => ({
        label: `${courier.first_name} ${courier.last_name}`,
        value: courier.id,
      })) ?? [],
    [data]
  );

  return (
    <div className="pickup-assignment-container">
      <div className="pickup-assignment-checkbox">
        <Checkbox
          inputId="courier_reception"
          name="courier_reception"
          checked={values.pickupAssignmentCheckbox}
          onChange={(e) => setFieldValue('pickupAssignmentCheckbox', e.checked)}
        />
        <label htmlFor="courier_reception">
          {t('Assign to courier for reception')}
        </label>
      </div>
      <Dropdown
        className="pickup-assignment-dropdown"
        value={values.pickupAssignmentCourier}
        onChange={(e) => setFieldValue('pickupAssignmentCourier', e.value)}
        placeholder={
          isLoading || isNearestHubLoading ? t('Loading...') : t('Courier')
        }
        disabled={
          isLoading ||
          isNearestHubLoading ||
          !values.pickupAssignmentCheckbox ||
          !!error
        }
        filter
        showClear
        options={courierOptions}
      />
    </div>
  );
}
