import { ColumnProps } from 'primereact/column';
import { TFunction } from 'react-i18next';

import { PriceListCollection } from '../../../types/api/priceLists';
import { ContextMenuItem } from '../../../types/primereact';
import {
  TableColumnHeadersMap,
  TableColumns,
  Unpacked,
} from '../../../types/util';
import { emptyCell, noColumnStyle } from '../../../utils/constants/tables';
import { dateCell } from '../../../utils/helpers/dataTable';
import { withTrailingEllipsis } from '../../../utils/helpers/formatting';
import { contextMenuModel } from '../../../utils/helpers/primereact';
import Tooltip from '../../Misc/Tooltip/Tooltip';

export type Row = Unpacked<PriceListCollection>;

export type Column = TableColumns<
  Pick<
    Row,
    | 'name'
    | 'description'
    | 'parent_name'
    | 'created_at'
    | 'created_by_full_name'
    | 'modified_at'
    | 'modified_by_full_name'
  >
>;

export const tableStorageKey = 'priceLists_dataTable';

export function getColumnHeadersMap(
  t: TFunction
): TableColumnHeadersMap<Column> {
  return {
    no: t('No.'),
    name: t('Name'),
    description: t('Description'),
    parent_name: t('Base price list'),
    created_at: t('Created at'),
    created_by_full_name: t('Created by'),
    modified_at: t('Modified at'),
    modified_by_full_name: t('Modified by'),
  };
}

export function additionalColumnProperties(column: Column): ColumnProps {
  switch (column) {
    case 'no':
      return noColumnStyle;

    case 'name':
    case 'parent_name':
    case 'created_by_full_name':
    case 'modified_by_full_name':
      return {
        style: { width: 250 },
        bodyStyle: { width: 250 },
      };

    case 'description':
      return {
        body: (row: Row) =>
          !!row[column] ? (
            <Tooltip text={row[column]}>
              {withTrailingEllipsis(row[column]!, 0, 34)}
            </Tooltip>
          ) : (
            emptyCell
          ),
        style: { width: 300 },
        bodyStyle: {
          width: 300,
        },
      };

    case 'created_at':
    case 'modified_at':
      return {
        body: (row: Row) => dateCell(row[column]),
        style: {
          width: 180,
        },
        bodyStyle: {
          width: 180,
        },
      };
  }
}

export function generateContextMenu({
  t,
  isEditShown,
  onEditClick,
  isPriceDefinitionsShown,
  onPriceDefinitionsClick,
  onPrintClick,
  isDeleteShown,
  onDeleteClick,
}: {
  t: TFunction;
  isEditShown: boolean;
  onEditClick: () => void;
  isPriceDefinitionsShown: boolean;
  onPriceDefinitionsClick: () => void;
  onPrintClick: () => void;
  isDeleteShown: boolean;
  onDeleteClick: () => void;
}): ContextMenuItem[] {
  const editOption = {
    label: t('Edit price list'),
    icon: 'fas fa-edit',
    command: onEditClick,
  };

  const priceDefinitionOption = {
    label: t('Price definitions'),
    icon: 'fas fa-tag',
    command: onPriceDefinitionsClick,
  };

  const printOption = {
    label: t('Print price list'),
    icon: 'fas fa-print',
    command: onPrintClick,
  };

  const deleteOption = {
    label: t('Delete price list'),
    icon: 'fas fa-trash',
    command: onDeleteClick,
  };

  return contextMenuModel([
    [[isEditShown, editOption]],
    [[isPriceDefinitionsShown, priceDefinitionOption]],
    [[false, printOption]], // !!!
    [[isDeleteShown, deleteOption]],
  ]);
}
