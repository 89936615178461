import './ImportFile.scss';

import { Form, Formik, FormikProps } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import ToastContext from '../../../../../context/ToastContext';
import useAxiosHook from '../../../../../hooks/useAxiosHook';
import useMediaQuery from '../../../../../hooks/useMediaQuery';
import usePrevious from '../../../../../hooks/usePrevious';
import { ImportListTypeResource } from '../../../../../types/api/importlisttypes';
import {
  errorToast,
  successToast,
} from '../../../../../utils/helpers/primereact';
import {
  ClientOption,
  FormValues,
  getValidationSchema,
  initialValues,
  toApiData,
} from './ImportFile.functions';
import ImportFileForm from './ImportFileForm';

type Props = {
  isShown: boolean;
  onHide: () => void;
  reloadImportedLists: () => void;
};

function ImportFile({
  isShown,
  onHide,
  reloadImportedLists,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const formRef = useRef<FormikProps<FormValues>>(null);
  const { toastRef } = useContext(ToastContext);

  const [client, setClient] = useState('');
  const [clientObj, setClientObj] = useState<ClientOption | null>(null);

  const [listTypeObj, setListTypeObj] =
    useState<ImportListTypeResource | null>(null);

  const onDialogHide = useCallback(() => {
    setClient('');
    setClientObj(null);
    setListTypeObj(null);
    onHide();
    formRef.current?.handleReset();
  }, [onHide]);

  const {
    data: formSubmissionData,
    error: formSubmissionError,
    isLoading: isFormSubmissionLoading,
    reload: formSubmissionReload,
  } = useAxiosHook<{ generalMsg?: string }, { generalMsg?: string }>();

  const prevformSubmissionData = usePrevious(formSubmissionData);
  const prevFormSubmissionError = usePrevious(formSubmissionError);

  useEffect(() => {
    if (!formSubmissionData || formSubmissionData === prevformSubmissionData) {
      return;
    }

    onDialogHide();
    reloadImportedLists();

    successToast(
      toastRef,
      t('Success'),
      formSubmissionData.generalMsg ?? t('List successfully imported')
    );
  }, [
    formSubmissionData,
    onDialogHide,
    prevformSubmissionData,
    reloadImportedLists,
    t,
    toastRef,
  ]);

  useEffect(() => {
    if (
      !formSubmissionError ||
      formSubmissionError === prevFormSubmissionError
    ) {
      return;
    }

    onDialogHide();

    errorToast(
      toastRef,
      t('Error'),
      formSubmissionError.response?.data.generalMsg ??
        t('An error occured while trying to import your list')
    );
  }, [formSubmissionError, onDialogHide, prevFormSubmissionError, t, toastRef]);

  function handleFormSubmision(values: FormValues) {
    formSubmissionReload({
      url: '/orders/import',
      method: 'POST',
      data: toApiData(values, listTypeObj!),
    });
  }

  const dialogFooter = (
    <>
      <Button
        type="button"
        label={t('Cancel')}
        className="p-button-text"
        onClick={onDialogHide}
        disabled={isFormSubmissionLoading}
      />

      <Button
        type="button"
        label={isFormSubmissionLoading ? t('Importing...') : t('Import')}
        disabled={isFormSubmissionLoading}
        onClick={() => {
          if (formRef.current) {
            formRef.current.handleSubmit();
          }
        }}
        data-cy="submit-btn"
      />
    </>
  );

  const validationSchema = useMemo(
    () => getValidationSchema(t, listTypeObj),
    [listTypeObj, t]
  );

  const isOnMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Dialog
      visible={isShown}
      onHide={onDialogHide}
      header={t('Import file')}
      footer={dialogFooter}
      blockScroll
      resizable={false}
      maximizable
      maximized={isOnMobile}
      className="imported-lists-import-from-file-dialog"
    >
      <div className="import-from-file-page">
        <div className="p-fluid">
          <Formik
            innerRef={formRef}
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange
            validateOnBlur={false}
            onSubmit={(values) => {
              handleFormSubmision(values);
            }}
          >
            <Form>
              <ImportFileForm
                client={client}
                setClient={setClient}
                clientObj={clientObj}
                setClientObj={setClientObj}
                listTypeObj={listTypeObj}
                setListTypeObj={setListTypeObj}
              />
            </Form>
          </Formik>
        </div>
      </div>
    </Dialog>
  );
}

export default ImportFile;
