import classNames from 'classnames';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Dropdown, DropdownChangeParams } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Dispatch, useMemo } from 'react';
import { useState } from 'react';
import { SetStateAction } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDebounce } from 'use-lodash-debounce';

import { UserStatus } from '../../../enums/users';
import useAxiosHook from '../../../hooks/useAxiosHook';
import { useEndpointGuard } from '../../../hooks/useEndpointGuard';
import useHaveValuesChanged from '../../../hooks/useHaveValuesChanged';
import useSearchQueryDropdownParam from '../../../hooks/useSearchQueryDropdownParam';
import useSearchQueryMultiSelectParam from '../../../hooks/useSearchQueryMultiSelectParam';
import useSearchQueryParam from '../../../hooks/useSearchQueryParam';
import { EmployeeRoles } from '../../../types/api/employees';
import { HubCollection } from '../../../types/api/hubs';
import { LabelValue } from '../../../types/options';
import * as employeesGuards from '../../../utils/constants/auth/employees';
import { debounceTimeout } from '../../../utils/constants/misc';
import { queryString } from '../../../utils/helpers/http';
import {
  getSearchQueryParam,
  tryMultiSelectParam,
} from '../../../utils/helpers/searchQuery';
import FiltersCounter from '../Components/Filters/FiltersCounter';

function useTableFilters(
  page: number,
  setPage: Dispatch<SetStateAction<number>>,
  limit: number
) {
  const { t } = useTranslation();
  const employeeRoleFilterGuard = useEndpointGuard(
    employeesGuards.employeeRoleFilter
  );
  const hubFilterGuard = useEndpointGuard(employeesGuards.hubFilter);
  const location = useLocation();
  const [name, setName] = useState<string>(
    () => getSearchQueryParam(location.search, 'name') ?? ''
  );
  const [lastName, setLastName] = useState<string>(
    () => getSearchQueryParam(location.search, 'surname') ?? ''
  );
  const [comment, setComment] = useState<string>(
    () => getSearchQueryParam(location.search, 'comment') ?? ''
  );
  const [roles, setRoles] = useState<number[] | null>(() => {
    const value = employeeRoleFilterGuard
      ? getSearchQueryParam(location.search, 'roles') ?? null
      : null;
    return value
      ? tryMultiSelectParam(value)
          ?.map((r) => parseInt(r))
          .filter((r) => !isNaN(r)) ?? null
      : null;
  });
  const [nativeHub, setNativeHub] = useState<string | null>(() =>
    hubFilterGuard
      ? getSearchQueryParam(location.search, 'native_hub') ?? null
      : null
  );
  const [hubs, setHubs] = useState<string[] | null>(() => {
    const value = getSearchQueryParam(location.search, 'hubs') ?? null;
    return value ? tryMultiSelectParam(value) ?? null : null;
  });
  const [statusId, setStatusId] = useState<string | null>(
    () => getSearchQueryParam(location.search, 'status') ?? null
  );

  const debouncedName = useDebounce(name, debounceTimeout);
  const debouncedSurname = useDebounce(lastName, debounceTimeout);
  const debouncedComment = useDebounce(comment, debounceTimeout);

  const { data: employeeRoles } = useAxiosHook<EmployeeRoles>(
    '/roles/employees',
    { skipWhen: !employeeRoleFilterGuard }
  );
  const { data: hubsOptions } = useAxiosHook<HubCollection>(
    '/hubs' +
      queryString({
        page: 1,
        limit: 10000000,
      }),
    { skipWhen: !hubFilterGuard }
  );
  const rolesOptions = useMemo(
    () =>
      employeeRoles?.data.map((r) => ({
        label: r.name,
        value: r.id,
      })) ?? [],
    [employeeRoles?.data]
  );
  const hubOptions = useMemo(
    () =>
      hubsOptions?.data?.map((h) => ({
        label: h.name,
        value: h.id,
      })) ?? [],
    [hubsOptions?.data]
  );
  const statusOptions = useMemo<LabelValue<UserStatus>[]>(
    () => [
      { label: t('Active'), value: UserStatus.Active },
      { label: t('Inactive'), value: UserStatus.Blocked },
      {
        label: t('Accepted, awaiting e-mail activation'),
        value: UserStatus.ActivationAccepted,
      },
      { label: t('Rejected'), value: UserStatus.ActivationRejected },
      { label: t('Pending'), value: UserStatus.ActivationRequest },
      { label: t('Deleted'), value: UserStatus.Delete },
      {
        label: t('Password reset request was sent'),
        value: UserStatus.PasswordReset,
      },
    ],
    [t]
  );
  const filtersArr = useMemo(
    () => [
      {
        name: debouncedName,
        debouncedSurname,
        statusId,
        roles,
        nativeHub,
        hubs,
        comment: debouncedComment,
      },
    ],
    [
      debouncedName,
      debouncedSurname,
      debouncedComment,
      hubs,
      nativeHub,
      roles,
      statusId,
    ]
  );

  const haveFiltersChanged = useHaveValuesChanged(filtersArr);

  useEffect(() => {
    setPage(1);
  }, [
    debouncedName,
    debouncedSurname,
    statusId,
    roles,
    nativeHub,
    hubs,
    debouncedComment,
    setPage,
  ]);

  useSearchQueryParam('name', debouncedName);
  useSearchQueryParam('surname', debouncedSurname);
  useSearchQueryParam('comment', debouncedComment);
  useSearchQueryDropdownParam(
    'status_id',
    statusId,
    setStatusId,
    statusOptions
  );
  useSearchQueryMultiSelectParam('roles', roles);
  useSearchQueryDropdownParam(
    'native_hub',
    nativeHub,
    setNativeHub,
    hubOptions
  );
  useSearchQueryMultiSelectParam('hubs', hubs);

  useEffect(() => {
    if (!employeeRoleFilterGuard) {
      setRoles(null);
    }
  }, [employeeRoleFilterGuard]);
  useEffect(() => {
    if (!hubFilterGuard) {
      setNativeHub(null);
    }
  }, [hubFilterGuard]);

  const httpFiltersObj = useMemo(
    () => ({
      name: debouncedName,
      surname: debouncedSurname,
      status_id: statusId,
      roles: roles,
      native_hub_id: nativeHub,
      hubs: hubs,
      comment: debouncedComment,
      page: haveFiltersChanged ? 1 : page,
      limit,
    }),
    [
      debouncedName,
      debouncedSurname,
      debouncedComment,
      haveFiltersChanged,
      hubs,
      limit,
      nativeHub,
      page,
      roles,
      statusId,
    ]
  );

  const basicFiltersActiveFilterCount = Object.values({
    name,
    lastName,
    roles,
    statusId,
    nativeHub,
    hubs,
    comment,
  }).filter(Boolean).length;

  const filters = useMemo<JSX.Element>(
    () => (
      <Accordion multiple activeIndex={[0]}>
        <AccordionTab
          disabled
          headerTemplate={
            <FiltersCounter
              description={t('Basic filters')}
              counter={basicFiltersActiveFilterCount}
            />
          }
        >
          <div className="sidebar_filter">
            <label htmlFor="filter_name">{t('Name')}</label>
            <InputText
              id="filter_name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className={classNames({
                sidebar_filter_active: name,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="filter_surname">{t('Surname')}</label>
            <InputText
              id="filter_surname"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              className={classNames({
                sidebar_filter_active: lastName,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="filter_status">{t('Status')}</label>
            <Dropdown
              inputId="filter_status"
              value={statusId}
              showClear
              options={statusOptions}
              onChange={(e: DropdownChangeParams) => {
                setStatusId(e.value);
              }}
              className={classNames({
                sidebar_filter_active: statusId,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="filter_roles">{t('Roles')}</label>
            <MultiSelect
              inputId="filter_roles"
              value={roles}
              options={rolesOptions}
              maxSelectedLabels={0}
              selectedItemsLabel={t('{0} selected')}
              filter
              showClear
              onChange={(e) => {
                setRoles(e.target.value);
              }}
              className={classNames({
                sidebar_filter_active: roles,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="filter_native_hub">{t('Native warehouse')}</label>
            <Dropdown
              inputId="filter_native_hub"
              value={nativeHub}
              showClear
              options={hubOptions}
              onChange={(e: DropdownChangeParams) => {
                setNativeHub(e.value);
              }}
              className={classNames({
                sidebar_filter_active: nativeHub,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="filter_hubs">{t('Warehouses')}</label>
            <MultiSelect
              inputId="filter_hubs"
              value={hubs}
              options={hubOptions}
              maxSelectedLabels={0}
              selectedItemsLabel={t('{0} selected')}
              filter
              showClear
              onChange={(e) => {
                setHubs(e.target.value);
              }}
              className={classNames({
                sidebar_filter_active: hubs,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="filter_comment">{t('Comment')}</label>
            <InputText
              id="filter_comment"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              className={classNames({
                sidebar_filter_active: comment,
              })}
            />
          </div>
        </AccordionTab>
      </Accordion>
    ),
    [
      rolesOptions,
      hubOptions,
      hubs,
      name,
      comment,
      nativeHub,
      roles,
      statusId,
      statusOptions,
      lastName,
      basicFiltersActiveFilterCount,
      t,
    ]
  );

  const headerFiltersCount = useMemo(
    () => basicFiltersActiveFilterCount,
    [basicFiltersActiveFilterCount]
  );

  function resetAllFilters() {
    setName('');
    setLastName('');
    setRoles(null);
    setStatusId(null);
    setNativeHub(null);
    setHubs(null);
    setComment('');
  }

  return {
    filters,
    resetAllFilters,
    httpFiltersObj,
    headerFiltersCount,
  };
}

export default useTableFilters;
