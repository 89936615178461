import { useTranslation } from 'react-i18next';

import styles from './Item.module.scss';

function EmptyItem(): JSX.Element {
  const { t } = useTranslation();

  return (
    <li className={styles.item}>
      <i>{t('No actions available')}</i>
    </li>
  );
}

export default EmptyItem;
