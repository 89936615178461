import { useSelector } from 'react-redux';
import { Redirect, RouteProps } from 'react-router-dom';

import { ReduxState } from '../../types/redux';
import SentryRoute from './SentryRoute';

function UnauthRoute({ ...routeProps }: RouteProps): JSX.Element {
  const isUserLoggedIn = useSelector<ReduxState>(
    (state) => state.user.isLoggedIn
  );

  return isUserLoggedIn ? <Redirect to="/" /> : <SentryRoute {...routeProps} />;
}

export default UnauthRoute;
