import { Dropdown } from 'primereact/dropdown';
import { ToggleButton } from 'primereact/togglebutton';
import { Tree } from 'primereact/tree';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { functionalities } from '../roles';

function Roles() {
  const [expandedKeys, setExpandedKeys] = useState({});
  const [groupBy, setGroupBy] = useState('role');

  const [areExpanded, setAreExpanded] = useState(false);
  const { t } = useTranslation();

  const allRoles = useMemo(() => {
    return [
      { key: 'Admin', name: t('Admin') },
      { key: 'Cash Desk', name: t('Cash Desk') },
      { key: 'Courier', name: t('Courier') },
      { key: 'Warehouse', name: t('Warehouse') },
      { key: 'Contact Center', name: t('Contact Center') },
      { key: 'Sales & Marketing', name: t('Sales & Marketing') },
      { key: 'Transport (Courier)', name: t('Transport (Courier)') },
      { key: 'Finance', name: t('Finance') },
    ];
  }, [t]);

  const allCategories = useMemo(() => {
    return [
      { key: 'Clients', name: t('Clients') },
      { key: 'Employees', name: t('Employees') },
      { key: 'Parcel Map', name: t('Parcel Map') },
      { key: 'Courier Map', name: t('Courier Map') },
      { key: 'Regions', name: t('Regions') },
      { key: 'Device Management', name: t('Device Management') },
      { key: 'List Types', name: t('Batch Order Templates') },
      { key: 'Imported Lists', name: t('Imported Lists') },
      {
        key: 'Shipment Pickup Assignment',
        name: t('Shipment Pickup Assignment'),
      },
      { key: 'Courier Liabilities', name: t('Courier Liabilities') },
      { key: 'Warehouse', name: t('Warehouse') },
      { key: 'OAuth Clients', name: t('OAuth Clients') },
      { key: 'Orders', name: t('Orders') },
      { key: 'Deleted Orders', name: t('Deleted Orders') },
    ];
  }, [t]);

  const groupByOptions = useMemo(
    () => [
      { label: t('Role'), value: 'role' },
      { label: t('Category'), value: 'category' },
    ],
    [t]
  );

  const displayData = useMemo(() => {
    let allFeatures = [...functionalities];

    if (groupBy === 'role') {
      let groupedByRole = [];

      allRoles.forEach((role) => {
        let groupedArray = [];

        allFeatures.forEach((f) => {
          if (f.roles.includes(role.key)) {
            groupedArray.push({
              ...f,
              key: `${role.key}-${f.id}`,
              label: f.name,
            });
          }
        });

        groupedByRole = [
          ...groupedByRole,
          { key: role.key, label: role.name, children: groupedArray },
        ];
      });

      return groupedByRole;
    }

    if (groupBy === 'category') {
      let groupedByCategory = [];

      allCategories.forEach((category) => {
        let groupedArray = [];

        allFeatures.forEach((f) => {
          if (f.category === category.key) {
            groupedArray.push({
              ...f,
              key: `${category.key}-${f.id}`,
              label: f.name,
            });
          }
        });

        groupedByCategory = [
          ...groupedByCategory,
          { key: category.key, label: category.name, children: groupedArray },
        ];
      });

      return groupedByCategory;
    }
  }, [allCategories, allRoles, groupBy]);

  function toggleNodes(value) {
    if (value) {
      expandAll();
    } else {
      collapseAll();
    }
  }

  function expandAll() {
    let _expandedKeys = {};
    for (let node of displayData) {
      expandNode(node, _expandedKeys);
    }

    setExpandedKeys(_expandedKeys);
  }

  function collapseAll() {
    setExpandedKeys({});
  }

  function expandNode(node, _expandedKeys) {
    if (node.children && node.children.length) {
      _expandedKeys[node.key] = true;

      for (let child of node.children) {
        expandNode(child, _expandedKeys);
      }
    }
  }

  return (
    <>
      <div className="p-d-flex p-jc-between p-ai-baseline p-mb-3">
        <div className="p-d-flex p-ai-baseline">
          <label htmlFor="group_by">{t('Group by')}</label>

          <Dropdown
            id="group_by"
            value={groupBy}
            options={groupByOptions}
            onChange={(e) => setGroupBy(e.value)}
            placeholder={t('Group by')}
            style={{ width: '110px', marginLeft: '8px' }}
          />
        </div>

        <div className="p-d-flex ">
          <ToggleButton
            offLabel={t('Expand All')}
            onLabel={t('Collapse All')}
            offIcon="pi pi-plus"
            onIcon="pi pi-minus"
            checked={areExpanded}
            onChange={(e) => {
              setAreExpanded(e.value);
              toggleNodes(e.value);
            }}
          />
        </div>
      </div>

      <Tree
        value={displayData}
        expandedKeys={expandedKeys}
        onToggle={(e) => {
          setExpandedKeys(e.value);
        }}
      />
    </>
  );
}

export default Roles;
