import { RefObject, useEffect, useState } from 'react';

/**
 * @param ref root table element (.react-table)
 */
function useVirtualizedReactTableHeight(ref: RefObject<HTMLElement>): number {
  const [height, setHeight] = useState(500);

  useEffect(() => {
    function calculate(): void {
      if (!ref?.current) {
        return;
      }

      const tableContainerOffsetTop =
        ref.current.querySelector<HTMLElement>('.table-container')?.offsetTop ??
        0;

      const additionalHeight = Array.from<HTMLElement>(
        ref.current.querySelectorAll('.table-footer')
      ).reduce((acc, currentVal) => acc + currentVal.offsetHeight, 0);

      setHeight(
        window.innerHeight >= 576
          ? window.innerHeight - tableContainerOffsetTop - additionalHeight
          : 768
      );
    }

    calculate();
    window.addEventListener('resize', calculate);

    return () => {
      window.removeEventListener('resize', calculate);
    };
  }, [ref]);

  return height;
}

export default useVirtualizedReactTableHeight;
