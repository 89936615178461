import { TFunction } from 'react-i18next';
import * as Yup from 'yup';

import { AddPriceResourceRequestPayload } from '../../../../../types/api/prices';
import { yupRequiredField } from '../../../../../utils/helpers/yup';
import { FormFields } from './AddDialog.types';

export const generalInfoTabFields: (keyof FormFields)[] = [
  'price_list_id',
  'tariff_id',
];

export const priceTabFields: (keyof FormFields)[] = ['price'];

export function getDefaultValues(): FormFields {
  return {
    tariff_id: null,
    tariff_name: '',
    price_list_id: null,
    price_list_name: '',
    price: '',
  };
}

export function toApiData(values: FormFields): AddPriceResourceRequestPayload {
  return {
    tariff_id: values.tariff_id!,
    price_list_id: values.price_list_id!,
    price: values.price,
  };
}

export function getValidationSchema(
  t: TFunction
): Yup.SchemaOf<Omit<FormFields, 'tariff_name' | 'price_list_name'>> {
  return Yup.object().shape({
    tariff_id: Yup.mixed().required(yupRequiredField(t, t('Tariff'))),
    price_list_id: Yup.mixed().required(yupRequiredField(t, t('Price list'))),
    price: Yup.string().required(yupRequiredField(t, t('Price'))),
  });
}
