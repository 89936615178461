import { faCheckSquare, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Column, ColumnProps } from 'primereact/column';
import { TFunction } from 'react-i18next';

import { emptyCell } from '../../../../../../utils/constants/tables';
import { dateCell } from '../../../../../../utils/helpers/dataTable';
import { currencyFormat } from '../../../../../../utils/helpers/formatting';

type PaymentEntity = {
  id: number;
  audit_id: number;
  date: string;
  action: 'C' | 'M' | 'D';
  klient_ime: string;
  opis: string;
  gotovina: string;
  kolicina: string;
  ediznos: string;
  iznos: string;
  autocalculated: string | null;
  plateno: string | null;
};

export type ApiData = PaymentEntity[];

function getHeadersMap(t: TFunction) {
  return {
    date: t('Date'),
    action: t('Action'),
    klient_ime: t('Client'),
    opis: t('Tariff'),
    gotovina: t('Cash'),
    kolicina: t('Quantity'),
    ediznos: t('Unit price'),
    iznos: t('Price'),
    autocalculated: t('Auto-calculated'),
    plateno: t('Paid'),
  };
}

export function getTableColumns(t: TFunction): JSX.Element[] {
  const headersMap = getHeadersMap(t);

  return Object.keys(headersMap).map<JSX.Element>((k) => (
    <Column
      key={k}
      field={k}
      header={headersMap[k as keyof typeof headersMap]}
      body={(rowData) =>
        (typeof rowData[k] === 'string' ? rowData[k].trim() : rowData[k]) ||
        emptyCell
      }
      {...additionalColumnProperties(t, k as keyof typeof headersMap)}
    />
  ));
}

function additionalColumnProperties(
  t: TFunction,
  column: keyof ReturnType<typeof getHeadersMap>
): Partial<ColumnProps> {
  switch (column) {
    case 'date':
      return {
        body: (row) => dateCell(row[column]),
        style: {
          width: 180,
        },
        bodyStyle: {
          width: 180,
        },
      };

    case 'action':
      return {
        body: (row) => {
          const value = row[column] as PaymentEntity['action'];

          if (value === 'C') {
            return t('Creation');
          } else if (value === 'D') {
            return t('Deletion');
          } else {
            return t('Modification');
          }
        },
        style: {
          width: 140,
        },
        bodyStyle: {
          width: 140,
        },
      };

    case 'klient_ime':
    case 'opis':
      return {
        style: {
          width: 250,
        },
        bodyStyle: {
          width: 250,
        },
      };

    case 'gotovina':
    case 'plateno':
      return {
        body: (row) =>
          !!parseInt(row[column]) ? (
            <FontAwesomeIcon icon={faCheckSquare} />
          ) : (
            <FontAwesomeIcon icon={faTimes} />
          ),
        style: {
          width: 120,
        },
        bodyStyle: {
          width: 120,
          textAlign: 'center',
        },
      };

    case 'autocalculated':
      return {
        body: (row) =>
          !!parseInt(row[column]) ? (
            <FontAwesomeIcon icon={faCheckSquare} />
          ) : (
            <FontAwesomeIcon icon={faTimes} />
          ),
        style: {
          width: 250,
        },
        bodyStyle: {
          width: 250,
          textAlign: 'center',
        },
      };

    case 'kolicina':
      return {
        style: {
          width: 120,
        },
        bodyStyle: {
          width: 120,
          textAlign: 'center',
        },
      };

    case 'iznos':
    case 'ediznos':
      return {
        body: (row) => currencyFormat(row[column], { showCurrency: true }),
        style: {
          width: 140,
        },
        bodyStyle: {
          width: 140,
        },
      };

    default:
      return {};
  }
}
