export const invoiceCollectionGuard = 'GET /invoices';
export const invoiceResourceGuard = 'GET /invoices/d+';
export const invoiceShipmentCollectionGuard = 'GET /invoices/d+/shipments';

export const createInvoiceResourceGuard = 'POST /invoices';
export const updateInvoiceResourceGuard = 'PUT /invoices/d+';

export const bulkApproveInvoicesGuard = 'PUT /invoices/approve';
export const bulkPayInvoicesGuard = 'PUT /invoices/set-payed';
export const bulkReverseInvoicesGuard = 'PUT /invoices/reverse';
export const bulkRecreateInvoicesGuard = 'PUT /invoices/recreate';
export const bulkEmailInvoicesGuard = 'PUT /invoices/email';

// Candidates
export const invoiceCandidatesItemCollectionGuard =
  'GET /invoices/candidates/items';
export const invoiceCandidatesShipmentCollectionGuard =
  'GET /invoices/candidates/shipments';
export const invoiceCandidatesCustomerCollectionGuard =
  'GET /invoices/candidates/customers';
