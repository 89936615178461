import { EmployeeResource } from '../../types/api/employees';
import { UserLoginResource } from '../../types/api/users';
import { processUser } from '../../utils/helpers/redux';

export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const REFRESH_AUTH_TOKEN = 'REFRESH_AUTH_TOKEN';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_EMPLOYEE_RESOURCE = 'UPDATE_EMPLOYEE_RESOURCE';

export function loginUser(
  data: UserLoginResource,
  rememberMe: boolean = false,
  timestamp: number
) {
  return {
    type: LOGIN_USER,
    data: processUser(data),
    rememberMe,
    timestamp,
  };
}

export function logoutUser() {
  return { type: LOGOUT_USER };
}

export function updateUser(data: UserLoginResource) {
  return { type: UPDATE_USER, data: processUser(data) };
}

export function refreshAuthToken(data: UserLoginResource) {
  return { type: REFRESH_AUTH_TOKEN, data: processUser(data) };
}

export function updateEmployeeResource(data: EmployeeResource) {
  return { type: UPDATE_EMPLOYEE_RESOURCE, data };
}
