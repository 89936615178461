import { clientLookupCollectionGuard } from './_api/clients';
import {
  clientOrderCollectionGuard,
  clientOrderResourceGuard,
} from './_api/reports';

export const clientFilter = clientLookupCollectionGuard;

const reportResource = clientOrderResourceGuard;
export const routeGuard = [clientOrderCollectionGuard, reportResource];
