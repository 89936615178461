import './ViewDialog.scss';

import { useTranslation } from 'react-i18next';
import { MapContainer, Marker, TileLayer, Tooltip } from 'react-leaflet';

import { defaultTileLayerProvider } from '../../../../../../utils/constants/map';

function GeoLocation({ data }) {
  const { t } = useTranslation();

  return (
    <>
      {data?.location ? (
        <MapContainer
          zoom={15}
          center={data.location}
          dragging={false}
          boxZoom={false}
          doubleClickZoom={false}
          keyboard={false}
          scrollWheelZoom={false}
          touchZoom={false}
          className="map"
        >
          <TileLayer url={defaultTileLayerProvider} />

          <Marker position={[data.location.lat, data.location.lng]}>
            <Tooltip>{data.name}</Tooltip>
          </Marker>
        </MapContainer>
      ) : (
        <div className="no-location-available">
          <i>{t('This warehouse has no location defined')}</i>
        </div>
      )}
    </>
  );
}

export default GeoLocation;
