import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import ToastContext from '../../../../../context/ToastContext';
import useAxiosHook from '../../../../../hooks/useAxiosHook';
import useMediaQuery from '../../../../../hooks/useMediaQuery';
import useToastMessage from '../../../../../hooks/useToastMessage';
import {
  CreatePriceListResourceRequestPayload,
  PriceListResource,
  UpdatePriceListResourceRequestPayload,
} from '../../../../../types/api/priceLists';
import { LabelValue } from '../../../../../types/options';
import { EntityIdRouteParams } from '../../../../../types/routing';
import { sequential } from '../../../../../utils/helpers/functions';
import { infoToast } from '../../../../../utils/helpers/primereact';
import DialogSpinner from '../../../../Dialogs/DialogSpinner/DialogSpinner';
import FormContainer from './FormContainer';

type Props = {
  isEditDialog: boolean;
  isShown: boolean;
  onHide: () => void;
  reloadCollection: () => void;
  parentOptions: LabelValue[];
};

function CreateEditDialog({
  isEditDialog,
  isShown,
  onHide,
  reloadCollection,
  parentOptions,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const { id } = useParams<EntityIdRouteParams>();

  const { toastRef } = useContext(ToastContext);

  const {
    data: priceListData,
    error: priceListError,
    isLoading: isPriceListLoading,
  } = useAxiosHook<PriceListResource>(`/price-lists/${id}`, {
    skipWhen: !isShown || !isEditDialog,
  });

  useToastMessage(undefined, priceListError, {
    error: {
      summary: t('An error occured while reading price list data.'),
      callback: onHide,
    },
  });

  const {
    data: createData,
    error: createError,
    isLoading: isCreateLoading,
    reload: createReload,
  } = useAxiosHook(
    {
      url: '/price-lists',
      method: 'POST',
    },
    {
      skipWhen: true,
    }
  );

  useToastMessage(createData, createError, {
    success: {
      summary: t('The price list has been created successfully.'),
      callback: () => sequential(onHide, reloadCollection),
    },
    error: {
      summary: t('An error occured while creating price list.'),
      callback: onHide,
    },
  });

  const {
    data: editData,
    error: editError,
    isLoading: isEditLoading,
    reload: editReload,
  } = useAxiosHook(
    {
      url: `/price-lists/${id}`,
      method: 'PUT',
    },
    {
      skipWhen: true,
    }
  );

  useToastMessage(editData, editError, {
    success: {
      summary: t('The price list has been edited successfully.'),
      callback: () => sequential(onHide, reloadCollection),
    },
    error: {
      summary: t('An error occured while editing the price list.'),
      callback: onHide,
    },
  });

  function handleCreation(data: CreatePriceListResourceRequestPayload) {
    createReload({ data });
  }

  function handleEditing(data: UpdatePriceListResourceRequestPayload) {
    if (!Object.keys(data).length) {
      // If there are no changes introduced, don't contaminate the API
      infoToast(
        toastRef,
        t('No changes made'),
        t("You haven't made any changes yet.")
      );

      return;
    }

    editReload({ data });
  }

  const dialogFooter = isPriceListLoading ? (
    <></>
  ) : (
    <>
      <Button
        type="button"
        label={t('Close')}
        onClick={() => onHide()}
        className="p-button-text"
      />

      <Button
        type="submit"
        form="price-lists-create-edit-form"
        label={
          isEditDialog
            ? isEditLoading
              ? t('Updating...')
              : t('Update')
            : isCreateLoading
            ? t('Creating...')
            : t('Create')
        }
        disabled={isPriceListLoading || isCreateLoading || isEditLoading}
      />
    </>
  );

  const header = isEditDialog
    ? isPriceListLoading
      ? t('Loading...')
      : t('Editing {{name}}', {
          name: priceListData?.name ?? '',
        })
    : t('Create a price list');

  const isOnMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Dialog
      header={header}
      footer={dialogFooter}
      visible={isShown}
      resizable={false}
      maximizable={isOnMobile}
      maximized={isOnMobile}
      onHide={onHide}
      style={{ width: 580, maxWidth: '100%' }}
    >
      {isPriceListLoading ? (
        <DialogSpinner />
      ) : (
        <FormContainer
          isEditDialog={isEditDialog}
          priceListData={priceListData}
          parentOptions={parentOptions}
          handleCreation={handleCreation}
          handleEditing={handleEditing}
        />
      )}
    </Dialog>
  );
}

export default CreateEditDialog;
