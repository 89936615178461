import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, useFormikContext } from 'formik';
import _ from 'lodash';
import { Button } from 'primereact/button';
import { Checkbox, CheckboxChangeParams } from 'primereact/checkbox';
import { Dropdown, DropdownChangeParams } from 'primereact/dropdown';
import { Panel } from 'primereact/panel';
import { TabPanel, TabView, TabViewTabChangeParams } from 'primereact/tabview';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-lodash-debounce';

import { Product } from '../../../../../enums/product';
import useAxiosHook from '../../../../../hooks/useAxiosHook';
import usePrevious from '../../../../../hooks/usePrevious';
import { HubCollection } from '../../../../../types/api/hubs';
import { PlaceResource } from '../../../../../types/api/places';
import { invalidTabHeaderIcon } from '../../../../../utils/globals';
import { cleanUpObject } from '../../../../../utils/helpers/object';
import { SinglePayment, toCalculatorData } from './Calculator.functions';
import {
  ConstantFields,
  FormValues,
  OrdererType,
  attributesTabFields,
  ordererTabFields,
  recipientTabFields,
  senderTabFields,
} from './CreateEditDialog.functions';
import AttributesTab from './Tabs/Attributes/AttributesTab';
import OrdererTab from './Tabs/OrdererTab';
import PaymentsTab from './Tabs/PaymentsTab';
import { PickupAssignmentTab } from './Tabs/PickupAssignmentTab';
import RecipientTab from './Tabs/RecipientTab';
import SenderTab from './Tabs/SenderTab';

type Props = {
  initialValues: FormValues;
  constantFields: ConstantFields;
  isEditing: boolean;
  isTabs: boolean;
  isWarehouseReception?: boolean;
  orderIndex?: number;
  isSenderTabDisabled?: boolean;
};

function DialogContainer({
  initialValues,
  constantFields,
  isEditing,
  isTabs,
  isWarehouseReception,
  orderIndex = 0,
  isSenderTabDisabled = false,
}: Props): JSX.Element {
  const [active, setActive] = useState<OrdererType>('sender');
  const [activeTab, setActiveTab] = useState(0);

  const { t } = useTranslation();

  const { values, errors, setFieldValue, touched } =
    useFormikContext<FormValues>();

  const calculatorPayloadData = useMemo(
    () => cleanUpObject(toCalculatorData(values)),
    [values]
  );

  const isOrdererDisplayed = values?.orderer === 3;

  const { data: hubsData } = useAxiosHook<HubCollection>('/hubs');

  const initialCalculatorData = useMemo(
    () => cleanUpObject(toCalculatorData(initialValues)),
    [initialValues]
  );

  const isPaymentsEqualToItsDefaultValue = useMemo(() => {
    if (!isEditing) {
      return false;
    }

    return _.isEqual(calculatorPayloadData, initialCalculatorData);
  }, [calculatorPayloadData, initialCalculatorData, isEditing]);

  const { data: calculatorData, isLoading: isCalculatorDataLoading } =
    useAxiosHook<SinglePayment[]>(
      {
        url: '/orders/calculator',
        method: 'POST',
        data: calculatorPayloadData,
      },
      {
        skipWhen:
          !values?.mesto_do_ime ||
          !values?.mesto_od_ime ||
          isPaymentsEqualToItsDefaultValue,
      }
    );

  const previousCalculatorData = usePrevious(calculatorData);

  useEffect(() => {
    if (!calculatorData || previousCalculatorData === calculatorData) {
      return;
    }

    setFieldValue(
      'payments',
      calculatorData.map((payment) => ({
        ...payment,
        editedPrice: payment.unit_price,
      }))
    );
  }, [calculatorData, previousCalculatorData, setFieldValue]);

  useEffect(() => {
    if (!isPaymentsEqualToItsDefaultValue) {
      return;
    }

    setFieldValue('payments', initialValues.payments);
  }, [initialValues.payments, isPaymentsEqualToItsDefaultValue, setFieldValue]);

  const { data: ordererLocationData } = useAxiosHook<PlaceResource>(
    {
      url: `/places/${values.mesto_naracatel_id}`,
    },
    { skipWhen: !values.mesto_naracatel_id }
  );

  useEffect(() => {
    setFieldValue('mesto_naracatel_ime', ordererLocationData?.ime ?? '');
  }, [ordererLocationData, setFieldValue]);

  const isRecipientPlaceModified =
    initialValues.mesto_do_id !== values.mesto_do_id;

  const debounced_is_place_modified = useDebounce(
    isRecipientPlaceModified,
    500
  );
  const debounced_mesto_do_id = useDebounce(values.mesto_do_id, 500);
  const debounced_mesto_do_ime = useDebounce(values.mesto_do_ime, 500);
  const debounced_adresa_do = useDebounce(values.adresa_do, 500);
  const debounced_broj_do = useDebounce(values.broj_do, 500);

  const { data: nearestRecipientHub, isLoading: isLoadingNearestHub } =
    useAxiosHook<{
      ime: string;
      id: number;
    }>(
      {
        url: '/hubs/nearest',
        data: {
          place_id: debounced_mesto_do_id,
          address_from: `${debounced_adresa_do} ${debounced_broj_do}, ${debounced_mesto_do_ime}`,
        },
        method: 'POST',
      },
      {
        skipWhen:
          !debounced_mesto_do_id ??
          !debounced_is_place_modified ??
          !values.delivery_location_type_id,
      }
    );

  const senderHeader = (
    <div className="p-d-flex p-jc-between p-ai-center">
      <div>{t('Sender')}</div>
      <div className="p-d-flex p-ai-center p-jc-around">
        <label className="p-mr-2 p-text-normal">{t('Sender is orderer')}</label>

        <Field
          as={Checkbox}
          inputId="sender_orderer"
          checked={values?.orderer === 1}
          onChange={(e: CheckboxChangeParams) => {
            if (e.checked) {
              setFieldValue('orderer', 1);
            } else {
              setFieldValue('orderer', 2);
            }
          }}
        />
      </div>
    </div>
  );

  const recipientHeader = (
    <div className="p-d-flex p-jc-between p-ai-center">
      <div>{t('Recipient')}</div>
      <div className="p-d-flex p-ai-center p-jc-around">
        <label className="p-mr-2 p-text-normal">
          {t('Recipient is orderer')}
        </label>
        <Field
          as={Checkbox}
          inputId="recipient_orderer"
          checked={values?.orderer === 2}
          onChange={(e: CheckboxChangeParams) => {
            if (e.checked) {
              setFieldValue('orderer', 2);
            } else {
              setFieldValue('orderer', 1);
            }
          }}
        />
      </div>
    </div>
  );

  const ordererHeader = (
    <div className="p-d-flex p-jc-between p-ai-center">
      <div>{t('Orderer')}</div>
      <div
        className="remove-orderer"
        onClick={() => {
          setFieldValue('orderer', 1);
          setFieldValue('klient_naracatel_id', values.klient_od_id);
        }}
      >
        <FontAwesomeIcon icon={faTimes} />
      </div>
    </div>
  );

  const attributesHeader = (
    <div className="p-d-flex p-jc-between p-ai-center">
      <div>{t('Attributes')}</div>
      <div className="p-d-flex  p-ai-center">
        <div>
          <Field
            as={Dropdown}
            name="proizvod_id"
            inputId="proizvod_id"
            onChange={(e: DropdownChangeParams) => {
              setFieldValue('proizvod_id', e.value);
            }}
            options={[
              { label: t('Package'), value: Product.Package },
              { label: t('Letter'), value: Product.Letter },
            ]}
          />
        </div>
      </div>
    </div>
  );

  function isTabValid(TabObject: Partial<FormValues>) {
    for (let key in TabObject) {
      if (errors[key as keyof FormValues] && touched[key as keyof FormValues]) {
        return false;
      }
    }
    return true;
  }

  const senderTabValid = isTabValid(senderTabFields);
  const recipientTabValid = isTabValid(recipientTabFields);
  const ordererTabValid = isTabValid(ordererTabFields);
  const attributesTabValid = isTabValid(attributesTabFields);

  const senderTabIcon = senderTabValid ? {} : invalidTabHeaderIcon;
  const recipientTabIcon = recipientTabValid ? {} : invalidTabHeaderIcon;
  const ordererTabIcon = ordererTabValid ? {} : invalidTabHeaderIcon;
  const attributesTabIcon = attributesTabValid ? {} : invalidTabHeaderIcon;

  return (
    <>
      {isTabs ? (
        <TabView
          className={
            Object.keys(errors).some((k) => Object.keys(touched).includes(k))
              ? t('invalid')
              : ''
          }
          activeIndex={activeTab}
          onTabChange={(e: TabViewTabChangeParams) => {
            setActiveTab(e.index);
          }}
        >
          <TabPanel
            header={t('Sender')}
            headerClassName="data-cy-sender-tab"
            {...senderTabIcon}
          >
            <SenderTab
              isOrderLoading
              hubs={hubsData}
              isConstant={constantFields.isSenderConstant}
              orderIndex={orderIndex}
              disabled={isSenderTabDisabled}
            />
          </TabPanel>

          <TabPanel
            header={t('Recipient')}
            headerClassName="data-cy-recipient-tab"
            {...recipientTabIcon}
          >
            <RecipientTab
              initialValues={initialValues}
              hubs={hubsData}
              isConstant={constantFields.isRecipientConstant}
              orderIndex={orderIndex}
              nearestRecipientHub={nearestRecipientHub}
              isNearestRecipientHubLoading={isLoadingNearestHub}
            />
          </TabPanel>

          <TabPanel
            headerStyle={isOrdererDisplayed ? {} : { display: 'none' }}
            header={t('Orderer')}
            headerClassName="data-cy-orderer-tab"
            {...ordererTabIcon}
          >
            <OrdererTab
              isConstant={constantFields.isOrdererConstant}
              orderIndex={orderIndex}
            />
          </TabPanel>

          <TabPanel
            header={t('Attributes')}
            headerClassName="data-cy-attributes-tab"
          >
            <AttributesTab isTabs={isTabs} {...attributesTabIcon} />
            {!isEditing && (
              <div className="pickup-assignment">
                <PickupAssignmentTab initialValues={initialValues} />
              </div>
            )}
            <PaymentsTab isLoading={isCalculatorDataLoading} />
          </TabPanel>
        </TabView>
      ) : (
        <div className="p-d-flex p-flex-wrap">
          <div className={`tabs ${isOrdererDisplayed && `${active}-active`}`}>
            <div className="sender" onClick={() => setActive('sender')}>
              <div className="tab">
                <Panel header={senderHeader}>
                  <SenderTab
                    isWarehouseReception={isWarehouseReception}
                    isOrderLoading
                    hubs={hubsData}
                    isConstant={constantFields.isSenderConstant}
                    orderIndex={orderIndex}
                    disabled={isSenderTabDisabled}
                  />
                </Panel>

                <Panel header={recipientHeader}>
                  <RecipientTab
                    initialValues={initialValues}
                    isConstant={constantFields.isRecipientConstant}
                    orderIndex={orderIndex}
                    hubs={hubsData}
                    nearestRecipientHub={nearestRecipientHub}
                    isNearestRecipientHubLoading={isLoadingNearestHub}
                  />
                </Panel>
              </div>
            </div>
            <div onClick={() => setActive('orderer')} className="orderer">
              <Panel
                header={ordererHeader}
                className={'tab ' + (!isOrdererDisplayed && ' p-d-none')}
              >
                <OrdererTab
                  isConstant={constantFields.isOrdererConstant}
                  orderIndex={orderIndex}
                />
              </Panel>
              <Button
                type="button"
                label={t('Third party orderer')}
                icon="fas fa-plus"
                iconPos="left"
                onClick={() => {
                  setFieldValue('orderer', 3);
                  setFieldValue('klient_naracatel_id', '3');
                  setActive('orderer');
                }}
                className={
                  'toggle-orderer ' + (isOrdererDisplayed && ' p-d-none')
                }
              />
            </div>
          </div>

          <div className="attributes">
            <Panel header={attributesHeader}>
              <AttributesTab
                isConstant={constantFields.isAttributesConstant}
                orderIndex={orderIndex}
              />
            </Panel>
          </div>

          {!isEditing && (
            <div className="pickup-assignment">
              <PickupAssignmentTab initialValues={initialValues} />
            </div>
          )}

          <div className="payments">
            <PaymentsTab isLoading={isCalculatorDataLoading} />
          </div>
        </div>
      )}
    </>
  );
}

export default DialogContainer;
