import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import ToastContext from '../../../context/ToastContext';
import { FileTypes } from '../../../enums/files';
import useAxiosHook from '../../../hooks/useAxiosHook';
import { useEndpointGuard } from '../../../hooks/useEndpointGuard';
import useMediaQuery from '../../../hooks/useMediaQuery';
import usePageTitle from '../../../hooks/usePageTitle';
import useTableColumns from '../../../hooks/useTableColumns';
import useTableState from '../../../hooks/useTableState';
import * as couriersCashGuards from '../../../utils/constants/auth/couriersCash';
import { downloadFile, getFileName } from '../../../utils/helpers/files';
import { httpDateFormat } from '../../../utils/helpers/formatting';
import { queryString } from '../../../utils/helpers/http';
import { tryInt } from '../../../utils/helpers/parse';
import {
  getSearchQueryParam,
  tryDateSearchParam,
} from '../../../utils/helpers/searchQuery';
import Table from '../../DataTable/Table/Table';
import Grid from '../../Grid/Grid';
import MainContent from '../../Grid/MainContent';
import SidePanels from '../../Grid/SidePanels';
import HeaderPages from '../Components/HeaderPages/HeaderPages';
import {
  CouriersCashData,
  additionalColumnOptions,
  getColumnHeadersMap,
  tableStorageKey,
} from './CouriersCash.functions';
import Filters from './Sidebar/Filters';
import Stats from './Sidebar/Stats';

function CouriersCash(): JSX.Element {
  const { t } = useTranslation();

  const courierFilterGuard = useEndpointGuard(couriersCashGuards.courierFilter);

  const columnHeadersMap = getColumnHeadersMap(t);

  const location = useLocation();

  usePageTitle(t("Courier's Cash"));

  const { bottomRightToastRef } = useContext(ToastContext);

  // Filters state
  const [date, setDate] = useState<Date>(
    () =>
      tryDateSearchParam(getSearchQueryParam(location.search, 'date') ?? '') ??
      new Date()
  );
  const [courier, setCourier] = useState<number | null>(() =>
    courierFilterGuard
      ? tryInt(getSearchQueryParam(location.search, 'courier_id')) ?? null
      : null
  );

  const {
    tableRef,
    page,
    setPage,
    limit,
    setLimit,
    sortField,
    sortOrder,
    selection,
    setSortField,
    setSortOrder,
    setSelection,
  } = useTableState(tableStorageKey);

  const { columns, columnOptions, selectedColumns, setSelectedColumns } =
    useTableColumns(
      page,
      limit,
      'couriersCash',
      columnHeadersMap,
      columnHeadersMap,
      additionalColumnOptions
    );

  const isTwoColumnLayout = useMediaQuery('(min-width: 769px)');

  // HTTP requests
  const {
    data: allData,
    error: allError,
    isLoading: isAlLLoading,
    reload: allReload,
  } = useAxiosHook<CouriersCashData[]>(
    '/couriers-cash/shipments' +
      queryString({
        date: httpDateFormat(date),
      })
  );

  const { data, error, isLoading, reload } = useAxiosHook<CouriersCashData[]>(
    '/couriers-cash/shipments' +
      queryString({
        date: httpDateFormat(date),
        courier_id: courier,
      }),
    { skipWhen: !courier }
  );

  // Fetch couriers
  const couriersList = useAxiosHook<{ id: number; name: string }[]>(
    '/couriers-cash/shipments/employees' +
      queryString({ date: httpDateFormat(date) }),
    { skipWhen: !courierFilterGuard }
  );

  // Reset courier on date change
  useEffect(() => {
    setCourier(null);
  }, [date]);

  useEffect(() => {
    if (!courierFilterGuard) {
      setCourier(null);
    }
  }, [courierFilterGuard]);

  // Handle export to excel
  function handleExportToExcel() {
    downloadFile(
      `${process.env.REACT_APP_REPORT_URL}/couriers-cash-shipments` +
        queryString({
          date: httpDateFormat(date),
          courier_id: courier,
        }),
      getFileName(t('CouriersCash'), undefined, true),
      FileTypes.XLSX,
      bottomRightToastRef
    );
  }

  return (
    <div className="page couriers-cash">
      <HeaderPages>
        <div>
          <h2 className="title">{t("Courier's Cash")}</h2>
          <p className="subtitle">{t('Preview shipments that contain cash')}</p>
        </div>
        <div></div>
      </HeaderPages>

      <Grid>
        <SidePanels columnLayout={isTwoColumnLayout}>
          <Filters
            date={date}
            courier={courier}
            request={couriersList}
            setDate={setDate}
            setCourier={setCourier}
          />
          <Stats
            date={date}
            courier={couriersList.data?.find((item) => item.id === courier)}
            allData={allData}
            courierData={data}
          />
        </SidePanels>

        <MainContent columnLayout={isTwoColumnLayout}>
          <Table
            tableRef={tableRef}
            columns={columns}
            data={courier ? data : allData}
            isLoading={courier ? isLoading : isAlLLoading}
            hasError={!!error || !!allError}
            reload={courier ? reload : allReload}
            setPage={setPage}
            setLimit={setLimit}
            sortField={sortField}
            rows={limit}
            setSortField={setSortField}
            setSortOrder={setSortOrder}
            setSelection={setSelection}
            sortOrder={sortOrder}
            selection={selection}
            storageString={tableStorageKey}
            rebuildTooltip
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            columnOptions={columnOptions}
            lazy={false}
            headerTitle={t('Shipments With Cash Payment')}
            exportToExcelButton
            onExportToExcelButtonClick={handleExportToExcel}
          />
        </MainContent>
      </Grid>
    </div>
  );
}

export default CouriersCash;
