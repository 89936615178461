import { Numeric } from '../../types/general';

export function formDataFromObject(
  obj: Record<string, string | number | Numeric[] | Blob>
): FormData {
  const formData = new FormData();

  const keys: string[] = Object.keys(obj).filter(
    (key) => obj[key] !== undefined
  );

  for (const key of keys) {
    const value = obj[key];

    if (Array.isArray(value)) {
      for (const subVal of value) {
        formData.append(`${key}[]`, String(subVal));
      }
    } else {
      formData.append(key, String(value));
    }
  }

  return formData;
}
