import './ViewParcelsDialog.scss';

import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

import ToastContext from '../../../../../../context/ToastContext';
import useAxiosHook from '../../../../../../hooks/useAxiosHook';
import useMediaQuery from '../../../../../../hooks/useMediaQuery';
import usePrevious from '../../../../../../hooks/usePrevious';
import useTableColumns from '../../../../../../hooks/useTableColumns';
import useTableScrollHeight from '../../../../../../hooks/useTableScrollHeight';
import useTableState from '../../../../../../hooks/useTableState';
import { getDataTableProps } from '../../../../../../utils/globals';
import { currencyFormat } from '../../../../../../utils/helpers/formatting';
import { errorToast } from '../../../../../../utils/helpers/primereact';
import DialogSpinner from '../../../../../Dialogs/DialogSpinner/DialogSpinner';
import {
  SingleWarrantList,
  WarrantParcel,
  additionalParcelsColumnProperties,
  getParcelsColumnHeadersMap,
  tableStorageKey,
} from '../../Warrants.functions';

type Props = {
  isShown: boolean;
  warrant: SingleWarrantList | undefined;
  isWarrantLoading: boolean;
  onHide: () => void;
};

function ViewParcelsDialog({
  isShown,
  warrant,
  isWarrantLoading,
  onHide,
}: Props) {
  const { t } = useTranslation();

  const dataTableProps = useMemo(() => getDataTableProps(t), [t]);

  const parcelsColumnHeadersMap = useMemo(
    () => getParcelsColumnHeadersMap(t),
    [t]
  );

  const { toastRef } = useContext(ToastContext);

  const { data, isLoading, error } = useAxiosHook<WarrantParcel[]>(
    `/cashondeliveries/${warrant?.id}/parcels`,
    {
      skipWhen: !isShown || isWarrantLoading || !warrant,
    }
  );

  const prevError = usePrevious(error);

  const { tableRef, page, limit } = useTableState(tableStorageKey);

  const { columns } = useTableColumns(
    page,
    limit,
    'cod_viewParcelsDialog',
    parcelsColumnHeadersMap,
    parcelsColumnHeadersMap,
    (column) =>
      additionalParcelsColumnProperties(
        column as keyof typeof parcelsColumnHeadersMap
      )
  );

  const scrollHeight = useTableScrollHeight(tableRef);

  const tableFooter = useMemo(() => {
    const length = data ? Object.keys(data).length : 0;

    return length > 0 ? (
      <div className="text-right">
        <span>
          {t('Total')}: {length} {length === 1 ? t('parcel') : t('parcels')}
        </span>
      </div>
    ) : null;
  }, [t, data]);

  useEffect(() => {
    if (!error || error === prevError) {
      return;
    }

    errorToast(
      toastRef,
      t('Error'),
      t('An error occured while reading parcel data.')
    );
  }, [t, error, prevError, toastRef]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [data]);

  const isOnMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Dialog
      visible={isShown}
      onHide={onHide}
      header={
        isWarrantLoading
          ? t('Loading...')
          : t('{{serialNumber}} Parcels', {
              serialNumber: warrant?.adresnica_seriski,
            })
      }
      maximizable={!isLoading}
      maximized={isOnMobile}
      resizable={false}
      style={{ width: isLoading ? '480px' : '90%', maxWidth: '100%' }}
      className="cod-view-parcels-dialog"
    >
      {isWarrantLoading || isLoading ? (
        <DialogSpinner />
      ) : (
        <>
          {warrant?.vkupen_otkup && (
            <p className="total-ransom">
              {t('Total redemption')}:{' '}
              <b>
                {warrant?.vkupen_otkup
                  ? currencyFormat(warrant.vkupen_otkup, { showCurrency: true })
                  : ''}
              </b>
            </p>
          )}

          <DataTable
            ref={tableRef}
            footer={tableFooter}
            value={data ?? []}
            scrollable
            scrollHeight={`${scrollHeight}px`}
            loading={isLoading}
            emptyMessage={
              !!error
                ? dataTableProps.emptyMessageError
                : t('The selected list does not contain any parcels')
            }
            className="p-datatable-sm p-datatable-striped"
          >
            {columns}
          </DataTable>
        </>
      )}
    </Dialog>
  );
}
export default ViewParcelsDialog;
