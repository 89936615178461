import { ReactNode } from 'react';

import styles from './Flex.module.scss';

type Props = {
  children: ReactNode;
};

function Sidebar({ children }: Props): JSX.Element {
  return <div className={styles.sidebar}>{children}</div>;
}

export default Sidebar;
