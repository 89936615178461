import * as Sentry from '@sentry/react';
import classNames from 'classnames';
import {
  CSSProperties,
  HTMLProps,
  ReactNode,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import ReactDOM from 'react-dom';

import { getPossiblePosition, getStyle } from './Tooltip.functions';
import styles from './Tooltip.module.scss';
import { Position } from './Tooltip.types';

type Props = {
  text: ReactNode;
  position?: Position;
  isShown?: boolean | '' | undefined;
  children: ReactNode;
} & HTMLProps<HTMLDivElement>;

// Q: Explanation regarding the implementation?
// A: Getting a display:none; element dimensions is not possible until that element becomes visible (display: something;).
//    Hence, style is a state, and not a simple constant, as we need to "force" a rerender after the element becomes visible.
//
// Q: Why useLayoutEffect?
// A: Because we need to calculate the style right before the browser repaints, otherwise the browser updates
//    the scroll height of the page (the element is visible again for a short period of time) and the user ends up with a flashy page.
function Tooltip({
  text,
  position: requestedPosition = 'right',
  isShown = true,
  className,
  children,
  ...other
}: Props): JSX.Element {
  const [isHovering, setIsHovering] = useState(false);
  const [style, setStyle] = useState<CSSProperties | undefined>();

  const parentRef = useRef<HTMLDivElement>(null);
  const tooltipTextRef = useRef<HTMLDivElement>(null);

  const position = getPossiblePosition(
    requestedPosition,
    parentRef,
    tooltipTextRef
  );

  function handleMouseEnter() {
    setIsHovering(true);
  }

  function handleMouseLeave() {
    setIsHovering(false);
  }

  useLayoutEffect(() => {
    setStyle(
      isHovering ? getStyle(position, parentRef, tooltipTextRef) : undefined
    );
  }, [isHovering, position]);

  const tooltipPortalContainer =
    document.getElementById('tooltip-portal-container') || document.body;

  if (tooltipPortalContainer === document.body) {
    Sentry.captureMessage(
      'Tooltip container element not found!',
      Sentry.Severity.Error
    );
  }

  return (
    <div
      ref={parentRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={classNames(styles.tooltip, className)}
      {...other}
    >
      {children}
      {isHovering &&
        isShown &&
        ReactDOM.createPortal(
          <div
            ref={tooltipTextRef}
            className={classNames(
              styles.tooltipText,
              styles[position],
              'p-p-2',
              {
                [styles.visible]: isHovering,
              }
            )}
            style={style}
          >
            {text}
          </div>,
          tooltipPortalContainer
        )}
    </div>
  );
}

export default Tooltip;
