import { Redirect, RouteProps } from 'react-router-dom';

import { isDevEnv } from '../../utils/helpers/misc';
import AuthRoute from './AuthRoute';
import UnauthRoute from './UnauthRoute';

type Props = RouteProps & {
  unauth?: boolean;
};

function DevOnlyRoute({
  unauth = false,
  ...routeProps
}: Props): JSX.Element | null {
  const RouteComponent = unauth ? UnauthRoute : AuthRoute;

  return !isDevEnv() ? <Redirect to="/" /> : <RouteComponent {...routeProps} />;
}

export default DevOnlyRoute;
