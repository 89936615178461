import { faPalette } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import SidebarItem from '../../../../../../Grid/Sidebar/SidebarItem';
import styles from './Legend.module.scss';

function Legend(): JSX.Element {
  const { t } = useTranslation();

  return (
    <SidebarItem title={t('Legend')} icon={faPalette}>
      <div className={styles['legend_container']}>
        <div className={styles['legend_item']}>
          <span className={styles['legend_item__indicator-green']} />
          <span className={styles['legend_item__title']}>
            {t('Is reconciled')}
          </span>
        </div>
        <div className={styles['legend_item']}>
          <span className={styles['legend_item__indicator-blue']} />
          <span className={styles['legend_item__title']}>
            {t('Parcel contains return document')}
          </span>
        </div>
        <div className={styles['legend_item']}>
          <span className={styles['legend_item__indicator-dark-blue']} />
          <span className={styles['legend_item__title']}>
            {t('Parcel is reconciled and contains return document')}
          </span>
        </div>
        <div className={styles['legend_item']}>
          <span className={styles['legend_item__indicator-orange']} />
          <span className={styles['legend_item__title']}>
            {t('Expected to be reconciled')}
          </span>
        </div>
      </div>
    </SidebarItem>
  );
}

export default Legend;
