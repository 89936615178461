import { TFunction } from 'react-i18next';
import * as Yup from 'yup';

import { Country } from '../../../../../enums/countries';
import { getCountryString } from '../../../../../utils/helpers/misc';
import { yupRequiredField } from '../../../../../utils/helpers/yup';

const regPlateRegex: Record<Country, RegExp> = {
  [Country.Macedonia]:
    /^(B([ET])|D([BEHK])|G([EV])|K([AIORPSU])|M([BK])|(NE)|(OH)|P([PES])|R([AE])|S([KNRTU])|(TE)|V([AEIV]))-[0-9]{3,4}-[A-Z]{2}$/i,
  [Country.Serbia]:
    /^(A([LRC])|B([BGOPTĆUČ])|V([ABLPRSŠ])|G([LM])|DE|ĐA|Z([AR])|I([NC])|JA|К([АVGŽILМОCŠ])|L([BEOU])|N([VGIPS])|P([ABEŽZIKNOPRT])|R([AU])|S([AVDJMOPTUC])|Т([ОSТ])|ĆU|U([BER])|ČA|ŠA|ŠI)-[0-9]{3,4}-[A-Z]{2}$/i,
};

export type FormFields = {
  marka_id: number | null;
  model_id: number | null;
  registarska_tablicka: string;
  datum_registracija: string;
  posleden_servis: string;
  komentar: string | null;
  aktivno: boolean;
  volume: number | null;
};

export function getInitialValues(isEditDialog: boolean, data: any) {
  return {
    marka_id: isEditDialog ? data?.marka_id ?? '' : '',
    model_id: isEditDialog ? data?.model_id ?? '' : '',
    registarska_tablicka: isEditDialog ? data?.registarska_tablicka ?? '' : '',
    datum_registracija: isEditDialog ? data?.datum_registracija ?? '' : '',
    posleden_servis: isEditDialog ? data?.posleden_servis ?? '' : '',
    komentar: isEditDialog ? data?.komentar ?? '' : '',
    aktivno: isEditDialog ? !!data.aktivno ?? false : false,
    volume: isEditDialog ? data?.volume ?? '' : '',
  };
}

export function getValidationSchema(t: TFunction) {
  return Yup.object().shape({
    marka_id: Yup.number()
      .required(yupRequiredField(t, t('Brand')))
      .typeError(t('Select a brand')),
    model_id: Yup.number()
      .required(yupRequiredField(t, t('Model')))
      .typeError(t('Select a model')),
    registarska_tablicka: Yup.string()
      .required(yupRequiredField(t, t('Registration Plates')))
      .test({
        name: 'registarska_tablicka',
        test: (value) => !value?.match(/[\u0400-\u04ff]/g),
        message: t(
          'Cyrillic letters are forbidden in this context. Please use only latin letters.'
        ),
      })
      .test({
        name: 'registarska_tablicka',
        test: (value) => !!value?.match(regPlateRegex[getCountryString()]),
        message: t(
          'Use the following format: SK-123-AA or SK-1234-AA. Check that the city code is correct.',
          { nsSeparator: false }
        ),
      }),
    volume: Yup.number()
      .required(yupRequiredField(t, t('Load Capacity')))
      .typeError(t('Load capacity must be a number')),
  });
}
