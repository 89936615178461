import { FieldArray, useFormikContext } from 'formik';
import { Tooltip } from 'primereact/tooltip';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ArrowUp } from '../../../../../../../assets/svg/arrows/arrow-up-1.svg';
import { Product } from '../../../../../../../enums/product';
import { Service } from '../../../../../../../enums/service';
import { UseAxiosReturn } from '../../../../../../../hooks/useAxiosHook';
import { ProductServiceCollection } from '../../../../../../../types/api/productServices';
import { FormFields } from '../../CreateEditDialog.types';
import Content from './Content';
import styles from './Discounts.module.scss';
import EmptyContent from './EmptyContent';

type Props = {
  disabled: boolean;
  productServicesCollectionRequest: UseAxiosReturn<ProductServiceCollection>;
};

function Discounts({
  disabled,
  productServicesCollectionRequest,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const { values } = useFormikContext<FormFields>();

  const productServiceMapping = useMemo<[Product, Service][]>(
    () =>
      productServicesCollectionRequest.data?.data.map((ps) => [
        ps.product_id,
        ps.service_id,
      ]) ?? [],
    [productServicesCollectionRequest.data?.data]
  );

  if (!values.popust.length && disabled) {
    return (
      <div>
        <ArrowUp className={styles.arrowUp} />

        <h3 className="p-mb-2">{t('Invoicing is not enabled')}</h3>

        {t('Invoicing must be enabled before discounts can be defined.')}
        <br />
        {t('Please make sure this option is checked in the Legal data tab.')}
      </div>
    );
  }

  return (
    <div>
      <Tooltip
        target=".discount-tooltip"
        position="top"
        style={!disabled ? { display: 'none' } : {}}
      />

      <div
        className="discount-tooltip"
        data-pr-tooltip={t(
          'Invoicing must be enabled. Please make sure this option is checked in the Legal data tab.'
        )}
      >
        <FieldArray
          name="popust"
          render={(arrayHelpers) =>
            values.popust.length ? (
              <Content
                disabled={disabled}
                arrayHelpers={arrayHelpers}
                productServiceMapping={productServiceMapping}
                isLoading={productServicesCollectionRequest.isLoading}
              />
            ) : (
              <EmptyContent />
            )
          }
        />
      </div>
    </div>
  );
}

export default Discounts;
