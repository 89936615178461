import { IconDefinition, faKey } from '@fortawesome/free-solid-svg-icons';
import { ComponentType } from 'react';
import { TFunction } from 'react-i18next';

import ChangePassword from './Pages/ChangePassword/ChangePassword';

type Route = {
  path: string;
  component: ComponentType<any>;
  icon: IconDefinition;
  title: string;
  desc: string;
  section: string;
};

export function getRoutes(t: TFunction): Route[] {
  return [
    {
      path: '/change-password',
      component: ChangePassword,
      icon: faKey,
      title: t('Change Password'),
      desc: t('Manage password'),
      section: t('Change Password'),
    },
  ];
}
