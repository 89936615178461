import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';

import SidebarItem from '../../../../Grid/Sidebar/SidebarItem';

type Props = {
  onHandOverBtnClick: () => void;
  disabled: boolean;
};

function Actions({ onHandOverBtnClick, disabled }: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <SidebarItem template="actions">
      <Button
        type="button"
        label={t('Hand over')}
        icon="fas fa-save"
        disabled={disabled}
        onClick={onHandOverBtnClick}
      />
    </SidebarItem>
  );
}

export default Actions;
