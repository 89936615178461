import { ProductType, ServiceType, SingleOrder } from '../../Orders.functions';

export function getAmount(
  data: SingleOrder | undefined,
  proizvod_id: ProductType,
  usluga_id: ServiceType
): number | null {
  if (data?.proizvod_id !== proizvod_id) {
    return null;
  }

  const packageType = data?.payments?.find(
    (p) => p.usluga_id === String(usluga_id)
  );

  return packageType?.kolicina ? Number(packageType.kolicina) : 0;
}
