import { TFunction } from 'i18next';

import {
  emptyCell,
  noColumnStyle,
} from '../../../../../../utils/constants/tables';
import { currencyFormat } from '../../../../../../utils/helpers/formatting';
import { formatPhoneOrMobileNumber } from '../../../../../../utils/helpers/phoneNumbers';
import { contextMenuModel } from '../../../../../../utils/helpers/primereact';

export function additionalColumnProperties(column: string) {
  switch (column) {
    case 'no':
      return noColumnStyle;

    case 'seriski_broj':
      return {
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
        },
      };

    case 'klient_do_ime':
      return {
        style: {
          width: 250,
        },
        bodyStyle: {
          width: 250,
        },
      };

    case 'adresa_do':
      return {
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
        },
      };

    case 'mobilen_do':
    case 'telefon_do':
      return {
        body: (row: any) => {
          return row[column]
            ? formatPhoneOrMobileNumber(row[column])
            : emptyCell;
        },
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
        },
      };

    case 'otkup':
      return {
        body: (row: any) =>
          row[column]
            ? currencyFormat(row[column], { showCurrency: true })
            : emptyCell,
        style: {
          width: 140,
        },
        bodyStyle: {
          width: 140,
        },
      };

    default:
      return {};
  }
}

export function generateContextMenu(
  t: TFunction,
  isViewShown: boolean,
  handleView: () => void,
  isEditShown: boolean,
  handleEdit: () => void
) {
  const viewOption = {
    label: t('View shipment'),
    icon: 'fas fa-search',
    command: handleView,
  };

  const editOption = {
    label: t('Edit shipment'),
    icon: 'fas fa-edit',
    command: handleEdit,
  };

  return contextMenuModel([
    [[isViewShown, viewOption]],
    [[isEditShown, editOption]],
  ]);
}
