import * as Sentry from '@sentry/react';
import classNames from 'classnames';
import { Dispatch, SetStateAction, useLayoutEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

import { ParentMenuItem } from '../SideMenu/SideMenu.types';
import styles from './PopUpSubMenu.module.scss';
import PopUpSubMenuItem from './PopUpSubMenuItem/PopUpSubMenuItem';

type Props = {
  popUpSubMenuTop: number | undefined;
  setPopUpSubMenuTop: Dispatch<SetStateAction<number | undefined>>;
  parentItem: ParentMenuItem;
  handleChildItemClick: () => void;
};

function PopUpSubMenu({
  popUpSubMenuTop,
  setPopUpSubMenuTop,
  parentItem,
  handleChildItemClick,
}: Props) {
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const frameId = requestAnimationFrame(() => {
      const rect = ref.current?.getBoundingClientRect();

      if (rect) {
        const overflowsTop = rect.top < 48;
        const overflowsBottom =
          rect.bottom >
          (window.innerHeight || document.documentElement.clientHeight);

        if (popUpSubMenuTop !== undefined) {
          if (overflowsTop) {
            setPopUpSubMenuTop(60);
          } else if (overflowsBottom) {
            const newPopUpSideMenuTop =
              popUpSubMenuTop - (rect.bottom - window.innerHeight) - 30;

            setPopUpSubMenuTop(newPopUpSideMenuTop);
          }
        }
      }
    });

    return () => cancelAnimationFrame(frameId);
  }, [popUpSubMenuTop, setPopUpSubMenuTop]);

  const PopUpSubMenuPortalContainer =
    document.getElementById('pop-up-sub-menu-portal-container') ||
    document.body;

  if (PopUpSubMenuPortalContainer === document.body) {
    Sentry.captureMessage(
      'ContextMenu portal container element not found!',
      Sentry.Severity.Error
    );
  }

  return ReactDOM.createPortal(
    <div
      className={styles.popUpSubMenu}
      style={{ top: popUpSubMenuTop }}
      ref={ref}
    >
      <div className={classNames(styles.title, 'p-px-4 p-py-3')}>
        {parentItem.label}
      </div>
      {parentItem.items.map((childItem, idx) => {
        const key = `${childItem.type === 'item' ? childItem.path : ''}-${idx}`;

        return (
          <PopUpSubMenuItem
            {...childItem}
            handleChildItemClick={handleChildItemClick}
            {...(childItem.type === 'item'
              ? { path: parentItem.basePath + childItem.path }
              : { basePath: parentItem.basePath + childItem.basePath })}
            key={key}
          />
        );
      })}
    </div>,
    document.getElementById('pop-up-sub-menu-portal-container') || document.body
  );
}

export default PopUpSubMenu;
