import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';

import useAxiosHook from '../../../../../../../hooks/useAxiosHook';
import useToastMessage from '../../../../../../../hooks/useToastMessage';
import { sequential } from '../../../../../../../utils/helpers/functions';

type Props = {
  visible: boolean;
  onHide: () => void;
  onSuccess: () => void;
  receptionId: number | undefined;
};

function DiscardDialog({
  visible,
  onHide,
  onSuccess,
  receptionId,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const { data, error, isLoading, reload } = useAxiosHook();

  useToastMessage(data, error, {
    success: {
      summary: t('Reception successfully discarded!'),
      callback: () => sequential(onHide, onSuccess),
    },
    error: {
      summary: t('An error occured while discarding reception.'),
    },
  });

  function onDiscardBtnClick() {
    reload({
      url: `/customer-receptions/${receptionId}`,
      method: 'DELETE',
    });
  }

  const dialogFooter = (
    <>
      <Button
        type="button"
        label={t('Close')}
        onClick={onHide}
        className="p-button-text"
      />

      <Button
        type="button"
        label={isLoading ? t('Discarding...') : t('Discard')}
        disabled={isLoading}
        onClick={onDiscardBtnClick}
        className="p-button-danger"
      />
    </>
  );

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header={t('Discard Reception?')}
      footer={dialogFooter}
    >
      {t(
        'Are you sure you want to discard the current reception, along with all its orders?'
      )}
      <br />
      {t('This action cannot be undone.')}
    </Dialog>
  );
}

export default DiscardDialog;
