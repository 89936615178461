import _ from 'lodash';

import { WithPagination } from '../../types/api';
import { Numeric } from '../../types/general';

export function isEqual<T>(currentValues: T, initialValues: T) {
  for (const [key, value] of Object.entries(currentValues)) {
    if (initialValues[key as keyof T] !== value) {
      return false;
    }
  }
  return true;
}

export function getChangedValues<T>(
  currentValues: T,
  initialValues: T
): { [key: string]: any } {
  let changedValues: [key: string, value: any][] = [];

  for (const [key, value] of Object.entries(currentValues)) {
    if (!_.isEqual(initialValues[key as keyof T], value)) {
      changedValues.push([key, value]);
    }
  }

  return Object.fromEntries(changedValues);
}

export function objectFromSoundsArray(
  arrayData: {
    id: Numeric;
    sound: string;
  }[]
): Record<string, string> {
  return Object.fromEntries(
    arrayData.map((item) => [String(item.id), item.sound])
  );
}

export const emptyPagination: WithPagination<[]> = {
  data: [],
  pagination: {
    current_page: 0,
    last_page: 0,
    limit: 0,
    from: 0,
    to: 0,
    total: 0,
    requested_page: 0,
  },
};

export function cleanUpObject<T extends object>(obj: T): Partial<T> {
  return _.pick(
    obj,
    Object.keys(obj).filter((key) => {
      const value: unknown = obj[key as keyof T];

      if (typeof value === 'boolean') {
        return true;
      }

      if (typeof value === 'number') {
        return !isNaN(value);
      }

      if (Array.isArray(value)) {
        return value.length > 0;
      }

      return Boolean(value);
    })
  );
}
