import dayjs from 'dayjs';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useMediaQuery from '../../../../../hooks/useMediaQuery';
import { Vehicle } from '../../Vehicles.functions';

type Props = {
  visible: boolean;
  data: Vehicle | null;
  onHide: () => void;
  onEditClick: () => void;
};

function ViewDialog({
  visible,
  data,
  onHide,
  onEditClick,
}: Props): JSX.Element {
  const { t } = useTranslation();

  if (!data) {
    onHide();
  }

  const reformattedDates = useMemo(
    () =>
      data
        ? {
            datum_registracija: dayjs(data.datum_registracija).isValid()
              ? dayjs(data.datum_registracija).format('DD MMM YYYY')
              : t('No date'),
            posleden_servis: dayjs(data.posleden_servis).isValid()
              ? dayjs(data.posleden_servis).format('DD MMM YYYY')
              : t('No date'),
          }
        : undefined,
    [data, t]
  );

  const dialogFooter = (
    <>
      <Button
        type="button"
        label={t('Edit')}
        className="p-button-text"
        onClick={() => onEditClick()}
      />
      <Button type="button" label={t('Close')} onClick={() => onHide()} />
    </>
  );

  const isOnMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Dialog
      header={t('Vehicle {{regPlates}}', {
        regPlates: data?.registarska_tablicka,
      })}
      footer={dialogFooter}
      visible={visible}
      resizable={false}
      maximizable={isOnMobile}
      maximized={isOnMobile}
      onHide={onHide}
      style={{ width: 360, maxWidth: '100%' }}
    >
      <p>
        <b>{t('Brand')}</b>
        <br />
        {data?.marka_name}
      </p>

      <p>
        <b>{t('Model')}</b>
        <br />
        {data?.model_name}
      </p>

      <p>
        <b>{t('Registration Plates')}</b>
        <br />
        {data?.registarska_tablicka}
      </p>

      <p>
        <b>{t('Registration Date')}</b>
        <br />
        {reformattedDates?.datum_registracija}
      </p>

      <p>
        <b>{t('Last Service')}</b>
        <br />
        {reformattedDates?.posleden_servis}
      </p>

      <p>
        <b>
          {t('Load Capacity')} (m<sup>3</sup>)
        </b>
        <br />
        {data?.volume ?? t('No load capacity specified')}
      </p>

      <p>
        <b>{t('Status')}</b>
        <br />
        <span>
          {data?.aktivno !== undefined
            ? data?.aktivno
              ? t('Active.IT')
              : t('Inactive')
            : t('Unknown')}
        </span>
      </p>

      <p>
        <b>{t('Comment')}</b>
        <br />
        {data?.komentar ? data?.komentar : t('No comment')}
      </p>
    </Dialog>
  );
}

export default ViewDialog;
