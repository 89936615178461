import { RefObject, useEffect, useMemo } from 'react';

import styles from './useCanvasDraw.module.scss';

type Return = {
  canvas: JSX.Element;
  clear: () => void;
};

type Args = {
  canvasRef: RefObject<HTMLCanvasElement>;
  width: number;
  height: number;
  color?: string;
  lineWidth?: number;
  lineCap?: CanvasRenderingContext2D['lineCap'];
};

function useCanvasDraw({
  canvasRef,
  width,
  height,
  color = '#000000',
  lineWidth = 3,
  lineCap = 'round',
}: Args): Return {
  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');

    let coord = { x: 0, y: 0 };

    function draw(e: MouseEvent) {
      if (!ctx) {
        return;
      }

      ctx.beginPath();
      ctx.lineWidth = lineWidth;
      ctx.lineCap = lineCap;
      ctx.strokeStyle = color;
      ctx.moveTo(coord.x, coord.y);
      reposition(e);
      ctx.lineTo(coord.x, coord.y);
      ctx.stroke();
    }

    function reposition(e: MouseEvent) {
      if (!canvas) {
        return;
      }

      coord.x = e.offsetX;
      coord.y = e.offsetY;
    }

    function start(e: MouseEvent) {
      if (e.button !== 0 || !canvas) {
        return;
      }

      canvas.addEventListener('mousemove', draw);

      reposition(e);
    }

    function stop(e: MouseEvent) {
      if (e.button !== 0 || !canvas) {
        return;
      }

      canvas.removeEventListener('mousemove', draw);
    }

    canvas?.addEventListener('mousedown', start);
    canvas?.addEventListener('mouseup', stop);

    return () => {
      canvas?.removeEventListener('mousedown', start);
      canvas?.removeEventListener('mouseup', stop);
      canvas?.removeEventListener('mousemove', draw);
    };
  }, [canvasRef, color, lineCap, lineWidth]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');

    let coord = { x: 0, y: 0 };

    function draw(e: TouchEvent) {
      if (!ctx) {
        return;
      }

      ctx.beginPath();
      ctx.lineWidth = lineWidth;
      ctx.lineCap = lineCap;
      ctx.strokeStyle = color;
      ctx.moveTo(coord.x, coord.y);
      reposition(e);
      ctx.lineTo(coord.x, coord.y);
      ctx.stroke();
    }

    function reposition(e: TouchEvent) {
      if (!canvas) {
        return;
      }

      const touch = e.changedTouches[0];

      if (!touch) {
        return;
      }

      coord.x = touch.pageX;
      coord.y = touch.pageY;
    }

    function start(e: TouchEvent) {
      if (!canvas) {
        return;
      }

      canvas.addEventListener('touchmove', draw);

      reposition(e);
    }

    function stop() {
      if (!canvas) {
        return;
      }

      canvas.removeEventListener('touchmove', draw);
    }

    canvas?.addEventListener('touchend', start);
    canvas?.addEventListener('touchstart', stop);

    return () => {
      canvas?.removeEventListener('touchend', start);
      canvas?.removeEventListener('touchstart', stop);
      canvas?.removeEventListener('touchmove', draw);
    };
  }, [canvasRef, color, lineCap, lineWidth]);

  function clear() {
    const ctx = canvasRef.current?.getContext('2d');

    if (!ctx) {
      return;
    }

    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
  }

  const canvas = useMemo(() => {
    function handleContextMenu(e: React.MouseEvent) {
      e.preventDefault();
      e.stopPropagation();
    }

    return (
      <canvas
        ref={canvasRef}
        width={width}
        height={height}
        onContextMenu={handleContextMenu}
        className={styles.canvas}
      />
    );
  }, [canvasRef, height, width]);

  return { canvas, clear };
}

export default useCanvasDraw;
