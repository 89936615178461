import { Field } from 'formik';
import { InputText } from 'primereact/inputtext';
import { useTranslation } from 'react-i18next';

import FieldWithErrors from '../../../Forms/FieldWithErrors/FieldWithErrors';

function Mail({ section }) {
  const { t } = useTranslation();

  return (
    <>
      <FieldWithErrors
        name={`${section}-Notifications`}
        label={t('Notifications Email')}
      >
        <Field
          as={InputText}
          id={`${section}-Notifications`}
          name={`${section}-Notifications`}
          tooltip={t('Email address used for notifications')}
        />
      </FieldWithErrors>

      <FieldWithErrors
        name={`${section}-RegistrationAdminApprove`}
        label={t('Admin Registration Email')}
      >
        <Field
          as={InputText}
          id={`${section}-RegistrationAdminApprove`}
          name={`${section}-RegistrationAdminApprove`}
          tooltip={t(
            'Email address used by the administrator for approving new clients'
          )}
        />
      </FieldWithErrors>

      <FieldWithErrors name={`${section}-Support`} label={t('Support Email')}>
        <Field
          as={InputText}
          id={`${section}-Support`}
          name={`${section}-Support`}
          tooltip={t('Email address for support with software usage.')}
        />
      </FieldWithErrors>
    </>
  );
}

export default Mail;
