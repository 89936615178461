import { ListBoxItemTemplateType } from 'primereact/listbox';
import { TFunction } from 'react-i18next';
import * as Yup from 'yup';

import { ClientLookupCollection } from '../../../../../types/api/clients';
import { LabelValue } from '../../../../../types/options';
import { Unpacked } from '../../../../../types/util';

export type ClientOption = Pick<
  Unpacked<ClientLookupCollection>,
  'id' | 'ime' | 'email'
>;

export type FormFields = {
  preserving: string | ClientOption;
  deleting: string | ClientOption;
};

export function getInitialValues(
  deletingClient: ClientOption | undefined
): FormFields {
  return {
    preserving: '',
    deleting: deletingClient ?? '',
  };
}

export function getValidationSchema(t: TFunction) {
  return Yup.object().shape({
    preserving: Yup.mixed().required(t('A client must be selected')),
    deleting: Yup.mixed().required(t('A client must be selected')),
  });
}

export function getItemTemplate(t: TFunction): ListBoxItemTemplateType {
  return (option: LabelValue<ClientOption>) => (
    <>
      {option.value.ime}
      <br />
      <span className="p-text-secondary text-sm">
        ({option.value.email}; {t('ID')} {option.value.id})
      </span>
    </>
  );
}
