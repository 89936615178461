import { faCheckCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import { CellProps, Row } from 'react-table';

import { currencyFormat, dateFormat } from '../../helpers/formatting';
import { WithTitle } from './components';

export function NoCell({ row, flatRows }: { row: Row; flatRows: Row[] }) {
  return flatRows.indexOf(row) + 1;
}

export function BooleanCell({ value }: { value: string }) {
  return (
    <div className="p-text-center">
      <FontAwesomeIcon icon={!!parseInt(value) ? faCheckCircle : faTimes} />
    </div>
  );
}

export function DateCell({ value }: { value: string }) {
  return value ? dateFormat(value) : '';
}

export function CashCell({ value }: { value: string }) {
  return currencyFormat(value, { showCurrency: true });
}

export function CellWithTitle({ value }: { value: string }) {
  return WithTitle(value);
}

export function EditableCell({
  value: initialValue,
  row: { index, isSelected },
  column: { id },
  editCellDataUpdate,
}: CellProps<any>) {
  const [value, setValue] = useState(initialValue);

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    setValue(e.target.value);
  }

  function handleBlur() {
    editCellDataUpdate(index, id, value);
  }

  function handleClick(e: MouseEvent<HTMLInputElement>) {
    if (isSelected) {
      e.stopPropagation();
    }
  }

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <input
      type="text"
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      onClick={handleClick}
      className="editable-cell-input"
    />
  );
}
