import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import {
  faBarcode,
  faChartPie,
  faFilter,
  faHandPointer,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { TFunction } from 'i18next';

import { Template } from './SidebarItem.types';

export function getTemplateIcon(template: Template): IconDefinition {
  switch (template) {
    case 'filters':
      return faFilter;
    case 'barcode':
      return faBarcode;
    case 'actions':
      return faHandPointer;
    case 'stats':
      return faChartPie;
    case 'info':
      return faInfoCircle;
  }
}

export function getTemplateTitle(t: TFunction, template: Template): string {
  switch (template) {
    case 'filters':
      return t('Filters');
    case 'barcode':
      return t('Barcode');
    case 'actions':
      return t('Actions');
    case 'stats':
      return t('Stats');
    case 'info':
      return t('Info');
  }
}
