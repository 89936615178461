import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import useAxiosHook from '../../../../../hooks/useAxiosHook';
import useToastMessage from '../../../../../hooks/useToastMessage';
import { PriceResource } from '../../../../../types/api/prices';
import { EntityIdRouteParams } from '../../../../../types/routing';
import { sequential } from '../../../../../utils/helpers/functions';
import DialogSpinner from '../../../../Dialogs/DialogSpinner/DialogSpinner';
import Content from './Content';

type Props = {
  isShown: boolean;
  onHide: () => void;
  reloadCollection: () => void;
};

function DeleteDialog({
  isShown,
  onHide,
  reloadCollection,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const { id } = useParams<EntityIdRouteParams>();

  const {
    data: priceData,
    error: priceError,
    isLoading: isPriceLoading,
  } = useAxiosHook<PriceResource>(`/prices/${id}`, {
    skipWhen: !isShown || !id,
  });

  useToastMessage(undefined, priceError, {
    error: {
      summary: t('An error occured while reading price data.'),
    },
  });

  const {
    data: deleteData,
    error: deleteError,
    isLoading: isDeleteLoading,
    reload: deleteReload,
  } = useAxiosHook(
    {
      url: `/prices/${id}`,
      method: 'DELETE',
    },
    {
      skipWhen: true,
    }
  );

  useToastMessage(deleteData, deleteError, {
    success: {
      summary: t('The price has been deleted successfully.'),
      callback: () => sequential(onHide, reloadCollection),
    },
    error: {
      summary: t('An error occured while deleting the price.'),
      callback: onHide,
    },
  });

  const dialogFooter = isPriceLoading ? (
    <></>
  ) : (
    <>
      <Button
        type="button"
        label={t('Close')}
        onClick={onHide}
        className="p-button-text"
      />

      <Button
        type="button"
        label={
          isPriceLoading
            ? t('Loading...')
            : isDeleteLoading
            ? t('Deleting...')
            : t('Delete')
        }
        disabled={isPriceLoading || isDeleteLoading}
        onClick={() => deleteReload()}
        className="p-button-danger"
      />
    </>
  );

  return (
    <Dialog
      header={isPriceLoading ? t('Loading...') : t('Price deletion')}
      footer={dialogFooter}
      visible={isShown}
      resizable={false}
      onHide={onHide}
      style={{ width: 480, maxWidth: '100%' }}
    >
      {isPriceLoading ? <DialogSpinner /> : <Content data={priceData} />}
    </Dialog>
  );
}

export default DeleteDialog;
