import { Accordion, AccordionTab } from 'primereact/accordion';
import { Fragment } from 'react';
import AceEditor, { IAceEditorProps } from 'react-ace';
import { useTranslation } from 'react-i18next';

import { HelperSection } from './MySQLCodeEditor.types';

type Props = {
  value: string;
  onChange: IAceEditorProps['onChange'];
  sections?: HelperSection[];
  editorProps?: IAceEditorProps;
};

function MySQLCodeEditor({
  value,
  onChange,
  sections,
  editorProps,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <div>
      <h4 className="p-mt-0">{t('Expression')}</h4>

      <AceEditor
        {...editorProps}
        placeholder={t('Write your MySQL query here')}
        mode="mysql"
        theme="tomorrow"
        name="izraz_ace_editor"
        onChange={onChange}
        fontSize={14}
        showPrintMargin
        showGutter
        highlightActiveLine
        value={value}
        setOptions={{
          enableBasicAutocompletion: false,
          enableLiveAutocompletion: false,
          showLineNumbers: true,
          wrap: true,
          tabSize: 2,
        }}
        width="auto"
      />

      {sections && (
        <>
          <h4>{t('Helpers')}</h4>

          <Accordion>
            {sections.map((s, idx) => (
              <AccordionTab key={idx} header={s.title}>
                {s.subsections.map((sub, idx) => (
                  <Fragment key={sub.label}>
                    <h4 className={idx === 0 ? 'p-mt-0' : undefined}>
                      {sub.label}
                    </h4>

                    {sub.content}
                  </Fragment>
                ))}
              </AccordionTab>
            ))}
          </Accordion>
        </>
      )}
    </div>
  );
}

export default MySQLCodeEditor;
