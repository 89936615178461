import { faTags } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'primereact/button';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import useAxiosHook from '../../../hooks/useAxiosHook';
import { useEndpointGuard } from '../../../hooks/useEndpointGuard';
import usePageTitle from '../../../hooks/usePageTitle';
import useRouteDialog from '../../../hooks/useRouteDialog';
import useTableColumns from '../../../hooks/useTableColumns';
import useTableState from '../../../hooks/useTableState';
import useToastMessage from '../../../hooks/useToastMessage';
import { PriceCollection } from '../../../types/api/prices';
import { EntityIdRouteParams } from '../../../types/routing';
import {
  addPriceResourceGuard,
  deletePriceResourceGuard,
  editPriceResourceGuard,
} from '../../../utils/constants/auth/_api/prices';
import {
  RoutePaths,
  constructIdRoute,
} from '../../../utils/constants/routePaths';
import { queryString } from '../../../utils/helpers/http';
import Table from '../../DataTable/Table/Table';
import Flex from '../../layout/flex/Flex';
import MainContent from '../../layout/flex/MainContent';
import Filters from '../Components/Filters/Filters';
import HeaderPages from '../Components/HeaderPages/HeaderPages';
import AddDialog from './Dialogs/Add/AddDialog';
import DeleteDialog from './Dialogs/Delete/DeleteDialog';
import EditDialog from './Dialogs/Edit/EditDialog';
import {
  Column,
  Row,
  getAdditionalColumnProperties,
  getColumnHeadersMap,
  getContextMenuModel,
  tableStorageKey,
} from './Prices.functions';
import useTableFilters from './useTableFilters';

function Prices(): JSX.Element {
  const { id } = useParams<EntityIdRouteParams>();
  const { t } = useTranslation();
  const pageTitle = t('Prices');
  usePageTitle(pageTitle);
  const addEpGuard = useEndpointGuard(addPriceResourceGuard);
  const editEpGuard = useEndpointGuard(editPriceResourceGuard);
  const deleteEpGuard = useEndpointGuard(deletePriceResourceGuard);
  const {
    tableRef,
    page,
    setPage,
    limit,
    setLimit,
    sortField,
    sortOrder,
    selection,
    setSortField,
    setSortOrder,
    setSelection,
  } = useTableState<Row>(tableStorageKey);
  const { filters, headerFiltersCount, resetAllFilters, httpFiltersObj } =
    useTableFilters(page, setPage, limit);

  const columnHeadersMap = useMemo(() => getColumnHeadersMap(t), [t]);

  const additionalColumnProperties = useMemo(
    () => getAdditionalColumnProperties(t),
    [t]
  );

  const { selectedColumns, setSelectedColumns, columnOptions, columns } =
    useTableColumns<Column>(
      page,
      limit,
      'prices',
      columnHeadersMap,
      columnHeadersMap,
      additionalColumnProperties
    );

  const {
    show: showAddDialog,
    hide: hideAddDialog,
    isVisible: isAddDialogVisible,
  } = useRouteDialog(RoutePaths.Prices, RoutePaths.AddPrice, addEpGuard);

  const {
    show: showEditDialog,
    hide: hideEditDialog,
    isVisible: isEditDialogVisible,
  } = useRouteDialog(
    RoutePaths.Prices,
    constructIdRoute(RoutePaths.EditPrice, id ?? selection?.id),
    editEpGuard
  );

  const {
    show: showDeleteDialog,
    hide: hideDeleteDialog,
    isVisible: isDeleteDialogVisible,
  } = useRouteDialog(
    RoutePaths.Prices,
    constructIdRoute(RoutePaths.DeletePrice, id ?? selection?.id),
    deleteEpGuard
  );

  const { data, error, isLoading, reload } = useAxiosHook<PriceCollection>(
    '/prices' + queryString(httpFiltersObj)
  );

  useToastMessage(undefined, error, {
    error: {
      summary: t('An error occured while reading prices.'),
    },
  });

  const contextMenuModel = useMemo(
    () =>
      getContextMenuModel({
        t,
        isEditShown: editEpGuard,
        onEditClick: showEditDialog,
        isDeleteShown: deleteEpGuard,
        onDeleteClick: showDeleteDialog,
      }),
    [deleteEpGuard, editEpGuard, showDeleteDialog, showEditDialog, t]
  );

  return (
    <div className="page prices">
      <HeaderPages
        title={pageTitle}
        subtitle={t('View and manage prices.')}
        icon={faTags}
      >
        <Button
          type="button"
          label={t('Add a price')}
          icon="fas fa-plus"
          onClick={showAddDialog}
          className="main-btn"
        />
      </HeaderPages>

      <AddDialog
        isShown={isAddDialogVisible}
        onHide={hideAddDialog}
        reloadCollection={reload}
      />

      <EditDialog
        isShown={isEditDialogVisible}
        onHide={hideEditDialog}
        reloadCollection={reload}
      />

      <DeleteDialog
        isShown={isDeleteDialogVisible}
        onHide={hideDeleteDialog}
        reloadCollection={reload}
      />

      <Flex direction="column">
        <Filters
          filters={filters}
          resetAllFilters={resetAllFilters}
          headerFiltersCount={headerFiltersCount}
          filterHeight={170}
        />
        <MainContent>
          <Table
            tableRef={tableRef}
            columns={columns}
            data={data}
            isLoading={isLoading}
            hasError={!!error}
            reload={reload}
            headerTitle=""
            headerFiltersCount={headerFiltersCount}
            onHeaderFiltersResetAllBtnClick={resetAllFilters}
            setPage={setPage}
            setLimit={setLimit}
            sortField={sortField}
            rows={limit}
            filterHeight={170}
            contextMenuModel={contextMenuModel}
            setSortField={setSortField}
            setSortOrder={setSortOrder}
            setSelection={setSelection}
            sortOrder={sortOrder}
            selection={selection}
            storageString={tableStorageKey}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            columnOptions={columnOptions}
            exportToExcelButton
          />
        </MainContent>
      </Flex>
    </div>
  );
}

export default Prices;
