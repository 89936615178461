import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { Numeric } from '../../../../../types/general';
import { FormValues } from './CreateEditDialog.functions';

type Props = {
  title?: string;
  serialNumber?: Numeric | null;
  isGroupDialog: boolean;
  isEditDialog: boolean;
  orderIndex?: number;
  ordersCount: number;
};

export function DialogHeader({
  title,
  isEditDialog,
  serialNumber,
  isGroupDialog,
  orderIndex,
  ordersCount,
}: Props) {
  const { t } = useTranslation();

  const { values } = useFormikContext<FormValues>();

  return (
    <>
      {title ? (
        title
      ) : isGroupDialog ? (
        <>
          <div>
            {t('Group Order {{serialNumber}}', {
              serialNumber: values?.seriski_broj,
            })}{' '}
          </div>

          {isGroupDialog && (
            <div className="group-buttons">
              {(orderIndex ?? 0) + 1} {t('of')} {ordersCount}
            </div>
          )}
        </>
      ) : isEditDialog ? (
        t('Edit Order {{serialNumber}}', { serialNumber: serialNumber })
      ) : (
        t('Create Order')
      )}
    </>
  );
}
