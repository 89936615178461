import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';

import { useEndpointGuard } from '../../../../../../hooks/useEndpointGuard';
import {
  routeGuard as clientsPageGuard,
  createClient as createClientGuard,
} from '../../../../../../utils/constants/auth/clients';
import { RoutePaths } from '../../../../../../utils/constants/routePaths';
import LinkButton from '../../../../../Button/LinkButton/LinkButton';
import SidebarItem from '../../../../../Grid/Sidebar/SidebarItem';
import SidebarContentDivider from '../../../../../Sidebar/ContentDivider/SidebarContentDivider';

type Props = {
  receptionId: number | undefined;
  onNewReceptionBtnClick: () => void;
  onSingleOrderBtnClick: () => void;
  onBatchOrderBtnClick: () => void;
  onSaveBtnClick: () => void;
  onDiscardBtnClick: () => void;
  isSaveBtnDisabled: boolean;
};

function Actions({
  receptionId,
  onNewReceptionBtnClick,
  onSingleOrderBtnClick,
  onBatchOrderBtnClick,
  onSaveBtnClick,
  onDiscardBtnClick,
  isSaveBtnDisabled,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const canAddClients = useEndpointGuard(createClientGuard);
  const canVisitClientsPage = useEndpointGuard(clientsPageGuard);

  const isAddClientLinkVisible = canAddClients && canVisitClientsPage;

  return (
    <SidebarItem template="actions">
      {receptionId !== undefined && (
        <>
          <div className="p-mb-3 p-d-flex">
            <Button
              type="button"
              label={t('Single Order')}
              icon="fas fa-plus"
              onClick={onSingleOrderBtnClick}
              className="p-mr-2"
            />

            <Button
              type="button"
              label={t('Batch Order')}
              onClick={onBatchOrderBtnClick}
              icon="fas fa-plus"
              className="p-ml-2"
            />
          </div>

          <div className="p-d-flex">
            <Button
              type="button"
              label={t('Save')}
              icon="fas fa-save"
              disabled={isSaveBtnDisabled}
              onClick={onSaveBtnClick}
              className="p-mr-2 p-button-outlined p-button-secondary"
            />

            <Button
              type="button"
              label={t('Discard')}
              icon="fas fa-trash"
              onClick={onDiscardBtnClick}
              className="p-ml-2 p-button-outlined p-button-danger"
            />
          </div>

          <SidebarContentDivider />
        </>
      )}

      {receptionId === undefined ? (
        <Button
          type="button"
          label={t('New Reception')}
          icon="fas fa-plus"
          onClick={onNewReceptionBtnClick}
        />
      ) : (
        <div className="p-d-flex">
          <Button
            type="button"
            label={t('New Reception')}
            icon="fas fa-plus"
            onClick={onNewReceptionBtnClick}
            className="p-mr-2 p-button-text"
          />

          {isAddClientLinkVisible && (
            <LinkButton
              to={RoutePaths.Clients}
              label={t('Add new Client')}
              icon="fas fa-plus"
              className="p-ml-2 p-button-text"
            />
          )}
        </div>
      )}
    </SidebarItem>
  );
}

export default Actions;
