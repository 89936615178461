export const hubCollectionGuard = 'GET /hubs';
export const hubResourceGuard = 'GET /hubs/d+';
export const createHubResourceGuard = 'POST /hubs';
export const editHubResourceGuard = 'PUT /hubs/d+';
export const deleteHubResourceGuard = 'DELETE /hubs/d+';
export const nearestHubCollectionGuard = 'POST /hubs/nearest';

export const availableHubCollectionGuard = 'GET /hubs/available';
export const hubNotAssignedCollectionGuard = 'GET /hubs/regions-not-assigned';

export const hubsReports = 'GET /reports/warehouse/state';
export const hubReport = 'GET /reports/warehouse/state/:id';
