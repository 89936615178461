import { Field, useFormikContext } from 'formik';
import { Checkbox } from 'primereact/checkbox';
import { useTranslation } from 'react-i18next';

import FieldWithErrors from '../../../Forms/FieldWithErrors/FieldWithErrors';
import SectionFormTooltip from '../SectionFormTooltip';

function Parcel({ section }) {
  const { t } = useTranslation();

  const { values } = useFormikContext();

  return (
    <>
      <FieldWithErrors name={`${section}-KontrolaSeriskiBroj`} label={false}>
        <Field
          as={Checkbox}
          inputId={`${section}-KontrolaSeriskiBroj`}
          name={`${section}-KontrolaSeriskiBroj`}
          checked={values[`${section}-KontrolaSeriskiBroj`]}
        />
        <label htmlFor={`${section}-KontrolaSeriskiBroj`}>
          {t('Control Serial Number')}
        </label>

        <SectionFormTooltip
          text={t(
            'Control accepted and returned parcels when a parcel is entered from paper (data center)'
          )}
        />
      </FieldWithErrors>

      <FieldWithErrors
        name={`${section}-PrikaziNovaFormaPosleKreiranje`}
        label={false}
      >
        <Field
          as={Checkbox}
          inputId={`${section}-PrikaziNovaFormaPosleKreiranje`}
          name={`${section}-PrikaziNovaFormaPosleKreiranje`}
          checked={values[`${section}-PrikaziNovaFormaPosleKreiranje`]}
        />
        <label htmlFor={`${section}-PrikaziNovaFormaPosleKreiranje`}>
          {t('New empty form after parcel creation')}
        </label>

        <SectionFormTooltip
          text={t(
            'Show an empty form for creating a new parcel, immediately after creating one'
          )}
        />
      </FieldWithErrors>

      <FieldWithErrors
        name={`${section}-DefaultFreeInputForTheReceiver`}
        label={false}
      >
        <Field
          as={Checkbox}
          inputId={`${section}-DefaultFreeInputForTheReceiver`}
          name={`${section}-DefaultFreeInputForTheReceiver`}
          checked={values[`${section}-DefaultFreeInputForTheReceiver`]}
        />
        <label htmlFor={`${section}-DefaultFreeInputForTheReceiver`}>
          {t('Reciever fillable field')}
        </label>

        <SectionFormTooltip
          text={t('Parcel receiver field should be fillable')}
        />
      </FieldWithErrors>
    </>
  );
}

export default Parcel;
