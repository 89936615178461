import './AssignDialog.scss';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import ToastContext from '../../../../../context/ToastContext';
import useAxiosHook from '../../../../../hooks/useAxiosHook';
import usePrevious from '../../../../../hooks/usePrevious';
import { LabelValue } from '../../../../../types/options';
import {
  errorToast,
  successToast,
} from '../../../../../utils/helpers/primereact';

type Props = {
  isShown: boolean;
  onHide: () => void;
  courierOptions: LabelValue<number>[] | undefined;
  selectionMultiple: { id: number }[];
  setSelectionMultiple: Dispatch<SetStateAction<any[]>>;
  reload: () => void;
};

function AssignDialog({
  isShown,
  onHide,
  courierOptions,
  selectionMultiple,
  setSelectionMultiple,
  reload: reloadTable,
}: Props) {
  const { t } = useTranslation();

  const { toastRef } = useContext(ToastContext);

  const [selectedCourier, setSelectedCourier] = useState(null);

  const { data, error, isLoading, reload } = useAxiosHook();

  const prevAssignmentData = usePrevious(data);
  const prevAssignmentError = usePrevious(error);

  useEffect(() => {
    if (!data || data === prevAssignmentData) {
      return;
    }

    successToast(
      toastRef,
      t('Success'),
      t('The selected parcels have been successfully assigned.')
    );

    onHide();
    reloadTable();
    setSelectionMultiple([]);
  }, [
    data,
    onHide,
    prevAssignmentData,
    reloadTable,
    setSelectionMultiple,
    t,
    toastRef,
  ]);

  useEffect(() => {
    if (!error || error === prevAssignmentError) {
      return;
    }

    errorToast(
      toastRef,
      t('Error'),
      t('An error occured while trying to assign the selected parcels.')
    );

    onHide();
  }, [error, onHide, prevAssignmentError, t, toastRef]);

  function handleSubmit() {
    reload({
      url: '/orders/courier/assignments/pickup',
      method: 'PUT',
      data: {
        courier_id: selectedCourier,
        parcels: selectionMultiple.map((r) => r.id),
      },
    });
  }

  const dialogFooter = (
    <>
      <Button
        type="button"
        label={t('Cancel')}
        className="p-button-text"
        onClick={() => onHide()}
      />

      <Button
        type="button"
        label={isLoading ? t('Saving...') : t('Save changes')}
        disabled={!selectedCourier || isLoading}
        onClick={handleSubmit}
        data-cy="submit-btn"
      />
    </>
  );

  return (
    <Dialog
      visible={isShown}
      onHide={onHide}
      header={t('Select a courier')}
      footer={dialogFooter}
      dismissableMask
      resizable={false}
      className="order-parcel-assignment-courier-dialog"
    >
      <div className="p-fluid">
        <div className="p-field">
          <label htmlFor="courier">{t('Assign to')}</label>
          <Dropdown
            inputId="courier"
            placeholder={t('Choose a courier')}
            filter
            showClear
            value={selectedCourier}
            options={courierOptions}
            onChange={(e) => {
              setSelectedCourier(e.target.value);
            }}
            className="data-cy-courier"
          />
        </div>
      </div>
    </Dialog>
  );
}

export default AssignDialog;
