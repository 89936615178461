import useSearchQueryParam from './useSearchQueryParam';

function useSearchQueryMultiSelectParam(
  param: string,
  filter: string[] | number[] | null
) {
  useSearchQueryParam(param, filter ? filter.join() : '');
}

export default useSearchQueryMultiSelectParam;
