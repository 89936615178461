export const importListCollectionGuard = 'GET /importlists';
export const importListResourceGuard = 'GET /importlists/d+';

export const importListsImportLogCollectionGuard = 'GET /importlists/d+/log';

export const importListsParcelCollectionGuard = 'GET /importlists/d+/parcels';

export const importListTypeCollectionGuard = 'GET /importlisttypes';
export const importListTypeResourceGuard = 'GET /importlisttypes/d+';
export const exportListTypeToExcelOrCSVCollectionGuard =
  'GET /importlisttypes/d+/export/(excel|csv)';
export const createImportListTypeResourceGuard = 'POST /importlisttypes';
export const updateImportListTypeResourceGuard = 'PUT /importlisttypes/d+';
export const deleteImportListTypeResourceGuard =
  'DELETE /importlisttypefields/d+';

export const createImportListTypeFieldResourceGuard =
  'POST /importlisttypefields';
export const updateImportListTypeFieldResourceGuard =
  'PUT /importlisttypefields/d+';

export const createImportListParcelDataDefinitionResourceGuard =
  'POST /importlistparceldata/definition';

export const entityPropertyCollectionGuard = 'GET /entityproperties';
