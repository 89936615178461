import dayjs from 'dayjs';

import {
  FailedOrderReason,
  ReturnedOrderCheck,
} from '../../../../../../../../../enums/orders';
import { UpdateReturnedShipmentRequestPayload } from '../../../../../../../../../types/api/orders';
import { httpDateFormat } from '../../../../../../../../../utils/helpers/formatting';
import { Row } from '../../ReturnedShipments.functions';

export type FormFields = {
  next_delivery: Date;
  reason_id: FailedOrderReason;
  check_id: ReturnedOrderCheck;
};

export function getDefaultValues(selection: Row | null): FormFields {
  return {
    reason_id: selection?.reason_id
      ? selection.reason_id
      : FailedOrderReason.Unknown,
    next_delivery: selection?.next_delivery
      ? dayjs(selection.next_delivery).toDate()
      : new Date(),
    check_id: selection?.check_id ?? ReturnedOrderCheck.No,
  };
}

export function toApiData(
  values: FormFields,
  courierWorkOrderId: number
): UpdateReturnedShipmentRequestPayload {
  return {
    ...values,
    work_order_id: courierWorkOrderId,
    next_delivery: httpDateFormat(values.next_delivery),
  };
}
