import './ViewCourierDialog.scss';

import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useMediaQuery from '../../../../../../hooks/useMediaQuery';
import { Courier, Region } from '../../../Regions.functions';

type Props = {
  isShown: boolean;
  selectedRegion: Region | undefined;
  selectedCourier: Courier | undefined;
  onHide: () => void;
  onEditBtnClick: () => void;
};

function ViewCourierDialog({
  isShown,
  selectedRegion,
  selectedCourier,
  onHide,
  onEditBtnClick,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const courierName = `${selectedCourier?.first_name} ${selectedCourier?.last_name}`;
  const dialogHeader = t('Courier {{courierName}}', {
    courierName: courierName,
  });

  const collectPackages = useMemo<boolean>(
    () =>
      !!selectedCourier?.assignments.find(
        (a) => a.product_id === 1 && a.shipment_type_id === 1
      ),
    [selectedCourier]
  );

  const collectLetters = useMemo<boolean>(
    () =>
      !!selectedCourier?.assignments.find(
        (a) => a.product_id === 2 && a.shipment_type_id === 1
      ),
    [selectedCourier]
  );

  const collectPersonalfiles = useMemo<boolean>(
    () =>
      !!selectedCourier?.assignments.find(
        (a) => a.product_id === 3 && a.shipment_type_id === 1
      ),
    [selectedCourier]
  );

  const deliverPackages = useMemo<boolean>(
    () =>
      !!selectedCourier?.assignments.find(
        (a) => a.product_id === 1 && a.shipment_type_id === 2
      ),
    [selectedCourier]
  );

  const collectOversizedPackages = useMemo<boolean>(
    () =>
      !!selectedCourier?.assignments.find(
        (a) => a.product_id === 5 && a.shipment_type_id === 1
      ),
    [selectedCourier]
  );

  const deliverOversizedPackages = useMemo<boolean>(
    () =>
      !!selectedCourier?.assignments.find(
        (a) => a.product_id === 5 && a.shipment_type_id === 2
      ),
    [selectedCourier]
  );

  const deliverLetters = useMemo<boolean>(
    () =>
      !!selectedCourier?.assignments.find(
        (a) => a.product_id === 2 && a.shipment_type_id === 2
      ),
    [selectedCourier]
  );

  const deliverPersonalfiles = useMemo<boolean>(
    () =>
      !!selectedCourier?.assignments.find(
        (a) => a.product_id === 3 && a.shipment_type_id === 2
      ),
    [selectedCourier]
  );

  const dialogFooter = (
    <Button
      type="button"
      label={t('Edit')}
      onClick={onEditBtnClick}
      className="p-button-text"
    />
  );

  const isOnMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Dialog
      visible={isShown}
      onHide={onHide}
      header={dialogHeader}
      footer={dialogFooter}
      dismissableMask
      resizable={false}
      maximizable={isOnMobile}
      maximized={isOnMobile}
      className="regions-view-courier-dialog"
    >
      <div className="info">
        <div className="info-item">
          <h4>{t('Courier')}</h4>
          <span>{courierName}</span>
        </div>

        <div className="info-item">
          <h4>{t('Region')}</h4>
          <span>{selectedRegion?.name}</span>
        </div>

        <div className="info-item">
          <h4>{t('Assignments')}</h4>
          <div className="courier-assignments">
            <span />
            <span>{t('Pickup')}</span>
            <span>{t('Deliver')}</span>

            <span>{t('Packages')}</span>
            <Checkbox checked={collectPackages} disabled />
            <Checkbox checked={deliverPackages} disabled />

            <span>{t('Letters')}</span>
            <Checkbox checked={collectLetters} disabled />
            <Checkbox checked={deliverLetters} disabled />

            <span>{t('Personal Files')}</span>
            <Checkbox checked={collectPersonalfiles} disabled />
            <Checkbox checked={deliverPersonalfiles} disabled />

            <span>{t('Oversize Package')}</span>
            <Checkbox checked={collectOversizedPackages} disabled />
            <Checkbox checked={deliverOversizedPackages} disabled />
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default ViewCourierDialog;
