import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { UseAxiosReturn } from '../../../../../../hooks/useAxiosHook';
import { CourierSummaryCollection } from '../../../../../../types/api/orders';
import { tryInt, trySum } from '../../../../../../utils/helpers/parse';
import StatsWrapper from '../../../../Components/StatsWrapper/StatsWrapper';
import CommonStateContext from '../../../_AssignmentAndReception/CommonStateContext';

type Props = {
  request: UseAxiosReturn<CourierSummaryCollection>;
};

function Stats({ request: { data } }: Props): JSX.Element {
  const { t } = useTranslation();

  const { hasCourierWorkOrder, nativeHubId } = useContext(CommonStateContext);

  const hubStats = data?.[0]?.hubs?.find((h) => h.id === nativeHubId);

  const newShipments = hubStats?.reception.collected;
  const availableNew = tryInt(newShipments?.available);
  const completedNew = tryInt(newShipments?.completed) ?? 0;
  const totalNew = trySum(availableNew, completedNew) ?? 0;

  const returnedShipments = hubStats?.reception.returned;
  const availableReturned = tryInt(returnedShipments?.available);
  const completedReturned = tryInt(returnedShipments?.completed) ?? 0;
  const totalReturned = trySum(availableReturned, completedReturned) ?? 0;

  return (
    <StatsWrapper>
      <p className="p-mt-1 p-mb-1">
        - {t('New.THEM')}:{' '}
        {data && hasCourierWorkOrder ? (
          <b>{`${completedNew} / ${totalNew}`}</b>
        ) : (
          <b>- / -</b>
        )}
      </p>

      <p className="p-mt-0 p-mb-0">
        - {t('Returned.THEM')}:{' '}
        {data && hasCourierWorkOrder ? (
          <b>{`${completedReturned} / ${totalReturned}`}</b>
        ) : (
          <b>- / -</b>
        )}
      </p>
    </StatsWrapper>
  );
}

export default Stats;
