import _ from 'lodash';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

function useTabViewRouting(
  tabRoutes: string[]
): [number, Dispatch<SetStateAction<number>>] {
  const history = useHistory();

  const [activeTabIndex, setActiveTabIndex] = useState<number>(() => {
    const index = tabRoutes.findIndex((route) =>
      new RegExp(route + '/?').test(history.location.pathname)
    );
    return index !== -1 ? index : 0;
  });

  useEffect(() => {
    const regex = new RegExp(tabRoutes.join('|'), 'g');
    const baseRoute = history.location.pathname
      .replace(regex, '')
      // replace all ending /, so that no / gets appended on each tab change
      .replace(/\/+$/g, '');

    history.replace({
      ..._.omit(history.location, 'key'),
      pathname: `${baseRoute}/${tabRoutes[activeTabIndex]}`,
    });
  }, [activeTabIndex, history, tabRoutes]);

  return [activeTabIndex, setActiveTabIndex];
}

export default useTabViewRouting;
