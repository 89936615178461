import { Dispatch, SetStateAction, createContext } from 'react';

export type StepperDialogContextType = {
  stepIndex: number;
  isLastStep: boolean;
  setIsLoading?: Dispatch<SetStateAction<boolean>>;
};

const StepperDialogContext = createContext<StepperDialogContextType>({
  stepIndex: 0,
  isLastStep: false,
});

export default StepperDialogContext;
