import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';

import { ReduxState } from '../../types/redux';

type AdvancedEndpointGuard = {
  required?: string[];
  oneOf: Array<string | string[]>;
};

export type EndpointGuard = string | string[] | AdvancedEndpointGuard;

export type GuardArray = {
  label: string;
  path?: string;
  icon?: IconDefinition;
  guard?: EndpointGuard;
  items?: GuardArray;
}[];

// !!!!!!!!!!!!!!!
// !!! WARNING !!!
// !!!!!!!!!!!!!!!
//
// If you intend to use this function, you're probably doing something wrong.
// Please use the useEndpointGuard hook instead.
export function doesEndpointGuardMatch(
  guard: EndpointGuard,
  endpoints: ReduxState['user']['permitted_routes']
) {
  if (typeof guard === 'string' || Array.isArray(guard)) {
    return (
      _.difference(typeof guard === 'string' ? [guard] : guard, endpoints ?? [])
        .length === 0
    );
  }

  const oneOfStrings = guard.oneOf.filter((e) => typeof e === 'string');
  const oneOfStringArrs = guard.oneOf.filter((e) => Array.isArray(e));

  const areRequiredEndpointsPermitted = guard.required
    ? _.difference(guard.required, endpoints ?? []).length === 0
    : true;

  const areOneOfStringsPermitted =
    _.difference(oneOfStrings, endpoints ?? []).length < oneOfStrings.length;

  const areOneOfStringArrsPermitted = oneOfStringArrs.some(
    (arr) => _.difference(arr, endpoints ?? []).length === 0
  );

  return (
    areRequiredEndpointsPermitted &&
    (areOneOfStringsPermitted || areOneOfStringArrsPermitted)
  );
}

// !!!!!!!!!!!!!!!
// !!! WARNING !!!
// !!!!!!!!!!!!!!!
//
// If you intend to use this function, you're probably doing something wrong.
// Please use the useEndpointGuardsFilter hook instead.
export function filterGuardArray(
  guards: GuardArray,
  endpoints: ReduxState['user']['permitted_routes']
): GuardArray {
  return _.cloneDeep(guards).filter(function f(g): boolean {
    if (g.items) {
      g.items = g.items.filter(f);
    }

    return g.guard
      ? doesEndpointGuardMatch(g.guard, endpoints)
      : !!g.items?.length;
  });
}
