import './Regions.scss';

import { faMap } from '@fortawesome/free-solid-svg-icons';
import { Toast } from 'primereact/toast';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import useAxiosHook from '../../../hooks/useAxiosHook';
import { useEndpointGuard } from '../../../hooks/useEndpointGuard';
import usePageTitle from '../../../hooks/usePageTitle';
import { WithData } from '../../../types/api';
import * as regionsGuards from '../../../utils/constants/auth/regions';
import { getSearchQueryParam } from '../../../utils/helpers/searchQuery';
import HeaderPages from '../Components/HeaderPages/HeaderPages';
import AddEditCourierDialog from './Dialogs/Courier/AddEdit/AddEditCourierDialog';
import DeleteCourierDialog from './Dialogs/Courier/Delete/DeleteCourierDialog';
import ViewCourierDialog from './Dialogs/Courier/View/ViewCourierDialog';
import AddEditRegionDialog from './Dialogs/Region/AddEdit/AddEditRegionDialog';
import DeleteRegionDialog from './Dialogs/Region/Delete/DeleteRegionDialog';
import ViewRegionDialog from './Dialogs/Region/View/ViewRegionDialog';
import { Courier, Region } from './Regions.functions';
import RegionsMap from './RegionsMap';
import Sidebar from './Sidebar';

function Regions() {
  const { t } = useTranslation();

  const addRegionGuard = useEndpointGuard(regionsGuards.addRegion);
  const editRegionGuard = useEndpointGuard(regionsGuards.editRegion);
  const deleteRegionGuard = useEndpointGuard(regionsGuards.deleteRegion);
  const assignCourierGuard = useEndpointGuard(regionsGuards.assignCourier);
  const editCourierGuard = useEndpointGuard(regionsGuards.editCourier);
  const deleteCourierGuard = useEndpointGuard(regionsGuards.deleteCourier);

  const location = useLocation();

  usePageTitle(t('Regions'));

  const [selectedRegionId, setSelectedRegionId] = useState<number | null>(
    () => {
      const value = Number(getSearchQueryParam(location.search, 'region'));

      return isNaN(value) ? null : value;
    }
  );

  const [selectedCourierId, setSelectedCourierId] = useState<number | null>(
    () => {
      const value = Number(getSearchQueryParam(location.search, 'courier'));

      return isNaN(value) ? null : value;
    }
  );

  const [isViewRegionDialogShown, setIsViewRegionDialogShown] =
    useState<boolean>(false);

  const [isAddEditRegionDialogShown, setIsAddEditRegionDialogShown] =
    useState<boolean>(false);

  const [isDeleteRegionDialogShown, setIsDeleteRegionDialogShown] =
    useState<boolean>(false);

  const [isViewCourierDialogShown, setIsViewCourierDialogShown] =
    useState<boolean>(false);

  const [isAddEditCourierDialogShown, setIsAddEditCourierDialogShown] =
    useState<boolean>(false);

  const [isDeleteCourierDialogShown, setIsDeleteCourierDialogShown] =
    useState<boolean>(false);

  const [isEditingRegion, setIsEditingRegion] = useState<boolean>(false);
  const [isEditingCourier, setIsEditingCourier] = useState<boolean>(false);

  const toastRef = useRef<Toast>(null);

  const { data, reload } = useAxiosHook<WithData<any>>('/regions/couriers');

  const sortedRegions = useMemo<Region[]>(() => {
    if (!data?.data) {
      return [];
    }

    return [...data.data].sort((r1, r2) => r1.region_id - r2.region_id);
  }, [data]);

  const selectedRegion = useMemo<Region | undefined>(
    () => sortedRegions.find((r) => r.region_id === selectedRegionId),
    [sortedRegions, selectedRegionId]
  );

  const selectedCourier = useMemo<Courier | undefined>(
    () =>
      selectedCourierId
        ? selectedRegion?.couriers.find((c) => c.id === selectedCourierId)
        : undefined,
    [selectedCourierId, selectedRegion]
  );

  function handleNewRegionBtnClick() {
    setIsEditingRegion(false);
    setIsAddEditRegionDialogShown(true);
  }

  function handleViewRegionBtnClick() {
    setIsViewRegionDialogShown(true);
  }

  function handleEditRegionBtnClick() {
    setIsViewRegionDialogShown(false);
    setIsEditingRegion(true);
    setIsAddEditRegionDialogShown(true);
  }

  function handleDeleteRegionBtnClick() {
    setIsDeleteRegionDialogShown(true);
  }

  function handleNewCourierBtnClick() {
    setIsEditingCourier(false);
    setIsAddEditCourierDialogShown(true);
  }

  function handleViewCourierBtnClick() {
    setIsViewCourierDialogShown(true);
  }

  function handleEditCourierBtnClick() {
    setIsViewCourierDialogShown(false);
    setIsEditingCourier(true);
    setIsAddEditCourierDialogShown(true);
  }

  function handleDeleteCourierBtnClick() {
    setIsDeleteCourierDialogShown(true);
  }

  const handleRegionDeletion = useCallback(
    (name: string) => {
      if (toastRef.current) {
        toastRef.current.show({
          severity: 'success',
          summary: t('Success'),
          detail: t('Region {{name}} has been deleted.', { name: name }),
        });
      }

      setSelectedRegionId(null);
      setIsDeleteRegionDialogShown(false);
      reload();
    },
    [reload, t]
  );

  const handleCreateRegion = useCallback(
    (name: string) => {
      if (toastRef.current) {
        toastRef.current.show({
          severity: 'success',
          summary: t('Success'),
          detail: t('Region {{name}} has been created.', { name: name }),
        });
      }

      setIsAddEditRegionDialogShown(false);
      reload();
    },
    [reload, t]
  );

  const handleEditRegion = useCallback(
    (name: string) => {
      if (toastRef.current) {
        toastRef.current.show({
          severity: 'success',
          summary: t('Success'),
          detail: t('Changes for region {{name}} were applied.', {
            name: name,
          }),
        });
      }

      setIsAddEditRegionDialogShown(false);
      reload();
    },
    [reload, t]
  );

  const handleCourierDeletion = useCallback(
    (courierName: string, regionName: string) => {
      if (toastRef.current) {
        toastRef.current.show({
          severity: 'success',
          summary: t('Success'),
          detail: t(
            'Courier {{courierName}} has been removed from region {{regionName}}.',
            { courierName: courierName, regionName: regionName }
          ),
        });
      }

      setSelectedCourierId(null);
      setIsDeleteCourierDialogShown(false);
      reload();
    },
    [reload, t]
  );

  const handleCreateCourier = useCallback(
    (courierName: string, regionName: string) => {
      if (toastRef.current) {
        toastRef.current.show({
          severity: 'success',
          summary: t('Success'),
          detail: t(
            'Courier {{courierName}} has been assigned to region {{regionName}}.',
            { courierName: courierName, regionName: regionName }
          ),
        });
      }

      setIsAddEditCourierDialogShown(false);
      reload();
    },
    [reload, t]
  );

  const handleEditCourier = useCallback(
    (courierName: string, regionName: string) => {
      if (toastRef.current) {
        toastRef.current.show({
          severity: 'success',
          summary: t('Success'),
          detail: t(
            'Changes for {{courierName}} in region {{regionName}} were applied.',
            { courierName: courierName, regionName: regionName }
          ),
        });
      }

      setIsAddEditCourierDialogShown(false);
      reload();
    },
    [reload, t]
  );

  const handleRegionError = useCallback(
    (name: string) => {
      if (toastRef.current) {
        toastRef.current.show({
          severity: 'error',
          summary: t('Error'),
          detail: t('{{name}} {{status}} failed.', {
            name: name,
            status: isEditingRegion ? t('editing') : t('creation'),
          }),
          life: 5000,
        });
      }
    },
    [isEditingRegion, t]
  );

  const handleCourierError = useCallback(
    (courierName: string, regionName: string) => {
      if (toastRef.current) {
        toastRef.current.show({
          severity: 'error',
          summary: t('Error'),
          detail: t('Courier {{name}} for region {{regionName}} failed.', {
            name: isEditingCourier
              ? courierName + t(' editing')
              : t('assignment'),
            regionName: regionName,
          }),
          life: 5000,
        });
      }
    },
    [isEditingCourier, t]
  );

  useEffect(() => {
    if (
      (!selectedRegion && sortedRegions.length === 0) ||
      (selectedCourierId &&
        selectedRegion?.couriers.find((c) => c.id === selectedCourierId))
    ) {
      return;
    }

    setSelectedCourierId(selectedRegion?.couriers?.[0]?.id ?? null);
  }, [selectedCourierId, selectedRegion, sortedRegions.length]);

  useEffect(() => {
    if (!addRegionGuard && isAddEditRegionDialogShown && !isEditingRegion) {
      setIsAddEditRegionDialogShown(false);
    }
  }, [addRegionGuard, isAddEditRegionDialogShown, isEditingRegion]);

  useEffect(() => {
    if (!editRegionGuard && isAddEditRegionDialogShown && isEditingRegion) {
      setIsAddEditRegionDialogShown(false);
    }
  }, [editRegionGuard, isAddEditRegionDialogShown, isEditingRegion]);

  useEffect(() => {
    if (!deleteRegionGuard) {
      setIsDeleteRegionDialogShown(false);
    }
  }, [deleteRegionGuard]);

  useEffect(() => {
    if (
      !assignCourierGuard &&
      isAddEditCourierDialogShown &&
      !isEditingCourier
    ) {
      setIsAddEditCourierDialogShown(false);
    }
  }, [assignCourierGuard, isAddEditCourierDialogShown, isEditingCourier]);

  useEffect(() => {
    if (!editCourierGuard && isAddEditCourierDialogShown && isEditingCourier) {
      setIsAddEditCourierDialogShown(false);
    }
  }, [editCourierGuard, isAddEditCourierDialogShown, isEditingCourier]);

  useEffect(() => {
    if (!deleteCourierGuard) {
      setIsDeleteCourierDialogShown(false);
    }
  }, [deleteCourierGuard]);

  return (
    <div className="page regions-page">
      <HeaderPages
        title={t('Regions')}
        subtitle={t(
          'View and manage regions along with their assigned couriers'
        )}
        icon={faMap}
      />

      <div className="p-grid">
        <div className="p-col">
          <RegionsMap
            regions={sortedRegions}
            selectedRegionId={selectedRegionId}
            setSelectedRegionId={setSelectedRegionId}
          />
        </div>

        <div className="p-col-fixed" style={{ width: '300px' }}>
          <Sidebar
            regions={sortedRegions}
            selectedRegion={selectedRegion}
            selectedRegionId={selectedRegionId}
            setSelectedRegionId={setSelectedRegionId}
            selectedCourierId={selectedCourierId}
            setSelectedCourierId={setSelectedCourierId}
            handleNewRegionBtnClick={handleNewRegionBtnClick}
            handleViewRegionBtnClick={handleViewRegionBtnClick}
            handleEditRegionBtnClick={handleEditRegionBtnClick}
            handleDeleteRegionBtnClick={handleDeleteRegionBtnClick}
            handleNewCourierBtnClick={handleNewCourierBtnClick}
            handleViewCourierBtnClick={handleViewCourierBtnClick}
            handleEditCourierBtnClick={handleEditCourierBtnClick}
            handleDeleteCourierBtnClick={handleDeleteCourierBtnClick}
          />
        </div>
      </div>

      <AddEditRegionDialog
        isShown={isAddEditRegionDialogShown}
        regions={sortedRegions}
        selectedRegion={selectedRegion}
        isEditDialog={isEditingRegion}
        onHide={() => setIsAddEditRegionDialogShown(false)}
        onCreateSubmit={handleCreateRegion}
        onEditSubmit={handleEditRegion}
        onError={handleRegionError}
      />

      <ViewRegionDialog
        isShown={isViewRegionDialogShown}
        selectedRegion={selectedRegion}
        onHide={() => setIsViewRegionDialogShown(false)}
        onEditBtnClick={handleEditRegionBtnClick}
      />

      <DeleteRegionDialog
        isShown={isDeleteRegionDialogShown}
        selectedRegion={selectedRegion}
        onHide={() => setIsDeleteRegionDialogShown(false)}
        onDeleteSuccess={handleRegionDeletion}
        onError={handleRegionError}
      />

      <ViewCourierDialog
        isShown={isViewCourierDialogShown}
        selectedRegion={selectedRegion}
        selectedCourier={selectedCourier}
        onHide={() => setIsViewCourierDialogShown(false)}
        onEditBtnClick={handleEditCourierBtnClick}
      />

      <AddEditCourierDialog
        isShown={isAddEditCourierDialogShown}
        isEditDialog={isEditingCourier}
        selectedRegion={selectedRegion}
        selectedCourier={selectedCourier}
        onHide={() => setIsAddEditCourierDialogShown(false)}
        onCreateSubmit={handleCreateCourier}
        onEditSubmit={handleEditCourier}
        onError={handleCourierError}
      />

      <DeleteCourierDialog
        isShown={isDeleteCourierDialogShown}
        selectedRegion={selectedRegion}
        selectedCourier={selectedCourier}
        onHide={() => setIsDeleteCourierDialogShown(false)}
        onDeleteSucess={handleCourierDeletion}
        onError={handleCourierError}
      />

      <Toast ref={toastRef} />
    </div>
  );
}

export default Regions;
