import { Field, useFormikContext } from 'formik';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { useTranslation } from 'react-i18next';

import useAxiosHook from '../../../../hooks/useAxiosHook';
import FieldWithErrors from '../../../Forms/FieldWithErrors/FieldWithErrors';

function CustomerInputs({ section }) {
  const { t } = useTranslation();

  const { values } = useFormikContext();

  const { data: priceListsData, isLoading: isPriceListsDataLoading } =
    useAxiosHook('/price-lists?limit=0');

  const priceListOptions = priceListsData?.data.map((p) => ({
    label: p.name ?? '',
    value: parseInt(p.id),
  }));

  return (
    <>
      <FieldWithErrors
        name={`${section}-DefaultPricelistId`}
        label={t('Default price list')}
      >
        <Field
          as={Dropdown}
          inputId={`${section}-DefaultPricelistId`}
          name={`${section}-DefaultPricelistId`}
          options={priceListOptions}
          tooltip={t(
            'SMS provider used for sending SMS messages as notifications'
          )}
          disabled={isPriceListsDataLoading}
          placeholder={isPriceListsDataLoading ? t('Loading...') : null}
        />
      </FieldWithErrors>

      <FieldWithErrors name={`${section}-RequiredEmail`} label={false}>
        <Field
          as={Checkbox}
          inputId={`${section}-RequiredEmail`}
          name={`${section}-RequiredEmail`}
          checked={values[`${section}-RequiredEmail`]}
        />
        <label htmlFor={`${section}-RequiredEmail`}>
          {t('Required email')}
        </label>
      </FieldWithErrors>

      <FieldWithErrors name={`${section}-RequiredMobileOrPhone`} label={false}>
        <Field
          as={Checkbox}
          inputId={`${section}-RequiredMobileOrPhone`}
          name={`${section}-RequiredMobileOrPhone`}
          checked={values[`${section}-RequiredMobileOrPhone`]}
        />
        <label htmlFor={`${section}-RequiredMobileOrPhone`}>
          {t('Required mobile or phone')}
        </label>
      </FieldWithErrors>

      <FieldWithErrors name={`${section}-RequiredBankAccount`} label={false}>
        <Field
          as={Checkbox}
          inputId={`${section}-RequiredBankAccount`}
          name={`${section}-RequiredBankAccount`}
          checked={values[`${section}-RequiredBankAccount`]}
        />
        <label htmlFor={`${section}-RequiredBankAccount`}>
          {t('Required bank account')}
        </label>
      </FieldWithErrors>

      <FieldWithErrors name={`${section}-RequiredEntityNumber`} label={false}>
        <Field
          as={Checkbox}
          inputId={`${section}-RequiredEntityNumber`}
          name={`${section}-RequiredEntityNumber`}
          checked={values[`${section}-RequiredEntityNumber`]}
        />
        <label htmlFor={`${section}-RequiredEntityNumber`}>
          {t('Required social security number')}
        </label>
      </FieldWithErrors>

      <FieldWithErrors name={`${section}-RequiredTaxNumber`} label={false}>
        <Field
          as={Checkbox}
          inputId={`${section}-RequiredTaxNumber`}
          name={`${section}-RequiredTaxNumber`}
          checked={values[`${section}-RequiredTaxNumber`]}
        />
        <label htmlFor={`${section}-RequiredTaxNumber`}>
          {t('Required tax number')}
        </label>
      </FieldWithErrors>

      <FieldWithErrors name={`${section}-RequiredHouseNumber`} label={false}>
        <Field
          as={Checkbox}
          inputId={`${section}-RequiredHouseNumber`}
          name={`${section}-RequiredHouseNumber`}
          checked={values[`${section}-RequiredHouseNumber`]}
        />
        <label htmlFor={`${section}-RequiredHouseNumber`}>
          {t('Required street number')}
        </label>
      </FieldWithErrors>
    </>
  );
}

export default CustomerInputs;
