import { faCarSide } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'primereact/button';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useAxiosHook from '../../../hooks/useAxiosHook';
import { useEndpointGuard } from '../../../hooks/useEndpointGuard';
import usePageTitle from '../../../hooks/usePageTitle';
import useRouteDialog from '../../../hooks/useRouteDialog';
import useTableColumns from '../../../hooks/useTableColumns';
import useTableState from '../../../hooks/useTableState';
import * as vehiclesGuards from '../../../utils/constants/auth/vehicles';
import {
  RoutePaths,
  constructIdRoute,
} from '../../../utils/constants/routePaths';
import { queryString } from '../../../utils/helpers/http';
import Table from '../../DataTable/Table/Table';
import Flex from '../../layout/flex/Flex';
import MainContent from '../../layout/flex/MainContent';
import Filters from '../Components/Filters/Filters';
import HeaderPages from '../Components/HeaderPages/HeaderPages';
import AddEditDialog from './Dialogs/AddEdit/AddEditDialog';
import DeleteDialog from './Dialogs/Delete/DeleteDialog';
import ViewDialog from './Dialogs/View/ViewDialog';
import useTableFilters from './useTableFilters';
import {
  ApiData,
  Vehicle,
  additionalColumnProperties,
  generateContextMenu,
  getColumnHeadersMap,
  tableStorageKey,
} from './Vehicles.functions';

function Vehicles(): JSX.Element {
  const { t } = useTranslation();
  const addGuard = useEndpointGuard(vehiclesGuards.addVehicle);
  const editGuard = useEndpointGuard(vehiclesGuards.editVehicle);
  const deleteGuard = useEndpointGuard(vehiclesGuards.deleteVehicle);
  const columnHeadersMap = useMemo(() => getColumnHeadersMap(t), [t]);
  usePageTitle(t('Vehicles'));

  const {
    tableRef,
    page,
    setPage,
    limit,
    setLimit,
    sortField,
    sortOrder,
    selection,
    setSortField,
    setSortOrder,
    setSelection,
  } = useTableState<Vehicle>(tableStorageKey);

  const { filters, headerFiltersCount, resetAllFilters, httpFiltersObj } =
    useTableFilters(page, limit);

  const { selectedColumns, setSelectedColumns, columnOptions, columns } =
    useTableColumns(
      page,
      limit,
      'vehicles',
      columnHeadersMap,
      columnHeadersMap,
      (c) =>
        additionalColumnProperties(
          t,
          c as keyof ReturnType<typeof getColumnHeadersMap>
        )
    );

  const {
    show: showViewDialog,
    hide: hideViewDialog,
    isVisible: isViewDialogVisible,
  } = useRouteDialog(
    RoutePaths.Vehicles,
    constructIdRoute(RoutePaths.ViewVehicle, selection?.id)
  );

  const {
    show: showAddDialog,
    hide: hideAddDialog,
    isVisible: isAddDialogVisible,
  } = useRouteDialog(RoutePaths.ViewVehicle, RoutePaths.AddVehicle);

  const {
    show: showEditDialog,
    hide: hideEditDialog,
    isVisible: isEditDialogVisible,
  } = useRouteDialog(
    RoutePaths.Vehicles,
    constructIdRoute(RoutePaths.EditVehicle, selection?.id)
  );

  const {
    show: showDeleteDialog,
    hide: hideDeleteDialog,
    isVisible: isDeleteDialogVisible,
  } = useRouteDialog(
    RoutePaths.Vehicles,
    constructIdRoute(RoutePaths.DeleteVehicle, selection?.id)
  );

  const { data, error, reload, isLoading } = useAxiosHook<ApiData>(
    '/vehicles' + queryString(httpFiltersObj)
  );

  useEffect(() => {
    if (!addGuard) {
      hideAddDialog();
    }
  }, [addGuard, hideAddDialog]);

  useEffect(() => {
    if (!editGuard) {
      hideEditDialog();
    }
  }, [editGuard, hideEditDialog]);

  useEffect(() => {
    if (!deleteGuard) {
      hideDeleteDialog();
    }
  }, [deleteGuard, hideDeleteDialog]);

  return (
    <div className="page vehicle-management-page">
      <HeaderPages
        title={t('Vehicles')}
        subtitle={t('View and manage vehicles')}
        icon={faCarSide}
      >
        {addGuard && (
          <Button
            type="button"
            label={t('Add vehicle')}
            icon="fas fa-plus"
            className="main-btn"
            onClick={() => showAddDialog()}
            data-cy="add-vehicle"
          />
        )}
      </HeaderPages>

      <AddEditDialog
        data={isEditDialogVisible ? selection : null}
        isEditDialog={isEditDialogVisible}
        onHide={() => {
          hideAddDialog();
          hideEditDialog();
        }}
        tableReload={reload}
        visible={isAddDialogVisible || isEditDialogVisible}
      />

      <ViewDialog
        visible={isViewDialogVisible}
        data={selection}
        onHide={hideViewDialog}
        onEditClick={showEditDialog}
      />

      <DeleteDialog
        visible={isDeleteDialogVisible}
        onHide={hideDeleteDialog}
        vehicle={selection}
        tableReload={reload}
      />

      <Flex direction="column">
        <Filters
          filters={filters}
          resetAllFilters={resetAllFilters}
          headerFiltersCount={headerFiltersCount}
          filterHeight={220}
        />
        <MainContent>
          <Table
            tableRef={tableRef}
            columns={columns}
            data={data}
            isLoading={isLoading}
            hasError={!!error}
            reload={reload}
            headerTitle=""
            headerFiltersCount={headerFiltersCount}
            onHeaderFiltersResetAllBtnClick={resetAllFilters}
            setPage={setPage}
            setLimit={setLimit}
            sortField={sortField}
            rows={limit}
            filterHeight={220}
            contextMenuModel={generateContextMenu(
              t,
              showViewDialog,
              editGuard,
              showEditDialog,
              deleteGuard,
              showDeleteDialog
            )}
            setSortField={setSortField}
            setSortOrder={setSortOrder}
            setSelection={setSelection}
            sortOrder={sortOrder}
            selection={selection}
            storageString={tableStorageKey}
            rebuildTooltip
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            columnOptions={columnOptions}
            onRowDoubleClick={showViewDialog}
          />
        </MainContent>
      </Flex>
    </div>
  );
}

export default Vehicles;
