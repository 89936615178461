import { Field, useFormikContext } from 'formik';
import { Dropdown } from 'primereact/dropdown';
import { Tooltip } from 'primereact/tooltip';
import { useTranslation } from 'react-i18next';

import FieldWithErrors from '../../../../../../Forms/FieldWithErrors/FieldWithErrors';

function isDisabled(
  values,
  isHeadquarter,
  headquarterData,
  isHeadquarterDataLoading,
  arePriceListsLoading
) {
  if (arePriceListsLoading || isHeadquarterDataLoading) {
    return true;
  } else {
    if (isHeadquarter) {
      return false;
    } else {
      if (values.pravno_lice) {
        return (
          values.direkcija_id !== null &&
          headquarterData?.cenovnik_direkcija === 1
        );
      } else {
        return false;
      }
    }
  }
}

function PriceListField({
  priceListOptions,
  arePriceListsLoading,
  isHeadquarter,
  headquarterData,
  isHeadquarterDataLoading,
}) {
  const { t } = useTranslation();

  const { values } = useFormikContext();

  const isFieldDisabled = isDisabled(
    values,
    isHeadquarter,
    headquarterData,
    isHeadquarterDataLoading,
    arePriceListsLoading
  );

  return (
    <>
      <Tooltip
        target=".pricelist-tooltip"
        position="left"
        style={!isFieldDisabled ? { display: 'none' } : {}}
      />

      <div
        className="pricelist-tooltip"
        data-pr-tooltip={
          !arePriceListsLoading && !isHeadquarterDataLoading && isFieldDisabled
            ? t(
                'The headquarter is using one price list for all of its subsidiaries.'
              )
            : ''
        }
      >
        <FieldWithErrors name="cenovnik_id" label={t('Price list')}>
          <Field
            as={Dropdown}
            name="cenovnik_id"
            inputId="cenovnik_id"
            options={priceListOptions}
            value={
              arePriceListsLoading || isHeadquarterDataLoading
                ? t('Loading...')
                : values.cenovnik_id
            }
            placeholder={
              arePriceListsLoading || isHeadquarterDataLoading
                ? t('Loading...')
                : null
            }
            disabled={isFieldDisabled}
            className="data-cy-price_list"
          />
        </FieldWithErrors>
      </div>
    </>
  );
}

export default PriceListField;
