import { ComponentProps, Dispatch, SetStateAction, useState } from 'react';

import Barcode from './Barcode';

type Return = Required<
  Pick<
    ComponentProps<typeof Barcode>,
    | 'value'
    | 'setValue'
    | 'hasError'
    | 'setHasError'
    | 'scannedBarcodes'
    | 'isInvalidPrefix'
    | 'setIsInvalidPrefix'
  >
> & {
  setScannedBarcodes: Dispatch<SetStateAction<string[]>>;
};

function useBarcodeState(): Return {
  const [value, setValue] = useState('');
  const [hasError, setHasError] = useState(false);
  const [scannedBarcodes, setScannedBarcodes] = useState<string[]>([]);
  const [isInvalidPrefix, setIsInvalidPrefix] = useState<boolean>(false);

  return {
    value,
    setValue,
    hasError,
    setHasError,
    isInvalidPrefix,
    setIsInvalidPrefix,
    scannedBarcodes,
    setScannedBarcodes,
  };
}

export default useBarcodeState;
