import { useFormikContext } from 'formik';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';

import emptyIllustration from '../../../../../../../assets/svg/no-data-pana.svg';
import { FormFields } from '../../CreateEditDialog.types';
import styles from './Discounts.module.scss';

function EmptyContent(): JSX.Element {
  const { t } = useTranslation();

  const { setFieldValue } = useFormikContext<FormFields>();

  return (
    <div className={styles.emptyContent}>
      <img src={emptyIllustration} alt="" className="illustration" />
      <p>
        {t('No discounts are present yet.')}
        <br />
        {t('Add the first one by clicking the button below!')}
      </p>

      <Button
        type="button"
        label={t('Add discount')}
        icon="fas fa-plus"
        onClick={() =>
          setFieldValue('popust', [
            {
              UslugaId: null,
              ProizvodId: null,
              StatusId: null,
              Popust: '',
            },
          ])
        }
        className="p-button-outlined"
      />
    </div>
  );
}

export default EmptyContent;
