import './HubCart.scss';

import { useTranslation } from 'react-i18next';

import { Hubs } from '../HubsReports.functions';

function HubCart({ hub, onClickHub }: { hub: Hubs; onClickHub: Function }) {
  const { t } = useTranslation();
  const types = hub?.stats?.map((item) => Object.keys(item)[0]);
  return (
    <div
      key={hub?.id}
      className="hub-report-cart"
      onClick={() => onClickHub(hub)}
    >
      <div key={'hub_report_info' + hub?.id} className="hub-report-cart-info">
        <h2 className="hub-report-cart-title">{hub?.name}</h2>
        <div className="hub-report-cart-details">
          <div>
            <span className="hub-report-label">{t(`Working hours`)}:</span>{' '}
            <span>{hub?.working_hours}</span>
          </div>
          <div>
            <span className="hub-report-label">{t('Location')}:</span>{' '}
            <span>
              {hub?.place} <span className="hub-reports-address-span">/</span>{' '}
              {hub?.municipality}
            </span>
          </div>
          <div>
            <span className="hub-report-label">{t('Phone')}:</span>{' '}
            <span>{hub?.phone}</span>
          </div>
        </div>
      </div>
      <div className="hub-reports">
        {hub?.stats?.map((item: any, index: number) => (
          <div key={'item_' + item?.id + index} className="hub-report">
            <table className="hub-reports-table">
              <thead>
                <tr>
                  <th className="th-title" colSpan={10}>
                    {t(types[index])}
                  </th>
                </tr>
                <tr>
                  <th colSpan={4}>{t('Package')} </th>
                  <th colSpan={6}>{t('Letter')}</th>
                  <th colSpan={2}>{t('Redemption')}</th>
                </tr>
                <tr>
                  <th colSpan={2}>{t('Regular')} </th>
                  <th colSpan={2}>{t('Oversized')}</th>
                  <th colSpan={2}>{t('Unregistered')} </th>
                  <th colSpan={2}>{t('Registered')}</th>
                  <th colSpan={2}>{t('Personal Delivery')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    className={`${
                      item[types[index]].package.regular.assigned ===
                      item[types[index]].package.regular.received
                        ? 'report-table-same'
                        : 'report-table-different'
                    }`}
                  >
                    {t('Assignees.SHE')}
                  </td>
                  <td
                    className={`${
                      item[types[index]].package.regular.assigned ===
                      item[types[index]].package.regular.received
                        ? 'report-table-same'
                        : 'report-table-different'
                    }`}
                  >
                    {item[types[index]].package.regular.assigned}
                  </td>
                  <td
                    className={`${
                      item[types[index]].package.oversized.assigned ===
                      item[types[index]].package.oversized.received
                        ? 'report-table-same'
                        : 'report-table-different'
                    }`}
                  >
                    {t('Assignees.SHE')}
                  </td>
                  <td
                    className={`${
                      item[types[index]].package.oversized.assigned ===
                      item[types[index]].package.oversized.received
                        ? 'report-table-same'
                        : 'report-table-different'
                    }`}
                  >
                    {item[types[index]].package.oversized.assigned}
                  </td>
                  <td
                    className={`${
                      item[types[index]].letter.regular.assigned ===
                      item[types[index]].letter.regular.received
                        ? 'report-table-same'
                        : 'report-table-different'
                    }`}
                  >
                    {t('Assignees.SHE')}
                  </td>
                  <td
                    className={`${
                      item[types[index]].letter.regular.assigned ===
                      item[types[index]].letter.regular.received
                        ? 'report-table-same'
                        : 'report-table-different'
                    }`}
                  >
                    {item[types[index]].letter.regular.assigned}
                  </td>
                  <td
                    className={`${
                      item[types[index]].letter.registered.assigned ===
                      item[types[index]].letter.registered.received
                        ? 'report-table-same'
                        : 'report-table-different'
                    }`}
                  >
                    {t('Assignees.SHE')}
                  </td>
                  <td
                    className={`${
                      item[types[index]].letter.registered.assigned ===
                      item[types[index]].letter.registered.received
                        ? 'report-table-same'
                        : 'report-table-different'
                    }`}
                  >
                    {item[types[index]].letter.registered.assigned}
                  </td>
                  <td
                    className={`${
                      item[types[index]].letter.personal_delivery.assigned ===
                      item[types[index]].letter.personal_delivery.received
                        ? 'report-table-same'
                        : 'report-table-different'
                    }`}
                  >
                    {t('Assignees.SHE')}
                  </td>
                  <td
                    className={`${
                      item[types[index]].letter.personal_delivery.assigned ===
                      item[types[index]].letter.personal_delivery.received
                        ? 'report-table-same'
                        : 'report-table-different'
                    }`}
                  >
                    {item[types[index]].letter.personal_delivery.assigned}
                  </td>
                  <td
                    className={`${
                      item[types[index]].redemption[''].assigned ===
                      item[types[index]].redemption[''].received
                        ? 'report-table-same'
                        : 'report-table-different'
                    }`}
                  >
                    {t('Assignees.SHE')}
                  </td>
                  <td
                    className={`${
                      item[types[index]].redemption[''].assigned ===
                      item[types[index]].redemption[''].received
                        ? 'report-table-same'
                        : 'report-table-different'
                    }`}
                  >
                    {item[types[index]].redemption[''].assigned}
                  </td>
                </tr>
                <tr>
                  <td
                    className={`${
                      item[types[index]].package.regular.assigned ===
                      item[types[index]].package.regular.received
                        ? 'report-table-same'
                        : 'report-table-different'
                    }`}
                  >
                    {t('Received.THEM')}
                  </td>
                  <td
                    className={`${
                      item[types[index]].package.regular.assigned ===
                      item[types[index]].package.regular.received
                        ? 'report-table-same'
                        : 'report-table-different'
                    }`}
                  >
                    {item[types[index]].package.regular.received}
                  </td>
                  <td
                    className={`${
                      item[types[index]].package.oversized.assigned ===
                      item[types[index]].package.oversized.received
                        ? 'report-table-same'
                        : 'report-table-different'
                    }`}
                  >
                    {t('Received.THEM')}
                  </td>
                  <td
                    className={`${
                      item[types[index]].package.oversized.assigned ===
                      item[types[index]].package.oversized.received
                        ? 'report-table-same'
                        : 'report-table-different'
                    }`}
                  >
                    {item[types[index]].package.oversized.received}
                  </td>
                  <td
                    className={`${
                      item[types[index]].letter.regular.assigned ===
                      item[types[index]].letter.regular.received
                        ? 'report-table-same'
                        : 'report-table-different'
                    }`}
                  >
                    {t('Received.THEM')}
                  </td>
                  <td
                    className={`${
                      item[types[index]].letter.regular.assigned ===
                      item[types[index]].letter.regular.received
                        ? 'report-table-same'
                        : 'report-table-different'
                    }`}
                  >
                    {item[types[index]].letter.regular.received}
                  </td>
                  <td
                    className={`${
                      item[types[index]].letter.registered.assigned ===
                      item[types[index]].letter.registered.received
                        ? 'report-table-same'
                        : 'report-table-different'
                    }`}
                  >
                    {t('Received.THEM')}
                  </td>
                  <td
                    className={`${
                      item[types[index]].letter.registered.assigned ===
                      item[types[index]].letter.registered.received
                        ? 'report-table-same'
                        : 'report-table-different'
                    }`}
                  >
                    {item[types[index]].letter.registered.received}
                  </td>
                  <td
                    className={`${
                      item[types[index]].letter.personal_delivery.assigned ===
                      item[types[index]].letter.personal_delivery.received
                        ? 'report-table-same'
                        : 'report-table-different'
                    }`}
                  >
                    {t('Received.THEM')}
                  </td>
                  <td
                    className={`${
                      item[types[index]].letter.personal_delivery.assigned ===
                      item[types[index]].letter.personal_delivery.received
                        ? 'report-table-same'
                        : 'report-table-different'
                    }`}
                  >
                    {item[types[index]].letter.personal_delivery.received}
                  </td>
                  <td
                    className={`${
                      item[types[index]].redemption[''].assigned ===
                      item[types[index]].redemption[''].received
                        ? 'report-table-same'
                        : 'report-table-different'
                    }`}
                  >
                    {t('Received.THEM')}
                  </td>
                  <td
                    className={`${
                      item[types[index]].redemption[''].assigned ===
                      item[types[index]].redemption[''].received
                        ? 'report-table-same'
                        : 'report-table-different'
                    }`}
                  >
                    {item[types[index]].redemption[''].received}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ))}
      </div>
    </div>
  );
}

export default HubCart;
