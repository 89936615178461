import classNames from 'classnames';
import { Link, LinkProps } from 'react-router-dom';

type Props = {
  label: string;
  icon?: string;
} & LinkProps;

function LinkButton({
  label,
  icon,
  to,
  className,
  ...otherLinkProps
}: Props): JSX.Element {
  return (
    <Link
      to={to}
      className={classNames('p-button p-component', className)}
      style={{ width: '100%' }}
      {...otherLinkProps}
    >
      {icon && (
        <span
          className={classNames('p-button-icon p-c p-button-icon-left', icon)}
        />
      )}

      <span className="p-button-label p-c">{label}</span>
    </Link>
  );
}

export default LinkButton;
