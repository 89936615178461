import { TFunction } from 'react-i18next';

import { emptyCell, noColumnStyle } from '../../../../utils/constants/tables';
import { dateCell } from '../../../../utils/helpers/dataTable';
import { currencyFormat } from '../../../../utils/helpers/formatting';

export const tableStorageKey = 'cod_create_warrants_dataTable';

export type ParcelCandidate = {
  id: number;
  no: string;
  status_ime: string | null;
  created_by_full_name: string | null;
  modified_by_full_name: string | null;
  klient_od_ime: string | null;
  klient_do_ime: string | null;
  barkod: string | null;
  seriski_broj: string | null;
  datum_kreiranje: string | null;
  datum_priem: string | null;
  datum_isporaka: string | null;
  tezina: string | null;
  cena: string | null;
  otkup: string | null;
  adresa_od: string | null;
  adresa_do: string | null;
  klient_od_id: number | null;
  klient_od_direkcija_id: number | null;
};

export function getParcelsColumnHeadersMap(
  t: TFunction
): Record<
  | keyof Omit<
      ParcelCandidate,
      'id' | 'klient_od_id' | 'klient_od_direkcija_id'
    >
  | 'no',
  string
> {
  return {
    no: t('No.'),
    status_ime: t('Status'),
    created_by_full_name: t('Created By'),
    modified_by_full_name: t('Modified By'),
    klient_od_ime: t('Client From'),
    klient_do_ime: t('Client To'),
    barkod: t('Barcode'),
    seriski_broj: t('Serial No'),
    datum_kreiranje: t('Created at'),
    datum_priem: t('Received at'),
    datum_isporaka: t('Delivered at'),
    tezina: t('Weight'),
    cena: t('Price'),
    otkup: t('Redemption'),
    adresa_od: t('Address From'),
    adresa_do: t('Address To'),
  };
}

export type ClientTreeNode = {
  id: number;
  ime: string;
  datum_isporaka: string;
  otkup: string;
  count: string;
};

type TreeNode = {
  key: string;
  label: string;
  children: FirstLevelTreeNode[];
};

type FirstLevelTreeNode = {
  key: string;
  label: string;
  children: SecondLevelTreeNode[];
  totalCount: string;
  totalRansom: string;
};

type SecondLevelTreeNode = ClientTreeNode & {
  key: string;
  label: string;
};
export type ApiData = {
  parcels: ParcelCandidate[];
  client_tree: ClientTreeNode[];
};

export type GroupFilter = 'ime' | 'datum_isporaka';

export function generateTreeNodes(
  t: TFunction,
  clients: ClientTreeNode[],
  parentGroupKey: GroupFilter,
  childGroupKey: GroupFilter
): TreeNode[] {
  if (!clients) {
    return [];
  }

  let groupedData = {} as Record<
    GroupFilter,
    Record<GroupFilter, ClientTreeNode>
  >;

  let nodes: FirstLevelTreeNode[] = [];

  for (const c of clients) {
    const parent = c[parentGroupKey] as GroupFilter;
    const child = c[childGroupKey] as GroupFilter;

    if (!groupedData[parent]) {
      groupedData[parent] = {
        [child]: c,
      } as Record<GroupFilter, ClientTreeNode>;
    } else {
      if (!groupedData[parent][child]) {
        groupedData[parent][child] = c;
      } else {
        groupedData[parent][child] = {
          ...groupedData[parent][child],
          count: String(
            parseFloat(groupedData[parent][child].count) + parseFloat(c.count)
          ),
          otkup: String(
            parseFloat(groupedData[parent][child].otkup) + parseFloat(c.otkup)
          ),
        };
      }
    }
  }

  for (const key in groupedData) {
    let totalCount = 0;
    let totalRansom = 0;
    let nodeChildren: SecondLevelTreeNode[] = [];

    const children = Object.values(groupedData[key as GroupFilter]);
    for (let c of children) {
      nodeChildren.push({
        key: `${c.datum_isporaka}-${c.id}`,
        label: c[childGroupKey],
        ...c,
      });

      totalCount += parseInt(c.count);
      totalRansom += parseInt(c.otkup);
    }

    const node: FirstLevelTreeNode = {
      key,
      label: key,
      children: nodeChildren,
      totalCount: String(totalCount),
      totalRansom: String(totalRansom),
    };

    nodes.push(node);
  }

  return [
    {
      key: 'all',
      label: t('Select all {{length}}', { length: nodes.length }),
      children: nodes,
    },
  ];
}

export function getNodeTemplate(t: TFunction) {
  return function (
    node: TreeNode | FirstLevelTreeNode | SecondLevelTreeNode
  ): JSX.Element {
    const count =
      (node as FirstLevelTreeNode).totalCount ??
      (node as SecondLevelTreeNode).count;

    const ransom =
      (node as FirstLevelTreeNode).totalRansom ??
      (node as SecondLevelTreeNode).otkup;

    return (
      <span className="tree-node">
        <span className="label">{node.label}</span>
        &nbsp;
        <span className="stats">
          {`${count ?? t('Total')} (${
            ransom ? currencyFormat(ransom) : t('Redemption')
          })`}
        </span>
      </span>
    );
  };
}

export function additionalParcelsColumnProperties(
  column: keyof ReturnType<typeof getParcelsColumnHeadersMap>
) {
  switch (column) {
    case 'no':
      return noColumnStyle;

    case 'status_ime':
      return {
        style: {
          width: 120,
        },
        bodyStyle: {
          width: 120,
        },
      };

    case 'created_by_full_name':
    case 'modified_by_full_name':
    case 'klient_od_ime':
    case 'klient_do_ime':
      return {
        style: {
          width: 250,
        },
        bodyStyle: {
          width: 250,
        },
      };

    case 'barkod':
    case 'seriski_broj':
    case 'adresa_od':
    case 'adresa_do':
      return {
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
        },
      };

    case 'datum_kreiranje':
    case 'datum_priem':
    case 'datum_isporaka':
      return {
        body: (row: ParcelCandidate) =>
          typeof row[column] === 'string' && !!row[column]?.trim()
            ? dateCell(row[column], true)
            : emptyCell,
        style: {
          width: 140,
        },
        bodyStyle: {
          width: 140,
        },
      };

    case 'tezina':
      return {
        body: (row: ParcelCandidate) =>
          `${Intl.NumberFormat().format(Number(row[column]))} kg`,
        style: {
          width: 100,
        },
        bodyStyle: {
          width: 100,
        },
      };

    case 'cena':
    case 'otkup':
      return {
        body: (row: ParcelCandidate) =>
          currencyFormat(row[column] ?? '', { showCurrency: true }),
        style: {
          width: 140,
        },
        bodyStyle: {
          width: 140,
        },
      };

    default:
      return {};
  }
}
