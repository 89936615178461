import { Column } from 'primereact/column';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useTableColumns from '../../../../hooks/useTableColumns';
import useTableDataToDisplay from '../../../../hooks/useTableDataToDisplay';
import useTableState from '../../../../hooks/useTableState';
import Table from '../../../DataTable/Table/Table';
import { CourierCompDocument } from '../CourierCompensation.functions';
import {
  additionalCCDColumnProperties,
  getCourierCompensationDocColumnHeadersMap,
} from '../CourierCompensation.functions';

type Props = {
  data: CourierCompDocument[];
  setSelectedCourierCompDoc: Dispatch<
    SetStateAction<CourierCompDocument | null>
  >;
};

function CompensationDocsTable({
  data,
  setSelectedCourierCompDoc,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const tableStorageKey = 'customer_compensation_doc_dataTable';

  const courierCompDocColumnHeadersMap = useMemo(
    () => getCourierCompensationDocColumnHeadersMap(t),
    [t]
  );

  const {
    tableRef,
    page,
    setPage,
    limit,
    setLimit,
    sortField,
    sortOrder,
    selection,
    setSelection,
    setSortField,
    setSortOrder,
  } = useTableState(tableStorageKey);

  const { selectedColumns, setSelectedColumns, columnOptions, columns } =
    useTableColumns(
      page,
      limit,
      'courierCompensation_compensationDocs',
      courierCompDocColumnHeadersMap,
      courierCompDocColumnHeadersMap,
      (c) =>
        additionalCCDColumnProperties(
          c as keyof typeof courierCompDocColumnHeadersMap
        )
    );

  function customDataModifier(
    data: CourierCompDocument[]
  ): CourierCompDocument[] {
    return data.sort((a: any, b: any) => b.date - a.date);
  }

  const dataToDisplay = useTableDataToDisplay(
    data,
    sortField,
    sortOrder,
    customDataModifier
  );

  const finalColumns = useMemo<JSX.Element[]>(
    () => [
      <Column
        key="__checkbox__"
        selectionMode="single"
        style={{ width: 34 }}
      />,
      ...columns,
    ],
    [columns]
  );

  return (
    <Table
      tableRef={tableRef}
      columns={finalColumns}
      data={dataToDisplay}
      hasError={false}
      isLoading={false}
      reload={() => console.log('reload')}
      setPage={setPage}
      setLimit={setLimit}
      sortField={sortField}
      lazy={false}
      rows={limit}
      setSortField={setSortField}
      setSortOrder={setSortOrder}
      onSelectionChange={(e) => {
        setSelection(e.value);
        setSelectedCourierCompDoc(e.value);
      }}
      setSelection={setSelection}
      selection={selection}
      sortOrder={sortOrder}
      storageString={tableStorageKey}
      rebuildTooltip
      selectedColumns={selectedColumns}
      setSelectedColumns={setSelectedColumns}
      columnOptions={columnOptions}
      selectionMode="single"
      headerTitle={t('Compensation Documents')}
    />
  );
}

export default CompensationDocsTable;
