import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';

import useAxiosHook from '../../../../../hooks/useAxiosHook';
import useToastMessage from '../../../../../hooks/useToastMessage';
import { AddPriceResourceRequestPayload } from '../../../../../types/api/prices';
import { sequential } from '../../../../../utils/helpers/functions';
import FormContainer from './FormContainer';

type Props = {
  isShown: boolean;
  onHide: () => void;
  reloadCollection: () => void;
};

function AddDialog({ isShown, onHide, reloadCollection }: Props): JSX.Element {
  const { t } = useTranslation();

  const { data, error, isLoading, reload } = useAxiosHook();

  useToastMessage(data, error, {
    success: {
      summary: t('The price has been added successfully.'),
      callback: () => sequential(reloadCollection, onHide),
    },
    error: {
      summary: t('An error occured while adding the price.'),
      callback: onHide,
    },
  });

  function handleFormSubmission(data: AddPriceResourceRequestPayload) {
    reload({ url: '/prices', method: 'POST', data });
  }

  const dialogFooter = (
    <>
      <Button
        type="button"
        label={t('Close')}
        onClick={onHide}
        className="p-button-text"
      />

      <Button
        type="submit"
        form="prices-add-price-form"
        label={isLoading ? t('Loading...') : t('Add')}
        disabled={isLoading}
      />
    </>
  );

  return (
    <Dialog
      header={t('Add a price')}
      footer={dialogFooter}
      visible={isShown}
      resizable={false}
      onHide={onHide}
      maximizable
      style={{ width: 600, maxWidth: '100%' }}
    >
      <FormContainer handleCreation={handleFormSubmission} />
    </Dialog>
  );
}

export default AddDialog;
