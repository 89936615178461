export enum UserRole {
  Courier = 3,
  TransferCourier = 7,
}

// DbTable "user_status"
export enum UserStatus {
  ActivationRequest = 1, // User requested activation
  ActivationAccepted = 2, // Admin activated user
  ActivationRejected = 3, // Admin rejected user
  Active = 4, // Active
  Blocked = 5, // Blocked
  PasswordReset = 6, // Reset password request was sent
  Delete = 7, // Deleted
}
