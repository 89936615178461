import {
  faBarcode,
  faExclamationTriangle,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranslation } from 'react-i18next';

import errorSignal from '../../../assets/sounds/error-signal.wav';
import ToastContext from '../../../context/ToastContext';
import useAxiosHook from '../../../hooks/useAxiosHook';
import useBarcodeScannerDetection from '../../../hooks/useBarcodeScannerDetection';
import { BarcodePrefixes } from '../../../types/api/orders';
import { XOR } from '../../../types/util';
import { playAudio } from '../../../utils/helpers/audio';
import { errorToast, infoToast } from '../../../utils/helpers/primereact';
import { defaultValues } from './Barcode.functions';
import styles from './Barcode.module.scss';
import { FormValues } from './Barcode.types';
import Form from './Form';

// 1) GET /orders/barcode-prefixes in its array might return "",
//  which is a wildcard allowing all prefixes.
//  This means that onInvalidPrefixScan will never fire, since all
//  prefixes would be valid.
//  prefixExceptions is used to "help" the component know which prefixes
//  are meant to be handled outside it.
//  Ex: LD123456 barcode (LD prefix) used for warehouse purposes.
// 2) scannedBarcodes are only used to display the last scanned barcode value here,
//  but the parent component might use such state for its internal logic.
type Props = {
  value: string;
  setValue: Dispatch<SetStateAction<string>> | ((value: string) => void);
  hasError: boolean;
  setHasError: Dispatch<SetStateAction<boolean>> | ((value: boolean) => void);
  isInvalidPrefix?: boolean;
  setIsInvalidPrefix?:
    | Dispatch<SetStateAction<boolean>>
    | ((value: boolean) => void);
  validityCheck?: (barcode: string) => boolean;
  errorMessage?: string;
  disabled?: boolean;
  infoMessage?: string;
  placeholder?: string;
} & XOR<
  {
    prefixExceptions?: string[];
    onPrefixExceptionScan?: (
      value: string,
      prefix: string,
      valueWithoutPrefix: string
    ) => void;
  },
  {}
> &
  XOR<
    {
      scannedBarcodes: string[];
      setScannedBarcodes: Dispatch<SetStateAction<string[]>>;
    },
    {}
  >;

function Barcode({
  value,
  setValue,
  hasError,
  setHasError,
  isInvalidPrefix,
  setIsInvalidPrefix,
  validityCheck,
  errorMessage,
  disabled,
  infoMessage,
  placeholder,
  scannedBarcodes,
  setScannedBarcodes,
  prefixExceptions,
  onPrefixExceptionScan,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement>(null);

  const { toastRef, bottomRightToastRef } = useContext(ToastContext);

  const barcodeScannerInput = useBarcodeScannerDetection();

  const methods = useForm<FormValues>({
    defaultValues,
  });

  const { setValue: setFormValue } = methods;

  const { data: barcodePrefixesData, error: barcodePrefixesError } =
    useAxiosHook<BarcodePrefixes>('/orders/barcode-prefixes');

  const isScanningEnabled =
    barcodePrefixesData?.data && !barcodePrefixesError && !disabled;

  const handleBarcodeInput = useCallback(
    (barcode: string) => {
      if (!isScanningEnabled || !barcode.trim().length) {
        return;
      }

      // If I scan an invalid barcode, display it in the input field
      setFormValue('barcode', barcode);

      const exceptionPrefix = prefixExceptions?.find(
        (p) => !!barcode.match(new RegExp('^' + p))
      );

      if (exceptionPrefix !== undefined) {
        const valueWithoutPrefix = barcode.replace(
          new RegExp('^' + exceptionPrefix),
          ''
        );

        setHasError(false);
        setIsInvalidPrefix?.(false);
        onPrefixExceptionScan?.(barcode, exceptionPrefix, valueWithoutPrefix);

        return;
      }

      const isPrefixValid = !!barcodePrefixesData?.data.some(
        (p) => !!barcode.match(new RegExp('^' + p))
      );

      // Set any state other than setHasError here,
      //  because otherwise we may override some of validityCheck's side effects.
      if (!isPrefixValid) {
        setIsInvalidPrefix?.(true);
      }

      const isValid =
        isPrefixValid && validityCheck ? validityCheck(barcode) : true;

      if (isValid) {
        setValue(barcode);
        setHasError(false);
      } else {
        setHasError(true);

        errorToast(
          toastRef,
          t('Error'),
          t(
            'The barcode you just scanned is invalid. Please make sure it has a valid prefix.'
          )
        );
      }
    },
    [
      barcodePrefixesData?.data,
      isScanningEnabled,
      onPrefixExceptionScan,
      prefixExceptions,
      setFormValue,
      setHasError,
      setIsInvalidPrefix,
      setValue,
      t,
      toastRef,
      validityCheck,
    ]
  );

  useEffect(() => {
    handleBarcodeInput(barcodeScannerInput);
  }, [barcodeScannerInput, handleBarcodeInput]);

  useEffect(() => {
    setFormValue('barcode', value);
  }, [setFormValue, value]);

  useEffect(() => {
    if (!value.length) {
      return;
    }

    setScannedBarcodes?.((prevScanned) => [...prevScanned, value]);
  }, [setScannedBarcodes, value]);

  useEffect(() => {
    if (hasError) {
      playAudio(errorSignal);
    }
  }, [hasError]);

  useHotkeys('space+b, b+space', (e) => {
    if (!inputRef.current) {
      return;
    }

    e.preventDefault();

    inputRef.current.focus();

    if (bottomRightToastRef?.current) {
      infoToast(
        bottomRightToastRef,
        t('Barcode'),
        t('Barcode mode activated'),
        {
          life: 1500,
        }
      );
    }
  });

  function handleFormSubmission(values: FormValues) {
    handleBarcodeInput(values.barcode);
  }

  const lastScannedBarcode = scannedBarcodes?.length
    ? scannedBarcodes[scannedBarcodes.length - 1]
    : null;

  return (
    <div className={classNames(styles.barcode)}>
      <h3 className={classNames(styles.barcodeTitle)}>
        <FontAwesomeIcon icon={faBarcode} />
        {t('Barcode')}
      </h3>
      <div className="p-field">
        <Form
          barcodeRef={inputRef}
          methods={methods}
          disabled={!isScanningEnabled}
          hasError={hasError}
          setHasError={setHasError}
          onSubmit={handleFormSubmission}
          placeholder={placeholder}
        />

        {hasError ? (
          <p className="p-mt-2 p-mb-0 p-error">
            <FontAwesomeIcon icon={faExclamationTriangle} className="p-mr-2" />
            <b>
              {isInvalidPrefix
                ? t('Invalid barcode!')
                : errorMessage ?? t("The barcode doesn't match any parcels!")}
            </b>
          </p>
        ) : (
          infoMessage && (
            <p className="p-mt-2 p-mb-0 color-bluegray-500">
              <FontAwesomeIcon icon={faInfoCircle} className="p-mr-2" />
              {infoMessage}
            </p>
          )
        )}

        {lastScannedBarcode && (
          <p className={classNames('p-mt-2 p-mb-0', { 'p-error': hasError })}>
            {t('Last scanned')}: <i>{lastScannedBarcode}</i>
          </p>
        )}
      </div>
    </div>
  );
}

export default Barcode;
