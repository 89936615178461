import { Field, FieldArrayRenderProps, useFormikContext } from 'formik';
import { Button } from 'primereact/button';
import { Dropdown, DropdownChangeParams } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { ChangeEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { OrderStatus } from '../../../../../../../enums/orders';
import { Product } from '../../../../../../../enums/product';
import { Service } from '../../../../../../../enums/service';
import { LabelValue } from '../../../../../../../types/options';
import { invalidDecimalPointCharactersRegex } from '../../../../../../../utils/constants/regex';
import { FormFields } from '../../CreateEditDialog.types';

type Props = {
  idx: number;
  arrayHelpers: FieldArrayRenderProps;
  serviceLabels: Record<Service, string>;
  serviceOptions: LabelValue<Service>[];
  productLabels: Record<Product, string>;
  productOptions: LabelValue<Product>[];
  orderStatusOptions: LabelValue<OrderStatus>[];
  productServiceMapping: [Product, Service][];
  disabled: boolean;
  isLoading: boolean;
};

function Row({
  idx,
  arrayHelpers,
  serviceLabels,
  serviceOptions: _serviceOptions,
  productLabels,
  productOptions: _productOptions,
  orderStatusOptions,
  productServiceMapping,
  disabled,
  isLoading,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const { values, setFieldValue } = useFormikContext<FormFields>();

  const productId = values.popust[idx].ProizvodId;
  const serviceId = values.popust[idx].UslugaId;

  const serviceOptions = useMemo<LabelValue<Service>[]>(() => {
    if (!productId) {
      return _serviceOptions;
    }

    const allowedProductServices = productServiceMapping.filter(
      ([product]) => product === productId
    );

    return allowedProductServices.map(([_, service]) => ({
      label: serviceLabels[service],
      value: service,
    }));
  }, [_serviceOptions, productId, productServiceMapping, serviceLabels]);

  const productOptions = useMemo<LabelValue<Product>[]>(() => {
    if (!serviceId) {
      return _productOptions;
    }

    const allowedProductproducts = productServiceMapping.filter(
      ([_, service]) => service === serviceId
    );

    return allowedProductproducts.map(([product]) => ({
      label: productLabels[product],
      value: product,
    }));
  }, [_productOptions, productLabels, productServiceMapping, serviceId]);

  return (
    <>
      <div>{idx + 1}.</div>

      <div>
        <Field
          as={Dropdown}
          name={`popust.${idx}.UslugaId`}
          disabled={disabled}
          options={serviceOptions}
          placeholder={isLoading ? t('Loading...') : t('All')}
          showClear
          onChange={(e: DropdownChangeParams) => {
            setFieldValue(`popust.${idx}.UslugaId`, e.value);
          }}
        />
      </div>

      <div>
        <Field
          as={Dropdown}
          name={`popust.${idx}.ProizvodId`}
          disabled={disabled}
          options={productOptions}
          placeholder={isLoading ? t('Loading...') : t('All')}
          showClear
          onChange={(e: DropdownChangeParams) => {
            setFieldValue(`popust.${idx}.ProizvodId`, e.value);
          }}
        />
      </div>

      <div>
        <Field
          as={Dropdown}
          name={`popust.${idx}.StatusId`}
          disabled={disabled}
          options={orderStatusOptions}
          placeholder={t('All')}
          showClear
          onChange={(e: DropdownChangeParams) => {
            setFieldValue(`popust.${idx}.StatusId`, e.value);
          }}
        />
      </div>

      <div>
        <Field
          as={InputText}
          name={`popust.${idx}.Popust`}
          disabled={disabled}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setFieldValue(
              `popust.${idx}.Popust`,
              e.target.value.replace(invalidDecimalPointCharactersRegex, '')
            );
          }}
        />
      </div>

      <div>
        <Button
          type="button"
          onClick={() => arrayHelpers.remove(idx)}
          icon="fas fa-times"
          className="p-button-text p-button-plain"
        />
      </div>
    </>
  );
}

export default Row;
