import { ReactNode } from 'react';

import useMediaQuery from '../../hooks/useMediaQuery';

type Props = {
  children: ReactNode;
  columnLayout?: boolean;
};

function MainContent({ children, columnLayout }: Props): JSX.Element {
  const columnLayoutFallback = useMediaQuery('(min-width: 769px)');

  return (
    <div
      {...((columnLayout === undefined ? columnLayoutFallback : columnLayout)
        ? {
            className: 'p-col p-pb-0',
            style: { width: 'calc(100% - 360px)' },
          }
        : { className: 'p-col-12 p-pb-0' })}
    >
      {children}
    </div>
  );
}

export default MainContent;
