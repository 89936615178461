import { configCollectionGuard } from './_api/configs';
import { availableCourierCollectionGuard } from './_api/courier';
import {
  cancelCourierOrdersResourceGuard,
  courierOrdersCollectedCollectionGuard,
  courierOrdersReturnedCollectionGuard,
  courierSummaryCollectionGuard,
  orderResourceGuard,
  ordersBarcodePrefixesCollectionGuard,
  submitCourierOrdersResourceGuard,
  updateCourierOrdersReturnedResourceGuard,
} from './_api/orders';
import { regionCollectionGuard } from './_api/regions';

export const regionSoundsGuard = [regionCollectionGuard, configCollectionGuard];

export const receiveShipmentGuard = submitCourierOrdersResourceGuard;
export const updateReturnedShipmentGuard =
  updateCourierOrdersReturnedResourceGuard;
export const undoReceivedShipmentGuard = cancelCourierOrdersResourceGuard;

export const routeGuard = [
  availableCourierCollectionGuard,
  courierOrdersCollectedCollectionGuard,
  courierOrdersReturnedCollectionGuard,
  courierSummaryCollectionGuard,
  orderResourceGuard,
  ordersBarcodePrefixesCollectionGuard,
];
