import classNames from 'classnames';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Dispatch, useMemo } from 'react';
import { useState } from 'react';
import { SetStateAction } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDebounce } from 'use-lodash-debounce';

import { getProductTypeOptions } from '../../../configs/product';
import { getServiceTypeOptions } from '../../../configs/service';
import useAxiosHook from '../../../hooks/useAxiosHook';
import { useEndpointGuard } from '../../../hooks/useEndpointGuard';
import useHaveValuesChanged from '../../../hooks/useHaveValuesChanged';
import useSearchQueryDropdownParam from '../../../hooks/useSearchQueryDropdownParam';
import useSearchQueryParam from '../../../hooks/useSearchQueryParam';
import {
  PriceListCollection,
  PriceListCollectionQueryParams,
} from '../../../types/api/priceLists';
import { priceListCollectionGuard } from '../../../utils/constants/auth/_api/priceLists';
import { debounceTimeout } from '../../../utils/constants/misc';
import { queryString } from '../../../utils/helpers/http';
import { getSearchQueryParam } from '../../../utils/helpers/searchQuery';
import FiltersCounter from '../Components/Filters/FiltersCounter';

function useTableFilters(
  page: number,
  setPage: Dispatch<SetStateAction<number>>,
  limit: number
) {
  const { t } = useTranslation();
  const location = useLocation();
  const priceListCollectionEpGuard = useEndpointGuard(priceListCollectionGuard);
  const [nameFilter, setNameFilter] = useState<string>(
    () => getSearchQueryParam(location.search, 'tariff_name') ?? ''
  );
  const [priceListId, setPriceListId] = useState<string | null>(
    () => getSearchQueryParam(location.search, 'price_list_id') ?? null
  );
  const [productTypeId, setProductTypeId] = useState<string | null>(
    () => getSearchQueryParam(location.search, 'tariff_product_id') ?? null
  );
  const [serviceTypeId, setServiceTypeId] = useState<string | null>(
    () => getSearchQueryParam(location.search, 'tariff_service_id') ?? null
  );

  const debouncedNameFilter = useDebounce(nameFilter, debounceTimeout);

  const filtersArr = useMemo(
    () => [debouncedNameFilter, priceListId, productTypeId, serviceTypeId],
    [debouncedNameFilter, priceListId, productTypeId, serviceTypeId]
  );

  const haveFiltersChanged = useHaveValuesChanged(filtersArr);

  const productTypeOptions = useMemo(() => getProductTypeOptions(t), [t]);
  const serviceTypeOptions = useMemo(() => getServiceTypeOptions(t), [t]);

  const { data: priceLists } = useAxiosHook<PriceListCollection>(
    '/price-lists' +
      queryString<Partial<PriceListCollectionQueryParams>>({
        page: 1,
        limit: 15,
      })
  );

  const priceListOptions = useMemo<{ label: string; value: string }[]>(
    () =>
      priceLists?.data?.map((p: any) => ({
        label: p.name ?? '',
        value: p.id,
      })) ?? [],
    [priceLists?.data]
  );

  useEffect(() => {
    setPage(1);
  }, [setPage, debouncedNameFilter, priceListId, productTypeId, serviceTypeId]);

  useSearchQueryParam('tariff_name', debouncedNameFilter);
  useSearchQueryDropdownParam(
    'price_list_id',
    priceListId,
    setPriceListId,
    priceListOptions
  );
  useSearchQueryDropdownParam(
    'tariff_product_id',
    productTypeId,
    setProductTypeId,
    productTypeOptions
  );
  useSearchQueryDropdownParam(
    'tariff_service_id',
    serviceTypeId,
    setServiceTypeId,
    serviceTypeOptions
  );

  const httpFiltersObj = useMemo(
    () => ({
      price_list_id: priceListId,
      tariff_name: debouncedNameFilter,
      tariff_product_id: productTypeId,
      tariff_service_id: serviceTypeId,
      page: haveFiltersChanged ? 1 : page,
      limit,
    }),
    [
      debouncedNameFilter,
      haveFiltersChanged,
      limit,
      page,
      priceListId,
      productTypeId,
      serviceTypeId,
    ]
  );

  const basicFiltersActiveFilterCount = Object.values({
    nameFilter,
    priceListId,
    productTypeId,
    serviceTypeId,
  }).filter(Boolean).length;

  const filters = useMemo<JSX.Element>(
    () => (
      <Accordion multiple activeIndex={[0]}>
        <AccordionTab
          disabled
          headerTemplate={
            <FiltersCounter
              description={t('Basic filters')}
              counter={basicFiltersActiveFilterCount}
            />
          }
        >
          <div className="sidebar_filter">
            <label htmlFor="filter_name">{t('Name')}</label>
            <InputText
              id="filter_name"
              value={nameFilter}
              onChange={(e) => setNameFilter(e.target.value)}
              className={classNames({
                sidebar_filter_active: nameFilter,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="filter_product">{t('Product')}</label>
            <Dropdown
              id="filter_product"
              value={productTypeId}
              options={productTypeOptions}
              onChange={(e) => setProductTypeId(e.value ?? null)}
              showClear
              className={classNames({
                sidebar_filter_active: productTypeId,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="filter_service">{t('Service')}</label>
            <Dropdown
              id="filter_service"
              value={serviceTypeId}
              options={serviceTypeOptions}
              onChange={(e) => setServiceTypeId(e.value ?? null)}
              showClear
              className={classNames({
                sidebar_filter_active: serviceTypeId,
              })}
            />
          </div>
          {priceListCollectionEpGuard && (
            <div className="sidebar_filter">
              <label htmlFor="filter_price_list">{t('Price list')}</label>
              <Dropdown
                id="filter_service"
                value={priceListId}
                options={priceListOptions}
                onChange={(e) => setPriceListId(e.value)}
                showClear
                className={classNames({
                  sidebar_filter_active: priceListId,
                })}
              />

              {/* 
              <AutoCompleteInput
                id="filter_price_list"
                filterValue={priceListFilter}
                value={priceListFilterObj}
                options={priceListOptions}
                placeholder={t('Search by name')}
                onFilterChange={setPriceListFilter}
                onSelectionChange={setPriceListFilterObj}
                className={classNames({
                  sidebar_filter_active: priceListFilter,
                })}
              /> */}
            </div>
          )}
        </AccordionTab>
      </Accordion>
    ),
    [
      nameFilter,
      priceListCollectionEpGuard,
      priceListId,
      priceListOptions,
      productTypeId,
      productTypeOptions,
      serviceTypeId,
      serviceTypeOptions,
      basicFiltersActiveFilterCount,
      t,
    ]
  );

  const headerFiltersCount = useMemo(
    () => basicFiltersActiveFilterCount,
    [basicFiltersActiveFilterCount]
  );

  function resetAllFilters() {
    setNameFilter('');
    setProductTypeId(null);
    setServiceTypeId(null);
    setPriceListId(null);
    // setPriceListFilterObj(null);
  }

  return {
    filters,
    resetAllFilters,
    httpFiltersObj,
    headerFiltersCount,
  };
}

export default useTableFilters;
