import { Field, useFormikContext } from 'formik';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useTranslation } from 'react-i18next';

import useAxiosHook from '../../../../hooks/useAxiosHook';
import { queryString } from '../../../../utils/helpers/http';
import FieldWithErrors from '../../../Forms/FieldWithErrors/FieldWithErrors';

function CreateEditDialogHubsTab() {
  const { t } = useTranslation();

  const { values } = useFormikContext();

  const { data: hubs, isLoading } = useAxiosHook(
    '/hubs' +
      queryString({
        page: 1,
        limit: 10000000,
      })
  );

  const hubIdOptions = hubs?.data?.map((h) => ({ label: h.name, value: h.id }));

  return (
    <>
      {isLoading ? (
        <div className="text-center">
          <ProgressSpinner style={{ width: 72 }} />
        </div>
      ) : (
        <div className="p-fluid">
          <FieldWithErrors name="hub_id" label={t('Native warehouse')}>
            <Field
              name="hub_id"
              as={Dropdown}
              inputId="hub_id"
              options={hubIdOptions}
              filter
              filterPlaceholder={t('Search')}
              disabled={isLoading}
              placeholder={isLoading ? t('Loading...') : null}
              className="data-cy-base_hub"
            />
          </FieldWithErrors>

          <FieldWithErrors name="hubs" label={t('Warehouses')}>
            {hubs?.data?.map((h, idx) => {
              const id = parseInt(h.id);

              return (
                <div key={id} className="checkbox-container">
                  <Field
                    as={Checkbox}
                    inputId={`hubs_${id}`}
                    name="hubs"
                    value={id}
                    checked={values.hubs.includes(id)}
                  />

                  <label htmlFor={`hubs_${id}`} data-cy={`hubs-${idx}`}>
                    {h.name}
                  </label>
                </div>
              );
            })}
          </FieldWithErrors>
        </div>
      )}
    </>
  );
}

export default CreateEditDialogHubsTab;
