import dayjs from 'dayjs';
import { Field, useFormikContext } from 'formik';
import { Calendar } from 'primereact/calendar';
import { useTranslation } from 'react-i18next';

import FieldWithErrors from '../../../../../../Forms/FieldWithErrors/FieldWithErrors';

function WorkingHoursField() {
  const { t } = useTranslation();

  const { values, setFieldValue } = useFormikContext();
  return (
    <FieldWithErrors
      name="working_hours_from"
      label={t('Working hours')}
      includeErrorsFor={['working_hours_to']}
    >
      <div className="working-hours-wrapper">
        <Field
          as={Calendar}
          name="working_hours_from"
          inputId="working_hours_from"
          timeOnly
          stepMinute={5}
          onFocus={() => {
            if (
              !values.working_hours_from ||
              Object.keys(values.working_hours_from).length === 0
            ) {
              setFieldValue(
                'working_hours_from',
                dayjs().hour(8).minute(0).toDate()
              );
            } else {
              setFieldValue('working_hours_from', values.working_hours_from);
            }
          }}
          inputClassName="data-cy-working_hours_from"
        />

        <span className="divider">-</span>

        <Field
          as={Calendar}
          name="working_hours_to"
          timeOnly
          stepMinute={5}
          onFocus={() => {
            if (
              !values.working_hours_to ||
              Object.keys(values.working_hours_to).length === 0
            ) {
              setFieldValue(
                'working_hours_to',
                dayjs().hour(16).minute(0).toDate()
              );
            } else {
              setFieldValue('working_hours_to', values.working_hours_to);
            }
          }}
          inputClassName="data-cy-working_hours_to"
        />
      </div>
    </FieldWithErrors>
  );
}

export default WorkingHoursField;
