import { TFunction } from 'react-i18next';
import * as Yup from 'yup';

import { CreateReconciliationResourceRequestPayload } from '../../../../../../types/api/reconciliations';
import { yupRequiredField } from '../../../../../../utils/helpers/yup';
import { FormFields } from './CreateReconciliationDialog.types';

export function getDefaultValues(date: Date): FormFields {
  return {
    date,
    employee_name: '',
    employee_id: null,
    work_order_id: '',
  };
}

export function toApiData(
  values: FormFields
): CreateReconciliationResourceRequestPayload {
  return {
    work_order_id:
      typeof values.work_order_id === 'object'
        ? values.work_order_id.id
        : values.work_order_id,
  };
}

export function getValidationSchema(
  t: TFunction
): Yup.SchemaOf<
  Partial<Omit<FormFields, 'employee_name' | 'is_work_order_valid'>>
> {
  const employeeWorkOrderError = t(
    'Either select a combination of date and employee for an existing work order or manually enter one'
  );

  return Yup.object().shape({
    date: Yup.mixed().required(yupRequiredField(t, t('Date'))),
    employee_id: Yup.mixed().test(
      'employee_id',
      employeeWorkOrderError,
      (value, { parent }) => {
        return !!value && !!parent.work_order_id;
      }
    ),
    work_order_id: Yup.mixed().test(
      'work_order_id',
      employeeWorkOrderError,
      (value, { parent }) => {
        return !!value && !!parent.employee_id;
      }
    ),
  });
}
