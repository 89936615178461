import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import useAxiosHook from '../../../hooks/useAxiosHook';
import usePageTitle from '../../../hooks/usePageTitle';
import useRouteDialog from '../../../hooks/useRouteDialog';
import useTableColumns from '../../../hooks/useTableColumns';
import useTableState from '../../../hooks/useTableState';
import { WithPagination } from '../../../types/api';
import { EntityIdRouteParams } from '../../../types/routing';
import {
  RoutePaths,
  constructIdRoute,
} from '../../../utils/constants/routePaths';
import Table from '../../DataTable/Table/Table';
import Flex from '../../layout/flex/Flex';
import MainContent from '../../layout/flex/MainContent';
import Sidebar from '../../layout/flex/Sidebar';
import Filters from '../Components/Filters/Filters';
import HeaderPages from '../Components/HeaderPages/HeaderPages';
import EditDialog from './Dialogs/Edit/EditDialog';
import ViewDialog from './Dialogs/View/ViewDialog';
import {
  Route,
  additionalColumnProperties,
  generateContextMenu,
  getColumnHeadersMap,
  tableStorageKey,
} from './Routes.functions';
import useTableFilters from './useTableFilters';

function Routes(): JSX.Element {
  const { t } = useTranslation();

  usePageTitle(t('Routes'));

  const { id } = useParams<EntityIdRouteParams>();

  const {
    tableRef,
    page,
    setPage,
    limit,
    setLimit,
    sortField,
    sortOrder,
    selection,
    setSortField,
    setSortOrder,
    setSelection,
  } = useTableState<Route>(tableStorageKey);

  const {
    headerFiltersForm,
    headerFiltersCount,
    resetAllFilters,
    httpFiltersObj,
  } = useTableFilters(page, setPage!, limit);

  const columnHeadersMap = useMemo(() => getColumnHeadersMap(t), [t]);

  const { selectedColumns, setSelectedColumns, columnOptions, columns } =
    useTableColumns(
      page,
      limit,
      'clients',
      columnHeadersMap,
      columnHeadersMap,
      (c) => additionalColumnProperties(c as any)
    );

  const { data, error, isLoading, reload } = useAxiosHook<
    WithPagination<Route[]>
  >('/routes' + httpFiltersObj);

  const {
    show: showViewDialog,
    hide: hideViewDialog,
    isVisible: isViewDialogVisible,
  } = useRouteDialog(
    RoutePaths.RoutesPage,
    constructIdRoute(RoutePaths.ViewRoute, id ?? selection?.id)
  );

  const {
    show: showEditDialog,
    hide: hideEditDialog,
    isVisible: isEditDialogVisible,
  } = useRouteDialog(
    RoutePaths.RoutesPage,
    constructIdRoute(RoutePaths.EditRoute, id ?? selection?.id)
  );

  return (
    <div className="page">
      <HeaderPages>
        <div>
          <h2 className="title">{t('Routes')}</h2>
          <p className="subtitle">{t('Routes management')}</p>
        </div>
      </HeaderPages>

      <ViewDialog
        isShown={isViewDialogVisible}
        onHide={hideViewDialog}
        isEditBtnShown // !!!
      />

      <EditDialog isShown={isEditDialogVisible} onHide={hideEditDialog} />

      <Flex direction="column">
        <Sidebar>
          <Filters
            filters={headerFiltersForm}
            resetAllFilters={resetAllFilters}
          />
        </Sidebar>
        <MainContent>
          <Table
            tableRef={tableRef}
            columns={columns}
            data={data}
            isLoading={isLoading}
            hasError={!!error}
            reload={reload}
            headerTitle=""
            headerFiltersCount={headerFiltersCount}
            onHeaderFiltersResetAllBtnClick={resetAllFilters}
            setPage={setPage}
            setLimit={setLimit}
            sortField={sortField}
            rows={limit}
            contextMenuModel={generateContextMenu(
              t,
              true, // !!!
              showViewDialog,
              true, // !!!
              showEditDialog
            )}
            setSortField={setSortField}
            setSortOrder={setSortOrder}
            setSelection={setSelection}
            sortOrder={sortOrder}
            selection={selection}
            storageString={tableStorageKey}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            columnOptions={columnOptions}
            onRowDoubleClick={showViewDialog}
          />
        </MainContent>
      </Flex>
    </div>
  );
}

export default Routes;
