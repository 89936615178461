import classNames from 'classnames';
import { HTMLProps, ReactNode, useContext } from 'react';

import StepperDialogContext from './StepperDialogContext';

type Props = HTMLProps<HTMLDivElement> & {
  title: string;
  subtitle: string;
  children: ReactNode;
  childrenWrapperClassName?: string;
  fullWidth?: boolean;
};

function Step({
  title,
  subtitle,
  children,
  childrenWrapperClassName,
  fullWidth = false,
  ...other
}: Props): JSX.Element {
  const { stepIndex } = useContext(StepperDialogContext);

  return (
    <div className={classNames('step', { 'full-width': fullWidth })} {...other}>
      <div className="p-fluid">
        <h3 className="p-mt-0 p-mb-2">
          {stepIndex + 1}. {title}
        </h3>

        <p className="p-mt-0 p-mb-4 section-subtitle">{subtitle}</p>

        <div className={childrenWrapperClassName}>{children}</div>
      </div>
    </div>
  );
}

export default Step;
