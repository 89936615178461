import { AxiosError } from 'axios';
import { useContext, useEffect } from 'react';
import { TFunction, useTranslation } from 'react-i18next';

import ToastContext from '../../../../context/ToastContext';
import usePrevious from '../../../../hooks/usePrevious';
import { MarkAsDoneReconciliationsError } from '../../../../types/api/reconciliations';
import { errorToast } from '../../../../utils/helpers/primereact';

function printInvalidWorkOrders(
  t: TFunction,
  errors: MarkAsDoneReconciliationsError['error_description']
) {
  return (
    <ul style={{ padding: 0 }}>
      {errors.map((error) => {
        return (
          <li key={error.work_order_id}>
            {String(error.work_order_id)
              .concat(': ')
              .concat(t([`${error.message}`, 'Unknown error']))
              .concat('\n')}
          </li>
        );
      })}
    </ul>
  );
}

function useHandleMarkAllAsDoneResponse(
  updateError: AxiosError<MarkAsDoneReconciliationsError> | undefined
) {
  const { t } = useTranslation();

  const { toastRef } = useContext(ToastContext);

  const previousUpdateError = usePrevious(updateError);

  useEffect(() => {
    if (!updateError || updateError === previousUpdateError) {
      return;
    }

    if (
      updateError.response?.status === 422 &&
      updateError.response.data.error_description
    )
      errorToast(
        toastRef,
        t('Error'),
        printInvalidWorkOrders(t, updateError.response.data.error_description)
      );
  }, [previousUpdateError, t, toastRef, updateError]);
}

export default useHandleMarkAllAsDoneResponse;
