export const uintRegex = /^\d*$/;

// Alternative: /^([0-2][0-9]|3[0-1])(0[0-9]|1[0-2])(9[0-9]{2})([0-9]{2})([0-9]{3})([0-9])$/
export const individualSsnRegex = /^\d{13}$/;

export const legalSsnRegex = /^\d{7}$/;

export const bankAccountRegex =
  /^(100|200|210|240|250|270|280|290|300|320|330|350|370|380|500|530)\d{12}$/;

export const domainNameRegex =
  /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/;

export const invalidDecimalPointCharactersRegex = /[^\d.]/;

export const specialCharsRegex = /[~`±§!@#$%^&*()\-_=+[\]{};:'"\\|,<.>/?\s]/g;

export const emailRegex =
  // eslint-disable-next-line no-control-regex
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
