import { Field, useFormikContext } from 'formik';
import { Map } from 'leaflet';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import FieldWithErrors from '../../../../../Forms/FieldWithErrors/FieldWithErrors';
import { Region } from '../../../Regions.functions';
import { getZoneDropdownOptions } from './AddEditRegionDialog.functions';
import DialogMap from './DialogMap';

type Props = {
  isEditDialog: boolean;
  selectedRegion: Region | undefined;
  allRegions: Region[];
  map: Map | undefined;
  setMap: Dispatch<SetStateAction<Map | undefined>>;
};

function DialogForm({
  isEditDialog,
  selectedRegion,
  allRegions,
  map,
  setMap,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const zoneDropdownOptions = useMemo(() => getZoneDropdownOptions(t), [t]);

  const { validateForm } = useFormikContext();

  return (
    <div className="p-grid form-grid">
      <div className="p-col-12 p-md-8 p-lg-9">
        <FieldWithErrors name="coordinates" label={t('Area')}>
          <DialogMap
            isEditDialog={isEditDialog}
            selectedRegion={selectedRegion}
            allRegions={allRegions}
            map={map}
            setMap={setMap}
            onCreate={validateForm}
            onRemove={validateForm}
          />
        </FieldWithErrors>
      </div>

      <div className="p-col-12 p-md-4 p-lg-3">
        <div className="p-fluid">
          <div>
            <FieldWithErrors name="name">
              <Field as={InputText} id="name" name="name" data-cy="name" />
            </FieldWithErrors>

            <FieldWithErrors name="zone_id" label={t('Zone')}>
              <Field
                as={Dropdown}
                inputId="zone_id"
                name="zone_id"
                options={zoneDropdownOptions}
                placeholder={t('Choose a zone')}
                className="data-cy-zone_id"
              />
            </FieldWithErrors>

            <FieldWithErrors name="description">
              <Field
                as={InputTextarea}
                name="description"
                id="description"
                autoResize
                data-cy="description"
              />
            </FieldWithErrors>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DialogForm;
