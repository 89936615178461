import './TrackShipmentsByLocation.scss';

import { faPallet } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import { Skeleton } from 'primereact/skeleton';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-lodash-debounce';

import useAxiosHook from '../../../../hooks/useAxiosHook';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import usePageTitle from '../../../../hooks/usePageTitle';
import {
  OrderLocationsFilterCollection,
  OrderLocationsFilterCollectionQueryParams,
} from '../../../../types/api/orders';
import { debounceTimeout } from '../../../../utils/constants/misc';
import { queryString } from '../../../../utils/helpers/http';
import Grid from '../../../Grid/Grid';
import MainContent from '../../../Grid/MainContent';
import SidePanels from '../../../Grid/SidePanels';
import HeaderPages from '../../Components/HeaderPages/HeaderPages';
import Filters from './Sidebar/Filters';
import ShipmentCollection from './Sidebar/ShipmentCollection';
import ShipmentsLocation from './Sidebar/ShipmentLocations';
import {
  ShipmentLocation,
  generateTotalShipments,
  getLocationIcon,
  getLocationName,
} from './TrackShipmentsByLocation.functions';

function TrackShipmentsByLocation() {
  const { t } = useTranslation();

  usePageTitle(t('Warehouse Shipments'));

  const [openPanelGroup, setOpenPanelGroup] = useState<string[]>([]);
  const [selectedLocation, setSelectedLocation] =
    useState<string | undefined>(undefined);

  const [serialNoFilter, setSerialNoFilter] = useState<string>('');

  const debouncedserialNoFilter = useDebounce(serialNoFilter, debounceTimeout);

  const isTwoColumnLayout = useMediaQuery('(min-width: 769px)');

  const { data, isLoading } = useAxiosHook<OrderLocationsFilterCollection>(
    '/orders/locations/filters' +
      queryString<OrderLocationsFilterCollectionQueryParams>({
        serial_number: debouncedserialNoFilter,
      })
  );

  const modifiedData: ShipmentLocation[] = useMemo(() => {
    let groupedData = _.groupBy(data, 'entity_location_id');

    let newData = [];

    for (let i in groupedData) {
      const newLocationData = {
        id: i,
        label: getLocationName(t, i),
        icon: i.includes('-') ? getLocationIcon(i) : undefined,
        items: groupedData[i].map((l) => {
          return {
            entity_id: l.entity_id,
            entity_location_id: l.entity_location_id,
            entity_name: l.entity_name,
            id: `${i}-${l.entity_id}`,
            label: l.entity_name,
            icon: getLocationIcon(i),
            total: generateTotalShipments(l?.statuses),
            items: l.statuses?.map((s) => {
              return {
                ...s,
                id: `${i}-${l.entity_id}-${s.status_id}`,
                label: s.status_name,
              };
            }),
          };
        }),
      };

      newData.push(newLocationData);
    }
    return newData;
  }, [data, t]);

  return (
    <div className="page shipment-location-status-page">
      <HeaderPages
        title={t('Track Shipments By Location')}
        subtitle={t('View shipments location and status')}
        icon={faPallet}
      />

      {isLoading && !modifiedData ? (
        <div className="p-d-flex p-jc-start">
          <div>
            <Skeleton width="20%" height="20%" />
            <Skeleton width="20%" height="70%" />
          </div>
          <Skeleton width="80%" height="100%" />
        </div>
      ) : (
        <Grid>
          <SidePanels columnLayout={isTwoColumnLayout}>
            <Filters
              serialNoFilter={serialNoFilter}
              setSerialNoFilter={setSerialNoFilter}
            />

            <ShipmentsLocation
              data={modifiedData}
              setOpenPanelGroup={setOpenPanelGroup}
              openPanelGroup={openPanelGroup}
              selectedLocation={selectedLocation}
              setSelectedLocation={setSelectedLocation}
            />
          </SidePanels>

          <MainContent columnLayout={isTwoColumnLayout}>
            <ShipmentCollection selectedLocation={selectedLocation} />
          </MainContent>
        </Grid>
      )}
    </div>
  );
}

export default TrackShipmentsByLocation;
