import useIsMounted from './useIsMounted';

// Sometimes, we have cases where we have to use async operations.
// This hook is used to safely call callbacks on unmounted components.
function useSafeCallback<T>(cb: T): T | undefined {
  const isMounted = useIsMounted();

  if (!isMounted.current) {
    return;
  }

  return cb;
}

export default useSafeCallback;
