import { TFunction } from 'react-i18next';

import { InvoiceEmailStatus, InvoiceStatus } from '../enums/invoices';
import { LabelValue } from '../types/options';

type InvoiceStatusConfig = {
  label: string;
  rowClassName?: string;
};

export function getInvoiceStatusConfig(
  t: TFunction
): Record<InvoiceStatus, InvoiceStatusConfig> {
  return {
    [InvoiceStatus.Created]: {
      label: t('Created.SHE'),
    },
    [InvoiceStatus.Approved]: {
      label: t('Approved.SHE'),
      rowClassName: 'bg-blue-100',
    },
    [InvoiceStatus.Cancelled]: {
      label: t('Cancelled.INVOICE'),
      rowClassName: 'bg-red-100',
    },
    [InvoiceStatus.Paid]: {
      label: t('Paid.SHE'),
      rowClassName: 'bg-green-100',
    },
  };
}

export function getInvoiceStatusOptions(
  t: TFunction
): LabelValue<InvoiceStatus>[] {
  const config = getInvoiceStatusConfig(t);

  return [
    {
      label: config[InvoiceStatus.Created].label,
      value: InvoiceStatus.Created,
    },
    {
      label: config[InvoiceStatus.Approved].label,
      value: InvoiceStatus.Approved,
    },
    {
      label: config[InvoiceStatus.Cancelled].label,
      value: InvoiceStatus.Cancelled,
    },
    { label: config[InvoiceStatus.Paid].label, value: InvoiceStatus.Paid },
  ];
}

export function getInvoiceEmailStatusLabels(
  t: TFunction
): Record<InvoiceEmailStatus, string> {
  return {
    [InvoiceEmailStatus.NotSent]: t('Not sent'),
    [InvoiceEmailStatus.Sent]: t('Sent'),
    [InvoiceEmailStatus.ErrorWhileSending]: t('Error while sending'),
  };
}

export function getInvoiceEmailStatusOptions(
  t: TFunction
): LabelValue<InvoiceEmailStatus>[] {
  const labels = getInvoiceEmailStatusLabels(t);

  return [
    {
      label: labels[InvoiceEmailStatus.NotSent],
      value: InvoiceEmailStatus.NotSent,
    },
    { label: labels[InvoiceEmailStatus.Sent], value: InvoiceEmailStatus.Sent },
    {
      label: labels[InvoiceEmailStatus.ErrorWhileSending],
      value: InvoiceEmailStatus.ErrorWhileSending,
    },
  ];
}
