import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import {
  Column,
  PluginHook,
  Row,
  TableInstance,
  TableOptions,
  useBlockLayout,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';

import { emptyArray } from '../utils/constants/misc';
import { editCellDataUpdate } from '../utils/helpers/react-table';

type Props<T extends object> = Omit<TableOptions<T>, 'data' | 'columns'> & {
  data: T[] | undefined;
  columns: Column<T>[];
  setData?: Dispatch<SetStateAction<T[]>>;
  hooks?: PluginHook<T>[];
};

function useVirtualizedReactTableState<T extends object>({
  columns,
  data,
  setData,
  hooks,
  ...otherProps
}: Props<T>): TableInstance<T> {
  const [contextMenuSelection, setContextMenuSelection] =
    useState<Row<T> | undefined>();

  const editCellDataUpdateFn = useMemo(
    () => editCellDataUpdate(setData ?? (() => {})),
    [setData]
  );

  return useTable<T>(
    {
      columns,
      data: data ?? emptyArray,
      autoResetSortBy: false,
      editCellDataUpdate: editCellDataUpdateFn,
      contextMenuSelection,
      setContextMenuSelection,
      ...otherProps,
    },
    useSortBy,
    useRowSelect,
    useBlockLayout,
    ...(hooks ?? [])
  );
}

export default useVirtualizedReactTableState;
