import { Field, useFormikContext } from 'formik';
import { Checkbox, CheckboxChangeParams } from 'primereact/checkbox';
import { Dropdown, DropdownChangeParams } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { ChangeEvent, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-lodash-debounce';

import { usePhoneOptionsContext } from '../../../../../../context/PhoneOptionsContext';
import useAxiosHook from '../../../../../../hooks/useAxiosHook';
import {
  ClientLookupCollection,
  ClientLookupCollectionQueryParams,
} from '../../../../../../types/api/clients';
import { HubCollection } from '../../../../../../types/api/hubs';
import { PlaceOption } from '../../../../../../types/options';
import { isStreetFreeInputAllowed } from '../../../../../../utils/constants/misc';
import { invalidPhoneNumberCharactersRegex } from '../../../../../../utils/constants/phoneNumbers';
import { queryString } from '../../../../../../utils/helpers/http';
import { placeItemTemplate } from '../../../../../../utils/helpers/misc';
import { tryString } from '../../../../../../utils/helpers/parse';
import {
  formatMobileNumber,
  formatPhoneNumber,
  getPhoneorMobileNumberRegion,
} from '../../../../../../utils/helpers/phoneNumbers';
import AutoCompleteInput from '../../../../../Forms/AutoCompleteInput/AutoCompleteInput';
import FieldWithErrors from '../../../../../Forms/FieldWithErrors/FieldWithErrors';
import {
  FormValues,
  PlaceCollection,
  Street,
} from '../CreateEditDialog.functions';

type Props = {
  initialValues: FormValues;
  hubs: HubCollection | undefined;
  orderIndex: number;
  isConstant: boolean;
  nearestRecipientHub: { ime: string; id: number } | undefined;
  isNearestRecipientHubLoading: boolean;
};

function RecipientTab({
  initialValues,
  hubs,
  orderIndex,
  isConstant,
  nearestRecipientHub,
  isNearestRecipientHubLoading,
}: Props) {
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const { t } = useTranslation();

  const debouncedRecipient = useDebounce(values?.klient_do_ime, 300);
  const debouncedPlaceTo = useDebounce(values?.mesto_do_ime, 300);

  const hubsSuggestions =
    hubs?.data.map((hub) => {
      return { id: hub.id, label: hub.name };
    }) ?? [];

  const { data: recipientsData, isLoading: isRecipientsDataLoading } =
    useAxiosHook<ClientLookupCollection>(
      '/clients/lookup' +
        queryString<ClientLookupCollectionQueryParams>({
          ime: debouncedRecipient,
          page: 1,
          limit: 10,
        }),
      { skipWhen: !!values?.no_recipient }
    );

  const clientsSuggestions =
    recipientsData?.data.map((client) => {
      return {
        label: client.ime,
        value: String(client.id),
      };
    }) ?? [];

  const { data: placesData } = useAxiosHook<PlaceCollection>(
    '/places' +
      queryString({
        place: debouncedPlaceTo ?? '',
        page: 1,
        limit: 10,
      })
  );

  const placeSuggestions: PlaceOption[] =
    placesData?.data?.map((place) => {
      return {
        label: place.place_name,
        value: String(place.place_id),
        postal_code: place.place_postal_code,
        municipality_id: place.municipality_id,
        municipality_name: place.municipality_name,
      };
    }) ?? [];

  const { data: streetsData } = useAxiosHook<Street[]>(
    {
      url: `places/${values.mesto_do_id}/streets`,
    },
    {
      skipWhen: !values.mesto_do_id,
    }
  );

  const streetOptions = streetsData?.map((street) => ({
    label: street.ime,
    value: String(street.id),
  }));

  const isRecipientPlaceModified =
    initialValues.mesto_do_id !== values.mesto_do_id;
  const debounced_is_place_modified = useDebounce(
    isRecipientPlaceModified,
    500
  );

  useEffect(() => {
    if (debounced_is_place_modified && values.delivery_location_type_id) {
      setFieldValue('hub_do_id', nearestRecipientHub?.id);
    }
  }, [
    debounced_is_place_modified,
    nearestRecipientHub?.id,
    setFieldValue,
    values.delivery_location_type_id,
  ]);

  const { countryOptionTemplate, languages, selectedCountryTemplate } =
    usePhoneOptionsContext();

  const handleClientChange = useCallback(
    (ime: string) => {
      setFieldValue('klient_do_ime', ime);
    },
    [setFieldValue]
  );

  const handleClientSelect = useCallback(
    (id: string | null) => {
      setFieldValue('klient_do_id', id);

      const recipient = recipientsData?.data.find(
        (recipient) => String(recipient.id) === id
      );

      setFieldValue('mesto_do_id', recipient?.mesto_id ?? '');
      setFieldValue('mesto_do_ime', recipient?.mesto_ime ?? '');
      setFieldValue('opstina_do_id', recipient?.opstina_id ?? '');
      setFieldValue('opstina_do_ime', recipient?.opstina_ime ?? '');
      setFieldValue('telefon_do', recipient?.telefon ?? '');
      setFieldValue(
        'telefon_do_region',
        getPhoneorMobileNumberRegion(recipient?.telefon ?? '')
      );
      setFieldValue('mobilen_do', recipient?.mobilen ?? '');
      setFieldValue(
        'mobilen_do_region',
        getPhoneorMobileNumberRegion(recipient?.mobilen ?? '')
      );
      setFieldValue('broj_do', recipient?.broj ?? '');
      setFieldValue('vlez_do', recipient?.vlez ?? '');
      setFieldValue('stan_do', recipient?.stan ?? '');
      setFieldValue('ulica_do_id', tryString(recipient?.ulica_id) ?? '');
      setFieldValue('adresa_do', recipient?.adresa ?? '');
    },
    [recipientsData?.data, setFieldValue]
  );

  const handlePlaceChange = useCallback(
    (name: string) => {
      setFieldValue('mesto_do_ime', name);
    },
    [setFieldValue]
  );

  const handlePlaceSelectionChange = useCallback(
    (id: string | null) => {
      setFieldValue('mesto_do_id', id);

      if (id) {
        const place = placesData?.data.find(
          (place) => String(place.place_id) === id
        );
        setFieldValue('mesto_do_ime', place?.place_name);
        setFieldValue('opstina_do_id', place?.municipality_id);
        setFieldValue('opstina_do_ime', place?.municipality_name);
      }
    },
    [placesData?.data, setFieldValue]
  );

  return (
    <>
      {orderIndex !== 0 && isConstant && <div className="constant" />}

      {values.no_recipient ? (
        <FieldWithErrors
          name="klient_do_ime"
          includeErrorsFor={['klient_do_id']}
          label={t('Recipient Name')}
        >
          <Field
            as={InputText}
            name="klient_do_ime"
            placeholder={t('Required')}
          />
        </FieldWithErrors>
      ) : (
        <FieldWithErrors
          includeErrorsFor={['klient_do_id']}
          name="klient_do_ime"
          label={t('Search Clients')}
        >
          <AutoCompleteInput
            placeholder={t('Search by name or phone number')}
            options={clientsSuggestions}
            value={tryString(values.klient_do_id) ?? null}
            filterValue={values.klient_do_ime ?? ''}
            onFilterChange={handleClientChange}
            onSelectionChange={handleClientSelect}
            filterDataCy="recipient"
            optionsClassName="data-cy-recipient-options"
          />
        </FieldWithErrors>
      )}
      <FieldWithErrors name="no_recipient" label={false}>
        <Field
          as={Checkbox}
          name="no_recipient"
          onChange={(e: CheckboxChangeParams) => {
            if (e.checked) {
              setFieldValue('klient_do_id', '2');
              setFieldValue('no_recipient', true);
            } else {
              setFieldValue('klient_do_id', '');
              setFieldValue('no_recipient', false);
            }
          }}
          inputId="no_recipient"
          checked={values.no_recipient}
          className="data-cy-no_recipient"
        />

        <label htmlFor="no_recipient">{t('Unknown client')}</label>
      </FieldWithErrors>

      <FieldWithErrors name="mesto_do_id" label={t('Place')}>
        <AutoCompleteInput
          options={placeSuggestions ?? []}
          itemTemplate={placeItemTemplate}
          valueTemplate={placeItemTemplate}
          value={tryString(values.mesto_do_id) ?? null}
          filterValue={isRecipientsDataLoading ? '' : values.mesto_do_ime ?? ''}
          placeholder={t('Search places')}
          disabled={isRecipientsDataLoading}
          onFilterChange={handlePlaceChange}
          onSelectionChange={handlePlaceSelectionChange}
        />
      </FieldWithErrors>

      <FieldWithErrors
        name="ulica_do_id"
        label={isStreetFreeInputAllowed ? t('Address') : t('Street')}
      >
        {isStreetFreeInputAllowed ? (
          <Field
            as={InputText}
            name="adresa_do"
            id="adresa_do"
            maxLength="256"
          />
        ) : (
          <>
            <Field
              as={Dropdown}
              filter
              placeholder={t('Select street')}
              disabled={!values.mesto_do_id}
              filterInputAutoFocus
              options={streetOptions}
              inputId="ulica_do_id"
              name="ulica_do_id"
              onChange={(e: DropdownChangeParams) => {
                if (e.value) {
                  const item = streetOptions?.find((s) => s.value === e.value);
                  setFieldValue('ulica_do_id', e.value);
                  setFieldValue('adresa_do', item?.label);
                }
              }}
              className="p-mb-2"
            />

            <FieldWithErrors
              name="broj_do"
              label={false}
              includeErrorsFor={['vlez_do', 'stan_do']}
            >
              <div className="p-grid">
                <div className="p-col-4">
                  <Field
                    as={InputText}
                    id="broj_do"
                    name="broj_do"
                    placeholder={t('Street No.')}
                  />
                </div>

                <div className="p-col-4">
                  <Field
                    as={InputText}
                    id="vlez_do"
                    name="vlez_do"
                    placeholder={t('Entrance')}
                  />
                </div>

                <div className="p-col-4">
                  <Field
                    as={InputText}
                    id="stan_do"
                    name="stan_do"
                    placeholder={t('Apartment')}
                  />
                </div>
              </div>
            </FieldWithErrors>
          </>
        )}
      </FieldWithErrors>

      <FieldWithErrors
        name="mobilen_do"
        includeErrorsFor={['telefon_do', 'mobilen_do']}
        label={false}
      >
        <label htmlFor="mobilen_do">{t('Mobile number')}</label>

        <div className="p-field p-inputgroup number-group">
          <Field
            as={Dropdown}
            className="mobilen_do_region"
            style={{ padding: 0, width: '5rem' }}
            id="mobilen_do_region"
            name="mobilen_do_region"
            options={languages}
            itemTemplate={countryOptionTemplate}
            valueTemplate={selectedCountryTemplate}
          />

          <Field
            as={InputText}
            id="mobilen_do"
            name="mobilen_do"
            value={formatMobileNumber(
              values.mobilen_do || '',
              values.mobilen_do_region ||
                getPhoneorMobileNumberRegion(values.mobilen_do)
            )}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setFieldValue(
                'mobilen_do',
                e.target.value?.replace(invalidPhoneNumberCharactersRegex, '')
              );
            }}
          />
        </div>

        <label htmlFor="telefon_do">{t('Phone number')}</label>
        <div className="p-field p-inputgroup number-group">
          <Field
            as={Dropdown}
            className="telefon_do_region"
            style={{ padding: 0, width: '5rem' }}
            id="telefon_do_region"
            name="telefon_do_region"
            options={languages}
            itemTemplate={countryOptionTemplate}
            valueTemplate={selectedCountryTemplate}
          />

          <Field
            as={InputText}
            name="telefon_do"
            value={formatPhoneNumber(
              values.telefon_do || '',
              values.telefon_do_region ||
                getPhoneorMobileNumberRegion(values.telefon_do)
            )}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setFieldValue(
                'telefon_do',
                e.target.value?.replace(invalidPhoneNumberCharactersRegex, '')
              );
            }}
          />
        </div>
      </FieldWithErrors>

      <FieldWithErrors
        name="delivery_location_type_id"
        label={t('Recipient picks up from warehouse')}
      >
        <div className="p-d-flex">
          <Field
            as={Checkbox}
            name="delivery_location_type_id"
            className="hub-checkbox data-cy-deliver-to-hub-cbx"
            checked={values.delivery_location_type_id}
            onChange={(e: CheckboxChangeParams) => {
              setFieldValue('delivery_location_type_id', e.checked);

              if (!e.checked) {
                setFieldValue('hub_do_id', '');
                return;
              }
              setFieldValue('hub_do_id', nearestRecipientHub?.id);
            }}
          />
          <Field
            as={Dropdown}
            filter
            filterPlaceholder={t('Search')}
            filterBy="label"
            name="hub_do_id"
            inputId="hub_do_id"
            options={hubsSuggestions}
            optionLabel="label"
            optionValue="id"
            placeholder={
              isNearestRecipientHubLoading && values.delivery_location_type_id
                ? t('Loading...')
                : undefined
            }
            disabled={!values.delivery_location_type_id}
            className="hub-inputtext"
          />
        </div>
      </FieldWithErrors>
    </>
  );
}

export default RecipientTab;
