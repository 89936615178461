import { Field, useFormikContext } from 'formik';
import { Checkbox } from 'primereact/checkbox';
import { useTranslation } from 'react-i18next';

import FieldWithErrors from '../../../Forms/FieldWithErrors/FieldWithErrors';
import SectionFormTooltip from '../SectionFormTooltip';

function Web({ section }) {
  const { t } = useTranslation();

  const { values } = useFormikContext();

  return (
    <>
      <FieldWithErrors name={`${section}-SaveImportListContent`} label={false}>
        <Field
          as={Checkbox}
          inputId={`${section}-SaveImportListContent`}
          name={`${section}-SaveImportListContent`}
          checked={values[`${section}-SaveImportListContent`]}
        />
        <label htmlFor={`${section}-SaveImportListContent`}>
          {t('Save import list content')}
        </label>

        <SectionFormTooltip
          text={t('Save .csv file content in the database')}
        />
      </FieldWithErrors>
    </>
  );
}

export default Web;
