import { TFunction } from 'react-i18next';

import { Product } from '../enums/product';
import { LabelValue } from '../types/options';

export function getProductTypeLabels(t: TFunction): Record<Product, string> {
  return {
    [Product.Package]: t('Package'),
    [Product.Letter]: t('Letter'),
    [Product.PersonalDelivery]: t('Personal Delivery'),
    [Product.OversizePackage]: t('Oversize Package'),
  };
}

export function getProductTypeOptions(t: TFunction): LabelValue<Product>[] {
  const labels = getProductTypeLabels(t);

  return [
    { label: labels[Product.Package], value: Product.Package },
    { label: labels[Product.Letter], value: Product.Letter },
    {
      label: labels[Product.PersonalDelivery],
      value: Product.PersonalDelivery,
    },
    {
      label: labels[Product.OversizePackage],
      value: Product.OversizePackage,
    },
  ];
}
