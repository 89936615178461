import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, FieldArray, useFormikContext } from 'formik';
import _ from 'lodash';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { useMemo } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import emptyIllustration from '../../../../assets/svg/no-data-pana.svg';
import FieldWithErrors from '../../../Forms/FieldWithErrors/FieldWithErrors';
import {
  getNewFieldId,
  setNewFieldsIndexes,
} from './CreateEditDialog.functions';

function CreateEditDialogFieldMappingTab({
  onFieldDelete,
  entities,
  isEntitiesLoading,
  entityProperties,
  isEntityPropertiesLoading,
}) {
  const { t } = useTranslation();

  const { values, setFieldValue } = useFormikContext();

  const entitiesOptions = entities?.map((e) => ({
    value: e.id,
    label: e.displayname,
  }));

  const entityPropertiesByEntityId = useMemo(() => {
    return _.groupBy(entityProperties, 'entity_id');
  }, [entityProperties]);

  function getEntityPropertiesOptions(entity_id) {
    const entityId = entities?.find((e) => entity_id === e.id)?.id;

    return entityId
      ? entityPropertiesByEntityId[parseInt(entityId)]?.map((ep) => ({
          value: ep.id,
          label: ep.fieldname,
        }))
      : [];
  }

  function getTooltipForEntityProperty(i) {
    const entityId = values.fields[i].entity_id;
    const propertyId = values.fields[i].property_id;

    if (!entityId) {
      return t('You must select an entity first');
    }

    if (!propertyId) {
      return null;
    }

    const entityProperty = entityPropertiesByEntityId[entityId]?.find(
      (ep) => ep.id === propertyId
    );

    return entityProperty?.displayname.trim() || null;
  }

  function handleDragEnd(result) {
    if (!result.destination) {
      return;
    }

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    if (sourceIndex === destinationIndex) {
      return;
    }

    let newFields = [...values.fields];

    const [deleted] = newFields.splice(sourceIndex, 1);
    newFields.splice(destinationIndex, 0, deleted);

    setNewFieldsIndexes(newFields);

    setFieldValue('fields', newFields);
  }

  function handleFieldDeleteBtnClick(i) {
    onFieldDelete(values.fields[i]);

    let newFields = [...values.fields];
    newFields.splice(i, 1);

    setNewFieldsIndexes(newFields);

    setFieldValue('fields', newFields);
  }

  return (
    <div className="field-mapping-tab">
      {values.fields.length > 0 ? (
        <p>
          {t(
            'Reorder fields by grabbing the icon placed on the left side of each field'
          )}
        </p>
      ) : (
        <div className="no-fields">
          <img src={emptyIllustration} alt="" className="illustration" />

          <p>
            {t("This list type doesn't contain any fields yet.")}
            <br />
            {t('Add some by clicking the button below!')}
          </p>
        </div>
      )}
      <FieldWithErrors name="fields" label={false}>
        <table>
          {values.fields.length > 0 && (
            <thead>
              <tr>
                <th />
                <th>{t('Field name')}</th>
                <th>{t('Entity')}</th>
                <th>{t('Property')}</th>
                <th>{t('Required')}</th>
                <th>{t('Default value')}</th>
              </tr>
            </thead>
          )}

          <FieldArray
            name="fields"
            validateOnChange={false}
            render={(arrayHelpers) => (
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="list">
                  {(dropProvided) => (
                    <tbody ref={dropProvided.innerRef}>
                      {values.fields.map((field, i) => (
                        <Draggable
                          key={field.id}
                          index={i}
                          draggableId={field.id.toString()}
                        >
                          {(dragProvided) => (
                            <tr
                              key={field.id}
                              ref={dragProvided.innerRef}
                              {...dropProvided.droppableProps}
                              {...dragProvided.draggableProps}
                            >
                              <td {...dragProvided.dragHandleProps}>
                                <FontAwesomeIcon icon={faBars} />
                              </td>

                              <td>
                                <div className="p-fluid">
                                  <Field
                                    as={InputText}
                                    name={`fields[${i}].name`}
                                    data-cy={`name-${i}`}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="p-fluid">
                                  <Field
                                    name={`fields[${i}].entity_id`}
                                    as={Dropdown}
                                    options={entitiesOptions}
                                    filter
                                    filterPlaceholder={t('Search')}
                                    placeholder={
                                      isEntitiesLoading ? t('Loading...') : null
                                    }
                                    showClear
                                    onChange={(e) => {
                                      const entitySelected = entities?.find(
                                        (entity) => entity.id === e.value
                                      );

                                      const entityname = entitySelected
                                        ? entitySelected.displayname
                                        : null;

                                      setFieldValue(
                                        `fields[${i}].entityname`,
                                        entityname
                                      );
                                      setFieldValue(
                                        `fields[${i}].entity_id`,
                                        e.value
                                      );

                                      setFieldValue(
                                        `fields[${i}].property_id`,
                                        null
                                      );

                                      setFieldValue(
                                        `fields[${i}].propertyname`,
                                        null
                                      );
                                    }}
                                    className={`data-cy-entity-${i}`}
                                  />
                                </div>
                              </td>

                              <td>
                                <div className="p-fluid">
                                  <Field
                                    name={`fields[${i}].property_id`}
                                    as={Dropdown}
                                    options={getEntityPropertiesOptions(
                                      values.fields[i].entity_id
                                    )}
                                    filter
                                    filterPlaceholder={t('Search')}
                                    disabled={!values.fields[i].entity_id}
                                    placeholder={
                                      isEntityPropertiesLoading
                                        ? t('Loading...')
                                        : null
                                    }
                                    tooltip={getTooltipForEntityProperty(i)}
                                    showClear
                                    onChange={(e) => {
                                      const propertySelected =
                                        entityProperties?.find(
                                          (property) => property.id === e.value
                                        );

                                      const propertyname = propertySelected
                                        ? propertySelected.fieldname
                                        : null;

                                      setFieldValue(
                                        `fields[${i}].propertyname`,
                                        propertyname
                                      );
                                      setFieldValue(
                                        `fields[${i}].property_id`,
                                        e.value
                                      );
                                    }}
                                    className={`data-cy-property-${i}`}
                                  />
                                </div>
                              </td>

                              <td>
                                <div className="text-center">
                                  <Field
                                    name={`fields[${i}].isRequired`}
                                    as={Checkbox}
                                    checked={
                                      parseInt(values.fields[i].isRequired) ===
                                      1
                                    }
                                    onChange={(e) =>
                                      arrayHelpers.replace(i, {
                                        ...values.fields[i],
                                        isRequired: e.checked ? 1 : 0,
                                      })
                                    }
                                    className={`data-cy-required-${i}`}
                                  />
                                </div>
                              </td>

                              <td>
                                <div className="p-fluid">
                                  <Field
                                    as={InputText}
                                    name={`fields[${i}].default_value`}
                                    data-cy={`default_value-${i}`}
                                  />
                                </div>
                              </td>

                              <td>
                                {values.fields.length > 1 && (
                                  <Button
                                    type="button"
                                    icon="fas fa-times"
                                    tooltip={t('Remove field')}
                                    tooltipOptions={{ position: 'top' }}
                                    className="p-button-plain p-button-text"
                                    onClick={() => {
                                      handleFieldDeleteBtnClick(i);
                                    }}
                                    data-cy={`remove-field-${i}`}
                                  />
                                )}
                              </td>
                            </tr>
                          )}
                        </Draggable>
                      ))}

                      {dropProvided.placeholder}

                      <tr>
                        <td
                          colSpan="7"
                          className={`add-field-btn ${
                            values.fields.length > 0
                              ? 'text-right'
                              : 'text-center'
                          }`}
                        >
                          <Button
                            type="button"
                            label={t('Add field')}
                            icon="fas fa-plus"
                            className="p-button-outlined"
                            onClick={() =>
                              arrayHelpers.push({
                                id: getNewFieldId(),
                                name: '',
                                entity_id: null,
                                property_id: null,
                                isRequired: 0,
                                index: values.fields.length
                                  ? values.fields.length + 1
                                  : 1,
                                default_value: '',
                              })
                            }
                            data-cy="add-field"
                          />
                        </td>
                      </tr>
                    </tbody>
                  )}
                </Droppable>
              </DragDropContext>
            )}
          />
        </table>
      </FieldWithErrors>
    </div>
  );
}

export default CreateEditDialogFieldMappingTab;
