import { Dispatch, SetStateAction, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useAxiosHook from '../../../../../../../../hooks/useAxiosHook';
import useTableColumns from '../../../../../../../../hooks/useTableColumns';
import useTableState from '../../../../../../../../hooks/useTableState';
import {
  InvoiceCandidatesCustomerCollection,
  InvoiceCandidatesItemCollection,
} from '../../../../../../../../types/api/invoices';
import { Unpacked } from '../../../../../../../../types/util';
import { httpDateFormat } from '../../../../../../../../utils/helpers/formatting';
import { queryString } from '../../../../../../../../utils/helpers/http';
import Table from '../../../../../../../DataTable/Table/Table';
import BreadcrumbsDialogContext from '../../../../../../../Dialogs/BreadCrumbsDialog/BreadcrumbsDialogContext';
import {
  additionalColumnOptions,
  generateContextMenu,
  getColumnHeadersMap,
  tableStorageKey,
} from './Items.functions';

type ContextMenuSelection = Unpacked<InvoiceCandidatesCustomerCollection>;
type Selection = Unpacked<InvoiceCandidatesItemCollection>;

type Props = {
  dateFrom: Date;
  dateTo: Date;
  contextMenuSelection: ContextMenuSelection | undefined;
  selection: Selection | null;
  setSelection: Dispatch<SetStateAction<Selection | null>>;
};

function Items({
  dateFrom,
  dateTo,
  contextMenuSelection,
  selection,
  setSelection,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const columnHeadersMap = useMemo(() => getColumnHeadersMap(t), [t]);

  const { nextItem } = useContext(BreadcrumbsDialogContext);

  const {
    tableRef,
    page,
    setPage,
    limit,
    setLimit,
    sortField,
    sortOrder,
    setSortField,
    setSortOrder,
  } = useTableState(tableStorageKey);

  const { columns, columnOptions, selectedColumns, setSelectedColumns } =
    useTableColumns(
      page,
      limit,
      'invoicesItems',
      columnHeadersMap,
      columnHeadersMap,
      additionalColumnOptions
    );

  const { data, error, isLoading, reload } =
    useAxiosHook<InvoiceCandidatesItemCollection>(
      '/invoices/candidates/items' +
        queryString({
          client_id: contextMenuSelection?.client_id,
          department_id: contextMenuSelection?.department_id,
          date_from: httpDateFormat(dateFrom),
          date_to: httpDateFormat(dateTo),
        }),
      {
        skipWhen: !contextMenuSelection,
      }
    );

  const contextMenuModel = useMemo(
    () => generateContextMenu(t, () => (nextItem ? nextItem() : {})),
    [nextItem, t]
  );

  const headerTitle = t('Items');

  return (
    <Table
      tableRef={tableRef}
      columnOptions={columnOptions}
      columns={columns}
      data={data}
      hasError={!!error}
      headerTitle={headerTitle}
      isLoading={isLoading}
      rebuildTooltip
      reload={reload}
      rows={limit}
      selectedColumns={selectedColumns}
      selection={selection}
      setLimit={setLimit}
      setPage={setPage}
      setSelectedColumns={setSelectedColumns}
      setSelection={setSelection}
      setSortField={setSortField}
      setSortOrder={setSortOrder}
      sortField={sortField}
      sortOrder={sortOrder}
      storageString={tableStorageKey}
      contextMenuModel={contextMenuModel}
      onRowDoubleClick={() => (nextItem ? nextItem() : {})}
    />
  );
}

export default Items;
