import {
  clientLookupCollectionGuard,
  clientResourceGuard,
} from './_api/clients';
import { hubCollectionGuard, nearestHubCollectionGuard } from './_api/hubs';
import {
  editOrderResourceGuard,
  orderCalculatorGuard,
  orderRegExpResourceGuard,
  ordersByLocationCollectionGuard,
  ordersByLocationFilterCollectionGuard,
} from './_api/orders';
import {
  placeResourceGuard,
  placesStreetCollectionGuard,
  searchPlaceCollectionGuard,
} from './_api/places';
import { specializedProductCollectionGuard } from './_api/specializedProducts';

const createEditCommon = [
  hubCollectionGuard,
  orderCalculatorGuard,
  specializedProductCollectionGuard,
  clientLookupCollectionGuard,
  clientResourceGuard,
  placeResourceGuard,
  placesStreetCollectionGuard,
  nearestHubCollectionGuard,
  searchPlaceCollectionGuard,
];

export const viewShipment = orderRegExpResourceGuard;
export const editShipment = [...createEditCommon, editOrderResourceGuard];

export const routeGuard = [
  ordersByLocationFilterCollectionGuard,
  ordersByLocationCollectionGuard,
];
