import dayjs from 'dayjs';
import _ from 'lodash';
import * as Yup from 'yup';

import { isStreetFreeInputAllowed } from '../../../../../../utils/constants/misc';
import {
  formatMobileNumber,
  formatPhoneNumber,
  getPhoneorMobileNumberRegion,
  isValidMobileNumber,
  isValidPhoneNumber,
} from '../../../../../../utils/helpers/phoneNumbers';
import {
  conditionallyRequiredField,
  yupMaxField,
  yupMinField,
  yupRequiredField,
} from '../../../../../../utils/helpers/yup';

const fields = {
  ime: '',
  mobile: '',
  phone: '',
  working_hours: '',
  working_hours_from: '',
  working_hours_to: '',
  partner_id: null,
  partner_name: '',
  regions: [],
  municipality_name: '',
  municipality_id: '',
  place_id: '',
  place_name: '',
  street_name: '',
  adresa: '',
  street_id: '',
  street_no: '',
  sorting_center: false,
  drop_point: false,
};

export function getInitialValues(data = {}) {
  let initialValues = {};
  for (let f in fields) {
    initialValues[f] = data[f] || fields[f];
  }

  if (Object.keys(data).length) {
    const workingHours = data?.working_hours?.split('-');
    const workingHoursOK = workingHours?.length === 2;

    const start =
      workingHoursOK && _.trim(workingHours[0]) !== 'Invalid date '
        ? _.trim(workingHours[0])
        : null;
    const end =
      workingHoursOK && _.trim(workingHours[1]) !== ' Invalid date'
        ? _.trim(workingHours[1])
        : null;

    if (_.trim(start) && _.trim(end)) {
      const startWorkingHours = start?.split(':');
      const endWorkingHours = end?.split(':');

      if (startWorkingHours[0] && startWorkingHours[1]) {
        initialValues.working_hours_from = dayjs()
          .hour(startWorkingHours[0])
          .minute(startWorkingHours[1])
          .toDate();
      }
      if (endWorkingHours[0] && endWorkingHours[1]) {
        initialValues.working_hours_to = dayjs()
          .hour(endWorkingHours[0])
          .minute(endWorkingHours[1])
          .toDate();
      }
    } else {
      initialValues.working_hours = '';
    }

    initialValues.ime = data?.name ?? '';
    initialValues.ime = data?.name ?? '';
    initialValues.regions = data?.regions?.map((r) => r.id) ?? [];
  }

  initialValues.sorting_center = !!parseInt(data.sorting_center);
  initialValues.drop_point = !!parseInt(data.drop_point);

  initialValues.phone = data?.phone ?? initialValues.phone;
  initialValues.phone_region = getPhoneorMobileNumberRegion(
    initialValues.phone
  );

  initialValues.mobile = data?.mobile ?? initialValues.mobile;
  initialValues.mobile_region = getPhoneorMobileNumberRegion(
    initialValues.mobile
  );

  return initialValues;
}

export function toApiData(values) {
  let apiData = {
    ...values,
  };

  if (isStreetFreeInputAllowed) {
    delete apiData.ulica_id;
  } else {
    apiData.ulica_id = values.street_id;
    delete apiData.adresa;
  }

  if (apiData.working_hours_from && apiData.working_hours_to) {
    const start = dayjs(apiData.working_hours_from).format('HH:mm');
    const end = dayjs(apiData.working_hours_to).format('HH:mm');
    apiData.working_hours = `${start}-${end}`;
  }

  apiData.mesto_id = values.place_id;
  apiData.broj = values.street_no;
  apiData.telefon = values.phone;
  apiData.mobilen = values.mobile;

  if (!apiData.partner_id) {
    apiData.partner_id = null;
  }

  // Mobile and phone numbers
  if (apiData.telefon) {
    apiData.telefon = formatPhoneNumber(
      apiData.telefon,
      apiData.telefon_region
    );
  }

  if (apiData.mobilen) {
    apiData.mobilen = formatMobileNumber(
      apiData.mobilen,
      apiData.mobilen_region
    );
  }

  delete apiData.municipality_name;
  delete apiData.municipality_id;
  delete apiData.place_id;
  delete apiData.place_name;
  delete apiData.street_id;
  delete apiData.street_name;
  delete apiData.street_no;
  delete apiData.phone;
  delete apiData.mobile;
  delete apiData.working_hours_from;
  delete apiData.working_hours_to;
  delete apiData.partner_name;
  delete apiData.phone_region;
  delete apiData.mobile_region;

  return apiData;
}

export function getValidationSchema(t) {
  return {
    1: Yup.object().shape({
      ime: Yup.string()
        .required(yupRequiredField(t, t('Name')))
        .min(1, ({ min }) => yupMinField(t, t('Name'), min))
        .max(45, ({ max }) => yupMaxField(t, t('Name'), max)),
      phone: Yup.string()
        .test(
          'PhoneValidity',
          t('Invalid phone number'),
          function (value, context) {
            const { phone_region } = context.parent;
            return isValidPhoneNumber(value, phone_region);
          }
        )
        .test(
          'phone',
          t('At least phone or mobile must be filled out'),
          function (value) {
            const { mobile } = this.parent;

            if (!mobile) {
              return value != null;
            }

            return true;
          }
        ),
      mobile: Yup.string()
        .test(
          'MobileValidity',
          t('Invalid mobile number'),
          function (value, context) {
            const { mobile_region } = context.parent;
            return isValidMobileNumber(value, mobile_region);
          }
        )
        .test(
          'mobile',
          t('At least phone or mobile must be filled out'),
          function (value) {
            const { phone } = this.parent;

            if (!phone) {
              return value != null;
            }

            return true;
          }
        ),

      working_hours_from: Yup.date().nullable(),
      working_hours_to: Yup.date().nullable(),
    }),
    2: Yup.object().shape({
      partner: Yup.string().nullable(),
      regions: Yup.array().nullable(),
      sorting_center: Yup.string(),
      drop_point: Yup.string(),
    }),
    3: Yup.object().shape({
      municipality_id: Yup.string()
        .nullable()
        .required(t('Municipality is required field')),
      place_id: Yup.string().nullable().required(t('Place is required field')),
      street_id: conditionallyRequiredField(
        Yup.string().nullable(),
        !isStreetFreeInputAllowed,
        yupRequiredField(t, t('Street'))
      ),
      adresa: conditionallyRequiredField(
        Yup.string(),
        isStreetFreeInputAllowed,
        yupRequiredField(t, t('Street'))
      ),
      street_no: Yup.string()
        .required(t('Required'))
        .min(1, ({ min }) => yupMinField(t, t('Street No.'), min))
        .max(45, ({ max }) => yupMaxField(t, t('Street No.'), max)),
    }),
  };
}
