import { Form, Formik, FormikProps } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useRef } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useAxiosHook from '../../../../../hooks/useAxiosHook';
import useToastMessage from '../../../../../hooks/useToastMessage';
import { ClientCollection } from '../../../../../types/api/clients';
import { Unpacked } from '../../../../../types/util';
import { sequential } from '../../../../../utils/helpers/functions';
import DialogContent from './DialogContent';
import {
  FormFields,
  getInitialValues,
  getValidationSchema,
} from './MergeDialog.functions';
import styles from './MergeDialog.module.scss';

type Props = {
  isShown: boolean;
  onHide: () => void;
  client: Unpacked<ClientCollection> | undefined;
  successCallback: () => void;
};

function MergeDialog({
  isShown,
  onHide,
  client,
  successCallback,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const validationSchema = useMemo(() => getValidationSchema(t), [t]);

  const formRef = useRef<FormikProps<FormFields>>(null);

  const { data, error, reload, isLoading } = useAxiosHook();

  const preservingName: string =
    (formRef.current?.values.preserving as Unpacked<ClientCollection> | null)
      ?.ime ?? '';

  const deletingName: string =
    (formRef.current?.values.deleting as Unpacked<ClientCollection> | null)
      ?.ime ?? '';

  useToastMessage(data, error, {
    success: {
      summary: t(
        'Client {{deletingName}} was successfully merged into {{preservingName}}.',
        {
          deletingName,
          preservingName,
        }
      ),
      callback: () => sequential(successCallback, onHide),
    },
    error: {
      summary: t(
        'Something went wrong while trying to merge client {{deletingName}} into {{preservingName}}.',
        {
          deletingName,
          preservingName,
        }
      ),
      callback: onHide,
    },
  });

  function handleFormSubmision(values: FormFields) {
    const preserving = values.preserving as Unpacked<ClientCollection>;
    const deleting = values.deleting as Unpacked<ClientCollection>;

    reload({
      url: `/clients/merge`,
      method: 'POST',
      data: {
        source_id: deleting.id,
        target_id: preserving.id,
      },
    });
  }

  const dialogFooter = (
    <>
      <Button
        type="button"
        label={t('Cancel')}
        className="p-button-text"
        onClick={() => onHide()}
      />

      <Button
        type="button"
        label={isLoading ? t('Merging...') : t('Merge clients')}
        disabled={isLoading}
        onClick={() => {
          formRef?.current?.handleSubmit();
        }}
        data-cy="submit"
      />
    </>
  );

  const initialValues = useMemo(() => getInitialValues(client), [client]);

  return (
    <Dialog
      header={t('Merge clients')}
      footer={dialogFooter}
      visible={isShown}
      onHide={onHide}
      resizable={false}
      className={styles.mergeDialog}
    >
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmision}
      >
        <Form>
          <DialogContent client={client} />
        </Form>
      </Formik>
    </Dialog>
  );
}

export default MergeDialog;
