import './CreateWarrants.scss';

import { faFileSignature } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useDebounce } from 'use-lodash-debounce';

import ToastContext from '../../../../context/ToastContext';
import useAxiosHook from '../../../../hooks/useAxiosHook';
import { useEndpointGuard } from '../../../../hooks/useEndpointGuard';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import usePageTitle from '../../../../hooks/usePageTitle';
import usePrevious from '../../../../hooks/usePrevious';
import useSearchQueryDateParam from '../../../../hooks/useSearchQueryDateParam';
import useSearchQueryParam from '../../../../hooks/useSearchQueryParam';
import useTableColumns from '../../../../hooks/useTableColumns';
import useTableDataToDisplay from '../../../../hooks/useTableDataToDisplay';
import useTableState from '../../../../hooks/useTableState';
import { CreateCODWarrantResource } from '../../../../types/api/cashondeliveries';
import { TreeComponentSelectionNode } from '../../../../types/primereact';
import * as createWarrantsGuards from '../../../../utils/constants/auth/cod/createWarrants';
import { debounceTimeout } from '../../../../utils/constants/misc';
import { toastWithLinkLife } from '../../../../utils/constants/primereact';
import {
  RoutePaths,
  constructIdRoute,
} from '../../../../utils/constants/routePaths';
import { getDataTableProps } from '../../../../utils/globals';
import {
  currencyFormat,
  httpDateFormat,
} from '../../../../utils/helpers/formatting';
import { queryString } from '../../../../utils/helpers/http';
import { tryString } from '../../../../utils/helpers/parse';
import { errorToast, successToast } from '../../../../utils/helpers/primereact';
import {
  dateSearchParamToString,
  getSearchQueryParam,
  tryDateSearchParam,
} from '../../../../utils/helpers/searchQuery';
import Table from '../../../DataTable/Table/Table';
import Barcode from '../../../Sidebar/Barcode/Barcode';
import HeaderPages from '../../Components/HeaderPages/HeaderPages';
import {
  ApiData,
  GroupFilter,
  ParcelCandidate,
  additionalParcelsColumnProperties,
  getParcelsColumnHeadersMap,
  tableStorageKey,
} from './CreateWarrants.functions';
import Actions from './Sidebar/Actions/Actions';
import ClientFilters from './Sidebar/Filters/ClientFilters';
import ClientTree from './Sidebar/Tree/ClientTree';

function CreateWarrants() {
  const { t } = useTranslation();

  usePageTitle(t('COD Create Warrants'));

  const createGuard = useEndpointGuard(createWarrantsGuards.createWarrant);

  const { toastRef, bottomRightToastRef } = useContext(ToastContext);

  const location = useLocation();

  const [barcodeValue, setBarcodeValue] = useState<string>('');
  const [barcodeError, setBarcodeError] = useState<boolean>(false);

  const [selectedNodes, setSelectedNodes] =
    useState<TreeComponentSelectionNode>({});

  const [groupFilter, setGroupFilter] = useState<GroupFilter>(() => {
    const searchQueryParam = getSearchQueryParam(location.search, 'groupBy');

    if (searchQueryParam === 'ime' || searchQueryParam === 'datum_isporaka') {
      return searchQueryParam;
    }

    return 'datum_isporaka';
  });

  const [dateFromFilter, setDateFromFilter] = useState<Date | null>(
    () =>
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'date_from') ?? ''
      ) ?? dayjs().subtract(6, 'months').toDate()
  );

  const [dateToFilter, setDateToFilter] = useState<Date | null>(
    () =>
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'date_to') ?? ''
      ) ?? new Date()
  );

  useSearchQueryParam('groupBy', groupFilter);
  useSearchQueryDateParam('date_from', dateFromFilter);
  useSearchQueryDateParam('date_to', dateToFilter);

  const canLoadData = dateFromFilter && dateToFilter;

  const { data, reload, isLoading, error } = useAxiosHook<ApiData>(
    '/cashondeliveries/candidates' +
      queryString({
        date_from: dateFromFilter ? httpDateFormat(dateFromFilter) : undefined,
        date_to: dateToFilter ? httpDateFormat(dateToFilter) : undefined,
      }),
    {
      skipWhen: !canLoadData,
    }
  );

  const {
    tableRef,
    page,
    setPage,
    limit,
    setLimit,
    sortField,
    sortOrder,
    selectionMultiple,
    setSortField,
    setSortOrder,
    setSelectionMultiple,
  } = useTableState<ParcelCandidate>(tableStorageKey);

  const [contextMenuSelection, setContextMenuSelection] = useState<any>(null);

  const dataTableProps = useMemo(() => getDataTableProps(t), [t]);

  const parcelsColumnHeadersMap = useMemo(
    () => getParcelsColumnHeadersMap(t),
    [t]
  );

  const { selectedColumns, setSelectedColumns, columnOptions, columns } =
    useTableColumns(
      page,
      limit,
      'cod_createWarrants',
      parcelsColumnHeadersMap,
      parcelsColumnHeadersMap,
      (c) =>
        additionalParcelsColumnProperties(
          c as keyof typeof parcelsColumnHeadersMap
        )
    );

  const debouncedValue = useDebounce(barcodeValue, debounceTimeout);

  useEffect(() => {
    const trimmedDebounceValue = debouncedValue.trim();

    if (!trimmedDebounceValue) {
      return;
    }

    const parcel = (data?.parcels ?? []).find(
      (p) => p.barkod === trimmedDebounceValue
    );

    if (!parcel) {
      if (debouncedValue.length >= 5) {
        setBarcodeError(true);
      }

      return;
    }

    setBarcodeValue('');

    setSelectionMultiple((prevSelection: ParcelCandidate[]) =>
      Array.from(new Set([...prevSelection, parcel]))
    );

    if (!bottomRightToastRef) {
      return;
    }

    successToast(
      bottomRightToastRef,
      t('Parcel selected'),
      t('The parcel has been selected.'),
      { life: 1500 }
    );
  }, [
    bottomRightToastRef,
    data?.parcels,
    debouncedValue,
    setSelectionMultiple,
    t,
  ]);

  const [totalParcelValue, setTotalParcelValue] = useState<string>('0');

  const customDataModifier = useCallback<
    (data: ParcelCandidate[]) => ParcelCandidate[]
  >(
    (data: ParcelCandidate[]) => {
      let returnVal = _.clone(data);

      const snKeys = selectedNodes ? Object.keys(selectedNodes) : [];
      const snKeysIds = snKeys
        .map((n) => n !== 'all' && n.substring(11))
        .filter((n) => !!n);

      if (
        snKeysIds.length > 0 &&
        !selectedNodes?.all?.checked &&
        selectedNodes?.all?.partialChecked
      ) {
        returnVal = returnVal.filter(
          (p) =>
            !!snKeysIds.find(
              (key) =>
                key === tryString(p.klient_od_id) ||
                key === tryString(p.klient_od_direkcija_id)
            )
        );
      }

      return returnVal;
    },
    [selectedNodes]
  );

  const dataToDisplay = useTableDataToDisplay(
    data?.parcels ?? [],
    sortField,
    sortOrder,
    customDataModifier
  );

  useEffect(() => {
    if (!createGuard) {
      setSelectionMultiple([]);
    }
  }, [createGuard, setSelectionMultiple]);

  const prevSelectionMultiple = usePrevious(selectionMultiple);

  useEffect(() => {
    // Fixes infinite rerendering
    if (selectionMultiple === prevSelectionMultiple) {
      setSelectionMultiple((prevSelected) => {
        const prevSelectedIds = _.map(prevSelected, 'id');

        return dataToDisplay.filter((o) => prevSelectedIds.includes(o.id));
      });
    }

    setTotalParcelValue(
      currencyFormat(
        _.sumBy(
          dataToDisplay.map((row) => row.otkup ?? '0', 'otkup'),
          parseFloat
        ),
        {
          showCurrency: true,
        }
      )
    );
  }, [
    dataToDisplay,
    prevSelectionMultiple,
    selectionMultiple,
    setSelectionMultiple,
  ]);

  const paginatorRight = useMemo<JSX.Element>(
    () => (
      <p className="p-m-2">
        {t('Total value')}:{' '}
        {createGuard && selectionMultiple.length > 0
          ? currencyFormat(
              _.sumBy(
                selectionMultiple.map((row) => row.otkup ?? '0'),
                parseFloat
              ),
              { showCurrency: true }
            ) + ' / '
          : ''}
        {totalParcelValue}
      </p>
    ),
    [createGuard, selectionMultiple, t, totalParcelValue]
  );

  const warrantsQueryParams = useCallback(
    () =>
      dateFromFilter && dateToFilter
        ? `?date_from=${dateSearchParamToString(
            dateFromFilter
          )}&date_to=${dateSearchParamToString(dateToFilter)}`
        : '',
    [dateFromFilter, dateToFilter]
  );

  const {
    data: createListData,
    error: createListError,
    reload: createListRequest,
    isLoading: isCreateListLoading,
  } = useAxiosHook<CreateCODWarrantResource>(
    { url: '/cashondeliveries', method: 'POST' },
    {
      skipWhen: true,
    }
  );

  const prevCreateListData = usePrevious(createListData);
  const prevCreateListError = usePrevious(createListError);

  useEffect(() => {
    if (!createListData || createListData === prevCreateListData || !toastRef) {
      return;
    }

    successToast(
      toastRef,
      t('Success'),
      <>
        {t('A warrant has been successfully created for the selected parcels.')}

        {createListData.length === 1 && (
          <p className="p-mb-0">
            <Link
              to={`${constructIdRoute(
                RoutePaths.ViewWarrant,
                createListData[0].id
              )}${warrantsQueryParams()}`}
            >
              {t("View warrant's parcels")}
            </Link>
          </p>
        )}
      </>,
      {
        life: toastWithLinkLife,
      }
    );

    reload();
  }, [
    createListData,
    prevCreateListData,
    reload,
    t,
    toastRef,
    warrantsQueryParams,
  ]);

  useEffect(() => {
    if (
      !createListError ||
      createListError === prevCreateListError ||
      !toastRef
    ) {
      return;
    }

    errorToast(
      toastRef,
      t('Error'),
      t('An error occured while creating the warrant.')
    );
  }, [createListError, prevCreateListError, t, toastRef]);

  function handleCreateWarrantBtnClick() {
    const selectedIds = _.map(selectionMultiple, (row) => ({ id: row.id }));

    createListRequest({
      data: {
        parcels: selectedIds,
      },
    });
  }

  const isTwoColumnLayout = useMediaQuery('(min-width: 769px)');

  return (
    <div className="page cod-create-warrants-page">
      <HeaderPages
        title={t('Create Warrants')}
        subtitle={t('View cash on delivery candidates for creating a warrant')}
        icon={faFileSignature}
        // goBackTitle={t('Warrants')}
        // goBackTo={`/cod/warrants${warrantsQueryParams()}`}
      />

      <div className="p-grid">
        <div
          {...(isTwoColumnLayout
            ? { className: 'p-col-fixed', style: { width: 360 } }
            : { className: 'p-col-12' })}
        >
          <ClientFilters
            dateFromFilter={dateFromFilter}
            setDateToFilter={setDateToFilter}
            dateToFilter={dateToFilter}
            setDateFromFilter={setDateFromFilter}
            groupFilter={groupFilter}
            setGroupFilter={setGroupFilter}
          />

          {createGuard && (
            <>
              <Barcode
                value={barcodeValue}
                setValue={setBarcodeValue}
                hasError={barcodeError}
                setHasError={setBarcodeError}
              />

              <Actions
                onBtnClick={handleCreateWarrantBtnClick}
                isDisabled={
                  isLoading ||
                  isCreateListLoading ||
                  selectionMultiple.length === 0
                }
                isLoading={isCreateListLoading}
                hasError={!!createListError}
              />
            </>
          )}

          <ClientTree
            clientTree={data?.client_tree ?? []}
            selectedNodes={selectedNodes}
            setSelectedNodes={setSelectedNodes}
            isLoading={isLoading}
            groupFilter={groupFilter}
          />
        </div>

        <div
          {...(isTwoColumnLayout
            ? {
                className: 'p-col p-pb-0',
                style: { width: 'calc(100% - 360px)' },
              }
            : { className: 'p-col-12 p-pb-0' })}
        >
          <Table
            tableRef={tableRef}
            columns={columns}
            data={dataToDisplay}
            isReloadDisabled={!canLoadData}
            hasError={!!error}
            isLoading={isLoading}
            reload={reload}
            setPage={setPage}
            setLimit={setLimit}
            sortField={sortField}
            lazy={false}
            rows={limit}
            filterHeight={10}
            setSortField={setSortField}
            setSortOrder={setSortOrder}
            setSelection={createGuard ? setSelectionMultiple : () => {}}
            sortOrder={sortOrder}
            selection={createGuard ? selectionMultiple : {}}
            storageString={tableStorageKey}
            rebuildTooltip
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            columnOptions={columnOptions}
            footer={paginatorRight}
            selectionMode={createGuard ? 'multiple' : 'single'}
            headerTitle={t('Parcels')}
            emptyMessage={
              !!error
                ? dataTableProps.emptyMessageError
                : selectedNodes &&
                  Object.keys(selectedNodes)?.length === 1 &&
                  !(selectedNodes as any)?.all?.checked &&
                  !(selectedNodes as any)?.all?.partialChecked
                ? t('Select some candidates to view their associated parcels')
                : t("The selected candidates don't have any associated parcels")
            }
            contextMenuSelection={contextMenuSelection}
            setContextMenuSelection={setContextMenuSelection}
          />
        </div>
      </div>
    </div>
  );
}

export default CreateWarrants;
