import dayjs from 'dayjs';
import _ from 'lodash';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { Dispatch, useMemo } from 'react';
import { useState } from 'react';
import { SetStateAction } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDebounce } from 'use-lodash-debounce';

import useAxiosHook from '../../../hooks/useAxiosHook';
import { useEndpointGuard } from '../../../hooks/useEndpointGuard';
import useHaveValuesChanged from '../../../hooks/useHaveValuesChanged';
import useSearchQueryAutoCompleteInputParam from '../../../hooks/useSearchQueryAutoCompleteInputParam';
import useSearchQueryDateParam from '../../../hooks/useSearchQueryDateParam';
import useSearchQueryParam from '../../../hooks/useSearchQueryParam';
import {
  ClientLookupCollection,
  ClientLookupCollectionQueryParams,
  ClientResource,
} from '../../../types/api/clients';
import {
  EmployeeCollection,
  EmployeeResource,
} from '../../../types/api/employees';
import { LabelValue } from '../../../types/options';
import * as courierLiabilitiesGuards from '../../../utils/constants/auth/courierLiabilities';
import { debounceTimeout } from '../../../utils/constants/misc';
import { dateToFilterText } from '../../../utils/helpers/dataTable';
import { httpDateFormat } from '../../../utils/helpers/formatting';
import { identity } from '../../../utils/helpers/functions';
import { queryString } from '../../../utils/helpers/http';
import {
  getSearchQueryParam,
  tryDateSearchParam,
} from '../../../utils/helpers/searchQuery';
import TableHeaderFilters from '../../DataTable/Table/HeaderFilters/TableHeaderFilters';
import {
  OrdinaryFilter,
  RequiredFilter,
  filterByValue,
} from '../../DataTable/Table/HeaderFilters/TableHeaderFilters.function';
import AutoCompleteInput from '../../Forms/AutoCompleteInput/AutoCompleteInput';
import { ClientOption, EmployeeOption } from './CourierLiabilities.functions';

function useTableFilters(
  page: number,
  setPage: Dispatch<SetStateAction<number>>,
  limit: number
) {
  const { t } = useTranslation();

  const employeeFiltersGuard = useEndpointGuard(
    courierLiabilitiesGuards.employeeFilters
  );
  const clientFilterGuard = useEndpointGuard(
    courierLiabilitiesGuards.clientFilter
  );

  const location = useLocation();

  const [headerFiltersCount, setHeadersFilterCount] = useState<number>(0);

  const [dateFrom, setDateFrom] = useState<Date | null>(
    () =>
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'date_from') ?? ''
      ) ?? new Date()
  );

  const [dateTo, setDateTo] = useState<Date | null>(
    () =>
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'date_to') ?? ''
      ) ?? new Date()
  );

  const [prefixFilter, setPrefixFilter] = useState(
    getSearchQueryParam(location.search, 'prefix') ?? ''
  );

  const [serialNoFilter, setSerialNoFilter] = useState(
    getSearchQueryParam(location.search, 'serialNo') ?? ''
  );

  const [clientFilter, setClientFilter] = useState('');
  const [clientFilterObj, setClientFilterObj] =
    useState<ClientOption | null>(null);

  const [employeeFilter, setEmployeeFilter] = useState('');
  const [employeeFilterObj, setEmployeeFilterObj] =
    useState<EmployeeOption | null>(null);

  const [courierFilter, setCourierFilter] = useState('');
  const [courierFilterObj, setCourierFilterObj] =
    useState<EmployeeOption | null>(null);

  const debouncedPrefixFilter = useDebounce(prefixFilter, debounceTimeout);
  const debouncedSerialNoFilter = useDebounce(serialNoFilter, debounceTimeout);
  const debouncedClientFilter = useDebounce(clientFilter, debounceTimeout);
  const debouncedEmployeeFilter = useDebounce(employeeFilter, debounceTimeout);
  const debouncedCourierFilter = useDebounce(courierFilter, debounceTimeout);

  const { data: adminData, isLoading: isAdminDataLoading } =
    useAxiosHook<EmployeeCollection>(
      '/employees' +
        queryString({
          name: debouncedEmployeeFilter,
          roles: [1],
          limit: 20,
        }),
      { skipWhen: !employeeFiltersGuard }
    );

  const { data: courierData, isLoading: isCourierDataLoading } =
    useAxiosHook<EmployeeCollection>(
      '/employees' +
        queryString({
          name: debouncedCourierFilter,
          roles: [3],
          limit: 20,
        }),
      { skipWhen: !employeeFiltersGuard }
    );

  const { data: clientData, isLoading: isClientDataLoading } =
    useAxiosHook<ClientLookupCollection>(
      '/clients/lookup' +
        queryString<ClientLookupCollectionQueryParams>({
          ime: debouncedClientFilter,
          page: 1,
          limit: 20,
        }),
      { skipWhen: _.isObject(clientFilterObj) || !clientFilterGuard }
    );

  const couriersOptions = useMemo(
    () =>
      courierData?.data?.map((courier) => {
        return {
          label: courier.ime + ' ' + courier.prezime,
          value: courier,
        };
      }) ?? [],
    [courierData?.data]
  );

  const employeesOptions = useMemo(
    () =>
      adminData?.data?.map((employee) => {
        return {
          label: employee.ime + ' ' + employee.prezime,
          value: employee,
        };
      }) ?? [],
    [adminData?.data]
  );

  const clientOptions = useMemo<LabelValue<ClientOption>[]>(
    () =>
      clientData?.data.map((client) => {
        return { label: client.ime, value: client };
      }) ?? [],
    [clientData?.data]
  );

  const filtersArr = useMemo(
    () => [
      dateFrom,
      dateTo,
      courierFilter,
      clientFilterObj,
      employeeFilter,
      debouncedPrefixFilter,
      debouncedSerialNoFilter,
    ],
    [
      courierFilter,
      dateFrom,
      dateTo,
      clientFilterObj,
      employeeFilter,
      debouncedPrefixFilter,
      debouncedSerialNoFilter,
    ]
  );

  const haveFiltersChanged = useHaveValuesChanged(filtersArr);

  useEffect(() => {
    setPage(1);
  }, [
    setPage,
    dateFrom,
    dateTo,
    courierFilter,
    employeeFilter,
    clientFilterObj,
    debouncedPrefixFilter,
    debouncedSerialNoFilter,
  ]);

  useSearchQueryParam('prefix', debouncedPrefixFilter);
  useSearchQueryParam('serialNo', debouncedSerialNoFilter);

  useSearchQueryDateParam('date_from', dateFrom);
  useSearchQueryDateParam('date_to', dateTo);

  useSearchQueryAutoCompleteInputParam<ClientOption, ClientResource>({
    param: 'client',
    filterValue: clientFilter,
    setFilterValue: setClientFilter,
    filterValueObj: clientFilterObj,
    setFilterValueObj: setClientFilterObj,
    axiosRequestConfig: (clientId) => `/clients/${clientId}`,
    initialFilterValueDataPath: 'ime',
    filterValueObjPath: 'id',
    initialDataModifier: identity,
  });

  useSearchQueryAutoCompleteInputParam<EmployeeOption, EmployeeResource>({
    param: 'employee',
    filterValue: employeeFilter,
    setFilterValue: setEmployeeFilter,
    filterValueObj: employeeFilterObj,
    setFilterValueObj: setEmployeeFilterObj,
    axiosRequestConfig: (employeeId) => `/employees/${employeeId}`,
    initialFilterValueDataPath: (employeeResource) =>
      `${employeeResource.data.ime} ${employeeResource.data.prezime}`,
    filterValueObjPath: 'id',
    initialDataModifier: (employeeResource) => employeeResource.data,
  });

  useSearchQueryAutoCompleteInputParam<EmployeeOption, EmployeeResource>({
    param: 'courier',
    filterValue: courierFilter,
    setFilterValue: setCourierFilter,
    filterValueObj: courierFilterObj,
    setFilterValueObj: setCourierFilterObj,
    axiosRequestConfig: (employeeId) => `/employees/${employeeId}`,
    initialFilterValueDataPath: (employeeResource) =>
      `${employeeResource.data.ime} ${employeeResource.data.prezime}`,
    filterValueObjPath: 'id',
    initialDataModifier: (employeeResource) => employeeResource.data,
  });

  useEffect(() => {
    if (!employeeFiltersGuard) {
      setCourierFilterObj(null);
      setEmployeeFilterObj(null);
    }
  }, [employeeFiltersGuard]);

  useEffect(() => {
    if (!clientFilterGuard) {
      setClientFilter('');
      setClientFilterObj(null);
    }
  }, [clientFilterGuard]);

  const httpFiltersObj = useMemo(
    () => ({
      date_from: dateFrom ? httpDateFormat(dateFrom) : undefined,
      date_to: dateTo ? httpDateFormat(dateTo) : undefined,
      postar_id: courierFilterObj?.id,
      rabotnik_id: employeeFilterObj?.id,
      client_id: clientFilterObj?.id,
      prefix: debouncedPrefixFilter,
      serial_no: debouncedSerialNoFilter,
      page: haveFiltersChanged ? 1 : page,
      limit,
    }),
    [
      clientFilterObj?.id,
      courierFilterObj?.id,
      dateFrom,
      dateTo,
      debouncedPrefixFilter,
      debouncedSerialNoFilter,
      employeeFilterObj?.id,
      haveFiltersChanged,
      limit,
      page,
    ]
  );

  const headerFiltersForm = useMemo<JSX.Element>(
    () => (
      <>
        <div className="filter">
          <label htmlFor="filter_date_from">{t('Date From')}</label>
          <Calendar
            value={dateFrom ?? undefined}
            monthNavigator
            yearNavigator
            yearRange={`2009:${dayjs().format('YYYY')}`}
            dateFormat="dd/mm/yy"
            maxDate={new Date()}
            inputId="filter_date_from"
            onChange={(e) => {
              setDateFrom(e.value as Date | null);
            }}
          />
        </div>

        <div className="filter">
          <label htmlFor="filter_date_to">{t('Date To')}</label>
          <Calendar
            value={dateTo ?? undefined}
            monthNavigator
            yearNavigator
            yearRange={`2009:${dayjs().format('YYYY')}`}
            dateFormat="dd/mm/yy"
            maxDate={new Date()}
            inputId="filter_date_to"
            onChange={(e) => {
              setDateTo(e.value as Date | null);
            }}
          />
        </div>

        {employeeFiltersGuard && (
          <>
            <div className="filter">
              <label htmlFor="filter_courier">{t('Courier')}</label>
              <AutoCompleteInput
                id="filter_courier"
                filterValue={courierFilter}
                value={courierFilterObj}
                options={couriersOptions}
                onFilterChange={setCourierFilter}
                onSelectionChange={setCourierFilterObj}
                isLoading={isCourierDataLoading}
              />
            </div>

            <div className="filter">
              <label htmlFor="filter_employee">{t('Employee')}</label>
              <AutoCompleteInput
                id="filter_employee"
                filterValue={employeeFilter}
                value={employeeFilterObj}
                options={employeesOptions}
                onFilterChange={setEmployeeFilter}
                onSelectionChange={setEmployeeFilterObj}
                isLoading={isAdminDataLoading}
              />
            </div>
          </>
        )}

        {clientFilterGuard && (
          <div className="filter">
            <label htmlFor="filter_client">{t('Client')}</label>
            <AutoCompleteInput
              id="filter_client"
              filterValue={clientFilter}
              value={clientFilterObj}
              options={clientOptions}
              onFilterChange={setClientFilter}
              onSelectionChange={setClientFilterObj}
              isLoading={isClientDataLoading}
            />
          </div>
        )}

        <div className="filter">
          <label htmlFor="filter_prefix">{t('Prefix')}</label>
          <InputText
            id="filter_prefix"
            value={prefixFilter}
            onChange={(e) => setPrefixFilter(e.target.value)}
          />
        </div>

        <div className="filter">
          <label htmlFor="filter_serial_no">{t('Serial No')}</label>
          <InputText
            id="filter_serial_no"
            value={serialNoFilter}
            onChange={(e) =>
              setSerialNoFilter(e.target.value.replace(/\D/g, ''))
            }
          />
        </div>
      </>
    ),
    [
      clientFilter,
      clientFilterGuard,
      clientFilterObj,
      clientOptions,
      courierFilter,
      courierFilterObj,
      couriersOptions,
      dateFrom,
      dateTo,
      employeeFilter,
      employeeFilterObj,
      employeeFiltersGuard,
      employeesOptions,
      isAdminDataLoading,
      isClientDataLoading,
      isCourierDataLoading,
      prefixFilter,
      serialNoFilter,
      t,
    ]
  );

  const headerFilters = useMemo(() => {
    const required: RequiredFilter[] = [
      {
        label: t('Date'),
        value: dateToFilterText([dateFrom, dateTo]),
      },
    ];

    const ordinary: OrdinaryFilter[] = [
      {
        label: t('Courier'),
        value: courierFilterObj
          ? `${courierFilterObj.ime} ${courierFilterObj.prezime}`
          : '',
        onDelete: () => {
          setCourierFilter('');
          setCourierFilterObj(null);
        },
      },
      {
        label: t('Employee'),
        value: employeeFilterObj
          ? `${employeeFilterObj.ime} ${employeeFilterObj.prezime}`
          : '',
        onDelete: () => {
          setEmployeeFilter('');
          setEmployeeFilterObj(null);
        },
      },
      {
        label: t('Client'),
        value: clientFilterObj?.ime ?? '',
        onDelete: () => {
          setClientFilter('');
          setClientFilterObj(null);
        },
      },
      {
        label: t('Prefix'),
        value: prefixFilter,
        onDelete: () => setPrefixFilter(''),
      },
      {
        label: t('Serial No'),
        value: serialNoFilter,
        onDelete: () => setSerialNoFilter(''),
      },
    ].filter(filterByValue);

    setHeadersFilterCount(ordinary.length);

    return <TableHeaderFilters required={required} ordinary={ordinary} />;
  }, [
    clientFilterObj?.ime,
    courierFilterObj,
    dateFrom,
    dateTo,
    employeeFilterObj,
    prefixFilter,
    serialNoFilter,
    t,
  ]);

  function resetAllFilters() {
    setDateFrom(new Date());
    setDateTo(new Date());
    setCourierFilter('');
    setCourierFilterObj(null);
    setEmployeeFilter('');
    setEmployeeFilterObj(null);
    setClientFilter('');
    setClientFilterObj(null);
    setPrefixFilter('');
    setSerialNoFilter('');
  }

  return {
    headerFiltersForm,
    headerFilters,
    resetAllFilters,
    httpFiltersObj,
    headerFiltersCount,
    couriersOptions,
  };
}

export default useTableFilters;
