import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import { MenuContext } from '../../context/MenuContext';
import logos from '../../utils/logos';
import WhatsNewDialog from './Dialogs/WhatsNewDialog';
import HeaderMenu from './HeaderMenu';
import LanguageSelector from './LanguageSelector';

function AuthHeader(): JSX.Element {
  const [isWhatsNewDialogVisible, setIsWhatsNewDialogVisible] = useState(false);
  const { setIsMenuExpanded, isMenuExpanded } = useContext(MenuContext);
  return (
    <>
      <div
        className="menu-btn"
        onClick={() => setIsMenuExpanded(!isMenuExpanded)}
      >
        <FontAwesomeIcon icon={faBars} />
      </div>
      <Link to="/" className="logo">
        <img
          src={
            logos[process.env.REACT_APP_CLIENT_NAME as keyof typeof logos] ??
            logos['koder']
          }
          alt="Logo"
        />
      </Link>
      <div className="right-section">
        <LanguageSelector />
        <HeaderMenu />
      </div>
      <WhatsNewDialog
        isShown={isWhatsNewDialogVisible}
        onHide={() => setIsWhatsNewDialogVisible(false)}
      />
    </>
  );
}

export default AuthHeader;
