import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { LabelValue } from '../../../../../types/options';
import { Route } from '../../Routes.functions';

type Props = {
  data: Route | undefined;
  privacyOptions: LabelValue[];
  rolesOptions: LabelValue<number>[];
  clientTypeOptions: LabelValue<number>[];
};

function DialogContainer({
  data,
  privacyOptions,
  rolesOptions,
  clientTypeOptions,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const rolesTableContent = useMemo(
    () =>
      data?.roles.map((r, i) => (
        <tr key={i}>
          <td>
            {clientTypeOptions.find((co) => co.value === r.clientType)?.label ??
              ''}
          </td>
          <td>
            {privacyOptions.find((po) => po.value === r.privacy)?.label ?? ''}
          </td>
          <td>
            {r.roles
              .map(
                (id) => rolesOptions.find((ro) => ro.value === id)?.label ?? ''
              )
              .join(', ')}
          </td>
        </tr>
      )),
    [clientTypeOptions, data?.roles, privacyOptions, rolesOptions]
  );

  return (
    <div>
      <table cellPadding="0" cellSpacing="0">
        <tbody>
          <tr className="section">
            <td colSpan={2}>
              <h4 className="p-mt-0">{t('General')}</h4>
            </td>
          </tr>
          <tr>
            <td>
              <b>{t('Name')}</b>
            </td>
            <td>{data?.name || '-'}</td>
          </tr>
          <tr>
            <td>
              <b>{t('Label')}</b>
            </td>
            <td>{data?.label || '-'}</td>
          </tr>
          <tr>
            <td>
              <b>{t('Description')}</b>
            </td>
            <td>{data?.description || '-'}</td>
          </tr>
          <tr>
            <td>
              <b>{t('Category')}</b>
            </td>
            <td>{data?.route_category_name || '-'}</td>
          </tr>
          <tr>
            <td>
              <b>{t('Method')}</b>
            </td>
            <td>{data?.http_method || '-'}</td>
          </tr>
          <tr>
            <td>
              <b>{t('URI')}</b>
            </td>
            <td>{data?.uri || '-'}</td>
          </tr>
          <tr>
            <td>
              <b>{t('Created at')}</b>
            </td>
            <td>{data?.created_at || '-'}</td>
          </tr>
          <tr>
            <td>
              <b>{t('Created by')}</b>
            </td>
            <td>{data?.created_by_full_name || '-'}</td>
          </tr>
          <tr>
            <td>
              <b>{t('Modified at')}</b>
            </td>
            <td>{data?.modified_at || '-'}</td>
          </tr>
          <tr>
            <td>
              <b>{t('Modified by')}</b>
            </td>
            <td>{data?.modified_by_full_name || '-'}</td>
          </tr>

          <tr className="section">
            <td colSpan={2}>
              <h4>{t('Roles')}</h4>
            </td>
          </tr>
          <tr className="roles-table">
            <td colSpan={2}>
              <table cellPadding="0" cellSpacing="0">
                <thead>
                  <tr>
                    <th>{t('Client type')}</th>
                    <th>{t('Privacy')}</th>
                    <th>{t('Roles')}</th>
                  </tr>
                </thead>

                <tbody>{rolesTableContent}</tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default DialogContainer;
