import { Field, useFormikContext } from 'formik';
import { Checkbox } from 'primereact/checkbox';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useTranslation } from 'react-i18next';

import useAxios from '../../../../hooks/useAxios';
import FieldWithErrors from '../../../Forms/FieldWithErrors/FieldWithErrors';

function CreateEditDialogRolesDataTab() {
  const { t } = useTranslation();

  const { values } = useFormikContext();

  const { data: employeeRoles, isLoading } = useAxios('/roles/employees');

  return (
    <>
      <p className="mt-0">
        {t('Select the roles you want assigned to this employee')}
      </p>

      {isLoading ? (
        <div className="text-center">
          <ProgressSpinner style={{ width: 72 }} />
        </div>
      ) : (
        <div className="p-fluid">
          <FieldWithErrors name="roles" label={false}>
            {employeeRoles?.data?.map((r, idx) => {
              const id = parseInt(r.id);

              return (
                <div key={id} className="checkbox-container">
                  <Field
                    as={Checkbox}
                    inputId={`roles_${id}`}
                    name="roles"
                    value={id}
                    checked={values.roles.includes(id)}
                    tooltipOptions={{ position: 'top' }}
                  />

                  <label htmlFor={`roles_${id}`} data-cy={`roles-${idx}`}>
                    {r.name}
                  </label>
                </div>
              );
            })}
          </FieldWithErrors>
        </div>
      )}
    </>
  );
}

export default CreateEditDialogRolesDataTab;
