import { useTranslation } from 'react-i18next';

import { PriceListReferences } from '../../../../../../types/api/priceLists';
import ReferenceList from './ReferenceList';

type Props = {
  references: PriceListReferences | undefined;
};

function ContainsReferences({ references }: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      <p className="p-mt-0">
        {t(
          'This price list cannot be deleted as it contains the following references'
        )}
        :
      </p>

      <ReferenceList
        title={t('Clients')}
        references={references?.clients ?? []}
      />

      <ReferenceList
        title={t('Price lists')}
        references={references?.price_lists ?? []}
      />

      <ReferenceList
        title={t('Prices')}
        references={references?.prices ?? []}
      />

      <ReferenceList
        title={t('Orders')}
        references={references?.orders ?? []}
      />
    </>
  );
}

export default ContainsReferences;
