import classNames from 'classnames';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useDebounce } from 'use-lodash-debounce';

import useAxiosHook from '../../../hooks/useAxiosHook';
import useHaveValuesChanged from '../../../hooks/useHaveValuesChanged';
import useSearchQueryDropdownParam from '../../../hooks/useSearchQueryDropdownParam';
import useSearchQueryParam from '../../../hooks/useSearchQueryParam';
import { EmployeeUserDeviceCollectionQueryParams } from '../../../types/api/employees';
import { OAuthClientsFilter } from '../../../types/api/oAuthClients';
import { LabelValue } from '../../../types/options';
import { debounceTimeout } from '../../../utils/constants/misc';
import { getSearchQueryParam } from '../../../utils/helpers/searchQuery';
import FiltersCounter from '../Components/Filters/FiltersCounter';

function useTableFilters(
  page: number,
  setPage: Dispatch<SetStateAction<number>>,
  limit: number
) {
  const { t } = useTranslation();
  const location = useLocation();
  const [name, setName] = useState(
    () => getSearchQueryParam(location.search, 'name') ?? ''
  );
  const [surname, setSurname] = useState(
    () => getSearchQueryParam(location.search, 'surname') ?? ''
  );
  const [username, setUsername] = useState(
    () => getSearchQueryParam(location.search, 'username') ?? ''
  );
  const [deviceId, setDeviceId] = useState(
    () => getSearchQueryParam(location.search, 'deviceId') ?? ''
  );
  const [deviceName, setDeviceName] = useState(
    () => getSearchQueryParam(location.search, 'deviceName') ?? ''
  );
  const [oAuthClientId, setOAuthClientId] = useState<string | null>(
    () => getSearchQueryParam(location.search, 'oAuthClientId') ?? null
  );
  const [appVersion, setAppVersion] = useState(
    () => getSearchQueryParam(location.search, 'appVersion') ?? ''
  );

  const debouncedName = useDebounce(name, debounceTimeout);
  const debouncedSurname = useDebounce(surname, debounceTimeout);
  const debouncedUsername = useDebounce(username, debounceTimeout);
  const debouncedDeviceId = useDebounce(deviceId, debounceTimeout);
  const debouncedDeviceName = useDebounce(deviceName, debounceTimeout);
  const debouncedAppVersion = useDebounce(appVersion, debounceTimeout);

  const { data: oAuthClientsData, isLoading: isOAuthClientsLoading } =
    useAxiosHook<OAuthClientsFilter>('/oauthclients/filter');

  const oAuthClientIdOptions = useMemo<LabelValue[]>(
    () =>
      oAuthClientsData?.map((c) => ({ label: c.name, value: String(c.id) })) ??
      [],
    [oAuthClientsData]
  );

  useSearchQueryParam('name', debouncedName);
  useSearchQueryParam('surname', debouncedSurname);
  useSearchQueryParam('username', debouncedUsername);
  useSearchQueryParam('deviceId', debouncedDeviceId);
  useSearchQueryParam('deviceName', debouncedDeviceName);
  useSearchQueryParam('appVersion', debouncedAppVersion);
  useSearchQueryDropdownParam(
    'oAuthClientId',
    oAuthClientId,
    setOAuthClientId,
    oAuthClientIdOptions
  );

  const filtersArr = useMemo(
    () => [
      debouncedName,
      debouncedSurname,
      debouncedUsername,
      debouncedDeviceId,
      debouncedDeviceName,
      debouncedAppVersion,
      oAuthClientId,
    ],
    [
      debouncedAppVersion,
      debouncedDeviceId,
      debouncedDeviceName,
      debouncedName,
      debouncedSurname,
      debouncedUsername,
      oAuthClientId,
    ]
  );

  const haveFiltersChanged = useHaveValuesChanged(filtersArr);

  useEffect(() => {
    setPage(1);
  }, [setPage, filtersArr]);

  const httpFiltersObj = useMemo<EmployeeUserDeviceCollectionQueryParams>(
    () => ({
      name: debouncedName,
      surname: debouncedSurname,
      username: debouncedUsername,
      device_id: debouncedDeviceId,
      device_name: debouncedDeviceName,
      app_version: debouncedAppVersion,
      oauth_client_id: oAuthClientId ?? '',
      page: haveFiltersChanged ? 1 : page,
      limit,
    }),
    [
      debouncedAppVersion,
      debouncedDeviceId,
      debouncedDeviceName,
      debouncedName,
      debouncedSurname,
      debouncedUsername,
      haveFiltersChanged,
      limit,
      oAuthClientId,
      page,
    ]
  );

  const basicFiltersActiveFilterCount = Object.values({
    name,
    surname,
    deviceId,
    deviceName,
    oAuthClientId,
    appVersion,
  }).filter(Boolean).length;

  const filters = useMemo<JSX.Element>(
    () => (
      <Accordion multiple activeIndex={[0]}>
        <AccordionTab
          disabled
          headerTemplate={
            <FiltersCounter
              description={t('Basic filters')}
              counter={basicFiltersActiveFilterCount}
            />
          }
        >
          <div className="sidebar_filter">
            <label htmlFor="name">{t('Name')}</label>
            <InputText
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className={classNames({
                sidebar_filter_active: name,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="surname">{t('Surname')}</label>
            <InputText
              id="surname"
              value={surname}
              onChange={(e) => setSurname(e.target.value)}
              className={classNames({
                sidebar_filter_active: surname,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="username">{t('Username')}</label>
            <InputText
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className={classNames({
                sidebar_filter_active: username,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="deviceId">{t('Device ID')}</label>
            <InputText
              id="deviceId"
              value={deviceId}
              onChange={(e) => setDeviceId(e.target.value)}
              className={classNames({
                sidebar_filter_active: deviceId,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="deviceName">{t('Device name')}</label>
            <InputText
              id="deviceName"
              value={deviceName}
              onChange={(e) => setDeviceName(e.target.value)}
              className={classNames({
                sidebar_filter_active: deviceName,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="oAuthClientId">{t('App name')}</label>
            <Dropdown
              id="oAuthClientId"
              name="oAuthClientId"
              options={oAuthClientIdOptions}
              placeholder={
                isOAuthClientsLoading
                  ? t('Loading...')
                  : !oAuthClientIdOptions.length
                  ? t('No options available')
                  : undefined
              }
              showClear
              disabled={!oAuthClientIdOptions.length}
              value={oAuthClientId}
              onChange={(e) => setOAuthClientId(e.value)}
              className={classNames({
                sidebar_filter_active: oAuthClientId,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="appVersion">{t('App version')}</label>
            <InputText
              id="appVersion"
              value={appVersion}
              onChange={(e) => setAppVersion(e.target.value)}
              className={classNames({
                sidebar_filter_active: appVersion,
              })}
            />
          </div>
        </AccordionTab>
      </Accordion>
    ),
    [
      appVersion,
      deviceId,
      deviceName,
      isOAuthClientsLoading,
      name,
      oAuthClientId,
      oAuthClientIdOptions,
      surname,
      t,
      basicFiltersActiveFilterCount,
      username,
    ]
  );

  const headerFiltersCount = useMemo(
    () => basicFiltersActiveFilterCount,
    [basicFiltersActiveFilterCount]
  );

  function resetAllFilters() {
    setName('');
    setSurname('');
    setUsername('');
    setDeviceName('');
    setDeviceId('');
    setOAuthClientId(null);
    setAppVersion('');
  }

  return {
    filters,
    resetAllFilters,
    httpFiltersObj,
    headerFiltersCount,
  };
}

export default useTableFilters;
