import '../TrackShipmentsByLocation.scss';

import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useAxiosHook from '../../../../../hooks/useAxiosHook';
import { useEndpointGuard } from '../../../../../hooks/useEndpointGuard';
import useMediaQuery from '../../../../../hooks/useMediaQuery';
import usePageTitleToggler from '../../../../../hooks/usePageTitleToggler';
import useRouteDialog from '../../../../../hooks/useRouteDialog';
import useVirtualizedReactTableState from '../../../../../hooks/useVirtualizedReactTableState';
import {
  OrderLocationCollection,
  OrderLocationCollectionQueryParams,
} from '../../../../../types/api/orders';
import * as ordersGuards from '../../../../../utils/constants/auth/orders';
import {
  RoutePaths,
  constructIdRoute,
} from '../../../../../utils/constants/routePaths';
import { queryString } from '../../../../../utils/helpers/http';
import VirtualizedReactTable from '../../../../react-table/VirtualizedReactTable/VirtualizedReactTable';
import CreateEditDialog from '../../../Orders/Dialogs/CreateEdit/CreateEditDialog';
import ViewActiveOrderDialog from '../../../Orders/Dialogs/View/ViewActiveOrderDialog';
import { SingleOrder } from '../../../Orders/Orders.functions';
import {
  Row,
  generateContextMenu,
  getColumns,
} from '../TrackShipmentsByLocation.functions';

type Props = {
  selectedLocation: string | undefined;
};

function ShipmentCollection({ selectedLocation }: Props) {
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery('(max-width: 1000px)');

  const readShipmentGuard = useEndpointGuard(ordersGuards.readOrder);
  const editShipmentGuard = useEndpointGuard(ordersGuards.editOrder);

  const selectedLocationQueryFilter: OrderLocationCollectionQueryParams =
    useMemo(() => {
      if (selectedLocation) {
        const splitedID = selectedLocation.split('-');

        const entityType =
          splitedID[0] === '0'
            ? 'unknown'
            : splitedID[0] === '1'
            ? 'sender'
            : splitedID[0] === '2'
            ? 'courier'
            : splitedID[0] === '3'
            ? 'transfer courier'
            : splitedID[0] === '4'
            ? 'warehouse'
            : splitedID[0] === '5'
            ? 'recipient'
            : '';

        const entityId = splitedID[1] !== '0' ? splitedID[1] : '';

        const filter: OrderLocationCollectionQueryParams = {
          entity_type: entityType,
          entity_id: entityId,
          limit: 0,
        };

        if (splitedID[2]) {
          filter['status_id'] = splitedID[2];
        }
        return filter;
      } else {
        return {};
      }
    }, [selectedLocation]);

  const {
    data: shipmentCollectionData,
    isLoading: isLoadingShipmentCollection,
  } = useAxiosHook<OrderLocationCollection>(
    '/orders/locations' +
      queryString<OrderLocationCollectionQueryParams>(
        selectedLocationQueryFilter
      ),
    {
      skipWhen: !selectedLocation,
    }
  );

  const columns = useMemo(() => getColumns(t), [t]);

  const shipments = useMemo(() => {
    if (!shipmentCollectionData) {
      return [];
    }
    return shipmentCollectionData.data;
  }, [shipmentCollectionData]);

  const tableInstance = useVirtualizedReactTableState<Row>({
    data: shipments ?? [],
    columns,
  });

  const {
    show: showViewOrderDialog,
    hide: hideViewOrderDialog,
    isVisible: isViewOrderDialogVisible,
  } = useRouteDialog(
    RoutePaths.TrackShipmentsByLocation,
    constructIdRoute(
      RoutePaths.ViewTrackedShipmentByLocation,
      tableInstance.selectedFlatRows?.[0]?.original?.order_id
    )
  );

  const {
    show: showEditDialog,
    hide: hideEditDialog,
    isVisible: isEditDialogVisible,
  } = useRouteDialog(
    RoutePaths.TrackShipmentsByLocation,
    constructIdRoute(
      RoutePaths.EditTrackedShipmentByLocation,
      tableInstance.selectedFlatRows?.[0]?.original?.order_id
    )
  );

  const {
    data: orderData,
    isLoading: isOrderDataLoading,
    reload: reloadActiveOrder,
  } = useAxiosHook<SingleOrder>(
    {
      url: `/orders/${tableInstance.selectedFlatRows?.[0]?.original?.order_id}`,
    },
    { skipWhen: !tableInstance.selectedFlatRows?.[0] }
  );

  usePageTitleToggler(
    isOrderDataLoading
      ? t('Loading...')
      : t('{{serialNo}} preview', {
          serialNo: orderData?.seriski_broj ?? '',
        }),
    t('Shipment Location'),
    isViewOrderDialogVisible
  );

  usePageTitleToggler(
    isOrderDataLoading
      ? t('Loading...')
      : t('{{serialNo}} edit', {
          serialNo: orderData?.seriski_broj ?? '',
        }),
    t('Shipment Location'),
    isEditDialogVisible
  );

  function handleCreateEditReload() {
    reloadActiveOrder();
  }

  const onViewShipmentInfo = useCallback(() => {
    if (!tableInstance.selectedFlatRows?.[0]) {
      return;
    }
    showViewOrderDialog();
  }, [showViewOrderDialog, tableInstance.selectedFlatRows]);

  const onEditShipmentInfo = useCallback(() => {
    if (!tableInstance.selectedFlatRows?.[0]) {
      return;
    }

    showEditDialog();
  }, [showEditDialog, tableInstance.selectedFlatRows]);

  function handleFromViewToEdit() {
    showEditDialog();
  }

  useEffect(() => {
    if (!readShipmentGuard) {
      hideViewOrderDialog();
    }
  }, [hideViewOrderDialog, readShipmentGuard]);

  useEffect(() => {
    if (!editShipmentGuard) {
      hideEditDialog();
    }
  }, [editShipmentGuard, hideEditDialog]);

  const contextMenuModel = useMemo(() => {
    return generateContextMenu(
      t,
      onViewShipmentInfo,
      readShipmentGuard,
      onEditShipmentInfo,
      editShipmentGuard
    );
  }, [
    editShipmentGuard,
    onEditShipmentInfo,
    onViewShipmentInfo,
    readShipmentGuard,
    t,
  ]);

  return (
    <div className="shipment-collection">
      <ViewActiveOrderDialog
        visible={isViewOrderDialogVisible}
        data={orderData}
        isLoading={isOrderDataLoading}
        {...(editShipmentGuard ? { onEdit: handleFromViewToEdit } : {})}
        onHide={hideViewOrderDialog}
      />

      <CreateEditDialog
        visible={isEditDialogVisible}
        onHide={hideEditDialog}
        data={orderData}
        isLoading={isOrderDataLoading}
        isEditDialog={isEditDialogVisible}
        reload={handleCreateEditReload}
        isSmallScreen={isSmallScreen}
        setOrderData={() => {}}
      />

      <VirtualizedReactTable
        tableInstance={tableInstance}
        header={t('Shipments')}
        isLoading={isLoadingShipmentCollection}
        rowSelection
        contextMenuModel={contextMenuModel}
      />
    </div>
  );
}

export default ShipmentCollection;
