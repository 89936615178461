import dayjs, { Dayjs } from 'dayjs';

import store from '../../redux/store';
import { Numeric } from '../../types/general';
import { getCountryString } from './misc';

// TODO: make showCurrency to default to true
export function currencyFormat(number: Numeric, config = {}): string {
  let conf =
    getCountryString() === 'mk'
      ? {
          locale: 'mk-MK',
          currency: 'MKD',
          showCurrency: false,
          ...config,
        }
      : {
          locale:
            store.getState().language === 'sr-Cyrl' ? 'sr-Cyrl' : 'sr-Latn',
          currency: 'RSD',
          showCurrency: false,
          ...config,
        };

  return new Intl.NumberFormat(
    conf.locale,
    conf.showCurrency
      ? {
          style: 'currency',
          currency: conf.currency,
        }
      : {}
  ).format(Number(number));
}

export function dateFormat(date: Dayjs | Date | string): string {
  return dayjs(date).format('DD MMM YYYY');
}

export function dateTimeFormat(date: Dayjs | Date | string): string {
  return dayjs(date).format('DD MMM YYYY, HH:mm:ss');
}

export function httpDateFormat(date: Dayjs | Date | string): string {
  const _date = dayjs(date);

  return _date.isValid() ? _date.format('YYYY-MM-DD') : '';
}

export function withTrailingEllipsis(text: string, from: number, to: number) {
  const substring = text.substring(from, to);

  return text.length > substring.length ? `${substring} ...` : substring;
}

export function percentageFormat(input: Numeric, multiply: boolean = false) {
  if (multiply) {
    return `${Number(input) * 100}%`;
  }

  return `${input}%`;
}
