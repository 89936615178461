import dayjs from 'dayjs';

import { httpDateFormat } from '../../../../../../utils/helpers/formatting';

export function toApiData(
  dateFrom: Date,
  dateTo: Date,
  period: string,
  isWithSubsidiariesOn: boolean
) {
  const yearMonthString = dayjs(dateTo).format('YYYY-MM');
  const daysInMonth = dayjs(yearMonthString).daysInMonth();

  const newDateTo = new Date(`${yearMonthString}-${daysInMonth}`);

  const filters = {
    date_from: httpDateFormat(dateFrom),
    date_to: httpDateFormat(newDateTo),
    time_unit: period,
  };

  return isWithSubsidiariesOn ? { ...filters, with_subsidiaries: 1 } : filters;
}
