import './CreateEditDialog.scss';

import { Form, Formik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useAxios from '../../../../../../hooks/useAxios';
import useMediaQuery from '../../../../../../hooks/useMediaQuery';
import {
  getInitialValues,
  getValidationSchema,
} from './CreateEditDialog.functions';
import DialogContainer from './DialogContainer';

function CreateEditDialog({
  formRef,
  isShown,
  isEditDialog,
  data,
  onHide,
  onFormSubmision,
  isFormSubmissionRequestLoading,
  step,
  setStep,
  isNameUsed,
  setIsNameUsed,
}) {
  const { t } = useTranslation();

  const validationSchema = useMemo(() => getValidationSchema(t), [t]);

  const { data: regionsData, isLoading: isLoadingRegions } = useAxios(
    `/hubs/regions-not-assigned`,
    undefined,
    { skipWhen: !isShown }
  );

  const regionsOptions =
    regionsData?.data?.map((region) => {
      return { id: parseInt(region.id), name: region.name };
    }) ?? [];

  if (isEditDialog && data.regions) {
    data.regions.forEach((region) => {
      regionsOptions.unshift(region);
    });
  }

  const dialogHeader = isEditDialog ? data.name : t('Add new warehouse');

  const dialogFooter = (
    <div>
      <Button
        type="button"
        label={t('Cancel')}
        className="p-button-secondary p-button-text"
        onClick={() => {
          onHide();
          setStep(1);
        }}
        style={{ float: 'left' }}
      />

      {step > 1 && (
        <Button
          type="button"
          label={t('Back')}
          className="p-button-text"
          onClick={() => {
            let previousStep = step - 1;
            setStep(previousStep);
          }}
          disabled={step === 1}
          icon="fa fa-angle-left"
        />
      )}

      <Button
        type="button"
        label={step < 3 ? t('Next') : isEditDialog ? t('Save') : t('Create')}
        icon={step < 3 ? 'fas fa-angle-right' : null}
        iconPos="right"
        disabled={isFormSubmissionRequestLoading}
        onClick={() => {
          if (formRef.current) {
            formRef.current.handleSubmit();
          }
        }}
        className={step < 3 ? 'p-button-outlined' : 'p-button-primary'}
        data-cy="submit-btn"
      />
    </div>
  );

  const isOnMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Dialog
      visible={isShown}
      onShow={() => {
        setStep(1);
      }}
      onHide={() => {
        onHide();
        setStep(1);
      }}
      header={dialogHeader}
      footer={dialogFooter}
      blockScroll
      resizable={false}
      maximizable={isOnMobile}
      maximized={isOnMobile}
      className="hubs-create-edit-dialog"
    >
      <Formik
        innerRef={formRef}
        initialValues={getInitialValues(data)}
        enableReinitialize={false}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={validationSchema[step]}
        onSubmit={() => onFormSubmision(formRef?.current?.values, step)}
      >
        <Form>
          <DialogContainer
            step={step}
            isEditDialog={isEditDialog}
            regionsOptions={regionsOptions}
            isLoadingRegions={isLoadingRegions}
            data={data}
            isNameUsed={isNameUsed}
            setIsNameUsed={setIsNameUsed}
            isShown={isShown}
          />
        </Form>
      </Formik>
    </Dialog>
  );
}

export default CreateEditDialog;
