import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

type Props = {
  to: string | undefined;
  className: string | undefined;
  children: ReactNode;
};

function MaybeLink({ to, className, children }: Props): JSX.Element {
  return to ? (
    <Link to={to} className={className}>
      {children}
    </Link>
  ) : (
    <div className={className}>{children}</div>
  );
}

export default MaybeLink;
