import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { InvoiceCandidatesCustomerCollection } from '../../../../../types/api/invoices';
import { currencyFormat } from '../../../../../utils/helpers/formatting';
import SidebarItem from '../../../../Grid/Sidebar/SidebarItem';
import { Row } from '../InvoicesCreation.functions';

type Props = {
  collectionData: InvoiceCandidatesCustomerCollection | undefined;
  selectionMultiple: Row[];
};

function Stats({ collectionData, selectionMultiple }: Props): JSX.Element {
  const { t } = useTranslation();

  const totalAmountSelection = useMemo(
    () =>
      selectionMultiple.reduce(
        (acc, selection) => acc + selection.price_and_vat_discounted,
        0
      ),
    [selectionMultiple]
  );

  const totalPriceAndVatDiscounted =
    collectionData?.summary?.total_price_and_vat_discounted;

  return (
    <SidebarItem template="stats">
      <p>
        - {t('Total invoice candidates')}:{' '}
        <b>{collectionData?.pagination.total ?? '-'}</b>
      </p>

      <p>
        - {t('Total amount')}:{' '}
        <b>
          {totalPriceAndVatDiscounted
            ? currencyFormat(totalPriceAndVatDiscounted, {
                showCurrency: true,
              })
            : '-'}
        </b>
      </p>

      <p>
        - {t('Selected candidates')}: <b>{selectionMultiple.length}</b>
      </p>

      <p>
        - {t('Total amount from selected candidates')}:{' '}
        <b>{currencyFormat(totalAmountSelection, { showCurrency: true })}</b>
      </p>
    </SidebarItem>
  );
}

export default Stats;
