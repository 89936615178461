import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';

import useMediaQuery from '../../../../../hooks/useMediaQuery';
import HubReportSkeleton from '../Skeletons/HubReportSkeleton';

type Props = {
  visible: boolean;
  hubData: any | undefined;
  isLoading: boolean;
  onHide: () => void;
};

function HubReportsDialog({ visible, hubData, isLoading, onHide }: Props) {
  const { t } = useTranslation();
  const isOnMobile = useMediaQuery('(max-width: 768px)');

  console.log('HubReportsDialog ', hubData);
  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header={
        isLoading
          ? t('Loading...')
          : t('Hub {{name}}', { name: hubData?.name ?? '' })
      }
      footer={<Button label={t('Close')} onClick={() => onHide()} />}
      dismissableMask
      blockScroll
      maximizable
      maximized={isOnMobile}
      className="view-order-dialog"
    >
      {isLoading ? <HubReportSkeleton /> : <div>{hubData?.title}</div>}
    </Dialog>
  );
}
export default HubReportsDialog;
