import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import useAxiosHook from '../../../../../../hooks/useAxiosHook';
import useToastMessage from '../../../../../../hooks/useToastMessage';
import { CreateReconciliationResourceError } from '../../../../../../types/api/reconciliations';
import { sequential } from '../../../../../../utils/helpers/functions';
import {
  getDefaultValues,
  getValidationSchema,
  toApiData,
} from './CreateReconciliationDialog.functions';
import { FormFields } from './CreateReconciliationDialog.types';
import FormContainer from './FormContainer';

type Props = {
  isShown: boolean;
  onSuccess: () => void;
  onHide: () => void;
  goToReconciliation: (id: number) => void;
};

function CreateReconciliationDialog({
  isShown,
  onSuccess,
  onHide,
  goToReconciliation,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const defaultValues = useMemo(() => getDefaultValues(new Date()), []);

  const validationSchema = useMemo(() => getValidationSchema(t), [t]);

  const methods = useForm<FormFields>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const { setError, reset } = methods;

  const { data, error, response, isLoading, reload } =
    useAxiosHook<
      { data: string; id: number },
      CreateReconciliationResourceError
    >();

  function handleFormSubmission(values: FormFields) {
    const data = toApiData(values);

    reload({ url: '/reconciliations', method: 'POST', data });
  }

  useEffect(() => {
    if (isShown) {
      reset(defaultValues);
    }
  }, [defaultValues, isShown, reset]);

  useToastMessage(data, error, {
    success: {
      summary: t('Your reconciliation has been successfully created.'),
      callback: () =>
        sequential(onSuccess, onHide, () => {
          if (data?.id) {
            goToReconciliation(data.id);
          }
        }),
    },
    error: {
      summary:
        response?.status === 422
          ? t(
              'A reconciliation already exists for this courier and date combination.'
            )
          : t('An error occured while creating your reconciliation.'),
      callback:
        response?.status === 422
          ? () => {
              setError('date', {
                type: 'custom',
                message: t(
                  'A reconciliation already exists for this courier and date combination.'
                ),
              });
            }
          : onHide,
    },
  });

  const dialogFooter = (
    <>
      <Button label={t('Close')} onClick={onHide} className="p-button-text" />

      <Button
        type="submit"
        form="create-reconciliation-form"
        label={isLoading ? t('Loading...') : t('Create')}
        disabled={isLoading}
      />
    </>
  );

  return (
    <Dialog
      header={t('Create a reconciliation')}
      footer={dialogFooter}
      visible={isShown}
      resizable={false}
      onHide={onHide}
      maximizable
      style={{ width: 480, maxWidth: '100%' }}
    >
      <FormContainer
        methods={methods}
        handleFormSubmission={handleFormSubmission}
      />
    </Dialog>
  );
}

export default CreateReconciliationDialog;
