import '../CourierCompensation.scss';

import { Column } from 'primereact/column';
import { DataTableExpandedRows } from 'primereact/datatable';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useTableColumns from '../../../../hooks/useTableColumns';
import useTableDataToDisplay from '../../../../hooks/useTableDataToDisplay';
import useTableState from '../../../../hooks/useTableState';
import { currencyFormat } from '../../../../utils/helpers/formatting';
import {
  formatMobileNumber,
  formatPhoneNumber,
  getPhoneorMobileNumberRegion,
} from '../../../../utils/helpers/phoneNumbers';
import Table from '../../../DataTable/Table/Table';
import {
  CourierCompDocumentAddresses,
  additionalCCDAddressColumnProperties,
  getCCDAddressesColumnHeadersMap,
} from '../CourierCompensation.functions';

type Props = {
  data: CourierCompDocumentAddresses[];
};

function AddressesTable({ data }: Props): JSX.Element {
  const [expandedRows, setExpandedRows] =
    useState<any[] | DataTableExpandedRows | undefined>();

  const { t } = useTranslation();

  const tableStorageKey = 'courier_comp_doc_addresses_dataTable';

  let row = 0;

  const addressesColumnHeadersMap = useMemo(
    () => getCCDAddressesColumnHeadersMap(t),
    [t]
  );

  const {
    tableRef,
    page,
    setPage,
    limit,
    setLimit,
    sortField,
    sortOrder,
    setSelection,
    setSortField,
    setSortOrder,
  } = useTableState(tableStorageKey);

  const { selectedColumns, setSelectedColumns, columnOptions, columns } =
    useTableColumns(
      page,
      limit,
      'courierCompensation_addresses',
      addressesColumnHeadersMap,
      addressesColumnHeadersMap,
      (c) =>
        additionalCCDAddressColumnProperties(
          c as keyof typeof addressesColumnHeadersMap
        )
    );

  function customDataModifier(
    data: CourierCompDocumentAddresses[]
  ): CourierCompDocumentAddresses[] {
    return data.sort((a: any, b: any) => b.serial_no - a.serial_no);
  }

  const dataToDisplay = useTableDataToDisplay(
    data,
    sortField,
    sortOrder,
    customDataModifier
  );
  const finalColumns = useMemo<JSX.Element[]>(
    () => [
      <Column expander style={{ width: '3em' }} key={row + 1} />,
      ...columns,
    ],
    [columns, row]
  );

  function rowExpansionTemplate(d: CourierCompDocumentAddresses) {
    return (
      <div key={d.id} className="p-d-flex p-jc-between row-expanded">
        <div className="p-m-3 more-info-section">
          <h4 className="p-mt-0 row-expanded-section-labels">
            {t('Shipment information')}
          </h4>
          <table cellPadding="0" cellSpacing="0">
            <tbody>
              <tr>
                <td className="row-cell">{t('Shipment Type')}</td>
                <td>{d.shipment_type_name ?? '-'}</td>
              </tr>

              <tr>
                <td className="row-cell">{t('Status')}</td>
                <td>{d?.status_name ?? '-'}</td>
              </tr>

              <tr>
                <td className="row-cell">{t('Redemption')}</td>
                <td>
                  {Intl.NumberFormat().format(Number(d.redemption)) ?? '-'}
                </td>
              </tr>

              <tr>
                <td className="row-cell">{t('Price')}</td>
                <td>
                  {currencyFormat(
                    Intl.NumberFormat().format(Number(d.price))
                  ) ?? '-'}
                </td>
              </tr>

              <tr>
                <td className="row-cell">{t('Weight')} (kg)</td>
                <td>
                  {`${currencyFormat(
                    Intl.NumberFormat().format(Number(d.weight))
                  )} kg` ?? '-'}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="p-m-3 more-info-section">
          <h4 className="p-mt-0 row-expanded-section-labels">
            {t('Sender information')}
          </h4>
          <table cellPadding="0" cellSpacing="0">
            <tbody>
              <tr>
                <td className="row-cell">{t('Name')}</td>
                <td>{d.sender_name ?? '-'}</td>
              </tr>

              <tr>
                <td className="row-cell">{t('Address')}</td>
                <td>{d.address_from ?? '-'}</td>
              </tr>

              <tr>
                <td className="row-cell">{t('Contact')}</td>
                <td>{d.contact_from ?? '-'}</td>
              </tr>
              <tr>
                <td className="row-cell">{t('Phone')}</td>
                <td>
                  {d.phone_from?.trim()
                    ? formatPhoneNumber(
                        d.phone_from,
                        getPhoneorMobileNumberRegion(d.phone_from)
                      )
                    : '-'}
                </td>
              </tr>
              <tr>
                <td className="row-cell">{t('Mobile')}</td>
                <td>
                  {d.mobile_from?.trim()
                    ? formatMobileNumber(
                        d.mobile_from,
                        getPhoneorMobileNumberRegion(d.mobile_from)
                      )
                    : '-'}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="p-m-3 more-info-section">
          <h4 className="p-mt-0 row-expanded-section-labels">
            {t('Recipient information')}
          </h4>
          <table cellPadding="0" cellSpacing="0">
            <tbody>
              <tr>
                <td className="row-cell">{t('Name')}</td>
                <td>{d.recipient_name ?? '-'}</td>
              </tr>

              <tr>
                <td className="row-cell">{t('Address')}</td>
                <td>{d.address_to ?? '-'}</td>
              </tr>

              <tr>
                <td className="row-cell">{t('Contact')}</td>
                <td>{d.contact_to ?? '-'}</td>
              </tr>
              <tr>
                <td className="row-cell">{t('Phone')}</td>
                <td>
                  {d.phone_to?.trim()
                    ? formatPhoneNumber(
                        d.phone_to,
                        getPhoneorMobileNumberRegion(d.phone_to)
                      )
                    : '-'}
                </td>
              </tr>
              <tr>
                <td className="row-cell">{t('Mobile')}</td>
                <td>
                  {d.mobile_to?.trim()
                    ? formatMobileNumber(
                        d.mobile_to,
                        getPhoneorMobileNumberRegion(d.mobile_to)
                      )
                    : '-'}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  return (
    <Table
      tableRef={tableRef}
      columns={finalColumns}
      data={dataToDisplay}
      hasError={false}
      isLoading={false}
      reload={() => console.log('reload')}
      setPage={setPage}
      setLimit={setLimit}
      sortField={sortField}
      lazy={false}
      rows={limit}
      setSortField={setSortField}
      setSortOrder={setSortOrder}
      sortOrder={sortOrder}
      setSelection={setSelection}
      selection={{}}
      expandedRows={expandedRows}
      onRowToggle={(e) => {
        setExpandedRows(e.data);
      }}
      rowExpansionTemplate={(e) => rowExpansionTemplate(e)}
      dataKey="id"
      storageString={tableStorageKey}
      rebuildTooltip
      selectedColumns={selectedColumns}
      setSelectedColumns={setSelectedColumns}
      columnOptions={columnOptions}
      headerTitle={t('Addresses')}
    />
  );
}

export default AddressesTable;
