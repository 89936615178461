import { useState } from 'react';

type Props = {
  defaultValueFrom?: Date;
  defaultValueTo?: Date;
};

function usePlainDateFilters({ defaultValueFrom, defaultValueTo }: Props = {}) {
  const [dateFrom, setDateFrom] = useState<Date | null>(
    () => defaultValueFrom ?? new Date()
  );

  const [dateTo, setDateTo] = useState<Date | null>(
    () => defaultValueTo ?? new Date()
  );

  return {
    dateFrom,
    setDateFrom,
    dateTo,
    setDateTo,
  };
}

export default usePlainDateFilters;
