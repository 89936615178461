import { ColumnProps } from 'primereact/column';
import { TFunction } from 'react-i18next';

import { getOrderStatusConfig } from '../../../../../../../../configs/orders';
import { OrderStatus } from '../../../../../../../../enums/orders';
import { TableColumnHeadersMap } from '../../../../../../../../types/util';
import {
  emptyCell,
  noColumnStyle,
} from '../../../../../../../../utils/constants/tables';
import { dateCell } from '../../../../../../../../utils/helpers/dataTable';
import { currencyFormat } from '../../../../../../../../utils/helpers/formatting';
import { Column, Row } from './Shipments.types';

export const tableStorageKey = 'invoicesShipments_dataTable';

export function getColumnHeadersMap(
  t: TFunction
): TableColumnHeadersMap<Column> {
  return {
    no: t('No.'),
    serial_number: t('Serial No.'),
    reference1: t('Reference 1'),
    reference2: t('Reference 2'),
    status_id: t('Status'),
    date_received: t('Reception Date'),
    date_delivered: t('Dispatch Date'),
    client_sender_name: t('Sender'),
    client_recipient_name: t('Recipient'),
    place_to_name: t('Place To'),
    place_from_name: t('Place From'),
    comment: t('Comment'),
    weight: t('Weight'),
    redemption: t('Redemption'),
    declared_value: t('Value'),
    sender_return_document: t('Return Document'),
    total_postage: t('Postage'),
    total_insurance: t('Insurance'),
    total_price: t('Price'),
  };
}

export function additionalColumnOptions(t: TFunction) {
  const orderStatusConfig = getOrderStatusConfig(t);

  return (column: Column): ColumnProps => {
    switch (column) {
      case 'no':
        return noColumnStyle;

      case 'client_recipient_name':
      case 'client_sender_name':
      case 'place_from_name':
      case 'place_to_name':
        return {
          style: {
            width: 250,
          },
          bodyStyle: {
            width: 250,
          },
        };

      case 'serial_number':
      case 'reference1':
      case 'reference2':
        return {
          style: {
            width: 200,
          },
          bodyStyle: {
            width: 200,
          },
        };

      case 'total_price':
      case 'sender_return_document':
      case 'total_insurance':
      case 'total_postage':
      case 'declared_value':
      case 'weight':
        return {
          body: (row: Row) =>
            currencyFormat(row[column], { showCurrency: true }),
          style: { width: 200 },
          bodyStyle: { width: 200 },
        };

      case 'date_received':
      case 'date_delivered':
        return {
          body: (row: Row) => dateCell(row[column], true),
          style: { width: 180 },
          bodyStyle: { width: 180 },
        };

      case 'status_id':
        return {
          body: (row: Row) =>
            orderStatusConfig[row[column] as OrderStatus].label,
          style: { width: 300 },
          bodyStyle: { width: 300 },
        };

      case 'redemption':
        return {
          body: (row: Row) => {
            return typeof row[column] === 'string'
              ? currencyFormat(row[column] as string, { showCurrency: true })
              : emptyCell;
          },
          style: {
            width: 150,
          },
          bodyStyle: {
            width: 150,
          },
        };

      case 'comment':
        return {
          body: (row: Row) => (!!row[column]?.trim() ? row[column] : emptyCell),
          style: {
            width: 180,
          },
          bodyStyle: {
            width: 180,
          },
        };
    }
  };
}

export function generateContextMenu(t: TFunction, handleViewClick: () => void) {
  return [
    {
      label: t('View Details'),
      icon: 'fas fa-search',
      command: handleViewClick,
    },
  ];
}
