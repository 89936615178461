import { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import ToastContext from '../../../../context/ToastContext';
import useAxiosHook from '../../../../hooks/useAxiosHook';
import usePrevious from '../../../../hooks/usePrevious';
import { WithPagination } from '../../../../types/api';
import { LabelValue } from '../../../../types/options';
import { EntityIdRouteParams } from '../../../../types/routing';
import { errorToast } from '../../../../utils/helpers/primereact';
import { OAuthClient } from '../../OAuthClients/OAuthClients.functions';
import {
  Route,
  resourceMockData as data,
  getPrivacyOptions,
} from '../Routes.functions';

function useDialogData(isShown: boolean, onHide: () => void) {
  const { t } = useTranslation();

  const { id } = useParams<EntityIdRouteParams>();

  const { toastRef } = useContext(ToastContext);

  const { error: dataError, isLoading: isDataLoading } = useAxiosHook<Route>(
    `/routes/${id}`,
    {
      skipWhen: !isShown || true, // !!!
    }
  );

  const {
    data: oAuthClientsData,
    error: oAuthClientsError,
    isLoading: isOAuthClientsLoading,
  } = useAxiosHook<WithPagination<OAuthClient[]>>('/oauthclients?limit=0', {
    skipWhen: !isShown,
  });

  const {
    data: rolesData,
    error: rolesError,
    isLoading: isRolesLoading,
  } = useAxiosHook<WithPagination<{ id: number; name: string }[]>>(
    '/roles?limit=0',
    {
      skipWhen: !isShown,
    }
  );

  const clientTypeOptions = useMemo<LabelValue<number>[]>(
    () =>
      oAuthClientsData?.data.map((c) => ({
        label: c.client_name,
        value: c.id,
      })) ?? [],
    [oAuthClientsData?.data]
  );

  const rolesOptions = useMemo<LabelValue<number>[]>(
    () =>
      rolesData?.data.map((c) => ({
        label: c.name,
        value: c.id,
      })) ?? [],
    [rolesData?.data]
  );

  const privacyOptions = useMemo<LabelValue[]>(() => getPrivacyOptions(t), [t]);

  const prevError = usePrevious(dataError);
  const prevOAuthClientsError = usePrevious(oAuthClientsError);
  const prevRolesError = usePrevious(rolesError);

  useEffect(() => {
    if (
      (!dataError || dataError === prevError) &&
      (!oAuthClientsError || oAuthClientsError === prevOAuthClientsError) &&
      (!rolesError || rolesError === prevRolesError)
    ) {
      return;
    }

    if (toastRef?.current) {
      errorToast(
        toastRef,
        t('Error'),
        t('An error occured while reading data.')
      );
    }

    onHide();
  }, [
    dataError,
    oAuthClientsError,
    onHide,
    prevError,
    prevOAuthClientsError,
    prevRolesError,
    rolesError,
    t,
    toastRef,
  ]);

  return {
    title: data?.name ?? `${data?.http_method ?? ''} ${data?.uri ?? ''}`,
    data,
    isLoading: isDataLoading || isOAuthClientsLoading || isRolesLoading,
    clientTypeOptions,
    rolesOptions,
    privacyOptions,
  };
}

export default useDialogData;
