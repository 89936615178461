import { ErrorMessage, Field, FieldArray, useFormikContext } from 'formik';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

function ChargingDepartmentsTab({
  clientData,
  isEditDialog,
  headquarterSubsidiaries,
}) {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext();
  const clientOptions = useMemo(
    () => [
      ...(!isEditDialog
        ? [
            {
              label: t('Registering customer'),
              value: 0,
            },
          ]
        : []),
      ...(headquarterSubsidiaries?.length
        ? headquarterSubsidiaries?.map((client) => ({
            label: client.ime,
            value: client.id,
          }))
        : []),
    ],
    [headquarterSubsidiaries, isEditDialog, t]
  );

  return (
    <div className="charging-departments-tab">
      <FieldArray name="departments">
        {({ push, remove }) => (
          <div>
            <Button
              type="button"
              icon="fas fa-plus"
              label={t('Add New')}
              className="p-mb-3"
              onClick={() =>
                push({
                  id: null,
                  name: '',
                  headquarters_id: clientData.id,
                  charge_customer_id: '',
                })
              }
            />
            <div className="p-fluid departments">
              {values.departments.map((chargingDepartment, index) => (
                <div className="charging-department" key={index}>
                  {values.departments.length > 1 && (
                    <Button
                      type="button"
                      icon="fas fa-trash"
                      className="p-button-outlined delete-department-btn"
                      onClick={() => remove(index)}
                    />
                  )}
                  <div className="department-name">
                    <label htmlFor="name">{t('Department')}</label>
                    <Field
                      as={InputText}
                      name={`departments[${index}].name`}
                      id="name"
                    />

                    <ErrorMessage name={`departments[${index}].name`}>
                      {(message) => (
                        <div className="department-error">{message}</div>
                      )}
                    </ErrorMessage>
                  </div>

                  <div className="department-client">
                    <label htmlFor="charge_customer_id">{t('Client')}</label>
                    <Field
                      as={Dropdown}
                      name={`departments[${index}].charge_customer_id`}
                      inputId="charge_customer_id"
                      options={clientOptions}
                      value={chargingDepartment.charge_customer_id}
                      onChange={(e) =>
                        setFieldValue(
                          `departments[${index}].charge_customer_id`,
                          e.value
                        )
                      }
                    />

                    <ErrorMessage
                      name={`departments[${index}].charge_customer_id`}
                    >
                      {(message) => (
                        <div className="department-error">{message}</div>
                      )}
                    </ErrorMessage>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </FieldArray>
    </div>
  );
}

export default ChargingDepartmentsTab;
