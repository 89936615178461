import { ContextMenuSection } from '../ContextMenu.types';
import Item from '../Item/Item';
import styles from './Section.module.scss';

type Props = ContextMenuSection & {
  hideContextMenu: () => void;
};

function Section({ title, items, hideContextMenu }: Props): JSX.Element {
  return (
    <li className={styles.section}>
      <span className={styles.title}>{title}</span>

      <menu>
        {items.map((item, idx) => (
          <Item key={idx} {...item} hideContextMenu={hideContextMenu} />
        ))}
      </menu>
    </li>
  );
}

export default Section;
