import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

function Grid({ children }: Props): JSX.Element {
  return <div className="p-grid">{children}</div>;
}

export default Grid;
