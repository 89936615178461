import { useTranslation } from 'react-i18next';

import useAxiosHook from '../../../../../hooks/useAxiosHook';
import useToastMessage from '../../../../../hooks/useToastMessage';
import {
  EntityCollection,
  EntityCollectionQueryParams,
} from '../../../../../types/api/entities';
import {
  FunctionExpressionCollection,
  OperatorExpressionCollection,
} from '../../../../../types/api/expressions';
import { queryString } from '../../../../../utils/helpers/http';

function usePriceTabHelpers() {
  const { t } = useTranslation();

  const entitiesRequest = useAxiosHook<EntityCollection>(
    '/entities' +
      queryString<EntityCollectionQueryParams>({
        show_properties: 1,
      })
  );

  const { error: entitiesError } = entitiesRequest;

  useToastMessage(undefined, entitiesError, {
    error: {
      summary: t('An error occured while loading entities.'),
    },
  });

  const functionsRequest = useAxiosHook<FunctionExpressionCollection>(
    '/expressions/functions'
  );

  const { error: functionsError } = functionsRequest;

  useToastMessage(undefined, functionsError, {
    error: {
      summary: t('An error occured while loading function expressions.'),
    },
  });

  const operatorsRequest = useAxiosHook<OperatorExpressionCollection>(
    '/expressions/operators'
  );

  const { error: operatorsError } = operatorsRequest;

  useToastMessage(undefined, operatorsError, {
    error: {
      summary: t('An error occured while loading operator expressions.'),
    },
  });

  return {
    entitiesRequest,
    functionsRequest,
    operatorsRequest,
  };
}

export default usePriceTabHelpers;
