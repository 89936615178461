import { TFunction } from 'react-i18next';
import * as Yup from 'yup';

import {
  PriceResource,
  UpdatePriceResourceRequestPayload,
} from '../../../../../types/api/prices';
import { yupRequiredField } from '../../../../../utils/helpers/yup';
import { CommonFormFields } from '../_Common/_Common.types';

export type FormFields = CommonFormFields;

export function getDefaultValues(
  resource: PriceResource | undefined
): FormFields {
  return {
    price: resource?.data.price ?? '',
  };
}

export function getValidationSchema(t: TFunction): Yup.SchemaOf<FormFields> {
  return Yup.object().shape({
    price: Yup.string().required(yupRequiredField(t, t('Expression'))),
  });
}

export function toApiData(
  values: FormFields
): UpdatePriceResourceRequestPayload {
  return { price: values.price };
}
