import classNames from 'classnames';
import _ from 'lodash';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Dispatch, useMemo } from 'react';
import { useState } from 'react';
import { SetStateAction } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDebounce } from 'use-lodash-debounce';

import { toStringBool } from '../../../configs/booleans';
import useHaveValuesChanged from '../../../hooks/useHaveValuesChanged';
import useSearchQueryBoolParam from '../../../hooks/useSearchQueryBoolParam';
import useSearchQueryDropdownParam from '../../../hooks/useSearchQueryDropdownParam';
import useSearchQueryParam from '../../../hooks/useSearchQueryParam';
import { PriceListCollectionQueryParams } from '../../../types/api/priceLists';
import { LabelValue } from '../../../types/options';
import { debounceTimeout } from '../../../utils/constants/misc';
import { getSearchQueryParam } from '../../../utils/helpers/searchQuery';
import FiltersCounter from '../Components/Filters/FiltersCounter';

function useTableFilters(
  page: number,
  setPage: Dispatch<SetStateAction<number>>,
  limit: number,
  parentOptions: LabelValue[]
) {
  const { t } = useTranslation();
  const location = useLocation();
  const [nameFilter, setNameFilter] = useState(
    () => getSearchQueryParam(location.search, 'name') ?? ''
  );
  const [descriptionFilter, setDescriptionFilter] = useState(
    () => getSearchQueryParam(location.search, 'desc') ?? ''
  );
  const [parentFilter, setParentFilter] = useState<string | null>(
    () => getSearchQueryParam(location.search, 'parent') ?? null
  );
  const [selfReferencedFilter, setSelfReferencedFilter] = useState<
    boolean | null
  >(() => {
    const param = getSearchQueryParam(location.search, 'selfReferenced');
    const parentParam = getSearchQueryParam(location.search, 'parent');
    return !parentParam && typeof param === 'string' ? !!parseInt(param) : null;
  });

  const debouncedNameFilter = useDebounce(nameFilter, debounceTimeout);
  const debouncedDescriptionFilter = useDebounce(
    descriptionFilter,
    debounceTimeout
  );

  const selfReferencedOptions = useMemo<LabelValue<boolean>[]>(
    () => [
      { label: t('Parent to self'), value: true },
      { label: t('Not a parent to self'), value: false },
    ],
    [t]
  );

  const filtersArr = useMemo(
    () => [
      debouncedNameFilter,
      debouncedDescriptionFilter,
      parentFilter,
      selfReferencedFilter,
    ],
    [
      debouncedDescriptionFilter,
      debouncedNameFilter,
      parentFilter,
      selfReferencedFilter,
    ]
  );

  const haveFiltersChanged = useHaveValuesChanged(filtersArr);

  useEffect(() => {
    setPage(1);
  }, [setPage, filtersArr]);

  useSearchQueryParam('name', debouncedNameFilter);
  useSearchQueryParam('desc', debouncedDescriptionFilter);
  useSearchQueryDropdownParam(
    'parent',
    parentFilter,
    setParentFilter,
    parentOptions
  );
  useSearchQueryBoolParam('selfReferenced', selfReferencedFilter);

  const httpFiltersObj = useMemo<
    Partial<PriceListCollectionQueryParams>
  >(() => {
    const returnObj = {
      page: haveFiltersChanged ? 1 : page,
      limit,
      name: debouncedNameFilter,
      description: debouncedDescriptionFilter,
      parent_id: parentFilter ?? undefined,
      is_self_referenced:
        selfReferencedFilter !== null
          ? toStringBool(selfReferencedFilter)
          : undefined,
    };

    return returnObj.parent_id
      ? _.omit(returnObj, 'is_self_referenced')
      : _.omit(returnObj, 'parent_id');
  }, [
    debouncedDescriptionFilter,
    debouncedNameFilter,
    haveFiltersChanged,
    limit,
    page,
    parentFilter,
    selfReferencedFilter,
  ]);

  const basicFiltersActiveFilterCount = Object.values({
    nameFilter,
    descriptionFilter,
    parentFilter,
    selfReferencedFilter,
  }).filter(Boolean).length;

  const filters = useMemo<JSX.Element>(
    () => (
      <Accordion multiple activeIndex={[0]}>
        <AccordionTab
          disabled
          headerTemplate={
            <FiltersCounter
              description={t('Basic filters')}
              counter={basicFiltersActiveFilterCount}
            />
          }
        >
          <div className="sidebar_filter">
            <label htmlFor="filter_name">{t('Name')}</label>
            <InputText
              id="filter_name"
              value={nameFilter}
              onChange={(e) => setNameFilter(e.target.value)}
              className={classNames({
                sidebar_filter_active: nameFilter,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="filter_desc">{t('Description')}</label>
            <InputText
              id="filter_desc"
              value={descriptionFilter}
              onChange={(e) => setDescriptionFilter(e.target.value)}
              className={classNames({
                sidebar_filter_active: descriptionFilter,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="filter_parent">{t('Parent')}</label>
            <Dropdown
              id="filter_parent"
              value={parentFilter}
              options={parentOptions}
              onChange={(e) => setParentFilter(e.value ?? null)}
              disabled={selfReferencedFilter !== null}
              showClear
              className={classNames({
                sidebar_filter_active: parentFilter,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="filter_self_referenced">
              {t('Parent to self')}
            </label>
            <Dropdown
              id="filter_self_referenced"
              value={selfReferencedFilter}
              options={selfReferencedOptions}
              onChange={(e) => setSelfReferencedFilter(e.value ?? null)}
              disabled={parentFilter !== null}
              showClear
              className={classNames({
                sidebar_filter_active: selfReferencedFilter,
              })}
            />
          </div>
        </AccordionTab>
      </Accordion>
    ),
    [
      descriptionFilter,
      nameFilter,
      parentFilter,
      parentOptions,
      selfReferencedFilter,
      selfReferencedOptions,
      basicFiltersActiveFilterCount,
      t,
    ]
  );

  const headerFiltersCount = useMemo(
    () => basicFiltersActiveFilterCount,
    [basicFiltersActiveFilterCount]
  );

  function resetAllFilters() {
    setNameFilter('');
    setDescriptionFilter('');
    setParentFilter(null);
    setSelfReferencedFilter(null);
  }

  return {
    filters,
    resetAllFilters,
    httpFiltersObj,
    headerFiltersCount,
  };
}

export default useTableFilters;
