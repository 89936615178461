import classNames from 'classnames';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { UseAxiosReturn } from '../../../../../hooks/useAxiosHook';
import { useEndpointGuard } from '../../../../../hooks/useEndpointGuard';
import useSearchQueryDropdownParam from '../../../../../hooks/useSearchQueryDropdownParam';
import { AvailableCouriersCollection } from '../../../../../types/api/couriers';
import { availableHubCollectionGuard } from '../../../../../utils/constants/auth/_api/hubs';
import CollapsibleFilterSection from '../../../../layout/flex/components/CollapsibleFilterSection';
import CommonStateContext from '../CommonStateContext';

type Props = {
  availableTransferCouriersRequest?: UseAxiosReturn<AvailableCouriersCollection>;
  availableCouriersRequest: UseAxiosReturn<AvailableCouriersCollection>;
};

function Filters({
  availableTransferCouriersRequest,
  availableCouriersRequest,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const availableHubsGuard = useEndpointGuard(availableHubCollectionGuard);

  const {
    dateFilter,
    setDateFilter,
    destinationHubFilter,
    setDestinationHubFilter,
    transferCourierFilter,
    setTransferCourierFilter,
    courierFilter,
    setCourierFilter,
    candidatesFilter,
    setCandidatesFilter,
    candidatesOptions,
    serialNumberFilter,
    setSerialNumberFilter,
    courierWorkOrderRequest: { data: courierWorkOrderData },
    courierWorkOrderId,
    transferCourierWorkOrderRequest: { data: transferCourierWorkOrderData },
    transferCourierWorkOrderId,
    isDateValid,
    isDestinationHubFilterShown,
    isTransferCourierFilterShown,
    availableHubsCollectionRequest,
    noCouriersAvailableMessage,
    dateTemplate,
    isLoadingHolidays,
  } = useContext(CommonStateContext);

  const { data: availableHubsData, isLoading: isAvailableHubsLoading } =
    availableHubsCollectionRequest;

  const { data: transferCouriersData, isLoading: isTransferCouriersLoading } =
    availableTransferCouriersRequest ?? {};

  const { data: couriersData, isLoading: isCouriersLoading } =
    availableCouriersRequest;

  const transferCourierOptions = useMemo(
    () =>
      transferCouriersData?.map((courier) => ({
        value: courier.id,
        label: courier.full_name,
      })) ?? [],
    [transferCouriersData]
  );

  const courierOptions = useMemo(
    () =>
      couriersData?.map((courier) => ({
        value: courier.id,
        label: courier.full_name,
      })) ?? [],
    [couriersData]
  );

  const destinationHubOptions = useMemo(
    () => availableHubsData?.map((h) => ({ label: h.name, value: h.id })) ?? [],
    [availableHubsData]
  );

  useSearchQueryDropdownParam(
    'destinationHub',
    availableHubsGuard ? destinationHubFilter : null,
    setDestinationHubFilter,
    destinationHubOptions
  );

  useSearchQueryDropdownParam(
    'courier',
    courierFilter,
    setCourierFilter,
    courierOptions
  );

  useSearchQueryDropdownParam(
    'transferCourier',
    transferCourierFilter,
    setTransferCourierFilter,
    transferCourierOptions
  );

  function resetAllFilters() {
    setDateFilter(new Date());
    setCourierFilter(null);
    setTransferCourierFilter(null);
    setDestinationHubFilter(null);
  }

  const basicFiltersHeaderTemplate = useCallback(
    () => (
      <div className="accordionHeaderTemplate">
        <span>{t('Basic filters')}</span>
        {/* {no counter > 0 && <span className="badge">{no counter}</span>} */}
      </div>
    ),
    [t]
  );

  return (
    <CollapsibleFilterSection
      childrenWrapperClassName={classNames('p-accordion-filters')}
      onResetFields={resetAllFilters}
      filterHeight={210}
    >
      <Accordion multiple activeIndex={[0]}>
        <AccordionTab disabled headerTemplate={basicFiltersHeaderTemplate}>
          <div className="sidebar_filter">
            <label htmlFor="date">{t('Date')}</label>
            <Calendar
              name="date"
              id="date"
              dateFormat="dd/mm/yy"
              disabled={isLoadingHolidays}
              dateTemplate={dateTemplate}
              readOnlyInput
              monthNavigator
              value={dateFilter}
              onChange={(e) => {
                setDateFilter(e.value as Date);
              }}
            />
          </div>

          {isDestinationHubFilterShown && availableHubsGuard && (
            <div className="sidebar_filter">
              <label htmlFor="destinationHub">
                {t('Destination warehouse')}
              </label>
              <Dropdown
                name="destinationHub"
                id="destinationHub"
                filter
                disabled={isAvailableHubsLoading || !availableHubsData?.length}
                placeholder={
                  isAvailableHubsLoading
                    ? t('Loading...')
                    : !destinationHubOptions.length
                    ? t('No warehouses are available')
                    : t('Select a warehouse')
                }
                value={destinationHubFilter}
                options={destinationHubOptions}
                onChange={(e) => setDestinationHubFilter(e.value)}
              />

              {availableHubsData && !destinationHubOptions.length && (
                <p className="p-my-2 p-error">
                  {t('No warehouses are available')}
                </p>
              )}
            </div>
          )}

          {isTransferCourierFilterShown && (
            <div className="sidebar_filter">
              <label htmlFor="transfer_courier">{t('Transfer Courier')}</label>
              <Dropdown
                name="transfer_courier"
                id="transfer_courier"
                filter
                showClear
                disabled={
                  isTransferCouriersLoading || !transferCourierOptions.length
                }
                placeholder={
                  isTransferCouriersLoading
                    ? t('Loading...')
                    : !transferCourierOptions.length
                    ? t('No couriers found')
                    : t('Select a courier')
                }
                value={transferCourierFilter}
                options={transferCourierOptions}
                onChange={(e) => setTransferCourierFilter(e.value)}
              />

              {transferCouriersData && !transferCourierOptions.length && (
                <p className="p-my-2 p-error">{noCouriersAvailableMessage}</p>
              )}
            </div>
          )}

          <div className="sidebar_filter">
            <label htmlFor="courier">
              {isTransferCourierFilterShown
                ? t('Loading courier')
                : t('Courier')}
            </label>
            <Dropdown
              name="courier"
              id="courier"
              filter
              showClear
              disabled={
                isCouriersLoading ||
                !courierOptions.length ||
                // Make sure a transfer courier is selected first
                (isTransferCourierFilterShown &&
                  (!transferCourierFilter ||
                    isTransferCouriersLoading ||
                    !transferCourierOptions.length))
              }
              placeholder={
                isCouriersLoading
                  ? t('Loading...')
                  : isTransferCourierFilterShown && !transferCourierFilter
                  ? t('Select a transfer courier first')
                  : !courierOptions.length
                  ? t('No couriers found')
                  : t('Select a courier')
              }
              value={courierFilter}
              options={courierOptions}
              onChange={(e) => setCourierFilter(e.value)}
            />

            {couriersData && !courierOptions.length && (
              <p className="p-my-2 p-error">{noCouriersAvailableMessage}</p>
            )}
          </div>

          <div className="sidebar_filter">
            <label htmlFor="candidates">{t('Candidates')}</label>
            <Dropdown
              name="candidates"
              id="candidates"
              filter
              disabled={!isDateValid}
              value={candidatesFilter}
              options={candidatesOptions}
              onChange={(e) => setCandidatesFilter(e.value)}
            />
          </div>

          <div className="sidebar_filter">
            <label htmlFor="serial_number">{t('Serial No.')}</label>
            <InputText
              name="serial_number"
              id="serial_number"
              value={serialNumberFilter}
              onChange={(e) => setSerialNumberFilter(e.target.value)}
            />
          </div>

          <div>
            <p>
              {isTransferCourierFilterShown ? (
                <>
                  <b>- {t('Work Order')}</b>
                  <br />
                  {t('Courier')}: <b>{courierWorkOrderId ?? 0}</b>
                  <br />
                  {t('Transfer Courier')}:{' '}
                  <b>{transferCourierWorkOrderId ?? 0}</b>
                </>
              ) : (
                <>
                  {t('Work Order')} <b>{courierWorkOrderId ?? 0}</b>
                </>
              )}
            </p>
          </div>
          <div>
            <p>
              {isTransferCourierFilterShown ? (
                <>
                  <b>- {t('Vehicle')}</b>
                  <br />
                  {t('Courier')}:{' '}
                  <b>
                    {courierWorkOrderData?.vehicle_registration_plate ?? '-'}
                  </b>
                  <br />
                  {t('Transfer Courier')}:{' '}
                  <b>
                    {transferCourierWorkOrderData?.vehicle_registration_plate ??
                      '-'}
                  </b>
                </>
              ) : (
                <>
                  {t('Vehicle')}:{' '}
                  <b>
                    {courierWorkOrderData?.vehicle_registration_plate ?? '-'}
                  </b>
                </>
              )}
            </p>
          </div>
        </AccordionTab>
      </Accordion>
    </CollapsibleFilterSection>
  );
}

export default Filters;
